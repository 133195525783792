import { Typography, Grid, List, ListItem, ListItemIcon } from "@mui/material";
import { ScoreMajor } from "~/components/scores/styles";
import { Asset } from "~/pages/inventory/asset/types";

type PrintPoliciesListProps = {
  asset: Asset;
};

export function PrintPoliciesList({ asset }: PrintPoliciesListProps) {
  const policies = asset.listPolicies?.edges || [];

  return (
    <Grid
      item
      xs={12}
      sx={{
        pb: 4,
        px: 4,
        borderBottom: "4px solid #DBDADD",
      }}
    >
      <Typography variant="h4" component="h2" mb={-1}>
        Policies
      </Typography>
      <List>
        {policies.map((edge) => {
          const score = edge.node.score;
          const policyName = edge.node.name;
          return (
            <ListItem disableGutters key={edge.node.mrn}>
              <ListItemIcon sx={{ minWidth: 0, mr: 3 }}>
                <ScoreMajor
                  className={`score-major ${score.grade}`}
                  sx={{
                    display: "inline-block",
                    m: 0,
                    fontSize: "inherit",
                    fontWeight: "bold",
                    lineHeight: "normal",
                    verticalAlign: "middle",
                  }}
                >
                  {score.grade}
                </ScoreMajor>
              </ListItemIcon>
              <Typography>{policyName}</Typography>
            </ListItem>
          );
        })}
      </List>
    </Grid>
  );
}
