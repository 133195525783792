import React from "react";
import { NavDrawerFleetIcon, PartialFleetIcon } from "~/components/icons";
import { CreateCaseMenu } from "~/components/cases/components/CreateCaseMenu/CreateCaseMenu";

type CreateCaseMenuButtonProps = {
  onAllAssetsItemClick: () => void;
};

export const CreateCaseMenuButton = ({
  onAllAssetsItemClick,
}: CreateCaseMenuButtonProps) => {
  return (
    <CreateCaseMenu
      id="create-case-button"
      actions={[
        {
          label: "All Assets",
          action: onAllAssetsItemClick,
          dataName: `create-case-all-assets`,
          icon: <NavDrawerFleetIcon />,
        },
        {
          label: "Selected Assets",
          action: () => {
            document
              .querySelector(`#affected-assets`)
              ?.scrollIntoView({ behavior: "smooth" });
          },
          dataName: `create-case-selected-assets`,
          icon: <PartialFleetIcon />,
        },
      ]}
    />
  );
};
