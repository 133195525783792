import { Box, Tooltip } from "@mui/material";

export interface CompletionBarProps {
  percent: number;
  height?: string;
  tooltip?: string;
  inactive?: boolean;
}

export function CompletionBar({
  percent = 0,
  height,
  tooltip,
  inactive = false,
}: CompletionBarProps) {
  return (
    <Tooltip title={tooltip ? tooltip : `${percent}%`} placement="top" arrow>
      <Box
        sx={{
          position: "relative",
          height: height ? height : (theme) => theme.spacing(2),
        }}
      >
        <Box
          sx={{
            position: "absolute",
            height: height ? height : (theme) => theme.spacing(2),
            width: 1,
            backgroundColor: "background.default",
            borderRadius: 0.5,
          }}
        />
        <Box
          sx={{
            position: "absolute",
            height: height ? height : (theme) => theme.spacing(2),
            width: `${inactive ? 100 : percent}%`,
            background: (theme) =>
              inactive
                ? `linear-gradient(270deg, ${theme.palette.unrated.main} 0%, #F1F1F1 100%)`
                : `linear-gradient(270deg, ${theme.palette.primary.light} 0%, #5000C1 100%)`,
            borderRadius: 0.5,
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              border: (theme) => {
                return percent > 0
                  ? `1px solid ${theme.palette.primary.light}`
                  : "none";
              },
              borderRadius: 0.5,
            },
          }}
        />
      </Box>
    </Tooltip>
  );
}
