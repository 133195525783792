import { useTheme } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import { getColor } from "~/lib/colors";
import { ScoreCard } from "../components/PolicyScoreCard";
import { PolicyAction } from "~/operations";
import { policyIcon } from "../utils/policyIcon";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";

export type UsePolicyScoreCardProps = {
  policyScorecard: ScoreCard;
  isCicd?: boolean;
};

export function usePolicyScoreCard({
  policyScorecard,
  isCicd,
}: UsePolicyScoreCardProps) {
  const theme = useTheme();
  const [searchParams, _setSearchparams] = useSearchParams();
  const { id } = useParams();

  const grade = policyScorecard.score.grade;
  let color = getColor(theme, grade);

  if (policyScorecard.action === PolicyAction.Ignore) {
    color = getColor(theme, "snooze");
  }
  if (!policyScorecard.action) {
    color = getColor(theme, "U");
  }

  const { __typename, ...rest } = policyScorecard.scoreStats || {};
  const scoreStats = Object.entries(rest)
    .filter(([key, _value]) =>
      ["passed", "failed", "errors", "snoozed", "disabled"].includes(key),
    )
    .map(([key, value]) => {
      if (key === "errors") {
        key = "errorscan";
      }

      return {
        result: key as keyof ScoreCard["scoreStats"],
        value,
      };
    });

  const byMondoo = policyScorecard.title.includes("by Mondoo");
  const policyName = byMondoo
    ? policyScorecard.title.replace(" by Mondoo", "")
    : policyScorecard.title;
  const encodedPolicyMrn = PolicyMrnToURIEncodedId(policyScorecard.mrn);

  const policyHref = isCicd
    ? `/space/cicd/jobs/${id}/checks?spaceId=${searchParams.get(
        "spaceId",
      )}&encodedPolicyMrn=${encodedPolicyMrn}&projectId=${searchParams.get(
        "projectId",
      )}&jobId=${searchParams.get("jobId")}`
    : `/space/inventory/${id}/checks?spaceId=${searchParams.get(
        "spaceId",
      )}&encodedPolicyMrn=${encodedPolicyMrn}`;

  const icon = policyIcon(policyScorecard.title);
  const isPreviewMode = policyScorecard.action === PolicyAction.Ignore;

  return {
    color,
    grade,
    icon,
    policyName,
    policyHref,
    byMondoo,
    scoreStats,
    isPreviewMode,
  };
}
