function loadHubspot() {
  const tag = document.createElement("script");
  const region =
    window.AppConfig.VITE_HUBSPOT_REGION || import.meta.env.VITE_HUBSPOT_REGION;
  const src = `//js-${region}.hsforms.net/forms/v2.js`;
  tag.type = "text/javascript";
  tag.async = true;
  tag.src = src;
  const firstScript = document.getElementsByTagName("script")[0];
  firstScript.parentNode.insertBefore(tag, firstScript);
}

loadHubspot();
