import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { ComplianceBreadcrumbs } from "../breadcrumbs";
import { Space } from "~/lib/types";
import {
  ExceptionMutationAction,
  ExceptionType,
  GetComplianceControlQuery,
  ReviewStatus,
  TestIamActionsQuery,
  useGetComplianceControlQuery,
  useGetComplianceFrameworkQuery,
} from "~/operations";
import { FrameworkHeader } from "../framework/framework-header";
import {
  MostRecentChanges,
  MostRecentChangesProps,
} from "../most-recent-changes";
import {
  LoadingFailedPage,
  LoadingPage,
  MoonIcon,
} from "~/components/ui-library";
import { SpeechBubbleIcon } from "../exception-icon";
import { BlockOutlined } from "@mui/icons-material";
import { sortBy } from "lodash";
import { pluralize } from "~/lib/pluralize";
import { ControlExceptionApproval } from "./control-outlets/exception-approval/exception-approval";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { CompletionV2 } from "~/pages/compliance/completionV2";
import { Distribution } from "~/pages/compliance/distribution";

export type ComplianceControl = GetComplianceControlQuery["complianceControl"];

type ControlContextType = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  control: ComplianceControl;
  controlMrn: string;
  frameworkMrn: string;
};

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Control({ space, availablePermissions }: Props) {
  const [searchParams] = useSearchParams();
  const frameworkMrn = searchParams.get("frameworkMrn") || "";
  const controlMrn = searchParams.get("controlMrn") || "";

  const {
    data: { complianceControl: control } = {},
    error,
    loading,
  } = useGetComplianceControlQuery({
    variables: {
      input: {
        controlMrn,
        frameworkMrn,
        scopeMrn: space.mrn,
      },
    },
  });

  const { data: { complianceFramework: framework } = {} } =
    useGetComplianceFrameworkQuery({
      variables: {
        input: {
          scopeMrn: space.mrn,
          frameworkMrn: frameworkMrn,
        },
      },
    });

  const { exceptionGroups, pendingAndReviewedExceptionGroups } =
    useFetchExceptions({
      mrn: controlMrn,
      scopeMrn: space.mrn,
      types: [ExceptionType.Compliance],
    });

  const { pendingExceptionsGroups: pendingChecksExceptionGroups } =
    useFetchExceptions({
      mrn: controlMrn,
      scopeMrn: space.mrn,
      types: [ExceptionType.Security],
    });

  if (loading) {
    return <LoadingPage what="compliance control" />;
  }

  if (error || !control) {
    return <LoadingFailedPage what="compliance control" />;
  }

  const tabList: TabListItem[] = [
    {
      label: "Data Queries",
      to: `data-queries?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&controlMrn=${controlMrn}`,
      route: "/data-queries",
    },
    {
      label: "Checks",
      to: `checks?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&controlMrn=${controlMrn}`,
      route: "/checks",
    },
    {
      label: "Assets",
      to: `assets?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&controlMrn=${controlMrn}`,
      route: "/assets",
    },
    {
      label: (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          Exceptions
          {pendingChecksExceptionGroups.length > 0 && (
            <Tooltip title="Exceptions pending review" placement="top" arrow>
              <SpeechBubbleIcon color="warning" sx={{ ml: 1, fontSize: 16 }} />
            </Tooltip>
          )}
        </Box>
      ),
      to: `exceptions?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&controlMrn=${controlMrn}`,
      route: "/exceptions",
    },
  ].flatMap((tab) => tab ?? []);

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "data-queries",
  );

  const maxChanges = 6;
  const changes: MostRecentChangesProps["changes"] = sortBy(
    exceptionGroups,
    "createdAt",
  )
    .filter((e) => e.reviewStatus === ReviewStatus.Approved)
    .reverse()
    .slice(0, maxChanges)
    .map((e) => {
      const isDisabled = e.action === ExceptionMutationAction.Disable;
      return {
        href: `exceptions?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&controlMrn=${controlMrn}&exceptionId=${e.id}`,
        description: (
          <Typography sx={{ fontSize: (theme) => theme.spacing(1.5) }}>
            Moved {e.exceptions.length}{" "}
            {pluralize("control", e.exceptions.length)} to{" "}
            <Typography
              component="span"
              textTransform="uppercase"
              fontWeight="bold"
              sx={{ fontSize: (theme) => theme.spacing(1.5) }}
            >
              {e.action}
            </Typography>
          </Typography>
        ),
        startIcon: isDisabled ? <BlockOutlined /> : <MoonIcon />,
      };
    });

  return (
    <Box>
      <ComplianceBreadcrumbs
        space={space}
        framework={framework}
        control={control}
      />
      <FrameworkHeader space={space} control={control} />
      <Grid container spacing={3} pt={3} mb={5}>
        <Grid item xs={12}>
          {pendingAndReviewedExceptionGroups.map((exceptionGroup) => (
            <ControlExceptionApproval
              availablePermissions={availablePermissions}
              exceptionGroup={exceptionGroup}
              frameworkMrn={frameworkMrn}
              controlMrn={controlMrn}
              space={space}
            />
          ))}
        </Grid>
        <Grid
          item
          xs={12}
          md={changes.length > 0 ? 8 : 12}
          rowSpacing={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            gap: 3,
          }}
        >
          <CompletionV2 progress={control.completion} />
          <Distribution
            disabledCount={control.checksStats.disabledChecks}
            snoozedCount={control.checksStats.snoozedChecks}
          />
        </Grid>
        {changes.length > 0 && (
          <Grid item xs={12} md={4}>
            <MostRecentChanges {...{ changes }} />
          </Grid>
        )}
      </Grid>
      <TabNavigation {...{ id: "space-settings-tabs", tabList, currentTab }} />
      <Box>
        <Outlet
          context={
            {
              space,
              availablePermissions,
              controlMrn,
              frameworkMrn,
              control,
            } satisfies ControlContextType
          }
        />
      </Box>
    </Box>
  );
}

export function useControlContext() {
  return useOutletContext<ControlContextType>();
}
