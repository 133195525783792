import { createSvgIcon } from "@mui/material";

export const TerraformColorIcon = createSvgIcon(
  <svg viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9844 9.86746L35.292 18.7077V36.3881L19.9844 27.5479V9.86746Z"
      fill="#5C4EE5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.9688 18.7077V36.3881L52.2836 27.5479V9.86746L36.9688 18.7077Z"
      fill="#4040B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0V17.6804L18.3076 26.5206V8.8402L3 0Z"
      fill="#5C4EE5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9844 47.1598L35.292 56V38.4426V38.3268L19.9844 29.4866V47.1598Z"
      fill="#5C4EE5"
    />
  </svg>,
  "Terraform",
);
