import { createSvgIcon } from "@mui/material";

export const AtlassianIcon = createSvgIcon(
  <svg
    version="1.1"
    id="svg36"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 67 67"
    enableBackground="new 0 0 67 67"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <linearGradient
        id="path13_00000075134051521385272860000001466305247365980325_"
        gradientUnits="userSpaceOnUse"
        x1="28.9514"
        y1="-1.8657"
        x2="11.8214"
        y2="-31.5457"
        gradientTransform="matrix(1 0 0 -1 -3.981800e-03 34.0385)"
      >
        <stop offset="0" stopColor="#0052CC" />
        <stop offset="0.92" stopColor="#2684FF" />
      </linearGradient>

      <path
        id="path13"
        inkscape:connector-curvature="0"
        fill="url(#path13_00000075134051521385272860000001466305247365980325_)"
        d="
M20,30.9c-0.6-0.8-1.8-1-2.6-0.4c-0.2,0.2-0.4,0.4-0.6,0.7L0.6,63.7c-0.5,1-0.1,2.1,0.9,2.6c0.3,0.1,0.6,0.2,0.9,0.2h22.6
c0.7,0,1.4-0.4,1.7-1.1C31.5,55.4,28.6,40.1,20,30.9z"
      />
      <path
        id="path15"
        inkscape:connector-curvature="0"
        fill="#2684FF"
        d="M31.9,1.4c-8.2,12.7-9.2,28.7-2.5,42.3l10.9,21.7
		c0.3,0.7,1,1.1,1.7,1.1h22.6c1.1-0.1,1.9-1.1,1.8-2.2c0-0.2-0.1-0.4-0.2-0.6L35.2,1.4c-0.4-0.9-1.5-1.3-2.4-0.9
		C32.4,0.8,32.1,1.1,31.9,1.4z"
      />
    </g>
  </svg>,
  "Atlassian",
);
