import { alpha, Box, Grid, List, ListItem, Typography } from "@mui/material";
import { useRef } from "react";
import { DonutChart } from "~/components/SmallDonut/Chart";
import { useDimensions } from "~/components/SmallDonut/useDimensions";
import { HubCard } from "~/components/hub-card";
import { getColor } from "~/lib/colors";
import { numberKFormat } from "~/lib/number-k-formatting";

export type SmallDonutData = {
  context: Array<{
    value: number;
    impact: string;
  }>;
  total: number;
};

type SmallDonutCardProps = {
  /**
   * data that is required for the donut chart
   */
  data: SmallDonutData;
  /**
   * title of the card
   */
  title: string;
  /**
   * if provided, the card will display an icon to the right of the title
   */
  icon?: JSX.Element;
  /**
   * if `true`, the card will display a loading spinner instead of content
   */
  loading?: boolean;
  /**
   * if a `to` prop is provided, the card will be wrapped in a `Link` component
   */
  to?: string;
  "data-testid"?: string;
};

export function SmallDonutCard({
  data,
  title,
  icon,
  loading,
  to,
  ...rest
}: SmallDonutCardProps) {
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width, height } = useDimensions(chartContainerRef);

  return (
    <HubCard
      loading={loading ? loading : false}
      {...(to && { to })}
      title={title}
      icon={icon}
      PaperProps={{
        minHeight: 208,
        pb: 1,
      }}
      data-testid={rest["data-testid"]}
    >
      {data && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: 1,
          }}
        >
          <Grid container sx={{ width: 1, height: 1 }}>
            <Grid
              item
              xs={3.5}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  textAlign: "center",
                  color: data.total > 0 ? "text.primary" : "text.disabled",
                }}
              >
                <Typography
                  sx={{
                    fontSize: (theme) => theme.spacing(5),
                    fontWeight: 800,
                    lineHeight: "normal",
                  }}
                >
                  {numberKFormat.format(data.total || 0)}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    textTransform: "uppercase",
                    color: "text.secondary",
                  }}
                >
                  Total
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              ref={chartContainerRef}
              xs="auto"
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <DonutChart width={width || 122} height={height} data={data} />
            </Grid>
            <Grid item xs>
              <List sx={{ pl: 2 }}>
                {data.context.map((d, i) => (
                  <ListItem key={i} disablePadding sx={{ py: 0.25 }}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        mr: 1,
                        background: (theme) =>
                          d.value > 0
                            ? getColor(theme, d.impact)
                            : alpha(theme.palette.text.primary, 0.3),
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        color: (theme) =>
                          d.value > 0
                            ? "inherit"
                            : alpha(theme.palette.text.primary, 0.3),
                        fontWeight: 700,
                        textTransform: "uppercase",
                      }}
                    >
                      {d.impact}
                      <Typography
                        variant="caption"
                        sx={{ fontWeight: 400, textTransform: "uppercase" }}
                        data-testid={`${d.impact}-value`}
                      >
                        {" "}
                        {d.value}
                      </Typography>
                    </Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      )}
    </HubCard>
  );
}
