import {
  ChangeEvent,
  Fragment,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import {
  alpha,
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  List,
  Divider,
  RadioGroup,
  Radio,
  Button,
  TextField,
  Link,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Paper,
} from "@mui/material";
import { ChevronLeftIcon, ChevronRightIcon } from "~/components/icons";
import { Markdown } from "~/components/markdown";
import { Space } from "~/lib/types";
import { UpdateK8Data } from "./k8-update-configuration";
import {
  ClientIntegrationType,
  GetIntegrationsSummaryDocument,
  K8sConfigurationOptionsInput,
  ListClientIntegrationsDocument,
  useCreateClientIntegrationMutation,
} from "~/operations";

type K8ConfigurationPanelProps = {
  data: { title: string; description: string };
  space: Space;
  updateData?: UpdateK8Data;
  hasUpdateK8IntegrationsPermission?: boolean;
  hasCreateK8IntegrationPermission?: boolean;
};

export type K8CustomizationFields = {
  scanNodes: boolean;
  scanWorkloads: boolean;
  scanDeploys: boolean;
  scanPublicImages: boolean;
  certificateManager: "cert-manager" | "openshift" | null;
};

export const K8ConfigurationPanel = ({
  data,
  space,
  updateData,
  hasUpdateK8IntegrationsPermission,
  hasCreateK8IntegrationPermission,
}: K8ConfigurationPanelProps) => {
  const initialConfig: K8sConfigurationOptionsInput = updateData
    ? {
        scanNodes: updateData.configurationOptions.scanNodes,
        scanWorkloads: updateData.configurationOptions.scanWorkloads,
        scanPublicImages: updateData.configurationOptions.scanPublicImages,
        scanDeploys: updateData.configurationOptions.scanDeploys,
        certificateManager: updateData.configurationOptions.certificateManager,
      }
    : {
        scanNodes: true,
        scanWorkloads: true,
        scanPublicImages: true,
        scanDeploys: false,
        certificateManager: "cert-manager",
      };

  const theme = useTheme();
  const { integrationId } = useParams();
  const navigate = useNavigate();
  const [token, setToken] = useState<string>("");
  const [customization, setCustomization] =
    useState<K8sConfigurationOptionsInput>(initialConfig);
  const {
    scanNodes,
    scanWorkloads,
    scanPublicImages,
    scanDeploys,
    certificateManager,
  } = customization;
  const [installURL, setInstallURL] = useState<string>("");
  const [newId, setNewId] = useState<string>("");
  const [integrationName, setIntegrationName] = useState<string>("");
  const [publicImageTextExpanded, setPublicImageTextExpanded] =
    useState<boolean>(false);
  const hasName = updateData?.name || integrationName;

  const [createIntegration, integrationResult] =
    useCreateClientIntegrationMutation({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      refetchQueries: [
        ListClientIntegrationsDocument,
        GetIntegrationsSummaryDocument,
      ],
    });

  const getId = (mrn: string): string => {
    const id = [...mrn.split(`/`)].pop();
    return id ? id : "";
  };

  useEffect(() => {
    if (installURL && !integrationResult.loading) {
      const rawToken =
        integrationResult.data?.createClientIntegration.integration.token;
      rawToken
        ? setToken(window.btoa(rawToken))
        : setToken("Error getting registration token.");
    }
  }, [integrationResult.loading]);

  useEffect(() => {
    const mrn = integrationResult.data?.createClientIntegration.integration.mrn;
    const id = getId(mrn ? mrn : "");
    id ? setNewId(id) : setNewId("");
  }, [integrationResult]);

  const submitCustomization = async () => {
    const baseURL: string = "https://install.mondoo.com/k8s/auditconfig?";
    let generatedURL = `nodes=${scanNodes}&kubernetesresources=${scanWorkloads}&admission=${scanDeploys}`;
    if (scanWorkloads) {
      generatedURL += `&containerImageScanning=${scanPublicImages}`;
    }
    if (scanDeploys) {
      generatedURL += `&admission_certificate_provisioning_mode=${certificateManager}`;
    }
    setInstallURL(`'${baseURL}${generatedURL}'`);
    if (updateData) {
      // update existing
      updateData.handleUpdate({
        scanNodes,
        scanWorkloads,
        scanPublicImages,
        scanDeploys,
        certificateManager,
      });
      setToken(window.btoa(updateData.token));
    } else {
      // create
      await createIntegration({
        variables: {
          input: {
            name: integrationName,
            spaceMrn: space.mrn,
            type: ClientIntegrationType.K8S,
            longLivedToken: false,
            configurationOptions: {
              k8sConfigurationOptions: {
                scanNodes,
                scanWorkloads,
                scanPublicImages,
                scanDeploys,
                certificateManager,
              },
            },
          },
        },
      });
    }
  };

  const resetcustomization = () => {
    setIntegrationName("");
    setInstallURL("");
    setToken("");
  };

  const optionHandler = (event: ChangeEvent<HTMLInputElement>) => {
    let newValue: string | boolean | null = null;
    if (event.target.id === "switch") {
      newValue = event.target.checked;
    } else if (event.target.id === "radio") {
      newValue = event.target.value;
    }
    setCustomization({
      ...customization,
      [event.target.name]: newValue,
    });
    if (event.target.name === "scanWorkloads") {
      setPublicImageTextExpanded(false);
    }
  };

  const customInstaller = (installURL: string) => {
    let newInstallConfig = data.description.replace(/\%URL\%/g, installURL);
    newInstallConfig = newInstallConfig.replace(/\%TOKEN\%/g, token);
    const descText = <Markdown source={newInstallConfig} />;
    return (
      <div key={`Kubernetes-${data.title.replace(" ", "-")}-bash-example`}>
        <h1>{data.title}</h1>
        {descText}
      </div>
    );
  };

  const hasPermission = updateData
    ? Boolean(hasUpdateK8IntegrationsPermission)
    : Boolean(hasCreateK8IntegrationPermission);

  const isDisabled = !hasName || !hasPermission;

  const renderAfterConfigButtons = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: newId ? "space-between" : "flex-end",
        }}
      >
        {newId && (
          <Button
            type="reset"
            variant="outlined"
            startIcon={<ChevronLeftIcon style={{ fontSize: 30 }} />}
            sx={{
              marginTop: 2,
              marginBottom: 3,
              color: theme.palette.primary.light,
              border: "1px solid",
              borderColor: alpha(theme.palette.primary.light, 0.5),
            }}
            data-name={"k8s-create-another-integration-button"}
          >
            Create Another Integration
          </Button>
        )}
        <Button
          sx={{ marginTop: 2, marginBottom: 3 }}
          color="primary"
          variant="contained"
          onClick={() => {
            newId
              ? navigate(`/space/integrations/add/mondoo/kubernetes/${newId}`)
              : navigate(
                  `/space/integrations/kubernetes/${integrationId}?spaceId=${space.id}`,
                );
          }}
          endIcon={newId ? <ChevronRightIcon style={{ fontSize: 30 }} /> : null}
          data-name={
            newId ? "k8s-set-policies-buttons" : "k8s-finished-update-button"
          }
        >
          {newId ? "Set Policies" : "Done"}
        </Button>
      </Box>
    );
  };

  const renderBeforeConfigButtons = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: updateData ? "space-between" : "flex-end",
          mt: 2,
        }}
      >
        {updateData && (
          <Button
            variant="outlined"
            component={RouterLink}
            to={
              integrationId
                ? `/space/integrations/kubernetes/${integrationId}?spaceId=${space.id}`
                : `/space/integrations/kubernetes?spaceId=${space.id}`
            }
            color="secondary"
            data-name="back-to-k8s-list-button"
          >
            Cancel
          </Button>
        )}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isDisabled}
          data-name={updateData ? "k8s-update-button" : "k8s-create-button"}
          sx={{
            "&.Mui-disabled": {
              cursor: hasPermission ? "" : "not-allowed",
              pointerEvents: "auto",
            },
          }}
        >
          {updateData ? "Update Configuration" : "Create"}
        </Button>
      </Box>
    );
  };

  return (
    <Fragment>
      {!installURL && (
        <form onSubmit={submitCustomization}>
          <Paper
            sx={{
              p: !updateData ? 0 : 3,
              boxShadow: !updateData ? 0 : "initial",
            }}
          >
            <FormGroup>
              <List disablePadding sx={{ listStyleType: "none" }}>
                <ListItem disablePadding>
                  <ListItemText
                    primary={"Integration name"}
                    secondary={
                      "A friendly name to identify this integration. Note: You will not be able to change this later"
                    }
                    primaryTypographyProps={{
                      variant: "h5",
                      component: "h3",
                      fontWeight: 700,
                      display: "inline-block",
                      sx: { mb: 1 },
                    }}
                  />
                  <TextField
                    required
                    value={updateData ? updateData.name : integrationName}
                    onChange={(e) => setIntegrationName(e.target.value)}
                    disabled={updateData ? true : false}
                  />
                </ListItem>
                <CustomizationOption
                  key="k8-scan-nodes-option"
                  primary="Scan nodes"
                  secondary="Allow Mondoo to scan cluster nodes for security misconfigurations and CVEs"
                  action={
                    <CustomizationSwitch
                      field={{
                        name: "scanNodes",
                        checked: scanNodes,
                      }}
                      onChange={optionHandler}
                    />
                  }
                />
                <CustomizationOption
                  key="k8-scan-workloads-option"
                  primary="Scan workloads"
                  secondary="Allow Mondoo to scan running workloads and resources"
                  action={
                    <CustomizationSwitch
                      field={{
                        name: "scanWorkloads",
                        checked: scanWorkloads,
                      }}
                      onChange={optionHandler}
                    />
                  }
                />
                <Grid container pr={2} pl={4}>
                  <Grid item xs={10}>
                    <ListItemText
                      key="k8-scan-public-images-option"
                      primary="Scan Container Images"
                      secondary={
                        <span>
                          {`Allow Mondoo to scan container images in workloads  `}
                          <Button
                            onClick={(e: SyntheticEvent) => {
                              setPublicImageTextExpanded((prev) => !prev);
                            }}
                            variant="text"
                            sx={{
                              textTransform: "unset",
                              color: scanWorkloads
                                ? theme.palette.primary.main
                                : theme.palette.text.disabled,
                              "&:hover": {
                                backgroundColor: theme.palette.background.paper,
                                borderColor: theme.palette.background.paper,
                                boxShadow: "none",
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {publicImageTextExpanded
                              ? "Show less"
                              : "This may exhaust your container registry quota"}
                          </Button>
                          <Accordion
                            expanded={publicImageTextExpanded}
                            sx={{ boxShadow: "none" }}
                          >
                            <AccordionSummary
                              expandIcon={null}
                              sx={{
                                "&.MuiAccordionSummary-root": {
                                  minHeight: 0,
                                  maxHeight: 0,
                                },
                                "&.Mui-expanded": { maxHeight: 0 },
                              }}
                            ></AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                variant="caption"
                                color={theme.palette.text.secondary}
                              >
                                With container image scanning enabled, the
                                Mondoo Operator will pull and scan all container
                                images daily. Many public registries use
                                IP-based quotas to limit the number of
                                unauthenticated container image pull requests
                                per day. Enabling image scanning may cause your
                                cluster to exceed non-authenticated image pull
                                request quotas on public registries.
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        </span>
                      }
                      primaryTypographyProps={
                        scanWorkloads
                          ? { color: theme.palette.text.primary }
                          : {
                              color: theme.palette.text.disabled,
                            }
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "end",
                    }}
                  >
                    <CustomizationSwitch
                      field={{
                        name: "scanPublicImages",
                        checked: scanPublicImages
                          ? scanWorkloads && scanPublicImages
                          : false,
                      }}
                      onChange={optionHandler}
                      disabled={!scanWorkloads}
                      noLabel={true}
                    />
                  </Grid>
                </Grid>
                <Divider
                  component="li"
                  key="k8-config-divider-1"
                  sx={{ my: 2 }}
                />
                <CustomizationOption
                  key="k8-scan-deploys-option"
                  primary="Scan incoming deployments"
                  secondary="Allow the Mondoo Admission controller to scan and display deployments in the CI/CD view"
                  action={
                    <CustomizationSwitch
                      field={{
                        name: "scanDeploys",
                        checked: scanDeploys,
                      }}
                      onChange={optionHandler}
                    />
                  }
                />
                <ul
                  key="k8-certificate-manager-option"
                  style={{ listStyleType: "none" }}
                >
                  <li key="k8-certificate-manager-detail">
                    <ListItemText
                      id="certificate-manager-choice"
                      primary="Choose which certificate manager is running in your cluster:"
                      secondary={
                        <span>
                          {`Learn more about `}
                          <Link
                            href="https://cert-manager.io/docs/"
                            target="_blank"
                            rel="noopener"
                            color={!scanDeploys ? "#BDBDBD" : "primary"}
                          >
                            cert-manager
                          </Link>
                          {` and `}
                          <Link
                            href="https://docs.openshift.com/"
                            target="_blank"
                            rel="noopener"
                            color={!scanDeploys ? "#BDBDBD" : "primary"}
                          >
                            OpenShift
                          </Link>
                        </span>
                      }
                      primaryTypographyProps={
                        scanDeploys
                          ? { variant: "body1" }
                          : {
                              variant: "body1",
                              color: theme.palette.text.disabled,
                            }
                      }
                    />
                    <RadioGroup
                      key="k8-certificate-manager-radio-group"
                      row
                      value={certificateManager}
                      name="certificateManager"
                      onChange={optionHandler}
                      aria-labelledby="certificate-manager-choice"
                    >
                      <FormControlLabel
                        value="cert-manager"
                        label="cert-manager"
                        control={
                          <Radio
                            id="radio"
                            disabled={!scanDeploys}
                            sx={{ paddingLeft: "21px" }}
                          />
                        }
                      />
                      <FormControlLabel
                        value="openshift"
                        label="OpenShift"
                        control={
                          <Radio
                            id="radio"
                            disabled={!scanDeploys}
                            sx={{ paddingLeft: "42px" }}
                          />
                        }
                      />
                    </RadioGroup>
                  </li>
                </ul>
              </List>
            </FormGroup>
          </Paper>
          {renderBeforeConfigButtons()}
        </form>
      )}
      {installURL && !integrationResult.loading && token && (
        <form onReset={resetcustomization}>
          {customInstaller(installURL)}
          {renderAfterConfigButtons()}
        </form>
      )}
    </Fragment>
  );
};

type CustomizationOptionProps = {
  primary: string;
  secondary: string | ReactNode;
  action?: ReactNode;
};

const CustomizationOption = ({
  primary,
  secondary,
  action,
}: CustomizationOptionProps) => {
  return (
    <ListItem key={primary.replace(/\s+/g, "-")} disablePadding>
      <ListItemText
        primary={primary}
        secondary={secondary}
        primaryTypographyProps={{
          variant: "h5",
          component: "h3",
          fontWeight: 700,
          display: "inline-block",
          sx: { mb: 1 },
        }}
      />
      <ListItemSecondaryAction>{action}</ListItemSecondaryAction>
    </ListItem>
  );
};

type CustomizationSwitchProps = {
  field: {
    name: string;
    checked: boolean;
  };
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  noLabel?: boolean;
};

const CustomizationSwitch = ({
  field: { name, checked },
  onChange,
  disabled,
  noLabel,
}: CustomizationSwitchProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          name={name}
          onChange={onChange}
          id="switch"
          disabled={disabled}
        />
      }
      label={noLabel ? "" : checked ? "On" : "Off"}
      labelPlacement="start"
    />
  );
};
