import { createSvgIcon } from "@mui/material";

export const ProviderGithubIcon = createSvgIcon(
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="128" height="128" rx="4" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M64 13C35.27 13 12 36.1704 12 64.7773C12 87.6888 26.885 107.041 47.555 113.901C50.155 114.354 51.13 112.801 51.13 111.442C51.13 110.212 51.065 106.134 51.065 101.798C38 104.193 34.62 98.6268 33.58 95.7143C32.995 94.2257 30.46 89.6305 28.25 88.4007C26.43 87.4299 23.83 85.0352 28.185 84.9705C32.28 84.9058 35.205 88.7244 36.18 90.2777C40.86 98.109 48.335 95.9085 51.325 94.5493C51.78 91.1838 53.145 88.9185 54.64 87.6241C43.07 86.3297 30.98 81.8639 30.98 62.059C30.98 56.4282 32.995 51.7683 36.31 48.1439C35.79 46.8494 33.97 41.5423 36.83 34.4229C36.83 34.4229 41.185 33.0637 51.13 39.7301C55.29 38.5651 59.71 37.9826 64.13 37.9826C68.55 37.9826 72.97 38.5651 77.13 39.7301C87.075 32.999 91.43 34.4229 91.43 34.4229C94.29 41.5423 92.47 46.8494 91.95 48.1439C95.265 51.7683 97.28 56.3635 97.28 62.059C97.28 81.9286 85.125 86.3297 73.555 87.6241C75.44 89.2421 77.065 92.3488 77.065 97.2029C77.065 104.128 77 109.694 77 111.442C77 112.801 77.975 114.419 80.575 113.901C101.115 107.041 116 87.6241 116 64.7773C116 36.1704 92.73 13 64 13Z"
      fill="#1B1F23"
    />
  </svg>,
  "Github",
);
