import { Flex } from "~/components/ui-library";
import { TableCell, Typography } from "@mui/material";
import React from "react";

export const ClosedCaseCell = () => {
  return (
    <TableCell>
      <Flex flex="1 0 auto" justifyContent="flex-end" sx={{ pr: 3 }}>
        <Typography color="text.disabled" fontSize={13}>
          CLOSED
        </Typography>
      </Flex>
    </TableCell>
  );
};
