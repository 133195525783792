import { createSvgIcon } from "@mui/material";

export const VPCSIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_13910)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M27 21.964C27 23.606 26.495 28.964 20 28.964C13.338 28.964 13 23.133 13 21.964V13.737L20 11.07L27 13.737V21.964ZM7 10H10V7H7V10ZM30 10H33V7H30V10ZM30 33H33V30H30V33ZM7 33H10V30H7V33ZM33.5 11C33.776 11 34 10.776 34 10.5V6.5C34 6.223 33.776 6 33.5 6H29.5C29.224 6 29 6.223 29 6.5V10.293L26.728 12.564L20.178 10.068C20.064 10.024 19.936 10.024 19.822 10.068L13.272 12.564L11 10.293V6.5C11 6.223 10.776 6 10.5 6H6.5C6.224 6 6 6.223 6 6.5V10.5C6 10.776 6.224 11 6.5 11H10.293L12.264 12.971C12.109 13.058 12 13.211 12 13.393V21.964C12 23.394 12.32 24.914 13.049 26.244L10.293 29H6.5C6.224 29 6 29.223 6 29.5V33.5C6 33.776 6.224 34 6.5 34H10.5C10.776 34 11 33.776 11 33.5V29.707L13.605 27.102C14.852 28.768 16.895 29.964 20 29.964C23.34 29.964 25.288 28.682 26.424 27.13L29 29.707V33.5C29 33.776 29.224 34 29.5 34H33.5C33.776 34 34 33.776 34 33.5V29.5C34 29.223 33.776 29 33.5 29H29.707L26.962 26.254C27.902 24.481 28 22.607 28 21.964V13.393C28 13.211 27.891 13.058 27.736 12.971L29.707 11H33.5ZM24 21.964C24 24.477 22.843 25.769 20.5 25.931V14.471L24 15.804V21.964ZM16 21.964V15.804L19.5 14.471V25.93C17.187 25.762 16 24.44 16 21.964ZM19.822 13.278L15.322 14.992C15.128 15.066 15 15.252 15 15.46V21.964C15 25.188 16.775 26.964 20 26.964C23.271 26.964 25 25.235 25 21.964V15.46C25 15.252 24.872 15.066 24.678 14.992L20.178 13.278C20.064 13.235 19.936 13.235 19.822 13.278Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_13910"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "VPCS",
);
