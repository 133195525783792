import { Fragment, useState } from "react";
import { Box, Button, Grid, Typography, useTheme } from "@mui/material";
import { RecommendedPolicies } from "./components/recommended-policies";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { WorkstationIcon } from "~/components/icons/mondoo/workstation";
import { ContentFlag } from "~/components/content-flag";
import { Code } from "~/components/code";
import { Markdown } from "~/components/markdown";
import { Command } from "~/components/guides/components";
import { useToken } from "~/providers/token";
import {
  ConfigExample,
  getCicdInstructions,
} from "~/components/integrations/cicd-instructions";
import { ProviderAzureIcon } from "~/components/icons/mondoo/provider-azure";
import { ProviderCircleCIIcon } from "~/components/icons/mondoo/provider-circleci";
import { ProviderJenkinsIcon } from "~/components/icons/mondoo/provider-jenkins";
import { ProviderGitlabIcon } from "~/components/icons/mondoo/provider-gitlab";
import { ProviderGithubIcon } from "~/components/icons/mondoo/provider-github";
import { Space } from "~/lib/types";
import { AnimatePresence, motion } from "framer-motion";
import { DockerIcon } from "~/components/icons/mondoo/docker-whale";
import { KubernetesIcon } from "~/components/icons/mondoo/kubernetes";
import { TerraformMarkIcon } from "~/components/icons/mondoo/terraform-mark";

export function CiCdIntegrationAdd({
  space,
  type,
}: {
  space: Space;
  type: string;
}) {
  const theme = useTheme();
  const { exchangedToken } = useToken({ autoExchange: true });
  const [view, setView] = useState<string>("setup");
  const [selectedConfig, setSelectedConfig] = useState<number>(0);

  const instructionSet = getCicdInstructions(type);
  const { title, flags, tagline, instructions } = instructionSet;

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ flags, title, type, tagline }} />
          <Box>
            {instructions.map((x) => {
              return (
                <Box pb={4} key={x.id}>
                  <Box sx={{ display: "inline-flex" }}>
                    <Command
                      number={x.id}
                      options={{
                        fontSize: { xs: 16 },
                        dotColor: theme.palette.background.lightest,
                      }}
                    >
                      {x.tagline}
                    </Command>
                  </Box>
                  <Box pl={6}>
                    {x.token && exchangedToken && (
                      <Fragment>
                        <Code className="bash" copyButton dataId="something">
                          {window.btoa(JSON.stringify(exchangedToken))}
                        </Code>
                      </Fragment>
                    )}
                    {x.link && x.href && (
                      <Box mt={3}>
                        <Button
                          href={x.href}
                          target="_blank"
                          variant="outlined"
                          color="secondary"
                          endIcon={<OpenInNewIcon />}
                          sx={{
                            py: 0.5,
                            px: 1,
                          }}
                        >
                          {x.link}
                        </Button>
                      </Box>
                    )}
                    {x.typography && (
                      <Box>
                        <Typography color="text.secondary" sx={{ pt: 3 }}>
                          {x.typography}
                        </Typography>
                      </Box>
                    )}
                    {x.markdownText && (
                      <Markdown
                        source={x.markdownText}
                        sx={{
                          color: theme.palette.text.secondary,
                          "& a": {
                            color: theme.palette.secondary.light,
                          },
                        }}
                      />
                    )}
                    {x.markdown && (
                      <Box className="integration">
                        <Markdown className="integration" source={x.markdown} />
                      </Box>
                    )}
                    {x.configExamples && (
                      <Box mt={3}>
                        <Box>
                          {x.configExamples.map((example, i) => (
                            <ConfigSelectorButton
                              key={example.id}
                              item={example}
                              itemIndex={i}
                              onClick={() => setSelectedConfig(i)}
                              selectedConfig={selectedConfig}
                            />
                          ))}
                        </Box>
                        <AnimatePresence>
                          {x.configExamples.map((example, i) => (
                            <Fragment key={example.id}>
                              {selectedConfig === i && (
                                <Box
                                  component={motion.div}
                                  initial={{ opacity: 0 }}
                                  animate={{ opacity: 1 }}
                                  exit={{ opacity: 0 }}
                                >
                                  <Markdown
                                    className="integration"
                                    source={example.config}
                                  />
                                </Box>
                              )}
                            </Fragment>
                          ))}
                        </AnimatePresence>
                      </Box>
                    )}
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              onClick={() => setView("add scans")}
            >
              start scanning
            </Button>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies {...{ space }} filterTypes={[type]} />
      )}
    </Fragment>
  );
}

type Flag = {
  id: string;
  title: string;
};

type Props = {
  type: string;
  title: string;
  tagline: string;
  flags: Flag[];
};

const IntegrationAddHeader = ({ flags, type, title, tagline }: Props) => {
  const getIconFromTitle = (type: string) => {
    const iconSx = { fontSize: 56 };

    switch (type.toLowerCase()) {
      case "azure":
        return <ProviderAzureIcon sx={iconSx} />;
      case "circleci":
        return <ProviderCircleCIIcon sx={iconSx} />;
      case "github":
        return <ProviderGithubIcon sx={iconSx} />;
      case "gitlab":
        return <ProviderGitlabIcon sx={iconSx} />;
      case "jenkins":
        return <ProviderJenkinsIcon sx={iconSx} />;
      default:
        return <WorkstationIcon sx={{ fontSize: 56 }} />;
    }
  };

  const getDocsLinkFromTitle = (type: string) => {
    switch (type.toLowerCase()) {
      case "azure":
        return "https://mondoo.com/docs/platform/infra/supply/cicd/azure-pipelines/";
      case "circleci":
        return "https://mondoo.com/docs/platform/infra/supply/cicd/circleci/";
      case "github":
        return "https://mondoo.com/docs/platform/infra/supply/cicd/github-actions/";
      case "gitlab":
        return "https://mondoo.com/docs/platform/infra/supply/cicd/gitlab/";
      case "jenkins":
        return "https://mondoo.com/docs/platform/infra/supply/cicd/jenkins/";
      default:
        return "https://mondoo.com/docs/";
    }
  };

  return (
    <Box>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid
          item
          xs={12}
          sm={8}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            mb: {
              xs: 2,
            },
          }}
        >
          {getIconFromTitle(type)}
          <Box ml={3}>
            <Typography
              variant="h4"
              fontWeight={700}
              sx={{ textTransform: "uppercase" }}
            >
              {title}
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ mt: -2, textTransform: "uppercase" }}
            >
              {tagline}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          sx={{
            textAlign: { xs: "left", sm: "right" },
          }}
        >
          <Button
            href={getDocsLinkFromTitle(type)}
            target="_blank"
            variant="outlined"
            color="secondary"
            endIcon={<OpenInNewIcon />}
            sx={{
              py: 0.5,
              px: 1,
            }}
          >
            Documentation
          </Button>
        </Grid>
      </Grid>
      <Box mb={5}>
        {flags.map((flag) => (
          <ContentFlag
            flag={flag.title}
            key={flag.id}
            layout={{ mr: 2, mb: { xs: 1, sm: 0 } }}
          />
        ))}
      </Box>
    </Box>
  );
};

type ConfigSelectorProps = {
  item: ConfigExample;
  itemIndex: number;
  selectedConfig: number;
  onClick: () => void;
};

const ConfigSelectorButton = ({
  item,
  itemIndex,
  selectedConfig,
  onClick,
}: ConfigSelectorProps) => {
  const getIcon = (id: string) => {
    const sxOptions = {
      mr: 1,
    };

    switch (id) {
      case "docker_config":
        return <DockerIcon sx={sxOptions} />;
      case "kubernetes_config":
        return <KubernetesIcon sx={sxOptions} />;
      case "terraform_config":
        return <TerraformMarkIcon sx={sxOptions} />;
      default:
        return <WorkstationIcon sx={sxOptions} />;
    }
  };

  const getTitle = (id: string) => {
    switch (id) {
      case "docker_config":
        return "Docker";
      case "kubernetes_config":
        return "Kubernetes";
      case "terraform_config":
        return "Terraform";
      default:
        return "Example Config";
    }
  };

  return (
    <Box
      component={Button}
      sx={{
        display: {
          xs: "flex",
          sm: "inline-flex",
        },
        mr: 2,
        mb: {
          xs: 2,
          sm: 0,
        },
        px: 2,
        py: 1,
        background: (theme) => theme.palette.background.paper,
        boxShadow: (theme) =>
          selectedConfig === itemIndex
            ? `0 0 0 1px ${theme.palette.primary.main}`
            : "",
      }}
      onClick={onClick}
    >
      {getIcon(item.id)}
      <Typography fontWeight={700}>{getTitle(item.id)}</Typography>
    </Box>
  );
};
