import { Box, Button, alpha, Grid, Typography } from "@mui/material";
import { GoogleIcon, GithubIcon, MicrosoftIconBW } from "~/components/icons";

type Props = {
  view: "signup" | "login";
  provider: string;
  handleLoginWithProvider: any;
};

export function LoginProviderButton({
  view,
  provider,
  handleLoginWithProvider,
}: Props) {
  const getProviderData = (provider: string) => {
    switch (provider) {
      case "msft":
        return {
          id: "msft",
          displayName: "Microsoft",
          icon: <MicrosoftIconBW sx={{ fontSize: { xs: 48, sm: 32 } }} />,
          backgroundColor: "#FFFFFF",
        };
      case "google":
        return {
          id: "google",
          displayName: "Google",
          icon: <GoogleIcon sx={{ fontSize: { xs: 48, sm: 32 } }} />,
          backgroundColor: "#4285F4",
        };
      case "github":
        return {
          id: "github",
          displayName: "GitHub",
          icon: <GithubIcon sx={{ fontSize: { xs: 48, sm: 32 } }} />,
          backgroundColor: "#333333",
        };
    }
  };

  const data = getProviderData(provider);
  if (!data) {
    return <Box />;
  }

  const id = `login-provider-${data.id}`;
  const formId = `${id}-form`;
  const buttonId = `${id}-button`;

  return (
    <Box
      id={formId}
      data-testid={formId}
      component="form"
      onSubmit={(event) => handleLoginWithProvider(event, data.id)}
    >
      <Button
        id={buttonId}
        data-testid={buttonId}
        type="submit"
        fullWidth
        sx={{
          background: data.backgroundColor,
          height: { xs: 96, sm: "unset" },
          minHeight: 56,
          border: "1px solid #454545",
          "&:hover": {
            background: alpha(data.backgroundColor, 0.8),
          },
          ...(data.id === "msft" && {
            color: "black",
          }),
        }}
      >
        <Box
          sx={{
            display: { xs: "flex", sm: "none" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {data.icon}
        </Box>
        <Grid
          container
          sx={{
            display: { xs: "none", sm: "flex" },
            alignItems: "center",
          }}
        >
          <Grid
            item
            sm={4}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {data.icon}
          </Grid>
          <Grid item xs sx={{ textAlign: "left" }}>
            <Typography
              sx={{
                textTransform: "initial",
                fontSize: (theme) => theme.spacing(2),
              }}
            >
              {view === "signup" ? "Sign up" : "Log in"} with {data.displayName}
            </Typography>
          </Grid>
        </Grid>
      </Button>
    </Box>
  );
}
