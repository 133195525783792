import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AssetAdvisoryException } from "~/operations";
import { Clamp } from "../clamp/clamp";
import { DynamicIcon } from "../icons/DynamicIcon";
import { Impact } from "../impact";
import { Space } from "~/lib/types";

export const AssetAdvisoryExceptionTable = ({
  exceptions,
  space,
}: {
  exceptions: AssetAdvisoryException[];
  space: Space;
}) => {
  let navigate = useNavigate();

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Result</TableCell>
          <TableCell>Advisory</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {exceptions.map((exception) => {
          const advisoryUrl = `/space/vulns/advisory/${exception.id}?spaceId=${space.id}`;

          const handleClick = () => {
            navigate(advisoryUrl);
          };

          return (
            <TableRow sx={{ cursor: "pointer" }} onClick={handleClick}>
              <TableCell>
                <Impact impact={exception.riskScore || 0} isRiskScore />
              </TableCell>
              <TableCell>
                <Clamp>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: 14,
                      lineHeight: "16px",
                      gap: 1,
                      fontWeight: 700,
                    }}
                    data-testid="advisory-id"
                  >
                    <DynamicIcon iconId={exception?.iconId} size="16" />
                    {exception?.title}
                  </Typography>
                </Clamp>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
