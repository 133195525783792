import { ImpactLabels } from "./constants";

type GetImpactLabelParams = {
  impact: number | undefined;
  isRiskScore?: boolean;
};

export function getImpactLabel({
  impact,
  isRiskScore,
}: GetImpactLabelParams): (typeof ImpactLabels)[keyof typeof ImpactLabels] {
  let impactNumber = Number(impact);

  if (isRiskScore) {
    if (impactNumber >= 91) {
      return ImpactLabels.NONE;
    } else if (impactNumber >= 71) {
      return ImpactLabels.LOW;
    } else if (impactNumber >= 41) {
      return ImpactLabels.MEDIUM;
    } else if (impactNumber >= 1) {
      return ImpactLabels.HIGH;
    } else {
      return ImpactLabels.Critical;
    }
  }

  if (impactNumber === 0) {
    return ImpactLabels.NONE;
  }

  if (!impactNumber) {
    return ImpactLabels.Unknown;
  }

  if (!impactNumber || impactNumber >= 91) {
    return ImpactLabels.Critical;
  } else if (impactNumber >= 71) {
    return ImpactLabels.HIGH;
  } else if (impactNumber >= 41) {
    return ImpactLabels.MEDIUM;
  } else if (impactNumber >= 1) {
    return ImpactLabels.LOW;
  } else {
    return ImpactLabels.INFO;
  }
}
