import { SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const AristaIcon = ({ ...props }) => {
  const { mode } = useColorMode();
  return (
    <SvgIcon {...props} data-testid="AristaIcon">
      <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M110.564 52.931C103.386 64.1062 8 213.5 8 213.5H41.845L73.6419 162.686H141.334L158.77 135.247H91.0764L127.999 76.303L214.154 212.479H248C248 212.479 153.639 65.1238 145.432 51.9133C136.203 38.7048 119.795 39.7195 110.564 52.931Z"
          fill={mode === "dark" ? "#FFFFFF" : "#16325B"}
        />
      </svg>
      ,
    </SvgIcon>
  );
};

AristaIcon.displayName = "Arista";
