import { ThemeOptions } from "@mui/material/styles";
import { sharedPalette } from "./sharedPalette";

export const printTheme: ThemeOptions = {
  palette: {
    mode: "light",
    background: {
      default: "#fff",
      paper: "rgb(250, 250, 250, 1)",
      light: "rgba(222,222,235,0.7)",
      lighter: "rgba(222,222,235,0.4)",
    },
    tables: {
      tableHead: {
        shadow: "none",
      },
      tableCell: {
        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
      },
    },
    code: {
      background: "#FFFFFF",
      text: "#2B2B2B",
    },
    ...sharedPalette,
  },
  // style overrides
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: "inherit",
          textDecoration: "none",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(240, 240, 240, 1)",
          boxShadow: "none",
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(250, 250, 250, 1)",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderBottom: "none",
          borderRadius: "4px",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "inherit",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "5px 5px 5px 5px",
          fontSize: "10pt",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none !important",
        },
      },
    },
  },
};
