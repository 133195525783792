import { Fragment } from "react";
import { debounce } from "lodash";
import { Link as RouterLink } from "react-router-dom";
import {
  alpha,
  Button,
  IconButton,
  Typography,
  Grid,
  Divider,
  Box,
  Tooltip,
} from "@mui/material";
import { PersonIcon } from "~/components/icons";
import { ContentFlag } from "~/components/content-flag";
import { AWSOrgSetupGuide } from "~/components/guides/aws/aws-org-setup";
import { FormatRelativeDate } from "~/lib/date";
import { EditIcon, DeleteIcon } from "~/components/icons";
import { ActionMenu } from "~/components/action-menu";
import { HeartbeatIcon } from "~/components/icons/mondoo/heartbeat";
import { TestIamActionsQuery } from "~/operations";
import { AwsIntegration } from "./integration-aws-account-serverless";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import { Flex } from "~/components/ui-library";

type Props = {
  space: Space;
  integrationId: string;
  awsIntegration: AwsIntegration;
  actions: {
    retrySetup: () => void;
    triggerScan: () => void;
    ping: () => void;
    deleteIntegration: () => void;
    forceLambdaUpdate: () => void;
    sendLambdaMetrics: () => void;
    sendDiagnosticsData: () => void;
    cancelScans: () => void;
  };
  mrn: string;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const AwsAccountHeader = ({
  awsIntegration,
  mrn,
  actions,
  availablePermissions,
  space,
  integrationId,
}: Props) => {
  const hasIntegrationDeletePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_DELETE,
  );

  const hasIntegrationTriggerActionPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_TRIGGERACTION,
  );

  const hasIntegrationsTokenPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_GETTOKENFORINTEGRATION,
  );

  const hasIntegrationUpdatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
  );

  if (
    awsIntegration.status === "WAITING_FOR_SETUP" &&
    awsIntegration?.configurationOptions?.isOrganization &&
    hasIntegrationsTokenPermission
  ) {
    return (
      <Box>
        <Flex justifyContent="flex-end" mb={2}>
          {hasIntegrationDeletePermission && (
            <IconButton
              size="large"
              sx={{
                mx: 0.5,
                color: (theme) => theme.palette.error.main,
                "&:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.error.main, 0.15),
                },
              }}
              onClick={actions.deleteIntegration}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Flex>
        <AWSOrgSetupGuide item={awsIntegration} />
      </Box>
    );
  }

  const formatStatus = (status: string) => {
    if (!status) return null;
    switch (status.toLowerCase()) {
      case "waiting_for_setup":
        return "pending";
      default:
        return status;
    }
  };

  const agentCheckinDate = (timestamp: string | null) => {
    if (timestamp == null || timestamp == "") {
      return "Never";
    }
    return FormatRelativeDate(timestamp);
  };

  const showPing =
    awsIntegration.status !== "WAITING_FOR_SETUP" &&
    awsIntegration.status !== "DELETED";

  const showRetry =
    awsIntegration.status !== "WAITING_FOR_SETUP" &&
    awsIntegration.status !== "DELETED" &&
    hasIntegrationTriggerActionPermission;

  const showTrigger =
    (awsIntegration.status === "ACTIVE" ||
      awsIntegration.status === "WARNING" ||
      awsIntegration.status === "ERROR") &&
    hasIntegrationTriggerActionPermission;

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} md>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              id="aws-integration-name"
              variant="h4"
              fontWeight={700}
              sx={{ display: "inline", pr: 2, overflowWrap: "anywhere" }}
            >
              {awsIntegration.name}
            </Typography>
            <ContentFlag
              flag={formatStatus(awsIntegration.status)}
              color={awsIntegration.status}
            />
          </Box>
          <Grid container columnGap={2}>
            <Grid item xs={12} sm="auto">
              <HeartbeatIcon
                sx={{
                  verticalAlign: "middle",
                  color: "text.secondary",
                }}
              />
              {awsIntegration.lastCheckin && (
                <Typography
                  display="inline"
                  variant="caption"
                  color="text.secondary"
                  sx={{ ml: 0.75 }}
                >
                  Last ping: {agentCheckinDate(awsIntegration.lastCheckin)}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm="auto">
              {awsIntegration.createdBy?.name != "" && (
                <Fragment>
                  <PersonIcon
                    sx={{
                      fontSize: "inherit",
                      color: "text.secondary",
                      verticalAlign: "middle",
                    }}
                  />
                  <Typography
                    display="inline"
                    variant="caption"
                    color="text.secondary"
                    sx={{ ml: 0.75 }}
                  >
                    Created by: {awsIntegration.createdBy?.name}
                  </Typography>
                </Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: "text.secondary",
            mb: 1.625,
          }}
          xs={12}
          md={5}
        >
          <Grid item xs display="flex" alignItems="center" justifyContent="end">
            {hasIntegrationUpdatePermission && (
              <IconButton
                size="large"
                component={RouterLink}
                to={`/space/integrations/aws/${integrationId}/edit?spaceId=${space.id}&integrationMrn=${awsIntegration.mrn}`}
                sx={{ mx: 0.5 }}
              >
                <EditIcon />
              </IconButton>
            )}
            {hasIntegrationDeletePermission && (
              <IconButton
                size="large"
                sx={{
                  mx: 0.5,
                  color: (theme) => theme.palette.error.main,
                  "&:hover": {
                    backgroundColor: (theme) =>
                      alpha(theme.palette.error.main, 0.15),
                  },
                }}
                onClick={actions.deleteIntegration}
              >
                <DeleteIcon />
              </IconButton>
            )}
            {hasIntegrationTriggerActionPermission && (
              <ActionMenu
                id="aws-account-actions"
                actions={[
                  {
                    label: "Copy MRN",
                    action: () => navigator.clipboard.writeText(`${mrn}`),
                    dataName: "aws-copyMrn",
                  },
                  // Retry only visible when not waiting for setup or deleted and user has permissions
                  ...(showRetry
                    ? [
                        {
                          label: "Retry Setup",
                          action: actions.retrySetup,
                          dataName: "aws-retrySetup",
                        },
                      ]
                    : []),
                  {
                    label: "Force Lambda Update",
                    action: actions.forceLambdaUpdate,
                    dataName: "aws-forceLambdaUpdate",
                  },

                  {
                    label: "Send Lambda Metrics",
                    action: actions.sendLambdaMetrics,
                    dataName: "aws-sendLambdaMetrics",
                  },

                  {
                    label: "Send Diagnostics Data",
                    action: actions.sendDiagnosticsData,
                    dataName: "aws-sendDiagnosticsData",
                  },
                  {
                    label: "Cancel Scans",
                    action: actions.cancelScans,
                    dataName: "aws-cancelScans",
                  },
                ]}
              />
            )}
            {/* We only show the divider if anything exists to the right of it */}
            {(showPing || showTrigger) && (
              <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
            )}
            {/* Ping only visible when not waiting for setup and not deleted */}
            {showPing && (
              <Tooltip title="Ping" placement="top" arrow>
                <IconButton
                  size="large"
                  onClick={actions.ping}
                  sx={{ mr: 3, ml: 1 }}
                  data-name="aws-ping"
                >
                  <HeartbeatIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* Scan Trigger only visible when user has permission and status is active/error/warning */}
            {showTrigger && (
              <Button
                variant="contained"
                color="primary"
                onClick={debounce(actions.triggerScan, 1000)}
                data-name="aws-triggerscan"
                sx={{
                  position: "relative",
                  background:
                    "linear-gradient(133.55deg, #9147FF 0%, #4C35E8 100%)", //<--- palette breaking
                  ml: 1,
                }}
              >
                Scan Now
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
