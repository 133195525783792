const policyMrnRegex =
  /\/\/policy.api.mondoo.app(?:\/spaces\/(.*)){0,1}\/policies\/(.*)/;

// //policy.api.mondoo.app/policies/amazon-linux-2-level-2 -> amazon-linux-2-level-2
// //policy.api.mondoo.app/spaces/space1/policies/amazon-linux-2-level-2 -> space1/amazon-linux-2-level-2
export const PolicyMrnToId = (mrn: string): string => {
  let m = policyMrnRegex.exec(mrn);
  if (m == null || m.length != 3) {
    return mrn;
  }
  let id = "";
  if (m[1] != null && m[1] != "") {
    id = m[1] + "/";
  }
  return id + m[2];
};

export const PolicyMrnToURIEncodedId = (mrn: string): string => {
  return encodeURIComponent(PolicyMrnToId(mrn));
};

// amazon-linux-2-level-2 -> //policy.api.mondoo.app/policies/amazon-linux-2-level-2
// space1/amazon-linux-2-level-2 -> //policy.api.mondoo.app/spaces/space1/policies/amazon-linux-2-level-2
export const PolicyIdToMrn = (id: string): string => {
  let decoded = decodeURIComponent(id);
  let res = decoded.split("/");
  if (res.length == 1) {
    return `//policy.api.mondoo.app/policies/${res[0]}`;
  }
  if (res.length == 2) {
    return `//policy.api.mondoo.app/spaces/${res[0]}/policies/${res[1]}`;
  } else {
    return id;
  }
};

const spaceIdRegex = /api.mondoo.app(?:\/spaces\/([^\/]+)){0,1}\//;

// TODO: this will work as long as space is the first argument
export const SpaceID = (mrn: string): string => {
  let m = spaceIdRegex.exec(mrn);
  if (m != null && m.length == 2) {
    return m[1];
  }
  return "";
};

export const PLATFORM_POLICY_MRN = "//platform.api.mondoo.app";

// => "//assets.api.mondoo.app/spaces/test-infallible-taussig-796596/assets/2U59H3PHXhC30nTMZ3F0GHimYo4"
// <= "/space/inventory/2U59H3PHXhC30nTMZ3F0GHimYo4?spaceId=test-infallible-taussig-796596"
export function assetMrnToInventoryHref(mrn: string) {
  const rx =
    /^\/\/assets\.api\.mondoo\.app\/spaces\/([\w\d-]+)\/assets\/([\w\d-]+)$/;
  const [validMrn, spaceId, assetId] = rx.exec(mrn) || [];
  if (!validMrn) {
    return null;
  }
  return `/space/inventory/${assetId}?spaceId=${spaceId}`;
}
