import { Box, Grid } from "@mui/material";
import { CvssRadar } from "./cvss-radar";
import { CvssMetricBox } from "./cvss-metric-box";
import { useCvssRisk } from "./useCvssRisk";

export type CvssRiskContentProps = {
  id?: string;
};

export function CvssRiskContent({ id }: CvssRiskContentProps) {
  const risk = useCvssRisk();
  return (
    <Box id={id}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={8.5}
          container
          spacing={3}
          order={{ xs: 2, md: 1 }}
          sx={{ mb: "auto" }}
        >
          {risk.metrics.map((metric, i) => (
            <Grid
              key={metric.field.acronym}
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              display="flex"
            >
              <CvssMetricBox metric={metric} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={6} lg={3.5} order={{ xs: 1, md: 2 }}>
          <CvssRadar />
        </Grid>
      </Grid>
    </Box>
  );
}
