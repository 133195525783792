import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
} from "@mui/material";

export interface ConfirmationDialogProps {
  id: string;
  value: string;
  open: boolean;
  onCancel: () => void;
  onConfirm: (value?: string) => void;
  title: string;
  content: JSX.Element;
}

export function ConfirmDeleteDialog(props: ConfirmationDialogProps) {
  const { onCancel, onConfirm, value, open, title, content, ...other } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} {...other}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={handleCancel}
          variant="contained"
          color="primary"
        >
          Cancel
        </Button>
        <Button onClick={handleOk} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
