import { createSvgIcon } from "@mui/material";

export const PDFIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2783_42848)">
      <path d="M19.11 8.36L13.39 2.64C12.9781 2.2304 12.4209 2.00033 11.84 2H6.44C5.85918 2 5.30214 2.23073 4.89144 2.64144C4.48073 3.05214 4.25 3.60918 4.25 4.19V19.81C4.25 20.3908 4.48073 20.9479 4.89144 21.3586C5.30214 21.7693 5.85918 22 6.44 22H17.56C18.1408 22 18.6979 21.7693 19.1086 21.3586C19.5193 20.9479 19.75 20.3908 19.75 19.81V9.91C19.7497 9.3291 19.5196 8.77191 19.11 8.36ZM12.73 3.82L17.87 9H13.61C13.3757 8.99737 13.1519 8.90244 12.9871 8.73582C12.8224 8.56919 12.73 8.34432 12.73 8.11V3.82ZM18.45 19.82C18.45 20.056 18.3562 20.2824 18.1893 20.4493C18.0224 20.6162 17.796 20.71 17.56 20.71H6.44C6.20396 20.71 5.97758 20.6162 5.81068 20.4493C5.64377 20.2824 5.55 20.056 5.55 19.82V4.19C5.55 3.95396 5.64377 3.72758 5.81068 3.56067C5.97758 3.39377 6.20396 3.3 6.44 3.3H11.44V8.07C11.44 8.6491 11.6694 9.20462 12.0779 9.61504C12.4864 10.0255 13.0409 10.2574 13.62 10.26H18.46L18.45 19.82Z" />
      <path d="M6.50977 17.6191V13.2891H8.30977C8.49887 13.2891 8.68612 13.3263 8.86083 13.3987C9.03554 13.471 9.19428 13.5771 9.328 13.7108C9.46172 13.8445 9.56779 14.0033 9.64015 14.178C9.71252 14.3527 9.74977 14.54 9.74977 14.7291C9.74977 14.9182 9.71252 15.1054 9.64015 15.2801C9.56779 15.4548 9.46172 15.6136 9.328 15.7473C9.19428 15.881 9.03554 15.9871 8.86083 16.0594C8.68612 16.1318 8.49887 16.1691 8.30977 16.1691H7.55977V17.6191H6.50977ZM7.50977 14.1991V15.2691H8.20977C8.33907 15.2513 8.45759 15.1874 8.54338 15.089C8.62918 14.9907 8.67645 14.8646 8.67645 14.7341C8.67645 14.6035 8.62918 14.4774 8.54338 14.3791C8.45759 14.2807 8.33907 14.2168 8.20977 14.1991H7.50977Z" />
      <path d="M10.4004 13.2891H12.1204C12.398 13.2881 12.6727 13.3449 12.9272 13.4558C13.1816 13.5668 13.4102 13.7295 13.5983 13.9336C13.7864 14.1377 13.93 14.3788 14.0199 14.6414C14.1098 14.904 14.144 15.1825 14.1204 15.4591C14.1425 15.7349 14.1072 16.0122 14.0167 16.2737C13.9261 16.5351 13.7823 16.7749 13.5943 16.9779C13.4064 17.1809 13.1783 17.3427 12.9246 17.4531C12.6709 17.5634 12.3971 17.62 12.1204 17.6191H10.4004V13.2891ZM11.4604 16.6791H12.1204C12.7304 16.6791 13.0404 16.0991 13.0404 15.4591C13.0404 14.8191 12.7304 14.2191 12.1204 14.2191H11.4604V16.6791Z" />
      <path d="M14.8398 17.6191V13.2891H17.6198V14.1991H15.8998V14.9991H17.2698V15.9091H15.8998V17.5891L14.8398 17.6191Z" />
    </g>
    <defs>
      <clipPath id="clip0_2783_42848">
        <rect
          width="15.5"
          height="20"
          fill="white"
          transform="translate(4.25 2)"
        />
      </clipPath>
    </defs>
  </svg>,
  "PDF",
);
