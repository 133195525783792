import { Space } from "~/lib/types";
import { useLoadSpaceQuery } from "~/operations";

type UsePlatformVulnerabilityConfigurationProps = {
  space: Space;
};

export function usePlatformVulnerabilityConfiguration({
  space,
}: UsePlatformVulnerabilityConfigurationProps) {
  const { data: spaceDataFromSpaceId } = useLoadSpaceQuery({
    variables: { spaceMrn: "//captain.api.mondoo.app/spaces/" + space.id },
    skip: !space.id,
  });

  return {
    pvcIsEnabled:
      !!spaceDataFromSpaceId?.space?.settings
        ?.platformVulnerabilityConfiguration.enable,
  };
}
