import { ChangeEvent, ChangeEventHandler, MouseEventHandler } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { platformTitle } from "~/lib/platform";
import { DateString } from "~/pages/integration-managed-agent";
import { AgentStatusTag } from "./score-card";
import { ClickableTableRow } from "./report";
import {
  AgentForwardPaginationQuery,
  AgentOrder,
  AgentOrderField,
} from "~/operations";
import { Space } from "~/lib/types";
import { SpacePagesIntegrations } from "~/pages/routes";
import { DataTable } from "./data-table";

type AgentsConnection = NonNullable<AgentForwardPaginationQuery["agents"]>;
type Agent = NonNullable<NonNullable<AgentsConnection["edges"]>[0]["node"]>;

export type ManagedClientsIntegrationsTableProps = {
  multiActions?: {
    selectedItems: string[];
    handleSelectAllChange: ChangeEventHandler;
    handleSelectItemChange: (
      event: ChangeEvent<HTMLInputElement>,
      item: Agent,
    ) => void;
  };
  space: Space;
  integrations: AgentsConnection;
  sort: AgentOrder;
  handleSortClick: (id: AgentOrderField) => MouseEventHandler;
};

export function ManagedClientsIntegrationsTable({
  space,
  integrations,
  multiActions,
  sort,
  handleSortClick,
}: ManagedClientsIntegrationsTableProps) {
  let navigate = useNavigate();

  const selectedItemsLength = multiActions
    ? multiActions.selectedItems.length
    : 0;
  const totalCount = integrations.totalCount;

  function handleHref(href: string) {
    return function () {
      navigate(href);
    };
  }

  const agentUrl = (
    space: Space | undefined,
    agentId: string,
    params: URLSearchParams | null,
  ): string => {
    if (params == null) {
      params = new URLSearchParams();
    }

    let prefix = "";
    if (space != null) {
      prefix = "/space";
      params.set("spaceId", space.id);
    }

    let url = `${prefix}/${SpacePagesIntegrations}/managed/${agentId}`;

    let query = params.toString();
    if (query != "") {
      url = url + "?" + query;
    }

    return url;
  };

  const hasEditPermission = !!multiActions;

  const totalRows = integrations.edges?.length || 0;

  return (
    <DataTable
      selectable={hasEditPermission}
      selection={multiActions?.selectedItems}
    >
      <TableHead>
        <TableRow>
          {hasEditPermission && (
            <TableCell>
              <Checkbox
                checked={
                  selectedItemsLength > 0 && selectedItemsLength === totalRows
                }
                onChange={(e) => multiActions.handleSelectAllChange(e)}
                indeterminate={
                  selectedItemsLength > 0 && selectedItemsLength < totalRows
                }
                onClick={(e) => e.stopPropagation()}
                disabled={totalCount === 0}
              />
            </TableCell>
          )}
          {tableHeaders.map((header) => (
            <TableCell
              key={header.id}
              sortDirection={
                sort.field === header.id
                  ? sort.direction.toLowerCase() === "asc"
                    ? "asc"
                    : "desc"
                  : false
              }
              sx={{ ...header.options }}
            >
              <TableSortLabel
                onClick={handleSortClick(header.id as AgentOrderField)}
                direction={
                  sort.direction.toLowerCase() === "asc" ? "asc" : "desc"
                }
                active={sort.field === header.id}
              >
                {header.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {integrations?.edges?.map((edge) => {
          const item = edge.node;
          if (!item) return <></>;
          const href = agentUrl(space, item.id, null);
          const isSelected = multiActions?.selectedItems.includes(item?.mrn);
          const className = isSelected ? "selected" : "";
          return (
            <ClickableTableRow
              key={item?.id}
              onClick={handleHref(href)}
              className={className}
            >
              {hasEditPermission && (
                <TableCell>
                  <Checkbox
                    checked={isSelected}
                    onChange={(e) =>
                      multiActions.handleSelectItemChange(e, item)
                    }
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
              )}
              <TableCell>{item.hostname || "n/a"}</TableCell>
              <TableCell>{item.ipaddress}</TableCell>
              <TableCell>{platformTitle(item.platform)}</TableCell>
              <TableCell>
                {item.status?.lastCheckin &&
                  DateString(item.status.lastCheckin)}
              </TableCell>
              <TableCell>{item.status?.version}</TableCell>
              <TableCell>
                {item.status?.state && (
                  <AgentStatusTag status={item.status.state} />
                )}
              </TableCell>
            </ClickableTableRow>
          );
        })}
      </TableBody>
    </DataTable>
  );
}

//////// Table Configuration

type Header = {
  id: string;
  label: string;
  colSpan?: number;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};

const tableHeaders: Header[] = [
  {
    id: "HOSTNAME",
    label: "Hostname",
    options: { textAlign: "left" },
  },
  { id: "IP", label: "IP Address" },
  {
    id: "PLATFORM",
    label: "Platform",
  },
  {
    id: "LAST_CHECKIN",
    label: "Last Check-in",
  },
  {
    id: "VERSION",
    label: "Version",
  },
  {
    id: "STATUS",
    label: "Status",
  },
];
