import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { Header } from "~/types/table";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { Link as RouterLink } from "react-router-dom";
import { LibraryHref } from "~/lib/libraryhref";
import { CveNodeFieldsFragment } from "~/operations";
import {
  TitleCell,
  ImpactCell,
  PublishCell,
} from "../SoftwareAdvisories/cells";
import { DeprecatedRiskFactorsCell } from "~/components/vulnerabilities/DeprecatedRiskFactorsCell";

export const VulnerabilitiesTable = ({
  cves,
}: {
  cves: CveNodeFieldsFragment[];
}) => {
  const tableHeaders: Header[] = [
    {
      id: "IMPACT",
      label: "Impact",
      options: { width: "10%" },
      sortable: false,
    },
    {
      id: "VULNERABILITY",
      label: "Vulnerability",
      options: { width: "50%" },
      sortable: false,
    },
    {
      id: "RISK_FACTORS",
      label: "Risk Factors",
      sortable: false,
    },
    {
      id: "PUBLISHED",
      label: "Published",
      sortable: false,
    },
  ];

  return (
    <TableContainer>
      <Table data-testid="software-vulnerabilities">
        <TableHead tableHeaders={tableHeaders} />
        <TableBody>
          {cves.map((cve) => {
            return (
              <TableRow
                key={cve.id}
                component={RouterLink}
                to={LibraryHref("cve", cve.id)}
              >
                <ImpactCell cve={cve} />
                <TitleCell cve={cve} />
                <DeprecatedRiskFactorsCell
                  vulnerabilityRiskFactors={cve.vulnerabilityRiskFactors}
                />
                <PublishCell cve={cve} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
