import { Fragment, ReactNode, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
import { DeleteIcon } from "~/components/icons";
import { LoadingButton } from "./loading-button";

export type DangerZoneProps = {
  children: ReactNode;
};

export function DangerZone({ children }: DangerZoneProps) {
  const subheader = (
    <DangerListSubheader component="div">
      <Typography component="span" variant="h6">
        Danger zone
      </Typography>
    </DangerListSubheader>
  );

  return (
    <DangerPaper>
      <List subheader={subheader}>{children}</List>
    </DangerPaper>
  );
}

export type DangerZoneItemProps = {
  primary: string;
  secondary: string;
  confirm: string;
  action: string;
  onClick: () => void;
  isDeleting: boolean;
};

export function DangerZoneItem({
  primary,
  secondary,
  confirm,
  action,
  onClick,
  isDeleting,
}: DangerZoneItemProps) {
  const [disabled, setDisabled] = useState<boolean>(true);

  const handleCheckbox = (): void => {
    setDisabled(!disabled);
  };

  const handleClick = () => {
    setDisabled(true);
    onClick();
  };

  const compiledPrimary = (
    <Typography component="span" variant="h6" color="textPrimary">
      {primary}
    </Typography>
  );

  const compiledSecondary = (
    <Fragment>
      <Typography component="span" variant="body2" color="textPrimary">
        {secondary}
      </Typography>
      <FormControlLabel
        style={{ display: "block" }}
        control={
          <Checkbox
            checked={!disabled}
            onChange={handleCheckbox}
            color="critical"
          />
        }
        label={confirm}
      />
    </Fragment>
  );

  return (
    <ListItem>
      <ListItemText
        style={{ paddingRight: 96 }}
        primary={compiledPrimary}
        secondary={compiledSecondary}
      />
      <ListItemSecondaryAction>
        <LoadingButton
          loading={isDeleting}
          buttonText={action}
          variant="contained"
          color="critical"
          startIcon={<DeleteIcon />}
          onClick={handleClick}
          disabled={disabled}
          data-name="danger-zone-delete-button"
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
}

const DangerPaper = styled(Paper)`
  margin-bottom: ${(props) => props.theme.spacing(3)};
  border: 1px solid ${(props) => props.theme.palette.critical.main};

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }
`;

const DangerListSubheader = styled(ListSubheader)<{ component: string }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: ${(props) => props.theme.palette.background.lighter};
  border-radius: 6px 6px 0px 0px;
`;
