import { Box } from "@mui/material";

export function Saturn({}) {
  return (
    <Box
      component="svg"
      width="100%"
      height="auto"
      viewBox="0 0 266 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M147.158 139.349C176.869 131.388 194.499 100.838 186.534 71.1135C178.57 41.3893 148.027 23.7469 118.316 31.708C88.604 39.6692 70.9746 70.2193 78.9392 99.9435C86.9038 129.668 117.446 147.31 147.158 139.349Z"
        fill="url(#paint0_radial_4891_52980)"
      />
      <path
        d="M183.603 59.0939C205.85 56.0687 220.899 56.6757 222.206 61.5522C224.424 69.8292 186.171 87.2725 136.766 100.511C87.3597 113.749 45.5106 117.769 43.2928 109.492C41.9912 104.634 54.7013 96.5745 75.4561 88.0774"
        stroke="#1F232D"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M181.883 55.3202C215.832 49.5905 239.78 49.537 241.607 56.3566C244.306 66.4282 197.757 87.6536 137.637 103.763C77.5161 119.872 26.5911 124.765 23.8924 114.693C22.0614 107.86 42.8985 95.8931 75.2578 83.8642"
        stroke="#1F232D"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.253 57.7241C183.845 58.7994 184.406 59.9026 184.935 61.0336C201.304 59.2737 212.066 60.1272 213.104 64.0026C215.09 71.4133 180.726 87.0627 136.348 98.9536C91.9705 110.845 54.3881 114.473 52.4024 107.063C51.3647 103.19 60.2442 97.0736 75.2874 90.4164C75.1791 89.1814 75.1169 87.9456 75.0892 86.7093C50.3832 96.43 34.9556 105.796 36.4387 111.331C38.8192 120.215 83.8691 115.862 137.052 101.611C190.234 87.3612 231.425 68.6069 229.045 59.7227C227.577 54.1806 209.522 53.7858 183.253 57.7241Z"
        fill="#772728"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.825 53.6828C181.421 54.579 181.988 55.4972 182.533 56.4301C212.957 51.5204 234.17 51.6794 235.837 57.9026C238.385 67.4102 194.295 87.4877 137.361 102.743C80.4258 117.999 32.2073 122.655 29.6597 113.148C27.9966 106.941 46.2845 96.186 75.0708 85.2274C75.076 84.1462 75.1111 83.0685 75.176 81.9944C37.215 95.637 12.3466 109.488 14.4212 117.231C17.3455 128.144 72.694 122.798 138.042 105.288C203.391 87.7781 253.997 64.7338 251.073 53.8202C249 46.0742 220.539 46.5155 180.825 53.6828Z"
        fill="#5C113E"
      />
      <defs>
        <radialGradient
          id="paint0_radial_4891_52980"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(80.1957 59.0978) rotate(-15) scale(109.209 109.256)"
        >
          <stop offset="0.01" stopColor="#47474A" />
          <stop offset="0.97" stopColor="#1C1C24" />
        </radialGradient>
      </defs>
    </Box>
  );
}
