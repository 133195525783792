import { ReactNode } from "react";
import { AccordionProps, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import { groupBy, map, sortBy } from "lodash";
import { FlagTypes } from "~/components/ui-library";
import { format, formatISO, parseISO } from "~/lib/date";
import {
  ExceptionGroup,
  ExceptionMutationAction,
  ReviewStatus,
  TestIamActionsQuery,
  useApplyExceptionReviewMutation,
} from "~/operations";
import { ExceptionTimeline } from "~/components/exceptions/timeline";
import {
  ExceptionTimelineItem,
  ExceptionTimelineItemProps,
} from "~/components/exceptions/timeline-item";
import { useSearchParams } from "react-router-dom";
import { IamActions } from "~/lib/iam";
import { EmptyState } from "~/components/empty-state/empty-state";
import { InternalRefetchQueryDescriptor } from "@apollo/client/core/types";

export type TimelineGroup = {
  title: string;
  date: string;
  flags: FlagTypes[];
  snoozed: number;
  disabled: number;
  exceptions: ExceptionGroup[];
  exceptionRows: ReactNode;
  accordionProps?: Partial<AccordionProps>;
};

export type ExceptionsProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  emptyStateType: string;
  refetchQueries: InternalRefetchQueryDescriptor[];
  exceptionGroups: ExceptionGroup[];
  renderTable: (exceptionGroup: ExceptionGroup) => ReactNode;
};

export function Exceptions({
  availablePermissions,
  emptyStateType,
  refetchQueries,
  exceptionGroups,
  renderTable,
}: ExceptionsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const hasApplyExceptionReviewPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONREVIEWMUTATION,
  );

  const [applyExceptionReview] = useApplyExceptionReviewMutation();

  const handleReviewActionChange: ExceptionTimelineItemProps["onReviewActionChange"] =
    async (exceptionGroup, action) => {
      try {
        await applyExceptionReview({
          variables: {
            input: {
              scopeMrn: exceptionGroup.scopeMrn,
              exceptionId: exceptionGroup.id,
              action,
            },
          },
          refetchQueries,
        });
      } catch (error) {
        enqueueSnackbar("Failed to apply exception review", {
          variant: "error",
        });
      }
    };

  const groupedByDate = groupBy(exceptionGroups, (e) => {
    return formatISO(new Date(e.createdAt), { representation: "date" });
  });

  const focusedExceptionId = searchParams.get("exceptionId");

  const groups = map(groupedByDate, (exceptions, date) => {
    const title = format(parseISO(date), "MMMM do yyyy");
    const isFeatured = false;
    const isLocked = false;
    const needsReview = exceptions.some(
      (e) => e.reviewStatus === ReviewStatus.NotReviewed,
    );
    const snoozed = exceptions.filter(
      (e) => e.action === ExceptionMutationAction.Snooze,
    ).length;
    const disabled = exceptions.filter(
      (e) => e.action === ExceptionMutationAction.Disable,
    ).length;
    const flags = [
      isFeatured ? FlagTypes.featured : undefined,
      isLocked ? FlagTypes.unlock : undefined,
      needsReview ? FlagTypes.review : undefined,
    ].flatMap((f) => f ?? []);
    const isExpanded = exceptions.some(
      (e) =>
        e.id === searchParams.get("exceptionId") ||
        flags.some((f) => f === FlagTypes.review),
    );

    return {
      date,
      title,
      flags,
      snoozed,
      disabled,
      exceptions,
      exceptionRows: sortBy(exceptions, (exc) => exc.createdAt)
        .reverse()
        .map((e) => (
          <ExceptionTimelineItem
            key={e.createdAt}
            exceptionGroup={e}
            onReviewActionChange={
              hasApplyExceptionReviewPermission
                ? handleReviewActionChange
                : undefined
            }
            renderTable={renderTable}
          />
        )),
      accordionProps: {
        sx: {
          flex: 1,
        },
        defaultExpanded: isExpanded,
      },
    };
  });

  // If exceptions exist and a specific exception is not focused,
  // expand the first date group.
  const firstGroup = groups[0];
  if (firstGroup && !focusedExceptionId) {
    firstGroup.accordionProps.defaultExpanded = true;
  }

  const timelines = sortBy(groups, "date").reverse();

  if (!timelines.length) {
    return (
      <Box pt={3}>
        <EmptyState contentType={emptyStateType} />
      </Box>
    );
  }

  return (
    <Box>
      <ExceptionTimeline
        {...{
          timelines,
        }}
      />
    </Box>
  );
}
