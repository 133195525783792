import {
  alpha,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from "@mui/material";
import { ControlResultIcon } from "~/components/control-result-icon";
import { getColor } from "~/lib/colors";
import { Loading, LoadingFailed } from "~/components/loading";
import { DashboardCard } from "~/pages/organization/dashboard/dashboard-card";
import { CheckIcon } from "~/components/icons";
import { Metrics, useMetrics } from "~/hooks";

type Props = {
  assetMrn: string;
  onRowClick?: (row: any) => void;
  impactFilter: string[];
};

export function AssetChecksChart({
  assetMrn,
  impactFilter,
  onRowClick,
}: Props) {
  const theme = useTheme();

  const { checksResults, isLoading, error } = useMetrics({
    entityMrn: assetMrn,
    metricMrns: [Metrics.ChecksResultDistribution],
  });

  const unscored = Object.entries(checksResults || {}).reduce(
    (acc, [_, summary]) => {
      return acc + summary.unscored;
    },
    0,
  );

  if (isLoading && !checksResults.length) {
    return <Loading what="Asset Checks" />;
  }

  if (error) {
    return <LoadingFailed what="Asset Checks" />;
  }

  return (
    <DashboardCard
      title="Check Overview"
      icon={<CheckIcon />}
      sx={{ height: 1 }}
    >
      <TableContainer
        sx={[
          { minWidth: 240 },
          {
            "td, th": {
              fontSize: 12,
              fontWeight: 700,
              textTransform: "uppercase",
              whiteSpace: "nowrap",
            },
          },
        ]}
      >
        <Table
          size="small"
          sx={{
            "& .MuiTableRow-root": {
              outline: 0,
            },
          }}
        >
          <TableHead
            sx={[
              { backgroundColor: "transparent", boxShadow: "none" },
              {
                "tr:first-of-type th": {
                  borderBottom: "none",
                },
              },
              {
                "tr:last-child th": {
                  borderBottom: "none",
                },
              },
            ]}
          >
            <TableRow
              sx={{
                "&:hover": {
                  background: "transparent",
                },
              }}
            >
              <TableCell sx={{ pl: 0 }}>{/* Intentionally blank */}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    mr: -0.6,
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <ControlResultIcon status="pass" size="sm" />
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ mr: -0.6, display: "flex", justifyContent: "end" }}>
                  <ControlResultIcon status="fail" size="sm" />
                </Box>
              </TableCell>
              <TableCell>
                <Box sx={{ mr: -0.6, display: "flex", justifyContent: "end" }}>
                  <ControlResultIcon status="error" size="sm" />
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(checksResults || {}).map(([state, summary]) => {
              const color = getColor(theme, state.toLowerCase());

              if (typeof summary !== "object") return null;

              const queryImpact = state === "none" ? ImpactType.Info : state;

              return (
                <TableRow
                  key={state}
                  className="checks-impact-row"
                  onClick={() => onRowClick?.(queryImpact)}
                  sx={{
                    cursor: "pointer",
                    ...(impactFilter.includes(queryImpact) && {
                      background: alpha(color, 0.1),
                    }),
                  }}
                >
                  <TableCell className="checks-impact-state" sx={{ color }}>
                    {state}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color:
                        summary.passing === 0
                          ? theme.palette.text.disabled
                          : "text.default",
                    }}
                  >
                    {summary.passing}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color:
                        summary.failing === 0
                          ? theme.palette.text.disabled
                          : "text.default",
                    }}
                  >
                    {summary.failing}
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      color:
                        summary.errored === 0
                          ? theme.palette.text.disabled
                          : "text.default",
                    }}
                  >
                    {summary.errored}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow
              key="unscored"
              className="checks-impact-row"
              onClick={() => onRowClick?.(ImpactType.Unscored)}
              sx={{
                td: {
                  color:
                    unscored === 0
                      ? theme.palette.text.disabled
                      : theme.palette.unknown.main,
                },
                cursor: "pointer",
                ...(impactFilter.includes(ImpactType.Unscored) && {
                  background: alpha(theme.palette.unknown.main, 0.1),
                }),
              }}
            >
              <TableCell className="checks-impact-state">unscored</TableCell>
              <TableCell colSpan={3} align="right">
                {unscored}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardCard>
  );
}

// Get control data numbers
enum ImpactType {
  Critical = "critical",
  High = "high",
  Medium = "medium",
  Low = "low",
  Info = "info",
  Unscored = "unscored",
}
