import { TableBody, TableRow } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { LibraryHref } from "~/lib/libraryhref";
import { ImpactCell } from "./ImpactCell";
import { AdvisoryCell } from "./AdvisoryCell";
import { PublishCell } from "./PublishCell";

import { TableHead } from "~/pages/inventory/components/TableHead";
import {
  ExceptionMutationAction,
  ScoreState,
  TestIamActionsQuery,
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreOrder,
} from "~/operations";
import { DataTable } from "~/components/data-table";
import { Fragment, useState } from "react";
import { Space } from "~/lib/types";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { CheckboxCell, RiskFactorsCell } from "~/components/Cells";
import { useAssetVulnerabilitiesTable } from "~/pages/inventory/asset/AssetVulnerabilitiesTable";
import { VulnerabilityScoresType } from "~/pages/inventory/components/Vulnerabilities/types";

export type AdvisoriesTableProps = {
  assetMrn: string;
  space: Space;
  advisoryVulnerabilityScores: VulnerabilityScoresType | null | undefined;
  totalCount: number;
  onSort: (id: string) => void;
  orderBy: VulnerabilityScoreOrder;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  fetchMore: ReturnType<typeof useGetVulnerabilityScoresQuery>["fetchMore"];
};

export const AdvisoriesTable = ({
  assetMrn,
  space,
  advisoryVulnerabilityScores,
  totalCount,
  onSort,
  orderBy,
  availablePermissions,
  fetchMore,
}: AdvisoriesTableProps) => {
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);

  const advisories =
    advisoryVulnerabilityScores?.edges
      ?.slice(pageItems.from, pageItems.to)
      ?.flatMap((e) => e.node ?? []) || [];

  const {
    hasApplyExceptionPermission,
    tableHeaders,
    exceptionsSelection,
    exception,
    exceptionGroups,
  } = useAssetVulnerabilitiesTable({
    availablePermissions,
    scope: "advisory",
    space,
    entityMrn: assetMrn,
    items: advisories,
  });

  return (
    // TODO @JAMIE: unite with VulnerabilitiesTable
    <Fragment>
      <DataTable
        id="asset-vulnerabilities-table"
        selectable={hasApplyExceptionPermission}
        selection={exceptionsSelection.selectedEntities}
      >
        <TableHead
          tableHeaders={tableHeaders}
          onSort={onSort}
          orderBy={orderBy}
        />
        <TableBody>
          {advisories.map((advisory) => {
            return (
              <TableRow
                key={advisory.id}
                component={RouterLink}
                to={LibraryHref("advisory", advisory.id)}
                className={advisory.state === ScoreState.Open ? "" : "excepted"}
                sx={{
                  "&.MuiTableRow-root:hover .firewatch-chip": {
                    backgroundColor: "background.lighter",
                  },
                }}
              >
                {hasApplyExceptionPermission && (
                  <CheckboxCell
                    onClick={(e) => exceptionsSelection.handleNodeClick(e)}
                    onChange={(e, changed) =>
                      exceptionsSelection.handleNodeChange(e, changed, {
                        groupId: "",
                        mrn: advisory.mrn,
                        exception: !Boolean(
                          advisory.state === ScoreState.Snoozed ||
                            advisory.state === ScoreState.Disabled,
                        )
                          ? null
                          : {
                              justification: advisory.state,
                              action:
                                advisory.state === ScoreState.Snoozed
                                  ? ExceptionMutationAction.Snooze
                                  : ExceptionMutationAction.Disable,
                            },
                      })
                    }
                    checked={Boolean(
                      exceptionsSelection.selectedEntities.find(
                        (selectedCheck) => advisory.mrn === selectedCheck.mrn,
                      ),
                    )}
                  />
                )}

                <ImpactCell data-testid="advisory-impact" advisory={advisory} />
                <AdvisoryCell
                  data-testid="advisory-title"
                  advisory={advisory}
                  hasException={advisory.state}
                />
                <RiskFactorsCell
                  data-testid="advisory-risk-factors"
                  riskFactors={advisory.riskFactors}
                />
                <PublishCell
                  data-testid="advisory-published"
                  advisory={advisory}
                />
              </TableRow>
            );
          })}
        </TableBody>
      </DataTable>
      <Pagination
        fetchMore={fetchMore}
        pageInfo={advisoryVulnerabilityScores?.pageInfo}
        totalCount={advisoryVulnerabilityScores?.totalCount || 0}
        setPageItems={setPageItems}
      />
      {exceptionsSelection.selectedEntities.length > 0 && (
        <ExceptionsToolbar
          onCancel={exceptionsSelection.handleCancelClick}
          onRemoveExceptionClick={exception.handleRemoveExceptionModalOpen}
          onSetExceptionClick={exception.handleSetExceptionModalOpen}
          selectedEntities={exceptionsSelection.selectedEntities}
          totalCount={totalCount}
          target="advisory"
        />
      )}
      <ExceptionsModals
        isSetExceptionModalOpen={exception.isSettingException}
        isRemoveExceptionModalOpen={exception.isRemovingException}
        onRemoveExceptionModalClose={exception.handleRemoveExceptionModalClose}
        onSetExceptionModalClose={exception.handleSetExceptionModalClose}
        onSetExceptionModalSave={exception.handleSetException}
        onRemoveExceptionModalSave={exception.handleRemoveException}
        loading={exception.loading}
        target="advisory"
        role="asset"
        exceptionGroups={exceptionGroups}
        selectedEntities={exceptionsSelection.selectedEntities}
      />
    </Fragment>
  );
};
