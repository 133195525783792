import { ReactNode } from "react";
import { Box, Paper, SxProps, Theme, Typography } from "@mui/material";

export type DashboardCardProps = {
  title: ReactNode;
  icon: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  sx?: SxProps<Theme>;
};

export function DashboardCard({
  title,
  subtitle,
  icon,
  sx,
  children,
}: DashboardCardProps) {
  return (
    <Paper
      sx={[
        {
          display: "flex",
          flexDirection: "column",
          position: "relative",
        },
        {
          ".card-header": {
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            p: 3,
            pb: 0,
          },
        },
        { ".card-body": { flexGrow: 1, overflowY: "auto", px: 3, mb: 3 } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box className="card-header">
        <Box>
          <Typography
            variant="h5"
            fontWeight="bold"
            textTransform="uppercase"
            lineHeight={1}
          >
            {title}
          </Typography>
          {subtitle && (
            <Typography
              variant="caption"
              color="text.secondary"
              textTransform="uppercase"
            >
              {subtitle}
            </Typography>
          )}
        </Box>
        <Box>{icon}</Box>
      </Box>
      <Box className="card-body">{children}</Box>
    </Paper>
  );
}
