import { Grid, TableCell, TableRow, Typography } from "@mui/material";
import { Flex } from "~/components/ui-library";
import { format } from "date-fns";
import { HalfStyleDataTable } from "./half-data-table";
import { HostedClientIntegration } from "../types";
import { useConfigurationsAndMessages } from "../hooks/useConfigurationsAndMessages";
import { HalfDataTableRow } from "./half-data-table-row";

export type ConfigurationsAndMessagesProps = {
  integration: HostedClientIntegration;
  token?: string;
};

export function ConfigurationsAndMessages({
  integration,
  token,
}: ConfigurationsAndMessagesProps) {
  const { displayedConfigs, configFormat } = useConfigurationsAndMessages({
    integration,
    token,
  });

  return (
    <Grid
      container
      columnSpacing={{ xs: 0, sm: 3 }}
      rowSpacing={{ xs: 5, sm: 0 }}
    >
      <Grid item xs={12} sm={6}>
        <HalfStyleDataTable title="Integration Configuration">
          <HalfDataTableRow
            title="Created"
            value={format(integration.created, "M/dd/yy, h:mmaaa")}
          />

          <HalfDataTableRow
            title="Last modified"
            value={
              integration?.lastModified
                ? format(integration?.lastModified, "M/dd/yy, h:mmaaa")
                : "Never"
            }
          />

          {displayedConfigs &&
            Object.entries(displayedConfigs).map(([key, value]) => (
              <HalfDataTableRow
                key={key}
                title={configFormat.configName(key)}
                value={
                  (configFormat.group.includes(key)
                    ? configFormat.value(key, value)
                    : value) || <>&nbsp;</>
                }
              />
            ))}
        </HalfStyleDataTable>
        <HalfStyleDataTable title="Scan Details" ContainerSxProps={{ mt: 3 }}>
          <HalfDataTableRow
            title="Last Successful Scan"
            value={configFormat.scanStartAndFinish(
              integration?.scanJobStarted,
              integration?.scanJobFinished,
            )}
          />
          <HalfDataTableRow
            title="Last Attempted Scan"
            value={
              integration?.scanJobStarted
                ? format(integration.scanJobStarted, "M/dd/yy, h:mmaaa")
                : "Never"
            }
          />
        </HalfStyleDataTable>
      </Grid>

      <Grid item xs={12} sm={6}>
        <HalfStyleDataTable title="Most Recent Activity">
          {integration.messages && integration.messages.length > 0 ? (
            integration.messages.map((message, i) => (
              <HalfDataTableRow
                key={i}
                title={
                  message?.timestamp
                    ? format(new Date(message.timestamp), "M/dd/yy, h:mmaaa")
                    : "Never"
                }
                value={message.message}
              />
            ))
          ) : (
            <TableRow>
              <TableCell align="center">
                <Flex
                  sx={{
                    height: (theme) => theme.spacing(10),
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography color="text.secondary">
                    You have no recent activity.
                  </Typography>
                </Flex>
              </TableCell>
            </TableRow>
          )}
        </HalfStyleDataTable>
      </Grid>
    </Grid>
  );
}
