import { createSvgIcon } from "@mui/material";

export const AlmaLinuxIcon = createSvgIcon(
  <svg viewBox="0 0 246 241" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M225.911 135.93C236.363 135.126 244.805 141.96 245.609 152.412C246.413 163.266 238.775 172.11 228.323 172.914C218.273 173.718 209.429 166.08 208.625 156.03C207.821 145.176 215.057 137.136 225.911 135.93Z"
      fill="#86DA2F"
    />
    <path
      d="M98.877 221.555C98.877 211.103 106.917 203.063 116.565 203.063C126.213 203.063 135.459 211.907 135.459 221.957C135.459 231.605 127.419 240.047 118.173 240.449C106.515 240.449 98.877 233.213 98.877 221.555Z"
      fill="#24C2FF"
    />
    <path
      d="M127.42 104.174C125.812 104.978 125.008 103.772 124.606 102.566C109.732 74.8285 114.154 39.8549 140.284 18.9511C147.118 13.3232 159.982 12.1172 165.61 17.7452C168.022 19.7551 168.424 22.1671 168.826 24.9811C169.63 31.011 170.836 37.0409 174.856 41.8649C179.278 47.0908 184.906 49.1008 191.338 48.6988C196.965 48.6988 202.593 47.8948 206.211 54.3268C208.221 57.9447 207.417 72.0146 204.201 74.8285C202.593 76.0345 201.387 75.2305 200.181 74.8285C190.936 71.2106 181.288 71.2106 171.64 72.8186C168.424 73.2206 166.816 72.4166 166.816 68.7986C166.414 62.7687 165.208 57.1407 161.992 51.9148C155.962 41.0609 144.706 40.6589 137.47 50.7088C131.44 58.7487 129.832 68.3966 128.224 78.0445C126.616 86.4864 127.018 95.3303 127.42 104.174Z"
      fill="#FFCB12"
    />
    <path
      d="M137.469 110.202C136.665 108.996 137.067 107.79 138.273 106.986C161.187 86.0825 195.356 82.0626 221.888 102.564C228.722 108.192 233.144 119.85 229.526 126.684C227.918 129.498 225.908 130.704 223.496 131.508C217.868 133.92 212.642 136.332 209.024 141.558C205.406 146.784 204.602 152.814 206.21 159.246C207.416 164.472 209.426 170.1 204.2 174.924C201.386 177.738 187.719 180.15 184.101 177.738C182.493 176.532 182.895 175.326 183.297 173.718C184.503 163.668 182.091 154.422 178.473 145.578C177.267 142.362 177.669 140.754 180.885 139.95C186.513 138.342 191.738 135.528 195.758 131.508C204.602 123.066 202.592 112.212 190.934 107.388C181.689 103.368 172.041 104.172 162.795 104.976C153.951 105.378 145.509 107.79 137.469 110.202Z"
      fill="#86DA2F"
    />
    <path
      d="M132.243 120.655C133.449 119.449 134.253 119.851 135.459 120.655C161.99 136.735 175.658 168.492 163.598 199.446C160.382 207.486 149.931 215.526 142.293 213.516C139.077 212.712 137.469 211.104 135.861 209.094C132.243 204.27 128.223 199.848 122.193 197.838C115.761 195.828 110.133 197.034 104.505 200.25C99.6812 203.064 94.8572 206.682 88.8273 203.064C85.2093 201.054 79.1794 188.592 80.3854 184.572C81.1894 182.964 82.7973 182.964 84.4053 182.964C94.4552 181.356 102.495 176.532 110.133 170.1C112.545 168.09 114.555 168.09 116.565 170.904C119.781 175.728 123.801 179.748 129.027 182.562C139.479 188.592 149.529 183.366 150.735 171.306C151.941 161.256 148.323 152.412 145.107 143.568C141.489 135.529 137.067 127.891 132.243 120.655Z"
      fill="#24C2FF"
    />
    <path
      d="M119.379 123.066C117.771 131.106 114.153 138.744 109.329 145.578C94.8569 167.286 73.9532 176.129 48.2254 173.718C38.9795 172.914 31.3416 165.276 30.5376 157.638C30.1356 154.422 30.9396 152.01 32.9496 149.598C35.7636 145.98 38.1756 142.764 39.3815 138.342C41.7935 129.498 38.5775 122.262 32.1456 115.83C23.3017 106.986 24.5077 98.9463 34.9596 92.5144C36.1656 91.7104 37.7736 90.9064 39.3815 90.1024C41.7935 88.8964 43.8035 88.8964 44.6075 91.7104C48.2254 100.956 55.4614 107.79 63.5013 113.418C66.3152 115.83 66.3152 117.438 63.9033 120.252C59.0793 125.478 56.2654 131.91 55.8634 139.146C55.0594 147.99 60.2853 153.618 69.1292 153.618C74.7571 153.618 79.9831 151.608 84.807 149.196C97.2689 142.764 106.917 133.518 116.163 123.87C117.369 123.468 117.771 122.664 119.379 123.066Z"
      fill="#0069DA"
    />
    <path
      d="M53.4508 38.6484C54.2548 38.6484 56.2648 39.0504 58.2747 39.4524C73.1486 42.2664 82.3945 37.0404 87.2184 22.9706C90.4344 13.7247 97.2683 10.9107 106.112 15.7347C106.514 15.7347 106.514 16.1367 106.916 16.1367C116.162 21.3626 116.162 22.1666 110.534 30.2065C105.71 36.6384 103.298 43.8744 102.092 51.5123C101.288 55.9342 99.6803 56.7382 95.6603 55.1302C89.2284 52.7183 82.3945 52.7183 75.5606 55.1302C67.9226 57.5422 64.7067 64.3761 67.1186 72.014C70.3346 82.0639 79.1785 86.4859 86.8164 91.7118C94.4544 96.9378 103.298 99.7517 111.74 103.37C112.946 103.772 114.956 103.772 114.554 105.782C114.152 106.988 112.544 106.988 110.936 106.988C92.8464 107.792 75.5606 104.978 61.4907 92.9178C48.2249 82.0639 38.577 68.7981 40.1849 50.3063C41.3909 44.2763 45.8129 39.8544 53.4508 38.6484Z"
      fill="#FF4649"
    />
    <path
      d="M20.4893 151.608C10.8414 152.814 1.19353 144.774 0.389541 134.724C-0.41445 125.077 7.62546 115.429 16.8714 114.625C27.3232 113.419 36.9711 120.655 37.7751 130.302C38.1771 139.548 32.1472 150.804 20.4893 151.608Z"
      fill="#0069DA"
    />
    <path
      d="M189.328 8.49884C199.377 7.69485 209.025 15.7348 209.829 25.7846C210.633 35.4325 202.593 44.6784 192.946 45.4824C182.494 46.2864 173.248 38.6485 172.444 28.5986C171.64 18.5487 178.876 9.30283 189.328 8.49884Z"
      fill="#FFCB12"
    />
    <path
      d="M84.4054 16.1366C85.6114 26.5885 78.3754 35.4324 67.5216 37.0403C58.2757 38.2463 48.6278 30.6084 47.4218 21.7645C46.2158 10.1066 52.6477 1.66473 63.5016 0.458743C73.5515 -0.747244 83.1994 6.89067 84.4054 16.1366Z"
      fill="#FF4649"
    />
  </svg>,
  "AlmaLinux",
);
