export const azureInstructions = {
  title: "Azure DevOps Pipelines",
  tagline: "PIPELINE MONITORING FOR AZURE PROJECTS",
  flags: [
    { id: "ci_cd", title: "CI/CD" },
    { id: "shift_left", title: "Shift Left" },
  ],
  instructions: [
    { id: 1, tagline: "Copy the Mondoo Platform credentials", token: true },
    {
      id: 2,
      tagline: "Securely store the credentials",
      markdownText:
        "In your pipeline, generate a new <a href='https://learn.microsoft.com/en-us/azure/devops/pipelines/process/variables?view=azure-devops&tabs=yaml%2Cbatch' target='_blank' rel='noopener'>environment variable</a>. Name the environment variable `MONDOO_CONFIG_BASE64`, and set the value to your generated credentials. To learn more, read the <a href='https://learn.microsoft.com/en-us/azure/devops/pipelines/?view=azure-devops' target='_blank' rel='noopener'>Azure Pipelines documentation</a>.",
    },
    {
      id: 3,
      tagline: "Use cnspec in your Azure Pipelines",
      markdownText:
        "Now that you have Mondoo Platform credentials for running CI security scans, you can add cnspec steps to Azure Pipelines using your `.azure-pipelines.yml` file. You can use the example below or customize it to suit your needs. To learn more, read the <a href='https://learn.microsoft.com/en-us/azure/devops/pipelines/?view=azure-devops' target='_blank' rel='noopener'>Azure Pipelines documentation</a>.",
      configExamples: [
        {
          id: "docker_config",
          config:
            '```yaml\n# Docker Image Scan cnspec pipeline\n\
  #\n\
  # The script uses the following secret:\n\
  # - MONDOO_CONFIG_BASE64 - Mondoo Platform credentials\n\
  \n\
  trigger:\n\
  - main\n\
  pool:\n\
      vmImage: "ubuntu-latest"\n\
  variables:\n\
      imageNamespace: securityimages.azurecr.io\n\
      imageRepo: mondoo-app-docker\n\
      \n\
  stages:\n\
  \n\
  - stage: BuildApp\n\
    jobs:\n\
    - job: BuildPushImage\n\
      steps:\n\
      - task: Docker@2\n\
        inputs:\n\
          containerRegistry: "AzureRegistry"\n\
          repository: "$(imageRepo)"\n\
          command: "buildAndPush"\n\
          Dockerfile: "**/Dockerfile"\n\
          tags: latest\n\
          \n\
  - stage: ScanwithCnspec\n\
    jobs:\n\
    - job: ScanImage\n\
      steps:\n\
      # - task: Docker@2\n\
      #   inputs:\n\
      #     containerRegistry: "AzureRegistry"\n\
      #     command: "login"\n\
      \n\
      - script: |\n\
          bash -c "$(curl -sSL https://install.mondoo.com/sh)"\n\
          cnspec version\n\
        displayName: "Install cnspec"\n\
        \n\
      - script: |\n\
          docker login securityimages.azurecr.io --username 7b91ff56-a757-4d10-a19b-360c32219a8a --password TUO8Q~NnmBlq2Ard83CA~IFMLEjg-0C9S4odPaih\n\
        displayName: "Log into Azure Container Registry"  \n\
        \n\
      - script: |\n\
          # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
          cnspec scan docker image $(imageNamespace)/$(imageRepo):latest --score-threshold 100 --output junit > TEST-cnspec.xml\n\
        displayName: "Scan Docker Image with cnspec"\n\
        env:\n\
          MONDOO_CONFIG_BASE64: $(MONDOO_CONFIG_BASE64)\n\
          \n\
      - task: PublishTestResults@2\n\
        inputs:\n\
          testResultsFormat: "JUnit"\n\
          testResultsFiles: "**/TEST-*.xml"\n\
          mergeTestResults: true\n\
          testRunTitle: "cnspec"\n\
        condition: succeededOrFailed()```',
        },
        {
          id: "kubernetes_config",
          config:
            '```yaml\n# # Kubernetes Manifest Scan cnspec pipeline\n\
    #\n\
    # The script uses the following secret:\n\
    # - MONDOO_CONFIG_BASE64 - Mondoo Platform credentials\n\
    \n\
    trigger:\n\
    - main\n\
    \n\
    resources:\n\
    - repo: self\n\
    \n\
    variables:\n\
      imageRepo: mondoo-app\n\
      tag: latest\n\
      \n\
    stages:\n\
    \n\
    - stage: Build\n\
      displayName: Build and push the image\n\
      jobs:\n\
      - job: Build\n\
        displayName: Build\n\
        pool:\n\
          vmImage: ubuntu-latest\n\
        steps:\n\
        - task: Docker@2\n\
          displayName: Build and Push image\n\
          inputs:\n\
            containerRegistry: "AzureRegistry"\n\
            repository: "$(imageRepo)"\n\
            command: "buildAndPush"\n\
            Dockerfile: "$(Build.SourcesDirectory)/app/Dockerfile"\n\
            tags: |\n\
              $(tag)\n\
              latest\n\
        # since in this example our manifest is located in our github, we use "Publish Pipeline Artifacts" in order to make the contents of the kubernetes folder which are our manifest files to any stage from now on\n\
        - task: PublishPipelineArtifact@1\n\
          inputs:\n\
            targetPath: "$(Pipeline.Workspace)/s/kubernetes"\n\
            artifact: "manifests"\n\
            publishLocation: "pipeline"\n\
            \n\
    - stage: Deploy\n\
      displayName: Deploy to Kubernetes Cluster\n\
      dependsOn: Build\n\
      variables:\n\
        k8s-secret: k8sauth\n\
        acrdevurl: "securityimages.azurecr.io"\n\
      jobs:\n\
      - job: Deploy\n\
        displayName: Deploy to AKS\n\
        pool: \n\
          vmImage: ubuntu-latest\n\
        steps:\n\
        # In the previous stage we published the artifacts to our pipeline, and now we need to download it in the stages which we need them!\n\
        - task: DownloadPipelineArtifact@2\n\
          inputs:\n\
            buildType: "current"\n\
            artifactName: "manifests"\n\
            targetPath: "$(Pipeline.Workspace)/manifests"\n\
            # whenever the AKS cluster needs to pull an image from the Azure Container Registry, it will use the following credential for that\n\
            # and this is due to the fact that Azure Container Registry is Private\n\
        - task: KubernetesManifest@0\n\
          inputs:\n\
            action: "createSecret"\n\
            kubernetesServiceConnection: "k8s-connection-default"\n\
            namespace: "default"\n\
            secretType: "dockerRegistry"\n\
            secretName: "$(k8s-secret)"\n\
            dockerRegistryEndpoint: "AzureRegistry"\n\
        - task: KubernetesManifest@0\n\
          inputs:\n\
            action: "deploy"\n\
            kubernetesServiceConnection: "k8s-connection-default"\n\
            namespace: "default"\n\
            manifests: |\n\
              $(Pipeline.Workspace)/manifests/deployment.yml\n\
              $(Pipeline.Workspace)/manifests/service.yml\n\
            containers: "$(acrdevurl)/$(imageRepo):$(tag)"\n\
            imagePullSecrets: "$(k8s-secret)"\n\
            \n\
            \n\
    - stage: Scan\n\
      displayName: Scan with cnspec\n\
      dependsOn: Deploy\n\
      jobs:\n\
      - job: ScanImage\n\
        steps:\n\
        - task: DownloadPipelineArtifact@2\n\
          inputs:\n\
            buildType: "current"\n\
            artifactName: "manifests"\n\
            targetPath: "$(Pipeline.Workspace)/manifests"\n\
            \n\
        - script: |\n\
            bash -c "$(curl -sSL https://install.mondoo.com/sh)"\n\
            cnspec version\n\
          displayName: "Install cnspec"\n\
          \n\
        - script: |\n\
            # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
            cnspec scan k8s --path "$(Pipeline.Workspace)/manifests/deployment.yml" --score-threshold 100\n\
            cnspec scan k8s --path "$(Pipeline.Workspace)/manifests/service.yml" --score-threshold 100\n\
          displayName: "Scan Manifests with cnspec"\n\
          env:\n\
            MONDOO_CONFIG_BASE64: $(MONDOO_CONFIG_BASE64)```',
        },
        {
          id: "terraform_config",
          config:
            '```yaml\n# Terraform Scan cnspec pipeline\n\
    #\n\
    # The script uses the following secret:\n\
    # - MONDOO_CONFIG_BASE64 - Mondoo Platform credentials\n\
    \n\
    trigger:\n\
      - main\n\
    \n\
    pool:\n\
      vmImage: "ubuntu-latest"\n\
    \n\
    steps:\n\
      - script: |\n\
          bash -c "$(curl -sSL https://install.mondoo.com/sh)"\n\
          ./cnspec version\n\
        displayName: "Install cnspec"\n\
        env:\n\
          MONDOO_CONFIG_BASE64: $(MONDOO_CONFIG_BASE64)\n\
    \n\
      - script: |\n\
          # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
          ./cnspec scan terraform --path my_tf_config.tf --score-threshold 90\n\
        displayName: "Scan Terraform with cnspec"```',
        },
      ],
    },
  ],
};
