import {
  ControlsEdge,
  ExceptionGroup,
  GetComplianceFrameworkControlsDocument,
  ListExceptionGroupsDocument,
  TestIamActionsQuery,
} from "~/operations";
import { Exceptions } from "~/components/exceptions/exceptions";
import { useFrameworkContext } from "~/pages/compliance/framework/framework";
import { Table, TableCell, TableHead, TableRow, useTheme } from "@mui/material";
import { CompletionBar } from "~/pages/compliance/completion-bar";
import { Link, useParams, useSearchParams } from "react-router-dom";

export type ExceptionsProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function ExceptionsWrapper({ availablePermissions }: ExceptionsProps) {
  const { space, framework, exceptionGroups } = useFrameworkContext();
  const [searchParams] = useSearchParams();
  const params = useParams();
  const theme = useTheme();

  return (
    <Exceptions
      availablePermissions={availablePermissions}
      emptyStateType="framework-exceptions"
      refetchQueries={[
        ListExceptionGroupsDocument,
        {
          query: GetComplianceFrameworkControlsDocument,
          variables: {
            input: {
              frameworkMrn: framework.mrn,
              scopeMrn: space.mrn,
            },
            controlsInput: {
              after: null,
              limit: 50,
            },
          },
        },
      ]}
      exceptionGroups={exceptionGroups}
      renderTable={function (exceptionGroup: ExceptionGroup) {
        const controls = exceptionGroup.exceptions;
        return (
          <>
            <Table sx={{ backgroundColor: "background.lighter" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: "40%" }}>Control</TableCell>
                  <TableCell>Assets</TableCell>
                  <TableCell sx={{ width: "20%" }}>Completion</TableCell>
                  <TableCell>Framework</TableCell>
                </TableRow>
              </TableHead>
              {(controls as ControlsEdge[]).map((control) => {
                const { node } = control;
                const controlHref = () => {
                  const spaceId = searchParams.get("spaceId");
                  const frameworkMrn = searchParams.get("frameworkMrn");
                  const frameworkId = params.frameworkId;
                  const controlMrn = node?.mrn;
                  const controlId = node?.mrn.split("/").pop();
                  return `/space/compliance/${frameworkId}/controls/${controlId}?spaceId=${spaceId}&frameworkMrn=${frameworkMrn}&controlMrn=${controlMrn}`;
                };

                return (
                  <TableRow key={node?.mrn} component={Link} to={controlHref()}>
                    <TableCell sx={{ width: "40%" }}>{node?.title}</TableCell>
                    <TableCell>{node?.assetsStats.totalAssets}</TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      <CompletionBar
                        percent={Number(node?.completion)}
                        height={theme.spacing(1)}
                        tooltip={`Completion: ${node?.completion}%`}
                      />
                    </TableCell>
                    <TableCell>
                      {node?.framework?.name} {node?.framework?.version}
                    </TableCell>
                  </TableRow>
                );
              })}
            </Table>
          </>
        );
      }}
    />
  );
}
