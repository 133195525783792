import {
  Box,
  Grid,
  styled,
  TextareaAutosize,
  Typography,
  useTheme,
} from "@mui/material";
import { motion, Variants } from "framer-motion";
import { ControllerRenderProps, FieldValues } from "react-hook-form";

type OperatorListProps<T extends FieldValues> = {
  configuration: string;
  caption: string;
  field: ControllerRenderProps<T, any>;
  index?: number;
  disabled?: boolean;
};

const operatorListVariants: Variants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
};

export function OperatorList<T extends FieldValues>({
  configuration,
  caption,
  field,
  index = 0,
  disabled,
}: OperatorListProps<T>) {
  const theme = useTheme();

  return (
    <Grid
      container
      component={motion.div}
      variants={operatorListVariants}
      sx={{
        px: 3,
        "& .form-text": {
          color: disabled ? "text.disabled" : "inherit",
        },
      }}
    >
      <Grid item container xs={12}>
        <Grid item>
          <Box sx={{ width: 50, height: 50, mr: 1 }}>
            <MotionPath {...{ index, off: true, disabled }} />
          </Box>
        </Grid>
        <Grid
          item
          xs
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <Typography
            sx={{ display: "inline-block", mb: -0.25 }}
            className="form-text"
          >
            {configuration}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid sx={{ width: 50, mr: 1 }}>{/* intentionally left blank */}</Grid>
        <Grid item xs>
          <Typography
            sx={{ fontSize: (theme) => theme.spacing(1.5) }}
            color="text.secondary"
            className="form-text"
          >
            {caption}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ pl: 7.5, pr: 12, pt: 2 }}>
        <TextareaAutosize
          aria-label={configuration}
          {...field}
          disabled={disabled}
          minRows={3}
          style={{
            width: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            padding: "16px",
            background: theme.palette.code.background,
            borderRadius: "4px",
            color: disabled
              ? theme.palette.text.disabled
              : theme.palette.text.primary,
          }}
        />
      </Grid>
    </Grid>
  );
}

// Elbow Shaped self drawing path
type MotionPathProps = {
  index: number;
  off?: boolean;
  disabled?: boolean;
};

const MotionPath = ({ index, off, disabled }: MotionPathProps) => {
  const MotionSvg = styled(motion.svg)`
    fill: none;
    stroke: ${(p) =>
      disabled
        ? p.theme.palette.text.disabled
        : p.theme.palette.text.secondary};
  `;

  const pathVariants = {
    closed: {
      opacity: 0,
      pathLength: 0,
    },
    open: {
      opacity: 1,
      pathLength: 1,
      transition: {
        delay: index * 0.1,
        duration: 0.35,
        ease: "easeIn",
      },
    },
  };

  return (
    <MotionSvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        d="M 15 10 L 15 20 L 23 20"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={pathVariants}
        initial={off ? "open" : "closed"}
        animate="open"
      />
    </MotionSvg>
  );
};
