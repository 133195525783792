import { createSvgIcon } from "@mui/material";

export const ComfyListIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="4" y="4" width="16" height="4" />
    <rect x="4" y="10" width="16" height="4" />
    <rect x="4" y="16" width="16" height="4" />
  </svg>,
  "ComfyList",
);
