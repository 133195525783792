import { createSvgIcon } from "@mui/material";

// terraform Purple hex is #7b42bc

export const TerraformMarkIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="15 -5 90 90"
    fill="currentColor"
  >
    <polygon
      fill="#7b42bc"
      points="47.48 13.64 68.64 25.86 68.64 50.3 47.48 38.08 47.48 13.64"
    />
    <polygon
      fill="#7b42bc"
      points="70.96 25.86 70.96 50.3 92.13 38.08 92.13 13.64 70.96 25.86"
    />
    <polygon
      fill="#7b42bc"
      points="24 0 24 24.44 45.16 36.66 45.16 12.22 24 0"
    />
    <polygon
      fill="#7b42bc"
      points="47.48 65.19 68.64 77.41 68.64 53.14 68.64 52.98 47.48 40.76 47.48 65.19"
    />
  </svg>,
  "Terraform",
);
