import { forwardRef } from "react";
import { Box, SxProps, TextField, TextFieldProps } from "@mui/material";

type Props = {
  containerProps?: SxProps;
} & TextFieldProps;

export const AuthTextField = forwardRef(
  ({ containerProps, ...props }: Props, ref) => {
    return (
      <Box sx={containerProps}>
        <TextField
          fullWidth
          inputProps={{
            sx: {
              p: 1.5,
              color: "#000000",
              "&::placeholder": { color: "#AAAAAA" },
            },
          }}
          sx={{
            background: "#ECECEC",
            boxShadow: "inset 0 0 0 1px #BFBFBF",
            borderRadius: 1,
            ".MuiFormHelperText-root": {
              m: 0,
              pt: 0.75,
              px: 1,
              pl: 0,
              backgroundColor: "#FFFFFF",
            },
          }}
          {...props}
          inputRef={ref}
        />
      </Box>
    );
  },
);
