import { useGetAssetPackagesQuery } from "~/operations";
import { TableContainer, Table, TableBody, TableRow } from "@mui/material";
import { LoadingPage, LoadingFailedPage } from "~/components/loading";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { Header } from "~/types/table";
import { ImpactCell } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTable/ImpactCell";
import { TitleCell } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTable/TitleCell";
import { FirstFoundCell } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTable/FirstFoundCell";
import { RiskFactorsCell } from "../Cells";

export type PrintPackagesProps = {
  assetMrn: string;
  hideEmpty?: boolean;
};

export function PrintPackages(props: PrintPackagesProps) {
  const { assetMrn, hideEmpty } = props;

  const { loading, error, data } = useGetAssetPackagesQuery({
    variables: {
      input: {
        entityMrn: assetMrn,
      },
    },
  });

  const packageScores =
    data?.packageScores?.__typename === "PackageScores"
      ? data?.packageScores
      : undefined;

  const packages = packageScores?.edges?.flatMap((e) => e.node ?? []) || [];

  if (loading) {
    return <LoadingPage what="packages" />;
  }

  if (error || !packageScores) {
    return <LoadingFailedPage what="packages" />;
  }

  // if hideEmpty is set, we do not render the table
  if (hideEmpty && packages.length === 0) {
    return <></>;
  }

  const tableHeaders: Header[] = [
    {
      id: "IMPACT",
      label: "Impact",
      sortable: false,
      options: {
        width: "10%",
      },
    },
    {
      id: "PACKAGE",
      label: "Package",
      sortable: false,
      options: {
        width: "50%",
      },
    },
    {
      id: "RISK_FACTORS",
      label: "Risk Factors",
      sortable: false,
    },
    {
      id: "FIRST_FOUND",
      label: "First found",
      sortable: false,
      options: {
        width: "15%",
      },
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead tableHeaders={tableHeaders} />
        <TableBody>
          {packages.map((pckg, index) => (
            <TableRow key={index}>
              <ImpactCell
                hideTooltip
                pckg={pckg}
                data-test-id="software-impact"
              />
              <TitleCell pckg={pckg} data-test-id="software-name" />
              <RiskFactorsCell
                riskFactors={pckg.riskFactors}
                data-test-id="software-risk-factors"
              />
              <FirstFoundCell pckg={pckg} data-test-id="software-first-found" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
