import { ChangeEvent } from "react";
import {
  Link as RouterLink,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Loading, LoadingFailedPage } from "~/components/loading";
import { Space } from "~/lib/types";
import {
  AggregateScoreType,
  CaseStatus,
  GetCasesCountDocument,
  TestIamActionsQuery,
  useGetAggregateScoresQuery,
  useGetCasesCountQuery,
  useGetTicketingIntegrationsQuery,
  useLoadAdvisoryQuery,
} from "~/operations";
import { HomeIcon } from "~/components/icons";
import {
  CveRiskFactors,
  Sources,
  SourcesProps,
} from "~/components/vulnerabilities";
import { AdvisoryContentDetails } from "~/components/vulnerabilities/advisory-content-details";
import { useInventory } from "./inventory/hooks/useInventory";
import { FlagOutlined, Radar } from "@mui/icons-material";
import { SectionHeading, Stats } from "~/components/DetailsPage/components";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { Header } from "~/components/DetailsPage/components/Header";
import { AdvisoryRemediation } from "~/components/vulnerabilities/advisory-remediation";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { useCases } from "~/components/cases/hooks";
import { CasesSummary } from "~/components/cases/components/CasesSummary";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { CreateCaseButton } from "~/components/cases/components/CreateCaseButton";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { CreateCasesIntegrationModal } from "~/components/cases/components/CreateCasesIntegrationModal";
import { CreateCaseModal } from "~/components/cases/components/CreateCaseModal";
import { ScrollToTop } from "~/lib/scroll-to-top";
import { CreateCaseMenuButton } from "~/components/cases/components/CreateCaseMenu";
import { VulnerabilityAffectedAssetsAdapter } from "~/pages/space/vulnerabilities/components/AffectedAssets";
import { ScoreBlock } from "./space/security/components/Check/ScoreBlock";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";

export type SpaceAdvisoryPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const SpaceAdvisoryPage = ({
  space,
  availablePermissions,
}: SpaceAdvisoryPageProps) => {
  const { id = "" } = useParams();
  const [searchParams] = useSearchParams();
  const { state } = useLocation();
  const isMvdSource = state?.source === "mvd";
  const { spaceStats } = useInventory({ spaceMrn: space.mrn });

  const { data, loading, error } = useLoadAdvisoryQuery({
    variables: { id, spaceMrn: space.mrn },
  });

  const { data: aggScoreData, loading: aggScoreLoading } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: space.mrn,
        filter: {
          findingMrn: data?.advisory?.mrn,
        },
      },
      skip: !data?.advisory?.mrn,
    });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: String(data?.advisory?.mrn || ""),
    scoreType: AggregateScoreType.Advisory,
  });

  /*
    const {
      isRemovingException,
      isSettingException,
      handleSetExceptionModalClose,
      handleRemoveExceptionModalClose,
      handleRemoveExceptionForScopes,
      handleSetExceptionForScopes,
      handleSetScopeForMultipleScopes,
      loading: exceptionsLoading,
    } = useExceptions({
      onSetException: () => {
        setSelectedChecks([]);
      },
      onRemoveException: () => {
        setSelectedChecks([]);
      },
      // TBD when backend is ready
      scopeMrns: [],
      controlMrns: [],
      multipleScopes: true,
      refetchQueries: [],
    });*/

  const {
    handleCreateCaseIntegrationModalOpen,
    handleCreateCaseIntegrationModalClose,
    handleCreateCaseModalOpen,
    handleCreateCaseModalClose,
    isCreateCaseModalOpen,
    isCreateCaseIntegrationModalOpen,
    handleCreateCase,
    hasCreateCasePermissions,
    hasIntegrationCreatePermission,
    hasListCasePermissions,
    loading: createCaseLoading,
  } = useCases({
    createCaseRefetchQueries: [GetCasesCountDocument],
    availablePermissions,
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        findingMrns: [data?.advisory?.mrn as string],
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !data?.advisory?.mrn || !hasListCasePermissions,
  });

  const { data: ticketingIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasCreateCasePermissions,
  });

  const {
    handleNodeClick,
    handleCancelClick,
    selectedEntities: selectedAssets,
    setSelectedEntities: setSelectedAssets,
  } = useExceptionsSelection();

  if (loading || aggScoreLoading || riskFactorsLoading) {
    return <Loading what="Advisory" />;
  }

  const advisory = data?.advisory;
  const aggScore =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores.edges?.at(0)?.node
      : undefined;

  // When no aggregateScore exists, we fallback cvssScore of the Advisory.
  let fallbackCvssScore = undefined;
  if (!aggScore) {
    if (advisory?.cvssScore.value) {
      // If the Advisory has no cvssScore - the scoreblock will fallback further to UNKNOWN
      fallbackCvssScore = advisory?.cvssScore;
    }
  }

  if (error || !advisory) {
    return <LoadingFailedPage what="Advisory" />;
  }

  const sourceLinks: SourcesProps["links"] = (advisory?.externalUrls || [])
    .flatMap((link) => link ?? [])
    .map((reference) => ({
      href: reference.url,
      text: reference.title || "Advisory Source Link",
      icon: reference.iconIDEnum,
    }));

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    isMvdSource ? (
      <Link
        key="/space/vulns"
        component={RouterLink}
        to={`/space/vulns?spaceId=${space.id}&risktype=ADVISORY`}
        display="flex"
      >
        Mondoo Vulnerability Database
      </Link>
    ) : (
      <Link
        key="/space/vulns"
        component={RouterLink}
        to={`/space/vulnerabilities/advisories?spaceId=${space.id}`}
        display="flex"
      >
        Advisories
      </Link>
    ),
    <Typography key={"/space/vulns/advisory/:advisory"}>
      {advisory.title}
    </Typography>,
  ];

  const totalScanned =
    spaceStats.data?.space?.stats?.riskdistribution.total || 0;
  const totalAffected = advisory.assets?.length || 0;

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Affected",
      value: totalAffected < 0 ? "---" : totalAffected.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
      onClick: () => {
        document
          .querySelector(`#affected-assets`)
          ?.scrollIntoView({ behavior: "smooth" });
      },
    },
    // {
    //   label: "Updated",
    //   count: -1, // TODO
    //   icon: <ZoomOutMap fontSize="inherit" />,
    // },
  ];

  const hasTicketingIntegration =
    (ticketingIntegrationsData?.ticketingIntegrations?.integrations?.length ||
      0) > 0;

  document.title = `${advisory.title} · Advisories · Mondoo`;

  return (
    <Box>
      <ScrollToTop />
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>

      <Header
        id="advisory-title"
        title={advisory.id}
        created={String(advisory.publishedAt)}
        lastModified={String(advisory.modifiedAt)}
        riskFactors={riskFactors}
        {...{
          rightSection: hasTicketingIntegration ? (
            hasCreateCasePermissions && (
              <CreateCaseMenuButton
                onAllAssetsItemClick={() => {
                  setSelectedAssets([
                    {
                      mrn: advisory?.mrn,
                      scopeMrn: space.mrn,
                      groupId: "ALL_ASSETS",
                    },
                  ]);
                  handleCreateCaseModalOpen();
                }}
              />
            )
          ) : (
            <CreateCaseButton onClick={handleCreateCaseIntegrationModalOpen} />
          ),
        }}
      />

      <Box id="description">
        <SectionHeading heading="Description" />
        {hasListCasePermissions && (casesData?.cases.totalCount || 0 > 0) && (
          <CasesSummary
            count={casesData?.cases.totalCount || 0}
            space={space}
          />
        )}
        <Typography textTransform="uppercase" fontWeight={700} mb={1}>
          Summary
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Stats id="advisory-asset-stats" stats={stats} />
            {advisory.description && (
              <ShowMoreSummary
                id="advisory-summary"
                text={advisory.description}
              />
            )}
          </Grid>
          <Grid item xs={12} md={4}>
            <ScoreBlock
              hasScore={Boolean(aggScore)}
              mainScore={aggScore?.riskScore}
              cvssScore={aggScore?.cvss || fallbackCvssScore}
              epssScore={aggScore?.epss}
              blastRadius={aggScore?.blastRadius}
              riskFactors={aggScore?.riskFactors}
              hasError={
                Boolean(aggScore) && aggScore?.__typename !== "AggregateScore"
              }
            />
          </Grid>
        </Grid>
        <Grid container mt={3}>
          <Grid item xs={12} md={6}>
            <Sources id="advisory-sources" links={sourceLinks} />
          </Grid>
        </Grid>
      </Box>

      <Box id="risk-factors" className="section">
        <SectionHeading heading="Risk Assessment" />
        <Box className="section-content">
          <CveRiskFactors
            id="cve-risk-factors"
            cvssScore={advisory.cvssScore}
            riskFactors={riskFactorsWithDocs}
          />
        </Box>
      </Box>

      <AdvisoryContentDetails advisoryId={advisory.id} space={space} />

      <AdvisoryRemediation advisoryId={advisory.id} spaceMrn={space.mrn} />

      <VulnerabilityAffectedAssetsAdapter
        space={space}
        vulnerabilityId={advisory.id}
        vulnerabilityScoreType="Advisory"
        selectionProps={{
          selectedEntities: selectedAssets,
          hasSelectPermissions: hasCreateCasePermissions,
          onCheckboxClick: handleNodeClick,
          getIsRowSelected: (rowAssetMrn) => {
            return Boolean(
              selectedAssets.find((asset) => asset.scopeMrn === rowAssetMrn),
            );
          },
          onCheckAll: (checked, edges) => {
            if (checked) {
              setSelectedAssets(
                (edges || []).map((edge) => ({
                  groupId: "",
                  scopeMrn: edge?.mrn || "",
                  mrn: advisory?.mrn,
                  exception: null,
                })),
              );
            } else {
              setSelectedAssets([]);
            }
          },
          onCheckboxChange: async (
            e: ChangeEvent<HTMLInputElement>,
            checked: boolean,
            asset,
          ) => {
            const nextSelected = selectedAssets.filter(
              (s) => s.scopeMrn !== asset?.mrn,
            );
            if (checked) {
              nextSelected.push({
                groupId: "",
                mrn: advisory?.mrn,
                scopeMrn: asset?.mrn || "",
                exception: null,
              });
            }

            setSelectedAssets(nextSelected);
          },
          renderToolbar: (totalCount) => {
            return (
              selectedAssets.filter((a) => a.groupId !== "ALL_ASSETS").length >
                0 && (
                <ExceptionsToolbar
                  target="asset"
                  onCreateCaseClick={
                    hasTicketingIntegration
                      ? hasCreateCasePermissions
                        ? handleCreateCaseModalOpen
                        : undefined
                      : handleCreateCaseIntegrationModalOpen
                  }
                  onCancel={handleCancelClick}
                  selectedEntities={selectedAssets}
                  totalCount={totalCount}
                />
              )
            );
          },
        }}
      />
      <CreateCasesIntegrationModal
        open={isCreateCaseIntegrationModalOpen}
        onClose={handleCreateCaseIntegrationModalClose}
        space={space}
        redirectTo={`${location.pathname}?${searchParams}`}
        hasIntegrationCreatePermission={hasIntegrationCreatePermission}
      />
      <CreateCaseModal
        open={isCreateCaseModalOpen}
        target="asset"
        loading={loading || createCaseLoading}
        onClose={() => {
          handleCreateCaseModalClose();
          setSelectedAssets([]);
        }}
        onSave={handleCreateCase}
        selectedAssets={selectedAssets}
        space={space}
        hasCreateCasePermissions={hasCreateCasePermissions}
      />
    </Box>
  );
};
