import { Fragment, MouseEvent, useState } from "react";
import {
  ClickAwayListener,
  Grow,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  PopperPlacementType,
  Typography,
} from "@mui/material";
import { CreateCaseButton } from "~/components/cases/components/CreateCaseButton";

type Props = {
  id: string;
  actions: Action[];
  placement?: PopperPlacementType;
  buttonIcon?: JSX.Element;
  buttonSize?: "small" | "medium" | "large";
  listProps?: {
    title?: string;
    checkbox?: boolean;
  };
};

export type Action = {
  label: string;
  action: () => void;
  icon?: JSX.Element;
  dataName?: string;
  checked?: boolean;
};

export function CreateCaseMenu({
  id,
  actions,
  listProps,
  placement = "bottom-end",
}: Props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {actions?.length > 0 && <CreateCaseButton onClick={handleClick} />}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        sx={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} timeout={350}>
              <Paper sx={{ minWidth: 150, p: listProps?.title ? 1 : 0 }}>
                <Fragment>
                  {listProps?.title && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ pt: 1, px: 2 }}
                    >
                      {listProps.title}
                    </Typography>
                  )}
                  <MenuList>
                    {actions.map((action: Action) => (
                      <MenuItem
                        {...(listProps?.checkbox
                          ? {}
                          : { onClick: action.action })}
                        key={action.label}
                        {...(action.dataName
                          ? { "data-name": action.dataName }
                          : {})}
                      >
                        <Fragment>
                          {action.icon && (
                            <ListItemIcon>{action.icon}</ListItemIcon>
                          )}
                          <ListItemText>{action.label}</ListItemText>
                        </Fragment>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Fragment>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Fragment>
  );
}
