import { createSvgIcon } from "@mui/material";

export const GCPAuthIcon = createSvgIcon(
  <svg viewBox="0 0 49 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1622_35474)">
      <path
        d="M31.087 11.0058H32.5882L36.8367 6.69541L37.035 4.85633C34.5959 2.66551 31.6457 1.14284 28.4642 0.432573C25.2827 -0.277697 21.975 -0.152031 18.8548 0.797666C15.7345 1.74736 12.9049 3.48962 10.6341 5.85937C8.36335 8.22913 6.72657 11.1479 5.87891 14.3391C6.3554 14.1417 6.88212 14.1064 7.38006 14.2385L15.8772 12.8017C15.8772 12.8017 16.3162 12.069 16.5428 12.1264C18.3634 10.09 20.8833 8.8377 23.5853 8.62663C26.2872 8.41556 28.9664 9.26169 31.0728 10.9914L31.087 11.0058Z"
        fill="#AAAAAA"
      />
      <path
        d="M42.9091 14.3391C41.9329 10.6795 39.9247 7.38869 37.1311 4.87067L31.1548 10.9339C32.3978 11.9682 33.3939 13.2746 34.0684 14.7552C34.7429 16.2357 35.0782 17.852 35.0493 19.4828V20.5603C36.4615 20.5603 37.8159 21.1295 38.8145 22.1426C39.8131 23.1558 40.3741 24.5299 40.3741 25.9626C40.3741 27.3954 39.8131 28.7695 38.8145 29.7826C37.8159 30.7958 36.4615 31.3649 35.0493 31.3649H24.4137L23.3516 32.4569V38.9224L24.4137 40H35.0493C38.0191 40.0224 40.9173 39.0747 43.3153 37.2971C45.7133 35.5194 47.4838 33.006 48.3651 30.1286C49.2465 27.2512 49.1918 24.1625 48.2093 21.319C47.2267 18.4755 45.3684 16.0283 42.9091 14.3391Z"
        fill="#AAAAAA"
      />
      <path
        d="M13.75 39.9425H24.3997V31.3218H13.75C12.9935 31.3225 12.2455 31.1609 11.5549 30.8477L10.1387 31.3075L5.89017 35.6178L5.50781 37.1408C7.8853 38.9524 10.7766 39.9353 13.75 39.9425Z"
        fill="#AAAAAA"
      />
      <path
        d="M13.7487 11.9109C10.8674 11.9294 8.06369 12.86 5.72881 14.5729C3.39392 16.2858 1.64436 18.6955 0.724319 21.4657C-0.195727 24.2359 -0.240364 27.2285 0.59663 30.0257C1.43362 32.8229 3.1105 35.2852 5.39322 37.069L11.5678 30.8333C10.9184 30.5492 10.3312 30.1366 9.84071 29.6197C9.35017 29.1028 8.96604 28.492 8.71074 27.8229C8.45544 27.1537 8.33409 26.4397 8.35377 25.7224C8.37345 25.0052 8.53376 24.2991 8.82535 23.6454C9.11695 22.9917 9.53399 22.4034 10.0521 21.9151C10.5702 21.4267 11.1791 21.0479 11.843 20.8008C12.507 20.5537 13.2129 20.4433 13.9194 20.4761C14.6259 20.5088 15.3189 20.6839 15.9579 20.9914C17.1378 21.5312 18.0805 22.4927 18.6062 23.6925L24.7808 17.4282C23.4872 15.7061 21.8179 14.3118 19.9037 13.3545C17.9896 12.3972 15.8828 11.9031 13.7487 11.9109Z"
        fill="#AAAAAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1622_35474">
        <rect width="49" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "GCP",
);
