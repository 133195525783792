import { IamActions } from "~/lib/iam";
import { TestIamActionsQuery } from "~/operations";

export type UsePolicyPermissionsProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function usePolicyPermissions({
  availablePermissions,
}: UsePolicyPermissionsProps) {
  const policyAssignPermission = [
    IamActions.POLICY_DISTRIBUTOR_ASSIGN,
    IamActions.POLICY_DISTRIBUTOR_UNASSIGN,
    IamActions.CNSPEC_POLICY_POLICYRESOLVER_ASSIGN,
    IamActions.CNSPEC_POLICY_POLICYRESOLVER_UNASSIGN,
  ].some((permission) => availablePermissions?.includes(permission));

  const editPolicyPermission = [
    IamActions.ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
  ].some((permission) => availablePermissions?.includes(permission));

  const policyUploadPermission = availablePermissions.includes(
    IamActions.ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
  );

  // If a user has upload permission, they also have delete permission
  const policyDeletePermission = availablePermissions.includes(
    IamActions.ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
  );

  // Query Pack's boil down to policies, so we can use the same permissions
  const queryPackAssignPermission = policyAssignPermission;

  const queryPackUploadPermission = availablePermissions.includes(
    IamActions.ACTION_CNQUERY_EXPLORER_QUERYHUB_SETBUNDLE,
  );

  const queryPackDeletePermission = availablePermissions.includes(
    IamActions.ACTION_CNQUERY_EXPLORER_QUERYHUB_DELETEQUERYPACK,
  );

  return {
    policyAssignPermission,
    policyUploadPermission,
    policyDeletePermission,
    editPolicyPermission,
    queryPackAssignPermission,
    queryPackUploadPermission,
    queryPackDeletePermission,
  };
}
