import { ThemeOptions } from "@mui/material/styles";
import { sharedColorBlindPalette } from "./sharedColorBlindPalette";

export const darkColorBlindTheme: ThemeOptions = {
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: sharedColorBlindPalette.primary.light,
          textDecoration: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#333333",
          color: "#FFFFFF",
          boxShadow:
            "0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033",
        },
        arrow: {
          "&::before": {
            backgroundColor: "#333333",
            boxShadow:
              "1px 1px 3px 0px #0000001F, 1px 1px 1px 0px #00000024, 2px 2px 1px -1px #0003",
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#1E1D1C",
      paper: "#292928",
      light: "#333333",
      lighter: "#3B3B3B",
      lightest: "#444444",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B4B4B4",
      disabled: "#949494",
    },
    code: {
      background: "rgba(0, 0, 0, 0.8)",
      flash: "rgba(170,170,170,0.7)",
    },
    tables: {
      tableHead: {
        shadow: "0 2px 8px 0 rgba(30,29,28,0.4)", // todo find update for this
      },
      tableCell: {
        borderBottom: "1px solid #2B2B2B",
      },
    },

    ...sharedColorBlindPalette,
  },
};
