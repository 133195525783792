import { Routes, Route } from "react-router-dom";
import { MVDVulnerabilityPage } from "~/pages/mvd-vulnerability";
import { Redirect } from "~/routes";
import { Space } from "~/lib/types";
import { MondooVulnerabilityDatabase } from "~/components/vulnerabilities/MondooVulnerabilityDatabase/MondooVulnerabilityDatabase";

type Props = {
  space: Space;
};

export function MVDRoutes({ space }: Props) {
  return (
    <Routes>
      <Route index element={<MondooVulnerabilityDatabase space={space} />} />
      <Route path="advisory">
        <Route
          path=":id/*"
          element={<Redirect from="mvd" to="vulns" state={{ source: "mvd" }} />}
        />
      </Route>

      <Route path="cve">
        <Route path=":id/*" element={<MVDVulnerabilityPage space={space} />} />
      </Route>
    </Routes>
  );
}
