import { LoadOrganizationQuery, LoadViewerQuery } from "~/operations";

export type OrgId = {
  id: string;
  mrn: string;
  name: string | null;
};

export type Org = NonNullable<
  NonNullable<LoadViewerQuery["viewer"]>["organizations"]
>[0];

export type MinimalOrg = NonNullable<
  NonNullable<LoadOrganizationQuery["organization"]>
>;

export type SpaceId = {
  id: string;
  mrn: string;
};

export type Space = NonNullable<
  NonNullable<LoadViewerQuery["viewer"]>["firstSpace"]
>;

export type KeyValue = {
  key: string;
  value?: string | null;
};

export type AWSEventPatternInput = {
  scanType: string;
  eventSource: string;
  eventDetailType: string;
};

export interface Ec2ScanOptions {
  ssm: boolean;
  awsSecretsManagerVault: boolean;
  secretsMetadataQuery: string;
  vaultType: string;
  instanceIdsFilter: string[];
  regionsFilter: string[];
  tagsFilter: { [key: string]: string };
  ebsVolumeScan: boolean;
  ebsScanOptions: EbsScanOptions;
  instanceConnect: boolean;
}

export interface EbsScanOptions {
  targetInstancesPerScanner: number;
  maxAsgInstances: number;
  imageType: string;
  instanceType: string;
}

export interface ScanConfiguration {
  accountScan: boolean;
  ec2Scan: boolean;
  ecrScan: boolean;
  ecsScan: boolean;
  cronScaninHours: number;
  ec2ScanOptions: Ec2ScanOptions;
  eventScanTriggers: AWSEventPatternInput[];
  eventScanTriggersString: string[];
}

export type Role = {
  mrn: string;
  title: string;
};

export type ServiceAccount = {
  id: string;
  mrn: string;
  name: string;
  roles: Role[];
  createdAt: string;
};

export type Score = {
  type: number;
  value: number;
};

export type MajorScore = "A" | "B" | "C" | "D" | "F" | "U" | "X";

export const advisoryTitle = (
  advisory: { id?: string | null; title?: string | null },
  maxLen = 100,
) => {
  const { id, title } = advisory;
  let text = title || id || "Advisory";
  if (text && text.length > maxLen) {
    text = text.substr(0, maxLen) + "…";
  }
  return text;
};

export type ScoreRange = {
  begin: number;
  end: number;
};

export type SortDirection = "ASC" | "DESC";

export type Sort = {
  field: string;
  direction: SortDirection;
};

export type ScoreRatingDistribution = {
  total: number;
  A: number;
  B: number;
  C: number;
  D: number;
  F: number;
  U: number;
  Error: number;
};

export interface AgentFilter {
  title(): string;
  value(): any;
}
