import { Grid } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { GridSegment } from "../grid-segment";
import { RemediatedShieldIcon } from "~/components/icons/mondoo/dashboard";
import { Space } from "~/lib/types";
import { Metrics, useMetrics } from "~/hooks";

type Props = {
  sizes?: CardSizes;
  space: Space;
};

export function RemediatedCard({ space, sizes }: Props) {
  const { remediationSeverity, isLoading } = useMetrics({
    entityMrn: space.mrn,
    metricMrns: [Metrics.RemediationSeverity],
  });

  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "Total Vulnerabilities Remediated",
        icon: <RemediatedShieldIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
      data-testid="remediated-vulnerabilities"
    >
      <Grid container spacing={2} sx={{ pt: 2 }}>
        {remediationSeverity
          ?.filter((x) => x !== null)
          .map((x) => (
            <GridSegment data={x} key={x.title} color segments={5} />
          ))}
      </Grid>
    </HubCard>
  );
}
