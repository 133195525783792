import { FormEvent, ReactNode, createContext } from "react";

// TODO we should make our User and AuthError definition independent from Firebase
import { User, IdTokenResult } from "./firebase-provider";

// types
export type AuthProviderProps = { children: ReactNode };

// actions
type Action =
  | { type: "set user begin" }
  | {
      type: "set user success";
      user: User | null;
      idTokenResult: IdTokenResult | null;
    }
  | { type: "set user failure"; error: string }
  | { type: "reset password begin" }
  | { type: "reset password success" }
  | { type: "reset password failure" }
  | { type: "clear errors" }
  | { type: "set geolock"; isGeolocked: boolean };

export type Dispatch = (action: Action) => void;

export type State = {
  status: "idle" | "pending";
  user: User | null;
  idTokenResult: IdTokenResult | null;
  error: string | null;
  isGeolocked: boolean;
};

export type SignupProps = {
  email: string;
  password: string;
  optIn: boolean;
};

export type AuthOptions = "google" | "github" | "msft";

export type AuthContextTypes =
  | {
      state: State;
      dispatch: Dispatch;
      logout: () => void;
      loginWithProvider: (
        e: FormEvent,
        dispatch: Dispatch,
        provideOption: AuthOptions,
      ) => void;
      signupWithEmailAndPassword: (
        e: FormEvent,
        dispatch: Dispatch,
        { email, password, optIn }: SignupProps,
      ) => void;
      loginWithEmailAndPassword: (
        e: FormEvent,
        dispatch: Dispatch,
        { email, password }: { email: string; password: string },
      ) => void;
      loginWithSSO: (e: FormEvent, dispatch: Dispatch, orgId: string) => void;
      sendResetPasswordEmail: (
        dispatch: Dispatch,
        email: string,
        e?: FormEvent,
      ) => void;
      sendUserEmailVerification: () => Promise<void>;
      changePassword: (
        oldPassword: string,
        newPassword: string,
      ) => Promise<void>;
      clearErrors: () => void;
      setGeolock: (isGeolocked: boolean) => void;
      handleErrorMessage: (errorCode: string) => string;
    }
  | undefined;

export const AuthContext = createContext<AuthContextTypes>(undefined);

export function authReducer(state: State, action: Action): State {
  switch (action.type) {
    case "set user begin":
      return { ...state, status: "pending" };
    case "set user success":
      return {
        ...state,
        status: "idle",
        user: action.user,
        idTokenResult: action.idTokenResult,
        error: null,
      };
    case "set user failure":
      return {
        ...state,
        status: "idle",
        user: null,
        idTokenResult: null,
        error: action.error,
      };
    case "reset password begin":
      return { ...state, status: "pending" };
    case "reset password success":
      return { ...state, status: "idle" };
    case "reset password failure":
      return { ...state, status: "idle" };
    case "clear errors":
      return { ...state, error: null };
    case "set geolock":
      return { ...state, isGeolocked: action.isGeolocked };
    default:
      throw new Error(`Unhandled action type: ${action}`);
  }
}

export const initialState: State = {
  status: "idle",
  user: null,
  idTokenResult: null,
  error: null,
  isGeolocked: false,
};
