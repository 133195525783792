import { TableCell, Typography } from "@mui/material";
import { FormatAbbrDateYear } from "~/lib/date";

type DateCellProps = {
  date: string | null | undefined;
};

export const DateCell = ({ date = "" }: DateCellProps) => {
  return (
    <TableCell>
      <Typography fontSize={12}>{FormatAbbrDateYear(date)}</Typography>
    </TableCell>
  );
};
