import { PolicyIdToMrn } from "~/lib/mrn";
import { Space } from "~/lib/types";
import { useGetPolicyPropsQuery } from "~/operations";
import { PropsTable } from "./props-table";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { Box } from "@mui/material";
import { EmptyState } from "~/components/empty-state/empty-state";

export type PolicyPropertiesProps = {
  space: Space;
  policyId: string;
  variantPlatformFilter?: string | null;
};

export function PolicyProperties({
  space,
  policyId,
  variantPlatformFilter,
}: PolicyPropertiesProps) {
  const mrn = PolicyIdToMrn(policyId);

  const { data, loading, error, refetch } = useGetPolicyPropsQuery({
    variables: {
      input: { mrn, variantPlatformFilter },
      scopeInput: { mrn: space.mrn },
    },
    // network-only until we can figure out how to cache Policy.properties
    // for different variants of a Policy
    fetchPolicy: "network-only",
  });

  const loadingFailed = !loading && (error || !data);
  const policy = data?.policy;
  const scopePolicy = data?.scopePolicy;
  const scopePolicyProps = scopePolicy?.properties ?? [];
  const queriesProps =
    policy?.queries?.flatMap((q) => q.properties ?? []) ?? [];
  const alteredProps = queriesProps
    .map((qp) => ({
      ...qp,
      mql: scopePolicyProps.find((pp) => pp.mrn === qp.mrn)?.mql || qp.mql,
    }))
    .sort((a, b) => a.uid.localeCompare(b.uid));

  const listView = (
    <PropsTable space={space} data={alteredProps} onChange={() => refetch()} />
  );

  return (
    <Box className="policy-properties">
      {loading && <LoadingPage what="Properties" />}
      {loadingFailed && <LoadingFailedPage what="Properties" />}
      {!loading && alteredProps.length > 0 && <Box>{listView}</Box>}
      {!loading && alteredProps.length === 0 && (
        <EmptyState contentType="policy-properties" />
      )}
    </Box>
  );
}
