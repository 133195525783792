import { createSvgIcon } from "@mui/material";

export const ProviderAzureIcon = createSvgIcon(
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="128" height="128" rx="4" fill="white" />
    <path
      d="M16 88.0016H23.1975V104.802H39.9984V112.003H16V88.0016Z"
      fill="#91B0F2"
    />
    <path
      d="M16 43.998V65.9998L30.0089 80.4147L35.9986 73.9993L54.0007 92.0013L47.9978 97.991L61.9968 111.99H83.9986C85.0594 111.99 86.0768 111.569 86.8269 110.819C87.577 110.068 87.9983 109.051 87.9983 107.99V75.9793L52.0008 39.9983H19.9898C18.9307 40.0009 17.9159 40.4235 17.168 41.1733C16.42 41.9231 16 42.939 16 43.998Z"
      fill="#0F46BD"
    />
    <path
      d="M33.3088 73.7419C32.6546 73.0878 32.2474 72.2271 32.1566 71.3064C32.0657 70.3858 32.2968 69.4621 32.8104 68.6927L65.6135 19.5575C66.3442 18.463 67.3337 17.5657 68.4942 16.9452C69.6548 16.3246 70.9505 16 72.2665 16H108C109.061 16 110.078 16.4214 110.828 17.1715C111.578 17.9216 112 18.9389 112 19.9997V55.7233C112 57.0394 111.675 58.335 111.055 59.4956C110.434 60.6561 109.537 61.6456 108.442 62.3763L59.307 95.1794C58.5376 95.693 57.6139 95.9241 56.6933 95.8333C55.7726 95.7424 54.912 95.3352 54.2578 94.6811L33.3088 73.7419Z"
      fill="#2560E0"
    />
    <path
      d="M35.9487 86.1338L66.4912 55.5913L72.405 61.5051L41.8658 92.0508L35.9487 86.1338Z"
      fill="#0A44C2"
    />
    <path
      d="M35.9487 86.1338L66.4912 55.5913L72.405 61.5051L41.8658 92.0508L35.9487 86.1338Z"
      fill="#729AF2"
    />
    <path
      d="M46.7463 87.1667L40.8325 81.253L66.4941 55.5947L72.4079 61.5084L46.7463 87.1667Z"
      fill="#4C80F0"
    />
    <path
      d="M88.0026 52.0008C90.3758 52.0008 92.6957 51.2971 94.669 49.9786C96.6422 48.6601 98.1802 46.7861 99.0884 44.5935C99.9966 42.401 100.234 39.9883 99.7712 37.6607C99.3082 35.3331 98.1654 33.1951 96.4873 31.517C94.8092 29.8388 92.6711 28.696 90.3435 28.233C88.0159 27.77 85.6033 28.0077 83.4107 28.9159C81.2181 29.824 79.3441 31.362 78.0256 33.3353C76.7072 35.3085 76.0034 37.6284 76.0034 40.0017C76.0034 43.184 77.2676 46.2361 79.5179 48.4863C81.7682 50.7366 84.8202 52.0008 88.0026 52.0008Z"
      fill="#0A44C2"
    />
    <path
      d="M88.0026 52.0008C90.3758 52.0008 92.6957 51.2971 94.669 49.9786C96.6422 48.6601 98.1802 46.7861 99.0884 44.5935C99.9966 42.401 100.234 39.9883 99.7712 37.6607C99.3082 35.3331 98.1654 33.1951 96.4873 31.517C94.8092 29.8388 92.6711 28.696 90.3435 28.233C88.0159 27.77 85.6033 28.0077 83.4107 28.9159C81.2181 29.824 79.3441 31.362 78.0256 33.3353C76.7072 35.3085 76.0034 37.6284 76.0034 40.0017C76.0034 43.184 77.2676 46.2361 79.5179 48.4863C81.7682 50.7366 84.8202 52.0008 88.0026 52.0008Z"
      fill="#91B0F2"
    />
    <g opacity="0.2">
      <path
        d="M30.0089 80.4147L34.8403 75.25L40.8432 81.2529L35.9491 86.1338L41.8662 92.0509L46.7471 87.17L52.7797 93.2125L47.9978 97.9911L61.9968 111.99H83.9986C85.0594 111.99 86.0768 111.569 86.8269 110.819C87.577 110.069 87.9984 109.051 87.9984 107.99V75.9794L108.442 62.3532C109.537 61.6223 110.434 60.6327 111.054 59.4723C111.675 58.3118 112 57.0162 112 55.7002V19.9997C112 18.9389 111.579 17.9216 110.829 17.1715C110.078 16.4214 109.061 16 108 16H72.28C70.964 16.0003 69.6684 16.325 68.5079 16.9456C67.3475 17.5661 66.3579 18.4632 65.627 19.5575L52.0008 39.9984H19.9898C18.9302 40.001 17.9149 40.424 17.1668 41.1745C16.4188 41.925 15.9991 42.9417 16 44.0014V65.9999L30.0089 80.4147Z"
        fill="url(#paint0_linear_5694_57615)"
      />
      <path
        d="M23.1975 88.0016H16V112.003H39.9984V104.802H23.1975V88.0016Z"
        fill="url(#paint1_linear_5694_57615)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_5694_57615"
        x1="64.0323"
        y1="16.024"
        x2="64.0323"
        y2="112.027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5694_57615"
        x1="28.031"
        y1="16.024"
        x2="28.031"
        y2="112.027"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>,
  "Azure",
);
