import { createSvgIcon } from "@mui/material";

export const EmptyStateIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 38" fill="none">
    <g id="Group 431">
      <path
        id="Vector"
        d="M26.7194 21.4798H16.8794V11.6398C16.8794 10.7433 16.5233 9.88362 15.8894 9.24974C15.2555 8.61587 14.3958 8.25977 13.4994 8.25977H3.73937C2.84294 8.25977 1.98323 8.61587 1.34935 9.24974C0.715481 9.88362 0.359375 10.7433 0.359375 11.6398V34.6198C0.359375 35.5162 0.715481 36.3759 1.34935 37.0098C1.98323 37.6437 2.84294 37.9998 3.73937 37.9998H26.7194C27.6158 37.9998 28.4755 37.6437 29.1094 37.0098C29.7433 36.3759 30.0994 35.5162 30.0994 34.6198V24.8598C30.0994 23.9633 29.7433 23.1036 29.1094 22.4697C28.4755 21.8359 27.6158 21.4798 26.7194 21.4798ZM3.73937 10.9998H13.4994C13.6691 10.9998 13.8319 11.0672 13.9519 11.1872C14.0719 11.3072 14.1394 11.47 14.1394 11.6398V21.4798H3.73937C3.5265 21.46 3.31225 21.46 3.09938 21.4798V11.6398C3.09938 11.47 3.1668 11.3072 3.28683 11.1872C3.40685 11.0672 3.56964 10.9998 3.73937 10.9998ZM3.73937 35.2598C3.56964 35.2598 3.40685 35.1923 3.28683 35.0723C3.1668 34.9523 3.09938 34.7895 3.09938 34.6198V24.8598C3.09938 24.69 3.1668 24.5272 3.28683 24.4072C3.40685 24.2872 3.56964 24.2198 3.73937 24.2198H14.1394V34.6198C14.1394 34.7038 14.1228 34.787 14.0907 34.8647C14.0585 34.9423 14.0114 35.0129 13.9519 35.0723C13.8925 35.1317 13.8219 35.1789 13.7443 35.211C13.6666 35.2432 13.5834 35.2598 13.4994 35.2598H3.73937ZM27.3594 34.6198C27.3594 34.7895 27.2919 34.9523 27.1719 35.0723C27.0519 35.1923 26.8891 35.2598 26.7194 35.2598H16.8194C16.84 35.0469 16.84 34.8326 16.8194 34.6198V24.2198H26.6594C26.8291 24.2198 26.9919 24.2872 27.1119 24.4072C27.2319 24.5272 27.2994 24.69 27.2994 24.8598L27.3594 34.6198Z"
        fill="#9147FF"
      />
      <path
        id="Vector_2"
        d="M30.0998 0C28.4662 0 26.8692 0.484441 25.5108 1.39206C24.1525 2.29968 23.0938 3.58972 22.4686 5.09904C21.8434 6.60835 21.6798 8.26916 21.9986 9.87145C22.3173 11.4737 23.104 12.9455 24.2591 14.1007C25.4143 15.2559 26.8861 16.0426 28.4884 16.3613C30.0907 16.68 31.7515 16.5164 33.2608 15.8912C34.7701 15.2661 36.0602 14.2074 36.9678 12.849C37.8754 11.4907 38.3598 9.89367 38.3598 8.26C38.3598 6.06931 37.4896 3.96835 35.9405 2.4193C34.3915 0.870248 32.2905 0 30.0998 0ZM30.0998 13.78C29.0081 13.78 27.9409 13.4563 27.0331 12.8497C26.1253 12.2432 25.4178 11.3811 25 10.3724C24.5822 9.36376 24.4729 8.25388 24.6859 7.1831C24.8989 6.11233 25.4246 5.12876 26.1966 4.35677C26.9686 3.58478 27.9522 3.05906 29.0229 2.84607C30.0937 2.63307 31.2036 2.74239 32.2123 3.16018C33.2209 3.57798 34.083 4.28549 34.6896 5.19325C35.2961 6.10101 35.6198 7.16825 35.6198 8.26C35.6146 9.72237 35.0313 11.1233 33.9973 12.1574C32.9632 13.1915 31.5622 13.7747 30.0998 13.78Z"
        fill="#9147FF"
      />
    </g>
  </svg>,
  "Nothing to show",
);
