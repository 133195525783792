import { Box, DialogContent, DialogActions, Button, Link } from "@mui/material";
import { DialogContainer, TitleArea, Command, InfoText } from "../components";
import { Code } from "~/components/code";
import { ChevronLeftIcon, ChevronRightIcon } from "~/components/icons";
import { useToken } from "~/providers/token";

type Props = {
  onClose: any;
  nav: {
    next: () => void;
    prev: () => void;
  };
};

export const StepFour = ({ nav, onClose }: Props) => {
  const { token } = useToken();
  const source = `bash -c "$(curl -sSL https://install.mondoo.com/sh)"\ncnspec login -t '${token?.value}'`;

  return (
    <DialogContainer>
      <TitleArea title="AWS Setup" onClose={onClose} />

      <DialogContent sx={{ p: 0, mt: 4 }}>
        <Box display="flex" flexDirection="column" height={1}>
          <Box>
            <Command
              number={3}
              tooltip={
                <Link
                  href="https://mondoo.com/docs/cnspec/cnspec-aws/cnspec-aws-cloudshell/#step-3-install-cnspec-in-cloudshell-and-register-with-mondoo-platform"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo Docs - Assess AWS Security from AWS CloudShell
                </Link>
              }
            >
              Install cnspec
            </Command>
          </Box>
          <Box
            display="flex"
            flexDirection={"column"}
            flex="1"
            justifyContent="center"
          >
            <Box sx={{ minWidth: "0" }}>
              <Code className="bash" copyButton>
                {source}
              </Code>
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <InfoText text="Once the terminal in your AWS CloudShell window has connected, paste the command above into your CloudShell prompt and execute it." />

      <DialogActions sx={{ p: 0, pt: 0, justifyContent: "space-between" }}>
        <Button
          onClick={nav.prev}
          variant="outlined"
          color="secondary"
          startIcon={<ChevronLeftIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Back
        </Button>
        <Button
          onClick={nav.next}
          variant="contained"
          color="secondary"
          endIcon={<ChevronRightIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Continue
        </Button>
      </DialogActions>
    </DialogContainer>
  );
};
