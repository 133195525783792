import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { motion, Variants } from "framer-motion";
import { FieldValues } from "react-hook-form";
import { ReactNode } from "react";

type OperatorListProps<T extends FieldValues> = {
  configuration: string;
  caption: string;
  field: ReactNode;
  index?: number;
  disabled?: boolean;
};

const operatorListVariants: Variants = {
  closed: {
    opacity: 0,
  },
  open: {
    opacity: 1,
  },
};

export function SubField<T extends FieldValues>({
  configuration,
  caption,
  field,
  index = 0,
  disabled = false,
}: OperatorListProps<T>) {
  const theme = useTheme();

  return (
    <Grid
      container
      component={motion.div}
      variants={operatorListVariants}
      sx={{ pl: 3 }}
    >
      <Grid item container xs={12} md={6} sx={{ opacity: disabled ? 0.4 : 1 }}>
        <Grid item container xs={12}>
          <Grid item>
            <Box sx={{ width: 50, height: 50, mr: 1 }}>
              <MotionPath {...{ index }} />
            </Box>
          </Grid>
          <Grid
            item
            xs
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <Typography sx={{ display: "inline-block", mb: -0.25 }}>
              {configuration}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid sx={{ width: 50, mr: 1 }}>
            {/* intentionally left blank */}
          </Grid>
          <Grid item xs>
            <Typography
              sx={{ fontSize: (theme) => theme.spacing(1.5) }}
              color="text.secondary"
            >
              {caption}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {field}
    </Grid>
  );
}

// Elbow Shaped self drawing path
type MotionPathProps = {
  index: number;
};

const MotionPath = ({ index }: MotionPathProps) => {
  const MotionSvg = styled(motion.svg)`
    fill: none;
    stroke: ${(p) => p.theme.palette.text.secondary};
  `;

  const pathVariants = {
    closed: {
      opacity: 0,
      pathLength: 0,
    },
    open: {
      opacity: 1,
      pathLength: 1,
      transition: {
        delay: index * 0.1,
        duration: 0.35,
        ease: "easeIn",
      },
    },
  };

  return (
    <MotionSvg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <motion.path
        d="M 15 10 L 15 20 L 23 20"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        variants={pathVariants}
        initial="closed"
        animate="open"
      />
    </MotionSvg>
  );
};
