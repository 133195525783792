import { useRemoveExceptionTextVariant } from "~/components/exceptions/useRemoveExceptionTextVariant";
import { ExceptionGroup } from "~/operations";
import { ExceptionTarget, SelectedEntity } from "~/components/exceptions/types";
import { Grid, Typography } from "@mui/material";
import { targetLabelMap } from "~/components/exceptions/constants";

type RemoveExceptionTextProps = {
  exceptionGroups: ExceptionGroup[];
  selectedEntities: SelectedEntity[];
  target: ExceptionTarget;
};

export const RemoveExceptionText = (props: RemoveExceptionTextProps) => {
  const { exceptionGroups, selectedEntities, target } = props;

  const checksContainingExceptions = selectedEntities.filter(
    (check) => check?.exception,
  );

  if (!checksContainingExceptions.length) return <></>;

  const actionLabelMap = {
    SNOOZE: "snoozed",
    DISABLE: "disabled",
    ENABLE: "enabled",
    OUT_OF_SCOPE: "out of scope",
  };

  const variant = useRemoveExceptionTextVariant({
    exceptionGroups,
    selectedEntities,
  });

  const targetGroup = exceptionGroups.find(
    (group) => group.id === checksContainingExceptions[0].groupId,
  );

  const genericText = `This will remove ${
    checksContainingExceptions.length
  } exception${checksContainingExceptions.length > 1 ? "s" : ""}. Are you
        sure you want to remove the exceptions and enable the 
        ${targetLabelMap[target]}s?`;

  if (!targetGroup)
    return (
      <Grid item xs={12}>
        <Typography>{genericText}</Typography>
      </Grid>
    );

  const remainingExceptionsCount =
    targetGroup.exceptions.length - checksContainingExceptions.length;

  const targetGroupActionLabel = actionLabelMap[targetGroup.action];

  switch (variant) {
    case "SeparateGroups":
      return (
        <>
          <>
            <Grid item xs={12}>
              <Typography>
                These {checksContainingExceptions.length}{" "}
                {targetLabelMap[target]}s are part of multiple exceptions
                containing other {targetLabelMap[target]}
                s. Are you sure you want to enable the selected{" "}
                {targetLabelMap[target]}s? This will remove them from the
                exceptions. Other {targetLabelMap[target]}s in the exceptions
                will remain disabled/snoozed.
              </Typography>
            </Grid>
          </>
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to remove the exceptions and enable the{" "}
              {targetLabelMap[target]}s?
            </Typography>
          </Grid>
        </>
      );
    case "GroupSubset":
      return (
        <>
          {checksContainingExceptions.length === 1 ? (
            <>
              <>
                <Grid item xs={12}>
                  <Typography>
                    This {targetLabelMap[target]} is part of an exception
                    containing {remainingExceptionsCount} other{" "}
                    {targetLabelMap[target]}
                    {remainingExceptionsCount > 1 && "s"}.
                  </Typography>
                </Grid>
              </>
              {checksContainingExceptions[0]?.exception?.justification && (
                <>
                  <Grid item xs={12}>
                    <Typography>
                      It was {targetGroupActionLabel} for this reason: “
                      {checksContainingExceptions[0]?.exception?.justification}”
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <Typography>
                  Are you sure you want to enable the {targetLabelMap[target]}?
                  This will delete this exception.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <>
                <Grid item xs={12}>
                  <Typography>
                    These {checksContainingExceptions.length}{" "}
                    {targetLabelMap[target]}s are part of an exception
                    containing {remainingExceptionsCount} other{" "}
                    {targetLabelMap[target]}
                    {remainingExceptionsCount > 1 && "s"}.
                  </Typography>
                </Grid>
              </>
              <Grid item xs={12}>
                <Typography>
                  Are you sure you want to enable the {targetLabelMap[target]}s?
                  This will delete this exception.
                </Typography>
              </Grid>
            </>
          )}
        </>
      );
    case "SingleCheck":
      return (
        <>
          {checksContainingExceptions[0]?.exception?.justification && (
            <>
              <Grid item xs={12}>
                <Typography>
                  This {targetLabelMap[target]} has been{" "}
                  {targetGroupActionLabel} for this reason:
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  “{checksContainingExceptions[0]?.exception?.justification}”
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Typography>
              Are you sure you want to enable the {targetLabelMap[target]}? This
              will delete this exception.
            </Typography>
          </Grid>
        </>
      );
    case "Generic":
    default:
      return (
        <Grid item xs={12}>
          <Typography>{genericText}</Typography>
        </Grid>
      );
  }
};
