import { createSvgIcon } from "@mui/material";

export const ESDomainsIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_14085)" />
    <svg
      width="25"
      height="31"
      viewBox="0 0 25 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="7.5"
      y="4.5"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 23.9057V13.2893L1.58118 13.1387L2.33412 13.1763L2.22118 24.5457L1.58118 24.6963L0 23.9057Z"
        fill="#C3C3C3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.3665 13.2893L1.54297 13.1387V24.6963L4.3665 24.0187V13.2893Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.05078 25.4496V8.84728L5.72372 8.01904L7.45549 8.35787V26.2779L5.72372 26.8426L3.05078 25.4496Z"
        fill="#C3C3C3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.3156 8.92257L5.72266 8.01904V26.8426L10.3156 25.3367V8.92257Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.5675 8.01904V22.0614L20.4805 21.572V8.58375L23.5675 8.01904Z"
        fill="#C3C3C3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5187 9.63749L22.8905 9.29867V7.19043L13.9681 3.83984L13.3281 7.79279C13.3281 7.83043 20.5187 9.63749 20.5187 9.63749Z"
        fill="#A7A7A7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.5188 20.48L22.8906 20.8188V22.9647L15.0976 25.6753L12.5 22.5506L20.5188 20.48Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.86719 2.22118L12.2342 0L15.6601 1.73176L14.7942 28.8L12.2342 30.1176L7.86719 27.8965V2.22118Z"
        fill="#C3C3C3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.4697 6.24941V23.8682L12.2344 30.1176V0L24.4697 6.24941ZM22.8885 9.33647L15.8861 7.22824V23.0024L22.8885 20.8565V9.33647Z"
        fill="white"
      />
    </svg>
    <defs>
      <linearGradient
        id="paint0_linear_1045_14085"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "ES Domains",
);
