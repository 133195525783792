import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { motion } from "framer-motion";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Wave } from "~/components/galaxy/wave";
import { Stars } from "~/components/galaxy/stars";
import { IoOnTheMoon } from "~/components/galaxy/io-on-the-moon";
import { Jupiter } from "~/components/galaxy/jupiter";
import { Saturn } from "~/components/galaxy/saturn";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { useColorMode } from "~/providers/color-mode";
import { SaturnLight } from "~/components/galaxy/saturn-light";
import { Rocket } from "~/components/galaxy/rocket";
import { RocketPath } from "~/components/galaxy/RocketPath";

type Options = {
  id: string; // ID is used for tracking purposes and should align with the page where vast is being used
  headline: string;
  tagline: string;
  buttonText: string;
  href: string;
  buttonText2: string;
  hrefButton2: string;
};

type Props = {
  options: Options;
};

export function VastEmptyness({ options }: Props) {
  const navDrawer = useAppNavDrawer();
  const { mode } = useColorMode();
  return (
    <Box
      id="vast-emptyness"
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        background: `${
          mode === "light"
            ? "linear-gradient(180deg, rgba(119, 66, 214, 0) 13.02%, #7A40D1 100%);"
            : "linear-gradient(180deg, rgba(39, 25, 77, 0) 0%, #27194D 100%)"
        }`,
        transition: (theme) =>
          theme.transitions.create("left", navDrawer.transition),
        height: {
          xs: `calc(100vh - ${navDrawer.navbarHeight}px)`,
        },
        top: {
          xs: navDrawer.navbarHeight,
        },
        left: navDrawer.width,
        right: 0,
        overflow: "hidden",
      }}
    >
      <Grid container height={1}>
        {/* I/O */}
        <Grid
          item
          xs={1}
          sm={2.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <Floater>
            <Box
              sx={{
                display: "block",
                transform: {
                  xs: "translate3d(70%,-75%,0)",
                  sm: "translate3d(20%,-50%,0)",
                  md: "translate3d(30%,-10%,0)",
                  lg: "translate3d(40%,-10%,0)",
                },
              }}
            >
              <IoOnTheMoon
                sx={{
                  width: {
                    xs: 132,
                    sm: 200,
                    md: 292,
                  },
                }}
              />
            </Box>
          </Floater>
        </Grid>
        {/* Text */}
        <Grid
          item
          xs={10}
          sm={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: {
              sm: "center",
            },
            mt: {
              sm: "-30%",
              md: "-15%",
            },
            pt: {
              xs: 16,
              sm: 0,
            },
            zIndex: 1,
          }}
        >
          <Box textAlign="center">
            <Typography
              variant="h1"
              sx={{
                background: (theme) => theme.palette.primary.gradient,
                backgroundClip: "text",
                WebkitTextFillColor: "transparent",
                fontWeight: 900,
                fontSize: { xs: 32, sm: 40 },
                lineHeight: 1.2,
                textTransform: "uppercase",
              }}
            >
              {options.headline}
            </Typography>
            <Typography
              sx={{
                pt: 2,
                fontSize: { xs: 16, sm: 24 },
                lineHeight: 1.5,
                maxWidth: 460,
                textAlign: "center",
              }}
            >
              {options.tagline}
            </Typography>
          </Box>

          <Box
            sx={{
              mt: 2.5,
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <Button
              component={RouterLink}
              to={options.href}
              variant="contained"
              id={`${options.id}-vast-emptyness-button-1`}
              sx={{
                py: 1.5,
                background: (theme) => theme.palette.primary.gradient,
                whiteSpace: "nowrap",
              }}
            >
              {options.buttonText}
            </Button>
            <Button
              component={RouterLink}
              to={options.hrefButton2}
              variant="outlined"
              color="secondary"
              id={`${options.id}-vast-emptyness-button-2`}
              sx={{
                py: 1.5,
                whiteSpace: "nowrap",
              }}
            >
              {options.buttonText2}
            </Button>
          </Box>
          <Box display="flex" position="relative">
            <RocketPath />
            <Typography
              sx={{
                position: "absolute",
                right: "-45px",
                top: "-55px",
                fontSize: "48px",
              }}
            >
              🚀
            </Typography>
          </Box>
        </Grid>
        {/* Saturn and Jupiter */}
        <Grid
          item
          xs={1}
          sm={2.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-around",
          }}
        >
          <Floater delay={2}>
            <Box
              sx={{
                width: {
                  xs: 245,
                },
                transform: {
                  xs: "translate3d(-40%,-100%,0)",
                  md: "translate3d(-20%,-50%,0)",
                  lg: "translate3d(-20%,-50%,0)",
                },
              }}
            >
              {mode === "light" ? <SaturnLight /> : <Saturn />}
            </Box>
          </Floater>
          <Floater delay={4}>
            <Box
              sx={{
                position: "relative",
                width: {
                  xs: 135,
                  sm: 168,
                },
                transform: {
                  xs: "translate3d(-100%,0,0)",
                  md: "translate3d(-70%,0,0)",
                  lg: "translate3d(-120%,-60%,0)",
                },
              }}
            >
              <Jupiter />
            </Box>
          </Floater>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: 1,
          overflow: "hidden",
        }}
      >
        <Stars />
      </Box>

      <Wave
        color={mode === "light" ? "black" : "white"}
        opacity={mode === "light" ? "0.09" : "0.02"}
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: {
            xs: "80%",
            md: "60%",
          },
        }}
      />
    </Box>
  );
}

type FloaterProps = {
  children: ReactNode;
  delay?: number;
};

const Floater = ({ children, delay }: FloaterProps) => {
  return (
    <motion.div
      animate={{ y: ["5px", "2px", "5px", "0px"] }}
      transition={{
        y: {
          duration: 5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
          delay: delay ? delay : 0,
        },
      }}
    >
      {children}
    </motion.div>
  );
};
