import { useGetAssetSupportVulnerabilitiesQuery } from "~/operations";

type useGetSupportVulnerabilitiesType = {
  assetMrn: string;
};

export function useGetSupportVulnerabilities({
  assetMrn,
}: useGetSupportVulnerabilitiesType) {
  const { data } = useGetAssetSupportVulnerabilitiesQuery({
    variables: {
      assetMrn,
    },
  });

  return { supported: data?.assetSupportVulnerabilities };
}
