import { createSvgIcon } from "@mui/material";

export const AssetStarIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M17.208 18.791C17.1244 18.7902 17.0421 18.7696 16.968 18.731L12.348 16.301L7.71797 18.731C7.63811 18.7796 7.54644 18.8052 7.45297 18.8052C7.3595 18.8052 7.26783 18.7796 7.18797 18.731C7.11462 18.674 7.05753 18.5987 7.02243 18.5127C6.98733 18.4267 6.97544 18.333 6.98797 18.241L7.87797 13.081L4.12797 9.41099C4.06204 9.34369 4.01524 9.26003 3.99239 9.16863C3.96954 9.07723 3.97146 8.98139 3.99797 8.89099C4.02657 8.8028 4.07912 8.72431 4.14976 8.66427C4.22039 8.60423 4.30633 8.56501 4.39797 8.55099L9.58797 7.80099L11.898 3.11099C11.9437 3.03209 12.0093 2.9666 12.0883 2.92106C12.1672 2.87553 12.2568 2.85156 12.348 2.85156C12.4391 2.85156 12.5287 2.87553 12.6077 2.92106C12.6867 2.9666 12.7523 3.03209 12.798 3.11099L15.108 7.80099L20.278 8.55099C20.371 8.56437 20.4585 8.60321 20.5309 8.66321C20.6032 8.72321 20.6576 8.80204 20.688 8.89099C20.7145 8.98139 20.7164 9.07723 20.6935 9.16863C20.6707 9.26003 20.6239 9.34369 20.558 9.41099L16.818 13.051L17.698 18.211C17.7105 18.303 17.6986 18.3967 17.6635 18.4827C17.6284 18.5687 17.5713 18.644 17.498 18.701C17.4124 18.7592 17.3114 18.7905 17.208 18.791ZM12.348 15.241C12.4239 15.2259 12.502 15.2259 12.578 15.241L16.578 17.331L15.788 13.001C15.7733 12.9218 15.7785 12.8402 15.8029 12.7635C15.8273 12.6867 15.8703 12.6172 15.928 12.561L19.138 9.40099L14.698 8.75099C14.6184 8.74127 14.5426 8.71137 14.4779 8.66411C14.4131 8.61685 14.3615 8.55381 14.328 8.48099L12.328 4.48099L10.328 8.48099C10.2913 8.55347 10.2376 8.61594 10.1714 8.66298C10.1051 8.71002 10.0285 8.74023 9.94797 8.75099L5.55797 9.40099L8.75797 12.521C8.81744 12.5762 8.86229 12.6453 8.88847 12.7221C8.91465 12.7989 8.92135 12.881 8.90797 12.961L8.14797 17.381L12.148 15.291C12.2118 15.2644 12.2792 15.2475 12.348 15.241Z" />
    <path d="M12.3516 21.5489C12.219 21.5489 12.0918 21.4962 11.998 21.4025C11.9042 21.3087 11.8516 21.1815 11.8516 21.0489V18.3789C11.8516 18.2463 11.9042 18.1191 11.998 18.0254C12.0918 17.9316 12.219 17.8789 12.3516 17.8789C12.4834 17.8815 12.6091 17.935 12.7023 18.0282C12.7955 18.1214 12.849 18.2471 12.8516 18.3789V21.0489C12.8516 21.1815 12.7989 21.3087 12.7051 21.4025C12.6113 21.4962 12.4842 21.5489 12.3516 21.5489Z" />
    <path d="M3.32488 14.8091C3.19227 14.8276 3.05772 14.7927 2.95082 14.7121C2.84393 14.6315 2.77344 14.5117 2.75488 14.3791C2.73631 14.2464 2.77119 14.1119 2.85183 14.005C2.93247 13.8981 3.05227 13.8276 3.18488 13.8091L5.74488 13.0491C5.86198 13.0381 5.9792 13.0688 6.07593 13.1357C6.17266 13.2026 6.2427 13.3014 6.27375 13.4149C6.3048 13.5283 6.29486 13.6491 6.24569 13.7559C6.19651 13.8628 6.11124 13.9489 6.00488 13.9991L3.43488 14.7591L3.32488 14.8091Z" />
    <path d="M8.41615 6.67005C8.33416 6.67301 8.25274 6.65533 8.17935 6.61864C8.10597 6.58195 8.04298 6.52742 7.99615 6.46005L6.45615 4.29005C6.41107 4.2374 6.37742 4.17596 6.35736 4.10962C6.33729 4.04327 6.33123 3.97348 6.33957 3.90468C6.34791 3.83587 6.37047 3.76955 6.4058 3.70992C6.44114 3.6503 6.48849 3.59867 6.54484 3.55832C6.6012 3.51796 6.66532 3.48977 6.73316 3.47552C6.80099 3.46127 6.87104 3.46128 6.93886 3.47555C7.00669 3.48982 7.07081 3.51803 7.12715 3.5584C7.18349 3.59877 7.23083 3.65041 7.26615 3.71005L8.82615 5.88005C8.86451 5.93379 8.89186 5.9946 8.9066 6.05896C8.92134 6.12333 8.92318 6.18998 8.91202 6.25506C8.90087 6.32014 8.87693 6.38237 8.84159 6.43815C8.80626 6.49393 8.76022 6.54216 8.70615 6.58005C8.62059 6.63823 8.51961 6.66957 8.41615 6.67005Z" />
    <path d="M16.2695 6.66829C16.1729 6.66398 16.0794 6.63282 15.9995 6.57829C15.894 6.49862 15.8236 6.3811 15.8031 6.25051C15.7825 6.11993 15.8135 5.98648 15.8895 5.87829L17.4395 3.70829C17.5164 3.59955 17.6334 3.52582 17.7646 3.50332C17.8959 3.48081 18.0307 3.51138 18.1395 3.58829C18.2482 3.6652 18.3219 3.78216 18.3445 3.91344C18.367 4.04471 18.3364 4.17955 18.2595 4.28829L16.6795 6.45829C16.6336 6.52422 16.5722 6.57786 16.5007 6.61448C16.4292 6.65109 16.3498 6.66958 16.2695 6.66829Z" />
    <path d="M21.3171 14.9999C21.2675 15.0097 21.2166 15.0097 21.1671 14.9999L18.6271 14.1999C18.5638 14.1806 18.505 14.1489 18.4541 14.1066C18.4032 14.0644 18.3612 14.0124 18.3305 13.9539C18.2998 13.8953 18.2811 13.8312 18.2753 13.7653C18.2696 13.6994 18.277 13.633 18.2971 13.5699C18.3163 13.5068 18.3481 13.4482 18.3905 13.3977C18.4329 13.3471 18.4851 13.3057 18.5439 13.2758C18.6028 13.2459 18.667 13.2282 18.7329 13.2238C18.7987 13.2193 18.8648 13.2282 18.9271 13.2499L21.4671 14.0499C21.5997 14.0698 21.719 14.1416 21.7987 14.2494C21.8784 14.3573 21.912 14.4923 21.8921 14.6249C21.8722 14.7576 21.8004 14.8768 21.6926 14.9565C21.5848 15.0362 21.4497 15.0698 21.3171 15.0499V14.9999Z" />
  </svg>,
  "Asset Star",
);
