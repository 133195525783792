import React from "react";
import { createSvgIcon } from "@mui/material";

export const ApplicationIcon = createSvgIcon(
  <svg
    viewBox="0 0 37 30"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M33.8795 0.707031H2.1704C0.952861 0.707031 0 1.71283 0 2.87744V27.1224C0 28.34 1.0058 29.2928 2.1704 29.2928H33.8795C35.097 29.2928 36.0499 28.2871 36.0499 27.1224V2.87744C36.1028 1.71283 35.097 0.707031 33.8795 0.707031ZM34.5147 27.1754C34.5147 27.493 34.25 27.7577 33.9324 27.7577H2.1704C1.85278 27.7577 1.5881 27.493 1.5881 27.1754V8.22404H34.5147V27.1754ZM34.5147 6.63594H1.53516V2.93037C1.53516 2.61275 1.79985 2.34807 2.11747 2.34807H33.8265C34.1442 2.34807 34.4089 2.61275 34.4089 2.93037V6.63594H34.5147Z"
      fill="white"
    />
    <path
      d="M6.8773 5.57881C7.49125 5.57881 7.98897 5.0811 7.98897 4.46714C7.98897 3.85318 7.49125 3.35547 6.8773 3.35547C6.26334 3.35547 5.76562 3.85318 5.76562 4.46714C5.76562 5.0811 6.26334 5.57881 6.8773 5.57881Z"
      fill="white"
    />
    <path
      d="M3.76011 5.57881C4.37407 5.57881 4.87178 5.0811 4.87178 4.46714C4.87178 3.85318 4.37407 3.35547 3.76011 3.35547C3.14615 3.35547 2.64844 3.85318 2.64844 4.46714C2.64844 5.0811 3.14615 5.57881 3.76011 5.57881Z"
      fill="white"
    />
    <path
      d="M9.95542 5.57881C10.5694 5.57881 11.0671 5.0811 11.0671 4.46714C11.0671 3.85318 10.5694 3.35547 9.95542 3.35547C9.34146 3.35547 8.84375 3.85318 8.84375 4.46714C8.84375 5.0811 9.34146 5.57881 9.95542 5.57881Z"
      fill="white"
    />
  </svg>,
  "Application",
);
