import { Box, BoxProps } from "@mui/material";
import { useViewport } from "~/hooks/useViewport";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";

export type StretcherProps = {
  children?: BoxProps["children"];
  sx?: BoxProps["sx"];
};

export function Stretcher({ children, sx }: StretcherProps) {
  const navDrawer = useAppNavDrawer();
  const viewport = useViewport();
  return (
    <Box
      className="stretcher"
      sx={[
        {
          width: "100vw",
          mx: `calc(((-100vw + 100%) - ${navDrawer.width}) / 2)`,
          pl: navDrawer.width,
          transition: (theme) =>
            // transition smoothly with the navDrawer but
            // prevent bouncing while viewport is being resized
            viewport.isResizing
              ? "none"
              : theme.transitions.create(
                  ["margin-left", "margin-right", "padding-left"],
                  navDrawer.transition,
                ),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
}
