import { createSvgIcon } from "@mui/material";

export const FolderOpenFilled = createSvgIcon(
  <svg viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.332 3.99984H16.9987L13.6654 0.666504H3.66536C1.83203 0.666504 0.348698 2.1665 0.348698 3.99984L0.332031 23.9998C0.332031 25.8332 1.83203 27.3332 3.66536 27.3332H30.332C32.1654 27.3332 33.6654 25.8332 33.6654 23.9998V7.33317C33.6654 5.49984 32.1654 3.99984 30.332 3.99984ZM30.332 23.9998H3.66536V7.33317H30.332V23.9998Z"
      fill="url(#paint0_linear_7556_24815)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7556_24815"
        x1="0.332031"
        y1="0.666504"
        x2="27.2891"
        y2="32.6991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E45E2" />
        <stop offset="1" stopColor="#A22E95" />
      </linearGradient>
    </defs>
  </svg>,
  "FolderOpenFilled",
);
