import { Box, styled } from "@mui/material";

export const DialogContainer = styled(Box)`
  padding: ${(p) => p.theme.spacing(3)};
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${(p) => p.theme.palette.background.lighter};
  border: 1px solid ${(p) => p.theme.palette.background.default};
  height: 610px;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(p) => p.theme.spacing(5)};
    width: 784px;
  }
`;
