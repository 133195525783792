import { ReactElement, ReactNode } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  GridProps,
  Paper,
  Typography,
} from "@mui/material";

export type HubCardSizes = {
  xs: "auto" | number | boolean;
  sm?: "auto" | number | boolean;
  md?: "auto" | number | boolean;
  lg?: "auto" | number | boolean;
  xl?: "auto" | number | boolean;
};

type HubCardProps = GridProps & {
  loading: boolean;
  sizes?: HubCardSizes;
  title: string;
  icon: ReactNode;
  children?: ReactElement | null;
  tagline?: string;
  onNavigate?: () => void;
};

export const HubCard = ({
  loading,
  sizes,
  title,
  icon,
  children,
  tagline,
  onNavigate,
  ...restProps
}: HubCardProps) => {
  return (
    <Grid
      container
      item
      {...sizes}
      onClick={() => {
        onNavigate?.();
      }}
      style={{
        position: "relative",
        ...(onNavigate ? { cursor: "pointer" } : {}),
      }}
      {...restProps}
    >
      <Paper sx={{ p: 3, minWidth: 0, width: 1 }}>
        <Grid container item>
          <Grid item xs>
            <Typography
              variant="h5"
              fontWeight={700}
              sx={{ textTransform: "uppercase", mb: -0.5 }}
            >
              {title}
            </Typography>
            {tagline && (
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                {tagline}
              </Typography>
            )}
          </Grid>
          <Grid item sx={{ p: 0.5 }}>
            {icon}
          </Grid>
        </Grid>
        <Grid item xs>
          {loading ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 4,
              }}
            >
              <CircularProgress size={28} />
            </Box>
          ) : (
            children
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

export default HubCard;
