import { Fragment, ReactNode } from "react";
import { Link, Typography } from "@mui/material";
import { FieldError } from "react-hook-form";
import { isIP } from "is-ip";
import { WarningAmberIcon } from "~/components/icons";
import isCidr from "is-cidr";

export const ID_PLACEHOLDER = "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX";
export const MATCHING_ID_ERROR_MESSAGE =
  "Your Application (client) ID cannot be the same as your Directory (Tenant) ID. Please read the Microsoft documentation to find the correct values for each.";
// validation regex came from https://learn.microsoft.com/en-us/answers/questions/802673/guid-regex
export const GUID_PATTERN = new RegExp(
  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
);

// RFC 5322
export const EMAIL_PATTERN = new RegExp(
  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
);

// https://www.oreilly.com/library/view/regular-expressions-cookbook/9781449327453/ch08s15.html
export const DOMAIN_PATTERN = new RegExp(
  /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/i,
);

export function isIpOrDomain(address: string) {
  return isIP(address) || DOMAIN_PATTERN.test(address);
}
// validate if the input is either an IP, an IP range, a domain, or empty using isCIDR
export function isIpOrDomainDomainRangeOrEmpty(address: string) {
  return (
    isIpOrDomain(address) ||
    isCidr.v4(address) ||
    isCidr.v6(address) ||
    address === ""
  );
}

export function isUrl(url: string, errorMessage: string) {
  try {
    return Boolean(new URL(url));
  } catch {
    return errorMessage;
  }
}

// Specific to Github Enterprise Server URL on GitHub integrations
export function isNotGithubUrl(url: string) {
  try {
    return !new URL(url).hostname.includes("github.com");
  } catch {}
}

// Extension to domain with optional https:// or http:// schema
export const URL_PATTERN = new RegExp(
  /^((http|https):\/\/)*([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/,
);

// Validate pattern with only https://<at least one char> schema -> .com/.net etc not required
export const SECURE_HOST_URL_PATTERN = new RegExp(
  /^https:\/\/.*[a-zA-Z0-9].*$/,
);

export const JIRA_HOST_PATTERN = new RegExp(
  /^((http|https):\/\/)*([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}\/?$/,
);

//GCP
export const GCP_ID_PATTERN = new RegExp(/^[-!' a-z0-9]{4,30}$/g);

export const GCP_ID_ERROR_MESSAGE =
  "A project or organization ID can contain only letters, numbers, or hyphens.";

// Github Issues Personal Access token uses fine grained scanning or classic
//^(ghp_[a-zA-Z0-9]{36}|github_pat_[a-zA-Z0-9]{22}_[a-zA-Z0-9]{59})$
// found at https://gist.github.com/magnetikonline/073afe7909ffdd6f10ef06a00bc3bc88
export const GITHUB_ISSUES_PERSONAL_ACCESS_TOKEN_PATTERN = new RegExp(
  /^(ghp_[a-zA-Z0-9]{36}|github_pat_[a-zA-Z0-9]{22}_[a-zA-Z0-9]{59})$/,
);
export const GITHUB_ISSUES_PERSONAL_ACCESS_TOKEN_ERROR_MESSAGE =
  "Not a valid format for a GitHub personal access token (classic or fine-grained)";

export const AWS_ACCESS_KEY_PATTERN = /((?:ASIA|AKIA|AROA|AIDA)([A-Z0-7]{16}))/;
export const AWS_ACCESS_KEY_ERROR_MESSAGE = "Not in valid Access Key ID format";
export const AWS_SECRET_KEY_PATTERN = /([a-zA-Z0-9+/]{40})/;
export const AWS_SECRET_KEY_ERROR_MESSAGE =
  "Not in valid Secret Access Key format";

export const helperTextStyles = {
  ".MuiFormHelperText-root": {
    m: 0,
    pt: 0.75,
    px: 1,
    pl: 0,
    backgroundColor: "background.default",
  },
};

export const ValidationMessage = ({
  error,
  integrationTypeId,
}: {
  error: FieldError | undefined;
  integrationTypeId?: string;
}) => {
  const parseErrors = (error: FieldError | undefined) => {
    switch (error?.type) {
      case "required":
        return error?.message || "Required";
      case "pattern":
        // TODO:: We might want to remove business logic from here and move it to the consumers (form fields)
        if (integrationTypeId === "gcp") {
          return GCP_ID_ERROR_MESSAGE;
        }
        if (integrationTypeId === "azure_blob") {
          return "Not in valid https URL format";
        }
        if (integrationTypeId === "aws-serverless") {
          return "Must be a number in hour format";
        }

        return error?.message || "Not in valid GUID format";
      case "matchIpOrDomain":
        return "Not in valid URL or IP format";

      case "isNotGithubUrl":
        return (
          <Typography variant="body2" sx={{ display: "inline" }}>
            Please enter a valid GitHub Enterprise Server URL or leave this
            field empty. To learn more, read the{" "}
            <Link
              target="_blank"
              rel="noopener"
              sx={{ color: "error.main" }}
              href="https://mondoo.com/docs/platform/infra/saas/github/#set-up-a-github-integration"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
        );
      default:
        return error?.message || "Error";
    }
  };
  return <HelperText>{parseErrors(error)}</HelperText>;
};

export const HelperText = ({ children }: { children: ReactNode }) => {
  return (
    <Fragment>
      <WarningAmberIcon
        color="error"
        sx={{
          mr: 0.5,
          fontSize: 14,
          verticalAlign: "text-bottom",
        }}
      />
      <Typography variant="body2" component="span" color="error">
        {children}
      </Typography>
    </Fragment>
  );
};
