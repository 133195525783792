import { ReactNode } from "react";
import {
  SxProps,
  Table,
  TableCell,
  TableCellProps,
  TableProps,
  Theme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export type DashboardTableProps = {
  children?: ReactNode;
  sx?: SxProps<Theme>;
} & TableProps;

export function DashboardTable({
  sx,
  children,
  ...props
}: DashboardTableProps) {
  const tableSx: SxProps<Theme> = [
    { tableLayout: "auto", height: "1px" },
    {
      ".MuiTableCell-root": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 0,
        fontSize: "14px",
        py: 1.5,
        "&:first-of-type": { pl: 0 },
        "&:last-of-type": {
          pr: 0,
          textAlign: "right",
          a: {
            justifyContent: "flex-end",
          },
        },
        "&.clickable": {
          p: 0,

          a: {
            display: "flex",
            height: "100%",
            py: 1.5,
            alignItems: "center",
          },
        },
      },
    },
    {
      ".MuiTableCell-head": {
        fontSize: "10px",
        fontWeight: "normal",
        textTransform: "uppercase",
        lineHeight: 1,
        color: "text.secondary",
        pt: 0,
        pb: 0.5,
        backgroundColor: "background.paper",
        borderBottom: "none",
      },
    },
    {
      ".MuiTableCell-body": {
        borderBottom: "1px solid",
        borderColor: "divider",
      },
    },
    { ".MuiTableHead-root": { background: "none", boxShadow: "none" } },
  ];

  return (
    <Table
      sx={[...(Array.isArray(sx) ? sx : [sx]), ...tableSx]}
      stickyHeader
      {...props}
    >
      {children}
    </Table>
  );
}

export type ClickableTableCellProps = { href?: string } & TableCellProps;

export function ClickableTableCell({
  href,
  children,
  className = "",
  ...rest
}: ClickableTableCellProps) {
  return (
    <TableCell className={className + " clickable"} {...rest}>
      {href ? <RouterLink to={href}>{children}</RouterLink> : <>{children}</>}
    </TableCell>
  );
}
