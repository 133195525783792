import { TableCell } from "@mui/material";
import { CommonCellProps } from "~/pages/inventory/components/Vulnerabilities/types";
import { Impact } from "~/components/impact";

export const ImpactCell = ({ cve, ...props }: CommonCellProps) => {
  return (
    <TableCell width="10%" data-testid={props["data-testid"]}>
      <Impact impact={cve.riskScore || 0} isRiskScore />
    </TableCell>
  );
};
