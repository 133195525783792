import { ReactNode } from "react";
import { Box, alpha, useTheme } from "@mui/material";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { useColorMode } from "~/providers/color-mode";

export type SelectionToolbarProps = {
  children: ReactNode;
};

export function SelectionToolbar({ children }: SelectionToolbarProps) {
  const navDrawer = useAppNavDrawer();
  const theme = useTheme();
  const { mode } = useColorMode();

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: (theme) => theme.spacing(7),
        right: (theme) => theme.spacing(3),
        left: `calc(${navDrawer.width} + ${theme.spacing(3)})`,
        transition: (theme) =>
          theme.transitions.create("left", navDrawer.transition),
        zIndex: (theme) => theme.zIndex.drawer,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          background: (theme) => `
              linear-gradient(
                ${alpha(theme.palette.primary.main, 0.2)},
                ${alpha(theme.palette.primary.main, 0.2)}
              ),
              linear-gradient(
                ${theme.palette.background.default},
                ${theme.palette.background.default}
              )
            `,
          py: 1,
          px: 2,
          borderRadius: 1,
          border: "1px solid",
          borderColor: "primary.main",
          boxShadow: (theme) =>
            mode === "light"
              ? `0 0 20px 0 ${theme.palette.background.lightest}`
              : "0 0 24px rgba(0,0,0,0.5)",
          maxWidth: (theme) =>
            `calc(${theme.breakpoints.values.lg}px - ${theme.spacing(6)})`,
          mx: "auto",

          ".MuiButton-root": {
            ml: 2,
            py: 0.5,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
