import { createSvgIcon } from "@mui/material";

export const ResourcesIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_204_5728)">
      <path
        d="M20.3982 9.71642H17.6472V6.96537C17.6472 6.88635 17.6316 6.80809 17.6014 6.73508C17.5711 6.66207 17.5268 6.59572 17.4709 6.53984C17.415 6.48396 17.3487 6.43963 17.2757 6.40939C17.2027 6.37915 17.1244 6.36358 17.0454 6.36358H14.2836V3.60179C14.2836 3.52276 14.268 3.44451 14.2378 3.3715C14.2075 3.29848 14.1632 3.23214 14.1073 3.17626C14.0514 3.12038 13.9851 3.07605 13.9121 3.04581C13.8391 3.01557 13.7608 3 13.6818 3H3.60179C3.44219 3 3.28912 3.0634 3.17626 3.17626C3.0634 3.28912 3 3.44219 3 3.60179V13.6818C3 13.7608 3.01557 13.8391 3.04581 13.9121C3.07605 13.9851 3.12038 14.0514 3.17626 14.1073C3.23214 14.1632 3.29848 14.2075 3.3715 14.2378C3.44451 14.268 3.52276 14.2836 3.60179 14.2836H6.36358V17.0346C6.36358 17.1942 6.42699 17.3473 6.53984 17.4602C6.6527 17.573 6.80577 17.6364 6.96537 17.6364H9.71642V20.3982C9.71642 20.4772 9.73198 20.5555 9.76223 20.6285C9.79247 20.7015 9.8368 20.7679 9.89268 20.8237C9.94856 20.8796 10.0149 20.924 10.0879 20.9542C10.1609 20.9844 10.2392 21 10.3182 21H20.3982C20.5578 21 20.7109 20.9366 20.8237 20.8237C20.9366 20.7109 21 20.5578 21 20.3982V10.3182C21 10.1586 20.9366 10.0055 20.8237 9.89268C20.7109 9.77982 20.5578 9.71642 20.3982 9.71642ZM6.36358 6.96537V13.08H4.21433V4.20358H13.08V6.35284H6.96537C6.88543 6.35282 6.80629 6.36874 6.73257 6.39965C6.65885 6.43056 6.59202 6.47585 6.536 6.53288C6.47997 6.5899 6.43587 6.65752 6.40627 6.73178C6.37667 6.80603 6.36215 6.88545 6.36358 6.96537ZM9.71642 10.3182V16.4436H7.56716V7.55642H16.4436V9.70567H10.3182C10.2383 9.70566 10.1591 9.72157 10.0854 9.75249C10.0117 9.7834 9.94486 9.82869 9.88883 9.88572C9.83281 9.94274 9.78871 10.0104 9.75911 10.0846C9.7295 10.1589 9.71499 10.2383 9.71642 10.3182ZM19.7964 19.7964H10.92V10.92H19.7964V19.7964Z"
        fill="#B4B4B4"
      />
    </g>
    <defs>
      <clipPath id="clip0_204_5728">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>,
  "Resources",
);
