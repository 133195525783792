import { useEffect } from "react";
import { IamActions } from "~/lib/iam";
import { ErrorBoundary } from "~/components/error-boundary";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { useTestIamActionsQuery } from "~/operations";
import { OrganizationRoutes } from "~/routes";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import {
  NavDrawerOverviewIcon,
  NavDrawerSettingsIcon,
  NavDrawerSpacesIcon,
} from "~/components/icons";
import { useViewer } from "~/providers/viewer";
import { Navigate } from "react-router-dom";

export type OrganizationPagesProps = {};

export function OrganizationPages({}: OrganizationPagesProps) {
  const { setTree } = useAppNavDrawer();
  const { currentOrg } = useViewer();
  const org = currentOrg();

  useEffect(() => {
    setTree(
      [
        {
          text: "Overview",
          icon: <NavDrawerOverviewIcon />,
          link: {
            to: `/organization/overview?organizationId=${org?.id}`,
          },
        },

        {
          text: "Spaces",
          icon: <NavDrawerSpacesIcon />,
          link: {
            to: `/organization/spaces?organizationId=${org?.id}`,
          },
        },
        {
          text: "Settings",
          icon: <NavDrawerSettingsIcon />,
          link: {
            to: `/organization/settings?organizationId=${org?.id}`,
          },
        },
      ].flatMap((navItem) => navItem ?? []),
    );
    return () => setTree([]);
  }, [org]);

  const { loading, data, error } = useTestIamActionsQuery({
    variables: { resourceMrn: org?.mrn || "", actions: IamActions.default() },
    skip: !org?.mrn,
  });

  if (loading) {
    return <LoadingPage />;
  }

  if (error) {
    return <LoadingFailedPage />;
  }

  if (!data || !org) {
    return <Navigate to="/dashboard" replace />;
  }

  const { testIamActions } = data;
  const availablePermissions = testIamActions;

  return (
    <ErrorBoundary key="organization">
      <OrganizationRoutes {...{ org, availablePermissions }} />
    </ErrorBoundary>
  );
}
