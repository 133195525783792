import { Link as RouterLink, matchPath } from "react-router-dom";
import { Space } from "~/lib/types";
import { AvailableIntegrations } from "./available-integrations";
import { ActiveIntegrations } from "~/components/integrations/active-integrations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import {
  TestIamActionsQuery,
  useGetIntegrationsSummaryQuery,
} from "~/operations";
import { AnimatePresence } from "framer-motion";
import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { AddButton } from "~/components/add-button";
import { HomeIcon, OpenInNewIcon } from "~/components/icons";
import { IamActions } from "~/lib/iam";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Integrations({ space, availablePermissions }: Props) {
  const { data, error, loading } = useGetIntegrationsSummaryQuery({
    variables: { input: { spaceMrn: space.mrn } },
  });

  const hasIntegrations = Boolean(data?.integrationsSummary?.total || 0 > 0);
  const hasCreateIntegrationPermission = availablePermissions.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );

  const isAdding = Boolean(
    matchPath({ path: "/space/integrations/add" }, location.pathname),
  );

  if (error) {
    return <LoadingFailedPage />;
  }

  if (loading || !data?.integrationsSummary.integrationSummaries) {
    return <LoadingPage />;
  }

  const getTitle = () => {
    if (isAdding) {
      return !hasIntegrations
        ? "ADD YOUR FIRST INTEGRATION TO GET STARTED"
        : "AVAILABLE INTEGRATIONS";
    } else {
      return "INTEGRATIONS";
    }
  };

  const listCrumb = isAdding ? (
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>
  ) : (
    <Typography key="/space/integrations">Integrations</Typography>
  );
  const addCrumb = isAdding ? (
    <Typography key="/space/integrations/add">Add</Typography>
  ) : undefined;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    listCrumb,
    addCrumb,
  ];

  document.title = "Integrations · Mondoo";

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid
        container
        columnGap={2}
        mt={2}
        mb={4}
        sx={{
          alignItems: {
            xs: "start",
            sm: "center",
          },
        }}
      >
        <Grid item xs>
          <Typography
            component="h1"
            fontWeight="bold"
            sx={{
              fontSize: {
                xs: 22,
                sm: 32,
              },
            }}
          >
            {getTitle()}
          </Typography>
        </Grid>
        <Grid sx={{ minWidth: 150 }}>
          <Box
            display="flex"
            justifyContent="end"
            sx={{
              mt: {
                xs: 0.75,
                sm: 0,
              },
            }}
          >
            {!hasIntegrations ? (
              <Button
                href="https://mondoo.com/support"
                target="_blank"
                variant="outlined"
                color="secondary"
                endIcon={<OpenInNewIcon />}
                sx={{ py: 0.5, px: 1 }}
              >
                Get Support
              </Button>
            ) : hasCreateIntegrationPermission ? (
              <AddButton
                id="integration"
                aria-label="Add Integration"
                close={isAdding}
                href={
                  isAdding
                    ? `/space/integrations?spaceId=${space.id}`
                    : `/space/integrations/add?spaceId=${space.id}`
                }
              />
            ) : undefined}
          </Box>
        </Grid>
      </Grid>
      <AnimatePresence>
        {!hasIntegrations || isAdding ? (
          <AvailableIntegrations {...{ space }} />
        ) : (
          <ActiveIntegrations
            space={space}
            availablePermissions={availablePermissions}
            summaries={data.integrationsSummary.integrationSummaries}
          />
        )}
      </AnimatePresence>
    </Box>
  );
}
