import { Box } from "@mui/material";
import { Tag } from "~/operations";
import {
  BsiIcon,
  CISIcon,
  CsaIcon,
  NistIcon,
  VdaIcon,
  ISOIcon,
  HIPAAIcon,
  PCIIcon,
  SOCIcon,
  EuIcon,
} from "../icons";
import { ComplianceFramework } from "../compliance-page";

type Props = {
  iconKey: Tag | undefined;
};

export function FrameworkIcon({ iconKey }: Props) {
  const sizeProps = { fontSize: 32 };

  if (!iconKey) {
    return <Box sx={{ ...sizeProps }} />;
  }

  const matchIcon = () => {
    switch (iconKey.value) {
      case "bsi":
        return <BsiIcon sx={{ ...sizeProps }} />;
      case "cis":
        return <CISIcon sx={{ ...sizeProps }} />;
      case "csa-ccm":
        return <CsaIcon sx={{ ...sizeProps }} />;
      case "nis2":
        return <EuIcon sx={{ ...sizeProps }} />;
      case "hhs":
        return <HIPAAIcon sx={{ ...sizeProps }} />;
      case "iso":
        return <ISOIcon sx={{ ...sizeProps }} />;
      case "nist":
        return <NistIcon sx={{ ...sizeProps }} />;
      case "soc":
        return <SOCIcon sx={{ ...sizeProps }} />;
      case "pci":
        return <PCIIcon sx={{ ...sizeProps }} />;
      case "vda":
        return <VdaIcon sx={{ ...sizeProps }} />;
      default:
        return <Box />;
    }
  };

  return matchIcon();
}

type UseFrameworkProps = {
  framework?: ComplianceFramework;
};

export const useFrameworkIcon = ({ framework }: UseFrameworkProps) => {
  const iconKey = framework?.tags?.find((tag) => tag.key === "mondoo.com/icon");

  return { iconKey };
};
