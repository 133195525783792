import { ReactNode } from "react";
import { Grid, Typography } from "@mui/material";

type CheckScoreItemProps = {
  type: string;
  title: string;
  component?: ReactNode;
  amount?: ReactNode;
};

export const ScoreLayout = ({
  type,
  title,
  component,
  amount,
}: CheckScoreItemProps) => {
  return (
    <Grid item container className={type}>
      <Grid
        item
        xs
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "left",
        }}
        gap={1}
      >
        <Typography
          className="title"
          sx={{ fontWeight: 700, color: "text.secondary" }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={2.6}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: type === "blast-radius" ? "center" : "right",
        }}
      >
        {component}
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
        className="amount"
      >
        {amount}
      </Grid>
    </Grid>
  );
};
