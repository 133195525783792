import { Box, Divider, Typography, useTheme } from "@mui/material";
import { ChangeHistoryOutlined } from "@mui/icons-material";
import {
  TrendingDownIcon,
  TrendingFlatIcon,
  TrendingUpIcon,
} from "~/components/icons";
import { DonutChart } from "~/components/charts/donut-chart";
import { getColor } from "~/lib/colors";
import {
  GetOrganizationOverviewQuery,
  useGetOrganizationOverviewQuery,
} from "~/operations";

type RawData =
  | NonNullable<GetOrganizationOverviewQuery["organizationOverview"]>["assets"]
  | undefined;
type Data = NonNullable<RawData>;
type AssetChangeType = "improved" | "regressed" | "unchanged";

export type AssetsTodayProps = {
  organizationMrn: string;
};

export function AssetsToday({ organizationMrn }: AssetsTodayProps) {
  const theme = useTheme();

  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  if (result.loading) {
    return <>Loading</>;
  }

  const rawData = result.data?.organizationOverview?.assets;
  const data = parseData(rawData);

  const { __typename, total, ...gradeScores } = data.scores;
  const donutData = Object.entries(gradeScores).map(([label, value]) => ({
    label,
    value,
    color: getColor(theme, label),
  }));

  const changeTypes: AssetChangeType[] = ["improved", "regressed", "unchanged"];

  const changeTypeIcon = (label: AssetChangeType) => {
    switch (label) {
      case "improved":
        return <TrendingUpIcon />;
      case "regressed":
        return <TrendingDownIcon />;
      case "unchanged":
        return <TrendingFlatIcon />;
    }
  };

  const changeTypeColor = (label: AssetChangeType) => {
    switch (label) {
      case "improved":
        return "#2FC089";
      case "regressed":
        return "#FC3779";
      case "unchanged":
        return "#FFFFFF";
    }
  };

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          pt: 2,
          "> .MuiGrid-root": { padding: 0, maxWidth: "350px", mx: "auto" },
        }}
      >
        <DonutChart data={donutData} dataType="assets" />
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          ".overview-item": {
            display: "flex",
            alignItems: "center",
            fontSize: 12,
            fontWeight: "bold",
            "&:first-of-type": { fontSize: 14 },
            svg: { mr: 0.5 },
          },
        }}
      >
        <Box className="overview-item">
          <ChangeHistoryOutlined fontSize="small" />
          <Typography component="span" fontSize="inherit">
            Last 7 Days
          </Typography>
        </Box>
        {changeTypes.map((changeType) => (
          <Box
            className="overview-item"
            sx={{ color: changeTypeColor(changeType) }}
            key={changeType}
          >
            {changeTypeIcon(changeType)}
            <Typography
              component="span"
              fontSize="inherit"
              fontWeight="inherit"
            >
              {data[changeType]}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

function parseData(rawData: RawData): Data {
  const zeroData: Data = {
    __typename: "AssetsInfo",
    improved: 0,
    regressed: 0,
    unchanged: 0,
    scores: {
      __typename: "RiskDistribution",
      total: 0,
      A: 0,
      B: 0,
      C: 0,
      D: 0,
      F: 0,
      U: 0,
      Error: 0,
    },
  };

  return rawData || zeroData;
}
