import { Box, Button, Grid, Paper, Typography } from "@mui/material";

type UpgradePlanProps = {
  onQuoteRequest: () => void;
};

export function UpgradePlan({ onQuoteRequest }: UpgradePlanProps) {
  return (
    <Grid container component={Paper} mt={2}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "background.light",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
        p={0}
      >
        <Typography
          component="h3"
          variant="body1"
          sx={{ textTransform: "uppercase", padding: 2 }}
        >
          Upgrade Your Plan
        </Typography>
      </Grid>
      <Grid>
        <Grid container item xs={12} p={5} pb={7}>
          <Grid item xs={9}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
              Get the most out of Mondoo with the Enterprise Plan!
            </Typography>
            <Typography>
              Collaboratively manage entire fleets of assets and ensure
              state-of-the-art security from staging to production – bespoke
              arrangements to match your needs available.
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box
              height={1}
              display="flex"
              alignItems="flex-start"
              justifyContent="right"
            >
              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ px: 5 }}
                onClick={() => onQuoteRequest()}
              >
                Get a quote
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
