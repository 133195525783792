import { createSvgIcon } from "@mui/material";

export const TerraformIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56543 4.22888L15.1258 8.01754V15.5949L8.56543 11.8062V4.22888Z"
      fill="#5C4EE5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8438 8.01754V15.5949L22.4072 11.8062V4.22888L15.8438 8.01754Z"
      fill="#4040B2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.28613 0V7.57732L7.84653 11.366V3.78866L1.28613 0Z"
      fill="#5C4EE5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.56543 20.2114L15.1258 24V16.4754V16.4258L8.56543 12.6371V20.2114Z"
      fill="#5C4EE5"
    />
  </svg>,
  "Terraform",
);
