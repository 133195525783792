import {
  FormControl,
  FormControlProps,
  InputLabel,
  Select as SelectMUI,
  MenuItem,
} from "@mui/material";
import { FieldOption, FilterOption } from "../../../types";

type SelectProps = FormControlProps &
  FilterOption & {
    onChangeHandler: (e: FieldOption) => void;
  };

const Select = ({
  name,
  label,
  options,
  value,
  multiple,
  type = "select",
  onChangeHandler,
  ...restProps
}: SelectProps) => {
  return (
    <FormControl {...restProps}>
      <InputLabel>{label}</InputLabel>
      <SelectMUI
        multiple={multiple}
        name={name}
        label={label}
        value={value as any}
        onChange={(e) => {
          onChangeHandler({
            name: e.target.name,
            value: e.target.value,
          });
        }}
      >
        {multiple && (
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
        )}
        {options &&
          options.map((option) =>
            option === "none" ? (
              <MenuItem key={option} value="">
                <em>None</em>
              </MenuItem>
            ) : (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ),
          )}
      </SelectMUI>
    </FormControl>
  );
};

export default Select;
