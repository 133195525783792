import { createSvgIcon } from "@mui/material";

export const OperatingSystemsIcon = createSvgIcon(
  <svg viewBox="0 0 40 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.3333 23.9998C35.1667 23.9998 36.65 22.4998 36.65 20.6665L36.6667 3.99984C36.6667 2.1665 35.1667 0.666504 33.3333 0.666504H6.66667C4.83333 0.666504 3.33333 2.1665 3.33333 3.99984V20.6665C3.33333 22.4998 4.83333 23.9998 6.66667 23.9998H0V27.3332H40V23.9998H33.3333ZM6.66667 3.99984H33.3333V20.6665H6.66667V3.99984Z"
      fill="url(#paint0_linear_7091_35031)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7091_35031"
        x1="0"
        y1="0.666504"
        x2="25.7228"
        y2="37.3457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E45E2" />
        <stop offset="1" stopColor="#A22E95" />
      </linearGradient>
    </defs>
  </svg>,
  "OperatingSystems",
);
