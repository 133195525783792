import { useState, useEffect } from "react";
import {
  TextField,
  InputAdornment,
  StandardTextFieldProps,
} from "@mui/material";
import { Search as SearchIcon } from "@mui/icons-material";
import { FieldOption, FilterOption } from "../../../types";
import { CloseIcon } from "~/components/icons";

type SearchProps = StandardTextFieldProps &
  FilterOption & {
    onChangeHandler: (value: FieldOption) => void;
  };

const DEBOUNCE_DELAY = 500;

const Search = ({
  name = "search",
  placeholder = "Search",
  type = "search",
  label,
  value,
  onChangeHandler,
  ...restProps
}: SearchProps) => {
  const [query, setQuery] = useState(value);

  useEffect(() => {
    setQuery(value);
  }, [value]);

  useEffect(() => {
    const timer = setTimeout(() => {
      onChangeHandler({
        name,
        value: query,
      });
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(timer);
  }, [query]);

  return (
    <TextField
      name={name}
      label={label}
      placeholder={query ? "" : placeholder}
      value={query}
      onChange={(e) => {
        setQuery(e.target.value);
      }}
      InputProps={{
        autoComplete: "off",
        autoCorrect: "off",
        spellCheck: false,
        endAdornment: (
          <InputAdornment
            position="start"
            sx={{ color: (theme) => theme.palette.grey[500] }}
          >
            {query ? (
              <CloseIcon cursor="pointer" onClick={() => setQuery("")} />
            ) : (
              <SearchIcon />
            )}
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};

export default Search;
