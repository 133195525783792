import { Button, ButtonProps } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

type GradientButtonProps = ButtonProps<
  "button",
  { component?: string | typeof RouterLink; to?: string; target?: string }
>;

export const GradientButton = ({
  children,
  sx,
  ...rest
}: GradientButtonProps) => {
  return (
    <Button
      variant="contained"
      sx={{
        background: (theme) => theme.palette.primary.gradient,
        textTransform: "none",
        px: 3,
        py: 2,
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
