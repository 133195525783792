import { Dialog, DialogTitle, DialogContent, Typography } from "@mui/material";

export function GeolockDialog() {
  return (
    <Dialog open id="geolock-dialog">
      <DialogTitle>Mondoo Unavailable</DialogTitle>
      <DialogContent sx={{ pb: 2 }}>
        <Typography variant="body1">
          Mondoo is unavailable in your location.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
