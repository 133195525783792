import { SxProps, SvgIcon } from "@mui/material";
import {
  MoreHorizIcon,
  DeleteIcon,
  SaveAltIcon,
  RefreshIcon,
  CloseIcon,
  EditIcon,
  InternetExposed,
  ExploitableIcon,
  DatabaseAccessIcon,
  AdminUser,
  ServiceRunning,
  RemoteExecutionIcon,
  SensitiveDataIcon,
  AccessToKeys,
  ContainerEnvironmentIcon,
  EndOfLife,
  DefensiveIcon,
  AddIcon,
} from "~/components/icons";
import { PlusIcon } from "~/components/icons/mondoo/utils/plusIcon";

type DynamicIconProps = {
  iconId: IconButtonType | null | undefined;
  size?: string;
  sx?: SxProps;
  defaultIcon?: typeof SvgIcon;
};

export const DynamicButtonIcon = ({
  iconId,
  size,
  sx = {},
  defaultIcon = MoreHorizIcon,
}: DynamicIconProps) => {
  const commonProps = {
    "data-testid": "icon-button",
    sx: {
      fontSize: size || 24,
      ...sx,
    },
  };

  const iconKey = iconId || IconButtonType.Default;

  const iconsDictionary: Record<
    IconButtonType,
    typeof SvgIcon | React.ComponentType
  > = {
    [IconButtonType.Close]: CloseIcon,
    [IconButtonType.Default]: SvgIcon,
    [IconButtonType.Delete]: DeleteIcon,
    [IconButtonType.Edit]: EditIcon,
    [IconButtonType.Download]: SaveAltIcon,
    [IconButtonType.Refresh]: RefreshIcon,
    [IconButtonType.InternetFacing]: InternetExposed,
    [IconButtonType.Exploitable]: ExploitableIcon,
    [IconButtonType.DbAccess]: DatabaseAccessIcon,
    [IconButtonType.UserRisk]: AdminUser,
    [IconButtonType.AssetInUse]: ServiceRunning,
    [IconButtonType.CodeExecution]: RemoteExecutionIcon,
    [IconButtonType.SensitiveData]: SensitiveDataIcon,
    [IconButtonType.Credentials]: AccessToKeys,
    [IconButtonType.Containers]: ContainerEnvironmentIcon,
    [IconButtonType.Eol]: EndOfLife,
    [IconButtonType.Defensive]: DefensiveIcon,
    [IconButtonType.More]: AddIcon,
    [IconButtonType.Plus]: PlusIcon,
  };

  let Icon = iconsDictionary[iconKey] || defaultIcon;

  return <Icon {...commonProps} />;
};

export enum IconButtonType {
  More = "MORE",
  Close = "CLOSE",
  Default = "DEFAULT",
  Delete = "DELETE",
  Download = "DOWNLOAD",
  Edit = "EDIT",
  Refresh = "REFRESH",
  // Risk Factor Icons
  InternetFacing = "INTERNET_FACING",
  Exploitable = "EXPLOITABLE",
  DbAccess = "DB_ACCESS",
  UserRisk = "USER_RISK",
  AssetInUse = "ASSET_IN_USE",
  CodeExecution = "CODE_EXECUTION",
  SensitiveData = "SENSITIVE_DATA",
  Credentials = "CREDENTIALS",
  Containers = "CONTAINERS",
  Eol = "EOL",
  Defensive = "DEFENSIVE",
  Plus = "PLUS",
}
