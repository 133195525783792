import React from "react";
import { FormControl, TextField, Typography, useTheme } from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import {
  Control,
  FormState,
  UseFormGetFieldState,
} from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { NotesField } from "~/components/cases/components/CreateCaseModal/NotesField";
import { TitleField } from "~/components/cases/components/CreateCaseModal/TitleField";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  getFieldState: UseFormGetFieldState<CreateCasesFormInput>;
  errors: FieldErrors<CreateCasesFormInput>;
  disabled: boolean;
};

export const CreateCaseGitlabModal = ({
  hasCreateCasePermissions,
  control,
  register,
  formState,
  getFieldState,
  errors,
  disabled,
}: CreateCaseModalProps) => {
  const theme = useTheme();

  return (
    <>
      <FormControl fullWidth>
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Project
          </Typography>
          <Controller
            name="gitlab.project"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={"your-group/your-project ..."}
                error={
                  getFieldState("gitlab.project").isTouched &&
                  Boolean(errors.gitlab?.project)
                }
                helperText={
                  Boolean(errors.gitlab?.project) &&
                  getFieldState("gitlab.project").isTouched && (
                    <ValidationMessage error={errors.gitlab?.project} />
                  )
                }
              />
            )}
          />
        </FormControl>
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <TitleField
          textFieldProps={register("gitlab.title", { required: true })}
          errorMessage={formState.errors?.gitlab?.title?.message}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <NotesField
          errorMessage={formState.errors.gitlab?.notes?.message}
          textFieldProps={register("gitlab.notes")}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
    </>
  );
};
