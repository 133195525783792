import { SvgIcon, SxProps } from "@mui/material";
import {
  AmazonLinuxInstancesIcon,
  AutoscalingGroupsIcon,
  CloudWatchAlarmsIcon,
  CloudWatchLogGroupsIcon,
  ConfigRecordersIcon,
  DynamoDBTablesIcon,
  EBSSnapshotsIcon,
  EBSVolumesIcon,
  EC2SecurityGroupsIcon,
  EFSFilesystemsIcon,
  EKSClustersIcon,
  EMRClustersIcon,
  ESDomainsIcon,
  IAMGroupsIcon,
  IAMUsersIcon,
  KMSKeysIcon,
  LambdaFunctionsIcon,
  MacOsInstancesIcon,
  RDSDBInstancesIcon,
  RHELInstancesIcon,
  RedshiftClustersIcon,
  S3BucketsIcon,
  SNSTopicsIcon,
  TotalEC2InstancesIcon,
  UbuntuInstancesIcon,
  VPCSIcon,
  WindowsInstancesIcon,
} from ".";

type DynamicIconProps = {
  title: string;
  id?: string;
  className?: string;
  size?: string;
  defaultIcon?: typeof SvgIcon;
  sx?: SxProps;
};

export const DynamicIcon = ({
  title,
  id,
  className,
  size,
  defaultIcon = TotalEC2InstancesIcon,
  sx = {},
}: DynamicIconProps) => {
  const commonProps = {
    id,
    className: `${className} aws-resource-icon`,
    "data-testid": "aws-resource-icon",
    sx: {
      fontSize: size || 24,
      ...(Array.isArray(sx) ? sx : [sx]),
    },
  };

  const iconKey = title.toLowerCase();
  const iconsDictionary: Record<string, typeof SvgIcon> = {
    "amazon linux instances": AmazonLinuxInstancesIcon,
    "autoscaling groups": AutoscalingGroupsIcon,
    "cloudwatch alarms": CloudWatchAlarmsIcon,
    "cloudwatch log groups": CloudWatchLogGroupsIcon,
    "config recorders": ConfigRecordersIcon,
    "dynamodb tables": DynamoDBTablesIcon,
    "ebs snapshots": EBSSnapshotsIcon,
    "ebs volumes": EBSVolumesIcon,
    "ec2 security groups": EC2SecurityGroupsIcon,
    "efs filesystems": EFSFilesystemsIcon,
    "eks clusters": EKSClustersIcon,
    "emr clusters": EMRClustersIcon,
    "es domains": ESDomainsIcon,
    "iam groups": IAMGroupsIcon,
    "iam users": IAMUsersIcon,
    "kms keys": KMSKeysIcon,
    "lambda functions": LambdaFunctionsIcon,
    "macos instances": MacOsInstancesIcon,
    "rds db instances": RDSDBInstancesIcon,
    "redshift clusters": RedshiftClustersIcon,
    "rhel instances": RHELInstancesIcon,
    "s3 buckets": S3BucketsIcon,
    "sns topics": SNSTopicsIcon,
    "total ec2 instances": TotalEC2InstancesIcon,
    "ubuntu instances": UbuntuInstancesIcon,
    vpcs: VPCSIcon,
    "windows instances": WindowsInstancesIcon,
  };

  const Icon = iconsDictionary[iconKey] || defaultIcon;

  return <Icon {...commonProps} />;
};
