import { CSSProperties, FC, PureComponent, ReactNode } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { CvssScoreType, Score2CvssScore } from "../lib/score";
import { Label } from "./label";
import { Score } from "~/lib/types";
import { ScoreCardBar } from "./scores/styles";

export const ScoreCardBox = styled("div")`
  margin: ${(props) => props.theme.spacing(4)} 0
    ${(props) => props.theme.spacing(2)} 0;
`;

export const scoreDotColor = (props: any | ScoreDotProps) => {
  const palette = props.theme.palette[props.status];
  if (palette == null) {
    console.error(`score-card> no score dot color for status==${props.status}`);
    return "black";
  }
  return palette.main;
};

export type ScoreDotProps = {
  status: string;
};

const ScoreDot = styled("div")<ScoreDotProps>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  background-color: ${scoreDotColor};
  margin-right: 10px;
  display: inline-block;
`;

const ScoreLabel = styled(Label)`
  margin: 5px;
`;

const LgScoreDot = styled("div")<ScoreDotProps>`
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${scoreDotColor};
  margin-right: ${(props) => props.theme.spacing(1)};
  display: inline-block;
`;

const LgScoreLabel = styled(Label)`
  margin: ${(props) => props.theme.spacing(1)} 0;
  padding: ${(props) => props.theme.spacing(1)};
  border-radius: 20px;
  justify-content: flex-start;
  font-size: 12px;
`;

export type StatusTagProps = {
  status: string;
  text: ReactNode;
  displaydot: boolean;
};

export const LargeStatusTag: FC<StatusTagProps> = ({
  status,
  text,
  displaydot = true,
}) => {
  return (
    <LgScoreLabel>
      {displaydot == true && <LgScoreDot status={status} />}
      <span>{text}</span>
    </LgScoreLabel>
  );
};

export const StatusTag: FC<StatusTagProps> = ({
  status,
  text,
  displaydot = true,
}) => {
  return (
    <ScoreLabel>
      {displaydot == true && <ScoreDot status={status} />}
      <span>{text}</span>
    </ScoreLabel>
  );
};

export const AgentStatus = {
  ACTIVE: "excellent",
  MISSING: "critical",
  TERMINATED: "unknown",
  NOT_READY: "unknown",
  WAITING_FOR_SETUP: "low",
  SETUP_IN_PROGRESS: "low",
  ERROR: "critical",
  REGISTERING: "low",
  CONFIGURING: "low",
  DELETED: "critical",
  WARNING: "low",
} as const;

const AgentStatusText = {
  ACTIVE: "active",
  MISSING: "missing",
  TERMINATED: "terminated",
  NOT_READY: "not ready",
  WAITING_FOR_SETUP: "waiting for setup",
  SETUP_IN_PROGRESS: "setup in progress",
  REGISTERING: "registering",
  CONFIGURING: "configuring",
  ERROR: "error",
  DELETED: "deleted",
  WARNING: "active",
} as const;

export const AgentStatusTag = ({
  status,
}: {
  status: keyof typeof AgentStatus;
}) => {
  const s = AgentStatus[status] || AgentStatus.NOT_READY;
  const text = AgentStatusText[status] || AgentStatusText.NOT_READY;
  return <StatusTag status={s} text={text} displaydot />;
};

export const LargeAgentStatusTag = ({
  status,
}: {
  status: keyof typeof AgentStatus;
}) => {
  const s = AgentStatus[status] || AgentStatus.NOT_READY;
  const text = AgentStatusText[status] || AgentStatusText.NOT_READY;
  return <LargeStatusTag status={s} text={text} displaydot />;
};

export type ScoreTagProps = {
  score?: { type: number; value: number };
  status?: string | boolean;
  unscored?: boolean;
  eol?: boolean;
  displaydot: boolean;
};

// score 0 - 100
// status critical/high/medium/low/unknown or true, to calculate from score
export const ScoreTag: FC<ScoreTagProps> = ({
  score,
  status,
  unscored = false,
  eol,
  displaydot,
}) => {
  if (unscored == true || score === undefined || score.type === 16) {
    return (
      <StatusTag status={"unknown"} text={"UNSCORED"} displaydot={displaydot} />
    );
  }

  if (status === true) {
    const isUnscored = score.type === 16 ? true : false;
    status = CvssScoreType(score.value, true, isUnscored);
  }

  let name =
    status == null || status == false ? "unknown" : status.toLocaleUpperCase();
  let num = Score2CvssScore(score.value);

  if (eol) {
    name = "End-Of-Life";
    status = "critical";
  }

  if (score != null) {
    name += " (" + num + ")";
  }

  if (!status) {
    status = "";
  }

  return <StatusTag status={status} text={name} displaydot={displaydot} />;
};

const ScoreHorizontalBarBody = styled("div")`
  display: block;
  width: 100%;
  height: 6px;
  padding-left: 8px;
  padding-right: 8px;
`;

export type ScoreHorizontalBarProps = {
  score: number;
  unscored: boolean;
};

export const ScoreHorizontalBar: FC<ScoreHorizontalBarProps> = ({ score }) => {
  const theme = useTheme();
  const status = score !== undefined ? CvssScoreType(score) : "unknown";
  const primaryColor = theme.palette[status].main;
  const secondaryColor = theme.palette["unknown"].main;

  return (
    <ScoreHorizontalBarBody>
      <HorizontalBar
        value={score}
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
      />
    </ScoreHorizontalBarBody>
  );
};

const HorizontalBarValue = styled("span")`
  display: block;
  position: relative;
  float: left;
  height: 100%;
  width: 100%;
`;

export type HorizontalBarProps = {
  value: number;
  primaryColor: string;
  secondaryColor: string;
};

// value is a 0 - 100 scale
export const HorizontalBar: FC<HorizontalBarProps> = ({
  value,
  primaryColor,
  secondaryColor,
}) => {
  if (value == undefined) {
    value == 0;
  }

  let primaryVal = value + "%";
  let secondaryVal = 100 - value + "%";
  if (value > 100) {
    value = 100;
  }

  if (value < 5) {
    return (
      <HorizontalBarValue
        style={{
          width: "100%",
          backgroundColor: secondaryColor,
          borderRadius: "5px 5px 5px 5px",
        }}
      />
    );
  }

  if (value > 95) {
    return (
      <HorizontalBarValue
        style={{
          width: "100%",
          backgroundColor: primaryColor,
          borderRadius: "5px 5px 5px 5px",
        }}
      />
    );
  }

  return (
    <>
      <HorizontalBarValue
        style={{
          width: primaryVal,
          backgroundColor: primaryColor,
          borderRadius: "5px 0px 0px 5px",
        }}
      />
      <HorizontalBarValue
        style={{
          width: secondaryVal,
          backgroundColor: secondaryColor,
          borderRadius: "0px 5px 5px 0px",
        }}
      />
    </>
  );
};

export type ScoredChipProps = {
  score: number;
  unscored: boolean;
  label: string;
  className?: string;
};

export class ScoredChip extends PureComponent<ScoredChipProps> {
  render() {
    const { score, unscored, label, ...rest } = this.props;
    const status = CvssScoreType(score, !unscored);

    return <StatusTag status={status} text={label} displaydot {...rest} />;
  }
}

const ScoreCardOuter = styled("div")`
  border-radius: 4px;
  background: linear-gradient(
    331.58deg,
    ${(props) => props.theme.palette.background.light} 0%,
    ${(props) => props.theme.palette.background.paper} 100%
  );
  color: ${(props) => props.theme.palette.text.primary};
  box-shadow: ${(props) => props.theme.shadows[1]};
  overflow: hidden;
  flex: 0 0 auto;
`;

const ScoreCardBody = styled("div")`
  padding: 24px;
  display: flex;
`;

const SubHead = styled("div")`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.07px;
  line-height: 14px;
  margin-bottom: 12px;
`;

const CBLeft = styled("div")`
  flex-grow: 99;
`;

const CBRight = styled("div")`
  flex-grow: 1;
`;

const StyledScore = styled("div")`
  font-size: 56px;
  line-height: 56px;
  padding: 0 0 0 ${(props) => props.theme.spacing(3)};
  text-align: right;
`;

export type ScoreCardProps = {
  score: Score;
  affected?: boolean;
  unscored?: boolean;
  eol?: boolean;
  displaydot?: boolean;
  style?: CSSProperties;
};

export const ScoreCard: FC<ScoreCardProps> = (props) => {
  const {
    score,
    affected = true,
    displaydot = true,
    unscored = false,
    eol = false,
    style,
  } = props;
  let num = Score2CvssScore(score.value, affected);
  const status = CvssScoreType(score.value, affected);
  if (score == null || unscored) {
    return (
      <ScoreBox score={score} affected={affected} eol={eol} style={style}>
        <CBLeft>
          <SubHead>No scoring data available</SubHead>
          <ScoreTag status={status} eol={eol} displaydot={displaydot} />
        </CBLeft>
      </ScoreBox>
    );
  }

  return (
    <ScoreBox
      score={score}
      affected={affected}
      unscored={unscored}
      eol={eol}
      style={style}
    >
      <CBLeft>
        <SubHead>Overall Impact</SubHead>
        <ScoreTag
          score={score}
          status={status}
          unscored={unscored}
          eol={eol}
          displaydot={displaydot}
        />
      </CBLeft>
      <CBRight>
        <StyledScore>{num}</StyledScore>
      </CBRight>
    </ScoreBox>
  );
};

export type ScoreBoxProps = {
  score: Score | number;
  affected?: boolean;
  unscored?: boolean;
  eol?: boolean;
  children?: ReactNode;
  style?: CSSProperties;
};

export const ScoreBox = ({
  score,
  affected = true,
  unscored = false,
  eol = false,
  children,
  ...props
}: ScoreBoxProps) => {
  const thisScore = typeof score === "number" ? score : score.value;
  let status = CvssScoreType(thisScore, affected, unscored);

  if (eol) {
    status = "critical";
  }

  return (
    <ScoreCardOuter {...props}>
      <ScoreCardBar status={status} />
      <ScoreCardBody>{children}</ScoreCardBody>
    </ScoreCardOuter>
  );
};
