import { FC } from "react";
import { styled } from "@mui/material";
import { AdvisoryIcon } from "../../material";
import { AwsIcon } from "../integrations/aws";
import { CentosIcon } from "../centos";
import { FedoraIcon } from "../fedora";
import { GithubIcon } from "../github";
import { WindowsIcon } from "../integrations/windows";
import { NodejsIcon } from "../nodejs";
import { NpmIcon } from "../npm";
import { PhpIcon } from "../php";
import { RedHatIcon } from "../integrations/redhat";
import { SuseIcon } from "../integrations/suse";
import { UbuntuIcon } from "../integrations/ubuntu";
import { VMWareIcon } from "../vmware";
import { DebianIcon } from "../integrations/debian";
import { MozillaIcon } from "../mozilla";
import { ChromeIcon } from "../chrome";
import { VSCodeIcon } from "../vscode";
import { MSEdgeIcon } from "../ms-edge";
import { OracleLinuxIcon } from "../oracleLinux";
import { AlmaLinuxIcon } from "../alma-linux";

const CommonLogo = styled("img")`
  height: 30px;
  width: 30px;
`;

export const AdvisoryVendorIcon: FC<{ id: string; size?: string }> = ({
  id,
  size,
}) => {
  let upper = id.toUpperCase();

  const commonProps = {
    sx: {
      fontSize: size || "large",
    },
    "data-testid": "advisory-icon",
  };

  if (upper.startsWith("RHSA")) {
    return <RedHatIcon {...commonProps} />;
  } else if (upper.startsWith("FEDORA")) {
    return <FedoraIcon {...commonProps} />;
  } else if (upper.startsWith("CESA")) {
    return <CentosIcon {...commonProps} />;
  } else if (upper.startsWith("DSA") || upper.startsWith("DLA")) {
    return <DebianIcon {...commonProps} />;
  } else if (upper.startsWith("KB")) {
    return <WindowsIcon {...commonProps} />;
  } else if (upper.startsWith("USN")) {
    return <UbuntuIcon {...commonProps} />;
  } else if (upper.startsWith("SUSE") || upper.startsWith("OPENSUSE")) {
    return <SuseIcon {...commonProps} />;
  } else if (upper.startsWith("ALAS") || upper.startsWith("ALAS2")) {
    return <AwsIcon {...commonProps} />;
  } else if (upper.startsWith("PHSA")) {
    // vmware photon project
    return <VMWareIcon {...commonProps} />;
  } else if (upper.startsWith("ASA")) {
    return <CommonLogo {...commonProps} />;
  } else if (upper.startsWith("NODEJS")) {
    return <NodejsIcon {...commonProps} />;
  } else if (upper.startsWith("NPMJS")) {
    return <NpmIcon {...commonProps} />;
  } else if (upper.startsWith("GHSA")) {
    return <GithubIcon {...commonProps} />;
  } else if (upper.startsWith("RUSTSEC")) {
    // rustsec project
    return <CommonLogo {...commonProps} />;
  } else if (upper.startsWith("FRIENDSOFPHP")) {
    return <PhpIcon {...commonProps} />;
  } else if (upper.startsWith("MFSA")) {
    return <MozillaIcon {...commonProps} />;
  } else if (upper.startsWith("MS-EDGE")) {
    return <MSEdgeIcon {...commonProps} />;
  } else if (upper.startsWith("VSCODE")) {
    return <VSCodeIcon {...commonProps} />;
  } else if (upper.startsWith("CHROME")) {
    return <ChromeIcon {...commonProps} />;
  } else if (upper.startsWith("ELSA")) {
    return <OracleLinuxIcon {...commonProps} />;
  } else if (upper.startsWith("ALSA")) {
    return <AlmaLinuxIcon {...commonProps} />;
  } else {
    return <AdvisoryIcon {...commonProps} />;
  }
};
