export const DisableIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00016 1.33325C4.32016 1.33325 1.3335 4.31992 1.3335 7.99992C1.3335 11.6799 4.32016 14.6666 8.00016 14.6666C11.6802 14.6666 14.6668 11.6799 14.6668 7.99992C14.6668 4.31992 11.6802 1.33325 8.00016 1.33325ZM2.66683 7.99992C2.66683 5.05325 5.0535 2.66659 8.00016 2.66659C9.2335 2.66659 10.3668 3.08659 11.2668 3.79325L3.7935 11.2666C3.08683 10.3666 2.66683 9.23325 2.66683 7.99992ZM8.00016 13.3333C6.76683 13.3333 5.6335 12.9133 4.7335 12.2066L12.2068 4.73325C12.9135 5.63325 13.3335 6.76658 13.3335 7.99992C13.3335 10.9466 10.9468 13.3333 8.00016 13.3333Z"
        fill="white"
      />
    </svg>
  );
};
