import { useState } from "react";
import {
  Box,
  Button,
  ButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import { KeyIcon } from "~/components/icons";
import { DynamicResourceIcon } from "./get-icon";

export function Legend() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick: ButtonProps["onClick"] = (e) =>
    setAnchorEl(e.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const defineColor = (type: string) => {
    switch (type.toLowerCase()) {
      case "linked_resource":
        return "primary.main";
      case "error":
        return "error.light";
      default:
        return "text.primary";
    }
  };

  return (
    <Box>
      <Button
        id="legend-button"
        variant="text"
        aria-controls={open ? "legend-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        endIcon={<KeyIcon />}
        sx={{ mx: 1.5 }}
      >
        Legend
      </Button>
      <Menu
        id="legend-menu"
        aria-labelledby="legend-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ p: 2 }}
      >
        {list.map((x) => (
          <MenuItem
            key={x.type}
            sx={{ width: 200, cursor: "default" }}
            onClick={handleClose}
            disableRipple
          >
            <ListItemIcon>
              <DynamicResourceIcon identifier={x.type} />
            </ListItemIcon>
            <ListItemText
              primaryTypographyProps={{ color: defineColor(x.type) }}
            >
              {x.displayName}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

const list = [
  { type: "resource", displayName: "Resource" },
  { type: "linked_resource", displayName: "Linked Resource" },
  { type: "asset", displayName: "Asset (with score)" },
  { type: "array", displayName: "Array" },
  { type: "string", displayName: "String" },
  { type: "number", displayName: "Number" },
  { type: "boolean", displayName: "Boolean" },
  { type: "date_time", displayName: "Date/Time" },
  { type: "regular_expression", displayName: "Regular Expression" },
  { type: "null", displayName: "Null" },
  { type: "error", displayName: "Error" },
];
