import { SpeechBubbleIcon } from "~/pages/compliance/exception-icon";
import { Tooltip } from "@mui/material";

export const PendingExceptionsTooltip = () => {
  return (
    <Tooltip title="Exceptions pending review" placement="top" arrow>
      <SpeechBubbleIcon color="warning" sx={{ fontSize: 16 }} />
    </Tooltip>
  );
};
