import { Link as RouterLink } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { Space } from "~/lib/types";
import { mapIntegrationRouteToTitle } from "../hosted-integrations/helpers";
import { EmptyRow } from "~/components/data-table";

export type IntegrationsTableEmptyRowProps = {
  space: Space;
  integrationTypeId: string | undefined;
};

export function IntegrationsTableEmptyRow({
  space,
  integrationTypeId,
}: IntegrationsTableEmptyRowProps) {
  const title = mapIntegrationRouteToTitle(integrationTypeId);
  let url = `/space/integrations/add/mondoo/${integrationTypeId}?spaceId=${space.id}
`;
  if (integrationTypeId === "managed") {
    url = `/space/integrations/add/mondoo/setup?spaceId=${space.id}`;
  }

  return (
    <EmptyRow>
      <Typography color="text.secondary" sx={{ mb: 3 }}>
        You currently don't have any {title} integrations. Get started by adding
        one.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={RouterLink}
        to={url}
      >
        {`add ${title} integration`}
      </Button>
    </EmptyRow>
  );
}
