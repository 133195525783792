import {
  Box,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { DynamicResourceIcon } from "./get-icon";
import { FormatDate } from "~/lib/date";
import { Field, Resource } from "~/operations";
// To check if date is valid
import { isValid } from "date-fns";

type GenericRowProps = {
  item: Resource | Field;
  handleClick?: any;
  active: boolean;
};

export const ResourceDateRow = ({
  item,
  handleClick,
  active,
}: GenericRowProps) => {
  //remove quotes from value
  const value =
    item.__typename === "Field" ? item.value.replace(/['"]+/g, "") : "";
  const parsedDate = new Date(value);
  const isValidDate = isValid(parsedDate);

  return (
    <ListItem
      disablePadding
      className={active ? "active" : ""}
      sx={{
        px: 2,
        alignItems: "baseline",
        "&:hover": {
          backgroundColor: "background.lighter",
          cursor: "pointer",
        },
        "&.active": {
          backgroundColor: "background.light",
        },
      }}
      onClick={handleClick}
    >
      <ListItemIcon sx={{ minWidth: 24 }}>
        <DynamicResourceIcon
          identifier={item.type}
          sx={{ fontSize: "14px", color: "text.primary" }}
        />
      </ListItemIcon>
      <ListItemText
        primary={<Typography sx={{ ...textEllipses }}>{item.name}</Typography>}
        secondary={
          <Typography
            variant="caption"
            sx={{ ...textEllipses, textTransform: "capitalize" }}
          >
            {item.type}
          </Typography>
        }
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "flex-end",
          minWidth: 100,
        }}
      >
        <Typography fontSize={12} color="text.secondary">
          {isValidDate ? FormatDate(value) : "Error parsing date"}
        </Typography>
      </Box>
    </ListItem>
  );
};

export const ResourceStringRow = ({
  item,
  handleClick,
  active,
}: GenericRowProps) => {
  //remove quotes from value
  const value =
    item.__typename === "Field" ? item.value.replace(/['"]+/g, "") : "";
  return (
    <ListItem
      disablePadding
      className={active ? "active" : ""}
      sx={{
        px: 2,
        alignItems: "baseline",
        "&:hover": {
          backgroundColor: "background.lighter",
          cursor: "pointer",
        },
        "&.active": {
          backgroundColor: "background.light",
        },
      }}
      onClick={handleClick}
    >
      <ListItemIcon sx={{ minWidth: 24 }}>
        <DynamicResourceIcon
          identifier={item.type}
          sx={{ fontSize: "14px", color: "text.primary" }}
        />
      </ListItemIcon>
      <ListItemText
        primary={<Typography sx={{ ...textEllipses }}>{item.name}</Typography>}
        secondary={
          <Typography
            variant="caption"
            sx={{ ...textEllipses, textTransform: "capitalize" }}
          >
            {item.type}
          </Typography>
        }
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "flex-end",
          maxWidth: 120,
        }}
      >
        <Typography
          fontSize={12}
          color="text.secondary"
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {value}
        </Typography>
      </Box>
    </ListItem>
  );
};

export const GenericResourceRow = ({
  item,
  handleClick,
  active,
}: GenericRowProps) => {
  return (
    <ListItem
      disablePadding
      className={active ? "active" : ""}
      sx={{
        px: 2,
        alignItems: "baseline",
        "&:hover": {
          backgroundColor: "background.lighter",
          cursor: "pointer",
        },
        "&.active": {
          backgroundColor: "background.light",
        },
      }}
      onClick={handleClick}
    >
      <ListItemIcon sx={{ minWidth: 24 }}>
        <DynamicResourceIcon type={item.type} sx={{ fontSize: "14px" }} />
      </ListItemIcon>
      <ListItemText
        primary={<Typography sx={{ ...textEllipses }}>{item.name}</Typography>}
        secondary={
          <Typography variant="caption" sx={{ ...textEllipses }}>
            {item.__typename}: {item.type}
          </Typography>
        }
      />
      {(item.nestedContent || item.__typename !== "Field") && (
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "flex-end",
            minWidth: 100,
          }}
        >
          <Typography>{item.numElements} fields</Typography>
          <IconButton
            edge="end"
            aria-label="view-field"
            disableRipple
            sx={{ mt: 0, p: 0 }}
          >
            <ChevronRightIcon />
          </IconButton>
        </Box>
      )}
    </ListItem>
  );
};

export const DynamicResourceRow = ({
  item,
  handleClick,
  active,
}: GenericRowProps) => {
  switch (item.type.toLowerCase()) {
    case "time":
      return (
        <ResourceDateRow
          {...{
            active,
            item,
            handleClick,
          }}
        />
      );
    case "string":
      return (
        <ResourceStringRow
          {...{
            active,
            item,
            handleClick,
          }}
        />
      );
    default:
      return (
        <GenericResourceRow
          {...{
            active,
            item,
            handleClick,
          }}
        />
      );
  }
};

const textEllipses: TypographyProps = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
