import { Box, TableCell, Typography } from "@mui/material";
import { OverflowHelper } from "~/components/OverflowHelper";
import { cvssVersion } from "~/components/cvss";
import { Score2CvssScore } from "~/lib/score";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { PackageIcon } from "~/components/icons/mondoo/package";
import { CommonCellProps } from "~/pages/inventory/components/AssetSoftware/types";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";
import { Tag } from "~/operations";

export const TitleCell = ({ pckg, ...props }: CommonCellProps) => {
  let tags: Tag[] = [];
  // Manually build the tags array to display as chips
  if (pckg.cvssScore.value > 0) {
    tags.push({
      key: `CVSS${cvssVersion(pckg.cvssScore.vector)}`,
      value: Score2CvssScore(pckg.cvssScore.value) || "",
      __typename: "Tag",
    });
  }
  if (pckg.epssScore?.probability) {
    tags.push({
      key: "Worst EPSS",
      value: (Number(pckg.epssScore.probability) * 100).toFixed(1) + "%",
      __typename: "Tag",
    });
  }
  if (pckg.installedVersion) {
    tags.push({
      key: "Installed",
      value: pckg.installedVersion,
      __typename: "Tag",
    });
  }

  if (pckg.availableVersion) {
    tags.push({
      key: "Available",
      value: pckg.availableVersion,
      __typename: "Tag",
    });
  }

  return (
    <TableCell data-testid={props["data-testid"]}>
      <OverflowHelper>
        <Typography
          fontSize="14px"
          fontWeight="bold"
          style={{
            display: "flex",
            alignItems: "center",
          }}
          gap={1}
          data-testid="software-name"
        >
          <DynamicIcon iconId={pckg.iconIDEnum} defaultIcon={PackageIcon} />
          {pckg.packageName}
        </Typography>
      </OverflowHelper>
      <Box display="flex" gap={1} mt={1}>
        {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
      </Box>
    </TableCell>
  );
};
