import { DialogTitle, IconButton, Typography } from "@mui/material";
import { AttachIcon, CloseIcon } from "~/components/icons";
import React from "react";
import { Flex } from "~/components/ui-library";

type CaseModalTitleProps = { onClose: () => void };

export const CaseModalTitle = ({ onClose }: CaseModalTitleProps) => {
  return (
    <DialogTitle
      component="div"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        p: 5,
        pb: 2,
      }}
    >
      <Flex sx={{ alignItems: "center" }} gap={2}>
        <AttachIcon />
        <Typography
          variant="h5"
          sx={{
            textTransform: "uppercase",
            fontWeight: "700",
          }}
        >
          Create New Case
        </Typography>
      </Flex>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
