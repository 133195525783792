import { Dispatch, SetStateAction } from "react";
import { MQueryAssetScore } from "~/pages/compliance/control/control-outlets/data-queries/data-query";
import { AssetOrderField } from "~/operations";
import { ComplianceTable, Header } from "~/pages/compliance/compliance-table";
import { Space } from "~/lib/types";
import { PaginationRange } from "../pagination";
import { DataQueryRow } from "~/pages/compliance/control/control-outlets/DataQueryRow";

type DataQueryListViewProps = {
  tableHeaders: Header[];
  assets: MQueryAssetScore[];
  pageItems: PaginationRange;
  setPageItems: Dispatch<SetStateAction<PaginationRange>>;
  space: Space;
  queryMrn: string;
};

export function DataQueryListView({
  tableHeaders,
  assets,
  pageItems,
  setPageItems,
  space,
  queryMrn,
}: DataQueryListViewProps) {
  return (
    <ComplianceTable
      tableHeaders={tableHeaders}
      defaultActiveColumn={AssetOrderField.Name}
      paginationProps={{
        totalCount: assets.length,
        setPageItems,
      }}
    >
      {assets.slice(pageItems.from, pageItems.to).map((asset, i) => {
        const assetId = asset.assetMrn?.split("/").pop();
        const to = `/space/inventory/${assetId}/data-queries?spaceId=${space.id}&queryMrn=${queryMrn}`;
        return (
          <DataQueryRow
            key={`${asset?.assetMrn}-${i}`}
            asset={asset}
            queryMrn={queryMrn}
            index={i}
            to={to}
          />
        );
      })}
    </ComplianceTable>
  );
}
