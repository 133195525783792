import { styled } from "@mui/material";

export const OverflowHelper = styled("div")`
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: "&nbsp;";
    visibility: hidden;
  }

  & > * {
    position: absolute;
    left: 0;
    right: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
