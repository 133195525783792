import { createSvgIcon } from "@mui/material";

export const GoogleWSIcon = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22.56 12.25C22.56 11.47 22.49 10.72 22.36 10H12V14.26H17.92C17.66 15.63 16.88 16.79 15.71 17.57V20.34H19.28C21.36 18.42 22.56 15.6 22.56 12.25Z" />
    <path d="M11.9997 23C14.9697 23 17.4597 22.02 19.2797 20.34L15.7097 17.57C14.7297 18.23 13.4797 18.63 11.9997 18.63C9.13969 18.63 6.70969 16.7 5.83969 14.1H2.17969V16.94C3.98969 20.53 7.69969 23 11.9997 23Z" />
    <path d="M5.84 14.09C5.62 13.43 5.49 12.73 5.49 12C5.49 11.27 5.62 10.57 5.84 9.91001V7.07001H2.18C1.43 8.55001 1 10.22 1 12C1 13.78 1.43 15.45 2.18 16.93L5.03 14.71L5.84 14.09Z" />
    <path d="M11.9997 5.38C13.6197 5.38 15.0597 5.94 16.2097 7.02L19.3597 3.87C17.4497 2.09 14.9697 1 11.9997 1C7.69969 1 3.98969 3.47 2.17969 7.07L5.83969 9.91C6.70969 7.31 9.13969 5.38 11.9997 5.38Z" />
  </svg>,
  "GoogleWS",
);
