import { TableCell } from "@mui/material";
import { CommonCellProps } from "~/pages/inventory/components/Advisories/types";
import { Impact } from "~/components/impact";

type ImpactCellProps = {
  hideTooltip?: boolean;
};

export const ImpactCell = ({
  advisory,
  hideTooltip = false,
  ...props
}: CommonCellProps & ImpactCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Impact
        impact={advisory.riskScore ?? 0}
        isRiskScore
        hideTooltip={hideTooltip}
      />
    </TableCell>
  );
};
