namespace Config {
  export const VITE_AUTH_PROVIDER: string =
    window.AppConfig.VITE_AUTH_PROVIDER || import.meta.env.VITE_AUTH_PROVIDER;
  export const VITE_AUTH_PROVIDER_OIDC_AUTHORITY: string | null =
    window.AppConfig.VITE_AUTH_PROVIDER_OIDC_AUTHORITY ||
    import.meta.env.VITE_AUTH_PROVIDER_OIDC_AUTHORITY;
  export const VITE_AUTH_PROVIDER_OIDC_CLIENTID: string | null =
    window.AppConfig.VITE_AUTH_PROVIDER_OIDC_CLIENTID ||
    import.meta.env.VITE_AUTH_PROVIDER_OIDC_CLIENTID;
  export const VITE_AUTH_PROVIDER_OIDC_REDIRECT_URL: string | null =
    window.AppConfig.VITE_AUTH_PROVIDER_OIDC_REDIRECT_URL ||
    import.meta.env.VITE_AUTH_PROVIDER_OIDC_REDIRECT_URL;
  export const VITE_AUTH_PROVIDER_OIDC_SCOPE: string | null =
    window.AppConfig.VITE_AUTH_PROVIDER_OIDC_SCOPE ||
    import.meta.env.VITE_AUTH_PROVIDER_OIDC_SCOPE;
  export const VITE_FIREBASE_API_KEY: string | null =
    window.AppConfig.VITE_FIREBASE_API_KEY ||
    import.meta.env.VITE_FIREBASE_API_KEY;
  export const VITE_FIREBASE_AUTH_DOMAIN: string | null =
    window.AppConfig.VITE_FIREBASE_AUTH_DOMAIN ||
    import.meta.env.VITE_FIREBASE_AUTH_DOMAIN;
  export const VITE_HEAP_ID: string | null =
    window.AppConfig.VITE_HEAP_ID || import.meta.env.VITE_HEAP_ID;
  export const VITE_GOOGLE_ANALYTICS_KEY: string | null =
    window.AppConfig.VITE_GOOGLE_ANALYTICS_KEY ||
    import.meta.env.VITE_GOOGLE_ANALYTICS_KEY;
  export const VITE_HUBSPOT_REGION: string | null =
    window.AppConfig.VITE_HUBSPOT_REGION || import.meta.env.VITE_HUBSPOT_REGION;
  export const VITE_HUBSPOT_PORTAL_ID: string | null =
    window.AppConfig.VITE_HUBSPOT_PORTAL_ID ||
    import.meta.env.VITE_HUBSPOT_PORTAL_ID;
  export const VITE_HUBSPOT_CONTACT_FORM_ID: string | null =
    window.AppConfig.VITE_HUBSPOT_CONTACT_FORM_ID ||
    import.meta.env.VITE_HUBSPOT_CONTACT_FORM_ID;
  export const VITE_SENTRY_ENABLE: string | null =
    window.AppConfig.VITE_SENTRY_ENABLE || import.meta.env.VITE_SENTRY_ENABLE;
  export const VITE_SENTRY_DSN: string | null =
    window.AppConfig.VITE_SENTRY_DSN || import.meta.env.VITE_SENTRY_DSN;
  export const VITE_SENTRY_ENVIRONMENT: string | null =
    window.AppConfig.VITE_SENTRY_ENVIRONMENT ||
    import.meta.env.VITE_SENTRY_ENVIRONMENT;
  export const VITE_CHANGELOG_PATH: string | null =
    window.AppConfig.VITE_CHANGELOG_PATH || import.meta.env.VITE_CHANGELOG_PATH;
  export const VITE_COMMUNITY_INVITE_LINK: string | null =
    window.AppConfig.VITE_COMMUNITY_INVITE_LINK ||
    import.meta.env.VITE_COMMUNITY_INVITE_LINK;
  export const VITE_MONDOO_ACCOUNT_ID: string | null =
    window.AppConfig.VITE_MONDOO_ACCOUNT_ID ||
    import.meta.env.VITE_MONDOO_ACCOUNT_ID;
  export const VITE_API_URL: string | null =
    window.AppConfig.VITE_API_URL || import.meta.env.VITE_API_URL;
  export const VITE_REGIONS: string | null =
    window.AppConfig.VITE_REGIONS || import.meta.env.VITE_REGIONS;
  export const VITE_RELEASE: string | null =
    window.AppConfig.VITE_RELEASE || import.meta.env.VITE_RELEASE;
  export const VITE_PRIVATE_INSTANCE: string | null =
    window.AppConfig.VITE_PRIVATE_INSTANCE ||
    import.meta.env.VITE_PRIVATE_INSTANCE;
  export const VITE_SESSION_MAX_DURATION: string | null =
    window.AppConfig.VITE_SESSION_MAX_DURATION ||
    import.meta.env.VITE_SESSION_MAX_DURATION;
}

export { Config };
