import { useLayoutEffect } from "react";

//ScrollToTop is a utility component for use inside individual pages
//where we would like users to land on the top of the page.

//It only completes the scroll when a user navigates TO the page, scroll
//position is restored when using the back button.

export const ScrollToTop = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};
