import { useEffect, useState } from "react";
import { useSearch } from "~/components/search/useSearch";
import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  OrderDirection,
  PackageScoresOrder,
  PackageScoresOrderField,
  useGetAssetPackagesQuery,
  useGetAssetSupportsVulnerabilitiesQuery,
} from "~/operations";
import { CopyTableToClipboardProps } from "~/components/copy-table-to-clipboard";
import { CvssScoreType, Score2CvssScore } from "~/lib/score";
import { useSoftwareEmptyState } from "~/pages/inventory/components/AssetSoftware/hooks/useSoftwareEmptyState";
import { useFilterPackages } from "~/pages/inventory/components/AssetSoftware/SoftwareTypeGroup/hooks/useFilterPackages";

type UseAssetSoftwareParams = {
  assetMrn: string;
  isVulnerabilityScanningEnabled: boolean;
  clipboardDetails: CopyTableToClipboardProps["tableData"]["details"];
};

export function useAssetSoftware({
  assetMrn,
  isVulnerabilityScanningEnabled,
  clipboardDetails,
}: UseAssetSoftwareParams) {
  const { handleFilterQuery, handleClearSearch, searchFilters } = useSearch();
  const [stats, setStats] = useState<{
    packagesCount: number;
    applicationsCount: number;
  }>({ packagesCount: 0, applicationsCount: 0 });
  const { handleSortClick, orderBy } = useSort<PackageScoresOrder>({
    defaultSort: {
      field: PackageScoresOrderField.RiskScore,
      direction: OrderDirection.Asc,
    },
    validFields: [
      "SCORE",
      "PACKAGE_NAME",
      "RISK_FACTORS",
      "FIRST_FOUND",
      "RISK_SCORE",
    ],
  });
  const { filter, handleFilter, displayedFilterTypes } = useFilterPackages({
    packagesCount: stats.packagesCount,
    applicationsCount: stats.applicationsCount,
  });

  const { loading, data, fetchMore, error } = useGetAssetPackagesQuery({
    variables: {
      input: {
        entityMrn: assetMrn,
        filter: { queryTerms: searchFilters, type: filter, isVulnerable: true },
      },
      orderBy,
      first: 10,
    },
    errorPolicy: "all",
  });

  const { data: assetSupportsData } = useGetAssetSupportsVulnerabilitiesQuery({
    variables: {
      assetMrn,
    },
  });

  const packageScores =
    data?.packageScores?.__typename === "PackageScores"
      ? data?.packageScores
      : undefined;

  useEffect(() => {
    setStats({
      applicationsCount: packageScores?.totalApps || 0,
      packagesCount: packageScores?.totalOsPackages || 0,
    });
  }, [data]);

  const { isEmptyState, emptyStateVariant } = useSoftwareEmptyState({
    packagesConnection: packageScores,
    isFilterApplied: Boolean(filter || searchFilters.length > 0),
    isPlatformSupported: Boolean(
      assetSupportsData?.assetSupportVulnerabilities,
    ),
    isVulnerabilityScanningEnabled,
    isNotFound: data?.packageScores?.__typename === "NotFoundError",
  });

  const tableData: CopyTableToClipboardProps["tableData"] = {
    columns: ["Package Name", "Installed", "Available", "Score"],
    rows: (packageScores?.edges?.flatMap((e) => e.node ?? []) || []).map(
      (pkg) => [
        pkg.packageName || "",
        pkg.installedVersion || "",
        pkg.availableVersion || "",
        `${CvssScoreType(pkg.cvssScore.value).toUpperCase()} (${Score2CvssScore(
          pkg.cvssScore.value,
        )})`,
      ],
    ),
    details: clipboardDetails,
  };

  const isScanningEmptyState =
    isEmptyState &&
    emptyStateVariant &&
    ["noVulnerablePackages", "notFoundError", "platformNotSupported"].includes(
      emptyStateVariant,
    );

  return {
    isLoading: loading,
    isLoadingFailed: error,
    isScanningEmptyState,
    emptyStateVariant,
    packageScores,
    showCopyToClipboard: packageScores?.totalCount !== 0,
    tableData,
    displayedFilterTypes,
    filter,
    handleFilter,
    searchFilters,
    handleClearSearch,
    handleFilterQuery,
    hasData: (packageScores?.totalCount || 0) > 0,
    handleSortClick,
    orderBy,
    fetchMore,
  };
}
