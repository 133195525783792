import { SectionHeading } from "~/components/DetailsPage/components";
import { Box, Grid, Typography } from "@mui/material";
import { CvssScoreBox } from "~/components/vulnerabilities";
import { Stat } from "~/components/DetailsPage/components/Stats/Stats";
import { AssetPackageDetailsFieldsFragment } from "~/operations";
import { SoftwareStats } from "./SoftwareStats";
import { SoftwareSummary } from "./SoftwareSummary";
import { SoftwareSources } from "./SoftwareSources";
import { SoftwareRiskFactors } from "./SoftwareRiskFactors";
import { SoftwareRemediation } from "~/pages/inventory/components/SoftwareDetails/SoftwareRemediation";

type SoftwareDescriptionProps = {
  stats: Stat[];
  software: AssetPackageDetailsFieldsFragment;
  assetMrn: string;
};

export const SoftwareDescription = ({
  stats,
  software,
  assetMrn,
}: SoftwareDescriptionProps) => {
  return (
    <Box id="description">
      <SectionHeading heading="Description" />
      <Typography textTransform="uppercase" fontWeight={700} mb={1}>
        Summary
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <SoftwareStats stats={stats} />
          <SoftwareSummary description={software.description} />
          <SoftwareSources />
        </Grid>
        <Grid item xs={3}>
          <CvssScoreBox
            id="software-cvss-score-box"
            value={software.cvssScore.value}
            sx={{ mx: "auto" }}
          />
        </Grid>
      </Grid>
      <SoftwareRemediation software={software} spaceMrn={assetMrn} />
      <SoftwareRiskFactors software={software} />
    </Box>
  );
};
