import { createSvgIcon } from "@mui/material";

export const AppleAuthIcon = createSvgIcon(
  <svg viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.02435 17.6443C-2.03367 24.6863 0.546088 35.3728 5.0336 41.8524C7.27358 45.0919 9.54141 47.9998 12.6476 47.9998C12.7061 47.9998 12.7649 47.9987 12.825 47.9963C14.2843 47.9381 15.3389 47.4888 16.3586 47.0546C17.5034 46.5667 18.6873 46.0623 20.5444 46.0623C22.3112 46.0623 23.4386 46.5503 24.5289 47.0221C25.6035 47.487 26.7104 47.968 28.3459 47.9373C31.8447 47.8722 33.9929 44.7343 35.8883 41.9662C37.866 39.0759 38.8582 36.2697 39.1929 35.1973L39.2064 35.1547C39.2702 34.9641 39.1802 34.756 38.9974 34.6721C38.9924 34.6698 38.9738 34.6621 38.9686 34.6599C38.3525 34.4079 32.9478 32.0102 32.891 25.4752C32.8383 20.1659 36.9455 17.3543 37.7693 16.8447L37.8077 16.8208C37.8969 16.7633 37.9591 16.6722 37.9801 16.568C38.0012 16.464 37.9792 16.3557 37.9194 16.2682C35.0844 12.1194 30.7391 11.4941 28.9897 11.4189C28.7359 11.3935 28.4738 11.3808 28.2107 11.3808C26.1559 11.3808 24.1876 12.1568 22.6062 12.7804C21.5147 13.2108 20.5718 13.5825 19.9215 13.5825C19.1908 13.5825 18.2426 13.2062 17.1446 12.7706C15.6762 12.1878 14.0119 11.5275 12.2501 11.5275C12.208 11.5275 12.1667 11.5279 12.1259 11.5287C8.02931 11.589 4.15841 13.9327 2.02435 17.6443Z"
      fill="#AAAAAA"
    />
    <path
      d="M28.9915 0.000302394C26.5107 0.10134 23.5348 1.62839 21.7561 3.71178C20.2445 5.46293 18.7678 8.38263 19.1568 11.3256C19.1812 11.5098 19.3311 11.6519 19.5163 11.6662C19.6838 11.6793 19.8547 11.6859 20.0249 11.686C22.4506 11.686 25.0674 10.344 26.8544 8.18323C28.7353 5.90155 29.6863 2.97335 29.3986 0.350471C29.3759 0.144934 29.1951 -0.00772394 28.9915 0.000302394Z"
      fill="#AAAAAA"
    />
  </svg>,
  "Apple",
);
