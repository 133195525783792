import { Fragment, ReactNode } from "react";
import { Box, Button, ButtonProps, Typography } from "@mui/material";
import blueArrow from "~/components/guides/aws/media/gradient-arrow.svg";

type AwsButtonProps = {
  icon: ReactNode;
  text: string;
  selected: boolean;
  index: number;
} & ButtonProps;

export const AwsButton = ({
  icon,
  text,
  selected,
  className,
  onClick,
  index,
  sx,
  ...rest
}: AwsButtonProps) => {
  const onLeft = index < 2;

  const determineBlockerPosition = (index: number) => {
    let position = "0";
    switch (index) {
      case 1:
        position = "calc(-100% - 4px)";
        break;
      case 2:
        position = "calc(-200% - 2px)";
        break;
      case 3:
        position = "calc(-300% - 4px)";
        break;
    }

    return position;
  };

  const determineLeftHelperPosition = (index: number) => {
    if (!onLeft) return "unset";
    const even = isEven(index);
    return even ? "2%" : "27%";
  };

  const determineRightHelperPosition = (index: number) => {
    if (onLeft) return "unset";
    const even = isEven(index);
    return even ? "27%" : "2%";
  };

  const isEven = (num: number) => num % 2 === 0;

  return (
    <Button
      onClick={onClick}
      className={className}
      sx={[
        // needed to allow cursor modifications
        {
          "&.MuiButtonBase-root.no-select": {
            cursor: "not-allowed",
            pointerEvents: "auto",
          },
          "&.MuiButtonBase-root.aws .helper": {
            display: "block",
            zIndex: 10,
          },
          "&.MuiButtonBase-root:hover": {
            ".blocker, .helper": {
              display: "block",
              zIndex: 20,
            },
          },
        },
        {
          display: "block",
          minHeight: {
            xs: 156,
            md: 176,
          },
          width: 1,
          backgroundColor: "background.default",
          ...(selected && {
            backgroundColor: "background.light",
            "&:hover": {
              backgroundColor: "background.lighter",
            },
          }),
          color: "text.primary",
          textTransform: "none",
          border: "1px solid",
          borderColor: "background.light",
          borderRadius: 0,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...rest}
    >
      {" "}
      <Box>
        {icon}
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: {
              xs: 10,
              sm: 14,
              md: 16,
            },
          }}
        >
          {text}
        </Typography>
      </Box>
      <Box
        className={"blocker"}
        sx={{
          position: "absolute",
          height: 100,
          width: "calc(400% + 4px)",
          top: "calc(100% + 2px)",
          left: determineBlockerPosition(index),
          backgroundColor: "background.lighter",
          overflow: "hidden",
          pointerEvents: "none",
        }}
      >
        <Box
          className="helper"
          sx={[
            {
              position: "absolute",
              display: "none",
              pt: 1,
              width: "70%",
              height: "50%",
              top: 4,
              left: determineLeftHelperPosition(index),
              right: determineRightHelperPosition(index),
            },
            {
              "&:before": {
                content: '""',
                position: "absolute",
                width: "calc(100% + 2px)",
                height: "100%",
                left: 0,
                top: 4,
              },
            },
          ]}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: onLeft ? "row" : "row-reverse",
              left: onLeft ? "15%" : "unset",
              right: onLeft ? "unset" : "15%",
            }}
          >
            <Box
              component="img"
              src={blueArrow}
              sx={{
                transform: onLeft ? "unset" : "scale(-1,1)",
              }}
            />
            <Typography
              lineHeight={"18.75px"}
              sx={{
                alignSelf: "flex-end",
                pl: onLeft ? 2 : 0,
                pr: onLeft ? 0 : 2,
                textAlign: onLeft ? "left" : "right",
                fontSize: {
                  xs: 12,
                  sm: 16,
                },
              }}
            >
              {index === 0 ? (
                <Fragment>
                  Mondoo's got a lot of them - <br /> for now, we want to pick
                  this.
                </Fragment>
              ) : (
                <Fragment>
                  Let's try this later - <br />
                  we'll stick with AWS for now!
                </Fragment>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Button>
  );
};
