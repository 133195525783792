import { OverflowHelper } from "~/components/OverflowHelper";
import { Box, Chip, TableCell, Typography } from "@mui/material";
import { AdvisoryVendorIcon } from "~/components/icons/mondoo/utils/advisoryVendorIcon";
import { advisoryTitle } from "~/lib/types";
import { Score2CvssScore } from "~/lib/score";
import { SoftwareDetailsAdvisoryCellProps } from "./types";

export const AdvisoryCell = ({
  advisory,
  ...props
}: SoftwareDetailsAdvisoryCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <OverflowHelper>
        <Typography
          fontSize="14px"
          style={{
            display: "flex",
            alignItems: "center",
          }}
          gap={1}
          data-testid="advisory-id"
        >
          <AdvisoryVendorIcon id={advisory.id} />
          {advisory.id} {advisoryTitle(advisory)}
        </Typography>
      </OverflowHelper>
      <Box display="flex" gap={1} mt={1}>
        <Chip
          label={`CVSS3 ${Score2CvssScore(advisory.cvssScore.value)}`}
          variant="filled"
          size="small"
          sx={{ borderRadius: "4px" }}
          data-testid="advisory-score"
        />
      </Box>
    </TableCell>
  );
};
