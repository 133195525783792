import { createSvgIcon } from "@mui/material";

export const PartialFleetIcon = createSvgIcon(
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6.5" y="10" width="4" height="4" rx="0.5" stroke="currentColor" />
    <rect x="2.5" y="10" width="4" height="4" rx="0.5" stroke="currentColor" />
    <rect x="6.5" y="2" width="4" height="4" rx="0.5" stroke="currentColor" />
    <rect x="8.5" y="6" width="4" height="4" rx="0.5" stroke="currentColor" />
  </svg>,
  "PartialFleet",
);
