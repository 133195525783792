function loadGA(GA_TAG) {
  if (GA_TAG == null) {
    console.log("%c Failed to initialize Google Analytics", "color: tomato;");
    return;
  }

  if (GA_TAG === "development-mode") {
    console.log(
      "%c Google Analytics disabled in development mode",
      "color: #987bea",
    );
    return;
  }

  if (GA_TAG === "edge-mode") {
    console.log(
      "%c Google Analytics is not currently in use on Edge",
      "color: #987bea",
    );
    return;
  }

  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  var tag = document.createElement("script");
  (tag.type = "text/javascript"),
    (tag.async = true),
    (tag.src = "https://www.googletagmanager.com/gtag/js?id=" + GA_TAG);
  var a = document.getElementsByTagName("script")[0];
  a.parentNode.insertBefore(tag, a);

  gtag("js", new Date());
  gtag("config", GA_TAG);
}

loadGA(
  window.AppConfig.VITE_GOOGLE_ANALYTICS_KEY ||
    import.meta.env.VITE_GOOGLE_ANALYTICS_KEY,
);
