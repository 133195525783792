import { useOrgSettingsOutletContext } from "./settings";
import { MembersMgmt } from "~/components/members";

export type MembersProps = {};

export function Members({}: MembersProps) {
  const { org, availablePermissions } = useOrgSettingsOutletContext();
  return (
    // Members Mgmt is being reused in both the space and org level
    <MembersMgmt {...{ org, availablePermissions }} />
  );
}
