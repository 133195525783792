import React, { forwardRef } from "react";
import { alpha, Box } from "@mui/material";

type SoftwareVersionUnitBaseProps = {
  color: string;
};

export const SoftwareVersionUnitBase = forwardRef<
  HTMLDivElement | HTMLLinkElement,
  SoftwareVersionUnitBaseProps
>(({ color, ...props }, ref) => (
  <Box
    {...props}
    ref={ref}
    sx={{
      border: `1px solid ${color}`,
      borderRadius: "2px",
      backgroundColor: alpha(color, 0.5),
      position: "relative",
      height: "8px",
    }}
  />
));
