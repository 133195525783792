import { CaseAssetsTable } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/CaseAssetsTable";
import { AssetsProps } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/types";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Box, Grid } from "@mui/material";
import React from "react";

export const AffectedAssets = ({
  assets,
  orderBy,
  urlContextType,
  contextId,
  filteredTotalCount,
  fetchMore,
  pageInfo,
  space,
  handleSortClick,
  totalCount,
}: AssetsProps) => {
  return (
    <Grid item xs={12}>
      <SectionHeading heading="Assets" />
      <Box className="section-content">
        <CaseAssetsTable
          assets={assets}
          contextId={contextId}
          fetchMore={fetchMore}
          filteredTotalCount={filteredTotalCount}
          handleSortClick={handleSortClick}
          orderBy={orderBy}
          pageInfo={pageInfo}
          space={space}
          urlContextType={urlContextType}
          totalCount={totalCount}
        />
      </Box>
    </Grid>
  );
};
