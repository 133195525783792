import {
  FormControl,
  Grid2 as Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { FieldLabel } from "~/pages/space/Workspaces/components/FormFields/FieldLabel";
import { EditOutlined } from "@mui/icons-material";
import React, { Fragment, useState } from "react";
import { RefreshIcon } from "~/components/icons";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";
import { Validations } from "~/lib/validations";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormRegister } from "react-hook-form";
import {
  CreateFormInput,
  TargetType,
} from "~/pages/space/Workspaces/components/FormFields/types";

type SuggestionReturn = { id: string; available: boolean };

type IdFieldProps = {
  onGenerateId?: (id?: string) => Promise<SuggestionReturn | null | undefined>;
  idSuggestion?: SuggestionReturn | null | undefined;
  errors: FieldErrors<{ id: string }>;
  register: UseFormRegister<CreateFormInput>;
  unavailableErrorMessage?: string;
  canEdit?: boolean;
  disabled?: boolean;
  target: TargetType;
};

export const IdField = ({
  onGenerateId,
  idSuggestion,
  errors,
  register,
  unavailableErrorMessage,
  canEdit = false,
  disabled = false,
  target,
}: IdFieldProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(!canEdit);

  const labelByTarget: Record<TargetType, string> = {
    organization: "Organization ID",
    space: "Space ID",
  };

  return (
    <Grid container size={{ xs: 12 }} gap={1}>
      <Grid>
        <FieldLabel
          id="id-input"
          label={labelByTarget[target]}
          caption={
            <>
              The unique ID used to identify this {target}.{" "}
              <b>You cannot change this ID later.</b> It is useful for certain
              operations on the command line, and to get support.
            </>
          }
        />
      </Grid>
      <Grid size={12}>
        {!isEditMode && (
          <Grid>
            <Typography
              component="span"
              sx={{ mx: 2 }}
              data-testid="suggestion-id"
            >
              {idSuggestion?.id}
            </Typography>
            <IconButton
              aria-label="edit id"
              onClick={() => {
                setIsEditMode(true);
              }}
              size="large"
            >
              <EditOutlined />
            </IconButton>
          </Grid>
        )}
        {isEditMode && (
          <Fragment>
            <FormControl fullWidth variant="outlined">
              <TextField
                id="id-input"
                placeholder={labelByTarget[target]}
                aria-describedby="id-helper-text"
                slotProps={{
                  input: {
                    sx: { backgroundColor: "code.background" },
                    endAdornment: canEdit ? (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="generate new id"
                          onClick={() => onGenerateId?.()}
                          size="large"
                        >
                          <RefreshIcon />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                  },
                }}
                disabled={disabled}
                error={Boolean(errors.id)}
                helperText={
                  Boolean(errors.id) && <ValidationMessage error={errors.id} />
                }
                {...register("id", {
                  validate: {
                    required: async (value) => {
                      const id = value;

                      if (
                        id == null ||
                        !id.match(Validations.idInvalidLengthRegex)
                      ) {
                        return Validations.idInvalidLengthErrorMsg;
                      } else if (
                        !id.match(Validations.idInvalidCharactersRegex)
                      ) {
                        return Validations.idInvalidCharactersErrorMsg;
                      }

                      return true;
                    },
                    available: async (value) => {
                      const suggestion = await onGenerateId?.(value);

                      if (suggestion?.available !== true) {
                        return unavailableErrorMessage;
                      }

                      return true;
                    },
                  },
                })}
              />
            </FormControl>
          </Fragment>
        )}
      </Grid>
    </Grid>
  );
};
