import { alpha, useTheme, Box, Tooltip } from "@mui/material";
import { getColor } from "~/lib/colors";
import {
  ArrowRightAltIcon,
  CheckIcon,
  CloseIcon,
  DataObjectIcon,
  RemoveIcon,
  ShieldFilledIcon,
  WarningAmberIcon,
} from "~/components/icons";

type Props = {
  status: string;
  colorOverride?: string;
  size?: "sm" | "md" | "lg";
  disableTooltip?: boolean;
};

export function ControlResultIcon({
  status,
  colorOverride,
  size = "md",
  disableTooltip = false,
}: Props) {
  const theme = useTheme();

  const getDiameter = (size: Props["size"]) => {
    switch (size) {
      case "sm":
        return theme.spacing(2.4);
      case "lg":
        return theme.spacing(6);
      case "md":
      default:
        return theme.spacing(4);
    }
  };

  const getFontSize = (size: Props["size"]) => {
    switch (size) {
      case "sm":
        return 14;
      case "lg":
        return 32;
      case "md":
      default:
        return 24;
    }
  };

  const diameter = getDiameter(size);
  const fontSize = getFontSize(size);

  let color: string;
  if (colorOverride) {
    color = colorOverride;
  } else {
    color = getColor(theme, status);
  }

  const iconStyles = {
    fontSize: fontSize,
    lineHeight: "16px",
    color,
  };

  const getIcon = (status: string) => {
    switch (status.toLowerCase()) {
      case "pass":
        return <CheckIcon sx={iconStyles} />;
      case "fail":
        return <CloseIcon sx={iconStyles} />;
      case "skip":
        return <ArrowRightAltIcon sx={iconStyles} />;
      case "errorScan":
      case "error":
        return (
          <WarningAmberIcon
            sx={{
              ...iconStyles,
              fontSize: fontSize * 0.8,
              mt: -0.25,
            }}
          />
        );
      case "security":
        return (
          <ShieldFilledIcon
            sx={{ ...iconStyles, fontSize: fontSize * 0.8 }}
            fill={color}
          />
        );
      case "data":
        return <DataObjectIcon sx={iconStyles} />;
      default:
        // unscored
        return <RemoveIcon sx={iconStyles} />;
    }
  };

  const toolTipContent = (status: string): string => {
    switch (status.toLowerCase()) {
      case "pass":
        return "Pass";
      case "fail":
        return "Fail";
      case "skip":
        return "Skipped";
      case "error":
      case "errorScan":
        return "Error";
      case "data":
        return "Data";
      default:
        return "Disabled";
    }
  };

  return (
    <Tooltip
      title={toolTipContent(status)}
      placement="top"
      arrow
      open={disableTooltip ? false : undefined}
    >
      <Box
        sx={[
          {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: diameter,
            height: diameter,
            borderRadius: "50%",
            background: alpha(color, 0.1),
          },
          disableTooltip
            ? {}
            : {
                transition: "box-shadow 300ms ease-in-out",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: `0px 0px 0px 1px ${color}`,
                },
              },
        ]}
      >
        {getIcon(status)}
      </Box>
    </Tooltip>
  );
}
