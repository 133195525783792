import { usePolicyAddDetailOutletContext } from "./policies-add-detail";
import { PolicyProperties } from "./components/PolicyProperties";

export type PolicyAddDetailPropertiesPageProps = {};

export function PolicyAddDetailPropertiesPage({}: PolicyAddDetailPropertiesPageProps) {
  const { space, availablePermissions, policyId, variantPlatformFilter } =
    usePolicyAddDetailOutletContext();
  return (
    <PolicyProperties
      space={space}
      policyId={policyId}
      variantPlatformFilter={variantPlatformFilter}
    />
  );
}
