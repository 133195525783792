import { AutocompleteProps, IconButton } from "@mui/material";
import { SplitDownArrowIcon } from "~/components/icons";
import { Flex } from "~/components/ui-library";
import { Search } from "~/components/search/search";
import { useSearch } from "~/components/search/useSearch";

interface FilterBarProps {
  searchId: string; // id for search component
  placeholder: string;
  searchFilters: string[];
  handleFilterQuery: AutocompleteProps<string, true, false, true>["onChange"];
  showFilters?: boolean;
  mb?: number; // margin bottom if desired => TODO remove mb on all filter bars by default
}

export const FilterBar = ({
  searchId,
  placeholder,
  searchFilters,
  handleFilterQuery,
  showFilters = false,
  mb = 3,
}: FilterBarProps) => {
  const { onTagDelete } = useSearch();

  return (
    <Flex sx={{ mb: mb, gap: 5 }}>
      <Search
        id={searchId}
        onDelete={onTagDelete}
        {...{
          searchFilters,
          handleFilterQuery,
          placeholder,
        }}
      />
      {showFilters && (
        <IconButton sx={{ alignSelf: "center" }}>
          <SplitDownArrowIcon />
        </IconButton>
      )}
    </Flex>
  );
};
