import { Link as RouterLink } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { AddIcon } from "./icons";

type Props = {
  id?: string;
  label: string;
  minHeight?: number;
  to: string;
};

export function AddAnElementCard({ label, minHeight, to, id }: Props) {
  const color = "#7B7B7B";

  // border image generated by https://kovart.github.io/dashed-border-generator/
  const backgroundImage = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%237B7B7B' strokeWidth='2' stroke-dasharray='12' stroke-dashoffset='0' strokeLinecap='square'/%3e%3c/svg%3e")`;

  return (
    <Box
      {...(id ? { id: `add-element-${id}` } : {})}
      {...(id ? { "data-name": `add-element-${id}` } : {})}
      component={RouterLink}
      to={to}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="calc(100% - 2px)"
      sx={{
        boxSizing: "border-box",
        minHeight: minHeight,
        borderRadius: 1,
        backgroundImage,
        "&:hover": {
          ".hover-highlight": {
            color: (theme) => theme.palette.text.primary,
            transition: "325ms color ease-in",
          },
        },
      }}
    >
      <AddIcon
        className="hover-highlight"
        sx={{ color, fontSize: 48, transition: "325ms color ease-out" }}
      />
      <Typography
        className="hover-highlight"
        color={color}
        variant="body2"
        fontWeight={400}
        sx={{
          pt: 1,
          textTransform: "uppercase",
          transition: "325ms color ease-in-out",
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}
