import { createSvgIcon } from "@mui/material";

export const GcsBucketIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4214 43.5689L0.577713 26.5184C0.199246 25.8629 0 25.1193 0 24.3624C0 23.6054 0.199246 22.8618 0.577713 22.2063L10.4214 5.15624C10.8 4.50062 11.3444 3.9562 12 3.5777C12.6557 3.1992 13.3994 2.99995 14.1564 3H33.8438C34.6008 3.00002 35.3445 3.19929 36 3.57779C36.6556 3.95629 37.2 4.50068 37.5785 5.15624L47.4222 22.2063C47.8007 22.8619 48 23.6055 48 24.3626C48 25.1196 47.8007 25.8632 47.4222 26.5188L37.5785 43.5685C37.2 44.224 36.6556 44.7684 36 45.1469C35.3445 45.5254 34.6008 45.7247 33.8438 45.7247H14.1564C13.3994 45.7248 12.6556 45.5256 12 45.1471C11.3444 44.7686 10.7999 44.2245 10.4214 43.5689Z"
      fill="url(#paint0_linear_17420_234875)"
    />
    <mask
      id="mask0_17420_234875"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="3"
      width="48"
      height="43"
    >
      <path
        d="M10.4214 43.5689L0.577713 26.5184C0.199246 25.8629 0 25.1193 0 24.3624C0 23.6054 0.199246 22.8618 0.577713 22.2063L10.4214 5.15624C10.7999 4.50065 11.3443 3.95624 11.9998 3.57774C12.6554 3.19924 13.3991 2.99998 14.156 3H33.8435C34.6005 2.99995 35.3442 3.1992 35.9999 3.5777C36.6555 3.9562 37.1999 4.50062 37.5785 5.15624L47.4222 22.2063C47.8007 22.8619 48 23.6055 48 24.3626C48 25.1196 47.8007 25.8632 47.4222 26.5188L37.5785 43.5685C37.2 44.2241 36.6555 44.7686 35.9999 45.1471C35.3443 45.5256 34.6005 45.7248 33.8435 45.7247H14.156C13.3991 45.7247 12.6554 45.5255 11.9998 45.147C11.3443 44.7685 10.7999 44.2241 10.4214 43.5685V43.5689Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_17420_234875)">
      <path
        opacity="0.07"
        d="M18.4803 20.5657L15.8516 23.187L18.0854 25.4212L15.8737 29.3497L32.6118 46.0881L36.6677 46.0889L44.5888 31.8626L32.0463 19.3188L18.4803 20.5657Z"
        fill="black"
      />
    </g>
    <path
      d="M31.7586 19.2139H16.2201C15.97 19.2139 15.7656 19.4187 15.7656 19.6692V22.8814C15.7656 23.1312 15.9704 23.3359 16.2201 23.3359H31.7586C32.0087 23.3359 32.2134 23.1312 32.2134 22.8814V19.6684C32.2134 19.4187 32.0091 19.2136 31.7589 19.2136M29.353 22.2046C29.1066 22.2039 28.8706 22.1056 28.6965 21.9313C28.5224 21.757 28.4245 21.5209 28.4241 21.2746C28.424 21.1525 28.4479 21.0317 28.4946 20.919C28.5412 20.8062 28.6096 20.7038 28.6959 20.6175C28.7822 20.5312 28.8846 20.4628 28.9974 20.4162C29.1101 20.3695 29.2309 20.3456 29.353 20.3457C29.8667 20.3457 30.2829 20.7593 30.2829 21.2746C30.2829 21.3967 30.2589 21.5176 30.2122 21.6304C30.1654 21.7433 30.0969 21.8458 30.0106 21.9322C29.9242 22.0185 29.8217 22.087 29.7088 22.1338C29.596 22.1805 29.4751 22.2046 29.353 22.2046ZM31.7586 25.3887L16.2201 25.3894C15.97 25.3894 15.7656 25.5942 15.7656 25.8447V29.0565C15.7656 29.3063 15.9704 29.5114 16.2201 29.5114H31.7586C32.0087 29.5114 32.2134 29.3063 32.2134 29.0565V25.8439C32.2134 25.5942 32.0087 25.3887 31.7586 25.3887ZM29.353 28.3797C29.1066 28.3791 28.8705 28.2809 28.6964 28.1065C28.5223 27.9322 28.4244 27.696 28.4241 27.4497C28.424 27.3276 28.4479 27.2068 28.4946 27.0941C28.5412 26.9813 28.6096 26.8789 28.6959 26.7926C28.7822 26.7064 28.8846 26.6379 28.9974 26.5913C29.1101 26.5446 29.2309 26.5207 29.353 26.5208C29.4781 26.5158 29.603 26.5361 29.7202 26.5805C29.8373 26.6249 29.9442 26.6926 30.0346 26.7794C30.1249 26.8661 30.1968 26.9703 30.2459 27.0855C30.295 27.2008 30.3203 27.3248 30.3203 27.45C30.3203 27.5753 30.295 27.6993 30.2459 27.8145C30.1968 27.9298 30.1249 28.0339 30.0346 28.1207C29.9442 28.2075 29.8373 28.2751 29.7202 28.3196C29.603 28.364 29.4781 28.3847 29.353 28.3797Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_17420_234875"
        x1="24.0001"
        y1="3.00038"
        x2="24.0001"
        y2="45.6584"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4387FD" />
        <stop offset="1" stopColor="#4683EA" />
      </linearGradient>
    </defs>
  </svg>,
  "GCS Bucket",
);
