import { SharedPalette } from "~/types/material-ui";

// https://www.figma.com/design/L3iS3IX1XB1GhywMyqFnMD/Mondoo-Brand-Design-System?node-id=272-5148&t=z6cWWNaDf6GG4FQf-4
export const sharedPalette: SharedPalette = {
  primary: {
    main: "#9147FF",
    light: "#A07AFF",
    dark: "#5000C1",
    darker: "#3F137C", // used mainly for the top nav bar
    gradient: "linear-gradient(to bottom right, #9147FF 0%, #A07AFF 100%)",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#4C35E8",
    light: "#8797FF",
    dark: "#2A15B7",
    gradient: "linear-gradient(to bottom right, #4C35E8 0%, #8797FF 100%)",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#FF5252",
    light: "#FF7070",
    dark: "#4F0808",
    gradient: "linear-gradient(to left, #FF5252 0%, #FF7070 100%)",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FBD153",
    light: "#FFED9F",
    dark: "#755800",
    gradient: "linear-gradient(to left, #FBD153 0%, #FFED9F 100%)",
    contrastText: "#000000",
  },
  critical: {
    main: "#FC3779",
    light: "#FD6094",
    dark: "#DF074F",
    gradient: "linear-gradient(to left, #FD5CA4 0%, #FF7AB6 100%)",
    contrastText: "#FFFFFF",
  },
  high: {
    main: "#CF2584",
    light: "#DD519F",
    dark: "#BA2177",
    gradient: "linear-gradient(to left, #CF2584 0%, #DD519F 100%)",
    contrastText: "#FFFFFF",
  },
  medium: {
    main: "#039CD8",
    light: "#33C3FB",
    dark: "#0278A6",
    gradient: "linear-gradient(to left, #039CD8 0%, #33C3FB 100%)",
    contrastText: "#FFFFFF",
  },
  low: {
    main: "#2CBFC9",
    light: "#5FD4DB",
    dark: "#167C83",
    gradient: "linear-gradient(to left, #2CBFC9 0%, #5FD4DB 100%)",
    contrastText: "#000000",
  },
  // none and good are the same for CVE purposes; none will be treated differently
  // when looking at general-purpose reports
  none: {
    main: "#2FC089",
    light: "#6CD9AF",
    dark: "#238B66",
    gradient: "linear-gradient(to left, #2FC089 0%, #6CD9AF 100%)",
    contrastText: "#000000",
  },
  unknown: {
    main: "#CCCCCC",
    light: "#D9D9D9",
    dark: "#696969",
    gradient: "linear-gradient(to left, #CCCCCC 0%, #D9D9D9 100%)",
    contrastText: "#000000",
  },
  errorScan: {
    main: "#FF7849",
    light: "#FE936E",
    dark: "#A42A00",
    gradient: "linear-gradient(to left, #FF7849 0%, #FE936E 100%)",
    contrastText: "#000000",
  },
  // score rating
  fail: {
    main: "#FC3779",
    light: "#FD6094",
    dark: "#DF074F",
    gradient: "linear-gradient(to left, #FD5CA4 0%, #FF7AB6 100%)",
    contrastText: "#FFFFFF",
  },
  poor: {
    main: "#CF2584",
    light: "#DD519F",
    dark: "#BA2177",
    gradient: "linear-gradient(to left, #CF2584 0%, #DD519F 100%)",
    contrastText: "#FFFFFF",
  },
  fair: {
    main: "#039CD8",
    light: "#33C3FB",
    dark: "#0278A6",
    gradient: "linear-gradient(to left, #33C3FB 0%, #33C3FB 100%)",
    contrastText: "#FFFFFF",
  },
  good: {
    main: "#2CBFC9",
    light: "#5FD4DB",
    dark: "#167C83",
    gradient: "linear-gradient(to left, #2CBFC9 0%, #5FD4DB 100%)",
    contrastText: "#000000",
  },
  excellent: {
    main: "#2FC089",
    light: "#6CD9AF",
    dark: "#238B66",
    gradient: "linear-gradient(to left, #2FC089 0%, #6CD9AF 100%)",
    contrastText: "#000000",
  },
  unrated: {
    main: "#CCCCCC",
    light: "#D9D9D9",
    dark: "#696969",
    gradient: "linear-gradient(to left, #CCCCCC 0%, #D9D9D9 100%)",
    contrastText: "#000000",
  },
};
