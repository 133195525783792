import {
  Box,
  Button,
  ButtonProps,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "../icons";
import { useEpssRisk } from "./useEpssRisk";
import { CvssScoreType } from "~/lib/score";

export type EpssRiskTitle = {
  id?: string;
  open: boolean;
  onClick: ButtonProps["onClick"];
};

export function EpssRiskTitle({ id, open, onClick }: EpssRiskTitle) {
  const risk = useEpssRisk();
  const isInteracting = Boolean(risk.focusedMetric);
  return (
    <Box id={id}>
      <Button
        variant="text"
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={onClick}
        sx={{
          textAlign: "left",
          ml: -0.5,
          p: 0.5,
          ".MuiButton-endIcon": { color: "primary.main" },
        }}
      >
        <Typography textTransform="uppercase" fontWeight={700}>
          EPSS Score
        </Typography>
      </Button>
      <Box
        id={id}
        className="epss-vector"
        sx={{
          position: "relative",
          zIndex: 1,
          lineHeight: 1,
          cursor: "default",
        }}
      >
        {Object.values(risk.metrics).map((metric, i) => {
          const highlighted = risk.isFocused(metric);
          const key = metric.label.split(" ").join("-").toLowerCase();

          return (
            <Typography
              key={key}
              className={`metric ${highlighted ? "highlighted" : ""}`}
              component="span"
              textTransform="uppercase"
              fontWeight={600}
              fontSize={12}
              lineHeight={1}
              onMouseEnter={() => risk.onMetricMouseEnter(metric)}
              onMouseLeave={() => risk.onMetricMouseLeave(metric)}
              sx={{
                color: (theme) =>
                  isInteracting
                    ? alpha(theme.palette.text.secondary, 0.5)
                    : "text.secondary",
              }}
            >
              {i === 0 ? "" : " / "}
              <Box
                className="field"
                component="span"
                color={highlighted ? "text.primary" : "inherit"}
              >
                {metric.label}:{" "}
              </Box>
              <Box
                className="value"
                component="span"
                color={(theme) =>
                  highlighted
                    ? theme.palette[CvssScoreType(risk.cvssScore)].main
                    : "inherit"
                }
              >
                {metric.formattedValue}
              </Box>
            </Typography>
          );
        })}
      </Box>
    </Box>
  );
}
