import { Box, SxProps, Theme } from "@mui/material";

type Props = {
  sx?: SxProps<Theme>;
  color?: string;
  opacity?: string;
};

export function Wave({ sx, color = "white", opacity = "0.02" }: Props) {
  return (
    <Box
      component="svg"
      viewBox="0 0 1512 595"
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={sx}
    >
      <path
        d="M749.5 324.25C593.5 313.75 520 140.25 386.5 140.25C266.466 140.25 108.437 183.097 -1.90346e-07 242.623L-5.90027e-07 751.25L1512 751.25L1512 0.392579C1391.21 36.8685 1375.26 250.25 1221 250.25C1168 250.25 1122.5 223.75 1060.5 223.75C930.453 223.75 878.5 332.932 749.5 324.25Z"
        fill={color}
        fillOpacity={opacity}
      />
    </Box>
  );
}
