import { TooltipProps, Tooltip } from "@mui/material";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";

export type AppNavDrawerTooltipProps = {
  title: TooltipProps["title"];
  children: TooltipProps["children"];
  open?: TooltipProps["open"];
};

export function AppNavDrawerTooltip({
  title,
  children,
  open,
}: AppNavDrawerTooltipProps) {
  const navDrawer = useAppNavDrawer();
  return (
    <Tooltip
      title={title}
      open={open}
      placement="right"
      disableInteractive
      PopperProps={{
        sx: {
          ".MuiTooltip-tooltip": {
            display: navDrawer.open ? "none" : undefined,
            py: 0.75,
            px: 2,
            fontSize: 14,
            lineHeight: "24px",
            fontWeight: (theme) => theme.typography.fontWeightRegular,
          },
        },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -6],
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
}
