import { createSvgIcon } from "@mui/material";

export const PackerIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80.14 114.64"
    fill="currentColor"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Logo">
        <path d="M2.92,89.27V87.15H1v2.11H0V84.19H1v2.13H2.92V84.19h1v5.08Zm4.61,0H6.75L6.68,89a2.15,2.15,0,0,1-1.14.32c-.7,0-1-.46-1-1.09s.34-1,1.11-1h.92v-.38c0-.41-.12-.55-.73-.55a5.55,5.55,0,0,0-1.06.11l-.12-.7A5.17,5.17,0,0,1,6,85.53c1.2,0,1.55.41,1.55,1.32Zm-1-1.38H5.87c-.31,0-.4.08-.4.36s.09.37.38.37a1.55,1.55,0,0,0,.72-.19Zm3,1.46a4.67,4.67,0,0,1-1.32-.21l.13-.7a4.44,4.44,0,0,0,1.14.16c.42,0,.49-.09.49-.37s0-.34-.67-.48c-.93-.22-1-.44-1-1.15s.34-1.06,1.43-1.06a5.22,5.22,0,0,1,1.14.13l-.09.73a7,7,0,0,0-1-.11c-.42,0-.49.09-.49.32s0,.32.54.44c1.07.27,1.17.41,1.17,1.16S10.68,89.34,9.53,89.34Zm4.38-.08V86.71c0-.2-.09-.29-.31-.29a2.71,2.71,0,0,0-1,.31v2.54h-1V84.12l1,.14v1.62A3.48,3.48,0,0,1,14,85.53c.63,0,.86.43.86,1.08v2.66Zm1.76-4.18v-.9h1v.9Zm0,4.18V85.6h1v3.66Zm1.72-3.63c0-.92.56-1.45,1.86-1.45a6.14,6.14,0,0,1,1.42.17l-.11.82A8,8,0,0,0,19.27,85c-.68,0-.9.23-.9.76v1.93c0,.53.22.76.9.76a8,8,0,0,0,1.28-.13l.11.82a6.14,6.14,0,0,1-1.42.17c-1.3,0-1.86-.53-1.86-1.45Zm5.39,3.71c-1.31,0-1.66-.69-1.66-1.44V87c0-.75.35-1.44,1.66-1.44s1.66.69,1.66,1.44v.93C24.43,88.65,24.08,89.34,22.77,89.34Zm0-3c-.51,0-.71.22-.71.63v1c0,.41.2.63.71.63s.71-.22.71-.63v-1C23.48,86.53,23.28,86.31,22.77,86.31Zm4.35.06a7.58,7.58,0,0,0-1,.53v2.36h-1V85.6H26L26,86a4.35,4.35,0,0,1,1-.48ZM30.95,88a1.13,1.13,0,0,1-1.26,1.29,5.48,5.48,0,0,1-1-.11v1.5l-1,.14V85.6h.76l.09.31a2.06,2.06,0,0,1,1.21-.38c.77,0,1.18.44,1.18,1.27Zm-2.28.41a4.41,4.41,0,0,0,.85.1c.34,0,.48-.16.48-.49V86.78c0-.3-.12-.47-.47-.47a1.38,1.38,0,0,0-.85.33Z" />
        <path d="M0,93.36H8.18c4.85,0,6.64,2,6.64,5.67v2.86c0,3.65-2,5.63-6.83,5.63H4v6.8H0Zm8,3.49H4V104H8c2.24,0,2.9-.82,2.9-2.55V99.34C10.86,97.61,10.14,96.85,8,96.85Z" />
        <path d="M28.24,114.32H25.09l-.28-1a8.38,8.38,0,0,1-4.56,1.35c-2.8,0-4-1.92-4-4.56,0-3.12,1.35-4.31,4.47-4.31H24.4v-1.61c0-1.7-.47-2.3-2.93-2.3a21.42,21.42,0,0,0-4.25.47l-.47-2.93A20,20,0,0,1,22,98.68c4.82,0,6.23,1.7,6.23,5.54Zm-3.84-5.79H21.56c-1.26,0-1.61.35-1.61,1.51s.35,1.54,1.54,1.54a6,6,0,0,0,2.9-.79Z" />
        <path d="M30.53,108.75v-4.19c0-4.12,1.79-5.89,6.61-5.89a13.21,13.21,0,0,1,3.75.57l-.47,3.15a14,14,0,0,0-3.18-.41c-2.2,0-2.86.66-2.86,2.55v4.25c0,1.89.66,2.55,2.86,2.55a13,13,0,0,0,3.18-.41l.47,3.15a12.64,12.64,0,0,1-3.75.57C32.33,114.64,30.53,112.87,30.53,108.75Z" />
        <path d="M43.31,114.32V92.73l3.84-.54v22.13ZM56.35,99l-4.5,7.59,4.69,7.74H52.32l-4.63-7.74L52.07,99Z" />
        <path d="M64.34,111.49a16.06,16.06,0,0,0,4.69-.72l.6,2.9a16.46,16.46,0,0,1-5.64,1c-4.82,0-6.48-2.24-6.48-5.92v-4.06c0-3.24,1.45-6,6.36-6s6,2.86,6,6.17v3.27H61.35v.79C61.35,110.77,62,111.49,64.34,111.49Zm-3-6.45h4.88v-.76c0-1.45-.44-2.46-2.33-2.46s-2.55,1-2.55,2.46Z" />
        <path d="M80.14,102.2a30.06,30.06,0,0,0-4.06,2.24v9.88H72.24V99h3.24l.25,1.7a17.18,17.18,0,0,1,4-2Z" />
        <polygon
          fillRule="evenodd"
          points="16.41 9.46 41.5 23.96 41.5 77.41 16.41 62.92 16.41 9.46"
        />
        <path d="M53.92,15.71,26.65,0V10.91L45.19,21.62V54.35l8.73,5c5.4,3.12,9.82,1.25,9.82-4.15v-24C63.74,25.8,59.32,18.83,53.92,15.71Z" />
      </g>
    </g>
  </svg>,
  "Packer",
);
