import { createSvgIcon } from "@mui/material";

export const MsTeamsIcon = createSvgIcon(
  <svg viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.345 8.66809H22.0275C22.5644 8.66809 22.9996 9.10295 22.9996 9.63938V14.8113C22.9996 16.7828 21.4001 18.381 19.4269 18.381H19.4101C17.4369 18.3813 15.8372 16.7833 15.8369 14.8118C15.8369 14.8116 15.8369 14.8114 15.8369 14.8113V9.17573C15.8369 8.89536 16.0644 8.66809 16.345 8.66809Z"
      fill="#5059C9"
    />
    <path
      d="M20.1861 7.64573C21.4576 7.64573 22.4883 6.61579 22.4883 5.3453C22.4883 4.0748 21.4576 3.04486 20.1861 3.04486C18.9146 3.04486 17.8838 4.0748 17.8838 5.3453C17.8838 6.61579 18.9146 7.64573 20.1861 7.64573Z"
      fill="#5059C9"
    />
    <path
      d="M13.0228 7.64571C14.8594 7.64571 16.3483 6.15802 16.3483 4.32285C16.3483 2.48769 14.8594 1 13.0228 1C11.1862 1 9.69727 2.48769 9.69727 4.32285C9.69727 6.15802 11.1862 7.64571 13.0228 7.64571Z"
      fill="#7B83EB"
    />
    <path
      d="M17.4571 8.66809H8.07708C7.54661 8.68121 7.12698 9.12109 7.13929 9.65115V15.55C7.06521 18.7308 9.58377 21.3704 12.7671 21.4483C15.9504 21.3704 18.469 18.7308 18.3949 15.55V9.65115C18.4072 9.12109 17.9876 8.68121 17.4571 8.66809Z"
      fill="#7B83EB"
    />
    <path
      opacity="0.1"
      d="M13.2784 8.66809V16.9343C13.2758 17.3134 13.0459 17.6539 12.6951 17.7983C12.5834 17.8455 12.4634 17.8698 12.3421 17.8698H7.58917C7.52266 17.7011 7.46126 17.5324 7.4101 17.3586C7.23101 16.772 7.13963 16.1622 7.13894 15.549V9.64961C7.12664 9.1204 7.54558 8.68121 8.0752 8.66809H13.2784Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.7667 8.66809V17.4455C12.7667 17.5667 12.7424 17.6867 12.6951 17.7983C12.5507 18.1488 12.2099 18.3785 11.8305 18.381H7.82963C7.74265 18.2123 7.6608 18.0436 7.58917 17.8698C7.51754 17.696 7.46126 17.5324 7.4101 17.3586C7.23101 16.772 7.13963 16.1622 7.13894 15.549V9.64961C7.12664 9.1204 7.54558 8.68121 8.0752 8.66809H12.7667Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.7667 8.66809V16.4231C12.7628 16.9382 12.3459 17.3547 11.8305 17.3586H7.4101C7.23101 16.772 7.13963 16.1622 7.13894 15.549V9.64961C7.12664 9.1204 7.54558 8.68121 8.0752 8.66809H12.7667Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.2551 8.66809V16.4231C12.2512 16.9382 11.8343 17.3547 11.3188 17.3586H7.4101C7.23101 16.772 7.13963 16.1622 7.13894 15.549V9.64961C7.12664 9.1204 7.54558 8.68121 8.0752 8.66809H12.2551Z"
      fill="black"
    />
    <path
      opacity="0.1"
      d="M13.2793 6.02516V7.63547C13.1923 7.64057 13.1104 7.64569 13.0235 7.64569C12.9365 7.64569 12.8546 7.64058 12.7677 7.63547C12.595 7.62402 12.4237 7.59664 12.256 7.55368C11.22 7.30852 10.3641 6.58252 9.95376 5.60087C9.88316 5.43601 9.82835 5.26484 9.79004 5.08966H12.343C12.8593 5.09161 13.2773 5.5093 13.2793 6.02516Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.767 6.53639V7.63549C12.5943 7.62404 12.4231 7.59666 12.2554 7.5537C11.2194 7.30855 10.3634 6.58255 9.95312 5.60089H11.8308C12.347 5.60284 12.7651 6.02054 12.767 6.53639Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.767 6.53639V7.63549C12.5943 7.62404 12.4231 7.59666 12.2554 7.5537C11.2194 7.30855 10.3634 6.58255 9.95312 5.60089H11.8308C12.347 5.60284 12.7651 6.02054 12.767 6.53639Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M12.2554 6.5364V7.5537C11.2194 7.30855 10.3634 6.58255 9.95312 5.60089H11.3191C11.8354 5.60285 12.2534 6.02055 12.2554 6.5364Z"
      fill="black"
    />
    <path
      d="M1.93779 5.60089H11.3178C11.8357 5.60089 12.2556 6.02042 12.2556 6.53793V15.9104C12.2556 16.4279 11.8357 16.8474 11.3178 16.8474H1.93779C1.41986 16.8474 1 16.4279 1 15.9104V6.53793C1 6.02042 1.41987 5.60089 1.93779 5.60089Z"
      fill="url(#paint0_linear_3039_21030)"
    />
    <path
      d="M9.09624 9.1681H7.22116V14.27H6.02654V9.1681H4.16016V8.17841H9.09624V9.1681Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3039_21030"
        x1="2.95532"
        y1="4.8687"
        x2="10.2914"
        y2="17.5847"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#5A62C3" />
        <stop offset="0.5" stopColor="#4D55BD" />
        <stop offset="1" stopColor="#3940AB" />
      </linearGradient>
    </defs>
  </svg>,
  "Microsoft Teams",
);
