import { Grid, Box, Typography, Breadcrumbs, Link } from "@mui/material";
import { Outlet, Link as RouterLink, useOutletContext } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { IamActions } from "~/lib/iam";
import { Org } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";

export type OrganizationSettingsPageProps = {
  org: Org;
  availablePermissions: string[] | null;
};

type OrgSettingsOutletContextType = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  org: Org;
};

export function OrganizationSettingsPage({
  org,
  availablePermissions,
}: OrganizationSettingsPageProps) {
  const hasBillingPermissions = IamActions.billing().every((permission) =>
    availablePermissions?.includes(permission),
  );

  const href = (tab: string) => {
    return `/organization/settings/${tab}?organizationId=${org.id}`;
  };

  const tabList: TabListItem[] = [
    { label: "General Settings", to: href("general"), route: "/general" },
    { label: "Members", to: href("members"), route: "/members" },
    { label: "Audit Log", to: href("auditlog"), route: "/auditlog" },
    {
      label: "Authentication",
      to: href("authentication"),
      route: "/authentication",
    },
    {
      label: "API Tokens",
      to: href("api-tokens"),
      route: "/api-tokens",
    },
    {
      label: "Service Accounts",
      to: href("serviceaccounts"),
      route: "/serviceaccounts",
    },
    hasBillingPermissions
      ? { label: "Billing", to: href("billing"), route: "/billing" }
      : null,
    { label: "Legal & Compliance", to: href("legal"), route: "/legal" },
  ].flatMap((tab) => tab ?? []);

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "general",
  );

  const breadcrumbs = [
    <Link
      key="/organization/overview"
      component={RouterLink}
      to={`/organization/overview?organizationId=${org.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key="/organization/settings">Settings</Typography>,
  ];

  document.title = `Settings · ${org.name} · Mondoo`;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
          {breadcrumbs}
        </Breadcrumbs>
        {/* Heading */}
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 4 }}>
          <Typography variant="h4" component="h2" fontWeight={700}>
            Organization Settings
          </Typography>
        </Box>
        <TabNavigation {...{ id: "org-settings-tabs", currentTab, tabList }} />
      </Grid>
      <Grid item xs>
        <Outlet context={{ availablePermissions, org }} />
      </Grid>
    </Grid>
  );
}

export function useOrgSettingsOutletContext() {
  return useOutletContext<OrgSettingsOutletContextType>();
}
