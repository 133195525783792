import { Box, Button, Chip, Grid, Paper, Typography } from "@mui/material";
import { SubscriptionInfoQuery } from "~/operations";
import { BillingGauge } from "./components";
import { useColorMode } from "~/providers/color-mode";

type SubscriptionInfo = NonNullable<SubscriptionInfoQuery["subscriptionInfo"]>;

type ActiveSubscriptionsProps = {
  billingSessionUrl: string;
  subscriptionInfo: SubscriptionInfo;
  onQuotaRequest: () => void;
};

export function ActiveSubscriptions({
  billingSessionUrl,
  subscriptionInfo,
  onQuotaRequest,
}: ActiveSubscriptionsProps) {
  const { basePlan, utilizationMetrics, pendingCancel } = subscriptionInfo;
  const isCommunityPlan = basePlan.key === "plan_community";
  const isTeamPlan = basePlan.key === "plan_team";
  const isEnterprisePlan = basePlan.key === "plan_enterprise";
  const { mode } = useColorMode();

  let title = `You are on the ${basePlan.name}`;
  let description = "";
  let buttonText = "";

  if (isCommunityPlan) {
    buttonText = "Upgrade now";
    description =
      "Upgrade your membership today to unlock powerful features and premium support!";
  } else if (isTeamPlan) {
    if (pendingCancel) {
      buttonText = "Renew subscription";
      description =
        "Your subscription has been cancelled but will continue to work through your current billing period.";
    } else {
      buttonText = "Manage subscription";
      description =
        "Manage your payment data, billing information, or cancel your subscription.";
    }
  } else if (isEnterprisePlan) {
    buttonText = "Get in touch";
    description =
      "Please get in touch with us if you want to manage your subscription details, such as adding more assets or buying premium support.";
  }

  const statusChip = pendingCancel ? (
    <Chip label="Cancelled" color="primary" variant="outlined" size="small" />
  ) : null;
  const upgradeMsg = isTeamPlan ? (
    <Grid container item xs={12} p={2} alignItems="center">
      <Typography variant="body2" mr={1}>
        Need a bigger quota?
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={onQuotaRequest}
      >
        Contact Us
      </Button>
    </Grid>
  ) : null;

  return (
    <Grid container component={Paper}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "background.light",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
        p={0}
      >
        <Typography
          component="h3"
          variant="body1"
          sx={{ textTransform: "uppercase", padding: 2 }}
        >
          My Active Subscriptions
        </Typography>
      </Grid>
      <Grid container item>
        <Grid container item xs={12} p={"40px"} pb={2}>
          <Grid item xs={8}>
            <Typography variant="h5" sx={{ mb: 2, fontWeight: 700 }}>
              {title} {statusChip}
            </Typography>
            <Typography>{description}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box
              height={1}
              display="flex"
              alignItems="flex-start"
              justifyContent="right"
            >
              {billingSessionUrl && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  href={billingSessionUrl}
                >
                  {buttonText}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          p={"40px"}
          sx={{
            backgroundColor: mode === "light" ? "common.white" : "grey.900",
          }}
        >
          <Grid item xs={12}>
            <Typography variant="overline" color={"primary.main"}>
              Your current usage
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" flexDirection="row">
            {utilizationMetrics?.map((m) => (
              <BillingGauge
                key={m.name}
                title={m.name}
                value={m.value}
                total={m.limit || 0}
              />
            ))}
          </Grid>
        </Grid>
        {upgradeMsg}
      </Grid>
    </Grid>
  );
}
