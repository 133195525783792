import { styled } from "@mui/material/styles";
import { Code } from "~/components/code";
import { scoreDotColor } from "./score-card";
import { PolicyQueryAssessment } from "~/operations";

export type PolicyQueryAssessmentResultsProps = {
  assessment: PolicyQueryAssessment["rendered"];
  status: string;
};

export function PolicyQueryAssessmentResults({
  assessment,
  status,
}: PolicyQueryAssessmentResultsProps) {
  return (
    <ResultOutput className="ini" status={status}>
      {assessment}
    </ResultOutput>
  );
}

const ResultOutput = styled(Code)<{ status: string }>`
  .hljs-section {
    color: ${scoreDotColor};
  }
`;
