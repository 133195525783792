import { Box, Tooltip, alpha, useTheme } from "@mui/material";
import * as d3 from "d3";
import { Status } from "~/components/scores/mondoo-score-card";
import { useCvssRisk } from "./useCvssRisk";
import { CvssScoreType } from "~/lib/score";

export type CvssRadarProps = {};

export function CvssRadar({}: CvssRadarProps) {
  const theme = useTheme();
  const risk = useCvssRisk();
  const status: Status = CvssScoreType(risk.score * 10);
  const color = theme.palette[status].main;

  const isInteracting = Boolean(risk.focusedMetric);

  const metrics = risk.metrics;

  const padding = 10;
  const width = 260;
  const height = 260;
  const centerX = (width + padding) / 2;
  const centerY = (height + padding) / 2;

  const viewboxWidth = width + padding;
  const viewboxHeight = height + padding;
  const viewBox = `0 0 ${viewboxWidth} ${viewboxHeight}`;

  const metricLevels: Status[] = ["none", "low", "medium", "high", "critical"];
  const radiusScale = d3.scaleLinear([0, width / 2]);
  const levelScale = d3.scaleSequential().interpolator((d): Status => {
    switch (true) {
      case d > 0 && d <= 0.25:
        return "low";
      case d > 0.25 && d <= 0.5:
        return "medium";
      case d > 0.5 && d <= 0.75:
        return "high";
      case d > 0.75:
        return "critical";
    }
    return "none";
  });
  const highlightedValue = risk.focusedMetric?.value.scalar;
  const highlightedLevel =
    highlightedValue !== undefined ? levelScale(highlightedValue) : undefined;

  const polyPoints = metrics
    .map((metric, i) => {
      const radius = radiusScale(metric.value.scalar) || 0;
      const angle = (i / metrics.length) * Math.PI * 2 + Math.PI;
      const x = Math.sin(angle) * radius + centerX;
      const y = Math.cos(angle) * radius + centerY;
      return [x, y].join(",");
    })
    .join(" ");
  const polyStroke = !isInteracting ? color : alpha(theme.palette.divider, 0.5);
  const polyFill = !isInteracting ? alpha(color, 0.1) : "none";
  const polygon = (
    <polygon
      points={polyPoints}
      fill={polyFill}
      stroke={polyStroke}
      strokeWidth={2}
    />
  );

  // setup datapoint start position and rotation direction
  const clockwise = true;
  const rotateStart = 1;
  const rotateSize = 360 / metrics.length;
  const rotateY = clockwise ? 180 : 0;
  const rotateZ = rotateStart * rotateSize * (clockwise ? 1 : -1);

  return (
    <Box
      component="svg"
      viewBox={viewBox}
      className="cvss-radar"
      sx={{ maxWidth: viewboxWidth, margin: "0 auto", display: "block" }}
    >
      <Box
        component="g"
        sx={{
          transformOrigin: "center",
          transform: `rotateY(${rotateY}deg) rotateZ(${rotateZ}deg)`,
        }}
      >
        {isInteracting && polygon}
        <circle
          cx={centerX}
          cy={centerY}
          r={1}
          fill={alpha(theme.palette.common.white, 0.08)}
        />
        {metricLevels.map((level, levelIndex) => {
          const radius = (width / 2) * (levelIndex / 4);
          const points = Array.from(new Array(metrics.length)).map((_v, i) => {
            const angle = (i / metrics.length) * Math.PI * 2 + Math.PI;
            return {
              x: Math.sin(angle) * radius + centerX,
              y: Math.cos(angle) * radius + centerY,
            };
          });

          const lines = Array.from(new Array(metrics.length)).map((_v, i) => ({
            x1: points[i].x,
            y1: points[i].y,
            x2: points[(i + 1) % metrics.length].x,
            y2: points[(i + 1) % metrics.length].y,
          }));

          return (
            <g className={`metric-level ${level}`} key={level}>
              {lines.map((line, i) => {
                return (
                  <line
                    key={i}
                    x1={line.x1}
                    y1={line.y1}
                    x2={line.x2}
                    y2={line.y2}
                    stroke={
                      highlightedLevel === level
                        ? theme.palette[highlightedLevel].main
                        : alpha(theme.palette.divider, 0.08 + 0.08 * levelIndex)
                    }
                    strokeWidth={2}
                  />
                );
              })}
            </g>
          );
        })}
        {!isInteracting && polygon}
        {metrics.map((metric, i) => {
          const radius = radiusScale(metric.value.scalar) || 0;
          const angle = (i / metrics.length) * Math.PI * 2 + Math.PI;
          const x = Math.sin(angle) * radius + centerX;
          const y = Math.cos(angle) * radius + centerY;
          const fieldTitle = metric.field.title;
          const valueTitle = metric.value.title;
          const interactedTitle = risk.focusedMetric?.field.title;
          const highlighted = fieldTitle === interactedTitle;
          const tooltipTitle = `${fieldTitle}: ${valueTitle}`;
          return (
            <Tooltip
              key={i}
              title={tooltipTitle}
              placement="top"
              arrow
              open={highlighted}
            >
              <circle
                className={`metric-point ${metric.field.title} ${
                  highlighted ? "highlighted" : ""
                }`}
                cx={x}
                cy={y}
                r={4}
                fill={
                  highlighted
                    ? metric.value.color
                    : theme.palette.background.default
                }
                stroke={highlighted ? metric.value.color : polyStroke}
                strokeWidth={2}
                onMouseEnter={() => risk.onMetricMouseEnter(metric)}
                onMouseLeave={() => risk.onMetricMouseLeave(metric)}
              />
            </Tooltip>
          );
        })}
      </Box>
    </Box>
  );
}
