import { Box, Grid, Paper, Typography, Tooltip } from "@mui/material";
import { Clamp } from "~/components/clamp/clamp";
import { FrameworkIcon } from "../framework/framework-icon";
import {
  AvailableComplianceFramework,
  useAvailableFramework,
} from "./UseAvailableFrameworkCard";
import { Fragment } from "react";
import { ConfirmActivateFrameworkDialog } from "./ConfirmActivateFrameworkDialog";
import { Space } from "~/lib/types";
import { Flex } from "~/components/ui-library";

export function AvailableFrameworkCard({
  space,
  framework,
}: {
  space: Space;
  framework: AvailableComplianceFramework;
}) {
  const {
    activateFrameworkDialogOpen,
    downloadFrameworkButton,
    handleActivateFramework,
    activateFramework,
    handleCancelActivateFramework,
  } = useAvailableFramework({ framework, space });

  const iconKey = framework?.tags?.find((tag) => tag.key === "mondoo.com/icon");
  const authors = framework.authors?.map((author) => author.name).join(", ");

  return (
    <Fragment>
      <Grid item xs={12} sm={6} md={4}>
        <Paper
          className="paper"
          sx={{
            height: "100%",
            p: 3,
            textAlign: "left",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => handleActivateFramework()}
        >
          <Box className="unscale">
            <Grid item container xs={12} sx={{ pb: 2 }}>
              <Grid item xs sx={{ display: "flex", alignItems: "flex-start" }}>
                <FrameworkIcon iconKey={iconKey} />
                <Flex alignItems="center" height={1} sx={{ pl: 2 }}>
                  <Clamp lines={2}>
                    <Typography
                      variant="caption"
                      fontWeight={600}
                      color="text.secondary"
                      sx={{ display: "inline-block", lineHeight: "16px" }}
                    >
                      {authors}
                    </Typography>
                  </Clamp>
                </Flex>
              </Grid>
              <Grid item xs="auto">
                {downloadFrameworkButton()}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ pb: 1 }}>
              <Tooltip arrow title={framework.name} placement="top">
                <Clamp>
                  <Typography variant="h5" fontWeight={700}>
                    {framework.name}
                  </Typography>
                </Clamp>
              </Tooltip>
            </Grid>
            <Grid item>
              <Clamp lines={3}>
                <Typography variant="body2" color="text.secondary">
                  {framework.summary}
                </Typography>
              </Clamp>
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <ConfirmActivateFrameworkDialog
        space={space}
        framework={framework}
        isOpen={activateFrameworkDialogOpen}
        onClose={handleCancelActivateFramework}
        onActivate={activateFramework}
      />
    </Fragment>
  );
}
