import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  AggregateScoreType,
  SearchType,
  useSearchAggregateScoreQuery,
} from "~/operations";

import { Org, Space } from "~/lib/types";

type UseFullTextSearchProps = {
  scope: Space | Org;
};

const DEFAULT_PAGE_SIZE = 25;

export function useFullTextSearch({ scope }: UseFullTextSearchProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageItems, setPageItems] = useState({
    from: 0,
    to: DEFAULT_PAGE_SIZE,
  });
  const [totalCount, setTotalCount] = useState(0);
  const [filteredTotalCount, setFilteredTotalCount] = useState(0);
  const activeFilter = searchParams.get("aggregateScoreType") || "ALL";
  const searchQuery = searchParams.get("q") || "";
  const aggregateScoreType = searchParams.get("aggregateScoreType");

  const { loading, data, error, fetchMore, networkStatus } =
    useSearchAggregateScoreQuery({
      variables: {
        query: searchQuery,
        scope: scope.mrn,
        filters: {
          aggregateScoreType: aggregateScoreType as AggregateScoreType,
        },
        type: SearchType.AggregateScore,
        first: 25,
      },
    });

  useEffect(() => {
    setPageItems({ from: 0, to: DEFAULT_PAGE_SIZE });
  }, [activeFilter]);

  useEffect(() => {
    if (data) {
      let totalCount = 0;
      const distribution = data?.search.scoreTypeDistribution;
      if (distribution) {
        const { __typename, ...counts } = distribution;
        totalCount = Object.values(counts)?.reduce(
          (acc, count) => acc + count,
          0,
        );
      }
      setTotalCount(totalCount);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      // Get the total count of the active filtered item for pagination
      let filteredTotalCount = 0;
      const distribution = data?.search.scoreTypeDistribution;
      if (distribution) {
        const { __typename, ...counts } = distribution;
        //push all total into filteredTotalCount if activeFilter is "ALL"
        if (activeFilter.toLowerCase() === "all") {
          filteredTotalCount = Object.values(counts)?.reduce(
            (acc, count) => acc + count,
            0,
          );
        } else {
          filteredTotalCount = Number(
            distribution[
              activeFilter.toLowerCase() as keyof typeof distribution
            ],
          );
        }
      }
      setFilteredTotalCount(filteredTotalCount);
    }
  }, [data, activeFilter]);

  const toggleFilter = (filter: string) => {
    if (filter.toLowerCase() === "all") {
      searchParams.delete("aggregateScoreType");
      return setSearchParams(searchParams);
    }
    if (!activeFilter || activeFilter !== filter) {
      searchParams.set("aggregateScoreType", filter);
    } else {
      searchParams.delete("aggregateScoreType");
    }
    setSearchParams(searchParams);
  };

  return {
    activeFilter,
    counts: {
      totalCount,
      filteredTotalCount,
    },
    page: {
      items: pageItems,
      setItems: setPageItems,
    },
    handle: {
      toggleFilter,
    },
    query: {
      loading,
      data,
      error,
      fetchMore,
      networkStatus,
      searchQuery,
    },
  };
}
