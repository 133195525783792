import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { RoleInput } from "~/operations";
import {
  Member,
  ROLE_EDITOR_MRN,
  ROLE_OWNER_MRN,
  ROLE_VIEWER_MRN,
} from "./members-mgmt";

export type EditMemberRoleDialogProps = {
  open: boolean;
  member: Member;
  onCancel: () => void;
  onSave: (roles: RoleInput[]) => void;
};

export function EditMemberRoleDialog({
  member,
  open,
  onCancel,
  onSave,
}: EditMemberRoleDialogProps) {
  let initialValue = "";
  if (member.roles.length > 0) {
    initialValue = member.roles[0].mrn;
  }

  const [value, setValue] = useState<string>(initialValue);

  const handleCancel = () => {
    onCancel();
  };

  const handleSave = () => {
    onSave([{ mrn: value }]);
  };

  const handleChange = (e: SelectChangeEvent) => {
    setValue(e.target.value);
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle id="edit-agent-role">Edit permissions</DialogTitle>
      <DialogContent>
        <p>{member.user.name}</p>
        <FormControl variant="outlined">
          <InputLabel htmlFor="role-select">Role</InputLabel>
          <Select
            id="role-select"
            label="Role"
            value={value}
            onChange={handleChange}
          >
            <MenuItem value={ROLE_VIEWER_MRN}>Viewer</MenuItem>
            <MenuItem value={ROLE_EDITOR_MRN}>Editor</MenuItem>
            <MenuItem value={ROLE_OWNER_MRN}>Owner</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={handleCancel} variant="contained" color="critical">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
