import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export function useGroupingButton() {
  const [selectedGroupingButton, setSelectedGroupingButton] = useState<
    string | null
  >(null);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const groupType = searchParams.get("groupType");

  useEffect(() => {
    setSelectedGroupingButton(groupType);
  }, [groupType]);

  const toggleSelectedGroupingButton = (groupType: string) => {
    if (selectedGroupingButton === groupType) {
      searchParams.delete("groupType");
    } else {
      searchParams.set("groupType", groupType);
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  return { toggleSelectedGroupingButton, selectedGroupingButton };
}
