import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Loading, LoadingFailed } from "~/components/loading";
import {
  AggregateScoreType,
  MvdEntryType,
  useLoadCveQuery,
} from "~/operations";
import { HomeIcon } from "~/components/icons";
import { Flex, TextTitle } from "~/components/ui-library";
import {
  CveExploits,
  CveExploitsProps,
  CveRiskFactors,
  CveSources,
  CvssScoreBox,
  EmptySection,
} from "~/components/vulnerabilities";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Header } from "~/components/DetailsPage/components/Header";
import { Space } from "~/lib/types";
import { useFindingRiskFactors } from "~/pages/space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";

type MvdVulnerabilityPageProps = {
  space: Space;
};

export const MVDVulnerabilityPage = ({ space }: MvdVulnerabilityPageProps) => {
  const { id = "" } = useParams();

  const { data, loading, error } = useLoadCveQuery({
    variables: { cveId: id },
  });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: String(data?.cve?.mrn || ""),
    scoreType: AggregateScoreType.Vulnerability,
  });

  if (loading || riskFactorsLoading) {
    return <Loading what="Vulnerability" />;
  }

  const cve = data?.cve;

  if (error || !cve) {
    return <LoadingFailed what="Vulnerability" />;
  }

  const exploits: CveExploitsProps["exploits"] =
    cve.exploits?.flatMap((e) => e ?? []) || [];

  const breadcrumbs = [
    <Link
      key="/space/mvd"
      component={RouterLink}
      to={`/space/mvd`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/vulns"
      component={RouterLink}
      to={`/space/mvd?risktype=${MvdEntryType.Cve}`}
      display="flex"
    >
      Mondoo Vulnerability Database
    </Link>,
    <Typography key={"/space/vulns/cve/:cve"}>{cve.id}</Typography>,
  ];

  document.title = `${cve.id} · CVEs · Mondoo`;

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>

      <Header
        id="cve-header"
        title={cve.id}
        created={String(cve.publishedAt)}
        lastModified={String(cve.modifiedAt)}
        riskFactors={riskFactors}
      />

      <Box id="description">
        <SectionHeading heading="Description" />
        <Typography textTransform="uppercase" fontWeight={700} mb={1}>
          Summary
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            {cve.summary && (
              <ShowMoreSummary id="cve-summary" text={cve.summary} />
            )}
          </Grid>
          <Grid item xs={3}>
            <CvssScoreBox
              id="cve-cvss-score-box"
              value={cve.cvssScore.value}
              sx={{ mx: "auto" }}
            />
          </Grid>
        </Grid>
        <Grid container mt={3}>
          <Grid item xs={12} md={6}>
            <CveSources
              id="cve-sources"
              url={cve.url}
              source={cve.source}
              references={cve.references}
            />
          </Grid>
        </Grid>
      </Box>

      <Box id="risk-factors" className="section">
        <SectionHeading heading="Risk Assessment" />
        <Box className="section-content">
          <CveRiskFactors
            id="cve-risk-factors"
            cvssScore={cve.cvssScore}
            epssScore={cve.epssScore}
            riskFactors={riskFactorsWithDocs}
          />
        </Box>
      </Box>

      <Box id="exploits" className="section">
        <Flex center gap={3} my={4} className="section-header">
          <TextTitle bold sx={{ whiteSpace: "nowrap", flexShrink: 0 }}>
            Known Exploits
          </TextTitle>
          <Box width={1}>
            <Divider />
          </Box>
        </Flex>
        <Box className="section-content">
          {exploits.length > 0 ? (
            <CveExploits id="cve-exploits" exploits={exploits} />
          ) : (
            <EmptySection
              id="cve-exploits-empty"
              text="There are currently no known exploits for this CVE"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
