import { Box } from "@mui/material";

export function Stars({}) {
  return (
    <Box
      component="svg"
      className="stars"
      width="1393"
      height="896"
      viewBox="0 0 1393 896"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.754 244.264H229.411C228.718 244.264 228.031 244.128 227.39 243.862C226.748 243.596 226.166 243.207 225.675 242.717C225.185 242.226 224.796 241.644 224.53 241.003C224.265 240.362 224.128 239.674 224.128 238.981V237.634C224.128 237.393 224.032 237.161 223.861 236.99C223.69 236.819 223.458 236.722 223.216 236.722C223.096 236.722 222.977 236.745 222.866 236.791C222.755 236.836 222.654 236.904 222.569 236.988C222.484 237.073 222.416 237.174 222.37 237.285C222.324 237.395 222.3 237.514 222.3 237.634V238.981C222.3 240.382 221.744 241.726 220.753 242.717C219.762 243.708 218.418 244.264 217.017 244.264H215.674C215.432 244.264 215.2 244.36 215.029 244.531C214.858 244.702 214.762 244.934 214.762 245.176C214.762 245.418 214.858 245.65 215.029 245.821C215.2 245.992 215.432 246.088 215.674 246.088H217.017C217.711 246.088 218.398 246.225 219.04 246.491C219.681 246.756 220.263 247.146 220.754 247.637C221.245 248.128 221.634 248.711 221.899 249.352C222.165 249.994 222.301 250.681 222.3 251.375V252.718C222.3 252.838 222.324 252.957 222.37 253.068C222.416 253.179 222.484 253.279 222.569 253.364C222.654 253.449 222.755 253.516 222.866 253.561C222.977 253.607 223.096 253.63 223.216 253.63C223.458 253.63 223.69 253.534 223.861 253.363C224.032 253.192 224.128 252.96 224.128 252.718V251.357C224.129 249.956 224.686 248.613 225.676 247.622C226.667 246.632 228.01 246.075 229.411 246.074H230.754C230.874 246.074 230.993 246.051 231.104 246.005C231.215 245.96 231.316 245.893 231.401 245.808C231.486 245.723 231.554 245.622 231.6 245.511C231.646 245.401 231.67 245.282 231.67 245.162C231.666 244.922 231.568 244.693 231.396 244.525C231.225 244.357 230.994 244.263 230.754 244.264Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1258.94 434.455H1257.6C1256.9 434.455 1256.22 434.318 1255.57 434.053C1254.93 433.787 1254.35 433.398 1253.86 432.907C1253.37 432.417 1252.98 431.834 1252.71 431.193C1252.45 430.552 1252.31 429.865 1252.31 429.171V427.825C1252.31 427.583 1252.22 427.351 1252.05 427.18C1251.87 427.009 1251.64 426.913 1251.4 426.913C1251.28 426.913 1251.16 426.936 1251.05 426.981C1250.94 427.027 1250.84 427.094 1250.75 427.179C1250.67 427.264 1250.6 427.364 1250.55 427.475C1250.51 427.586 1250.48 427.705 1250.48 427.825V429.171C1250.48 430.573 1249.93 431.916 1248.94 432.907C1247.95 433.898 1246.6 434.455 1245.2 434.455H1243.86C1243.62 434.455 1243.38 434.551 1243.21 434.722C1243.04 434.893 1242.95 435.125 1242.95 435.367C1242.95 435.609 1243.04 435.841 1243.21 436.012C1243.38 436.183 1243.62 436.279 1243.86 436.279H1245.2C1245.9 436.279 1246.58 436.416 1247.22 436.681C1247.87 436.947 1248.45 437.337 1248.94 437.828C1249.43 438.319 1249.82 438.901 1250.08 439.543C1250.35 440.184 1250.49 440.872 1250.48 441.566V442.909C1250.48 443.029 1250.51 443.148 1250.55 443.258C1250.6 443.369 1250.67 443.47 1250.75 443.555C1250.84 443.639 1250.94 443.707 1251.05 443.752C1251.16 443.798 1251.28 443.821 1251.4 443.821C1251.64 443.821 1251.87 443.724 1252.05 443.553C1252.22 443.382 1252.31 443.15 1252.31 442.909V441.548C1252.31 440.147 1252.87 438.804 1253.86 437.813C1254.85 436.822 1256.2 436.265 1257.6 436.264H1258.94C1259.06 436.265 1259.18 436.242 1259.29 436.196C1259.4 436.15 1259.5 436.083 1259.59 435.998C1259.67 435.914 1259.74 435.813 1259.78 435.702C1259.83 435.591 1259.85 435.472 1259.85 435.352C1259.85 435.112 1259.75 434.884 1259.58 434.716C1259.41 434.548 1259.18 434.454 1258.94 434.455Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M954.605 80.4551H953.262C952.568 80.4551 951.881 80.3184 951.24 80.0529C950.599 79.7874 950.017 79.3982 949.526 78.9076C949.035 78.4169 948.646 77.8345 948.381 77.1935C948.115 76.5524 947.978 75.8654 947.978 75.1715V73.8253C947.978 73.5834 947.882 73.3515 947.711 73.1804C947.54 73.0094 947.308 72.9133 947.067 72.9133C946.946 72.9129 946.827 72.9361 946.716 72.9817C946.605 73.0273 946.504 73.0944 946.419 73.1792C946.334 73.2639 946.267 73.3646 946.221 73.4755C946.175 73.5863 946.151 73.7052 946.151 73.8253V75.1715C946.151 76.5728 945.594 77.9167 944.603 78.9076C943.613 79.8984 942.269 80.4551 940.867 80.4551H939.525C939.283 80.4551 939.051 80.5512 938.88 80.7222C938.709 80.8932 938.613 81.1252 938.613 81.3671C938.613 81.6089 938.709 81.8409 938.88 82.0119C939.051 82.1829 939.283 82.279 939.525 82.279H940.867C941.562 82.279 942.249 82.4158 942.89 82.6816C943.531 82.9473 944.114 83.3368 944.605 83.8278C945.095 84.3188 945.484 84.9017 945.75 85.5432C946.015 86.1846 946.151 86.8721 946.151 87.5662V88.9088C946.151 89.0289 946.175 89.1478 946.221 89.2587C946.267 89.3695 946.334 89.4702 946.419 89.555C946.504 89.6397 946.605 89.7068 946.716 89.7524C946.827 89.798 946.946 89.8213 947.067 89.8208C947.308 89.8208 947.54 89.7247 947.711 89.5537C947.882 89.3826 947.978 89.1507 947.978 88.9088V87.5481C947.979 86.1471 948.536 84.8038 949.527 83.8131C950.518 82.8225 951.861 82.2655 953.262 82.2645H954.605C954.725 82.265 954.844 82.2418 954.955 82.1962C955.066 82.1506 955.167 82.0834 955.252 81.9987C955.337 81.914 955.404 81.8133 955.45 81.7024C955.497 81.5915 955.52 81.4727 955.52 81.3526C955.516 81.1126 955.418 80.8838 955.247 80.7158C955.075 80.5478 954.845 80.4541 954.605 80.4551Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.7004 105.816H79.3578C78.6639 105.816 77.9769 105.68 77.3358 105.414C76.6948 105.148 76.1123 104.759 75.6217 104.269C75.1311 103.778 74.7419 103.196 74.4764 102.555C74.2108 101.914 74.0742 101.226 74.0742 100.533V99.1864C74.0742 98.9445 73.9781 98.7126 73.8071 98.5415C73.636 98.3705 73.4041 98.2744 73.1622 98.2744C73.0422 98.2739 72.9232 98.2972 72.8121 98.3428C72.701 98.3884 72.6001 98.4555 72.515 98.5402C72.43 98.625 72.3625 98.7257 72.3164 98.8365C72.2703 98.9474 72.2466 99.0663 72.2466 99.1864V100.533C72.2466 101.934 71.69 103.278 70.6991 104.269C69.7082 105.26 68.3643 105.816 66.9631 105.816H65.6205C65.3786 105.816 65.1466 105.912 64.9756 106.083C64.8046 106.254 64.7085 106.486 64.7085 106.728C64.7085 106.97 64.8046 107.202 64.9756 107.373C65.1466 107.544 65.3786 107.64 65.6205 107.64H66.9631C67.6572 107.64 68.3446 107.777 68.9858 108.043C69.6271 108.308 70.2097 108.698 70.7004 109.189C71.1911 109.68 71.5802 110.263 71.8455 110.904C72.1108 111.546 72.2471 112.233 72.2466 112.927V114.27C72.2466 114.39 72.2703 114.509 72.3164 114.62C72.3625 114.731 72.43 114.831 72.515 114.916C72.6001 115.001 72.701 115.068 72.8121 115.113C72.9232 115.159 73.0422 115.182 73.1622 115.182C73.4041 115.182 73.636 115.086 73.8071 114.915C73.9781 114.744 74.0742 114.512 74.0742 114.27V112.909C74.0751 111.508 74.6321 110.165 75.6228 109.174C76.6134 108.184 77.9568 107.627 79.3578 107.626H80.7004C80.8204 107.626 80.9394 107.603 81.0505 107.557C81.1615 107.512 81.2625 107.445 81.3476 107.36C81.4326 107.275 81.5001 107.174 81.5462 107.063C81.5922 106.953 81.6159 106.834 81.6159 106.714C81.6121 106.474 81.5139 106.245 81.3425 106.077C81.1711 105.909 80.9404 105.815 80.7004 105.816Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2409 456.646H14.8983C14.2044 456.646 13.5174 456.509 12.8763 456.244C12.2353 455.978 11.6529 455.589 11.1622 455.098C10.6716 454.608 10.2824 454.025 10.0169 453.384C9.75137 452.743 9.61471 452.056 9.61471 451.362V450.016C9.61471 449.774 9.51863 449.542 9.3476 449.371C9.17657 449.2 8.94461 449.104 8.70274 449.104C8.58268 449.104 8.4637 449.127 8.35264 449.173C8.24158 449.218 8.14062 449.285 8.05555 449.37C7.97049 449.455 7.90299 449.556 7.85693 449.666C7.81087 449.777 7.78716 449.896 7.78716 450.016V451.362C7.78716 452.764 7.2305 454.108 6.23964 455.098C5.24878 456.089 3.90488 456.646 2.50358 456.646H1.16098C0.919113 456.646 0.687159 456.742 0.516133 456.913C0.345107 457.084 0.249023 457.316 0.249023 457.558C0.249023 457.8 0.345107 458.032 0.516133 458.203C0.687159 458.374 0.919113 458.47 1.16098 458.47H2.50358C3.19774 458.47 3.8851 458.607 4.52636 458.872C5.16763 459.138 5.75025 459.528 6.24092 460.019C6.73159 460.51 7.1207 461.093 7.38601 461.734C7.65133 462.376 7.78764 463.063 7.78716 463.757V465.1C7.78716 465.22 7.81087 465.339 7.85693 465.45C7.90299 465.56 7.97049 465.661 8.05555 465.746C8.14062 465.831 8.24158 465.898 8.35264 465.943C8.4637 465.989 8.58268 466.012 8.70274 466.012C8.94461 466.012 9.17657 465.916 9.3476 465.745C9.51863 465.574 9.61471 465.342 9.61471 465.1V463.739C9.61567 462.338 10.1726 460.995 11.1633 460.004C12.1539 459.013 13.4973 458.456 14.8983 458.455H16.2409C16.361 458.456 16.4799 458.433 16.591 458.387C16.7021 458.341 16.803 458.274 16.8881 458.19C16.9731 458.105 17.0407 458.004 17.0867 457.893C17.1328 457.782 17.1565 457.664 17.1565 457.544C17.1527 457.304 17.0544 457.075 16.883 456.907C16.7116 456.739 16.4809 456.645 16.2409 456.646Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1296.38 190.809H1295.71C1295.36 190.809 1295.02 190.741 1294.7 190.608C1294.38 190.476 1294.09 190.281 1293.84 190.036C1293.6 189.79 1293.4 189.499 1293.27 189.179C1293.14 188.858 1293.07 188.515 1293.07 188.168V187.495C1293.07 187.374 1293.02 187.258 1292.93 187.172C1292.85 187.087 1292.73 187.039 1292.61 187.039C1292.55 187.038 1292.49 187.05 1292.44 187.073C1292.38 187.096 1292.33 187.129 1292.29 187.171C1292.25 187.214 1292.21 187.264 1292.19 187.32C1292.17 187.375 1292.15 187.435 1292.15 187.495V188.168C1292.15 188.868 1291.88 189.54 1291.38 190.036C1290.88 190.531 1290.21 190.809 1289.51 190.809H1288.84C1288.72 190.809 1288.6 190.857 1288.52 190.943C1288.43 191.029 1288.38 191.145 1288.38 191.265C1288.38 191.386 1288.43 191.502 1288.52 191.588C1288.6 191.673 1288.72 191.721 1288.84 191.721H1289.51C1289.86 191.721 1290.2 191.79 1290.52 191.923C1290.84 192.056 1291.14 192.25 1291.38 192.496C1291.63 192.741 1291.82 193.033 1291.95 193.353C1292.09 193.674 1292.15 194.018 1292.15 194.365V195.036C1292.15 195.096 1292.17 195.156 1292.19 195.211C1292.21 195.267 1292.25 195.317 1292.29 195.359C1292.33 195.402 1292.38 195.435 1292.44 195.458C1292.49 195.481 1292.55 195.493 1292.61 195.492C1292.73 195.492 1292.85 195.444 1292.93 195.359C1293.02 195.273 1293.07 195.157 1293.07 195.036V194.356C1293.07 193.655 1293.35 192.984 1293.84 192.488C1294.34 191.993 1295.01 191.715 1295.71 191.714H1296.38C1296.44 191.714 1296.5 191.703 1296.56 191.68C1296.61 191.657 1296.66 191.624 1296.7 191.581C1296.75 191.539 1296.78 191.489 1296.8 191.433C1296.83 191.378 1296.84 191.318 1296.84 191.258C1296.84 191.138 1296.79 191.024 1296.7 190.94C1296.62 190.856 1296.5 190.809 1296.38 190.809Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.623 253.156H200.952C200.605 253.156 200.262 253.087 199.941 252.955C199.621 252.822 199.329 252.627 199.084 252.382C198.839 252.137 198.644 251.845 198.511 251.525C198.379 251.204 198.31 250.861 198.31 250.514V249.841C198.31 249.72 198.262 249.604 198.177 249.518C198.091 249.433 197.975 249.385 197.854 249.385C197.794 249.385 197.735 249.396 197.679 249.419C197.624 249.442 197.573 249.475 197.531 249.518C197.488 249.56 197.454 249.61 197.431 249.666C197.408 249.721 197.397 249.781 197.397 249.841V250.514C197.397 251.215 197.118 251.886 196.623 252.382C196.127 252.877 195.455 253.156 194.755 253.156H194.083C193.962 253.156 193.847 253.204 193.761 253.289C193.675 253.375 193.627 253.491 193.627 253.612C193.627 253.733 193.675 253.849 193.761 253.934C193.847 254.02 193.962 254.068 194.083 254.068H194.755C195.102 254.068 195.445 254.136 195.766 254.269C196.087 254.402 196.378 254.597 196.623 254.842C196.869 255.088 197.063 255.379 197.196 255.7C197.329 256.02 197.397 256.364 197.397 256.711V257.383C197.397 257.443 197.408 257.502 197.431 257.557C197.454 257.613 197.488 257.663 197.531 257.706C197.573 257.748 197.624 257.781 197.679 257.804C197.735 257.827 197.794 257.839 197.854 257.838C197.975 257.838 198.091 257.79 198.177 257.705C198.262 257.619 198.31 257.503 198.31 257.383V256.702C198.311 256.002 198.589 255.33 199.085 254.835C199.58 254.339 200.252 254.061 200.952 254.06H201.623C201.683 254.061 201.743 254.049 201.798 254.026C201.854 254.003 201.904 253.97 201.947 253.927C201.99 253.885 202.023 253.835 202.046 253.779C202.069 253.724 202.081 253.664 202.081 253.604C202.079 253.484 202.03 253.37 201.944 253.286C201.859 253.202 201.743 253.155 201.623 253.156Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M100.179 118.953H99.5077C99.1608 118.953 98.8173 118.885 98.4968 118.752C98.1763 118.619 97.885 118.425 97.6397 118.179C97.3944 117.934 97.1998 117.643 97.067 117.322C96.9343 117.002 96.8659 116.658 96.8659 116.311V115.638C96.8659 115.517 96.8179 115.401 96.7324 115.316C96.6469 115.23 96.5309 115.182 96.41 115.182C96.3499 115.182 96.2904 115.194 96.2349 115.216C96.1794 115.239 96.1289 115.273 96.0864 115.315C96.0438 115.357 96.0101 115.408 95.9871 115.463C95.964 115.519 95.9522 115.578 95.9522 115.638V116.311C95.9522 117.012 95.6738 117.684 95.1784 118.179C94.683 118.675 94.011 118.953 93.3104 118.953H92.6391C92.5182 118.953 92.4022 119.001 92.3167 119.087C92.2311 119.172 92.1831 119.288 92.1831 119.409C92.1831 119.53 92.2311 119.646 92.3167 119.731C92.4022 119.817 92.5182 119.865 92.6391 119.865H93.3104C93.6575 119.865 94.0011 119.933 94.3218 120.066C94.6424 120.199 94.9337 120.394 95.1791 120.639C95.4244 120.885 95.6189 121.176 95.7516 121.497C95.8843 121.818 95.9524 122.161 95.9522 122.509V123.18C95.9522 123.24 95.964 123.299 95.9871 123.355C96.0101 123.41 96.0438 123.461 96.0864 123.503C96.1289 123.545 96.1794 123.579 96.2349 123.602C96.2904 123.624 96.3499 123.636 96.41 123.636C96.5309 123.636 96.6469 123.588 96.7324 123.502C96.8179 123.417 96.8659 123.301 96.8659 123.18V122.5C96.8664 121.799 97.1449 121.127 97.6402 120.632C98.1356 120.137 98.8072 119.858 99.5077 119.858H100.179C100.239 119.858 100.299 119.846 100.354 119.824C100.41 119.801 100.46 119.767 100.503 119.725C100.545 119.682 100.579 119.632 100.602 119.577C100.625 119.521 100.637 119.462 100.637 119.402C100.635 119.282 100.586 119.167 100.5 119.083C100.414 118.999 100.299 118.953 100.179 118.953Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M400.286 24.9052H399.615C399.268 24.9052 398.925 24.8368 398.604 24.7041C398.284 24.5713 397.992 24.3767 397.747 24.1314C397.502 23.8861 397.307 23.5949 397.174 23.2743C397.042 22.9538 396.973 22.6103 396.973 22.2634V21.5903C396.973 21.4693 396.925 21.3533 396.84 21.2678C396.754 21.1823 396.638 21.1343 396.517 21.1343C396.457 21.134 396.398 21.1457 396.342 21.1685C396.287 21.1913 396.236 21.2248 396.194 21.2672C396.151 21.3096 396.118 21.3599 396.094 21.4153C396.071 21.4708 396.06 21.5302 396.06 21.5903V22.2634C396.06 22.964 395.781 23.636 395.286 24.1314C394.79 24.6268 394.118 24.9052 393.418 24.9052H392.747C392.626 24.9052 392.51 24.9532 392.424 25.0387C392.339 25.1242 392.291 25.2402 392.291 25.3611C392.291 25.4821 392.339 25.5981 392.424 25.6836C392.51 25.7691 392.626 25.8171 392.747 25.8171H393.418C393.765 25.8171 394.109 25.8855 394.429 26.0184C394.75 26.1513 395.041 26.346 395.286 26.5915C395.532 26.837 395.726 27.1285 395.859 27.4492C395.992 27.7699 396.06 28.1136 396.06 28.4607V29.132C396.06 29.192 396.071 29.2515 396.094 29.3069C396.118 29.3624 396.151 29.4127 396.194 29.4551C396.236 29.4974 396.287 29.531 396.342 29.5538C396.398 29.5766 396.457 29.5882 396.517 29.588C396.638 29.588 396.754 29.54 396.84 29.4544C396.925 29.3689 396.973 29.2529 396.973 29.132V28.4517C396.974 27.7512 397.252 27.0795 397.748 26.5842C398.243 26.0888 398.915 25.8104 399.615 25.8099H400.286C400.346 25.8101 400.406 25.7985 400.462 25.7757C400.517 25.7529 400.568 25.7193 400.61 25.677C400.653 25.6346 400.686 25.5843 400.709 25.5288C400.732 25.4734 400.744 25.4139 400.744 25.3539C400.742 25.2339 400.693 25.1195 400.608 25.0355C400.522 24.9515 400.406 24.9047 400.286 24.9052Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M963.516 52.3798H962.845C962.498 52.3798 962.154 52.3114 961.834 52.1787C961.513 52.0459 961.222 51.8513 960.977 51.606C960.731 51.3607 960.537 51.0695 960.404 50.749C960.271 50.4284 960.203 50.0849 960.203 49.738V49.0649C960.203 48.9439 960.155 48.828 960.069 48.7424C959.984 48.6569 959.868 48.6089 959.747 48.6089C959.687 48.6087 959.627 48.6203 959.572 48.6431C959.516 48.6659 959.466 48.6994 959.423 48.7418C959.381 48.7842 959.347 48.8345 959.324 48.89C959.301 48.9454 959.289 49.0048 959.289 49.0649V49.738C959.289 50.4386 959.011 51.1106 958.515 51.606C958.02 52.1014 957.348 52.3798 956.647 52.3798H955.976C955.855 52.3798 955.739 52.4278 955.654 52.5133C955.568 52.5988 955.52 52.7148 955.52 52.8357C955.52 52.9567 955.568 53.0727 955.654 53.1582C955.739 53.2437 955.855 53.2917 955.976 53.2917H956.647C956.994 53.2917 957.338 53.3601 957.659 53.493C957.979 53.6259 958.271 53.8206 958.516 54.0661C958.761 54.3116 958.956 54.6031 959.089 54.9238C959.221 55.2445 959.289 55.5882 959.289 55.9353V56.6066C959.289 56.6667 959.301 56.7261 959.324 56.7815C959.347 56.837 959.381 56.8873 959.423 56.9297C959.466 56.9721 959.516 57.0056 959.572 57.0284C959.627 57.0512 959.687 57.0628 959.747 57.0626C959.868 57.0626 959.984 57.0146 960.069 56.9291C960.155 56.8435 960.203 56.7276 960.203 56.6066V55.9263C960.203 55.2258 960.482 54.5541 960.977 54.0588C961.472 53.5635 962.144 53.285 962.845 53.2845H963.516C963.576 53.2847 963.635 53.2731 963.691 53.2503C963.747 53.2275 963.797 53.1939 963.84 53.1516C963.882 53.1092 963.916 53.0589 963.939 53.0034C963.962 52.948 963.974 52.8885 963.974 52.8285C963.972 52.7085 963.923 52.5941 963.837 52.5101C963.751 52.4261 963.636 52.3793 963.516 52.3798Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1314.35 202.433H1313.67C1313.33 202.433 1312.98 202.365 1312.66 202.232C1312.34 202.1 1312.05 201.905 1311.81 201.66C1311.56 201.414 1311.37 201.123 1311.23 200.803C1311.1 200.482 1311.03 200.139 1311.03 199.792V199.119C1311.03 198.998 1310.98 198.882 1310.9 198.796C1310.81 198.711 1310.7 198.663 1310.58 198.663C1310.52 198.662 1310.46 198.674 1310.4 198.697C1310.35 198.72 1310.3 198.753 1310.25 198.796C1310.21 198.838 1310.18 198.888 1310.15 198.944C1310.13 198.999 1310.12 199.059 1310.12 199.119V199.792C1310.12 200.492 1309.84 201.164 1309.34 201.66C1308.85 202.155 1308.18 202.433 1307.48 202.433H1306.81C1306.68 202.433 1306.57 202.482 1306.48 202.567C1306.4 202.653 1306.35 202.769 1306.35 202.889C1306.35 203.01 1306.4 203.126 1306.48 203.212C1306.57 203.297 1306.68 203.345 1306.81 203.345H1307.48C1307.82 203.345 1308.17 203.414 1308.49 203.547C1308.81 203.68 1309.1 203.874 1309.35 204.12C1309.59 204.365 1309.79 204.657 1309.92 204.978C1310.05 205.298 1310.12 205.642 1310.12 205.989V206.66C1310.12 206.72 1310.13 206.78 1310.15 206.835C1310.18 206.891 1310.21 206.941 1310.25 206.983C1310.3 207.026 1310.35 207.059 1310.4 207.082C1310.46 207.105 1310.52 207.117 1310.58 207.116C1310.7 207.116 1310.81 207.068 1310.9 206.983C1310.98 206.897 1311.03 206.781 1311.03 206.66V205.98C1311.03 205.279 1311.31 204.608 1311.81 204.112C1312.3 203.617 1312.97 203.339 1313.67 203.338H1314.35C1314.41 203.338 1314.47 203.327 1314.52 203.304C1314.58 203.281 1314.63 203.248 1314.67 203.205C1314.71 203.163 1314.75 203.113 1314.77 203.057C1314.79 203.002 1314.8 202.942 1314.8 202.882C1314.8 202.762 1314.75 202.648 1314.67 202.564C1314.58 202.48 1314.47 202.433 1314.35 202.433Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1241.43 443.365H1240.76C1240.41 443.365 1240.07 443.296 1239.75 443.164C1239.43 443.031 1239.14 442.836 1238.89 442.591C1238.65 442.346 1238.45 442.054 1238.32 441.734C1238.19 441.413 1238.12 441.07 1238.12 440.723V440.05C1238.12 439.929 1238.07 439.813 1237.99 439.727C1237.9 439.642 1237.78 439.594 1237.66 439.594C1237.6 439.594 1237.54 439.605 1237.49 439.628C1237.43 439.651 1237.38 439.684 1237.34 439.727C1237.3 439.769 1237.26 439.819 1237.24 439.875C1237.22 439.93 1237.21 439.99 1237.21 440.05V440.723C1237.21 441.423 1236.93 442.095 1236.43 442.591C1235.94 443.086 1235.26 443.365 1234.56 443.365H1233.89C1233.77 443.365 1233.66 443.413 1233.57 443.498C1233.48 443.584 1233.44 443.7 1233.44 443.821C1233.44 443.942 1233.48 444.058 1233.57 444.143C1233.66 444.229 1233.77 444.277 1233.89 444.277H1234.56C1234.91 444.277 1235.25 444.345 1235.57 444.478C1235.9 444.611 1236.19 444.805 1236.43 445.051C1236.68 445.296 1236.87 445.588 1237 445.909C1237.14 446.229 1237.21 446.573 1237.21 446.92V447.591C1237.21 447.652 1237.22 447.711 1237.24 447.766C1237.26 447.822 1237.3 447.872 1237.34 447.915C1237.38 447.957 1237.43 447.99 1237.49 448.013C1237.54 448.036 1237.6 448.048 1237.66 448.047C1237.78 448.047 1237.9 447.999 1237.99 447.914C1238.07 447.828 1238.12 447.712 1238.12 447.591V446.911C1238.12 446.211 1238.4 445.539 1238.89 445.044C1239.39 444.548 1240.06 444.27 1240.76 444.269H1241.43C1241.49 444.27 1241.55 444.258 1241.61 444.235C1241.66 444.212 1241.71 444.179 1241.76 444.136C1241.8 444.094 1241.83 444.044 1241.85 443.988C1241.88 443.933 1241.89 443.873 1241.89 443.813C1241.89 443.693 1241.84 443.579 1241.75 443.495C1241.67 443.411 1241.55 443.364 1241.43 443.365Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1522 445.478H24.4809C24.134 445.478 23.7904 445.41 23.4699 445.277C23.1494 445.144 22.8582 444.949 22.6129 444.704C22.3675 444.459 22.1729 444.168 22.0402 443.847C21.9074 443.527 21.8391 443.183 21.8391 442.836V442.163C21.8391 442.042 21.7911 441.926 21.7055 441.841C21.62 441.755 21.504 441.707 21.3831 441.707C21.3231 441.707 21.2636 441.718 21.2081 441.741C21.1525 441.764 21.102 441.798 21.0595 441.84C21.017 441.882 20.9832 441.933 20.9602 441.988C20.9372 442.044 20.9253 442.103 20.9253 442.163V442.836C20.9253 443.537 20.647 444.209 20.1516 444.704C19.6561 445.2 18.9842 445.478 18.2835 445.478H17.6122C17.4913 445.478 17.3753 445.526 17.2898 445.611C17.2043 445.697 17.1563 445.813 17.1562 445.934C17.1563 446.055 17.2043 446.171 17.2898 446.256C17.3753 446.342 17.4913 446.39 17.6122 446.39H18.2835C18.6306 446.39 18.9743 446.458 19.2949 446.591C19.6156 446.724 19.9069 446.919 20.1522 447.164C20.3975 447.41 20.5921 447.701 20.7247 448.022C20.8574 448.343 20.9256 448.686 20.9253 449.033V449.705C20.9253 449.765 20.9372 449.824 20.9602 449.88C20.9832 449.935 21.017 449.985 21.0595 450.028C21.102 450.07 21.1525 450.104 21.2081 450.127C21.2636 450.149 21.3231 450.161 21.3831 450.161C21.504 450.161 21.62 450.113 21.7055 450.027C21.7911 449.942 21.8391 449.826 21.8391 449.705V449.024C21.8396 448.324 22.1181 447.652 22.6134 447.157C23.1087 446.662 23.7804 446.383 24.4809 446.383H25.1522C25.2122 446.383 25.2717 446.371 25.3272 446.348C25.3828 446.326 25.4332 446.292 25.4758 446.25C25.5183 446.207 25.5521 446.157 25.5751 446.102C25.5981 446.046 25.61 445.987 25.61 445.927C25.6081 445.807 25.5589 445.692 25.4732 445.608C25.3875 445.524 25.2722 445.477 25.1522 445.478Z"
        fill="white"
      />
      <circle cx="345.795" cy="151.11" r="2.11343" fill="#C4C4C4" />
      <circle cx="998.845" cy="75.0268" r="2.11343" fill="#C4C4C4" />
      <circle cx="1296.84" cy="236.704" r="4.22686" fill="#C4C4C4" />
      <circle cx="1182.71" cy="395.212" r="2.11343" fill="#C4C4C4" />
      <circle cx="1390.89" cy="59.1759" r="2.11343" fill="#C4C4C4" />
      <circle cx="770.595" cy="2.11343" r="2.11343" fill="#C4C4C4" />
      <circle cx="10.8166" cy="191.266" r="2.11343" fill="#C4C4C4" />
      <circle cx="157.699" cy="29.588" r="2.11343" fill="#C4C4C4" />
      <circle cx="459.92" cy="352.943" r="2.11343" fill="#C4C4C4" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1161.92 734.137L1163.27 734.137C1163.97 734.137 1164.66 734.008 1165.3 733.756C1165.95 733.505 1166.53 733.137 1167.03 732.673C1167.52 732.208 1167.91 731.657 1168.18 731.05C1168.45 730.444 1168.58 729.794 1168.58 729.137L1168.58 727.863C1168.58 727.634 1168.68 727.415 1168.85 727.253C1169.02 727.091 1169.26 727 1169.5 727C1169.62 727 1169.74 727.022 1169.85 727.065C1169.96 727.108 1170.07 727.171 1170.15 727.252C1170.24 727.332 1170.3 727.427 1170.35 727.532C1170.4 727.637 1170.42 727.749 1170.42 727.863L1170.42 729.137C1170.42 730.463 1170.98 731.735 1171.98 732.673C1172.97 733.61 1174.32 734.137 1175.73 734.137L1177.08 734.137C1177.33 734.137 1177.56 734.228 1177.73 734.39C1177.9 734.552 1178 734.771 1178 735C1178 735.229 1177.9 735.448 1177.73 735.61C1177.56 735.772 1177.33 735.863 1177.08 735.863L1175.73 735.863C1175.04 735.863 1174.34 735.992 1173.7 736.244C1173.05 736.495 1172.47 736.864 1171.98 737.329C1171.48 737.793 1171.09 738.345 1170.82 738.952C1170.56 739.559 1170.42 740.21 1170.42 740.866L1170.42 742.137C1170.42 742.251 1170.4 742.363 1170.35 742.468C1170.3 742.573 1170.24 742.668 1170.15 742.748C1170.07 742.829 1169.96 742.892 1169.85 742.935C1169.74 742.978 1169.62 743 1169.5 743C1169.26 743 1169.02 742.909 1168.85 742.747C1168.68 742.585 1168.58 742.366 1168.58 742.137L1168.58 740.849C1168.58 739.524 1168.02 738.252 1167.03 737.315C1166.03 736.377 1164.68 735.85 1163.27 735.849L1161.92 735.849C1161.8 735.85 1161.68 735.828 1161.57 735.785C1161.46 735.741 1161.36 735.678 1161.27 735.598C1161.18 735.518 1161.12 735.422 1161.07 735.317C1161.02 735.212 1161 735.1 1161 734.986C1161 734.759 1161.1 734.543 1161.27 734.384C1161.45 734.225 1161.68 734.136 1161.92 734.137Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M437.921 583.137L439.271 583.137C439.968 583.137 440.659 583.008 441.304 582.756C441.948 582.505 442.534 582.137 443.027 581.673C443.52 581.208 443.912 580.657 444.179 580.05C444.446 579.444 444.583 578.794 444.583 578.137L444.583 576.863C444.583 576.634 444.68 576.415 444.852 576.253C445.024 576.091 445.257 576 445.5 576C445.621 576 445.74 576.022 445.852 576.065C445.964 576.108 446.065 576.171 446.151 576.252C446.236 576.332 446.304 576.427 446.35 576.532C446.397 576.637 446.421 576.749 446.421 576.863L446.421 578.137C446.421 579.463 446.98 580.735 447.977 581.673C448.973 582.61 450.324 583.137 451.733 583.137L453.083 583.137C453.326 583.137 453.559 583.228 453.731 583.39C453.903 583.552 454 583.771 454 584C454 584.229 453.903 584.448 453.731 584.61C453.559 584.772 453.326 584.863 453.083 584.863L451.733 584.863C451.035 584.863 450.344 584.992 449.699 585.244C449.054 585.495 448.469 585.864 447.975 586.329C447.482 586.793 447.091 587.345 446.824 587.952C446.557 588.559 446.42 589.21 446.421 589.866L446.421 591.137C446.421 591.251 446.397 591.363 446.35 591.468C446.304 591.573 446.236 591.668 446.151 591.748C446.065 591.829 445.964 591.892 445.852 591.935C445.74 591.978 445.621 592 445.5 592C445.257 592 445.024 591.909 444.852 591.747C444.68 591.585 444.583 591.366 444.583 591.137L444.583 589.849C444.582 588.524 444.022 587.252 443.026 586.315C442.03 585.377 440.679 584.85 439.271 584.849L437.921 584.849C437.8 584.85 437.68 584.828 437.569 584.785C437.457 584.741 437.355 584.678 437.27 584.598C437.184 584.518 437.116 584.422 437.07 584.317C437.024 584.212 437 584.1 437 583.986C437.004 583.759 437.103 583.543 437.275 583.384C437.447 583.225 437.679 583.136 437.921 583.137Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1311.92 606.691L1313.27 606.691C1313.97 606.691 1314.66 606.57 1315.3 606.334C1315.95 606.099 1316.53 605.753 1317.03 605.318C1317.52 604.883 1317.91 604.366 1318.18 603.797C1318.45 603.229 1318.58 602.619 1318.58 602.003L1318.58 600.809C1318.58 600.594 1318.68 600.389 1318.85 600.237C1319.02 600.085 1319.26 600 1319.5 600C1319.62 600 1319.74 600.02 1319.85 600.061C1319.96 600.101 1320.07 600.161 1320.15 600.236C1320.24 600.311 1320.3 600.4 1320.35 600.499C1320.4 600.597 1320.42 600.703 1320.42 600.809L1320.42 602.003C1320.42 603.247 1320.98 604.439 1321.98 605.318C1322.97 606.197 1324.32 606.691 1325.73 606.691L1327.08 606.691C1327.33 606.691 1327.56 606.776 1327.73 606.928C1327.9 607.08 1328 607.285 1328 607.5C1328 607.715 1327.9 607.92 1327.73 608.072C1327.56 608.224 1327.33 608.309 1327.08 608.309L1325.73 608.309C1325.04 608.309 1324.34 608.43 1323.7 608.666C1323.05 608.902 1322.47 609.248 1321.98 609.683C1321.48 610.119 1321.09 610.636 1320.82 611.205C1320.56 611.774 1320.42 612.384 1320.42 613L1320.42 614.191C1320.42 614.297 1320.4 614.403 1320.35 614.501C1320.3 614.6 1320.24 614.689 1320.15 614.764C1320.07 614.839 1319.96 614.899 1319.85 614.939C1319.74 614.98 1319.62 615 1319.5 615C1319.26 615 1319.02 614.915 1318.85 614.763C1318.68 614.611 1318.58 614.405 1318.58 614.191L1318.58 612.984C1318.58 611.741 1318.02 610.549 1317.03 609.67C1316.03 608.791 1314.68 608.297 1313.27 608.296L1311.92 608.296C1311.8 608.297 1311.68 608.276 1311.57 608.236C1311.46 608.195 1311.36 608.136 1311.27 608.06C1311.18 607.985 1311.12 607.896 1311.07 607.798C1311.02 607.699 1311 607.594 1311 607.487C1311 607.274 1311.1 607.071 1311.27 606.922C1311.45 606.773 1311.68 606.69 1311.92 606.691Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M96.4874 685.122L97.2021 685.122C97.5714 685.122 97.9371 685.066 98.2784 684.956C98.6196 684.846 98.9296 684.685 99.1908 684.482C99.452 684.279 99.6591 684.037 99.8005 683.772C99.9418 683.507 100.015 683.222 100.015 682.935L100.015 682.378C100.015 682.277 100.066 682.181 100.157 682.111C100.248 682.04 100.371 682 100.5 682C100.564 682 100.627 682.009 100.686 682.028C100.745 682.047 100.799 682.075 100.845 682.11C100.89 682.145 100.926 682.187 100.95 682.233C100.975 682.279 100.987 682.328 100.987 682.378L100.987 682.935C100.987 683.515 101.284 684.071 101.811 684.482C102.339 684.892 103.054 685.122 103.8 685.122L104.515 685.122C104.643 685.122 104.767 685.162 104.858 685.233C104.949 685.304 105 685.4 105 685.5C105 685.6 104.949 685.696 104.858 685.767C104.767 685.838 104.643 685.878 104.515 685.878L103.8 685.878C103.43 685.878 103.064 685.934 102.723 686.044C102.382 686.154 102.072 686.316 101.81 686.519C101.549 686.722 101.342 686.963 101.201 687.229C101.06 687.495 100.987 687.779 100.987 688.067L100.987 688.622C100.987 688.672 100.975 688.721 100.95 688.767C100.926 688.813 100.89 688.855 100.845 688.89C100.799 688.925 100.745 688.953 100.686 688.972C100.627 688.991 100.564 689 100.5 689C100.371 689 100.248 688.96 100.157 688.889C100.066 688.819 100.015 688.723 100.015 688.622L100.015 688.059C100.014 687.479 99.7176 686.923 99.1902 686.513C98.6629 686.103 97.9478 685.872 97.2021 685.872L96.4874 685.872C96.4235 685.872 96.3601 685.862 96.301 685.843C96.2419 685.824 96.1881 685.797 96.1429 685.762C96.0976 685.726 96.0617 685.685 96.0371 685.639C96.0126 685.593 96 685.544 96 685.494C96.002 685.395 96.0543 685.3 96.1456 685.23C96.2368 685.161 96.3596 685.122 96.4874 685.122Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1191.43 742.568L1192.07 742.568C1192.4 742.568 1192.72 742.504 1193.03 742.378C1193.33 742.253 1193.6 742.068 1193.84 741.836C1194.07 741.604 1194.25 741.329 1194.38 741.025C1194.5 740.722 1194.57 740.397 1194.57 740.068L1194.57 739.432C1194.57 739.317 1194.61 739.207 1194.69 739.126C1194.78 739.045 1194.89 739 1195 739C1195.06 739 1195.11 739.011 1195.17 739.032C1195.22 739.054 1195.27 739.086 1195.31 739.126C1195.35 739.166 1195.38 739.214 1195.4 739.266C1195.42 739.318 1195.43 739.375 1195.43 739.432L1195.43 740.068C1195.43 740.732 1195.7 741.367 1196.17 741.836C1196.63 742.305 1197.27 742.568 1197.93 742.568L1198.57 742.568C1198.68 742.568 1198.79 742.614 1198.87 742.695C1198.95 742.776 1199 742.886 1199 743C1199 743.114 1198.95 743.224 1198.87 743.305C1198.79 743.386 1198.68 743.432 1198.57 743.432L1197.93 743.432C1197.6 743.432 1197.28 743.496 1196.98 743.622C1196.67 743.748 1196.4 743.932 1196.16 744.164C1195.93 744.397 1195.75 744.672 1195.62 744.976C1195.5 745.279 1195.43 745.605 1195.43 745.933L1195.43 746.568C1195.43 746.625 1195.42 746.682 1195.4 746.734C1195.38 746.786 1195.35 746.834 1195.31 746.874C1195.27 746.914 1195.22 746.946 1195.17 746.968C1195.11 746.989 1195.06 747 1195 747C1194.89 747 1194.78 746.955 1194.69 746.874C1194.61 746.793 1194.57 746.683 1194.57 746.568L1194.57 745.925C1194.57 745.262 1194.3 744.626 1193.84 744.157C1193.37 743.689 1192.73 743.425 1192.07 743.425L1191.43 743.425C1191.38 743.425 1191.32 743.414 1191.27 743.392C1191.22 743.371 1191.17 743.339 1191.13 743.299C1191.09 743.259 1191.05 743.211 1191.03 743.159C1191.01 743.106 1191 743.05 1191 742.993C1191 742.88 1191.05 742.771 1191.13 742.692C1191.21 742.612 1191.32 742.568 1191.43 742.568Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1292.49 618.568L1293.2 618.568C1293.57 618.568 1293.94 618.504 1294.28 618.378C1294.62 618.253 1294.93 618.068 1295.19 617.836C1295.45 617.604 1295.66 617.329 1295.8 617.025C1295.94 616.722 1296.01 616.397 1296.01 616.068L1296.01 615.432C1296.01 615.317 1296.07 615.207 1296.16 615.126C1296.25 615.045 1296.37 615 1296.5 615C1296.56 615 1296.63 615.011 1296.69 615.032C1296.75 615.054 1296.8 615.086 1296.84 615.126C1296.89 615.166 1296.93 615.214 1296.95 615.266C1296.97 615.318 1296.99 615.375 1296.99 615.432L1296.99 616.068C1296.99 616.732 1297.28 617.367 1297.81 617.836C1298.34 618.305 1299.05 618.568 1299.8 618.568L1300.51 618.568C1300.64 618.568 1300.77 618.614 1300.86 618.695C1300.95 618.776 1301 618.886 1301 619C1301 619.114 1300.95 619.224 1300.86 619.305C1300.77 619.386 1300.64 619.432 1300.51 619.432L1299.8 619.432C1299.43 619.432 1299.06 619.496 1298.72 619.622C1298.38 619.748 1298.07 619.932 1297.81 620.164C1297.55 620.397 1297.34 620.672 1297.2 620.976C1297.06 621.279 1296.99 621.605 1296.99 621.933L1296.99 622.568C1296.99 622.625 1296.97 622.682 1296.95 622.734C1296.93 622.786 1296.89 622.834 1296.84 622.874C1296.8 622.914 1296.75 622.946 1296.69 622.968C1296.63 622.989 1296.56 623 1296.5 623C1296.37 623 1296.25 622.955 1296.16 622.874C1296.07 622.793 1296.01 622.683 1296.01 622.568L1296.01 621.925C1296.01 621.262 1295.72 620.626 1295.19 620.157C1294.66 619.689 1293.95 619.425 1293.2 619.425L1292.49 619.425C1292.42 619.425 1292.36 619.414 1292.3 619.392C1292.24 619.371 1292.19 619.339 1292.14 619.299C1292.1 619.259 1292.06 619.211 1292.04 619.159C1292.01 619.106 1292 619.05 1292 618.993C1292 618.88 1292.05 618.771 1292.15 618.692C1292.24 618.612 1292.36 618.568 1292.49 618.568Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M992.487 532.122L993.202 532.122C993.571 532.122 993.937 532.066 994.278 531.956C994.62 531.846 994.93 531.685 995.191 531.482C995.452 531.279 995.659 531.037 995.8 530.772C995.942 530.507 996.015 530.222 996.015 529.935L996.015 529.378C996.015 529.277 996.066 529.181 996.157 529.111C996.248 529.04 996.371 529 996.5 529C996.564 529 996.627 529.009 996.686 529.028C996.745 529.047 996.799 529.075 996.845 529.11C996.89 529.145 996.926 529.187 996.95 529.233C996.975 529.279 996.987 529.328 996.987 529.378L996.987 529.935C996.987 530.515 997.284 531.071 997.811 531.482C998.339 531.892 999.054 532.122 999.8 532.122L1000.51 532.122C1000.64 532.122 1000.77 532.162 1000.86 532.233C1000.95 532.304 1001 532.4 1001 532.5C1001 532.6 1000.95 532.696 1000.86 532.767C1000.77 532.838 1000.64 532.878 1000.51 532.878L999.8 532.878C999.43 532.878 999.064 532.934 998.723 533.044C998.382 533.154 998.072 533.316 997.81 533.519C997.549 533.722 997.342 533.963 997.201 534.229C997.06 534.495 996.987 534.779 996.987 535.067L996.987 535.622C996.987 535.672 996.975 535.721 996.95 535.767C996.926 535.813 996.89 535.855 996.845 535.89C996.799 535.925 996.745 535.953 996.686 535.972C996.627 535.991 996.564 536 996.5 536C996.371 536 996.248 535.96 996.157 535.889C996.066 535.819 996.015 535.723 996.015 535.622L996.015 535.059C996.014 534.479 995.718 533.923 995.19 533.513C994.663 533.103 993.948 532.872 993.202 532.872L992.487 532.872C992.423 532.872 992.36 532.862 992.301 532.843C992.242 532.824 992.188 532.797 992.143 532.762C992.098 532.726 992.062 532.685 992.037 532.639C992.013 532.593 992 532.544 992 532.494C992.002 532.395 992.054 532.3 992.146 532.23C992.237 532.161 992.36 532.122 992.487 532.122Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M429.433 557.568L430.068 557.568C430.397 557.568 430.722 557.504 431.025 557.378C431.329 557.253 431.604 557.068 431.836 556.836C432.068 556.604 432.253 556.329 432.378 556.025C432.504 555.722 432.568 555.397 432.568 555.068L432.568 554.432C432.568 554.317 432.614 554.207 432.695 554.126C432.776 554.045 432.886 554 433 554C433.057 554 433.113 554.011 433.166 554.032C433.218 554.054 433.266 554.086 433.306 554.126C433.346 554.166 433.378 554.214 433.4 554.266C433.422 554.318 433.433 554.375 433.433 554.432L433.433 555.068C433.433 555.732 433.697 556.367 434.165 556.836C434.634 557.305 435.27 557.568 435.933 557.568L436.568 557.568C436.683 557.568 436.793 557.614 436.874 557.695C436.955 557.776 437 557.886 437 558C437 558.114 436.955 558.224 436.874 558.305C436.793 558.386 436.683 558.432 436.568 558.432L435.933 558.432C435.605 558.432 435.28 558.496 434.976 558.622C434.673 558.748 434.397 558.932 434.165 559.164C433.933 559.397 433.749 559.672 433.623 559.976C433.497 560.279 433.433 560.605 433.433 560.933L433.433 561.568C433.433 561.625 433.422 561.682 433.4 561.734C433.378 561.786 433.346 561.834 433.306 561.874C433.266 561.914 433.218 561.946 433.166 561.968C433.113 561.989 433.057 562 433 562C432.886 562 432.776 561.955 432.695 561.874C432.614 561.793 432.568 561.683 432.568 561.568L432.568 560.925C432.568 560.262 432.305 559.626 431.836 559.157C431.367 558.689 430.731 558.425 430.068 558.425L429.433 558.425C429.376 558.425 429.32 558.414 429.268 558.392C429.215 558.371 429.167 558.339 429.127 558.299C429.087 558.259 429.055 558.211 429.033 558.159C429.011 558.106 429 558.05 429 557.993C429.002 557.88 429.048 557.771 429.129 557.692C429.21 557.612 429.32 557.568 429.433 557.568Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.4874 695.568L79.2021 695.568C79.5714 695.568 79.9371 695.504 80.2784 695.378C80.6196 695.253 80.9296 695.068 81.1908 694.836C81.452 694.604 81.6591 694.329 81.8005 694.025C81.9418 693.722 82.0146 693.397 82.0146 693.068L82.0146 692.432C82.0146 692.317 82.0657 692.207 82.1567 692.126C82.2478 692.045 82.3713 692 82.5 692C82.5639 692 82.6272 692.011 82.6864 692.032C82.7455 692.054 82.7992 692.086 82.8445 692.126C82.8898 692.166 82.9257 692.214 82.9502 692.266C82.9748 692.318 82.9874 692.375 82.9874 692.432L82.9874 693.068C82.9874 693.732 83.2837 694.367 83.8111 694.836C84.3386 695.305 85.054 695.568 85.7999 695.568L86.5146 695.568C86.6433 695.568 86.7668 695.614 86.8578 695.695C86.9489 695.776 87 695.886 87 696C87 696.114 86.9489 696.224 86.8578 696.305C86.7668 696.386 86.6433 696.432 86.5146 696.432L85.7999 696.432C85.4304 696.432 85.0645 696.496 84.7231 696.622C84.3818 696.748 84.0716 696.932 83.8105 697.164C83.5493 697.397 83.3421 697.672 83.2009 697.976C83.0597 698.279 82.9871 698.605 82.9874 698.933L82.9874 699.568C82.9874 699.625 82.9748 699.682 82.9502 699.734C82.9257 699.786 82.8898 699.834 82.8445 699.874C82.7992 699.914 82.7455 699.946 82.6864 699.968C82.6272 699.989 82.5639 700 82.5 700C82.3713 700 82.2478 699.955 82.1567 699.874C82.0657 699.793 82.0146 699.683 82.0146 699.568L82.0146 698.925C82.014 698.262 81.7176 697.626 81.1902 697.157C80.6629 696.689 79.9478 696.425 79.2021 696.425L78.4874 696.425C78.4235 696.425 78.3601 696.414 78.301 696.392C78.2419 696.371 78.1881 696.339 78.1429 696.299C78.0976 696.259 78.0617 696.211 78.0371 696.159C78.0126 696.106 78 696.05 78 695.993C78.002 695.88 78.0543 695.771 78.1456 695.692C78.2368 695.612 78.3596 695.568 78.4874 695.568Z"
        fill="white"
      />
      <circle
        r="2"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 1047 648)"
        fill="#C4C4C4"
      />
      <circle
        r="2"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 394 578)"
        fill="#C4C4C4"
      />
      <ellipse
        rx="4"
        ry="3.5"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 96 727.5)"
        fill="#C4C4C4"
      />
      <circle
        r="2"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 210 874)"
        fill="#C4C4C4"
      />
      <circle
        r="2"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 2 564)"
        fill="#C4C4C4"
      />
      <ellipse
        rx="2.5"
        ry="2"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 622.5 511)"
        fill="#C4C4C4"
      />
      <ellipse
        rx="2"
        ry="1.5"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 1382 685.5)"
        fill="#C4C4C4"
      />
      <circle
        r="2"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 1235 536)"
        fill="#C4C4C4"
      />
      <circle
        r="2"
        transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 933 835)"
        fill="#C4C4C4"
      />
    </Box>
  );
}
