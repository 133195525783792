import { TextField, Typography } from "@mui/material";
import React from "react";

type TitleFieldProps = {
  errorMessage: string | undefined;
  textFieldProps: object;
  disabled: boolean;
};

export const TitleField = ({
  errorMessage,
  textFieldProps,
  disabled,
}: TitleFieldProps) => {
  return (
    <>
      <Typography variant="body1" gutterBottom sx={{ mb: 1, fontWeight: 700 }}>
        Summary
      </Typography>
      <TextField
        placeholder="Write a short description to identify the issue..."
        {...textFieldProps}
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        disabled={disabled}
        slotProps={{
          input: {
            sx: {
              backgroundColor: "code.background",
              borderRadius: 1,
            },
          },
        }}
        data-test-id="case-title-input"
      />
    </>
  );
};
