import { Box, Grid } from "@mui/material";
import { useEpssRisk } from "./useEpssRisk";
import { EpssChart } from "./epss-chart";
import { EpssMetricBox } from "./epss-metric-box";

export type EpssRiskContentProps = {
  id?: string;
};

export function EpssRiskContent({ id }: EpssRiskContentProps) {
  const risk = useEpssRisk();
  return (
    <Box id={id}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          md={6}
          lg={8.5}
          container
          spacing={3}
          order={{ xs: 2, md: 1 }}
          sx={{ mb: "auto" }}
        >
          {Object.values(risk.metrics).map((metric, i) => (
            <Grid
              key={metric.label}
              item
              xs={12}
              sm={6}
              md={6}
              lg={4}
              display="flex"
            >
              <EpssMetricBox metric={metric} />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12} md={6} lg={3.5} order={{ xs: 1, md: 2 }}>
          <EpssChart />
        </Grid>
      </Grid>
    </Box>
  );
}
