import { default as AwsColor48 } from "./aws-color-48.png";
import { default as KubernetesColor48 } from "./kubernetes-color-48.png";
import { default as Workstation56 } from "./local-56.png";
import { default as BottomBanner2x } from "./bottombanner-2x.png";
import { default as BottomBanner } from "./bottombanner-1x.png";
import { default as BottomBannerLight2x } from "./bottom-banner-light-2x.jpg";
import { default as BottomBannerLight } from "./bottom-banner-light-1x.jpg";

export {
  AwsColor48,
  KubernetesColor48,
  Workstation56,
  BottomBanner,
  BottomBanner2x,
  BottomBannerLight,
  BottomBannerLight2x,
};

export const getTemporaryIcon = (id: string | undefined | null) => {
  switch (id?.toLowerCase()) {
    case "mondoo/aws":
    case "amazon aws":
    case "aws":
      return AwsColor48;
    case "mondoo/kubernetes":
    case "kubernetes":
    case "k8s":
      return KubernetesColor48;
    case "mondoo/local":
    case "managed":
    case "managed_client":
      return Workstation56;
    default:
      return Workstation56;
  }
};
