import { createSvgIcon } from "@mui/material";

export const RHELInstancesIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_4099)" />
    <svg
      width="28"
      height="22"
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="6"
      y="9"
    >
      <path
        d="M23.4646 10.5384L23.6954 12.0769C24.08 18.923 5.15696 14.3077 5.46465 8.53842C5.46465 8.15381 5.54157 7.84612 5.69542 7.4615L6.23388 6.15381C6.23388 6.15381 19.3108 9.53842 23.157 9.15381L23.4646 10.5384Z"
        fill="url(#paint0_linear_1045_13834)"
      />
      <path
        d="M18.6923 12.2308C20.5385 12.2308 23.1538 11.8462 23.1538 9.69231C23.1772 9.51358 23.1772 9.33257 23.1538 9.15385L22 4.38462C21.7692 3.38462 21.5385 2.84615 19.6923 1.92308C18.3077 1.23077 15.1538 0 14.3077 0C13.3846 0 13.1538 1.15385 12.1538 1.15385C11.1538 1.15385 10.4615 0.307692 9.53846 0.307692C8.61539 0.307692 8.07692 0.923077 7.61539 2.15385L6.23077 6.15385C6.18757 6.2512 6.16154 6.3553 6.15385 6.46154C6.15385 7.76923 11.5385 12.2308 18.6923 12.2308ZM23.4615 10.5385L23.6923 12.0769C23.6923 14.0769 21.3846 15.2308 18.3846 15.2308C11.4615 15.2308 5.46154 11.2308 5.46154 8.53846C5.46343 8.16742 5.542 7.80078 5.69231 7.46154C3.23077 7.61539 0 8.07692 0 10.8462C0 15.4615 11 21.2308 19.6923 21.2308C26.3077 21.2308 28 18.2308 28 15.8462C28 13.9231 26.3846 11.8462 23.4615 10.5385Z"
        fill="white"
      />
    </svg>
    <defs>
      <linearGradient
        id="paint0_linear_1045_4099"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "RHEL Instances",
);
