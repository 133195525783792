import { TableCell } from "@mui/material";
import { Impact } from "../impact";

type ImpactCellProps = {
  impact: number;
  isActive: boolean;
};

export function ImpactCell({ impact, isActive }: ImpactCellProps) {
  return (
    <TableCell>
      <Impact impact={impact} isActive={isActive} isRiskScore />
    </TableCell>
  );
}
