import { Route, Routes } from "react-router-dom";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { WorkspacesEntryPage } from "~/pages/space/Workspaces/WorkspacesEntryPage";
import { CreateWorkspacePage } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspacePage";

export type WorkspaceRoutesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function WorkspaceRoutes({
  space,
  availablePermissions,
}: WorkspaceRoutesProps) {
  return (
    <Routes>
      <Route
        index
        element={<WorkspacesEntryPage {...{ space, availablePermissions }} />}
      />
      <Route
        path="new"
        element={<CreateWorkspacePage {...{ space, availablePermissions }} />}
      />
    </Routes>
  );
}
