import {
  Box,
  Button,
  ButtonProps,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import {
  NavDrawerVulnerabilitiesIcon,
  ChecklistIcon,
  NavDrawerFleetIcon,
  NavDrawerPolicyIcon,
  DataObjectIcon,
  NavDrawerSpacesIcon,
  NavDrawerOverviewIcon,
  AdvisoriesIcon,
} from "~/components/icons";
import { ComplianceNavIcon } from "../compliance/temporary/ComplianceNavIcon";
import { AggregateScoreType } from "~/operations";
import { FilterType } from ".";
import { pluralize } from "~/lib/pluralize";

type SearchFilterGroupButtonProps = {
  active: boolean;
  filter: FilterType;
} & ButtonProps;

export const SearchFilterGroupButton = ({
  filter,
  active,
  ...props
}: SearchFilterGroupButtonProps) => {
  const theme = useTheme();

  const backgroundColor = active
    ? alpha(theme.palette.primary.main, 0.2)
    : "background.paper";

  return (
    <Box
      {...props}
      component={Button}
      disabled={filter.count === 0}
      sx={{
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        alignItems: "center",
        minWidth: 188,
        p: 1,
        borderRadius: 1,
        backgroundColor,
        boxShadow: 1,
      }}
    >
      <DynamicSearchIcon filterType={filter.scoreType} />
      <Typography
        variant="body2"
        sx={{
          ml: 1,
          fontWeight: 700,
          textTransform: "capitalize",
        }}
      >
        {filter.scoreType === "All"
          ? filter.scoreType
          : pluralize(filter.scoreType, 2)}
      </Typography>
      <Typography variant="body2" sx={{ ml: "auto", color: "text.secondary" }}>
        {filter.count}
      </Typography>
    </Box>
  );
};

export const DynamicSearchIcon = ({ filterType }: { filterType: string }) => {
  const sx = { color: "text.secondary" };

  switch (filterType.toUpperCase()) {
    case AggregateScoreType.Vulnerability:
      return <NavDrawerVulnerabilitiesIcon {...{ sx }} />;
    case AggregateScoreType.Advisory:
      return <AdvisoriesIcon {...{ sx }} />;
    case AggregateScoreType.Check:
      return <ChecklistIcon fontSize="small" {...{ sx }} />;
    case AggregateScoreType.Asset:
      return <NavDrawerFleetIcon {...{ sx }} />;
    case AggregateScoreType.Policy:
      return <NavDrawerPolicyIcon {...{ sx }} />;
    case "ALL": {
      return <NavDrawerOverviewIcon {...{ sx }} />;
    }
    case "QUERIES":
      return <DataObjectIcon {...{ sx }} />;
    case "CONTROL":
      return <ComplianceNavIcon {...{ sx }} />;
    case "SPACES":
      return <NavDrawerSpacesIcon {...{ sx }} />;

    //   unhandled
    // case "policy":
    // case "risk":
    // case "advisory":

    default:
      return <NavDrawerFleetIcon {...{ sx }} />;
  }
};
