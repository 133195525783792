import { createSvgIcon } from "@mui/material";

export const ContainerEnvironmentIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0628 21.9912H11.9144C11.8656 22.0029 11.8149 22.0029 11.7661 21.9912L3.85786 17.7731C3.75177 17.7177 3.66249 17.6324 3.60002 17.5269C3.53754 17.4214 3.50433 17.2998 3.5041 17.1756V6.82761C3.49863 6.77599 3.49863 6.72389 3.5041 6.67227C3.51882 6.60379 3.54594 6.53889 3.58398 6.48108C3.62696 6.42201 3.68141 6.37314 3.74374 6.33769C3.77649 6.29946 3.81505 6.26716 3.85786 6.2421H3.91491L11.7318 2.07182C11.8202 2.02461 11.9179 2 12.0171 2C12.1163 2 12.2141 2.02461 12.3024 2.07182L20.2106 6.20625C20.2534 6.23131 20.292 6.26361 20.3247 6.30184C20.365 6.34008 20.3996 6.38438 20.4274 6.43329C20.462 6.49172 20.4852 6.55667 20.4959 6.62447C20.5014 6.6761 20.5014 6.72819 20.4959 6.77981V17.1278C20.4957 17.252 20.4625 17.3736 20.4 17.4791C20.3375 17.5847 20.2482 17.6699 20.1421 17.7253L12.3595 21.9075C12.3104 21.9172 12.2601 21.9172 12.2111 21.9075L12.0628 21.9912ZM12.7018 11.3683V20.2346L19.3433 16.7574V7.96279L12.7018 11.3683ZM4.7936 16.7574L11.4237 20.2346V11.3683L4.7936 7.96279V16.7574ZM5.581 6.76786L12.0628 10.2092L18.5788 6.76786L12.0628 3.42208L5.581 6.76786Z"
      fill="white"
    />
  </svg>,
  "Container Environment",
);
