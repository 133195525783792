import { Box, Link } from "@mui/material";
import {
  ConfigurationPaper,
  ConfigurationList,
  ConfigurationItemLabel,
  ConfigurationItemButton,
} from "~/components/configuration-items";
import { FormatDay } from "~/lib/date";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import {
  LoadLegalPolicyConsentDocument,
  useAcceptLegalPolicyMutation,
  useLoadLegalPolicyConsentQuery,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useOrgSettingsOutletContext } from "./settings";

type ConsentState = {
  termsApprovalMsg: string;
  termsRequiresAccept: boolean;
  privacyApprovalMsg: string;
  privacyRequiresAccept: boolean;
};

export const LegalCompliance = () => {
  const { org } = useOrgSettingsOutletContext();
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, error } = useLoadLegalPolicyConsentQuery({
    variables: { mrn: org.mrn },
    notifyOnNetworkStatusChange: false,
  });

  const [policyAccept] = useAcceptLegalPolicyMutation();

  const acceptHandler = async (policyId: string) => {
    try {
      await policyAccept({
        variables: { input: { policyId, resourceMrn: org.mrn } },
        refetchQueries: [LoadLegalPolicyConsentDocument],
      });
    } catch (error) {
      enqueueSnackbar("Error occurred while attempting to accept policy", {
        variant: "error",
      });
    }
  };

  if (loading) {
    return <LoadingPage what="Legal Compliance" />;
  }
  if (error || !data) {
    return <LoadingFailedPage what="Legal Compliance" />;
  }

  const consentState: ConsentState = {
    termsApprovalMsg: "",
    termsRequiresAccept: true,
    privacyApprovalMsg: "",
    privacyRequiresAccept: true,
  };
  data.legalPolicies.forEach((policy) => {
    let approvalMessage = "";
    let requiresAccept = true;
    if (policy) {
      if (policy.consent) {
        approvalMessage = `Accepted on ${FormatDay(
          policy.consent.signatureAt,
        )} by ${policy.consent.email}`;
        requiresAccept = false;
      } else {
        approvalMessage = "Error loading legal consent data";
      }
      switch (policy.id) {
        case "terms":
          consentState.termsApprovalMsg = approvalMessage;
          consentState.termsRequiresAccept = requiresAccept;
          break;
        case "privacy":
          consentState.privacyApprovalMsg = approvalMessage;
          consentState.privacyRequiresAccept = requiresAccept;
          break;
      }
    }
  });

  return (
    <Box sx={{ ".MuiListItemSecondaryAction-root": { textAlign: "right" } }}>
      <ConfigurationPaper>
        <ConfigurationList title="Legal">
          {!consentState.privacyRequiresAccept && (
            <ConfigurationItemLabel
              primary={
                <Link
                  id="privacy-policy"
                  variant="h6"
                  target="_blank"
                  href="https://mondoo.com/privacy-policy"
                >
                  Privacy Policy
                </Link>
              }
              secondary="Updated on September 1, 2020"
              label={consentState.privacyApprovalMsg}
            ></ConfigurationItemLabel>
          )}
          {consentState.privacyRequiresAccept && (
            <ConfigurationItemButton
              primary={
                <Link
                  id="privacy-policy-accept"
                  variant="h6"
                  target="_blank"
                  href="https://mondoo.com/privacy-policy"
                >
                  Privacy Policy
                </Link>
              }
              secondary="Updated on September 1, 2020"
              action="Accept"
              onClick={() => acceptHandler("privacy")}
            ></ConfigurationItemButton>
          )}
          {!consentState.termsRequiresAccept && (
            <ConfigurationItemLabel
              primary={
                <Link
                  id="terms-and-conditions"
                  variant="h6"
                  target="_blank"
                  href="https://mondoo.com/terms-of-service"
                >
                  Terms &amp; Conditions
                </Link>
              }
              secondary="Updated on September 1, 2020"
              label={consentState.termsApprovalMsg}
            ></ConfigurationItemLabel>
          )}
          {consentState.termsRequiresAccept && (
            <ConfigurationItemButton
              primary={
                <Link
                  id="terms-and-conditions-accept"
                  variant="h6"
                  target="_blank"
                  href="https://mondoo.com/terms-of-service"
                >
                  Terms &amp; Conditions
                </Link>
              }
              secondary="Updated on September 1, 2020"
              action="Accept"
              onClick={() => acceptHandler("terms")}
            ></ConfigurationItemButton>
          )}
        </ConfigurationList>
      </ConfigurationPaper>
    </Box>
  );
};
