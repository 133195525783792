import {
  Link as RouterLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";
import { PaginationRange } from "~/components/pagination";
import {
  AdvisoryNodeFieldsFragment,
  AssetPackageDetailsFieldsFragment,
  CveNodeFieldsFragment,
  useAssetSoftwareDetailsQuery,
} from "~/operations";
import { Link, Typography } from "@mui/material";
import { HomeIcon } from "~/components/icons";
import { StatsProps } from "~/components/DetailsPage/components/Stats/Stats";
import { FlagOutlined, HealingOutlined } from "@mui/icons-material";
import { Space } from "~/lib/types";

type UseSoftwareDetailsProps = {
  space: Space;
};

type UseSoftwareDetailsReturn = {
  breadcrumbs: Array<React.ReactNode>;
  software: AssetPackageDetailsFieldsFragment | null | undefined;
  stats: StatsProps["stats"];
  cves: CveNodeFieldsFragment[];
  advisories: AdvisoryNodeFieldsFragment[];
  setCvePageItems: Dispatch<SetStateAction<PaginationRange>>;
  setAdvisoryPageItems: Dispatch<SetStateAction<PaginationRange>>;
  state: {
    isLoading: boolean;
    hasError: boolean;
  };
  assetMrn: string;
};

export function useSoftwareDetails({
  space,
}: UseSoftwareDetailsProps): UseSoftwareDetailsReturn {
  const [searchParams] = useSearchParams();
  const { name = "" } = useParams();
  const assetMrn = searchParams.get("assetMrn") || "";
  const assetId = assetMrn.split("/").pop() || "";
  const customPaginationRange = {
    from: 0,
    to: 3,
  };

  const [cvePageItems, setCvePageItems] = useState<PaginationRange>(
    customPaginationRange,
  );
  const [advisoryPageItems, setAdvisoryPageItems] = useState<PaginationRange>(
    customPaginationRange,
  );

  const { data, loading, error } = useAssetSoftwareDetailsQuery({
    variables: {
      input: {
        mrn: String(assetMrn),
        name,
      },
    },
  });

  const software = data?.assetPackageDetails as
    | AssetPackageDetailsFieldsFragment
    | undefined;

  const cves =
    (software?.cveSummaries?.edges || [])
      ?.slice(cvePageItems.from, cvePageItems.to)
      ?.flatMap((e) => e.node?.cve ?? []) || [];

  const advisories =
    (software?.advisorySummaries?.edges || [])
      ?.slice(advisoryPageItems.from, advisoryPageItems.to)
      ?.flatMap((e) => e.node?.advisory ?? []) || [];

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/inventory"
      component={RouterLink}
      to={`/space/inventory?spaceId=${space.id}`}
    >
      Inventory
    </Link>,
    <Link
      key="space/inventory/assetId"
      component={RouterLink}
      to={`/space/inventory/${assetId}/software?spaceId=${space.id}`}
    >
      {software?.asset?.name}
    </Link>,
    <Typography key="assetName">{software?.name}</Typography>,
  ];

  const stats: StatsProps["stats"] = [
    {
      label: "Installed",
      value: software?.version || "",
      icon: <FlagOutlined fontSize="inherit" />,
    },
    software?.available
      ? {
          label: "Available",
          value: software?.available,
          icon: <HealingOutlined fontSize="inherit" />,
        }
      : undefined,
  ].flatMap((f) => f ?? []);

  return {
    assetMrn,
    software,
    breadcrumbs,
    stats,
    cves,
    advisories,
    setAdvisoryPageItems,
    setCvePageItems,
    state: {
      isLoading: loading,
      hasError: !!error,
    },
  };
}
