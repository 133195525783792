import React from "react";
import { FormControl, TextField, Typography, useTheme } from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import {
  Control,
  FormState,
  UseFormGetFieldState,
} from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { NotesField } from "~/components/cases/components/CreateCaseModal/NotesField";
import { TitleField } from "~/components/cases/components/CreateCaseModal/TitleField";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  getFieldState: UseFormGetFieldState<CreateCasesFormInput>;
  errors: FieldErrors<CreateCasesFormInput>;
  disabled: boolean;
};

export const CreateCaseGithubModal = ({
  hasCreateCasePermissions,
  control,
  register,
  formState,
  getFieldState,
  errors,
  disabled,
}: CreateCaseModalProps) => {
  const theme = useTheme();

  return (
    <>
      <FormControl fullWidth>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Organization
        </Typography>
        <Controller
          name="github.owner"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              disabled={disabled}
              sx={{
                background: theme.palette.code.background,
                borderRadius: 1,
                color: "text.primary",
                ...helperTextStyles,
              }}
              placeholder={"Your GitHub organization..."}
              error={
                getFieldState("github.owner").isTouched &&
                Boolean(errors.github?.owner)
              }
              helperText={
                Boolean(errors.github?.owner) &&
                getFieldState("github.owner").isTouched && (
                  <ValidationMessage error={errors.github?.owner} />
                )
              }
            />
          )}
        />
        <FormControl fullWidth sx={{ marginTop: 3 }}>
          <Typography
            variant="body1"
            gutterBottom
            sx={{ mb: 1, fontWeight: 700 }}
          >
            Repository
          </Typography>
          <Controller
            name="github.repo"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                disabled={disabled}
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={"Your GitHub repository..."}
                error={
                  getFieldState("github.repo").isTouched &&
                  Boolean(errors.github?.repo)
                }
                helperText={
                  Boolean(errors.github?.repo) &&
                  getFieldState("github.repo").isTouched && (
                    <ValidationMessage error={errors.github?.repo} />
                  )
                }
              />
            )}
          />
        </FormControl>
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <TitleField
          textFieldProps={register("github.title", { required: true })}
          errorMessage={formState.errors?.github?.title?.message}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <NotesField
          errorMessage={formState.errors.github?.notes?.message}
          textFieldProps={register("github.notes")}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
    </>
  );
};
