import {
  alpha,
  Box,
  CircularProgress,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { getColorByCompletion } from "~/lib/colors";

type Props = {
  impactScore: number;
  completion: number;
  hideRadial?: boolean;
};

// Swap out default 66 upon implementation
function ImpactRadial({
  impactScore = 0,
  completion = 0,
  hideRadial = false,
}: Props) {
  const theme = useTheme();

  // todo: replace w/ getColorByScore?
  const getSeverityData = (severity: number) => {
    if (!severity) {
      return {
        color: theme.palette.critical.main,
        label: "critical",
        number: 100,
      };
    } else if (severity >= 91) {
      return {
        color: theme.palette.critical.main,
        label: "critical",
        number: severity,
      };
    } else if (severity >= 71) {
      return {
        color: theme.palette.high.main,
        label: "high",
        number: severity,
      };
    } else if (severity >= 41) {
      return {
        color: theme.palette.medium.main,
        label: "medium",
        number: severity,
      };
    } else if (severity >= 1) {
      return {
        color: theme.palette.low.main,
        label: "low",
        number: severity,
      };
    } else {
      return {
        color: theme.palette.unrated.main,
        label: "info",
        number: severity,
      };
    }
  };

  return (
    <Tooltip
      title={`Completion: ${Math.round(completion * 100)}%`}
      arrow
      placement="top"
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: (theme) => theme.spacing(4),
            height: (theme) => theme.spacing(4),
            // background: theme => getColorByScore(theme, impactScore) <== exists in previous open PR
            background: (theme) =>
              alpha(getSeverityData(impactScore).color, 0.5),
            border: "1px solid",
            borderColor: "background.light",
            borderRadius: 50,
            zIndex: 1,
          }}
        >
          <Typography sx={{ fontSize: 12, textAlign: "center" }}>
            {impactScore}
          </Typography>
        </Box>
        <CircularProgress
          variant="determinate"
          value={100}
          size={42}
          thickness={5}
          sx={{
            ...(hideRadial ? { display: "none" } : {}),
            position: "absolute",
            color: (theme) => alpha(theme.palette.background.default, 0.5),
          }}
        />
        <CircularProgress
          variant="determinate"
          value={completion * 100}
          thickness={5}
          sx={{
            ...(hideRadial ? { display: "none" } : {}),
            position: "absolute",
            color: (theme) =>
              getColorByCompletion(theme, completion * 100, false),
            strokeLinecap: "round",
          }}
          size={42}
        />
      </Box>
    </Tooltip>
  );
}

export default ImpactRadial;
