import { Box, TableCell, Typography } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { FirewatchChip } from "./ChipText";
import { DynamicIcon } from "../icons/DynamicIcon";
import { EntityInfoAsset, Icon_Ids, Tag } from "~/operations";

type AssetCellProps = {
  title?: string;
  tags?: EntityInfoAsset["tags"];
  iconId?: Icon_Ids | null;
};

export function AssetCell({ title, tags, iconId }: AssetCellProps) {
  const _tags = tags?.filter((x): x is Tag => x !== null);

  return (
    <TableCell>
      <Clamp>
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: 14,
            lineHeight: "16px",
            gap: 1,
            fontWeight: 700,
          }}
          data-testid="asset-id"
        >
          <DynamicIcon iconId={iconId} size="16" />
          {title}
        </Typography>
      </Clamp>
      <Box display="flex" gap={1} mt={1}>
        <Box display="flex" gap={1} mt={1}>
          {_tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
        </Box>
      </Box>
    </TableCell>
  );
}
