import { createSvgIcon } from "@mui/material";

export const PreviewIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M19.3883 16.47C18.7468 15.5723 18.4074 14.5138 18.4074 13.4088L18.4076 10.035C18.4076 8.35498 17.756 6.77783 16.5733 5.59482C15.9204 4.93946 15.1497 4.44672 14.3086 4.13729C14.3094 4.10684 14.3099 4.07623 14.3099 4.04562C14.3097 2.4496 13.3612 1.19922 12.15 1.19922C10.9389 1.19922 9.9904 2.44964 9.9904 4.04562C9.9904 4.08162 9.99103 4.11746 9.99214 4.15315C7.60452 5.06162 5.89257 7.43049 5.89257 10.1846V13.4084C5.89257 14.5134 5.55349 15.5717 4.91167 16.4696C4.50479 17.0387 4.39311 17.7479 4.6053 18.4153C4.81607 19.0793 5.31268 19.5898 5.96796 19.8159C7.00954 20.1753 8.14087 20.4396 9.32103 20.6021C9.52423 21.8433 10.7153 22.7992 12.15 22.7992C13.5847 22.7992 14.7757 21.8433 14.979 20.6021C16.1591 20.4396 17.2906 20.1753 18.332 19.8159C18.9873 19.5896 19.4839 19.0792 19.6947 18.4153C19.9069 17.7479 19.7952 17.0387 19.3883 16.4696L19.3883 16.47ZM12.1499 2.33566C12.6018 2.33566 13.1015 2.94294 13.1695 3.83825C12.8145 3.78052 12.4517 3.75308 12.084 3.75737C11.7596 3.7607 11.4409 3.78988 11.1299 3.84285C11.1965 2.94519 11.6972 2.33566 12.1499 2.33566ZM12.1499 21.663C11.4115 21.663 10.78 21.2755 10.5331 20.732C11.0664 20.7731 11.6065 20.7938 12.1499 20.7938C12.6933 20.7938 13.2333 20.7731 13.7666 20.732C13.5198 21.2754 12.8882 21.663 12.1499 21.663ZM18.6162 18.0702C18.515 18.3887 18.2775 18.6332 17.9641 18.7414C16.2277 19.3408 14.2173 19.6575 12.1502 19.6575C10.0832 19.6575 8.07252 19.3409 6.33642 18.7415C6.02294 18.6333 5.78546 18.3886 5.68433 18.0703C5.5821 17.7485 5.63583 17.4066 5.83191 17.1323C6.61263 16.0404 7.02517 14.7527 7.02517 13.4087V10.1847C7.02517 7.2962 9.29982 4.92306 12.0959 4.89382C12.1143 4.89367 12.1323 4.89367 12.1506 4.89367C13.5204 4.89367 14.8051 5.42673 15.7738 6.3991C16.7422 7.36783 17.2757 8.65901 17.2757 10.0349V13.4087C17.2757 14.7527 17.6882 16.0403 18.469 17.1323C18.6647 17.4067 18.7186 17.7486 18.6164 18.0703L18.6162 18.0702Z" />
    <path
      d="M4 5L20 21"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  "Preview",
);
