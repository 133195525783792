import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { InfoOutlinedIcon } from "../icons";

type MenuItem = {
  value: string;
  label: string | JSX.Element;
};

type CustomSelectProps = {
  id: string;
  value: string | number;
  label: string;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  menuItems: MenuItem[];
  formHelperText?: string;
};

export function MSelect({
  id,
  value,
  label,
  menuItems,
  onChange,
  formHelperText,
}: CustomSelectProps) {
  return (
    <FormControl
      variant="standard"
      sx={{
        minWidth: 270,
        "& .MuiFormLabel-root": { top: 6, left: 7, zIndex: 100, fontSize: 12 },
        "& .MuiSelect-select": { pt: 1.5, px: 0.75, pb: 0.5 },
        "& .MuiInputBase-root": { mt: 0 },
        ".MuiList-root .MuiList-padding .MuiMenu-list": { pt: 0 },
      }}
    >
      <InputLabel id={`${id}-select-label`}>Score by</InputLabel>
      <Select
        labelId={`${id}-select-label`}
        id={id}
        className="custom-select"
        value={value}
        label={label}
        onChange={onChange}
        sx={{
          background: (theme) => theme.palette.code.background,
          border: "1px solid",
          borderColor: (theme) => theme.palette.background.light,
          borderRadius: 1,
        }}
        disableUnderline
      >
        {
          /* Menu Items */
          menuItems.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))
        }
      </Select>
      {formHelperText && (
        <FormHelperText sx={{ fontSize: 10 }}>
          <InfoOutlinedIcon sx={{ fontSize: 10, mr: 0.25 }} />
          {formHelperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
