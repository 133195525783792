import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";
import { Space } from "~/lib/types";

export type SecurityPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function SecurityPage({}: SecurityPageProps) {
  document.title = "Security · Mondoo";

  return (
    <Box>
      <Outlet />
    </Box>
  );
}
