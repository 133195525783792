import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { ChromeIcon } from "~/components/icons/mondoo/chrome";
import { MozillaIcon } from "~/components/icons/mondoo/mozilla";
import { MSEdgeIcon } from "~/components/icons/mondoo/ms-edge";
import { VSCodeIcon } from "~/components/icons/mondoo/vscode";
import { WindowsIcon } from "~/components/icons/mondoo/integrations/windows";
import { UbuntuIcon } from "~/components/icons/mondoo/integrations/ubuntu";
import { SuseIcon } from "~/components/icons/mondoo/integrations/suse";
import { DebianIcon } from "~/components/icons/mondoo/integrations/debian";
import { AwsIcon } from "~/components/icons/mondoo/integrations/aws";
import { OracleLinuxIcon } from "~/components/icons/mondoo/oracleLinux";
import { VMWareIcon } from "~/components/ui-library";
import {
  AdvisoryIcon,
  CheckIcon,
  CveIcon,
  DnsIcon,
  GitHubIcon,
  PolicyIcon,
} from "~/components/icons/material";
import { SxProps } from "@mui/material";
import { RedHatIcon } from "~/components/icons/mondoo/integrations/redhat";
import {
  AlmaLinuxIcon,
  AlpineLinuxIcon,
  AzureIcon,
  CentosIcon,
  DotNetIcon,
  FedoraIcon,
  GitLabIcon,
  LinuxIcon,
  MacOsIcon,
  OCIIcon,
} from "~/components/icons/mondoo";
import { Icon_Ids } from "~/operations";
import {
  AixIcon,
  GCPIcon,
  GoogleWorkspaceIcon,
  KubernetesIcon,
  MS365Icon,
  OktaIcon,
  SlackIcon,
  TerraformIcon,
} from "~/components/icons/mondoo/integrations";

type DynamicIconProps = {
  iconId: Icon_Ids | null | undefined;
  size?: string;
  defaultIcon?: typeof SvgIcon;
  sx?: SxProps;
};

export const DynamicIcon = ({
  iconId,
  size,
  defaultIcon = AdvisoryIcon,
  sx = {},
}: DynamicIconProps) => {
  const commonProps = {
    "data-testid": "software-icon",
    sx: {
      fontSize: size || 24,
      ...sx,
    },
  };

  const iconKey = iconId || Icon_Ids.Default;

  const iconsDictionary: Record<
    Icon_Ids,
    typeof SvgIcon | React.ComponentType
  > = {
    [Icon_Ids.Check]: CheckIcon,
    [Icon_Ids.Cve]: CveIcon,
    [Icon_Ids.GoogleChrome]: ChromeIcon,
    [Icon_Ids.Mozilla]: MozillaIcon,
    [Icon_Ids.MozillaFirefox]: MozillaIcon,
    [Icon_Ids.Microsoft]: WindowsIcon,
    [Icon_Ids.MicrosoftEdge]: MSEdgeIcon,
    [Icon_Ids.MicrosoftVisualStudioCode]: VSCodeIcon,
    [Icon_Ids.Ubuntu]: UbuntuIcon,
    [Icon_Ids.Redhat]: RedHatIcon,
    [Icon_Ids.Suse]: SuseIcon,
    [Icon_Ids.Debian]: DebianIcon,
    [Icon_Ids.Amazon]: AwsIcon,
    [Icon_Ids.Fedora]: FedoraIcon,
    [Icon_Ids.Centos]: CentosIcon,
    [Icon_Ids.Oracle]: OracleLinuxIcon,
    [Icon_Ids.Other]: defaultIcon,
    [Icon_Ids.Policy]: PolicyIcon,
    [Icon_Ids.Risk]: defaultIcon,
    [Icon_Ids.Vmware]: VMWareIcon,
    [Icon_Ids.VmwarePhoton]: VMWareIcon,
    [Icon_Ids.Alpine]: AlpineLinuxIcon,
    [Icon_Ids.MicrosoftDotnet]: DotNetIcon,
    [Icon_Ids.Aix]: AixIcon,
    [Icon_Ids.Aws]: AwsIcon,
    [Icon_Ids.Azure]: AzureIcon,
    [Icon_Ids.Dns]: DnsIcon,
    [Icon_Ids.Gcp]: GCPIcon,
    [Icon_Ids.Github]: GitHubIcon,
    [Icon_Ids.Gitlab]: GitLabIcon,
    [Icon_Ids.GoogleWorkspace]: GoogleWorkspaceIcon,
    [Icon_Ids.K8S]: KubernetesIcon,
    [Icon_Ids.Linux]: LinuxIcon,
    [Icon_Ids.Macos]: MacOsIcon,
    [Icon_Ids.Ms365]: MS365Icon,
    [Icon_Ids.Oci]: OCIIcon,
    [Icon_Ids.Okta]: OktaIcon,
    [Icon_Ids.Slack]: SlackIcon,
    [Icon_Ids.Terraform]: TerraformIcon,
    [Icon_Ids.Windows]: WindowsIcon,
    [Icon_Ids.AlmaLinux]: AlmaLinuxIcon,
    [Icon_Ids.Arch]: defaultIcon,
    [Icon_Ids.Arista]: defaultIcon,
    [Icon_Ids.Asset]: defaultIcon,
    [Icon_Ids.Atlassian]: defaultIcon,
    [Icon_Ids.Busybox]: defaultIcon,
    [Icon_Ids.Equinix]: defaultIcon,
    [Icon_Ids.EuroLinux]: defaultIcon,
    [Icon_Ids.Gentoo]: defaultIcon,
    [Icon_Ids.Ipmi]: defaultIcon,
    [Icon_Ids.Kali]: defaultIcon,
    [Icon_Ids.LinuxMint]: defaultIcon,
    [Icon_Ids.Manjaro]: defaultIcon,
    [Icon_Ids.Opcua]: defaultIcon,
    [Icon_Ids.Openwrt]: defaultIcon,
    [Icon_Ids.Pop]: defaultIcon,
    [Icon_Ids.Raspbian]: defaultIcon,
    [Icon_Ids.RockyLinux]: defaultIcon,
    [Icon_Ids.ScientificLinux]: defaultIcon,
    [Icon_Ids.Ubios]: defaultIcon,
    [Icon_Ids.Vcd]: defaultIcon,
    [Icon_Ids.WrLinux]: defaultIcon,
    [Icon_Ids.Default]: defaultIcon,
  };

  let Icon = iconsDictionary[iconKey] || defaultIcon;

  return <Icon {...commonProps} />;
};
