import { GitLabColorIcon } from "~/components/ui-library";
import { SxProps } from "@mui/material";
import {
  AzureColorIcon,
  FlyingEnvelopeIcon,
  GithubIcon,
  GitHubIcon,
  MacOsIcon,
  OCIColorIcon,
} from "~/components/icons";
import { AmazonS3Icon } from "~/components/icons/mondoo/integrations/amazon-s3";
import { AwsIcon } from "~/components/icons/mondoo/integrations/aws";
import { AzureIcon } from "~/components/icons/mondoo/integrations/azure";
import { BigqueryIcon } from "~/components/icons/mondoo/integrations/bigquery";
import { GCPIcon } from "~/components/icons/mondoo/integrations/gcp";
import { GoogleWorkspaceIcon } from "~/components/icons/mondoo/integrations/google-workspace";
import { KubernetesIcon } from "~/components/icons/mondoo/integrations/kubernetes";
import { LongLivedCredsIcon } from "~/components/icons/mondoo/integrations/long-lived-creds";
import { MsTeamsIcon } from "~/components/icons/mondoo/integrations/ms-teams";
import { MS365Icon } from "~/components/icons/mondoo/integrations/ms365";
import { OktaIcon } from "~/components/icons/mondoo/integrations/okta";
import { PackerIcon } from "~/components/icons/mondoo/integrations/packer";
import { RedHatIcon } from "~/components/icons/mondoo/integrations/redhat";
import { SlackIcon } from "~/components/icons/mondoo/integrations/slack";
import { SnowflakeIcon } from "~/components/icons/mondoo/integrations/snowflake";
import { SuseIcon } from "~/components/icons/mondoo/integrations/suse";
import { TelegramIcon } from "~/components/icons/mondoo/integrations/telegram";
import { TerraformIcon } from "~/components/icons/mondoo/integrations/terraform";
import { UbuntuIcon } from "~/components/icons/mondoo/integrations/ubuntu";
import { VMwareIcon } from "~/components/icons/mondoo/integrations/vmware";
import { WebhookIcon } from "~/components/icons/mondoo/integrations/webhook";
import { WindowsIcon } from "~/components/icons/mondoo/integrations/windows";
import { WorkstationIcon } from "~/components/icons/mondoo/workstation";
import { ClientIntegrationType, IntegrationType } from "~/operations";
import { OtherIntegrations } from "../headers/integration-add-header";
import { GcsBucketIcon } from "~/components/icons/mondoo/integrations/gcs-bucket";
import { PostgreSQLIcon } from "~/components/icons/mondoo/integrations/postgresql";
import { JiraIcon } from "../../../components/icons/mondoo/integrations/jira-icon";
import { DomainIcon } from "~/components/icons/mondoo/integrations/domain";
import { ZendeskIcon } from "~/components/icons/mondoo/integrations/zendesk";
import {
  AzureDevOpsIcon,
  MsftDefenderIcon,
  ShodanIcon,
} from "~/components/icons/mondoo/integrations";

type Props = {
  type: IntegrationType | OtherIntegrations | ClientIntegrationType;
  sx?: SxProps;
};

export function DynamicIntegrationIcon({ type, sx }: Props) {
  switch (type) {
    case IntegrationType.AwsS3: //fallthrough
    case IntegrationType.S3:
      return <AmazonS3Icon {...{ sx }} />;
    case IntegrationType.Azure:
      return <AzureIcon {...{ sx }} />;
    case IntegrationType.AzureBlob:
      return <AzureIcon {...{ sx }} />;
    case IntegrationType.Aws:
    case OtherIntegrations.AMAZON_LINUX:
      return <AwsIcon {...{ sx }} />;
    case IntegrationType.Oci:
      return <OCIColorIcon {...{ sx }} />;
    case IntegrationType.Bigquery:
      return <BigqueryIcon {...{ sx }} />;
    case IntegrationType.MicrosoftDefender:
      return <MsftDefenderIcon {...{ sx }} />;
    case IntegrationType.Host:
      return <DomainIcon {...{ sx }} />;
    case IntegrationType.Snowflake:
      return <SnowflakeIcon {...{ sx }} />;
    case IntegrationType.Ms365:
      return <MS365Icon {...{ sx }} />;
    case IntegrationType.Gcp:
      return <GCPIcon {...{ sx }} />;
    case IntegrationType.GcsBucket:
      return <GcsBucketIcon {...{ sx }} />;
    case IntegrationType.Okta:
      return <OktaIcon {...{ sx }} />;
    case IntegrationType.Gitlab:
      return <GitLabColorIcon {...{ sx }} />;
    case IntegrationType.Github:
      return <GitHubIcon {...{ sx }} />;
    case IntegrationType.GoogleWorkspace:
      return <GoogleWorkspaceIcon {...{ sx }} />;
    case IntegrationType.TicketSystemJira:
      return <JiraIcon {...{ sx }} />;
    case IntegrationType.TicketSystemEmail:
      return <FlyingEnvelopeIcon {...{ sx }} />;
    case IntegrationType.TicketSystemZendesk:
      return <ZendeskIcon {...{ sx }} />;
    case IntegrationType.TicketSystemGithub:
      return <GithubIcon {...{ sx }} />;
    case IntegrationType.TicketSystemGitlab:
      return <GitLabColorIcon {...{ sx }} />;
    case IntegrationType.TicketSystemAzureDevops:
      return <AzureDevOpsIcon {...{ sx }} />;
    case IntegrationType.Postgres:
      return <PostgreSQLIcon {...{ sx }} />;
    case IntegrationType.K8S:
      return <KubernetesIcon {...{ sx }} />;
    case IntegrationType.Shodan:
      return <ShodanIcon {...{ sx }} />;
    case OtherIntegrations.REDHAT:
      return <RedHatIcon {...{ sx }} />;
    case OtherIntegrations.WINDOWS:
      return <WindowsIcon {...{ sx }} />;
    case OtherIntegrations.UBUNTU_DEBIAN:
      return <UbuntuIcon {...{ sx }} />;
    case OtherIntegrations.SUSE:
      return <SuseIcon {...{ sx }} />;
    case OtherIntegrations.MACOS:
      return <MacOsIcon {...{ sx }} />;
    case OtherIntegrations.VMWARE:
      return <VMwareIcon {...{ sx }} />;
    case OtherIntegrations.PACKER:
      return <PackerIcon {...{ sx }} />;
    case OtherIntegrations.TERRAFORM:
      return <TerraformIcon {...{ sx }} />;
    case OtherIntegrations.CREDENTIALS:
      return <LongLivedCredsIcon {...{ sx }} />;
    case OtherIntegrations.WORKSTATION_MONDOO:
      return <WorkstationIcon {...{ sx }} />;
    case OtherIntegrations.CHATOPS_MSTEAMS:
    case OtherIntegrations.MSTEAMS:
      return <MsTeamsIcon {...{ sx }} />;
    case OtherIntegrations.CHATOPS_SLACK:
    case IntegrationType.HostedSlack:
    case OtherIntegrations.SLACK:
      return <SlackIcon {...{ sx }} />;
    case OtherIntegrations.CHATOPS_TELEGRAM:
    case OtherIntegrations.TELEGRAM:
      return <TelegramIcon {...{ sx }} />;
    case OtherIntegrations.CHATOPS_WEBHOOK:
    case OtherIntegrations.WEBHOOK:
      return <WebhookIcon {...{ sx }} />;
    default:
      return <WorkstationIcon {...{ sx }} />;
  }
}
