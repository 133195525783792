import { Variants } from "framer-motion";

export const motionAccordionVariants: Variants = {
  closed: {
    opacity: 0,
    height: 0,
  },
  open: {
    opacity: 1,
    height: "auto",
  },
};
