import React from "react";
import { FormControl } from "@mui/material";
import { UseFormRegister } from "react-hook-form";
import { FormState } from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import { NotesField } from "~/components/cases/components/CreateCaseModal/NotesField";
import { TitleField } from "~/components/cases/components/CreateCaseModal/TitleField";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
};

export const CreateCaseZendeskModal = ({
  register,
  formState,
  hasCreateCasePermissions,
}: CreateCaseModalProps) => {
  return (
    <>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <TitleField
          textFieldProps={register("zendesk.title", { required: true })}
          errorMessage={formState.errors?.email?.title?.message}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <NotesField
          errorMessage={formState.errors.zendesk?.notes?.message}
          textFieldProps={register("zendesk.notes")}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
    </>
  );
};
