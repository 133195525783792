import React from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Box, Grid } from "@mui/material";
import { CveRiskFactors, EmptySection } from "~/components/vulnerabilities";
import { RiskFactorStats } from "~/operations";

type SoftwareRiskFactorsProps = {
  riskFactorsWithDocs: (RiskFactorStats & {
    description: { active: string; inactive: string };
  })[];
};

export const SoftwareRiskFactors = ({
  riskFactorsWithDocs,
}: SoftwareRiskFactorsProps) => {
  return (
    <Grid id="risk-factors" item xs={12}>
      <SectionHeading heading="Risk Assessment" />
      <Box className="section-content">
        <CveRiskFactors
          id="cve-risk-factors"
          riskFactors={riskFactorsWithDocs}
        />
      </Box>
    </Grid>
  );
};
