import { BoxProps, Box } from "@mui/material";
import { KeyValueChip } from "~/components/asset-search";

type ChipOnDeleteProps = BoxProps & {
  name: string;
  value: string;
  onDelete: () => void;
};

const ChipOnDelete = ({ name, value, onDelete }: ChipOnDeleteProps) => {
  return (
    <Box>
      <KeyValueChip
        keyValue={{ key: name, value }}
        onDelete={() => onDelete()}
      />
    </Box>
  );
};

export default ChipOnDelete;
