import {
  Navigate,
  RouteObject,
  matchRoutes,
  useRoutes,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../login/provider";
import VerifyEmailPage from "./pages/verifyEmail";
import UserManagementPage from "./pages/userManagement";
import { LoginPage } from "./pages/auth/login";
import { SignupPage } from "./pages/auth/signup";
import { SSOComponent } from "./pages/auth/sso";

const unauthRoutes: RouteObject[] = [
  { path: "/signup", element: <AuthPageWrapper view={"signup"} /> },
  { path: "/login", element: <AuthPageWrapper view={"login"} /> },
  { path: "/sso", element: <AuthPageWrapper view={"sso"} /> },
  {
    path: "/reset-password",
    element: <AuthPageWrapper view={"reset-password"} />,
  },
  { path: "/verify-email", element: <VerifyEmailPageWrapper /> },
  { path: "/user-mgmt", element: <UserMgmtPageWrapper /> },
  { path: "*", element: <Navigate to="/signup" /> },
];

export function isUnauthRoute(url: Partial<Location> | string) {
  // Only match against routes w/ a specific path location
  const pathRoutes = unauthRoutes.filter((r) => r.path !== "*");
  // Return true if any routes match the provided url
  const matches = matchRoutes(pathRoutes, url);
  return matches && matches.length > 0;
}

export default function UnauthenticatedApp() {
  return useRoutes(unauthRoutes);
}

// Custom Routing
function AuthPageWrapper({ view }: { view: string }) {
  const { user } = useAuth().state;

  if (user?.emailVerified === false)
    return <Navigate replace to="/verify-email" />;

  switch (view) {
    case "login":
      return <LoginPage />;
    case "signup":
      return <SignupPage />;
    case "sso":
      return <SSOComponent />;
    default:
      return <LoginPage />;
  }
}

function VerifyEmailPageWrapper() {
  const { user } = useAuth().state;

  if (!user) return <Navigate replace to="/login" />;
  return <VerifyEmailPage />;
}

function UserMgmtPageWrapper() {
  let [searchParams] = useSearchParams();
  const mode = searchParams.get("mode");
  const actionCode = searchParams.get("oobCode");

  if (!mode || !actionCode) return <Navigate replace to="/login" />;
  return <UserManagementPage mode={mode} actionCode={actionCode} />;
}
