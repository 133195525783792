import { createSvgIcon } from "@mui/material";

export const PackerIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3.68994L12.1295 7.80891V23L5 18.881V3.68994Z"
      fill="#02A8EF"
    />
    <path
      d="M15.6601 5.46467L7.90918 1V4.10147L13.1772 7.14556V16.45L15.6573 17.8748C17.1856 18.7607 18.4466 18.2288 18.4466 16.6949V9.86635C18.4494 8.33241 17.1954 6.352 15.6601 5.46467Z"
      fill="#02A8EF"
    />
  </svg>,
  "Packer",
);
