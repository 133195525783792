import { createSvgIcon } from "@mui/material";

export const AdvisoriesIcon = createSvgIcon(
  <svg viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.6875 5.75C9.6875 5.33579 9.35171 5 8.9375 5C8.52329 5 8.1875 5.33579 8.1875 5.75V11.25C8.1875 11.6642 8.52329 12 8.9375 12C9.35171 12 9.6875 11.6642 9.6875 11.25V5.75Z"
      fill="currentColor"
    />
    <path
      d="M8.9425 15.0901C9.42851 15.0901 9.8225 14.6961 9.8225 14.2101C9.8225 13.7241 9.42851 13.3301 8.9425 13.3301C8.45649 13.3301 8.0625 13.7241 8.0625 14.2101C8.0625 14.6961 8.45649 15.0901 8.9425 15.0901Z"
      fill="currentColor"
    />
    <path
      d="M9.00004 1.44L16.56 4.44V9C16.56 13.32 13.1 17.4 9.00004 18.57C4.90004 17.39 1.44004 13.31 1.44004 9V4.48L9.00004 1.48M9.00004 0C8.80818 0.00168959 8.61829 0.0389883 8.44004 0.11L0.870041 3.16C0.610525 3.26902 0.389264 3.4527 0.234359 3.68773C0.0794546 3.92276 -0.00210409 4.19852 4.12613e-05 4.48V9C4.12613e-05 14 3.85004 18.59 8.61004 20C8.86391 20.0798 9.13617 20.0798 9.39004 20C14.15 18.58 18 14 18 9V4.48C18.0022 4.19852 17.9206 3.92276 17.7657 3.68773C17.6108 3.4527 17.3896 3.26902 17.13 3.16L9.56004 0.11C9.38179 0.0389883 9.19191 0.00168959 9.00004 0Z"
      fill="currentColor"
    />
  </svg>,
  "Advisories",
);
