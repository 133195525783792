import { Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { Space } from "~/lib/types";
import { Link as RouterLink } from "react-router-dom";
import { GetActiveComplianceFrameworksQuery } from "~/operations";
import { ActiveFrameworkCard } from "./ActiveFrameworkCard";

type ActiveFrameworksProps = {
  space: Space;
  data: GetActiveComplianceFrameworksQuery;
};

export function ActiveFrameworks({ space, data }: ActiveFrameworksProps) {
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      sx={{ width: 1 }}
    >
      <Grid container item spacing={3}>
        {data?.complianceFrameworks?.map((framework) => {
          return (
            <Grid item xs={12} sm={12} md={6} key={framework.mrn}>
              <RouterLink
                to={`${framework.mrn.split("/").pop()}?spaceId=${
                  space.id
                }&frameworkMrn=${framework.mrn}`}
              >
                <ActiveFrameworkCard
                  {...{
                    space,
                    framework,
                  }}
                />
              </RouterLink>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
