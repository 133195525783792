import { alpha, Alert, useTheme } from "@mui/material";
import { EndOfLife, ErrorIcon, InfoIcon } from "./icons";

// info, warn, error
export enum MessageSeverity {
  Info = 1,
  Warning,
  Error,
  Alert,
}

export type Warning = {
  severity: MessageSeverity;
  msg: string;
};

export function WarningMessage({ severity, msg }: Warning) {
  const theme = useTheme();
  const getCardColor = (severity: MessageSeverity) => {
    switch (severity) {
      case MessageSeverity.Info:
        return theme.palette.info.main;
      case MessageSeverity.Warning:
        return theme.palette.warning.main;
      case MessageSeverity.Error:
        return theme.palette.error.main;
      default:
        return theme.palette.info.main;
    }
  };

  const getIcon = (severity: MessageSeverity, color: string) => {
    switch (severity) {
      case MessageSeverity.Info:
        return <InfoIcon fontSize="inherit" sx={{ color }} />;
      case MessageSeverity.Warning:
        return <EndOfLife fontSize="inherit" sx={{ color }} />;
      case MessageSeverity.Error:
        return <ErrorIcon fontSize="inherit" sx={{ color }} />;
      default:
        return <InfoIcon fontSize="inherit" sx={{ color }} />;
    }
  };

  const color = getCardColor(severity);

  return (
    <Alert
      sx={{
        color: color,
        background: alpha(color, 0.1),
        fontWeight: 500,
      }}
      icon={getIcon(severity, color)}
    >
      {msg}
    </Alert>
  );
}
