import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import {
  LoadCicdProjectJobsQuery,
  useLoadCicdProjectJobsQuery,
} from "~/operations";

type CicdProjectJobs = NonNullable<LoadCicdProjectJobsQuery["cicdProjectJobs"]>;

type JobsProviderProps = {
  children: ReactNode;
};

type ContextTypes =
  | {
      cicdProjectJobs: CicdProjectJobs | null;
      loading: boolean;
      refetch: ReturnType<typeof useLoadCicdProjectJobsQuery>["refetch"];
      fetchMore: ReturnType<typeof useLoadCicdProjectJobsQuery>["fetchMore"];
    }
  | undefined;

const JobsContext = createContext<ContextTypes>(undefined);

function JobsProvider({ children }: JobsProviderProps) {
  const [cicdProjectJobs, setCicdProjectJobs] =
    useState<CicdProjectJobs | null>(null);

  const [searchParams] = useSearchParams();

  const spaceId = searchParams.get("spaceId") || "";
  const projectId = searchParams.get("projectId") || "";
  const spaceMrn = `//captain.api.mondoo.app/spaces/${spaceId}`;

  const { data, loading, error, refetch, fetchMore } =
    useLoadCicdProjectJobsQuery({
      variables: { input: { spaceMrn, projectId } },
      skip: !spaceId || !projectId,
    });

  useEffect(() => {
    if (data?.cicdProjectJobs) {
      setCicdProjectJobs(data.cicdProjectJobs);
    }
  }, [data]);

  if (!spaceId) {
    return <Navigate to="/dashboard" />;
  }

  if (!projectId) {
    return <Navigate to={`/space/cicd?spaceId=${spaceId}`} />;
  }

  return (
    <JobsContext.Provider
      value={{ cicdProjectJobs, loading, refetch, fetchMore }}
    >
      {children}
    </JobsContext.Provider>
  );
}

function useJobs() {
  const context = useContext(JobsContext);
  if (context === undefined) {
    throw new Error("useJobs must be used within a JobsProvider");
  }
  return context;
}

export { JobsProvider, useJobs };
