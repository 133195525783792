import { SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const SOCIcon = ({ ...props }) => {
  const { mode } = useColorMode();
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 118 118"
        enableBackground="new 0 0 118 118"
        xmlSpace="preserve"
      >
        <path
          fill={mode === "light" ? "#363636" : "#FFFFFF"}
          d="M32,35.6H34l7.3,17h-2.7l-1.7-4.2h-8.1l-1.7,4.2h-2.7L32,35.6z M36.1,46.3l-3.2-7.8h0l-3.2,7.8H36.1z
	 M43.5,35.6h2.3v17h-2.3V35.6z M62,39.2c-0.5-0.6-1.1-1.1-1.8-1.4c-0.7-0.3-1.5-0.5-2.3-0.5c-1,0-1.8,0.2-2.6,0.6
	c-0.8,0.4-1.4,0.8-2,1.5c-0.5,0.6-1,1.4-1.3,2.2c-0.3,0.8-0.5,1.7-0.5,2.7c0,0.9,0.1,1.7,0.4,2.5c0.3,0.8,0.7,1.5,1.2,2.1
	c0.5,0.6,1.2,1.1,2,1.4c0.8,0.4,1.7,0.5,2.7,0.5c1,0,1.8-0.2,2.6-0.6c0.7-0.4,1.4-1,1.9-1.7l1.9,1.5c-0.1,0.2-0.4,0.4-0.7,0.8
	c-0.3,0.3-0.7,0.6-1.3,1c-0.5,0.3-1.2,0.6-1.9,0.8c-0.7,0.3-1.6,0.4-2.6,0.4c-1.3,0-2.6-0.3-3.6-0.8c-1.1-0.5-2-1.2-2.8-2
	c-0.8-0.8-1.3-1.8-1.7-2.8c-0.4-1.1-0.6-2.1-0.6-3.2c0-1.3,0.2-2.5,0.6-3.6c0.4-1.1,1-2.1,1.8-2.9c0.8-0.8,1.7-1.4,2.8-1.9
	c1.1-0.4,2.3-0.7,3.6-0.7c1.1,0,2.2,0.2,3.3,0.6c1.1,0.4,2,1.1,2.7,2L62,39.2z M67.1,35.6h6c1.1,0,2,0.2,2.7,0.5
	c0.7,0.3,1.3,0.7,1.7,1.1c0.4,0.4,0.7,1,0.9,1.5c0.2,0.6,0.2,1.1,0.2,1.6s-0.1,1.1-0.2,1.7c-0.2,0.6-0.4,1.1-0.9,1.5
	c-0.4,0.4-1,0.8-1.7,1.1c-0.7,0.3-1.6,0.4-2.7,0.4h-3.7v7.5h-2.3V35.6z M69.4,43.1h3c0.4,0,0.9,0,1.3-0.1c0.4-0.1,0.8-0.2,1.2-0.4
	c0.4-0.2,0.6-0.5,0.8-0.8c0.2-0.4,0.3-0.8,0.3-1.4s-0.1-1-0.3-1.4c-0.2-0.4-0.5-0.6-0.8-0.8c-0.3-0.2-0.7-0.3-1.2-0.4
	c-0.4-0.1-0.9-0.1-1.3-0.1h-3V43.1z M84.9,35.6H87l7.3,17h-2.7l-1.7-4.2h-8.1l-1.7,4.2h-2.7L84.9,35.6z M89,46.3l-3.2-7.8h0
	l-3.2,7.8H89z"
        />
        <path
          fill={mode === "light" ? "#363636" : "#FFFFFF"}
          d="M36.6,83.3c0.4,0.6,0.9,1.1,1.6,1.4c0.7,0.3,1.3,0.4,2,0.4c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.8-0.3,1.1-0.6
	c0.3-0.2,0.6-0.5,0.8-0.9s0.3-0.8,0.3-1.2c0-0.7-0.2-1.2-0.6-1.5c-0.4-0.4-0.9-0.6-1.6-0.9c-0.6-0.2-1.3-0.5-2-0.7
	c-0.7-0.2-1.4-0.5-2-0.9c-0.6-0.4-1.1-0.9-1.5-1.5c-0.4-0.7-0.6-1.5-0.6-2.6c0-0.5,0.1-1,0.3-1.6c0.2-0.6,0.6-1.1,1-1.5
	c0.5-0.5,1.1-0.8,1.8-1.2c0.7-0.3,1.6-0.5,2.6-0.5c0.9,0,1.8,0.1,2.7,0.4c0.8,0.3,1.6,0.8,2.2,1.6L44,73c-0.3-0.4-0.7-0.8-1.2-1.1
	c-0.5-0.3-1.1-0.4-1.8-0.4c-0.6,0-1.2,0.1-1.6,0.3c-0.4,0.2-0.8,0.4-1,0.6c-0.3,0.3-0.4,0.5-0.6,0.8c-0.1,0.3-0.1,0.6-0.1,0.8
	c0,0.7,0.2,1.3,0.6,1.7s0.9,0.7,1.5,0.9c0.6,0.2,1.3,0.5,2,0.6c0.7,0.2,1.4,0.5,2,0.8c0.6,0.3,1.1,0.8,1.6,1.4
	c0.4,0.6,0.6,1.4,0.6,2.4c0,0.8-0.2,1.6-0.5,2.2c-0.3,0.7-0.7,1.2-1.2,1.7s-1.2,0.8-1.9,1c-0.7,0.2-1.5,0.4-2.3,0.4
	c-1.1,0-2.1-0.2-3.1-0.6c-1-0.4-1.8-1-2.3-1.8L36.6,83.3z M57.3,87.2c-1.3,0-2.5-0.2-3.6-0.7c-1.1-0.5-2-1.1-2.8-1.9
	c-0.8-0.8-1.4-1.7-1.8-2.8c-0.4-1.1-0.6-2.3-0.6-3.5s0.2-2.4,0.6-3.5c0.4-1.1,1.1-2,1.8-2.8c0.8-0.8,1.7-1.4,2.8-1.9
	c1.1-0.5,2.3-0.7,3.6-0.7c1.3,0,2.5,0.2,3.6,0.7c1.1,0.4,2,1.1,2.8,1.9c0.8,0.8,1.4,1.7,1.8,2.8c0.4,1.1,0.7,2.3,0.7,3.5
	s-0.2,2.4-0.7,3.5c-0.4,1.1-1,2-1.8,2.8c-0.8,0.8-1.7,1.4-2.8,1.9C59.7,87,58.6,87.2,57.3,87.2z M57.3,85.1c1,0,1.9-0.2,2.6-0.5
	c0.8-0.4,1.5-0.9,2-1.5c0.6-0.6,1-1.3,1.3-2.1c0.3-0.8,0.5-1.7,0.5-2.6c0-0.9-0.2-1.8-0.5-2.6c-0.3-0.8-0.7-1.6-1.3-2.2
	c-0.6-0.6-1.2-1.1-2-1.4c-0.8-0.4-1.7-0.6-2.6-0.6c-1,0-1.9,0.2-2.6,0.6c-0.8,0.4-1.5,0.8-2,1.4c-0.6,0.6-1,1.3-1.3,2.2
	c-0.3,0.8-0.5,1.7-0.5,2.6c0,0.9,0.2,1.8,0.5,2.6c0.3,0.8,0.7,1.5,1.3,2.1c0.6,0.6,1.2,1.1,2,1.5C55.4,84.9,56.3,85.1,57.3,85.1z
	 M81.3,73.5c-0.5-0.6-1.1-1.1-1.8-1.4c-0.7-0.3-1.5-0.5-2.3-0.5c-1,0-1.8,0.2-2.6,0.6c-0.8,0.4-1.4,0.8-2,1.5
	c-0.5,0.6-1,1.4-1.3,2.2c-0.3,0.8-0.5,1.7-0.5,2.7c0,0.9,0.1,1.7,0.4,2.5c0.3,0.8,0.7,1.5,1.2,2.1s1.2,1.1,2,1.4
	c0.8,0.4,1.7,0.5,2.7,0.5c1,0,1.8-0.2,2.6-0.6c0.7-0.4,1.4-1,1.9-1.7l1.9,1.5c-0.1,0.2-0.4,0.4-0.7,0.8c-0.3,0.3-0.7,0.6-1.3,1
	c-0.5,0.3-1.2,0.6-1.9,0.8c-0.7,0.3-1.6,0.4-2.6,0.4c-1.3,0-2.6-0.3-3.6-0.8c-1.1-0.5-2-1.2-2.8-2c-0.8-0.8-1.3-1.8-1.7-2.8
	c-0.4-1.1-0.6-2.1-0.6-3.2c0-1.3,0.2-2.5,0.6-3.6c0.4-1.1,1-2.1,1.8-2.9c0.8-0.8,1.7-1.4,2.8-1.9c1.1-0.4,2.3-0.7,3.6-0.7
	c1.1,0,2.2,0.2,3.3,0.6c1.1,0.4,2,1.1,2.7,2L81.3,73.5z"
        />
        <line
          fill="none"
          stroke={mode === "light" ? "#363636" : "#FFFFFF"}
          x1="22.5"
          y1="61.2"
          x2="95.6"
          y2="61.2"
        />
        <circle
          stroke={mode === "light" ? "#363636" : "#FFFFFF"}
          fill="none"
          strokeWidth={2}
          cx="59.2"
          cy="59.4"
          r="49.1"
        />
        <circle
          stroke={mode === "light" ? "#363636" : "#FFFFFF"}
          fill="none"
          strokeWidth={2}
          cx="59.2"
          cy="59.4"
          r="57.6"
        />
      </svg>
    </SvgIcon>
  );
};

SOCIcon.displayName = "SOC";
