import { Box, styled, Typography } from "@mui/material";
import { Status } from "./mondoo-score-card";

export const ScoreMajor = styled(Box)`
  &.A {
    color: ${(props) => props.theme.palette.none.main};
  }
  &.B {
    color: ${(props) => props.theme.palette.low.main};
  }
  &.C {
    color: ${(props) => props.theme.palette.medium.main};
  }
  &.D {
    color: ${(props) => props.theme.palette.high.main};
  }
  &.F {
    color: ${(props) => props.theme.palette.critical.main};
  }
  &.X {
    color: ${(props) => props.theme.palette.errorScan.main};
  }
`;

// status = critical / high / medium / low / good / error
export const ScoreCardBar = styled("div")<{ status: Status }>`
  transform: scaleY(-1);
  background: ${(props) => props.theme.palette[props.status].gradient};
  width: 100%;
  height: 8px;
`;

export const StyledMajorScore = styled(Typography)`
  font-size: 7em;
  margin-right: 10px;
  display: inline-block;
  line-height: 140px;
  vertical-align: top;

  &.A {
    color: ${(props) => props.theme.palette.none.main};
  }
  &.B {
    color: ${(props) => props.theme.palette.low.main};
  }
  &.C {
    color: ${(props) => props.theme.palette.medium.main};
  }
  &.D {
    color: ${(props) => props.theme.palette.high.main};
  }
  &.F {
    color: ${(props) => props.theme.palette.critical.main};
  }
  &.X {
    color: ${(props) => props.theme.palette.errorScan.main};
  }
`;
