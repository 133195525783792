import { TooltipProps, Tooltip } from "@mui/material";

type Props = {
  title: TooltipProps["title"];
  children: JSX.Element;
};

export function NavbarTooltip({ title, children }: Props) {
  return (
    <Tooltip
      title={title}
      disableInteractive
      placement="bottom"
      PopperProps={{
        sx: {
          ".MuiTooltip-tooltip": {
            fontSize: 13,
            fontWeight: (theme) => theme.typography.fontWeightRegular,
          },
        },
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -8],
            },
          },
        ],
      }}
    >
      {children}
    </Tooltip>
  );
}
