import { Box, Container, Grid, Typography } from "@mui/material";
import SearchCat from "~/images/searchCat.svg";

type NoSearchResultsProps = {
  searchQuery: string;
  name: string;
};

export function NoSearchResults({ searchQuery, name }: NoSearchResultsProps) {
  return (
    <Container
      maxWidth="md"
      sx={{
        pt: {
          xs: 4,
          sm: 6,
          md: 14,
        },
      }}
    >
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sm
          order={{ xs: 1, sm: 2 }}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            rowGap: 2,
          }}
        >
          <Typography variant="h5" component="h2" fontWeight={700}>
            No results found
          </Typography>
          <Typography>
            “{searchQuery}” did not match any results in {name}. Please try
            again.
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm="auto"
          order={{ xs: 2, sm: 1 }}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            component="img"
            src={SearchCat}
            alt="Search Cat"
            sx={{ height: { xs: 200, sm: 292 }, width: "auto" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
