import { DialogTitle, IconButton } from "@mui/material";
import { CloseIcon } from "~/components/icons";

export type TitleAreaProps = {
  onClose: () => void;
  title?: string;
};

export const TitleArea = ({ title, onClose }: TitleAreaProps) => {
  return (
    <DialogTitle
      sx={{
        p: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        ...(title && {
          justifyContent: "space-between",
        }),
        textTransform: "uppercase",
        fontSize: {
          xs: 16,
          md: 20,
        },
      }}
    >
      {title}
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          color: "background.default",
          padding: {
            xs: 0,
            md: 1,
          },
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};
