import { ImpactLabels } from "./constants";
import { ImpactData } from "./types";
import { useGetImpactColor } from "./use-get-impact-color";
import { getImpactLabel } from "./utils";
import { useTheme } from "@mui/material";

type UseImpactRatioParams = {
  impact: number | undefined;
  adjustedColor?: string;
  isActive: boolean;
  isRiskScore?: boolean;
};

type UseImpactRatioReturn = {
  impactData: ImpactData;
  color: string;
  label: (typeof ImpactLabels)[keyof typeof ImpactLabels];
};

export function useImpact({
  impact,
  adjustedColor,
  isActive,
  isRiskScore,
}: UseImpactRatioParams): UseImpactRatioReturn {
  const theme = useTheme();

  const color = !isActive
    ? theme.palette.unknown.main
    : useGetImpactColor({ impact, isRiskScore });

  const label = getImpactLabel({ impact, isRiskScore });
  // It can be adjusted by success status, skipped/disabled/errored states
  const checkColor = adjustedColor || color;

  function getImpactData(impact: number | undefined): ImpactData {
    let impactNumber = Number(impact);

    if (isRiskScore) {
      if (impactNumber >= 91) {
        return {
          rating: 1,
        };
      } else if (impactNumber >= 71) {
        return {
          rating: 2,
        };
      } else if (impactNumber >= 41) {
        return {
          rating: 3,
        };
      } else if (impactNumber >= 1) {
        return {
          rating: 4,
        };
      } else {
        return {
          rating: 5,
        };
      }
    } else {
      if (impactNumber === 0 || !impactNumber) {
        return {
          rating: 1,
        };
      }
      if (impactNumber >= 91) {
        return {
          rating: 5,
        };
      } else if (impactNumber >= 71) {
        return {
          rating: 4,
        };
      } else if (impactNumber >= 41) {
        return {
          rating: 3,
        };
      } else if (impactNumber >= 1) {
        return {
          rating: 2,
        };
      } else {
        return {
          rating: 1,
        };
      }
    }
  }

  return {
    impactData: getImpactData(impact),
    color: checkColor,
    label,
  };
}
