import { Box, TableCell, Typography } from "@mui/material";
import { Format, FormatRelativeDate } from "~/lib/date";
import {
  AggregateScoresFirstDetectedAt,
  AggregateScoresLastScannedAt,
} from "../FirewatchPage";
import { capitalizeFirstLetter } from "~/lib/helpers";

// This is essentially the same as the FirstFoundCell component from the SoftwareTable component, adjusted for our prop needs, We can and should combine these components once API is settled.

type FirstFoundCellProps = {
  discoveryTime:
    | AggregateScoresFirstDetectedAt
    | AggregateScoresLastScannedAt
    | undefined
    | null;
};

export const FirstFoundCell = ({ discoveryTime = "" }: FirstFoundCellProps) => {
  return (
    <TableCell>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontSize={14}>
          {capitalizeFirstLetter(FormatRelativeDate(discoveryTime))}
        </Typography>
        <Typography variant="caption" color="text.secondary" fontSize={12}>
          {Format(discoveryTime)}
        </Typography>
      </Box>
    </TableCell>
  );
};
