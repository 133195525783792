import { createSvgIcon } from "@mui/material";

export const EBSVolumesIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_14085)" />
    <svg
      width="23"
      height="28"
      viewBox="0 0 23 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="8.5"
      y="6"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.72206 1.27316H17.7478L20.5589 5.0118H1.91155L4.72206 1.27316ZM0.636482 6.28496H21.834C22.0752 6.28496 22.2955 6.14873 22.4031 5.9323C22.5113 5.71649 22.4877 5.45868 22.3426 5.2658L18.5741 0.253996C18.4537 0.0935773 18.2653 0 18.0654 0H4.4044C4.20452 0 4.01609 0.0935773 3.89577 0.253996L0.127854 5.2658C-0.0172863 5.45868 -0.0408397 5.71649 0.067379 5.9323C0.174325 6.14873 0.395218 6.28496 0.636482 6.28496ZM1.31126 26.7268H21.1974V8.51172H1.31126V26.7268ZM21.834 7.23856H0.674677C0.322648 7.23856 0.0380963 7.52311 0.0380963 7.87514V27.3634C0.0380963 27.7154 0.322648 28 0.674677 28H21.834C22.186 28 22.4706 27.7154 22.4706 27.3634V7.87514C22.4706 7.52311 22.186 7.23856 21.834 7.23856Z"
        fill="white"
      />
    </svg>
    <defs>
      <linearGradient
        id="paint0_linear_1045_14085"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "EBS Volumes",
);
