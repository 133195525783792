import { alpha, Box, Typography, useTheme } from "@mui/material";
import { Score2CvssScore } from "~/lib/score";

type Props = {
  severity: number | string | undefined;
  ignored?: boolean;
  isDataQuery?: boolean;
  small?: boolean;
  useCvss?: boolean;
  disableLabel?: boolean;
};

export function SeverityChip({
  severity,
  ignored = false,
  isDataQuery = false,
  small = false,
  useCvss = false, // useCvss converts a number to the 10.0 scale
  disableLabel = false,
}: Props) {
  // when we're looking at a data query, severity should not be displayed

  if (isDataQuery) {
    return <div />;
  }

  const theme = useTheme();

  //   - critical: 91 - 100, mid: 95 (score:  0- 9 F)
  //   - high:     71 - 90,  mid: 80 (score: 10-29 D)
  //   - medium:   41 - 70,  mid: 55 (score: 30-59 C)
  //   - low:       1 - 40,  mid: 20 (score: 60-99 A-B)
  //   - info:      0                (score: 100   A+)

  const getSeverityData = (severity: Props["severity"]) => {
    // if severity is undefined, Number will convert it to 0
    // and it will evaluation to !severityNumber
    let severityNumber = Number(severity);
    if (!severityNumber) {
      return {
        color: theme.palette.critical.main,
        label: "critical",
        number: 100,
      };
    } else if (severityNumber >= 91) {
      return {
        color: theme.palette.critical.main,
        label: "critical",
        number: severityNumber,
      };
    } else if (severityNumber >= 71) {
      return {
        color: theme.palette.high.main,
        label: "high",
        number: severityNumber,
      };
    } else if (severityNumber >= 41) {
      return {
        color: theme.palette.medium.main,
        label: "medium",
        number: severityNumber,
      };
    } else if (severityNumber >= 1) {
      return {
        color: theme.palette.low.main,
        label: "low",
        number: severityNumber,
      };
    } else {
      return {
        color: theme.palette.unrated.main,
        label: "info",
        number: severityNumber,
      };
    }
  };

  // Small severity chip was only designed to hold 2 numbers, so we convert
  // a 10.0 => 10
  const convertToCvssScore = (num: number) => {
    const converted = Score2CvssScore(num);
    return converted === "10.0" ? "10" : converted;
  };

  const data = getSeverityData(severity);

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        height: small ? "22px" : "32px",
        background: (theme) => theme.palette.background.lighter,
        borderRadius: 16,
        opacity: ignored ? 0.5 : 1,
      }}
    >
      <Box
        sx={{
          height: small ? "22px" : "32px",
          width: small ? "22px" : "32px",
          borderRadius: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: alpha(data.color, 0.5),
        }}
      >
        <Typography fontSize={small ? 10 : 12} fontWeight={small ? 700 : 400}>
          {useCvss ? convertToCvssScore(data.number as number) : data.number}
        </Typography>
      </Box>
      {!disableLabel && (
        <Box px={1}>
          <Typography
            fontSize={small ? 10 : 12}
            fontWeight={small ? 700 : 400}
            sx={{ textTransform: "uppercase" }}
          >
            {data.label}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
