import { Box, Typography } from "@mui/material";

type Exploit = {
  title?: string | null;
  description?: string | null;
  published?: string | null;
};

export type CveExploitsProps = {
  exploits: Exploit[];
  id?: string;
};

export function CveExploits({ id, exploits }: CveExploitsProps) {
  return (
    <Box
      id={id}
      className="exploits-list"
      display="flex"
      flexDirection="column"
      gap={3}
    >
      {exploits.map((exploit, i) => (
        <Box key={i} className="exploits-list-item">
          <Typography fontWeight={700} mb={1}>
            {exploit.title || "Unspecified Exploit"}
          </Typography>
          <Typography fontWeight={400} color="text.secondary">
            {exploit.description || "No description provided"}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
