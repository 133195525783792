import { Grid, Typography } from "@mui/material";
import { useFrameworkCard } from "../hooks/UseFrameworkCard";
import { ConfirmationDialog } from "~/components/ConfirmationDialog";
import { Fragment } from "react";
import { Upload } from "~/components/upload";
import { Space } from "~/lib/types";
import { Format } from "~/lib/date";
import { CircularIconButton } from "../components/CircularIconButton";
import { IconButtonType } from "../components/DynamicButtonIcon";

type FrameworkCardEditToolsProps = {
  space: Space;
  data: ReturnType<typeof useFrameworkCard>["data"];
  handle: ReturnType<typeof useFrameworkCard>["handle"];
  loading: ReturnType<typeof useFrameworkCard>["loading"];
  isOfficial: boolean;
};

export function ActiveFrameworkCardEditTools({
  space,
  data,
  handle,
  loading,
  isOfficial,
}: FrameworkCardEditToolsProps) {
  const replaceFrameworkAdapter = async ({
    spaceMrn,
    dataurl,
  }: {
    spaceMrn: string;
    dataurl: string;
  }) => {
    await handle.replaceFramework({ spaceMrn, dataurl });
  };

  return (
    <Fragment>
      <Grid
        item
        container
        xs={12}
        sx={{
          height: 80,
          px: 3,
          py: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid item xs>
          {data.uploadedAt && (
            <Typography variant="caption" color="text.secondary">
              Uploaded {Format(data.uploadedAt)}
            </Typography>
          )}
        </Grid>
        <Grid
          container
          item
          xs="auto"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item>
            <CircularIconButton
              title="Download Framework"
              iconType={IconButtonType.Download}
              onClick={handle.downloadFramework}
            />
          </Grid>
          {/* only show this if the framework is custom */}
          {!isOfficial && (
            <Grid item>
              <Upload
                title="Replace Framework"
                placeholder={`Drag and drop a framework or click to replace ${data.name}`}
                errorMessage={`Failed to replace ${data.name}`}
                successMessage={`Successfully replaced ${data.name}`}
                spaceMrn={space.mrn}
                addFileMutation={replaceFrameworkAdapter}
                buttonIcon={IconButtonType.Refresh}
                buttonTitle="Replace Framework"
                force={false}
              />
            </Grid>
          )}
          <Grid item>
            <CircularIconButton
              title={`${isOfficial ? "Remove" : "Delete"} Framework`}
              iconType={IconButtonType.Delete}
              onClick={
                isOfficial
                  ? handle.removeFrameworkBegin
                  : handle.deleteFrameworkBegin
              }
              sx={{ color: "error.main" }}
            />
          </Grid>
          <Grid item>{data.displayInteractions}</Grid>
        </Grid>
      </Grid>
      <ConfirmationDialog
        title={`${isOfficial ? "Remove" : "Delete"} framework ${data.name}?`}
        isOpen={handle.deleteDialogOpen}
        confirmButtonText={isOfficial ? "Remove" : "Delete"}
        content={
          <Typography>
            {isOfficial
              ? "Are you sure you want to remove this framework?"
              : "Deleting a custom compliance framework will erase all data – this action cannot be undone. If you are facing problems, try replacing the file first.z"}
          </Typography>
        }
        onConfirm={
          isOfficial ? handle.removeFramework : handle.deleteFrameworkConfirm
        }
        onClose={handle.cancelDelete}
        loading={loading.deleteProcess}
      />
    </Fragment>
  );
}
