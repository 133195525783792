import { createSvgIcon } from "@mui/material";

export const SaasIcon = createSvgIcon(
  <svg viewBox="0 0 34 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M29.8346 18.9665C29.8013 18.9665 29.7846 18.9665 29.7513 18.9832C29.3346 16.1665 26.9346 13.9998 24.0013 13.9998C21.668 13.9998 19.668 15.3832 18.7346 17.3665C16.1013 17.4998 14.0013 19.6665 14.0013 22.3332C14.0013 25.0998 16.2346 27.3332 19.0013 27.3332L29.8346 27.2998C32.1346 27.2998 34.0013 25.4332 34.0013 23.1332C34.0013 20.8332 32.1346 18.9665 29.8346 18.9665ZM10.668 1.09984V4.58317C6.78464 5.9665 4.0013 9.64984 4.0013 13.9998C4.0013 16.9498 5.3013 19.5665 7.33464 21.3998V17.3332H10.668V27.3332H0.667969V23.9998H5.21797C2.43464 21.5665 0.667969 17.9998 0.667969 13.9998C0.667969 7.78317 4.91797 2.58317 10.668 1.09984ZM27.3346 3.99984H22.7846C25.168 6.09984 26.8013 9.0165 27.218 12.3332H23.8513C23.468 10.0665 22.3013 8.08317 20.668 6.59984V10.6665H17.3346V0.666504H27.3346V3.99984Z"
      fill="url(#paint0_linear_7091_2889)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7091_2889"
        x1="0.667969"
        y1="0.666504"
        x2="27.6251"
        y2="32.6991"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E45E2" />
        <stop offset="1" stopColor="#A22E95" />
      </linearGradient>
    </defs>
  </svg>,
  "Saas",
);
