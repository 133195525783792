import { ReactElement } from "react";
import { Box, Typography, SxProps, Theme } from "@mui/material";
import { InfoIcon } from "~/components/icons";

export const InfoText = ({
  text,
  sx,
}: {
  text: string | ReactElement;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "top",
        justifyContent: "center",
        px: {
          xs: 0,
          md: 5,
        },
        pt: {
          xs: 3,
          md: 0,
        },
        pb: 3,
        ...sx,
      }}
    >
      <InfoIcon fontSize="small" sx={{ color: "text.secondary" }} />
      <Typography
        variant="caption"
        lineHeight={"18px"}
        sx={{ ml: 1, color: "text.secondary" }}
      >
        {text}
      </Typography>
    </Box>
  );
};
