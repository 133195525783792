import { FormHelperText, Grid, Typography } from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { OnOffSwitch } from "./OnOffSwitch";

type ToggleOptionProps = {
  title: string;
  description: string;
  toggleSize?: "small" | "medium";
  helperText?: {
    message: string;
    icon?: React.ReactNode;
    color?: string;
  };
  formOptions: {
    name: string;
    control: Control<any>;
  };
  disabled?: boolean;
};

export function ToggleOption({
  title,
  description,
  toggleSize = "small",
  formOptions: { control, name },
  helperText,
  disabled,
}: ToggleOptionProps) {
  return (
    <Grid
      container
      sx={{
        "& .form-text": {
          color: disabled ? "text.disabled" : undefined,
        },
      }}
    >
      <Grid item xs>
        <Typography fontWeight={700} className="form-text">
          {title}
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          className="form-text"
        >
          {description}
        </Typography>
      </Grid>
      <Grid item xs="auto" sx={{ pr: 2 }}>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <OnOffSwitch {...{ field, toggleSize, disabled }} />
          )}
        />
      </Grid>
      {helperText && (
        <Grid item xs={12}>
          <FormHelperText
            margin="dense"
            sx={{
              display: "flex",
              alignItems: "center",
              color: helperText?.color,
            }}
            className={disabled ? "form-text" : ""}
          >
            {helperText?.icon}
            {helperText.message}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  );
}
