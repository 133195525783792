import { ReactNode, useEffect, useState } from "react";
import {
  auth,
  verifyPasswordResetCode,
  confirmPasswordReset,
  AuthError,
} from "~/login/firebase-provider";
import { useAuth } from "~/login/provider";
import { CliComponent } from "./cliComponent";
import { waitForTyping, WaitProps, HandleSubmit } from "./animatedTyping";

type Props = {
  actionCode: string;
};

export function ResetPasswordComponent({ actionCode }: Props) {
  const { handleErrorMessage } = useAuth();
  const [typings, setTypings] = useState<ReactNode[]>([]);
  let inputCount = 0;

  useEffect(() => {
    beginResetPassword();

    return () => {
      setTypings([]);
    };
  }, []);

  const handleSubmit: HandleSubmit = async ({ id, event }) => {
    event.preventDefault();

    // https://github.com/microsoft/TypeScript/issues/39003
    const passwordInput = event.currentTarget.elements.namedItem(id);
    if (!(passwordInput instanceof HTMLInputElement)) return;

    const newPassword = passwordInput.value;
    // disable input after submission
    passwordInput.disabled = true;

    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
      await waitForTyping(setTypings, { id: "6", type: "progress", text: "" });
      await waitForTyping(setTypings, {
        id: "7",
        type: "output",
        text: "✓ Your password has been reset. Thank you for using Mondoo.",
      });
      await waitForTyping(setTypings, {
        id: "8",
        type: "output",
        text: "→ To continue, ",
        link: {
          text: "please sign in with your new password.",
          to: "/login",
        },
        color: "hotpink",
      });
    } catch (error) {
      const errorMessage = handleErrorMessage((error as AuthError).code);
      // Something went wrong, please try again
      await waitForTyping(setTypings, {
        id: `9 ${+inputCount}`,
        type: "output",
        text: `𝗑 ${errorMessage}`,
        color: "hotpink",
      });

      await waitForTyping(
        setTypings,
        //adjust so index increments
        inputRequestFactory({ handleSubmit }),
      );
    }
  };

  const beginResetPassword = async () => {
    await waitForTyping(setTypings, {
      id: "1",
      type: "command",
      prepend: "$",
      text: "mondoo reset-password --incognito",
      delay: 1000,
    });
    await waitForTyping(setTypings, {
      id: "2",
      type: "output",
      text: "→ verifying access code",
    });

    try {
      const accountEmail = await verifyPasswordResetCode(auth, actionCode);
      await waitForTyping(setTypings, {
        id: "3",
        type: "output",
        text: `→ Welcome back, ${accountEmail}`,
      });
      await waitForTyping(setTypings, {
        id: "4",
        type: "output",
        text: "Please enter a new password of your choice: ",
      });
      await waitForTyping(setTypings, inputRequestFactory({ handleSubmit }));
    } catch (error) {
      const errorMessage = handleErrorMessage((error as AuthError).code);
      // code not valid or is expired
      await waitForTyping(setTypings, {
        id: "5",
        type: "output",
        text: `𝗑 ${errorMessage}`,
        link: {
          text: "Please request a new reset password link.",
          to: "/reset-password",
        },
        color: "hotpink",
      });
    }
  };

  // This function allows us to create a new password input element
  // with a unique ID in the case that a user attempts
  // to use an invalid password.
  const inputRequestFactory = ({
    handleSubmit,
  }: {
    handleSubmit: HandleSubmit;
  }): WaitProps => {
    const id = `newPassword${inputCount}`;
    // increment the inputCount incase there is a user error and
    // we need to loop again.
    inputCount += 1;
    return {
      id,
      type: "input",
      text: "",
      prepend: "˃ ",
      formDetails: {
        onSubmit: (event) => handleSubmit({ id, event }),
        id: id,
      },
    };
  };

  return <CliComponent>{typings}</CliComponent>;
}
