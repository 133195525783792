import { Component, JSXElementConstructor, ReactNode } from "react";
import { Box, Link, Typography } from "@mui/material";
import { LoadingFailedPage } from "~/components/loading";

type Props = {
  children: ReactNode;
  key: string | number;
  FallbackComponent?: JSXElementConstructor<{ error: Error }>;
};

type State = {
  error: Error | null;
};

export class ErrorBoundary extends Component<Props, State> {
  state: State = { error: null };

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  render() {
    const { error } = this.state;

    if (error) {
      if (this.props.FallbackComponent) {
        return <this.props.FallbackComponent error={error} />;
      }
      return <DefaultErrorFallback error={error} />;
    }

    return <>{this.props.children}</>;
  }
}

const DefaultErrorFallback = ({ error }: { error: Error }) => {
  const message = (
    <Box component="span">
      <Typography sx={{ pb: 3 }}>{error.message}</Typography>
      <Typography>
        Please try reloading the page. If the error persists, take a look at{" "}
        <Link href="https://status.mondoo.io/">our status page</Link> or reach
        out to us directly.
      </Typography>
    </Box>
  );

  return <LoadingFailedPage message={message} />;
};
