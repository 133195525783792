import {
  Box,
  IconButton,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { Fragment } from "react/jsx-runtime";
import { Code } from "~/components/code";
import { DetailRow } from "~/components/data-table";
import { ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { Loading, LoadingFailed } from "~/components/loading";
import { TitleDivider } from "~/components/title-divider";
import { getError } from "~/lib/handle-error";
import { useGetRenderedAssetDataQueryLazyQuery } from "~/operations";

type DataQueryRowProps = {
  asset: any;
  index: number;
  queryMrn: string;
  to: string;
};

export function DataQueryRow({
  asset,
  index,
  queryMrn,
  to,
}: DataQueryRowProps) {
  const [open, setOpen] = useState(false);

  const [getRenderedAssetDataQuery, { data, error, loading }] =
    useGetRenderedAssetDataQueryLazyQuery({
      variables: {
        input: {
          assetMrn: asset?.assetMrn,
          queryMrn: queryMrn,
        },
      },
    });

  const getData = async () => {
    try {
      await getRenderedAssetDataQuery();
    } catch (error) {
      const err = getError(error);
      enqueueSnackbar(`Failed to fetch data query: ${err}`, {
        variant: "error",
      });
    }
  };

  const handleOpenClick = () => {
    setOpen(!open);
    if (!open) {
      setOpen(true);
      getData();
    } else {
      setOpen(false);
    }
  };

  const notFoundErrorMessage =
    data?.renderedAssetQueryData?.__typename === "NotFoundError"
      ? data.renderedAssetQueryData.message
      : null;
  const renderedData =
    data?.renderedAssetQueryData?.__typename === "RenderedAssetQueryData"
      ? data.renderedAssetQueryData.data
      : null;

  return (
    <Fragment key={`${asset?.assetMrn}-${index}`}>
      <TableRow onClick={handleOpenClick}>
        <TableCell>{asset?.assetName}</TableCell>
        <TableCell>{asset?.assetPlatform?.name}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <DetailRow
        id={`${asset?.assetMrn}-${index}-expand`}
        colSpan={3}
        open={open}
      >
        <ListItem key="result" disableGutters>
          <ListItemText
            primary={
              <>
                <TitleDivider
                  title="Result"
                  TitleProps={{ sx: { fontSize: 16, pr: 3 } }}
                  ContainerProps={{ sx: { mt: 1 } }}
                  href={to}
                  hrefTitle="View on Asset"
                />
                {loading && (
                  <Box sx={{ pt: 2, pb: 5 }}>
                    <Loading />
                  </Box>
                )}
                {error && (
                  <Box sx={{ pt: 2, pb: 5 }}>
                    <LoadingFailed what="result data" />
                  </Box>
                )}
                {notFoundErrorMessage && (
                  <Code copyButton className="ini">
                    {"[Query Error]\n" + notFoundErrorMessage}
                  </Code>
                )}
                {renderedData && (
                  <Code copyButton className="plaintext">
                    {JSON.stringify(renderedData, null, 2)}
                  </Code>
                )}
              </>
            }
          />
        </ListItem>
      </DetailRow>
    </Fragment>
  );
}
