import { useState } from "react";

export function useConfirmationDialog() {
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const handleConfirmationDialogOpen = () => {
    setIsConfirmationDialogOpen(true);
  };

  const handleConfirmationDialogClose = () => {
    setIsConfirmationDialogOpen(false);
  };

  return {
    isConfirmationDialogOpen,
    handleConfirmationDialogOpen,
    handleConfirmationDialogClose,
  };
}
