import { createSvgIcon } from "@mui/material";

export const UbuntuInstancesIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_13910)" />
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="6"
      y="6"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M28 14.0001C28 21.7318 21.7319 27.9999 13.9999 27.9999C6.26784 27.9999 0 21.7319 0 14.0001C0 6.26793 6.26774 0 13.9998 0C21.7318 0 28 6.26793 28 14.0001ZM4.48016 12.1311C3.44764 12.1311 2.61099 12.9677 2.61099 14.0002C2.61099 15.0324 3.44764 15.8691 4.48016 15.8691C5.51229 15.8691 6.34903 15.0324 6.34903 14.0002C6.34903 12.9676 5.51238 12.1311 4.48016 12.1311ZM17.8257 20.6262C16.9317 21.1423 16.6257 22.2849 17.1416 23.1786C17.6578 24.0726 18.8003 24.3788 19.6944 23.8627C20.5881 23.3467 20.8943 22.204 20.3782 21.3101C19.8622 20.4164 18.7194 20.1101 17.8257 20.6262ZM8.54012 14C8.54012 12.1527 9.45806 10.5211 10.8618 9.53313L9.49529 7.24415C7.85954 8.33703 6.6428 10.0078 6.13735 11.9645C6.72774 12.4458 7.10498 13.1786 7.10498 14.0001C7.10498 14.8215 6.72774 15.5543 6.13725 16.0355C6.6426 17.9924 7.85944 19.6632 9.4952 20.7559L10.8618 18.4668C9.45806 17.479 8.54012 15.8475 8.54012 14ZM14 8.54002C16.8524 8.54002 19.1922 10.7271 19.4375 13.5157L22.1013 13.4768C21.9702 11.4179 21.0708 9.56898 19.6887 8.2101C18.978 8.47858 18.1571 8.43788 17.4476 8.02815C16.7372 7.61803 16.2914 6.92619 16.1693 6.17538C15.4787 5.98424 14.7517 5.88003 14.0001 5.88003C12.7075 5.88003 11.4864 6.18348 10.4016 6.72083L11.7001 9.04803C12.3992 8.72276 13.1781 8.54002 14 8.54002ZM14 19.46C13.178 19.46 12.3992 19.2772 11.7 18.952L10.4013 21.2794C11.4862 21.8165 12.7074 22.1201 14 22.1201C14.7516 22.1201 15.4786 22.0159 16.1693 21.8247C16.2914 21.0739 16.7373 20.3821 17.4478 19.9716C18.1573 19.5621 18.9782 19.5214 19.6889 19.79C21.0709 18.431 21.9703 16.5821 22.1012 14.5232L19.4374 14.4843C19.1923 17.2733 16.8524 19.46 14 19.46ZM17.8254 7.37365C18.7193 7.88996 19.8621 7.58365 20.378 6.69001C20.8942 5.79597 20.5882 4.65332 19.6942 4.1371C18.8003 3.62109 17.6577 3.9273 17.1415 4.82134C16.6255 5.71498 16.9317 6.85774 17.8254 7.37365Z"
        fill="white"
      />
    </svg>
    <defs>
      <linearGradient
        id="paint0_linear_1045_13910"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Ubuntu Instances",
);
