import { useSearchParams } from "react-router-dom";
import { Space } from "~/lib/types";
import {
  MvdEntryType,
  MvdOrder,
  MvdOrderField,
  OrderDirection,
  Severity,
} from "~/operations";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { DatabaseFilter } from "./MondooDatabaseFilterComponent";

type UseMondooVulnerabilityDatabaseProps = {};

export function useMondooVulnerabilityDatabase({}: UseMondooVulnerabilityDatabaseProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("query") || "";
  const severityParams = searchParams.getAll("severity");
  const severity = isSeverityArray(severityParams) ? severityParams : [];
  const entryTypeParam = searchParams.get("entryType");
  const entryType = isValidEntryType(entryTypeParam)
    ? entryTypeParam
    : MvdEntryType.Cve;

  const { orderBy, handleSortClick } = useSort<MvdOrder>({
    defaultSort: {
      field: MvdOrderField.Modified,
      direction: OrderDirection.Asc,
    },
    validFields: [
      MvdOrderField.Published,
      MvdOrderField.Id,
      MvdOrderField.Score,
      MvdOrderField.Title,
      MvdOrderField.Modified,
    ],
  });

  const handleQuery = (query: string) => {
    if (query) {
      searchParams.set("query", query);
    } else {
      searchParams.delete("query");
    }
    setSearchParams(searchParams, { replace: true });
  };

  const handleFilterChange = (filters: DatabaseFilter) => {
    searchParams.set("entryType", filters.entryType);
    searchParams.delete("severity");
    filters.severity.forEach((s) => {
      searchParams.append("severity", s);
    });
    setSearchParams(searchParams, { replace: true });
  };

  function isValidEntryType(value: any): value is MvdEntryType {
    return Object.values(MvdEntryType).includes(value);
  }

  function isSeverity(value: any): value is Severity {
    return Object.values(Severity).includes(value);
  }

  function isSeverityArray(values: any[]): values is Severity[] {
    return values.every((v) => isSeverity(v));
  }

  const databaseUrl = (space: Space | undefined, subpath: string): string => {
    const params = new URLSearchParams();
    let prefix = "/space";
    if (space != null) {
      prefix = "/space";
      params.set("spaceId", space.id);
    }

    let url = `${prefix}/mvd/${subpath}`;

    let query = params.toString();
    if (query != "") {
      url = url + "?" + query;
    }

    return url;
  };

  return {
    orderBy,
    query,
    severity,
    entryType,
    databaseUrl,
    isValidEntryType,
    handle: {
      sortClick: handleSortClick,
      query: handleQuery,
      filterChange: handleFilterChange,
    },
  };
}
