import { NotificationsOffOutlined } from "@mui/icons-material";
import {
  Menu,
  Box,
  Typography,
  MenuItem,
  alpha,
  ListItemIcon,
} from "@mui/material";
import { ReactNode } from "react";
import { BarChartIcon } from "~/components/ui-library";
import { PolicyAction } from "~/operations";

export type AssignmentOption = {
  action: PolicyAction;
  name: string;
  description: string;
  isDefault: boolean;
  icon: ReactNode;
};

type PolicyAssignMenuProps = {
  anchorEl: HTMLElement | null;
  open: boolean;
  handleMenuClose:
    | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
    | undefined;
  selectedOption: AssignmentOption | undefined;
  handleMenuItemClick: (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: AssignmentOption,
  ) => void;
  advanced?: boolean;
};

export const options: AssignmentOption[] = [
  {
    action: PolicyAction.Active,
    name: "Scored",
    description: "Running and contributing to your overall space scores",
    isDefault: true,
    icon: <BarChartIcon fontSize="inherit" />,
  },
  {
    action: PolicyAction.Ignore,
    name: "Preview",
    description: "Running but not contributing to your overall space scores",
    isDefault: false,
    icon: <NotificationsOffOutlined fontSize="inherit" />,
  },
];

export function PolicyAssignMenu({
  anchorEl,
  open,
  handleMenuClose,
  selectedOption,
  handleMenuItemClick,
  advanced,
}: PolicyAssignMenuProps) {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleMenuClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      slotProps={{
        paper: {
          sx: {
            bgcolor: "background.light",
          },
        },
      }}
    >
      {advanced && (
        <Box
          sx={{
            m: 1,
            mt: 0,
            p: 1,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography
            fontSize={8}
            fontWeight={700}
            textTransform="uppercase"
            color="text.secondary"
          >
            Advanced Options
          </Typography>
          <Typography fontSize={12} fontWeight={700} textTransform="uppercase">
            Add to space and set to...
          </Typography>
        </Box>
      )}
      {options.map((option) => (
        <MenuItem
          key={option.name}
          onClick={(event) => handleMenuItemClick(event, option)}
          selected={option.name === selectedOption?.name}
          sx={{
            whiteSpace: "normal",
            width: 258,
            alignItems: "flex-start",
            "&.Mui-selected, &.Mui-selected:hover, &.Mui-selected.Mui-focusVisible":
              {
                bgcolor: (theme) => alpha(theme.palette.excellent.main, 0.1),
                ".MuiListItemIcon-root": {
                  color: "excellent.main",
                },
              },
            ".MuiListItemIcon-root": {
              minWidth: "unset",
              color: "text.secondary",
            },
          }}
        >
          <ListItemIcon sx={{ mr: 1, mt: 0.5 }}>{option.icon}</ListItemIcon>
          <Box>
            <Typography
              fontSize={14}
              fontWeight={500}
              textTransform="uppercase"
            >
              {option.name}{" "}
              {option.isDefault && (
                <Typography
                  component="span"
                  color="text.secondary"
                  display="inline"
                  fontSize="inherit"
                  fontWeight="inherit"
                >
                  (Default)
                </Typography>
              )}
            </Typography>
            <Typography fontSize={12} fontWeight={400} color="text.secondary">
              {option.description}
            </Typography>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  );
}
