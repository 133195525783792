import { createSvgIcon } from "@mui/material";

export const AzureAuthIcon = createSvgIcon(
  <svg viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.1501 41.0397L33.4001 5.03967C33.1956 4.41873 32.7979 3.88314 32.2674 3.51477C31.7369 3.1464 31.103 2.96554 30.4626 2.99969H16.5387C15.9229 2.99806 15.3221 3.1941 14.8213 3.56016C14.3205 3.92625 13.9451 4.44381 13.7481 5.03967L1.85123 41.0397C1.69695 41.5038 1.65634 41.9994 1.73308 42.4833C1.80981 42.9675 2.00154 43.4247 2.29139 43.8156C2.58124 44.2062 2.96047 44.5185 3.39581 44.7249C3.83118 44.9313 4.3094 45.0258 4.78874 44.9997H13.3956C14.0064 44.9952 14.6005 44.796 15.0954 44.4303C15.5903 44.0649 15.9614 43.5507 16.1568 42.9597L17.9487 37.6197L26.7612 44.4297C27.2733 44.8086 27.8915 45.0084 28.5238 44.9997H42.2713C42.7457 45.0156 43.2169 44.9136 43.6443 44.703C44.072 44.4921 44.443 44.1789 44.7262 43.7895C45.0091 43.4004 45.1953 42.9468 45.2691 42.468C45.3431 41.9889 45.3022 41.499 45.1501 41.0397ZM28.6412 43.0197C28.4277 43.0197 28.2205 42.9459 28.0537 42.8097L11.4569 30.2397L11.1925 30.0297H20.005L20.24 29.3996L23.1775 21.8096L29.7576 41.6997C29.8105 41.8665 29.8202 42.0444 29.7861 42.216C29.752 42.3879 29.6753 42.5478 29.5631 42.6807C29.4509 42.8133 29.3072 42.9141 29.1457 42.9735C28.9843 43.0329 28.8105 43.0488 28.6412 43.0197ZM42.3592 43.0197H31.432C31.6514 42.3786 31.6514 41.6805 31.432 41.0397L19.535 5.03967H30.4626C30.67 5.04072 30.8718 5.10774 31.0404 5.23137C31.2087 5.35503 31.3353 5.52924 31.4026 5.7297L43.2995 41.7297C43.3394 41.8791 43.3459 42.036 43.3177 42.1884C43.2898 42.3408 43.2281 42.4845 43.1376 42.609C43.0468 42.7332 42.9299 42.8349 42.7951 42.906C42.6603 42.9771 42.511 43.0161 42.3592 43.0197Z"
      fill="#AAAAAA"
    />
  </svg>,
  "Azure",
);
