import { Box, Typography } from "@mui/material";
import { PreviewIcon } from "../icons/mondoo/preview";

export function PreviewFlag() {
  return (
    <Box
      className="preview-flag"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 0.5,
        py: 0.5,
        px: 0.5,
        border: "1px solid",
        borderColor: "text.disabled",
        borderRadius: 1,
        color: "text.disabled",
      }}
    >
      <PreviewIcon sx={{ fontSize: 16 }} />
      <Typography
        fontSize={12}
        fontWeight={600}
        lineHeight="14px"
        sx={{ textTransform: "uppercase" }}
      >
        Preview
      </Typography>
    </Box>
  );
}
