import { useOrgSettingsOutletContext } from "./settings";
import { SettingsAuthentication } from "~/pages/settings-authentication";

export function Authentication() {
  const { org } = useOrgSettingsOutletContext();
  return (
    // Repackaging Authentication so that we can use the context hook
    <SettingsAuthentication org={org} />
  );
}
