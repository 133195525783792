import { ChangeEvent, useCallback, useEffect } from "react";
import {
  Select,
  MenuItem,
  PaginationProps as MuiPaginationProps,
  BoxProps,
  Grid,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  getPageIndexes,
  PAGE,
  PaginationOptions,
  rowsPerPageOptions,
} from "~/components/ui-library";
import { ChevronLeftIcon, ChevronRightIcon } from "~/components/icons";

export type PaginationProps = BoxProps & {
  page?: number;
  count?: number;
  rowsPerPage?: number;
  paginationText?: string;
  onPaginationChange: (val: PaginationOptions) => void;
};

export const Pagination = ({
  page = PAGE,
  count = 0,
  rowsPerPage = rowsPerPageOptions[0],
  onPaginationChange,
  sx,
  ...restProps
}: PaginationProps) => {
  const scrollToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const onPageChange = useCallback<NonNullable<MuiPaginationProps["onChange"]>>(
    (e, _page) => {
      onPaginationChange({
        page: _page,
        count,
        rowsPerPage,
        ...getPageIndexes({
          page: _page,
          rowsPerPage,
        }),
      });

      // scrollToTop();
    },
    [page, count, rowsPerPage],
  );

  const onRowsPerPageChange = useCallback(
    (e: SelectChangeEvent) => {
      const _rowsPerPage = Number(e.target.value);

      onPaginationChange({
        page: PAGE,
        count,
        rowsPerPage: _rowsPerPage,
        ...getPageIndexes({
          page: PAGE,
          rowsPerPage: _rowsPerPage,
        }),
      });

      scrollToTop();
    },
    [page, count, rowsPerPage],
  );

  useEffect(() => {
    onPageChange({} as ChangeEvent, PAGE);
  }, [count]);

  const handleNextPage = useCallback(() => {
    onPageChange({} as ChangeEvent, page + 1);
  }, [page, count]);

  const handlePrevPage = useCallback(() => {
    onPageChange({} as ChangeEvent, page - 1);
  }, [page, count]);

  return (
    <Grid
      container
      sx={[
        {
          py: 2,
          px: 4,
          // display: shouldDisplay,
          alignItems: "center",
          justifyContent: "end",
          backgroundColor: "background.light",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Grid
        item
        xs={12}
        sm="auto"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "end",
          pb: { xs: 1, sm: 0 },
        }}
      >
        <InputLabel id="page-size-select-label">Rows Per Page</InputLabel>
        <FormControl sx={{ m: 1 }} size="small">
          <Select
            labelId="page-size-select-label"
            id="page-size-select"
            variant="outlined"
            value={String(rowsPerPage)}
            onChange={onRowsPerPageChange}
            autoWidth
            sx={{
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },
            }}
          >
            {rowsPerPageOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        sm="auto"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Button
          onClick={handlePrevPage}
          startIcon={<ChevronLeftIcon fontSize="small" />}
          disabled={page === 1}
        >
          Prev
        </Button>
        <Button
          onClick={handleNextPage}
          endIcon={<ChevronRightIcon fontSize="small" />}
          disabled={page >= Math.ceil(count / rowsPerPage)}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};
