import { createSvgIcon } from "@mui/material";

export const MS365Icon = createSvgIcon(
  <svg viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.5904 0.666504H0V22.7835H21.5904V0.666504Z" fill="#F8672C" />
    <path
      d="M45.5572 0.666504H23.9668V22.7835H45.5572V0.666504Z"
      fill="#90C300"
    />
    <path d="M21.5904 25.2165H0V47.3335H21.5904V25.2165Z" fill="#00B4F2" />
    <path
      d="M45.5572 25.2165H23.9668V47.3335H45.5572V25.2165Z"
      fill="#FFC400"
    />
  </svg>,
  "MS365",
);
