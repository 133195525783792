import { GetVulnerabilityScoresQuery } from "~/operations";
import { ChangeEvent, MouseEventHandler, ReactNode } from "react";

export type VulnerabilityScores = Extract<
  NonNullable<GetVulnerabilityScoresQuery["vulnerabilityScores"]>,
  { __typename: "VulnerabilityScoresConnection" }
>;

export type VulnerabilityScoresEdge = NonNullable<
  VulnerabilityScores["edges"]
>[0];
export type VulnerabilityScoresNode = NonNullable<
  VulnerabilityScoresEdge["node"]
>;

export type SelectionProps = {
  selectedEntities: Array<unknown>;
  hasSelectPermissions: boolean;
  onCheckboxClick: MouseEventHandler;
  onCheckboxChange: (
    e: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    data: { mrn: string } | null | undefined,
  ) => {};
  onCheckAll: (
    checked: boolean,
    edges: Array<{ mrn: string }> | null | undefined,
  ) => void;
  getIsRowSelected: (identifier: string) => boolean;
  renderToolbar?: (totalCount: number) => ReactNode;
};

export type VulnerabilityScoreType = "CVE" | "Advisory" | "Policy";

export const AssetContextualLinkType = {
  Check: "Check",
  Cve: "Cve",
  Advisory: "Advisory",
  Software: "Software",
  Asset: "Asset",
};
