import { Box, Paper, Typography, Button } from "@mui/material";
import { motion } from "framer-motion";
import { Link as RouterLink } from "react-router-dom";
import { motionOptions } from "./operator-configuration";
import { Markdown } from "~/components/markdown";
import { Space } from "~/lib/types";
import { IntegrationFormInputs } from "./operator-configuration-form";

type InstallSectionProps = {
  space: Space;
  integrationId: string;
  instructions: {
    title: string;
    description: string;
  };
  installUrl: string;
  token: string;
  reset: () => void;
  updateFlow?: {
    values: IntegrationFormInputs;
    integrationMrn: string;
    integrationId: string;
    token: string;
  };
};

export const OperatorInstallationSection = ({
  space,
  integrationId,
  instructions,
  installUrl,
  token,
  reset,
  updateFlow,
}: InstallSectionProps) => {
  const installScript = instructions.description
    .replace(/\%URL\%/g, installUrl)
    .replace(/\%TOKEN\%/g, token);

  return (
    <Box component={motion.div} {...motionOptions} key="install-section">
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" fontWeight={700}>
          {instructions.title}
        </Typography>
        <Markdown source={installScript} />
      </Paper>
      {updateFlow ? (
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
          {" "}
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/space/integrations/kubernetes/${integrationId}?spaceId=${space.id}`}
          >
            Done
          </Button>
        </Box>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button variant="outlined" color="secondary" onClick={reset}>
            Create another Integration
          </Button>
          <Button
            variant="contained"
            color="primary"
            component={RouterLink}
            to={`/space/integrations/add/mondoo/kubernetes/${integrationId}?spaceId=${space.id}`}
          >
            Set Policies
          </Button>
        </Box>
      )}
    </Box>
  );
};
