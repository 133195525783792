import { createSvgIcon } from "@mui/material";

export const DatabaseAccessIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M19.91 4.75964C19.4272 4.43985 18.9065 4.1812 18.36 3.98964C16.3143 3.28875 14.162 2.95034 12 2.98965C9.83785 2.94869 7.68526 3.28715 5.64 3.98964C5.09256 4.17904 4.57165 4.43782 4.09 4.75964C3.79221 4.91798 3.53739 5.14636 3.3475 5.4251C3.15761 5.70383 3.03835 6.02456 3 6.35964V17.2296C3 19.0996 7 20.6096 12 20.6096C17 20.6096 21 19.0996 21 17.2296V6.35964C20.9616 6.02456 20.8424 5.70383 20.6525 5.4251C20.4626 5.14636 20.2078 4.91798 19.91 4.75964ZM19.5 13.5896C19.16 14.1696 16.64 15.4696 12.02 15.4696C7.4 15.4696 4.86 14.1996 4.57 13.6196V11.8596C6.89474 12.9355 9.43996 13.4493 12 13.3596C14.5843 13.4573 17.1548 12.9398 19.5 11.8496V13.5896ZM19.5 9.97965C19.16 10.5596 16.64 11.8496 12.02 11.8496C7.4 11.8496 4.86 10.5796 4.57 9.99964V8.26964C6.91516 9.35976 9.4857 9.87729 12.07 9.77964C14.6543 9.87729 17.2248 9.35976 19.57 8.26964L19.5 9.97965ZM5.15 5.84964L5.48 5.68964L5.78 5.53964L6.22 5.35964L6.62 5.20965L7.18 5.05965L7.66 4.92964L8.36 4.78964L8.9 4.68964L9.81 4.58964L10.35 4.53964C10.8433 4.49964 11.3633 4.47965 11.91 4.47965H12.09C12.6433 4.47965 13.1633 4.49964 13.65 4.53964L14.19 4.58964L15.1 4.68964L15.63 4.78964L16.34 4.92964L16.82 5.05965L17.38 5.20965L17.78 5.35964L18.22 5.51964L18.52 5.66964L18.85 5.82964L19.05 5.96965L19.27 6.11964L19.38 6.23964C19.4162 6.27713 19.4497 6.31724 19.48 6.35964C19.12 6.93964 16.6 8.22965 12 8.22965C7.4 8.22965 4.87 6.95964 4.57 6.35964C4.59722 6.31493 4.63092 6.27449 4.67 6.23964L4.78 6.11964L4.99 5.96965L5.15 5.84964ZM12 19.1196C7.29 19.1196 4.75 17.7696 4.5 17.2096V15.4796C6.84408 16.5739 9.41483 17.0949 12 16.9996C14.5852 17.0949 17.1559 16.5739 19.5 15.4796V17.2096C19.25 17.7696 16.71 19.1196 12 19.1196Z"
      fill="currentColor"
    />
    <path
      d="M17.9609 10.4492C18.2371 10.4492 18.4609 10.2254 18.4609 9.94922C18.4609 9.67308 18.2371 9.44922 17.9609 9.44922C17.6848 9.44922 17.4609 9.67308 17.4609 9.94922C17.4609 10.2254 17.6848 10.4492 17.9609 10.4492Z"
      fill="currentColor"
    />
    <path
      d="M15.8594 10.9297C16.1355 10.9297 16.3594 10.7058 16.3594 10.4297C16.3594 10.1535 16.1355 9.92969 15.8594 9.92969C15.5832 9.92969 15.3594 10.1535 15.3594 10.4297C15.3594 10.7058 15.5832 10.9297 15.8594 10.9297Z"
      fill="currentColor"
    />
    <path
      d="M17.9609 14.0195C18.2371 14.0195 18.4609 13.7957 18.4609 13.5195C18.4609 13.2434 18.2371 13.0195 17.9609 13.0195C17.6848 13.0195 17.4609 13.2434 17.4609 13.5195C17.4609 13.7957 17.6848 14.0195 17.9609 14.0195Z"
      fill="currentColor"
    />
    <path
      d="M15.8594 14.5098C16.1355 14.5098 16.3594 14.2859 16.3594 14.0098C16.3594 13.7336 16.1355 13.5098 15.8594 13.5098C15.5832 13.5098 15.3594 13.7336 15.3594 14.0098C15.3594 14.2859 15.5832 14.5098 15.8594 14.5098Z"
      fill="currentColor"
    />
    <path
      d="M17.9609 17.6191C18.2371 17.6191 18.4609 17.3953 18.4609 17.1191C18.4609 16.843 18.2371 16.6191 17.9609 16.6191C17.6848 16.6191 17.4609 16.843 17.4609 17.1191C17.4609 17.3953 17.6848 17.6191 17.9609 17.6191Z"
      fill="currentColor"
    />
    <path
      d="M15.8594 18.1094C16.1355 18.1094 16.3594 17.8855 16.3594 17.6094C16.3594 17.3332 16.1355 17.1094 15.8594 17.1094C15.5832 17.1094 15.3594 17.3332 15.3594 17.6094C15.3594 17.8855 15.5832 18.1094 15.8594 18.1094Z"
      fill="currentColor"
    />
  </svg>,
  "Database Access",
);
