import { createSvgIcon } from "@mui/material";

export const EKSClustersIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_14029)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.403 16.1434V19.4551L22.273 16.1434H23.716L20.465 20.1487L23.882 24.3702H22.459L19.403 20.5653V24.3565H18.117V16.1434H19.403ZM32 24.2494L28 21.8926V15.8251C28 15.6355 27.888 15.4625 27.713 15.381L22 12.7363V8.28493L32 13.1823V24.2494ZM32.722 12.4387L21.722 7.05201C21.567 6.97533 21.383 6.98419 21.237 7.07361C21.09 7.16302 21 7.32118 21 7.49115V13.0478C21 13.2374 21.111 13.4113 21.287 13.4918L27 16.1365V22.1706C27 22.3435 27.091 22.5027 27.243 22.5911L32.243 25.5383C32.322 25.5855 32.411 25.6091 32.5 25.6091C32.585 25.6091 32.67 25.5875 32.746 25.5452C32.903 25.4578 33 25.2947 33 25.1179V12.8778C33 12.6921 32.892 12.5212 32.722 12.4387ZM19.995 32.9518L9 27.3166V13.1686L18 8.32032V12.7619L13.254 15.3977C13.096 15.4852 13 15.6483 13 15.8251V24.667C13 24.8457 13.099 25.0108 13.258 25.0972L19.758 28.6124C19.909 28.6939 20.091 28.6939 20.24 28.6134L26.49 25.2417L30.036 27.5719L19.995 32.9518ZM26.794 24.2592C26.638 24.1571 26.439 24.1492 26.275 24.2366L20 27.6221L14 24.3771V16.1119L18.746 13.4751C18.903 13.3886 19 13.2246 19 13.0478V7.49115C19 7.31724 18.907 7.15712 18.755 7.06869C18.603 6.98025 18.415 6.9773 18.259 7.05982L8.25902 12.4475C8.09902 12.5339 8 12.699 8 12.8778V27.6142C8 27.7979 8.10301 27.9649 8.268 28.0504L19.768 33.945C19.84 33.9814 19.921 34 20 34C20.082 34 20.165 33.9804 20.239 33.9401L31.239 28.0455C31.392 27.963 31.491 27.8087 31.499 27.6368C31.507 27.4649 31.424 27.3018 31.278 27.2065L26.794 24.2592Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_14029"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "EKS Clusters",
);
