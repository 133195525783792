import { ReactNode, useCallback } from "react";
import { styled, useTheme } from "@mui/material";
import { motion, useTransform, useMotionValue } from "framer-motion";

type ResizablePanelProps = {
  children?: ReactNode;
};

const MIN_WIDTH = 300;

export function ResizablePanel({ children }: ResizablePanelProps) {
  const theme = useTheme();
  const offset = useMotionValue(0);
  const panelWidth = useTransform(offset, (value) => MIN_WIDTH + value);

  const start = useCallback(() => {
    document.body.style.cursor = "col-resize";
  }, []);

  const stop = useCallback(() => {
    document.body.style.cursor = "default";
  }, []);

  return (
    <Panel style={{ width: panelWidth }}>
      <DragHandle
        drag="x"
        variants={{
          active: {
            backgroundColor: theme.palette.background.light,
          },
        }}
        dragMomentum={false}
        dragConstraints={{ left: 0 }}
        dragElastic={0}
        whileHover="active"
        whileTap="active"
        onPointerDown={start}
        onPointerUp={stop}
        onPanEnd={stop}
        style={{ x: offset }}
      />
      {children}
    </Panel>
  );
}

const Panel = styled(motion.div)(({ theme }) => ({
  position: "relative",
  borderRight: "1px solid",
  borderColor: theme.palette.background.light,
  maxHeight: "700px",
  overflowX: "scroll",
}));

const DragHandle = styled(motion.div)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: `${MIN_WIDTH - 8}px`,
  x: MIN_WIDTH,
  height: "100%",
  width: theme.spacing(1),
  backgroundColor: "transparent",
  cursor: "col-resize",
  transition: "background-color 0.05",
  zIndex: 15,
}));
