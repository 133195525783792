import { Balance, IosShare } from "@mui/icons-material";
import {
  Box,
  Typography,
  Tooltip,
  Button,
  alpha,
  Menu,
  MenuItem,
  Link,
  ListItemIcon,
  ListItemText,
  Divider,
  Collapse,
  SelectChangeEvent,
} from "@mui/material";
import { copyToClipboard } from "~/lib/clipboard";
import { Caption } from "~/pages/security/policies/components/Caption";
import { EmailIcon } from "../icons";
import { PolicyDownloadButton } from "../policy-download-button";
import { MSelect } from "../Select/Select";
import { ShowMoreSummary } from "../ShowMore/ShowMoreSummary";
import { Flex, CopyLinkIcon } from "../ui-library";
import { Fragment, useState } from "react";
import { usePolicyViewer } from "./hooks/usePolicyViewer";
import { Policy, PolicyViewerProps } from "./policy-gql";
import { Space } from "~/lib/types";
import { TestIamActionsQuery, TrustLevel } from "~/operations";
import { PolicyAssignSelect } from "./PolicyAssignSelect";
import { PolicyDeleteButton } from "./PolicyDeleteButton";
import { usePolicyPermissions } from "~/pages/security/policies/hooks/usePolicyPermissions";

type PolicyHeaderProps = {
  space: Space;
  policy: Policy;
  showScoringSystem: ReturnType<typeof usePolicyViewer>["showScoringSystem"];
  setShowScoringSystem: ReturnType<
    typeof usePolicyViewer
  >["setShowScoringSystem"];
  availablePermissions: TestIamActionsQuery["testIamActions"];
  onAssignChange: PolicyViewerProps["onAssignChange"];
  scoringSystem: ReturnType<typeof usePolicyViewer>["scoringSystem"];
  scoreByMenuItems: ReturnType<typeof usePolicyViewer>["scoreByMenuItems"];
  handleChangeScoringSystem: (event: SelectChangeEvent<unknown>) => void;
};

export function PolicyHeader({
  space,
  policy,
  showScoringSystem,
  setShowScoringSystem,
  availablePermissions,
  onAssignChange,
  scoringSystem,
  scoreByMenuItems,
  handleChangeScoringSystem,
}: PolicyHeaderProps) {
  const { policyAssignPermission } = usePolicyPermissions({
    availablePermissions,
  });
  const [shareAnchorEl, setShareAnchorEl] = useState<null | HTMLElement>(null);
  const shareOpen = Boolean(shareAnchorEl);
  const shareLink = window.location.href;
  const shareEmailLink = `mailto:hello@mondoo.com?subject=${encodeURIComponent("Take a look at this policy")}&body=${encodeURIComponent(shareLink)}`;
  const isCustomPolicy = policy.trustLevel === TrustLevel.Private;

  return (
    <>
      <Box
        id="policy-header"
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Flex flexDirection="column">
          <Typography
            variant="h4"
            fontWeight={700}
            textTransform="uppercase"
            sx={{ mb: 1 }}
          >
            {policy.name}
          </Typography>
          <Caption
            trustLevel={policy.trustLevel}
            version={policy.version}
            authors={policy.authors}
          />
        </Flex>
        <Flex id="policy-actions" alignItems="center" gap={4}>
          <Tooltip title="Score Weighting" placement="top" arrow>
            <Button
              onClick={(e) => setShowScoringSystem(!showScoringSystem)}
              sx={{
                minWidth: "unset",
                ...(showScoringSystem
                  ? {
                      color: "primary.main",
                      bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, 0.2),
                    }
                  : {}),
              }}
            >
              <Balance />
            </Button>
          </Tooltip>
          <Tooltip title="Share" placement="top" arrow>
            <Button
              onClick={(e) => setShareAnchorEl(e.currentTarget)}
              sx={{ minWidth: "unset" }}
            >
              <IosShare />
            </Button>
          </Tooltip>
          <Menu
            anchorEl={shareAnchorEl}
            open={shareOpen}
            onClose={() => setShareAnchorEl(null)}
            onClick={() => setShareAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem onClick={() => copyToClipboard(shareLink)}>
              <ListItemIcon>
                <CopyLinkIcon fontSize="inherit" />
              </ListItemIcon>
              <ListItemText>Copy Link</ListItemText>
            </MenuItem>
            <MenuItem component="a" href={shareEmailLink}>
              <ListItemIcon>
                <EmailIcon fontSize="inherit" />
              </ListItemIcon>
              <ListItemText>Email</ListItemText>
            </MenuItem>
          </Menu>
          <PolicyDownloadButton
            space={space}
            policy={policy}
            size="small"
            iconOnly
          />
          <Divider orientation="vertical" flexItem />

          {policyAssignPermission && (
            <Fragment>
              <PolicyDeleteButton
                space={space}
                policyMrn={policy.mrn}
                isCustom={isCustomPolicy}
                availablePermissions={availablePermissions}
              />
              <PolicyAssignSelect
                spaceMrn={space.mrn}
                policyMrn={policy.mrn}
                action={policy.action}
                onChange={onAssignChange}
              />
            </Fragment>
          )}
        </Flex>
      </Box>
      {policyAssignPermission && (
        <Collapse in={showScoringSystem}>
          <Flex sx={{ flexDirection: "column", gap: 1 }}>
            <Flex sx={{ gap: 0.5, alignItems: "center" }}>
              <Balance fontSize="inherit" />
              <Typography sx={{ fontWeight: 700, mb: 0 }}>
                Score weighting
              </Typography>
            </Flex>
            <Flex sx={{ gap: 3 }}>
              <Box>
                <Typography sx={{ color: "text.secondary", mb: 1 }}>
                  Choose how Mondoo calculates asset security scores with this
                  policy. To learn about scoring methods, read the{" "}
                  <Link
                    href="https://mondoo.com/docs/platform/security/customize/score/"
                    target="_blank"
                    rel="noopener"
                  >
                    Mondoo documentation
                  </Link>
                  .
                </Typography>
              </Box>
              <MSelect
                id="score-by"
                label="Score by"
                value={scoringSystem}
                menuItems={scoreByMenuItems}
                onChange={handleChangeScoringSystem}
              />
            </Flex>
            <Divider sx={{ my: 2 }} />
          </Flex>
        </Collapse>
      )}
      {policy.docs && <ShowMoreSummary maxHeight={72} text={policy.docs} />}
    </>
  );
}
