import { SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const EuIcon = ({ ...props }) => {
  const { mode } = useColorMode();
  return (
    <SvgIcon {...props}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 300 300"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        fillRule="evenodd"
        clipRule="evenodd"
        strokeLinejoin="round"
        strokeMiterlimit="2"
      >
        <g id="s" transform="matrix(1,0,0,1,150,30)">
          <g id="c">
            <g
              id="t"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t1"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c1"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t2"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t3"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c2"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t4"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t5"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c3"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t6"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t7"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c4"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t8"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t9"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s1" transform="matrix(1,1.22125e-15,-1.22125e-15,1,210,46.077)">
          <g id="c5">
            <g
              id="t10"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t11"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c6"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t12"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t13"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c7"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t14"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t15"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c8"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t16"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t17"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c9"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t18"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t19"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s2" transform="matrix(1,1.16573e-15,-1.16573e-15,1,253.923,90)">
          <g id="c10">
            <g
              id="t20"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t21"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c11"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t22"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t23"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c12"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t24"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t25"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c13"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t26"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t27"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c14"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t28"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t29"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s3" transform="matrix(1,1.08734e-15,-1.08734e-15,1,270,150)">
          <g id="c15">
            <g
              id="t30"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t31"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c16"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t32"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t33"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c17"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t34"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t35"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c18"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t36"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t37"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c19"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t38"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t39"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s4" transform="matrix(1,6.10623e-16,-6.10623e-16,1,253.923,210)">
          <g id="c20">
            <g
              id="t40"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t41"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c21"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t42"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t43"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c22"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t44"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t45"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c23"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t46"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t47"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c24"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t48"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t49"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s5" transform="matrix(1,1.16573e-15,-1.16573e-15,1,210,253.923)">
          <g id="c25">
            <g
              id="t50"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t51"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c26"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t52"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t53"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c27"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t54"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t55"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c28"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t56"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t57"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c29"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t58"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t59"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s6" transform="matrix(1,1.53143e-15,-1.53143e-15,1,150,270)">
          <g id="c30">
            <g
              id="t60"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t61"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c31"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t62"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t63"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c32"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t64"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t65"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c33"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t66"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t67"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c34"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t68"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t69"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s7" transform="matrix(1,1.16573e-15,-1.16573e-15,1,90,253.923)">
          <g id="c35">
            <g
              id="t70"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t71"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c36"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t72"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t73"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c37"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t74"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t75"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c38"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t76"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t77"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c39"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t78"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t79"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s8" transform="matrix(1,6.10623e-16,-6.10623e-16,1,46.077,210)">
          <g id="c40">
            <g
              id="t80"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t81"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c41"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t82"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t83"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c42"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t84"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t85"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c43"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t86"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t87"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c44"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t88"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t89"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s9" transform="matrix(1,1.08734e-15,-1.08734e-15,1,30,150)">
          <g id="c45">
            <g
              id="t90"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t91"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c46"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t92"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t93"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c47"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t94"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t95"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c48"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t96"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t97"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c49"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t98"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t99"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s10" transform="matrix(1,1.16573e-15,-1.16573e-15,1,46.077,90)">
          <g id="c50">
            <g
              id="t100"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t101"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c51"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t102"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t103"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c52"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t104"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t105"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c53"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t106"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t107"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c54"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t108"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t109"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
        <g id="s11" transform="matrix(1,1.22125e-15,-1.22125e-15,1,90,46.077)">
          <g id="c55">
            <g
              id="t110"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t111"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c56"
            transform="matrix(0.309017,0.951057,-0.951057,0.309017,0,0)"
          >
            <g
              id="t112"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t113"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c57"
            transform="matrix(-0.809017,0.587785,-0.587785,-0.809017,0,0)"
          >
            <g
              id="t114"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t115"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c58"
            transform="matrix(-0.809017,-0.587785,0.587785,-0.809017,0,0)"
          >
            <g
              id="t116"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t117"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
          <g
            id="c59"
            transform="matrix(0.309017,-0.951057,0.951057,0.309017,0,0)"
          >
            <g
              id="t118"
              transform="matrix(0.951057,0.309017,-0.309017,0.951057,-6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
            <g
              id="t119"
              transform="matrix(-0.951057,0.309017,0.309017,0.951057,6.18034,-0.97887)"
            >
              <path
                d="M0,-20L0,0L10,0"
                fill={mode === "light" ? "#363636" : "#fff"}
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </svg>
      ,
    </SvgIcon>
  );
};

EuIcon.displayName = "European Parliament";
