import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import { Space } from "~/lib/types";
import { AddAnElementCard } from "../add-an-element-card";
import { IntegrationSummaryCard } from "./cards/integration-summary-card";
import { fadeInOut } from "~/lib/animations";
import { activeSummaries } from "~/lib/integrations";
import { IntegrationTypeSummary, TestIamActionsQuery } from "~/operations";
import { IamActions } from "~/lib/iam";

type Props = {
  space: Space;
  summaries: IntegrationTypeSummary[];
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function ActiveIntegrations({
  space,
  summaries,
  availablePermissions,
}: Props) {
  const hasCreateIntegrationPermission = availablePermissions.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
  );
  return (
    <Grid
      container
      spacing={3}
      component={motion.div}
      {...fadeInOut}
      key="active-integrations"
    >
      {activeSummaries(summaries).map((integration) => (
        <Grid key={integration.type} item xs={12} sm={6} md={4}>
          <IntegrationSummaryCard {...{ space, integration }} />
        </Grid>
      ))}
      {hasCreateIntegrationPermission && (
        <Grid item xs={12} sm={6} md={4} sx={{ display: "flex" }}>
          <AddAnElementCard
            label="add another integration"
            to={`add?spaceId=${space.id}`}
            minHeight={173}
          />
        </Grid>
      )}
    </Grid>
  );
}
