import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  alpha,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Paper,
  Typography,
} from "@mui/material";
import { TestIamActionsQuery, useLoadSpacePlanQuery } from "~/operations";
import { Space } from "~/lib/types";
import {
  AccessTimeIcon,
  HomeIcon,
  TimerOutlinedIcon,
} from "~/components/icons";
import {
  Flex,
  LightbulbIcon,
  BarChartIcon,
  LoadingPage,
  LoadingFailedPage,
  Search,
} from "~/components/ui-library";
import { HubCard } from "~/components/hub-card";
import Improvement from "./improvement.svg";
import { BlockOutlined } from "@mui/icons-material";
import GradeBlock from "./GradeBlock";
import ScoreBarChart from "./ScoreBarChart";
import { FUTURE_GOALS_UID, RiskPlanner } from "./risk-planner";
import { ChecksList } from "./planning-edit";

export type PlanningPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Planning({ space, availablePermissions }: PlanningPageProps) {
  const { data, loading, error } = useLoadSpacePlanQuery({
    variables: { input: { spaceMrn: space.mrn, scopeMrn: space.mrn } },
  });

  const [searchValue, setSearchValue] = useState("");

  if (loading) {
    return <LoadingPage />;
  }

  const spacePlan = data?.spacePlan;

  if (error || !spacePlan) {
    return <LoadingFailedPage />;
  }

  const planner = new RiskPlanner(spacePlan);
  const activeChecks = planner.activeChecks;
  const plannedChecks = planner.plannedChecks;
  const plannedSummary = planner.checksScoreSummary(plannedChecks);
  const plannedGradeScore = planner.checksGradeScore(plannedChecks);
  const activeSummary = planner.checksScoreSummary(activeChecks);
  const activeGradeScore = planner.checksGradeScore(activeChecks);

  const groups = [
    ...planner.milestonesList.map((m) => m.uid),
    "active",
    "exceptions",
  ];

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key={2}>Risk Actions</Typography>,
  ];

  document.title = "Risk Actions · Mondoo";

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Flex alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="h4" fontWeight={700}>
          Planning
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          component={RouterLink}
          to={`/space/risk-actions/edit?spaceId=${space.id}`}
        >
          {spacePlan.planApplied ? "Update Plan" : "Start Planning"}
        </Button>
      </Flex>

      <Grid container spacing={3}>
        <HubCard
          {...{
            loading: false,
            sizes: { xs: 12, md: 6 },
            title: "Scored",
            icon: <BarChartIcon />,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              height: 1,
            }}
          >
            <Flex sx={{ width: 1 }}>
              <GradeBlock scoreNumber={activeGradeScore} />
              <Box sx={{ width: (theme) => theme.spacing(2) }} />
              <ScoreBarChart data={activeSummary} disableTotal />
            </Flex>
          </Box>
        </HubCard>
        <HubCard
          {...{
            loading: false,
            sizes: { xs: 12, md: 6 },
            title: "Planned",
            icon: <TimerOutlinedIcon />,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              height: 1,
            }}
          >
            {spacePlan.planApplied && (
              <Flex sx={{ width: 1 }}>
                <GradeBlock scoreNumber={plannedGradeScore} />
                <Box sx={{ width: (theme) => theme.spacing(2) }} />
                <ScoreBarChart data={plannedSummary} disableTotal />
              </Flex>
            )}
            {!spacePlan.planApplied && (
              <Typography
                fontWeight={700}
                textAlign="center"
                sx={{
                  color: (theme) => alpha(theme.palette.text.primary, 0.33),
                }}
              >
                The progress towards your plan will be tracked here
              </Typography>
            )}
          </Box>
        </HubCard>
        {!spacePlan.planApplied && (
          <Grid item xs={12}>
            <Paper
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                p: 4,
              }}
            >
              <Flex flexDirection="column" alignItems="center">
                <Box sx={{ mt: -3 }}>
                  <Box component="img" src={Improvement} />
                </Box>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  sx={{ mt: 1, mb: 3 }}
                >
                  <Typography
                    fontWeight={700}
                    sx={{ textTransform: "uppercase", textAlign: "center" }}
                  >
                    Set up a plan to improve your scores
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ textAlign: "center", display: "none" }}
                  >
                    Mondoo has found 45 checks that can improve your grade by up
                    to 25%
                  </Typography>
                </Flex>

                <Button
                  sx={{
                    px: 2,
                    background: "linear-gradient(#9147FF, #4C35E8)",
                  }}
                  component={RouterLink}
                  to={`/space/risk-actions/edit?spaceId=${space.id}`}
                >
                  Start Planning
                </Button>
              </Flex>
            </Paper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Search
            name="search-plan-checks"
            value={searchValue}
            onChangeHandler={(o) =>
              setSearchValue(
                Array.isArray(o.value) ? o.value.join("") : o.value,
              )
            }
            fullWidth
          />
        </Grid>
      </Grid>

      {groups.map((g, i) => {
        const m = planner.milestones[g];
        const id = g;
        const title = !m ? g : planner.milestoneTitle(g);
        const icon =
          g === "active" ? (
            <BarChartIcon />
          ) : g === "exceptions" ? (
            <BlockOutlined />
          ) : g === FUTURE_GOALS_UID ? (
            <LightbulbIcon />
          ) : (
            <AccessTimeIcon />
          );
        const checks = planner
          .checksForGroup(g)
          .filter((c) =>
            c.mquery.title.toLowerCase().includes(searchValue.toLowerCase()),
          );
        return (
          <ChecksList
            key={id}
            id={id}
            checks={checks}
            title={title}
            icon={icon}
          />
        );
      })}
    </Box>
  );
}
