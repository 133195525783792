import React from "react";
import { NavDrawerVulnerabilitiesIcon } from "~/components/icons";
import { Button } from "@mui/material";
import { EmptyState } from "~/pages/inventory/components/EmptyState/EmptyState";

type NoMatchFiltersProps = {
  onClick: () => void;
  title: string;
  content: string;
};

export const NoMatchFilters = ({
  onClick,
  content,
  title,
}: NoMatchFiltersProps) => {
  return (
    <EmptyState
      headline={title}
      icon={
        <NavDrawerVulnerabilitiesIcon
          sx={{ fontSize: 38, color: "primary.main" }}
        />
      }
      textContent={content}
      actionButtons={
        <Button variant="outlined" color="error" onClick={onClick}>
          Reset filters
        </Button>
      }
    />
  );
};
