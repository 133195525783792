import { createSvgIcon } from "@mui/material";

export const UbuntuIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4375 1H5V23H19.4375V1Z" fill="#E95420" />
    <path
      d="M8.30047 17.0325C9.12441 17.0325 9.79234 16.3645 9.79234 15.5406C9.79234 14.7166 9.12441 14.0487 8.30047 14.0487C7.47653 14.0487 6.80859 14.7166 6.80859 15.5406C6.80859 16.3645 7.47653 17.0325 8.30047 17.0325Z"
      fill="white"
    />
    <path
      d="M14.529 13.76C15.3529 13.76 16.0209 13.0921 16.0209 12.2681C16.0209 11.4442 15.3529 10.7762 14.529 10.7762C13.705 10.7762 13.0371 11.4442 13.0371 12.2681C13.0371 13.0921 13.705 13.76 14.529 13.76Z"
      fill="white"
    />
    <path
      d="M11.6688 19.3906C10.5895 19.1569 9.6957 18.4694 9.18695 17.5C8.7882 17.6787 8.34133 17.7337 7.9082 17.6581C8.52008 19.1637 9.81945 20.25 11.4145 20.5938C11.7651 20.6694 12.1226 20.7037 12.4732 20.7037C12.1982 20.3462 12.047 19.9063 12.0332 19.4525C11.9095 19.4388 11.7857 19.4181 11.6688 19.3906Z"
      fill="white"
    />
    <path
      d="M14.1921 20.8894C15.016 20.8894 15.6839 20.2215 15.6839 19.3975C15.6839 18.5736 15.016 17.9056 14.1921 17.9056C13.3681 17.9056 12.7002 18.5736 12.7002 19.3975C12.7002 20.2215 13.3681 20.8894 14.1921 20.8894Z"
      fill="white"
    />
    <path
      d="M16.2678 18.8475C16.7353 18.2631 17.0584 17.5687 17.2165 16.8331C17.4915 15.5475 17.2371 14.2 16.5084 13.1069C16.3365 13.5125 16.0409 13.8562 15.6696 14.09C16.0753 14.8531 16.199 15.7262 16.0134 16.5719C15.924 16.9844 15.7659 17.3762 15.5459 17.7269C15.8965 18.0156 16.144 18.4006 16.2609 18.8406L16.2678 18.8475Z"
      fill="white"
    />
    <path
      d="M8.18359 13.4025C8.22484 13.4025 8.25922 13.4025 8.30047 13.4025C8.45172 13.4025 8.60297 13.4163 8.75422 13.4506C8.99484 13.5056 9.22859 13.595 9.43484 13.7256C10.1086 12.7563 11.1948 12.1788 12.3705 12.1581C12.3705 12.0481 12.3911 11.9313 12.4186 11.8213C12.4873 11.4981 12.6248 11.2025 12.8242 10.9481C10.9473 10.7969 9.11859 11.7663 8.19047 13.4094L8.18359 13.4025Z"
      fill="white"
    />
  </svg>,
  "Ubuntu",
);
