import { TableCell, Box } from "@mui/material";
import { ExploitIcon } from "./exploit-icon";
import { RemoteExploitIcon } from "./remote-exploit-icon";
import { VulnerabilityRiskFactors } from "~/operations";

type DeprecatedRiskFactorsCellProps = {
  vulnerabilityRiskFactors: VulnerabilityRiskFactors;
  "data-testid"?: string;
};

export function DeprecatedRiskFactorsCell({
  vulnerabilityRiskFactors,
  ...props
}: DeprecatedRiskFactorsCellProps) {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Box display="flex" gap={2}>
        {vulnerabilityRiskFactors.knownExploited && <ExploitIcon />}
        {vulnerabilityRiskFactors.remoteExploitable && <RemoteExploitIcon />}
      </Box>
    </TableCell>
  );
}
