import { createContext, ReactNode, useContext, useState } from "react";
import { ordinalize } from "~/lib/ordinalize";

export type EpssScore = {
  probability: number;
  percentile: number;
};

export type EpssMetric = {
  id?: string | undefined;
  label: string;
  description: string;
  value: number;
  formattedValue: string;
};

export type EpssMetrics = {
  probability: EpssMetric;
  percentile: EpssMetric;
  cvssScore: EpssMetric;
};

type HoveredMetric = EpssMetric | undefined;
type FocusedMetric = EpssMetric | undefined;

export type EpssRiskContextType = {
  percentile: number;
  probability: number;
  cvssScore: number;
  metrics: EpssMetrics;
  focusedMetric: FocusedMetric;
  hoveredMetric: HoveredMetric;
  isFocused: (metric: EpssMetric) => boolean;
  isHovered: (metric: EpssMetric) => boolean;
  setFocusedMetric: (metric: FocusedMetric) => void;
  toggleFocusedMetric: (metric: EpssMetric) => void;
  setHoveredMetric: (metric: HoveredMetric) => void;
  toggleHoveredMetric: (metric: EpssMetric) => void;
  onMetricMouseEnter: (metric: EpssMetric) => void;
  onMetricMouseLeave: (metric: EpssMetric) => void;
  onMetricFocus: (metric: EpssMetric) => void;
  onMetricBlur: (metric: EpssMetric) => void;
};

export const EpssRiskContext = createContext<EpssRiskContextType | undefined>(
  undefined,
);

export type EpssRiskProviderProps = {
  children: ReactNode;
  percentile: number;
  probability: number;
  cvssScore: number;
};

export function EpssRiskProvider({
  children,
  percentile,
  probability,
  cvssScore,
}: EpssRiskProviderProps) {
  const percentileValue = Math.round(percentile * 100);
  const formattedPercentile = ordinalize(percentileValue);

  const formattedProbability = `${(probability * 100).toFixed(
    probability % 1 === 0 ? 0 : 1,
  )}%`;

  const metrics: EpssMetrics = {
    probability: {
      label: "Probability",
      description:
        "The FIRST Exploit Prediction Scoring System (EPSS) forecasts the probability that this CVE will be exploited in the next 30 days. It is a measure of overall risk.",
      value: probability,
      formattedValue: formattedProbability,
    },
    percentile: {
      label: "Percentile",
      description: `This CVE has a higher EPSS score than ${percentileValue}% of all EPSS-scored CVEs. The percentile is a measure of relative risk.`,
      value: percentile,
      formattedValue: formattedPercentile,
    },
    cvssScore: {
      label: "CVSS3 Score",
      description:
        "The Common Vulnerability Scoring System (CVSS) is a method used to supply a qualitative measure of CVE severity.",
      value: cvssScore,
      formattedValue: `${(cvssScore / 10).toFixed(1)}`,
    },
  };

  const [focusedMetric, setFocusedMetric] = useState<FocusedMetric>(undefined);
  const [hoveredMetric, setHoveredMetric] = useState<HoveredMetric>(undefined);

  const onMetricMouseEnter = (metric: EpssMetric) => {
    setFocusedMetric(metric);
  };

  const onMetricMouseLeave = (_metric: EpssMetric) => {
    setFocusedMetric(undefined);
  };

  const onMetricFocus = (metric: EpssMetric) => {
    setFocusedMetric(metric);
  };

  const onMetricBlur = (_metric: EpssMetric) => {
    setFocusedMetric(undefined);
  };

  const isFocused = (metric: EpssMetric) => {
    return focusedMetric?.label === metric.label;
  };

  const isHovered = (metric: EpssMetric) => {
    return hoveredMetric?.label === metric.label;
  };

  const toggleFocusedMetric = (metric: EpssMetric) => {
    setFocusedMetric(isFocused(metric) ? undefined : metric);
  };

  const toggleHoveredMetric = (metric: EpssMetric) => {
    setHoveredMetric(isHovered(metric) ? undefined : metric);
  };

  return (
    <EpssRiskContext.Provider
      value={{
        percentile,
        probability,
        cvssScore,
        metrics,
        focusedMetric,
        hoveredMetric,
        isFocused,
        isHovered,
        setFocusedMetric,
        toggleFocusedMetric,
        setHoveredMetric,
        toggleHoveredMetric,
        onMetricMouseEnter,
        onMetricMouseLeave,
        onMetricFocus,
        onMetricBlur,
      }}
    >
      {children}
    </EpssRiskContext.Provider>
  );
}

export function useEpssRisk() {
  const context = useContext(EpssRiskContext);
  if (context === undefined) {
    throw new Error("useEpssRisk must be used within a EpssRiskProvider");
  }
  return context;
}
