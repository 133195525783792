import React, { ReactNode } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import { FormattedRecipient } from "~/pages/integrations/hosted-integrations/forms/email/FormattedRecipient";
import { Control, FormState } from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import { NotesField } from "~/components/cases/components/CreateCaseModal/NotesField";
import { TitleField } from "~/components/cases/components/CreateCaseModal/TitleField";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  recipients: { email: string; name: string }[] | null | undefined;
  isRecipientsEmpty: boolean;
};

export const CreateCaseEmailModal = ({
  register,
  control,
  formState,
  recipients,
  isRecipientsEmpty,
  hasCreateCasePermissions,
}: CreateCaseModalProps) => {
  return (
    <>
      <FormControl fullWidth>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Recipient
        </Typography>

        <Controller
          name="email.recipient"
          {...{ control }}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              fullWidth
              disabled={!hasCreateCasePermissions}
              sx={{
                "&.MuiInputBase-root": {
                  bgcolor: "code.background",
                },
              }}
              displayEmpty
              renderValue={(selected: ReactNode) => {
                const selectedRecipient = recipients?.find(
                  (recipient) => recipient.email === selected,
                );

                if (!selectedRecipient) {
                  return <>Select a recipient</>;
                }

                const selectedRecipientTitle = (
                  <FormattedRecipient
                    name={selectedRecipient.name}
                    email={selectedRecipient.email}
                  />
                );

                if (isRecipientsEmpty) {
                  return (
                    <>
                      No recipients available – create one in email integration
                      first
                    </>
                  );
                }

                if (!selected || !selectedRecipientTitle) {
                  return <>Select a recipient</>;
                }

                return <>{selectedRecipientTitle}</>;
              }}
            >
              {(recipients || []).map((recipient) => (
                <MenuItem key={recipient.name} value={recipient.email}>
                  {recipient.name} ({recipient.email})
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <TitleField
          textFieldProps={register("email.title", { required: true })}
          errorMessage={formState.errors?.email?.title?.message}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <NotesField
          errorMessage={formState.errors.email?.notes?.message}
          textFieldProps={register("email.notes")}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
    </>
  );
};
