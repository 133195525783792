import { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Command } from "~/components/guides/components";
import { OperatorList } from "./operator-list";
import { ToggleOption } from "../../components/ToggleOption/ToggleOption";
import { ChevronRightIcon, WarningAmberIcon } from "~/components/icons";
import { motionOptions } from "./operator-configuration";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import { Space } from "~/lib/types";
import { K8sScanNodesStyle } from "~/operations";
import { motionAccordionVariants } from "~/constants/motion";

export interface IntegrationFormInputs {
  integrationName: string;
  scanNodes: boolean;
  scanNodesStyle: K8sScanNodesStyle;
  scanWorkloads: boolean;
  workloadImageScanning: boolean;
  namespaceScanning: boolean;
  scanDeploys: boolean;
  certificateManager: string;
  namespaceAllowList: string;
  namespaceDenyList: string;
}

type Props = {
  space: Space;
  onSubmit: SubmitHandler<IntegrationFormInputs>;
  updateFlow?: {
    values: IntegrationFormInputs;
    integrationMrn: string;
  };
  values?: IntegrationFormInputs;
};

export const defaultValues = {
  integrationName: "",
  scanNodes: true,
  scanWorkloads: true,
  workloadImageScanning: true,
  scanDeploys: false,
  scanNodesStyle: K8sScanNodesStyle.Cronjob,
  namespaceScanning: true,
  certificateManager: "cert-manager",
  namespaceAllowList: "",
  namespaceDenyList: "mondoo-operator",
};

export function OperatorConfigurationForm({
  space,
  onSubmit,
  updateFlow,
}: Props) {
  const theme = useTheme();
  const defaultIntegrationName = useGenerateIntegrationName({ space });
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<IntegrationFormInputs>({
    mode: "onChange",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  // If we are in the update flow, we want to reset the values to the
  // pre existing configurations
  useEffect(() => {
    if (updateFlow?.values) {
      reset({
        ...updateFlow.values,
        ...(updateFlow.values.certificateManager.length > 0
          ? {
              scanDeploys: true,
            }
          : {
              scanDeploys: false,
              certificateManager: "cert-manager",
            }),
        ...(updateFlow.values.namespaceAllowList.length > 0 ||
        updateFlow.values.namespaceDenyList.length > 0
          ? { namespaceScanning: true }
          : { namespaceScanning: false }),
      });
    }
  }, []);

  const [
    watchNamespace,
    watchScanNodes,
    watchScanWorkloads,
    watchIncomingDeployments,
  ] = watch(["namespaceScanning", "scanNodes", "scanWorkloads", "scanDeploys"]);

  return (
    <motion.form
      onSubmit={handleSubmit(onSubmit)}
      {...motionOptions}
      key="create-integration-form"
    >
      <Grid container spacing={5}>
        {/* Step 1 */}
        <Grid item xs={12}>
          <Command
            number={1}
            options={{
              fontSize: { xs: 16 },
              dotColor: theme.palette.background.lightest,
            }}
          >
            Choose an integration name
          </Command>

          <Typography variant="body2" color="text.secondary" sx={{ my: 3 }}>
            Please choose a descriptive name that lets you easily identify your
            integration.
          </Typography>
          <Controller
            name="integrationName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder="Your integration name..."
                error={Boolean(errors.integrationName)}
                helperText={
                  Boolean(errors.integrationName) && (
                    <ValidationMessage error={errors.integrationName} />
                  )
                }
              />
            )}
          />
        </Grid>

        {/* Step 2 */}
        <Grid item container xs={12} rowGap={2}>
          <Grid item xs={12}>
            <Command
              number={2}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Select node scanning options
            </Command>
          </Grid>

          <Grid item xs={12}>
            <ToggleOption
              title="Scan nodes"
              description="Allow Mondoo to scan cluster nodes for vulnerabilities."
              formOptions={{ name: "scanNodes", control }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel
                id="choose-job-type"
                sx={{
                  fontWeight: 700,
                  "&.Mui-focused": { color: "text.secondary" },
                }}
              >
                Choose how to scan cluster nodes
              </FormLabel>

              <Link
                variant="body2"
                sx={{
                  mx: 0,
                  color: "secondary.light",
                }}
                target="_blank"
                rel="noopener"
                href="https://mondoo.com/docs/platform/infra/cloud/kubernetes/scan-kubernetes-with-operator/"
              >
                To learn more, read the Mondoo documentation.
              </Link>
              <Controller
                name="scanNodesStyle"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="choose-job-options"
                    {...field}
                    sx={{
                      "& .MuiFormControlLabel-label": { fontWeight: 700 },
                    }}
                  >
                    <FormControlLabel
                      value={K8sScanNodesStyle.Cronjob}
                      control={<Radio />}
                      label="CronJob-based (recommended)"
                      disabled={watchScanNodes === false}
                    />
                    <FormControlLabel
                      value={K8sScanNodesStyle.Daemonset}
                      control={<Radio />}
                      label="DaemonSet-based"
                      disabled={watchScanNodes === false}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        {/* Step 3 */}
        <Grid container item xs={12} rowGap={2}>
          <Grid item xs={12}>
            <Command
              number={3}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Select workload scanning options
            </Command>
          </Grid>

          <Grid item xs={12}>
            <ToggleOption
              title="Scan workloads"
              description="Allow Mondoo to scan workloads."
              formOptions={{ name: "scanWorkloads", control }}
            />
          </Grid>
          <Grid item container xs={12} pl={4}>
            <Grid item xs={12} pb={2}>
              <ToggleOption
                title="Scan container images"
                description="Allow Mondoo to scan container images in workloads."
                helperText={{
                  message: "This can exhaust your registry quota.",
                  icon: <WarningAmberIcon fontSize="small" sx={{ mr: 0.5 }} />,
                  color: "warning.light",
                }}
                formOptions={{ name: "workloadImageScanning", control }}
                disabled={!watchScanWorkloads}
              />
            </Grid>
            <Grid item xs={12}>
              <ToggleOption
                title="Filter namespaces"
                description="Use the allow list or the deny list to limit the namespaces Mondoo scans."
                formOptions={{ name: "namespaceScanning", control }}
                disabled={!watchScanWorkloads}
              />
              <Box
                component={motion.div}
                variants={motionAccordionVariants}
                initial={false}
                animate={watchNamespace ? "open" : "closed"}
                transition={{
                  duration: 0.35,
                  staggerChildren: 0.25,
                }}
                sx={{ overflow: "hidden" }}
              >
                <Controller
                  name="namespaceAllowList"
                  {...{ control }}
                  render={({ field }) => (
                    <OperatorList
                      configuration="Allow list"
                      caption="Specify the namespaces for Mondoo to scan. Type each namespace on a new line:"
                      {...{ field }}
                      disabled={!watchScanWorkloads}
                    />
                  )}
                />

                <Controller
                  name="namespaceDenyList"
                  {...{ control }}
                  render={({ field }) => (
                    <OperatorList
                      configuration="Deny list"
                      caption="Specify the namespaces for Mondoo to skip. Type each namespace on a new line:"
                      index={1}
                      {...{ field }}
                      disabled={!watchScanWorkloads}
                    />
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {/* step 4 */}
        <Grid container item xs={12} rowGap={2}>
          <Grid item xs={12}>
            <Command
              number={4}
              options={{
                fontSize: { xs: 16 },
                dotColor: theme.palette.background.lightest,
              }}
            >
              Select incoming deployment scanning options
            </Command>
          </Grid>
          <Grid item xs={12}>
            <ToggleOption
              title="Scan incoming deployments"
              description="Allow Mondoo to scan incoming deployments with the Mondoo Kubernetes admission controller."
              formOptions={{ name: "scanDeploys", control }}
            />
            <FormControl sx={{ mt: 2 }}>
              <FormLabel
                id="choose-certificate-manager"
                sx={{
                  fontWeight: 700,
                  "&.Mui-focused": { color: "text.secondary" },
                }}
              >
                Choose which certificate manager is running in your cluster
              </FormLabel>

              <Link
                variant="body2"
                sx={{
                  mx: 0,
                  color: "secondary.light",
                }}
                target="_blank"
                rel="noopener"
                href="https://mondoo.com/docs/platform/infra/cloud/kubernetes/scan-kubernetes-with-operator/"
              >
                To learn more, read the Mondoo documentation.
              </Link>
              <Controller
                name="certificateManager"
                {...{ control }}
                render={({ field }) => (
                  <RadioGroup
                    aria-labelledby="choose-certificate-manager"
                    {...field}
                    sx={{
                      "& .MuiFormControlLabel-label": { fontWeight: 700 },
                    }}
                  >
                    <FormControlLabel
                      value="cert-manager"
                      control={<Radio />}
                      label="CertManager"
                      disabled={watchIncomingDeployments === false}
                    />
                    <FormControlLabel
                      value="openshift"
                      control={<Radio />}
                      label="OpenShift"
                      disabled={watchIncomingDeployments === false}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "right" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={updateFlow ? !isDirty || !isValid : !isValid}
            endIcon={<ChevronRightIcon />}
            sx={{ textAlign: "right" }}
          >
            {updateFlow
              ? "Update Integration"
              : "Create Kubernetes Integration"}
          </Button>
        </Grid>
      </Grid>
    </motion.form>
  );
}
