import {
  alpha,
  Box,
  TableCell,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ActivePolicyNode } from "./types";
import { getColor } from "~/lib/colors";

type AssetResultsCellProps = {
  blastRadius: ActivePolicyNode["blastRadius"];
  inPreviewMode?: boolean;
};

export const AssetResultsCell = ({
  blastRadius,
  inPreviewMode,
}: AssetResultsCellProps) => {
  const theme = useTheme();
  const {
    __typename,
    affected,
    assets,
    indicator,
    none,
    ...strippedBlastRadius
  } = blastRadius;

  const priorityOrder = [
    "pass",
    "low",
    "medium",
    "high",
    "critical",
    "unknown",
  ];

  const scaleHeight = (numberOfAssets: number, maxValue: number) => {
    return maxValue === 0 ? 0 : (numberOfAssets / maxValue) * 100;
  };

  //   get the maxValue from the strippedBlastRadius object, but also include the total assets value
  const maxValue = Math.max(...Object.values(strippedBlastRadius), assets);
  const sortedBlastRadius = Object.entries(strippedBlastRadius)
    .map(([key, value]) => {
      let modifiedKey = key === "error" ? "unknown" : key;
      modifiedKey = key === "pass" ? "none" : modifiedKey;
      // We're showing the unknown assets as a combitation of errors and unknowns
      if (key === "error") {
        const otherAssetsTotal =
          strippedBlastRadius.pass +
          strippedBlastRadius.low +
          strippedBlastRadius.medium +
          strippedBlastRadius.high +
          strippedBlastRadius.critical;
        // value is assets minus the sum of pass, low, medium, high, critical
        const unknownAssets = assets - otherAssetsTotal;
        return {
          key: modifiedKey,
          value: unknownAssets,
          height: scaleHeight(unknownAssets, maxValue),
        };
      }
      return {
        key: modifiedKey,
        value,
        height: scaleHeight(value, maxValue),
      };
    })
    .sort(
      (a, b) => priorityOrder.indexOf(a.key) - priorityOrder.indexOf(b.key),
    );

  return (
    <TableCell className="asset-results-cell">
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          gap: 3,
          height: 54,
          "&:after": {
            content: '""',
            display: "block",
            position: "absolute",
            left: -16,
            bottom: 16,
            height: 2,
            width: 248,
            borderRadius: 0.5,
            backgroundColor: (theme) => theme.palette.background.lighter,
            zIndex: 0,
          },
        }}
      >
        {sortedBlastRadius.map((x) => {
          const colorKey = inPreviewMode ? "unknown" : x.key;
          const color = getColor(theme, colorKey);
          const backgroundColor = getSpecialGradient(colorKey);
          const scaleHeight = x.height === 0 ? 2 : `${x.height}%`;
          const riskLabel = x.key === "unknown" ? "Error/Unscored" : x.key;

          const tooltipTitle = (
            <Box
              sx={{
                "p, span": { fontSize: 10, fontWeight: 700 },
                "& span": { color, textTransform: "uppercase" },
              }}
            >
              <Typography variant="body2">
                RISK: <Typography component="span">{riskLabel}</Typography>
              </Typography>
              <Typography variant="body2">
                # ASSETS: <Typography component="span">{x.value}</Typography>
              </Typography>
            </Box>
          );

          return (
            <Box
              key={x.key}
              className="asset-results-risk-item"
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Tooltip title={tooltipTitle} placement="top" arrow>
                <Box
                  sx={{
                    width: 16,
                    height: scaleHeight,
                    mb: 0.5,
                    zIndex: 1,
                    background: backgroundColor,
                    border: 1,
                    borderColor: alpha(color, 0.5),
                    borderRadius: 0.5,
                  }}
                />
              </Tooltip>
              <Typography
                sx={{
                  fontSize: 10,
                  fontWeight: 700,
                  lineHeight: "12px",
                  color,
                }}
              >
                {x.value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    </TableCell>
  );
};

// The gradients for these columns are special and are not based on the color palette gradients
const getSpecialGradient = (colorKey: string) => {
  switch (colorKey.toLowerCase()) {
    case "none":
      return "linear-gradient(90deg, #2FC089 0%, #6CD9AF 100%)";
    case "low":
      return "linear-gradient(90deg, #2CBFC9 1.82%, #5FD4DB 98.18%)";
    case "medium":
      return "linear-gradient(90deg, #039CD8 0%, #33C3FB 100%)";
    case "high":
      return "linear-gradient(90deg, #CF2584 0%, #DD519F 100%)";
    case "critical":
      return "linear-gradient(90deg, #FC3779 0%, #FD6094 100%)";
    case "unknown":
      return "linear-gradient(90deg, #CCC 8.59%, #F1F1F1 85.94%)";
    default:
      return "linear-gradient(90deg, #CCC 8.59%, #F1F1F1 85.94%)";
  }
};
