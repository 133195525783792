import { Grid, Typography } from "@mui/material";
import {
  CheckScoreOrderField,
  OrderDirection,
  ScoreStateFilter,
  useGetCheckScoresQuery,
} from "~/operations";
import { ListPolicyEdge } from "~/pages/inventory/asset/types";
import { FailedCheck } from "./FailedCheck";
import { Loading } from "../loading";

type FailedChecksProps = { listPolicyEdge: ListPolicyEdge; assetMrn: string };

export function FailedChecks({ assetMrn, listPolicyEdge }: FailedChecksProps) {
  const { data, loading } = useGetCheckScoresQuery({
    variables: {
      entityMrn: assetMrn,
      first: 100,
      after: null,
      orderBy: {
        field: CheckScoreOrderField.Score,
        direction: OrderDirection.Asc,
      },
      filter: {
        policyMrn: listPolicyEdge.node.mrn,
        state: ScoreStateFilter.Open,
      },
    },
  });

  if (loading || !data) {
    return (
      <Grid
        item
        xs={12}
        sx={{
          py: 4,
          borderBottom: "1px solid #DBDADD",
        }}
      >
        <Loading what="failed checks" />
      </Grid>
    );
  }

  const checks =
    data?.checkScores?.__typename === "CheckScoresConnection"
      ? data.checkScores.edges
      : [];

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        py: 4,
        borderBottom: "1px solid #DBDADD",
      }}
    >
      <Typography variant="h5" component="h4" sx={{ px: 4 }}>
        Failed Checks
      </Typography>
      <Grid
        container
        sx={{
          "& .failed-check": {
            borderBottom: "1px solid #DBDADD",
          },
          "& .failed-check:last-child": {
            borderBottom: "none",
          },
        }}
      >
        {checks?.map((edge) => {
          const check = edge.node;
          return (
            <FailedCheck key={check?.mrn} assetMrn={assetMrn} check={check} />
          );
        })}
      </Grid>
    </Grid>
  );
}
