import { TableCell, Typography } from "@mui/material";
import { AggregateScoresRiskScore } from "../FirewatchPage";

type RiskScoreCell = {
  riskScore: AggregateScoresRiskScore;
};

export const RiskScoreCell = ({ riskScore }: RiskScoreCell) => {
  return (
    <TableCell>
      <Typography fontSize={12}>{riskScore}</Typography>
    </TableCell>
  );
};
