import React, { ComponentType, useState } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { copyToClipboard } from "~/lib/clipboard";
import SvgIcon from "@mui/material/SvgIcon";

type OverviewIconProps = {
  iconName: string;
  Icon: typeof SvgIcon | ComponentType;
};

export const OverviewIcon = ({ iconName, Icon }: OverviewIconProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleClick = async () => {
    await copyToClipboard(`<${iconName} />`);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  };

  return (
    <Tooltip title={isCopied ? "Copied!" : "Click to copy"} placement="top">
      <Box display="flex" flexDirection="column" alignItems="center">
        <IconButton
          aria-label="copy icon name"
          onClick={handleClick}
          size="large"
        >
          <Icon fontSize="large" />
        </IconButton>
        <Typography variant="caption" fontWeight={700} textAlign="center">
          {iconName}
        </Typography>
      </Box>
    </Tooltip>
  );
};
