import { Navigate, useLocation } from "react-router-dom";

export type RedirectProps = {
  from: string;
  to: string;
  state?: unknown;
};

export function Redirect({ from, to, state }: RedirectProps) {
  const { hash, pathname, search } = useLocation();
  const newPathname = pathname.replace(from, to);
  const newUrl = newPathname + search + hash;
  return <Navigate replace to={newUrl} state={state} />;
}
