import { SxProps, Theme, useTheme } from "@mui/material";

export function syntaxStyle(): SxProps<Theme> {
  const theme = useTheme();

  // https://github.com/highlightjs/highlight.js/blob/200c09c30c5b8fc1afe62388024f66019052b73a/src/styles/atom-one-dark.css
  const dark: SxProps<Theme> = {
    ".hljs-comment, .hljs-quote": {
      color: "#5c6370",
      fontStyle: "italic",
    },

    ".hljs-doctag, .hljs-keyword, .hljs-formula": {
      color: "#c678dd",
    },

    ".hljs-section, .hljs-name, .hljs-selector-tag, .hljs-deletion, .hljs-subst":
      {
        color: "#e06c75",
      },

    ".hljs-literal": {
      color: "#56b6c2",
    },

    ".hljs-string, .hljs-regexp, .hljs-addition, .hljs-attribute, .hljs-meta .hljs-string":
      {
        color: "#98c379",
      },

    ".hljs-attr, .hljs-variable, .hljs-template-variable, .hljs-type, .hljs-selector-class, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-number":
      {
        color: "#d19a66",
      },

    ".hljs-symbol, .hljs-bullet, .hljs-link, .hljs-meta, .hljs-selector-id, .hljs-title":
      {
        color: "#61aeee",
      },

    ".hljs-built_in, .hljs-title.class_, .hljs-class .hljs-title": {
      color: "#e6c07b",
    },
  };

  // https://github.com/highlightjs/highlight.js/blob/200c09c30c5b8fc1afe62388024f66019052b73a/src/styles/atom-one-light.css
  const light: SxProps<Theme> = {
    ".hljs-comment, .hljs-quote": {
      color: "#a0a1a7",
      fontStyle: "italic",
    },

    ".hljs-doctag, .hljs-keyword, .hljs-formula": {
      color: "#a626a4",
    },

    ".hljs-section, .hljs-name, .hljs-selector-tag, .hljs-deletion, .hljs-subst":
      {
        color: "#e45649",
      },

    ".hljs-literal": {
      color: "#0184bb",
    },

    ".hljs-string, .hljs-regexp, .hljs-addition, .hljs-attribute, .hljs-meta .hljs-string":
      {
        color: "#50a14f",
      },

    ".hljs-attr, .hljs-variable, .hljs-template-variable, .hljs-type, .hljs-selector-class, .hljs-selector-attr, .hljs-selector-pseudo, .hljs-number":
      {
        color: "#986801",
      },

    ".hljs-symbol, .hljs-bullet, .hljs-link, .hljs-meta, .hljs-selector-id, .hljs-title":
      {
        color: "#4078f2",
      },

    ".hljs-built_in, .hljs-title.class_, .hljs-class .hljs-title": {
      color: "#c18401",
    },
  };

  return {
    "pre code.hljs": {
      display: "block",
      overflowX: "auto",
      padding: "1em",
    },
    "code.hljs": {
      padding: "3px 5px",
    },
    ".hljs": {
      background: (theme) => theme.palette.code.background,
      border: "1px solid",
      borderColor: (theme) => theme.palette.background.lightest,
      borderRadius: (theme) => theme.spacing(0.5),
      color: (theme) => theme.palette.text.primary,
    },
    ".hljs-emphasis": {
      fontStyle: "italic",
    },

    ".hljs-strong": {
      fontWeight: "bold",
    },

    ".hljs-link": {
      textDecoration: "underline",
    },
    ...(theme.palette.mode === "dark" ? dark : light),
  };
}
