import { createSvgIcon } from "@mui/material";

export const SpeechBubbleIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 17"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99981 0.000303662C4.9999 0.000303662 0 2.53818 0 7.61377C0 9.85097 1.22765 11.8449 3.13034 13.2328L0.617642 16.2697C0.492085 16.4216 0.481306 16.6363 0.591326 16.7996C0.701186 16.9629 0.906166 17.0369 1.09736 16.9821L7.56112 15.1225C10.0504 15.5981 13.0724 15.2429 15.5411 14.0389C18.0473 12.8166 20 10.6475 20 7.61346C20 2.53795 15.0001 0 10.0002 0L9.99981 0.000303662ZM9.99981 0.879936C14.5452 0.879936 19.0905 3.12449 19.0905 7.61385C19.0905 10.2755 17.4363 12.1159 15.1376 13.2368C12.8389 14.3579 9.91288 14.6867 7.63126 14.2245H7.63142C7.55866 14.2098 7.48336 14.2128 7.41218 14.2332L2.24588 15.7194L4.14365 13.4258H4.1435C4.22466 13.328 4.25986 13.2007 4.24067 13.0759C4.22133 12.951 4.14936 12.8398 4.04235 12.7701C2.10607 11.5066 0.909337 9.66386 0.909337 7.61383C0.909337 3.12456 5.4547 0.879922 10.0001 0.879922L9.99981 0.879936Z"
    />
  </svg>,
  "Speech Bubble",
);
