import { createSvgIcon } from "@mui/material";

export const RemoteExecutionIcon = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18.9322 5H5.06778C4.65198 5 4.25321 5.16518 3.95919 5.45919C3.66518 5.75321 3.5 6.15198 3.5 6.56778V17.9011C3.50249 18.3153 3.66877 18.7116 3.96252 19.0036C4.25626 19.2956 4.65361 19.4595 5.06778 19.4594H18.9322C19.348 19.4594 19.7468 19.2943 20.0408 19.0003C20.3348 18.7062 20.5 18.3075 20.5 17.8917V6.55833C20.4975 6.14417 20.3312 5.74782 20.0375 5.45585C19.7437 5.16387 19.3464 4.99999 18.9322 5ZM18.9322 18.2222H5.06778C4.9776 18.2222 4.89112 18.1864 4.82736 18.1226C4.7636 18.0589 4.72778 17.9724 4.72778 17.8822V8.68333H19.2722V17.8917C19.2722 17.9818 19.2364 18.0683 19.1726 18.1321C19.1089 18.1958 19.0224 18.2317 18.9322 18.2317V18.2222Z" />
    <path d="M16.8833 15.1152H12.4444C12.3662 15.1152 12.3027 15.1787 12.3027 15.2569V16.2013C12.3027 16.2796 12.3662 16.343 12.4444 16.343H16.8833C16.9615 16.343 17.025 16.2796 17.025 16.2013V15.2569C17.025 15.1787 16.9615 15.1152 16.8833 15.1152Z" />
    <path d="M8.2132 10.3553C8.15612 10.2983 8.08838 10.2531 8.01383 10.2223C7.93929 10.1915 7.85941 10.1757 7.77875 10.1758C7.69795 10.1747 7.61775 10.19 7.54306 10.2208C7.46837 10.2517 7.40074 10.2974 7.34431 10.3553C7.23183 10.47 7.16882 10.6243 7.16882 10.785C7.16882 10.9457 7.23183 11.1 7.34431 11.2147L9.38431 13.2547L7.34431 15.2947C7.23123 15.4111 7.16797 15.5669 7.16797 15.7292C7.16797 15.8914 7.23123 16.0473 7.34431 16.1636C7.46125 16.2762 7.61647 16.3403 7.77875 16.3431C7.94066 16.3385 8.09526 16.2746 8.2132 16.1636L10.6782 13.6892C10.7365 13.6328 10.7828 13.5653 10.8144 13.4907C10.8461 13.416 10.8624 13.3358 10.8624 13.2547C10.8624 13.1737 10.8461 13.0934 10.8144 13.0188C10.7828 12.9442 10.7365 12.8767 10.6782 12.8203L8.2132 10.3553Z" />
  </svg>,
  "Remote Execution",
);
