import { Policies } from "../components/Policies/types";
import { ScoreCard } from "../components/PolicyScoreCard";
import { PolicyAction, ScoreStats } from "~/operations";

type UsePolicyGridProps = {
  policies: Policies["edges"];
};

export function usePolicyGrid({ policies }: UsePolicyGridProps) {
  let scorecards: ScoreCard[] = [];

  const DEFAULT_SCORE_STATS = {
    __typename: "ScoreStats",
    disabled: 0,
    errors: 0,
    failed: 0,
    incomplete: 0,
    passed: 0,
    skipped: 0,
    snoozed: 0,
    total: 0,
    unknown: 0,
    worst: 0,
  };

  policies.forEach((policyEdge) => {
    const { mrn, name, score, scoreStats, action } = policyEdge.node;
    const isPlatVulnPolicy =
      mrn === "//policy.api.mondoo.app/policies/platform-vulnerability";
    // const isPlatEOLPolicy =
    //   mrn === "//policy.api.mondoo.app/policies/platform-eol";
    if (isPlatVulnPolicy) return;
    const unscored = score.type !== 2;
    const tags = unscored ? ["scores not available"] : ["scored"];
    scorecards.push({
      mrn: mrn,
      title: name,
      score,
      unscored,
      tags,
      date: new Date(),
      trend: null,
      assigned: true, // need assignment data
      action: action || PolicyAction.Active,
      scoreStats: scoreStats || (DEFAULT_SCORE_STATS as ScoreStats),
    });
  });

  return {
    scorecards,
  };
}
