import { createContext, ReactNode, useContext, useState } from "react";
import { useTheme } from "@mui/material";
import { CvssMetric, parseCvss } from "../cvss";

export type CvssScore = {
  vector: string;
  value: number;
};

type HoveredMetric = CvssMetric | undefined;
type FocusedMetric = CvssMetric | undefined;

export type CvssRiskContextType = {
  vector: string;
  version: 3 | 2;
  score: number;
  metrics: CvssMetric[];
  focusedMetric: FocusedMetric;
  hoveredMetric: HoveredMetric;
  isFocused: (metric: CvssMetric) => boolean;
  isHovered: (metric: CvssMetric) => boolean;
  setFocusedMetric: (metric: FocusedMetric) => void;
  toggleFocusedMetric: (metric: CvssMetric) => void;
  setHoveredMetric: (metric: HoveredMetric) => void;
  toggleHoveredMetric: (metric: CvssMetric) => void;
  onMetricMouseEnter: (metric: CvssMetric) => void;
  onMetricMouseLeave: (metric: CvssMetric) => void;
  onMetricFocus: (metric: CvssMetric) => void;
  onMetricBlur: (metric: CvssMetric) => void;
};

export const CvssRiskContext = createContext<CvssRiskContextType | undefined>(
  undefined,
);

export type CvssRiskProviderProps = {
  children: ReactNode;
  vector: string;
};

export function CvssRiskProvider({ children, vector }: CvssRiskProviderProps) {
  const theme = useTheme();
  const { metrics, score, version } = parseCvss(vector, theme);

  const [focusedMetric, setFocusedMetric] = useState<FocusedMetric>(undefined);
  const [hoveredMetric, setHoveredMetric] = useState<HoveredMetric>(undefined);

  const onMetricMouseEnter = (metric: CvssMetric) => {
    setFocusedMetric(metric);
  };

  const onMetricMouseLeave = (_metric: CvssMetric) => {
    setFocusedMetric(undefined);
  };

  const onMetricFocus = (metric: CvssMetric) => {
    setFocusedMetric(metric);
  };

  const onMetricBlur = (_metric: CvssMetric) => {
    setFocusedMetric(undefined);
  };

  const isFocused = (metric: CvssMetric) => {
    return focusedMetric?.field.title === metric.field.title;
  };

  const isHovered = (metric: CvssMetric) => {
    return hoveredMetric?.field.title === metric.field.title;
  };

  const toggleFocusedMetric = (metric: CvssMetric) => {
    setFocusedMetric(isFocused(metric) ? undefined : metric);
  };

  const toggleHoveredMetric = (metric: CvssMetric) => {
    setHoveredMetric(isHovered(metric) ? undefined : metric);
  };

  return (
    <CvssRiskContext.Provider
      value={{
        vector,
        version,
        score,
        metrics,
        focusedMetric,
        hoveredMetric,
        isFocused,
        isHovered,
        setFocusedMetric,
        toggleFocusedMetric,
        setHoveredMetric,
        toggleHoveredMetric,
        onMetricMouseEnter,
        onMetricMouseLeave,
        onMetricFocus,
        onMetricBlur,
      }}
    >
      {children}
    </CvssRiskContext.Provider>
  );
}

export function useCvssRisk() {
  const context = useContext(CvssRiskContext);
  if (context === undefined) {
    throw new Error("useCvssRisk must be used within a CvssRiskProvider");
  }
  return context;
}
