import { createSvgIcon } from "@mui/material";

export const LockClosedIcon = createSvgIcon(
  <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3393 6.08977H10.015V4.19973C10.015 1.88392 8.21392 0 5.99995 0C3.78598 0 1.98492 1.88392 1.98492 4.19973V6.08977H1.66056C0.744719 6.08977 0 6.86878 0 7.82671V14.2631C0 15.221 0.744751 16 1.66056 16H10.3394C11.2553 16 12 15.221 12 14.2631V7.82671C12 6.86875 11.2552 6.08977 10.3393 6.08977ZM2.79952 4.19973C2.79952 2.35362 4.23496 0.852207 5.99982 0.852207C7.76468 0.852207 9.20012 2.35369 9.20012 4.19973V6.08977H8.41148V4.19973C8.41148 2.80867 7.32965 1.67717 5.99985 1.67717C4.67006 1.67717 3.58823 2.80876 3.58823 4.19973V6.08977H2.79958L2.79952 4.19973ZM7.59688 6.08977H4.40293V4.19973C4.40293 3.27834 5.11905 2.52927 5.99992 2.52927C6.88078 2.52927 7.59691 3.27834 7.59691 4.19973L7.59688 6.08977ZM11.1852 14.2631C11.1852 14.7507 10.8055 15.1478 10.3393 15.1478H1.66041C1.19422 15.1478 0.814534 14.7507 0.814534 14.2631V7.82671C0.814534 7.33908 1.19422 6.94193 1.66041 6.94193H10.3393C10.8055 6.94193 11.1852 7.33908 11.1852 7.82671V14.2631ZM5.99982 8.51066C5.19401 8.51066 4.53829 9.19653 4.53829 10.0394C4.53829 10.4599 4.70428 10.8583 4.98984 11.1444V12.5242C4.98984 13.1063 5.44267 13.5799 5.99917 13.5799C6.55567 13.5799 7.0085 13.1063 7.0085 12.5242V11.1444C7.29469 10.8583 7.46005 10.4599 7.46005 10.0394C7.46005 9.19655 6.80434 8.51066 5.99852 8.51066H5.99982ZM6.36933 10.5942C6.25994 10.674 6.19507 10.8044 6.19507 10.9434V12.5234C6.19507 12.6338 6.10603 12.727 6.00046 12.727C5.89488 12.727 5.80584 12.6338 5.80584 12.5234V10.9434C5.80584 10.8044 5.74097 10.6733 5.63158 10.5942C5.45795 10.4678 5.35429 10.2596 5.35429 10.0387C5.35429 9.66548 5.6443 9.36212 6.00111 9.36212C6.3579 9.36212 6.64792 9.66547 6.64792 10.0387C6.64792 10.2596 6.54426 10.4671 6.37063 10.5942H6.36933Z"
      fill="currentColor"
    />
  </svg>,
  "LockClosed",
);
