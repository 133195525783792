import { GppBadOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

export function ExploitIcon() {
  return (
    <Tooltip title="Exploitable" placement="top" arrow>
      <GppBadOutlined
        color="warning"
        sx={{ opacity: 0.5, ":hover": { opacity: 1 } }}
      />
    </Tooltip>
  );
}
