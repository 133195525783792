import { useSearchParams } from "react-router-dom";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { AssetSoftware } from "./AssetSoftware";
import { clipboardDetails } from "~/pages/inventory/components/AssetSoftware/utils";

type Props = {
  isCicd?: boolean;
};

export function AssetSoftwareTab({ isCicd = false }: Props) {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { space, asset, isVulnerabilityScanningEnabled } = useAssetOutlet();
  const assetMrn = isCicd ? (searchParams.get("jobId") as string) : asset?.mrn;

  if (!space || !asset || !assetMrn) return <></>;

  return (
    <AssetSoftware
      assetMrn={assetMrn}
      clipboardDetails={clipboardDetails(asset)}
      space={space}
      isVulnerabilityScanningEnabled={isVulnerabilityScanningEnabled}
    />
  );
}
