import React from "react";
import { VulnerabilityPassIcon } from "~/components/icons/mondoo/vulnerability-pass";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { EmptyState } from "~/pages/inventory/components/EmptyState/EmptyState";
import { Space } from "~/lib/types";

type NoDataFoundProps = {
  title: string;
  content: string;
  showButton?: boolean;
  space?: Space;
  assetId?: string;
};

export const NoDataFound = ({
  title,
  content,
  showButton,
  space,
  assetId,
}: NoDataFoundProps) => {
  return (
    <EmptyState
      headline={title}
      icon={
        <VulnerabilityPassIcon sx={{ fontSize: 38, color: "primary.main" }} />
      }
      textContent={content}
      actionButtons={
        showButton && (
          <Button
            variant="outlined"
            component={RouterLink}
            to={`/space/inventory/${assetId}/vulnerabilities?spaceId=${space?.id}`}
            sx={{
              mx: 1,
              borderColor: "secondary.light",
              color: "secondary.light",
            }}
          >
            Check for vulnerabilities
          </Button>
        )
      }
    />
  );
};
