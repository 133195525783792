import { createSvgIcon } from "@mui/material";

export const PodsGradientIcon = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.55 13.0812L20.3427 5L7.41361 13.0812L20.3427 21.1624L33.55 13.0812ZM6.66699 15L19.3618 22.6923V35L6.66699 27.3077V15ZM34.2969 15L21.6021 22.6923V35L34.2969 27.3077V15Z"
      fill="url(#paint0_linear_7191_75159)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7191_75159"
        x1="6.66699"
        y1="5"
        x2="37.1761"
        y2="31.7115"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Pods",
);
