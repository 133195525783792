import { createSvgIcon } from "@mui/material";

export const NullIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g clipPath="url(#clip0_2658_20464)">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM2 10C2 5.58 5.58 2 10 2C11.85 2 13.55 2.63 14.9 3.69L3.69 14.9C2.63 13.55 2 11.85 2 10ZM10 18C8.15 18 6.45 17.37 5.1 16.31L16.31 5.1C17.37 6.45 18 8.15 18 10C18 14.42 14.42 18 10 18Z" />
    </g>
    <defs>
      <clipPath id="clip0_2658_20464">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </svg>,
  "Null",
);
