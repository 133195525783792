import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

export function useFirewatchSearchParams() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedRiskFactor, setSelectedRiskFactor] = useState<string | null>(
    null,
  );
  const riskFactorMrn = searchParams.get("riskFactorMrn") as string;

  useEffect(() => {
    setSelectedRiskFactor(riskFactorMrn);
  }, [searchParams]);

  const handleRiskFilterClick = (riskFactorMrn: string) => {
    const currentRiskFactorMrn = searchParams.get("riskFactorMrn") as string;

    if (currentRiskFactorMrn === riskFactorMrn) {
      searchParams.delete("riskFactorMrn");
    } else {
      searchParams.set("riskFactorMrn", riskFactorMrn);
    }
    setSearchParams(searchParams);
  };

  return {
    selectedRiskFactor,
    risksAnd: riskFactorMrn ? [riskFactorMrn] : [],
    handle: {
      riskFilterClick: handleRiskFilterClick,
    },
  };
}
