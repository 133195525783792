import { useEffect, useState } from "react";
import { AggregateScoreType } from "~/operations";

type useFirewatchPageProps = {
  pageType: AggregateScoreType;
};

export function useFirewatchPage({ pageType }: useFirewatchPageProps) {
  const [maxRiskScore, setMaxRiskScore] = useState<number | null>(null);
  const [findingMrn, setFindingMrn] = useState<string | null>(null);

  useEffect(() => {
    if (pageType === AggregateScoreType.Asset) {
      // Set the max risk score to 99 for the Affected Assets page, this will give us only the assets that are truly affected
      setMaxRiskScore(99);
      setFindingMrn("//security.api.mondoo.app/findings/vulns");
    } else if (pageType === AggregateScoreType.Advisory) {
      setMaxRiskScore(99);
      setFindingMrn(null);
    } else if (pageType === AggregateScoreType.Vulnerability) {
      setMaxRiskScore(99);
      setFindingMrn(null);
    } else {
      setMaxRiskScore(null);
      setFindingMrn(null);
    }
  }, [pageType]);

  return { maxRiskScore, findingMrn };
}
