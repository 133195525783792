import { PolicyAction, PolicyReportScoreEntryAction } from "~/operations";
import { BarChartIcon, LightbulbIcon, MoonIcon } from "~/components/ui-library";

export const getPolicyActionIcon = (
  action: PolicyAction | undefined,
): JSX.Element => {
  switch (action) {
    case PolicyAction.Ignore:
      return <LightbulbIcon />;
    case PolicyAction.Active:
      return <BarChartIcon />;
    default:
      return <MoonIcon />;
  }
};

export const getPolicyReportScoreEntryActionIcon = (
  action: PolicyReportScoreEntryAction,
): JSX.Element => {
  switch (action) {
    case PolicyReportScoreEntryAction.Ignore:
      return <LightbulbIcon />;
    case PolicyReportScoreEntryAction.Deactivate:
      return <BarChartIcon />;
    default:
      return <MoonIcon />;
  }
};
