import { TextField, Typography } from "@mui/material";
import React from "react";

type NotesFieldProps = {
  errorMessage: string | undefined;
  textFieldProps: object;
  disabled: boolean;
};

export const NotesField = ({
  textFieldProps,
  errorMessage,
  disabled,
}: NotesFieldProps) => {
  return (
    <>
      <Typography variant="body1" gutterBottom sx={{ mb: 1, fontWeight: 700 }}>
        Additional comments{" "}
        <Typography component="span" color="text.secondary">
          (optional)
        </Typography>
      </Typography>
      <TextField
        placeholder="Add more information..."
        {...textFieldProps}
        multiline
        error={Boolean(errorMessage)}
        helperText={errorMessage}
        minRows={4}
        disabled={disabled}
        slotProps={{
          input: {
            sx: {
              backgroundColor: "code.background",
              borderRadius: 1,
            },
          },
        }}
      />
    </>
  );
};
