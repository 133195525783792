import { Box, useTheme } from "@mui/material";
import { ImpactRatingOptions } from "~/components/impact/impact";

type ImpactCircleProps = {
  backgroundColor: string;
  borderColor: string;
  options?: ImpactRatingOptions;
};

export const ImpactRatingUnit = ({
  backgroundColor,
  borderColor,
  options,
}: ImpactCircleProps) => {
  const theme = useTheme();
  const size = options?.size || 8;
  const border = options?.border || 1;

  return (
    <Box
      sx={{
        height: size,
        width: size,
        borderRadius: "50%",
        background: backgroundColor || theme.palette.background.paper,
        border: `${border}px solid ${
          borderColor || theme.palette.background.lightest
        }`,
      }}
    />
  );
};
