import { Alert, AlertTitle, Container, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export function NotFoundPage() {
  return (
    <Container sx={{ pt: 3 }}>
      <Alert id="not-found-alert" severity="error" variant="outlined">
        <AlertTitle id="not-found-alert-title">Page Not Found</AlertTitle>
        <Typography id="not-found-alert-message">
          Go to{" "}
          <Link to={"/dashboard"} component={RouterLink}>
            your dashboard
          </Link>{" "}
          or ask for{" "}
          <Link href={"https://mondoo.link/slack"} target="_blank">
            community support
          </Link>
          .
        </Typography>
      </Alert>
    </Container>
  );
}
