import React, { ComponentType } from "react";
import { Box } from "@mui/material";
import { OverviewIcon } from "~/pages/icons/OverviewIcon";
import SvgIcon from "@mui/material/SvgIcon";

type IconsSectionProps = {
  title: string;
  list: {
    [iconName: string]: typeof SvgIcon | ComponentType;
  };
};

export const IconsSection = ({ list, title }: IconsSectionProps) => {
  return (
    <>
      <h2>{title}</h2>
      <Box
        display="grid"
        gap={1}
        gridTemplateColumns="repeat(8, minmax(0, 1fr))"
        sx={{ wordBreak: "break-all" }}
      >
        {Object.entries(list).map(([iconName, Icon]) => (
          <OverviewIcon iconName={iconName} Icon={Icon} key={iconName} />
        ))}
      </Box>
    </>
  );
};
