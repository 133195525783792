import { FormEvent, useState } from "react";
import { Hero } from "./components/background";
import {
  AuthComponent,
  AuthFormInput,
} from "~/unauthenticatedApp/components/auth-component";
import { SubmitHandler } from "react-hook-form";
import { AuthOptions } from "~/login/auth-context";
import { useAuth } from "~/login/provider";
import { MondooLogoHorizontal } from "~/images/icons";
import { Box } from "@mui/material";
import { AuthErrorsComponent } from "./components/auth-errors";

export function LoginPage() {
  const { dispatch, loginWithProvider, loginWithEmailAndPassword } = useAuth();

  const [step, setStep] = useState<"email" | "password">("email");

  function handleLoginWithProvider(event: FormEvent, provider: AuthOptions) {
    loginWithProvider(event, dispatch, provider);
  }

  function handleLoginWithEmailAndPassword(
    data: AuthFormInput,
    event: FormEvent,
  ) {
    const { email, password } = data;
    loginWithEmailAndPassword(event, dispatch, {
      email: email.trim(),
      password: password.trim(),
    });
  }

  const onContinueSubmit: SubmitHandler<AuthFormInput> = async (
    data,
    event,
  ) => {
    if (data.email && data.password) {
      return handleLoginWithEmailAndPassword(data, event as FormEvent);
    }

    if (data.email) {
      setStep("password");
    }
  };

  return (
    <Hero>
      <Box
        sx={{
          width: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 5,
            ".MuiTypography-root": {
              fontFamily: "Sofia Pro",
            },
          }}
        >
          <MondooLogoHorizontal width={180} />
        </Box>
        <AuthComponent
          {...{
            view: "login",
            step,
            setStep,
            onSubmit: onContinueSubmit,
            handleLoginWithProvider,
          }}
        />
      </Box>
      <AuthErrorsComponent />
    </Hero>
  );
}
