import React, { ReactNode, useCallback, useLayoutEffect } from "react";
import {
  List,
  Paper,
  styled,
  Avatar,
  Button,
  ListItem,
  useTheme,
  ClickAwayListener,
} from "@mui/material";
import {
  Flex,
  Text,
  TextCaption,
  ControlResultIcon,
  MoonIcon, // TODO::UILIBRARY - change name to SnoozeIcon
} from "~/components/ui-library";
import { useSearchParams } from "react-router-dom";
import {
  ExceptionGroup,
  ExceptionMutationAction,
  ExceptionReviewAction,
  ReviewStatus,
} from "~/operations";
import { FormatDate } from "~/lib/date";
import { BlockOutlined } from "@mui/icons-material";
import { AccessTimeIcon, VisibilityIcon } from "~/components/icons"; // TODO::UILIBRARY move into ui-library, properly exposing props

export type ExceptionTimelineItemProps = {
  exceptionGroup: ExceptionGroup;
  children?: ReactNode;
  onReviewActionChange?: (
    value: ExceptionGroup,
    action: ExceptionReviewAction,
  ) => void;
  renderTable: (exceptionGroup: ExceptionGroup) => ReactNode;
};

export const ExceptionTimelineItem = ({
  exceptionGroup,
  children,
  onReviewActionChange,
  renderTable,
}: ExceptionTimelineItemProps) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const isSnoozed = exceptionGroup.action === ExceptionMutationAction.Snooze;
  const isDisabled = exceptionGroup.action === ExceptionMutationAction.Disable;
  const isApproved = exceptionGroup.reviewStatus === ReviewStatus.Approved;
  const isRejected = exceptionGroup.reviewStatus === ReviewStatus.Rejected;
  const isReviewed = exceptionGroup.reviewStatus !== ReviewStatus.NotReviewed;

  const avatarSrc = ""; // "https://i.pravatar.cc/300";

  const isFocused = searchParams.get("exceptionId") === exceptionGroup.id;

  useLayoutEffect(() => {
    if (isFocused) {
      document
        .getElementById(exceptionGroup.id)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [isFocused]);

  const handleClickAway = useCallback(() => {
    if (!isFocused) return;
    searchParams.delete("exceptionId");
    setSearchParams(searchParams);
  }, [isFocused]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <List
        id={exceptionGroup.id}
        component={Paper}
        sx={(theme) => ({
          py: 0,
          overflow: "hidden",
          border: "1px solid",
          borderColor: isFocused ? theme.palette.primary.light : "transparent",
          scrollMarginTop: theme.spacing(3),
        })}
      >
        <ListItemContainer>
          <Flex flexDirection="column" flex={1}>
            <Flex justifyContent="space-between">
              <Flex center gap={2}>
                {isSnoozed && <MoonIcon />}
                {isDisabled && <BlockOutlined />}
                <Text bold sx={{ textTransform: "uppercase" }}>
                  {exceptionGroup.title}
                </Text>
              </Flex>
              {isReviewed ? (
                <StatusIcon disabled>
                  <ControlResultIcon
                    size="md"
                    label={
                      isApproved ? "APPROVED" : isRejected ? "REJECTED" : ""
                    }
                    status={isApproved ? "pass" : isRejected ? "fail" : ""}
                    colorOverride={theme.palette.text.primary}
                    disableTooltip
                  />
                </StatusIcon>
              ) : (
                onReviewActionChange && (
                  <Flex gap={3}>
                    <StatusIcon
                      onClick={() => {
                        onReviewActionChange(
                          exceptionGroup,
                          ExceptionReviewAction.Rejected,
                        );
                      }}
                    >
                      <ControlResultIcon
                        size="md"
                        label="REJECT"
                        status="fail"
                        disableTooltip
                      />
                    </StatusIcon>
                    <StatusIcon
                      onClick={() => {
                        onReviewActionChange(
                          exceptionGroup,
                          ExceptionReviewAction.Approved,
                        );
                      }}
                    >
                      <ControlResultIcon
                        size="md"
                        label="APPROVE"
                        status="pass"
                        disableTooltip
                      />
                    </StatusIcon>
                  </Flex>
                )
              )}
            </Flex>
            <Flex justifyContent="space-between" pl={5} pt={1} pb={2}>
              <Flex gap={3}>
                {exceptionGroup.author && (
                  <Flex center gap={1}>
                    <Avatar
                      src={avatarSrc}
                      sx={(theme) => ({
                        width: theme.spacing(2.5),
                        height: theme.spacing(2.5),
                        backgroundColor: "common.white",
                      })}
                    />
                    <TextCaption bold secondary>
                      {exceptionGroup.author.name}
                    </TextCaption>
                  </Flex>
                )}
                <Flex center gap={1}>
                  <AccessTimeIcon fontSize="small" />
                  <TextCaption bold secondary>
                    {FormatDate(exceptionGroup.createdAt)}
                  </TextCaption>
                </Flex>
              </Flex>
              {exceptionGroup.reviewer && (
                <Flex gap={3}>
                  <Flex center gap={1}>
                    <VisibilityIcon fontSize="small" />
                    <TextCaption bold secondary>
                      {exceptionGroup.reviewer.name}
                    </TextCaption>
                  </Flex>
                  <Flex center gap={1}>
                    <AccessTimeIcon fontSize="small" />
                    <TextCaption bold secondary>
                      {FormatDate(exceptionGroup.modifiedAt)}
                    </TextCaption>
                  </Flex>
                </Flex>
              )}
            </Flex>
            <Text secondary mb={2}>
              {exceptionGroup.justification}
            </Text>
            {renderTable(exceptionGroup)}
            {children}
          </Flex>
        </ListItemContainer>
      </List>
    </ClickAwayListener>
  );
};

const ListItemContainer = styled(ListItem)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(3),
}));

const StatusIcon = styled(Button)(({ theme }) => ({
  gap: theme.spacing(1),
}));
