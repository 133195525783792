import { styled } from "@mui/material";
import {
  Box,
  LinearProgress,
  Typography,
  LinearProgressProps,
} from "@mui/material";

export const SummaryProgress = styled(LinearProgress)<{
  width?: number;
  forecolor?: string;
}>`
  width: ${(props) => (props.width ? `${props.width}%` : "100%")};
  height: 8px;
  border-radius: 4px;

  .MuiLinearProgress-bar {
    border-radius: 4px;
  }

  &.MuiLinearProgress-colorPrimary {
    background-color: ${(props) => props.theme.palette.background.default};

    .MuiLinearProgress-barColorPrimary {
      background-color: ${(props) =>
        props.forecolor
          ? props.forecolor
          : props.theme.palette.primary.main}; // foreground
    }
  }
`;

export function LinearProgressWithLabel(
  props: LinearProgressProps & { label: string; boxwidth?: number },
) {
  return (
    <StyledBox display="flex" alignItems="center" width={props.boxwidth}>
      <Box minWidth={20}>
        <LinearLabel variant="body2" color="textSecondary">
          {props.label}
        </LinearLabel>
      </Box>
      <Box width="100%" mr={1}>
        <SummaryProgress variant="determinate" {...props} />
      </Box>
    </StyledBox>
  );
}

const StyledBox = styled(Box)`
  &.MuiBox-root {
    width: ${(props) => (props.width ? `${props.width}%` : "100%")};
  }
`;

const LinearLabel = styled(Typography)`
  text-transform: capitalize;
`;
