import { Chip } from "@mui/material";

const FeaturedFlag = () => {
  return (
    <Chip
      label="FEATURED"
      variant="outlined"
      size="small"
      sx={(theme) => ({
        borderRadius: 1,
        border: "1px solid #FBD153",
        color: "#FBD153",
      })}
    />
  );
};

export default FeaturedFlag;
