import { useRef, useState } from "react";
import { alpha, Box, BoxProps, IconButton, useTheme } from "@mui/material";
import { CloseIcon } from "~/components/icons";
import { CmdEnterIcon } from "~/components/icons";
import { Code } from "~/components/code";
import {
  PropertyQueryMutationAction,
  useEditPropertiesMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { useSnackbar } from "notistack";

export type EditablePropsProps = {
  children: string;
  propMrn: string;
  space: Space;
  onChange?: () => void;
};

export function EditableProps({
  children,
  onChange,
  propMrn,
  space,
}: EditablePropsProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const element = useRef<HTMLDivElement>(null);
  const [propValue, setPropValue] = useState(children);
  const [focus, setFocus] = useState(false);

  const [editProps] = useEditPropertiesMutation({
    variables: {
      input: {
        target: {
          mrn: space.mrn,
        },
        props: [
          {
            propertyMrn: propMrn,
          },
        ],
        action: PropertyQueryMutationAction.Set,
      },
    },
  });

  const handleEditProps = (value: string) => {
    const newMql = element.current?.innerText || "";

    if (newMql && value === "Enter") {
      // make call to check property is valid
      // if the property is valid, it will update.  If it fails validation,
      // it will not be set and the user will receive an error message.
      checkProps(newMql);
      window.getSelection()?.removeAllRanges();
      setFocus(false);
    }

    if (!newMql || value === "Escape") {
      // revert to currently saved prop
      setPropValue(children);
      handleBlur();
    }
  };

  const checkProps = (newMql: string) => {
    editProps({
      variables: {
        input: {
          target: {
            mrn: space.mrn,
          },
          props: [
            {
              propertyMrn: propMrn,
              mql: newMql,
            },
          ],
          action: PropertyQueryMutationAction.Check,
        },
      },
      onCompleted(data) {
        if (
          data.applyPropertyQueryMutation.__typename ===
          "PropertyQueryMutationSuccess"
        ) {
          setProps(newMql);
        } else if (data.applyPropertyQueryMutation.message) {
          enqueueSnackbar(data.applyPropertyQueryMutation.message, {
            variant: "error",
          });
        }
      },
    });
  };

  const setProps = async (newMql: string) => {
    editProps({
      variables: {
        input: {
          target: {
            mrn: space.mrn,
          },
          props: [
            {
              propertyMrn: propMrn,
              mql: newMql,
            },
          ],
          action: PropertyQueryMutationAction.Set,
        },
      },
      onCompleted(_data) {
        enqueueSnackbar("Successfully edited property", { variant: "success" });
        onChange?.();
      },
    });
  };

  const handleClick = (value: string) => {
    handleEditProps(value);
  };

  const handleKeyDown: BoxProps["onKeyDown"] = (e) => {
    if ((e.metaKey && e.key === "Enter") || e.key === "Escape") {
      handleEditProps(e.key);
    }
  };

  const handleFocusIn = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
  };

  return (
    <Box
      ref={element}
      onKeyDown={handleKeyDown}
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          top: (theme) => theme.spacing(0),
          right: (theme) => theme.spacing(2),
          zIndex: 50,
        }}
      >
        <IconButton
          disableRipple
          onClick={() => handleClick("Escape")}
          sx={{ p: 0 }}
        >
          <CloseIcon
            sx={{
              fontSize: 18,
              color: (theme) => alpha(theme.palette.error.main, 0.5),
            }}
          />
        </IconButton>
        <IconButton
          disableRipple
          onClick={() => handleClick("Enter")}
          sx={{ p: 0 }}
        >
          <CmdEnterIcon sx={{ fontSize: 40, ml: 1 }} />
        </IconButton>
      </Box>

      <Code
        contentEditable
        className="javascript"
        codeStyle={{
          padding: theme.spacing(3.5),
        }}
        onFocus={handleFocusIn}
        onBlur={handleBlur}
      >
        {propValue}
      </Code>
    </Box>
  );
}
