import { CSSProperties, Component, FC, ReactNode } from "react";
import {
  CardProps,
  Grid,
  IconButton,
  IconButtonProps,
  styled,
} from "@mui/material";
import {
  SettingsIcon,
  TrendingUpIcon,
  TrendingDownIcon,
  TrendingFlatIcon,
} from "~/components/icons";
import { Label } from "../label";
import { FilterRangesKeys, getMajorScore } from "../../lib/score";
import { Score } from "~/lib/types";
import { ScoreMajor, ScoreCardBar } from "./styles";

// score:
// 80  ..  100   A (100 A+ 95 A 85 A- 80)
// 60  ..   79   B ( 79 B+ 75 B 65 B- 60)
// 30  ..   59   C ( 59 C+ 50 C 40 C- 30)
// 10  ..   29   D ( 29 D+ 25 D 15 D- 10)
//  0  ..   10   F

export type Rating = "U" | "X" | "A" | "B" | "C" | "D" | "F";
export type Status =
  | "critical"
  | "high"
  | "medium"
  | "low"
  | "excellent"
  | "good"
  | "unknown"
  | "error"
  | "none";

export const MajorScore = (score: Score): Rating => {
  if (score.type === 0) return "U";
  if (score.type === 4) return "X";
  if (score.type === 8) return "U";
  if (score.type === 16) return "U";
  if (score.value >= 80) return "A";
  if (score.value >= 60) return "B";
  if (score.value >= 30) return "C";
  if (score.value >= 10) return "D";
  return "F";
};

const rating2statusMap: { [rating in Rating]: Status } = {
  A: "excellent",
  B: "low",
  C: "medium",
  D: "high",
  F: "critical",
  U: "unknown",
  X: "error",
};

export const ScoreStatus = (score: Score): Status => {
  const major = MajorScore(score);
  return rating2statusMap[major];
};

const Card = styled("div")`
  border: 1px solid ${(props) => props.theme.palette.background.light};
  background: ${(props) => props.theme.palette.background.paper};
  border-radius: 3px;

  &:hover {
    box-shadow: 0 0 20px 0 ${(props) => props.theme.palette.background.light};
    cursor: pointer;
  }
`;

const CardContent = styled("div")`
  margin: 25px 25px 40px 35px;
  min-height: 270px;
`;

const CardBottomBox = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${(props) => props.theme.palette.background.light};
  font-size: 0.7em;
  letter-spacing: 0.7px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.text.primary};
  height: 50px;
`;

const StyledScoreMajor = styled(ScoreMajor)`
  font-size: 7em;
  margin-right: 10px;
  display: inline-block;
  line-height: 140px;
  vertical-align: top;
`;

const ScoreDetails = styled("div")`
  color: ${(props) => props.theme.palette.text.primary};
  display: inline-block;
  width: 100%;
  line-height: 140px;
  vertical-align: top;
`;

const ScoreNumberWrapper = styled("div")`
  line-height: 72px;
  vertical-align: bottom;
  margin-bottom: 6px;
`;

const ScoreNumber = styled("div")`
  display: inline-block;
  color: ${(props) => props.theme.palette.text.primary};
  font-size: 2em;
  margin-right: 8px;
  vertical-align: bottom;
  line-height: 1em;
`;

const ScoreFields = styled("div")`
  line-height: 40px;
`;

const CardTitle = styled("div")`
  font-size: 1.3em;
  min-height: 52px;
`;

const CardTag = styled("div")`
  display: inline-block;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.background.light};
  padding: 6px 25px 6px 25px;
  vertical-align: middle;
  border-radius: 10em;
  line-height: 1em;
  font-size: 0.7em;
  margin: 10px 0;
`;

const BottomActions = styled("div")`
  border-left: 1px solid ${(props) => props.theme.palette.background.light};
  overflow: hidden;

  button {
    border-radius: 0;
  }
`;

const BottomInfo = styled("div")`
  padding-left: 20px;
`;

const ScoreTrend = styled("div")`
  display: inline-block;
  border: 1px solid ${(props) => props.theme.palette.background.light};
  color: ${(props) => props.theme.palette.text.primary};
  line-height: 1em;
  padding: 8px;
  border-radius: 15px;
  font-size: 0.65em;
  font-weight: bold;
  vertical-align: bottom;

  &.up {
    border-color: ${(props) => props.theme.palette.good.main};
    color: ${(props) => props.theme.palette.good.main};
  }
  &.down {
    border-color: ${(props) => props.theme.palette.critical.main};
    color: ${(props) => props.theme.palette.critical.main};
  }
`;

const ScoreTrendNumber = styled("div")`
  display: inline-block;
  font-size: 1.3em;
  margin-left: 4px;
  margin-right: 8px;
`;

export type MondooScoreTrendProps = {
  trend: number;
};

export interface MondooScoreTrendState {}

export class MondooScoreTrend extends Component<
  MondooScoreTrendProps,
  MondooScoreTrendState
> {
  trend(number: number): string {
    if (number > 0) return `+${number}`;
    return number.toString();
  }

  trendDirection(number: number): string {
    if (number > 0) return "up";
    if (number < 0) return "down";
    return "same";
  }

  render() {
    const { trend } = this.props;
    let direction = this.trendDirection(trend);
    return (
      <ScoreTrend className={direction}>
        {direction == "up" && <TrendingUpIcon fontSize="inherit" />}
        {direction == "down" && <TrendingDownIcon fontSize="inherit" />}
        {direction == "same" && <TrendingFlatIcon fontSize="inherit" />}
        <ScoreTrendNumber>{this.trend(trend)}</ScoreTrendNumber>
      </ScoreTrend>
    );
  }
}

const ScoreBarHolder = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const ScoreBarBlob = styled("div")`
  width: 24%;
  height: 8px;
  border-radius: 15px;
  background: ${(props) => props.theme.palette.background.lighter};
  &.a {
    background: ${(props) => props.theme.palette.none.main};
  }
  &.b {
    background: ${(props) => props.theme.palette.low.main};
  }
  &.c {
    background: ${(props) => props.theme.palette.medium.main};
  }
  &.d {
    background: ${(props) => props.theme.palette.high.main};
  }
  &.f {
    background: ${(props) => props.theme.palette.critical.main};
  }
`;

const ScoreTriangle = styled("div")`
  display: flex;
  width: calc(100% - 2px);
  height: 10px;
  line-height: 10px;
  margin-top: 5px;
  margin-left: -4px;

  div {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid ${(props) => props.theme.palette.common.black};
  }
`;

export type MondooScorebarProps = {
  major: string;
  scoreValue: number;
};

export interface MondooScorebarState {}

export class MondooScorebar extends Component<
  MondooScorebarProps,
  MondooScorebarState
> {
  render() {
    const { major, scoreValue } = this.props;
    return (
      <>
        <ScoreBarHolder>
          <ScoreBarBlob
            className={`${major == "D" ? "d" : ""} ${major == "F" ? "f" : ""}`}
          />
          <ScoreBarBlob className={`${major == "C" ? "c" : ""}`} />
          <ScoreBarBlob className={`${major == "B" ? "b" : ""}`} />
          <ScoreBarBlob className={`${major == "A" ? "a" : ""}`} />
        </ScoreBarHolder>
        <ScoreTriangle>
          <div style={{ marginLeft: `${scoreValue}%` }} />
        </ScoreTriangle>
      </>
    );
  }
}

const capitalize = (tags: string[]): string[] => {
  return tags.map((x) => x.toUpperCase());
};

export type MondooScorecardProps = {
  mrn: string;
  title: string;
  tags: string[];
  score: Score;
  scoreLabel: string | null;
  trend?: number;
  date: Date;
  unscored: boolean;
  onClick: CardProps["onClick"];
  onConfigClick?: IconButtonProps["onClick"];
};

export interface MondooScorecardState {}

export class MondooScorecard extends Component<
  MondooScorecardProps,
  MondooScorecardState
> {
  render() {
    const {
      title,
      score,
      unscored,
      tags,
      date,
      trend,
      scoreLabel,
      onClick,
      onConfigClick,
    } = this.props;

    var label = scoreLabel;
    if (label == null && !unscored) {
      label = score.value.toString();
    }

    // check if we need to shorten the title
    let cardTitle = title;
    if (cardTitle.length > 70) {
      cardTitle = cardTitle.substr(0, 70) + "...";
    }

    const bottomActions = onConfigClick ? (
      <BottomActions>
        <IconButton
          onClick={onConfigClick}
          aria-label="Configure"
          data-name="mondoo-scorecard-configure-button"
          size="large"
        >
          <SettingsIcon />
        </IconButton>
      </BottomActions>
    ) : null;

    const major = getMajorScore(score);
    return (
      <Card onClick={onClick}>
        <CardContent>
          <Grid container>
            <Grid item xs={3} sm={4}>
              <StyledScoreMajor className={major}>{major}</StyledScoreMajor>
            </Grid>
            <Grid item xs={8}>
              <ScoreDetails>
                <ScoreNumberWrapper>
                  <ScoreNumber>{label}</ScoreNumber>
                  {trend != null && (
                    <MondooScoreTrend trend={trend}></MondooScoreTrend>
                  )}
                </ScoreNumberWrapper>
                <ScoreFields>
                  <MondooScorebar
                    major={major}
                    scoreValue={score.value}
                  ></MondooScorebar>
                </ScoreFields>
              </ScoreDetails>
            </Grid>
          </Grid>
          <CardTitle>{cardTitle}</CardTitle>
        </CardContent>
        <CardBottomBox>
          <BottomInfo>
            {capitalize(tags).map((tag) => {
              return <CardTag key={tag}>{tag}</CardTag>;
            })}
          </BottomInfo>
          {bottomActions}
        </CardBottomBox>
      </Card>
    );
  }
}

const ScoreSelector = styled("div")`
  background-color: ${(props) => props.theme.palette.background.light};
  display: inline-block;
  width: 140px;
  text-align: center;
  border-radius: 20px;
  font-size: 0.65em;
  font-weight: bold;
  padding: 5px;
  color: white;
  margin-right: 10px;
  letter-spacing: 0.8px;

  &:hover {
    box-shadow: 0 0 6px ${(props) => props.theme.palette.background.light};
  }

  &.a.selected {
    background: ${(props) => props.theme.palette.good.main};
    &:hover {
      box-shadow: 0 0 6px ${(props) => props.theme.palette.good.main};
    }
  }
  &.b.selected {
    background: ${(props) => props.theme.palette.low.main};
    &:hover {
      box-shadow: 0 0 6px ${(props) => props.theme.palette.low.main};
    }
  }
  &.c.selected {
    background: ${(props) => props.theme.palette.medium.main};
    &:hover {
      box-shadow: 0 0 6px ${(props) => props.theme.palette.medium.main};
    }
  }
  &.d.selected {
    background: ${(props) => props.theme.palette.high.main};
    &:hover {
      box-shadow: 0 0 6px ${(props) => props.theme.palette.high.main};
    }
  }
  &.f.selected {
    background: ${(props) => props.theme.palette.critical.main};
    &:hover {
      box-shadow: 0 0 6px ${(props) => props.theme.palette.critical.main};
    }
  }
  &.u.selected {
    background: ${(props) => props.theme.palette.unknown.main};
    &:hover {
      box-shadow: 0 0 6px ${(props) => props.theme.palette.unknown.main};
    }
  }
`;

export type MondooScoreFilterProps = {
  onChange: (filter: FilterRangesKeys[]) => void;
};

export interface MondooScoreFilterState {
  a: boolean;
  b: boolean;
  c: boolean;
  d: boolean;
  f: boolean;
  u: boolean;
}

export class MondooScoreFilter extends Component<
  MondooScoreFilterProps,
  MondooScoreFilterState
> {
  state: MondooScoreFilterState = {
    a: true,
    b: true,
    c: true,
    d: true,
    f: true,
    u: false,
  };

  render() {
    const onClickHandler = (major: string) => () => {
      let state = this.state;
      switch (major) {
        case "a":
          state.a = !state.a;
          break;
        case "b":
          state.b = !state.b;
          break;
        case "c":
          state.c = !state.c;
          break;
        case "d":
          state.d = !state.d;
          break;
        case "f":
          state.f = !state.f;
          break;
        case "u":
          state.u = !state.u;
          break;
      }
      this.setState(state);
      if (this.props.onChange != null) {
        let scoreFilter: FilterRangesKeys[] = [];
        if (state.a) {
          scoreFilter.push("a");
        }
        if (state.b) {
          scoreFilter.push("b");
        }
        if (state.c) {
          scoreFilter.push("c");
        }
        if (state.d) {
          scoreFilter.push("d");
        }
        if (state.f) {
          scoreFilter.push("f");
        }
        if (state.u) {
          scoreFilter.push("u");
        }
        this.props.onChange(scoreFilter);
      }
    };

    return (
      <>
        <ScoreSelector
          className={`u ${this.state.u ? "selected" : ""}`}
          onClick={onClickHandler("u")}
        >
          U: unscored
        </ScoreSelector>
        <ScoreSelector
          className={`f ${this.state.f ? "selected" : ""}`}
          onClick={onClickHandler("f")}
        >
          F: 0 - 9
        </ScoreSelector>
        <ScoreSelector
          className={`d ${this.state.d ? "selected" : ""}`}
          onClick={onClickHandler("d")}
        >
          D: 10 - 29
        </ScoreSelector>
        <ScoreSelector
          className={`c ${this.state.c ? "selected" : ""}`}
          onClick={onClickHandler("c")}
        >
          C: 30 - 59
        </ScoreSelector>
        <ScoreSelector
          className={`b ${this.state.b ? "selected" : ""}`}
          onClick={onClickHandler("b")}
        >
          B: 60 - 79
        </ScoreSelector>
        <ScoreSelector
          className={`a ${this.state.a ? "selected" : ""}`}
          onClick={onClickHandler("a")}
        >
          A: 80 - 100
        </ScoreSelector>
      </>
    );
  }
}

export type MondooScoreBoxProps = {
  score: Score;
  trend?: number;
};

const ScoreMajorBox = styled(StyledScoreMajor)`
  font-size: 5em;
  line-height: 0.8em;
`;

const ScoreDetailsBox = styled(ScoreDetails)`
  line-height: reset;
`;

const ScoreNumberWrapperBox = styled(ScoreNumberWrapper)`
  line-height: 30px;
`;

export const MondooScoreBox: FC<MondooScoreBoxProps> = ({ score, trend }) => {
  const major = MajorScore(score);
  return (
    <ScoreBox score={score} style={{ width: "265px" }}>
      <ScoreMajorBox className={major}>{major}</ScoreMajorBox>
      <ScoreDetailsBox>
        <ScoreNumberWrapperBox>
          <ScoreNumber>{score.value}</ScoreNumber>
          {trend != null && <MondooScoreTrend trend={trend}></MondooScoreTrend>}
        </ScoreNumberWrapperBox>
        <ScoreFields>
          <MondooScorebar
            major={major}
            scoreValue={score.value}
          ></MondooScorebar>
        </ScoreFields>
      </ScoreDetailsBox>
    </ScoreBox>
  );
};

type ScoreBoxProps = {
  score: Score;
  children?: ReactNode;
  style?: CSSProperties;
};

const ScoreBox = ({ score, children, style }: ScoreBoxProps) => {
  const major = MajorScore(score);
  const status = rating2statusMap[major];

  return (
    <ScoreCardOuter style={style}>
      <ScoreCardBar status={status} />
      <ScoreCardBody>{children}</ScoreCardBody>
    </ScoreCardOuter>
  );
};

const ScoreCardOuter = styled("div")`
  border-radius: 4px;
  background: linear-gradient(
    331.58deg,
    ${(props) => props.theme.palette.background.paper}; 0%,
    ${(props) => props.theme.palette.background.light}; 100%
  );
  color: white;
  box-shadow: ${(props) => props.theme.shadows[1]};
  overflow: hidden;
  flex: 0 0 auto;
`;

const ScoreCardBody = styled("div")`
  padding: 24px;
  display: flex;
`;

const ScoreLabel = styled(Label)`
  width: 100%;
  max-width: 200px;
  margin: 5px;
`;

const ScoreFieldsTag = styled("div")`
  width: 100%;
  line-height: 40px;
  margin-top: 10px;
`;

export type StatusTagProps = {
  major: string;
  score: Score;
  text: ReactNode;
};

export const StatusTag: FC<StatusTagProps> = ({ major, score, text }) => {
  return (
    <ScoreLabel>
      {text}
      <ScoreFieldsTag>
        <MondooScorebar major={major} scoreValue={score.value}></MondooScorebar>
      </ScoreFieldsTag>
    </ScoreLabel>
  );
};
