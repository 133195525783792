import React from "react";
import { Typography } from "@mui/material";

type SoftwareVersionLabelProps = {
  version: string;
  color: string;
};

function shortenString(input: string, maxLength = 15) {
  if (input.length < maxLength) return input;

  const ellipsis = "...";
  const ellipsisLength = ellipsis.length;
  const prefixLength = Math.floor((maxLength - ellipsisLength) / 2);
  const suffixLength = maxLength - ellipsisLength - prefixLength;

  return `${input.slice(0, prefixLength)}${ellipsis}${input.slice(
    -suffixLength,
  )}`;
}

export const SoftwareVersionLabel = ({
  version,
  color,
}: SoftwareVersionLabelProps) => {
  return (
    <Typography
      textAlign="center"
      fontSize={12}
      sx={{
        alignSelf: "center",
        transform: `rotate(-45deg)`,
        transformOrigin: "top left",
        borderBottom: `2px solid ${color}`,
        display: "flex",
        maxWidth: "100px",
        whiteSpace: "nowrap",
      }}
    >
      {shortenString(version)}
    </Typography>
  );
};
