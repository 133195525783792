import {
  IntegrationType,
  LoadSpaceStatsQuery,
  useLoadSpaceStatsQuery,
} from "~/operations";

export type UseInventoryProps = {
  spaceMrn?: string | null;
};

export type SpaceStats = NonNullable<
  NonNullable<LoadSpaceStatsQuery["space"]>["stats"]
>;
export type AssetGroupStats = SpaceStats["assetGroupStatistics"][0];

export type Inventory = {
  spaceStats: ReturnType<typeof useLoadSpaceStatsQuery>;
  assetGroupStats: AssetGroupStats[];
  hasJiraIntegrationEnabled: boolean;
};

export function useInventory({ spaceMrn }: UseInventoryProps): Inventory {
  const spaceStats = useLoadSpaceStatsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      mrn: spaceMrn || "",
    },
    skip: !spaceMrn,
  });

  // https://www.figma.com/file/ks8K3oC02Li9Xautp2jPeU/4.-Registry%2C-Security%2C-Vulnerabilities?node-id=60%3A22425
  // "Below, there can be up to 5 most important integrations (as discussed in the call:
  // Cloud providers first, so K8s, AWS, GCP – then OS & Containers; possibly based on
  // # of assets in each; not when there’s 0 of a given type of course)"
  const sortByImportant = (a: AssetGroupStats, b: AssetGroupStats) => {
    const cloudGroup = ["k8s", "aws", "gcp", "azure", "vmware", "oci"];
    const osContainerGroup = ["os", "container"];
    const supplyChainGroup = ["terraform"];
    const saasGroup = [
      "ms365",
      "okta",
      "google-workspace",
      "saas_services",
      "gitlab",
      "github",
      "slack",
    ];
    const networkGroup = ["network_devices", "domains_and_hosts"];

    // least important first
    const groupsByImportance = [
      networkGroup,
      saasGroup,
      supplyChainGroup,
      osContainerGroup,
      cloudGroup,
    ];

    const aIdx = groupsByImportance.findIndex((g) => g.includes(a.groupType));
    const bIdx = groupsByImportance.findIndex((g) => g.includes(b.groupType));

    if (aIdx === bIdx) {
      return b.count - a.count;
    }

    return bIdx - aIdx;
  };

  const assetGroupStats =
    spaceStats.data?.space?.stats?.assetGroupStatistics
      .filter((g) => g.count > 0)
      .sort(sortByImportant) || [];

  const hasJiraIntegrationEnabled =
    spaceStats.data?.space?.stats?.integrationsStatistics.some(
      (ai) => ai.total > 0 && ai.type === IntegrationType.TicketSystemJira,
    ) || false;

  return { spaceStats, assetGroupStats, hasJiraIntegrationEnabled };
}
