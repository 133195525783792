import { createSvgIcon } from "@mui/material";

export const GCPColorIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 2385.7 1919.9"
    fill="currentColor"
  >
    <g>
      <path
        fill="#EA4335"
        d="M1513.8,528.7h72.8l207.4-207.4l10.2-88c-385.9-340.6-975-303.9-1315.6,82C393.9,422.5,325.2,550,287.8,688
		c23.1-9.5,48.7-11,72.8-4.4l414.7-68.4c0,0,21.1-34.9,32-32.7c184.5-202.6,495-226.2,708-53.8H1513.8z"
      />
      <path
        fill="#4285F4"
        d="M2089.4,688c-47.7-175.5-145.5-333.3-281.6-454l-291,291c122.9,100.4,192.9,251.7,189.9,410.4v51.7
		c143.1,0,259,116,259,259c0,143.1-116,259-259,259h-518.1l-51.7,52.4v310.7l51.7,51.7h518.1c297,2.3,560.5-190.2,648.7-473.8
		C2443.4,1162.4,2335.4,854.4,2089.4,688L2089.4,688z"
      />
      <path
        fill="#34A853"
        d="M669.8,1917h518.1v-414.7H669.8c-36.9,0-73.4-7.9-107-23.3l-72.8,22.5l-208.8,207.4l-18.2,72.8
		C380.1,1870.1,523,1917.6,669.8,1917L669.8,1917z"
      />
      <path
        fill="#FBBC05"
        d="M669.8,571.6c-287.8,1.7-542.7,186-634.5,458.7c-91.8,272.7-0.3,573.7,227.8,749.1l300.5-300.5
		c-130.4-58.9-188.3-212.3-129.4-342.7c58.9-130.4,212.3-188.3,342.7-129.4c57.4,26,103.4,72,129.4,129.4l300.5-300.5
		C1078.9,668.6,880.2,570.9,669.8,571.6L669.8,571.6z"
      />
    </g>
  </svg>,
  "GCP",
);
