import { Box } from "@mui/material";

export function SaturnLight({}) {
  return (
    <Box
      component="svg"
      width="100%"
      height="auto"
      viewBox="0 0 266 172"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M148.453 144.997C180.834 136.321 200.044 103.027 191.365 70.6378C182.687 38.2482 149.404 19.0204 117.023 27.6968C84.6421 36.3732 65.4323 69.6664 74.1111 102.056C82.7898 134.446 116.073 153.673 148.453 144.997Z"
        fill="url(#paint0_radial_3982_14227)"
        stroke="#E1DEEA"
        strokeWidth="10"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.252 57.5434C183.845 58.6187 184.405 59.7219 184.934 60.853C201.304 59.093 212.065 59.9466 213.104 63.8219C215.089 71.2326 180.725 86.882 136.348 98.773C91.97 110.664 54.3876 114.293 52.4019 106.882C51.3642 103.009 60.2437 96.8929 75.2869 90.2357C75.1786 89.0007 75.1164 87.765 75.0887 86.5286C50.3827 96.2493 34.9551 105.616 36.4382 111.151C38.8187 120.035 83.8686 115.681 137.051 101.431C190.233 87.1805 231.425 68.4262 229.044 59.542C227.577 53.9999 209.522 53.6052 183.252 57.5434Z"
        fill="#F39191"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.824 53.5014C181.42 54.3976 181.987 55.3158 182.531 56.2487C212.956 51.339 234.168 51.498 235.836 57.7212C238.383 67.2288 194.294 87.3063 137.359 102.562C80.4244 117.818 32.2058 122.474 29.6583 112.966C27.9951 106.759 46.2831 96.0046 75.0694 85.046C75.0746 83.9648 75.1096 82.8871 75.1745 81.813C37.2136 95.4556 12.3451 109.307 14.4197 117.049C17.344 127.963 72.6926 122.617 138.041 105.107C203.389 87.5968 253.996 64.5524 251.072 53.6388C248.999 45.8928 220.537 46.3341 180.824 53.5014Z"
        fill="#CB72C1"
      />
      <defs>
        <radialGradient
          id="paint0_radial_3982_14227"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(80.1971 59.9161) rotate(-15.0463) scale(104.383 104.428)"
        >
          <stop offset="0.01" stopColor="#FDFDE9" />
          <stop offset="0.97" stopColor="#B3A4D2" />
        </radialGradient>
      </defs>
    </Box>
  );
}
