import { createSvgIcon } from "@mui/material";

export const RegularExpressionIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M5.08076 17.452L4.3369 18.2239C1.61405 15.9923 0 13.8168 0 9.98521C0 6.15358 1.61405 3.96408 4.3369 1.73247L5.08076 2.50441C3.91277 3.35962 2.97379 4.48976 2.34704 5.79467C1.7203 7.09958 1.42514 8.53895 1.48774 9.98521C1.42624 11.4293 1.72198 12.8663 2.34873 14.1688C2.97548 15.4712 3.91388 16.599 5.08076 17.452Z" />
    <path d="M7.41177 13.101C7.61833 13.0809 7.82682 13.1042 8.02384 13.1695C8.22086 13.2347 8.40205 13.3405 8.55576 13.4799C8.70947 13.6194 8.8323 13.7895 8.91635 13.9792C9.00041 14.169 9.04383 14.3742 9.04383 14.5818C9.04383 14.7893 9.00041 14.9946 8.91635 15.1843C8.8323 15.3741 8.70947 15.5441 8.55576 15.6836C8.40205 15.8231 8.22086 15.9288 8.02384 15.9941C7.82682 16.0593 7.61833 16.0826 7.41177 16.0625C7.04392 16.0266 6.7026 15.8551 6.45426 15.5814C6.20592 15.3077 6.06836 14.9513 6.06836 14.5818C6.06836 14.2122 6.20592 13.8558 6.45426 13.5821C6.7026 13.3084 7.04392 13.1369 7.41177 13.101Z" />
    <path d="M12.3367 3.90794L12.2384 6.82727L15.0455 5.85884L15.4104 6.9957L12.6314 7.80974L14.3437 10.1536L13.3753 10.8554L11.7191 8.42729L10.063 10.8273L9.10856 10.1536L10.891 7.80974L8.08398 6.9957L8.42083 5.85884L11.2279 6.82727L11.1156 3.90794H12.3367Z" />
    <path d="M14.9053 2.50441L15.6491 1.73247C18.386 3.96408 20.0001 6.13955 20.0001 9.98521C20.0001 13.8309 18.386 15.9923 15.6491 18.2239L14.9053 17.452C16.0756 16.6014 17.0175 15.4745 17.6468 14.1717C18.2762 12.8689 18.5735 11.4307 18.5123 9.98521C18.5758 8.53735 18.2796 7.09631 17.6502 5.79089C17.0208 4.48547 16.0777 3.35634 14.9053 2.50441Z" />
  </svg>,
  "RegularExpression",
);
