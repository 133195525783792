import { createSvgIcon } from "@mui/material";

export const ExploitableIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.21875 14.4255C5.21875 12.0508 5.2226 8.94231 5.22453 7.50222C5.22582 6.77092 5.64068 6.10265 6.29544 5.77719L11.4776 3.20188C12.0217 2.9311 12.661 2.93304 13.2033 3.20575L18.3127 5.77657C18.9643 6.10395 19.3753 6.77093 19.3753 7.5003V14.4255C19.3753 15.8784 18.2658 17.2619 16.861 18.3515C14.9392 19.8417 12.5376 20.8104 12.5376 20.8104C12.3832 20.8728 12.2108 20.8728 12.0565 20.8104C12.0565 20.8104 9.6548 19.8417 7.73296 18.3515C6.32824 17.2619 5.21875 15.8784 5.21875 14.4255ZM6.50512 14.4255C6.50512 15.5285 7.45446 16.5081 8.5215 17.3352C9.95323 18.4454 11.6725 19.2436 12.2976 19.5156C12.9215 19.2436 14.6408 18.4454 16.0725 17.3352C17.1395 16.5081 18.0889 15.5285 18.0889 14.4255V7.5003C18.0889 7.25717 17.9519 7.03462 17.7345 6.92528L12.6257 4.35511C12.4443 4.26378 12.2314 4.26379 12.05 4.35384L6.86787 6.92915C6.64983 7.03785 6.51154 7.2604 6.5109 7.50416C6.50897 8.94361 6.50512 12.0515 6.50512 14.4255ZM12.1832 12.8091L10.9328 14.0601C10.6813 14.311 10.2742 14.311 10.0233 14.0601C9.77186 13.8093 9.77186 13.4015 10.0233 13.1507L11.2737 11.8997L10.1365 10.7625C9.8857 10.5117 9.8857 10.1045 10.1365 9.85306C10.388 9.60222 10.7952 9.60222 11.0466 9.85306L12.1832 10.9902L13.4342 9.73922C13.685 9.48838 14.0928 9.48838 14.3436 9.73922C14.5945 9.9907 14.5945 10.3978 14.3436 10.6493L13.0933 11.8997L14.4575 13.2645C14.7083 13.5154 14.7083 13.9225 14.4575 14.174C14.2066 14.4248 13.7988 14.4248 13.548 14.174L12.1832 12.8091Z"
      fill="currentColor"
    />
  </svg>,
  "Exploitable",
);
