import { ReactElement } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Score2CvssScore } from "~/lib/score";
import { CheckResult } from "./types";
import { getImpactLabel } from "./utils";

type CheckImpactTooltipProps = {
  children: ReactElement;
  result?: CheckResult;
  color: string;
  impact: number | undefined;
  isCVSS?: boolean;
  isRiskScore?: boolean;
};

export const ImpactTooltip = ({
  children,
  result,
  color,
  impact,
  isCVSS = false,
  isRiskScore = false,
}: CheckImpactTooltipProps) => {
  return (
    <Tooltip
      title={
        <Box>
          {result && (
            <Typography fontSize={10} fontWeight="700">
              RESULT:{" "}
              <Typography
                variant="caption"
                fontSize={10}
                color={color}
                fontWeight="700"
              >
                {result.toUpperCase()}
              </Typography>
            </Typography>
          )}
          <Typography fontSize={10} fontWeight="700">
            {isCVSS ? "CVSS SCORE" : "IMPACT"}:{" "}
            <Typography
              variant="caption"
              fontSize={10}
              color={color}
              fontWeight="700"
            >
              {getImpactLabel({ impact, isRiskScore })} (
              {isCVSS ? Score2CvssScore(impact) : impact})
            </Typography>
          </Typography>
        </Box>
      }
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "none",
            p: 1,
          },
        },
      }}
      placement="top"
      arrow
    >
      {children}
    </Tooltip>
  );
};
