import { createSvgIcon } from "@mui/material";

export const TerraformAuthIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9883 10.4006L29.469 17.0308V30.2911L17.9883 23.6609V10.4006Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.7266 17.0308V30.2911L42.2127 23.6609V10.4006L30.7266 17.0308Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.25 3V16.2603L16.7307 22.8905V9.63015L5.25 3Z"
      fill="#AAAAAA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9883 38.3699L29.469 45V31.832V31.7451L17.9883 25.115V38.3699Z"
      fill="#AAAAAA"
    />
  </svg>,
  "Terraform",
);
