import { createSvgIcon } from "@mui/material";

export const ContainersIcon = createSvgIcon(
  <svg viewBox="0 0 30 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.9833 27.9002L2.7 18.3502L0 20.4502L15 32.1168L30 20.4502L27.2833 18.3335L14.9833 27.9002ZM15 23.6668L27.2667 14.1168L30 12.0002L15 0.333496L0 12.0002L2.71667 14.1168L15 23.6668Z"
      fill="url(#paint0_linear_7091_4459)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7091_4459"
        x1="0"
        y1="0.333496"
        x2="32.4182"
        y2="29.4219"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E45E2" />
        <stop offset="1" stopColor="#A22E95" />
      </linearGradient>
    </defs>
  </svg>,
  "Containers",
);
