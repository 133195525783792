import { createSvgIcon } from "@mui/material";

export const JiraIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0041 0C9.6541 2.395 9.6391 6.185 12.1371 8.585L15.5491 11.998L12.3521 15.196C13.2528 16.1011 13.8671 17.2514 14.1182 18.5034C14.3693 19.7554 14.2461 21.0536 13.7641 22.236L23.3301 12.67C23.4184 12.5818 23.4884 12.477 23.5362 12.3617C23.584 12.2464 23.6086 12.1228 23.6086 11.998C23.6086 11.8732 23.584 11.7496 23.5362 11.6343C23.4884 11.519 23.4184 11.4142 23.3301 11.326L12.0041 0ZM10.2561 1.74L0.670099 11.327C0.581809 11.4152 0.511769 11.52 0.463983 11.6353C0.416197 11.7506 0.391602 11.8742 0.391602 11.999C0.391602 12.1238 0.416197 12.2474 0.463983 12.3627C0.511769 12.478 0.581809 12.5828 0.670099 12.671C4.4501 16.44 8.2201 20.244 12.0001 24C14.2951 21.702 14.3951 17.904 11.9201 15.467L8.4501 11.998L11.6501 8.798C9.7321 6.843 9.2871 4.073 10.2561 1.741V1.74Z"
      fill="currentColor"
    />
  </svg>,

  "Jira",
);
