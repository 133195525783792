import { ReactNode } from "react";
import { Grid, Paper, SxProps } from "@mui/material";
import Text from "~/components/ui-library/components/typography/Text";
import TextCaption from "~/components/ui-library/components/typography/TextCaption";
import { Obj } from "~/components/ui-library/types";

export type RegistriesByCategoryProps = {
  categoryOptions: { name: string; text: string; icon: ReactNode }[];
  categories: string[];
  onFacetsChange: (value: Partial<Obj>) => void;
  sx?: SxProps;
};

export const RegistriesByCategory = ({
  categoryOptions,
  categories,
  onFacetsChange,
  sx = {},
}: RegistriesByCategoryProps) => {
  return (
    <Grid container spacing={3}>
      {categoryOptions.map(({ name, text, icon }) => (
        <Grid key={name} item xs={12} sm={6} md={4} sx={sx}>
          <Paper
            sx={{
              p: 2,
              display: "block",
              cursor: "pointer",
              ...(categories.includes(name)
                ? { background: "rgba(145, 71, 255, 0.2)" }
                : { opacity: categories.length > 0 ? 0.4 : 1 }),
            }}
            onClick={() => {
              onFacetsChange({
                categories: [name],
              });
            }}
          >
            <Grid container>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  mr: 2,
                }}
              >
                {icon}
              </Grid>
              <Grid
                item
                xs
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Text bold textTransform="capitalize">
                  {name.replace(/-/g, " ")}
                </Text>
                <TextCaption secondary textTransform="uppercase">
                  {text}
                </TextCaption>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
