import { useTheme } from "@mui/material";
import { useGetImpactColor } from "./use-get-impact-color";
import { CheckResult } from "./types";

type UseImpactResult = {
  score: {
    type: number;
    value: number;
  };
  isActive: boolean;
  impact: number;
};

export function useCheckImpactResult({
  score,
  isActive,
  impact,
}: UseImpactResult) {
  const theme = useTheme();
  const color = useGetImpactColor({ impact });

  function getResultFromScore(): CheckResult {
    // SKIP
    if (score.type == 8 || score.type == 16) {
      return "skip";
    }
    // FAIL
    if (score.type == 2 && score.value != 100) {
      return "fail";
      // PASS
    } else if (score.type == 2 && score.value == 100) {
      return "pass";
      // ERROR
    } else if (score.type == 4) {
      return "error";
    }

    return "skip";
  }

  // The check was skipped in the process
  const isCheckResultSkipped = getResultFromScore() === "skip";

  const isInactive = isCheckResultSkipped || !isActive;

  function adjustCheckResultColor(color: string) {
    // If the check is ignored or disabled
    if (isInactive) {
      return theme.palette.unknown.main;
    }

    if (score) {
      // Check Error
      if (score.type === 4) {
        return theme.palette.error.main;
      }

      // Check Passed
      if (score.value === 100) {
        return theme.palette.none.main;
      }
    }

    return color;
  }

  return {
    result: getResultFromScore(),
    color: adjustCheckResultColor(color),
  };
}
