import { styled } from "@mui/material/styles";

// ToDo: atoms is the right folder ? could be Styles/Layouts folder ?
const ContentBody = styled("div")`
  padding: ${(props) => props.theme.spacing(1)};
  box-sizing: border-box;

  @media print {
    padding: ${(props) => props.theme.spacing(1)} 0;
  }
`;

export default ContentBody;
