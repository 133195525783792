import { FC, Fragment, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import makeCancellable from "make-cancellable-promise";
import {
  Typography,
  Chip,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Badge,
  Popover,
  IconButtonProps,
  ListItemButton,
} from "@mui/material";
import { ContentBody } from "./content-body";
import { NotificationsIcon } from "./icons";
import { releasesHref } from "../lib/releases-href";
import { FormatRelativeDate } from "~/lib/date";
import { Config } from "~/configuration_provider";
import { NavbarTooltip } from "./navbar-tooltip";

const ChangeLogChip = styled(Chip)`
  padding: 0 6px;
  margin-left: ${(props) => props.theme.spacing(1)};
`;

const Headline = styled("div")`
  text-align: center;
  padding: ${(props) => props.theme.spacing(1)};
`;

async function loadChangeLog(): Promise<ChangeLogEntry[]> {
  const path = Config.VITE_CHANGELOG_PATH;
  const maxEntries = 5;

  if (!path || typeof path !== "string") {
    throw new Error("Missing ENV var: CHANGELOG_PATH is not set to URL string");
  }

  return fetch(releasesHref(path))
    .then((resp: Response) => resp.text())
    .then((xmlText) => new DOMParser().parseFromString(xmlText, "text/xml"))
    .then((xmlDoc: XMLDocument) => {
      const entries: ChangeLogEntry[] = Array.from(
        xmlDoc.querySelectorAll("item"),
      ).map((item) => {
        return {
          title: item.querySelector("title")?.textContent || "",
          link: item.querySelector("link")?.textContent || "",
          guid: item.querySelector("guid")?.textContent || "",
          pubDate: item.querySelector("pubDate")?.textContent || "",
          description: item.querySelector("description")?.textContent || "",
          isNew: entryIsNew(item.querySelector("pubDate")?.textContent || ""),
        };
      });
      return entries.slice(0, maxEntries);
    });
}

interface ChangeLogEntry {
  title: string;
  link: string;
  guid: string;
  pubDate: string;
  description: string;
  isNew: boolean;
}

const getChangeLogLastRead = () => {
  const lastReadString = localStorage.getItem("changeLogLastRead") || "";
  return Date.parse(lastReadString) ? new Date(lastReadString) : new Date(0);
};

const setChangeLogLastRead = () => {
  localStorage.setItem("changeLogLastRead", new Date().toISOString());
};

const lastRead = getChangeLogLastRead();

const entryIsNew = (pubDate: string): boolean => {
  const pubDateVal = new Date(pubDate).valueOf();
  return pubDateVal > lastRead.valueOf();
};

interface ChangeLogProps {
  entries: ChangeLogEntry[];
  isLoading: boolean;
}

const ChangeLog: FC<ChangeLogProps> = ({ entries, isLoading }) => {
  const hasEntries = entries.length > 0;

  return (
    <ContentBody style={{ padding: "0" }}>
      <Headline>
        <Typography variant="h6">Latest Changes</Typography>
      </Headline>
      <Divider />
      <List id="changelog-list" disablePadding>
        {!isLoading &&
          hasEntries &&
          entries.map((entry) => (
            <ListItemButton
              key={entry.guid}
              className="changelog-item"
              divider
              alignItems="flex-start"
              component="a"
              href={releasesHref(entry.link)}
              target="_blank"
            >
              <ListItemText
                primary={
                  <Fragment>
                    <Typography
                      component="span"
                      variant="subtitle2"
                      className="changelog-item-title"
                    >
                      {entry.title}
                    </Typography>{" "}
                    <Typography
                      component="span"
                      variant="body2"
                      title={entry.pubDate}
                    >
                      ({FormatRelativeDate(entry.pubDate)})
                    </Typography>
                  </Fragment>
                }
                secondary={entry.description}
              />
              {entry.isNew ? (
                <ListItemIcon style={{ justifyContent: "flex-end" }}>
                  <ChangeLogChip size="small" color="primary" label={"New"} />
                </ListItemIcon>
              ) : null}
            </ListItemButton>
          ))}
        {!isLoading && !hasEntries && (
          <ListItem>
            <ListItemText primary="Changelog is currently unavailable" />
          </ListItem>
        )}
      </List>
    </ContentBody>
  );
};

export const ChangeLogButton: FC = () => {
  const [logIsOpen, setLogIsOpen] = useState(false);
  const [logIsLoading, setLogIsLoading] = useState(true);
  const [logEntries, setLogEntries] = useState<ChangeLogEntry[]>([]);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  useEffect(() => {
    const { promise, cancel } = makeCancellable(loadChangeLog());
    promise
      .then((changelog: ChangeLogEntry[]) => setLogEntries(changelog))
      .catch(() => setLogEntries([]))
      .finally(() => setLogIsLoading(false));

    return () => {
      cancel();
    };
  }, []);

  const handleButtonClick: IconButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
    setLogIsOpen(!logIsOpen);
  };

  const onClose = () => {
    setLogIsOpen(false);
    setChangeLogLastRead();
    setLogEntries(logEntries.map((entry) => ({ ...entry, isNew: false })));
  };

  const newEntries = logEntries.filter((entry) => entry.isNew);

  return (
    <Fragment>
      <NavbarTooltip title="Mondoo Updates">
        <IconButton
          id="changelog-button"
          onClick={handleButtonClick}
          data-name="nav-changelog-button"
          size="large"
          color="inherit"
        >
          <Badge color="error" badgeContent={newEntries.length}>
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </NavbarTooltip>
      <Popover
        open={logIsOpen}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div style={{ maxWidth: 400, maxHeight: 600 }}>
          <ChangeLog entries={logEntries} isLoading={logIsLoading}></ChangeLog>
        </div>
      </Popover>
    </Fragment>
  );
};
