import { copyToClipboard } from "~/lib/clipboard";

export type UseCopyTableToClipboardProps = {
  tableData: {
    details: string[] | null;
    columns: string[];
    rows: (string | number)[][];
  };
};

export function useCopyTableToClipboard({
  tableData,
}: UseCopyTableToClipboardProps) {
  const handleCopyTableToClipboard = () => {
    // generate a markdown-compatible table
    const header = tableData.columns;
    const columnWidth = header.map((h: string) => h.length);
    let table: string[][] = [];

    tableData.rows.map((item) => {
      const entry = item.map((e) => `${e}`);

      for (let i = 0, len = columnWidth.length; i < len; i++) {
        columnWidth[i] =
          columnWidth[i] > entry[i].length ? columnWidth[i] : entry[i].length;
      }

      table.push(entry);
    });

    table = [header, columnWidth.map((c) => "".padEnd(c, "-"))].concat(table);

    let markdownTable = "";
    table.forEach((value) => {
      const rows = value.map((v: string, i: number) =>
        v.padEnd(columnWidth[i], " "),
      );
      markdownTable += `| ${rows.join(" | ")} |\n`;
    });

    let text = "";
    text += tableData.details ? tableData.details.join("\n") : "";
    text += "\n\n";
    text += markdownTable;

    copyToClipboard(text);
  };
  return { handleCopyTableToClipboard };
}
