import { createSvgIcon } from "@mui/material";

export const ExportsIcon = createSvgIcon(
  <svg viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.2353 0C0.55285 0.000344647 0 0.553191 0 1.23529V40.7647C0 41.4472 0.55285 42 1.2353 42H30.8825C31.5646 42 32.1175 41.4472 32.1178 40.7647V25.9412C32.1223 25.6106 31.9944 25.2918 31.7621 25.0564C31.5298 24.821 31.213 24.6883 30.8825 24.6883C30.5516 24.6883 30.2349 24.821 30.0026 25.0564C29.7706 25.2918 29.6424 25.6107 29.6472 25.9412V39.5294H2.4706V2.47059H19.7648V11.1176C19.7648 11.8001 20.3177 12.3529 21.0001 12.3529H29.6472V16.0588C29.6424 16.3897 29.7706 16.7082 30.0026 16.9439C30.2349 17.1793 30.5516 17.3117 30.8825 17.3117C31.213 17.3117 31.5298 17.1793 31.7621 16.9439C31.9944 16.7082 32.1223 16.3897 32.1178 16.0588V11.1176C32.1175 10.7902 31.9875 10.4759 31.7559 10.2443L21.8735 0.361906C21.6419 0.130288 21.3275 0.000344118 21.0001 0H1.2353ZM22.2354 4.21738L27.8993 9.88235H22.2354V4.21738ZM35.8113 14.8118L35.811 14.8121C35.3081 14.8121 34.8556 15.1172 34.6667 15.5828C34.4775 16.0488 34.5898 16.5827 34.9503 16.9333L37.7818 19.7647H25.9415C24.2708 19.7409 24.2708 22.2591 25.9415 22.2353H37.7836L34.9501 25.0678C34.7181 25.2995 34.5878 25.6138 34.5878 25.9416C34.5878 26.2694 34.7178 26.5833 34.9494 26.8153C35.4319 27.2979 36.214 27.2982 36.6969 26.8157L41.6381 21.8745C41.8697 21.6429 42 21.3285 42 21.0007C42 20.673 41.8697 20.3586 41.6381 20.1267L36.6969 15.1855C36.4639 14.9466 36.1444 14.8119 35.8107 14.8122L35.8113 14.8118Z"
      fill="url(#paint0_linear_14140_142081)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_14140_142081"
        x1="0"
        y1="0"
        x2="43.0355"
        y2="40.9107"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Exports",
);
