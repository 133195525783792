import { ReactNode, useState } from "react";
import {
  Box,
  DialogContent,
  Link,
  Grid,
  DialogActions,
  Button,
  SxProps,
  Theme,
} from "@mui/material";
import {
  Command,
  DialogContainer,
  TitleArea,
  TitleAreaProps,
} from "../components";
import { AwsButton } from "~/components/guides/components";
import { ChevronRightIcon } from "~/components/icons";
import awsLogo from "./media/aws-64.svg";
import azureLogo from "./media/azure-64.svg";
import googleCloudLogo from "./media/google-cloud-64.svg";
import kubernetesLogo from "./media/kubernetes-64.svg";

type Option = {
  text: string;
  icon: ReactNode;
  sx?: SxProps<Theme>;
  disabled?: boolean;
  className?: string;
};

// update types
type Props = {
  onClose: TitleAreaProps["onClose"];
  nav: {
    next: () => void;
    prev: () => void;
  };
};

export const StepTwo = ({ onClose, nav }: Props) => {
  const [selected, setSelected] = useState<string | null>("AWS");

  const handleSelection = (_selection: string) => {
    // we're only allowing the selection of amazon at this time.
    // setSelected(selection === selected ? null : selection);
    setSelected("AWS");
  };

  return (
    <DialogContainer>
      <TitleArea title="mondoo setup assistant" onClose={onClose} />

      <DialogContent sx={{ p: 0, mt: 4 }}>
        <Box
          sx={{
            pb: {
              xs: 12,
              md: 9,
            },
          }}
        >
          <Command
            number={1}
            tooltip={
              <Link
                href="https://mondoo.com/docs/cnspec/cnspec-aws/cnspec-aws-cloudshell/"
                target="_blank"
                rel="noopener"
              >
                Mondoo Docs - Assess AWS Security from AWS CloudShell
              </Link>
            }
          >
            Choose your Cloud Service
          </Command>
        </Box>

        <Box>
          <Grid container>
            {setupOptions.map((option: Option, index: number) => (
              <Grid item xs={3} key={option.text} sx={{ position: "relative" }}>
                {/* Loop the buttons */}
                <AwsButton
                  selected={Boolean(selected === option.text)}
                  className={option.className}
                  text={option.text}
                  icon={option.icon}
                  disabled={option.disabled}
                  onClick={() => handleSelection(option.text)}
                  index={index}
                  sx={option.sx}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          p: 0,
          pt: 0,
        }}
      >
        <Button
          onClick={nav.next}
          disabled={!Boolean(selected)}
          variant="contained"
          color="secondary"
          endIcon={<ChevronRightIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Next step
        </Button>
      </DialogActions>
    </DialogContainer>
  );
};

const LogoIcon = ({ logo }: { logo: string }) => {
  return (
    <Box
      sx={{
        pt: {
          xs: 1,
          md: "unset",
        },
        pb: 2,
      }}
    >
      <Box
        component="img"
        src={logo}
        sx={{ height: { xs: 48, sm: 56, md: 64 }, width: "auto" }}
      />
    </Box>
  );
};

const setupOptions: Option[] = [
  {
    text: "AWS",
    icon: <LogoIcon logo={awsLogo} />,
    sx: { borderBottomLeftRadius: 16, borderTopLeftRadius: 16 },
    className: "aws",
  },
  {
    text: "Azure",
    icon: <LogoIcon logo={azureLogo} />,
    className: "no-select",
  },
  {
    text: "Google Cloud",
    icon: <LogoIcon logo={googleCloudLogo} />,
    className: "no-select",
  },
  {
    text: "Kubernetes",
    icon: <LogoIcon logo={kubernetesLogo} />,
    sx: { borderBottomRightRadius: 16, borderTopRightRadius: 16 },
    className: "no-select",
  },
];
