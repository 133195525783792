import { Box } from "@mui/material";
import { DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { ExplorerIcon } from "~/components/icons/mondoo/explorer";
import { Space } from "~/lib/types";
import { Metrics, useMetrics } from "~/hooks";

type Props = {
  space: Space;
  sizes?: CardSizes;
};

export function AssetOverviewCard({ space, sizes }: Props) {
  const { assetsGrades, isLoading } = useMetrics({
    entityMrn: space.mrn,
    metricMrns: [Metrics.SecurityAssetsStats],
  });

  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "Asset Overview",
        icon: <ExplorerIcon fontSize="small" />,
      }}
      PaperProps={{ height: 264 }}
      data-testid="asset-overview-card"
    >
      <Box sx={{ mt: 2 }}>
        <DonutChart data={assetsGrades} dataType="assets" />
      </Box>
    </HubCard>
  );
}
