import { useGetTicketContextQuery } from "~/operations";

type UseJiraTicketContextParams = {
  scopeMrn: string;
  skip?: boolean;
};

export function useEmailTicketContext({
  scopeMrn,
  skip = false,
}: UseJiraTicketContextParams) {
  const { data: ticketData } = useGetTicketContextQuery({
    variables: {
      input: {
        scopeMrn: scopeMrn,
      },
    },
    skip: skip,
  });

  const recipients =
    ticketData?.ticketContext?.__typename === "EmailTicketContextPayload"
      ? ticketData.ticketContext.recipients
      : undefined;

  const isRecipientsEmpty = (recipients || []).length === 0;

  return {
    recipients,
    isRecipientsEmpty,
  };
}
