import { createSvgIcon } from "@mui/material";

export const GCPIcon = createSvgIcon(
  <svg viewBox="0 0 512 512" fill="currentColor">
    <path
      d="M259.6,97.9C179,96.1,107.5,149.4,86.4,227.2c1-0.4-1,0.2,0,0c-72.7,47.8-73.5,152-4.6,205.2l0.1-0.1l-0.1,0.6
      c22.1,16.6,48.9,25.5,76.5,25.4h97l0.6,0.5h97.3c125.4,1,175.8-161.3,71.9-231.5c-8.9-33-27.3-62.7-52.9-85.3l-0.8,0.8l0.1-1
      C340.5,114.4,300.8,98.8,259.6,97.9L259.6,97.9z M252.8,175.7c23.3-0.8,47.2,6.9,65.3,21.5c22.6,18.9,35.5,47,34.9,76.5v9.7
      c66.2-1.3,66.2,98.7,0,97.3h-97.3l-0.1,0.1v-0.7h-97.4c-6.9,0-13.7-1.5-20-4.3c-31-14-38.4-54.7-14.4-78.8
      c24.1-24,64.7-16.6,78.8,14.4l56.5-56.5c-18.7-24.5-45.8-41.2-76.1-47.2c0.4-0.1,0.7-0.4,1-0.4
      C201.8,187.9,226.6,176.5,252.8,175.7L252.8,175.7z"
    />
  </svg>,
  "GCP",
);
