import { MembersMgmt } from "~/components/members";
import { TestIamActionsQuery } from "~/operations";
import { Space } from "~/lib/types";

export type MembersProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Members({ space, availablePermissions }: MembersProps) {
  return (
    // Members Mgmt is being reused in both the space and org level
    <MembersMgmt {...{ space, availablePermissions }} />
  );
}
