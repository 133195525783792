import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { isEmpty } from "lodash";
import { getColor } from "~/lib/colors";

type FirewatchTooltipProps = {
  data: {
    [key: string]: number;
  };
};

export function FirewatchTooltip({ data }: FirewatchTooltipProps) {
  const formattedData = Object.entries(data).filter(
    ([_, value]) => value && value > 0,
  );

  if (isEmpty(formattedData)) {
    formattedData.push(["Unknown", 0]);
  }

  return (
    <Box>
      <Table
        size="small"
        sx={{
          "th, td": {
            fontSize: 10,
            fontWeight: "bold",
            borderBottom: "unset",
            px: 0.5,
            py: 0,
            textAlign: "right",
          },
          thead: { backgroundColor: "unset", boxShadow: "unset" },
          th: {
            color: "text.primary",
            fontSize: 10,
            textTransform: "uppercase",
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Score</TableCell>
            <TableCell>Assets</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {formattedData.map(([key, value]) => (
            <TableRow
              key={key}
              sx={(theme) => ({
                td: {
                  color: getColor(theme, key.toLowerCase()),
                  textTransform: "uppercase",
                },
              })}
            >
              <TableCell>{key}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
