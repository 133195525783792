import { createSvgIcon } from "@mui/material";

export const ValidityIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9997 2L14.5446 4.5512L18.1805 3.90982L18.6627 7.39666L21.9994 8.90983L20.2351 11.9994L22.0001 15.0902L18.662 16.604L18.1799 20.0902L14.545 19.449L12.0004 22L9.45551 19.4488L5.81955 20.0902L5.33736 16.6033L2.00067 15.0902L3.76499 12.0006L2 8.90983L5.33812 7.39601L5.82022 3.90982L9.45504 4.551L11.9997 2ZM7.00297 12.4537L8.18145 11.3224L10.5318 13.5787L15.2423 9.05651L16.4207 10.1879L11.7102 14.7101L11.717 14.7166L10.5385 15.848L10.5318 15.8415L10.5284 15.8447L9.34988 14.7134L9.35328 14.7101L7.00297 12.4537Z"
      fill="white"
    />
  </svg>,
  "Validity",
);
