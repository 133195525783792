import { Box, Typography } from "@mui/material";
import { Format, FormatRelativeDate } from "~/lib/date";

type PublishedDateProps = {
  dateString: string | null;
};

export const PublishedDate = ({ dateString }: PublishedDateProps) => {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Typography fontSize={14}>{FormatRelativeDate(dateString)}</Typography>
      <Typography variant="caption" color="text.secondary" fontSize={12}>
        {Format(dateString)}
      </Typography>
    </Box>
  );
};
