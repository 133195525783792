import { useEffect } from "react";
import { Typography, Box, Breadcrumbs, Link } from "@mui/material";
import { HomeIcon, NavDrawerOrganizationsIcon } from "~/components/icons";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { AddButton } from "~/components/add-button";
import { Link as RouterLink } from "react-router-dom";
import { Flex } from "~/components/ui-library";
import { CreateOrganization } from "./space/Workspaces/components/CreateOrganization";

export type CreateOrganizationProps = {};

export function CreateOrganizationPage({}: CreateOrganizationProps) {
  const { setTree } = useAppNavDrawer();

  useEffect(() => {
    setTree([
      {
        text: "Organizations",
        icon: <NavDrawerOrganizationsIcon />,
        link: {
          to: `/dashboard`,
        },
        selected: true,
      },
    ]);
    return () => setTree([]);
  }, []);

  const breadcrumbs = [
    <Link
      key="/dashboard"
      component={RouterLink}
      to={`/dashboard`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/organizations"
      component={RouterLink}
      to={`/dashboard`}
      display="flex"
    >
      Organizations
    </Link>,
    <Typography key="/organizations/new">Create New</Typography>,
  ];

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="create-new-organization-header"
        mb={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Flex flexDirection="column">
          <Typography
            variant="h4"
            fontWeight={700}
            textTransform="uppercase"
            sx={{ mb: 1 }}
          >
            Create New Organization
          </Typography>
          <Typography sx={{ color: "text.secondary" }}>
            Create a new organization to contain all the different spaces in
            your company as well as role-based access control.
          </Typography>
        </Flex>
        <Flex id="create-new-organization-actions" alignItems="center" gap={4}>
          <AddButton
            id="organization"
            href={`/dashboard`}
            aria-label="Create New Organization"
            close
          />
        </Flex>
      </Box>
      <CreateOrganization />
    </Box>
  );
}
