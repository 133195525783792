import { createSvgIcon } from "@mui/material";

export const ResourceIcon = createSvgIcon(
  <svg
    viewBox="0 0 18 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M9.06276 19.9912H8.91441C8.8656 20.0029 8.81488 20.0029 8.76606 19.9912L0.857857 15.7731C0.751766 15.7177 0.662491 15.6324 0.600016 15.5269C0.537542 15.4214 0.504331 15.2998 0.504098 15.1756V4.82761C0.498634 4.77599 0.498634 4.72389 0.504098 4.67227C0.518822 4.60379 0.545937 4.53889 0.583979 4.48108C0.626964 4.42201 0.681413 4.37314 0.743741 4.33769C0.776489 4.29946 0.815048 4.26716 0.857857 4.2421H0.914914L8.73183 0.0718173C8.82017 0.0246116 8.91794 0 9.01712 0C9.1163 0 9.21406 0.0246116 9.30241 0.0718173L17.2106 4.20625C17.2534 4.23131 17.292 4.26361 17.3247 4.30184C17.365 4.34008 17.3996 4.38438 17.4274 4.43329C17.462 4.49172 17.4852 4.55667 17.4959 4.62447C17.5014 4.6761 17.5014 4.72819 17.4959 4.77981V15.1278C17.4957 15.252 17.4625 15.3736 17.4 15.4791C17.3375 15.5847 17.2482 15.6699 17.1421 15.7253L9.35946 19.9075C9.31045 19.9172 9.26013 19.9172 9.21111 19.9075L9.06276 19.9912ZM9.70181 9.36832V18.2346L16.3433 14.7574V5.96279L9.70181 9.36832ZM1.7936 14.7574L8.42372 18.2346V9.36832L1.7936 5.96279V14.7574ZM2.581 4.76786L9.06276 8.20924L15.5788 4.76786L9.06276 1.42208L2.581 4.76786Z" />
  </svg>,
  "Resource",
);
