import { Box, Typography, alpha } from "@mui/material";

type Props = {
  text: string;
};

export function NoEntriesTextOnly({ text }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: "1 0 0",
        alignSelf: "stretch",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          color: (theme) => alpha(theme.palette.common.white, 0.33),
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
