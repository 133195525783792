import { FormControlLabel, Switch } from "@mui/material";
import { ControllerRenderProps } from "react-hook-form";

type OnOffSwitchProps = {
  field: ControllerRenderProps<any, string>;
  toggleSize: "small" | "medium";
  noLabel?: boolean;
  disabled?: boolean;
};

export function OnOffSwitch({
  field,
  toggleSize,
  noLabel,
  disabled,
}: OnOffSwitchProps) {
  return (
    <FormControlLabel
      control={
        <Switch
          {...field}
          checked={field.value}
          size={toggleSize}
          disabled={disabled}
          sx={{
            "& .MuiSwitch-thumb ": {
              color: disabled ? "text.disabled" : "inherit",
            },
            "& .Mui-checked+.MuiSwitch-track": {
              backgroundColor: disabled ? "text.disabled" : "primary.main",
            },
          }}
        />
      }
      label={noLabel ? "" : field.value ? "On" : "Off"}
      labelPlacement="start"
      sx={{ color: disabled ? "text.disabled" : "inherit" }}
    />
  );
}
