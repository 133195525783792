import { useMemo } from "react";
import * as d3 from "d3";
import { alpha, useTheme } from "@mui/material";
import { Colors, Data } from "./types";
import { numberKFormat } from "~/lib/number-k-formatting";

type DonutChartProps = {
  width: number;
  height: number;
  data: Data;
};

export const DonutChart = ({ width, data }: DonutChartProps) => {
  const theme = useTheme();
  // restrict the width of the chart to 104px max
  let restrictedWidth = Math.min(width, 104);
  // make the height the same as the width
  const height = restrictedWidth;
  const radius = Math.min(restrictedWidth, height) / 2;

  const colors: Colors = {
    none: theme.palette.none.main,
    low: theme.palette.low.main,
    medium: theme.palette.medium.main,
    high: theme.palette.high.main,
    critical: theme.palette.critical.main,
    disabled: theme.palette.text.primary,
  };

  const pie = useMemo(() => {
    // Create the pie
    const pieGenerator = d3
      .pie<any, Data["context"][0]>()
      .value((d) => d.value);
    return pieGenerator(data.context);
  }, [data]);

  const arcs = useMemo(() => {
    // Create each of the sections that make up the donut
    const arcPathGenerator = d3.arc().cornerRadius(1);
    return pie.map((p) => {
      return {
        arc: arcPathGenerator({
          innerRadius: radius * 0.7,
          outerRadius: radius - 1,
          startAngle: p.startAngle,
          endAngle: p.endAngle > 0 ? p.endAngle : 2 * Math.PI,
          padAngle: 0.015,
        }),
        fill: Boolean(p.data.value > 0)
          ? colors[p.data.impact as keyof Colors]
          : alpha(colors.disabled, 0.3),
        opacity: p.data.value > 0 ? 1 : 0.3,
      };
    });
  }, [radius, pie, data]);

  return (
    <svg
      width={restrictedWidth}
      height={height}
      style={{ display: "inline-block" }}
    >
      <g transform={`translate(${restrictedWidth / 2}, ${height / 2})`}>
        {arcs.map((arc, i) => {
          return (
            <path
              key={i}
              d={arc.arc || ""}
              fill={arc.fill}
              opacity={arc.opacity}
            />
          );
        })}
        <text
          dy="0.3em"
          style={{
            fill:
              data.total > 0
                ? theme.palette.text.primary
                : theme.palette.text.disabled,
            fontSize: 16,
            fontWeight: 900,
            textAnchor: "middle",
          }}
        >
          {numberKFormat.format(data.total || 0)}
        </text>
      </g>
    </svg>
  );
};
