import { createSvgIcon } from "@mui/material";

export const AzureIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_3039_21110)">
      <path
        d="M8.00102 0.692839H15.1029L7.73045 22.5367C7.65467 22.7611 7.51042 22.9562 7.31798 23.0943C7.12554 23.2325 6.89462 23.3069 6.65772 23.3069H1.13069C0.95119 23.3069 0.774269 23.2642 0.614559 23.1823C0.454849 23.1004 0.316941 22.9816 0.212244 22.8358C0.107546 22.69 0.0390685 22.5214 0.0124761 22.3439C-0.0141164 22.1663 0.00194068 21.985 0.0593186 21.815L6.92801 1.46309C7.00375 1.23853 7.14803 1.0434 7.34053 0.905168C7.53302 0.766938 7.76403 0.692579 8.00102 0.692566V0.692839Z"
        fill="url(#paint0_linear_3039_21110)"
      />
      <path
        d="M18.3218 15.3442H7.05989C6.95519 15.3441 6.85288 15.3755 6.76629 15.4343C6.6797 15.4932 6.61284 15.5768 6.57443 15.6742C6.53602 15.7716 6.52783 15.8783 6.55093 15.9804C6.57403 16.0825 6.62736 16.1753 6.70395 16.2467L13.9406 23.0011C14.1513 23.1977 14.4287 23.3069 14.7168 23.3069H21.0938L18.3218 15.3442Z"
        fill="#0078D4"
      />
      <path
        d="M8.00055 0.692818C7.76098 0.691899 7.52739 0.767606 7.3339 0.908881C7.14041 1.05016 6.99717 1.2496 6.92509 1.47807L0.0673063 21.7964C0.00606799 21.9671 -0.0131576 22.15 0.0112558 22.3297C0.0356693 22.5094 0.103004 22.6806 0.207563 22.8288C0.312122 22.9769 0.450829 23.0977 0.611952 23.181C0.773074 23.2642 0.951871 23.3074 1.13322 23.3069H6.8029C7.01406 23.2692 7.21143 23.1761 7.37483 23.0371C7.53824 22.8981 7.66183 22.7183 7.73298 22.5159L9.10055 18.4855L13.9855 23.0418C14.1902 23.2111 14.447 23.3047 14.7127 23.3069H21.0659L18.2795 15.3442L10.1566 15.3461L15.1281 0.692818H8.00055Z"
        fill="url(#paint1_linear_3039_21110)"
      />
      <path
        d="M17.0723 1.46197C16.9966 1.23778 16.8526 1.04296 16.6604 0.904977C16.4682 0.76699 16.2375 0.692783 16.0009 0.69281H8.08594C8.32253 0.692824 8.55317 0.767046 8.74536 0.905028C8.93756 1.04301 9.08163 1.2378 9.1573 1.46197L16.0263 21.8147C16.0837 21.9848 16.0998 22.1661 16.0732 22.3437C16.0466 22.5212 15.9782 22.6899 15.8735 22.8358C15.7688 22.9816 15.6309 23.1004 15.4711 23.1824C15.3114 23.2644 15.1344 23.3071 14.9549 23.3072H22.8701C23.0497 23.3071 23.2266 23.2643 23.3863 23.1823C23.546 23.1003 23.6839 22.9815 23.7885 22.8357C23.8932 22.6898 23.9616 22.5211 23.9882 22.3436C24.0147 22.1661 23.9987 21.9848 23.9412 21.8147L17.0723 1.46197Z"
        fill="url(#paint2_linear_3039_21110)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3039_21110"
        x1="10.5894"
        y1="2.36862"
        x2="3.21396"
        y2="24.1576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#114A8B" />
        <stop offset="1" stopColor="#0669BC" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3039_21110"
        x1="12.8929"
        y1="12.5229"
        x2="11.1868"
        y2="13.0997"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopOpacity="0.3" />
        <stop offset="0.071" stopOpacity="0.2" />
        <stop offset="0.321" stopOpacity="0.1" />
        <stop offset="0.623" stopOpacity="0.05" />
        <stop offset="1" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3039_21110"
        x1="11.9555"
        y1="1.73308"
        x2="20.0515"
        y2="23.3025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3CCBF4" />
        <stop offset="1" stopColor="#2892DF" />
      </linearGradient>
      <clipPath id="clip0_3039_21110">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Azure",
);
