import { createSvgIcon, SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const HIPAAIcon = ({ ...props }) => {
  const { mode } = useColorMode();
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 44"
        enableBackground="new 0 0 48 44"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill={mode === "light" ? "#363636" : "#fff"}
            d="M23.8,39.5c0,1.3,0,2.2,0.1,2.6c0,0.1,0,0.1,0,0.2v0.1c0,0.3,0,0.5,0.1,0.7C24,43,24,42.7,24,42.5
			c0-0.1,0-1.4,0.1-3.5c0,0,0,0,0,0.1C24,39.2,23.8,39.3,23.8,39.5z M20,13.2c0.3-0.3,0.6-0.7,0.8-1c-0.1,0.3-0.7,1.6-0.2,1.7
			c0.6,0.1,1.3-0.1,2.5-1.6c0-2-0.1-3.7-0.1-5h-0.7c-0.4,0-1,0-1.4-0.6c-0.4-0.6-0.5-1.3-0.5-1.8c0-0.7-0.2-2-1.1-2.7
			c-0.6-0.5-2.3-0.9-3.8-0.3C15,2,14,2.6,13,3c-0.9,0.5-2.1,1-2.4,1c0.6-0.3,1.4-0.7,2.3-1.2c1-0.5,2-1.1,2.6-1.3
			c1-0.4,3-0.5,4.1,0.3c1,0.8,1.2,2.2,1.2,2.9c0,0.5,0,1.1,0.4,1.7c0.3,0.5,0.9,0.5,1.2,0.5H23V6.5c-0.6,0-1.4,0.1-1.8-0.7
			c-0.4-0.9,0-3.3-1.4-4.3c-1.4-1-3.8-0.8-5.8,0.4c-2.1,1.2-4.7,2.7-8.1,4C2.6,7.2,0.5,7.8,0.1,7.8c-0.4,0.1,0.3,0.3,1.7,0.4
			c0.9,0,1.7,0,2.6-0.1l10.5-4.8c0,0-9,4.5-9.8,5C4.4,8.8,3.8,9,3.8,9.4C3.9,9.9,4.4,9.8,6,9.8c1.6,0,1.9-0.2,2.5-0.3
			c0.5-0.2,6.4-2.9,6.4-2.9s-6.3,3.1-7.2,3.8c-0.9,0.7,0.1,1,0.7,1c0.6,0,2.3-0.1,3.2-0.5c0.9-0.5,3.9-2.3,3.9-2.3s-2.9,2.1-3.4,2.5
			c-0.5,0.4-0.7,0.7-0.6,1.1c0.2,0.5,1,0.5,2.2,0c0,0,1.2-0.4,1.6-0.8C15.8,11,17,9.8,17,9.8s-1.5,1.6-1.9,2.2
			c-0.4,0.7-0.4,0.8-0.3,1.2c0.3,0.5,2.2,0,2.7-0.6c0.4-0.5,0.8-0.9,1.1-1.4c0,0-0.6,0.9-0.7,1.5c-0.1,0.5-0.3,1,0.3,1
			C18.9,13.8,19.4,13.9,20,13.2z M23.6,25.5l0.2-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.1-0.1,0.3-0.2,0.4-0.3c0-1,0.1-2.1,0.1-3.1
			c-0.3-0.1-0.6-0.3-0.9-0.4c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.2c-0.3-0.1-0.5-0.2-0.8-0.3
			c-1.8,1-2.2,1.4-2.2,2.3c0,1.2,1.1,1.8,2.9,3C23,25.9,23.3,25.7,23.6,25.5z M21.4,23.3c0,0-0.1-0.8,1.9-1.7c0.1,0,0.1-0.1,0.2-0.1
			c0,1.2,0,2.5,0.1,3.7C22.5,24.5,21.4,23.9,21.4,23.3z M24.4,38.6c-0.1,0.1-0.2,0.2-0.3,0.3c0.5,0.7,0.5,1.4,0.2,2.3
			c0.3-0.3,0.4-0.6,0.5-1c0.1-0.4,0-0.8-0.1-1.1C24.6,38.9,24.5,38.7,24.4,38.6z M23.6,31.2l0.7,0.7l0.5,0.5c0,0,0.1,0,0.1,0.1
			c1.2-1.1,2.3-2.1,2.3-3.3c0-1-1-1.9-1.9-2.6c-0.1,0.1-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.3-0.6,0.4c0,0,0,0,0,0c0,0,0,0,0,0
			c0,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.3-0.7,0.5C23.6,28.8,23.6,30,23.6,31.2z M24.4,27.2c1.2,0.9,1.7,1.4,1.7,2
			c0,0.6-0.7,1.4-1.7,2.5C24.3,30.2,24.4,28.7,24.4,27.2z M31,9.9c0,0,1.3,1.1,1.7,1.5c0.5,0.4,1.6,0.8,1.6,0.8c1.2,0.4,2,0.5,2.2,0
			c0.1-0.4-0.1-0.7-0.6-1.1c-0.6-0.5-3.4-2.5-3.4-2.5s2.9,1.9,3.9,2.3c0.9,0.5,2.6,0.6,3.2,0.5c0.6,0,1.6-0.3,0.7-1
			c-0.9-0.7-7.2-3.8-7.2-3.8s5.8,2.7,6.4,2.9s0.9,0.3,2.5,0.3c1.6,0.1,2.1,0.1,2.2-0.4c0.1-0.5-0.6-0.6-1.3-1.1
			c-0.8-0.5-9.8-5-9.8-5l10.5,4.8c0.9,0.1,1.7,0.1,2.6,0.1c1.4,0,2.1-0.3,1.7-0.4c-0.4-0.1-2.5-0.6-5.8-1.9c-2.8-1.1-5.5-2.5-8.1-4
			c-2-1.2-4.4-1.4-5.8-0.4c-1.4,1-1,3.4-1.4,4.3c-0.4,0.8-1.2,0.7-1.8,0.7c0,0.2,0,0.3,0,0.5h0.7c0.3,0,0.9,0,1.2-0.5
			c0.4-0.6,0.4-1.2,0.4-1.7c0-0.7,0.2-2.1,1.2-2.9c1-0.8,3-0.7,4.1-0.3c0.5,0.2,1.6,0.8,2.6,1.3c0.9,0.5,1.7,0.9,2.3,1.2
			c-0.4,0-1.5-0.6-2.4-1c-1-0.5-2-1-2.5-1.2c-1.5-0.6-3.2-0.2-3.8,0.3c-0.9,0.7-1.1,2-1.1,2.7c0,0.5,0,1.2-0.5,1.8
			c-0.4,0.6-1,0.6-1.4,0.6H25c0,1.3-0.1,3-0.1,4.9c1.2,1.6,1.9,1.8,2.5,1.6c0.5-0.1,0-1.3-0.2-1.7c0.2,0.4,0.5,0.7,0.8,1
			c0.6,0.7,1.2,0.6,1.7,0.6c0.5-0.1,0.4-0.5,0.3-1c-0.1-0.5-0.7-1.5-0.7-1.5c0.3,0.5,0.7,1,1.1,1.4c0.5,0.6,2.5,1.2,2.7,0.6
			c0.2-0.4,0.1-0.5-0.3-1.2C32.5,11.4,31,9.9,31,9.9z M24,4.4c0.3,0,0.6-0.1,0.8-0.3c0.2-0.2,0.4-0.4,0.5-0.6
			c0.1-0.3,0.1-0.6,0.1-0.8c-0.1-0.3-0.2-0.5-0.4-0.8c-0.2-0.2-0.5-0.3-0.8-0.4c-0.3-0.1-0.6,0-0.8,0.1c-0.3,0.1-0.5,0.3-0.7,0.5
			c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.3,0.3,0.5c0.1,0.1,0.3,0.3,0.5,0.3C23.6,4.3,23.8,4.4,24,4.4z
			 M28.8,15.3c0,0,0-1-1.7-1.1c-1.7-0.1-1.7,0.6-1.7,0.9c0.1,0,0.2,0,0.2,0.1c0,0-0.1,0.1-0.2,0.1c0.1,0.3,0.3,0.6,1.1,1
			c0.6,0.3,0.9,0.3,1.2,0.2c0,0,0.4,0.2,0.4,0.6c0,0.4-0.6,1.1-3.6,2.5c0.2-8,0.3-15,0.3-15h0.2c0.1,0,0.1,0,0.2-0.1
			c0.1-0.1,0.1-0.1,0.1-0.2V4.2c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c-0.2,0.2-0.3,0.3-0.6,0.4c-0.2,0.1-0.4,0.2-0.7,0.1
			c-0.2,0-0.5-0.1-0.7-0.1c-0.2-0.1-0.4-0.2-0.6-0.4c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1v0.1c0,0.1,0,0.1,0.1,0.2s0.1,0.1,0.2,0.1
			h0.2c0,0,0.1,6.8,0.3,14.6l0.8,0.4c0,0,0.1,0,0.1,0.1c0.7,0.3,1.2,0.6,1.7,0.8c1.6-0.6,3.1-1.3,3.6-2.3
			C30.5,16.4,28.8,15.3,28.8,15.3L28.8,15.3z M26.6,14.9c-0.2,0-0.3-0.1-0.3-0.2c0-0.1,0.1-0.2,0.3-0.2s0.3,0.1,0.3,0.2
			C26.9,14.8,26.8,14.9,26.6,14.9z M23.8,37.7c0.1-0.2,0.3-0.4,0.4-0.5c0-1,0.1-2.2,0.1-3.4c-0.2-0.2-0.4-0.4-0.6-0.6
			c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.2c-0.5,0.5-1.5,1.5-1.5,2.4c0,1.1,1,2.1,1.8,2.9C23.6,38,23.6,37.9,23.8,37.7z
			 M23.6,33.4c0,1.5,0.1,2.9,0.1,4.1c-0.6-0.8-1.2-1.6-1.2-2.2C22.5,34.9,22.8,34.3,23.6,33.4z M24.1,19.8c-3.5-1.7-4.2-2.5-4.2-2.8
			c0-0.4,0.4-0.6,0.4-0.6c0.3,0.1,0.6,0.1,1.2-0.2c0.7-0.3,1-0.7,1.1-1c-0.1,0-0.2,0-0.2-0.1c0,0,0.1-0.1,0.2-0.1v0
			c0-0.3-0.1-1-1.7-0.9c-1.7,0.1-1.7,1.1-1.7,1.1s-1.7,1.1-0.8,2.9c0.8,1.6,4.3,2.5,6.4,3.4s1.9,1.7,1.9,1.7c0,0.7-1.4,1.4-2.5,2.2
			c-1.1,0.8-3.4,2.1-3.4,3.6c0,1.4,1.4,2.5,2.8,3.7c1.4,1.2,1.9,2,1.9,2.7c0,0.9-1.6,2.2-2.3,3.8c-0.8,1.6,0.8,4,0.3,2.7
			c-0.5-1.3-0.2-2.2,0.4-3.1c0.8-1.1,2.5-2.2,2.5-3.6c0-1.2-1.8-2.7-1.8-2.7c-1.6-1.6-2.6-2.5-2.6-3.3c0-0.8,0.8-1.5,2.9-2.9
			c2.1-1.4,3.4-1.9,3.4-3.2C28.2,22,27.6,21.5,24.1,19.8L24.1,19.8z M21.4,14.5c0.2,0,0.3,0.1,0.3,0.2s-0.1,0.2-0.3,0.2
			S21,14.8,21,14.7S21.2,14.5,21.4,14.5z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

HIPAAIcon.displayName = "HIPAA";
