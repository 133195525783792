import { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { alpha, Link, Box, Typography } from "@mui/material";
import { ArrowBackIcon } from "~/components/icons";
import { motion } from "framer-motion";
import { useAuth } from "~/login/provider";

type Props = {
  email: string;
};

export function EmailRecoveryComponent({ email }: Props) {
  const { sendResetPasswordEmail, dispatch } = useAuth();

  useEffect(() => {
    sendResetPasswordEmail(dispatch, email);
  }, []);

  const triggerResetPasswordEmail = () => {
    sendResetPasswordEmail(dispatch, email);
  };

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      sx={{ width: 1, maxWidth: 400, minWidth: 330 }}
    >
      <Box
        sx={{
          width: 1,
          py: 5,
          px: 4,
          backgroundColor: "#FFFFFF",
          borderRadius: 2,
          color: alpha("#454545", 0.66),
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          fontWeight={700}
          sx={{
            mb: 3,
            background: "linear-gradient(133.55deg, #9147FF 0%, #4C35E8 100%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Recover your account
        </Typography>
        <Typography>
          Instructions how to recover your account have been sent to:
        </Typography>
        <Typography
          variant="h5"
          component="p"
          fontWeight={700}
          sx={{ my: 2, color: "#454545" }}
        >
          {email}
        </Typography>
        <Typography variant="body2">
          If you do not wish to reset your password, you can ignore this email.
          If you did not get an email, check your spam folder -- if you still
          cannot find it,{" "}
          <Link
            component="button"
            onClick={triggerResetPasswordEmail}
            sx={{ textDecoration: "underline" }}
          >
            send it again
          </Link>
          .
        </Typography>
        <Box
          component={RouterLink}
          to="/login"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 5,

            px: 0,
          }}
        >
          <ArrowBackIcon sx={{ mr: 2.5, color: "secondary.main" }} />
          <Typography
            sx={{
              color: "background.lightest",
              textTransform: "initial",
              background:
                "linear-gradient(133.55deg, #9147FF 0%, #4C35E8 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Back to the log in screen
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
