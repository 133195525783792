import { styled } from "@mui/material/styles";
import { DeleteForeverIcon } from "~/components/icons";

export const ActionsRow = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const DeleteIcon = styled(DeleteForeverIcon)`
  opacity: 0.5;
  &:hover {
    opacity: 1;
    color: ${(props) => props.theme.palette.error.main};
  }
`;
