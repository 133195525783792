import { createSvgIcon } from "@mui/material";

export const SpaceIcon = createSvgIcon(
  <svg viewBox="0 0 1200 1200" fill="currentColor">
    <path
      d="M1.422,630.365c-1.822-9.987-1.373-18.468-1.373-28.858v-2.748
      c163.322-14.011,341.241-55.151,473.665-149.787c37.996,17.409,75.363,15.034,111.208-2.748
      c75.103,75.855,148.807,128.574,247.13,159.405c10.067,25.652,26.086,45.349,48.053,59.091
      c-26.543,65.961-63.612,124.135-111.208,174.521c-70.346-50.674-163.231-13.979-194.958,59.091
      C353.928,895.948,132.179,799.69,1.422,630.365z M572.565,984.905c-112.313,58.005-230.856,89.277-351.473,82.451
      C127.796,989.072,60.567,886.74,26.135,780.151C177.657,910.381,379.047,984.7,572.565,984.905z M821.068,968.415
      c127.807-26.659,245.244-78.05,340.489-156.657c-125.012,325.938-501.479,474.94-810.035,336.676
      c100.162-14.432,194.251-49.026,274.588-94.818C706.395,1099.621,801.941,1051.228,821.068,968.415z M1057.214,633.113
      c49.196-3.631,97.167-7.251,142.786-15.116c-0.089,12.283-1.357,24.374-1.373,35.729
      c-85.771,109.766-214.696,184.761-343.235,219.87c47.965-58.234,83.545-122.923,108.462-188.264
      C1003.028,680.25,1035.027,662.255,1057.214,633.113L1057.214,633.113z M1079.182,545.165
      c-5.416-40.734-25.791-73.796-57.664-94.819c10.072-93.269,11.733-184.275,4.119-272.089
      c96.156,99.264,154.383,225.964,170.244,351.792C1161.1,537.378,1122.199,542.417,1079.182,545.165L1079.182,545.165z
        M410.559,387.133C289.275,463.55,147.263,500.671,6.914,512.185c35.05-219.042,184.246-400.073,384.423-474.095
      c5.438,71.134,21.91,139.81,48.054,199.257C397.418,279.969,387.45,334.611,410.559,387.133z M646.704,285.443
      c63.215-78.489,115.77-158.695,145.532-252.851C843.492,50,889.715,72.444,930.903,99.928
      c14.386,113.183,16.386,225.917,5.492,331.18c-49.729,8.488-88.824,38.745-105.717,82.451
      c-73.416-26.577-133.514-76.069-186.72-129.174C657.321,349.907,657.826,317.59,646.704,285.443L646.704,285.443z M519.021,204.366
      c-25.544-63.148-42.217-124.34-42.561-191.012c76.599-17.623,159.296-17.036,232.027-2.748
      c-27.786,77.786-71.688,149.88-118.073,208.876C574.092,212.511,534.338,196.983,519.021,204.366z"
    />
  </svg>,
  "Space",
);
