import { useSearchParams } from "react-router-dom";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { AwsIntegrationServerlessPage } from "./integration-aws-account-serverless";
import { AwsIntegrationHostedPage } from "./integration-aws-account-hosted";

export type AwsIntegrationPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function AwsIntegrationPage({
  space,
  availablePermissions,
}: AwsIntegrationPageProps) {
  const [searchParams] = useSearchParams();
  const integrationMrn = searchParams.get("integrationMrn");
  const isServerless = !integrationMrn || integrationMrn.includes("aws");

  return isServerless ? (
    <AwsIntegrationServerlessPage
      space={space}
      availablePermissions={availablePermissions}
    />
  ) : (
    <AwsIntegrationHostedPage
      space={space}
      availablePermissions={availablePermissions}
    />
  );
}
