import { ReactNode } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import {
  AppleAuthIcon,
  AWSAuthIcon,
  AzureAuthIcon,
  DockerAuthIcon,
  GCPAuthIcon,
  GithubAuthIcon,
  GitlabAuthIcon,
  KubernetesAuthIcon,
  LinuxAuthIcon,
  OpenshiftAuthIcon,
  SlackAuthIcon,
  TerraformAuthIcon,
  VMwareAuthIcon,
  WindowsAuthIcon,
} from "./icons";

interface HeroProps {
  children: ReactNode;
  view?: "login" | "signup" | "sso";
}

export function Hero({ children, view }: HeroProps) {
  return (
    <Box
      sx={{
        ".MuiTypography-root": {
          fontFamily: "Sofia Pro",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          px: {
            xs: 0,
            sm: 0,
            md: 9,
          },
          py: {
            xs: 3,
          },
          minHeight: view === "signup" ? { xs: "100vh", xl: "90vh" } : "100vh",
          background:
            "linear-gradient(291.74deg, #3f137c 14.76%, #8797ff 100%)",
        }}
      >
        <Container
          maxWidth="xl"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: {
              xs: "center",
              md: view === "signup" ? "unset" : "center",
            },
          }}
        >
          {children}
        </Container>
      </Box>
      {view === "signup" && (
        <Box
          sx={{
            display: {
              xs: "none",
              xl: "block",
            },
            minHeight: "10vh",
            width: 1,
            py: 5,
            background: "white",
          }}
        >
          <Container maxWidth="xl" sx={{ display: { md: "none" }, mb: 5.5 }}>
            <Typography
              variant="h5"
              fontWeight={700}
              sx={{
                background: "linear-gradient(180deg, #A07AFF 0%, #5000C1 100%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
                textAlign: "center",
              }}
            >
              Develop your security posture across your entire infrastructure
              with xSPM
            </Typography>
          </Container>
          <Container
            maxWidth="xl"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Grid
              container
              spacing={5}
              columns={14}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                ".MuiGrid-item": {
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                },
              }}
            >
              <Grid item xs={3.5} md={1}>
                <KubernetesAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <AzureAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <AWSAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <GCPAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <VMwareAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <WindowsAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <AppleAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <LinuxAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <GithubAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <GitlabAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <DockerAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <TerraformAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <SlackAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
              <Grid item xs={3.5} md={1}>
                <OpenshiftAuthIcon sx={{ fontSize: 48 }} />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
    </Box>
  );
}
