import React, { ReactElement, ReactNode } from "react";
import { Tooltip } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

type SoftwareVersionTooltipProps = {
  children: ReactElement;
  content: ReactNode;
};

export const SoftwareVersionTooltip = ({
  content,
  children,
}: SoftwareVersionTooltipProps) => {
  const { mode } = useColorMode();
  return (
    <Tooltip
      title={content}
      placement="top"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "none",
            p: 1,
            ...(mode === "dark"
              ? {
                  boxShadow: 2,
                  bgcolor: "background.light",
                  "& .MuiTooltip-arrow": {
                    color: "background.light",
                  },
                }
              : {}),
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};
