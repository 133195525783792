import {
  JSXElementConstructor,
  ReactElement,
  SyntheticEvent,
  cloneElement,
  useState,
} from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Dropzone, DropzoneProps, DropzoneText } from "./dropzone";
import { toDataUrl } from "~/lib/blob";
import { getError } from "~/lib/handle-error";
import { CloseIcon } from "./icons";
import { AddButton } from "./add-button";
import { CircularIconButton } from "~/pages/compliance/components/CircularIconButton";
import { IconButtonType } from "~/pages/compliance/components/DynamicButtonIcon";

type UploadFileInput = {
  spaceMrn: string;
  force?: boolean;
  dataurl: string;
};

export type UploadProps = {
  title?: string;
  placeholder?: string;
  successMessage?: string;
  errorMessage?: string;
  spaceMrn: string;
  addFileMutation: (value: UploadFileInput) => Promise<any>;
  button?: ReactElement<
    { onClick: (e: SyntheticEvent<HTMLButtonElement, Event>) => void },
    string | JSXElementConstructor<any>
  >;
  buttonIcon?: IconButtonType;
  buttonTitle?: string;
  force?: boolean;
};

export function Upload({
  title,
  placeholder,
  successMessage = "Successfully added file",
  errorMessage = "Failed to upload file",
  spaceMrn,
  button = <AddButton />,
  buttonIcon,
  buttonTitle,
  addFileMutation,
  force = true,
}: UploadProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);

  const onOpen = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  };

  const onClose = (e: SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    await Promise.all(
      files.map(async (file) => {
        try {
          const input = {
            spaceMrn,
            force,
            dataurl: await toDataUrl(file),
          };

          await addFileMutation(input);

          enqueueSnackbar(successMessage, {
            variant: "success",
          });
        } catch (error) {
          enqueueSnackbar(getError(error) || errorMessage, {
            variant: "error",
          });
        }
      }),
    );

    setOpen(false);
  };

  return (
    <>
      {buttonIcon ? (
        <CircularIconButton
          title={buttonTitle || "Upload"}
          iconType={buttonIcon}
          onClick={onOpen}
        />
      ) : (
        cloneElement(button, { onClick: onOpen })
      )}
      <Dialog
        open={open}
        onClose={onClose}
        fullWidth={true}
        onClick={(e) => e.stopPropagation()}
      >
        <DialogTitle>
          <Typography variant="h6" component="span">
            {title}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Dropzone
            className="mondoo-dropzone-area"
            accept={{
              "application/x-yaml": [".yaml", ".yml"],
              "application/yaml": [".yaml", ".yml"],
            }}
            multiple={false}
            showIcon={false}
            showAlerts={["error"]}
            onDropAccepted={onDropAccepted}
          >
            <DropzoneText>{placeholder}</DropzoneText>
          </Dropzone>
        </DialogContent>
      </Dialog>
    </>
  );
}
