import {
  Dispatch,
  Fragment,
  SetStateAction,
  SyntheticEvent,
  useState,
} from "react";
import {
  TableRow,
  TableCell,
  Checkbox,
  IconButton,
  Grid,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon, DeleteIcon } from "~/components/icons";
import { FormatAbbrDateYear, FormatDate, formatLastUsed } from "~/lib/date";
import {
  GetApiTokensQuery,
  ServiceAccountForwardPaginationQuery,
} from "~/operations";
import { DetailRow } from "~/components/data-table";
import { APITokenToDelete } from "~/pages/space-settings/api-tokens/settings-api-tokens";

export type ApiTokenConnection = NonNullable<GetApiTokensQuery["apiTokens"]>;
export type ApiTokenEdge = NonNullable<ApiTokenConnection["edges"]>[0];
export type ApiToken = NonNullable<ApiTokenEdge["node"]>;

export type ServiceAccountConnection = NonNullable<
  ServiceAccountForwardPaginationQuery["serviceAccounts"]
>;
export type ServiceAccountEdge = NonNullable<
  ServiceAccountConnection["edges"]
>[0];
export type ServiceAccount = NonNullable<ServiceAccountEdge["node"]>;

export type TokenTableRowProps = {
  edge: ApiTokenEdge | ServiceAccountEdge;
  hasDeleteAgentsPermissions: boolean | undefined;
  hasSetAgentMembershipPermission: boolean | undefined;
  setDeleteDialogOpen: Dispatch<SetStateAction<boolean>>;
  setItemToDelete: Dispatch<SetStateAction<APITokenToDelete | undefined>>;
  isSelectable: boolean;
  selection: APITokenToDelete[];
  setSelection: Dispatch<SetStateAction<APITokenToDelete[]>>;
  setEditDialogOpen: Dispatch<SetStateAction<boolean>>;
  handleEditPermissions: (token: ApiToken | ServiceAccount) => void;
};

export function TokenTableRow({
  edge,
  hasDeleteAgentsPermissions,
  hasSetAgentMembershipPermission,
  setDeleteDialogOpen,
  setItemToDelete,
  isSelectable,
  selection,
  setSelection,
  handleEditPermissions,
}: TokenTableRowProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleOpen = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  const formatRoles = (
    roles: ApiToken["roles"] | ServiceAccount["roles"] | undefined,
  ) => {
    if (!roles || roles?.length === 0) return "No Permissions";
    // strip "Space" from the title
    const regex = new RegExp(/space/i);
    const rolesArray = roles.map((x) => {
      return x.title.replace(regex, "").trim();
    });
    return rolesArray.join(", ");
  };

  const formatCreatedBy = (
    creator: ApiToken["creator"] | ServiceAccount["creator"],
  ) => {
    if (!creator?.email) {
      return "";
    }

    return `Generated by ${creator?.email}, ${FormatDate(
      edge.node?.createdAt,
    )}`;
  };

  const handleDeleteBegin = (mrn: string | null | undefined) => {
    if (!mrn) return;

    setItemToDelete({
      mrn,
      date: edge.node?.createdAt
        ? FormatAbbrDateYear(edge.node?.createdAt)
        : null,
      name: edge.node?.creator?.email,
      reason: edge.node?.description,
    });
    setDeleteDialogOpen(true);
  };

  const handleCheck = (
    _e: SyntheticEvent,
    checked: boolean,
    mrn: string | undefined,
  ) => {
    if (!mrn) return;
    if (checked) {
      setSelection((prev) => [
        ...prev,
        {
          mrn,
          reason: edge.node?.description,
          date: FormatAbbrDateYear(edge.node?.createdAt),
          name: edge?.node?.creator?.email,
        },
      ]);
    } else {
      const selectedItemsCopy = [...selection];
      const found = selectedItemsCopy.findIndex((x) => x.mrn === mrn);
      selectedItemsCopy.splice(found, 1);
      setSelection(selectedItemsCopy);
    }
  };

  const isSelected = Boolean(selection.find((s) => s.mrn === edge?.node?.mrn));
  const className = isSelected ? "selected" : "";

  return (
    <Fragment>
      <TableRow
        onClick={handleOpen}
        className={className + " token-table-row"}
        sx={{
          background: (theme) =>
            isOpen ? theme.palette.background.lighter : "unset",
        }}
      >
        {isSelectable && (
          <TableCell>
            <Checkbox
              checked={isSelected}
              onChange={(e, checked) =>
                handleCheck(e, checked, edge?.node?.mrn)
              }
              onClick={(e) => e.stopPropagation()}
            />
          </TableCell>
        )}
        <TableCell>{edge?.node?.name}</TableCell>
        <TableCell>{formatRoles(edge.node?.roles)}</TableCell>
        <TableCell>{formatLastUsed(edge?.node?.createdAt)}</TableCell>
        <TableCell>{formatLastUsed(edge?.node?.lastUsed)}</TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand query" size="small">
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Accordion Content Row */}
      <DetailRow colSpan={isSelectable ? 6 : 5} open={isOpen}>
        <Grid container py={1}>
          <Grid
            item
            xs={12}
            sm
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography>{edge.node?.description}</Typography>
            </Box>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ display: "block" }}
            >
              {formatCreatedBy(edge.node?.creator)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm="auto"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            {hasSetAgentMembershipPermission && (
              <Button
                size="small"
                color="secondary"
                variant="outlined"
                sx={{ mb: 1 }}
                onClick={() =>
                  handleEditPermissions(edge.node as ApiToken | ServiceAccount)
                }
              >
                Permissions
              </Button>
            )}
            {hasDeleteAgentsPermissions && (
              <Button
                size="small"
                color="error"
                variant="outlined"
                endIcon={<DeleteIcon />}
                sx={{ mt: 1 }}
                onClick={() => handleDeleteBegin(edge?.node?.mrn)}
              >
                Delete
              </Button>
            )}
          </Grid>
        </Grid>
      </DetailRow>
    </Fragment>
  );
}
