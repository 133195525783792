import { createSvgIcon } from "@mui/material";

export const GithubAuthIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1622_35488)">
      <path
        d="M24 0.593999C10.7415 0.593999 0 11.3445 0 24.594C0 35.2035 6.876 44.196 16.4055 47.3685C17.61 47.595 18.045 46.8525 18.045 46.2105C18.045 45.6405 18.0315 44.133 18.0225 42.132C11.343 43.578 9.936 38.9055 9.936 38.9055C8.8425 36.1395 7.2645 35.397 7.2645 35.397C5.0925 33.9135 7.437 33.945 7.437 33.945C9.843 34.1085 11.109 36.4125 11.109 36.4125C13.2495 40.0845 16.725 39.0225 18.1005 38.4045C18.3105 36.858 18.936 35.7945 19.617 35.202C14.289 34.6005 8.6865 32.538 8.6865 23.3415C8.6865 20.715 9.6165 18.576 11.154 16.896C10.8885 16.2945 10.0755 13.8495 11.3655 10.5435C11.3655 10.5435 13.374 9.903 17.9655 13.005C19.887 12.4725 21.927 12.207 23.9655 12.1935C26.0055 12.207 28.044 12.474 29.9655 13.005C34.53 9.903 36.537 10.5435 36.537 10.5435C37.8255 13.8495 37.0125 16.293 36.7785 16.896C38.301 18.576 39.231 20.7165 39.231 23.3415C39.231 32.559 33.621 34.5915 28.287 35.178C29.121 35.8965 29.9025 37.374 29.9025 39.6165C29.9025 42.8265 29.8725 45.4125 29.8725 46.194C29.8725 46.818 30.2925 47.568 31.5285 47.3265C41.1285 44.187 47.997 35.187 47.997 24.5925C47.997 11.343 37.2555 0.592499 23.997 0.592499L24 0.593999Z"
        fill="#AAAAAA"
      />
    </g>
    <defs>
      <clipPath id="clip0_1622_35488">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Github",
);
