import { Fragment, useEffect } from "react";
import {
  createPath,
  Link as RouterLink,
  Navigate,
  Outlet,
  parsePath,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { Box, Link, Typography } from "@mui/material";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { CicdBreadcrumbs } from "~/components/cicd/cicd-breadcrumbs";
import { Space } from "~/lib/types";
import {
  CaseStatus,
  ExceptionGroup,
  ExceptionType,
  ReportViewedPage,
  TestIamActionsQuery,
  useGetAssetLinkInfoQuery,
  useGetCasesCountQuery,
  useLoadAssetQuery,
  useLoadOrganizationForSpaceQuery,
  useReportViewedMutation,
} from "~/operations";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { PendingExceptionsTooltip } from "~/components/exceptions/pending-exceptions-tooltip";
import { AssetOverviewHeader } from "./components/PolicyScoreCard/asset/assetOverviewHeader/assetOverviewHeader";
import { Asset, Project } from "./asset/types";
import { usePlatformVulnerabilityConfiguration } from "~/hooks";
import { IamActions } from "~/lib/iam";

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  isCicd?: boolean | null;
  project?: Project | null;
  assetMrn?: string;
};

export type AssetOutletContextType = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  asset: Asset;
  assetId: string;
  assetMrn: string;
  assetPath: string;
  space: Space;
  hasMondooPlatformVulnPolicy: boolean;
  exceptionGroups: ExceptionGroup[];
  isVulnerabilityScanningEnabled: boolean;
};

export function AssetPage({
  space,
  project,
  isCicd = false,
  availablePermissions,
  ...props
}: Props) {
  const { id } = useParams();

  const assetId = props.assetMrn?.split("/").pop() || id;

  if (!assetId) {
    return <LoadingFailedPage what="asset" />;
  }

  const assetMrn =
    props.assetMrn ||
    "//assets.api.mondoo.app/spaces/" + space.id + "/assets/" + assetId;
  const { exceptionGroups, pendingExceptionsGroups, exceptionsCount } =
    useFetchExceptions({
      scopeMrn: assetMrn as string,
      types: [ExceptionType.Security, ExceptionType.Advisory],
    });

  const [reportViewed] = useReportViewedMutation();

  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });

  const { data: orgDataForSpace, loading: orgDataLoading } =
    useLoadOrganizationForSpaceQuery({
      variables: { spaceMrn: space.mrn },
    });

  const hasListCasePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
  );

  // Send report viewed mutation on mount and when the tab changes
  useEffect(() => {
    if (orgDataLoading) return;
    let currentTab = window.location.pathname.split("/").pop() || "unknown";
    const org = orgDataForSpace?.space?.organization;
    let orgMrn = org?.mrn ? org.mrn : "unknown";

    const page = isCicd
      ? ReportViewedPage.CicdJob
      : ReportViewedPage.AssetOverview;

    const info = isCicd
      ? {
          cicdJob: {
            assetMrn: assetMrn,
            orgMrn: orgMrn,
            spaceMrn: space.mrn,
            tab: currentTab,
          },
        }
      : {
          assetOverview: {
            assetMrn: assetMrn,
            orgMrn: orgMrn,
            spaceMrn: space.mrn,
            tab: currentTab,
          },
        };

    reportViewed({
      variables: {
        input: {
          page: page,
          info: info,
        },
      },
    });

    return () => {};
  }, [window.location.pathname, orgDataForSpace]);

  const {
    data: assetData,
    error,
    loading,
  } = useLoadAssetQuery({
    variables: {
      mrn: assetMrn,
    },
  });

  const { data: assetLinkData } = useGetAssetLinkInfoQuery({
    variables: {
      input: {
        spaceID: space.id,
        assetId,
      },
    },
  });

  const { data: casesData } = useGetCasesCountQuery({
    variables: {
      input: {
        scopeMrn: assetMrn,
        findingMrns: [],
        statuses: [CaseStatus.Open],
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !hasListCasePermissions,
  });

  const assetLinkInfo = assetLinkData?.getAssetLinkInfo;
  const asset = assetData?.asset;

  if (loading) {
    return <LoadingPage what="asset" />;
  }

  if (error || !asset) {
    return <LoadingFailedPage what="asset" />;
  }

  let assetPath = `/space/inventory/${assetId}?spaceId=${space.id}`;

  if (assetLinkInfo?.__typename === "CiAssetLinkInfo") {
    const projectId = assetLinkInfo.projectID.split("/").pop();
    assetPath = `/space/cicd/jobs/${projectId}?spaceId=${space.id}&projectId=${projectId}&jobId=${assetMrn}`;

    if (!isCicd) {
      return <Navigate to={assetPath} replace />;
    }
  }

  const generateHref = (tab: string): string => {
    const parsedPath = parsePath(assetPath);
    const pathname = `${parsedPath.pathname}/${tab}`;
    const search = parsedPath.search;
    return createPath({ pathname, search });
  };

  // We only show the Asset Explorer Tab if an Asset has children
  let totalRelatedAssets = 0;
  if (asset.relatedAssets?.count) {
    totalRelatedAssets = asset.relatedAssets.count
      .filter((x: { isParent: boolean }) => !x.isParent)
      .reduce((agg: number, x: { count: number }) => agg + x.count, 0);
  }

  const hasRelatedAssets = totalRelatedAssets > 0;

  const tabList: TabListItem[] = [
    { label: "Overview", to: generateHref("overview"), route: "/overview" },
    {
      label: "Policies",
      to: generateHref("policies"),
      route: "/policies",
    },
    {
      label: "Checks",
      to: generateHref("checks"),
      route: "/checks",
    },
    {
      label: "Data Queries",
      to: generateHref("data-queries"),
      route: "/data-queries",
    },
    // display the software tab if the asset
    // has Platform Vulnerability Policy applied
    pvcIsEnabled
      ? {
          label: "Software",
          to: generateHref("software"),
          route: "/software",
        }
      : undefined,
    // display the vulnerabilities tab if the asset
    // has Platform Vulnerability Policy applied
    pvcIsEnabled
      ? {
          label: "Vulnerabilities",
          to: generateHref("vulnerabilities"),
          route: "/vulnerabilities",
        }
      : undefined,
    // display the advisories tab if the asset
    // has Platform Vulnerability Policy applied
    pvcIsEnabled
      ? {
          label: "Advisories",
          to: generateHref("advisories"),
          route: "/advisories",
        }
      : undefined,
    // display the asset explorer tab if this asset has children
    hasRelatedAssets
      ? {
          label: "Explorer",
          to: generateHref("explorer"),
          route: "/explorer",
        }
      : undefined,
    {
      label: "Resources",
      to: generateHref("resources"),
      route: "/resources",
    },
    {
      label: (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          Exceptions
          {pendingExceptionsGroups.length > 0 && <PendingExceptionsTooltip />}
        </Box>
      ),
      to: generateHref("exceptions"),
      route: "/exceptions",
    },
  ].flatMap((tab) => (tab ? [tab] : []));

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "overview",
  );

  const trail = isCicd
    ? [
        <Link
          key="1"
          component={RouterLink}
          to={`/space/cicd?spaceId=${space.id}`}
        >
          CI/CD
        </Link>,
        <Link
          key="2"
          component={RouterLink}
          to={`/space/cicd/jobs?spaceId=${space.id}&projectId=${project?.id}`}
        >
          {project?.name}
        </Link>,
        <Typography key="3">{asset.name}</Typography>,
      ]
    : [
        <Link
          key="1"
          component={RouterLink}
          to={`/space/inventory?spaceId=${space.id}`}
        >
          Inventory
        </Link>,
        <Typography key="2">{asset.name}</Typography>,
      ];

  // TODO: check settings for platform-vulnerability enabled instead
  const hasMondooPlatformVulnPolicy = Boolean(
    asset?.listPolicies?.edges.some(
      (policy) =>
        policy.node.mrn ===
        "//policy.api.mondoo.app/policies/platform-vulnerability",
    ),
  );

  // set page title
  document.title = `${asset.name} · Assets · Mondoo`;

  return (
    <Fragment>
      <CicdBreadcrumbs {...{ trail }} />

      <AssetOverviewHeader
        asset={asset}
        availablePermissions={availablePermissions}
        space={space}
        project={project}
        isCicd={isCicd}
        exceptionsCount={exceptionsCount}
        casesCount={casesData?.cases?.totalCount || 0}
      />

      {asset && (
        <Fragment>
          {/* Navigation Tabs List */}
          <TabNavigation
            {...{ id: "asset-tabs", tabList, currentTab, marginBottom: 0 }}
          />

          <Outlet
            context={
              {
                asset,
                assetMrn,
                assetPath,
                assetId,
                availablePermissions,
                exceptionGroups,
                space,
                hasMondooPlatformVulnPolicy,
                isVulnerabilityScanningEnabled: pvcIsEnabled,
              } satisfies AssetOutletContextType
            }
          />
        </Fragment>
      )}
    </Fragment>
  );
}

export function useAssetOutlet() {
  return useOutletContext<AssetOutletContextType>();
}
