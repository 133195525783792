import { useState } from "react";
import { Grid, Typography, Paper, styled, Tabs, Tab } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  LoadOrganizationMembersQuery,
  LoadSpaceMembersQuery,
  RoleInput,
} from "~/operations";
import { Org, Space } from "~/lib/types";
import { IamActions } from "~/lib/iam";
import { PersonAddIcon } from "~/components/icons";
import { NoPermission } from "~/components/no-permission";
import {
  ConfigurationList,
  ConfigurationItemButton,
} from "~/components/configuration-items";
import { ResourceInvitations } from "~/components/members/resource-invitations";
import { ResourceMembers } from "~/components/members/resource-members";
import { AddMemberDialog } from "./add-member-dialog";

export type SpaceMember = NonNullable<
  NonNullable<
    NonNullable<NonNullable<LoadSpaceMembersQuery["space"]>["members"]>["edges"]
  >[0]["node"]
>;
export type OrgMember = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<LoadOrganizationMembersQuery["organization"]>["members"]
    >["edges"]
  >[0]["node"]
>;
export type Member = OrgMember | SpaceMember;

export const ROLE_OWNER_MRN = "//iam.api.mondoo.app/roles/owner";
export const ROLE_EDITOR_MRN = "//iam.api.mondoo.app/roles/editor";
export const ROLE_VIEWER_MRN = "//iam.api.mondoo.app/roles/viewer";
export const ROLE_NAMES = {
  [ROLE_OWNER_MRN]: "Owner",
  [ROLE_EDITOR_MRN]: "Editor",
  [ROLE_VIEWER_MRN]: "Viewer",
};

export const isRoleMrn = (mrn: string): mrn is keyof typeof ROLE_NAMES => {
  return Object.keys(ROLE_NAMES).includes(mrn);
};

export const roleName = (mrn: string) => {
  return isRoleMrn(mrn) ? ROLE_NAMES[mrn] : null;
};

export const roleNames = (roles: RoleInput[]) => {
  const names = roles.map((r) => roleName(r.mrn));
  const nullNames = names.filter((n) => n === null);
  if (nullNames.length > 0) names.push(`${nullNames.length} more`);
  return names.flatMap((n) => n ?? []);
};

// Members management component that includes tabs for members and invitations
const TabsContainer = styled("div")`
  flex-grow: 1;
`;

const InviteMemberPaper = styled(Paper)`
  && {
    background: none;
  }
`;

type MembersMgmtProps = {
  org?: Org;
  space?: Space;
  availablePermissions: string[];
};

type MembersMgmtState = {
  addMemberOpen: boolean;
};

export function MembersMgmt(props: MembersMgmtProps) {
  const location = useLocation();
  let initialTab = "members";
  if (location.pathname.includes("invitations")) {
    initialTab = "invitations";
  }
  const currentTab = initialTab;

  const [state, setState] = useState<MembersMgmtState>({
    addMemberOpen: false,
  });

  const mergeState = (params: Partial<MembersMgmtState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const { org, space, availablePermissions } = props;
  let category = currentTab;

  const hasListOrgMembersPermission = availablePermissions.includes(
    IamActions.CAPTAIN_LISTORGANIZATIONMEMBERSHIPS,
  );
  const hasListSpaceMembersPermission = availablePermissions.includes(
    IamActions.CAPTAIN_LISTSPACEMEMBERSHIPS,
  );

  if (
    (space && !hasListSpaceMembersPermission) ||
    (org && !hasListOrgMembersPermission)
  ) {
    return <NoPermission />;
  }

  const hasListInvitationPermission = availablePermissions.includes(
    IamActions.INVITATIONMANAGER_LIST,
  );

  // TODO: we may want to display permission denied
  if (!hasListInvitationPermission && category == "invitations") {
    category = "members";
  }

  let hrefMembers = "";
  let hrefInvitations = "";
  let helpText = "";
  if (org != null) {
    hrefMembers = `/organization/settings/members?organizationId=${org.id}`;
    hrefInvitations = `/organization/settings/members/invitations?organizationId=${org.id}`;
    helpText =
      "Did you know you can add members to your entire organization or just to individual spaces?  Visit the Settings tab of any Space to manage permissions.";
  }

  if (space != null) {
    hrefMembers = `/space/settings/members?spaceId=${space.id}`;
    hrefInvitations = `/space/settings/members/invitations?spaceId=${space.id}`;
    helpText =
      "Did you know you can add members to your entire organization or just to individual spaces?  Manage your organizational members here.";
  }

  const hasAddPermission = availablePermissions.includes(
    IamActions.INVITATIONMANAGER_CREATE,
  );

  let table;
  let mrn = org?.mrn || space?.mrn;
  switch (category) {
    case "members":
      table = (
        <ResourceMembers
          org={org}
          space={space}
          availablePermissions={availablePermissions}
          handleAddMemberClick={
            hasAddPermission
              ? () => mergeState({ addMemberOpen: true })
              : undefined
          }
        />
      );
      break;
    case "invitations":
      table = mrn ? (
        <ResourceInvitations
          mrn={mrn}
          handleAddMemberClick={
            hasAddPermission
              ? () => mergeState({ addMemberOpen: true })
              : undefined
          }
        />
      ) : undefined;
      break;
    default:
      console.error(`membersmgmt> cannot render tab == ${category}`);
  }

  return (
    <>
      {mrn && (
        <AddMemberDialog
          resourceMrn={mrn}
          onClose={() => mergeState({ addMemberOpen: false })}
          dialogProps={{
            fullWidth: true,
            open: state.addMemberOpen,
          }}
        />
      )}
      <Grid container spacing={3}>
        {hasAddPermission && (
          <Grid item xs={12}>
            <Typography variant="body1" mb={2}>
              {helpText}
            </Typography>
            <InviteMemberPaper variant="outlined">
              <ConfigurationList>
                <ConfigurationItemButton
                  icon={<PersonAddIcon />}
                  primary="Invite new members"
                  action="Invite Member"
                  onClick={() => mergeState({ addMemberOpen: true })}
                  dataName="invite-member-button"
                ></ConfigurationItemButton>
              </ConfigurationList>
            </InviteMemberPaper>
          </Grid>
        )}
        <Grid item xs={12}>
          <Paper>
            <TabsContainer>
              <Tabs id="settings-nav" value={currentTab}>
                <Tab
                  value="members"
                  label="Members"
                  component={RouterLink}
                  to={hrefMembers}
                />
                {hasListInvitationPermission && (
                  <Tab
                    value="invitations"
                    label="Invitations"
                    component={RouterLink}
                    to={hrefInvitations}
                  />
                )}
              </Tabs>
            </TabsContainer>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {table}
        </Grid>
      </Grid>
    </>
  );
}
