import { useEffect, useState } from "react";
import { SearchResultScoreTypeDistribution } from "~/operations";
import { SearchFilterGroupButton } from ".";

export type FilterType = {
  scoreType: string;
  count: number;
};

type SearchFilterSectionProps = {
  activeFilter: string | null;
  toggleFilter: (filter: string) => void;
  scoreTypeDistribution?: SearchResultScoreTypeDistribution;
};
export function SearchFilterSection({
  activeFilter,
  toggleFilter,
  scoreTypeDistribution,
}: SearchFilterSectionProps) {
  const [test, setTest] = useState<FilterType[]>([]);

  useEffect(() => {
    if (scoreTypeDistribution) {
      const { __typename, ...scoreTypes } = scoreTypeDistribution || {};

      // check if two objects are equal
      if (JSON.stringify(scoreTypes) !== JSON.stringify(test)) {
        const nextfilterTypes = Object.entries(scoreTypes)
          .map((x) => ({
            scoreType: x[0],
            count: x[1],
          }))
          .sort((a, b) => a.scoreType.localeCompare(b.scoreType));
        // add an all filter
        nextfilterTypes.unshift({
          scoreType: "All",
          count: nextfilterTypes.reduce((acc, x) => acc + x.count, 0),
        });

        setTest(nextfilterTypes);
      }
    }
  }, [scoreTypeDistribution]);

  return test.map((filter) => (
    <SearchFilterGroupButton
      key={filter.scoreType}
      active={filter.scoreType.toLowerCase() === activeFilter?.toLowerCase()}
      filter={filter}
      {...(filter.count > 0 && {
        onClick: () => toggleFilter(filter.scoreType.toUpperCase()),
      })}
    />
  ));
}
