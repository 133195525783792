import { Button, ButtonProps, CircularProgress } from "@mui/material";

type LoadingButtonProps = {
  loading: boolean;
  buttonText: string;
  circleColor?: "inherit" | "primary" | "secondary";
  target?: string;
} & ButtonProps;

const LoadingButton = ({
  buttonText,
  loading,
  circleColor,
  disabled,
  sx,
  ...rest
}: LoadingButtonProps) => {
  return (
    <Button
      {...rest}
      disabled={loading || disabled}
      sx={{
        "&.Mui-disabled": { cursor: "not-allowed", pointerEvents: "auto" },
        ...sx,
      }}
    >
      {buttonText}
      {loading && (
        <CircularProgress
          size={25}
          color={circleColor || "secondary"}
          sx={{ position: "absolute" }}
        />
      )}
    </Button>
  );
};

export default LoadingButton;
