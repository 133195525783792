import { TableCell } from "@mui/material";
import { PublishedDate } from "~/pages/inventory/components/PublishedDate";
import { CommonCellProps } from "~/pages/inventory/components/Advisories/types";

export const PublishCell = ({ advisory, ...props }: CommonCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <PublishedDate dateString={String(advisory?.publishedAt ?? "")} />
    </TableCell>
  );
};
