import { useLayoutEffect, useRef } from "react";
import { Box, Tooltip, useTheme } from "@mui/material";
import * as d3 from "d3";

const defaultSize = 100;

export type GaugeData = {
  color: string;
  value: number;
};

type GaugeEntries = {
  value: GaugeData;
};

export type GaugeProps = {
  tooltip: string;
  label: string | number;
  size: number;
  data: GaugeData[];
};

export function Gauge({
  data,
  label = "",
  size = defaultSize,
  tooltip,
}: GaugeProps) {
  const theme = useTheme();
  const chartRef = useRef<SVGSVGElement>(null);

  const draw = () => {
    if (!chartRef.current) return;

    const d3Graph = d3.select(chartRef.current);

    const fontSize = size * 0.3;
    const radius = 0.5 * size; // for now leave it at the full size
    const innerRadius = radius * 0.8;
    const rotate = 180;

    // Compute the position of each group on the pie:
    const pie = d3
      .pie<GaugeEntries>()
      .value((d) => d.value.value)
      .sort(null);
    const dataReady = pie(
      Object.entries(data).map(([key, value]) => ({ key, value })),
    );

    // remove all previously added elements
    d3Graph.selectAll(".container").remove();
    const g = d3Graph.append("g").attr("class", "container");

    g.selectAll("path")
      .data(dataReady)
      .enter()
      .append("g")
      .attr(
        "transform",
        "translate(" + size / 2 + "," + size / 2 + ") rotate(" + rotate + ")",
      )
      .append("path")
      .attr(
        "d",
        d3
          .arc<d3.PieArcDatum<GaugeEntries>>()
          .innerRadius(innerRadius)
          .outerRadius(radius),
      )
      .attr("fill", (d: d3.PieArcDatum<GaugeEntries>) => d.data.value.color);

    g.selectAll("text")
      .data([label])
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", "50%")
      .attr("y", "50%")
      .attr("font-size", fontSize + "px")
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .attr("fill", theme.palette.text.primary)
      .text((d: string | number) => d);
  };

  useLayoutEffect(() => {
    draw();
  });

  return (
    <Tooltip title={tooltip} aria-label="Gauge summary" placement="top">
      <Box component="svg" ref={chartRef} width={size} height={size} />
    </Tooltip>
  );
}

export const oneColorGaugeData = (
  one: number,
  max: number,
  color: string,
  bgColor: string,
) => {
  return [
    { value: one, color },
    { value: max === 0 ? 1 : max - one, color: bgColor },
  ];
};
