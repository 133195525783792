import { Routes, Route } from "react-router-dom";
import { AssetExceptionsWrapper } from "~/components/asset-exceptions-wrapper";
import { AssetExplorerTab } from "~/components/asset-explorer/asset-explorer-tab";
import { OverviewTab } from "~/components/asset-overview-tab";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { AssetPage } from "~/pages/inventory/asset";
import { AssetDataQueries } from "~/pages/inventory/data-queries";
import { ResourcesExplorer } from "~/pages/resources-explorer/asset-resources-tab";
import { AssetPoliciesTab } from "~/pages/inventory/asset/asset-policies-tab";
import { AssetChecksTab } from "~/pages/inventory/asset/asset-checks-tab";
import { VulnerabilitiesTab } from "~/pages/inventory/components/Vulnerabilities";
import { AdvisoriesTab } from "~/pages/inventory/components/Advisories";
import { AssetSoftwareTab } from "~/pages/inventory/components/AssetSoftware";

export function AssetRoutes({
  space,
  availablePermissions,
}: {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
}) {
  return (
    <Routes>
      <Route
        path="/:id"
        element={
          <AssetPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      >
        <Route
          index
          element={<OverviewTab {...{ space, availablePermissions }} />}
        />
        <Route
          path="overview"
          element={<OverviewTab {...{ space, availablePermissions }} />}
        />
        <Route
          path="policies"
          element={
            <AssetPoliciesTab availablePermissions={availablePermissions} />
          }
        />
        <Route
          path="checks"
          element={
            <AssetChecksTab
              space={space}
              availablePermissions={availablePermissions}
            />
          }
        />
        <Route path="data-queries" element={<AssetDataQueries />} />
        <Route path="software" element={<AssetSoftwareTab />} />
        <Route path="vulnerabilities" element={<VulnerabilitiesTab />} />
        <Route
          path="advisories"
          element={
            <AdvisoriesTab availablePermissions={availablePermissions} />
          }
        />
        <Route path="explorer" element={<AssetExplorerTab />} />
        <Route path="resources" element={<ResourcesExplorer />} />
        <Route path="exceptions" element={<AssetExceptionsWrapper />} />
      </Route>
    </Routes>
  );
}
