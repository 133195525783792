import { styled } from "@mui/material/styles";
import { Paper, Grid, Typography, Divider, Button } from "@mui/material";
import { useAuth } from "~/login/provider";
import MondooBadge from "../images/mondoo.badge.svg";

export function WaitingList() {
  const { logout } = useAuth();

  return (
    <StyledPaper>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MondooBadgeLogo src={MondooBadge} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4">Congratulations</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            You are on the waiting list. We will add you once we expand our
            capacity.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            href="mailto:hello@mondoo.com"
            sx={{ mr: 1 }}
          >
            Contact
          </Button>
          <Button variant="outlined" onClick={logout}>
            Sign Out
          </Button>
        </Grid>
      </Grid>
    </StyledPaper>
  );
}

const StyledPaper = styled(Paper)`
  text-align: center;
  margin: ${(props) => props.theme.spacing(3)} auto;
  padding: ${(props) => props.theme.spacing(3)};
  max-width: 500px;
`;

const MondooBadgeLogo = styled("img")`
  height: 300px;
`;
