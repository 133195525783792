import React, { ReactNode } from "react";
import { Command } from "~/components/guides/components";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import {
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { Control, Controller } from "react-hook-form";
import { JiraFormInput } from "~/pages/integrations/hosted-integrations/forms/jira/types";
import { JiraProject } from "~/operations";
import { motion } from "framer-motion";
import { SubField } from "~/components/Form/components";
import { FormattedDestination } from "~/pages/integrations/hosted-integrations/forms/jira/FormattedDestination";

type JiraFormStep2Props = {
  control: Control<JiraFormInput>;
  isValid: boolean;
  disabled: boolean;
  isProjectsEmpty: boolean;
  projects: JiraProject[] | null | undefined;
  isUpdateFlow: boolean;
};

export const JiraFormStep2 = ({
  control,
  projects,
  isValid,
  disabled,
  isProjectsEmpty,
  isUpdateFlow,
}: JiraFormStep2Props) => {
  const theme = useTheme();

  return (
    <>
      <Box pb={4}>
        <Command
          number={3}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Configure preferences
        </Command>
        <Box mt={3}>
          <AutoConfigurationSection
            control={control}
            showAutoCreateSection={true}
            autoCreateSubField={
              <Box
                component={motion.div}
                initial={false}
                transition={{
                  duration: 0.35,
                  staggerChildren: 0.25,
                }}
                sx={{ overflow: "hidden" }}
              >
                <Controller
                  name="defaultProject"
                  {...{ control }}
                  render={({ field }) => (
                    <SubField
                      configuration="Select a default drift issue destination"
                      caption="Select where to create an issue for each new drift case."
                      disabled={disabled}
                      field={
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sx={{ alignSelf: "flex-end" }}
                        >
                          <Select
                            {...field}
                            fullWidth
                            disabled={disabled}
                            sx={{
                              "&.MuiInputBase-root": {
                                bgcolor: "code.background",
                              },
                            }}
                            displayEmpty
                            renderValue={(selected: ReactNode) => {
                              const selectedProject = projects?.find(
                                (project) => project.key === selected,
                              );

                              const selectedProjectTitle = (
                                <FormattedDestination
                                  projectKey={selectedProject?.key || ""}
                                  projectTitle={selectedProject?.name || ""}
                                />
                              );

                              if (isProjectsEmpty) {
                                return (
                                  <>
                                    No projects available – create one in Jira
                                    first
                                  </>
                                );
                              }

                              if (!selected || !selectedProjectTitle) {
                                return <>Select project</>;
                              }

                              return <>{selectedProjectTitle}</>;
                            }}
                          >
                            {(projects || []).map((project) => (
                              <MenuItem key={project.key} value={project.key}>
                                ({project.key}) {project.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                      }
                    />
                  )}
                />
              </Box>
            }
          />
        </Box>
      </Box>
      <Box width={1}>
        <Divider />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        <Button
          sx={{ textTransform: "uppercase" }}
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          disabled={!isValid}
        >
          Finalize {isUpdateFlow ? "Update" : "Setup"}
        </Button>
      </Box>
    </>
  );
};
