import { Box, Button, Container, Typography } from "@mui/material";
import { Stars } from "~/components/galaxy/stars";
import { RocketPath } from "~/components/galaxy/RocketPath";
import { useColorMode } from "~/providers/color-mode";

export type RequestIntegrationBannerProps = {};

export function RequestIntegrationBanner({}: RequestIntegrationBannerProps) {
  const { mode } = useColorMode();
  const bgStartColor = mode === "dark" ? "#3E2D6B" : "#7655CE";
  return (
    <Box
      sx={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <Container
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Box
          className="cta"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "256px",
          }}
        >
          <Typography
            className="cta-text"
            sx={{
              mb: 3,
              fontFamily: "Roboto",
              fontSize: { xs: 16, sm: 24, md: 40 },
              fontWeight: 900,
              textAlign: "center",
              textShadow: "0px 4px 16px rgba(0, 0, 0, 0.33)",
              color: (theme) => theme.palette.common.white,
            }}
          >
            Didn't find what you were looking for?
          </Typography>
          <Button
            className="cta-button"
            href="mailto:hello@mondoo.com?subject=Requesting an integration!"
            data-name="request-integration-contact-button"
            sx={{
              background: (theme) => theme.palette.primary.gradient,
              whiteSpace: "nowrap",
              boxShadow: (theme) => theme.shadows[2],
              color: (theme) => theme.palette.common.white,
            }}
          >
            Request An Integration
          </Button>
        </Box>
      </Container>
      <Box
        className="bg"
        sx={{
          backgroundImage: `linear-gradient(356.29deg, ${bgStartColor} 40.18%, rgba(62, 45, 107, 0) 112.92%)`,
          backgroundSize: "cover",
          position: "absolute",
          zIndex: 1,
          minHeight: "256px",
          width: "100%",
          left: 0,
          top: 0,
        }}
      >
        <Box
          className="rocket-layer"
          sx={{ position: "relative", zIndex: 2, height: "256px" }}
        >
          <Box
            sx={{
              position: "absolute",
              width: (theme) => theme.breakpoints.values.lg,
              height: "256px",
              left: "50%",
              ml: (theme) => `${-(theme.breakpoints.values.lg / 2)}px`,
            }}
          >
            <RocketPath
              className="rocket-path"
              sx={{
                position: "absolute",
                bottom: 0,
                left: "50%",
                ml: (theme) =>
                  `${-((theme.breakpoints.values.lg - 200) / 2)}px`,
                width: (theme) => theme.breakpoints.values.lg - 200,
                height: "128px",
              }}
            />
            <Typography
              className="rocket"
              sx={{
                position: "absolute",
                right: "64px",
                top: "56px",
                fontSize: "48px",
              }}
            >
              🚀
            </Typography>
          </Box>
        </Box>
        <Box
          className="star-layer"
          sx={{
            position: "absolute",
            zIndex: 1,
            height: "256px",
            width: "100%",
            top: 0,
            left: 0,
          }}
        >
          <Stars />
        </Box>
      </Box>
    </Box>
  );
}
