import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";

export type VulnerabilitiesPageProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function VulnerabilitiesPage({}: VulnerabilitiesPageProps) {
  document.title = "Vulnerabilities · Mondoo";

  return (
    <Box>
      <Outlet />
    </Box>
  );
}
