import { ReactNode, createContext, useContext } from "react";
import { PaletteMode, ThemeOptions } from "@mui/material";
import { useViewer } from "~/providers/viewer";
import {
  darkColorBlindTheme,
  darkTheme,
  lightColorBlindTheme,
  lightTheme,
  printTheme,
} from "~/themes";

type ColorModeProviderProps = {
  children: ReactNode;
};

type ColorModeContextTypes = {
  mode: PaletteMode;
  colorBlind: string | undefined; // true / false in key:value pair
  getThemeFromProvider: () => ThemeOptions;
};

const ColorModeContext = createContext<ColorModeContextTypes>({
  mode: "dark",
  colorBlind: "false",
  getThemeFromProvider(): ThemeOptions {
    return darkTheme;
  },
});

function ColorModeProvider({ children }: ColorModeProviderProps) {
  const { viewerSettings } = useViewer();
  const mode = viewerSettings?.colormode || "dark";
  const colorBlind = viewerSettings?.colorblind;

  const getThemeFromProvider = (): ThemeOptions => {
    if (window.location.pathname === "/print") return printTheme;
    if (mode === "light") {
      if (colorBlind === "true") {
        return lightColorBlindTheme;
      }
      return lightTheme;
    } else if (mode === "dark") {
      if (colorBlind === "true") {
        return darkColorBlindTheme;
      }
      return darkTheme;
    } else {
      return darkTheme;
    }
  };

  return (
    <ColorModeContext.Provider
      value={{ mode, colorBlind, getThemeFromProvider }}
    >
      {children}
    </ColorModeContext.Provider>
  );
}

function useColorMode() {
  return useContext(ColorModeContext);
}

export { ColorModeProvider, useColorMode };
