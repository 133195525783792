import React from "react";
import {
  alpha,
  Box,
  ButtonProps,
  TableCell,
  Typography,
  useTheme,
} from "@mui/material";
import { Flex, LoadingButton } from "~/components/ui-library";
import { TrendingUpIcon } from "~/components/icons/mondoo/trending-up-filled";
import { CompletionBar } from "~/pages/compliance/completion-bar";

type CloseCaseButtonCellProps = {
  isLoading: boolean;
  isOpen: boolean;
  onClick: ButtonProps["onClick"];
  percentage: number;
};

export const CloseCaseButtonCell = ({
  isLoading,
  isOpen,
  onClick,
  percentage,
}: CloseCaseButtonCellProps) => {
  const theme = useTheme();

  return (
    <TableCell>
      <Flex
        flex="1 0 auto"
        justifyContent="flex-end"
        sx={{ pr: 3, display: "none" }}
        alignItems="center"
        className="closeCase"
        gap={1}
      >
        <Flex gap={1}>
          <TrendingUpIcon sx={{ color: "primary.main" }} />
          <Typography fontWeight={700}>{percentage}%</Typography>
        </Flex>
        <LoadingButton
          variant="outlined"
          loading={isLoading}
          color="primary"
          onClick={onClick}
          data-testid="close-case"
          buttonText={isOpen ? "Close Case" : "Closed"}
          sx={{
            background: (theme) =>
              isOpen
                ? alpha(theme.palette.primary.main, 0.2)
                : alpha(theme.palette.unknown.main, 0.2),
            borderColor: (theme) =>
              isOpen ? theme.palette.primary.main : theme.palette.text.disabled,
            color: isOpen ? "text.primary" : "text.disabled",
            whiteSpace: "nowrap",
          }}
          disabled={!isOpen}
        />
      </Flex>
      <Flex
        flex="1 0 auto"
        justifyContent="flex-end"
        sx={{ pr: 3 }}
        alignItems="center"
        className="progressBar"
        gap={1}
      >
        <Box width="176px">
          <CompletionBar
            {...{
              percent: percentage,
              height: theme.spacing(1),
              tooltip: `Progress: ${percentage}%`,
            }}
          />
        </Box>
      </Flex>
    </TableCell>
  );
};
