import React from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Box, Grid } from "@mui/material";
import { CaseProgress } from "~/components/cases/components/CaseDetailsPage/components";

type CaseActivitiesSectionProps = {
  fixedRefs: number;
  totalRefs: number;
};

export const CaseActivitiesSection = ({
  fixedRefs,
  totalRefs,
}: CaseActivitiesSectionProps) => {
  return (
    <Grid item xs={12}>
      <SectionHeading heading="Case Activity" />
      <Box className="section-content">
        <CaseProgress fixedRefs={fixedRefs} totalRefs={totalRefs} />
      </Box>
    </Grid>
  );
};
