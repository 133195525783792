import {
  Box,
  Button,
  Link,
  Paper,
  Typography,
  Divider,
  Grid,
} from "@mui/material";
import { Code } from "~/components/code";
import { LiveHelpIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { stackUrl } from "~/pages/integration-setup";
import { DateString } from "~/pages/integration-managed-agent";
import { AgentStatus, LargeAgentStatusTag } from "~/components/score-card";
import TemplateSvg from "~/images/template.svg";
import ParametersSvg from "~/images/parameters.svg";
import RegionSvg from "~/images/region.svg";
import ManagedExecutionSvg from "~/images/managed-execution.svg";
import { Config } from "~/configuration_provider";
import { AwsIntegration } from "~/pages/integrations/cloud-security/aws/integration-aws-account-serverless";

export type AWSOrgSetupGuideProps = {
  item: AwsIntegration;
};

// item is type AWSIntegration
export function AWSOrgSetupGuide({ item }: AWSOrgSetupGuideProps) {
  // https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/using-regions-availability-zones.html
  const showRegionAlias = (region: string): string => {
    switch (region) {
      case "us-east-1":
        return "US East (N.Virginia)";
      case "us-east-2":
        return "US East (Ohio)";
      case "us-west-1":
        return "US West (N.California)";
      case "us-west-2":
        return "US West (Oregon)";
      case "af-south-1":
        return "Africa (Cape Town)";
      case "ap-east-1":
        return "Asia Pacific (Hong Kong)";
      case "ap-southeast-3":
        return "Asia Pacific (Jakarta)";
      case "ap-south-1":
        return "Asia Pacific (Mumbai)";
      case "ap-northeast-3":
        return "Asia Pacific (Osaka)";
      case "ap-northeast-2":
        return "Asia Pacific (Seoul)";
      case "ap-southeast-1":
        return "Asia Pacific (Singapore)";
      case "ap-southeast-2":
        return "Asia Pacific (Sydney)";
      case "ap-northeast-1":
        return "Asia Pacific (Tokyo)";
      case "ca-central-1":
        return "Canada (Central)";
      case "eu-central-1":
        return "Europe (Frankfurt)";
      case "eu-west-1":
        return "Europe (Ireland)";
      case "eu-west-2":
        return "Europe (London)";
      case "eu-south-1":
        return "Europe (Milan)";
      case "eu-west-3":
        return "Europe (Paris)";
      case "eu-north-1":
        return "Europe (Stockholm)";
      case "me-south-1":
        return "Middle East (Bahrain)";
      case "sa-east-1":
        return "South America (São Paulo)";
    }
    return "";
  };

  return (
    <Paper>
      <Box
        p={3}
        sx={{
          backgroundColor: "background.light",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
      >
        <Typography fontWeight="bold">Finalize your AWS Integration</Typography>
      </Box>
      <Box p={3}>
        <Box pb={3}>
          <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
            {item.name}
          </Typography>
          <Box>
            <Typography lineHeight="20px" mb={3}>
              To complete the installation, please launch the CloudFormation
              stack on AWS and follow the setup guide below.
            </Typography>

            <Typography lineHeight="20px">
              If you are still not sure how to proceed, refer to the Hints{" "}
              <LiveHelpIcon sx={{ mb: -1 }} /> and{" "}
              <Link
                href="https://mondoo.com/docs/platform/infra/cloud/aws/aws-overview/"
                target="_blank"
              >
                check our AWS documentation
              </Link>
              .
            </Typography>
          </Box>
          <Button
            component="a"
            variant="contained"
            color="primary"
            endIcon={<OpenInNewIcon />}
            href={stackUrl(
              item.configurationOptions.cloudFormationTemplateUrl,
              item.configurationOptions.originAWSAccountId,
              item.configurationOptions.region,
              item.mrn,
              item.token,
              item.configurationOptions.snsEndpoint,
              item.configurationOptions.isOrganization,
            )}
            target="_blank"
            sx={{ m: 0, mt: 3, textTransform: "none", fontWeight: 600 }}
          >
            Launch AWS CloudFormation
          </Button>
        </Box>
        <Divider sx={{ py: 2 }} />
        <Box>
          <Box p={3}>
            <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>
              Setup Guide
            </Typography>
          </Box>
          {/* step 1*/}
          <Box pb={4}>
            <Command
              number={1}
              tooltip={
                <Box
                  component="img"
                  src={TemplateSvg}
                  alt="template url image"
                />
              }
              options={{ fontSize: { xs: 16 } }}
            >
              Specify the template as Amazon S3 URL
            </Command>
            <Box px={6}>
              <Typography
                color="text.secondary"
                lineHeight="20px"
                sx={{ pt: 1, pb: 3 }}
              >
                Copy and paste the URL below. Keep all other default values.
              </Typography>

              <Typography
                fontWeight="bold"
                color="text.secondary"
                lineHeight="18.75px"
                mb={1}
              >
                Amazon S3 URL
              </Typography>
              <Code className="shell" copyButton style={{ margin: 0 }}>
                {item.configurationOptions.cloudFormationTemplateUrl}
              </Code>
            </Box>
          </Box>
          {/* end step 1*/}

          {/* step 2*/}
          <Box pb={4}>
            <Command
              number={2}
              tooltip={
                <Box
                  component="img"
                  src={ParametersSvg}
                  alt="parameters image"
                />
              }
              options={{ fontSize: { xs: 16 } }}
            >
              Fill in the Stack Name (we suggest "mondoo") and provide the
              integration Mrn and Token under Parameters
            </Command>
            <Box px={6}>
              <Typography
                color="text.secondary"
                lineHeight="20px"
                sx={{ pt: 1, pb: 3 }}
              >
                Use the provided values below for the corresponding fields.
              </Typography>

              <Typography
                fontWeight="bold"
                color="text.secondary"
                lineHeight="18.75px"
                mb={1}
              >
                MondooIntegrationMrn
              </Typography>
              <Code className="shell" copyButton style={{ margin: 0 }}>
                {item.mrn}
              </Code>
              <Typography
                fontWeight="bold"
                color="text.secondary"
                lineHeight="18.75px"
                mt={3}
                mb={1}
              >
                MondooToken
              </Typography>
              <Code className="shell" copyButton style={{ margin: 0 }}>
                {item.token}
              </Code>
              <Typography
                fontWeight="bold"
                color="text.secondary"
                lineHeight="18.75px"
                mt={3}
                mb={1}
              >
                OriginAwsAccount
              </Typography>
              <Code className="shell" copyButton style={{ margin: 0 }}>
                {Config.VITE_MONDOO_ACCOUNT_ID}
              </Code>
            </Box>
          </Box>
          {/* end step 2*/}

          {/* step 3*/}
          <Box pb={4}>
            <Command
              number={3}
              tooltip={
                <Box
                  component="img"
                  src={ManagedExecutionSvg}
                  alt="managed execution image"
                />
              }
              options={{ fontSize: { xs: 16 } }}
            >
              Add your own Tags if desired and set Execution Configuration to
              “Active”
            </Command>
            <Box px={6}>
              <Typography
                color="text.secondary"
                lineHeight="20px"
                sx={{ pt: 1 }}
              >
                When asked how to configure “Managed Execution”, choose
                “Active”. Advanced users may choose “Inactive” if necessary.
              </Typography>
            </Box>
          </Box>
          {/* end step 3*/}

          {/* step 4*/}
          <Box pb={4}>
            <Command
              number={4}
              tooltip={
                <Box
                  component="img"
                  src={RegionSvg}
                  alt="region selection image"
                />
              }
              options={{ fontSize: { xs: 16 } }}
            >
              Set “Region” to the same region you configured when creating the
              integration
            </Command>
            <Box px={6}>
              <Typography
                color="text.secondary"
                lineHeight="20px"
                sx={{ pt: 1, pb: 3 }}
              >
                Setup in only one region. This is the region you configured
                Mondoo with:
              </Typography>

              <Typography
                fontWeight="bold"
                color="text.secondary"
                lineHeight="18.75px"
                mb={1}
              >
                Region
              </Typography>
              <Code className="shell" style={{ margin: 0 }}>
                {showRegionAlias(item.configurationOptions.region)}
              </Code>
            </Box>
          </Box>
          {/* end step 4*/}

          {/* step 5*/}
          <Box pb={4}>
            <Command number={5} options={{ fontSize: { xs: 16 } }}>
              Confirm with CloudFormation
            </Command>
            <Grid container px={6}>
              <Grid item xs={12} md={8}>
                <Typography
                  color="text.secondary"
                  lineHeight="20px"
                  sx={{ pt: 1 }}
                >
                  If your setup was successful, you will see the status change
                  to active.
                  <Typography component="span" display="block" mt={3}>
                    {" "}
                    This process usually takes under 5 minutes.
                  </Typography>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                display="flex"
                sx={{
                  justifyContent: {
                    xs: "flex-start",
                    md: "flex-end",
                  },
                  pt: {
                    xs: 2,
                    md: 0,
                  },
                }}
              >
                <LargeAgentStatusTag
                  status={item.status as keyof typeof AgentStatus}
                />
              </Grid>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                mt={2}
              >
                <Box>
                  <Typography
                    color="text.secondary"
                    fontWeight={500}
                    fontSize={14}
                    mb={1}
                  >
                    Created
                  </Typography>
                  <Typography
                    color="text.secondary"
                    fontWeight={500}
                    fontSize={14}
                  >
                    Last Ping Time
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    color="text.secondary"
                    fontWeight={600}
                    textAlign="right"
                    fontSize={14}
                    mb={1}
                  >
                    {DateString(item.created)}
                  </Typography>
                  {item.lastCheckin && (
                    <Typography
                      color="text.secondary"
                      fontWeight={600}
                      textAlign="right"
                      fontSize={14}
                    >
                      {DateString(item.lastCheckin)}
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
          {/* end step 5*/}
        </Box>
      </Box>
    </Paper>
  );
}
