import { Fragment, useEffect, useState } from "react";
import {
  ListItem,
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Chip,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import { StrengthResult, passwordStrength } from "~/lib/password-strength";
import { useSnackbar } from "notistack";
import {
  AuthErrorCodes,
  AuthProvider,
  EmailAuthProvider,
  GithubAuthProvider,
  GoogleAuthProvider,
  linkWithPopup,
  linkWithRedirect,
  OAuthProvider,
  reauthenticateWithPopup,
  reauthenticateWithRedirect,
  unlink,
  User,
  UserInfo,
} from "firebase/auth";
import { FirebaseError } from "firebase/app";
import { useAuth } from "~/login/provider";
import {
  GithubIcon,
  MicrosoftIconBW,
  GoogleIcon,
  EmailIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
} from "./icons";
import { ConfigurationPaper, ConfigurationList } from "./configuration-items";
import { isSafari16Plus } from "~/lib/user-agent";

type AvailableProviders = Record<UserInfo["providerId"], string>;

export function AccountManagement() {
  const { enqueueSnackbar } = useSnackbar();

  const providers: AvailableProviders = {
    "microsoft.com": "Microsoft",
    "github.com": "GitHub",
    "google.com": "Google",
    password: "Email & Password",
  };

  const sortProviders = (a: [string, string], b: [string, string]) => {
    const sortOrder = ["google.com", "github.com", "microsoft.com", "password"];
    return sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0]);
  };

  useEffect(() => {
    const pendingMsg = window.sessionStorage.getItem("pendingAuthMessage");
    if (pendingMsg) {
      try {
        const snackbarMsg = JSON.parse(pendingMsg);
        if (snackbarMsg.message && snackbarMsg.variant) {
          enqueueSnackbar(snackbarMsg.message, {
            variant: snackbarMsg.variant,
          });
        }
      } catch (error) {
        console.error("Failed to parse pendingAuthMessage", error);
      } finally {
        window.sessionStorage.removeItem("pendingAuthMessage");
      }
    }
  }, []);

  return (
    <ConfigurationPaper>
      <ConfigurationList title="Connected Accounts">
        {Object.entries(providers)
          .sort(sortProviders)
          .map(([providerId, displayName], i) => (
            <ConnectedAccount
              key={providerId}
              providerId={providerId}
              displayName={displayName}
              providers={providers}
            />
          ))}
      </ConfigurationList>
    </ConfigurationPaper>
  );
}

type ConnectedAccountProps = {
  providerId: UserInfo["providerId"];
  displayName: UserInfo["displayName"];
  providers: AvailableProviders;
};

function ConnectedAccount({
  providerId,
  displayName,
  providers,
}: ConnectedAccountProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { state, changePassword } = useAuth();

  const activeProviderId = state.idTokenResult?.signInProvider || "";
  const userInfo = state.user?.providerData.find(
    (p) => p.providerId === providerId,
  );
  const isConnected = userInfo !== undefined;
  const isSignedIn = providerId === activeProviderId;
  const buttonText = isConnected ? "Disconnect" : "Connect";
  const [open, setOpen] = useState(false);

  type EmailAuthInputs = {
    email: string;
    password: string;
    passwordConfirm: string;
    passwordCurrent: string;
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    reset,
  } = useForm<EmailAuthInputs>({ mode: "onChange" });

  const getIcon = () => {
    switch (providerId) {
      case "github.com":
        return <GithubIcon />;
      case "microsoft.com":
        return <MicrosoftIconBW />;
      case "google.com":
        return <GoogleIcon />;
      case "password":
        return <EmailIcon />;
    }
  };

  const getAuthProvider = (
    providerId: UserInfo["providerId"],
  ):
    | GithubAuthProvider
    | GoogleAuthProvider
    | OAuthProvider
    | EmailAuthProvider
    | undefined => {
    switch (providerId) {
      case "google.com":
        return new GoogleAuthProvider();
      case "github.com":
        return new GithubAuthProvider();
      case "microsoft.com":
        return new OAuthProvider("microsoft.com");
      case "password":
        return new EmailAuthProvider();
    }
  };

  const reauthenticateWithProvider = (user: User, provider: AuthProvider) => {
    // Temporary work around for:
    // https://github.com/firebase/firebase-js-sdk/issues/6716
    if (isSafari16Plus()) {
      return reauthenticateWithPopup(user, provider);
    }
    return reauthenticateWithRedirect(user, provider);
  };

  const linkWithProvider = (user: User, provider: AuthProvider) => {
    // Temporary work around for:
    // https://github.com/firebase/firebase-js-sdk/issues/6716
    if (isSafari16Plus()) {
      return linkWithPopup(user, provider);
    }
    return linkWithRedirect(user, provider);
  };

  const handleConnectChange = async (
    providerId: UserInfo["providerId"],
    isConnected: boolean,
  ) => {
    if (state.user) {
      if (isConnected) {
        const provider = getAuthProvider(providerId);
        if (provider) {
          try {
            await linkWithProvider(state.user, provider);
          } catch (error) {
            const message = `Failed to connect ${providers[providerId]} account`;
            console.error(message, error);
            enqueueSnackbar(message, { variant: "error" });
          }
        }
      } else {
        try {
          await unlink(state.user, providerId);
          window.location.reload();
        } catch (error) {
          const message = `Failed to disconnect ${providers[providerId]} account`;
          console.error(message, error);
          enqueueSnackbar(message, { variant: "error" });
        }
      }
    }
  };

  const handleConnectEmail = async (email: string, password: string) => {
    if (state.user) {
      const provider = getAuthProvider(activeProviderId);
      if (provider) {
        try {
          // Create email cred
          const emailCredential = EmailAuthProvider.credential(email, password);
          // Store email cred for retrieval within getRedirectResult
          window.sessionStorage.setItem(
            "pendingEmailCredential",
            JSON.stringify(emailCredential.toJSON()),
          );
          // Reauthenticate with the currently logged in provider
          await reauthenticateWithProvider(state.user, provider);
        } catch (error) {
          const message = "Failed to connect email & password account";
          console.error(message, error);
          enqueueSnackbar(message, {
            variant: "error",
          });
        }
      }
    }
  };

  const handleUpdatePassword = async (
    currentPassword: string,
    password: string,
  ) => {
    try {
      await changePassword(currentPassword, password);
      enqueueSnackbar("Successfully updated your password", {
        variant: "success",
      });
      reset();
    } catch (error) {
      let message = "Failed to update your password.";
      if (error instanceof FirebaseError) {
        switch (error.code) {
          case AuthErrorCodes.INVALID_PASSWORD:
            message = message + " Current password is incorrect.";
        }
      }
      console.error(message, error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    }
  };

  const handleFormSubmit: SubmitHandler<EmailAuthInputs> = (data) => {
    const { email, password, passwordCurrent } = data;
    if (!isConnected) {
      handleConnectEmail(email, password);
    } else {
      handleUpdatePassword(passwordCurrent, password);
    }
  };

  const handleClick = () => {
    handleConnectChange(providerId, !isConnected);
  };

  const handleExpandClick = () => {
    setOpen(!open);
  };

  const handleCancelClick = () => {
    setOpen(false);
    reset();
  };

  const COMPLEXITY_LABELS: Record<StrengthResult["score"], string> = {
    0: "very weak",
    1: "weak",
    2: "medium",
    3: "strong",
    4: "very strong",
  };

  const getComplexityLabel = (result: StrengthResult) => {
    return COMPLEXITY_LABELS[result.score];
  };

  const getCrackTimeLabel = (result: StrengthResult) => {
    return String(result.crackTimesDisplay.offlineSlowHashing1e4PerSecond);
  };

  const getComplexityHelperText = (result: StrengthResult) => {
    const complexityLabel = getComplexityLabel(result);
    const crackTimeLabel = getCrackTimeLabel(result);
    return `Password complexity is ${complexityLabel}. Time to crack is ${crackTimeLabel}.`;
  };

  const passwordValue = watch("password");
  const passwordComplexity = passwordValue
    ? passwordStrength(passwordValue)
    : undefined;
  const complexityHelperText = passwordComplexity
    ? getComplexityHelperText(passwordComplexity)
    : undefined;

  return (
    <Fragment>
      <ListItem sx={{ justifyContent: "space-between", py: 1.5 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          {getIcon()}
          <Box sx={{ pl: 2 }}>
            <Typography fontWeight={600} component="span">
              {displayName}
            </Typography>
            {isSignedIn && (
              <Chip
                label="Active Session"
                variant="outlined"
                size="small"
                color="primary"
                sx={{ ml: 1 }}
              />
            )}
            {isConnected && (
              <Typography color="text.secondary">
                Signed in as {userInfo.email}
              </Typography>
            )}
          </Box>
        </Box>
        <Box alignSelf="center">
          {providerId !== "password" && (
            <Button
              variant="contained"
              color={isConnected ? "error" : "primary"}
              onClick={handleClick}
              disabled={isSignedIn}
            >
              {buttonText}
            </Button>
          )}
          {providerId === "password" && (
            <>
              <Button
                variant="contained"
                color="primary"
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={handleExpandClick}
              >
                Manage
              </Button>
              {isConnected && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleClick}
                  disabled={isSignedIn}
                  sx={{ ml: 1 }}
                >
                  Disconnect
                </Button>
              )}
            </>
          )}
        </Box>
      </ListItem>
      {open && (
        <Box
          component="form"
          onSubmit={handleSubmit(handleFormSubmit)}
          sx={{ px: 2 }}
          autoComplete="off"
        >
          {!isConnected && (
            <FormControl fullWidth margin="normal">
              <TextField
                id="email-input"
                label="Email"
                variant="outlined"
                {...register("email", {
                  disabled: isConnected,
                  required: { value: true, message: "Email is required." },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </FormControl>
          )}
          {isConnected && (
            <FormControl fullWidth margin="normal">
              <TextField
                id="current-password-input"
                label="Current Password"
                variant="outlined"
                type="password"
                {...register("passwordCurrent", {
                  required: {
                    value: true,
                    message: "Current password is required.",
                  },
                })}
                error={Boolean(errors.passwordCurrent)}
                helperText={errors.passwordCurrent?.message}
              />
            </FormControl>
          )}
          <FormControl fullWidth margin="normal">
            <TextField
              id="password-input"
              label="Password"
              variant="outlined"
              type="password"
              {...register("password", {
                required: { value: true, message: "Password is required." },
                validate: {
                  minComplexity: (value) => {
                    const result = passwordStrength(value);
                    return result.score > 2 || getComplexityHelperText(result);
                  },
                },
              })}
              error={Boolean(errors.password)}
              helperText={errors.password?.message || complexityHelperText}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              id="password-confirm-input"
              label="Password Confirm"
              variant="outlined"
              type="password"
              {...register("passwordConfirm", {
                required: {
                  value: true,
                  message: "Password confirmation is required.",
                },
                validate: {
                  mustMatch: (value) =>
                    value === getValues("password") ||
                    "Password confirmation must match password.",
                },
              })}
              error={Boolean(errors.passwordConfirm)}
              helperText={errors.passwordConfirm?.message}
            />
          </FormControl>
          <Box sx={{ display: "flex", py: 2 }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCancelClick}
              sx={{ ml: "auto" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ ml: 1 }}
            >
              {isConnected ? "Update Password" : "Connect"}
            </Button>
          </Box>
        </Box>
      )}
    </Fragment>
  );
}
