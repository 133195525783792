import React, { Dispatch, SetStateAction } from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Paper } from "@mui/material";
import { Pagination, PaginationRange } from "~/components/pagination";
import { EmptySection } from "~/components/vulnerabilities";
import { CveNodeFieldsFragment } from "~/operations";
import { VulnerabilitiesTable } from "./VulnerabilitiesTable";

type SoftwareVulnerabilitiesProps = {
  totalCount: number;
  cves: CveNodeFieldsFragment[];
  setCvePageItems: Dispatch<SetStateAction<PaginationRange>>;
};

export const SoftwareVulnerabilities = ({
  cves,
  totalCount,
  setCvePageItems,
}: SoftwareVulnerabilitiesProps) => {
  const hasCves = cves.length > 0;

  return (
    <>
      <SectionHeading heading="Vulnerabilities" />
      {hasCves ? (
        <Paper sx={{ my: 3 }}>
          <VulnerabilitiesTable cves={cves} />
          <Pagination
            totalCount={totalCount}
            setPageItems={setCvePageItems}
            customPageSizes={[3, 10, 25, 50]}
          />
        </Paper>
      ) : (
        <EmptySection
          id="software-vulnerabilities-empty"
          text="There are currently no known vulnerabilities for this software"
        />
      )}
    </>
  );
};
