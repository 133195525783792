import { createSvgIcon } from "@mui/material";

export const AzurePipelinesIcon = createSvgIcon(
  <svg viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 26.9999H2.69884V33.2997H8.99862V35.9998H0V26.9999Z"
      fill="#91B0F2"
    />
    <path
      d="M0 10.5V18.75L5.25291 24.1551L7.49885 21.7495L14.2491 28.4997L11.9982 30.7457L17.2474 35.9948H25.4973C25.8951 35.9948 26.2766 35.8368 26.5578 35.5556C26.8391 35.2743 26.9971 34.8928 26.9971 34.4951V22.492L13.4992 9.00023H1.49606C1.09894 9.00121 0.718418 9.15966 0.437959 9.44081C0.1575 9.72197 -1.21655e-06 10.1029 0 10.5Z"
      fill="#0F46BD"
    />
    <path
      d="M6.49398 21.6531C6.24869 21.4078 6.09601 21.0851 6.06194 20.7399C6.02787 20.3947 6.11452 20.0483 6.30713 19.7598L18.6072 1.33566C18.8812 0.925267 19.2523 0.588812 19.6874 0.356123C20.1226 0.123434 20.6084 0.00169909 21.1019 0.00170898H34.5008C34.8986 0.00170898 35.2801 0.15972 35.5613 0.440981C35.8426 0.722243 36.0006 1.10372 36.0006 1.50148V14.8967C36.0006 15.3902 35.8789 15.876 35.6462 16.3112C35.4135 16.7463 35.077 17.1174 34.6666 17.3914L16.2425 29.6915C15.954 29.8841 15.6076 29.9707 15.2624 29.9367C14.9172 29.9026 14.5945 29.7499 14.3492 29.5046L6.49398 21.6531Z"
      fill="#2560E0"
    />
    <path
      d="M7.48047 26.2999L18.9329 14.8475L21.1504 17.065L9.69919 28.5187L7.48047 26.2999Z"
      fill="#0A44C2"
    />
    <path
      d="M7.48047 26.2999L18.9329 14.8475L21.1504 17.065L9.69919 28.5187L7.48047 26.2999Z"
      fill="#729AF2"
    />
    <path
      d="M11.53 26.6873L9.3125 24.4699L18.9348 14.8488L21.1523 17.0663L11.53 26.6873Z"
      fill="#4C80F0"
    />
    <path
      d="M26.9954 13.5007C27.8853 13.5007 28.7552 13.2368 29.4951 12.7424C30.235 12.248 30.8117 11.5453 31.1522 10.7232C31.4928 9.90106 31.5819 8.9964 31.4083 8.12362C31.2347 7.25083 30.8061 6.44913 30.1769 5.81989C29.5477 5.19065 28.746 4.76214 27.8732 4.58853C27.0004 4.41492 26.0957 4.50402 25.2736 4.84457C24.4515 5.18511 23.7488 5.7618 23.2544 6.5017C22.76 7.24161 22.4961 8.11151 22.4961 9.00139C22.4961 10.1947 22.9701 11.3391 23.8139 12.1829C24.6577 13.0267 25.8021 13.5007 26.9954 13.5007Z"
      fill="#0A44C2"
    />
    <path
      d="M26.9954 13.5007C27.8853 13.5007 28.7552 13.2368 29.4951 12.7424C30.235 12.248 30.8117 11.5453 31.1522 10.7232C31.4928 9.90106 31.5819 8.9964 31.4083 8.12362C31.2347 7.25083 30.8061 6.44913 30.1769 5.81989C29.5477 5.19065 28.746 4.76214 27.8732 4.58853C27.0004 4.41492 26.0957 4.50402 25.2736 4.84457C24.4515 5.18511 23.7488 5.7618 23.2544 6.5017C22.76 7.24161 22.4961 8.11151 22.4961 9.00139C22.4961 10.1947 22.9701 11.3391 23.8139 12.1829C24.6577 13.0267 25.8021 13.5007 26.9954 13.5007Z"
      fill="#91B0F2"
    />
    <g opacity="0.2">
      <path
        d="M5.25291 24.1552L7.06451 22.2186L9.3154 24.4695L7.48029 26.2997L9.69901 28.5184L11.5292 26.6882L13.7912 28.9539L11.9982 30.7458L17.2474 35.9949H25.4973C25.8951 35.9949 26.2766 35.8369 26.5578 35.5557C26.8391 35.2744 26.9971 34.8929 26.9971 34.4952V22.4921L34.663 17.3827C35.0733 17.1086 35.4097 16.7376 35.6424 16.3024C35.8751 15.8673 35.9969 15.3815 35.997 14.888V1.50148C35.997 1.10372 35.8389 0.722243 35.5577 0.440981C35.2764 0.15972 34.895 0.00170898 34.4972 0.00170898H21.1032C20.6098 0.00180789 20.124 0.12359 19.6888 0.356269C19.2537 0.588948 18.8826 0.925344 18.6085 1.33566L13.4992 9.00033H1.49606C1.09872 9.00131 0.718017 9.15993 0.437522 9.44135C0.157028 9.72277 -0.000328537 10.104 5.15028e-07 10.5013V18.7501L5.25291 24.1552Z"
        fill="url(#paint0_linear_12866_14627)"
      />
      <path
        d="M2.69884 26.9997H0V35.9995H8.99862V33.2995H2.69884V26.9997Z"
        fill="url(#paint1_linear_12866_14627)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_12866_14627"
        x1="18.0106"
        y1="0.0107163"
        x2="18.0106"
        y2="36.0089"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12866_14627"
        x1="4.51126"
        y1="0.0103578"
        x2="4.51126"
        y2="36.0085"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>,
  "Azure Pipelines",
);
