import { Chip, useTheme, ChipProps } from "@mui/material";

type ReviewFlagProps = ChipProps;

const ReviewFlag = ({ ...restProps }: ReviewFlagProps) => {
  const theme = useTheme();

  return (
    <Chip
      label="REVIEW"
      variant="outlined"
      size="small"
      style={{
        borderRadius: 4,
        border: `1px solid ${theme.palette.warning.main}`,
        color: theme.palette.warning.main,
      }}
      {...restProps}
    />
  );
};

export default ReviewFlag;
