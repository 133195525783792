import {
  alpha,
  Box,
  Typography,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { getColor } from "~/lib/colors";
import { useColorMode } from "~/providers/color-mode";

export type Flag = {
  id: string;
  title: string;
};

export function ContentFlag({
  flag,
  color,
  layout,
}: {
  flag: string | null;
  color?: string;
  layout?: SxProps<Theme>;
}) {
  const theme = useTheme();
  const { mode } = useColorMode();
  // if there's no flag to report, we render nothing at all
  if (!flag) return <Box />;

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        py: 0.5,
        px: 1,
        border: "1px solid",
        borderColor: (theme) => (color ? getColor(theme, color) : "inherit"),
        borderRadius: 1,
        color: (theme) => (color ? getColor(theme, color) : "inherit"),
        ...(mode === "light"
          ? {
              color: "warning.dark",
              background: alpha(theme.palette.warning.main, 0.5),
            }
          : {}),
        ...layout,
      }}
    >
      <Typography
        fontSize={12}
        fontWeight={600}
        lineHeight="14px"
        sx={{ textTransform: "uppercase" }}
      >
        {flag}
      </Typography>
    </Box>
  );
}
