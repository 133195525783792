import { createSvgIcon } from "@mui/material";

export const MSEdgeIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4534_3341)">
      <path
        d="M20.8605 17.3711C20.5655 17.5244 20.2613 17.6593 19.9496 17.775C18.9627 18.1423 17.9175 18.3287 16.8645 18.325C12.7996 18.325 9.25898 15.532 9.25898 11.9398C9.26427 11.4588 9.39763 10.9879 9.64534 10.5756C9.89306 10.1632 10.2462 9.82431 10.6684 9.59375C6.99023 9.74844 6.04492 13.5812 6.04492 15.8242C6.04492 22.1836 11.8973 22.8195 13.1605 22.8195C13.8395 22.8195 14.8621 22.6219 15.4809 22.4242L15.5926 22.3898C17.9605 21.5715 19.9791 19.9712 21.316 17.8523C21.3549 17.788 21.3715 17.7126 21.3635 17.6379C21.3554 17.5631 21.3231 17.493 21.2714 17.4384C21.2197 17.3838 21.1515 17.3477 21.0773 17.3355C21.0031 17.3233 20.927 17.3358 20.8605 17.3711Z"
        fill="url(#paint0_linear_4534_3341)"
      />
      <path
        opacity="0.35"
        d="M20.8605 17.3711C20.5655 17.5244 20.2613 17.6593 19.9496 17.775C18.9627 18.1423 17.9175 18.3287 16.8645 18.325C12.7996 18.325 9.25898 15.532 9.25898 11.9398C9.26427 11.4588 9.39763 10.9879 9.64534 10.5756C9.89306 10.1632 10.2462 9.82431 10.6684 9.59375C6.99023 9.74844 6.04492 13.5812 6.04492 15.8242C6.04492 22.1836 11.8973 22.8195 13.1605 22.8195C13.8395 22.8195 14.8621 22.6219 15.4809 22.4242L15.5926 22.3898C17.9605 21.5715 19.9791 19.9712 21.316 17.8523C21.3549 17.788 21.3715 17.7126 21.3635 17.6379C21.3554 17.5631 21.3231 17.493 21.2714 17.4384C21.2197 17.3838 21.1515 17.3477 21.0773 17.3355C21.0031 17.3233 20.927 17.3358 20.8605 17.3711Z"
        fill="url(#paint1_radial_4534_3341)"
      />
      <path
        d="M10.0833 21.7367C9.31832 21.2616 8.65526 20.6395 8.13249 19.9063C7.53551 19.0892 7.12459 18.1513 6.92855 17.1585C6.73251 16.1658 6.75611 15.1421 6.99768 14.1594C7.23926 13.1767 7.69297 12.2588 8.32696 11.4701C8.96095 10.6814 9.75987 10.0409 10.6676 9.59376C10.9426 9.46486 11.3981 9.24142 12.0083 9.25001C12.4376 9.25333 12.8604 9.35591 13.2435 9.54971C13.6267 9.74352 13.9598 10.0233 14.2169 10.3672C14.5626 10.8322 14.7522 11.3948 14.7583 11.9742C14.7583 11.957 16.8637 5.13361 7.88327 5.13361C4.11061 5.13361 1.00827 8.70861 1.00827 11.8539C0.993263 13.5154 1.34848 15.1594 2.04811 16.6664C3.19389 19.1062 5.1956 21.0404 7.67327 22.1018C10.1509 23.1632 12.9321 23.2779 15.4887 22.4242C14.593 22.7071 13.6466 22.7922 12.7148 22.6735C11.783 22.5548 10.888 22.2352 10.0919 21.7367H10.0833Z"
        fill="url(#paint2_linear_4534_3341)"
      />
      <path
        opacity="0.41"
        d="M10.0833 21.7367C9.31832 21.2616 8.65526 20.6395 8.13249 19.9063C7.53551 19.0892 7.12459 18.1513 6.92855 17.1585C6.73251 16.1658 6.75611 15.1421 6.99768 14.1594C7.23926 13.1767 7.69297 12.2588 8.32696 11.4701C8.96095 10.6814 9.75987 10.0409 10.6676 9.59376C10.9426 9.46486 11.3981 9.24142 12.0083 9.25001C12.4376 9.25333 12.8604 9.35591 13.2435 9.54971C13.6267 9.74352 13.9598 10.0233 14.2169 10.3672C14.5626 10.8322 14.7522 11.3948 14.7583 11.9742C14.7583 11.957 16.8637 5.13361 7.88327 5.13361C4.11061 5.13361 1.00827 8.70861 1.00827 11.8539C0.993263 13.5154 1.34848 15.1594 2.04811 16.6664C3.19389 19.1062 5.1956 21.0404 7.67327 22.1018C10.1509 23.1632 12.9321 23.2779 15.4887 22.4242C14.593 22.7071 13.6466 22.7922 12.7148 22.6735C11.783 22.5548 10.888 22.2352 10.0919 21.7367H10.0833Z"
        fill="url(#paint3_radial_4534_3341)"
      />
      <path
        d="M14.0969 13.7875C14.0195 13.8734 13.8047 14.0023 13.8047 14.2687C13.8047 14.4922 13.9508 14.7156 14.2172 14.8961C15.4461 15.7555 17.775 15.6352 17.7836 15.6352C18.6997 15.6346 19.5989 15.3882 20.3875 14.9219C21.1814 14.4575 21.8401 13.7937 22.2984 12.9963C22.7566 12.1989 22.9985 11.2955 23 10.3758C23.0258 8.45078 22.3125 7.17031 22.0289 6.60312C20.1984 3.04531 16.2711 1 12 1C9.10922 0.999714 6.33452 2.13738 4.27601 4.16694C2.2175 6.19651 1.04065 8.95482 1 11.8453C1.04297 8.70859 4.1625 6.17344 7.875 6.17344C8.17578 6.17344 9.89453 6.19922 11.4844 7.03281C12.6013 7.58784 13.5263 8.4649 14.1398 9.55078C14.6641 10.4617 14.7586 11.6219 14.7586 12.0859C14.7586 12.55 14.5266 13.2289 14.0883 13.7961L14.0969 13.7875Z"
        fill="url(#paint4_radial_4534_3341)"
      />
      <path
        d="M14.0969 13.7875C14.0195 13.8734 13.8047 14.0023 13.8047 14.2687C13.8047 14.4922 13.9508 14.7156 14.2172 14.8961C15.4461 15.7555 17.775 15.6352 17.7836 15.6352C18.6997 15.6346 19.5989 15.3882 20.3875 14.9219C21.1814 14.4575 21.8401 13.7937 22.2984 12.9963C22.7566 12.1989 22.9985 11.2955 23 10.3758C23.0258 8.45078 22.3125 7.17031 22.0289 6.60312C20.1984 3.04531 16.2711 1 12 1C9.10922 0.999714 6.33452 2.13738 4.27601 4.16694C2.2175 6.19651 1.04065 8.95482 1 11.8453C1.04297 8.70859 4.1625 6.17344 7.875 6.17344C8.17578 6.17344 9.89453 6.19922 11.4844 7.03281C12.6013 7.58784 13.5263 8.4649 14.1398 9.55078C14.6641 10.4617 14.7586 11.6219 14.7586 12.0859C14.7586 12.55 14.5266 13.2289 14.0883 13.7961L14.0969 13.7875Z"
        fill="url(#paint5_radial_4534_3341)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4534_3341"
        x1="6.04492"
        y1="16.2109"
        x2="21.3762"
        y2="16.2109"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0C59A4" />
        <stop offset="1" stopColor="#114A8B" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_4534_3341"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(14.5098 16.3265) scale(8.19844 7.78852)"
      >
        <stop offset="0.7" stopOpacity="0" />
        <stop offset="0.9" stopOpacity="0.5" />
        <stop offset="1" />
      </radialGradient>
      <linearGradient
        id="paint2_linear_4534_3341"
        x1="14.1223"
        y1="9.55939"
        x2="4.55749"
        y2="19.9836"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1B9DE2" />
        <stop offset="0.2" stopColor="#1595DF" />
        <stop offset="0.7" stopColor="#0680D7" />
        <stop offset="1" stopColor="#0078D4" />
      </linearGradient>
      <radialGradient
        id="paint3_radial_4534_3341"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.063 18.0962) rotate(-81.3844) scale(12.3222 9.95513)"
      >
        <stop offset="0.8" stopOpacity="0" />
        <stop offset="0.9" stopOpacity="0.5" />
        <stop offset="1" />
      </radialGradient>
      <radialGradient
        id="paint4_radial_4534_3341"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(3.22492 5.06622) rotate(92.2906) scale(17.4077 37.0747)"
      >
        <stop stopColor="#35C1F1" />
        <stop offset="0.1" stopColor="#34C1ED" />
        <stop offset="0.2" stopColor="#2FC2DF" />
        <stop offset="0.3" stopColor="#2BC3D2" />
        <stop offset="0.7" stopColor="#36C752" />
      </radialGradient>
      <radialGradient
        id="paint5_radial_4534_3341"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(21.6302 7.64297) rotate(73.7398) scale(8.36172 6.79975)"
      >
        <stop stopColor="#66EB6E" />
        <stop offset="1" stopColor="#66EB6E" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_4534_3341">
        <rect width="22" height="22" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>,
  "MSEdge",
);
