import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Flex, HubCard } from "~/components/ui-library";
import { CompletionBar } from "~/pages/compliance/completion-bar";
import { pluralize } from "~/lib/pluralize";

type CaseProgressProps = {
  fixedRefs: number;
  totalRefs: number;
};

export const CaseProgress = ({ fixedRefs, totalRefs }: CaseProgressProps) => {
  const theme = useTheme();
  const percentage = Math.floor((fixedRefs / totalRefs) * 100) || 0;

  const isInactive = totalRefs === 0;
  return (
    <HubCard {...{ loading: false, title: "Progress", icon: <Box /> }}>
      <Box>
        <Flex
          justifyContent="space-between"
          sx={{
            mt: 2,
          }}
        >
          <Typography
            fontWeight={700}
            fontSize={12}
            color="text.secondary"
            textTransform="uppercase"
          >
            {fixedRefs} / {totalRefs} {pluralize("ASSET", fixedRefs)} FIXED
          </Typography>
          <Typography>{isInactive ? 100 : percentage}%</Typography>
        </Flex>
        <CompletionBar
          percent={percentage || 0}
          height={theme.spacing(2)}
          tooltip={`Completion: ${isInactive ? 100 : percentage || 0}%`}
          inactive={isInactive}
        />
      </Box>
    </HubCard>
  );
};
