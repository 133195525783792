import { Fragment } from "react";
import {
  Box,
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
  TableBody,
} from "@mui/material";
import { getFirewatchColumns } from "~/components/FirewatchPage/FirewatchPageColumns";
import { pageNameByType } from "~/components/FirewatchPage/lib";
import { SearchResultItemEdges } from "~/components/FirewatchPage/types";
import { DataTable } from "~/components/data-table";
import { Loading } from "~/components/loading";
import { Pagination, PaginationProps } from "~/components/pagination";
import { AggregateScoreType, OrderDirection } from "~/operations";
import { SearchResultRow } from ".";

type SearchResultsTableProps = {
  size?: string;
  loading: boolean;
  data: SearchResultItemEdges;
  pageType: AggregateScoreType;
  field: string;
  direction: OrderDirection;
  handleSortClick: (field: string) => void;
  paginationProps?: PaginationProps;
  isOrg?: boolean;
};

export function SearchResultsTable({
  size,
  loading,
  data,
  pageType,
  field,
  direction,
  handleSortClick,
  paginationProps,
  isOrg,
}: SearchResultsTableProps) {
  const tableHeaders = getFirewatchColumns(pageType, size, true, isOrg);

  return (
    <Fragment>
      <DataTable firewatchSize={size}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell
                key={header.field}
                align={header.options?.textAlign}
                width={header.options?.width}
                sx={header.options?.sx}
              >
                {/* sorting not available yet on api */}
                {false ? (
                  <TableSortLabel
                    onClick={() => handleSortClick(header.field)}
                    direction={
                      direction === OrderDirection.Asc ? "asc" : "desc"
                    }
                    active={header.field === field}
                  >
                    {header.label}
                  </TableSortLabel>
                ) : (
                  header.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* If the data is loading, show a loading message */}
          {loading ? (
            <TableRow>
              <TableCell colSpan={tableHeaders.length}>
                <Box sx={{ my: 10 }}>
                  <Loading what={`${pageNameByType(pageType)}`} />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            data?.map((edge, i) => (
              <SearchResultRow
                key={
                  edge?.node?.__typename === "AggregateScore"
                    ? edge?.node.nodeId
                    : i
                }
                row={edge?.node}
                pageType={pageType}
                tableHeaders={tableHeaders}
              />
            ))
          )}
        </TableBody>
      </DataTable>
      {paginationProps?.pageInfo && !loading && (
        <Pagination
          {...paginationProps}
          customPageSizes={[25, 50, 100]}
          isFullTextSearch
        />
      )}
    </Fragment>
  );
}
