const parseBoolean = (
  value: boolean | undefined | string,
): boolean | undefined => {
  try {
    if (typeof value === "boolean") {
      return value;
    } else if (typeof value === "undefined") {
      return undefined;
    } else {
      return value ? JSON.parse(String(value)) : undefined;
    }
  } catch (error) {
    return undefined;
  }
};

export default parseBoolean;
