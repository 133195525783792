import {
  TableCell,
  TableHead as TableHeadMUI,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { CveSummaryOrder, OrderDirection } from "~/operations";
import { Header } from "~/types/table";

type AdvisoriesTableHeadProps = {
  tableHeaders: Header[];
  onSort?: (id: string) => void;
  orderBy?: {
    field: string;
    direction: string;
  };
};

export const TableHead = ({
  tableHeaders,
  orderBy,
  onSort,
}: AdvisoriesTableHeadProps) => {
  return (
    <TableHeadMUI>
      <TableRow>
        {tableHeaders.map((header) => (
          <TableCell
            key={header.id}
            align={header.options?.textAlign}
            width={header.options?.width}
            sx={{ whiteSpace: "nowrap" }}
          >
            {header.sortable !== false ? (
              <TableSortLabel
                onClick={() => onSort?.(header.id)}
                direction={
                  orderBy?.direction === OrderDirection.Asc ? "asc" : "desc"
                }
                active={header.id === orderBy?.field}
              >
                {header.label}
              </TableSortLabel>
            ) : (
              header.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHeadMUI>
  );
};
