import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { FrameworkIcon } from "../framework/framework-icon";
import { ComplianceFramework } from "../compliance-page";
import { useFrameworkCard } from "../hooks/UseFrameworkCard";
import { Clamp } from "~/components/clamp/clamp";

import { Space } from "~/lib/types";

import { ActiveFrameworkCardEditTools } from "./ActiveFrameworkCardEditTools";
import { ActiveFrameworkCardData } from "./ActiveFrameworkCardData";
import { AnimatePresence, motion } from "framer-motion";
import { Flex } from "~/components/ui-library";

type Props = {
  space: Space;
  framework: ComplianceFramework;
};

export const ActiveFrameworkCard = ({ space, framework }: Props) => {
  const { setShowEditTools, showEditTools, data, loading, handle, get } =
    useFrameworkCard({
      framework,
      space,
    });
  const isOfficial = framework.mrn.split("/")[3] !== "spaces";

  return (
    <Grid container component={Paper} sx={{ height: 1 }}>
      <Grid item container xs={9} sx={{ px: 2, pt: 2, pb: 3 }}>
        <Grid
          item
          xs
          sx={{
            display: "flex",
            alignItems: "flex-start",
            pb: 2,
          }}
        >
          <FrameworkIcon iconKey={data.iconKey} />
          <Flex alignItems="center" height={1} sx={{ pl: 2 }}>
            <Clamp lines={2}>
              <Typography
                variant="caption"
                fontWeight={600}
                color="text.secondary"
                sx={{ display: "inline-block", lineHeight: "16px" }}
              >
                {data.authors}
              </Typography>
            </Clamp>
          </Flex>
        </Grid>
        <Grid item xs={12}>
          <Tooltip arrow title={data.tooltipTitle} placement="top">
            <Clamp>
              <Typography variant="h5" fontWeight={700}>
                {data.name}
              </Typography>
            </Clamp>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        item
        xs={3}
        sx={{
          pt: 2,
          pb: 3,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            p: 1,
            background: get.backgroundColor(),
            color: get.completionTextColor(),
            borderRadius: 1,
            width: 80,
          }}
        >
          <Typography sx={{ fontSize: 11, fontWeight: 600 }}>
            {data.state}
          </Typography>
          <Typography variant="h3" sx={{ fontSize: 32, fontWeight: 800 }}>
            {data.completion}
            <Typography component="span" sx={{ fontSize: 12 }}>
              %
            </Typography>
          </Typography>
        </Box>
      </Grid>

      <AnimatePresence mode="wait">
        <Grid
          item
          container
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
            backgroundColor: "background.light",
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
            maxHeight: 80,
          }}
        >
          {showEditTools ? (
            <Box
              key="edit-tools"
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              sx={{
                width: 1,
                display: "flex",
                alignItems: "center",
                alignSelf: "flex-end",
              }}
            >
              <ActiveFrameworkCardEditTools
                {...{ handle, data, loading, space, isOfficial }}
              />
            </Box>
          ) : (
            <Box
              key="show-data"
              component={motion.div}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              sx={{
                width: 1,
                display: "flex",
                alignItems: "center",
                alignSelf: "flex-end",
              }}
            >
              <ActiveFrameworkCardData {...{ data, get, setShowEditTools }} />
            </Box>
          )}
        </Grid>
      </AnimatePresence>
    </Grid>
  );
};
