import { SvgIconProps } from "@mui/material";
import {
  ArrayIcon,
  BooleanIcon,
  DateTimeIcon,
  ErrorIcon,
  NullIcon,
  NumberIcon,
  RegularExpressionIcon,
  ResourceIcon,
  StringIcon,
} from "./icons";
import { AssetAIcon } from "./icons/asset-scored/a";

type Props = {
  identifier?: string | null;
} & SvgIconProps;

export function DynamicResourceIcon({
  identifier,
  ...props
}: Props): JSX.Element {
  if (!identifier) return <ResourceIcon {...props} />;
  switch (identifier.toLowerCase()) {
    case "linked_resource":
      return <ResourceIcon color="primary" {...props} />;
    case "asset":
      return <AssetAIcon {...props} />;
    case "array":
      return <ArrayIcon {...props} />;
    case "string":
      return <StringIcon {...props} />;
    case "number":
      return <NumberIcon {...props} />;
    case "boolean":
      return <BooleanIcon {...props} />;
    case "date":
    case "time":
    case "date_time":
      return <DateTimeIcon {...props} />;
    case "regular_expression":
      return <RegularExpressionIcon {...props} />;
    case "null":
      return <NullIcon {...props} />;
    case "error":
      return <ErrorIcon sx={{ color: "error.light" }} {...props} />;
    default:
      return <ResourceIcon {...props} />;
  }
}
