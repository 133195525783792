// Allow developers to customize the Asset search text placeholder so that uses view
// a specific placeholder based on the type of asset they are searching.

const DEFAULT_TEXT = "Start typing to see a list of suggestions";

export function getSearchPlaceholder(type: string | null | undefined): string {
  if (!type) return DEFAULT_TEXT;
  switch (type.toLowerCase()) {
    case "fleet":
      return "Search for accounts, namespaces, configuration types, asset types, and more...";
    case "api tokens":
      return "Search for API Token names, descriptions, ...";
    case "available_integrations":
      return "Search available integrations...";
    case "service account":
      return "Search for Key IDs, Services, ...";
    case "compliance_controls":
      return "Search controls";
    case "compliance_policies":
      return "Search for Names, Technologies, Checks, ...";
    case "compliance_checks":
      return "Filter by Checks, Policies, Impact, ...";
    case "compliance_assets":
      return "Filter by Asset Name, Platform, Completion, ...";
    case "asset_data_queries":
      return "Filter queries...";
    case "compliance_data_query_assets":
      return "Filter by asset name...";
    case "inventory_asset_checks":
      return "Filter checks...";
    case "organization_spaces":
      return "Filter Spaces...";
    case "vm_software":
      return "Search for Software, Impact, Risk Factors, ...";
    case "vm_vulnerabilities":
      return "Search for Vulnerability, Impact, Risk Factors, ...";
    case "vm_advisories":
      return "Search for Advisory, Impact, Risk Factors, ...";
    case "space_vulns_vulnerability":
      return "Search CVEs...";
    case "space_vulns_advisory":
      return "Search Advisories...";
    case "space_vulns_check":
      return "Search Checks...";
    case "security_policies":
      return "Filter policies...";
    case "workspaces":
      return "Search for workspace names, tags, technologies, ...";
    default:
      return DEFAULT_TEXT;
  }
}
