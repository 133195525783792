import { SyntheticEvent, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import {
  ComplianceFrameworkState,
  TestIamActionsQuery,
  useGetAvailableComplianceFrameworksQuery,
} from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import { Space } from "~/lib/types";
import { AvailableFrameworkCard } from "../AvailableFrameworks/AvailableFrameworkCard";
import { motion } from "framer-motion";

type AddFrameworkProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function AddFramework({
  space,
  availablePermissions,
}: AddFrameworkProps) {
  const {
    data: { complianceFrameworks } = {},
    error,
    loading,
  } = useGetAvailableComplianceFrameworksQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
      },
    },
  });

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          placeContent: "center",
          width: 1,
          pt: 10,
        }}
      >
        <LoadingFailed what="available frameworks" />;
      </Box>
    );
  }

  if (loading || !complianceFrameworks) {
    return (
      <Box
        sx={{
          display: "flex",
          placeContent: "center",
          width: 1,
          pt: 10,
        }}
      >
        <Loading what="available frameworks" />
      </Box>
    );
  }

  // filter out any frameworks that are currently active
  const availableFrameworks = complianceFrameworks.filter(
    (framework) => framework.state !== ComplianceFrameworkState.Active,
  );

  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      sx={{ width: 1 }}
    >
      <Grid container item spacing={3}>
        {availableFrameworks.map((framework) => (
          <AvailableFrameworkCard
            framework={framework}
            key={framework.mrn}
            space={space}
          />
        ))}
      </Grid>
    </Box>
  );
}
