import { ReactNode } from "react";
import { AlertColor, Box } from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { DropzoneText } from "./dropzone-text";

export type DropzoneProps = {
  children?: ReactNode;
  className?: string;
  showAlerts?: boolean | AlertColor[];
  showIcon?: ReactNode;
} & DropzoneOptions;

export function Dropzone({
  children,
  className,
  showAlerts = true,
  showIcon = true,
  ...options
}: DropzoneProps) {
  const { enqueueSnackbar } = useSnackbar();

  const onDropAccepted: DropzoneOptions["onDropAccepted"] = (files, event) => {
    options.onDropAccepted?.(files, event);

    if (
      showAlerts === true ||
      (Array.isArray(showAlerts) && showAlerts.includes("success"))
    ) {
      files.forEach((file) => {
        enqueueSnackbar(`File ${file.name} was added.`, {
          variant: "success",
        });
      });
    }
  };

  const onDropRejected: DropzoneOptions["onDropRejected"] = (
    fileRejections,
    event,
  ) => {
    options.onDropRejected?.(fileRejections, event);

    if (
      showAlerts === true ||
      (Array.isArray(showAlerts) && showAlerts.includes("error"))
    ) {
      fileRejections.forEach((rejected) => {
        enqueueSnackbar(`File ${rejected.file.name} was rejected.`, {
          variant: "error",
        });
      });
    }
  };

  const {
    getInputProps,
    getRootProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ ...options, onDropAccepted, onDropRejected });

  const content = children || (
    <DropzoneText>Drag and drop your files here</DropzoneText>
  );
  const icon =
    showIcon === false ? undefined : showIcon === true ? (
      <CloudUpload />
    ) : (
      showIcon
    );

  return (
    <Box
      {...getRootProps()}
      className={`dropzone ${className}`}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        borderWidth: "3px",
        borderStyle: isDragActive ? "solid" : "dashed",
        borderColor: isDragActive
          ? isDragAccept
            ? "primary.light"
            : "error.main"
          : "divider",
        borderRadius: 1,
        minHeight: 250,
        p: 1,
        cursor: "pointer",
        backgroundColor: isDragReject ? "error.main" : "background.paper",
        backgroundImage: isDragActive
          ? "repeating-linear-gradient(-45deg, transparent, transparent 25px, rgba(255, 255, 255, 0.12) 25px, rgba(255, 255, 255, 0.12) 50px)"
          : "none",
        backgroundSize: "150% 100%",
        animation: "slideDropzone 2s linear infinite",

        "@keyframes slideDropzone": {
          from: {
            backgroundPositionX: "0",
          },
          to: {
            backgroundPositionX: "-70px",
          },
        },

        ".dropzone-icon": {
          mt: 3,

          ".MuiSvgIcon-root": {
            width: 50,
            height: 50,
          },
        },
      }}
    >
      <input {...getInputProps()} />
      <Box className="dropzone-content">{content}</Box>
      {icon && <Box className="dropzone-icon">{icon}</Box>}
    </Box>
  );
}
