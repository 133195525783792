import { useEffect, useState } from "react";
import { getMonth, getDate, getDaysInMonth, getYear } from "date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CloseIcon } from "~/components/icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

export type CreateMilestonalModalProps = {
  open: boolean;
  handleClose: () => void;
  handleCreate: ({ day, month, year }: FormInputs) => void;
};

const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const thisYear = getYear(new Date());
const thisMonth = getMonth(new Date());
const today = getDate(new Date());

type FormInputs = {
  day: number;
  month: number;
  year: number;
};

const defaultValues: FormInputs = {
  day: today,
  month: thisMonth,
  year: thisYear,
};

// EXAMPLE USAGE
{
  /* <CreateMilestonalModal
        open={false}
        handleClose={() => console.log("handleClose")}
        handleCreate={() => console.log("handleCreate")}
      /> */
}

export function CreateMilestonalModal({
  open,
  handleClose,
  handleCreate,
}: CreateMilestonalModalProps) {
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isValid },
  } = useForm({
    defaultValues,
  });

  const [daysInMonth, setDaysInMonth] = useState<number>(() =>
    getDaysInMonth(new Date()),
  );

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "month" || name === "year") {
        const { year, month, day } = { ...defaultValues, ...value };
        const nextDaysInMonth = getDaysInMonth(new Date(year, month));
        setDaysInMonth(nextDaysInMonth);
        if (nextDaysInMonth < day) {
          reset({ day: nextDaysInMonth, month, year });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    handleCreate(data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="create-a-milestone-dialog"
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          sx={{
            p: 5,
            fontSize: 24,
            fontWeight: 700,
            textTransform: "uppercase",
          }}
        >
          Create a Milestone
          {handleClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 38,
                top: 38,
                color: "text.secondary",
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <DialogContent sx={{ px: 5, pb: 5 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                alignItems: "center",
                pb: { xs: 2, sm: 0 },
              }}
            >
              <Typography>Enter the due date</Typography>
            </Grid>
            <Grid
              container
              item
              columnSpacing={1}
              rowSpacing={2}
              xs={12}
              sm={8}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", sm: "flex-end" },
                pt: 1,
              }}
            >
              <Grid item xs={6} sm="auto">
                <Controller
                  name="day"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl
                      sx={{
                        minWidth: 90,
                        width: { xs: "100%", sm: 0 },
                      }}
                      size="small"
                    >
                      <InputLabel id="select-day-label">Day</InputLabel>
                      <Select
                        {...field}
                        labelId="select-day-label"
                        id="select-day"
                        defaultValue={today}
                        label="Day"
                        displayEmpty
                      >
                        {[...Array(daysInMonth)].map((x, i) => (
                          <MenuItem key={i + 1} value={i + 1}>
                            {i + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid item xs={6} sm="auto">
                <Controller
                  name="month"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl
                      sx={{
                        minWidth: 120,
                        width: { xs: "100%", sm: 0 },
                      }}
                      size="small"
                    >
                      <InputLabel id="select-month-label">Month</InputLabel>
                      <Select
                        {...field}
                        labelId="select-month-label"
                        id="select-month"
                        label="Month"
                      >
                        {MONTHS.map((month, i) => (
                          <MenuItem key={month} value={i}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm="auto">
                <Controller
                  name="year"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <FormControl
                      sx={{
                        minWidth: 90,
                        width: { xs: "100%", sm: 0 },
                      }}
                      size="small"
                    >
                      <InputLabel id="select-year-label">Year</InputLabel>
                      <Select
                        {...field}
                        labelId="select-year-label"
                        id="select-year"
                        label="Year"
                      >
                        {[...Array(20)].map((_x, i) => (
                          <MenuItem key={thisYear + i} value={thisYear + i}>
                            {thisYear + i}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ px: 5, pb: 5, justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isValid}
          >
            Create Milestone
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
