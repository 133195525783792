import { ReactNode, useEffect, useState } from "react";
import { auth, applyActionCode, AuthError } from "~/login/firebase-provider";
import { useAuth } from "~/login/provider";
import { CliComponent } from "./cliComponent";
import { waitForTyping } from "./animatedTyping";

type Props = {
  actionCode: string;
};

export function EmailVerificationComponent({ actionCode }: Props) {
  const { handleErrorMessage } = useAuth();
  const [typings, setTypings] = useState<ReactNode[]>([]);

  useEffect(() => {
    handleVerifyEmail(actionCode);

    return () => {
      setTypings([]);
    };
  }, []);

  async function handleVerifyEmail(actionCode: string) {
    await waitForTyping(setTypings, {
      id: "1",
      type: "command",
      prepend: "$",
      text: "mondoo verify-email --incognito",
      delay: 1000,
    });
    await waitForTyping(setTypings, {
      id: "2",
      type: "output",
      text: "→ searching for email",
    });

    try {
      // Apply firebase action code
      await applyActionCode(auth, actionCode);

      await waitForTyping(setTypings, {
        id: "3",
        type: "output",
        text: "→ email matched",
      });
      await waitForTyping(setTypings, { id: "4", type: "progress", text: "" });
      await waitForTyping(setTypings, {
        id: "5",
        type: "output",
        text: "✓ email verified",
      });

      // get the current user
      const { currentUser } = auth;
      // if there is a current user, we will redirect them momentarily
      if (currentUser) {
        await waitForTyping(setTypings, {
          id: "6",
          type: "output",
          text: "✓ Thank you for using Mondoo. We are redirecting you now.",
          color: "hotpink",
        });
        // reloading the user will kick the authed application into action
        window.location.href = "/";
      } else {
        // if no current user then user is not logged in, prompt to login
        await waitForTyping(setTypings, {
          id: "7",
          type: "output",
          text: "→ To continue using Mondoo, please",
          link: {
            text: "sign in",
            to: "/login",
          },
          color: "hotpink",
        });
      }
    } catch (error) {
      const errorMessage = handleErrorMessage((error as AuthError).code);
      await waitForTyping(setTypings, {
        id: "8",
        type: "output",
        text: `𝗑 ${errorMessage}`,
        link: {
          text: "Please sign in to request a new one.",
          to: "/login",
        },
        color: "hotpink",
      });
    }
  }

  return <CliComponent>{typings.map((line) => line)}</CliComponent>;
}
