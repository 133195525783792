export const AzureIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.16723 3.9914H14.1976L8.97558 19.4637C8.92191 19.6227 8.81973 19.7608 8.68342 19.8587C8.54712 19.9566 8.38355 20.0092 8.21575 20.0093H4.30088C4.17374 20.0093 4.04843 19.979 3.9353 19.921C3.82218 19.863 3.72449 19.7788 3.65034 19.6756C3.57618 19.5723 3.52767 19.4528 3.50884 19.3271C3.49 19.2014 3.50137 19.073 3.54202 18.9525L8.4072 4.53698C8.46085 4.37793 8.56305 4.23971 8.69939 4.1418C8.83574 4.04389 8.99937 3.99122 9.16723 3.99121V3.9914Z"
        fill="url(#paint0_linear_14020_140583)"
      />
      <path
        d="M16.4794 14.3691H8.50241C8.42825 14.3691 8.35579 14.3913 8.29445 14.433C8.23312 14.4747 8.18577 14.5339 8.15856 14.6029C8.13135 14.6719 8.12555 14.7475 8.14191 14.8198C8.15827 14.8921 8.19604 14.9579 8.2503 15.0084L13.3761 19.7927C13.5253 19.9319 13.7218 20.0093 13.9259 20.0092H18.4428L16.4794 14.3691Z"
        fill="#0078D4"
      />
      <path
        d="M9.1662 3.99122C8.99651 3.99057 8.83106 4.04419 8.69401 4.14426C8.55696 4.24432 8.4555 4.38559 8.40444 4.54742L3.54698 18.9392C3.50361 19.0601 3.48999 19.1896 3.50728 19.3169C3.52457 19.4442 3.57227 19.5655 3.64633 19.6704C3.72039 19.7754 3.81864 19.8609 3.93276 19.9199C4.04689 19.9788 4.17353 20.0094 4.30198 20.0091H8.31789C8.46746 19.9823 8.60726 19.9164 8.723 19.818C8.83874 19.7195 8.92628 19.5921 8.97668 19.4488L9.94535 16.594L13.4054 19.8213C13.5504 19.9412 13.7323 20.0075 13.9205 20.0091H18.4206L16.4469 14.369L10.6934 14.3703L14.2147 3.99122H9.1662Z"
        fill="url(#paint1_linear_14020_140583)"
      />
      <path
        d="M15.5917 4.53602C15.5381 4.37722 15.4361 4.23923 15.2999 4.14149C15.1638 4.04375 15.0004 3.99119 14.8328 3.99121H9.22656C9.39415 3.99122 9.55751 4.04379 9.69364 4.14153C9.82978 4.23926 9.93183 4.37723 9.98543 4.53602L14.8508 18.9521C14.8915 19.0726 14.9029 19.201 14.8841 19.3268C14.8652 19.4526 14.8167 19.5721 14.7426 19.6754C14.6684 19.7787 14.5707 19.8628 14.4576 19.9209C14.3445 19.979 14.2191 20.0093 14.0919 20.0093H19.6984C19.8256 20.0092 19.9509 19.9789 20.064 19.9208C20.1771 19.8628 20.2748 19.7786 20.3489 19.6753C20.4231 19.572 20.4715 19.4525 20.4903 19.3268C20.5092 19.201 20.4978 19.0726 20.4571 18.9521L15.5917 4.53602Z"
        fill="url(#paint2_linear_14020_140583)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_14020_140583"
          x1="11.0006"
          y1="5.17838"
          x2="5.77649"
          y2="20.6118"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#114A8B" />
          <stop offset="1" stopColor="#0669BC" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_14020_140583"
          x1="12.6315"
          y1="12.3706"
          x2="11.4231"
          y2="12.7792"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.3" />
          <stop offset="0.071" stopOpacity="0.2" />
          <stop offset="0.321" stopOpacity="0.1" />
          <stop offset="0.623" stopOpacity="0.05" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_14020_140583"
          x1="11.9674"
          y1="4.72805"
          x2="17.702"
          y2="20.006"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CCBF4" />
          <stop offset="1" stopColor="#2892DF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

AzureIcon.displayName = "AzureIcon";
