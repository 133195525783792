import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Dropzone, DropzoneProps, DropzoneText } from "~/components/dropzone";
import { ChevronRightIcon, OpenInNewIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegration,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { GreenCheckCircleIcon } from "~/pages/invitation";
import { AnimatePresence, motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { RecommendedPolicies } from "../../components/recommended-policies";
import { UpdateFlowData } from "../../types";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";

type CloudFormInput = {
  integrationName: string;
  fingerprint: string;
  region: string;
  tenancyOcid: string;
  userOcid: string;
  configSnippet: string;
  pemKey: string;
};

const defaultValues: CloudFormInput = {
  integrationName: "",
  fingerprint: "",
  region: "",
  tenancyOcid: "",
  userOcid: "",
  configSnippet: "",
  pemKey: "",
};

export function OciIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [createdIntegrationMrn, setCreatedIntegrationMrn] = useState<
    ClientIntegration["mrn"] | undefined
  >(undefined);
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [pemKey] = watch(["pemKey"]);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      if (data.createClientIntegration.integration) {
        setCreatedIntegrationMrn(data.createClientIntegration.integration.mrn);
      }
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const [TriggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: {
      input: { mrn: createdIntegrationMrn!, type: ActionType.RunScan },
    },
  });

  const handleTriggerScan = async () => {
    try {
      await TriggerClientIntegrationScan();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow?.integration.configurationOptions?.__typename !==
        "OciConfigurationOptions"
      )
        return;
      reset({
        integrationName: updateFlow.integration.name,
        fingerprint: updateFlow.integration.configurationOptions.fingerprint,
        region: updateFlow.integration.configurationOptions.region,
        tenancyOcid: updateFlow.integration.configurationOptions.tenancyOcid,
        userOcid: updateFlow.integration.configurationOptions.userOcid,
      });
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  //function that takes in an OCI configuration file and parses the
  // fingerprint, region, tenancy, and user id
  const parseConfig = (config: string): Record<string, string> => {
    const lines = config.split("\n");
    const parsedConfig: Record<string, string> = {};

    for (const line of lines) {
      if (line.startsWith("user=")) {
        parsedConfig.user = line.substring(5);
      } else if (line.startsWith("fingerprint=")) {
        parsedConfig.fingerprint = line.substring(12);
      } else if (line.startsWith("key_file=")) {
        parsedConfig.keyFile = line.substring(9);
      } else if (line.startsWith("tenancy=")) {
        parsedConfig.tenancy = line.substring(8);
      } else if (line.startsWith("region=")) {
        parsedConfig.region = line.substring(7);
      }
    }

    return parsedConfig;
  };

  const onSubmit: SubmitHandler<CloudFormInput> = async (data) => {
    if (!updateFlow && !data.pemKey) {
      throw new Error("You must supply a valid .pem certificate");
    }

    const parsedConfig = parseConfig(data.configSnippet);
    console.log(parsedConfig);
    // return;

    const ociConfigurationOptions = {
      fingerprint: parsedConfig.fingerprint.trim(),
      region: parsedConfig.region.trim(),
      tenancyOcid: parsedConfig.tenancy.trim(),
      userOcid: parsedConfig.user.trim(),
      privateKey: data.pemKey,
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateIntegration({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Oci,
              configurationOptions: {
                ociConfigurationOptions,
              },
            },
          },
        });
        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/oci/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Oci,
              longLivedToken: false,
              configurationOptions: {
                ociConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  const onDropAccepted: DropzoneProps["onDropAccepted"] = async (files) => {
    setValue("pemKey", await files[0].text());
  };

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Oci }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Name your integration
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>
              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Create your OCI API key
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 3, mt: 2 }}
                  >
                    Before you can integrate your Oracle account with any third
                    party service, you must create an API key in the OCI
                    Dashboard. To learn how, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/cloud/oci/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Button
                    href="https://www.oracle.com/cloud/sign-in.html"
                    target="_blank"
                    variant="outlined"
                    color="secondary"
                    endIcon={<OpenInNewIcon />}
                  >
                    Go to the OCI dashboard
                  </Button>
                </Box>
              </Box>
              {/* Step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide the config file snippet
                </Command>
                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Copy and paste the config file snippet to create as you set
                    up your API key in the OCI console. To learn more, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/cloud/oci/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                </Box>
                {/* provide the config snippet here */}
                <Controller
                  name="configSnippet"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      multiline
                      rows={6}
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      placeholder={
                        "[DEFAULT]\nuser=ocidx.user.ocx..xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx\nfingerprint=xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx:xx\ntenancy=ocidx.tenancy.ocx..xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx\nregion=xx-region-x\nkey_file=~/.oci/user@url.com.pem"
                      }
                      error={Boolean(errors.configSnippet)}
                      helperText={
                        Boolean(errors.configSnippet) && (
                          <ValidationMessage error={errors.configSnippet} />
                        )
                      }
                    />
                  )}
                />
              </Box>
              {/* When in the updateFlow, we do not want to update the certificate */}
              {/* step 4 */}
              {!updateFlow && (
                <Box pb={4}>
                  <Command
                    number={4}
                    options={{
                      fontSize: { xs: 16 },
                      dotColor: theme.palette.background.lightest,
                    }}
                  >
                    Provide your OCI private key
                  </Command>
                  <Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mb: 2, mt: 2 }}
                    >
                      Upload the .pem file generated in the previous step
                      containing your private key. To learn more, read the{" "}
                      <Link
                        href="https://mondoo.com/docs/platform/infra/cloud/oci/"
                        target="_blank"
                        rel="noopener"
                      >
                        Mondoo documentation
                      </Link>
                      .
                    </Typography>
                    <AnimatePresence mode="wait">
                      {!pemKey ? (
                        <Box
                          key="dropzone"
                          component={motion.div}
                          {...fadeInOut}
                        >
                          <Dropzone
                            className="mondoo-dropzone-area"
                            accept={{
                              "application/x-x509-ca-cert": [".pem"],
                              "application/x-x509-user-cert": [".pem"],
                              "application/x-pkcs12": [".pem"],
                              "application/x-pem-file": [".pem"],
                            }}
                            multiple={false}
                            showAlerts={["error"]}
                            onDropAccepted={onDropAccepted}
                          >
                            <DropzoneText>
                              Drag and drop your .pem file here.
                            </DropzoneText>
                          </Dropzone>
                        </Box>
                      ) : (
                        <Box
                          key="success"
                          component={motion.div}
                          {...fadeInOut}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            py: 5,
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                            }}
                          >
                            <Typography variant="h6">
                              Successfully loaded certificate
                            </Typography>
                            <GreenCheckCircleIcon
                              fontSize="large"
                              sx={{ ml: 2 }}
                            />
                          </Box>

                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => setValue("pemKey", "")}
                          >
                            Remove certificate
                          </Button>
                        </Box>
                      )}
                    </AnimatePresence>
                  </Box>
                </Box>
              )}
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  // setValue doesn't trigger the required validation so we're doing it manually here
                  disabled={updateFlow ? !isValid : !isValid || !pemKey}
                >
                  {updateFlow ? "update configuration" : "start scanning"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies
          {...{ space }}
          filterTypes={["oci"]}
          finalizeAction={handleTriggerScan}
        />
      )}
    </Fragment>
  );
}
