import { createSvgIcon } from "@mui/material";

export const VersionsIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Frame 2536">
      <path
        id="Vector"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1707 19H9C7.69378 19 6.58254 18.1652 6.17071 17H5C3.34315 17 2 15.6569 2 14V10C2 8.34315 3.34315 7 5 7H6.17071C6.58254 5.83481 7.69378 5 9 5H10.1707C10.5825 3.83481 11.6938 3 13 3H19C20.6569 3 22 4.34315 22 6V18C22 19.6569 20.6569 21 19 21H13C11.6938 21 10.5825 20.1652 10.1707 19ZM10 7H9C8.44772 7 8 7.44772 8 8V16C8 16.5523 8.44772 17 9 17H10V7ZM12 6C12 5.44772 12.4477 5 13 5H19C19.5523 5 20 5.44772 20 6V18C20 18.5523 19.5523 19 19 19H13C12.4477 19 12 18.5523 12 18V6ZM6 9V15H5C4.44772 15 4 14.5523 4 14V10C4 9.44772 4.44772 9 5 9H6Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  "Versions",
);
