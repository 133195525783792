import { Header } from "~/types/table";
import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { Link as RouterLink } from "react-router-dom";
import { LibraryHref } from "~/lib/libraryhref";
import { AdvisoryNodeFieldsFragment } from "~/operations";
import { ImpactCell, AdvisoryCell, PublishCell } from "./cells";
import { DeprecatedRiskFactorsCell } from "~/components/vulnerabilities/DeprecatedRiskFactorsCell";

export const AdvisoriesTable = ({
  advisories,
}: {
  advisories: AdvisoryNodeFieldsFragment[];
}) => {
  const tableHeaders: Header[] = [
    {
      id: "IMPACT",
      label: "Impact",
      options: { width: "10%" },
      sortable: false,
    },
    {
      id: "ADVISORY",
      label: "Advisory",
      options: { width: "50%" },
      sortable: false,
    },
    {
      id: "RISK_FACTORS",
      label: "Risk Factors",
      sortable: false,
    },
    {
      id: "PUBLISHED",
      label: "Published",
      sortable: false,
    },
  ];

  return (
    <TableContainer>
      <Table data-testid="software-advisories">
        <TableHead tableHeaders={tableHeaders} />
        <TableBody>
          {advisories.map((advisory) => {
            return (
              <TableRow
                key={advisory.id}
                component={RouterLink}
                to={LibraryHref("advisory", advisory.id)}
              >
                <ImpactCell advisory={advisory} />
                <AdvisoryCell advisory={advisory} />
                <DeprecatedRiskFactorsCell
                  vulnerabilityRiskFactors={advisory.vulnerabilityRiskFactors}
                />
                <PublishCell advisory={advisory} />
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
