import { styled } from "@mui/material/styles";

export const B = styled("span")`
  font-weight: bold;
`;

export const ErrorSpan = styled("span")`
  color: ${(props) => props.theme.palette.error.main};
`;

export const Right = styled("div")`
  text-align: right;
`;

export const Left = styled("div")`
  text-align: left;
`;

export const Small = styled("span")`
  font-size: 0.7em;
  color: ${(props) => props.theme.palette.text.secondary};
`;

export const Spacer = styled("span")`
  width: 1em;
  display: inline-block;
`;

export const VerticalSpacer = styled("div")<{ space: string }>`
  height: ${(props) => props.space || "1em"};
`;
