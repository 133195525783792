import { createSvgIcon } from "@mui/material";

export const SecurityGradientIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 5.79422L32.4967 10.8631V18.3745C32.4382 21.9459 31.1984 25.4084 28.9542 28.2678C26.71 31.1273 23.5762 33.2375 20 34.2974C16.4238 33.2375 13.29 31.1273 11.0458 28.2678C8.80164 25.4084 7.56182 21.9459 7.50328 18.3745V10.8631L20 5.79422ZM20 3.40671C19.6639 3.41259 19.3315 3.47475 19.018 3.59037L6.52126 8.65923C6.06717 8.84131 5.68009 9.14806 5.40923 9.54048C5.13837 9.9329 4.99596 10.3933 5.00009 10.8631V18.3745C5.04479 22.4652 6.45618 26.4357 9.02911 29.7088C11.602 32.9819 15.2024 35.3871 19.3068 36.5747C19.76 36.6972 20.24 36.6972 20.6932 36.5747C24.8007 35.3862 28.4033 32.9782 30.9765 29.7014C33.5498 26.4246 34.9593 22.45 34.9999 18.3562V10.8631C35.004 10.3933 34.8616 9.9329 34.5908 9.54048C34.3199 9.14806 33.9328 8.84131 33.4787 8.65923L20.982 3.51691C20.6685 3.40129 20.3361 3.33913 20 3.33325V3.40671Z"
      fill="url(#paint0_linear_1598_11563)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1598_11563"
        x1="5"
        y1="3.33325"
        x2="38.7871"
        y2="32.2402"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "SecurityGradient",
);
