import { Tooltip } from "@mui/material";
import { RemoteExecutionIcon } from "../icons";

export function RemoteExploitIcon() {
  return (
    <Tooltip title="Remote Execution" placement="top" arrow>
      <RemoteExecutionIcon
        color="warning"
        sx={{ opacity: 0.5, ":hover": { opacity: 1 } }}
      />
    </Tooltip>
  );
}
