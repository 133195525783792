import { Fragment, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ResourceCard,
  getHealth,
  EmptyAssetDiscovery,
} from "~/components/aws-overview-account-tab";
import { AWSIntegrationStatus } from "~/pages/integrations/cloud-security/aws/integration-aws-account-serverless";
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon } from "~/components/icons";
import {
  ActionType,
  GetClientIntegrationQuery,
  ListDiscoveryResultsQuery,
  TestIamActionsQuery,
  useTriggerActionLazyQuery,
} from "~/operations";
import { useSnackbar } from "notistack";
import { IamActions } from "~/lib/iam";

type Integration =
  GetClientIntegrationQuery["clientIntegration"]["integration"];
type DiscoveredAssets = NonNullable<
  ListDiscoveryResultsQuery["listDiscoveryResults"]["stats"]
>;

type Props = {
  awsIntegration: Integration & {
    token: string | undefined;
  };
  integrationStatus: AWSIntegrationStatus;
  discoveredAssets: DiscoveredAssets;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function IndividualAccountsTab({
  awsIntegration,
  integrationStatus,
  discoveredAssets,
  availablePermissions,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  // Trigger will apply on every row of individual accounts
  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery();

  const hasIntegrationTriggerActionPermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_TRIGGERACTION,
  );

  const integrationHealth = getHealth(integrationStatus);
  // get array of all identifiers, duplicates removed.
  const identifiers: string[] = discoveredAssets
    //  is identifier really a nullable property?
    .map((asset) => (asset.identifier ? asset.identifier : ""))
    .filter((e, i, a) => a.indexOf(e) === i);

  // get all EC2 assets
  const allEC2Assets = discoveredAssets.filter(
    (asset) => asset.query.startsWith("aws.ec2") && asset.count > 0,
  );

  // get all non EC2 assets
  const allOtherAssets = discoveredAssets.filter(
    (asset) => !asset.query.startsWith("aws.ec2") && asset.count > 0,
  );

  const triggerAccountScan = async (accountId: string) => {
    try {
      await triggerClientIntegrationScan({
        variables: {
          input: {
            mrn: awsIntegration.mrn,
            actionOptions: { identifiers: [accountId] },
            type: ActionType.RunScan,
          },
        },
      });
      enqueueSnackbar(`Successfully triggered scan on account ${accountId}`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(`Failed to trigger scan on account ${accountId}`, {
        variant: "error",
      });
    }
  };

  const showTrigger =
    (awsIntegration.status === "ACTIVE" ||
      awsIntegration.status === "WARNING" ||
      awsIntegration.status === "ERROR") &&
    hasIntegrationTriggerActionPermission;

  return (
    <Grid id="individual-accounts-tab" container component={Paper} p={2}>
      <Typography variant="h6" sx={{ mb: 2, width: 1 }}>
        Individual Accounts
      </Typography>
      {integrationHealth === "empty" && <EmptyAssetDiscovery />}

      {integrationHealth === "healthy" && (
        <TableContainer>
          <Table sx={{ "tr:hover": { background: "unset" } }}>
            <TableBody>
              {identifiers.map((identifier) => (
                <CollapsableTableRow
                  key={identifier}
                  {...{
                    identifier,
                    showTrigger,
                    triggerAccountScan,
                    allEC2Assets,
                    allOtherAssets,
                  }}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Grid>
  );
}

const CollapsableTableRow = ({
  identifier,
  showTrigger,
  triggerAccountScan,
  allEC2Assets,
  allOtherAssets,
}: {
  identifier: string;
  showTrigger: boolean;
  triggerAccountScan: (identifier: string) => void;
  allEC2Assets: DiscoveredAssets;
  allOtherAssets: DiscoveredAssets;
}) => {
  const [open, setOpen] = useState<boolean>(false);

  // Get all non EC2 asset resource stats
  const otherAssets = allOtherAssets.filter(
    (asset) => asset.identifier === identifier,
  );

  // Get all EC2 asset resource stats
  const ec2Assets = allEC2Assets.filter(
    (asset) => asset.identifier === identifier,
  );

  // total up all the non EC2 assets
  const otherAssetCount = otherAssets.reduce(
    (agg, asset) => (agg += asset.count),
    0,
  );

  // total up all EC2 assets
  const ec2AssetCount = ec2Assets.reduce(
    (agg, asset) => (agg += asset.count),
    0,
  );

  // get total of all assets found
  const totalAssetCount = otherAssetCount + ec2AssetCount;

  const otherAssetsText =
    otherAssetCount > 0
      ? "Summary of detected resources:"
      : "Unable to gather discovered resources counts.";

  const ec2AssetsText =
    ec2AssetCount > 0
      ? "Summary of detected EC2 instances:"
      : "No EC2 instances detected";

  return (
    <Fragment>
      <TableRow
        className="collapsable"
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell width="50px">
          <IconButton
            id="expand-row-button"
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left">
          <Box sx={{ display: "flex", placeContent: "center space-between" }}>
            <Box>
              <Typography id="row-account-id">Account {identifier}</Typography>
              <Typography variant="caption" color="text.secondary">
                {totalAssetCount} Resources Discovered
              </Typography>
            </Box>
            <Box>
              {showTrigger && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => triggerAccountScan(identifier)}
                  sx={{
                    background:
                      "linear-gradient(133.55deg, #9147FF 0%, #4C35E8 100%)", //<--- palette breaking
                    ml: 1,
                  }}
                  data-name="aws-triggerscan-individual-account"
                >
                  Scan
                </Button>
              )}
            </Box>
          </Box>
        </TableCell>
      </TableRow>

      <TableRow className="collapsed-section">
        <TableCell colSpan={2} sx={{ py: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit sx={{ pb: 1 }}>
            <Box sx={{ py: 2, pl: 9 }}>
              {/* Non EC2 Resources */}
              <Typography className="section-title" sx={{ pb: 2 }}>
                {otherAssetsText}
              </Typography>
              <Grid
                id="detected-resources-group"
                container
                item
                rowSpacing={3}
                columnSpacing={2}
                sx={{ pb: 2 }}
              >
                {otherAssets.length > 0 &&
                  otherAssets.map((asset) => (
                    <ResourceCard key={asset.title} {...{ asset }} />
                  ))}
              </Grid>
              {/* EC2 Resources */}
              <Typography className="section-title" sx={{ py: 2 }}>
                {ec2AssetsText}
              </Typography>
              <Grid
                id="detected-ec2-group"
                container
                item
                rowSpacing={3}
                columnSpacing={2}
              >
                {ec2Assets.length > 0 &&
                  ec2Assets.map((asset) => (
                    <ResourceCard key={asset.title} {...{ asset }} />
                  ))}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};
