import { Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  Typography,
  Paper,
  Button,
  Grid,
  Divider,
  Link,
  Box,
  Breadcrumbs,
} from "@mui/material";
import { SpacePagesIntegrations } from "./routes";
import { AgentFilter, Space } from "~/lib/types";
import { DeleteConfirmDialog } from "../components/delete-confirm-dialog";
import { LoadingPage, LoadingFailedPage } from "../components/loading";
import { GettingStarted } from "../components/guides";
import { IamActions } from "~/lib/iam";
import { SearchFilter } from "../components/search";
import { DnsIcon, HomeIcon } from "../components/icons";
import { LoadMore } from "../components/load-more";
import { pluralize } from "../lib/pluralize";
import { docsHref } from "../lib/docs-href";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  ManagedClientsIntegrationsTable,
  ManagedClientsIntegrationsTableProps,
} from "~/components/integrations-managed-clients-table";
import {
  AgentForwardPaginationQuery,
  AgentForwardPaginationQueryVariables,
  AgentOrder,
  AgentOrderField,
  AgentState,
  ClientIntegration,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  OrderDirection,
  TestIamActionsQuery,
  useAgentForwardPaginationQuery,
  useDeleteAgentsMutation,
} from "~/operations";
import { IntegrationsListHeader } from "./integrations/headers/integrations-list-header";
import { SelectionToolbar } from "~/components/data-table";

type AgentsConnection = NonNullable<AgentForwardPaginationQuery["agents"]>;

const Loading = <LoadingPage what="integrations" />;
const LoadingFailed = <LoadingFailedPage what="integrations" />;

class AgentActiveFilter implements AgentFilter {
  name: string;
  active: boolean;
  constructor(name: string, active: boolean) {
    this.name = name;
    this.active = active;
  }
  title() {
    return this.name;
  }
  value() {
    let vars = new Map();
    if (this.active == true) {
      vars.set("state", "ACTIVE");
    } else {
      vars.set("state", "MISSING");
    }
    return vars;
  }
}

class AgentLatestFilter implements AgentFilter {
  name: string;
  active: boolean;
  constructor(name: string, active: boolean) {
    this.name = name;
    this.active = active;
  }
  title() {
    return this.name;
  }
  value() {
    let vars = new Map();
    if (this.active == true) {
      vars.set("version", "latest");
    } else {
      vars.set("version", "outdated");
    }
    return vars;
  }
}

class AgentQueryTermFilter implements AgentFilter {
  queryTerm: string;

  constructor(queryTerm: string) {
    this.queryTerm = queryTerm;
  }

  title() {
    return `Search: ${this.queryTerm}`;
  }

  value() {
    let vars = new Map();
    vars.set("queryTerm", this.queryTerm);
    return vars;
  }
}

const agentLatestVersionFilter = new AgentLatestFilter("Version: Latest", true);
const agentOutdatedVersionFilter = new AgentLatestFilter(
  "Version: Outdated",
  false,
);
const agentActiveFilter = new AgentActiveFilter("State: Active", true);
const agentMissingFilter = new AgentActiveFilter("State: Missing", false);

type AgentsParams = {
  filter: Array<AgentFilter>;
  initialView: number;
};

export type AgentsManagedProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

type AgentsManagedState = {
  openDeleteConfirmDialog: boolean;
  query?: string;
  availableFilter: AgentFilter[];
  sort: AgentOrder;
  latestAgentRelease: string;
  isDeleting: boolean;
};

function defaultFilter() {
  const agentFilter = [
    agentLatestVersionFilter,
    agentOutdatedVersionFilter,
    agentActiveFilter,
    agentMissingFilter,
  ];
  return agentFilter;
}

function parseQueryParams(queryParams: URLSearchParams): AgentsParams {
  let initialView = 1;
  let filter: Array<AgentFilter> = [];

  const state = queryParams.get("state");
  if (state != null) {
    state.split(",").forEach((value, idx) => {
      switch (value) {
        case "ACTIVE":
          filter.push(agentActiveFilter);
          break;
        case "MISSING":
          filter.push(agentMissingFilter);
          break;
      }
    });
  }

  const version = queryParams.get("version");
  if (version != null) {
    version.split(",").forEach((value, idx) => {
      switch (value) {
        case "latest":
          filter.push(agentLatestVersionFilter);
          break;
        case "outdated":
          filter.push(agentOutdatedVersionFilter);
          break;
      }
    });
  }

  const queryTerms = queryParams.get("queryterms");
  if (queryTerms) {
    filter.push(
      ...queryTerms.split(",").map((q) => new AgentQueryTermFilter(q)),
    );
  }

  return {
    filter,
    initialView,
  };
}

export function AgentsManagedPage({
  space,
  availablePermissions,
}: AgentsManagedProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);
  const params = parseQueryParams(queryParams);
  const pageSize = 25;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selection, setSelection] = useState<ClientIntegration["mrn"][]>([]);

  const defaultSort: AgentOrder = {
    field: AgentOrderField.Hostname,
    direction: OrderDirection.Asc,
  };

  const [state, setState] = useState<AgentsManagedState>({
    openDeleteConfirmDialog: false,
    availableFilter: defaultFilter(),
    sort: defaultSort,
    latestAgentRelease: "0.0.0",
    isDeleting: false,
  });

  const mergeState = (params: Partial<AgentsManagedState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const fetchLatestRelease = async (): Promise<string> => {
    // TODO: should this move someplace more central?
    return fetch("https://releases.mondoo.com/mondoo/latest.json")
      .then((res) => res.json())
      .then((releases) => {
        return releases?.version || "0.0.0";
      });
  };

  useEffect(() => {
    (async () => {
      const latestAgentRelease = await fetchLatestRelease();
      mergeState({ latestAgentRelease });
    })();
  }, []);

  // TODO: make the filter handling part of the backend
  const gqlVariables = (filter: AgentFilter[] | null) => {
    let agentState: Array<AgentState> = [];
    // name for url
    let version: Array<string> = [];
    // used in backend
    let versionFilter: Array<{ version: string; not: boolean }> = [];
    // search term
    let queryTerms: Array<string> = [];

    if (filter != null) {
      filter.forEach((item) => {
        const val = item.value();
        if (val.has("state")) {
          agentState.push(val.get("state"));
        }

        if (val.has("version")) {
          version.push(val.get("version"));

          // generate gql query object
          switch (val.get("version")) {
            case "latest":
              versionFilter.push({
                version: state.latestAgentRelease,
                not: false,
              });
              break;
            case "outdated":
              versionFilter.push({
                version: state.latestAgentRelease,
                not: true,
              });
              break;
          }
        }

        if (val.has("queryTerm")) {
          queryTerms.push(val.get("queryTerm"));
        }
      });
    }

    const filters = {
      version: version,
      versionFilter: versionFilter,
      state: agentState,
      queryTerms: queryTerms,
    };

    return filters;
  };

  const genVarsFromState = (): AgentForwardPaginationQueryVariables => {
    const filters = gqlVariables(params.filter);
    return {
      spaceMrn: space.mrn,
      first: pageSize,
      query: state.query,
      orderBy: state.sort,
      version: filters.versionFilter,
      state: filters.state,
      queryTerms: filters.queryTerms,
    };
  };

  const { loading, data, error, fetchMore, refetch } =
    useAgentForwardPaginationQuery({
      variables: genVarsFromState(),
    });

  const [deleteAgents] = useDeleteAgentsMutation({
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  if (error) {
    return LoadingFailed;
  }

  if (loading || !data?.agents) {
    return Loading;
  }

  const agents: AgentsConnection = JSON.parse(JSON.stringify(data.agents));

  const loadMore = () => {
    fetchMore({
      variables: { ...genVarsFromState(), after: agents.pageInfo.endCursor },
    });
  };

  const renderNoAgentsView = () => {
    return (
      <GettingStarted>
        <Grid item xs={12}>
          <Paper elevation={3}>
            <Box sx={{ p: 3, textAlign: "center" }}>
              <Box sx={{ height: 60 }}>
                <DnsIcon style={{ width: "40px", height: "40px" }}></DnsIcon>
              </Box>
              <Typography variant="h5" style={{ marginBottom: "1em" }}>
                Managed Clients
              </Typography>
              <Divider />
              <Box sx={{ pt: 1.25, pb: 2.5 }}>
                <Typography variant="body1">
                  For more information visit{" "}
                  <Link
                    target="_blank"
                    href={docsHref("/platform/infra/opsys/osoverview/")}
                  >
                    Server and Endpoint Security with Mondoo
                  </Link>{" "}
                  on our docs site.
                </Typography>
              </Box>
              <Button
                variant="contained"
                color="primary"
                aria-label="Add Managed Client"
                to={`/space/${SpacePagesIntegrations}/add?spaceId=${space.id}#serverendpointsecurity`}
                component={RouterLink}
                data-name="add-managed-client-button-none"
                sx={{ m: 1, ml: "auto", mr: 0 }}
              >
                Add Managed Client
              </Button>
            </Box>
          </Paper>
        </Grid>
      </GettingStarted>
    );
  };

  const handleFilterQuery = (filterQuery: AgentFilter[] | null) => {
    if (filterQuery == null) {
      return;
    }

    const filter: AgentFilter[] = filterQuery.map((f) =>
      typeof f === "string" ? new AgentQueryTermFilter(f) : f,
    );

    mergeState(state);

    // TODO: this is still not optimal
    const filters = gqlVariables(filter);
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);

    if (filters.state != null && filters.state.length > 0) {
      params.set("state", filters.state.join(","));
    } else {
      params.delete("state");
    }

    if (filters.version != null && filters.version.length > 0) {
      params.set("version", filters.version.join(","));
    } else {
      params.delete("version");
    }

    if (filters.queryTerms?.length) {
      params.set("queryterms", filters.queryTerms.join(","));
    } else {
      params.delete("queryterms");
    }

    navigate(window.location.pathname + "?" + params);
  };

  const { sort } = state;

  let isLoading = false;
  const hasNextPage = agents.pageInfo.hasNextPage || false;
  const hasAgents = Boolean(agents.edges && agents.edges.length > 0);

  if (
    agents != null &&
    agents.edges &&
    agents.edges.length === 0 &&
    state.query == null &&
    (params.filter == null || params.filter.length == 0)
  ) {
    return renderNoAgentsView();
  }

  const handleDeleteMultiItemsConfirm = () => {
    mergeState({ isDeleting: true });
    deleteAgents({
      variables: {
        input: { spaceMrn: space.mrn, agentMrns: selection },
      },
      update() {
        enqueueSnackbar(
          `Successfully deleted agent ${pluralize("role", selection.length)}`,
          {
            variant: "success",
          },
        );
        refetch(genVarsFromState());
      },
    })
      .catch((e) => {
        enqueueSnackbar(`Error deleting agent`, {
          variant: "error",
        });
      })
      .finally(() => {
        setSelection([]);
        mergeState({
          isDeleting: false,
        });
        setIsOpen(false);
      });
  };

  const handleSortClick = (field: AgentOrderField) => {
    return () => {
      const { sort } = state;
      const newSort = { field, direction: OrderDirection.Desc };

      // switch directions if the field id is the same
      if (sort.field.toUpperCase() === field.toUpperCase()) {
        newSort.direction =
          sort.direction.toUpperCase() === "ASC"
            ? OrderDirection.Desc
            : OrderDirection.Asc;
      }

      state.sort = newSort;
      mergeState(state);
      refetch(genVarsFromState());
    };
  };

  const hasDeleteAgentsPermissions = availablePermissions.includes(
    IamActions.AGENTMANAGER_DELETEAGENTS,
  );

  const multiActions: ManagedClientsIntegrationsTableProps["multiActions"] = {
    get selectedItems() {
      return selection;
    },
    handleSelectAllChange: () => {
      const updated =
        selection.length > 0 && agents.edges?.length
          ? []
          : agents.edges?.flatMap((x) => x?.node?.mrn ?? []) || [];
      setSelection(updated);
    },
    handleSelectItemChange: (e, item) => {
      if (e.target.checked) {
        setSelection((prev) => [...prev, item.mrn]);
      } else {
        const updated = selection.filter((x) => x !== item.mrn);
        setSelection(updated);
      }
    },
  };

  const handleRemoveClick = () => {
    setIsOpen(true);
  };

  const handleCancelClick = () => {
    setSelection([]);
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography
      key={1}
      component={RouterLink}
      color="primary.light"
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Typography>,
    <Typography key={2}>Managed Clients</Typography>,
  ];

  document.title = `Managed Clients · Integrations · Mondoo`;

  return (
    <Fragment>
      <Breadcrumbs sx={{ mb: 4 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <IntegrationsListHeader
        {...{
          space,
          type: IntegrationType.ManagedClient,
          hasIntegrations: hasAgents,
        }}
      />

      <Box mt={5} mb={4}>
        <SearchFilter
          value={params.filter}
          filter={state.availableFilter}
          onQuery={(filter) => handleFilterQuery(filter)}
          placeholder="Filter clients"
          freeSolo
        />
      </Box>

      <ManagedClientsIntegrationsTable
        space={space}
        integrations={agents}
        multiActions={hasDeleteAgentsPermissions ? multiActions : undefined}
        {...{ sort, handleSortClick }}
      />

      {multiActions.selectedItems.length > 0 && (
        <SelectionToolbar>
          <Typography>
            Selected {multiActions.selectedItems.length} of{" "}
            {agents.edges?.length} clients
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRemoveClick}
          >
            Remove
          </Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </SelectionToolbar>
      )}

      <LoadMore
        handleLoadMore={loadMore}
        hasNextPage={hasNextPage}
        loading={isLoading}
      />

      <DeleteConfirmDialog
        isDeleting={state.isDeleting}
        open={isOpen}
        items={selection}
        onConfirm={handleDeleteMultiItemsConfirm}
        onClose={() => setIsOpen(false)}
        itemType="agent"
        content={`Are you absolutely sure you want to delete  ${
          selection.length
        } managed ${pluralize(
          "client",
          selection.length,
        )}?  This operation cannot be undone.`}
      />
    </Fragment>
  );
}
