import { createSvgIcon } from "@mui/material";

export const AmazonS3Icon = createSvgIcon(
  <svg viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4016 8.2998L28.1016 24.0998L36.4016 39.8998L39.8016 37.9998V10.1998L36.4016 8.2998Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.4016 8.2998L19.8016 10.1998L11.3516 24.0998L19.8016 37.9998L36.4016 39.8998V8.2998Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.90313 8.2998L0.203125 9.4998V38.6998L2.90313 39.8998L19.8031 24.0998L2.90313 8.2998Z"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.86719 8.2666L19.8259 12.9777V35.6443L2.86719 39.911V8.2666Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8336 15.4667L12.6328 14.3111L19.8336 6.1333L27.0166 14.3111L19.8336 15.4667Z"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0166 14.3111L19.8247 15.4844L12.6328 14.3111V6.1333"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8336 32.6221L12.6328 33.9555L19.8336 40.9777L27.0166 33.9555L19.8336 32.6221Z"
      fill="#58150D"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8016 0L12.6016 3.8V14.3L19.824 12.2667L19.8016 0Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8247 18.0444L12.6328 18.8267V29.3105L19.8247 30.1333V18.0444Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8247 35.7335L12.6328 33.9302V44.2365L19.8247 48.0002V35.7335Z"
      fill="#7B1D13"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.02 33.9302L19.8281 35.734V48.0002L27.02 44.2365V33.9302Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8281 18.0444L27.02 18.8267V29.3105L19.8281 30.1333V18.0444Z"
      fill="#E25444"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8047 0L27.0047 3.8V14.3L19.8047 12.3V0Z"
      fill="#E25444"
    />
  </svg>,
  "Amazon S3",
);
