import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  GridProps,
  Link,
  LinkProps,
  Typography,
  TypographyProps,
} from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";

export type TitleDividerProps = {
  title: string;
  href?: string;
  hrefTitle?: string;
  icon?: ReactNode;
  TitleProps?: TypographyProps;
  ContainerProps?: GridProps;
  LinkProps?: LinkProps;
};

export function TitleDivider({
  title,
  href,
  icon,
  TitleProps,
  ContainerProps,
  LinkProps,
  hrefTitle,
}: TitleDividerProps) {
  const stripTitle = (title: string) => {
    return title.replace(/[^\w]/gi, "").toLowerCase();
  };

  return (
    <Grid container alignItems="center" mt={5} mb={3} {...ContainerProps}>
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        {icon && <Box sx={{ mr: 2 }}>{icon}</Box>}
        <Box id={stripTitle(title)}>
          <Typography
            fontWeight="fontWeightBold"
            sx={{
              display: "inline-block",
              pr: 3,
              fontSize: { xs: 20, sm: 24 },
            }}
            {...TitleProps}
          >
            {title}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
      {href && (
        <Grid item xs="auto">
          <Link
            component={RouterLink}
            to={href}
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 3,
              textTransform: "uppercase",
            }}
            {...LinkProps}
          >
            {hrefTitle ? hrefTitle : "Open Organization"} <ChevronRightIcon />
          </Link>
        </Grid>
      )}
    </Grid>
  );
}
