import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Typography, styled } from "@mui/material";
import ChooseAService from "~/images/cicd/choose-a-service.png";
import ChooseAServiceLight from "~/images/cicd/choose-a-service-light.png";
import Setup from "~/images/cicd/setup.png";
import SetupLight from "~/images/cicd/setup-light.png";
import BuildAndDeploy from "~/images/cicd/build-deploy.png";
import BuildAndDeployLight from "~/images/cicd/build-deploy-light.png";
import { useColorMode } from "~/providers/color-mode";

type Props = {
  spaceId: string;
};

export function ProjectsSplash({ spaceId }: Props) {
  let navigate = useNavigate();
  const { mode } = useColorMode();
  return (
    <Box id="projects-splash-screen" m="auto" my={5} maxWidth={896}>
      <Typography
        variant="h5"
        color="text.secondary"
        fontWeight={700}
        lineHeight="48px"
        textAlign="center"
        mb={10}
      >
        Use Mondoo to apply security policies to your CI/CD pipelines:
      </Typography>

      <Grid
        container
        justifyContent="center"
        columnSpacing={{ sm: 2, md: 0 }}
        rowSpacing={{ xs: 8, sm: 0 }}
      >
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent={{ xs: "center", sm: "start" }}
        >
          <Box maxWidth={208} px="9.5px">
            <Box
              component="img"
              src={mode === "light" ? ChooseAServiceLight : ChooseAService}
              width="100%"
              mb={2}
            />
            <StyledText>Choose a CI/CD Service to integrate with</StyledText>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} display="flex" justifyContent="center">
          <Box maxWidth={208}>
            <Box
              component="img"
              src={mode === "light" ? SetupLight : Setup}
              width="100%"
              mb={2}
            />
            <StyledText>
              Set up your integration and choose your policies
            </StyledText>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          justifyContent={{ xs: "center", sm: "end" }}
        >
          <Box maxWidth={208}>
            <Box
              component="img"
              src={mode === "light" ? BuildAndDeployLight : BuildAndDeploy}
              width="100%"
              mb={2}
            />
            <StyledText>
              Builds &amp; Deploys will automatically show up here
            </StyledText>
          </Box>
        </Grid>
      </Grid>
      <Button
        data-name="CiCd-add-first-integration"
        onClick={() =>
          navigate(`/space/integrations/add?spaceId=${spaceId}#cicd`)
        }
        sx={{
          display: "block",
          mt: 10,
          ml: "auto",
          mr: "auto",
          px: 2,
          background: (theme) => theme.palette.primary.gradient,
          color: "common.white",
        }}
      >
        ADD your first integration
      </Button>
    </Box>
  );
}

const StyledText = styled(Typography)`
  color: ${(p) => p.theme.palette.text.secondary};
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;
