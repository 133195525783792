import React, { useState } from "react";
import { Grid } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { EmptySection } from "~/components/vulnerabilities";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  AggregateScoreType,
  OrderDirection,
  useGetAggregateScoresQuery,
} from "~/operations";
import { FirewatchList } from "~/components/FirewatchList";
import { Space } from "~/lib/types";
import { INITIAL_PAGE_RANGE } from "~/components/pagination";
import { useSort } from "~/pages/inventory/hooks/useSort";

type SoftwareAdvisoriesProps = {
  space: Space;
  findingMrn: string;
};

export const SoftwareAdvisories = ({
  space,
  findingMrn,
}: SoftwareAdvisoriesProps) => {
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const { handleSortClick, orderBy } = useSort<AggregateScoreOrder>({
    defaultSort: {
      field: AggregateScoreOrderField.Rank,
      direction: OrderDirection.Asc,
    },
    validFields: ["RISK_SCORE", "RANK", "BLAST_RADIUS", "TITLE"],
    prefix: "advisory",
  });
  const { data, error, loading, fetchMore, networkStatus } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: space.mrn,
        first: 3,
        after: null,
        orderBy,
        filter: {
          scoreType: AggregateScoreType.Advisory,
          softwareFindingMrn: findingMrn,
        },
      },
    });

  const advisories =
    data?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? data.aggregateScores
      : undefined;

  const edges = advisories?.edges;

  return (
    <Grid id="version-chart" item xs={12}>
      <SectionHeading heading="Advisories" />
      {(edges?.length || 0) > 0 ? (
        <FirewatchList
          field={orderBy.field}
          direction={orderBy.direction}
          space={space}
          pageType={AggregateScoreType.Advisory}
          data={edges?.slice(pageItems.from, pageItems.to)}
          handleSortClick={handleSortClick}
          loading={loading}
          paginationProps={{
            fetchMore,
            pageInfo: advisories?.pageInfo,
            setPageItems,
            totalCount: advisories?.totalCount || 0,
            customPageSizes: [3, 10, 25, 50],
          }}
        />
      ) : (
        <EmptySection
          id="software-advisories-empty"
          text="There are currently no advisories for this software"
        />
      )}
    </Grid>
  );
};
