import { createSvgIcon } from "@mui/material";

export const InfrastructureAsCodeIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" fill="none">
    <path
      d="M12.3127 5.00391H11.2751C7.80973 5.00391 5.29444 7.50625 5.29444 10.9555V16.1754C5.29444 17.1256 4.59359 17.9753 3.66613 18.1517C3.47514 18.1874 3.33594 18.3541 3.33594 18.5483V19.7056C3.33594 19.8998 3.47514 20.0665 3.66613 20.1038C4.57902 20.277 5.29444 21.1559 5.29444 22.1076V27.2402C5.29444 30.6878 7.80973 33.1917 11.2751 33.1917H12.3127C12.536 33.1917 12.7173 33.0104 12.7173 32.7871V31.51C12.7173 31.2866 12.536 31.1054 12.3127 31.1054H11.2751C9.0868 31.1054 7.43746 29.4447 7.43746 27.2402V22.1076C7.43746 21.0215 6.88713 19.8917 6.05032 19.1779C6.89846 18.4787 7.43746 17.3425 7.43746 16.1754V10.9555C7.43746 8.75095 9.0868 7.08866 11.2751 7.08866H12.3127C12.536 7.08866 12.7173 6.90737 12.7173 6.68401V5.40855C12.7173 5.18519 12.536 5.00391 12.3127 5.00391Z"
      fill="url(#paint0_linear_2222_78423)"
    />
    <path
      d="M36.3402 18.1478C35.4128 17.9714 34.7119 17.1233 34.7119 16.1732V11.5747C34.7119 11.1167 34.7119 10.7509 34.6812 10.3867C34.4384 7.26603 31.936 5 28.7312 5H27.6937C27.4703 5 27.2891 5.18128 27.2891 5.40465V6.68172C27.2891 6.90508 27.4703 7.08637 27.6937 7.08637H28.4059C30.3255 7.08637 32.5673 8.09799 32.5673 10.9516V16.1732C32.5673 17.3385 33.1079 18.4764 33.9561 19.1756C33.1176 19.8894 32.5673 21.0176 32.5673 22.1053V27.2362C32.5673 29.512 30.9908 31.1031 28.7312 31.1031H27.6937C27.4703 31.1031 27.2891 31.2843 27.2891 31.5077V32.7832C27.2891 33.0065 27.4703 33.1878 27.6937 33.1878H28.7312C32.1966 33.1878 34.7119 30.6855 34.7119 27.2362V22.1053C34.7119 21.1536 35.4274 20.2731 36.3402 20.0999C36.5312 20.0643 36.6704 19.8975 36.6704 19.7017V18.546C36.6704 18.3502 36.5312 18.1834 36.3402 18.1478Z"
      fill="url(#paint1_linear_2222_78423)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7425 10.7891C28.254 10.7891 28.6719 11.207 28.6719 11.7185V14.4929C28.6719 15.0044 28.254 15.4223 27.7425 15.4223H12.9216C12.4101 15.4223 11.9922 15.0044 11.9922 14.4929V11.7185C11.9922 11.207 12.4101 10.7891 12.9216 10.7891H27.7425ZM14.9176 13.6988C14.9843 13.6988 15.039 13.6441 15.039 13.5774V12.6285C15.039 12.5617 14.9843 12.5071 14.9176 12.5071H13.9687C13.902 12.5071 13.8473 12.5617 13.8473 12.6285V13.5774C13.8473 13.6441 13.902 13.6988 13.9687 13.6988H14.9176ZM16.8247 13.7043C16.8914 13.7043 16.9461 13.6496 16.9461 13.5829V12.634C16.9461 12.5673 16.8914 12.5126 16.8247 12.5126H16.3521C16.2854 12.5126 16.2307 12.5673 16.2307 12.634V13.5829C16.2307 13.6496 16.2854 13.7043 16.3521 13.7043H16.8247Z"
      fill="url(#paint2_linear_2222_78423)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7425 16.8145C28.254 16.8145 28.6719 17.2324 28.6719 17.7439V20.5183C28.6719 21.0298 28.254 21.4477 27.7425 21.4477H12.9216C12.4101 21.4477 11.9922 21.0298 11.9922 20.5183V17.7439C11.9922 17.2324 12.4101 16.8145 12.9216 16.8145H27.7425ZM14.9176 19.7241C14.9843 19.7241 15.039 19.6695 15.039 19.6028V18.6548C15.039 18.5881 14.9843 18.5325 14.9176 18.5325H13.9687C13.902 18.5325 13.8473 18.5881 13.8473 18.6548V19.6028C13.8473 19.6695 13.902 19.7241 13.9687 19.7241H14.9176ZM16.8247 19.7297C16.8914 19.7297 16.9461 19.675 16.9461 19.6083V18.6594C16.9461 18.5927 16.8914 18.538 16.8247 18.538H16.3521C16.2854 18.538 16.2307 18.5927 16.2307 18.6594V19.6083C16.2307 19.675 16.2854 19.7297 16.3521 19.7297H16.8247Z"
      fill="url(#paint3_linear_2222_78423)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7425 22.8359C28.254 22.8359 28.6719 23.2539 28.6719 23.7654V26.5398C28.6719 27.0513 28.254 27.4692 27.7425 27.4692H12.9216C12.4101 27.4692 11.9922 27.0513 11.9922 26.5398V23.7654C11.9922 23.2539 12.4101 22.8359 12.9216 22.8359H27.7425ZM16.8247 25.7512C16.8914 25.7512 16.9461 25.6965 16.9461 25.6298V24.6809C16.9461 24.6142 16.8914 24.5595 16.8247 24.5595H16.3521C16.2854 24.5595 16.2307 24.6142 16.2307 24.6809V25.6298C16.2307 25.6965 16.2854 25.7512 16.3521 25.7512H16.8247ZM14.9176 25.7456C14.9843 25.7456 15.039 25.691 15.039 25.6242V24.6753C15.039 24.6086 14.9843 24.554 14.9176 24.554H13.9687C13.902 24.554 13.8473 24.6086 13.8473 24.6753V25.6242C13.8473 25.691 13.902 25.7456 13.9687 25.7456H14.9176Z"
      fill="url(#paint4_linear_2222_78423)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2222_78423"
        x1="3.33594"
        y1="5.00391"
        x2="19.9704"
        y2="10.2668"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2222_78423"
        x1="27.2891"
        y1="5"
        x2="43.9235"
        y2="10.2629"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2222_78423"
        x1="11.9922"
        y1="10.7891"
        x2="14.5517"
        y2="19.5483"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2222_78423"
        x1="11.9922"
        y1="16.8145"
        x2="14.5517"
        y2="25.5737"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2222_78423"
        x1="11.9922"
        y1="22.8359"
        x2="14.5517"
        y2="31.5952"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Infrastructure As Code",
);
