import { Box, Grid, Typography } from "@mui/material";
import { IntegrationType, useGetIntegrationsSummaryQuery } from "~/operations";
import { Loading } from "../loading";
import { motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";
import { Space } from "~/lib/types";
import { ticketingIntegrationTypes } from "./integration-counts";

type Props = {
  space: Space;
  types: IntegrationType[];
};

type DataBoxCounts = {
  total: number;
  assets?: number;
  policies?: number;
  checks?: number;
};

export function DataBox({ space, types }: Props) {
  const { data, error, loading } = useGetIntegrationsSummaryQuery({
    variables: { input: { spaceMrn: space.mrn } },
  });

  //If we error, lets just not show anything
  if (error) {
    return <div />;
  }

  if (loading || !data?.integrationsSummary.integrationSummaries) {
    return <LoaderContainer />;
  }

  const summaries = data.integrationsSummary.integrationSummaries.filter(
    (summary) => types.includes(summary.type),
  );

  const dataBoxCounts: DataBoxCounts = summaries.reduce(
    (acc, curr) => {
      acc.total = acc.total + curr.totalCount;
      acc.assets = acc.assets + curr.assetsCount;
      acc.policies = acc.policies + curr.policiesCount;
      acc.checks = acc.checks + curr.checksCount;
      return acc;
    },
    {
      total: 0,
      assets: 0,
      policies: 0,
      checks: 0,
    },
  );

  // If we have a ticketing system, we should only show the total count
  if (types.some((type) => ticketingIntegrationTypes.includes(type))) {
    delete dataBoxCounts.assets;
    delete dataBoxCounts.policies;
    delete dataBoxCounts.checks;
  }

  return (
    <Grid
      component={motion.div}
      {...fadeInOut}
      container
      item
      xs={12}
      sm
      columnSpacing={2}
      sx={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-end",
        my: {
          xs: 2,
          sm: 0,
        },
      }}
    >
      {Object.entries(dataBoxCounts).map(([key, value]) => {
        return (
          <Grid item key={key} sx={{ textAlign: "center" }}>
            <Typography
              fontSize={11}
              lineHeight="16px"
              color="text.secondary"
              sx={{ textTransform: "uppercase", mb: 2 }}
            >
              {key}
            </Typography>
            <Typography
              className={"integrations-value"}
              fontSize={24}
              lineHeight="15px"
              fontWeight={800}
            >
              {value.toLocaleString()}
            </Typography>
          </Grid>
        );
      })}
    </Grid>
  );
}

const LoaderContainer = () => {
  return (
    <Box sx={[{ mt: 0.8 }, { "& > *": { height: "unset" } }]}>
      <Loading what="data counts" />
    </Box>
  );
};
