import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { TabOption } from "~/components/ui-library";

type CasesTabsProps = {
  tabs: TabOption[];
  currentTab: number;
  onTabChange: (_event: React.SyntheticEvent, value: number) => void;
};

export const CasesTabs = ({
  tabs,
  currentTab,
  onTabChange,
}: CasesTabsProps) => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }} mt={2}>
      <Tabs
        variant="scrollable"
        value={currentTab}
        onChange={onTabChange}
        sx={{
          ".MuiTabs-scrollButtons.Mui-disabled": {
            opacity: 0.3,
          },
        }}
      >
        {(tabs || []).map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            sx={{ textTransform: "unset" }}
          />
        ))}
      </Tabs>
    </Box>
  );
};
