import { createSvgIcon } from "@mui/material";

export const CicdIcon = createSvgIcon(
  <svg viewBox="0 0 48 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M34.8752 0C27.6384 0 21.7504 6.2808 21.7504 14C21.7504 19.0728 17.8816 23.2 13.1256 23.2C8.3696 23.2 4.5 19.0728 4.5 14C4.5 8.9272 8.3688 4.8 13.1248 4.8C14.8416 4.8 16.4992 5.336 17.9184 6.3504C18.3928 6.6904 18.836 7.0792 19.2344 7.5064C20.0832 8.4152 21.5656 8.4184 22.416 7.5136C23.2952 6.58 23.2984 5.0576 22.4232 4.1192C21.8152 3.4688 21.1424 2.8776 20.424 2.364C18.2608 0.8176 15.7376 0.000800323 13.1248 0.000800323C5.888 3.23448e-07 0 6.2808 0 14C0 21.7192 5.888 28 13.1248 28C20.3616 28 26.2496 21.7192 26.2496 14C26.2496 8.9272 30.1184 4.8 34.8744 4.8C39.6304 4.8 43.5 8.9272 43.5 14C43.5 19.0728 39.6312 23.2 34.8752 23.2C32.572 23.2 30.4064 22.244 28.7776 20.5072C27.928 19.6008 26.4456 19.6008 25.5952 20.5072C25.1704 20.9608 24.9368 21.5632 24.9368 22.204C24.9368 22.8456 25.1712 23.4488 25.596 23.9008C28.0744 26.5448 31.3704 28 34.8752 28C42.112 28 48 21.7192 48 14C48 6.2808 42.112 0 34.8752 0Z"
      fill="url(#paint0_linear_5694_57854)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5694_57854"
        x1="0"
        y1="0"
        x2="25.6118"
        y2="41.7381"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "CI/CD",
);
