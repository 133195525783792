import React from "react";
import { SelectedEntity } from "~/components/exceptions/types";
import { IamActions } from "~/lib/iam";
import { CheckScoreOrder, TestIamActionsQuery } from "~/operations";
import { CheckScoresEdge } from "../asset/types";
import { useSearchParams } from "react-router-dom";
import { Header } from "~/types/table";

export type UseChecksTableProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  checkEdges: CheckScoresEdge[];
  sort: CheckScoreOrder;
};

export function useChecksTable({
  availablePermissions,
  checkEdges,
  sort,
}: UseChecksTableProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedChecks, setSelectedChecks] = React.useState<
    Array<SelectedEntity>
  >([]);

  const hasApplyExceptionMutationPermission = availablePermissions?.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const handleCheck = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    { mrn, exception, groupId }: SelectedEntity,
  ) => {
    if (checked) {
      setSelectedChecks((prev) => [
        ...prev,
        {
          groupId,
          mrn,
          exception,
        },
      ]);
    } else {
      setSelectedChecks(
        selectedChecks.filter((selection) => mrn !== selection.mrn),
      );
    }
  };

  const handleCheckAll = (checked: boolean) => {
    if (checked) {
      setSelectedChecks(
        checkEdges.map((edge) => {
          const { exception, mrn } = edge.node || {};
          return {
            groupId: exception?.id,
            mrn: mrn || "",
            exception: !exception
              ? null
              : {
                  justification: exception.justification,
                  action: exception.action,
                },
          };
        }),
      );
    } else {
      setSelectedChecks([]);
    }
  };

  const handleClose = (queryEdge: CheckScoresEdge) => {
    if (searchParams.get("checkMrn") === queryEdge.node?.mrn) {
      searchParams.delete("checkMrn");
      setSearchParams(searchParams);
    }
  };

  const handleSortClick = (header: Header) => {
    searchParams.set("field", header.id);
    searchParams.set(
      "direction",
      header.id !== sort.field
        ? "DESC"
        : sort.direction === "ASC"
          ? "DESC"
          : "ASC",
    );
    setSearchParams(searchParams);
  };

  const handleCancelClick = () => {
    setSelectedChecks([]);
  };

  return {
    hasApplyExceptionMutationPermission,
    selectedChecks,
    setSelectedChecks,
    checkHandle: {
      check: handleCheck,
      checkAll: handleCheckAll,
      close: handleClose,
      sortClick: handleSortClick,
      cancelClick: handleCancelClick,
    },
  };
}
