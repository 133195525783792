import { createSvgIcon } from "@mui/material";

export const MondooIcon = createSvgIcon(
  <svg
    viewBox="0 0 48 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M36 -0.000366211V23.9988C42.6269 23.9988 48.0002 18.6268 48.0002 11.9986C48.0002 5.37039 42.6269 -0.000366211 36 -0.000366211Z" />
    <path d="M24.0002 24C30.6277 24 36.0004 18.6273 36.0004 11.9998C36.0004 5.3723 30.6277 -0.000366211 24.0002 -0.000366211C17.3727 -0.000366211 12 5.3723 12 11.9998C12 18.6273 17.3727 24 24.0002 24Z" />
    <path d="M0 11.9999C0 18.6269 5.37199 24.0001 12.0002 24.0001V-0.000244141C5.37199 -0.000244141 0 5.37175 0 11.9999Z" />
  </svg>,
  "Mondoo",
);
