import { VisibilityOffIcon } from "~/components/icons";
import { Chip } from "~/components/chip";
import { Box, Tooltip, Typography } from "@mui/material";
import { AccessTimeFilled, PersonOutlined } from "@mui/icons-material";
import { formatISO } from "~/lib/date";

type OutOfScopeChipProps = {
  author: string | undefined;
  date: string | undefined;
};

export const OutOfScopeChip = ({ author, date }: OutOfScopeChipProps) => {
  return (
    <>
      <Tooltip
        title={
          <>
            <Box
              gap={1}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Box display="flex" gap={2} sx={{ color: "text.secondary" }}>
                {author && (
                  <Box gap={1} display="flex" alignItems="center">
                    <PersonOutlined /> {author}
                  </Box>
                )}
                {date && (
                  <Box gap={1} display="flex" alignItems="center">
                    <AccessTimeFilled />
                    {formatISO(new Date(date), { representation: "date" })}
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography
                  fontWeight={400}
                  fontSize={12}
                  justifyContent="center"
                >
                  This asset does not apply to this <br /> compliance framework
                  for reasons.
                </Typography>
              </Box>
            </Box>
          </>
        }
        placement="top"
        arrow
      >
        <Chip
          sx={{ cursor: "pointer" }}
          label="Out Of Scope"
          icon={<VisibilityOffIcon />}
          size="small"
          data-test-id="chip-out-of-scope"
        />
      </Tooltip>
    </>
  );
};
