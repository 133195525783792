import { createSvgIcon } from "@mui/material";

export const RDSDBInstancesIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_14110)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.854 28.854L7.708 33H10.5V34H6.5C6.224 34 6 33.777 6 33.5V29.5H7V32.293L11.147 28.147L11.854 28.854ZM33 29.5H34V33.5C34 33.777 33.777 34 33.5 34H29.5V33H32.293L28.147 28.854L28.854 28.147L33 32.293V29.5ZM34 6.5V10.5H33V7.708L28.854 11.854L28.147 11.147L32.293 7H29.5V6H33.5C33.777 6 34 6.224 34 6.5ZM7 10.5H6V6.5C6 6.224 6.224 6 6.5 6H10.5V7H7.527L11.846 11.14L11.155 11.861L7 7.88V10.5ZM32.726 19.4C32.726 17.842 30.92 16.3 27.896 15.277L28.216 14.33C31.718 15.515 33.726 17.363 33.726 19.4C33.726 21.437 31.718 23.286 28.216 24.471L27.896 23.523C30.92 22.5 32.726 20.958 32.726 19.4ZM7.737 19.4C7.737 20.892 9.427 22.393 12.258 23.414L11.918 24.354C8.625 23.167 6.737 21.361 6.737 19.4C6.737 17.439 8.625 15.633 11.918 14.445L12.258 15.386C9.427 16.407 7.737 17.908 7.737 19.4ZM20.023 15.719C16.887 15.719 15.132 14.92 15.106 14.594C15.132 14.268 16.887 13.469 20.023 13.469C23.156 13.469 24.913 14.267 24.94 14.594C24.913 14.92 23.156 15.719 20.023 15.719ZM20.023 19.274C16.841 19.274 15.106 18.348 15.106 17.872V15.842C16.241 16.437 18.175 16.719 20.023 16.719C21.871 16.719 23.805 16.437 24.94 15.842V17.872C24.94 18.348 23.205 19.274 20.023 19.274ZM20.023 23.011C16.841 23.011 15.106 22.084 15.106 21.608V19.265C16.225 19.93 18.129 20.274 20.023 20.274C21.917 20.274 23.821 19.93 24.94 19.265V21.608C24.94 22.084 23.205 23.011 20.023 23.011ZM20.023 26.29C16.841 26.29 15.106 25.375 15.106 24.905V23.001C16.225 23.667 18.129 24.011 20.023 24.011C21.917 24.011 23.821 23.667 24.94 23.001V24.905C24.94 25.375 23.205 26.29 20.023 26.29ZM20.023 12.469C17.173 12.469 14.106 13.134 14.106 14.594V24.905C14.106 26.471 17.083 27.29 20.023 27.29C22.963 27.29 25.94 26.471 25.94 24.905V14.594C25.94 13.134 22.873 12.469 20.023 12.469Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_14110"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "RDS DB Instances",
);
