import { Paper } from "@mui/material";
import {
  AggregateScoreOrderField,
  AggregateScoreType,
  useGetCveAdvisoriesQuery,
} from "~/operations";
import { memo, useState } from "react";
import { PaginationRange } from "~/components/pagination";
import { FirewatchList } from "~/components/FirewatchList";
import { EmptySection } from "~/components/vulnerabilities";
import { Space } from "~/lib/types";
import { OrderDirection } from "~/components/ui-library";
import { ObservableQuery } from "@apollo/client";
import { useParams } from "react-router-dom";

type AdvisoriesTableProps = {
  space: Space;
};

export const AdvisoriesTable = memo(({ space }: AdvisoriesTableProps) => {
  const { id = "" } = useParams();
  const [advisoryPageItems, setAdvisoryPageItems] = useState<PaginationRange>({
    from: 0,
    to: 3,
  });

  const { data, fetchMore, loading } = useGetCveAdvisoriesQuery({
    variables: {
      spaceMrn: space.mrn,
      id,
      advisoriesFirst: 3,
    },
  });

  const advisoriesConnection =
    data?.cve?.advisoryAggregateScores?.__typename ===
    "AggregateScoresConnection"
      ? data?.cve.advisoryAggregateScores
      : undefined;

  const fetchMoreMitigatedAssets: ObservableQuery["fetchMore"] = (params) => {
    const { variables } = params;
    return fetchMore({
      variables: {
        advisoriesFirst: variables?.first,
        advisoriesAfter: variables?.after,
      },
    });
  };

  const advisories = advisoriesConnection?.edges?.slice(
    advisoryPageItems.from,
    advisoryPageItems.to,
  );

  return (
    <>
      {(advisoriesConnection?.edges?.length || 0) > 0 ? (
        <Paper>
          <FirewatchList
            field={AggregateScoreOrderField.Rank}
            direction={OrderDirection.Asc}
            space={space}
            pageType={AggregateScoreType.Advisory}
            data={advisories}
            handleSortClick={() => {}}
            loading={loading}
            paginationProps={{
              fetchMore: fetchMoreMitigatedAssets,
              pageInfo: advisoriesConnection?.pageInfo,
              setPageItems: setAdvisoryPageItems,
              totalCount: advisoriesConnection?.totalCount || 0,
              customPageSizes: [3, 10, 25, 50],
            }}
          />
        </Paper>
      ) : (
        <EmptySection
          id="software-advisories-empty"
          text="There are currently no related advisories."
        />
      )}
    </>
  );
});
