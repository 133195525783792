import { ReactNode } from "react";
import { Grid } from "@mui/material";
import { ToggleOption } from "~/pages/integrations/components/ToggleOption/ToggleOption";
import { Control } from "react-hook-form/dist/types/form";
import { JiraFormInput } from "./types";
import { ZendeskFormInput } from "~/pages/integrations/hosted-integrations/forms/zendesk/types";
import { GithubIssueFormInput } from "~/pages/integrations/hosted-integrations/forms/github-issues/types";
import { GitlabIssueFormInput } from "~/pages/integrations/hosted-integrations/forms/gitlab-issues/types";
import { AzureDevOpsFormInput } from "~/pages/integrations/hosted-integrations/forms/azure-devops/types";

type AutoConfigurationSectionParams = {
  control:
    | Control<JiraFormInput>
    | Control<ZendeskFormInput>
    | Control<GithubIssueFormInput>
    | Control<GitlabIssueFormInput>
    | Control<AzureDevOpsFormInput>;
  autoCreateSubField?: ReactNode;
  showAutoCreateSection: boolean;
};

export const AutoConfigurationSection = ({
  control,
  autoCreateSubField,
  showAutoCreateSection,
}: AutoConfigurationSectionParams) => {
  return (
    <>
      <Grid item xs={12}>
        <ToggleOption
          title="Automatically close issues"
          description="If you create a case in Mondoo and then resolve it, the corresponding issue closes."
          formOptions={{
            name: "autoCloseTickets",
            control,
          }}
        />
      </Grid>

      {showAutoCreateSection && (
        <Grid item xs={12} mt={3}>
          <ToggleOption
            title="Create drift issues in this integration"
            description="If 'Automatically create cases on drift' is enabled in space settings, create an issue in this integration for each new drift case. "
            formOptions={{
              name: "autoCreateCases",
              control,
            }}
          />
          {autoCreateSubField}
        </Grid>
      )}
    </>
  );
};
