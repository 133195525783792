import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import jwtDecode, { JwtPayload } from "jwt-decode";
import {
  LoadRegistrationTokensDocument,
  LoadRegistrationTokensQuery,
  OrderDirection,
  RegistrationTokenInput,
  RegistrationTokenOrderField,
  TestIamActionsQuery,
  useGenerateRegistrationTokenMutation,
  useLoadRegistrationTokensQuery,
  useRevokeRegistrationTokenMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { IamActions } from "~/lib/iam";
import { Token } from "~/providers/token";
import {
  DeleteIcon,
  ExpandLessIcon,
  ExpandMoreIcon,
  VerifiedIcon,
} from "~/components/icons";
import { TokenStatusIcon, useTokenStatus } from "~/components/tokens";
import { AddButton } from "~/components/add-button";
import { Code } from "~/components/code";
import { LoadMore } from "~/components/load-more";
import { TimerOutlinedIcon } from "~/components/icons";
import { Format, FormatAbbrDateYear } from "~/lib/date";
import { DataTable, DetailRow } from "~/components/data-table";
import { NoTokensView } from "~/pages/space-settings/no-tokens-view";
import { ConfirmationDialog } from "~/components/ConfirmationDialog";
import { useConfirmationDialog } from "~/components/ConfirmationDialog/hooks";
import { Controller, useForm } from "react-hook-form";

type RegistrationToken = NonNullable<
  LoadRegistrationTokensQuery["registrationTokens"]
>["edges"][0]["node"];

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export type RegistrationTokenToDelete = {
  token: RegistrationToken;
};

const EXPIRATION_PERIOD = {
  TEN_MINUTES: 600,
  THIRTY_MINUTES: 1800,
  HOUR: 3600,
  EIGHT_HOURS: 28800,
  DAY: 86400,
  WEEK: 604800,
  NON_EXPIRING: -1,
};

const EXPIRATION_PERIOD_LABEL = {
  [EXPIRATION_PERIOD.TEN_MINUTES]: "10 Minutes",
  [EXPIRATION_PERIOD.THIRTY_MINUTES]: "30 Minutes",
  [EXPIRATION_PERIOD.HOUR]: "1 Hour",
  [EXPIRATION_PERIOD.EIGHT_HOURS]: "8 Hours",
  [EXPIRATION_PERIOD.DAY]: "24 Hours",
  [EXPIRATION_PERIOD.WEEK]: "1 Week",
  [EXPIRATION_PERIOD.NON_EXPIRING]: "Non-Expiring",
};

export function RegistrationTokensPage({ space, availablePermissions }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [addTokenFormOpen, setAddTokenFormOpen] = useState(false);
  const [generatedToken, setGeneratedToken] = useState<Token | null>(null);
  const [itemToDelete, setItemToDelete] = useState<RegistrationTokenToDelete>();
  const {
    isConfirmationDialogOpen,
    handleConfirmationDialogOpen,
    handleConfirmationDialogClose,
  } = useConfirmationDialog();

  const hasGenerateTokenPermission = availablePermissions.includes(
    IamActions.AGENTMANAGER_GENERATEREGISTRATIONTOKEN,
  );

  const { data, loading, error, fetchMore } = useLoadRegistrationTokensQuery({
    variables: {
      spaceMrn: space.mrn,
      orderBy: {
        field: RegistrationTokenOrderField.Created,
        direction: OrderDirection.Desc,
      },
      first: 25,
      after: null,
    },
  });

  const [revokeRegistrationToken] = useRevokeRegistrationTokenMutation();

  const [generateRegistrationToken] = useGenerateRegistrationTokenMutation();

  const getConfirmationModalContent = () => {
    if (itemToDelete?.token?.createdBy?.toLowerCase() === "aws") {
      return (
        <>
          <Typography>
            This registration token was created{" "}
            {itemToDelete?.token?.createdBy &&
              `by ${itemToDelete.token?.createdBy}`}{" "}
            {itemToDelete?.token.createdAt &&
              `on ${FormatAbbrDateYear(itemToDelete.token?.createdAt)}`}{" "}
            for a Mondoo AWS organization scanning integration.
          </Typography>
          <Typography>
            Deleting the token will prevent Mondoo from scanning this AWS
            account and any new AWS accounts you add to this organization.
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography>
            This registration token was created{" "}
            {itemToDelete?.token?.createdBy &&
              `by ${itemToDelete.token?.createdBy}`}{" "}
            {itemToDelete?.token.createdAt &&
              `on ${FormatAbbrDateYear(itemToDelete.token?.createdAt)}`}{" "}
            for this reason: “{itemToDelete?.token.description}”
          </Typography>
          <Typography>
            If one of your integrations relies on this registration token,
            deleting the token can prevent the integration from working. Once
            you delete a token, there's no way to recover it.
          </Typography>
        </>
      );
    }
  };

  const handleRevokeToken = async (token: RegistrationToken | undefined) => {
    if (!token) return;

    try {
      const revokeResult = await revokeRegistrationToken({
        variables: { input: { mrn: token.mrn } },
        refetchQueries: [LoadRegistrationTokensDocument],
      });
      handleConfirmationDialogClose();
      switch (revokeResult.data?.revokeRegistrationToken?.__typename) {
        case "RevokeRegistrationTokenSuccess":
          return enqueueSnackbar("Successfully revoked token", {
            variant: "success",
          });
        case "RevokeRegistrationTokenFailure":
          throw revokeResult.data.revokeRegistrationToken.message;
      }
    } catch {
      enqueueSnackbar("Failed to revoke token", { variant: "error" });
    }
  };

  const handleRevokeButtonClick = (token: RegistrationToken) => {
    handleConfirmationDialogOpen();
    setItemToDelete({ token });
  };

  const handleGenerateToken = async (
    tokenInput: Omit<RegistrationTokenInput, "spaceMrn">,
  ) => {
    try {
      const genResult = await generateRegistrationToken({
        variables: {
          input: {
            spaceMrn: space.mrn,
            ...tokenInput,
          },
        },
        refetchQueries: [LoadRegistrationTokensDocument],
      });
      const value = genResult.data?.generateRegistrationToken.token;
      if (!value) {
        throw new Error("Token is null");
      }
      const tokenData = jwtDecode<JwtPayload>(value);
      const generatedToken = {
        // we have to multiply to go from seconds to ms (which is what Date.now uses)
        endTime: (tokenData?.exp || 0) * 1000,
        startTime: Date.now(),
        value,
      };
      setGeneratedToken(generatedToken);
      enqueueSnackbar("Successfully added token", { variant: "success" });
    } catch {
      enqueueSnackbar("Failed to add token", { variant: "error" });
    }
  };

  const handleAddTokenClick = () => {
    if (addTokenFormOpen) {
      setAddTokenFormOpen(false);
    } else {
      setGeneratedToken(null);
      setAddTokenFormOpen(true);
    }
  };

  const handleLoadMore = () => {
    fetchMore({
      variables: { after: data?.registrationTokens?.pageInfo.endCursor },
    });
  };

  if (loading) {
    return <>Loading...</>;
  }

  if (error || !data) {
    return <>Loading failed.</>;
  }

  return (
    <Box>
      {/* Heading */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 3 }}>
        {hasGenerateTokenPermission && (
          <AddButton
            id="add-reg-token"
            onClick={handleAddTokenClick}
            aria-label="Add Registration Token"
            close={addTokenFormOpen}
          />
        )}
      </Box>
      <Collapse in={addTokenFormOpen} timeout="auto" unmountOnExit>
        <GenerateRegistrationTokenForm
          handleGenerateToken={handleGenerateToken}
        />
        <Collapse in={!!generatedToken} timeout="auto" unmountOnExit>
          {generatedToken && <RegistrationTokenResult token={generatedToken} />}
        </Collapse>
      </Collapse>
      {data?.registrationTokens?.totalCount === 0 ? (
        <NoTokensView tokenType="registration tokens" />
      ) : (
        <>
          <DataTable>
            <TableHead>
              <TableRow>
                <TableCell>
                  <VerifiedIcon />
                </TableCell>
                <TableCell>Key ID</TableCell>
                <TableCell>Expires</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.registrationTokens?.edges.map((tokenEdge) => (
                <TokenRow
                  key={tokenEdge.cursor}
                  token={tokenEdge.node}
                  onRevoke={handleRevokeButtonClick}
                  availablePermissions={availablePermissions}
                />
              ))}
            </TableBody>
          </DataTable>
          <ConfirmationDialog
            isOpen={isConfirmationDialogOpen}
            confirmButtonText="DELETE"
            content={getConfirmationModalContent()}
            onConfirm={() => handleRevokeToken(itemToDelete?.token)}
            onClose={handleConfirmationDialogClose}
            loading={loading}
          />
          <LoadMore
            loading={loading}
            handleLoadMore={handleLoadMore}
            hasNextPage={data.registrationTokens?.pageInfo.hasNextPage}
            sx={{ pt: 3 }}
          />
        </>
      )}
    </Box>
  );
}

type TokenRowProps = {
  token: RegistrationToken;
  onRevoke: (token: RegistrationToken) => void;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

function TokenRow({ token, onRevoke, availablePermissions }: TokenRowProps) {
  const [open, setOpen] = useState(false);
  const status = useTokenStatus(token);

  const hasRevokeTokenPermission = availablePermissions.includes(
    IamActions.AGENTMANAGER_REVOKEREGISTRATIONTOKEN,
  );

  const isStackSet = Boolean(
    token.labels.find(
      (l) => l.key === "aws.mondoo.com/stack-type" && l.value === "stackset",
    ),
  );

  return (
    <Fragment>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell>
          <TokenStatusIcon token={token} />
        </TableCell>
        <TableCell>{token.id}</TableCell>
        <TableCell>
          {token.expiresAt.includes("9999") ? "Never" : Format(token.expiresAt)}
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="Expand token" size="small">
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <DetailRow colSpan={5} open={open}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 1,
            py: 2,
          }}
        >
          <Box>
            <Typography variant="body2">{token.description}</Typography>
            <Typography variant="caption">
              Generated by {token.createdBy} at {Format(token.createdAt)}
            </Typography>
          </Box>
          {hasRevokeTokenPermission && (
            <Button
              variant="outlined"
              color="error"
              endIcon={<DeleteIcon />}
              onClick={() => onRevoke(token)}
              disabled={status !== "valid" || isStackSet}
            >
              Revoke
            </Button>
          )}
        </Box>
      </DetailRow>
    </Fragment>
  );
}

type GenerateRegistrationTokenFormProps = {
  handleGenerateToken: (
    tokenInput: Omit<RegistrationTokenInput, "spaceMrn">,
  ) => void;
};

type FormInput = {
  expiresIn: number;
  description: string;
};

const defaultValues = {
  expiresIn: 600,
  description: "",
};

function GenerateRegistrationTokenForm({
  handleGenerateToken,
}: GenerateRegistrationTokenFormProps) {
  const {
    control,
    watch,
    handleSubmit,
    formState: { isDirty, isValid },
  } = useForm<FormInput>({ defaultValues, mode: "onChange" });

  const expiresInValue = watch("expiresIn");

  const handleGenerateTokenSubmit = (data: FormInput) => {
    handleGenerateToken({
      noExpiration: data.expiresIn === -1,
      expiresIn: data.expiresIn === -1 ? undefined : Number(data.expiresIn),
      description: data.description,
    });
  };

  return (
    <Box
      component="form"
      sx={{ mb: 2 }}
      onSubmit={handleSubmit(handleGenerateTokenSubmit)}
    >
      <Controller
        name="expiresIn"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <FormControl fullWidth margin="normal">
            <InputLabel id="token-expires-label">Token expires</InputLabel>
            <Select
              {...field}
              id="token-expires-input"
              label="Token Expires"
              defaultValue={600}
              sx={{ backgroundColor: "code.background" }}
            >
              <MenuItem value={EXPIRATION_PERIOD.TEN_MINUTES}>
                {EXPIRATION_PERIOD_LABEL[EXPIRATION_PERIOD.TEN_MINUTES]}
              </MenuItem>
              <MenuItem value={EXPIRATION_PERIOD.THIRTY_MINUTES}>
                {EXPIRATION_PERIOD_LABEL[EXPIRATION_PERIOD.THIRTY_MINUTES]}
              </MenuItem>
              <MenuItem value={EXPIRATION_PERIOD.HOUR}>
                {EXPIRATION_PERIOD_LABEL[EXPIRATION_PERIOD.HOUR]}
              </MenuItem>
              <MenuItem value={EXPIRATION_PERIOD.EIGHT_HOURS}>
                {EXPIRATION_PERIOD_LABEL[EXPIRATION_PERIOD.EIGHT_HOURS]}
              </MenuItem>
              <MenuItem value={EXPIRATION_PERIOD.DAY}>
                {EXPIRATION_PERIOD_LABEL[EXPIRATION_PERIOD.DAY]}
              </MenuItem>
              <MenuItem value={EXPIRATION_PERIOD.WEEK}>
                {EXPIRATION_PERIOD_LABEL[EXPIRATION_PERIOD.WEEK]}
              </MenuItem>
              <MenuItem value={EXPIRATION_PERIOD.NON_EXPIRING}>
                {EXPIRATION_PERIOD_LABEL[EXPIRATION_PERIOD.NON_EXPIRING]}
              </MenuItem>
            </Select>
            {expiresInValue < EXPIRATION_PERIOD.DAY &&
              expiresInValue !== EXPIRATION_PERIOD.NON_EXPIRING && (
                <FormHelperText>
                  Because this token expires in only{" "}
                  {EXPIRATION_PERIOD_LABEL[expiresInValue]}, it won’t appear in
                  the table below.
                </FormHelperText>
              )}
          </FormControl>
        )}
      />
      <FormControl fullWidth margin="normal">
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              id="token-description-input"
              label="Token description"
              placeholder="Enter a description for this registration token..."
              multiline
              rows={5}
              sx={{ backgroundColor: "code.background" }}
            />
          )}
        />
      </FormControl>
      <FormControl fullWidth margin="normal">
        <Button
          color="primary"
          variant="contained"
          type="submit"
          sx={{ ml: "auto" }}
          disabled={!isValid || !isDirty}
        >
          Generate Token
        </Button>
      </FormControl>
    </Box>
  );
}

type RegistrationTokenResultProps = {
  token: Token;
};

function RegistrationTokenResult({ token }: RegistrationTokenResultProps) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: -0.5,
        }}
      >
        <Typography variant="body1" color="text.secondary" fontWeight="bold">
          Generated token
        </Typography>
        <TimerCountdown endTime={token.endTime} />
      </Box>
      <Code copyButton className="shell">
        {token.value}
      </Code>
    </Box>
  );
}

type TimerCountdownProps = {
  endTime: number;
};

function TimerCountdown({ endTime }: TimerCountdownProps) {
  let ticker: ReturnType<typeof setInterval>;

  const calcTimeLeft = (endTime: number) => {
    return endTime > 0 ? endTime - Date.now() : 0;
  };

  const formatTimeValue = (timeValue: number) => {
    return timeValue.toString().padStart(2, "0");
  };

  const formatTimeLeft = (timeLeft: number) => {
    const seconds = timeLeft / 1000;
    const secondsLeft = Math.floor(seconds % 60);
    const minutesLeft = Math.floor((seconds / 60) % 60);
    const hoursLeft = Math.floor(seconds / (60 * 60));
    const fmtSeconds = formatTimeValue(secondsLeft);
    const fmtMinutes = formatTimeValue(minutesLeft);
    const fmtHours = formatTimeValue(hoursLeft);

    let fmtTimeLeft = `${fmtMinutes}:${fmtSeconds}`;

    if (hoursLeft > 0) {
      fmtTimeLeft = `${fmtHours}:${fmtTimeLeft}`;
    }

    return fmtTimeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calcTimeLeft(endTime));

  useEffect(() => {
    if (endTime > 0) {
      ticker = setInterval(() => {
        setTimeLeft(calcTimeLeft(endTime));
      }, 1000);

      return () => {
        clearInterval(ticker);
      };
    }
  }, [endTime]);

  return (
    <Box sx={{ display: "flex" }}>
      <TimerOutlinedIcon sx={{ mr: 1 }} />
      <Typography>
        {endTime > 0 ? formatTimeLeft(timeLeft) : "Non-Expiring"}
      </Typography>
    </Box>
  );
}
