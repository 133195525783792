// prettier-ignore
export const gitlabInstructions = {
  title: "Gitlab CI/CD",
  tagline: "PIPELINE MONITORING FOR GITLAB PROJECTS",
  flags: [
    { id: "ci_cd", title: "CI/CD" },
    { id: "shift_left", title: "Shift Left" },
  ],
  instructions: [
    { id: 1, tagline: "Copy the Mondoo Platform credentials", token: true },
    {
      id: 2,
      tagline: "Securely store the credentials",
      markdownText:
        "In your GitLab project, generate a new CI/CD variable. Name the CI/CD variable `MONDOO_CONFIG_BASE64` and set the value to your generated credentials. To learn more, read the <a href='https://docs.gitlab.com/ee/ci/variables/' target='_blank' rel='noopener'>GitLab documentation</a>.",
    },
    {
      id: 3,
      tagline: "Create the GitLab pipeline config",
      markdownText:
        "Now that you have Mondoo credentials for running CI security scans, you can add cnspec steps to GitLab pipelines using your `.gitlab-ci.yml` file. You can use the example below or customize it to suit your needs. To learn more, read the <a href='https://mondoo.com/docs/platform/infra/supply/cicd/gitlab/' target='_blank' rel='noopener'>Mondoo documentation</a> and the <a href='https://docs.gitlab.com/ee/ci/' target='_blank' rel='noopener'>GitLab documentation</a>.",
      configExamples: [
        {
          id: "docker_config",
          config:
            '```yaml\nstages:\n\
    - build\n\
    - test\n\
  \n\
  # Build docker image\n\
  build-docker:\n\
    image: docker:latest\n\
    stage: build\n\
    services:\n\
      - docker:dind\n\
    script:\n\
      - mkdir -p /root/.docker/ && echo "{\"auths\":{\"$CI_REGISTRY\":{\"username\":\"$CI_REGISTRY_USER\",\"password\":\"$CI_REGISTRY_PASSWORD\"}}}" > /root/.docker/config.json\n\
      - docker build . --tag $CI_REGISTRY_IMAGE\n\
      - docker push $CI_REGISTRY_IMAGE:latest\n\
  \n\
  # Scan docker image\n\
  mondoo-cnspec:\n\
    stage: test\n\
    image:\n\
      name: mondoo/cnspec:latest\n\
      entrypoint: [""]\n\
    script:\n\
      - mkdir -p /root/.docker/ && echo "{\"auths\":{\"$CI_REGISTRY\":{\"username\":\"$CI_REGISTRY_USER\",\"password\":\"$CI_REGISTRY_PASSWORD\"}}}" > /root/.docker/config.json\n\
      # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
      - cnspec scan container ${CI_REGISTRY_IMAGE}:latest --score-threshold 90\n\
    dependencies:\n\
      - build-docker\n\
  ```',
        },
        {
          id: "kubernetes_config",
          config:
            '```yaml\nstages:\n\
  - test\n\
\n\
mondoo-cnspec:\n\
  stage: test\n\
  image:\n\
    name: mondoo/cnspec:latest\n\
    entrypoint: [""]\n\
  script:\n\
    # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
    - cnspec scan k8s my-kubernetes-manifest.yml --score-threshold 90```',
        },
        {
          id: "terraform_config",
          config:
            '```yaml\nstages:\n\
  - test\n\
\n\
mondoo-cnspec:\n\
  stage: test\n\
  image:\n\
    name: mondoo/cnspec:latest\n\
    entrypoint: [""]\n\
  script:\n\
    # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
    - cnspec scan terraform my_tf_config.tf --score-threshold 90```',
        },
      ],
    },
  ],
};
