import { createSvgIcon } from "@mui/material";

export const TelegramIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      fill="url(#paint0_linear_3039_21062)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.98053 11.884C9.18547 10.4864 11.3237 9.56546 12.3953 9.12101C15.4497 7.84941 16.0842 7.62966 16.4991 7.62225C16.5904 7.61978 16.7929 7.642 16.9262 7.75064C17.0373 7.842 17.067 7.96299 17.0818 8.04941C17.0966 8.13583 17.1139 8.33089 17.0991 8.48151C16.9336 10.2198 16.2176 14.442 15.8521 16.3901C15.6991 17.2148 15.3953 17.4914 15.1015 17.5185C14.462 17.5778 13.9781 17.0963 13.3583 16.6914C12.3904 16.0568 11.8423 15.6618 10.904 15.042C9.81757 14.326 10.5213 13.9334 11.141 13.2914C11.304 13.1235 14.1163 10.563 14.1707 10.3309C14.1781 10.3013 14.183 10.1926 14.1188 10.1358C14.0546 10.079 13.9608 10.0988 13.8916 10.1136C13.7953 10.1358 12.2472 11.1581 9.25214 13.1803C8.81263 13.4815 8.4151 13.6297 8.05955 13.6198C7.66695 13.6124 6.9114 13.3976 6.3509 13.2148C5.66202 12.9901 5.11387 12.8716 5.16325 12.4914C5.18547 12.2939 5.45955 12.0914 5.98053 11.884Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3039_21062"
        x1="12"
        y1="1"
        x2="12"
        y2="22.837"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2AABEE" />
        <stop offset="1" stopColor="#229ED9" />
      </linearGradient>
    </defs>
  </svg>,
  "Telegram",
);
