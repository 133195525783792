import { Container, ContainerOwnProps } from "@mui/material";
import { Outlet } from "react-router-dom";

export function PageLayout({
  maxWidth = "lg",
}: {
  maxWidth?: ContainerOwnProps["maxWidth"] | false;
}) {
  return (
    <Container sx={{ pt: 3 }} maxWidth={maxWidth}>
      <Outlet />
    </Container>
  );
}
