import { alpha, useTheme, Box, Tooltip } from "@mui/material";
import { CheckIcon, CloseIcon } from "~/components/icons";
import { capitalizeFirstLetter } from "~/lib/helpers";
import { useTokenStatus } from "./use-token-status";
import { TokenStatus, TokenStatusFields } from "./types";

export type TokenStatusIconProps = {
  token: TokenStatusFields;
  size?: "sm" | "md" | "lg";
  disableTooltip?: boolean;
};

export function TokenStatusIcon({
  token,
  size = "md",
  disableTooltip = false,
}: TokenStatusIconProps) {
  const theme = useTheme();
  const status = useTokenStatus(token);

  const getDiameter = (size: TokenStatusIconProps["size"]) => {
    switch (size) {
      case "sm":
        return theme.spacing(2.4);
      case "lg":
        return theme.spacing(6);
      case "md":
      default:
        return theme.spacing(4);
    }
  };

  const getFontSize = (size: TokenStatusIconProps["size"]) => {
    switch (size) {
      case "sm":
        return 14;
      case "lg":
        return 32;
      case "md":
      default:
        return 24;
    }
  };

  const diameter = getDiameter(size);
  const fontSize = getFontSize(size);

  const iconStyles = {
    fontSize: fontSize,
    lineHeight: "16px",
    color:
      status === "valid"
        ? theme.palette.excellent.main
        : theme.palette.fail.main,
  };

  const getIcon = (status: TokenStatus) => {
    switch (status) {
      case "valid":
        return <CheckIcon sx={iconStyles} />;
      case "expired":
      case "revoked":
      default:
        return <CloseIcon sx={iconStyles} />;
    }
  };

  const toolTipContent = (status: TokenStatus): string => {
    return capitalizeFirstLetter(status);
  };

  return (
    <Tooltip
      title={toolTipContent(status)}
      placement="top"
      arrow
      open={disableTooltip ? false : undefined}
    >
      <Box
        sx={[
          {
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            width: diameter,
            height: diameter,
            borderRadius: "50%",
            background: alpha(iconStyles.color, 0.1),
          },
          disableTooltip
            ? {}
            : {
                transition: "box-shadow 300ms ease-in-out",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: `0px 0px 0px 1px ${iconStyles.color}`,
                },
              },
        ]}
      >
        {getIcon(status)}
      </Box>
    </Tooltip>
  );
}
