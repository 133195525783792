import { TextField, StandardTextFieldProps, MenuItem } from "@mui/material";
import { SearchResultItemOrderField, FieldOption } from "../../../types";

type SortByProps = StandardTextFieldProps & {
  sortByOptions: FieldOption[];
  value: string;
  onChangeHandler: (value: FieldOption) => void;
};

const SortBy = ({
  size = "small",
  name = "orderBy",
  label = "Sort by",
  sortByOptions,
  value,
  onChangeHandler,
  ...restProps
}: SortByProps) => {
  return (
    <TextField
      select
      size={size}
      name={name}
      label={label}
      value={value}
      onChange={(e) => {
        onChangeHandler({
          name: SearchResultItemOrderField["Name"],
          value: e.target.value,
        });
      }}
      {...restProps}
    >
      {sortByOptions.map(({ name, value }) => (
        <MenuItem key={name} value={value}>
          {name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SortBy;
