import { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ChevronRightIcon } from "~/components/icons";
import { Command } from "~/components/guides/components";
import { Space } from "~/lib/types";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ActionType,
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  GitlabIntegrationType,
  IntegrationType,
  useCreateClientIntegrationMutation,
  useTriggerActionLazyQuery,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { getError } from "~/lib/handle-error";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { helperTextStyles, ValidationMessage } from "../../validations/helpers";
import { RecommendedPolicies } from "../../components/recommended-policies";
import useGenerateIntegrationName from "../../utils/useGenerateIntegrationName";
import { UpdateFlowData } from "../../types";

type GitlabFormInput = {
  integrationName: string;
  group?: string;
  token?: string;
  discoverGroups?: boolean;
  discoverProjects?: boolean;
  discoverTerraform?: boolean;
  discoverK8sManifests?: boolean;
  // discoverDockerfiles?: boolean;
  baseUrl?: string;
};

const defaultValues: GitlabFormInput = {
  integrationName: "",
  group: "",
  token: "",
  discoverGroups: false,
  discoverProjects: false,
  discoverTerraform: false,
  discoverK8sManifests: false,
  // discoverDockerfiles: false,
  baseUrl: "",
};

export function GitlabIntegrationForm({
  space,
  updateFlow,
}: {
  space: Space;
  updateFlow?: UpdateFlowData;
}) {
  let navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [view, setView] = useState<"setup" | "add policies">("setup");
  const defaultIntegrationName = useGenerateIntegrationName({ space });

  const {
    control,
    handleSubmit,
    reset,
    getFieldState,
    formState: { errors, isValid, isSubmitSuccessful },
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      ...defaultValues,
      integrationName: defaultIntegrationName,
    },
  });

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      const integrationMrn = data.createClientIntegration.integration.mrn;
      triggerClientIntegrationScan({
        variables: { input: { mrn: integrationMrn, type: ActionType.RunScan } },
      });
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateClientIntegrationConfigurationMutation] =
    useUpdateClientIntegrationConfigurationMutation({
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      refetchQueries: [
        {
          query: GetClientIntegrationDocument,
          variables: {
            mrn: `//integration.api.mondoo.app/spaces/${
              space.id
            }/integrations/${updateFlow?.integration.mrn.split("/").pop()}`,
          },
        },
      ],
    });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    onError(error) {
      console.log("%c Error Scheduling scan on creation", "color: tomato");
      console.log(error.message);
    },
  });

  useEffect(() => {
    if (updateFlow) {
      if (
        updateFlow.integration.configurationOptions?.__typename ===
        "GitlabConfigurationOptions"
      ) {
        reset({
          integrationName: updateFlow.integration.name,
          group: updateFlow.integration.configurationOptions?.group,
          discoverGroups:
            updateFlow.integration.configurationOptions?.discoverGroups ??
            false,
          discoverProjects:
            updateFlow.integration.configurationOptions?.discoverProjects ??
            false,
          discoverTerraform:
            updateFlow.integration.configurationOptions?.discoverTerraform ??
            false,
          discoverK8sManifests:
            updateFlow.integration.configurationOptions?.discoverK8sManifests ??
            false,
          // discoverDockerfiles:
          //   updateFlow.integration.configurationOptions?.discoverDockerfiles ??
          //   false,
          baseUrl: updateFlow.integration.configurationOptions?.baseUrl,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (isSubmitSuccessful && !updateFlow) {
      reset(defaultValues);
    }
  }, [isSubmitSuccessful]);

  const onSubmit: SubmitHandler<GitlabFormInput> = async (data) => {
    const gitlabConfigurationOptions = {
      group: data.group?.trim(),
      token: data.token?.trim(),
      type: data.group
        ? GitlabIntegrationType.Group
        : GitlabIntegrationType.None,
      discoverGroups: data?.discoverGroups ?? false,
      discoverProjects: data?.discoverProjects ?? false,
      discoverTerraform: data?.discoverTerraform ?? false,
      discoverK8sManifests: data?.discoverK8sManifests ?? false,
      // discoverDockerfiles: data?.discoverDockerfiles ?? false,
      baseUrl: data?.baseUrl,
    };

    try {
      if (updateFlow) {
        const integrationId = updateFlow.integration.mrn.split("/").pop();
        const mrn = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;
        await updateClientIntegrationConfigurationMutation({
          variables: {
            input: {
              mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Gitlab,
              configurationOptions: {
                gitlabConfigurationOptions,
              },
            },
          },
        });

        enqueueSnackbar("Successfully updated configuration", {
          variant: "success",
        });
        navigate(
          `/space/integrations/gitlab/${integrationId}/?spaceId=${space.id}`,
        );
      } else {
        await createIntegration({
          variables: {
            input: {
              spaceMrn: space.mrn,
              name: data.integrationName.trim(),
              type: ClientIntegrationType.Gitlab,
              longLivedToken: false,
              configurationOptions: {
                gitlabConfigurationOptions,
              },
            },
          },
        });
        setView("add policies");
      }
    } catch (e) {
      const msg = getError(e);
      enqueueSnackbar(msg, { variant: "error" });
    }
  };

  document.title = "GitLab · Integrations Setup · Mondoo";

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader {...{ type: IntegrationType.Gitlab }} />
          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Step 1 */}
              <Box pb={4}>
                <Command
                  number={1}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Choose an integration name
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Please choose a descriptive name that lets you easily
                    identify your integration.
                  </Typography>
                  <Controller
                    name="integrationName"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your integration name..."
                        error={Boolean(errors.integrationName)}
                        helperText={
                          Boolean(errors.integrationName) && (
                            <ValidationMessage error={errors.integrationName} />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 2 */}
              <Box pb={4}>
                <Command
                  number={2}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide your GitLab base URL{" "}
                  <Typography
                    component="span"
                    color="text.secondary"
                    sx={{ fontStyle: "italic" }}
                  >
                    (optional)
                  </Typography>
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Specify a custom GitLab base URL. By default, Mondoo uses{" "}
                    <Link
                      href="https://gitlab.com"
                      target="_blank"
                      rel="noopener"
                    >
                      gitlab.com
                    </Link>{" "}
                    .
                  </Typography>
                  <Controller
                    name="baseUrl"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your Gitlab base URL..."
                        error={Boolean(errors.baseUrl)}
                        helperText={
                          getFieldState("baseUrl").isTouched &&
                          Boolean(errors.baseUrl) && (
                            <ValidationMessage
                              error={errors.baseUrl}
                              integrationTypeId="gitlab"
                            />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 3 */}
              <Box pb={4}>
                <Command
                  number={3}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Define the GitLab group to scan{" "}
                  <Typography
                    component="span"
                    color="text.secondary"
                    sx={{ fontStyle: "italic" }}
                  >
                    (optional)
                  </Typography>
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    Specify the GitLab group you want to monitor. To learn more,
                    read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/saas/gitlab/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="group"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="Your GitLab group..."
                        error={Boolean(errors.group)}
                        helperText={
                          getFieldState("group").isTouched &&
                          Boolean(errors.group) && (
                            <ValidationMessage
                              error={errors.group}
                              integrationTypeId="gitlab"
                            />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 4 */}
              <Box pb={4}>
                <Command
                  number={4}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Provide your personal access token
                </Command>

                <Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, mt: 2 }}
                  >
                    To learn about your personal access token, read the{" "}
                    <Link
                      href="https://mondoo.com/docs/platform/infra/saas/gitlab/"
                      target="_blank"
                      rel="noopener"
                    >
                      Mondoo documentation
                    </Link>
                    .
                  </Typography>
                  <Controller
                    name="token"
                    control={control}
                    rules={{
                      required: updateFlow === undefined ? true : false,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="password"
                        sx={{
                          background: theme.palette.code.background,
                          borderRadius: 1,
                          color: "text.primary",
                          ...helperTextStyles,
                        }}
                        placeholder="xxxx-your-token-here"
                        error={Boolean(errors.token)}
                        helperText={
                          getFieldState("token").isTouched &&
                          Boolean(errors.token) && (
                            <ValidationMessage
                              error={errors.token}
                              integrationTypeId="gitlab"
                            />
                          )
                        }
                      />
                    )}
                  />
                </Box>
              </Box>

              {/* Step 5 */}
              <Box pb={4}>
                <Command
                  number={5}
                  options={{
                    fontSize: { xs: 16 },
                    dotColor: theme.palette.background.lightest,
                  }}
                >
                  Select discovery options
                </Command>

                <FormControl>
                  <FormGroup sx={{ mt: 1, pl: 1.5 }}>
                    <Controller
                      name="discoverGroups"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Groups the token can access"
                        />
                      )}
                    />
                    <Controller
                      name="discoverProjects"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Projects the token can access"
                        />
                      )}
                    />
                    <Controller
                      name="discoverTerraform"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Terraform files"
                        />
                      )}
                    />
                    <Controller
                      name="discoverK8sManifests"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Kubernetes manifests"
                        />
                      )}
                    />
                    {/* <Controller
                      name="discoverDockerfiles"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          control={
                            <Checkbox {...field} checked={field.value} />
                          }
                          label="Dockerfiles"
                        />
                      )}
                    /> */}
                  </FormGroup>
                </FormControl>
              </Box>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  endIcon={<ChevronRightIcon />}
                  disabled={!isValid}
                >
                  {updateFlow ? "update configuration" : "start scanning"}
                </Button>
              </Box>
            </form>
          </Box>
        </Fragment>
      ) : (
        <RecommendedPolicies {...{ space }} filterTypes={["gitlab"]} />
      )}
    </Fragment>
  );
}
