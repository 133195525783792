import { FormEvent, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Hero } from "./components/background";
import { CheckCircleIcon } from "~/components/icons";
import { MondooLogoHorizontal } from "~/images/icons";
import {
  AuthComponent,
  AuthFormInput,
} from "~/unauthenticatedApp/components/auth-component";
import { useAuth } from "~/login/provider";
import { SubmitHandler } from "react-hook-form";
import { AuthOptions } from "~/login/auth-context";
import { AuthErrorsComponent } from "./components/auth-errors";

export function SignupPage() {
  const { dispatch, signupWithEmailAndPassword, loginWithProvider } = useAuth();
  const [step, setStep] = useState<"email" | "password">("email");
  async function handleSignUpWithEmailAndPassword(
    data: AuthFormInput,
    event: FormEvent,
  ) {
    const { email, password, optIn } = data;

    signupWithEmailAndPassword(event, dispatch, {
      email: email.trim(),
      password: password.trim(),
      optIn,
    });
  }

  function handleLoginWithProvider(event: FormEvent, provider: AuthOptions) {
    loginWithProvider(event, dispatch, provider);
  }

  const onContinueSubmit: SubmitHandler<AuthFormInput> = async (
    data,
    event,
  ) => {
    // we have all the info we need, sign the user up.
    if (data.email && data.password) {
      return handleSignUpWithEmailAndPassword(data, event as FormEvent);
    }

    // we only have the email so far, so move the user to the password step
    if (data.email) {
      setStep("password");
    }
  };
  return (
    <Hero view="signup">
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "space-between" },
          width: 1,
          ".MuiTypography-root": {
            fontFamily: "Sofia Pro",
          },
        }}
      >
        <Box sx={{ display: { xs: "none", md: "block" }, maxWidth: 900 }}>
          <MondooLogoHorizontal width={267} />
          <Typography
            variant="h3"
            component="h1"
            fontWeight={700}
            sx={{ my: 6 }}
          >
            Develop your security posture across your entire infrastructure with
            xSPM
          </Typography>
          <List>
            {listItems.map((item) => (
              <ListItem
                disableGutters
                sx={{ alignItems: "flex-start" }}
                key={item.id}
              >
                <ListItemIcon sx={{ mt: 0.5, mr: 2, minWidth: 20 }}>
                  <CheckCircleIcon />
                </ListItemIcon>
                <ListItemText
                  primary={item.primary}
                  primaryTypographyProps={{
                    fontSize: 24,
                    color: "text.primary",
                    fontWeight: 700,
                  }}
                  secondary={item.secondary}
                  sx={{ mt: 0 }}
                  secondaryTypographyProps={{
                    fontSize: 24,
                    color: "text.primary",
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Box>
        <Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "center",
              mb: 5,
            }}
          >
            <MondooLogoHorizontal width={180} />
          </Box>
          <AuthComponent
            {...{
              view: "signup",
              step,
              setStep,
              onSubmit: onContinueSubmit,
              handleLoginWithProvider,
            }}
          />
        </Box>
      </Box>
      <AuthErrorsComponent />
    </Hero>
  );
}

const listItems = [
  {
    id: "signup",
    primary: "Sign up to our free plan",
    secondary: "No timed trials, no lock-in",
  },
  {
    id: "scan",
    primary: "Scan your infrastructure",
    secondary: "Instantly identify vulnerabilities and risky misconfigurations",
  },
  {
    id: "establish",
    primary: "Establish your baseline",
    secondary: "Effortlessly set goals and track improvements",
  },
];
