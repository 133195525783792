import { createSvgIcon } from "@mui/material";

export const OpenshiftAuthIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M47.0014 13.782C46.4839 12.7199 45.8915 11.6918 45.197 10.7249L37.8095 13.4144C38.6674 14.2927 39.3892 15.28 39.9815 16.3353L47.0014 13.782ZM14.3602 21.9526L6.97266 24.642C7.06798 25.8267 7.27224 26.9979 7.55821 28.1417L14.578 25.5884C14.3465 24.3969 14.2648 23.1781 14.3602 21.9526Z"
      fill="#AAAAAA"
    />
    <path
      d="M30.7457 11.6917C32.2845 12.4066 33.6122 13.3871 34.7356 14.531L42.1231 11.8415C40.0805 8.96821 37.2889 6.57153 33.8777 4.97828C23.3173 0.0555458 10.7279 4.63784 5.8052 15.1982C4.21195 18.6162 3.61959 22.2453 3.89194 25.7586L11.2794 23.0692C11.402 21.4691 11.7901 19.8622 12.505 18.3303C15.7051 11.4738 23.8893 8.49841 30.7457 11.6917Z"
      fill="#AAAAAA"
    />
    <path
      d="M38.6259 25.187C38.5102 26.787 38.1017 28.3871 37.3868 29.9259C34.1866 36.7823 26.0093 39.7645 19.1529 36.5644C17.6141 35.8495 16.2728 34.8759 15.1561 33.7252L7.78906 36.4078C9.83169 39.2811 12.6097 41.6778 16.0277 43.2711C26.5881 48.1938 39.1775 43.6115 44.1002 33.0511C45.6934 29.6399 46.2858 26.0108 45.9998 22.4975L38.6259 25.187Z"
      fill="#AAAAAA"
    />
    <path
      d="M40.4432 16.172L33.4233 18.7253C34.7238 21.0607 35.3434 23.7501 35.1391 26.4532L42.5062 23.7706C42.302 21.1288 41.5938 18.5483 40.4432 16.172ZM8.01303 27.9716L1 30.5249C1.64683 33.085 2.76347 35.5157 4.30225 37.6809L11.6693 34.9982C9.77649 33.0577 8.51687 30.5998 8.01303 27.9716Z"
      fill="#AAAAAA"
    />
    <path
      d="M45.9314 11.8417C45.6999 11.4672 45.4548 11.0927 45.2029 10.7319L37.8153 13.4213C38.1422 13.755 38.4418 14.109 38.7277 14.4699L45.9314 11.8417ZM14.3251 23.437C14.3047 22.9468 14.3183 22.4498 14.3524 21.9527L6.96484 24.6422C7.0057 25.112 7.06017 25.5818 7.13506 26.0516L14.3251 23.437Z"
      fill="#AAAAAA"
    />
    <path
      d="M45.992 22.5045L38.6249 25.1872C38.55 26.2493 38.339 27.3115 38.0053 28.3601L46.0261 25.4323C46.0805 24.4518 46.0737 23.4782 45.992 22.5045ZM7.78125 36.4148C8.34638 37.2114 8.97959 37.974 9.66047 38.6958L17.6812 35.768C16.7416 35.1824 15.8973 34.4947 15.1483 33.7322L7.78125 36.4148Z"
      fill="#AAAAAA"
    />
  </svg>,
  "Openshift",
);
