import { Component } from "react";
import { WarningIcon as NoPermissionIcon } from "~/components/icons";
import { styled } from "@mui/material/styles";
import { Grid, Typography } from "@mui/material";
import { ContentBody } from "./content-body";

const Center = styled("div")`
  text-align: center;
`;

const BigNoPermission = styled(NoPermissionIcon)`
  width: 2em !important;
  height: 2em !important;
`;

export class NoPermission extends Component {
  render() {
    return (
      <Grid
        container
        spacing={3}
        style={{
          maxWidth: 1200,
        }}
      >
        <Grid item xs={12}>
          <ContentBody>
            <Center>
              <BigNoPermission />
              <Typography variant="h3">No Permission</Typography>
            </Center>
          </ContentBody>
        </Grid>
      </Grid>
    );
  }
}
