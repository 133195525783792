import React from "react";
import { alpha, Box, Button, Paper, Typography } from "@mui/material";
import { PackageIcon } from "~/components/icons/mondoo/package";
import { ApplicationIcon } from "~/components/ui-library/icons/ApplicationIcon";
import { PackageTypes } from "~/pages/inventory/components/AssetSoftware/types";

type PackageGroupType = {
  active: boolean;
  onClick: (type: keyof typeof PackageTypes) => void;
  id: keyof typeof PackageTypes;
  title: string;
  count: string;
};

export const SoftwareTypeGroup = ({
  onClick,
  active,
  id,
  title,
  count,
}: PackageGroupType) => {
  const typeIconMap = {
    [PackageTypes.APPLICATION]: <ApplicationIcon fontSize="large" />,
    [PackageTypes.OS_PACKAGE]: <PackageIcon fontSize="large" />,
  };

  return (
    <Paper
      onClick={() => onClick(id)}
      component={Button}
      disableRipple
      fullWidth
      data-testid={`package-type-${id}`}
      sx={{
        height: "100%",
        p: 2,
        cursor: "pointer",
        justifyContent: "flex-start",
        background: (theme) =>
          active ? alpha(theme.palette.primary.main, 0.2) : "background.paper",
        opacity: 1,
        boxShadow: (theme) =>
          active ? `0px 0px 0px 1px ${theme.palette.primary.main}` : "none",
        transition: "all .35s ease-in-out",
        "&:hover, &:focus": {
          background: (theme) => alpha(theme.palette.primary.main, 0.2),
          opacity: 1,
        },
      }}
    >
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mr: 2,
          }}
        >
          {typeIconMap[id] || <PackageIcon fontSize="large" />}
        </Box>
        <Box sx={{ textAlign: "left" }}>
          <Typography fontWeight={700}>{title}</Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            sx={{ textTransform: "uppercase" }}
          >
            {count}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
