import { ScoreType, useAssetForwardPaginationQuery } from "~/operations";
import { Space } from "~/lib/types";

type UseAssetsParams = {
  space: Space;
  searchFilters: string[];
};

type UseAssetsReturnType = {
  loading: boolean;
  count: number;
};

export function useGetAssetsCount({
  space,
  searchFilters,
}: UseAssetsParams): UseAssetsReturnType {
  const { data, loading } = useAssetForwardPaginationQuery({
    variables: {
      spaceMrn: space.mrn,
      scoreRange: null,
      scoreType: ScoreType.Unknown,
      platformName: [],
      platformKind: [],
      platformRuntime: [],
      labels: [],
      eol: null,
      reboot: null,
      exploitable: null,
      updated: null,
      orderBy: null,
      queryTerms: searchFilters,
      first: 50,
      groups: [],
    },
    fetchPolicy: "cache-and-network",
  });

  return {
    count: data?.assets?.totalCount || 0,
    loading,
  };
}
