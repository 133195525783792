import {
  AggregateScoreType,
  RiskFactorStats,
  useGetFindingRiskFactorsQuery,
  useGetRiskFactorsDocsQuery,
} from "~/operations";
import { AggregateScoresEdges } from "~/components/FirewatchPage";

type UseFindingRiskFactorsParams = {
  spaceMrn: string;
  findingMrn: string;
  scoreType: AggregateScoreType;
};

export function useFindingRiskFactors({
  spaceMrn,
  findingMrn,
  scoreType,
}: UseFindingRiskFactorsParams) {
  const { data: aggregateScoresData, loading: aggregateScoresLoading } =
    useGetFindingRiskFactorsQuery({
      variables: {
        entityMrn: spaceMrn,
        first: 1,
        filter: {
          scoreType,
          findingMrn,
        },
      },
      skip: !findingMrn,
    });

  const aggregateScoresResponse = aggregateScoresData?.aggregateScores as {
    edges: AggregateScoresEdges;
  };

  const edges = aggregateScoresResponse?.edges;

  const riskFactors = edges?.[0]?.node?.riskFactors || [];

  const { data: riskFactorsDocsData, loading: riskFactorsLoading } =
    useGetRiskFactorsDocsQuery({
      variables: {
        spaceMrn,
        mrns: riskFactors.map((rf) => String(rf?.mrn)),
      },
      skip: riskFactors.length === 0,
    });

  const riskFactorsWithDocs = riskFactors.map((rf) => {
    const riskFactorDocs = riskFactorsDocsData?.riskFactors?.edges?.find(
      (rf2) => rf2.mrn === rf?.mrn,
    );

    return {
      ...rf,
      description: {
        active: String(riskFactorDocs?.docs?.active || ""),
        inactive: String(riskFactorDocs?.docs?.inactive || ""),
      },
    } as RiskFactorStats & {
      description: { active: string; inactive: string };
    };
  });

  return {
    riskFactorsWithDocs,
    riskFactors,
    loading: aggregateScoresLoading || riskFactorsLoading,
  };
}
