import { createSvgIcon } from "@mui/material";

export const ChromeIcon = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g clipPath="url(#clip0_4418_3295)">
      <path
        d="M12.0002 16.8C14.6512 16.8 16.8002 14.651 16.8002 12C16.8002 9.34905 14.6512 7.20001 12.0002 7.20001C9.34923 7.20001 7.2002 9.34905 7.2002 12C7.2002 14.651 9.34923 16.8 12.0002 16.8Z"
        fill="#188FD1"
      />
      <path
        d="M2.04004 6.23999C3.05334 4.4965 4.50655 3.04955 6.2544 2.04378C8.00224 1.03801 9.98348 0.508667 12 0.508667C14.0166 0.508667 15.9978 1.03801 17.7457 2.04378C19.4935 3.04955 20.9467 4.4965 21.96 6.23999L14.88 7.07999C14.0094 6.57724 13.0224 6.31112 12.017 6.30809C11.0116 6.30506 10.023 6.56522 9.14937 7.06271C8.27572 7.5602 7.54751 8.27769 7.03711 9.14386C6.52672 10.01 6.25192 10.9947 6.24004 12L2.04004 6.23999Z"
        fill="#EA3939"
      />
      <path
        d="M12.0005 23.52C9.97969 23.5171 7.99523 22.9827 6.24628 21.9704C4.49734 20.9581 3.04543 19.5036 2.03631 17.7528C1.02718 16.002 0.496341 14.0166 0.497071 11.9958C0.497801 9.97506 1.03007 7.99003 2.04046 6.23999L6.24046 12C6.25235 13.0053 6.52714 13.99 7.03754 14.8561C7.54793 15.7223 8.27615 16.4398 9.14979 16.9373C10.0234 17.4348 11.0121 17.6949 12.0174 17.6919C13.0228 17.6889 14.0098 17.4227 14.8805 16.92L12.0005 23.52Z"
        fill="#4AAE48"
      />
      <path
        d="M21.96 6.23999C22.9704 7.99003 23.5027 9.97506 23.5034 11.9958C23.5041 14.0166 22.9733 16.002 21.9642 17.7528C20.955 19.5036 19.5031 20.9581 17.7542 21.9704C16.0052 22.9827 14.0208 23.5171 12 23.52L14.88 16.92C15.7243 16.406 16.422 15.6835 16.9063 14.8218C17.3905 13.9601 17.6448 12.9884 17.6448 12C17.6448 11.0116 17.3905 10.0398 16.9063 9.17818C16.422 8.31652 15.7243 7.59395 14.88 7.07999L21.96 6.23999Z"
        fill="#FED14B"
      />
    </g>
    <defs>
      <clipPath id="clip0_4418_3295">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Chrome",
);
