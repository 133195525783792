import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { HubCard } from "~/components/ui-library";

type Change = {
  description: ReactNode;
  href: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

export type MostRecentChangesProps = {
  changes: Change[];
};

export function MostRecentChanges({ changes }: MostRecentChangesProps) {
  return (
    <HubCard
      {...{
        loading: false,
        title: "Most Recent Changes",
        icon: <Box />,
        sx: { height: 1 },
      }}
    >
      <List sx={{ pl: 0, pt: 3 }} disablePadding>
        {changes.map(({ description, href, startIcon, endIcon }) => {
          return (
            <ListItem disablePadding key={href}>
              <ListItemButton
                component={RouterLink}
                to={href}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  pl: 1,
                  py: 0.5,
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "background.light",
                  },
                }}
              >
                {startIcon && <ListItemIcon>{startIcon}</ListItemIcon>}
                <ListItemText primary={description} />
                {endIcon && (
                  <ListItemIcon sx={{ minWidth: 0, ml: "auto" }}>
                    {endIcon}
                  </ListItemIcon>
                )}
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </HubCard>
  );
}

const fakeRecentChangeData = [
  { mrn: "/somelongmrn1", action: "disabled", total: 1, exceptions: true },
  { mrn: "/somelongmrn2", action: "snooze", total: 3, exceptions: false },
  { mrn: "/somelongmrn3", action: "disabled", total: 1, exceptions: false },
  { mrn: "/somelongmrn4", action: "snooze", total: 1, exceptions: true },
  { mrn: "/somelongmrn5", action: "snooze", total: 2, exceptions: false },
];
