import { createSvgIcon } from "@mui/material";

export const GitlabAuthIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8265 46.1539L0.744 29.0271C0.444923 28.7908 0.219692 28.4732 0.096 28.1095L0.0923077 28.0948C0.0332308 27.9286 0 27.7385 0 27.5391C0 27.3397 0.0332308 27.1495 0.096 26.9705L0.0923077 26.9834L2.79323 18.792L23.8265 46.1539ZM9.77169 2.4997L15.0775 18.7883H2.79138L8.00308 2.4997C8.13231 2.11755 8.48862 1.84801 8.90769 1.84801H8.93538H8.93354C8.94831 1.84801 8.96492 1.84616 8.98154 1.84616C9.37292 1.84616 9.69785 2.12493 9.76985 2.49416V2.4997H9.77169ZM15.0775 18.7883H32.5772L23.8283 46.1539L15.0775 18.7883ZM47.5606 26.9779C47.6197 27.144 47.6529 27.3342 47.6529 27.5335C47.6529 27.7329 47.6197 27.9231 47.5569 28.1022L47.5606 28.0892C47.4332 28.4677 47.208 28.7834 46.9126 29.0179L46.9089 29.0215L23.8265 46.1539L44.8615 18.7883L47.5606 26.9779ZM39.6498 2.4997L44.8615 18.7883H32.5754L37.8812 2.4997C37.9551 2.12493 38.28 1.84616 38.6714 1.84616C38.688 1.84616 38.7046 1.84616 38.7212 1.84801H38.7194H38.7452C39.1643 1.84801 39.5188 2.11755 39.648 2.49231L39.6498 2.4997Z"
      fill="#AAAAAA"
    />
  </svg>,
  "Gitlab",
);
