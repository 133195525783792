import { SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const NistIcon = ({ ...props }) => {
  const { mode } = useColorMode();
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 645.3 170.53">
        <path
          d="M45,0A44.81,44.81,0,0,0,0,45V170H40V45c0-4,5-6,8.5-3.5L153.4,156.6c29,29,77,8,77-31V-.4h-40v126c0,4-5,6-8.3,3.5L77.6,14.1c-10-10-18-14-32.5-14M250.2,0V125a45,45,0,0,0,45,45H463a52.5,52.5,0,0,0,0-105H357.8a12.5,12.5,0,0,1,0-25H535.3V170h40V40h70V0H357.8a52.5,52.5,0,0,0,0,105h105a12.5,12.5,0,0,1,0,25H295.3a5,5,0,0,1-5-5V0Z"
          transform="translate(0 0.4)"
          fill={mode === "light" ? "#363636" : "#fff"}
        />
      </svg>
      ,
    </SvgIcon>
  );
};

NistIcon.displayName = "NIST";
