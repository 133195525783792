import { createSvgIcon } from "@mui/material";

export const HealingIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.99513 8.37113C10.2815 8.37113 10.5137 8.13896 10.5137 7.85256C10.5137 7.56616 10.2815 7.33398 9.99513 7.33398C9.70873 7.33398 9.47656 7.56616 9.47656 7.85256C9.47656 8.13896 9.70873 8.37113 9.99513 8.37113Z"
      fill="currentColor"
    />
    <path
      d="M12.1475 10.5196C12.4339 10.5196 12.666 10.2874 12.666 10.001C12.666 9.71459 12.4339 9.48242 12.1475 9.48242C11.8611 9.48242 11.6289 9.71459 11.6289 10.001C11.6289 10.2874 11.8611 10.5196 12.1475 10.5196Z"
      fill="currentColor"
    />
    <path
      d="M7.8467 10.5196C8.1331 10.5196 8.36527 10.2874 8.36527 10.001C8.36527 9.71459 8.1331 9.48242 7.8467 9.48242C7.5603 9.48242 7.32812 9.71459 7.32812 10.001C7.32812 10.2874 7.5603 10.5196 7.8467 10.5196Z"
      fill="currentColor"
    />
    <path
      d="M9.99513 12.668C10.2815 12.668 10.5137 12.4358 10.5137 12.1494C10.5137 11.863 10.2815 11.6309 9.99513 11.6309C9.70873 11.6309 9.47656 11.863 9.47656 12.1494C9.47656 12.4358 9.70873 12.668 9.99513 12.668Z"
      fill="currentColor"
    />
    <path
      d="M4.59306 20.0021C3.40776 20.0021 2.22245 19.5576 1.33347 18.6686C-0.44449 16.8906 -0.44449 14.0014 1.33347 12.2235L12.1494 1.33347C13.9274 -0.44449 16.8165 -0.44449 18.5945 1.33347C20.3725 3.11143 20.3725 6.00061 18.5945 7.77857L7.85266 18.6686C6.8896 19.5576 5.77837 20.0021 4.59306 20.0021ZM15.409 1.11122C14.52 1.11122 13.631 1.48163 12.9643 2.14837L2.14837 12.9643C0.814898 14.2978 0.814898 16.5202 2.14837 17.8537C3.48184 19.1872 5.70429 19.1872 7.03776 17.8537L17.8537 7.03776C19.1872 5.70429 19.1872 3.48184 17.8537 2.14837C17.1869 1.48163 16.298 1.11122 15.409 1.11122Z"
      fill="currentColor"
    />
    <path
      d="M4.29674 10.5938C4.14857 10.5938 4.00041 10.5197 3.92633 10.4457L1.33347 7.85279C-0.44449 6.07483 -0.44449 3.18565 1.33347 1.40769C3.11143 -0.370271 6.00061 -0.370271 7.77857 1.40769L10.3714 4.00055C10.4455 4.07463 10.5196 4.22279 10.5196 4.37096C10.5196 4.51912 10.4455 4.66728 10.3714 4.74136L4.66715 10.4457C4.59306 10.5197 4.4449 10.5938 4.29674 10.5938ZM4.59306 1.11136C3.70408 1.11136 2.8151 1.48177 2.14837 2.14851C0.814898 3.48198 0.814898 5.70443 2.14837 7.0379L4.29674 9.18626L9.18613 4.29687L7.03776 2.14851C6.37102 1.48177 5.48204 1.11136 4.59306 1.11136Z"
      fill="currentColor"
    />
    <path
      d="M15.4108 20.001C14.2255 20.001 13.0402 19.5565 12.1512 18.6675L9.55832 16.0747C9.48424 16.0006 9.41016 15.8524 9.41016 15.7043C9.41016 15.5561 9.48424 15.4079 9.55832 15.3339L15.2626 9.62957C15.4849 9.40733 15.8553 9.40733 16.0775 9.62957L18.6704 12.2224C20.4483 14.0004 20.4483 16.8896 18.6704 18.6675C17.7814 19.5565 16.5961 20.001 15.4108 20.001ZM10.8177 15.7043L12.9661 17.8526C14.2995 19.1861 16.522 19.1861 17.8555 17.8526C19.1889 16.5192 19.1889 14.2967 17.8555 12.9632L15.7071 10.8149L10.8177 15.7043Z"
      fill="currentColor"
    />
  </svg>,
  "Healing",
);
