import { Fragment, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import {
  CheckScoreOrder,
  CheckScoreOrderField,
  ExceptionType,
  GET_CHECK_SCORES,
  OrderDirection,
  QueryImpact,
  TestIamActionsQuery,
  useGetCheckScoresQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import { PolicyIdToMrn } from "~/lib/mrn";
import { KeyValueChip } from "~/components/asset-search";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSearch } from "~/components/search/useSearch";
import { AssetChecksChart } from "./assetChecksChart/assetChecksChart";
import { AssetRecommendedActions } from "./assetRecommendedActions/assetRecommendedActions";
import { EmptyState } from "~/components/empty-state/empty-state";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";
import { useChecksTable } from "../hooks";
import { ChecksTable } from "../components/Checks/ChecksTable";
import { Loading, LoadingFailed } from "~/components/loading";
import { useAssetOutlet } from "../asset";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { GET_METRICS } from "~/operations/queries/GetMetrics";
import { useSort } from "../hooks/useSort";

type Props = {
  space: Space;
  isCicd?: boolean;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const formatPolicyName = (p: string, spaceId: string): string => {
  if (p?.startsWith("//policy.api.mondoo.app/spaces/")) {
    const title = p.split("/").pop();
    return `${spaceId}/${title}`;
  }
  return p?.replace("//policy.api.mondoo.app/policies/", "");
};

const isImpactFilter = (value: any): value is QueryImpact => {
  return Object.values(QueryImpact).includes(value);
};

export function AssetChecksTab({
  space,
  isCicd = false,
  availablePermissions,
}: Props) {
  const { assetId, assetPath, asset } = useAssetOutlet();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  const jobId = searchParams.get("jobId") || "";
  const encodedPolicyMrn = searchParams.get("encodedPolicyMrn");
  const assetMrn = isCicd
    ? jobId
    : `//assets.api.mondoo.app/spaces/${space.id}/assets/${assetId}`;
  const policyMrn = encodedPolicyMrn
    ? PolicyIdToMrn(encodedPolicyMrn)
    : undefined;
  const impactFilter = searchParams.get("impact")?.split(",") || [];
  const impactFilters = impactFilter.flatMap((i) => {
    let filter = i.toUpperCase();
    if (isImpactFilter(filter)) {
      return filter;
    } else {
      return [];
    }
  });

  const searchFilter = searchFilters.join(" ");
  const policyFilter = policyMrn ? policyMrn : undefined;
  const hasFilters =
    searchFilter.length > 0 ||
    impactFilter.length > 0 ||
    Boolean(policyFilter?.length);

  const { handleSortClick, orderBy } = useSort<CheckScoreOrder>({
    defaultSort: {
      field: CheckScoreOrderField.Score,
      direction: OrderDirection.Asc,
    },
    validFields: Object.values(CheckScoreOrderField),
  });

  const { data, error, fetchMore, networkStatus } = useGetCheckScoresQuery({
    variables: {
      entityMrn: assetMrn,
      first: 10,
      after: null,
      orderBy,
      filter: {
        checkMrn: null,
        policyMrn: policyFilter,
        queryTerms: searchFilters,
        impact: impactFilters,
        state: null,
      },
    },
  });

  const checksFetching = networkStatus === 1;
  const checksRefetching = networkStatus === 2;
  const checksLoading = checksFetching || checksRefetching;
  const checkScores =
    data?.checkScores?.__typename === "CheckScoresConnection"
      ? data.checkScores
      : undefined;
  const checksTotalCount = checkScores?.totalCount ?? 0;
  const checkEdges = checkScores?.edges || [];
  const hasChecks = !checksFetching && checksTotalCount > 0;

  const {
    hasApplyExceptionMutationPermission,
    setSelectedChecks,
    selectedChecks,
    checkHandle,
  } = useChecksTable({
    sort: orderBy,
    availablePermissions,
    checkEdges,
  });

  const { exceptionGroups } = useFetchExceptions({
    scopeMrn: assetMrn,
    types: [ExceptionType.Security],
  });

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    loading,
  } = useExceptions({
    onSetException: () => {
      setSelectedChecks([]);
    },
    onRemoveException: () => {
      setSelectedChecks([]);
    },
    scopeMrns: [assetMrn],
    queryMrns: mapSelectedEntitiesToString(selectedChecks),
    refetchQueries: [
      {
        query: GET_CHECK_SCORES,
        variables: {
          entityMrn: assetMrn,
          orderBy,
          filter: {
            policyMrn: policyFilter,
            queryTerms: searchFilters,
            impact: impactFilters,
            state: null,
          },
        },
      },
      {
        query: GET_METRICS,
      },
    ],
  });

  if (error && !checkScores) {
    return (
      <Box sx={{ display: "flex", placeContent: "center", p: 3 }}>
        <LoadingFailed what="checks" />
      </Box>
    );
  }

  const handlePolicyFilterDelete = () => {
    searchParams.delete("encodedPolicyMrn");
    setSearchParams(searchParams);
  };

  const handleImpactFilterClick = (impact: string) => {
    //remove the focused checkMrn from the url
    const checkMrn = searchParams.get("checkMrn");
    if (checkMrn) {
      searchParams.delete("checkMrn");
    }
    if (impactFilter.includes(impact)) {
      //remove impact from filter
      const index = impactFilter.indexOf(impact);
      impactFilter.splice(index, 1);
      if (impactFilter.length === 0) {
        searchParams.delete("impact");
      } else {
        searchParams.set("impact", impactFilter.join(","));
      }
    } else {
      //add impact to filter
      impactFilter.push(impact);
      searchParams.set("impact", impactFilter.join(","));
    }
    setSearchParams(searchParams);
  };

  return (
    <Fragment>
      <Grid container mt={3} rowGap={3}>
        {hasChecks && (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <AssetChecksChart
                {...{
                  assetMrn,
                  impactFilter,
                  onRowClick: handleImpactFilterClick,
                }}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <AssetRecommendedActions {...{ asset, assetPath }} />
            </Grid>
          </Grid>
        )}

        {hasChecks && (
          <Grid item xs={12}>
            <FilterBar
              searchId="inventory-asset-checks"
              placeholder="inventory_asset_checks"
              searchFilters={searchFilters}
              handleFilterQuery={handleFilterQuery}
              mb={0}
            />
            {encodedPolicyMrn && (
              <KeyValueChip
                keyValue={{ key: "Policy", value: encodedPolicyMrn }}
                onDelete={handlePolicyFilterDelete}
              />
            )}
          </Grid>
        )}

        {checksLoading ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              placeContent: "center",
              py: 10,
            }}
          >
            <Loading what="checks" />
          </Box>
        ) : (
          <Fragment>
            {hasChecks && (
              <Grid item xs={12}>
                <ChecksTable
                  hasApplyExceptionMutationPermission={
                    hasApplyExceptionMutationPermission
                  }
                  selectedChecks={selectedChecks}
                  checkHandle={checkHandle}
                  sort={orderBy}
                  queryEdges={checkEdges.slice(pageItems.from, pageItems.to)}
                  space={space}
                  asset={asset}
                />
                <Pagination
                  fetchMore={fetchMore}
                  pageInfo={checkScores?.pageInfo}
                  totalCount={checksTotalCount}
                  setPageItems={setPageItems}
                />
                {selectedChecks.length > 0 && (
                  <ExceptionsToolbar
                    target="check"
                    onCancel={checkHandle.cancelClick}
                    onRemoveExceptionClick={handleRemoveExceptionModalOpen}
                    onSetExceptionClick={handleSetExceptionModalOpen}
                    selectedEntities={selectedChecks}
                    totalCount={checksTotalCount}
                  />
                )}
              </Grid>
            )}
            {!hasChecks && (
              <Grid item xs={12}>
                <EmptyState contentType="asset-checks-tab" space={space} />
              </Grid>
            )}
            <ExceptionsModals
              isSetExceptionModalOpen={isSettingException}
              isRemoveExceptionModalOpen={isRemovingException}
              onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
              onSetExceptionModalClose={handleSetExceptionModalClose}
              onSetExceptionModalSave={handleSetException}
              onRemoveExceptionModalSave={handleRemoveException}
              loading={loading}
              target="check"
              role="security"
              exceptionGroups={exceptionGroups}
              selectedEntities={selectedChecks}
            />
          </Fragment>
        )}
      </Grid>
    </Fragment>
  );
}
