import { useTheme } from "@mui/material";

type UseGetImpactColorParams = {
  impact: number | undefined;
  isRiskScore?: boolean;
};

export function useGetImpactColor({
  impact,
  isRiskScore,
}: UseGetImpactColorParams) {
  const theme = useTheme();

  let impactNumber = Number(impact);

  // if impact is not a number, return unknown color
  if (isNaN(impactNumber)) {
    return theme.palette.unknown.main;
  }

  if (isRiskScore) {
    if (impactNumber >= 91) {
      return theme.palette.none.main;
    } else if (impactNumber >= 71) {
      return theme.palette.low.main;
    } else if (impactNumber >= 41) {
      return theme.palette.medium.main;
    } else if (impactNumber >= 1) {
      return theme.palette.high.main;
    } else {
      return theme.palette.critical.main;
    }
  }

  if (impactNumber >= 91) {
    return theme.palette.critical.main;
  } else if (impactNumber >= 71) {
    return theme.palette.high.main;
  } else if (impactNumber >= 41) {
    return theme.palette.medium.main;
  } else if (impactNumber >= 1) {
    return theme.palette.low.main;
  } else {
    return theme.palette.unknown.main;
  }
}
