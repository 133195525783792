import { createSvgIcon } from "@mui/material";

export const EmailIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.28491 5C3.68452 5 2.375 6.36491 2.375 8.03336V15.9666C2.375 17.6349 3.68436 19 5.28491 19H18.7151C20.3155 19 21.625 17.6351 21.625 15.9666V8.03336C21.625 6.36507 20.3156 5 18.7151 5H5.28491ZM5.28491 6.40008H18.7151C18.9515 6.40008 19.1737 6.45248 19.3726 6.54591L12.2098 13.5534C12.1303 13.6312 11.8696 13.6312 11.7901 13.5534L4.62735 6.54591C4.82643 6.45248 5.04844 6.40008 5.28483 6.40008H5.28491ZM3.76694 7.62498L8.70533 12.4595L3.8297 16.594C3.75522 16.4018 3.71775 16.1903 3.71775 15.9669V8.03357C3.71775 7.89164 3.7357 7.75573 3.76677 7.6252L3.76694 7.62498ZM20.2329 7.62498C20.264 7.75552 20.2819 7.89126 20.2819 8.03335V15.9666C20.2819 16.1901 20.2445 16.4015 20.17 16.5938L15.2947 12.4595L20.2331 7.62493L20.2329 7.62498ZM9.69141 13.4291L10.8665 14.574C11.508 15.2019 12.4914 15.2019 13.1328 14.574L14.3078 13.4291L19.1483 17.5415C19.0107 17.5814 18.866 17.5998 18.7145 17.5998H5.28435C5.1329 17.5998 4.98816 17.5814 4.85059 17.5415L9.69105 13.4291H9.69141Z" />
  </svg>,
  "Email",
);
