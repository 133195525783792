import { alpha, Box, Grid, Typography } from "@mui/material";
import { Flex } from "~/components/ui-library";
import { getRiskFactorIcon } from "~/pages/space/security/components/RiskFactors/utils";
import { RiskFactorStats } from "~/operations";
import { useRiskFactorsContentStatus } from "./useRiskFactorsContentStatus";

export type RiskFactorsContentProps = {
  riskFactors?:
    | (
        | (RiskFactorStats & {
            description: { active: string; inactive: string };
          })
        | null
      )[]
    | null
    | undefined;
};

const RiskFactorsContent = ({ riskFactors }: RiskFactorsContentProps) => {
  const { getRiskStatus } = useRiskFactorsContentStatus();

  return (
    <>
      <Box sx={{ pt: 3 }}>
        <Grid container spacing={3}>
          {riskFactors?.map((riskFactor) => {
            const riskStatus = getRiskStatus(
              riskFactor?.affected,
              riskFactor?.total,
              riskFactor?.indicator,
            );

            return (
              <Grid item xs={12} sm={6} lg={4}>
                <Flex flexDirection="column" gap={1}>
                  <Flex
                    justifyContent="space-between"
                    alignItems="flex-start"
                    gap={1}
                    mb={1}
                    sx={{
                      ".metric-value": {
                        fontWeight: 600,
                        fontSize: 14,
                        textTransform: "uppercase",
                        color: riskStatus.color,
                        backgroundColor: alpha(riskStatus.color, 0.1),
                        borderRadius: 1,
                        px: 1,
                        py: 0.5,
                        my: -0.25,
                        maxWidth: 104,
                        flexBasis: "40%",
                        flexShrink: 0,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <Flex gap={1}>
                      {getRiskFactorIcon({
                        indicator: riskFactor?.indicator,
                      })}
                      <Typography
                        fontWeight={500}
                        component="span"
                        sx={{ alignSelf: "center" }}
                      >
                        {riskFactor?.title}
                      </Typography>
                    </Flex>
                    <Box className="metric-value">{`${
                      riskFactor?.affected || 0
                    }/${riskFactor?.total || 0}`}</Box>
                  </Flex>
                  {riskFactor?.description && (
                    <Typography variant="caption" color="text.secondary">
                      {/* @TODO JAMIE: toggle active/inactive state here */}
                      {riskFactor.description[riskStatus.status]}
                    </Typography>
                  )}
                </Flex>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default RiskFactorsContent;
