import { createSvgIcon } from "@mui/material";

export const RemediatedShieldIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0011 4.23039L13.4911 4.86039L12.0011 7.18039L13.5611 10.0004L12.7811 13.2004L15.0011 16.0004L16.0011 13.1704L17.1711 10.3904L16.1711 7.39039L16.7711 6.26039L18.4911 7.00039V11.0904C18.4586 13.0333 17.8138 14.9164 16.6484 16.4713C15.4831 18.0263 13.8568 19.1738 12.0011 19.7504C10.1438 19.1733 8.51634 18.0243 7.35087 16.4673C6.18539 14.9103 5.54151 13.025 5.5111 11.0804V7.00039L12.0011 4.23039ZM12.0011 2.93039C11.8266 2.93359 11.6539 2.96744 11.4911 3.03039L5.0011 5.79039C4.76369 5.89018 4.56157 6.05875 4.42078 6.27439C4.27998 6.49004 4.20696 6.74289 4.2111 7.00039V11.0904C4.23432 13.3178 4.96731 15.4797 6.30352 17.2619C7.63974 19.0441 9.50953 20.3537 11.6411 21.0004C11.8765 21.0671 12.1257 21.0671 12.3611 21.0004C14.4943 20.3532 16.3653 19.0421 17.7016 17.2579C19.038 15.4737 19.77 13.3095 19.7911 11.0804V7.00039C19.7946 6.74429 19.7212 6.49303 19.5803 6.2791C19.4395 6.06517 19.2377 5.89841 19.0011 5.80039L17.3311 5.06039C17.1663 5.00889 16.9934 4.98854 16.8211 5.00039C16.5838 5.00099 16.3512 5.06653 16.1484 5.18993C15.9457 5.31332 15.7806 5.48986 15.6711 5.70039L15.0711 6.82039C14.9917 6.97328 14.9437 7.14054 14.9299 7.3123C14.9162 7.48405 14.937 7.65681 14.9911 7.82039L15.7911 10.3604L14.7911 12.6904C14.7954 12.7102 14.7954 12.7306 14.7911 12.7504L14.5611 13.3904L14.2011 12.9304L14.8311 10.3504C14.9118 10.0332 14.8689 9.69715 14.7111 9.41039L13.5111 7.23039L14.5811 5.57039C14.6837 5.41045 14.7502 5.23012 14.7761 5.0419C14.802 4.85368 14.7866 4.66208 14.7311 4.48039C14.6766 4.29996 14.5829 4.13379 14.4567 3.99377C14.3305 3.85375 14.1749 3.74332 14.0011 3.67039L12.5111 3.00039C12.3483 2.93744 12.1757 2.90359 12.0011 2.90039V2.93039Z"
      fill="currentColor"
    />
  </svg>,
  "RemediatedShield",
);
