import { ReactNode } from "react";
import { motion } from "framer-motion";
import { Alert, Box, Grid, Link, Typography } from "@mui/material";
import { AuthContextProps } from "react-oidc-context";
import { Wave } from "~/components/galaxy/wave";
import { Stars } from "~/components/galaxy/stars";
import { IoOnTheMoon } from "~/components/galaxy/io-on-the-moon";
import { Jupiter } from "~/components/galaxy/jupiter";
import { Saturn } from "~/components/galaxy/saturn";
import { LoadingButton } from "~/components/loading-button";
import { ArrowForwardIcon } from "~/components/icons";
import { MondooLogoHorizontal } from "~/images/icons";

type Props = {
  onSignInClick: () => void;
  auth: AuthContextProps;
};

export function OidcLogin({ onSignInClick, auth }: Props) {
  return (
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        background: `linear-gradient(135deg, #6E45E2 0%, #A22E95 100%)`,
        height: "100vh",
        width: 1,
        top: 0,
        left: 0,
        overflow: "hidden",
      }}
    >
      <Grid container height={1} sx={{ zIndex: 1 }}>
        {/* I/O */}
        <Grid
          item
          xs={1}
          sm={2.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "end",
          }}
        >
          <Floater>
            <Box
              sx={{
                display: "block",
                transform: {
                  xs: "translate3d(80%,-75%,0)",
                  sm: "translate3d(30%,-30%,0)",
                  md: "translate3d(50%,-10%,0)",
                  lg: "translate3d(70%,-15%,0)",
                },
              }}
            >
              <IoOnTheMoon
                sx={{
                  width: {
                    xs: 132,
                    sm: 292,
                  },
                }}
              />
            </Box>
          </Floater>
        </Grid>
        {/* Text */}
        <Grid
          item
          xs={10}
          sm={7}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: {
              sm: "center",
            },
            mt: {
              sm: "-30%",
              md: "-15%",
            },
            pt: {
              xs: 7,
              sm: 0,
            },
            zIndex: 1,
          }}
        >
          <Box display="flex" flexDirection="column" alignItems="center">
            <MondooLogoHorizontal layout={{ py: 2 }} color="white" />
            <LoadingButton
              loading={auth.isLoading}
              buttonText={"Sign In"}
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              disabled={auth.isLoading}
              onClick={onSignInClick}
              sx={{
                my: 2,
                py: 1.5,
                background:
                  "linear-gradient(90.05deg, #E85076 1.6%, #EC8259 100.43%)",
                width: 320,

                ".MuiCircularProgress-root": {
                  color: "white",
                  opacity: 0.7,
                },
              }}
            />
            {auth.error && (
              <Alert
                severity="error"
                variant="outlined"
                sx={{ mb: 1, color: "white" }}
              >
                An error occurred, please try again.
              </Alert>
            )}
            <Typography sx={{ fontSize: 12, opacity: 0.5 }}>
              Problems signing in?{" "}
              <Link sx={{ color: "inherit", textDecoration: "underline" }}>
                Contact an admin
              </Link>
            </Typography>
          </Box>
        </Grid>
        {/* Saturn and Jupiter */}
        <Grid
          item
          xs={1}
          sm={2.5}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "space-around",
          }}
        >
          <Floater delay={2}>
            <Box
              sx={{
                width: {
                  xs: 245,
                },
                transform: {
                  xs: "translate3d(-40%,-100%,0)",
                  md: "translate3d(-20%,-50%,0)",
                  lg: "translate3d(-20%,-50%,0)",
                },
              }}
            >
              <Saturn />
            </Box>
          </Floater>
          <Floater delay={4}>
            <Box
              sx={{
                position: "relative",
                width: {
                  xs: 135,
                  sm: 168,
                },
                transform: {
                  xs: "translate3d(-100%,0,0)",
                  md: "translate3d(-70%,0,0)",
                  lg: "translate3d(-120%,-60%,0)",
                },
              }}
            >
              <Jupiter />
            </Box>
          </Floater>
        </Grid>
      </Grid>

      <Box
        sx={{
          position: "absolute",
          left: 0,
          top: 0,
          width: 1,
          overflow: "hidden",
        }}
      >
        <Stars />
      </Box>

      <Wave
        color="black"
        opacity="0.2"
        sx={{
          position: "absolute",
          left: 0,
          bottom: 0,
          width: 1,
          height: {
            xs: "80%",
            md: "75%",
          },
        }}
      />
    </Box>
  );
}

type FloaterProps = {
  children: ReactNode;
  delay?: number;
};

const Floater = ({ children, delay }: FloaterProps) => {
  return (
    <motion.div
      animate={{ y: ["5px", "2px", "5px", "0px"] }}
      transition={{
        y: {
          duration: 5,
          repeat: Infinity,
          repeatType: "reverse",
          ease: "easeInOut",
          delay: delay ? delay : 0,
        },
      }}
    >
      {children}
    </motion.div>
  );
};
