import { alpha, Box, Typography, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";

type SoftwareTileProps = {
  id?: string;
  title?: string;
  version?: string | null | undefined;
  isActive?: boolean;
  onClick?: (version: string) => void;
  maxWidth?: string;
};

export const RemediationFilterTile = ({
  id,
  title,
  version,
  onClick,
  isActive = false,
  maxWidth,
}: SoftwareTileProps) => {
  const theme = useTheme();

  return (
    <Box
      onClick={() => onClick?.(String(id))}
      display="flex"
      sx={{
        flexDirection: "column",
        backgroundColor: `${alpha(
          theme.palette.secondary.main,
          isActive ? 0.4 : 0.2,
        )}`,
        minWidth: "190px",
        minHeight: "56px",
        borderRadius: "4px",
        border: `${
          isActive ? `1px solid ${theme.palette.secondary.light}` : "none"
        }`,
        padding: `${theme.spacing(1, 2)}`,
        cursor: "pointer",
        maxWidth,
      }}
      component={motion.button}
      {...fadeInOut}
      data-testid="package"
    >
      <Typography data-testid="package-title">{title}</Typography>

      <Box>
        <Typography
          variant="caption"
          color="text.secondary"
          data-testid="package-version"
          sx={{ wordBreak: "break-all" }}
        >
          {version}
        </Typography>
      </Box>
    </Box>
  );
};
