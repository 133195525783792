import { Routes, Route } from "react-router-dom";
import { Space } from "~/lib/types";
import { AggregateScoreType, TestIamActionsQuery } from "~/operations";
import { VulnerabilitiesPage } from "~/pages/vulnerabilities";
import { Dashboard } from "~/pages/space/vulnerabilities";
import { FirewatchPage } from "~/components/FirewatchPage";
import { Redirect } from "..";
import { FeatureFlaggedItem } from "~/components/FeatureFlaggedItem";
import { Software } from "~/pages/space/software/Software/Software";

export type VulnerabilitiesRoutesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function VulnerabilitiesRoutes({
  space,
  availablePermissions,
}: VulnerabilitiesRoutesProps) {
  return (
    <Routes>
      <Route
        element={
          <VulnerabilitiesPage availablePermissions={availablePermissions} />
        }
      >
        <Route index element={<Dashboard space={space} />} />

        <Route
          path="cves"
          element={
            <FirewatchPage
              {...{ space, pageType: AggregateScoreType.Vulnerability }}
            />
          }
        />

        <Route
          path="advisories"
          element={
            <FirewatchPage
              {...{ space, pageType: AggregateScoreType.Advisory }}
            />
          }
        />
        <Route
          path="affected-assets"
          element={
            <FirewatchPage {...{ space, pageType: AggregateScoreType.Asset }} />
          }
        />

        <Route path="software">
          <Route
            index
            element={
              <FirewatchPage
                {...{ space, pageType: AggregateScoreType.Software }}
              />
            }
          />
          <Route path=":softwareId" element={<Software {...{ space }} />} />
        </Route>
      </Route>
    </Routes>
  );
}
