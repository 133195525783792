import { createSvgIcon } from "@mui/material";

export const AlmaLinuxIcon = createSvgIcon(
  <svg
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.2955 26.4241C45.2552 26.2734 46.8381 27.5547 46.9888 29.5145C47.1396 31.5496 45.7074 33.2078 43.7477 33.3585C41.8634 33.5093 40.2051 32.0772 40.0544 30.1928C39.9036 28.1577 41.2604 26.6502 43.2955 26.4241Z"
      fill="#86DA2F"
    />
    <path
      d="M19.4766 42.4789C19.4766 40.5192 20.984 39.0117 22.793 39.0117C24.602 39.0117 26.3356 40.67 26.3356 42.5543C26.3356 44.3633 24.8281 45.9461 23.0945 46.0215C20.9087 46.0215 19.4766 44.6648 19.4766 42.4789Z"
      fill="#24C2FF"
    />
    <path
      d="M24.8298 20.47C24.5283 20.6207 24.3775 20.3946 24.3021 20.1685C21.5133 14.9677 22.3424 8.41011 27.2417 4.49066C28.5231 3.43542 30.9351 3.20929 31.9903 4.26453C32.4426 4.6414 32.5179 5.09365 32.5933 5.62127C32.7441 6.75188 32.9702 7.88249 33.7239 8.78698C34.553 9.76685 35.6083 10.1437 36.8143 10.0683C37.8695 10.0683 38.9247 9.9176 39.6031 11.1236C39.98 11.802 39.8292 14.44 39.2262 14.9677C38.9247 15.1938 38.6986 15.043 38.4725 14.9677C36.7389 14.2893 34.9299 14.2893 33.1209 14.5908C32.5179 14.6662 32.2164 14.5154 32.2164 13.8371C32.1411 12.7064 31.9149 11.6512 31.3119 10.6713C30.1813 8.63624 28.0709 8.56086 26.7141 10.4452C25.5835 11.9527 25.282 13.7617 24.9805 15.5707C24.679 17.1535 24.7544 18.8117 24.8298 20.47Z"
      fill="#FFCB12"
    />
    <path
      d="M26.7132 21.6005C26.5624 21.3744 26.6378 21.1483 26.8639 20.9976C31.1603 17.0781 37.5671 16.3244 42.5418 20.1684C43.8231 21.2237 44.6523 23.4095 43.9739 24.6909C43.6724 25.2185 43.2955 25.4446 42.8433 25.5954C41.788 26.0476 40.8082 26.4999 40.1298 27.4797C39.4514 28.4596 39.3007 29.5902 39.6022 30.7962C39.8283 31.7761 40.2052 32.8313 39.2253 33.7358C38.6977 34.2634 36.135 34.7157 35.4566 34.2634C35.1551 34.0373 35.2305 33.8112 35.3059 33.5097C35.532 31.6253 35.0797 29.8917 34.4014 28.2335C34.1752 27.6305 34.2506 27.329 34.8536 27.1782C35.9088 26.8767 36.8887 26.3491 37.6424 25.5954C39.3007 24.0125 38.9238 21.9774 36.738 21.0729C35.0044 20.3192 33.1954 20.4699 31.4618 20.6207C29.8035 20.6961 28.2207 21.1483 26.7132 21.6005Z"
      fill="#86DA2F"
    />
    <path
      d="M25.7331 23.5607C25.9593 23.3346 26.11 23.4099 26.3361 23.5607C31.3108 26.5756 33.8736 32.5302 31.6123 38.334C31.0093 39.8415 29.0496 41.349 27.6175 40.9721C27.0145 40.8214 26.713 40.5199 26.4115 40.143C25.7332 39.2385 24.9794 38.4094 23.8488 38.0325C22.6428 37.6557 21.5876 37.8818 20.5323 38.4848C19.6278 39.0124 18.7234 39.6908 17.5927 39.0124C16.9144 38.6355 15.7838 36.2989 16.0099 35.5452C16.1606 35.2437 16.4621 35.2437 16.7636 35.2437C18.648 34.9422 20.1555 34.0377 21.5876 32.8317C22.0398 32.4548 22.4167 32.4548 22.7936 32.9825C23.3965 33.8869 24.1503 34.6407 25.1302 35.1683C27.0899 36.2989 28.9742 35.3191 29.2004 33.0578C29.4265 31.1735 28.7481 29.5152 28.1451 27.857C27.4668 26.3495 26.6376 24.9174 25.7331 23.5607Z"
      fill="#24C2FF"
    />
    <path
      d="M23.3212 24.0127C23.0197 25.5202 22.3414 26.9523 21.4369 28.2336C18.7234 32.3039 14.804 33.9621 9.98001 33.5098C8.24641 33.3591 6.8143 31.927 6.66355 30.4949C6.58818 29.8919 6.73893 29.4396 7.1158 28.9874C7.64342 28.309 8.09566 27.706 8.32178 26.8769C8.77403 25.2187 8.17103 23.8619 6.96505 22.656C5.30682 20.9977 5.53294 19.4902 7.49267 18.2843C7.71879 18.1335 8.02029 17.9828 8.32178 17.832C8.77403 17.6059 9.1509 17.6059 9.30165 18.1335C9.98001 19.8671 11.3368 21.1485 12.8442 22.2037C13.3719 22.656 13.3719 22.9575 12.9196 23.4851C12.0151 24.4649 11.4875 25.6709 11.4121 27.0277C11.2614 28.6859 12.2412 29.7411 13.8995 29.7411C14.9547 29.7411 15.9346 29.3643 16.8391 28.912C19.1757 27.706 20.9846 25.9724 22.7183 24.1634C22.9444 24.0881 23.0197 23.9373 23.3212 24.0127Z"
      fill="#0069DA"
    />
    <path
      d="M10.9596 8.18432C11.1104 8.18432 11.4873 8.2597 11.8641 8.33507C14.653 8.86269 16.3866 7.88282 17.2911 5.24473C17.8941 3.51112 19.1754 2.9835 20.8337 3.88799C20.909 3.88799 20.909 3.96337 20.9844 3.96337C22.718 4.94323 22.718 5.09398 21.6628 6.60146C20.7583 7.80745 20.306 9.16419 20.0799 10.5963C19.9292 11.4254 19.6277 11.5762 18.8739 11.2747C17.6679 10.8224 16.3866 10.8224 15.1052 11.2747C13.6731 11.7269 13.0701 13.0083 13.5224 14.4404C14.1254 16.3247 15.7836 17.1538 17.2157 18.1337C18.6478 19.1136 20.306 19.6412 21.8889 20.3196C22.115 20.3949 22.4919 20.3949 22.4165 20.7718C22.3411 20.9979 22.0396 20.9979 21.7382 20.9979C18.3463 21.1487 15.1052 20.6211 12.4671 18.3598C9.97978 16.3247 8.1708 13.8374 8.4723 10.3702C8.69842 9.23956 9.52754 8.41044 10.9596 8.18432Z"
      fill="#FF4649"
    />
    <path
      d="M4.77913 29.3644C2.97015 29.5905 1.16117 28.083 1.01042 26.1986C0.859669 24.3897 2.36715 22.5807 4.10076 22.4299C6.06049 22.2038 7.86947 23.5605 8.02022 25.3695C8.09559 27.1031 6.96498 29.2136 4.77913 29.3644Z"
      fill="#0069DA"
    />
    <path
      d="M36.4369 2.53091C38.3213 2.38016 40.1302 3.88764 40.281 5.772C40.4317 7.58098 38.9242 9.31459 37.1153 9.46533C35.1555 9.61608 33.4219 8.18397 33.2712 6.29962C33.1204 4.41526 34.4772 2.68166 36.4369 2.53091Z"
      fill="#FFCB12"
    />
    <path
      d="M16.7642 3.96317C16.9904 5.9229 15.6336 7.58113 13.5985 7.88263C11.8649 8.10875 10.0559 6.67664 9.82982 5.01841C9.60369 2.83256 10.8097 1.2497 12.8448 1.02358C14.7291 0.797453 16.5381 2.22956 16.7642 3.96317Z"
      fill="#FF4649"
    />
  </svg>,
  "Alma Linux",
);
