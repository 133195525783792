import { createSvgIcon } from "@mui/material";

export const DomainsHostsIcon = createSvgIcon(
  <svg viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.9987 6.66667V0H0.332031V30H33.6654V6.66667H16.9987ZM6.9987 26.6667H3.66536V23.3333H6.9987V26.6667ZM6.9987 20H3.66536V16.6667H6.9987V20ZM6.9987 13.3333H3.66536V10H6.9987V13.3333ZM6.9987 6.66667H3.66536V3.33333H6.9987V6.66667ZM13.6654 26.6667H10.332V23.3333H13.6654V26.6667ZM13.6654 20H10.332V16.6667H13.6654V20ZM13.6654 13.3333H10.332V10H13.6654V13.3333ZM13.6654 6.66667H10.332V3.33333H13.6654V6.66667ZM30.332 26.6667H16.9987V23.3333H20.332V20H16.9987V16.6667H20.332V13.3333H16.9987V10H30.332V26.6667ZM26.9987 13.3333H23.6654V16.6667H26.9987V13.3333ZM26.9987 20H23.6654V23.3333H26.9987V20Z"
      fill="url(#paint0_linear_7091_4352)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7091_4352"
        x1="0.332031"
        y1="0"
        x2="31.0651"
        y2="32.4618"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E45E2" />
        <stop offset="1" stopColor="#A22E95" />
      </linearGradient>
    </defs>
  </svg>,
  "Domains And Hosts",
);
