import { ReactElement } from "react";
import { Grid } from "@mui/material";
import { ResizablePanel } from "./resizeable-panel";

type PanelContainerProps = {
  children?:
    | ReactElement<typeof ResizablePanel>
    | ReactElement<typeof ResizablePanel>[];
  height?: string | number;
};

export function PanelContainer({ children, height }: PanelContainerProps) {
  return (
    <Grid
      container
      item
      sx={{
        display: "grid",
        justifyContent: "start",
        gridAutoColumns: "auto",
        gridAutoFlow: "column",
        height: height ?? "calc(100vh - 400px)",
      }}
    >
      {children}
    </Grid>
  );
}
