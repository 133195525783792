import { FormControl, Typography } from "@mui/material";
import { ReactElement } from "react";

type FieldLabelProps = {
  label: string;
  caption: ReactElement | string;
  id: string;
  optional?: boolean;
};

export const FieldLabel = ({
  label,
  caption,
  id,
  optional = false,
}: FieldLabelProps) => {
  return (
    <FormControl>
      <Typography
        component="label"
        sx={{ fontSize: "20px", fontWeight: 700 }}
        role="label"
        htmlFor={id}
      >
        {label}
        {optional && (
          <>
            {" "}
            <Typography
              component="span"
              color="text.secondary"
              sx={{ fontSize: "20px" }}
            >
              (optional)
            </Typography>
          </>
        )}
      </Typography>
      <Typography
        variant="caption"
        color="text.secondary"
        sx={{ fontSize: "14px" }}
      >
        {caption}
      </Typography>
    </FormControl>
  );
};
