import {
  Checkbox,
  Paper,
  Grid,
  Radio,
  RadioGroup,
  RadioGroupProps,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
} from "@mui/material";
import { ContentBody } from "~/components/content-body";
import { AdvisoryOrder, CveOrder, MvdEntryType, Severity } from "~/operations";
import { useMondooVulnerabilityDatabase } from "./useMondooVulnerabilityDatabase";

export type DatabaseFilter = {
  entryType: MvdEntryType;
  severity: Severity[];
  orderBy?: AdvisoryOrder | CveOrder;
};

export type Props = {
  filters: DatabaseFilter;
  onChange: (filters: DatabaseFilter) => void;
};

export function MondooDatabaseFilterComponent({ filters, onChange }: Props) {
  const { isValidEntryType } = useMondooVulnerabilityDatabase({});
  const handleSeverityFilterChange =
    (name: Severity): FormControlLabelProps["onChange"] =>
    (_event, checked) => {
      const nextSeverity = filters.severity.filter((s) => s !== name);
      if (checked) {
        nextSeverity.push(name);
      }
      onChange({ ...filters, severity: nextSeverity });
    };

  const handleEntryTypeChange: RadioGroupProps["onChange"] = (event) => {
    const value = event.target.value;
    if (isValidEntryType(value)) {
      onChange({ ...filters, entryType: value });
    }
  };

  const { severity } = filters;
  return (
    <Paper variant="outlined" sx={{ mt: 2, backgroundColor: "transparent" }}>
      <ContentBody>
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Severity</FormLabel>
              <FormGroup aria-label="severity" row={true}>
                <FormControlLabel
                  value="critical"
                  onChange={handleSeverityFilterChange(Severity.Critical)}
                  control={
                    <Checkbox checked={severity.includes(Severity.Critical)} />
                  }
                  label="Critical"
                />
                <FormControlLabel
                  value="high"
                  onChange={handleSeverityFilterChange(Severity.High)}
                  control={
                    <Checkbox checked={severity.includes(Severity.High)} />
                  }
                  label="High"
                />
                <FormControlLabel
                  value="medium"
                  onChange={handleSeverityFilterChange(Severity.Medium)}
                  control={
                    <Checkbox checked={severity.includes(Severity.Medium)} />
                  }
                  label="Medium"
                />
                <FormControlLabel
                  value="low"
                  onChange={handleSeverityFilterChange(Severity.Low)}
                  control={
                    <Checkbox checked={severity.includes(Severity.Low)} />
                  }
                  label="Low"
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Entry Type</FormLabel>
              <RadioGroup
                value={filters.entryType}
                id="radio-entrytype-filter"
                aria-label="entry type"
                onChange={handleEntryTypeChange}
                row={true}
              >
                <FormControlLabel
                  value={MvdEntryType.Advisory}
                  control={<Radio />}
                  label="Advisories"
                />
                <FormControlLabel
                  value={MvdEntryType.Cve}
                  control={<Radio />}
                  label="CVEs"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </ContentBody>
    </Paper>
  );
}
