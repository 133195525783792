import { createSvgIcon } from "@mui/material";

export const TerraformIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 116.12 114.64"
    fill="currentColor"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Logo">
        <path
          fill="#FFFFFF"
          d="M2.92,89.27V87.15H1v2.11H0V84.19H1v2.13H2.92V84.19h1v5.08Zm4.61,0H6.75L6.68,89a2.15,2.15,0,0,1-1.14.32c-.7,0-1-.46-1-1.09s.34-1,1.11-1h.92v-.38c0-.41-.12-.55-.73-.55a5.55,5.55,0,0,0-1.06.11l-.12-.7A5.18,5.18,0,0,1,6,85.53c1.2,0,1.55.41,1.55,1.32Zm-1-1.38H5.87c-.31,0-.4.08-.4.36s.09.37.38.37a1.55,1.55,0,0,0,.72-.19Zm3,1.46a4.67,4.67,0,0,1-1.32-.21l.13-.7a4.44,4.44,0,0,0,1.14.16c.42,0,.49-.09.49-.37s0-.34-.67-.48c-.93-.22-1-.44-1-1.15s.34-1.06,1.43-1.06a5.22,5.22,0,0,1,1.14.13l-.09.73a7,7,0,0,0-1.05-.11c-.42,0-.49.09-.49.32s0,.32.54.44c1.07.27,1.17.41,1.17,1.16S10.69,89.34,9.54,89.34Zm4.38-.08V86.71c0-.2-.09-.29-.31-.29a2.72,2.72,0,0,0-1,.31v2.54h-1V84.12l1,.14v1.62a3.48,3.48,0,0,1,1.4-.35c.63,0,.86.43.86,1.08v2.66Zm1.76-4.18v-.9h1v.9Zm0,4.18V85.6h1v3.67Zm1.72-3.63c0-.92.56-1.45,1.86-1.45a6.14,6.14,0,0,1,1.42.17l-.11.82A8,8,0,0,0,19.28,85c-.68,0-.9.23-.9.76v1.93c0,.53.22.76.9.76a8,8,0,0,0,1.28-.13l.11.82a6.14,6.14,0,0,1-1.42.17c-1.3,0-1.86-.53-1.86-1.45Zm5.39,3.71c-1.31,0-1.66-.69-1.66-1.44V87c0-.75.35-1.44,1.66-1.44s1.66.69,1.66,1.44v.93C24.44,88.65,24.09,89.34,22.78,89.34Zm0-3c-.51,0-.71.22-.71.63v1c0,.41.2.63.71.63s.71-.22.71-.63v-1C23.49,86.53,23.29,86.31,22.78,86.31Zm4.35.06a7.58,7.58,0,0,0-1,.53v2.36h-1V85.6H26L26,86a4.35,4.35,0,0,1,1-.48ZM31,88a1.13,1.13,0,0,1-1.26,1.29,5.48,5.48,0,0,1-1-.11v1.5l-1,.14V85.6h.76l.09.31a2.06,2.06,0,0,1,1.21-.38c.77,0,1.18.44,1.18,1.27Zm-2.28.41a4.41,4.41,0,0,0,.85.1c.34,0,.48-.16.48-.49V86.78c0-.3-.12-.47-.47-.47a1.38,1.38,0,0,0-.85.33Z"
        />
        <path
          fill="#FFFFFF"
          d="M5.83,96.93H0V93.45H15.61v3.48H9.78v17.4H5.83Z"
        />
        <path
          fill="#FFFFFF"
          d="M19.21,111.5a16,16,0,0,0,4.67-.72l.6,2.88a16.39,16.39,0,0,1-5.61,1c-4.8,0-6.46-2.23-6.46-5.89v-4c0-3.23,1.44-6,6.33-6s6,2.85,6,6.14v3.26H16.24v.78C16.24,110.78,16.89,111.5,19.21,111.5Zm-3-6.43h4.86v-.75c0-1.44-.44-2.44-2.32-2.44s-2.54,1-2.54,2.44Z"
        />
        <path
          fill="#FFFFFF"
          d="M34.95,102.26a29.93,29.93,0,0,0-4,2.23v9.84H27.08V99.06h3.23l.25,1.69a17.1,17.1,0,0,1,4-2Z"
        />
        <path
          fill="#FFFFFF"
          d="M44.29,102.26a29.93,29.93,0,0,0-4,2.23v9.84H36.42V99.06h3.23l.25,1.69a17.1,17.1,0,0,1,4-2Z"
        />
        <path
          fill="#FFFFFF"
          d="M57.07,114.32H53.94l-.28-1a8.35,8.35,0,0,1-4.54,1.35c-2.79,0-4-1.91-4-4.54,0-3.1,1.35-4.29,4.45-4.29h3.67v-1.6c0-1.69-.47-2.29-2.91-2.29a21.33,21.33,0,0,0-4.23.47l-.47-2.91a19.92,19.92,0,0,1,5.23-.72c4.8,0,6.21,1.69,6.21,5.52Zm-3.82-5.77H50.43c-1.25,0-1.6.34-1.6,1.5s.34,1.54,1.54,1.54a6,6,0,0,0,2.88-.78Z"
        />
        <path
          fill="#FFFFFF"
          d="M68.83,95.58a11.22,11.22,0,0,0-2.19-.25c-1.5,0-1.72.66-1.72,1.82v1.91H68.8l-.22,3H64.91v12.22H61.09V102.1H58.64v-3h2.44V96.93c0-3.17,1.47-4.73,4.86-4.73a12.11,12.11,0,0,1,3.29.44Z"
        />
        <path
          fill="#FFFFFF"
          d="M76.29,114.64c-5.23,0-6.64-2.88-6.64-6v-3.86c0-3.13,1.41-6,6.64-6s6.65,2.88,6.65,6v3.86C82.93,111.75,81.52,114.64,76.29,114.64Zm0-12.63c-2,0-2.82.91-2.82,2.63v4.11c0,1.72.78,2.63,2.82,2.63s2.82-.91,2.82-2.63v-4.11C79.11,102.91,78.32,102,76.29,102Z"
        />
        <path
          fill="#FFFFFF"
          d="M92.77,102.26a29.93,29.93,0,0,0-4,2.23v9.84H84.91V99.06h3.23l.25,1.69a17.1,17.1,0,0,1,4-2Z"
        />
        <path
          fill="#FFFFFF"
          d="M103.27,114.32V103.67c0-.81-.34-1.22-1.22-1.22a10.54,10.54,0,0,0-4,1.29v10.59H94.25V99.06h2.91l.38,1.29a15.29,15.29,0,0,1,6.11-1.6,2.9,2.9,0,0,1,2.88,1.63,15,15,0,0,1,6.14-1.63c2.54,0,3.45,1.79,3.45,4.51v11.06H112.3V103.67c0-.81-.34-1.22-1.22-1.22a10.12,10.12,0,0,0-4,1.29v10.59Z"
        />
        <polygon
          fill="#FFFFFF"
          points="47.48 13.64 68.64 25.86 68.64 50.3 47.48 38.08 47.48 13.64"
        />
        <polygon
          fill="#FFFFFF"
          points="70.96 25.86 70.96 50.3 92.13 38.08 92.13 13.64 70.96 25.86"
        />
        <polygon
          fill="#FFFFFF"
          points="24 0 24 24.44 45.16 36.66 45.16 12.22 24 0"
        />
        <polygon
          fill="#FFFFFF"
          points="47.48 65.19 68.64 77.41 68.64 53.14 68.64 52.98 47.48 40.76 47.48 65.19"
        />
        <path fill="#FFFFFF" d="M83.18,61.37" />
        <path fill="#FFFFFF" d="M47.48,40.76" />
      </g>
    </g>
  </svg>,
  "Terraform",
);
