import { useMemo } from "react";
import { List, ListProps } from "@mui/material";
import { Text, Flex } from "~/components/ui-library";
import { ResizablePanel } from "./resizeable-panel";
import { DynamicResourceRow } from "./resource-rows";
import { ResourceIcon } from "./icons";
import { Field, Resource } from "~/operations";

type ColumnProps = {
  asset: any;
  column: any;
  columnIndex: number;
  handleItemClick: any;
  userTrail: any;
  listProps: ListProps;
};

export const Column = ({
  asset,
  column,
  columnIndex,
  handleItemClick,
  userTrail,
  listProps = {},
}: ColumnProps) => {
  const { rows, columnName } = useMemo(() => {
    // we start out as each resource is a list item.
    const resources = column?.resources as Resource[];
    let rows: (Resource | Field)[] = resources;
    let columnName = asset.name;

    // if there is only one resource, and it has fields, then we want to loop through the fields
    if (
      resources?.length === 1 &&
      resources?.some((x) => Number(x?.fields?.length) > 0)
    ) {
      rows = resources[0].fields as Field[];
    }

    // if the initial column has a fields array, we want to loop through the fields instead
    if (column?.fields) {
      rows = column.fields;
    }

    // get the column name
    if (column?.name) {
      columnName = column.name;
    } else if (column?.selectedNames?.length > 0) {
      columnName = column.selectedNames[0];
    }

    return {
      rows,
      columnName,
    };
  }, [asset, column]);

  return (
    <ResizablePanel>
      <Flex alignItems="center" pt={2} px={2} mb={1.5}>
        <ResourceIcon sx={{ mr: 1.25, fontWeight: 700 }} />
        <Text bold>{columnName}</Text>
      </Flex>
      <List {...listProps}>
        {rows.map((item) => {
          return (
            <DynamicResourceRow
              key={item.name}
              {...{
                item,
                handleClick: () => handleItemClick(columnIndex, item),
                active: userTrail?.[columnIndex] === item.name,
              }}
            />
          );
        })}
      </List>
    </ResizablePanel>
  );
};
