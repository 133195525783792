import { ReactElement, useEffect, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  AutocompleteProps,
  Box,
  Button,
  Checkbox,
  SxProps,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  Flex,
  Loading,
  LoadingFailedPage,
  LoadingPage,
  OrderDirection,
} from "~/components/ui-library";
import {
  ApiTokenOrder,
  ApiTokenOrderField,
  GetApiTokensDocument,
  TestIamActionsQuery,
  useDeleteApiTokenMutation,
  useGetApiTokensQuery,
  useSetRolesMutation,
} from "~/operations";
import { ApiToken, TokenTableRow, TokenTableRowProps } from "./token-table-row";
import { pluralize } from "~/lib/pluralize";
import {
  EditRoleDialog,
  formatRoleName,
  PermissionFormInput,
} from "~/components/edit-role-dialog";
import { IamActions } from "~/lib/iam";
import { TokenSearch } from "../../organization/settings/components/token-search";
import { Org, Space } from "~/lib/types";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { AddButton } from "~/components/add-button";
import { DataTable, SelectionToolbar } from "~/components/data-table";
import { NoTokensView } from "../no-tokens-view";
import { NoPermission } from "~/components/no-permission";
import { ConfirmationDialog } from "~/components/ConfirmationDialog";
import { FormatAbbrDateYear } from "~/lib/date";

type Props = {
  space?: Space;
  org?: Org;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export type APITokenToDelete = {
  mrn: string;
  name: string | null | undefined;
  date: string | null | undefined;
  reason: string | null | undefined;
};

export function ApiTokensPage({ space, org, availablePermissions }: Props) {
  let navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, _setSearchParams] = useSearchParams();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [deleteSingleDialogOpen, setDeleteSingleDialogOpen] =
    useState<boolean>(false);

  const hasListApiTokensPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_LISTAPITOKENS,
  );
  const hasDeleteAgentsPermissions = availablePermissions?.includes(
    IamActions.AGENTMANAGER_DELETEAGENTS,
  );
  const hasSetAgentMembershipPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_SETSERVICEACCOUNTMEMBERSHIP,
  );
  const hasCreateAgentPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_CREATEAGENT,
  );

  // Params
  const [direction, setDirection] = useState<OrderDirection>(
    (searchParams.get("orderBy") as OrderDirection) || OrderDirection.Desc,
  );
  const [field, setField] = useState<ApiTokenOrderField>(
    (searchParams.get("field") as ApiTokenOrderField) ||
      ApiTokenOrderField.CreatedAt,
  );
  const [searchFilters, setSearchFilters] = useState<string[]>(
    () => searchParams.get("queryTerms")?.split(",") || [],
  );

  // Editing and Deleting
  const [selection, setSelection] = useState<APITokenToDelete[]>([]);
  const [itemToDelete, setItemToDelete] = useState<APITokenToDelete>();
  const [apiTokenToEdit, setApiTokenToEdit] = useState<ApiToken | null>(null);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  // toggle the edit dialog form based on whether or not we have
  // a api token available to edit
  useEffect(() => {
    if (apiTokenToEdit) {
      setEditDialogOpen(true);
    } else {
      setEditDialogOpen(false);
    }
  }, [apiTokenToEdit]);

  useEffect(() => {
    const nextSearchFilters = searchParams.get("queryTerms");
    if (nextSearchFilters) {
      setSearchFilters(nextSearchFilters.split(","));
    } else {
      setSearchFilters([]);
    }
  }, [searchParams]);

  // Get Tokens
  const { data, loading, error, refetch, fetchMore } = useGetApiTokensQuery({
    variables: {
      spaceMrn: space?.mrn || "",
      scopeMrn: org?.mrn,
      first: 20,
      queryTerms: searchFilters,
      orderBy: {
        field,
        direction,
      },
    },
    initialFetchPolicy: "cache-and-network",
    skip: !hasListApiTokensPermission,
  });

  const [setRoles] = useSetRolesMutation();

  const [deleteApiToken] = useDeleteApiTokenMutation({
    refetchQueries: [GetApiTokensDocument],
  });

  if (!hasListApiTokensPermission) {
    return <NoPermission />;
  }

  if (loading && !data) {
    return <LoadingPage what="API tokens" />;
  }

  if (error) {
    return <LoadingFailedPage what="API tokens" />;
  }

  const handleSort = (id: string) => {
    const newDirection =
      direction === OrderDirection.Desc
        ? OrderDirection.Asc
        : OrderDirection.Desc;
    const newField = id as ApiTokenOrderField;

    const newOrder: ApiTokenOrder = {
      field: newField,
      direction: newDirection,
    };

    setDirection(newDirection);
    setField(newField);
    refetch({ orderBy: newOrder });
  };

  const handleAddTokenClick = async () => {
    if (space) {
      navigate(`add?spaceId=${space.id}`);
    } else if (org) {
      navigate(`add?organizationId=${org.id}`);
    }
  };

  const handleCancelDelete = () => {
    setItemToDelete(undefined);
    setDeleteSingleDialogOpen(false);
    setDeleteDialogOpen(false);
  };

  const handleSingleItemDelete = async (
    params: APITokenToDelete | undefined,
  ) => {
    if (!params?.mrn) return;
    setIsDeleting(true);
    try {
      await deleteApiToken({ variables: { input: { mrn: params.mrn } } });
      enqueueSnackbar("Successfully deleted API Token", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Failed to remove API Token`, { variant: "error" });
    }
    setDeleteSingleDialogOpen(false);
    setIsDeleting(false);
  };

  const completeBatchEdit = async () => {
    if (selection.length < 1) return resetEditing();
    const total = selection.length;

    try {
      selection.forEach(({ mrn }) => {
        deleteApiToken({ variables: { input: { mrn } } });
      });

      enqueueSnackbar(
        `Successfully removed ${total} API ${pluralize("token", total)}`,
        { variant: "success" },
      );
    } catch (error) {
      enqueueSnackbar(`Failed to remove API ${pluralize("token", total)}`, {
        variant: "error",
      });
    }

    resetEditing();
  };

  const handleSelectAll = () => {
    if (selection.length > 0) {
      return setSelection([]);
    }

    const allPageItems =
      data?.apiTokens?.edges?.slice(pageItems.from, pageItems.to) || [];

    const tmp = allPageItems
      .flatMap((edge) => edge ?? [])
      .map((edge) => ({
        mrn: String(edge.node?.mrn),
        date: FormatAbbrDateYear(edge.node?.createdAt),
        name: edge.node?.creator?.email,
        reason: edge.node?.description,
      }));

    setSelection(tmp);
  };

  const resetEditing = () => {
    setDeleteDialogOpen(false);
    setSelection([]);
  };

  const handleDeleteClick = () => {
    if (selection.length === 1) {
      const item = selection[0];
      setItemToDelete({
        mrn: item.mrn,
        date: item.date,
        name: item.name,
        reason: item.reason,
      });
      setDeleteSingleDialogOpen(true);
      return;
    }
    setDeleteDialogOpen(true);
  };

  const handleCancelClick = () => {
    setSelection([]);
  };

  const handleEditPermissions: TokenTableRowProps["handleEditPermissions"] = (
    apiToken,
  ) => {
    if (apiToken.__typename !== "APIToken") {
      setApiTokenToEdit(null);
    } else {
      setApiTokenToEdit(apiToken);
    }
  };

  const onPermissionEditSubmit: SubmitHandler<PermissionFormInput> = async (
    data,
  ) => {
    if (!apiTokenToEdit) {
      throw new Error("Uh oh, there's no API Token to edit");
    }
    const roles = Object.entries(data)
      .filter(([_role, value]) => value === true)
      .map(([role, _value]) => {
        return {
          mrn: `//iam.api.mondoo.app/roles/${formatRoleName(
            role as keyof PermissionFormInput,
          )}`,
        };
      });

    try {
      await setRoles({
        variables: {
          input: {
            spaceMrn: space?.mrn || "",
            scopeMrn: org?.mrn,
            updates: [
              {
                entityMrn: apiTokenToEdit.mrn,
                roles,
              },
            ],
          },
        },
        refetchQueries: [GetApiTokensDocument],
      });
      setApiTokenToEdit(null);
      enqueueSnackbar(`Successfully updated permissions`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("Failed to update permissions", {
        variant: "error",
      });
    }
  };

  const onTagDelete = (value: string) => {
    const queryTerms = searchParams.get("queryTerms");

    if (queryTerms) {
      const qtArray = queryTerms.split(",");
      if (qtArray.length <= 1) {
        searchParams.delete("queryTerms");
      } else {
        const found = qtArray.indexOf(value);
        qtArray.splice(found, 1);
        searchParams.set("queryTerms", qtArray.join(","));
      }

      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  const handleFilterQuery: AutocompleteProps<
    string,
    true,
    false,
    true
  >["onChange"] = (_event, query, reason) => {
    if (reason === "clear") {
      searchParams.delete("queryTerms");
    } else {
      searchParams.set("queryTerms", query.join(","));
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  return (
    <Box>
      {/* Bulk Delete and Search Component */}
      <Box sx={{ display: "flex", mb: 3 }}>
        <TokenSearch
          onDelete={onTagDelete}
          {...{
            searchFilters,
            handleFilterQuery,
            placeholder: "api tokens",
          }}
        />
        {hasCreateAgentPermission && (
          <Box sx={{ pl: 3, pt: 1 }}>
            <AddButton
              id="add-api-token"
              onClick={handleAddTokenClick}
              aria-label="Add API Token Token"
            />
          </Box>
        )}
      </Box>
      {data?.apiTokens?.totalCount === 0 ? (
        <NoTokensView tokenType="API tokens" />
      ) : (
        <Box>
          {data && loading ? (
            <Flex alignItems="center" justifyContent="center" sx={{ py: 5 }}>
              <Loading what="API tokens" />
            </Flex>
          ) : (
            <DataTable
              selectable={hasDeleteAgentsPermissions}
              selection={selection}
            >
              <TableHead>
                <TableRow>
                  {hasDeleteAgentsPermissions && (
                    <TableCell>
                      <Checkbox
                        checked={
                          (data?.apiTokens?.edges?.length || 0) > 0 &&
                          selection.length === data?.apiTokens?.edges?.length
                        }
                        indeterminate={
                          selection.length > 0 &&
                          selection.length <
                            (data?.apiTokens?.edges?.length || 0)
                        }
                        onChange={handleSelectAll}
                        disabled={data?.apiTokens?.edges?.length === 0}
                      />
                    </TableCell>
                  )}

                  {tableHeaders.map((header) => (
                    <TableCell
                      key={header.id}
                      sx={{ ...header.options }}
                      {...(header.colSpan ? { colSpan: header.colSpan } : {})}
                      {...(header.sortable
                        ? {
                            sortDirection: "desc",
                          }
                        : {})}
                    >
                      {header.sortable ? (
                        <TableSortLabel
                          onClick={() => handleSort(header.id)}
                          direction={
                            direction === OrderDirection.Desc ? "desc" : "asc"
                          }
                          active={field === header.id}
                        >
                          {header.label}
                        </TableSortLabel>
                      ) : (
                        header.label
                      )}
                    </TableCell>
                  ))}
                  <TableCell width={50}>
                    {/* Intentionally left blank */}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.apiTokens?.edges
                  ?.slice(pageItems.from, pageItems.to)
                  .map((edge) => {
                    return (
                      <TokenTableRow
                        key={edge?.node?.mrn}
                        {...{
                          edge,
                          hasDeleteAgentsPermissions,
                          hasSetAgentMembershipPermission,
                          setDeleteDialogOpen: setDeleteSingleDialogOpen,
                          setItemToDelete,
                          isSelectable: hasDeleteAgentsPermissions,
                          selection,
                          setSelection,
                          setEditDialogOpen,
                          handleEditPermissions,
                        }}
                      />
                    );
                  })}
              </TableBody>
            </DataTable>
          )}
          <Pagination
            fetchMore={fetchMore}
            pageInfo={data?.apiTokens?.pageInfo}
            totalCount={data?.apiTokens?.totalCount || 0}
            setPageItems={setPageItems}
          />
          {selection.length > 0 && (
            <SelectionToolbar>
              <Typography>
                Selected {selection.length} of {data?.apiTokens?.totalCount}{" "}
                tokens
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDeleteClick}
              >
                Delete
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </SelectionToolbar>
          )}
        </Box>
      )}

      <EditRoleDialog
        open={editDialogOpen}
        roles={apiTokenToEdit?.roles}
        onConfirm={onPermissionEditSubmit}
        onClose={() => setApiTokenToEdit(null)}
        scope={org ? "organization" : "space"}
      />

      {/* Bulk Delete confirmation dialog */}
      <ConfirmationDialog
        isOpen={deleteDialogOpen}
        title={`ARE YOU SURE YOU WANT TO DELETE ${selection.length} ${pluralize(
          "TOKEN",
          selection.length,
        )}?`}
        confirmButtonText="DELETE"
        content={
          <Typography>
            If one of your automation workflows uses an API token, deleting that
            token can cause the automation to fail. Once you delete a token,
            there's no way to recover it.
          </Typography>
        }
        onConfirm={() => completeBatchEdit()}
        onClose={handleCancelDelete}
        loading={isDeleting}
      />

      <ConfirmationDialog
        isOpen={deleteSingleDialogOpen}
        confirmButtonText="DELETE"
        content={
          <>
            <Typography>
              This API token was created{" "}
              {itemToDelete?.name && `by ${itemToDelete.name}`}{" "}
              {itemToDelete?.date && `on ${itemToDelete.date}`} for this reason:
              “{itemToDelete?.reason}”
            </Typography>
            <Typography>
              If one of your automation workflows uses this registration token,
              deleting the token can cause the automation to fail. Once you
              delete a token, there's no way to recover it.
            </Typography>
          </>
        }
        onConfirm={() => handleSingleItemDelete(itemToDelete)}
        onClose={handleCancelDelete}
        loading={isDeleting}
      />
    </Box>
  );
}

//////// Table Configuration

type Header = {
  id: string;
  label: string | ReactElement;
  colSpan?: number;
  sortable?: boolean;
  options?: SxProps;
};

const tableHeaders: Header[] = [
  {
    id: "NAME",
    label: "API Token",
    sortable: true,
  },
  { id: "ROLE", label: "Role" },
  { id: "CREATED_AT", label: "Created" },
  {
    id: "LAST_USED",
    label: "Last Used",
    sortable: true,
    options: { width: 140 },
  },
];
