import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DataTable } from "~/components/data-table";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { PolicyIdToMrn } from "~/lib/mrn";
import { Space } from "~/lib/types";
import { useGetQueryPackQueriesQuery } from "~/operations";
import { usePolicyAddDetailOutletContext } from "./policies-add-detail";
import { ExpandMoreIcon } from "~/components/icons";
import { Markdown } from "~/components/markdown";
import { LayoutSwitch } from "~/components/LayoutSwitch/LayoutSwitch";
import { ViewType } from "~/components/view-switcher";
import { pluralize } from "~/lib/pluralize";

export type PolicyAddDetailChecksPageProps = {};

export function PolicyAddDetailChecksPage({}: PolicyAddDetailChecksPageProps) {
  const { space, availablePermissions, policyId, variantPlatformFilter } =
    usePolicyAddDetailOutletContext();
  return (
    <PolicyChecks
      space={space}
      policyId={policyId}
      variantPlatformFilter={variantPlatformFilter}
    />
  );
}

export type PolicyChecksProps = {
  space: Space;
  policyId: string;
  variantPlatformFilter: string | null;
};

export function PolicyChecks({
  space,
  policyId,
  variantPlatformFilter,
}: PolicyChecksProps) {
  const mrn = PolicyIdToMrn(policyId);
  const { data, loading, error } = useGetQueryPackQueriesQuery({
    variables: { input: { mrn, spaceMrn: space.mrn, variantPlatformFilter } },
    // network-only until we can figure out how to cache Policy.queries
    // for different variants of a Policy
    fetchPolicy: "network-only",
  });

  const loadingFailed = error || !data?.policy;
  const policy = data?.policy;
  const groups = (policy?.groups || []).sort((a, b) =>
    a.title.localeCompare(b.title),
  );
  const queries = (policy?.queries || []).sort((a, b) =>
    a.title.localeCompare(b.title),
  );

  const groupedQueries = groups.map((group) => {
    return {
      title: group.title,
      summary: group.summary,
      queries:
        group.checks?.flatMap(
          (gq) => queries?.find((q) => q.mrn === gq.mrn) ?? [],
        ) || [],
    };
  });

  const ungroupedQueries = {
    title: "Other",
    summary: "",
    queries: queries.filter(
      (q) =>
        !groups.some((g) => g.checks?.some((gq) => q.mrn === gq.mrn) || false),
    ),
  };

  const groupedView = [...groupedQueries, ungroupedQueries].map(
    (group) =>
      group.queries.length > 0 && (
        <Box key={group.title} mb={3}>
          <Accordion defaultExpanded>
            <AccordionSummary
              sx={{
                backgroundColor: "background.light",
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                flexDirection: "row-reverse",
                "& .Mui-expanded .expand-more-icon": {
                  transform: "rotate(-180deg)",
                },
                "&.Mui-expanded": {
                  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                },
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  fontWeight={700}
                  sx={{ mr: 0.5, textTransform: "uppercase" }}
                >
                  {group.title}
                </Typography>
                <ExpandMoreIcon
                  className="expand-more-icon"
                  sx={{
                    transform: "rotate(0deg)",
                    transition: "transform .25s",
                  }}
                />
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ px: 0, py: 0 }}>
              {/* I've yet to see a group that doesn't have a blank string summary */}
              {group.summary && (
                <Box
                  sx={{
                    bgcolor: "background.light",
                    px: 2,
                    py: 0,
                    color: "text.secondary",
                    overflow: "auto",
                  }}
                >
                  <Markdown source={group.summary} />
                </Box>
              )}
              <DataTable>
                <TableHead>
                  <TableRow>
                    <TableCell>Check</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {group.queries.map((query) => {
                    const queryId = query.mrn.split("/").pop();
                    return (
                      <TableRow
                        key={query.mrn}
                        component={RouterLink}
                        to={`/space/security/policies/add/${encodeURIComponent(policyId)}/queries/${queryId}?spaceId=${space.id}`}
                      >
                        <TableCell>{query.title}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </DataTable>
            </AccordionDetails>
          </Accordion>
        </Box>
      ),
  );

  const listView = (
    <DataTable>
      <TableHead>
        <TableRow>
          <TableCell>Check</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {queries.map((query) => {
          const queryId = query.mrn.split("/").pop();
          return (
            <TableRow
              key={query.mrn}
              component={RouterLink}
              to={`/space/security/policies/add/${encodeURIComponent(policyId)}/queries/${queryId}?spaceId=${space.id}`}
            >
              <TableCell>{query.title}</TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </DataTable>
  );

  return (
    <Box>
      {loading && <LoadingPage what="Checks" />}
      {!loading && loadingFailed && <LoadingFailedPage what="Checks" />}
      {!loading && policy && (
        <Box>
          <LayoutSwitch
            views={{
              grouped: groupedView,
              list: listView,
            }}
            defaultView={ViewType.Grouped}
            countOfCountCaption={`Showing ${queries.length} of ${queries.length} ${pluralize("Query", queries.length)}:`}
          />
        </Box>
      )}
    </Box>
  );
}
