import {
  OrderDirection,
  TestIamActionsQuery,
  useGetVulnerabilityScoresQuery,
  VulnerabilityScoreOrder,
  VulnerabilityScoreOrderField,
  VulnerabilityScoreType,
} from "~/operations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { AdvisoriesTable } from "./AdvisoriesTable";
import { useSearch } from "~/components/search/useSearch";
import { FilterBar } from "~/pages/compliance/filter-bar";
import { useSort } from "~/pages/inventory/hooks/useSort";
import {
  DisabledScanning,
  NoDataFound,
  NoMatchFilters,
} from "~/pages/inventory/components/EmptyState";
import { Space } from "~/lib/types";
import { useGetSupportVulnerabilities } from "../Vulnerabilities";

export type AdvisoriesProps = {
  assetMrn: string;
  space: Space;
  isVulnerabilityScanningEnabled: boolean;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Advisories({
  assetMrn,
  space,
  isVulnerabilityScanningEnabled,
  availablePermissions,
}: AdvisoriesProps) {
  const { supported } = useGetSupportVulnerabilities({ assetMrn });
  const assetId = assetMrn.split("/").pop() || "";

  const { handleFilterQuery, handleClearSearch, searchFilters } = useSearch();
  const { handleSortClick, orderBy } = useSort<VulnerabilityScoreOrder>({
    defaultSort: {
      field: VulnerabilityScoreOrderField.RiskScore,
      direction: OrderDirection.Asc,
    },
    validFields: ["RISK_SCORE", "PUBLISHED_DATE", "MRN"],
  });

  const { data, error, loading, fetchMore } = useGetVulnerabilityScoresQuery({
    variables: {
      entityMrn: assetMrn,
      first: 10,
      orderBy,
      filter: {
        queryTerms: searchFilters,
        scoreType: VulnerabilityScoreType.Advisory,
        // state: null,
      },
    },
  });

  if (error) {
    return <LoadingFailedPage what="Advisories" />;
  }

  if (loading) {
    return <LoadingPage what="Advisories" />;
  }

  const reportStatus = data?.vulnerabilityScores;
  const hasReport = !!reportStatus;
  const hasAdvisories =
    reportStatus?.__typename === "VulnerabilityScoresConnection";

  const advisoryVulnerabilityScores =
    data?.vulnerabilityScores?.__typename === "VulnerabilityScoresConnection"
      ? data?.vulnerabilityScores
      : undefined;

  if (!reportStatus) {
    return <LoadingFailedPage what="advisories" />;
  }

  const noEntriesTitle = hasReport
    ? "You're all set!"
    : "Vulnerability data not available";
  const noEntriesStatus: AlertColor = hasReport ? "success" : "error";

  let noEntriesMessage = <Typography>No vulnerabilities found</Typography>;

  if (reportStatus.__typename === "NotFoundError") {
    noEntriesMessage = (
      <Typography>Mondoo didn't find any packages on this asset.</Typography>
    );
  }

  if (!supported) {
    noEntriesMessage = (
      <Typography>
        Mondoo didn't find any advisories for this asset because we don't yet
        support the platform. Please let us know what platforms you want support
        for:{" "}
        <Link
          sx={{ textDecoration: "underline", color: "inherit" }}
          href="mailto:hello@mondoo.com"
        >
          hello@mondoo.com
        </Link>
      </Typography>
    );
  }

  const noEntries = (
    <Alert severity={noEntriesStatus} variant="outlined" sx={{ my: 3 }}>
      <AlertTitle>{noEntriesTitle}</AlertTitle>
      {noEntriesMessage}
    </Alert>
  );

  if (!isVulnerabilityScanningEnabled) {
    return (
      <Box mt={3}>
        <DisabledScanning space={space} />
      </Box>
    );
  }

  if (!hasAdvisories) return noEntries;

  return (
    <Box>
      <Box mt={5}>
        <FilterBar
          searchId="vulnerabilities-search"
          placeholder="vm_advisories"
          searchFilters={searchFilters}
          handleFilterQuery={handleFilterQuery}
        />
      </Box>
      {(advisoryVulnerabilityScores?.edges?.length || 0) > 0 ? (
        <AdvisoriesTable
          assetMrn={assetMrn}
          space={space}
          totalCount={advisoryVulnerabilityScores?.totalCount || 0}
          advisoryVulnerabilityScores={advisoryVulnerabilityScores}
          onSort={handleSortClick}
          orderBy={orderBy}
          availablePermissions={availablePermissions}
          fetchMore={fetchMore}
        />
      ) : (
        <Grid item xs={12} mt={1}>
          {searchFilters.length > 0 ? (
            // TODO @JAMIE: get count of all advisories available
            <NoMatchFilters
              title="No advisories match your filter selection"
              content={`Out of the advisories available for this asset, none match the filters you selected. Reset them to reveal all advisories.`}
              onClick={() => handleClearSearch()}
            />
          ) : (
            <NoDataFound
              title="Good news: No advisories found!"
              content={`Mondoo scanned this asset and did not find that it's affected by any of the public advisories available.
Because some vulnerabilities don't have published advisories, it's smart to check for vulnerabilities as well.`}
              space={space}
              showButton
              assetId={assetId}
            />
          )}
        </Grid>
      )}
    </Box>
  );
}
