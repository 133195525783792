import { Dispatch, SetStateAction } from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Paper } from "@mui/material";
import { Pagination, PaginationRange } from "~/components/pagination";
import { EmptySection } from "~/components/vulnerabilities";
import { AdvisoryNodeFieldsFragment } from "~/operations";
import { AdvisoriesTable } from "./AdvisoriesTable";

type SoftwareAdvisoriesProps = {
  totalCount: number;
  advisories: AdvisoryNodeFieldsFragment[];
  setAdvisoryPageItems: Dispatch<SetStateAction<PaginationRange>>;
};

export const SoftwareAdvisories = ({
  advisories,
  setAdvisoryPageItems,
  totalCount,
}: SoftwareAdvisoriesProps) => {
  const hasAdvisories = advisories.length > 0;

  return (
    <>
      <SectionHeading heading="Advisories" />
      {hasAdvisories ? (
        <Paper sx={{ my: 3 }}>
          <AdvisoriesTable advisories={advisories} />
          <Pagination
            totalCount={totalCount}
            setPageItems={setAdvisoryPageItems}
            customPageSizes={[3, 10, 25, 50]}
          />
        </Paper>
      ) : (
        <EmptySection
          id="software-advisories-empty"
          text="There are currently no advisories for this software"
        />
      )}
    </>
  );
};
