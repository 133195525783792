export const numberKFormat = new Intl.NumberFormat("en", {
  notation: "compact",
  compactDisplay: "short",
});

const numberKFormatNoDecimals = new Intl.NumberFormat("en", {
  notation: "compact",
  compactDisplay: "short",
  maximumFractionDigits: 0,
});

export const rankFormat = (value: number) => {
  if (value > 99000) {
    return "99k+";
  }

  if (value >= 10000 && value < 99000) {
    return numberKFormatNoDecimals.format(value);
  }

  return numberKFormat.format(value);
};
