import { createSvgIcon } from "@mui/material";

export const AlpineLinuxIcon = createSvgIcon(
  <svg
    viewBox="0 0 44 39"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9452 23.7126V19.0733L12.606 22.4124C12.9666 22.6627 13.3034 22.8675 13.6226 23.0359C13.9417 23.2035 14.2402 23.3314 14.5179 23.4319C14.796 23.5306 15.0528 23.598 15.2921 23.6417C15.5297 23.6851 15.7472 23.7046 15.9452 23.7073M33.0251 23.3231C33.031 23.3282 33.0635 23.3556 33.1235 23.3938C33.1838 23.4322 33.2719 23.4815 33.3889 23.5303C33.5056 23.5788 33.6516 23.6269 33.8262 23.6627C34.0023 23.6987 34.2074 23.7224 34.4468 23.7224C34.6451 23.7224 34.8634 23.7049 35.1028 23.6633C35.3427 23.6213 35.6022 23.5548 35.8829 23.4567C36.1645 23.3583 36.468 23.2283 36.7901 23.0607C37.1122 22.8917 37.4579 22.6825 37.8243 22.4284L35.2919 19.9462L26.8997 11.5244L23.2059 15.2182L18.2415 10.106L5.77142 22.4284C6.13784 22.6825 6.48062 22.8893 6.80271 23.0607C7.12481 23.2295 7.42917 23.3592 7.7099 23.4567C7.99092 23.5548 8.25066 23.6213 8.49002 23.663C8.72937 23.7049 8.94804 23.7224 9.14603 23.7224C9.38361 23.7224 9.58928 23.6987 9.76658 23.6627C9.9427 23.6269 10.0887 23.5788 10.2039 23.53C10.3209 23.4815 10.4087 23.4322 10.469 23.3938C10.5293 23.3554 10.5618 23.3279 10.5677 23.3228L16.2117 17.6788L18.2241 15.7492L23.8091 21.3341L25.7564 23.3376C25.7623 23.3426 25.7948 23.3701 25.8551 23.4082C25.9154 23.4467 26.0031 23.496 26.1202 23.5448C26.2372 23.5932 26.3832 23.6414 26.5575 23.6772C26.7336 23.7132 26.9417 23.7368 27.1781 23.7368C27.376 23.7368 27.5947 23.7194 27.8341 23.6777C28.0737 23.6358 28.3335 23.5693 28.6142 23.4712C28.8955 23.3728 29.1993 23.2428 29.5214 23.0752C29.8435 22.9062 30.1892 22.697 30.5556 22.4428L26.0936 18.0694L26.9269 17.2361L30.7979 21.1072L33.0556 23.3441M33.0074 -0.000404358L44 19.0594L33.0074 38.1191H10.9926L0 19.0594L10.9926 -0.000404358H33.0074Z"
      fill="#0D597F"
    />
  </svg>,
  "Alpine Linux",
);
