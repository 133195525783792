import { createSvgIcon, SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

// #363636
export const CsaIcon = ({ ...props }) => {
  const { mode } = useColorMode();

  return (
    <SvgIcon {...props}>
      <svg
        id="logo"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 268.34 268.34"
      >
        <path
          d="M31.89,126.06c-.3,1.39-.55,2.82-.77,4.28-2.25,15.26,1.05,27.38,13.59,27.38,9.12,0,13.69-4.71,20.76-14.12L88.14,156c-9,13.12-22.47,25.81-47.27,25.81C9.8,181.81-3.55,159.86.8,130.34c.22-1.46.42-2.86.71-4.27ZM4.78,114.9c8.7-22.77,28.16-36.05,51.39-36.05,25.7,0,34.3,10.56,38.7,29.38l-27.74,9.84C64.77,108.8,62,103,52.48,103c-7.62,0-13.2,4.58-16.86,11.94Z"
          fill={mode === "light" ? "#363636" : "#fff"}
        />
        <path
          d="M172,126.05c8,5.29,11,12.49,9.36,23.54-3.35,22.82-22.81,32.22-47.9,32.22-16.89,0-32.4-5.27-42.29-12.11L108.44,148c6.88,5,19.44,10,29.15,10,10.3,0,13.84-1.71,14.54-6.42.54-3.71-2.86-6-16.44-9.12-16.47-3.75-26.81-7.89-30.95-16.36Zm-69.4-11.16a42,42,0,0,1,.44-4.52c2.77-18.82,20.63-31.52,45.87-31.52,17.78,0,30,4.14,40,11.56l-17.24,21.67c-8.38-6-17.13-9.41-27.74-9.41-8.67,0-11.14,2.57-11.66,6.13-.39,2.67,1.3,4.31,7.14,6.14Z"
          fill={mode === "light" ? "#363636" : "#fff"}
        />
        <polyline
          points="208.89 114.92 225.24 80.42 250.42 80.42 256.61 114.91"
          fill={mode === "light" ? "#363636" : "#fff"}
        />
        <polyline
          points="221.33 140.68 236.25 140.68 234.64 126.03 258.61 126.03 268.34 180.26 241.38 180.26 239.08 162.71 212.1 162.71 204.63 180.26 177.91 180.26 203.62 126.03 227.5 126.03"
          fill={mode === "light" ? "#363636" : "#fff"}
        />
      </svg>
    </SvgIcon>
  );
};

CsaIcon.displayName = "Cloud Security Alliance";
