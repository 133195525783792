import { Box, Typography } from "@mui/material";

export function NoEntries() {
  return (
    <Box textAlign="center" width="100%" pt={10} pb={10}>
      <Typography
        sx={{ mb: 1.5, color: "text.secondary", fontSize: "h5.fontSize" }}
      >
        Nothing found
      </Typography>
    </Box>
  );
}
