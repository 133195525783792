import * as React from "react";
import {
  Box,
  ClickAwayListener,
  Grow,
  InputAdornment,
  ListSubheader,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { SearchIcon } from "~/components/icons";

export type AutocompleteOption = { label: string; value: string };

type AutocompleteProps = {
  options: Array<AutocompleteOption>;
  selected: string;
  onSelect: (option: string) => void;
  open: boolean;
  onClose: () => void;
  anchorRef: React.RefObject<HTMLElement>;
  onSearchInputChange: TextFieldProps["onChange"];
  scope?: string;
};

export function Autocomplete({
  options,
  selected,
  onSelect,
  onClose,
  open,
  anchorRef,
  onSearchInputChange,
  scope = "",
}: AutocompleteProps) {
  return (
    <Popper
      sx={{
        zIndex: 1,
        minWidth: "100%",
      }}
      open={open}
      anchorEl={anchorRef.current}
      placement="bottom-start"
      transition
      disablePortal
      modifiers={[{ name: "flip", enabled: false }]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: "left top" }}>
          <Paper
            sx={{
              border: "1px solid",
              borderColor: "primary.main",
              borderTopLeftRadius: 0,
              mt: "-1px",
            }}
          >
            <ClickAwayListener onClickAway={onClose}>
              <Box sx={{ minWidth: "100px" }}>
                <TextField
                  color="secondary"
                  placeholder={`Search ${scope}...`}
                  size="small"
                  onChange={onSearchInputChange}
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                    sx: {
                      bgcolor: "code.background",
                      m: 2,
                      mb: 0,
                    },
                  }}
                />
                <MenuList
                  id={`${scope}-button-menu`}
                  disablePadding
                  subheader={
                    <ListSubheader
                      children={scope}
                      disableGutters
                      sx={{
                        textTransform: "uppercase",
                        fontSize: 12,
                        fontWeight: 700,
                        lineHeight: "16px",
                        pb: 0.5,
                      }}
                    />
                  }
                  sx={{
                    maxHeight: "calc(100vh - 152px)",
                    overflow: "auto",
                    p: 2,
                    pt: 0,
                    mt: 2,
                  }}
                >
                  {options.map((option) => (
                    <MenuItem
                      disableGutters
                      selected={option.value === selected}
                      onClick={() => onSelect(option.value)}
                      sx={{ p: 1, borderRadius: 1 }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Box>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
}
