import { FC, ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { Tooltip } from "@mui/material";
import { KeyValue as KV } from "~/operations";

type LabelBodyProps = {
  maxWidth?: string;
};

export type LabelProps = {
  children: ReactNode;
  tooltip?: ReactNode | string;
  maxWidth?: string;
};

export const Label: FC<LabelProps> = ({ tooltip, ...rest }) => {
  if (tooltip == null) {
    return <LabelBody {...rest} />;
  }

  return (
    <Tooltip title={tooltip}>
      <LabelBody {...rest} />
    </Tooltip>
  );
};

const reGroupLabel = /^([a-z0-9._-]+)[/]([^ ]+)/;

type KeyValue = Omit<KV, "__typename">;

type Groups = {
  [s: string]: KeyValue[];
};

const groupLabel = (label: KeyValue, groups: Groups) => {
  const m = reGroupLabel.exec(label.key);

  if (m == null) {
    if (groups[label.key] == null) {
      groups[label.key] = [];
    }
    groups[label.key].push(label);
    return;
  }

  if (groups[m[1]] == null) {
    groups[m[1]] = [];
  }
  groups[m[1]].push({ key: m[2], value: label.value });
};

const renderLabel = (label: KeyValue, tooltip: JSX.Element | string = "") => {
  if (label.value == null || label.value === "")
    return (
      <Label key={label.key} maxWidth="250px" tooltip={tooltip}>
        {label.key}
      </Label>
    );
  return (
    <Label
      key={label.key + ":" + label.value}
      maxWidth="250px"
      tooltip={tooltip}
    >
      <span>
        {label.key}
        <LabelColon />
        {label.value}
      </span>
    </Label>
  );
};

const renderTooltipLine = (label: KeyValue) => {
  if (label.value == null || label.value === "")
    return <span>{label.key}</span>;
  return (
    <span>
      {label.key}
      <LabelColon />
      {label.value}
    </span>
  );
};

const renderLabelTooltip = (group: KeyValue[]) => {
  const body = group.map((label, idx) => (
    <div key={idx}>{renderTooltipLine(label)}</div>
  ));
  return <TooltipBox>{body}</TooltipBox>;
};

const renderLabelGroup = (key: string, group: KeyValue[]) => {
  if (group.length === 1) {
    return [renderLabel(group[0])];
  }

  return renderLabel({ key }, renderLabelTooltip(group));
};

export const renderLabels = (labels: KeyValue[] | null) => {
  if (labels == null) return null;

  const groups: Groups = {};
  labels.forEach((label) => groupLabel(label, groups));

  const res = Object.keys(groups).map((key) =>
    renderLabelGroup(key, groups[key]),
  );

  return res;
};

export const CardViewLabels: FC<{ labels: KeyValue[] }> = ({ labels }) => {
  if (labels == null) return null;
  return <CardViewLabelsBox>{renderLabels(labels)}</CardViewLabelsBox>;
};

const CardViewLabelsBox = styled("div")`
  grid-column-start: span 2;
  margin-top: ${(props) => props.theme.spacing(1)};
`;

const LabelBody = styled("div")<LabelBodyProps>`
  border-radius: 12px;
  color: ${(props) => props.theme.palette.text.primary};
  background-color: ${(props) => props.theme.palette.background.light};

  padding: 4px 7px;
  display: inline-flex;
  align-items: center;
  min-width: 90px;

  font-size: 10px;
  font-weight: bold;
  line-height: 16px;
  text-align: center;
  justify-content: center;

  margin-bottom: ${(props) => props.theme.spacing(1)};
  margin-right: ${(props) => props.theme.spacing(1)};

  & span {
    margin-left: 5px;
    margin-right: 5px;
    display: inline;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: ${(props) => props.maxWidth || "inherit"};
  }

  @media print {
    display: inline-block;
  }
`;

export const LabelColon = styled("span")`
  display: inline-block;
  margin: 0 0.23em;
  &::after {
    content: ":";
  }
`;

const TooltipBox = styled("div")`
  /* please note the rather nasty workaround to get around the padding introduce
  by the default theme, which is hard to override (as we use styled components and not withTheme) */
  margin: -${(props) => props.theme.spacing(2)};
  padding: ${(props) => props.theme.spacing(1)}
    ${(props) => props.theme.spacing(2)};
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  border-radius: ${(props) => props.theme.spacing(1)};
`;
