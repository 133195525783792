import { Box } from "@mui/material";
import { Code } from "~/components/code";

export function Overview() {
  let mql = `kernel.parameters['net.ipv4.ip_forward'] == 0
kernel.parameters['net.ipv6.conf.all.forwarding'] == props.Ipv6Forwarding`;

  return (
    <Box>
      {mql && (
        <Code copyButton className="coffeescript">
          {mql}
        </Code>
      )}
    </Box>
  );
}
