import { Box, DialogContent, DialogActions, Button, Link } from "@mui/material";
import { DialogContainer, InfoText, TitleArea, Command } from "../components";
import { ChevronLeftIcon, ChevronRightIcon } from "~/components/icons";
import terminal from "./media/terminal.png";

type Props = {
  onClose: any;
  nav: {
    next: () => void;
    prev: () => void;
  };
};

export const StepFourTwo = ({ nav, onClose }: Props) => {
  return (
    <DialogContainer>
      <TitleArea title="AWS Setup" onClose={onClose} />

      <DialogContent sx={{ p: 0, mt: 4 }}>
        <Box display="flex" flexDirection="column" height={1}>
          <Box>
            <Command
              number={3}
              tooltip={
                <Link
                  href="https://mondoo.com/docs/cnspec/cnspec-aws/cnspec-aws-cloudshell/#step-3-install-cnspec-in-cloudshell-and-register-with-mondoo-platform"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo Docs - Assess AWS Security from AWS CloudShell
                </Link>
              }
            >
              Install cnspec
            </Command>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "1",
            }}
          >
            <Box>
              <Box
                component="img"
                src={terminal}
                sx={{
                  height: "auto",
                  width: {
                    xs: 270,
                    sm: 401,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </DialogContent>

      <InfoText text="You should be seeing something quite like the window above. If yours looks different or displays any errors, make sure to check out our troubleshooting page & our FAQs." />
      <DialogActions sx={{ p: 0, pt: 0, justifyContent: "space-between" }}>
        <Button
          onClick={nav.prev}
          variant="outlined"
          color="secondary"
          startIcon={<ChevronLeftIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Back
        </Button>
        <Button
          onClick={nav.next}
          variant="contained"
          color="secondary"
          endIcon={<ChevronRightIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Next step
        </Button>
      </DialogActions>
    </DialogContainer>
  );
};
