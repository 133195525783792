import { createSvgIcon } from "@mui/material";

export const NodesGradientIcon = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9441 7.96297H14.4441V7.11111V6.61111V6.11111H14.9441H15.4441H16.296V6.61111V7.11111H15.4441V7.96297H14.9441ZM21.8515 6.11111H18.1478V6.61111V7.11111H21.8515V6.61111V6.11111ZM23.7034 6.11111V6.61111V7.11111H27.4071V6.61111V6.11111H23.7034ZM29.2589 6.11111V6.61111V7.11111H32.9626V6.61111V6.11111H29.2589ZM34.8145 6.11111V6.61111V7.11111H35.6663V7.96297H36.1663H36.6663V7.11111V6.61111V6.11111H36.1663H35.6663H34.8145ZM36.6663 9.81482H36.1663H35.6663V13.5185H36.1663H36.6663V9.81482ZM36.6663 15.3704H36.1663H35.6663V19.0741H36.1663H36.6663V15.3704ZM36.6663 20.9259H36.1663H35.6663V21.7778H34.8145V22.2778V22.7778H35.6663H36.1663H36.6663V22.2778V21.7778V20.9259ZM32.9626 22.7778V22.2778V21.7778H32.4995V22.7778H32.9626ZM15.4441 9.81482V11.6667L16.296 11.6667V12.1667V12.6667H12.5923V12.1667V11.6667L14.4441 11.6667V9.81482H14.9441H15.4441ZM9.38856 13.5185H8.88856V12.6667V12.1667V11.6667H9.38856H9.88856H10.7404V12.1667V12.6667H9.88856V13.5185H9.38856ZM18.1478 11.6667V12.1667V12.6667H21.8515V12.1667V11.6667H18.1478ZM23.7034 11.6667V12.1667V12.6667H27.4071V12.1667V11.6667H23.7034ZM29.2589 11.6667V12.1667V12.6667H30.1108V13.5185H30.6108H31.1108V12.6667V12.1667V11.6667H30.6108H30.1108H29.2589ZM31.1108 15.3704H30.6108H30.1108V19.0741H30.6108H31.1108V15.3704ZM31.1108 20.9259H30.6108H30.1108V24.6296H30.6108H31.1108V20.9259ZM31.1108 26.4815H30.6108H30.1108V27.3333H29.2589V27.8333V28.3333H30.1108H30.6108H31.1108V27.8333V27.3333V26.4815ZM27.4071 28.3333V27.8333V27.3333H25.5552V17.2222H9.88856V15.3704H9.38856H8.88856V17.2222H3.33301V33.8889H25.5552V28.3333H27.4071Z"
      fill="url(#paint0_linear_7191_75153)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7191_75153"
        x1="3.33301"
        y1="6.11111"
        x2="31.5868"
        y2="38.3417"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Nodes",
);
