import { useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { Box } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useSnackbar } from "notistack";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  GetIntegrationsSummaryDocument,
  IntegrationType,
  K8sScanNodesStyle,
  useCreateClientIntegrationMutation,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import {
  IntegrationFormInputs,
  OperatorConfigurationForm,
} from "./operator-configuration-form";
import { OperatorInstallationSection } from "./operator-install-section";
import { IntegrationAddHeader } from "../../headers/integration-add-header";
import { getError } from "~/lib/handle-error";

type Props = {
  space: Space;
  updateFlow?: {
    values: IntegrationFormInputs;
    integrationMrn: string;
    integrationId: string;
    token: string;
  };
  setup: {
    id: string;
    title: string;
    summary: string;
    docs_path: string;
    image: string;
    customization: {
      title: string;
      description: string;
    };
    update: {
      title: string;
      description: string;
    };
  };
};

export function OperatorConfiguration({ space, setup, updateFlow }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const [installUrl, setInstallUrl] = useState<string | null>(null);
  const [integrationToken, setIntegrationToken] = useState<string | null>(
    updateFlow ? updateFlow.token : null,
  );
  const [integrationId, setIntegrationId] = useState<string | null>(
    updateFlow ? updateFlow.integrationId : null,
  );
  const [complete, setComplete] = useState<boolean>(false);

  const [createIntegration] = useCreateClientIntegrationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted(data) {
      setIntegrationToken(
        data.createClientIntegration.integration.token || null,
      );
      setIntegrationId(
        data.createClientIntegration.integration.mrn.split("/").pop() || "",
      );
      setComplete(true);
    },
    refetchQueries: [
      {
        query: GetIntegrationsSummaryDocument,
        variables: { input: { spaceMrn: space.mrn } },
      },
    ],
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    notifyOnNetworkStatusChange: true,
    onCompleted() {
      setComplete(true);
    },
    refetchQueries: [GetClientIntegrationDocument],
  });

  const reset = () => {
    setInstallUrl(null);
    setIntegrationToken(null);
    setIntegrationId(null);
    setComplete(false);
  };

  const onSubmit: SubmitHandler<IntegrationFormInputs> = async (data) => {
    const splitAndTrim = (string: string): string[] => {
      const replaceNewlinesRegex = new RegExp(/\r?\n|\r/g);
      return string
        .replace(replaceNewlinesRegex, ",")
        .split(",")
        .map((x) => x.trim());
    };

    const integrationVariables = {
      // important: extras passed into update/create function directly otherwise
      // we get type errors
      type: ClientIntegrationType.K8S,
      configurationOptions: {
        k8sConfigurationOptions: {
          scanNodes: data.scanNodes,
          scanNodesStyle: data.scanNodes
            ? data.scanNodesStyle
            : K8sScanNodesStyle.Unknown,

          // workload scanning section
          scanWorkloads: data.scanWorkloads,
          scanPublicImages: data.scanWorkloads
            ? data.workloadImageScanning
            : false,
          namespaceAllowList: data.namespaceScanning
            ? splitAndTrim(data.namespaceAllowList)
            : [],
          namespaceDenyList: data.namespaceScanning
            ? splitAndTrim(data.namespaceDenyList)
            : [],

          // scan incoming deployments section
          scanDeploys: data.scanDeploys,
          certificateManager: data.scanDeploys ? data.certificateManager : "",
        },
      },
    };

    const buildInstallURL = () => {
      const installPathUrl = new URL(
        "https://install.mondoo.com/k8s/auditconfig",
      );
      installPathUrl.searchParams.set("nodes", data.scanNodes.toString());
      if (data.scanNodes) {
        installPathUrl.searchParams.set(
          "nodesStyle",
          data.scanNodesStyle?.toLowerCase(),
        );
      }

      installPathUrl.searchParams.set(
        "kubernetesresources",
        data.scanWorkloads.toString(),
      );
      installPathUrl.searchParams.set("admission", data.scanDeploys.toString());
      if (data.scanDeploys) {
        installPathUrl.searchParams.set(
          "admission_certificate_provisioning_mode",
          data.certificateManager,
        );
      }
      installPathUrl.searchParams.set(
        "containerimagescanning",
        data.workloadImageScanning.toString(),
      );

      if (data.namespaceScanning) {
        if (data.namespaceAllowList) {
          installPathUrl.searchParams.set(
            "namespaceallowlist",
            //strip out any extra spaces after commas in the list
            data.namespaceAllowList.replaceAll(", ", ","),
          );
        }
        if (data.namespaceDenyList) {
          installPathUrl.searchParams.set(
            "namespacedenylist",
            //strip out any extra spaces after commas in the list
            data.namespaceDenyList.replaceAll(", ", ","),
          );
        }
      }
      //we need to decode the URL and return as a string so that we rid it of any characters that the kubectl doesn't like
      return decodeURIComponent(installPathUrl.href);
    };

    try {
      if (updateFlow) {
        await updateIntegration({
          variables: {
            input: {
              ...integrationVariables,
              name: data.integrationName,
              mrn: updateFlow.integrationMrn,
            },
          },
        });
        setInstallUrl(buildInstallURL);
        enqueueSnackbar("Successfully updated Kubernetes Integration", {
          variant: "success",
        });
      } else {
        await createIntegration({
          variables: {
            input: {
              ...integrationVariables,
              name: data.integrationName,
              spaceMrn: space.mrn,
              longLivedToken: false,
            },
          },
        });
        setInstallUrl(buildInstallURL);
        enqueueSnackbar("Successfully created Kubernetes Integration", {
          variant: "success",
        });
      }
    } catch (err) {
      const msg = getError(err);
      enqueueSnackbar(msg, {
        variant: "error",
      });
    }
  };

  return (
    <Box>
      <IntegrationAddHeader {...{ type: IntegrationType.K8S }} />

      <AnimatePresence mode="wait" initial={false}>
        {/* convert these to state machines */}
        {complete && integrationToken && installUrl && integrationId ? (
          <OperatorInstallationSection
            {...{ space, integrationId, installUrl, reset, updateFlow }}
            instructions={updateFlow ? setup.update : setup.customization}
            token={integrationToken}
          />
        ) : (
          <OperatorConfigurationForm {...{ space, onSubmit, updateFlow }} />
        )}
        {/* Update to set policies within this stepper? */}
      </AnimatePresence>
    </Box>
  );
}

export const motionOptions = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
