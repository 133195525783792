import {
  Alert,
  AlertTitle,
  Button,
  CircularProgress,
  Grid,
  SxProps,
  alpha,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useBanner } from "./useBanner";

export type BannerProps = {
  type: "info" | "warning" | "error" | "success";
  title: string;
  message: string;
  buttonText: string;
  showBanner?: boolean;
  sx?: SxProps;
  to?: string;
  onClick?: () => void;
  buttonLoading?: boolean;
};

export function Banner({
  type,
  showBanner,
  title,
  message,
  buttonText,
  sx,
  to,
  onClick,
  buttonLoading,
}: BannerProps) {
  const { bannerColors } = useBanner({ type });

  return (
    <Grid item xs={12} sx={{ display: showBanner ? "block" : "none" }}>
      <Alert
        severity={type}
        variant="filled"
        sx={[
          {
            color: bannerColors.color,
            background: alpha(bannerColors.background, 0.2),
            fontWeight: 400,
            ".MuiAlert-message": {
              width: 1,
            },
            ".MuiAlert-action": {
              pl: 0.5,
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md>
            <AlertTitle sx={{ color: bannerColors.color }}>{title}</AlertTitle>
            {message}
          </Grid>
          <Grid
            item
            xs={12}
            md="auto"
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "flex-end" },
            }}
          >
            <Button
              // if supplied a "to" prop, use RouterLink
              {...(to ? { component: RouterLink, to: to } : {})}
              {...(onClick ? { onClick: onClick } : {})}
              color="inherit"
              size="small"
              sx={{ alignSelf: "start", mt: -0.5 }}
            >
              {buttonLoading ? <CircularProgress size={16} /> : buttonText}
            </Button>
          </Grid>
        </Grid>
      </Alert>
    </Grid>
  );
}
