import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Avatar,
  MenuItem,
  IconButton,
  Popover,
  Paper,
  MenuList,
  IconButtonProps,
} from "@mui/material";
import { AccountCircle } from "~/components/icons";
import { useAuth } from "~/login/provider";
import { useViewer } from "~/providers/viewer";
import { Username } from "~/lib/user";
import { NavbarTooltip } from "./navbar-tooltip";

export function UserMenu() {
  const { state, logout } = useAuth();
  const { viewer } = useViewer();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpen: IconButtonProps["onClick"] = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "user-menu" : undefined;
  const src = state.user?.photoURL;

  const name = Username(viewer);
  const avatar = src ? (
    <Avatar
      src={src}
      sx={{
        width: (theme) => theme.spacing(3),
        height: (theme) => theme.spacing(3),

        ".MuiAvatar-fallback": {
          backgroundColor: "text.primary",
          color: "primary.darker",
          width: (theme) => theme.spacing(3),
          height: (theme) => theme.spacing(3),
        },
      }}
    />
  ) : (
    <AccountCircle />
  );

  return (
    <>
      <NavbarTooltip title={name}>
        <IconButton
          aria-describedby={id}
          onClick={handleOpen}
          size="large"
          data-name="avatar"
          color="inherit"
        >
          {avatar}
        </IconButton>
      </NavbarTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Paper>
          <MenuList>
            <MenuItem disabled={true}>
              <div>
                <span>
                  Signed in as <b>{name}</b>
                </span>
              </div>
            </MenuItem>
            <MenuItem
              data-name="nav-user-settings"
              to="/settings"
              component={RouterLink}
              onClick={handleClose}
            >
              User Settings
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              to="/invitations"
              component={RouterLink}
              data-name="nav-invitations"
            >
              Invitations
            </MenuItem>
            <MenuItem onClick={logout} data-name="nav-logout">
              Logout
            </MenuItem>
          </MenuList>
        </Paper>
      </Popover>
    </>
  );
}
