import { useEffect, useState } from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  Button,
  TextField,
  Link,
  IconButton,
  Box,
} from "@mui/material";
import { SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import { Org } from "~/lib/types";
import {
  ConfigurationPaper,
  ConfigurationItemBasic,
  ConfigurationList,
  ConfigurationItemSwitch,
  ConfigurationItemText,
  ConfigurationItemP,
  ConfigurationItemSwitchProps,
  ConfigurationItemBase,
} from "~/components/configuration-items";
import { Loading } from "~/components/loading";
import { AddIcon, DeleteIcon } from "~/components/icons";
import {
  LoadOrganizationSsoProviderDocument,
  useEnableSsoProviderMutation,
  useLoadOrganizationSsoProviderQuery,
  useSetSsoProviderMutation,
} from "~/operations";

type SsoConfigFormInput = {
  ssoUrl: string;
  idpEntityID: string;
  rpEntityID: string;
  x509Fingerprints: { value: string }[];
  x509Certificates: { value: string }[];
  enabled: boolean;
};

const defaultValues: SsoConfigFormInput = {
  ssoUrl: "",
  idpEntityID: "",
  rpEntityID: "",
  x509Fingerprints: [],
  x509Certificates: [],
  enabled: false,
};

export type SettingsAuthProps = {
  org: Org;
};

export function SettingsAuthentication({ org }: SettingsAuthProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const { data, loading } = useLoadOrganizationSsoProviderQuery({
    variables: { mrn: org.mrn },
  });
  const [enableSsoProvider] = useEnableSsoProviderMutation();
  const [setSsoProvider] = useSetSsoProviderMutation();

  const ssoProvider = data?.ssoProvider.provider;

  const values: SsoConfigFormInput = ssoProvider
    ? {
        enabled: ssoProvider.enabled,
        ssoUrl: ssoProvider.ssoUrl,
        idpEntityID: ssoProvider.idpEntityID,
        rpEntityID: ssoProvider.rpEntityID,
        x509Fingerprints: ssoProvider.x509Fingerprints.map((value) => ({
          value,
        })),
        x509Certificates: [],
      }
    : defaultValues;

  const { register, handleSubmit, reset, formState, watch, setValue, control } =
    useForm<SsoConfigFormInput>({
      mode: "onChange",
      defaultValues,
      values,
    });

  const printsArray = useFieldArray({
    control,
    name: "x509Fingerprints",
  });

  const certsArray = useFieldArray({
    control,
    name: "x509Certificates",
  });

  useEffect(() => {
    reset(values);
  }, []);

  const certPrefix = "-----BEGIN CERTIFICATE-----";
  const certSuffix = "-----END CERTIFICATE-----";
  const certPlaceholderText = `Starts with "${certPrefix}" and ends with "${certSuffix}"`;

  const certValueIsValid = (value: string) => {
    return value.startsWith(certPrefix) && value.endsWith(certSuffix);
  };

  if (loading) {
    return <Loading what="SSO Provider" />;
  }

  const isSsoCapable = org.capabilities.includes("mondoo.organization.SSO");
  const isSsoEnabled = watch("enabled");
  const hasSsoConfig = ssoProvider !== undefined;

  const actionText = (enabled: boolean): string => {
    return enabled ? "Disable" : "Enable";
  };

  const handleConfigureClick = () => {
    setIsConfigOpen(true);
  };

  const handleSamlEnableChange: ConfigurationItemSwitchProps["onChange"] =
    async (_event, enabled) => {
      try {
        setValue("enabled", enabled);
        await enableSsoProvider({
          variables: { input: { organizationMrn: org.mrn, enabled } },
          refetchQueries: [LoadOrganizationSsoProviderDocument],
        });
      } catch (error) {
        setValue("enabled", !enabled);
        enqueueSnackbar(
          `Failed to ${enabled ? "enable" : "disable"} SSO configuration`,
          {
            variant: "error",
          },
        );
      }
    };

  const handleConfigCancel = () => {
    reset();
    setIsConfigOpen(false);
  };

  const handleRemoveFingerprint = (index: number) => {
    printsArray.remove(index);
  };

  const handleAddCertificate = () => {
    certsArray.append({ value: "" });
  };

  const handleRemoveCertificate = (index: number) => {
    certsArray.remove(index);
  };

  const handleFormSubmit: SubmitHandler<SsoConfigFormInput> = async (
    formData,
  ) => {
    try {
      await setSsoProvider({
        variables: {
          input: {
            organizationMrn: org.mrn,
            provider: {
              enabled: formData.enabled,
              ssoUrl: formData.ssoUrl,
              idpEntityID: formData.idpEntityID,
              rpEntityID: formData.rpEntityID,
              x509Fingerprints: formData.x509Fingerprints.map((f) => f.value),
              x509Certificates: formData.x509Certificates.map((f) => f.value),
            },
          },
        },
        refetchQueries: [LoadOrganizationSsoProviderDocument],
      });
      enqueueSnackbar("Successfully saved SSO configuration", {
        variant: "success",
      });
      setIsConfigOpen(false);
    } catch (error) {
      enqueueSnackbar("Failed to save SSO configuration", {
        variant: "error",
      });
    }
  };

  const ssoSection = (
    <ConfigurationPaper
      key="sso-config"
      className={isSsoCapable ? "" : "disabled"}
    >
      {!isConfigOpen && (
        <ConfigurationList title="SSO">
          <ConfigurationItemText
            primary="Name"
            secondary="The organization ID is used for SSO login"
            label="Name"
            value={org.id}
            disabled={true}
          />
          <Divider></Divider>
          <ConfigurationItemSwitch
            primary="SAML 2.0 Connection"
            secondary={
              <Link
                id="saml-configure-link"
                className={hasSsoConfig ? "configured" : ""}
                onClick={handleConfigureClick}
              >
                Configure
              </Link>
            }
            checked={isSsoEnabled}
            action={actionText(isSsoEnabled)}
            onChange={handleSamlEnableChange}
            disabled={!hasSsoConfig}
          />
        </ConfigurationList>
      )}
      {isConfigOpen && (
        <Box
          component="form"
          id="sso-config-form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <ConfigurationList title="SSO > Identity Provider">
            <ConfigurationItemBase
              primary="Single Sign-On URL"
              secondary="Identity Provider URL"
            >
              <TextField
                id="ssoUrl-input"
                variant="outlined"
                fullWidth
                label="Single Sign-On URL"
                {...register("ssoUrl", { required: "Required" })}
                error={Boolean(formState.errors.ssoUrl?.message)}
                helperText={formState.errors.ssoUrl?.message}
              />
            </ConfigurationItemBase>
            <Divider></Divider>
            <ConfigurationItemBase
              primary="Identity Provider Entity ID"
              secondary="Unique name for the Identity Provider"
            >
              <TextField
                id="idpEntityID-input"
                variant="outlined"
                fullWidth
                label="Identity Provider Entity ID"
                {...register("idpEntityID", { required: "Required" })}
                error={Boolean(formState.errors.idpEntityID?.message)}
                helperText={formState.errors.idpEntityID?.message}
              />
            </ConfigurationItemBase>
            <Divider></Divider>
            <ConfigurationItemBase
              primary="Service Provider Entity ID"
              secondary="Unique name for the Service Provider"
            >
              <TextField
                id="rpEntityID-input"
                variant="outlined"
                fullWidth
                label="Service Provider Entity ID"
                {...register("rpEntityID", { required: "Required" })}
                error={Boolean(formState.errors.rpEntityID?.message)}
                helperText={formState.errors.rpEntityID?.message}
              />
            </ConfigurationItemBase>
            <Divider></Divider>
            <ConfigurationItemP>
              <Typography variant="h6">X.509 Certificates</Typography>
              <Typography component="span" variant="body2" color="textPrimary">
                Public Certificate for IdP SAML endpoint
              </Typography>
              <List>
                {printsArray.fields.map((f, i) => {
                  return (
                    <ListItem key={f.id}>
                      <ListItemText
                        primary={`Certificate #${i + 1}`}
                        secondary={f.value}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="remove certificate"
                          onClick={() => handleRemoveFingerprint(i)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
                {certsArray.fields.map((f, i) => {
                  const errorMsg =
                    formState.errors.x509Certificates?.at?.(i)?.value?.message;
                  return (
                    <ListItem key={f.id}>
                      <ListItemText
                        primary={`Certificate #${
                          printsArray.fields.length + i + 1
                        }`}
                        secondary={
                          <TextField
                            id={`cert-${f.id}-input`}
                            inputProps={{
                              className: "cert-input",
                            }}
                            variant="outlined"
                            fullWidth
                            multiline
                            placeholder={certPlaceholderText}
                            {...register(`x509Certificates.${i}.value`, {
                              required: "Required",
                              validate: {
                                mustBeCert: (value) =>
                                  certValueIsValid(value) ||
                                  "Must be valid certificate.",
                              },
                            })}
                            error={Boolean(errorMsg)}
                            helperText={errorMsg}
                          />
                        }
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="remove certificate"
                          onClick={() => handleRemoveCertificate(i)}
                          size="large"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
                <ListItem key="add-cert" sx={{ px: 0 }}>
                  <Button
                    sx={{ ml: "auto" }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddCertificate}
                  >
                    Add Certificate
                  </Button>
                </ListItem>
              </List>
            </ConfigurationItemP>
            <Divider></Divider>
            <ConfigurationItemP>
              <Box display="flex" justifyContent="end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formState.isValid || !formState.isDirty}
                  sx={{ mr: 1 }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="critical"
                  onClick={handleConfigCancel}
                >
                  Cancel
                </Button>
              </Box>
            </ConfigurationItemP>
          </ConfigurationList>
        </Box>
      )}
    </ConfigurationPaper>
  );

  const ssoDisabledSection = (
    <ConfigurationPaper key="sso-disabled" id="sso-disabled-section">
      <ConfigurationList title="" style={{ paddingBottom: "0" }}>
        <ConfigurationItemBasic
          primary={
            <Typography component="span" variant="h6" color="textPrimary">
              Protect your organization with SSO
            </Typography>
          }
          secondary="Upgrade to add SSO login to your space"
          action={
            <Button
              href="mailto:hello@mondoo.com"
              variant="contained"
              color="primary"
              data-name="sso-contact-button"
            >
              Contact
            </Button>
          }
        />
      </ConfigurationList>
    </ConfigurationPaper>
  );

  return (
    <Box>
      {!isSsoCapable && ssoDisabledSection}
      {ssoSection}
    </Box>
  );
}
