import { createSvgIcon } from "@mui/material";

export const CloudWatchAlarmsIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_11191)" />
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      x="6"
      y="5.5"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.1223 24.6399H20.7967V11.3619C22.1891 11.8054 23.7293 11.8054 25.1223 11.3619V24.6399ZM25.4855 9.85098C23.8956 10.608 22.0221 10.6074 20.4335 9.85098C20.2372 9.7573 20.0053 9.77069 19.8192 9.8873C19.6344 10.0039 19.5223 10.2072 19.5223 10.4258V25.2771C19.5223 25.6295 19.8071 25.9143 20.1595 25.9143H25.7595C26.1119 25.9143 26.3967 25.6295 26.3967 25.2771V10.4258C26.3967 10.2072 26.2846 10.0039 26.0998 9.8873C25.9143 9.77069 25.6824 9.75667 25.4855 9.85098ZM22.9595 1.27447C24.7699 1.27447 26.2425 2.74711 26.2425 4.55685C26.2425 6.36723 24.7699 7.83987 22.9595 7.83987C21.1497 7.83987 19.6771 6.36723 19.6771 4.55685C19.6771 2.74711 21.1497 1.27447 22.9595 1.27447ZM22.9595 9.11434C25.4727 9.11434 27.517 7.0701 27.517 4.55685C27.517 2.04424 25.4727 0 22.9595 0C20.4469 0 18.4026 2.04424 18.4026 4.55685C18.4026 7.0701 20.4469 9.11434 22.9595 9.11434ZM0 28.1548H28V26.8804H0V28.1548ZM11.8366 24.6399H16.1621V16.3947H11.8366V24.6399ZM11.1994 25.9143H16.7994C17.1518 25.9143 17.4366 25.6295 17.4366 25.2771V15.7575C17.4366 15.4051 17.1518 15.1203 16.7994 15.1203H11.1994C10.8476 15.1203 10.5621 15.4051 10.5621 15.7575V25.2771C10.5621 25.6295 10.8476 25.9143 11.1994 25.9143ZM2.87711 24.6399H7.20264V13.5947H2.87711V24.6399ZM2.23987 25.9143H7.83987C8.19226 25.9143 8.47711 25.6295 8.47711 25.2771V12.9575C8.47711 12.6051 8.19226 12.3203 7.83987 12.3203H2.23987C1.88748 12.3203 1.60264 12.6051 1.60264 12.9575V25.2771C1.60264 25.6295 1.88748 25.9143 2.23987 25.9143Z"
        fill="white"
      />
    </svg>
    <defs>
      <linearGradient
        id="paint0_linear_1045_11191"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "CloudWatch Alarms",
);
