import { useEffect, useState } from "react";

export type UseSectionsProps = {
  key: string;
  sections?: string[];
  withLocalStorage?: boolean;
};

export function useSections({
  key,
  sections = [],
  withLocalStorage = true,
}: UseSectionsProps) {
  const openSectionsKey = `openSections.${key}`;
  const [openSections, setOpenSections] = useState<string[]>([]);

  useEffect(() => {
    const storedOpenSections = (() => {
      try {
        const stored = localStorage.getItem(openSectionsKey) || "";
        const parsed = JSON.parse(stored);
        const isValid = (v: unknown): v is string[] =>
          Array.isArray(parsed) && parsed.every((s) => sections.includes(s));
        if (!isValid(parsed)) throw `Invalid value for ${openSectionsKey}`;
        return parsed;
      } catch {
        return sections;
      }
    })();
    setOpenSections(withLocalStorage ? storedOpenSections : []);
  }, []);

  const setOpen = (sections: string[]) => {
    setOpenSections(sections);
    if (withLocalStorage) {
      localStorage.setItem(openSectionsKey, JSON.stringify(sections));
    }
  };

  const isOpen = (section: string) => {
    return openSections.includes(section);
  };

  const toggle = (section: string) => {
    const nextOpenSections = openSections.filter((s) => s !== section);
    if (!isOpen(section)) {
      nextOpenSections.push(section);
    }
    setOpen(nextOpenSections);
  };

  return { openSections, setOpen, isOpen, toggle };
}
