import { createSvgIcon } from "@mui/material";

export const BigqueryIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.7559 42.6006L1.15709 25.9743C0.78804 25.3351 0.59375 24.61 0.59375 23.8719C0.59375 23.1338 0.78804 22.4087 1.15709 21.7695L10.7559 5.14356C11.125 4.50425 11.6559 3.97337 12.2953 3.60428C12.9346 3.23519 13.6598 3.04091 14.398 3.04095H33.5957C34.3339 3.04097 35.059 3.23529 35.6983 3.60437C36.3375 3.97346 36.8684 4.5043 37.2374 5.14356L46.8363 21.7695C47.2054 22.4088 47.3997 23.1339 47.3997 23.8721C47.3997 24.6103 47.2054 25.3354 46.8363 25.9747L37.2374 42.6006C36.8683 43.2398 36.3374 43.7706 35.6982 44.1396C35.0589 44.5086 34.3338 44.7029 33.5957 44.7029H14.398C13.6598 44.703 12.9346 44.5087 12.2952 44.1396C11.6559 43.7705 11.125 43.24 10.7559 42.6006Z"
      fill="url(#paint0_linear_14087_142091)"
    />
    <path
      opacity="0.07"
      d="M44.1947 32.0923L30.0783 17.9753L23.9991 16.3484L18.5385 18.3779L16.4297 23.8721L18.1253 29.9894L33.0951 44.9592L36.2717 44.818L44.1947 32.0923Z"
      fill="black"
    />
    <path
      d="M23.9973 15.39C19.3131 15.39 15.5156 19.1875 15.5156 23.8721C15.5156 28.5563 19.3131 32.3538 23.9973 32.3538C28.6816 32.3538 32.4787 28.5563 32.4787 23.8721C32.4787 19.1875 28.6812 15.39 23.9973 15.39ZM23.9973 30.3075C20.443 30.3075 17.5616 27.4261 17.5616 23.8717C17.5616 20.3174 20.443 17.4363 23.9973 17.4363C27.5517 17.4363 30.4331 20.3178 30.4331 23.8721C30.4331 27.4264 27.5517 30.3075 23.9973 30.3075Z"
      fill="white"
    />
    <path
      d="M19.9688 23.5444V26.1809C20.3588 26.8506 20.9092 27.4129 21.5704 27.8173V23.5444H19.9688ZM23.1446 21.3219V28.4199C23.417 28.47 23.6957 28.5004 23.982 28.5004C24.2431 28.5004 24.4976 28.473 24.7473 28.4313V21.3219H23.1446ZM26.4689 24.64V27.7709C27.1406 27.3437 27.6922 26.7522 28.0713 26.0522V24.6404L26.4689 24.64ZM30.0982 29.0964L29.2195 29.9759C29.1451 30.0504 29.1033 30.1515 29.1033 30.2569C29.1033 30.3622 29.1451 30.4633 29.2195 30.5379L32.5526 33.8699C32.6272 33.9443 32.7282 33.9861 32.8336 33.9861C32.939 33.9861 33.04 33.9443 33.1146 33.8699L33.9933 32.9915C34.0673 32.9168 34.1087 32.8159 34.1087 32.7107C34.1087 32.6055 34.0673 32.5046 33.9933 32.4299L30.6599 29.0964C30.5852 29.0223 30.4842 28.9807 30.379 28.9807C30.2738 28.9807 30.1729 29.0223 30.0982 29.0964Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_14087_142091"
        x1="23.9969"
        y1="3.04132"
        x2="23.9969"
        y2="44.6382"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4387FD" />
        <stop offset="1" stopColor="#4683EA" />
      </linearGradient>
    </defs>
  </svg>,
  "Bigquery",
);
