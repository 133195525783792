import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

type Props = {
  children?: ReactNode;
};

// highly inspired by termynal - https://github.com/ines/termynal
export function CliComponent({ children }: Props) {
  return (
    <CliContainer
      initial={{ y: 100, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.3 }}
    >
      <InnerContainer>{children}</InnerContainer>
    </CliContainer>
  );
}

const CliContainer = styled(motion.div)`
  position: relative;
  display: flex;
  padding: 56px 16px 16px;
  flex-direction: column;
  height: 340px;
  width: 90%;
  max-width: 750px;
  background: #333;
  border-radius: 8px;
  color: #ddd;
  font-family: "Fira Mono", Consolas, Menlo, Monaco, "Courier New", Courier,
    monospace;
  // box-shadow generated by https://shadows.brumm.af/
  box-shadow:
    0px 0.6px 4.2px rgba(0, 0, 0, 0.02),
    0px 1.3px 10px rgba(0, 0, 0, 0.028),
    0px 2.5px 18.8px rgba(0, 0, 0, 0.035),
    0px 4.5px 33.5px rgba(0, 0, 0, 0.042),
    0px 8.4px 62.7px rgba(0, 0, 0, 0.05),
    0px 20px 150px rgba(0, 0, 0, 0.07);

  &:before {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #d9515d;
    box-shadow:
      25px 0 0 #f4c025,
      50px 0 0 #3ec930;
  }
`;

const InnerContainer = styled("div")`
  flex: 1 0 auto;
  height: 100%;
  width: 100%;
  background: inherit;
  color: #ddd;
  font-family: "Fira Mono", Consolas, Menlo, Monaco, "Courier New", Courier,
    monospace;
  overflow-y: scroll;
`;
