import { TableCell, Typography } from "@mui/material";

type TypeCellProps = {
  type: string;
};

export const TypeCell = ({ type }: TypeCellProps) => {
  return (
    <TableCell>
      <Typography fontSize={14} sx={{ textTransform: "capitalize" }}>
        {type.toLowerCase()}
      </Typography>
    </TableCell>
  );
};
