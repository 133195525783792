import { Theme } from "@mui/material";

const getStateColor = (theme: Theme, label: string, gradient = false) => {
  if (label === undefined || label === null) {
    return "#000000";
  }

  switch (label?.toLowerCase()) {
    case "a":
    case "excellent":
    case "none":
    case "pass":
    case "active":
      return gradient
        ? theme.palette.excellent.gradient
        : theme.palette.excellent.main;
    case "b":
    case "good":
    case "low":
      return gradient ? theme.palette.good.gradient : theme.palette.good.main;
    case "c":
    case "fair":
    case "medium":
    case "not_ready":
    case "waiting_for_setup":
      return gradient ? theme.palette.fair.gradient : theme.palette.fair.main;
    case "d":
    case "poor":
    case "bad":
    case "high":
      return gradient ? theme.palette.poor.gradient : theme.palette.poor.main;
    case "f":
    case "fail":
    case "critical":
    case "missing":
      return gradient ? theme.palette.fail.gradient : theme.palette.fail.main;
    case "u":
    case "unknown":
    case "unrated":
    case "unscored":
    case "ignored":
    case "info":
    case "data":
    case "skip":
      return gradient
        ? theme.palette.unknown.gradient
        : theme.palette.unknown.main;
    case "recommended":
    case "warning":
    case "security":
      return gradient
        ? theme.palette.warning.gradient
        : theme.palette.warning.main;
    case "x":
    case "errorscan":
      return gradient
        ? theme.palette.errorScan.gradient
        : theme.palette.errorScan.main;
    case "error":
      return gradient ? theme.palette.error.gradient : theme.palette.error.main;
    case "primarytext":
      return theme.palette.text.primary;
    case "remove":
      return theme.palette.unknown.main;
    default:
      return "#000000";
  }
};

export default getStateColor;
