import {
  ReactNode,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Box, Button, Collapse } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "../icons";
import { Clamp } from "../clamp/clamp";

export type ShowDetails = {
  children: ReactNode;
  maxLines?: number;
  id?: string;
};

export function ShowDetails({ children, maxLines = 1, id }: ShowDetails) {
  const [contentIsOpen, setContentIsOpen] = useState(false);
  const [contentOpacity, setContentOpacity] = useState(0);
  const [clippedOpacity, setClippedOpacity] = useState(1);
  const [showToggle, setShowToggle] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const clippedRef = useRef<HTMLDivElement>(null);
  const clippedHeight = clippedRef.current?.clientHeight ?? 0;

  const setToggleVisibility = useCallback(() => {
    const clippedHeight = clippedRef.current?.clientHeight ?? 0;
    const contentHeight = contentRef.current?.clientHeight ?? 0;
    setShowToggle(!contentIsOpen ? contentHeight > clippedHeight : true);
  }, [clippedRef.current, contentRef.current, contentIsOpen]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setToggleVisibility();
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [children]);

  const handleShowClick = useCallback(() => {
    if (!contentIsOpen) {
      setContentOpacity(1);
      setClippedOpacity(0);
    }
    setContentIsOpen(!contentIsOpen);
  }, [contentIsOpen]);

  const handleTranistionEnd = useCallback(() => {
    setContentOpacity(contentIsOpen ? 1 : 0);
    setClippedOpacity(contentIsOpen ? 0 : 1);
  }, [contentIsOpen]);

  return (
    <Box
      id={id}
      className="show-details"
      data-testid="show-details"
      sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}
    >
      {/*
        To have normal clamping behavior when closed, but then smoothly transition the content to
        full height when opened, the strategy used here is to place the clamped and full height
        content on top of each other and then toggle their opacity.
      */}
      <Box sx={{ position: "relative" }}>
        <Clamp
          ref={clippedRef}
          lines={maxLines}
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            opacity: clippedOpacity,
            // Keep visible content on top to allow text selection.
            zIndex: contentIsOpen ? 0 : 1,
          }}
        >
          {children}
        </Clamp>
        <Collapse
          in={contentIsOpen}
          collapsedSize={clippedHeight}
          className="show-details-full-content"
          sx={{ opacity: contentOpacity, zIndex: contentIsOpen ? 1 : 0 }}
          onTransitionEnd={handleTranistionEnd}
        >
          <Box ref={contentRef} className="show-details-content">
            {children}
          </Box>
        </Collapse>
      </Box>
      <Box className="show-details-actions">
        {showToggle && (
          <Button
            className="show-details-toggle"
            variant="text"
            color="secondary"
            onClick={handleShowClick}
            endIcon={contentIsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            disableRipple
            sx={{
              p: 0,
              mt: "-2px",
              height: "1rem",
              background: "transparent",
              textTransform: "none",
              fontWeight: 600,
              whiteSpace: "nowrap",
              "&:hover": { background: "transparent" },
            }}
          >
            Show Details
          </Button>
        )}
      </Box>
    </Box>
  );
}
