import { createSvgIcon } from "@mui/material";

export const LambdaFunctionsIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_14085)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.3864 33H8.26957L15.0326 18.574L18.0969 25.014L14.3864 33ZM15.4707 17.202C15.3891 17.03 15.2173 16.92 15.0287 16.92H15.0267C14.8391 16.921 14.6673 17.032 14.5858 17.205L7.04778 33.285C6.97511 33.439 6.98591 33.622 7.07627 33.767C7.16564 33.912 7.32278 34 7.49073 34H14.6967C14.8873 34 15.0601 33.889 15.1416 33.714L19.0859 25.225C19.1498 25.088 19.1488 24.929 19.084 24.793L15.4707 17.202ZM32.0179 33H26.1358L16.6669 12.289C16.5864 12.113 16.4125 12 16.222 12H12.37L12.3749 7H19.9237L29.3484 27.71C29.4289 27.887 29.6037 28 29.7943 28H32.0179V33ZM32.5089 27H30.1086L20.6829 6.29C20.6024 6.113 20.4276 6 20.237 6H11.8839C11.6128 6 11.3928 6.224 11.3928 6.5L11.3869 12.5C11.3869 12.632 11.439 12.759 11.5313 12.854C11.6236 12.947 11.7474 13 11.878 13H15.9097L25.3776 33.711C25.4581 33.887 25.6319 34 25.8235 34H32.5089C32.781 34 33 33.776 33 33.5V27.5C33 27.224 32.781 27 32.5089 27Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_14085"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Lambda Functions",
);
