import { Sources, SourcesProps } from "./sources";
import { Cve } from "~/operations";

export type CveSourcesProps = {
  url: Cve["url"];
  source: Pick<Cve["source"], "name" | "url">;
  references: Cve["references"];
  id?: string;
};

export function CveSources({ url, source, references, id }: CveSourcesProps) {
  const CISA_URL_BASE =
    "https://www.cisa.gov/known-exploited-vulnerabilities-catalog";
  const META_URL_BASE = "https://www.rapid7.com";
  const refs = references?.flatMap((r) => r ?? []) || [];

  const mainSourceUrl = url || source.url;
  const cisaSourceUrl = refs.find((r) => r.startsWith(CISA_URL_BASE));
  const metaSourceUrl = refs.find((r) => r.startsWith(META_URL_BASE));

  const mainSourceLink = {
    href: mainSourceUrl,
    text: source.name,
  };
  const cisaSourceLink = cisaSourceUrl
    ? {
        href: cisaSourceUrl,
        text: "CISA Known Exploited Vulnerability (KEV) Database",
      }
    : undefined;
  const metaSourceLink = metaSourceUrl
    ? { href: metaSourceUrl, text: "Metasploit" }
    : undefined;

  const sourceLinks: SourcesProps["links"] = [
    mainSourceLink,
    cisaSourceLink,
    metaSourceLink,
  ].flatMap((link) => link ?? []);

  return <Sources id={id} links={sourceLinks} />;
}
