import { FC } from "react";
import { useToken } from "~/providers/token";
import { Button, styled } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Gauge, oneColorGaugeData } from "~/components/gauges/gauge";
import { Code } from "~/components/code";

export type TokenTimerWidgetProps = {
  minimized?: boolean;
  disabled?: boolean;
};

export function TokenTimerWidget({
  minimized,
  disabled,
}: TokenTimerWidgetProps) {
  const { token, tokenTimeLeft, tokenTimeTotal, refreshToken } = useToken();

  if (minimized) {
    return <TokenTimer timeLeft={tokenTimeLeft} timeTotal={tokenTimeTotal} />;
  }

  return (
    <FlexLine style={{ height: "100%" }}>
      {/* min-width required because: https://weblog.west-wind.com/posts/2016/feb/15/flexbox-containers-pre-tags-and-managing-overflow */}
      <div style={{ flexGrow: 99, minWidth: "0" }}>
        <Code copyButton className="shell">
          {token.value}
        </Code>
      </div>
      <FlexLine style={{ flexGrow: 1 }}>
        <LineItem>
          <TokenTimer timeLeft={tokenTimeLeft} timeTotal={tokenTimeTotal} />
        </LineItem>
        <LineItem>
          <Button
            disabled={disabled}
            onClick={refreshToken}
            data-name="refresh-token-timer"
          >
            Refresh
          </Button>
        </LineItem>
      </FlexLine>
    </FlexLine>
  );
}

type TokenTimerProps = {
  timeLeft?: number;
  timeTotal?: number;
};

const TokenTimer: FC<TokenTimerProps> = ({ timeLeft = 0, timeTotal = 1 }) => {
  const theme = useTheme();
  const left = timeLeft.toFixed(0);
  const data = oneColorGaugeData(
    timeLeft,
    timeTotal,
    theme.palette.good.main,
    theme.palette.background.light,
  );

  let label = "..",
    tooltip = "Waiting for token";
  if (timeLeft != null && timeTotal != null) {
    label = left;
    tooltip = left + "s left until token expires";
  }

  return <Gauge data={data} label={label} tooltip={tooltip} size={44} />;
};

const FlexLine = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LineItem = styled("div")`
  margin-left: ${(p) => p.theme.spacing(3)};
`;
