import { FormEvent, Fragment } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom";
import { Box, Typography, Link, FormHelperText } from "@mui/material";
import { motion } from "framer-motion";
import { useAuth } from "../../../login/provider";
import { InfoOutlinedIcon, WarningAmberIcon } from "~/components/icons";
import { AuthTextField } from "../../components/auth-text-field";
import { ContinueButton } from "../../components/continue-button";
import { Hero } from "./components/background";
import { MondooLogoHorizontal } from "~/images/icons";
import { AuthErrorsComponent } from "./components/auth-errors";

interface IFormInputs {
  ssoOrgId: string;
}

export function SSOComponent() {
  const { state, dispatch, loginWithSSO } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IFormInputs>({
    mode: "onChange",
    defaultValues: {
      ssoOrgId: "",
    },
  });

  function handleLoginWithSSO(data: IFormInputs, event: FormEvent) {
    const orgId = data.ssoOrgId.trim();
    loginWithSSO(event, dispatch, orgId);
  }

  return (
    <Hero>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MondooLogoHorizontal width={180} />
        </Box>
        <Box
          component={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          sx={{
            width: 1,
            maxWidth: 380,
            minWidth: 330,
            px: "30px",
            py: "40px",
          }}
        >
          <form
            data-name="form-login-with-sso"
            onSubmit={handleSubmit((data, event) =>
              handleLoginWithSSO(data, event as FormEvent),
            )}
          >
            <Controller
              name="ssoOrgId"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <AuthTextField
                  {...field}
                  id="ssoOrgId"
                  placeholder="Enter your Organization ID..."
                  error={Boolean(errors.ssoOrgId)}
                />
              )}
            />
            <Box minHeight={64}>
              {errors.ssoOrgId && (
                <FormHelperText
                  sx={{ color: (theme) => theme.palette.error.main }}
                >
                  <Fragment>
                    <WarningAmberIcon
                      sx={{
                        mr: 0.5,
                        fontSize: 14,
                        verticalAlign: "text-bottom",
                      }}
                    />
                    <Typography variant="caption" component="span">
                      Organization ID is required
                    </Typography>
                  </Fragment>
                </FormHelperText>
              )}
              <FormHelperText sx={{ display: "flex" }}>
                <Fragment>
                  <InfoOutlinedIcon
                    sx={{
                      mr: 0.5,
                      mt: 0.25,
                      fontSize: 14,
                      verticalAlign: "text-bottom",
                    }}
                  />
                  <Typography variant="caption" component="span">
                    Your Mondoo admin should have sent you your organization ID.
                    If you don't have it, please contact them.
                  </Typography>
                </Fragment>
              </FormHelperText>
            </Box>
            <ContinueButton
              type="submit"
              content="continue"
              loading={state.status === "pending"}
              disabled={!isValid}
            />
          </form>

          <Typography
            sx={{ mt: 10, textAlign: "center", color: "text.secondary" }}
          >
            Don't have SSO?{" "}
            <Link
              sx={{ color: "text.secondary", textDecoration: "underline" }}
              component={RouterLink}
              to="/login"
            >
              View all login options
            </Link>
          </Typography>
        </Box>
      </Box>
      <AuthErrorsComponent />
    </Hero>
  );
}
