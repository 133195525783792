import { styled } from "@mui/material";
import { MaterialDesignContent, SnackbarProviderProps } from "notistack";

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    "&.notistack-MuiContent-success": {
      backgroundColor: theme.palette.excellent.main,
    },
    "&.notistack-MuiContent-error": {
      backgroundColor: theme.palette.error.main,
    },
    "&.notistack-MuiContent-warning": {
      backgroundColor: theme.palette.warning.main,
    },
    "&.notistack-MuiContent-info": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
);

export const SnackbarComponents: SnackbarProviderProps["Components"] = {
  success: StyledMaterialDesignContent,
  error: StyledMaterialDesignContent,
  warning: StyledMaterialDesignContent,
  info: StyledMaterialDesignContent,
};
