import { createSvgIcon } from "@mui/material";

export const ArrayIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g clipPath="url(#clip0_2655_20420)">
      <path d="M19.3313 7.46269H16.2746V4.40597C16.2746 4.31816 16.2573 4.23121 16.2237 4.15009C16.1901 4.06896 16.1409 3.99525 16.0788 3.93316C16.0167 3.87107 15.943 3.82181 15.8619 3.78821C15.7807 3.75461 15.6938 3.73731 15.606 3.73731H12.5373V0.668657C12.5373 0.580847 12.52 0.493898 12.4864 0.412773C12.4528 0.331648 12.4036 0.257936 12.3415 0.195845C12.2794 0.133754 12.2057 0.0845016 12.1245 0.0508985C12.0434 0.0172953 11.9565 0 11.8687 0H0.668657C0.491318 0 0.321242 0.0704475 0.195845 0.195845C0.0704475 0.321242 0 0.491318 0 0.668657L0 11.8687C0 11.9565 0.0172953 12.0434 0.0508985 12.1245C0.0845016 12.2057 0.133754 12.2794 0.195845 12.3415C0.257936 12.4036 0.331648 12.4528 0.412773 12.4864C0.493898 12.52 0.580847 12.5373 0.668657 12.5373H3.73731V15.594C3.73731 15.7714 3.80776 15.9414 3.93316 16.0668C4.05856 16.1922 4.22863 16.2627 4.40597 16.2627H7.46269V19.3313C7.46269 19.4192 7.47998 19.5061 7.51358 19.5872C7.54719 19.6684 7.59644 19.7421 7.65853 19.8042C7.72062 19.8662 7.79433 19.9155 7.87546 19.9491C7.95658 19.9827 8.04353 20 8.13134 20H19.3313C19.5087 20 19.6788 19.9296 19.8042 19.8042C19.9296 19.6788 20 19.5087 20 19.3313V8.13134C20 7.954 19.9296 7.78393 19.8042 7.65853C19.6788 7.53313 19.5087 7.46269 19.3313 7.46269ZM3.73731 4.40597V11.2H1.34925V1.33731H11.2V3.72537H4.40597C4.31715 3.72536 4.22921 3.74304 4.1473 3.77739C4.06538 3.81174 3.99114 3.86206 3.92889 3.92542C3.86664 3.98878 3.81764 4.06391 3.78474 4.14642C3.75185 4.22893 3.73573 4.31716 3.73731 4.40597ZM7.46269 8.13134V14.9373H5.07463V5.06269H14.9373V7.45075H8.13134C8.04252 7.45073 7.95458 7.46841 7.87267 7.50276C7.79076 7.53711 7.71651 7.58743 7.65426 7.65079C7.59201 7.71415 7.54301 7.78928 7.51012 7.87179C7.47722 7.9543 7.4611 8.04253 7.46269 8.13134ZM18.6627 18.6627H8.8V8.8H18.6627V18.6627Z" />
    </g>
    <defs>
      <clipPath id="clip0_2655_20420">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </svg>,
  "Array",
);
