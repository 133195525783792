import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Tab,
  Tabs,
  TabsProps,
  Typography,
} from "@mui/material";
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import {
  ActionType,
  GetClientIntegrationQuery,
  GetIntegrationsSummaryDocument,
  TestIamActionsQuery,
  useDeleteClientIntegrationMutation,
  useGetClientIntegrationQuery,
  useListDiscoveryResultsQuery,
  usePingIntegrationLazyQuery,
  useTriggerActionLazyQuery,
} from "~/operations";
import { AwsAccountHeader } from "./aws-account-header-hosted";
import { useState } from "react";
import { RecommendedPolicies } from "../../components/recommended-policies";
import { SpacePagesIntegrations } from "~/pages/routes";
import { AWSOverviewAccountTab } from "~/components/aws-overview-account-tab";
import { AWSIntegrationStatus } from "./integration-aws-account-serverless";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "~/components/ConfirmationDialog";

export type ClientIntegration = GetClientIntegrationQuery["clientIntegration"];
export type Integration = ClientIntegration["integration"];
export type ConfigurationOptions = NonNullable<
  Integration["configurationOptions"]
>;
export type AwsConfigurationOptions = Extract<
  ConfigurationOptions,
  { __typename: "HostedAwsConfigurationOptions" }
>;
export type AwsIntegration = Integration & {
  configurationOptions: AwsConfigurationOptions;
};

export type AwsIntegrationHostedPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

type AwsIntegrationServerlessPageState = {
  currentTab: string;
  openDeleteConfirmDialog: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  lastUpdate: number;
};

export function AwsIntegrationHostedPage({
  space,
  availablePermissions,
}: AwsIntegrationHostedPageProps) {
  const navigate = useNavigate();
  const { integrationId = "" } = useParams();
  const [searchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const defaultTab = "overview";
  const tab = window.location.pathname.split("/").pop() || defaultTab;
  const currentTab = ["overview", "policies", "configuration"].includes(tab)
    ? tab
    : defaultTab;

  const [state, setState] = useState<AwsIntegrationServerlessPageState>({
    currentTab,
    openDeleteConfirmDialog: false,
    isDeleting: false,
    isEditing: false,
    lastUpdate: Date.now(),
  });

  function mergeState(params: any) {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  }

  const hasIntegrationsGetPermission = availablePermissions.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_GET,
  );
  const hasIntegrationsDiscoveryPermission = availablePermissions.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_LISTDISCOVERYRESULTS,
  );
  const hasIntegrationUpdatePermission = availablePermissions?.includes(
    IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_UPDATE,
  );
  const hasPolicyAssignPermission = [
    IamActions.POLICY_DISTRIBUTOR_ASSIGN,
    IamActions.CNSPEC_POLICY_POLICYRESOLVER_ASSIGN,
  ].some((permission) => availablePermissions?.includes(permission));

  const mrn = searchParams.get("integrationMrn");

  if (!mrn) {
    return <LoadingFailedPage what="AWS Integration" />;
  }

  const clientIntegrationResult = useGetClientIntegrationQuery({
    variables: { mrn },
    pollInterval: 10000,
    fetchPolicy: "network-only",
    skip: !hasIntegrationsGetPermission,
  });

  const listDiscoveryResultsResult = useListDiscoveryResultsQuery({
    variables: { input: { mrn } },
    pollInterval: 10000,
    fetchPolicy: "network-only",
    skip: !hasIntegrationsDiscoveryPermission,
  });

  const [deleteClientIntegration] = useDeleteClientIntegrationMutation({
    refetchQueries: [GetIntegrationsSummaryDocument],
  });

  const [pingClientIntegration] = usePingIntegrationLazyQuery({
    variables: { input: { mrn } },
  });

  const [triggerClientIntegrationScan] = useTriggerActionLazyQuery({
    variables: { input: { mrn, type: ActionType.RunScan } },
    fetchPolicy: "no-cache",
  });

  if (clientIntegrationResult.loading || listDiscoveryResultsResult.loading) {
    return <LoadingPage what="AWS Integration" />;
  }

  const integration = clientIntegrationResult.data?.clientIntegration
    .integration as AwsIntegration;
  const discoveryResults =
    listDiscoveryResultsResult.data?.listDiscoveryResults;

  if (
    clientIntegrationResult.error ||
    integration?.configurationOptions?.__typename !==
      "HostedAwsConfigurationOptions"
  ) {
    return <LoadingFailedPage what="AWS Integration" />;
  }

  const tabs = ["overview"];
  const tabNames = ["Overview"];

  if (hasPolicyAssignPermission) {
    tabs.push("policies");
    tabNames.push("Recommended Policies");
  }

  const handleTabChange: TabsProps["onChange"] = (e, value) => {
    const currentTab = tabs[value];
    const newUrl = `/space/${SpacePagesIntegrations}/aws/${integrationId}/${currentTab}?spaceId=${space.id}&integrationMrn=${mrn}`;
    navigate(newUrl);
  };

  const deleteIntegrationBegin = () => {
    mergeState({ openDeleteConfirmDialog: true });
  };

  const handleDeleteAgentClose = () => {
    mergeState({ isDeleting: false, openDeleteConfirmDialog: false });
  };

  const handleDeleteAgentConfirm = async () => {
    mergeState({ isDeleting: true });
    try {
      await deleteClientIntegration({
        variables: { input: { mrn: integration.mrn } },
      });
      // Display success msg
      enqueueSnackbar("Successfully removed integration.", {
        variant: "success",
      });
      // Nav back to list view
      navigate(`/space/${SpacePagesIntegrations}/aws?spaceId=${space.id}`);
    } catch (error) {
      enqueueSnackbar("Failed to remove integration.", { variant: "error" });
    } finally {
      mergeState({ isDeleting: false, openDeleteConfirmDialog: false });
    }
  };

  const triggerScan = async () => {
    try {
      await triggerClientIntegrationScan();
      enqueueSnackbar("Successfully triggered scan.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to trigger scan.", { variant: "error" });
    }
  };

  const ping = async () => {
    try {
      await pingClientIntegration();
      enqueueSnackbar("Successfully pinged AWS.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar("Failed to ping AWS.", { variant: "error" });
    }
  };

  const discoveredAssets =
    discoveryResults?.stats
      ?.flatMap((asset) => asset ?? [])
      .sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return 1;
        return 0;
      }) || [];

  let content = null;
  switch (currentTab) {
    case "overview":
      content = (
        <AWSOverviewAccountTab
          {...{
            space,
            integration,
            integrationMrn: mrn,
            discoveredAssets,
            integrationStatus: integration.status as AWSIntegrationStatus,
          }}
        />
      );
      break;
    case "policies":
      content = <RecommendedPolicies {...{ space }} filterTypes={["aws"]} />;
      break;
  }

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key="/space/integrations/aws"
      component={RouterLink}
      to={`/space/integrations/aws?spaceId=${space.id}`}
    >
      AWS
    </Link>,
    <Typography key="/space/integrations/aws/detail">
      {integration.name}
    </Typography>,
  ];

  document.title = `${integration.name} · AWS · Mondoo`;

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid item xs={12} sm={12}>
        <AwsAccountHeader
          actions={{
            triggerScan,
            ping,
            deleteIntegration: deleteIntegrationBegin,
          }}
          {...{
            mrn,
            space,
            availablePermissions,
            awsIntegration: integration,
            integrationId,
          }}
        />
      </Grid>
      <Grid item xs={12} mt={4} mb={3}>
        <Box
          sx={{
            position: "relative",
            mb: 0,
            "&:after": {
              content: "''",
              position: "absolute",
              bottom: 0,
              left: 0,
              width: 1,
              height: 4,
              backgroundColor: "background.light",
            },
          }}
        >
          <Tabs
            variant="scrollable"
            scrollButtons="auto"
            value={tabs.indexOf(currentTab)}
            onChange={handleTabChange}
            sx={[
              {
                ".MuiTabs-indicator": {
                  height: 4,
                  zIndex: 1,
                },
              },
            ]}
          >
            {tabs.map((tab, i) => {
              return (
                <Tab
                  key={i}
                  label={tabNames[i]}
                  data-name={`integration-aws-tab-${tabNames[i]}`}
                  sx={{
                    fontSize: 14,
                    fontWeight: 400,
                    color: "text.secondary",
                    textTransform: "capitalize",
                  }}
                />
              );
            })}
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {content}
      </Grid>
      <ConfirmationDialog
        isOpen={state.openDeleteConfirmDialog}
        onConfirm={handleDeleteAgentConfirm}
        onClose={handleDeleteAgentClose}
        loading={state.isDeleting}
        confirmButtonText="Delete"
        title={`Remove integration "${integration.name}"`}
        content={
          <Typography
            variant="body2"
            sx={{
              mt: -3,
              color: "text.main",
            }}
          >
            Once you delete an integration from Mondoo, all related data will be
            deleted alongside it. You can add the integration again, but any
            historical data will be lost.
          </Typography>
        }
      />
    </Box>
  );
}
