import { createSvgIcon } from "@mui/material";

export const ContainersGradientIcon = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.9833 30.9L7.7 21.35L5 23.45L20 35.1167L35 23.45L32.2833 21.3333L19.9833 30.9V30.9ZM20 26.6667L32.2667 17.1167L35 15L20 3.33334L5 15L7.71667 17.1167L20 26.6667Z"
      fill="url(#paint0_linear_7191_75164)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7191_75164"
        x1="5"
        y1="3.33334"
        x2="37.4182"
        y2="32.4217"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Containers",
);
