import { Link as RouterLink } from "react-router-dom";
import { ControlResultIcon } from "~/components/ui-library";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { Space } from "~/lib/types";
import { MQueryAssetScore } from "~/pages/compliance/control/control-outlets/data-queries/data-query";

type DataQueryGridViewProps = {
  assets: MQueryAssetScore[];
  space: Space;
  queryMrn: string;
};

export function DataQueryGridView({
  assets,
  space,
  queryMrn,
}: DataQueryGridViewProps) {
  return (
    <Grid container item spacing={3}>
      {assets.map((asset) => {
        const assetId = asset?.assetMrn?.split("/").pop();
        const to = `/space/inventory/${assetId}/data-queries?spaceId=${space.id}&queryMrn=${queryMrn}`;
        return (
          <Grid item xs={12} md={6} lg={4} key={asset?.assetMrn}>
            <Paper
              {...(to && { component: RouterLink, to: to })}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                p: 2,
              }}
            >
              <Box sx={{ width: 1 }}>
                <Typography
                  sx={{
                    display: "block",
                    fontWeight: 700,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: { xs: 200, md: 300, lg: 240 },
                  }}
                >
                  {asset?.assetName}
                </Typography>
                <Typography sx={{ fontSize: 12, color: "text.secondary" }}>
                  {asset?.assetPlatform?.name} {asset?.assetPlatform?.version}
                </Typography>
              </Box>
              <Box>
                <ControlResultIcon status={"data"} size="lg" />
              </Box>
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
}
