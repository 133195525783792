import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { ApolloError } from "@apollo/client";
import { useViewer } from "~/providers/viewer";
import { useNavigate } from "react-router-dom";
import {
  useCreateOrganizationMutation,
  useOrganizationIdSuggestionLazyQuery,
} from "~/operations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { Checkbox, FormControlLabel, Grid2 as Grid, Link } from "@mui/material";
import { LoadingButton } from "~/components/loading-button";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateFormInput } from "~/pages/space/Workspaces/components/FormFields/types";
import { IdField } from "~/pages/space/Workspaces/components/FormFields/IdField";
import { DisplayNameField } from "~/pages/space/Workspaces/components/FormFields/DisplayNameField";
import { DescriptionField } from "~/pages/space/Workspaces/components/FormFields/DescriptionField";

export type CreateOrganizationProps = {};

const ORGANIZATION_ID_UNAVAILABLE = "This organization id is not available";

export function CreateOrganization({}: CreateOrganizationProps) {
  const { viewer, refetch } = useViewer();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const hasCreateOrganizationPermissions = true;

  const [
    getOrganizationIdSuggestion,
    {
      data: organizationIdSuggestion,
      refetch: refetchOrganizationIdSuggestion,
    },
  ] = useOrganizationIdSuggestionLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [createOrganization, { loading: isCreateOrganizationLoading }] =
    useCreateOrganizationMutation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setError,
    formState: { errors, isValid },
  } = useForm<CreateFormInput>({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      id: "",
      tac: false,
    },
  });

  useEffect(() => {
    const values = getValues();

    reset({
      ...values,
      id: organizationIdSuggestion?.organizationIDSuggestion?.id || "",
    });

    if (
      organizationIdSuggestion?.organizationIDSuggestion?.available !== true
    ) {
      setError("id", { message: ORGANIZATION_ID_UNAVAILABLE });
    }
  }, [organizationIdSuggestion]);

  const getIdSuggestion = async (id = "") => {
    // ID needs to be hyphenated to be valid
    const idAvailable = id.trim().split(" ").join("-").toLowerCase();
    const suggestion = await refetchOrganizationIdSuggestion({
      input: { idAvailable },
    });

    return suggestion?.data?.organizationIDSuggestion;
  };

  useEffect(() => {
    getOrganizationIdSuggestion({
      variables: {
        input: { idAvailable: "" },
      },
    });
  }, []);

  const handleFormSubmit: SubmitHandler<CreateFormInput> = async (values) => {
    try {
      const id = values.id.trim().split(" ").join("-").toLowerCase();
      const name = values.name;
      const description = values.description || "";

      const createOrganizationResult = await createOrganization({
        variables: {
          input: {
            name,
            id,
            description,
          },
        },
      });
      const createdOrganization =
        createOrganizationResult.data?.createOrganization;

      if (!createdOrganization)
        throw new Error("Failed to create organization");

      enqueueSnackbar(`Successfully created organization: ${name}`, {
        variant: "success",
      });
      // we have to wait for viewer and settings to load again
      // before we can navigate to the new space
      await refetch();
      navigate(`/organization/spaces?organizationId=${createdOrganization.id}`);
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.map((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
      } else {
        enqueueSnackbar("Failed to create organization", { variant: "error" });
      }
    }
  };

  if (!viewer) {
    return <LoadingPage what="organizations" />;
  }

  if (viewer.organizations?.length === 0) {
    return <LoadingFailedPage what="organization" />;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container size={12} gap={5}>
        <IdField
          errors={errors}
          register={register}
          idSuggestion={organizationIdSuggestion?.organizationIDSuggestion}
          onGenerateId={getIdSuggestion}
          unavailableErrorMessage={ORGANIZATION_ID_UNAVAILABLE}
          canEdit
          target="organization"
        />

        <DisplayNameField
          errors={errors}
          register={register}
          target="organization"
        />

        <DescriptionField register={register} target="organization" />

        <Grid size={12} textAlign="center">
          <FormControlLabel
            control={
              <Checkbox
                id="policy-accept-input"
                {...register("tac", { required: true })}
              />
            }
            label={
              <p>
                I have read and agree to the Mondoo{" "}
                <Link
                  href="https://mondoo.com/terms-of-service"
                  target="_blank"
                  rel="noopener"
                  sx={{ color: "inherit", textDecoration: "underline" }}
                >
                  Terms of Service
                </Link>{" "}
                and the{" "}
                <Link
                  href="https://mondoo.com/privacy-policy"
                  target="_blank"
                  rel="noopener"
                  sx={{ color: "inherit", textDecoration: "underline" }}
                >
                  Privacy Policy
                </Link>
              </p>
            }
          />
        </Grid>

        <Grid size={{ xs: 12 }} style={{ textAlign: "center" }}>
          <LoadingButton
            type="submit"
            buttonText="Create Organization"
            variant="contained"
            color="primary"
            disabled={!isValid || !hasCreateOrganizationPermissions}
            loading={isCreateOrganizationLoading}
          />
        </Grid>
      </Grid>
    </form>
  );
}
