import { TableCell, Checkbox, CheckboxProps } from "@mui/material";

export function CheckboxCell({
  onClick,
  onChange,
  checked,
}: {
  onClick: CheckboxProps["onClick"];
  onChange: CheckboxProps["onChange"];
  checked: boolean;
}) {
  return (
    <TableCell width={50}>
      <Checkbox {...{ onClick, onChange, checked }} />
    </TableCell>
  );
}
