import { TableCell, Typography } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { AggregateScoresNode } from "../FirewatchPage";

type PolicyNameCellProps = { row: AggregateScoresNode; size?: string };

export function PolicyNameCell({ row }: PolicyNameCellProps) {
  return (
    <TableCell>
      <Clamp>
        <Typography sx={{ fontSize: 14, fontWeight: 700, lineHeight: "16px" }}>
          {row?.title || row?.nodeTitle}
        </Typography>
      </Clamp>
    </TableCell>
  );
}
