import { Typography } from "@mui/material";

export const FormattedDestination = ({
  projectKey,
  projectTitle,
}: {
  projectKey: string;
  projectTitle: string;
}) => {
  return (
    <Typography>
      {projectTitle}{" "}
      <Typography component="span" variant="caption" color="text.secondary">
        ({projectKey})
      </Typography>
    </Typography>
  );
};
