import { Box, TableCell, Typography } from "@mui/material";
import { Format, FormatRelativeDate } from "~/lib/date";
import { CommonCellProps } from "~/pages/inventory/components/AssetSoftware/types";

export const FirstFoundCell = ({ pckg, ...props }: CommonCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontSize={14}>
          {FormatRelativeDate(String(pckg.firstFound))}
        </Typography>
        <Typography variant="caption" color="text.secondary" fontSize={12}>
          {Format(pckg.firstFound)}
        </Typography>
      </Box>
    </TableCell>
  );
};
