import { useState } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import {
  OrderDirection,
  ServiceAccountOrderField,
  useServiceAccountForwardPaginationQuery,
} from "~/operations";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import { Loading, LoadingFailed } from "~/components/loading";
import { ArrowForwardIcon, VerifiedIcon } from "~/components/icons";
import { INITIAL_PAGE_RANGE, Pagination } from "~/components/pagination";
import { ControlResultIcon } from "~/components/control-result-icon";
import { FormatRelativeDateAbbreviated, formatLastUsed } from "~/lib/date";
import { ServiceAccountNoPermissions } from "./service-account-no-permissions";

type Props = {
  space: Space;
  mrn: string;
  availablePermissions: string[];
};
export function NestedServiceAccountsSection({
  space,
  mrn,
  availablePermissions,
}: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [pageItems, setPageItems] = useState(INITIAL_PAGE_RANGE);
  const [direction, setDirection] = useState<OrderDirection>(
    (searchParams.get("orderBy") as OrderDirection) || OrderDirection.Desc,
  );

  // Permissions to create/edit/set service accounts determine if
  // the user is even able to see the full list in settings.
  const hasCreateAgentPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_CREATEAGENT,
  );
  const hasDeleteAgentsPermissions = availablePermissions?.includes(
    IamActions.AGENTMANAGER_DELETEAGENTS,
  );
  const hasSetAgentMembershipPermission = availablePermissions?.includes(
    IamActions.AGENTMANAGER_SETSERVICEACCOUNTMEMBERSHIP,
  );

  const { data, loading, error, fetchMore } =
    useServiceAccountForwardPaginationQuery({
      skip: !hasCreateAgentPermission,
      variables: {
        spaceMrn: space.mrn,
        queryTerms: [mrn],
        orderBy: {
          field: ServiceAccountOrderField.CreatedAt,
          direction: direction,
        },
        first: 50,
      },
    });

  if (!hasCreateAgentPermission) {
    return <ServiceAccountNoPermissions />;
  }

  if (error) {
    return (
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        <LoadingFailed what="service accounts" />
      </Paper>
    );
  }

  if (loading || !data?.serviceAccounts?.edges) {
    return (
      <Paper
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
        }}
      >
        <Loading what="service accounts" />
      </Paper>
    );
  }

  const { edges } = data.serviceAccounts;

  const handleSort = () => {
    const newDirection =
      direction === OrderDirection.Desc
        ? OrderDirection.Asc
        : OrderDirection.Desc;

    setDirection(newDirection);
    setSearchParams({ orderBy: newDirection, spaceId: space.id });
  };

  const getServiceAccountsUrl = () => {
    searchParams.set("queryTerms", mrn);
    return `/space/settings/serviceaccounts?${searchParams}`;
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Typography variant="h5" component="h2" fontWeight={700}>
          Service Accounts
        </Typography>
        {/* only if they have permissions */}
        {(hasCreateAgentPermission ||
          hasDeleteAgentsPermissions ||
          hasSetAgentMembershipPermission) &&
          edges.length > 0 && (
            <Button
              color="secondary"
              component={RouterLink}
              to={getServiceAccountsUrl()}
              endIcon={<ArrowForwardIcon />}
            >
              Show In Service Accounts
            </Button>
          )}
      </Box>
      <TableContainer component={Paper}>
        {edges.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: 3,
              py: 4,
            }}
          >
            <Typography>
              You have no service accounts associated with this integration
            </Typography>
          </Box>
        ) : (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <VerifiedIcon fontSize="small" />
                  </Box>
                </TableCell>
                {tableHeaders.map((header) => (
                  <TableCell
                    key={header.id}
                    sx={{ ...header.options }}
                    {...(header.colSpan ? { colSpan: header.colSpan } : {})}
                    {...(header.sortable
                      ? {
                          sortDirection:
                            direction === OrderDirection.Desc ? "desc" : "asc",
                        }
                      : {})}
                  >
                    {header.sortable ? (
                      <TableSortLabel
                        onClick={handleSort}
                        direction={
                          direction === OrderDirection.Desc ? "desc" : "asc"
                        }
                        active={true}
                      >
                        {header.label}
                      </TableSortLabel>
                    ) : (
                      header.label
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {edges.slice(pageItems.from, pageItems.to).map((edge) => (
                <TableRow
                  key={edge.node?.id}
                  sx={{
                    "&:hover": {
                      background: "inherit",
                    },
                  }}
                >
                  <TableCell align="center">
                    <ControlResultIcon status="pass" size="sm" />
                  </TableCell>
                  <TableCell>{edge.node?.id}</TableCell>
                  <TableCell>{edge.node?.roles[0].title}</TableCell>
                  <TableCell>
                    {FormatRelativeDateAbbreviated(edge.node?.createdAt)}
                  </TableCell>
                  <TableCell>{formatLastUsed(edge.node?.lastUsed)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
        <Pagination
          fetchMore={fetchMore}
          pageInfo={data?.serviceAccounts?.pageInfo}
          setPageItems={setPageItems}
          totalCount={data?.serviceAccounts.totalCount}
        />
      </TableContainer>
    </Box>
  );
}

//////// Table Configuration
type Header = {
  id: string;
  label: string;
  colSpan?: number;
  sortable?: boolean;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};

const tableHeaders: Header[] = [
  {
    id: "KEY_ID",
    label: "Key ID",
  },
  {
    id: "AGENT_ROLE",
    label: "Agent Role",
  },
  { id: "CREATED_AT", label: "Created", sortable: true },
  {
    id: "LAST_USED",
    label: "Last Used",
    colSpan: 2,
  },
];
