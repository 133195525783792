import { createSvgIcon } from "@mui/material";

export const AixIcon = createSvgIcon(
  <svg
    version="1.1"
    viewBox="0 0 34.66 13.79"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <defs>
      <linearGradient
        id="vlpb"
        x1=".1521"
        x2=".5468"
        y1="-.5875"
        y2="-.4003"
        gradientTransform="matrix(70.15 -70.15 70.15 70.15 31.51 66.85)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6fdc8c" offset="0" />
        <stop stopColor="#6fdc8c" offset=".3049" />
        <stop stopColor="#009d9a" offset=".9279" />
        <stop stopColor="#009d9a" offset="1" />
      </linearGradient>
    </defs>
    <g transform="matrix(.9865 0 0 .9865 -.9241 -.976)">
      <g transform="scale(.9942 1.006)" fill="url(#vlpb)" strokeWidth=".7367">
        <path
          d="m10.91 14.79-0.9823-3.261h-4.578l-0.9823 3.261h-3.025l4.519-13.71h3.693l4.46 13.71zm-3.242-11h-0.09823l-1.513 5.206h3.143z"
          fill="url(#vlpb)"
          strokeWidth=".7367"
        />
        <path
          d="m15.39 14.79v-2.377h1.768v-8.959h-1.768v-2.377h6.522v2.377h-1.768v8.959h1.768v2.377z"
          fill="url(#vlpb)"
          strokeWidth=".7367"
        />
        <path
          d="m35.8 14.79h-3.458l-2.967-5.049h-0.05894l-2.888 5.049h-3.222l4.401-7.092-4.185-6.621h3.477l2.672 4.636h0.05894l2.711-4.636h3.222l-4.224 6.68z"
          fill="url(#vlpb)"
          strokeWidth=".7367"
        />
      </g>
    </g>
  </svg>,
  "AIX",
);
