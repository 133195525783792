import { ReactNode } from "react";
import {
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Flex, Route } from "~/components/ui-library";
import { ExpandMoreIcon } from "~/components/icons";

export type CollapsibleListProps = {
  id: string;
  title: string;
  children: ReactNode;
  icon?: ReactNode;
  total?: number;
  onNavigate?: (value: Route["href"]) => void;
};

// Move to Shared Component Library

const CollapsibleList = ({
  id,
  title,
  icon,
  children,
  total,
  onNavigate,
}: CollapsibleListProps) => {
  return (
    <Box
      component={Paper}
      sx={(theme) => ({
        width: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
        borderRadius: 1,
      })}
    >
      <Accordion
        defaultExpanded
        sx={{
          backgroundColor: "#333333",
        }}
      >
        <AccordionSummary
          id={id.split(" ").join("-") + "-accordion-summary"}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={id.split(" ").join("-") + "-accordion-content"}
        >
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: 700,
              textTransform: "uppercase",
            }}
          >
            {icon ? (
              <Flex mr={1} component="span">
                {icon}
              </Flex>
            ) : null}
            {title}
            {total ? (
              <Typography
                component="span"
                sx={{ ml: 0.5, color: "text.secondary", fontWeight: 700 }}
              >
                ({total})
              </Typography>
            ) : null}
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default CollapsibleList;
