import { createSvgIcon } from "@mui/material";

export const PackageIcon = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.6365 37.5516L4.95312 28.7349V11.1016L20.6365 19.9182V37.5516Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6328 37.5516L36.3328 28.7349V11.1016L20.6328 19.9182V37.5516Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.3365 11.0005L20.5365 20.0005L4.95312 11.1005L20.5365 2.86719L36.3365 11.0005Z"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.4297 15.1828L27.863 6.63281"
      stroke="currentColor"
      strokeWidth="1.3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M36.7845 10.9851C36.7852 10.7818 36.7295 10.5822 36.6235 10.4086C36.5176 10.2351 36.3657 10.0942 36.1845 10.0018L20.4012 1.90179C20.2472 1.82007 20.0755 1.77734 19.9012 1.77734C19.7269 1.77734 19.5552 1.82007 19.4012 1.90179L3.80121 10.1351C3.71314 10.1895 3.63896 10.2637 3.58454 10.3518C3.51725 10.3942 3.4603 10.4512 3.41787 10.5185C3.35997 10.6207 3.32579 10.7346 3.31787 10.8518C3.31071 10.9183 3.31071 10.9853 3.31787 11.0518V28.7351C3.32157 28.9271 3.37398 29.115 3.47018 29.2812C3.56638 29.4473 3.70323 29.5863 3.86787 29.6851L19.5679 38.5018C19.732 38.5893 19.9152 38.6351 20.1012 38.6351C20.2872 38.6351 20.4704 38.5893 20.6345 38.5018L36.3345 29.6851C36.4992 29.5863 36.636 29.4473 36.7322 29.2812C36.8284 29.115 36.8808 28.9271 36.8845 28.7351V10.9851H36.7845ZM22.8345 17.0851L20.0012 18.6685L13.9179 15.3351L27.2512 7.95179L33.4179 11.1351L22.8345 17.0851ZM19.9012 4.08512L25.0012 6.66846L11.6679 14.0018L6.56787 11.1351L19.9012 4.08512ZM5.38454 12.9685L18.9179 20.6851V35.6851L5.38454 28.1018V12.9685ZM34.6179 28.1018L21.0845 35.7018V20.5685L23.9179 18.9018L34.6179 12.9018V28.1018Z"
      fill="currentColor"
    />
  </svg>,
  "Package",
);
