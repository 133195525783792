import { useNavigate, useSearchParams } from "react-router-dom";
import React from "react";
import { PackageType } from "~/operations";
import { PackageTypes } from "~/pages/inventory/components/AssetSoftware/types";

type UseFilterPackagesParams = {
  packagesCount: number;
  applicationsCount: number;
};

export function useFilterPackages({
  packagesCount,
  applicationsCount,
}: UseFilterPackagesParams) {
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();

  const [filter, setFilter] = React.useState<
    keyof typeof PackageType | string | null
  >(() => searchParams.get("packageType"));

  React.useEffect(() => {
    const nextSearchFilters = searchParams.get("packageType");
    if (nextSearchFilters) {
      setFilter(nextSearchFilters);
    } else {
      setFilter(null);
    }
  }, [searchParams]);

  const displayedFilterTypes = [
    {
      id: PackageTypes.OS_PACKAGE,
      title: "Operating System Packages",
      count: `${packagesCount} Packages`,
    },
    {
      id: PackageTypes.APPLICATION,
      title: "Applications",
      count: `${applicationsCount} Applications`,
    },
  ];

  const handleFilter = (packageType: keyof typeof PackageTypes) => {
    if (filter === packageType) {
      searchParams.delete("packageType");
    } else {
      searchParams.set("packageType", packageType);
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  return {
    displayedFilterTypes,
    filter: filter as PackageType,
    handleFilter,
  };
}
