import { useCallback, useEffect, useState } from "react";
import { Box, List, Paper, ListItem, IconButton } from "@mui/material";
import { Flex, Text, TextButton } from "~/components/ui-library";
import { Code } from "~/components/code";
import { DynamicResourceIcon } from "./get-icon";
import { Field } from "~/operations";
import { RemoveIcon } from "~/components/icons";

type JsonViewerProps = {
  popoverHeight: number;
  popoverContent: Field | null;
};

export function JsonViewer({ popoverHeight, popoverContent }: JsonViewerProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [content, setContent] = useState<any | null>(null);
  const [collapse, setCollapse] = useState(false);

  useEffect(() => {
    setOpen(Boolean(popoverContent));
    setCollapse(false);
  }, [popoverContent]);

  const onCollapse = useCallback(() => {
    setCollapse((v) => !v);
  }, []);

  useEffect(() => {
    if (popoverContent?.value && popoverContent.value !== "null") {
      const nextContent = JSON.stringify(
        JSON.parse(popoverContent.value),
        null,
        " ",
      );
      setContent(nextContent);
    }
  }, [popoverContent]);

  return (
    <Box
      sx={{
        position: "absolute",
        display: "inline-block",
        height: `${collapse ? 65 : popoverHeight}px`,
        overflow: "hidden",
        left: "calc(100% - 528px)",
        width: 528,
        pointerEvents: "none",
        bottom: 0,
        transition: "height 500ms ease-in-out",
      }}
    >
      <Box
        component={Paper}
        sx={{
          position: "absolute",
          width: 528,
          right: open ? "0%" : "-100%",
          transition: ".5s",
          borderTopLeftRadius: 4,
          borderBottomLeftRadius: 4,
          height: `${popoverHeight}px`,
          zIndex: 16,
          overflow: "scroll",
          pointerEvents: "auto",
          pre: {
            margin: 0,
          },
        }}
      >
        {popoverContent && (
          <>
            <Box
              sx={{
                position: "sticky",
                top: 0,
                p: 2,
                backgroundColor: "background.paper",
                zIndex: 16,
              }}
            >
              <Flex justifyContent="space-between">
                <Flex center>
                  <DynamicResourceIcon
                    identifier={popoverContent.type}
                    sx={{ mr: 1 }}
                  />
                  <Text bold>{popoverContent.name}</Text>
                </Flex>
                <Flex center gap={1}>
                  <IconButton onClick={onCollapse}>
                    <RemoveIcon />
                  </IconButton>
                </Flex>
              </Flex>
              <List dense>
                <ListItem disableGutters>
                  <TextButton secondary>
                    {popoverContent.__typename}: {popoverContent.type}
                  </TextButton>
                </ListItem>
                <ListItem disableGutters>
                  <TextButton secondary>Path: {popoverContent.path}</TextButton>
                </ListItem>
                <ListItem disableGutters>
                  <TextButton secondary>
                    Last Updated: {popoverContent.updatedAt}
                  </TextButton>
                </ListItem>
              </List>
            </Box>
            <Box px={2} pb={2} sx={{ fontWeight: 700 }}>
              {content && <Code className="json">{content}</Code>}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
