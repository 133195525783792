import { Box } from "@mui/material";
import { RiskGraphPrototype } from "./Prototype/RiskGraphPrototype";

export type RiskGraphProps = {};

export function RiskGraph({}: RiskGraphProps) {
  return (
    <Box py={3}>
      <RiskGraphPrototype />
    </Box>
  );
}
