import { Alert, Box } from "@mui/material";
import { UseAssetUrlStats } from "~/hooks/useAssetUrlStats";
import { SunburstHeader } from "./sunburst-header";
import { SunburstChart } from "./sunburst-chart";
import { SunburstFooter } from "./sunburst-footer";
import { Fragment } from "react";
import { useBanner } from "../Banner/useBanner";

export type SunburstProps = {
  assetUrlStats: UseAssetUrlStats;
};

export function Sunburst({ assetUrlStats }: SunburstProps) {
  const { bannerColors } = useBanner({ type: "info" });

  const hasAssets = assetUrlStats.hasAssets;
  const noData = !assetUrlStats.isRefreshing && !assetUrlStats.data.length;

  return (
    <Fragment>
      {noData && hasAssets && (
        <Alert
          severity="info"
          variant="filled"
          sx={{
            position: "fixed",
            top: 65,
            left: 0,
            width: 1,
            pl: "228px",
            alignItems: "center",
            justifyContent: "center",
            background: "#272245", // same as alpha(bannerColors.background, 0.2) but no transparency
            color: bannerColors.color,
            zIndex: 10,
          }}
        >
          Mondoo doesn't have recent information about the assets in this space.
          Please rescan your assets with the latest Mondoo version.
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          alignItems: "center",
        }}
      >
        {noData ? <Box /> : <SunburstHeader assetUrlStats={assetUrlStats} />}
        <SunburstChart assetUrlStats={assetUrlStats} />
        <SunburstFooter assetUrlStats={assetUrlStats} />
      </Box>
    </Fragment>
  );
}
