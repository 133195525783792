import { createSvgIcon } from "@mui/material";

export const AttachIcon = createSvgIcon(
  <svg
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7974 9.93656L11.246 19.5107C9.13581 21.6209 5.71551 21.6209 3.58265 19.5107C1.47245 17.4005 1.47245 13.9802 3.58265 11.8473L8.84682 6.58312C9.1132 6.31675 9.53577 6.31675 9.80214 6.58312C10.0685 6.84949 10.0685 7.27207 9.80214 7.53844L4.53708 12.8017C2.95963 14.3792 2.95963 16.9553 4.53708 18.5328C6.11453 20.1103 8.69062 20.1103 10.2682 18.5328L19.8423 8.95872C20.9086 7.89233 20.9086 6.18214 19.8423 5.13837C18.7759 4.07198 17.0657 4.07198 16.0219 5.13837L6.47047 14.7125C5.93772 15.2452 5.93772 16.0895 6.47047 16.6231C7.00322 17.1558 7.84748 17.1558 8.38111 16.6231L16.5105 8.49283C16.7769 8.22646 17.1994 8.22646 17.4658 8.49283C17.7322 8.7592 17.7322 9.18178 17.4658 9.44815L9.33554 17.5775C8.26916 18.6439 6.55897 18.6439 5.51519 17.5775C4.44881 16.5111 4.44881 14.801 5.51519 13.7572L15.0893 4.18309C16.6667 2.60564 19.2428 2.60564 20.8204 4.18309C22.397 5.78309 22.397 8.35929 20.7978 9.93661L20.7974 9.93656Z"
      fill="currentColor"
    />
  </svg>,
  "Attach",
);
