import { createSvgIcon } from "@mui/material";

export const CloudformationColorIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 2500 2500"
    fill="currentColor"
  >
    <path
      fill="#B7CA9D"
      d="M410.1,1698.3l16.4,18.6l827.9,234.7l3.8-145.5l-579.8-145.5l-28.4-10.2L410.1,1698.3"
    />
    <path
      fill="#B7CA9D"
      d="M2089.9,1698.3l-845.5,282.2l-2.5-174.4l579.8-145.5l25.3-28.4L2089.9,1698.3"
    />
    <path
      fill="#3C4929"
      d="M1241.8,701.3l579.8,135.1l255-12.1l13.2-25.6L1282,508L1241.8,701.3"
    />
    <path
      fill="#759C3E"
      d="M678.3,1660.6l-268.2,37.7l-7.5-27.8V828.1l7.5-29.3l242.3,18l25.9,19.7V1660.6"
    />
    <path
      fill="#3C4929"
      d="M1258.1,701.3L678.3,836.5l-268.2-37.7l10.1-15.9l834.2-237.4L1258.1,701.3"
    />
    <path
      fill="#4B612C"
      d="M1248.8,74.9L285.9,561l0.5,1375.4l962.5,485.8l14.3-21.8V103.2L1248.8,74.9z M967.3,1865.5l-557.1-167.2V798.8
	l557.1-167.2V1865.5z M1821.6,1660.6l268.2,37.7l15-26.6V824.4l-15-25.6l-268.2,37.7V1660.6"
    />
    <path
      fill="#759C3E"
      d="M1248.8,2422.2l964.8-485.8l0.5-1375.4L1248.8,74.9V2422.2z M1532.7,631.6l557.2,167.2v899.5l-557.2,167.2
	V631.6L1532.7,631.6z"
    />
  </svg>,
  "CloudformationColor",
);
