import { createSvgIcon, SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const VdaIcon = ({ ...props }) => {
  const { mode } = useColorMode();
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 84 28"
        enableBackground="new 0 0 84 28"
        xmlSpace="preserve"
      >
        <path
          fill={mode === "light" ? "#363636" : "#FFFFFF"}
          d="M31.7,28c-0.4,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6c-0.3-0.3-0.5-0.6-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1
      V2.8c0-0.4,0.1-0.7,0.2-1.1c0.1-0.3,0.3-0.6,0.6-0.9c0.3-0.3,0.6-0.5,0.9-0.6C31,0.1,31.4,0,31.7,0h7.5c2.3,0,4.1,0.2,5.3,0.5
      c1.6,0.5,3,1.4,4.2,2.6c1.2,1.3,2.1,2.9,2.7,4.5c0.6,1.8,0.9,4,0.9,6.7c0,2.3-0.3,4.3-0.8,6c-0.7,2-1.7,3.7-3,4.9
      c-1,0.9-2.3,1.7-3.9,2.2c-1.2,0.4-2.9,0.6-5,0.6L31.7,28z M34.6,23.3h4.2c1.1,0,2.3-0.1,3.4-0.3c0.8-0.2,1.6-0.6,2.3-1.2
      c0.6-0.5,1.1-1.4,1.5-2.7c0.4-1.3,0.6-3,0.6-5.1c0-2.2-0.2-3.8-0.6-5C45.5,7.9,45,7,44.3,6.4c-0.7-0.7-1.6-1.1-2.6-1.3
      c-0.8-0.2-2.3-0.3-4.6-0.3h-2.5V23.3z M53.9,28c-0.1,0-0.3,0-0.4-0.1s-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.1-0.4
      c0-0.1,0-0.3,0.1-0.4l9.3-24.1c0.3-0.8,0.9-1.5,1.6-2C64.8,0.3,65.6,0,66.5,0s1.7,0.3,2.5,0.8c0.7,0.5,1.3,1.2,1.6,2l2.2,5.4
      l2.9,7.3h-5.9l-3.3-9l-3.8,10.4h18.9c0.2,0,0.3,0.1,0.5,0.1c0.1,0.1,0.2,0.2,0.3,0.4l1.2,3c0,0.1,0.1,0.3,0.1,0.4
      c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.1H60.9l-2.1,5.8c-0.1,0.2-0.2,0.3-0.3,0.4
      C58.4,27.9,58.2,28,58,28L53.9,28z M75.1,28c-0.2,0-0.3,0-0.5-0.1c-0.1-0.1-0.2-0.2-0.3-0.4L72.6,23l6,0l1.5,3.8
      c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0.1-0.4,0.1L75.1,28z M8.6,25.1L0,1.1
      C0,1,0,0.9,0,0.7c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3C0.6,0,0.7,0,0.8,0h4.3c0.2,0,0.3,0.1,0.5,0.2
      c0.1,0.1,0.2,0.2,0.3,0.4l6.9,20.1l6.6-20.1c0.1-0.2,0.2-0.3,0.3-0.4C19.9,0.1,20,0,20.2,0h4.2c0.1,0,0.3,0,0.4,0.1
      c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.2,0.1,0.4s0,0.3,0,0.4l-8.6,24c-0.3,0.8-0.8,1.6-1.6,2.1S13.5,28,12.6,28
      c-0.9,0-1.7-0.3-2.5-0.8C9.4,26.7,8.9,26,8.6,25.1z"
        />
      </svg>
    </SvgIcon>
  );
};

VdaIcon.displayName = "VDA";
