import { createSvgIcon } from "@mui/material";

export const WebhookIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2997 10.3C10.3597 11.88 9.45973 13.4 8.55973 14.9C8.31973 15.28 8.19973 15.6 8.39973 16.1C8.91973 17.46 8.17973 18.78 6.81973 19.14C5.51973 19.48 4.25973 18.62 3.99973 17.24C3.77973 16.02 4.71973 14.8 6.07973 14.62C6.19973 14.6 6.29973 14.6 6.49973 14.58C7.15973 13.46 7.83973 12.32 8.55973 11.12C7.25973 9.83999 6.49973 8.31999 6.65973 6.45999C6.77973 5.13999 7.29973 3.99999 8.23973 3.05999C10.0397 1.29999 12.8197 1.01999 14.9597 2.39999C17.0197 3.71999 17.9597 6.27999 17.1597 8.47999C16.5597 8.31999 15.9397 8.15999 15.2797 7.95999C15.5397 6.73999 15.3397 5.63999 14.5197 4.69999C13.9797 4.07999 13.2797 3.75999 12.4797 3.63999C10.8797 3.39999 9.31973 4.41999 8.85973 5.97999C8.31973 7.75999 9.11973 9.21999 11.2997 10.3Z"
      fill="#C73A63"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9593 8.43997C14.6193 9.59997 15.2793 10.78 15.9593 11.96C19.3193 10.92 21.8393 12.78 22.7393 14.76C23.8393 17.16 23.0793 20 20.9393 21.48C18.7393 23 15.9393 22.74 13.9793 20.78C14.4793 20.36 14.9793 19.94 15.5193 19.5C17.4593 20.76 19.1393 20.7 20.3993 19.22C21.4593 17.96 21.4393 16.08 20.3393 14.84C19.0593 13.42 17.3593 13.36 15.3193 14.74C14.4593 13.22 13.5993 11.72 12.7593 10.2C12.4793 9.67997 12.1793 9.37997 11.5393 9.27997C10.4993 9.09997 9.81929 8.19997 9.77929 7.17997C9.73929 6.17997 10.3193 5.27997 11.2393 4.91997C12.1593 4.57997 13.2193 4.85997 13.8393 5.61997C14.3393 6.25997 14.4993 6.95997 14.2393 7.73997C14.1593 7.97997 14.0593 8.17997 13.9593 8.43997Z"
      fill="#4B4B4B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5397 17.96C14.2197 17.96 12.8797 17.96 11.4997 17.96C11.1197 19.56 10.2797 20.84 8.83971 21.66C7.71971 22.3 6.51971 22.52 5.23971 22.3C2.87971 21.9 0.939709 19.78 0.759709 17.38C0.559709 14.66 2.43971 12.26 4.91971 11.72C5.09971 12.34 5.25971 12.98 5.43971 13.6C3.15971 14.76 2.35971 16.24 2.99971 18.06C3.55971 19.68 5.15971 20.56 6.89971 20.22C8.67971 19.88 9.55971 18.4 9.45971 16.04C11.1397 16.04 12.8197 16.02 14.4997 16.04C15.1597 16.06 15.6597 15.98 16.1597 15.4C16.9797 14.44 18.4597 14.54 19.3397 15.44C20.2397 16.36 20.1997 17.84 19.2397 18.72C18.3197 19.56 16.8797 19.52 16.0197 18.6C15.8597 18.4 15.7197 18.18 15.5397 17.96Z"
      fill="#4A4A4A"
    />
  </svg>,
  "Webhook",
);
