import { createSvgIcon } from "@mui/material";

export const GenericPolicyIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Layer_1"
    focusable="false"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    enableBackground="new: 0 0 24 24"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <linearGradient
        id="SV1"
        gradientUnits="userSpaceOnUse"
        x1="3.7933"
        y1="3.9949"
        x2="18.9653"
        y2="16.8221"
      >
        <stop offset="0" stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <path
        fill="url(#SV1)"
        d="M12,1L3,5v6c0,5.5,3.8,10.7,9,12c2.3-0.6,4.3-1.9,5.9-3.7l-3.1-3.1c-1.9,1.3-4.6,1.1-6.3-0.6c-2-2-2-5.1,0-7.1   c1.9-2,5.1-2,7.1,0c1.7,1.7,1.9,4.3,0.6,6.3l2.9,2.9c1.2-2,1.9-4.3,1.9-6.7V5L12,1z"
      />

      <linearGradient
        id="SV2"
        gradientUnits="userSpaceOnUse"
        x1="3.2674"
        y1="4.617"
        x2="18.4394"
        y2="17.4442"
      >
        <stop offset="0" stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <circle fill="url(#SV2)" cx="12" cy="12" r="3" />
    </g>
  </svg>,
  "Policy",
);
