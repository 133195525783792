import { ReactNode } from "react";
import { Box } from "@mui/material";
import { StatItem } from "./StatItem";

export type Stat = {
  label: string;
  value: string;
  icon: ReactNode;
  onClick?: () => void;
};

export type StatsProps = {
  stats: Stat[];
  id?: string;
};

export function Stats({ stats, id }: StatsProps) {
  return (
    <Box
      id={id}
      className="asset-stats"
      mb={1}
      display="flex"
      alignItems="center"
    >
      <Box overflow="auto" p={2} m={-2}>
        <Box display="flex">
          {stats.map((stat) => {
            return (
              <StatItem
                key={stat.label}
                icon={stat.icon}
                value={stat.value}
                label={stat.label}
                onClick={stat.onClick}
                sx={{ mr: 2 }}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}
