import { ThemeOptions } from "@mui/material/styles";
import { sharedColorBlindPalette } from "./sharedColorBlindPalette";

export const lightColorBlindTheme: ThemeOptions = {
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: sharedColorBlindPalette.primary.main,
          textDecoration: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#f0f0f0",
          color: "#363636",
          boxShadow:
            "0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033",
        },
        arrow: {
          "&::before": {
            backgroundColor: "#f0f0f0",
            boxShadow:
              "1px 1px 3px 0px #0000001F, 1px 1px 1px 0px #00000024, 2px 2px 1px -1px #0003",
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#fff",
      paper: "rgb(250, 250, 250, 1)",
      light: "rgba(222,222,235,0.7)",
      lighter: "rgba(222,222,235,0.4)",
      lightest: "rgba(222,222,235,0.3)",
    },
    code: {
      background: "rgba(0, 0, 0, 0.8)",
      flash: "rgba(170,170,170,0.7)",
    },
    tables: {
      tableHead: {
        shadow: "0 2px 8px 0 rgba(20,20,65,0.4)",
      },
      tableCell: {
        borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
      },
    },

    ...sharedColorBlindPalette,
  },
};
