import { createSvgIcon } from "@mui/material";

export const SlackAuthIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.2384 29.7936C11.2384 32.3381 9.1637 34.4128 6.61922 34.4128C4.07473 34.4128 2 32.3381 2 29.7936C2 27.2491 4.07473 25.1744 6.61922 25.1744H11.2384V29.7936Z"
      fill="#AAAAAA"
    />
    <path
      d="M13.5664 29.7936C13.5664 27.2491 15.6411 25.1744 18.1856 25.1744C20.7301 25.1744 22.8048 27.2491 22.8048 29.7936V41.3612C22.8048 43.9057 20.7301 45.9804 18.1856 45.9804C15.6411 45.9804 13.5664 43.9057 13.5664 41.3612C13.5664 41.3808 13.5664 29.7936 13.5664 29.7936Z"
      fill="#AAAAAA"
    />
    <path
      d="M18.2052 11.2384C15.6607 11.2384 13.5859 9.1637 13.5859 6.61922C13.5859 4.07473 15.6607 2 18.2052 2C20.7496 2 22.8244 4.07473 22.8244 6.61922V11.2384H18.2052Z"
      fill="#AAAAAA"
    />
    <path
      d="M18.2064 13.5676C20.7509 13.5676 22.8256 15.6424 22.8256 18.1868C22.8256 20.7313 20.7313 22.8256 18.2064 22.8256H6.61922C4.07473 22.8256 2 20.7509 2 18.2064C2 15.6619 4.07473 13.5872 6.61922 13.5872C6.61922 13.5676 18.2064 13.5676 18.2064 13.5676Z"
      fill="#AAAAAA"
    />
    <path
      d="M36.7617 18.2064C36.7617 15.6619 38.8365 13.5872 41.3809 13.5872C43.9254 13.5872 46.0002 15.6619 46.0002 18.2064C46.0002 20.7509 43.9254 22.8256 41.3809 22.8256H36.7617V18.2064Z"
      fill="#AAAAAA"
    />
    <path
      d="M34.4337 18.2064C34.4337 20.7509 32.359 22.8256 29.8145 22.8256C27.27 22.8256 25.1953 20.7509 25.1953 18.2064V6.61922C25.1953 4.07473 27.27 2 29.8145 2C32.359 2 34.4337 4.07473 34.4337 6.61922V18.2064Z"
      fill="#AAAAAA"
    />
    <path
      d="M29.7911 36.7616C32.3356 36.7616 34.4103 38.8363 34.4103 41.3808C34.4103 43.9253 32.3356 46 29.7911 46C27.2466 46 25.1719 43.9253 25.1719 41.3808V36.7616H29.7911Z"
      fill="#AAAAAA"
    />
    <path
      d="M29.7911 34.4324C27.2466 34.4324 25.1719 32.3577 25.1719 29.8132C25.1719 27.2687 27.2466 25.194 29.7911 25.194H41.3587C43.9032 25.194 45.9779 27.2687 45.9779 29.8132C45.9779 32.3577 43.9032 34.4324 41.3587 34.4324H29.7911Z"
      fill="#AAAAAA"
    />
  </svg>,
  "Slack",
);
