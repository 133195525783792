import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Space } from "~/lib/types";
import { LoadingFailedPage, LoadingPage } from "../components/loading";
import {
  LoadSpaceStatsQuery,
  TestIamActionsQuery,
  useLoadSpaceStatsQuery,
} from "~/operations";
import { AwsGuide } from "./aws-guide";
import { VastEmptyness } from "./vast-emptyness/vast-emptyness";
import { Overview } from "./space/overview";

type SpaceStats = NonNullable<
  NonNullable<LoadSpaceStatsQuery["space"]>["stats"]
>;

export type DashboardContentProps = {
  space: Space;
  spaceStats: SpaceStats;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function DashboardContent({
  space,
  spaceStats,
  availablePermissions,
}: DashboardContentProps) {
  const noData = spaceStats.riskdistribution.total === 0;

  if (noData) {
    const VastEmptynessOptions = {
      id: "overview",
      headline: "WELCOME TO MONDOO",
      tagline:
        "To begin scanning for vulnerabilities, let's integrate your infrastructure with Mondoo.",
      buttonText: "Start Scanning",
      href: `/space/integrations/add/mondoo/setup?spaceId=${space.id}`,
      buttonText2: "Browse Integrations",
      hrefButton2: `/space/integrations/add?spaceId=${space.id}`,
    };

    return <VastEmptyness options={VastEmptynessOptions} />;
  }

  return <Overview space={space} />;
}

type SpaceDashboardProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function SpaceDashboard({
  space,
  availablePermissions,
}: SpaceDashboardProps) {
  let [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    setOpen(Boolean(searchParams.get("guide") === "aws"));
  }, []);

  const { loading, data, error } = useLoadSpaceStatsQuery({
    variables: { mrn: space.mrn },
    notifyOnNetworkStatusChange: false,
  });

  if (loading) {
    return <LoadingPage what="Space Stats" />;
  }

  if (error || !data?.space?.stats) {
    return <LoadingFailedPage what="Space Stats" />;
  }

  const handleClose = () => {
    // remove the guide=aws search param upon closing
    searchParams.delete("guide");
    setSearchParams(searchParams);
    setOpen(false);
  };

  const stats = data.space.stats;

  document.title = `${space.name} · Mondoo`;

  return (
    <Fragment>
      <DashboardContent
        space={space}
        spaceStats={stats}
        availablePermissions={availablePermissions}
      />
      <AwsGuide space={space} open={open} onClose={handleClose} />
    </Fragment>
  );
}
