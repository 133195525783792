import React, { MouseEvent } from "react";
import { alpha, Button } from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";
import SvgIcon from "@mui/material/SvgIcon";

type FrameworkStateButtonProps = {
  onClick: (e: MouseEvent<HTMLElement>) => void;
  Icon: typeof SvgIcon;
  color: string;
  label: string;
};

export const FrameworkStateButton = ({
  onClick,
  Icon,
  color,
  label,
}: FrameworkStateButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      startIcon={<Icon sx={{ color }} />}
      endIcon={
        <ArrowDropDown
          sx={{ color: (theme) => alpha(theme.palette.text.primary, 0.5) }}
        />
      }
      sx={{ color, fontWeight: 600 }}
    >
      {label}
    </Button>
  );
};
