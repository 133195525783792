import { Grid, List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { ListPolicyEdge } from "~/pages/inventory/asset/types";
import {
  CheckScoreOrderField,
  OrderDirection,
  useGetCheckScoresQuery,
} from "~/operations";
import { CheckResultIcon } from "./CheckResultIcon";
import { Loading } from "../loading";
import { FailedChecks } from "./FailedChecks";
import { PrintPolicySummaryHeader } from "./PrintPolicySummaryHeader";

type PrintPolicyReportProps = {
  listPolicyEdge: ListPolicyEdge;
  assetMrn: string;
};

export function PrintPolicyReport({
  listPolicyEdge,
  assetMrn,
}: PrintPolicyReportProps) {
  const { data, loading } = useGetCheckScoresQuery({
    variables: {
      entityMrn: assetMrn,
      first: 100,
      after: null,
      orderBy: {
        field: CheckScoreOrderField.Score,
        direction: OrderDirection.Asc,
      },
      filter: {
        policyMrn: listPolicyEdge.node.mrn,
      },
    },
  });

  if (loading || !data) {
    return (
      <Grid
        container
        item
        sx={{
          pb: 4,
          borderBottom: "1px solid #DBDADD",
        }}
      >
        <Grid item xs>
          <Loading what="policy" />
        </Grid>
      </Grid>
    );
  }

  const checks =
    data?.checkScores?.__typename === "CheckScoresConnection"
      ? data.checkScores.edges
      : [];

  return (
    <Grid container item>
      <PrintPolicySummaryHeader listPolicyEdge={listPolicyEdge} />
      {/* Checks */}
      <Grid
        item
        xs={12}
        sx={{
          py: 4,
          px: 4,
          borderBottom: "1px solid #DBDADD",
        }}
      >
        <Typography variant="h5" component="h4">
          Checks
        </Typography>
        <List>
          {checks?.map((check) => {
            return (
              <ListItem disableGutters key={check.node?.mrn}>
                <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                  <CheckResultIcon
                    result={check.node?.resultType}
                    scoreState={check.node?.state}
                  />
                </ListItemIcon>
                <Typography variant="body2">{check.node?.title}</Typography>
              </ListItem>
            );
          })}
        </List>
      </Grid>
      {/* failed Checks */}
      <FailedChecks assetMrn={assetMrn} listPolicyEdge={listPolicyEdge} />
    </Grid>
  );
}
