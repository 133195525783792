import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useEffect } from "react";

type UseRemediationFilterParams = {
  defaultValue: string;
  filterKey: string;
};

export function useRemediationFilter({
  defaultValue,
  filterKey,
}: UseRemediationFilterParams) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams({
    [filterKey]: defaultValue,
  });

  const handleFilter = (version: string) => {
    if (searchParams.get(filterKey) !== version) {
      searchParams.set(filterKey, version);
      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  return {
    filter: searchParams.get(filterKey) || defaultValue,
    handleFilter,
  };
}
