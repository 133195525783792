type getGraphQLErrorsProps = {
  error: any;
  errorMessage?: string;
};

const getGraphQLErrors = ({
  error,
  errorMessage = "error",
}: getGraphQLErrorsProps): string => {
  let message = String(error?.message || errorMessage);

  if (error?.graphQLErrors?.length > 0) {
    const messages = error?.graphQLErrors.reduce(
      (output: string[], error: any) => [...output, error?.message],
      [],
    );

    message = messages?.join(". ");
  }

  return String(error);
};

export default getGraphQLErrors;
