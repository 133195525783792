import { Box, Typography, useTheme } from "@mui/material";
import { UseAssetUrlStats } from "~/hooks/useAssetUrlStats";

export type SunburstFooterProps = {
  assetUrlStats: UseAssetUrlStats;
};

export function SunburstFooter({ assetUrlStats }: SunburstFooterProps) {
  const theme = useTheme();

  const statusTextRange = [
    "critical",
    "high",
    "medium",
    "low",
    "none",
    "unknown",
  ] as const;

  return (
    assetUrlStats.data.length > 0 && (
      <Box
        className="sunburst-footer"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 1,
          width: "100%",
        }}
      >
        <Box
          className="sunburst-legend"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1.5,
          }}
        >
          {statusTextRange
            // .filter((s) => s !== "unknown")
            .map((s) => (
              <Box
                key={s}
                sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
              >
                <Box
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: "50%",
                    bgcolor: theme.palette[s].main,
                  }}
                />
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    color: theme.palette[s].main,
                  }}
                >
                  {/* {s} */}
                  {s === "unknown" ? "unscored" : s === "none" ? "ok" : s}
                </Typography>
              </Box>
            ))}
        </Box>
      </Box>
    )
  );
}
