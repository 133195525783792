import React, { Fragment } from "react";
import {
  ConfigurationList,
  ConfigurationPaper,
} from "~/components/configuration-items";
import { IamActions } from "~/lib/iam";
import { useViewer } from "~/providers/viewer";
import { useSnackbar } from "notistack";
import { getGraphQLErrors } from "~/components/ui-library";
import { IdField } from "~/pages/space/Workspaces/components/FormFields/IdField";
import { DisplayNameField } from "~/pages/space/Workspaces/components/FormFields/DisplayNameField";
import { DescriptionField } from "~/pages/space/Workspaces/components/FormFields/DescriptionField";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateFormInput } from "~/pages/space/Workspaces/components/FormFields/types";
import { Grid2 } from "@mui/material";
import { useOrgSettingsOutletContext } from "~/pages/organization";

type Props = {};

export function OrganizationNameSettings({}: Props) {
  const { availablePermissions, org } = useOrgSettingsOutletContext();
  const { enqueueSnackbar } = useSnackbar();
  const { updateOrgName } = useViewer();

  const updateOrgPermission = availablePermissions?.includes(
    IamActions.CAPTAIN_UPDATEORGANIZATION,
  );

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<CreateFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: org.name,
      description: org.description,
      id: org.id,
    },
  });

  const handleBlur: SubmitHandler<CreateFormInput> = async (params) => {
    const { name, description } = params;
    if (!isDirty) return;

    try {
      await updateOrgName({
        variables: {
          input: {
            mrn: org.mrn,
            name,
            description,
          },
        },
      });

      enqueueSnackbar(`Organization successfully updated`, {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar(
        getGraphQLErrors({
          error,
          errorMessage: "Failed to update organization.",
        }),
        {
          variant: "error",
        },
      );
    }
  };

  return (
    <Fragment>
      <ConfigurationPaper>
        <ConfigurationList title="Name and Description">
          <Grid2
            component="form"
            onBlur={handleSubmit(handleBlur)}
            container
            p={2}
            gap={3}
          >
            <IdField
              errors={errors}
              register={register}
              disabled
              target="organization"
            />

            <DisplayNameField
              errors={errors}
              register={register}
              disabled={!updateOrgPermission}
              target="organization"
            />

            <DescriptionField
              register={register}
              disabled={!updateOrgPermission}
              target="organization"
            />
          </Grid2>
        </ConfigurationList>
      </ConfigurationPaper>
    </Fragment>
  );
}
