import { createSvgIcon } from "@mui/material";

export const TaggedPriorityIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.0625 19L10.8262 4.78125H12.2324L9.45898 19H8.0625ZM12.0371 19L14.8105 4.78125H16.207L13.4336 19H12.0371ZM17.3984 10.2012H7.24219V8.84375H17.3984V10.2012ZM16.666 14.9961H6.5V13.6484H16.666V14.9961Z"
      fill="white"
    />
  </svg>,
  "Tagged Priority",
);
