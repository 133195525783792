import { createSvgIcon } from "@mui/material";

export const CentosIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1768_36982)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3168 22.2877L22.0186 23.9898L20.3168 25.6918H8.29845V31.3652L0.959961 23.9898L8.29845 16.7278V22.2877H20.3168Z"
        fill="#932279"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.6675 20.3422L23.9658 22.0441L22.2641 20.3422V8.3216H16.5918L23.9658 0.981445L31.2264 8.3216H25.6675V20.3422Z"
        fill="#EFA724"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7474 25.7435L26.0459 24.0415L27.7474 22.3394H39.7657V16.666L47.1045 24.0415L39.7657 31.3035V25.7435H27.7474Z"
        fill="#262577"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3256 27.6675L24.0273 25.9657L25.729 27.6675V39.6882H31.4014L24.0273 47.0283L16.7667 39.6882H22.3256V27.6675Z"
        fill="#9CCD2A"
      />
      <path
        d="M7.85938 7.87793H22.4302V22.4515H7.85938V7.87793Z"
        fill="#9CCD2A"
        stroke="white"
        strokeWidth="1.10632"
        strokeMiterlimit="4.2543"
      />
      <path
        d="M25.5146 7.87793H40.0855V22.4515H25.5146V7.87793Z"
        fill="#932279"
        stroke="white"
        strokeWidth="1.10632"
        strokeMiterlimit="4.2543"
      />
      <path
        d="M25.5146 25.5361H40.0855V40.11H25.5146V25.5361Z"
        fill="#EFA724"
        stroke="white"
        strokeWidth="1.10632"
        strokeMiterlimit="4.2543"
      />
      <path
        d="M7.85938 25.5361H22.4302V40.11H7.85938V25.5361Z"
        fill="#262577"
        stroke="white"
        strokeWidth="1.10632"
        strokeMiterlimit="4.2543"
      />
      <path
        d="M1.15918 23.9865L11.4757 13.668L21.7923 23.9865L11.4757 34.3051L1.15918 23.9865Z"
        stroke="white"
        strokeWidth="1.10643"
        strokeMiterlimit="4.2547"
      />
      <path
        d="M13.709 11.5402L24.0256 1.22168L34.3421 11.5402L24.0256 21.8587L13.709 11.5402Z"
        stroke="white"
        strokeWidth="1.10632"
        strokeMiterlimit="4.2547"
      />
      <path
        d="M26.1523 23.9865L36.4689 13.668L46.7855 23.9865L36.4689 34.3051L26.1523 23.9865Z"
        stroke="white"
        strokeWidth="1.10632"
        strokeMiterlimit="4.2543"
      />
      <path
        d="M13.709 36.5382L24.0256 26.2197L34.3421 36.5382L24.0256 46.8568L13.709 36.5382Z"
        stroke="white"
        strokeWidth="1.10643"
        strokeMiterlimit="4.2547"
      />
    </g>
    <defs>
      <clipPath id="clip0_1768_36982">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Centos",
);
