import { alpha, Box, Typography } from "@mui/material";
import { getMajorScoreByValue } from "~/lib/score";
import { getColor } from "~/lib/colors";
import { MajorScore } from "~/lib/types";

interface GradeOnlyOption {
  grade: MajorScore;
  scoreNumber?: never;
  small?: boolean;
}

interface ScoreOnlyOption {
  grade?: never;
  scoreNumber: number;
  small?: boolean;
}

type GradeBlockProps = GradeOnlyOption | ScoreOnlyOption;

const majorScores = ["A", "B", "C", "D", "F", "U", "X"] as const;
export type Scores = (typeof majorScores)[number];
const isMajorScore = (x: any): x is Scores => majorScores.includes(x);

function GradeBlock({ grade, scoreNumber, small = false }: GradeBlockProps) {
  let displayGrade = "U";

  if (grade) {
    displayGrade = isMajorScore(grade) ? grade : "X";
  }

  if (scoreNumber != null || scoreNumber === 0) {
    displayGrade =
      typeof scoreNumber === "number" ? getMajorScoreByValue(scoreNumber) : "X";
  }

  return (
    <Box
      sx={{
        width: small ? 24 : 40,
        height: small ? 24 : 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: (theme) => alpha(getColor(theme, displayGrade), 0.1),
        borderRadius: 1,
      }}
    >
      <Typography
        sx={{
          p: 1,
          fontSize: small ? 16 : 24,
          lineHeight: "24px",
          fontWeight: 700,
          color: (theme) => getColor(theme, displayGrade),
          textTransform: "uppercase",
        }}
      >
        {displayGrade}
      </Typography>
    </Box>
  );
}

export default GradeBlock;
