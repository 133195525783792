import { Box, alpha } from "@mui/material";
import {
  CheckIcon,
  CloseIcon,
  RemoveIcon,
  WarningAmberIcon,
} from "~/components/icons";
import { useImpactResultIcon } from "./use-impact-result-icon";
import { CheckResult } from "./types";

type Props = {
  result: CheckResult;
  color: string;
  size?: "s" | "m" | "l";
};

export function ImpactResultIcon({ color, result, size = "m" }: Props) {
  const { iconStyles, fontSize, diameter } = useImpactResultIcon({
    color,
    size,
  });

  const getIcon = () => {
    switch (result) {
      case "pass":
        return <CheckIcon sx={iconStyles} />;
      case "fail":
        return <CloseIcon sx={iconStyles} />;
      case "error":
        return (
          <WarningAmberIcon
            sx={{
              ...iconStyles,
              fontSize: fontSize * 0.8,
              mt: -0.25,
            }}
          />
        );
      case "skip":
      default:
        // unscored
        return <RemoveIcon sx={iconStyles} />;
    }
  };

  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: diameter,
        height: diameter,
        borderRadius: "50%",
        background: alpha(color, 0.1),
      }}
    >
      {getIcon()}
    </Box>
  );
}
