import { useState } from "react";
import {
  Alert,
  AlertColor,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useAuth } from "~/login/provider";
import { MondooLogoHorizontal } from "~/images/icons";

export default function VerifyEmailPage() {
  const { logout, sendUserEmailVerification } = useAuth();
  const [severity, setSeverity] = useState<AlertColor | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const handleEmailVerification = () => {
    sendUserEmailVerification()
      .then(() => {
        setSeverity("success");
        setMessage("The mail has been sent successfully");
      })
      .catch((err) => {
        setSeverity("error");
        setMessage(err);
      });
  };

  const handleReload = () => {
    location.reload();
  };

  return (
    <Container maxWidth="sm">
      <Paper sx={{ m: 3, p: 3, textAlign: "center" }}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <MondooLogoHorizontal />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4">Please Verify Email</Typography>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            <Typography variant="body1">
              Thank you for joining Mondoo. You have been sent an email with a
              verification link contained inside.
            </Typography>
            <Typography variant="body1">
              To begin enjoying Mondoo, you must first click that link. If you
              have not received your verification email, you can request a
              another one.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {severity && message && (
            <Grid item xs={12}>
              <Alert severity={severity}>{message}</Alert>
            </Grid>
          )}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={handleReload}>
              Reload
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleEmailVerification}
              sx={{ mx: 1, my: 0 }}
            >
              Resend Verification Email
            </Button>
            <Button color="critical" variant="contained" onClick={logout}>
              Sign Out
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
}
