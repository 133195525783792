import { Box, Link, SxProps, Theme } from "@mui/material";
import { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSlug from "rehype-slug";
import remarkBreaks from "remark-breaks";
import { Code } from "~/components/code";

export type MarkdownProps = {
  source: string;
  trusted?: boolean;
  copyButton?: boolean;
  dataId?: string;
  className?: string;
  sx?: SxProps<Theme>;
};

export function Markdown({
  source,
  trusted = false,
  copyButton = true,
  dataId,
  className,
  sx,
}: MarkdownProps) {
  const output = useMemo(() => {
    return (
      <ReactMarkdown
        rehypePlugins={[
          // Adds `id` anchors to headings
          rehypeSlug,
          // Allow raw html from trusted source
          trusted ? rehypeRaw : undefined,
        ].flatMap((p) => p ?? [])}
        remarkPlugins={[
          // Render newlines as `<br>` elements
          [remarkBreaks],
        ]}
        components={{
          // Render code blocks with Code component
          pre({ children }) {
            return <Code copyButton={copyButton}>{children}</Code>;
          },
          // Render links with MUI Link component
          a({ children, href }) {
            return (
              <Link href={href} sx={{ textDecoration: "underline" }}>
                {children}
              </Link>
            );
          },
        }}
      >
        {source}
      </ReactMarkdown>
    );
  }, [source]);

  return (
    <Box
      className={className}
      data-id={dataId}
      data-testid="markdown"
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      {output}
    </Box>
  );
}
