export const FixedPercentage = (n: number): string => {
  return `${Math.round(n * 100 * 100) / 100.0}%`;
};

//Capitalize the first letter of a sentence
export const capitalizeFirstLetter = (string: string | null): string => {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};
