import { Dispatch, Fragment, SetStateAction, useState } from "react";
import { alpha, Box, Button, Typography, useTheme } from "@mui/material";
import { CardSizes, HubCard } from "~/components/hub-card";
import { TimerOutlinedIcon } from "~/components/icons";
import { getColor } from "~/lib/colors";
import { Space } from "~/lib/types";
import { Metrics, useMetrics } from "~/hooks";

type Props = {
  sizes?: CardSizes;
  space: Space;
};

type Category = "low" | "medium" | "high" | "critical";
const categoryButtons: Category[] = ["low", "medium", "high", "critical"];

export function MttrPerScoreCard({ sizes, space }: Props) {
  const [active, setActive] = useState<Category>("critical");

  const { mttrSeverity, isLoading } = useMetrics({
    entityMrn: space.mrn,
    metricMrns: [Metrics.MTTRSeverity],
  });

  return (
    <HubCard
      {...{
        loading: isLoading,
        sizes,
        title: "MTTR Per Score",
        tagline: "MEAN TIME TO REMEDIATE PER CVSS CATEGORY",
        icon: <TimerOutlinedIcon fontSize="small" />,
      }}
      PaperProps={{ minHeight: 264 }}
      data-testid="mttr-per-score-card"
    >
      <Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 2,
          }}
        >
          {categoryButtons.map((category) => (
            <SelectorButton
              {...{ category, active, setActive }}
              key={category}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "baseline",
            justifyContent: "center",
            mt: 1.5,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mx: 1,
            }}
          >
            <Typography
              sx={{
                fontSize: (theme) => theme.spacing(8),
                lineHeight: "80px",
              }}
              fontWeight={800}
              data-testid="mttr-days"
            >
              {mttrSeverity[active]?.days || 0}
            </Typography>
            <Typography
              variant="caption"
              sx={{ textTransform: "uppercase", color: "text.secondary" }}
            >
              Days
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mx: 1,
            }}
          >
            <Typography
              fontWeight={800}
              sx={{
                fontSize: (theme) => theme.spacing(5),
                color: "text.secondary",
              }}
              data-testid="mttr-hours"
            >
              {mttrSeverity[active]?.hours || 0}
            </Typography>
            <Typography
              variant="caption"
              sx={{ textTransform: "uppercase", color: "text.secondary" }}
            >
              Hours
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              mx: 1,
            }}
          >
            <Typography
              fontWeight={800}
              sx={{
                fontSize: (theme) => theme.spacing(5),
                color: "text.secondary",
              }}
              data-testid="mttr-minutes"
            >
              {mttrSeverity[active]?.minutes || 0}
            </Typography>
            <Typography
              variant="caption"
              sx={{ textTransform: "uppercase", color: "text.secondary" }}
            >
              Minutes
            </Typography>
          </Box>
        </Box>
      </Fragment>
    </HubCard>
  );
}

const SelectorButton = ({
  category,
  active,
  setActive,
}: {
  category: Category;
  active: Category;
  setActive: Dispatch<SetStateAction<Category>>;
}) => {
  const theme = useTheme();
  const color = getColor(theme, category);

  return (
    <Button
      variant="contained"
      onClick={() => setActive(category)}
      sx={{
        py: 0.5,
        px: 1,
        mx: { xs: 0.5, sm: 1, md: 0.5 },
        color: active === category ? color : alpha(color, 0.5),
        backgroundColor: alpha(color, 0.1),
        ...(active === category && {
          boxShadow: `inset 0 0 0 1px ${color}`,
        }),
        "&:hover": {
          backgroundColor: alpha(color, 0.2),
          ...(active === category && {
            boxShadow: `inset 0 0 0 1px ${color}`,
          }),
        },
      }}
    >
      {category}
    </Button>
  );
};
