import { NotificationsOffOutlined } from "@mui/icons-material";
import { Box, ButtonProps, MenuProps } from "@mui/material";
import { Fragment, ReactNode, useState } from "react";
import { ArrowDropDownIcon } from "~/components/icons";
import { LoadingButton } from "~/components/loading-button";
import { BarChartIcon } from "~/components/ui-library";
import {
  PolicyAction,
  useAssignPolicyMutation,
  useUnassignPolicyMutation,
} from "~/operations";
import { PolicyAssignMenu } from "~/pages/security/policies/components/PolicyAssignMenu";

type AssignmentOption = {
  action: PolicyAction;
  name: string;
  description: string;
  isDefault: boolean;
  icon: ReactNode;
};

export type PolicyAssignButtonProps = {
  spaceMrn: string;
  policyMrn: string;
  action?: PolicyAction | null;
  onChange?: (action?: PolicyAction) => void;
};

export function PolicyAssignSelect({
  spaceMrn,
  policyMrn,
  action,
  onChange,
}: PolicyAssignButtonProps) {
  const [assignPolicy, assignResult] = useAssignPolicyMutation();
  const [_unassignPolicy, unassignResult] = useUnassignPolicyMutation();
  const loading = unassignResult.loading || assignResult.loading;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const options: AssignmentOption[] = [
    {
      action: PolicyAction.Active,
      name: "Scored",
      description: "Running and contributing to your overall space scores",
      isDefault: true,
      icon: <BarChartIcon fontSize="inherit" />,
    },
    {
      action: PolicyAction.Ignore,
      name: "Preview",
      description: "Running but not contributing to your overall space scores",
      isDefault: false,
      icon: <NotificationsOffOutlined fontSize="inherit" />,
    },
  ];

  const selectedOption = options.find((o) => o.action === action);

  const handleMenuOpen: ButtonProps["onClick"] = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuItemClick = async (
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: AssignmentOption,
  ) => {
    setAnchorEl(null);

    if (option.action !== selectedOption?.action) {
      await assignPolicy({
        variables: {
          input: {
            assetMrn: spaceMrn,
            policyMrn,
            action: option.action,
          },
        },
      });

      onChange?.();
    }
  };

  const handleMenuClose: MenuProps["onClose"] = (e) => {
    setAnchorEl(null);
  };

  return (
    <Box onClick={(e) => e.stopPropagation()} sx={{ whiteSpace: "nowrap" }}>
      <Fragment>
        <LoadingButton
          buttonText={selectedOption?.name || ""}
          loading={loading}
          variant="outlined"
          size="small"
          onClick={handleMenuOpen}
          startIcon={selectedOption?.icon}
          endIcon={<ArrowDropDownIcon />}
          sx={{
            minWidth: 126,
            justifyContent: loading ? "center" : "end",
            borderColor: "background.lightest",
            textTransform: "uppercase",
            ...(selectedOption?.action === PolicyAction.Active && {
              color: "none.light",
            }),
          }}
          LoaderSxProps={{ right: 50 }}
        />
        <PolicyAssignMenu
          {...{
            anchorEl,
            open,
            handleMenuClose,
            selectedOption,
            handleMenuItemClick,
          }}
        />
      </Fragment>
    </Box>
  );
}
