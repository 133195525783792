import { Platform } from "~/operations";

const platforms: { [s: string]: string } = {
  arch: "Arch Linux",
  centos: "CentOS",
  redhat: "Red Hat",
  rhel: "RHEL",
  debian: "Debian",
  ubuntu: "Ubuntu",
  suse: "SUSE Linux",
  windows: "Windows",
  amzn: "Amazon Linux",
  macos: "macOS",
  amazonlinux: "Amazon Linux",
  alpine: "Alpine Linux",
  scientific: "Scientific Linux",
  opensuse: "openSUSE",
  "opensuse-leap": "openSUSE Leap",
  "opensuse-tumbleweed": "openSUSE Tumbleweed",
  sles: "SUSE Linux",
  "vmware-esxi": "VMware ESXi",
  "vmware-vsphere": "VMware vSphere",
  aws: "AWS Account",
  azure: "Azure Subscription",
  "arista-eos": "Arista EOS",
  fedora: "Fedora",
  gcp: "Google Cloud Project",
  linuxmint: "Linux Mint",
  ipmi: "IPMI",
  microsoft365: "Microsoft 365",
};

const unknownPlatform = "Unknown Platform";

export const platformTitle = (platform?: Partial<Platform> | null): string => {
  if (!platform?.name) return unknownPlatform;
  const { name, release, arch, title } = platform;
  if (title) return capitalize(title);
  let res = platforms[name] || capitalize(name) || unknownPlatform;
  if (release) res += " " + release;
  if (arch) res += " (" + arch + ")";
  return res;
};

export const replacePlatformID = (name: string): string => {
  for (const property in platforms) {
    name = name.replace(property, platforms[property]);
  }
  return name || unknownPlatform;
};

const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  const words = s.split(" ");
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const kinds: { [s: string]: string } = {
  KIND_BARE_METAL: "Bare Metal",
  KIND_CONTAINER: "Running Container",
  KIND_CONTAINER_IMAGE: "Container Image",
  KIND_UNKNOWN: "Unknown",
  KIND_VIRTUAL_MACHINE_IMAGE: "Virtual Machine Image",
  KIND_CODE: "Code",
  KIND_PACKAGE: "Package",
  KIND_VIRTUAL_MACHINE: "Virtual Machine",
  KIND_PROCESS: "Process",
  KIND_API: "API",
};

export const platformKind = (kind: string): string => {
  let res = kinds[kind] || capitalize(kind) || "Unknown Platform Kind";
  return res;
};

const runtimes: { [s: string]: string } = {
  "docker image": "Container Image",
  "docker registry": "Container Registry",
  "aws ec2": "AWS EC2",
  "az compute": "Azure Compute",
  "gcp compute": "GCP Compute",
  "gcp gcr": "Google Container Registry",
  vsphere: "VMware vSphere",
  "vsphere vm": "VMware ESXi",
  "vsphere host": "VMware vSphere VM",
  k8s: "Kubernetes",
};

export const platformRuntime = (runtime: string): string => {
  let res =
    runtimes[runtime] || capitalize(runtime) || "Unknown Platform Runtime";
  return res;
};
