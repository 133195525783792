export const truncateText = (
  /**
   * Text to truncate.
   */
  text: string,
  /**
   * Max number of characters before truncating occurs. Defaults to `100`.
   */
  maxLen = 100,
  /**
   * Include the suffix characters within the max number of characters. Defaults to `false`.
   */
  inclusive = false,
  /**
   * Suffix string used to indicate truncated characters. Defaults to `"..."`.
   */
  suffix = "...",
) => {
  if (text.length <= maxLen) return text;
  const prefix = text.substring(0, inclusive ? maxLen - suffix.length : maxLen);
  const maxChars = inclusive ? maxLen : maxLen + suffix.length;
  return (prefix + suffix).substring(0, maxChars);
};
