import { TableCell } from "@mui/material";
import { CommonCellProps } from "~/pages/inventory/components/Vulnerabilities/types";
import { PublishedDate } from "~/pages/inventory/components/PublishedDate";

export const PublishedCell = ({ cve, ...props }: CommonCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <PublishedDate dateString={cve?.publishedAt ?? null} />
    </TableCell>
  );
};
