import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Checkbox,
  Grid,
  Paper,
  TableCell,
  TableRow,
  useTheme,
} from "@mui/material";
import {
  BarChartIcon,
  Loading,
  LoadingFailed,
  MoonIcon,
} from "~/components/ui-library";
import { useFrameworkContext } from "../framework";
import { FilterBar } from "../../filter-bar";
import { ComplianceTable, Header } from "../../compliance-table";
import {
  ComplianceFramework,
  ControlException,
  ControlsOrderField,
  ControlState,
  GetComplianceFrameworkControlsDocument,
  GetComplianceFrameworkControlsQuery,
  GetContentDocument,
  OrderDirection,
  PolicyAction,
  ReviewStatus,
  TestIamActionsQuery,
  useAssignPolicyMutation,
  useGetComplianceFrameworkControlsQuery,
  useGetContentQuery,
} from "~/operations";
import { BlockIcon } from "~/components/icons";
import { useSearch } from "~/components/search/useSearch";
import { Chip } from "~/components/chip";
import { useSnackbar } from "notistack";
import { getError } from "~/lib/handle-error";
import { LoadingButton } from "~/components/loading-button";
import { Space } from "~/lib/types";
import { CompletionCell } from "../../components/completion-cell";
import { IamActions } from "~/lib/iam";
import {
  INITIAL_PAGE_RANGE,
  Pagination,
  PaginationRange,
} from "~/components/pagination";
import { ExceptionsToolbar } from "~/components/exceptions/exceptions-toolbar";
import { useExceptions } from "~/components/exceptions/use-exceptions";
import { ExceptionsModals } from "~/components/exceptions/exceptions-modals";
import { NavigateToExceptionButton } from "~/components/exceptions/navigate-to-exception-button";
import { OutOfScopeChip } from "~/components/exceptions/out-of-scope-chip";
import { useExceptionsSelection } from "~/components/exceptions/use-exceptions-selection";
import { mapSelectedEntitiesToString } from "~/components/exceptions/utils";

type ControlsConnection = NonNullable<
  GetComplianceFrameworkControlsQuery["complianceFramework"]["controls"]
>;
type ControlEdge = NonNullable<ControlsConnection["edges"]>[0];
type ControlNode = NonNullable<ControlEdge["node"]>;

export type ControlsProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Controls({ availablePermissions }: ControlsProps) {
  const { space, framework, exceptionGroups } = useFrameworkContext();
  const [searchParams] = useSearchParams();
  const { handleFilterQuery, searchFilters } = useSearch();
  const [pageItems, setPageItems] =
    useState<PaginationRange>(INITIAL_PAGE_RANGE);
  const navigate = useNavigate();
  const frameworkMrn = searchParams.get("frameworkMrn") || "";
  const frameworkId = frameworkMrn.split("/").pop();
  const theme = useTheme();

  const {
    data: { complianceFramework } = {},
    error,
    loading,
  } = useGetComplianceFrameworkControlsQuery({
    variables: {
      input: {
        frameworkMrn: framework.mrn,
        scopeMrn: space.mrn,
      },
      controlsInput: {
        after: null,
        limit: 50,
        orderBy: {
          field:
            (searchParams.get("field") as ControlsOrderField) ||
            ControlsOrderField.Assets,
          direction:
            (searchParams.get("direction") as OrderDirection) ||
            OrderDirection.Desc,
        },
        query: searchFilters.join(","),
      },
    },
  });

  const {
    isGroupChecked,
    isGroupIndeterminate,
    onGroupCheckChange,
    selectedEntities: selectedControls,
    setSelectedEntities: setSelectedControls,
    handleNodeClick,
    handleNodeChange,
    handleCancelClick,
  } = useExceptionsSelection();

  const {
    isRemovingException,
    isSettingException,
    handleSetExceptionModalOpen,
    handleSetExceptionModalClose,
    handleRemoveExceptionModalOpen,
    handleRemoveExceptionModalClose,
    handleRemoveException,
    handleSetException,
    handleSetScope,
    loading: exceptionsLoading,
  } = useExceptions({
    onSetException: () => {
      setSelectedControls([]);
    },
    onRemoveException: () => {
      setSelectedControls([]);
    },
    scopeMrns: [space.mrn],
    controlMrns: mapSelectedEntitiesToString(selectedControls),
    refetchQueries: [GetComplianceFrameworkControlsDocument],
  });

  const { controls } = complianceFramework || {};

  if (loading) {
    return <Loading what="controls" />;
  }

  if (error || !controls) {
    return <LoadingFailed what="controls" />;
  }

  const controlEdges = controls.edges ?? [];
  const controlsArray = controlEdges.flatMap((edge) => edge?.node ?? []);

  const targetPaginatedGroup = controlsArray
    .slice(pageItems.from, pageItems.to)
    .map((control) => ({
      mrn: control.mrn as string,
      exception: control.exception,
      groupId: control.exception?.id,
    }));

  // if we're on the controls tab, we only need to include
  // the control name in the URL, otherwise we need to include "controls"
  const buildControlLink = (
    control: ControlNode,
    subpath = "",
    params: string[][] = [],
  ) => {
    const controlId = control.mrn?.split("/").pop();
    const base = `/space/compliance/${frameworkId}/controls/${controlId}`;
    const path = [base, subpath].flatMap((s) => s ?? []).join("/");
    const search = new URLSearchParams([
      ["spaceId", space.id],
      ["frameworkMrn", frameworkMrn],
      ["controlMrn", control.mrn],
      ...params,
    ]);
    return `${path}?${search}`;
  };

  const handleControlClick = (control: ControlNode) => {
    navigate(buildControlLink(control));
  };

  const handlePendingReviewClick = (exception: ControlException) => {
    navigate(
      `/space/compliance/${frameworkId}/exceptions?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&exceptionId=${exception.id}`,
    );
  };

  const hasApplyExceptionPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONMUTATION,
  );

  const tableHeaders: Header[] = [
    hasApplyExceptionPermission
      ? {
          id: "SELECT",
          label: (
            <Checkbox
              checked={isGroupChecked(targetPaginatedGroup)}
              indeterminate={isGroupIndeterminate(targetPaginatedGroup)}
              onChange={(event) =>
                onGroupCheckChange(event, targetPaginatedGroup)
              }
            />
          ),
          sortable: false,
        }
      : undefined,
    {
      id: "TITLE",
      label: "Control",
    },
    { id: "CHECKS", label: "Checks" },
    { id: "QUERIES", label: "Queries" },
    { id: "EXCEPTIONS", label: "Exceptions" },
    { id: "ASSETS", label: "Assets" },
    { id: "COMPLETION", label: "Completion" },
  ].flatMap((h) => h ?? []);

  return (
    <Grid container rowGap={3}>
      <Grid item xs={12}>
        <FilterBar
          searchId="compliance-controls"
          placeholder="compliance_controls"
          searchFilters={searchFilters}
          handleFilterQuery={handleFilterQuery}
          mb={0}
        />
      </Grid>

      <Grid item xs={12} component={Paper} pb={3}>
        <ComplianceTable
          tableHeaders={tableHeaders}
          defaultActiveColumn={ControlsOrderField.Completion}
          selection={selectedControls}
          selectable={hasApplyExceptionPermission}
        >
          {controlsArray.slice(pageItems.from, pageItems.to).map((control) => {
            const controlId = control.mrn?.split("/").pop();
            const isSelected = Boolean(
              selectedControls.find((check) => check.mrn === control.mrn),
            );
            const exception = control.exception;

            const isSnoozed = control.state === ControlState.Snoozed;
            const isDisabled = control.state === ControlState.Disabled;
            const isOutOfScope = control.state === ControlState.OutOfScope;
            const isInactive = control.state !== ControlState.Active;
            const isPending =
              exception?.reviewStatus === ReviewStatus.NotReviewed;

            const className = [
              isInactive ? "inactive" : "",
              isSelected ? "selected" : "",
            ].join(" ");

            return (
              <TableRow
                onClick={() => handleControlClick(control)}
                key={controlId}
                className={className}
              >
                {hasApplyExceptionPermission && (
                  <TableCell>
                    <Checkbox
                      onClick={(e) => handleNodeClick(e)}
                      onChange={(e, changed) =>
                        handleNodeChange(e, changed, {
                          groupId: exception?.id,
                          mrn: control.mrn,
                          exception: !exception
                            ? null
                            : {
                                justification: exception.justification,
                                action: exception.action,
                              },
                        })
                      }
                      checked={Boolean(
                        selectedControls.find(
                          (check) => check.mrn === control.mrn,
                        ),
                      )}
                    />
                  </TableCell>
                )}
                <TableCell sx={{ fontWeight: 700 }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    {isOutOfScope && (
                      <OutOfScopeChip
                        author={exception?.author.name}
                        date={exception?.createdAt}
                      />
                    )}
                    {isSnoozed && (
                      <Chip
                        label="Snoozed"
                        icon={<MoonIcon />}
                        size="small"
                        data-test-id="chip-snoozed"
                      />
                    )}
                    {isDisabled && (
                      <Chip
                        label="Disabled"
                        icon={<BlockIcon />}
                        size="small"
                        data-test-id="chip-disabled"
                      />
                    )}
                    {isPending && !isOutOfScope && (
                      <NavigateToExceptionButton
                        onClick={(e) => {
                          e.stopPropagation();
                          handlePendingReviewClick(exception);
                        }}
                      />
                    )}
                    {control.title}
                  </Box>
                </TableCell>
                <TableCell>{control?.checksStats.totalChecks}</TableCell>
                <TableCell>{control?.checksStats.totalQueries}</TableCell>
                <TableCell>-</TableCell>
                <TableCell>{control?.assetsStats.totalAssets}</TableCell>
                <TableCell>
                  <Box sx={{ ...(isInactive && { opacity: 0.25 }) }}>
                    <CompletionCell
                      assets={control?.assetsStats.totalAssets}
                      completionData={{
                        percent: control.completion,
                        height: theme.spacing(1),
                        tooltip: `Completion: ${control.completion}%`,
                      }}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            );
          })}
        </ComplianceTable>

        {selectedControls.length > 0 && (
          <ExceptionsToolbar
            target="control"
            onCancel={handleCancelClick}
            onRemoveExceptionClick={handleRemoveExceptionModalOpen}
            onSetExceptionClick={handleSetExceptionModalOpen}
            onSetScopeClick={handleSetScope}
            selectedEntities={selectedControls}
            totalCount={controlsArray.length}
          />
        )}
        <Pagination
          totalCount={complianceFramework?.controls?.totalCount ?? 0}
          setPageItems={setPageItems}
        />
      </Grid>
      <ExceptionsModals
        isSetExceptionModalOpen={isSettingException}
        isRemoveExceptionModalOpen={isRemovingException}
        onRemoveExceptionModalClose={handleRemoveExceptionModalClose}
        onSetExceptionModalClose={handleSetExceptionModalClose}
        onSetExceptionModalSave={handleSetException}
        onRemoveExceptionModalSave={handleRemoveException}
        loading={exceptionsLoading}
        target="control"
        role="compliance"
        exceptionGroups={exceptionGroups}
        selectedEntities={selectedControls}
      />
    </Grid>
  );
}

const EnableAllPoliciesButton = ({
  space,
  framework,
}: {
  space: Space;
  framework: ComplianceFramework;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { data: { content } = { content: null }, loading } = useGetContentQuery(
    {
      variables: {
        input: {
          scopeMrn: space.mrn,
          contentMrns: framework.policiesMrns,
          query: "",
        },
      },
      skip: framework.policiesMrns.length === 0,
    },
  );

  const [assignPolicyMutation, { loading: assignPolicyMutationLoading }] =
    useAssignPolicyMutation({
      refetchQueries: [
        {
          query: GetContentDocument,
          variables: {
            input: {
              scopeMrn: space.mrn,
              contentMrns: framework.policiesMrns,
              query: "",
            },
          },
        },
      ],
    });

  const { edges } = content ?? {};
  const policies = edges?.map((edge) => edge.node) ?? [];
  const recommendedPolicies = policies?.filter((policy) => !policy.assigned);

  const handleAddAllPolicies = async () => {
    try {
      await assignPolicyMutation({
        variables: {
          input: {
            action: PolicyAction.Active,
            assetMrn: space.mrn,
            // we could technically only activate the recommended policies, but this is more thorough
            policyMrns: framework.policiesMrns,
          },
        },
      });
      enqueueSnackbar("All policies are now active.", { variant: "success" });
    } catch (error) {
      enqueueSnackbar(`Failed to add all policies. ${getError(error)}`, {
        variant: "error",
      });
    }
  };

  return (
    <Box
      {...(recommendedPolicies.length === 0 && {
        display: "none",
      })}
    >
      <LoadingButton
        variant="outlined"
        color="primary"
        startIcon={<BarChartIcon />}
        onClick={handleAddAllPolicies}
        loading={assignPolicyMutationLoading || loading}
        buttonText="Enable All Policies"
      />
    </Box>
  );
};
