import React from "react";
import { useViewer } from "~/providers/viewer";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { Grid2 as Grid, TextField, Typography } from "@mui/material";
import { LoadingButton } from "~/components/loading-button";
import { ValidationMessage } from "~/pages/integrations/validations/helpers";
import { Validations } from "~/lib/validations";
import { Flex } from "~/components/ui-library";
import {
  FieldErrors,
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
} from "react-hook-form";
import { CreateFormInput } from "~/pages/space/Workspaces/components/FormFields/types";

export type CreateWorkspaceProps = {
  register: UseFormRegister<CreateFormInput>;
  errors: FieldErrors<CreateFormInput>;
  disabled: boolean;
  isLoading: boolean;
  handleSubmit: UseFormHandleSubmit<CreateFormInput>;
  onSubmit: SubmitHandler<CreateFormInput>;
};

export function CreateWorkspaceFormFields({
  register,
  errors,
  onSubmit,
  disabled,
  isLoading,
  handleSubmit,
}: CreateWorkspaceProps) {
  const { viewer } = useViewer();

  if (!viewer) {
    return <LoadingPage what="workspaces" />;
  }

  if (viewer.organizations?.length === 0) {
    return <LoadingFailedPage what="organization" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container size={12} gap={2}>
        <Flex flexDirection="column" sx={{ width: "100%" }} gap={1}>
          <Typography fontWeight={700}>Name</Typography>
          <TextField
            id="name-input"
            fullWidth
            placeholder="What’s a unique name for this workspace?"
            variant="outlined"
            slotProps={{
              input: {
                sx: { backgroundColor: "code.background" },
              },
            }}
            error={Boolean(errors.name)}
            helperText={
              Boolean(errors.name) && <ValidationMessage error={errors.name} />
            }
            disabled={false}
            {...register("name", {
              validate: {
                required: (value) => {
                  if (
                    value == null ||
                    !value.match(Validations.nameInvalidLengthRegex)
                  ) {
                    return Validations.nameInvalidLengthErrorMsg;
                  } else if (
                    !value.match(Validations.nameInvalidCharactersRegex)
                  ) {
                    return Validations.nameInvalidCharactersErrorMsg;
                  }

                  return true;
                },
              },
            })}
          />
        </Flex>
        <Flex flexDirection="column" sx={{ width: "100%" }} gap={1}>
          <Typography fontWeight={700}>
            Description
            <>
              {" "}
              <Typography component="span" color="text.secondary">
                (optional)
              </Typography>
            </>
          </Typography>
          <TextField
            id="description-input"
            fullWidth
            multiline
            minRows={3}
            placeholder="What is this workspace going to be used for?"
            variant="outlined"
            disabled={false}
            slotProps={{
              input: {
                sx: { backgroundColor: "code.background" },
              },
            }}
            {...register("description")}
          />
        </Flex>

        <Grid size={{ xs: 12 }} sx={{ textAlign: "center", mt: 10 }}>
          <LoadingButton
            type="submit"
            buttonText="Create Workspace"
            variant="contained"
            color="primary"
            disabled={disabled}
            loading={isLoading}
          />
        </Grid>
      </Grid>
    </form>
  );
}
