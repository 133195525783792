import { IconButton, IconButtonProps, Tooltip } from "@mui/material";
import { SpeechBubbleIcon } from "~/pages/compliance/exception-icon";

export const NavigateToExceptionButton = ({
  onClick,
}: {
  onClick: IconButtonProps["onClick"];
}) => {
  return (
    <Tooltip title="Exception pending review" placement="top" arrow>
      <IconButton onClick={onClick}>
        <SpeechBubbleIcon color="warning" fontSize="small" />
      </IconButton>
    </Tooltip>
  );
};
