import { alpha, Box, ButtonBase, Grid, Typography } from "@mui/material";
import { getColor } from "~/lib/colors";
import { numberKFormat } from "~/lib/number-k-formatting";

export type GridData = {
  title: string;
  score?: number;
  total?: number;
};

type Props = {
  data: GridData;
  color?: boolean;
  showTotal?: boolean;
  handleCategoryFilterClick?: (category: string) => void;
  isSelected?: boolean;
  segments?: number;
};

export function GridSegment({
  data,
  color,
  showTotal = true,
  handleCategoryFilterClick,
  isSelected,
  segments = 4,
}: Props) {
  return (
    <Grid item xs={6} md={segments === 5 ? 2.4 : 3}>
      <Box
        {...(handleCategoryFilterClick && {
          component: ButtonBase,
          onClick: () => handleCategoryFilterClick?.(data.title),
        })}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          p: 3,
          maxHeight: 160,
          height: 160,
          backgroundColor: (theme) =>
            color
              ? alpha(getColor(theme, data?.title), 0.1)
              : "background.light",
          textAlign: "right",
          borderRadius: 1,
          ...(isSelected && {
            boxShadow: (theme) =>
              `0px 0px 0px 1px ${theme.palette.primary.main}`,
          }),
          ...(handleCategoryFilterClick && {
            width: "100%",
            alignItems: "flex-end",
            cursor: "pointer",
            "&:hover, &:focus-visible": {
              background: (theme) => alpha(theme.palette.primary.main, 0.2),
              opacity: 1,
              transition: "all .35s ease-in-out",
            },
          }),
        }}
      >
        <Typography
          fontWeight={700}
          sx={{
            color: (theme) =>
              color ? getColor(theme, data?.title) : "text.primary",
            textTransform: "uppercase",
          }}
        >
          {data?.title}
        </Typography>
        <Typography
          fontWeight={700}
          sx={{
            display: "flex",
            flexDirection: "column",
            color: (theme) =>
              color ? getColor(theme, data?.title) : "text.primary",
            fontSize: (theme) => theme.typography.fontSize * 2.85,
          }}
        >
          {numberKFormat.format(data.score || 0)}
          {showTotal && (
            <Typography component="span">
              /{numberKFormat.format(data.total || 0)}
            </Typography>
          )}
        </Typography>
      </Box>
    </Grid>
  );
}
