import { Theme } from "@mui/material";

export type CvssVersion = 3 | 2;

export type CvssFieldValue = {
  title: string;
  acronym: string;
  value?: number;
  scalar: number;
  color: string;
};
export type CvssFieldValues = { [valueId: string]: CvssFieldValue };
export type CvssField = {
  title: string;
  description: string;
  acronym: string;
  values: CvssFieldValues;
};
export type CvssFields = { [fieldId: string]: CvssField | null };

export type CvssMetric = { field: CvssField; value: CvssFieldValue };

export const cvssMetric =
  (fields: CvssFields) =>
  (str: string): CvssMetric | null => {
    const [id, v] = str.split(":");
    const field = fields[id];

    if (field == null) {
      console.error(`CVSS: Cannot find field "${id}""`);
      return null;
    }

    const value = field.values[v];
    if (value == null) {
      console.error(
        `CVSS: Cannot find value "${v}" of field "${field.acronym}"`,
      );
      return null;
    }

    return { field, value };
  };

// Common Vulnerability Scoring System version 3.1: Specification Document
// https://www.first.org/cvss/specification-document

// A Complete Guide to the Common Vulnerability Scoring System
// https://www.first.org/cvss/v2/guide

// In both cvss2Fields and cvss3Fields - In order to reference our theme
// properties, it was necessary to pass
// the theme down from its closeset parent component
export const cvss2Fields = (theme: Theme): CvssFields => {
  return {
    av: {
      title: "Access Vector",
      acronym: "AV",
      description:
        "This metric reflects how the vulnerability is exploited. The more remote an attacker can be to attack a host, the greater the vulnerability score.",
      values: {
        n: {
          title: "Network",
          acronym: "N",
          value: 1.0,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        a: {
          title: "Adjacent Network",
          acronym: "A",
          value: 0.646,
          scalar: 0.65,
          color: theme.palette.medium.main,
        },
        l: {
          title: "Local",
          acronym: "L",
          value: 0.395,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    ac: {
      title: "Access Complexity",
      acronym: "AC",
      description:
        "This metric measures the complexity of the attack required to exploit the vulnerability once an attacker has gained access to the target system.",
      values: {
        l: {
          title: "Low",
          acronym: "L",
          value: 0.71,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        m: {
          title: "Medium",
          acronym: "M",
          value: 0.61,
          scalar: 0.65,
          color: theme.palette.medium.main,
        },
        h: {
          title: "High",
          acronym: "H",
          value: 0.35,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    au: {
      title: "Authentication",
      acronym: "Au",
      description:
        "This metric measures the number of times an attacker must authenticate to a target in order to exploit a vulnerability.",
      values: {
        n: {
          title: "None",
          acronym: "N",
          value: 0.704,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        s: {
          title: "Single",
          acronym: "S",
          value: 0.56,
          scalar: 0.65,
          color: theme.palette.medium.main,
        },
        m: {
          title: "Multiple",
          acronym: "M",
          value: 0.45,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    c: {
      title: "Confidentiality",
      acronym: "C",
      description:
        "This metric measures the impact on confidentiality of a successfully exploited vulnerability.",
      values: {
        c: {
          title: "Complete",
          acronym: "C",
          value: 0.66,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        p: {
          title: "Partial",
          acronym: "P",
          value: 0.275,
          scalar: 0.65,
          color: theme.palette.medium.main,
        },
        n: {
          title: "None",
          acronym: "N",
          value: 0.0,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    i: {
      title: "Impact",
      acronym: "I",
      description:
        "This metric measures the impact to integrity of a successfully exploited vulnerability.",
      values: {
        c: {
          title: "Complete",
          acronym: "C",
          value: 0.66,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        p: {
          title: "Partial",
          acronym: "P",
          value: 0.275,
          scalar: 0.65,
          color: theme.palette.medium.main,
        },
        n: {
          title: "None",
          acronym: "N",
          value: 0.0,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    a: {
      title: "Availability",
      acronym: "A",
      description:
        "This metric measures the impact to availability of a successfully exploited vulnerability.",
      values: {
        c: {
          title: "Complete",
          acronym: "C",
          value: 0.66,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        p: {
          title: "Partial",
          acronym: "P",
          value: 0.275,
          scalar: 0.65,
          color: theme.palette.medium.main,
        },
        n: {
          title: "None",
          acronym: "N",
          value: 0.0,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
  };
};

export const cvss3Fields = (theme: Theme): CvssFields => {
  return {
    av: {
      title: "Attack Vector",
      acronym: "AV",
      description:
        "This metric reflects the context by which vulnerability exploitation is possible. This metric value will be larger the more remote an attacker can be in order to exploit the vulnerable component.",
      values: {
        n: {
          title: "Network",
          acronym: "N",
          value: 0.85,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        a: {
          title: "Adjacent",
          acronym: "A",
          value: 0.62,
          scalar: 0.75,
          color: theme.palette.high.main,
        },
        l: {
          title: "Local",
          acronym: "L",
          value: 0.55,
          scalar: 0.5,
          color: theme.palette.medium.main,
        },
        p: {
          title: "Physical",
          acronym: "P",
          value: 0.2,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    ac: {
      title: "Attack Complexity",
      acronym: "AC",
      description:
        "This metric describes the conditions beyond the attacker's control that must exist in order to exploit the vulnerability.",
      values: {
        l: {
          title: "Low",
          acronym: "L",
          value: 0.77,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        h: {
          title: "High",
          acronym: "H",
          value: 0.44,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    pr: {
      title: "Privileges Required",
      acronym: "PR",
      description:
        "This metric describes the level of privileges an attacker must possess before successfully exploiting the vulnerability.",
      values: {
        n: {
          title: "None",
          acronym: "N",
          value: 0.85,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        l: {
          title: "Low",
          acronym: "L",
          value: 0.62,
          scalar: 0.63,
          color: theme.palette.high.main,
        },
        h: {
          title: "High",
          acronym: "H",
          value: 0.27,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    ui: {
      title: "User Interaction",
      acronym: "UI",
      description:
        "This metric captures the requirement for a user, other than the attacker, to participate in the successful compromise of the vulnerable component.",
      values: {
        n: {
          title: "None",
          acronym: "N",
          // value: 0.85,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        r: {
          title: "Required",
          acronym: "R",
          // value: 0.62,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    s: {
      title: "Scope",
      acronym: "S",
      description:
        "Scope refers to the collection of privileges defined by a computing authority when granting access to computing resources.",
      values: {
        c: {
          title: "Changed",
          acronym: "C",
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        u: {
          title: "Unchanged",
          acronym: "U",
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    c: {
      title: "Confidentiality",
      acronym: "C",
      description:
        "This metric measures the impact to the confidentiality of the information resources managed by a software component due to a successfully exploited vulnerability.",
      values: {
        h: {
          title: "High",
          acronym: "H",
          // value: 0,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        l: {
          title: "Low",
          acronym: "L",
          // value: 0.22,
          scalar: 0.63,
          color: theme.palette.high.main,
        },
        n: {
          title: "None",
          acronym: "N",
          // value: 0.56,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    i: {
      title: "Integrity",
      acronym: "I",
      description:
        "This metric measures the impact to integrity of a successfully exploited vulnerability. Integrity refers to the trustworthiness and veracity of information.",
      values: {
        h: {
          title: "High",
          acronym: "H",
          // value: 0,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        l: {
          title: "Low",
          acronym: "L",
          // value: 0.22,
          scalar: 0.63,
          color: theme.palette.high.main,
        },
        n: {
          title: "None",
          acronym: "N",
          // value: 0.56,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
    a: {
      title: "Availability",
      acronym: "A",
      description:
        "This metric measures the impact to the availability of the impacted component resulting from a successfully exploited vulnerability.",
      values: {
        h: {
          title: "High",
          acronym: "H",
          // value: 0,
          scalar: 1.0,
          color: theme.palette.critical.main,
        },
        l: {
          title: "Low",
          acronym: "L",
          // value: 0.22,
          scalar: 0.63,
          color: theme.palette.high.main,
        },
        n: {
          title: "None",
          acronym: "N",
          // value: 0.56,
          scalar: 0.25,
          color: theme.palette.low.main,
        },
      },
    },
  };
};

export const cvssScore = (vector: string): number => {
  return parseFloat(vector.split("/")[0]);
};

export const cvssVersion = (vector: string): CvssVersion => {
  return vector.includes("CVSS:3") ? 3 : 2;
};

export const parseCvss = (vector: string, theme: Theme) => {
  const parts = vector.split("/");
  const score = cvssScore(vector);
  const version = cvssVersion(vector);
  const fields = version === 3 ? cvss3Fields(theme) : cvss2Fields(theme);
  const rawMetrics = version === 3 ? parts.slice(2) : parts.slice(1);
  const metrics = rawMetrics.flatMap(
    (m) => cvssMetric(fields)(m.toLowerCase()) ?? [],
  );
  return {
    version,
    score,
    metrics,
  };
};
