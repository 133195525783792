import { ReactNode } from "react";
import { useSnackbar } from "notistack";
import {
  Box,
  IconButton,
  SxProps,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { Flex } from "~/components/ui-library";
import { useSearchParams } from "react-router-dom";
import {
  LoadResourceInvitationsDocument,
  LoadResourceInvitationsQuery,
  useCancelInvitationMutation,
  useLoadResourceInvitationsQuery,
  useResendInvitationMutation,
} from "~/operations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { CancelIcon, OrgIcon, ReplayIcon, SpaceIcon } from "~/components/icons";
import { DataTable } from "~/components/data-table";
import { roleName } from "./members-mgmt";
import { AddButton } from "~/components/add-button";

type Invitation = NonNullable<
  NonNullable<
    NonNullable<LoadResourceInvitationsQuery["invitations"]>["edges"]
  >[0]["node"]
>;

export type ResourceInvitationsProps = {
  mrn: string;
  handleAddMemberClick?: () => void;
};

export function ResourceInvitations({
  mrn,
  handleAddMemberClick,
}: ResourceInvitationsProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const { loading, data, error } = useLoadResourceInvitationsQuery({
    variables: { resourceMrn: mrn },
  });

  const [cancelInvitation, cancelResponse] = useCancelInvitationMutation({
    refetchQueries: [LoadResourceInvitationsDocument],
  });

  const [resendInvitation] = useResendInvitationMutation({
    refetchQueries: [LoadResourceInvitationsDocument],
  });

  if (loading) {
    return <LoadingPage what="invitations" />;
  }

  if (error || !data?.invitations) {
    return <LoadingFailedPage what="invitations" />;
  }

  const query = searchParams.get("query");
  const allInvitations =
    data.invitations.edges?.flatMap((e) => (e.node ? e.node : [])) || [];
  const filteredInvitations = allInvitations.filter(
    ({ senderEmail, inviteeEmail }) => {
      if (!query) return true;
      const filterable = [senderEmail, inviteeEmail];
      return filterable.some((value) =>
        value.toLowerCase().includes(query.toLowerCase()),
      );
    },
  );

  if (allInvitations.length === 0) {
    return <NoInvitationsView />;
  }

  const cancel = async (invite: Invitation) => {
    try {
      await cancelInvitation({ variables: { invitationMrn: invite.mrn } });
    } catch {
      if (cancelResponse.error?.message.includes("PermissionDenied")) {
        enqueueSnackbar(
          "You do not have permission to delete invitations you don't own.",
          { variant: "error" },
        );
      } else if (cancelResponse.called) {
        enqueueSnackbar("Ran into problem when canceling invitation.", {
          variant: "error",
        });
      }
    }
  };

  const resend = async (invite: Invitation) => {
    try {
      await resendInvitation({ variables: { invitationMrn: invite.mrn } });
      enqueueSnackbar("Invitation successfully resent", {
        variant: "success",
      });
    } catch {
      enqueueSnackbar("Failed to resend invitation", {
        variant: "error",
      });
    }
  };

  const resourceLabel = (resourceMrn: string) => {
    const re = /\/(spaces|organizations)\/([\w\d-]+)\/{0,1}/;
    const matches = resourceMrn.match(re);
    if (!matches) {
      return undefined;
    }

    const icon = resourceMrn.includes("organizations") ? (
      <OrgIcon sx={{ mr: 1 }} />
    ) : (
      <SpaceIcon sx={{ mr: 1 }} />
    );

    return (
      <Flex alignItems="center">
        {icon} {matches[2]}
      </Flex>
    );
  };

  const handleFilterChange: TextFieldProps["onChange"] = (e) => {
    const query = e.target.value;
    if (query === "") {
      searchParams.delete("query");
    } else {
      searchParams.set("query", query);
    }
    setSearchParams(searchParams, { replace: true });
  };

  return (
    <Box>
      <Box sx={{ display: "flex", mb: 3 }}>
        <TextField
          placeholder="Filter invitations..."
          value={query}
          onChange={handleFilterChange}
          fullWidth
          InputProps={{
            sx: {
              backgroundColor: (theme) => theme.palette.code.background,
            },
          }}
        />
        {handleAddMemberClick && (
          <Box sx={{ pl: 3, pt: 1 }}>
            <AddButton
              id="invite-member-button"
              onClick={handleAddMemberClick}
              aria-label="Invite Member"
            />
          </Box>
        )}
      </Box>
      <DataTable>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell key={header.id} sx={{ ...header.options }}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredInvitations.map((invite) => (
            <TableRow key={invite.mrn}>
              <TableCell>{invite.senderEmail}</TableCell>
              <TableCell>{invite.inviteeEmail}</TableCell>
              <TableCell>{resourceLabel(invite.resourceMrn)}</TableCell>
              <TableCell>{roleName(invite.roleMrn)}</TableCell>
              <TableCell>{invite.state}</TableCell>
              <TableCell>
                <Flex justifyContent="flex-end">
                  <Tooltip title="Re-send invitation">
                    <IconButton
                      aria-label="Re-send invitation"
                      onClick={() => resend(invite)}
                    >
                      <ReplayIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Cancel invitation">
                    <IconButton
                      aria-label="Cancel invitation"
                      onClick={() => cancel(invite)}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </Flex>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </DataTable>
    </Box>
  );
}

type Header = {
  id: string;
  label: ReactNode;
  colSpan?: number;
  sortable?: boolean;
  options?: SxProps;
};

const tableHeaders: Header[] = [
  {
    id: "FROM",
    label: "From",
  },
  {
    id: "TO",
    label: "To",
  },
  {
    id: "RESOURCE",
    label: "Resource",
  },
  { id: "ROLE", label: "Role" },
  {
    id: "STATE",
    label: "State",
  },
  {
    id: "ACTIONS",
    label: undefined,
  },
];

const NoInvitationsView = () => {
  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      sx={{
        py: 10,
        px: 2,
        textAlign: "center",
        border: "3px dashed",
        borderColor: "background.light",
      }}
    >
      <Typography variant="h6" component="p" color="text.secondary">
        No invitations
      </Typography>
    </Flex>
  );
};
