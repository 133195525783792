import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { PrintAssetHeader } from "./PrintAssetHeader";
import { PrintPolicies } from "./print-policies";
import { useLoadAssetQuery } from "~/operations";
import { Grid } from "@mui/material";
import { PrintPoliciesList } from "./print-policies-list";

type PrintAssetProps = {
  assetMrn: string;
};

export function PrintAsset({ assetMrn }: PrintAssetProps) {
  const { data, error, loading } = useLoadAssetQuery({
    variables: {
      mrn: assetMrn,
    },
  });

  const asset = data?.asset;

  if (loading) {
    return <LoadingPage what="asset" />;
  }

  if (error || !asset) {
    return <LoadingFailedPage what="asset" />;
  }

  return (
    <Grid container rowSpacing={4} py={4}>
      <PrintAssetHeader asset={asset} />
      <PrintPoliciesList asset={asset} />
      <PrintPolicies asset={asset} />
    </Grid>
  );
}
