import {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  ReactElement,
} from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Slide,
  CircularProgress,
} from "@mui/material";
import { FilterListSharp } from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import Flex from "../../layouts/Flex";
import Text from "../../typography/Text";
import TextTitle from "../../typography/TextTitle";
import Select from "../../atoms/Select";
import Search from "../../atoms/Search";
import SelectedFiltersPortal from "./SelectedFiltersPortal";
import { findDifference } from "../../../utils";
import { FieldOption, FilterOption, Obj } from "../../../types";
import { CloseIcon } from "~/components/icons";

type FiltersModalProps = {
  filtersLoading?: boolean;
  filtersErrors?: string[];
  filterOptions: FilterOption[];
  filters: Obj;
  onChangeHandler: (values: Obj) => void;
  refEle?: null | HTMLElement;
};

const FiltersModal = ({
  filtersLoading,
  filtersErrors,
  filterOptions,
  filters,
  onChangeHandler,
  refEle,
}: FiltersModalProps) => {
  const [open, setOpen] = useState(false);
  const defaultValues = filterOptions.reduce((acc, option) => {
    acc[option.name] = option.value;
    return acc;
  }, {} as Obj);
  const [values, setValues] = useState(filters);
  const [appliedValues, setAppliedValues] = useState<Obj>([]);

  useEffect(() => {
    setValues(filters);
    setAppliedValues(findDifference({ defaultValues, newValues: filters }));
  }, [filters]);

  const onChangeFilters = useCallback(({ name, value }: FieldOption) => {
    setValues((v) => ({
      ...v,
      [name]: Array.isArray(value) && value.includes("") ? [] : value,
    }));
  }, []);

  const onResetFilters = useCallback(() => {
    setValues(defaultValues);
  }, []);

  const onCancel = useCallback(() => {
    setOpen(false);
    setValues(filters);
  }, [filters]);

  const onApply = useCallback(() => {
    onChangeHandler({
      ...values,
    });
    setOpen(false);
  }, [values]);

  const onDelete = useCallback(
    ({
      filterName,
      valueToRemove,
    }: Record<"filterName" | "valueToRemove", string>) => {
      const valueToChange = filters[filterName];
      const initialValue = defaultValues[filterName];
      const filtersToChange = {
        ...filters,
        [filterName]: Array.isArray(valueToChange)
          ? valueToChange.filter((v) => v !== valueToRemove)
          : initialValue,
      };

      setValues(filtersToChange);
      onChangeHandler({
        ...filtersToChange,
      });
    },
    [filters],
  );

  return (
    <>
      <SelectedFiltersPortal
        refEle={refEle}
        appliedValues={appliedValues}
        onDelete={onDelete}
      />
      <IconButton onClick={() => setOpen(true)}>
        <FilterListSharp />
      </IconButton>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={onCancel}
        fullWidth
      >
        <DialogTitle>
          <TextTitle>FILTERS</TextTitle>
          <IconButton
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={(theme) => ({
            paddingTop: "10px !important",
            display: "flex",
            flexDirection: "column",
            gridGap: theme.spacing(3),
          })}
        >
          {filterOptions.map((filterOption) => {
            const value = values[filterOption.name];

            if (
              filterOption.type === "select" &&
              Number(filterOption.options?.length) > 0
            ) {
              return (
                <Select
                  key={filterOption.name}
                  {...filterOption}
                  value={value}
                  onChangeHandler={onChangeFilters}
                  fullWidth
                />
              );
            } else if (filterOption.type === "search") {
              return (
                <Search
                  key={filterOption.name}
                  {...filterOption}
                  value={value}
                  onChangeHandler={onChangeFilters}
                  fullWidth
                />
              );
            }

            return null;
          })}
          {filtersLoading && (
            <Flex center>
              <CircularProgress />
            </Flex>
          )}
          {filtersErrors && filtersErrors?.length > 0 && (
            <Box>
              <Text>Errors</Text>
              {filtersErrors.map((error) => (
                <Text key={String(error)}>{String(error)}</Text>
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            mb: 2,
            px: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button onClick={onResetFilters} sx={{ color: "#8797FF" }}>
            Reset all Filters
          </Button>
          <Button variant="contained" onClick={onApply}>
            Show Results
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FiltersModal;

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
