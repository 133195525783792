import { Chip as MuiChip, styled } from "@mui/material";

export const Chip = styled(MuiChip)((props) => {
  const variant = props.variant || "outlined";
  const defaultColor = props.theme.palette.text.disabled;
  const color =
    props.color && props.color !== "default"
      ? props.theme.palette[props.color].main
      : defaultColor;
  return {
    color,
    borderRadius: props.theme.shape.borderRadius,
    backgroundColor: variant === "outlined" ? "transparent" : "currentcolor",
    border: variant === "outlined" ? "1px solid" : "none",
    borderColor: "currentcolor",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: 600,

    ".MuiChip-icon": {
      color: "currentColor",
    },
  };
});
