import { FormEvent, useEffect, useReducer, useState } from "react";
import {
  User,
  AuthError,
  ParsedToken,
  UserInfo,
  UserMetadata,
  IdTokenResult,
} from "firebase/auth"; // TODO: do we really need firebase here?
import {
  AuthContextProps,
  AuthProvider,
  AuthProviderProps as OidcAuthProviderProps,
  useAuth as useOidcAuth,
} from "react-oidc-context";
import { User as OidcAuthUser, WebStorageStateStore } from "oidc-client-ts";
import {
  AuthProviderProps,
  authReducer,
  Dispatch,
  AuthContext,
  SignupProps,
  initialState,
} from "./auth-context";

import { GeolockDialog } from "~/components/geolock-dialog";
import { OidcLogin } from "~/unauthenticatedApp/components/oidcLogin";
import { Config } from "~/configuration_provider";

export class OidcParsedToken implements ParsedToken {
  "exp"?: string;
  "sub"?: string;
  "auth_time"?: string;
  "iat"?: string;
  "firebase"?: {
    sign_in_provider?: string;
    sign_in_second_factor?: string;
  };
  [key: string]: string | object | undefined;
}

export class OicdIdTokenResult implements IdTokenResult {
  authTime: string = "";
  expirationTime: string = "";
  issuedAtTime: string = "";
  signInProvider: string | null = "";
  signInSecondFactor: string | null = null;
  token: string = "";
  claims: ParsedToken = new OidcParsedToken(); // TODO: implement
}

export class OidcUser implements User {
  phoneNumber: string | null = null;
  photoURL: string | null = null;
  providerId: string = "email";
  isAnonymous: boolean = false;
  metadata: UserMetadata = {};
  providerData: UserInfo[] = [
    {
      providerId: "OIDC",
      uid: "OIDCUID",
      displayName: "",
      email: "",
      phoneNumber: null,
      photoURL: null,
    },
  ];
  refreshToken: string = "";
  tenantId: string | null = "";

  constructor(public oidcAuth: AuthContextProps) {}

  get token() {
    return this.oidcAuth.user?.access_token;
  }

  get uid() {
    return this.oidcAuth.user?.profile.sub || "";
  }

  get displayName() {
    return this.oidcAuth.user?.profile.name || "";
  }

  get email() {
    return this.oidcAuth.user?.profile.email || "";
  }

  get emailVerified() {
    return true; // we won't verify oidc users
    // return !!this.oidcAuth.user?.profile.email_verified;
  }

  delete(): Promise<void> {
    return Promise.resolve();
  }

  getIdToken(forceRefresh?: boolean): Promise<string> {
    return forceRefresh
      ? this.oidcAuth
          .signinSilent()
          .then((oidcAuthUser) => oidcAuthUser?.id_token || "")
      : Promise.resolve(this.oidcAuth.user?.id_token || "");
  }

  getIdTokenResult(forceRefresh?: boolean): Promise<IdTokenResult> {
    return Promise.resolve(new OicdIdTokenResult());
  }

  reload(): Promise<void> {
    return Promise.resolve();
  }

  toJSON(): object {
    return {};
  }
}

// Error codes can be found at
// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error#code
const handleErrorMessage = (errorCode: string) => {
  let customError = "Oops, something went wrong. Please give it another try.";
  switch (errorCode) {
    default:
      return customError;
  }
};

export function MondooOidcProvider({ children }: AuthProviderProps) {
  // Place some of these values in .env file?
  const oidcConfig: OidcAuthProviderProps = {
    authority: Config.VITE_AUTH_PROVIDER_OIDC_AUTHORITY || "",
    client_id: Config.VITE_AUTH_PROVIDER_OIDC_CLIENTID || "",
    redirect_uri: Config.VITE_AUTH_PROVIDER_OIDC_REDIRECT_URL || "",
    scope: Config.VITE_AUTH_PROVIDER_OIDC_SCOPE || "",
    onSigninCallback: (
      _oidcAuthUser: OidcAuthUser | void,
    ): Promise<void> | void => {
      window.history.replaceState({}, document.title, window.location.pathname);
    },
    userStore: new WebStorageStateStore({
      store: window.localStorage,
    }),
  };

  return (
    <AuthProvider {...oidcConfig}>
      <OidcAuthProvider>{children}</OidcAuthProvider>
    </AuthProvider>
  );
}

export function OidcAuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [hasFetchedUser, setHasFetchedUser] = useState<boolean>(false);
  const auth = useOidcAuth();

  useEffect(() => {
    if (auth.isAuthenticated) {
      dispatch({
        type: "set user success",
        user: new OidcUser(auth),
        idTokenResult: null,
      });
      setHasFetchedUser(true);
    }
  }, [auth]);

  const loginWithProvider = async () => {
    dispatch({ type: "set user begin" });
    auth.signinRedirect();
  };

  if (!auth.isAuthenticated) {
    return <OidcLogin onSignInClick={loginWithProvider} auth={auth} />;
  }

  const logout = async () => {
    try {
      auth.removeUser();
      // reset heap analytics identity for a new anonymous session
      window.heap.resetIdentity();
      location.reload();
    } catch (error) {
      console.log("%c---- ERROR LOGGING OUT", "background: pink; color: black");
      const message = handleErrorMessage((error as AuthError).code);
      dispatch({ type: "set user failure", error: message });
    }
  };

  const loginWithSSO = async (
    event: FormEvent,
    dispatch: Dispatch,
    _orgId: string,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
  };

  const signupWithEmailAndPassword = async (
    event: FormEvent,
    dispatch: Dispatch,
    _props: SignupProps,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
  };

  const loginWithEmailAndPassword = async (
    event: FormEvent,
    dispatch: Dispatch,
    _props: { email: string; password: string },
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
  };

  const sendResetPasswordEmail = async (
    dispatch: Dispatch,
    _email: string,
    event?: FormEvent,
  ) => {
    event?.preventDefault();
    dispatch({ type: "reset password begin" });
  };

  const sendUserEmailVerification = async () => {};

  const changePassword = async (
    _oldPassword: string,
    _newPassword: string,
  ): Promise<void> => {
    const { user } = state;
    if (!user?.email) {
      return Promise.reject("Unable to update password");
    }

    return Promise.resolve();
  };

  const clearErrors = () => {
    dispatch({ type: "clear errors" });
  };

  const setGeolock = (isGeolocked: boolean) => {
    dispatch({ type: "set geolock", isGeolocked });
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        logout,
        loginWithProvider,
        signupWithEmailAndPassword,
        loginWithEmailAndPassword,
        loginWithSSO,
        sendResetPasswordEmail,
        sendUserEmailVerification,
        changePassword,
        clearErrors,
        setGeolock,
        handleErrorMessage,
      }}
    >
      {hasFetchedUser && children}
      {state.isGeolocked && <GeolockDialog />}
    </AuthContext.Provider>
  );
}
