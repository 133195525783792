import { ReactNode } from "react";
import { Typography } from "@mui/material";

export type DropzoneTextProps = {
  children: ReactNode;
};

export function DropzoneText({ children }: DropzoneTextProps) {
  return (
    <Typography variant="h5" component="p" textAlign="center">
      {children}
    </Typography>
  );
}
