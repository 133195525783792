import { createSvgIcon } from "@mui/material";

export const JiraIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M46.8694 22.6562L26.2136 2.00042L24.2117 0L1.55547 22.6562C0.814843 23.3989 0.814843 24.6011 1.55547 25.3423L15.7611 39.5474L24.2117 48L46.8694 25.3423C47.6105 24.6011 47.6105 23.3989 46.8694 22.6562ZM24.2117 31.0947L17.1167 24.0003L24.2117 16.9032L31.3082 24.0003L24.2117 31.0947Z"
      fill="#2684FF"
    />
    <path
      d="M24.2117 16.9034C19.5666 12.2563 19.5429 4.73001 24.1622 0.0556641L8.63281 15.5791L17.0854 24.0317L24.2117 16.9034Z"
      fill="url(#paint0_linear_19441_287129)"
    />
    <path
      d="M31.3281 23.98L24.2119 31.0946C26.4545 33.3352 27.7158 36.3764 27.7158 39.5473C27.7158 42.7181 26.4545 45.7573 24.2119 47.9999L39.7787 32.4326L31.3281 23.98Z"
      fill="url(#paint1_linear_19441_287129)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_19441_287129"
        x1="21.1619"
        y1="11.5091"
        x2="13.036"
        y2="19.6329"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_19441_287129"
        x1="27.3495"
        y1="36.412"
        x2="35.4591"
        y2="28.3023"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </svg>,
  "Jira",
);
