import { createSvgIcon } from "@mui/material";

export const MsftDefenderIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path fill="#0370c8" d="M24,44c-0.552,0-1-0.448-1-1s0.448-1,1-1V44z" />
    <path
      fill="#0f5094"
      d="M25,43c0,0.552-0.448,1-1,1v-2C24.552,42,25,42.448,25,43z"
    />
    <circle cx="42" cy="11" r="1" fill="#0883d9" />
    <circle cx="6" cy="11" r="1" fill="#33bff0" />
    <path
      fill="#0f5094"
      d="M24,43l0.427,0.907c0,0,15.144-7.9,18.08-19.907H24V43z"
    />
    <path
      fill="#0883d9"
      d="M43,11l-1-1c-11.122,0-11.278-6-18-6v20h18.507C42.822,22.712,43,21.378,43,20C43,16.856,43,11,43,11	z"
    />
    <path
      fill="#0370c8"
      d="M24,43l-0.427,0.907c0,0-15.144-7.9-18.08-19.907H24V43z"
    />
    <path
      fill="#33bff0"
      d="M5,11l1-1c11.122,0,11.278-6,18-6v20H5.493C5.178,22.712,5,21.378,5,20C5,16.856,5,11,5,11z"
    />
  </svg>,
  "Microsoft Defender for Cloud",
);
