import { ChangeEvent, Fragment, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TableBody,
  Typography,
  useTheme,
  Box,
  Button,
  Checkbox,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  LoadCicdProjectJobsDocument,
  LoadCicdProjectJobsQuery,
  useDeleteAssetsMutation,
} from "~/operations";
import { AccessTimeIcon } from "~/components/icons";
import { ClickableTableRow } from "~/components/report";
import { getColor } from "~/lib/colors";
import { pluralize } from "~/lib/pluralize";
import { KeyValue, Space } from "~/lib/types";
import { Header, JobsTypes } from "../../pages/cicd/jobs";
import { FormatRelativeDateAbbreviated, FormatTime } from "~/lib/date";
import { useJobs } from "~/providers/jobs";
import { CicdSearch } from "../cicd-search";
import { DataTable, SelectionToolbar } from "../data-table";

type CicdProjectJobs = NonNullable<LoadCicdProjectJobsQuery["cicdProjectJobs"]>;
type Project = CicdProjectJobs["project"];
type JobsConnection = NonNullable<CicdProjectJobs["jobs"]>;
type JobsEdge = NonNullable<JobsConnection["edges"]>[0];
type Job = JobsEdge["node"];
type AzureDevopsJob = Extract<Job, { __typename: "AzureDevopsJob" }>;
type CircleCIJob = Extract<Job, { __typename: "CircleCIJob" }>;
type GithubJob = Extract<Job, { __typename: "GithubJob" }>;
type GitlabJob = Extract<Job, { __typename: "GitlabJob" }>;
type JenkinsJob = Extract<Job, { __typename: "JenkinsJob" }>;
type KubernetesJob = Extract<Job, { __typename: "KubernetesJob" }>;

interface CombinedPossibleJobs
  extends AzureDevopsJob,
    GitlabJob,
    GithubJob,
    CircleCIJob,
    JenkinsJob,
    KubernetesJob {
  __typename: never;
}

export type CiCdJobsTableProps = {
  jobs: CicdProjectJobs["jobs"];
  space: Space;
  project: Project;
  type: JobsTypes;
  headers: Header[];
  selectable?: boolean;
};

export function CiCdJobsTable({
  jobs,
  type,
  space,
  headers,
  project,
  selectable,
}: CiCdJobsTableProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [searchFilters, setSearchFilters] = useState<KeyValue[]>([]);

  const [searchParams, _setSearchParams] = useSearchParams();
  const spaceId = searchParams.get("spaceId");
  const projectId = searchParams.get("projectId");
  const { refetch } = useJobs();
  const [selection, setSelection] = useState<string[]>([]);
  const [deleteJobs] = useDeleteAssetsMutation({
    variables: { input: { spaceMrn: space.mrn, assetMrns: selection } },
    refetchQueries: [LoadCicdProjectJobsDocument],
  });

  const totalCount = jobs?.totalCount || 0;

  // Whenever the searchFilters are updated, we refetch
  // the data with our new filters
  useEffect(() => {
    refetch({ labelFilter: searchFilters });
  }, [searchFilters]);

  // This function is taking in an array of edges and grouping them
  // by matching the provided matchers returned in the getComparators function
  // i.e. sha, ref, repository, job, runId
  const groupBy = <T, _F>(array: T[], f: (o: T) => string[]): T[][] => {
    let groups: { [key: string]: T[] } = {};
    array.forEach((o) => {
      let group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map((group) => groups[group]);
  };

  // Return an array of the properties that should get compared
  // dependent on the type of job. fallback is to return an empty
  // array meaning abort groupings and just print out the list.
  const getComparators = (edge: JobsEdge): string[] => {
    if (!edge || !edge.node) return [];

    const type = edge.node.__typename;

    if (type === "GithubJob") {
      return [
        edge.node.job,
        edge.node.ref,
        edge.node.repository,
        edge.node.runId,
        edge.node.sha,
      ];
    }

    if (type === "GitlabJob") {
      return [
        edge.node.commitSha,
        edge.node.commitRefName,
        edge.node.jobId,
        edge.node.jobStage,
        edge.node.projectUrl,
      ];
    }

    if (type === "CircleCIJob") {
      return [
        edge.node.commitSha,
        edge.node.job,
        edge.node.jobId,
        edge.node.projectUrl,
      ];
    }

    if (type === "AzureDevopsJob") {
      return [
        edge.node.commitSha,
        edge.node.job,
        edge.node.jobId,
        edge.node.projectUrl,
      ];
    }
    if (type === "JenkinsJob") {
      return [
        edge.node.commitSha,
        edge.node.job,
        edge.node.jobId,
        edge.node.projectUrl,
      ];
    }
    if (type === "KubernetesJob") {
      return [edge.node.namespace];
    }

    return [];
  };

  // Use the above functions to group the jobs together with related scans
  const groupedJobs = groupBy(jobs?.edges!, (edge) => getComparators(edge));

  // Anytime the searchParams change, we gather everything from
  // the queryterms search param and parse it back into a format that
  // the API will accept, and then we update the search filters
  useEffect(() => {
    let nextHolding: KeyValue[] = [];
    const nextSearchFilters = searchParams.get("queryterms");
    if (nextSearchFilters) {
      nextSearchFilters.split(",").map((filter) => {
        if (isJsonString(filter)) {
          const x = JSON.parse(filter);
          nextHolding.push({
            key: `${project.type}/${Object.keys(x)[0]}`,
            value: `${Object.values(x)[0]}`,
          });
        } else {
          nextHolding.push({
            key: `${project.type}/search`,
            value: filter,
          });
        }
      });
      setSearchFilters(nextHolding);
    } else {
      setSearchFilters([]);
    }
  }, [searchParams]);

  // helper function that returns whether or not a given
  // string is valid JSON
  const isJsonString = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  // handleQuery's job is to pick apart the filters given
  // from the CICD Search component, and deconstruct them
  // into something that will work as a URL to navigate to.
  const handleQuery = (newFilters: KeyValue[]) => {
    const reg = new RegExp(`^${project.type}\/`);
    let queryterms: string[] = [];
    newFilters.forEach((filter) => {
      const key = filter.key.replace(reg, "");
      const value = filter?.value?.replace(reg, "");
      if (key === "search" && value) {
        queryterms.push(value);
      } else {
        queryterms.push(JSON.stringify({ [key]: value }));
      }
    });
    if (queryterms.length > 0) {
      searchParams.set("queryterms", queryterms.join(","));
    } else {
      searchParams.delete("queryterms");
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  const completeBatchDelete = async () => {
    const total = selection.length;
    try {
      await deleteJobs();
      enqueueSnackbar(
        `Successfully removed ${total} ${pluralize("job", total)}`,
        { variant: "success" },
      );
      resetEditing();
    } catch (error) {
      enqueueSnackbar(`Failed to remove ${pluralize("job", total)}`, {
        variant: "error",
      });
    }
  };

  const resetEditing = () => {
    setSelection([]);
  };

  const handleCheck = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
    mrn: string,
  ) => {
    if (checked) {
      return setSelection((prev) => [...prev, mrn]);
    }
    setSelection(selection.filter((selection) => mrn !== selection));
  };

  const handleCheckAll = () => {
    if (selection.length === 0) {
      setSelection(jobs?.edges?.map((job) => job.node.mrn) || []);
    } else {
      setSelection([]);
    }
  };

  const isJobChecked = (mrn: string) => {
    return selection.includes(mrn);
  };

  const isGroupChecked = (mrns: string[]) => {
    return mrns.every((mrn) => isJobChecked(mrn));
  };

  const isGroupIndeterminate = (mrns: string[]) => {
    if (isGroupChecked(mrns)) return false;
    return mrns.some((mrn) => isJobChecked(mrn));
  };

  const handleGroupCheckChange = (
    _event: ChangeEvent<HTMLInputElement>,
    mrns: string[],
  ) => {
    if (isGroupChecked(mrns) || isGroupIndeterminate(mrns)) {
      // uncheck all
      setSelection(selection.filter((mrn) => !mrns.includes(mrn)));
    } else {
      // check all
      setSelection([
        ...selection,
        ...mrns.filter((mrn) => !selection.includes(mrn)),
      ]);
    }
  };

  const handleDeleteClick = () => {
    completeBatchDelete();
  };

  const handleCancelClick = () => {
    resetEditing();
  };

  const onJobClick = (job: Job) =>
    navigate(
      `/space/cicd/jobs/${projectId}?spaceId=${spaceId}&projectId=${projectId}&jobId=${job.mrn}`,
    );

  const getJobFields = () => {
    switch (type) {
      case JobsTypes.Github:
        return [
          "name",
          "pipelineKind",
          "grade",
          "runNumber",
          "actor",
          "updatedAt",
        ] as Array<keyof CombinedPossibleJobs>;
      case JobsTypes.Kubernetes:
        return [
          "namespace",
          "operation",
          "kind",
          "grade",
          "author",
          "updatedAt",
        ] as Array<keyof CombinedPossibleJobs>;
      case JobsTypes.Jenkins:
        return ["name", "pipelineKind", "grade", "jobId", "updatedAt"];
      default:
        // GitLab, CircleCI, and Azure have same fields
        return [
          "name",
          "pipelineKind",
          "grade",
          "jobId",
          "userName",
          "updatedAt",
        ] as Array<keyof CombinedPossibleJobs>;
    }
  };

  const formattedPipelineKind = (pipelineKind: string) => {
    switch (pipelineKind) {
      case "PULL_REQUEST":
        return "Pull Request";
      case "BRANCH":
        return "Branch";
      case "TAG":
        return "Tag";
      default:
        return pipelineKind;
    }
  };

  const formattedDate = (date: string) =>
    `${FormatRelativeDateAbbreviated(date)} - ${FormatTime(date)}`;

  const getGitWorkflows = (job: CombinedPossibleJobs) => {
    switch (job.__typename) {
      case "GitlabJob":
        return `${job.jobStage} - ${job.jobName} - ${job.target}`;

      case "GithubJob":
        return `${job.workflow} - ${job.job} - ${job.target}`;
    }
  };

  const renderGitWorkflows = (job: CombinedPossibleJobs) => {
    const workflowString = getGitWorkflows(job);

    return (
      <Box sx={{ pl: 3, color: "text.secondary", overflowWrap: "anywhere" }}>
        {workflowString}
      </Box>
    );
  };

  const renderTableCellContent = (
    field: keyof CombinedPossibleJobs,
    job: CombinedPossibleJobs,
  ) => {
    if (
      (job.__typename === "GithubJob" || job.__typename === "GitlabJob") &&
      field === "name"
    ) {
      return renderGitWorkflows(job);
    }
    switch (field) {
      case "name":
        return (
          <Box
            sx={{ pl: 3, color: "text.secondary", overflowWrap: "anywhere" }}
          >
            {job.target}
          </Box>
        );
      case "pipelineKind":
        return <Box>{formattedPipelineKind(job.pipelineKind)}</Box>;
      case "namespace":
        return <Box sx={{ pl: 3, overflowWrap: "anywhere" }}>{job.name}</Box>;
      case "grade":
        return (
          <Typography
            sx={{
              fontSize: 24,
              lineHeight: "1em",
              fontWeight: 700,
              color: getColor(theme, job.grade),
            }}
          >
            {job.grade}
          </Typography>
        );
      case "updatedAt":
        return (
          <Box sx={{ fontSize: 12 }}>
            <AccessTimeIcon sx={{ mr: 1, fontSize: "inherit" }} />
            {formattedDate(job.updatedAt)}
          </Box>
        );
      default:
        return <Box>{job[field]}</Box>;
    }
  };

  return (
    <>
      <Box id="jobs-list-header" sx={{ mb: 4 }}>
        <CicdSearch
          onQuery={handleQuery}
          project={project}
          spaceMrn={space.mrn}
          filters={searchFilters}
          projectId={project.projectID}
        />
      </Box>
      <DataTable id="jobs-list" selectable={selectable} selection={selection}>
        <TableHead>
          <TableRow>
            {selectable && (
              <TableCell key={`cicd-jobs-${projectId}-checkbox`}>
                <Checkbox
                  checked={selection.length === totalCount}
                  indeterminate={
                    selection.length > 0 && selection.length < totalCount
                  }
                  onChange={handleCheckAll}
                />
              </TableCell>
            )}
            {headers.map((header: Header) => (
              <TableCell key={`cicd-jobs-${projectId}-${header.label}`}>
                {header.label}
              </TableCell>
            ))}
            <TableCell sortDirection={"desc"} width={220}>
              <TableSortLabel direction={"desc"} active={true}>
                Time
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedJobs.map((group) => {
            const groupMrns = group.map((job) => job.node.mrn);
            return group.map((edge, index) => {
              const job = edge.node as CombinedPossibleJobs;
              const isSelected = isJobChecked(job.mrn);
              const className =
                (isSelected ? "selected" : "") + " jobs-list-row";
              return (
                <Fragment key={`${job.runId}-${index}`}>
                  {/* If this group contains multiple scans, we'll provide a header and then indent the scans */}
                  {index === 0 && (
                    <TableRow>
                      {selectable && (
                        <TableCell
                          sx={{
                            borderBottom: (theme) =>
                              `1px solid ${theme.palette.background.light}`,
                          }}
                        >
                          <Checkbox
                            checked={isGroupChecked(groupMrns)}
                            indeterminate={isGroupIndeterminate(groupMrns)}
                            onChange={(event) =>
                              handleGroupCheckChange(event, groupMrns)
                            }
                          />
                        </TableCell>
                      )}
                      <TableCell
                        colSpan={headers.length + 1}
                        sx={{
                          borderBottom: (theme) =>
                            `1px solid ${theme.palette.background.light}`,
                        }}
                      >
                        {job.__typename === "KubernetesJob"
                          ? job.namespace
                          : job.name}
                      </TableCell>
                    </TableRow>
                  )}
                  <ClickableTableRow
                    key={job.id}
                    className={className}
                    onClick={() => onJobClick(job)}
                  >
                    {selectable && (
                      <TableCell
                        key={`cicd-jobs-${projectId}-${job.id}-checkbox`}
                      >
                        <Checkbox
                          onChange={(e, checked) =>
                            handleCheck(e, checked, edge.node.mrn)
                          }
                          checked={isSelected}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </TableCell>
                    )}
                    {getJobFields().map((field) => {
                      return (
                        <TableCell
                          key={`cicd-jobs-${projectId}-${job.id}-${field}`}
                        >
                          {renderTableCellContent(
                            field as keyof CombinedPossibleJobs,
                            job,
                          )}
                        </TableCell>
                      );
                    })}
                  </ClickableTableRow>
                </Fragment>
              );
            });
          })}
        </TableBody>
      </DataTable>
      {selection.length > 0 && (
        <SelectionToolbar>
          <Typography>
            Selected {selection.length} of {totalCount} jobs
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteClick}
          >
            Delete
          </Button>
          <Button onClick={handleCancelClick}>Cancel</Button>
        </SelectionToolbar>
      )}
    </>
  );
}
