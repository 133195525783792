import { FC, ReactNode } from "react";
import { Grid, Typography, styled } from "@mui/material";

type GettingStartedProps = {
  title?: string;
  subtitle?: string;
  children?: ReactNode;
};

// Getting Started Template
export const GettingStarted: FC<GettingStartedProps> = ({
  title,
  subtitle,
  children,
}) => (
  <Grid
    container
    justifyContent="center"
    spacing={2}
    style={{
      maxWidth: 1200,
    }}
  >
    {title && (
      <Grid item xs={12}>
        <WelcomeText>
          {title != "" && <Typography variant="h2">{title}</Typography>}
          {subtitle && subtitle != "" && (
            <Typography variant="body1">{subtitle}</Typography>
          )}
        </WelcomeText>
      </Grid>
    )}
    {children}
  </Grid>
);

const WelcomeText = styled("div")`
  text-align: center;
  margin: ${(props) => props.theme.spacing(3)};
  padding: ${(props) => props.theme.spacing(3)};
`;
