import { TableCell } from "@mui/material";
import { BlastRadius } from "../blast/blast-radius";
import { FirewatchTooltip } from "./Tooltip";
import { useImpact } from "../impact/use-impact";
import { BlastRadiusFieldsFragment } from "~/operations";

type BlastRadiusCellProps = {
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
  isActive?: boolean;
  impact: number;
};

export function BlastRadiusCell({
  blastRadius,
  isActive,
  impact,
}: BlastRadiusCellProps) {
  // separate the blastRadius ratins from the rest of the row
  const { __typename, indicator, assets, affected, ...ratings } =
    blastRadius || {};
  // color should be same as impact
  const { color } = useImpact({
    impact,
    isActive: Boolean(isActive),
    isRiskScore: true,
  });

  return (
    <TableCell>
      <BlastRadius
        radius={indicator || "none"}
        label={affected ? String(affected) : "0"}
        tooltip={<FirewatchTooltip data={ratings} />}
        color={color}
      />
    </TableCell>
  );
}
