import React from "react";
import { SoftwareVersionTooltipData } from "~/pages/space/software/Software/components/SoftwareVersions/SoftwareVersionTooltipData";
import { Flex } from "~/components/ui-library";
import { SoftwareVersionLabel } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionLabel";
import { SoftwareVersionTooltip } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionTooltip";
import { SoftwareVersionUnitBase } from "~/pages/space/software/Software/components/SoftwareVersionDistribution/SoftwareVersionUnitBase";
import {
  AggregateScoreType,
  BlastRadiusFieldsFragment,
  EpssScore,
  RiskFactorStats,
} from "~/operations";
import { Link } from "react-router-dom";
import { getLinkByType } from "~/components/FirewatchPage/lib";
import { Space } from "~/lib/types";

type SoftwareVersionUnitProps = {
  width: number;
  color: string;
  version: string;
  riskFactors: (RiskFactorStats | null)[] | null | undefined;
  blastRadius: BlastRadiusFieldsFragment | null | undefined;
  epss: EpssScore | null | undefined;
  cvss: number;
  riskScore: number;
  title: string;
  findingMrn: string;
  space: Space;
};

export const SoftwareVersionUnit = ({
  width,
  color,
  version,
  riskFactors,
  blastRadius,
  epss,
  cvss,
  riskScore,
  title,
  space,
  findingMrn,
}: SoftwareVersionUnitProps) => {
  return (
    <Flex alignSelf="flex-end" flexDirection="column">
      <SoftwareVersionLabel color={color} version={version} />
      <SoftwareVersionTooltip
        content={
          <SoftwareVersionTooltipData
            blastRadius={blastRadius}
            riskScore={riskScore}
            epssScore={epss}
            cvss={cvss}
            title={title}
            riskFactors={riskFactors}
            href={getLinkByType(
              AggregateScoreType.VersionedSoftware,
              space.id,
              findingMrn + "/" + version,
            )}
          />
        }
      >
        <SoftwareVersionUnitBase
          color={color}
          {...{
            component: Link,
            to: getLinkByType(
              AggregateScoreType.VersionedSoftware,
              space.id,
              findingMrn + "/" + version,
            ),
          }}
        />
      </SoftwareVersionTooltip>
    </Flex>
  );
};
