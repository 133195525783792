import { Fragment, useEffect, useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  Link,
  FormControlLabel,
  Checkbox,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import {
  ArrowBackIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "~/components/icons";
import { AuthTextField } from "./auth-text-field";
import { ContinueButton } from "./continue-button";
import { LoginProviderButton } from "./login-provider-button";
import { useAuth } from "../../login/provider";
import { EmailRecoveryComponent } from "../pages/auth/components/email-recovery";
import { PasswordComplexityInput } from "./passwordComplexityInput";
import { StrengthResult, passwordStrength } from "~/lib/password-strength";
import { EMAIL_PATTERN } from "~/pages/integrations/validations/helpers";

type Props = {
  view: "login" | "signup";
  onSubmit: any;
  step: "email" | "password";
  setStep: any;
  handleLoginWithProvider: any;
};

export interface AuthFormInput {
  email: string;
  password: string;
  optIn: boolean;
}

export type Complexity = {
  score: StrengthResult["score"] | undefined;
  crackTime: StrengthResult["crackTimesDisplay"]["offlineSlowHashing1e4PerSecond"];
  isComplex: boolean; // isComplex will register true if the ZXCVBNScore is higher than 2
};

const loginProviders = ["google", "github", "msft"];

export function AuthComponent({
  view,
  onSubmit,
  step,
  setStep,
  handleLoginWithProvider,
}: Props) {
  const { state } = useAuth();
  let location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showPassword, setShowPassword] = useState(false);
  const initialOptIn = searchParams.get("emailOptIn");
  const {
    control,
    handleSubmit,
    watch,
    register,
    reset,
    formState: { errors, isValid },
  } = useForm<AuthFormInput>({
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
      optIn: Boolean(initialOptIn === "true"),
    },
  });
  const [email, optIn] = watch(["email", "optIn"]);

  const [complexity, setComplexity] = useState<Complexity>({
    score: undefined,
    isComplex: false,
    crackTime: "",
  });

  useEffect(() => {
    return () => {
      setStep("email");
      reset({
        email: "",
        password: "",
        optIn: false,
      });

      searchParams.delete("emailOptIn");
    };
  }, []);

  useEffect(() => {
    if (optIn) {
      setSearchParams({ emailOptIn: "true" }, { replace: true });
    } else {
      searchParams.delete("emailOptIn");
      setSearchParams(searchParams, { replace: true });
    }
  }, [optIn]);

  const handleBack = () => {
    reset({
      email,
      password: "",
      optIn,
    });
    setStep("email");
  };

  const getComplexityScore = (password: string) => {
    const result = passwordStrength(password);
    setComplexity({
      score: result.score,
      crackTime: result.crackTimesDisplay.offlineSlowHashing1e4PerSecond,
      isComplex: result.score > 2,
    });
  };

  const getFormContent = () => {
    switch (view) {
      case "signup":
        return {
          header: "Sign up for free",
          footer: "Already have an account?",
          footerLink: "Log in instead",
          passwordPlaceholder: "Create a password...",
        };
      default:
        return {
          header: "Log in to your account:",
          footer: "Don’t have an account yet?",
          footerLink: "Sign up now",
          passwordPlaceholder: "Your password...",
        };
    }
  };

  if (location.pathname === "/reset-password" && email) {
    return <EmailRecoveryComponent {...{ email }} />;
  }

  const formContent = getFormContent();
  return (
    <Box
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      sx={{ width: 1, maxWidth: 400, minWidth: 330 }}
    >
      <Box
        sx={{
          width: 1,
          py: 5,
          px: 4,
          backgroundColor: "#FFFFFF",
          borderRadius: 2,
          ".MuiTypography-root": {
            fontFamily: "Sofia Pro",
          },
        }}
      >
        <Typography
          variant="h5"
          fontWeight={900}
          sx={{
            mb: step === "email" ? 5 : 3,
            background:
              "linear-gradient(135.02deg, #8797FF 14.67%, #3F137C 80.49%);",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          {formContent.header}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Box sx={{ mb: 2 }}>
            {step === "email" ? (
              <Fragment>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email is Required",
                    validate: {
                      isEmail: (value) =>
                        Boolean(value.match(EMAIL_PATTERN)) ||
                        "Not a valid email",
                    },
                  }}
                  render={({ field }) => (
                    <AuthTextField
                      {...field}
                      type="email"
                      placeholder="Your Email..."
                      error={Boolean(errors.email)}
                      helperText={errors.email?.message}
                      containerProps={{
                        pb: view === "login" && step === "email" ? 1.5 : 0,
                      }}
                    />
                  )}
                />
                {view == "signup" && (
                  <Box mt={2}>
                    <Typography
                      variant="caption"
                      sx={{ color: "text.disabled" }}
                    >
                      By continuing, you agree to the{" "}
                      <Link
                        target="_blank"
                        href="https://mondoo.com/terms-of-service"
                        rel="noopener"
                        sx={{
                          color: "text.disabled",
                          textDecoration: "underline",
                        }}
                      >
                        Terms of Service
                      </Link>{" "}
                      and{" "}
                      <Link
                        target="_blank"
                        href="https://mondoo.com/privacy-policy"
                        rel="noopener"
                        sx={{
                          color: "text.disabled",
                          textDecoration: "underline",
                        }}
                      >
                        Privacy Policy
                      </Link>
                    </Typography>
                    <Controller
                      name="optIn"
                      control={control}
                      render={({ field }) => (
                        <FormControlLabel
                          sx={{ mr: 0 }}
                          label={
                            <Typography
                              variant="caption"
                              sx={{ color: "text.disabled" }}
                            >
                              Send me tips, tricks, and occasional announcements
                            </Typography>
                          }
                          control={
                            <Checkbox
                              {...field}
                              disableRipple
                              size="small"
                              checked={field.value}
                            />
                          }
                        />
                      )}
                    />
                  </Box>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Box
                  component={Button}
                  onClick={handleBack}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    mb: 5,
                    px: 0,
                  }}
                >
                  <ArrowBackIcon sx={{ mr: 2.5, color: "secondary.main" }} />
                  <Typography
                    sx={{
                      color: "background.lightest",
                      textTransform: "initial",
                    }}
                  >
                    {email}
                  </Typography>
                </Box>

                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => {
                    // if signing up - render the password complexity checker
                    if (view === "signup") {
                      return (
                        <PasswordComplexityInput
                          {...{
                            field,
                            register,
                            errors,
                            complexity,
                            getComplexityScore,
                            placeholder: formContent.passwordPlaceholder,
                          }}
                        />
                      );
                    } else {
                      return (
                        <AuthTextField
                          type={showPassword ? "text" : "password"}
                          {...field}
                          placeholder={formContent.passwordPlaceholder}
                          error={Boolean(errors.password)}
                          helperText={errors.password?.message}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    setShowPassword((prev) => !prev)
                                  }
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon
                                      sx={{ color: "#B8B8B8" }}
                                    />
                                  ) : (
                                    <VisibilityIcon sx={{ color: "#B8B8B8" }} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      );
                    }
                  }}
                />

                {view === "login" && (
                  <Typography
                    component={RouterLink}
                    to="/reset-password"
                    data-name="link-forgot-password"
                    sx={{
                      display: "block",
                      mt: 2.5,
                      mb: 4,
                      background:
                        "linear-gradient(180deg, #A07AFF 0%, #5000C1 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: 500,
                    }}
                  >
                    Forgot your password?
                  </Typography>
                )}
              </Fragment>
            )}
          </Box>
          <Box mt={step === "password" ? 5 : 0}>
            <ContinueButton
              type="submit"
              step={step}
              loading={state.status === "pending"}
              disabled={!isValid}
              content={
                step === "email"
                  ? "continue"
                  : view === "login"
                    ? "log in"
                    : "sign up"
              }
            />
          </Box>
        </Box>

        {(step === "email" || view === "signup") && (
          <Fragment>
            <Divider
              sx={{
                mt: 3,
                ".MuiDivider-wrapper": {
                  padding: 0,
                },
                "&:before, &:after": {
                  borderTop: (theme) =>
                    `2px solid ${theme.palette.text.secondary}`,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 48,
                  height: 48,
                  border: "2px solid",
                  borderColor: "text.secondary",
                  borderRadius: "50%",
                }}
              >
                <Typography color="background.lightest" fontWeight={500}>
                  OR
                </Typography>
              </Box>
            </Divider>

            <Grid container spacing={2} sx={{ mt: 1 }}>
              {loginProviders.map((provider) => (
                <Grid item xs={4} sm={12} key={provider}>
                  <LoginProviderButton
                    {...{
                      view,
                      provider,
                      handleLoginWithProvider,
                    }}
                  />
                </Grid>
              ))}
            </Grid>
            {view === "login" && (
              <Box sx={{ textAlign: "center", mt: 3 }}>
                <Link
                  component={RouterLink}
                  to="/sso"
                  className="show-sso tagline"
                >
                  <Typography
                    sx={{
                      background:
                        "linear-gradient(133.55deg, #9147FF 0%, #4C35E8 100%)",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                    }}
                  >
                    Log in via SSO
                  </Typography>
                </Link>
              </Box>
            )}
          </Fragment>
        )}
      </Box>
      <Typography sx={{ mt: 3, color: "text.secondary", textAlign: "center" }}>
        {formContent.footer}{" "}
        <Link
          component={RouterLink}
          to={`/${view === "signup" ? "login" : "signup"}`}
          sx={{ color: "text.secondary", textDecoration: "underline" }}
        >
          {formContent.footerLink}
        </Link>
      </Typography>
    </Box>
  );
}
