import { createSvgIcon } from "@mui/material";

export const WindowsIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 1H11.4291V11.4246H1V1ZM12.5709 1H23V11.4246H12.5709V1ZM1 12.5709H11.4291V23H1V12.5709ZM12.5709 12.5709H23V23H12.5709"
      fill="#0078D4"
    />
  </svg>,
  "Windows",
);
