import {
  ComplianceFrameworkMutationAction,
  ComplianceFrameworkState,
} from "~/operations";
import { SvgIcon } from "@mui/material";

type UseFrameworkStateActionsParams = {
  state: ComplianceFrameworkState;
  onClose: () => void;
  onStateChange: (input: ComplianceFrameworkMutationAction) => void;
  getIcon: (state: ComplianceFrameworkState) => typeof SvgIcon;
  getLabel: (state: ComplianceFrameworkState) => string;
  getColor: (state: ComplianceFrameworkState) => string;
  getDescription: (state: ComplianceFrameworkState) => string;
};

export function useFrameworkStateActions({
  state,
  onClose,
  onStateChange,
  getLabel,
  getDescription,
  getIcon,
  getColor,
}: UseFrameworkStateActionsParams) {
  const frameworkStateFrameworkActionMap = {
    [ComplianceFrameworkState.Active]: ComplianceFrameworkMutationAction.Enable,
    [ComplianceFrameworkState.Preview]:
      ComplianceFrameworkMutationAction.Preview,
    [ComplianceFrameworkState.Disabled]:
      ComplianceFrameworkMutationAction.Disable,
  };

  const frameworkStateFrameworkIconMap = {
    [ComplianceFrameworkState.Active]: getIcon(ComplianceFrameworkState.Active),
    [ComplianceFrameworkState.Preview]: getIcon(
      ComplianceFrameworkState.Preview,
    ),
    [ComplianceFrameworkState.Disabled]: getIcon(
      ComplianceFrameworkState.Disabled,
    ),
  };

  const actions = [
    ComplianceFrameworkState.Active,
    ComplianceFrameworkState.Preview,
  ]
    .map((s) => {
      if (state === s) return null;

      const Icon = frameworkStateFrameworkIconMap[s];
      const label = getLabel(s);
      const color = getColor(s);
      const description = getDescription(s);

      return {
        label,
        action: () => {
          onStateChange(frameworkStateFrameworkActionMap[s]);
          onClose();
        },
        dataName: state,
        icon: <Icon sx={{ color, fontSize: 16 }} />,
        description,
        color,
      };
    })
    .flatMap((x) => x ?? []);

  return {
    actions,
  };
}
