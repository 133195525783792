// this is a temporary to house reused components until broken out further
import {
  Box,
  Button,
  Typography,
  Tooltip,
  styled,
  SxProps,
  Theme,
  ButtonProps,
  BoxProps,
} from "@mui/material";
import { CheckIcon } from "~/components/icons";
import { FixedPercentage } from "~/lib/helpers";

// Move to own component or make animated gauge more flexible
type GaugeProps = {
  title: string;
  value: number;
  total: number;
  maxWidth?: number;
  onClick?: BoxProps["onClick"];
};

export function BillingGauge({ value, total, title, onClick }: GaugeProps) {
  const radius = 40;
  const percentage = value / total || 0;
  const circumference = Math.PI * (radius * 2);
  const strokeDasharray = `${
    (percentage * 100 * circumference) / 100
  } ${circumference}`;

  const notApplicable = total === 0 ? true : false;
  const tooltip = notApplicable
    ? "Not Applicable"
    : `${value} of ${total} ${title}`;

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: 144,
        marginRight: 3,
        "&:last-child": {
          marginRight: 0,
        },
      }}
    >
      <Box
        position={"absolute"}
        sx={{
          color: notApplicable ? "#828282" : "primary.main",
          textAlign: "center",
          textTransform: "uppercase",
        }}
      >
        <Typography sx={{ fontSize: 10, fontWeight: 700 }}>{title}</Typography>
        <Typography sx={{ fontSize: 14, fontWeight: 700 }}>
          {notApplicable ? "N/A" : FixedPercentage(percentage)}
        </Typography>
      </Box>
      <Tooltip title={tooltip} aria-label="Gauge summary" placement="top">
        <StyledSvg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <Box
            component="circle"
            className="shadow"
            cx="50"
            cy="50"
            r={radius}
          />
          <Box
            component="circle"
            className="value"
            cx="50"
            cy="50"
            r={radius}
            strokeDasharray={strokeDasharray}
            sx={{
              stroke:
                value === 0
                  ? "transparent"
                  : (theme) => theme.palette.primary.main,
            }}
          />
        </StyledSvg>
      </Tooltip>
    </Box>
  );
}

const StyledSvg = styled("svg")`
  width: 100%;
  height: 100%;
  transform: rotate(-90deg) scale(1, -1);

  circle {
    fill: none;
    stroke-width: 10;
  }

  .shadow {
    stroke: ${(props) => props.theme.palette.background.light};
  }

  .value {
    stroke-linecap: round;
    transition: 0.4s linear;
  }
`;

// Move all to component files
type BillingButtonProps = {
  active?: boolean;
} & ButtonProps;

export const BillingButton = ({
  active,
  children,
  sx,
  ...rest
}: BillingButtonProps) => {
  return (
    <Button
      size="large"
      variant="contained"
      fullWidth
      endIcon={active && <CheckIcon />}
      sx={{ textTransform: "none", fontWeight: 600, ...sx }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export const SmallSpan = ({
  text,
  sx,
}: {
  text: string;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Typography component="span" sx={{ fontSize: 8, ...sx }}>
      {text}
    </Typography>
  );
};

export const DisplayPricing = ({
  price,
  text,
  prepend,
}: {
  price: string;
  text: string;
  prepend?: string;
}) => {
  return (
    <>
      <Typography sx={{ fontSize: 8 }}>{prepend ? prepend : "\xa0"}</Typography>
      <Typography variant="h5" sx={{ fontWeight: 700 }}>
        ${price} <SmallSpan text={text} />
      </Typography>
    </>
  );
};
