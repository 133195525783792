import React from "react";
import { RemediationFilterContainer } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationFilterContainer";
import { RemediationFilterTile } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationFilterTile";

export const RemediationFilterSkeleton = () => {
  return (
    <RemediationFilterContainer>
      <RemediationFilterTile isActive />
      <RemediationFilterTile />
      <RemediationFilterTile />
      <RemediationFilterTile />
    </RemediationFilterContainer>
  );
};
