import { createSvgIcon } from "@mui/material";

export const ZendeskIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M36.1038 30.6602H36.0993C30.3648 30.6602 25.7148 35.3102 25.7148 41.0447V41.0507H46.4958C46.4958 35.3132 41.8443 30.6617 36.1068 30.6617L36.1038 30.6602ZM22.2888 15.9647L1.50781 41.0507H22.2888V15.9647ZM1.50781 6.95421C1.50781 12.6932 6.15931 17.3447 11.8983 17.3447C17.6373 17.3447 22.2888 12.6932 22.2888 6.95421H1.50781ZM25.7148 6.94971V32.0417L46.4958 6.95271H25.7148V6.94971Z"
      fill="currentColor"
    />
  </svg>,
  "Zendesk",
);
