import { CSSProperties, ReactNode } from "react";
import { styled } from "@mui/material/styles";

type CardProps = {
  children: ReactNode;
  style?: CSSProperties;
};

export function Card({ children, ...props }: CardProps) {
  return (
    <CardBox {...props}>
      <CardBody>{children}</CardBody>
    </CardBox>
  );
}

const CardBox = styled("div")`
  display: block;
  border-radius: 4px;
  background-color: ${(props) => props.theme.palette.background.paper};
  box-shadow: ${(props) => props.theme.shadows[1]};
  margin: 0;
  cursor: pointer;

  & .hideUntilHover {
    visibility: hidden;
  }

  &:hover .hideUntilHover {
    visibility: initial;
  }
`;

const CardBody = styled("div")`
  padding: 25px;
`;

export const CardDetailsGrid = styled("div")`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 80px;
  grid-row-gap: 5px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.07px;
  color: ${(props) => props.theme.palette.text.secondary};
  align-items: center;
  height: fit-content;
  margin: auto 0;

  span {
    font-size: 14px;
  }
`;

export const CardHeader = styled("div")`
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.38px;
  line-height: 24px;
  margin-bottom: 21px;
  word-break: break-all;
`;
