import { ListName } from "../types";

const getAuthors = (authors: ListName[] = []): string => {
  const authorCount = authors?.length;

  // If there are two authors, return them separated by "and"
  // e.g. "Alice and Bob"
  if (authorCount === 2) {
    return authors.map((val) => val?.name || "").join(" and ");
  }

  // If there are more than two authors, return them separated by commas, with the last author preceded by "and"
  // e.g. "Alice, Bob, and Charlie"
  if (authorCount > 2) {
    return (
      authors
        ?.map((val) => val?.name || "")
        ?.slice(0, -1)
        ?.join(", ") +
      ", and " +
      authors[authorCount - 1]?.name
    );
  }

  // If there is only one author, return their name
  // e.g. "Alice"
  return authors[0]?.name || "";
};

export default getAuthors;
