import { Fragment, ReactNode } from "react";
import {
  TableHead,
  TableCell,
  TableSortLabel,
  TableBody,
  TableRow,
} from "@mui/material";
import { useSearchParams } from "react-router-dom";
import {
  ChecksOrderField,
  ComplianceAssetOrderField,
  ControlsOrderField,
  OrderDirection,
} from "~/operations";
import { DataTable, DataTableProps } from "~/components/data-table";
import { Pagination, PaginationProps } from "~/components/pagination";

type ComplianceOrderField =
  | ControlsOrderField
  | ComplianceAssetOrderField
  | ChecksOrderField;

type ComplianceOrder = {
  field: ComplianceOrderField;
  direction: OrderDirection;
};

function isComplianceOrderField(value: any): value is ComplianceOrderField {
  return (
    isControlsOrderField(value) ||
    isComplianceAssetOrderField(value) ||
    isChecksOrderField(value)
  );
}

function isControlsOrderField(value: any): value is ControlsOrderField {
  return Object.values(ControlsOrderField).includes(value);
}

function isComplianceAssetOrderField(
  value: any,
): value is ComplianceAssetOrderField {
  return Object.values(ComplianceAssetOrderField).includes(value);
}

function isChecksOrderField(value: any): value is ChecksOrderField {
  return Object.values(ChecksOrderField).includes(value);
}

// Todo: Add Table row as a compound component => <ComplianceTable.Row/>
// good reference at https://www.patterns.dev/posts/compound-pattern

export type ComplianceTableProps = {
  children: ReactNode;
  tableHeaders: Header[];
  defaultActiveColumn?: Header["id"];
  selectable?: DataTableProps["selectable"];
  selection?: DataTableProps["selection"];
  paginationProps?: PaginationProps;
};

export function ComplianceTable({
  children,
  tableHeaders,
  defaultActiveColumn,
  selectable,
  selection,
  paginationProps,
}: ComplianceTableProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const fieldParam = searchParams.get("field");
  const field = isComplianceOrderField(fieldParam)
    ? fieldParam
    : isComplianceOrderField(defaultActiveColumn)
      ? defaultActiveColumn
      : ControlsOrderField.Title;

  const directionParam = searchParams.get("direction");
  const direction =
    directionParam === OrderDirection.Asc
      ? OrderDirection.Asc
      : OrderDirection.Desc;

  const sort: ComplianceOrder = {
    field,
    direction,
  };

  const handleSortClick = (id: string) => {
    searchParams.set("field", id);
    searchParams.set(
      "direction",
      id !== sort.field ? "DESC" : sort.direction === "ASC" ? "DESC" : "ASC",
    );
    setSearchParams(searchParams);
  };

  return (
    <Fragment>
      <DataTable selectable={selectable} selection={selection}>
        <TableHead>
          <TableRow>
            {tableHeaders.map((header) => (
              <TableCell
                key={header.id}
                align={header.options?.textAlign}
                width={header.options?.width}
              >
                {header.sortable !== false ? (
                  <TableSortLabel
                    onClick={() => handleSortClick(header.id)}
                    direction={
                      direction === OrderDirection.Asc ? "asc" : "desc"
                    }
                    active={header.id === field}
                  >
                    {header.label}
                  </TableSortLabel>
                ) : (
                  header.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </DataTable>
      {paginationProps && <Pagination {...paginationProps} />}
    </Fragment>
  );
}

export type Header = {
  id: string;
  label: ReactNode;
  colSpan?: number;
  sortable?: boolean;
  options?: {
    textAlign?: "inherit" | "left" | "center" | "right" | "justify";
    width?: number;
  };
};
