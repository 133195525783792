import { Table, TableBody, TableContainer, TableRow } from "@mui/material";
import { PackageScoresOrder } from "~/operations";
import { Header } from "~/types/table";
import { TableHead } from "~/pages/inventory/components/TableHead";
import { Link as RouterLink } from "react-router-dom";
import { ImpactCell } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTable/ImpactCell";
import { TitleCell } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTable/TitleCell";
import { FirstFoundCell } from "~/pages/inventory/components/AssetSoftware/AssetSoftwareTable/FirstFoundCell";
import { RiskFactorsCell } from "~/components/Cells";
import { AssetPackageNodeType } from "~/pages/inventory/components/AssetSoftware/types";

type SoftwareTableProps = {
  packages: Array<AssetPackageNodeType>;
  assetMrn: string;
  onSort: (id: string) => void;
  orderBy: PackageScoresOrder;
};

export const SoftwareTable = ({
  packages,
  assetMrn,
  onSort,
  orderBy,
}: SoftwareTableProps) => {
  const tableHeaders: Header[] = [
    {
      id: "RISK_SCORE",
      label: "Risk Score",
      options: { width: "10%" },
    },
    {
      id: "PACKAGE_NAME",
      label: "Package",
      options: { width: "50%" },
    },
    {
      id: "RISK_FACTORS",
      label: "Risk Factors",
    },
    {
      id: "FIRST_FOUND",
      label: "First found",
    },
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead
          tableHeaders={tableHeaders}
          onSort={onSort}
          orderBy={orderBy}
        />
        <TableBody>
          {packages.map((pckg, index) => (
            <TableRow
              key={index}
              component={RouterLink}
              to={`/space/software/${pckg.packageName}?assetMrn=${assetMrn}`}
            >
              <ImpactCell pckg={pckg} data-testid="software-impact" />
              <TitleCell pckg={pckg} data-testid="software-title" />
              <RiskFactorsCell
                riskFactors={pckg.riskFactors}
                data-testid="software-risk-factors"
              />
              <FirstFoundCell pckg={pckg} data-testid="software-first-found" />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
