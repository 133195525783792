import { createSvgIcon } from "@mui/material";

export const DockerIcon = createSvgIcon(
  <svg viewBox="0 0 24 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8783 4.8032H16.0299V7.0027H17.1177C17.6202 7.0027 18.1369 6.91321 18.6126 6.75203C18.8465 6.6727 19.1088 6.56242 19.3394 6.42363C19.0357 6.02711 18.8806 5.52643 18.8351 5.03289C18.773 4.36152 18.9085 3.48774 19.3628 2.96231L19.589 2.70072L19.8585 2.91742C20.537 3.46252 21.1077 4.22432 21.2083 5.09274C22.0253 4.85242 22.9846 4.90925 23.7048 5.32487L24.0002 5.49537L23.8448 5.79893C23.2357 6.98755 21.9623 7.35584 20.7174 7.29063C18.8546 11.9304 14.7989 14.127 9.88157 14.127C7.34097 14.127 5.01021 13.1773 3.68289 10.9233L3.66115 10.8866L3.46779 10.4931C3.01914 9.50095 2.87009 8.41405 2.97115 7.32799L3.00154 7.0027H4.8415V4.8032H6.993V2.6516H11.2963V0.5H13.8783V4.8032Z"
      fill="#364548"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8489 5.61683C20.9931 4.49559 20.1541 3.61494 19.6337 3.19678C19.0341 3.89008 18.9409 5.70707 19.8817 6.47207C19.3566 6.93841 18.2503 7.36109 17.1175 7.36109H3.3282C3.21811 8.5436 3.42569 9.63257 3.89984 10.5646L4.0567 10.8516C4.15602 11.02 4.26455 11.1829 4.3818 11.3394C4.94873 11.3758 5.47143 11.3883 5.94963 11.3778H5.94982C6.88955 11.3571 7.65634 11.246 8.23748 11.0447C8.27856 11.0319 8.32301 11.0356 8.36141 11.055C8.39981 11.0744 8.42917 11.108 8.44326 11.1486C8.45735 11.1893 8.45508 11.2338 8.43692 11.2728C8.41877 11.3118 8.38615 11.3422 8.34597 11.3576C8.26863 11.3845 8.18808 11.4096 8.1049 11.4334H8.10462C7.64703 11.5641 7.15632 11.652 6.52324 11.691C6.56088 11.6917 6.48409 11.6967 6.4839 11.6967C6.46236 11.6981 6.43526 11.7012 6.41362 11.7023C6.16445 11.7163 5.89552 11.7192 5.62057 11.7192C5.31984 11.7192 5.02372 11.7136 4.6926 11.6967L4.68413 11.7023C5.83304 12.9937 7.62953 13.7682 9.88143 13.7682C14.6472 13.7682 18.6896 11.6555 20.4797 6.91263C21.7496 7.04296 22.9701 6.71908 23.5253 5.63528C22.641 5.12499 21.5037 5.28769 20.8489 5.61683Z"
      fill="#22A0C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8485 5.61683C20.9928 4.49559 20.1537 3.61494 19.6334 3.19678C19.0337 3.89008 18.9405 5.70707 19.8813 6.47207C19.3562 6.93841 18.25 7.36109 17.1171 7.36109H4.14439C4.08812 9.17207 4.76015 10.5467 5.94915 11.3778H5.94943C6.88917 11.3571 7.65596 11.246 8.2371 11.0447C8.27818 11.0319 8.32262 11.0356 8.36103 11.055C8.39943 11.0744 8.42878 11.108 8.44288 11.1486C8.45697 11.1893 8.4547 11.2338 8.43654 11.2728C8.41838 11.3118 8.38577 11.3422 8.34559 11.3576C8.26824 11.3845 8.1877 11.4096 8.10451 11.4334H8.10423C7.64664 11.5641 7.11378 11.6633 6.4807 11.7023C6.48051 11.7023 6.46536 11.6877 6.46517 11.6877C8.08711 12.5197 10.4388 12.5167 13.1352 11.481C16.1585 10.3195 18.9719 8.10671 20.9348 5.57581C20.9053 5.58917 20.8765 5.60281 20.8485 5.61683Z"
      fill="#37B1D9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.35449 8.7981C3.44012 9.43155 3.62549 10.0246 3.90025 10.5648L4.05711 10.8518C4.15641 11.0203 4.26494 11.1831 4.38221 11.3396C4.94923 11.376 5.47194 11.3886 5.95023 11.378C6.88996 11.3573 7.65675 11.2463 8.23789 11.0449C8.27898 11.0321 8.32342 11.0358 8.36182 11.0552C8.40023 11.0746 8.42958 11.1082 8.44367 11.1489C8.45776 11.1895 8.45549 11.2341 8.43734 11.2731C8.41918 11.3121 8.38656 11.3425 8.34638 11.3579C8.26904 11.3848 8.18849 11.4098 8.10531 11.4336H8.10503C7.64744 11.5643 7.11739 11.6579 6.48432 11.697C6.46258 11.6983 6.42466 11.6986 6.40274 11.6998C6.15376 11.7138 5.88756 11.7223 5.61252 11.7223C5.31188 11.7223 5.00437 11.7166 4.67344 11.6997C5.82235 12.9911 7.62994 13.7684 9.88184 13.7684C13.9619 13.7684 17.5115 12.2198 19.5718 8.7981H3.35449Z"
      fill="#1B81A5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.26904 8.7981C4.51294 9.91031 5.09906 10.7834 5.94998 11.378C6.88971 11.3573 7.6565 11.2463 8.23764 11.0449C8.27872 11.0321 8.32317 11.0358 8.36157 11.0552C8.39997 11.0746 8.42933 11.1082 8.44342 11.1489C8.45751 11.1895 8.45524 11.2341 8.43708 11.2731C8.41893 11.3121 8.38631 11.3425 8.34613 11.3579C8.26879 11.3848 8.18824 11.4098 8.10506 11.4336H8.10478C7.64719 11.5643 7.10595 11.6579 6.47277 11.697C8.09461 12.5288 10.4396 12.5169 13.1357 11.4812C14.7668 10.8546 16.3366 9.9217 17.7449 8.7981H4.26904Z"
      fill="#1D91B4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.2002 5.16186H7.06499V7.02665H5.2002V5.16186ZM5.35555 5.3173H5.50271V6.8713H5.35555V5.3173ZM5.63219 5.3173H5.78529V6.8713H5.63228V5.3173H5.63219ZM5.91467 5.3173H6.06776V6.8713H5.91467V5.3173ZM6.19733 5.3173H6.35033V6.8713H6.19733V5.3173ZM6.4799 5.3173H6.6329V6.8713H6.4799V5.3173ZM6.76238 5.3173H6.90964V6.8713H6.76238V5.3173ZM7.3518 3.01025H9.21669V4.87496H7.3517V3.01025H7.3518ZM7.50724 3.1657H7.65432V4.7196H7.50724V3.1657ZM7.78389 3.1657H7.93689V4.7196H7.78398V3.1657H7.78389ZM8.06636 3.1657H8.21936V4.7196H8.06636V3.1657ZM8.34893 3.1657H8.50193V4.7196H8.34893V3.1657ZM8.6315 3.1657H8.7846V4.7196H8.6315V3.1657ZM8.91398 3.1657H9.06133V4.7196H8.91398V3.1657Z"
      fill="#23A3C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.35166 5.16187H9.21655V7.02666H7.35156V5.16187H7.35166ZM7.5071 5.31731H7.65418V6.87131H7.5071V5.31731ZM7.78375 5.31731H7.93675V6.87131H7.78384V5.31731H7.78375ZM8.06622 5.31731H8.21922V6.87131H8.06622V5.31731ZM8.34879 5.31731H8.50179V6.87131H8.34879V5.31731ZM8.63136 5.31731H8.78446V6.87131H8.63136V5.31731ZM8.91384 5.31731H9.06119V6.87131H8.91384V5.31731Z"
      fill="#34BBDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50342 5.16187H11.3682V7.02666H9.50342V5.16187ZM9.65877 5.31731H9.80584V6.87131H9.65877V5.31731ZM9.93541 5.31731H10.0884V6.87131H9.93541V5.31731ZM10.218 5.31731H10.371V6.87131H10.218V5.31731ZM10.5006 5.31731H10.6536V6.87131H10.5006V5.31731ZM10.783 5.31731H10.9362V6.87131H10.783V5.31731ZM11.0656 5.31731H11.2128V6.87131H11.0656V5.31731Z"
      fill="#23A3C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.50342 3.01025H11.3682V4.87496H9.50342V3.01025ZM9.65877 3.1657H9.80584V4.7196H9.65877V3.1657ZM9.93541 3.1657H10.0884V4.7196H9.93541V3.1657ZM10.218 3.1657H10.371V4.7196H10.218V3.1657ZM10.5006 3.1657H10.6536V4.7196H10.5006V3.1657ZM10.783 3.1657H10.9362V4.7196H10.783V3.1657ZM11.0656 3.1657H11.2128V4.7196H11.0656V3.1657ZM11.655 5.16186H13.5198V7.02665H11.655V5.16186ZM11.8105 5.3173H11.9575V6.8713H11.8105V5.3173ZM12.0871 5.3173H12.2401V6.8713H12.0871V5.3173ZM12.3696 5.3173H12.5226V6.8713H12.3696V5.3173ZM12.6522 5.3173H12.8053V6.8713H12.6522V5.3173ZM12.9347 5.3173H13.0878V6.8713H12.9347V5.3173ZM13.2173 5.3173H13.3645V6.8713H13.2173V5.3173Z"
      fill="#34BBDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6548 3.01025H13.5196V4.87496H11.6548V3.01025ZM11.8102 3.1657H11.9573V4.7196H11.8102V3.1657ZM12.0869 3.1657H12.2399V4.7196H12.0869V3.1657ZM12.3694 3.1657H12.5224V4.7196H12.3694V3.1657ZM12.6519 3.1657H12.805V4.7196H12.6519V3.1657ZM12.9345 3.1657H13.0876V4.7196H12.9345V3.1657ZM13.2171 3.1657H13.3642V4.7196H13.2171V3.1657Z"
      fill="#23A3C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6548 0.858398H13.5196V2.72338H11.6548V0.858398ZM11.8102 1.01375H11.9573V2.56784H11.8102V1.01366V1.01375ZM12.0869 1.01375H12.2399V2.56784H12.0869V1.01366V1.01375ZM12.3694 1.01375H12.5224V2.56784H12.3694V1.01366V1.01375ZM12.6519 1.01375H12.805V2.56784H12.6519V1.01366V1.01375ZM12.9345 1.01375H13.0876V2.56784H12.9345V1.01366V1.01375ZM13.2171 1.01375H13.3642V2.56784H13.2171V1.01366V1.01375Z"
      fill="#34BBDE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8057 5.16187H15.6705V7.02666H13.8057V5.16187ZM13.9609 5.31731H14.1082V6.87131H13.961V5.31731H13.9609ZM14.2376 5.31731H14.3907V6.87131H14.2377V5.31731H14.2376ZM14.5202 5.31731H14.6733V6.87131H14.5202V5.31731ZM14.8027 5.31731H14.9558V6.87131H14.8027V5.31731ZM15.0854 5.31731H15.2384V6.87131H15.0854V5.31731ZM15.3678 5.31731H15.515V6.87131H15.3678V5.31731Z"
      fill="#23A3C2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09945 10.0499C9.1676 10.0489 9.23525 10.0616 9.29847 10.087C9.36169 10.1125 9.41922 10.1502 9.46773 10.1981C9.51624 10.246 9.55475 10.303 9.58103 10.3659C9.60732 10.4288 9.62084 10.4963 9.62083 10.5644C9.62082 10.6326 9.60727 10.7 9.58096 10.7629C9.55466 10.8258 9.51612 10.8828 9.4676 10.9307C9.41907 10.9785 9.36152 11.0163 9.29829 11.0417C9.23506 11.0671 9.16741 11.0797 9.09926 11.0788C8.96402 11.0769 8.83495 11.0219 8.73998 10.9256C8.64501 10.8293 8.59177 10.6995 8.5918 10.5642C8.59182 10.429 8.6451 10.2992 8.74011 10.2029C8.83512 10.1066 8.96421 10.0517 9.09945 10.0499Z"
      fill="#D3ECEC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.09947 10.1957C9.14652 10.1957 9.1914 10.2045 9.2328 10.2206C9.20423 10.2372 9.18192 10.2628 9.16931 10.2933C9.1567 10.3238 9.1545 10.3577 9.16305 10.3896C9.17159 10.4215 9.19041 10.4497 9.21659 10.4698C9.24277 10.49 9.27486 10.501 9.30789 10.501C9.36482 10.501 9.41422 10.4695 9.43981 10.4228C9.47012 10.4958 9.47614 10.5767 9.45699 10.6533C9.43784 10.73 9.39453 10.7985 9.33347 10.8487C9.2724 10.8989 9.19682 10.9281 9.11787 10.932C9.03893 10.9359 8.96082 10.9144 8.89508 10.8705C8.82934 10.8266 8.77945 10.7628 8.75279 10.6884C8.72612 10.6139 8.7241 10.5329 8.74701 10.4573C8.76992 10.3816 8.81656 10.3154 8.88003 10.2683C8.94349 10.2212 9.02043 10.1957 9.09947 10.1957ZM0 8.98365H23.9312C23.4102 8.85153 22.2826 8.67294 22.4685 7.98999C21.521 9.0864 19.2361 8.75923 18.6594 8.21864C18.0172 9.15001 14.2788 8.79592 14.0181 8.07035C13.213 9.01517 10.7184 9.01517 9.91331 8.07035C9.65238 8.79592 5.91403 9.15001 5.27183 8.21855C4.69521 8.75923 2.41037 9.0864 1.46282 7.99008C1.64875 8.67294 0.521198 8.85153 0 8.98374"
      fill="#364548"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4669 13.757C9.19288 13.1524 8.49365 12.3305 8.10456 11.4333C7.63126 11.5684 7.06245 11.6548 6.40142 11.6918C6.15245 11.7058 5.89048 11.7129 5.61572 11.7129C5.29881 11.7129 4.96486 11.7035 4.61426 11.685C5.78265 12.8527 7.21997 13.7517 9.88138 13.7681C10.0779 13.7681 10.2728 13.7643 10.4669 13.757Z"
      fill="#BDD9D7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5782 12.2614C8.40196 12.0221 8.23099 11.7214 8.10528 11.4333C7.63197 11.5686 7.06297 11.6549 6.40186 11.6921C6.85596 11.9386 7.50532 12.1669 8.5783 12.2614"
      fill="#D3ECEC"
    />
  </svg>,
  "Docker",
);
