import { Box, TableCell, TableRow } from "@mui/material";
import { Loading } from "../loading";

export type LoadingRowProps = {
  colSpan: number;
  what: string;
  id?: string;
};

export function LoadingRow({ colSpan, what, id }: LoadingRowProps) {
  return (
    <TableRow
      id={id}
      className="loading-row"
      sx={{ borderBottom: "none", "&:hover": { backgroundColor: "unset" } }}
    >
      <TableCell colSpan={colSpan} sx={{ py: 0, maxWidth: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 1,
            height: 100,
          }}
        >
          <Loading what={what} />
        </Box>
      </TableCell>
    </TableRow>
  );
}
