import { useTheme } from "@mui/material";
import { ComplianceFrameworkState } from "~/operations";
import { BarChartIcon } from "~/components/ui-library";
import { BlockIcon, PreviewIcon } from "~/components/icons";
import { QuestionMark } from "@mui/icons-material";

type useFrameworkStateParams = {
  state: ComplianceFrameworkState;
};

export function useFrameworkState({ state }: useFrameworkStateParams) {
  const theme = useTheme();

  const getLabel = (state: ComplianceFrameworkState) => {
    switch (state) {
      case ComplianceFrameworkState.Active:
        return "Active";
      case ComplianceFrameworkState.Preview:
        return "Preview";
      case ComplianceFrameworkState.Disabled:
        return "Disabled";
      default:
        return "Unknown";
    }
  };

  const getColor = (state: ComplianceFrameworkState) => {
    switch (state) {
      case ComplianceFrameworkState.Active:
        return theme.palette.none.main;
      case ComplianceFrameworkState.Preview:
      case ComplianceFrameworkState.Disabled:
      default:
        return theme.palette.text.primary;
    }
  };

  const getIcon = (state: ComplianceFrameworkState) => {
    switch (state) {
      case ComplianceFrameworkState.Active:
        return BarChartIcon;
      case ComplianceFrameworkState.Preview:
        return PreviewIcon;
      case ComplianceFrameworkState.Disabled:
        return BlockIcon;
      default:
        return QuestionMark;
    }
  };

  const getDescription = (state: ComplianceFrameworkState) => {
    switch (state) {
      case ComplianceFrameworkState.Active:
        return "Work toward a scheduled audit using this framework";
      case ComplianceFrameworkState.Preview:
        return "See preliminary progress toward compliance with this framework";
      case ComplianceFrameworkState.Disabled:
        return "Remove this framework from Compliance Hub for this space";
      default:
        return "";
    }
  };

  return {
    getLabel,
    getDescription,
    getColor,
    getIcon,
  };
}
