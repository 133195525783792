import { Box, Grid } from "@mui/material";
import React from "react";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import {
  CasesBreadcrumbs,
  ClosedCases,
  CreateFirstCase,
  OpenCases,
  Title,
} from "./components";
import { CreateTicketingIntegration } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration";
import { useCasesPage } from "~/components/cases/hooks/useCasesPage";
import { ScrollToTop } from "~/lib/scroll-to-top";
import { CasesTabs } from "~/components/cases/components/CasesPage/components/CasesTabs/CasesTabs";
import { CASES_TABS } from "~/components/cases/constants";

type CasesPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const CasesPage = ({ space, availablePermissions }: CasesPageProps) => {
  const {
    hasIntegrationCreatePermission,
    hasCloseCasePermissions,
    openCases,
    openCasesDataLoading,
    closedCases,
    closedCasesDataLoading,
    showCreateFirstCaseScreen,
    showCreateTicketingIntegrationScreen,
    hasCases,
    tabList,
    currentTab,
    fetchMoreOpenCases,
    fetchMoreClosedCases,
    openCasesPageInfo,
    openCasesTotalCount,
    closedCasesPageInfo,
    closedCasesTotalCount,
    onTabChange,
  } = useCasesPage({
    space,
    availablePermissions,
  });
  return (
    <Box>
      <ScrollToTop />
      <Grid container>
        <CasesBreadcrumbs space={space} />
        <Title />
        {/* No API Support and no needed for first iteration */}
        {/*<FilterSection />*/}

        {!showCreateFirstCaseScreen &&
          !showCreateTicketingIntegrationScreen && (
            <Grid item xs={12}>
              <CasesTabs
                tabs={tabList}
                onTabChange={onTabChange}
                currentTab={currentTab}
              />
            </Grid>
          )}
        {showCreateFirstCaseScreen && <CreateFirstCase space={space} />}

        {hasCases && (
          <>
            {currentTab === CASES_TABS.OPEN ? (
              <OpenCases
                openCases={openCases}
                hasCloseCasePermissions={hasCloseCasePermissions}
                isLoading={openCasesDataLoading}
                fetchMoreOpenCases={fetchMoreOpenCases}
                openCasesPageInfo={openCasesPageInfo}
                openCasesTotalCount={openCasesTotalCount}
                space={space}
              />
            ) : (
              <ClosedCases
                closedCases={closedCases}
                hasCloseCasePermissions={hasCloseCasePermissions}
                isLoading={closedCasesDataLoading}
                closedCasesPageInfo={closedCasesPageInfo}
                closedCasesTotalCount={closedCasesTotalCount}
                fetchMoreClosedCases={fetchMoreClosedCases}
                space={space}
              />
            )}
          </>
        )}

        {showCreateTicketingIntegrationScreen && (
          <CreateTicketingIntegration
            space={space}
            hasIntegrationCreatePermission={hasIntegrationCreatePermission}
          />
        )}
      </Grid>
    </Box>
  );
};
