import { createSvgIcon } from "@mui/material";

export const OCIColorIcon = createSvgIcon(
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 1522.6 964.4"
    fill="currentColor"
  >
    <path
      id="Layer"
      fill="#ea1b22"
      d="M494.6,964c-129.7,3.4-255.2-45.8-348.1-136.4C53.6,737,1.2,612.7,1.2,482.8
	c0-129.8,52.4-254.1,145.3-344.8C239.4,47.5,364.9-1.7,494.6,1.7h559c125.4,3.3,244.5,55.4,332,145.3S1522,357.4,1522,482.8
	c0,125.5-49,246.1-136.5,335.9c-87.5,89.9-206.6,142-332,145.3H494.6z M1040.9,794.6c84.3,2.7,166.1-28.9,226.6-87.6
	c60.6-58.7,94.8-139.5,94.8-223.8c0-84.4-34.2-165.1-94.8-223.9c-60.6-58.7-142.4-90.3-226.6-87.6h-534
	c-80.8,2.6-157.4,36.6-213.6,94.7c-56.3,58.1-87.7,135.9-87.7,216.8c0,80.9,31.4,158.6,87.7,216.8c56.2,58.1,132.8,92.1,213.6,94.7
	H1040.9z"
    />
  </svg>,
  "OCIColor",
);
