import { Link as RouterLink } from "react-router-dom";
import { AppBar, Toolbar, Box, IconButton, useTheme } from "@mui/material";
import { useViewer } from "~/providers/viewer";
import { AppNavBreadcrumbs } from "./AppNavBreadcrumbs";
import { ChangeLogButton } from "./changelog";
import { UserMenu } from "./user-menu";
import { useColorMode } from "~/providers/color-mode";
import { useAppNavDrawer } from "~/providers/app-nav-drawer";
import { LoadViewerDocument } from "~/operations";
import { MondooLogoHorizontal } from "~/images/icons";
import {
  GitHubIcon,
  MenuBookIcon,
  SlackIcon,
  LightModeIcon,
  DarkModeIcon,
} from "~/components/icons";
import { Config } from "~/configuration_provider";
import { NavbarTooltip } from "./navbar-tooltip";
import { AppSearch } from "./app-search";

export type AppNavToolbarProps = {};

export function AppNavToolbar({}: AppNavToolbarProps) {
  const { updateViewerSettings } = useViewer();
  const { mode } = useColorMode();
  const navDrawer = useAppNavDrawer();
  const theme = useTheme();

  const handleChangeColorMode = () => {
    const nextMode = mode === "dark" ? "light" : "dark";

    updateViewerSettings({
      variables: { key: "colormode", value: nextMode },
      refetchQueries: [LoadViewerDocument],
    });
  };

  return (
    <Box sx={{ height: navDrawer.navbarHeight }}>
      <AppBar
        position="fixed"
        enableColorOnDark
        elevation={0}
        sx={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "primary.darker",
        }}
      >
        <Toolbar disableGutters>
          <Box
            component={RouterLink}
            id="toolbar-logo"
            to="/dashboard"
            title="Mondoo Dashboard"
            data-name="nav-mondoo-logo"
            sx={{
              display: "flex",
              pl: 2,
              width: navDrawer.width,
              height: (theme) => theme.spacing(8),
              borderRight: "1px solid rgba(0, 0, 0, 0.2)",
              transition: (theme) =>
                theme.transitions.create("width", navDrawer.transition),
              overflow: "hidden",
              flexShrink: 0,
            }}
          >
            <MondooLogoHorizontal
              layout={{
                flexShrink: 0,
                width: "112px",
                transition: (theme) =>
                  theme.transitions.create("opacity", navDrawer.transition),
                "#mondoo-logo-text": {
                  transition: (theme) =>
                    theme.transitions.create("opacity", navDrawer.transition),
                },
              }}
              iconOnly={!navDrawer.open}
              color={theme.palette.common.white}
            />
          </Box>

          <AppNavBreadcrumbs />

          <NavbarTooltip title={mode === "dark" ? "Light Mode" : "Dark Mode"}>
            <IconButton
              data-name="nav-color-mode"
              color="inherit"
              size="large"
              onClick={handleChangeColorMode}
            >
              {mode === "dark" ? <LightModeIcon /> : <DarkModeIcon />}
            </IconButton>
          </NavbarTooltip>

          <NavbarTooltip title="Documentation">
            <IconButton
              data-name="nav-docs-button"
              color="inherit"
              size="large"
              href={"https://mondoo.com/docs/platform/home/"}
              target="_blank"
            >
              <MenuBookIcon />
            </IconButton>
          </NavbarTooltip>
          <NavbarTooltip title="Mondoo GitHub">
            <IconButton
              data-name="nav-github-button"
              color="inherit"
              size="large"
              href={"https://github.com/mondoohq/"}
              target="_blank"
            >
              <GitHubIcon />
            </IconButton>
          </NavbarTooltip>
          <NavbarTooltip title="Mondoo Community">
            <IconButton
              data-name="nav-slack-button"
              color="inherit"
              size="large"
              href={`${Config.VITE_COMMUNITY_INVITE_LINK}`}
              target="_blank"
            >
              <SlackIcon />
            </IconButton>
          </NavbarTooltip>
          <ChangeLogButton />
          <UserMenu />
          <AppSearch />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
