import { Box, Grid, Paper, Tooltip, Typography } from "@mui/material";
import { ListDiscoveryResultsQuery } from "~/operations";
import { AWSIntegrationStatus } from "~/pages/integrations/cloud-security/aws/integration-aws-account-serverless";
import { Space } from "~/lib/types";
import { ConfigurationsAndMessages } from "~/pages/integrations/components/configurations-and-messages";
import { HostedClientIntegration } from "~/pages/integrations/types";
import { Clamp } from "./clamp/clamp";
import { DynamicIcon } from "./icons/mondoo/aws-resources/DynamicIcon";

type DiscoveredAsset = NonNullable<
  ListDiscoveryResultsQuery["listDiscoveryResults"]["stats"]
>[0];

type Props = {
  space: Space;
  integration: HostedClientIntegration;
  integrationMrn: string;
  integrationStatus: AWSIntegrationStatus;
  discoveredAssets: DiscoveredAsset[];
  token?: string;
};

export function AWSOverviewAccountTab({
  space,
  integration,
  integrationMrn,
  integrationStatus,
  discoveredAssets,
  token,
}: Props) {
  const isServerless =
    integration.configurationOptions?.__typename === "AWSConfigurationOptions";
  const integrationHealth = getHealth(integrationStatus);
  // get all non EC2 assets
  const allOtherAssets = discoveredAssets.filter(
    (asset) => !asset.query.startsWith("aws.ec2") && asset.count > 0,
  );

  // get all EC2 assets
  const allEC2Assets = discoveredAssets.filter(
    (asset) => asset.query.startsWith("aws.ec2") && asset.count > 0,
  );

  // total up all the non EC2 assets
  const otherAssetCount = allOtherAssets.reduce(
    (agg, asset) => (agg += asset.count),
    0,
  );

  // total up all EC2 assets
  const ec2AssetCount = allEC2Assets.reduce(
    (agg, asset) => (agg += asset.count),
    0,
  );

  // Deduplicate and merge the counts of each discovered asset
  const mergedOtherAssets = mergeAssets(allOtherAssets);
  const mergedEC2Assets = mergeAssets(allEC2Assets);

  return (
    <Box id="overview-accounts-tab" component={Paper} p={2} mb={3}>
      {isServerless && (
        <Box mb={3}>
          <Typography
            sx={{
              textTransform: "uppercase",
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: "1.5rem",
              pb: 1,
              mb: 3,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            Discovered Resources ({otherAssetCount})
          </Typography>
          <Box>
            {integrationHealth === "empty" && <EmptyAssetDiscovery />}
            {integrationHealth === "healthy" && (
              <Box>
                {/* Non EC2 Resources */}
                <Grid
                  id="detected-resources-group"
                  container
                  item
                  rowSpacing={3}
                  columnSpacing={2}
                  sx={{ mb: 5 }}
                >
                  {otherAssetCount > 0 &&
                    Object.values(mergedOtherAssets).map((asset) => (
                      <ResourceCard key={asset.title} {...{ asset }} />
                    ))}
                </Grid>
                {/* EC2 Resources */}
                <Typography
                  className="section-title"
                  sx={{
                    textTransform: "uppercase",
                    fontWeight: 500,
                    fontSize: "0.875rem",
                    lineHeight: "1.5rem",
                    pb: 1,
                    mb: 3,
                    borderBottom: "1px solid",
                    borderColor: "divider",
                  }}
                >
                  Summary of Detected EC2 Instances ({ec2AssetCount})
                </Typography>
                <Grid
                  id="detected-ec2-group"
                  container
                  item
                  rowSpacing={3}
                  columnSpacing={2}
                  mb={5}
                >
                  {ec2AssetCount > 0 &&
                    Object.values(mergedEC2Assets).map((asset) => (
                      <ResourceCard key={asset.title} {...{ asset }} />
                    ))}
                </Grid>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Box>
        <ConfigurationsAndMessages {...{ integration, token }} />
      </Box>
    </Box>
  );
}

export const ResourceCard = ({ asset }: { asset: DiscoveredAsset }) => {
  return (
    <Grid item key={asset.title} xs={6} sm={4} md={3}>
      <Tooltip
        title={
          <Box>
            <Typography display="block" variant="caption">
              {asset.title}
            </Typography>
            <Typography
              display="block"
              variant="caption"
              color="text.secondary"
            >
              {asset.query}
            </Typography>
          </Box>
        }
        placement="top"
        arrow
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            borderRadius: 0.5,
            height: 40,
          }}
        >
          <Box
            sx={{
              display: "flex",
              borderRadius: 1,
              overflow: "hidden",
              svg: { height: 40, width: 40 },
            }}
          >
            <DynamicIcon title={asset.title} />
          </Box>
          <Box>
            <Typography fontWeight={700} lineHeight={1}>
              <Clamp>{asset.title}</Clamp>
            </Typography>
            <Typography
              variant="caption"
              color="text.secondary"
              textTransform="uppercase"
            >
              {asset.count} discovered
            </Typography>
          </Box>
        </Box>
      </Tooltip>
    </Grid>
  );
};

export const EmptyAssetDiscovery = () => {
  return (
    <Box>
      <Typography>
        A list of discovered resources will appear here when the integration is
        active.
      </Typography>
    </Box>
  );
};

export const getHealth = (
  status: AWSIntegrationStatus,
): "healthy" | "empty" | "unhealthy" => {
  switch (status.toLowerCase()) {
    case "active":
    case "configuring":
    case "registering":
    case "warning":
      return "healthy";
    case "waiting_for_setup":
    case "setup_in_progress":
    case "not_ready":
      return "empty";
    default:
      return "healthy";
  }
};

// This function removes duplicate queries while summing the count
const mergeAssets = (array: DiscoveredAsset[]) => {
  let merged: {
    [key: DiscoveredAsset["query"]]: DiscoveredAsset;
  } = {};
  array.forEach((item) => {
    merged[item.query] = {
      identifier: item.identifier,
      title: item.title,
      count: item.count + (merged[item.query]?.count || 0),
      query: item.query,
      __typename: "DiscoveryStat",
    };
  });

  return merged;
};
