import { useNavigate, useSearchParams } from "react-router-dom";
import { OrderDirection } from "~/operations";

type UseSortParams = {
  validFields?: Array<string | null>;
  defaultSort: {
    direction: OrderDirection;
    field: string;
  };
  prefix?: string;
};

export function useSort<T>({
  validFields = [],
  defaultSort,
  prefix,
}: UseSortParams) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const url = new URL(window.location.toString());

  const paramField =
    searchParams.get(`${prefix || ""}field`) || defaultSort.field;
  const paramDirection =
    searchParams.get(`${prefix || ""}direction`) || defaultSort.direction;
  const field =
    validFields.length !== 0 && validFields.includes(paramField)
      ? paramField
      : defaultSort.field;
  const direction = (paramDirection || defaultSort.direction) as OrderDirection;

  const handleSortClick = (id: string) => {
    if (field === id && direction === OrderDirection.Asc) {
      url.searchParams.set(`${prefix || ""}direction`, OrderDirection.Desc);
    } else {
      url.searchParams.set(`${prefix || ""}direction`, OrderDirection.Asc);
    }

    url.searchParams.set(`${prefix || ""}field`, id);

    navigate(url.pathname + url.search, { replace: true });
  };

  const orderBy = {
    direction,
    field,
  } as T;

  return {
    orderBy,
    handleSortClick,
  };
}
