import React from "react";
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Command } from "~/components/guides/components";
import { Control, Controller } from "react-hook-form";
import {
  EMAIL_PATTERN,
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import { UseFormGetFieldState } from "react-hook-form/dist/types/form";
import { ChevronRightIcon } from "~/components/icons";
import { ZendeskFormInput } from "~/pages/integrations/hosted-integrations/forms/zendesk/types";
import { Flex } from "~/components/ui-library";

type JiraFormStep1Props = {
  control: Control<ZendeskFormInput>;
  errors: FieldErrors<ZendeskFormInput>;
  isUpdateFlow: boolean;
  isValid: boolean;
  getFieldState: UseFormGetFieldState<ZendeskFormInput>;
  disabled: boolean;
};

export const ZendeskFormStep1 = ({
  control,
  errors,
  isUpdateFlow,
  getFieldState,
  isValid,
}: JiraFormStep1Props) => {
  const theme = useTheme();

  return (
    <>
      {/* Step 1 */}
      <Box pb={4}>
        <Command
          number={1}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Choose an integration name
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Please choose a descriptive name that lets you easily identify your
            integration.
          </Typography>
          <Controller
            name="integrationName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder="Your integration name..."
                error={Boolean(errors.integrationName)}
                helperText={
                  Boolean(errors.integrationName) && (
                    <ValidationMessage error={errors.integrationName} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>

      {/* Step 2 */}
      <Box pb={4}>
        <Command
          number={2}
          options={{
            fontSize: { xs: 16 },
            dotColor: theme.palette.background.lightest,
          }}
        >
          Provide your Zendesk credentials
        </Command>

        <Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ mb: 2, mt: 2 }}
          >
            Provide the credentials for the Zendesk account in which you want to
            create tickets. To learn more, read the{" "}
            <Link
              href="https://mondoo.com/docs/platform/cases/setup-zendesk/"
              target="_blank"
              rel="noopener"
            >
              Mondoo documentation
            </Link>
            .
          </Typography>
          <Typography fontWeight={700} sx={{ mb: 1 }}>
            Zendesk subdomain
          </Typography>
          <Flex sx={{ position: "relative", alignItems: "center" }}>
            <Controller
              name="subdomain"
              control={control}
              rules={{
                required: true,
                validate: {
                  isSubdomain: (value) =>
                    Boolean(
                      value.match(
                        new RegExp(/^(?!-)[A-Za-z0-9-]{1,63}(?<!-)$/),
                      ),
                    ) || "Not a valid subdomain",
                },
              }}
              render={({ field }) => {
                return (
                  <>
                    {!field.value && (
                      <Flex
                        sx={{
                          position: "absolute",
                          top: 0,
                          left: "16px",
                          height: "56px",
                          alignItems: "center",
                          pointerEvents: "none",
                          color: (theme) => theme.palette.text.disabled,
                          zIndex: 1,
                          gap: 0.5,
                        }}
                      >
                        <Typography component="span">your-subdomain</Typography>
                        <Typography color="text.primary">
                          .zendesk.com/
                        </Typography>
                      </Flex>
                    )}
                    <TextField
                      {...field}
                      fullWidth
                      disabled={Boolean(isUpdateFlow)}
                      sx={{
                        background: theme.palette.code.background,
                        borderRadius: 1,
                        color: "text.primary",
                        ...helperTextStyles,
                      }}
                      error={
                        getFieldState("subdomain").isTouched &&
                        Boolean(errors.subdomain)
                      }
                      helperText={
                        Boolean(errors.subdomain) &&
                        getFieldState("subdomain").isTouched && (
                          <ValidationMessage error={errors.subdomain} />
                        )
                      }
                    />
                  </>
                );
              }}
            />
          </Flex>
          <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
            Email address
          </Typography>
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              validate: {
                isEmail: (value) =>
                  Boolean(value.match(EMAIL_PATTERN)) || "Not a valid email",
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder="zendesk-owner@email.com"
                error={
                  getFieldState("email").isTouched && Boolean(errors.email)
                }
                helperText={
                  Boolean(errors.email) &&
                  getFieldState("email").isTouched && (
                    <ValidationMessage error={errors.email} />
                  )
                }
              />
            )}
          />
          <Typography fontWeight={700} sx={{ mb: 1, mt: 3 }}>
            {isUpdateFlow ? "Update " : ""}API token
          </Typography>
          <Controller
            name="apiToken"
            control={control}
            rules={{
              required: !isUpdateFlow,
            }}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  background: theme.palette.code.background,
                  borderRadius: 1,
                  color: "text.primary",
                  ...helperTextStyles,
                }}
                placeholder={
                  isUpdateFlow ? "●●●●●●●●●●●●●●●●" : "Your API key ..."
                }
                error={
                  getFieldState("apiToken").isTouched &&
                  Boolean(errors.apiToken)
                }
                helperText={
                  Boolean(errors.apiToken) &&
                  getFieldState("apiToken").isTouched && (
                    <ValidationMessage error={errors.apiToken} />
                  )
                }
              />
            )}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "end", mt: 5 }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          disabled={!isValid}
        >
          {isUpdateFlow ? "update configuration" : "create integration"}
        </Button>
      </Box>
    </>
  );
};
