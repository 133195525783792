export type WordEventHandler = () => void;
export type WordEventMap = {
  [word: string]: WordEventHandler;
};

class WordEvents {
  words: WordEventMap = {};
  letters = "";
  clearTimeout = 1100;
  clearTimer?: ReturnType<typeof setTimeout>;

  register(word: string, handler: WordEventHandler) {
    this.words[word] = handler;
  }

  enable() {
    window.addEventListener("keypress", (event: KeyboardEvent) => {
      if (!this._isGlobal(event)) return;
      this.addLetter(event.key);
    });
  }

  addLetter(char: string) {
    this.letters += char;

    const handler = this.words[this.letters];
    if (handler != null) {
      clearTimeout(this.clearTimer);
      return this._callEvent(handler, this.letters);
    }

    this.clearTimer = setTimeout(() => (this.letters = ""), this.clearTimeout);
  }

  _isGlobal(event: KeyboardEvent): boolean {
    const target = event.target;
    if (!(target instanceof HTMLElement)) return false;
    return target.nodeName !== "INPUT" && target.nodeName !== "TEXTAREA";
  }

  _callEvent(evt: WordEventHandler, word: string) {
    try {
      evt();
    } catch (err) {
      console.error(`global.words> failed to call event for '${word}'`);
      console.error(err);
    }
  }
}

export const GlobalWords = new WordEvents();
