import { TableCell } from "@mui/material";
import { Impact } from "~/components/impact";
import { SoftwareDetailsCommonCellProps } from "./types";

type ImpactCellProps = {
  hideTooltip?: boolean;
};

export const ImpactCell = ({
  advisory,
  cve,
  hideTooltip = false,
  ...props
}: SoftwareDetailsCommonCellProps & ImpactCellProps) => {
  const target = advisory ?? cve;
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Impact
        impact={target.cvssScore.value}
        isCVSS
        hideTooltip={hideTooltip}
      />
    </TableCell>
  );
};
