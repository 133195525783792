import { createSvgIcon } from "@mui/material";

export const PhpIconBW = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    fill="currentColor"
  >
    <path d="M320 104.5c171.4 0 303.2 72.2 303.2 151.5S491.3 407.5 320 407.5c-171.4 0-303.2-72.2-303.2-151.5S148.7 104.5 320 104.5m0-16.8C143.3 87.7 0 163 0 256s143.3 168.3 320 168.3S640 349 640 256 496.7 87.7 320 87.7zM218.2 242.5c-7.9 40.5-35.8 36.3-70.1 36.3l13.7-70.6c38 0 63.8-4.1 56.4 34.3zM97.4 350.3h36.7l8.7-44.8c41.1 0 66.6 3 90.2-19.1 26.1-24 32.9-66.7 14.3-88.1-9.7-11.2-25.3-16.7-46.5-16.7h-70.7L97.4 350.3zm185.7-213.6h36.5l-8.7 44.8c31.5 0 60.7-2.3 74.8 10.7 14.8 13.6 7.7 31-8.3 113.1h-37c15.4-79.4 18.3-86 12.7-92-5.4-5.8-17.7-4.6-47.4-4.6l-18.8 96.6h-36.5l32.7-168.6zM505 242.5c-8 41.1-36.7 36.3-70.1 36.3l13.7-70.6c38.2 0 63.8-4.1 56.4 34.3zM384.2 350.3H421l8.7-44.8c43.2 0 67.1 2.5 90.2-19.1 26.1-24 32.9-66.7 14.3-88.1-9.7-11.2-25.3-16.7-46.5-16.7H417l-32.8 168.7z" />
  </svg>,
  "Php",
);

export const PhpIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
      fill="#8892BF"
    />
    <path
      d="M10.8295 7.5H12.0895L11.7334 9.39315H12.8656C13.4865 9.40643 13.949 9.54517 14.2534 9.80947C14.5639 10.0737 14.6552 10.5761 14.5274 11.3161L13.9156 14.6167H12.6373L13.2217 11.4647C13.2825 11.1343 13.2643 10.8997 13.1669 10.761C13.0696 10.6222 12.8596 10.5529 12.5369 10.5529L11.5234 10.543L10.7746 14.6167H9.51465L10.8295 7.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.05569 9.39325H7.5027C8.2209 9.39977 8.7414 9.62455 9.06405 10.0672C9.3867 10.5099 9.4932 11.1145 9.38363 11.8811C9.3411 12.2314 9.24668 12.5749 9.10057 12.912C8.96055 13.2489 8.76585 13.5528 8.51625 13.8238C8.21182 14.1674 7.88618 14.3854 7.53923 14.478C7.19227 14.5706 6.83307 14.6168 6.46181 14.6168H5.36613L5.01917 16.5H3.75L5.05569 9.39325ZM5.57614 13.4967L6.12215 10.5331H6.30659C6.37361 10.5331 6.44355 10.5297 6.5166 10.5232C7.00363 10.5166 7.4083 10.5662 7.731 10.6718C8.05965 10.7775 8.16922 11.1774 8.05965 11.8712C7.92585 12.6972 7.66395 13.1795 7.27444 13.3183C6.88492 13.4505 6.3979 13.5132 5.81353 13.5066H5.6857C5.64918 13.5066 5.61266 13.5032 5.57614 13.4967Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.3269 9.39325H15.8799L14.5742 16.5H15.8434L16.1904 14.6168H17.2861C17.6573 14.6168 18.0165 14.5706 18.3634 14.478C18.7105 14.3854 19.036 14.1674 19.3405 13.8238C19.5901 13.5528 19.7848 13.2489 19.9248 12.912C20.0709 12.5749 20.1653 12.2314 20.2078 11.8811C20.3174 11.1145 20.211 10.5099 19.8883 10.0672C19.5656 9.62455 19.0452 9.39977 18.3269 9.39325ZM16.9464 10.5331L16.4004 13.4967C16.4369 13.5032 16.4734 13.5066 16.51 13.5066H16.6378C17.2222 13.5132 17.7091 13.4505 18.0987 13.3183C18.4882 13.1795 18.7501 12.6972 18.8839 11.8712C18.9935 11.1774 18.8839 10.7775 18.5552 10.6718C18.2326 10.5662 17.8279 10.5166 17.3408 10.5232C17.2678 10.5297 17.1979 10.5331 17.1308 10.5331H16.9464Z"
      fill="white"
    />
  </svg>,
  "Php",
);
