import { Box, Typography } from "@mui/material";

type SuccessCardProps = {
  headline: string;
  textContent?: React.ReactNode;
  icon: React.ReactNode;
};

export function SuccessCard({ headline, textContent, icon }: SuccessCardProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 2,
        height: 1,
      }}
    >
      {icon}
      <Typography
        variant="h5"
        fontWeight={700}
        sx={{ mt: 2, mb: 1, textAlign: "center" }}
      >
        {headline}
      </Typography>
      {textContent && (
        <Typography sx={{ textAlign: "center", color: "text.secondary" }}>
          {textContent}
        </Typography>
      )}
    </Box>
  );
}
