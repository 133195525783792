import { ThemeOptions } from "@mui/material/styles";
import { sharedPalette } from "./sharedPalette";

export const lightTheme: ThemeOptions = {
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: sharedPalette.primary.main,
          textDecoration: "none",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#f0f0f0",
          color: "#363636",
          boxShadow:
            "0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033",
        },
        arrow: {
          "&::before": {
            backgroundColor: "#f0f0f0",
            boxShadow:
              "1px 1px 3px 0px #0000001F, 1px 1px 1px 0px #00000024, 2px 2px 1px -1px #0003",
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    background: {
      default: "#F7F7F8",
      paper: "#FFFFFF",
      light: "rgb(232,232,232)",
      lighter: "rgb(222,222,222)",
      lightest: "rgb(215,215,215)",
    },
    text: {
      primary: "#363636",
      secondary: "#676767",
      disabled: "#9C9C9C",
    },
    code: {
      background: "#FFF",
      flash: "rgba(170,170,170,0.7)",
    },
    tables: {
      tableHead: {
        shadow: "0 2px 4px 0 rgba(20,20,65,0.05)",
      },
      tableCell: {
        borderBottom: "1px solid rgba(0,0,0,0.13)",
      },
    },

    ...sharedPalette,
  },
};
