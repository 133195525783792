import { createSvgIcon } from "@mui/material";

export const BooleanIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <g clipPath="url(#clip0_2655_20416)">
      <path d="M6.68043 7.90985H15.7709C16.5066 7.90985 17.2122 7.60756 17.7324 7.06947C18.2526 6.53138 18.5448 5.80157 18.5448 5.0406C18.5462 4.66341 18.4754 4.28966 18.3365 3.9409C18.1975 3.59215 17.9933 3.27528 17.7354 3.00856C17.4776 2.74184 17.1712 2.53055 16.8341 2.38686C16.4969 2.24317 16.1356 2.16992 15.7709 2.17135H6.65965C6.11203 1.6097 5.41594 1.22827 4.65911 1.07515C3.90227 0.922021 3.11854 1.00404 2.40669 1.31087C1.69484 1.6177 1.08672 2.13561 0.658957 2.79934C0.231194 3.46306 0.00292969 4.24291 0.00292969 5.0406C0.00292969 5.83829 0.231194 6.61814 0.658957 7.28186C1.08672 7.94559 1.69484 8.4635 2.40669 8.77033C3.11854 9.07716 3.90227 9.15918 4.65911 9.00605C5.41594 8.85292 6.11203 8.4715 6.65965 7.90985H6.68043ZM17.3812 5.0406C17.3812 5.48236 17.2116 5.90603 16.9096 6.21841C16.6076 6.53078 16.198 6.70627 15.7709 6.70627H7.45962C7.68556 6.18585 7.80241 5.62184 7.80246 5.05135C7.79988 4.47308 7.67952 3.90186 7.44923 3.37493H15.7605C15.9733 3.37206 16.1844 3.41311 16.3816 3.49567C16.5788 3.57823 16.7581 3.70065 16.909 3.85575C17.0599 4.01085 17.1794 4.19553 17.2605 4.39897C17.3416 4.60242 17.3826 4.82055 17.3812 5.0406Z" />
      <path d="M15.8845 10.9081C14.8579 10.905 13.8719 11.3222 13.1417 12.0687H3.93698C3.2013 12.0687 2.49575 12.371 1.97554 12.909C1.45533 13.4471 1.16309 14.1769 1.16309 14.9379C1.16309 15.6989 1.45533 16.4287 1.97554 16.9668C2.49575 17.5049 3.2013 17.8072 3.93698 17.8072H13.121C13.4817 18.1861 13.912 18.4869 14.3865 18.6917C14.861 18.8965 15.3703 19.0013 15.8845 19C16.4151 19.0283 16.9457 18.9446 17.4441 18.7541C17.9425 18.5635 18.3982 18.2701 18.7834 17.8916C19.1687 17.5131 19.4755 17.0575 19.6851 16.5525C19.8947 16.0475 20.0028 15.5036 20.0028 14.954C20.0028 14.4044 19.8947 13.8606 19.6851 13.3556C19.4755 12.8506 19.1687 12.395 18.7834 12.0165C18.3982 11.638 17.9425 11.3445 17.4441 11.154C16.9457 10.9634 16.4151 10.8798 15.8845 10.9081ZM3.93698 16.6036C3.5099 16.6036 3.10031 16.4281 2.79832 16.1157C2.49633 15.8033 2.32667 15.3797 2.32667 14.9379C2.32667 14.4961 2.49633 14.0725 2.79832 13.7601C3.10031 13.4477 3.5099 13.2722 3.93698 13.2722H12.3314C12.1003 13.8065 11.98 14.3849 11.9782 14.9701C11.9782 15.5406 12.0951 16.1047 12.321 16.6251L3.93698 16.6036Z" />
    </g>
    <defs>
      <clipPath id="clip0_2655_20416">
        <rect width="20" height="20" />
      </clipPath>
    </defs>
  </svg>,
  "Boolean",
);
