import { Asset } from "~/pages/inventory/asset/types";
import { platformTitle } from "~/lib/platform";
import { getMajorScore } from "~/lib/score";

export const clipboardDetails = (asset: Asset): string[] => {
  const title = asset.name || asset.id;
  const details = [
    "name: " + title,
    "platform: " + platformTitle(asset.platform),
    "score: " + `${getMajorScore(asset.score)} (${asset.score.value})`,
  ];

  for (const { key, value } of asset.labels) {
    switch (key) {
      case "os.mondoo.app/hostname":
        details.push("hostname: " + value);
        break;
      case "os.mondoo.app/ip":
        details.push("ip: " + value);
        break;
      case "mondoo.app/region":
        details.push("region: " + value);
        break;
      case "mondoo.app/instance":
        details.push("instance: " + value);
        break;
    }
  }

  return details;
};
