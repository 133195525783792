import { MajorScore } from "./scores/mondoo-score-card";
import { alpha, Box, Typography } from "@mui/material";
import { getColor } from "~/lib/colors";
import { Asset, ListPolicyEdge } from "~/pages/inventory/asset/types";

type Props = {
  score: Asset["score"] | ListPolicyEdge["node"]["score"];
  policyBox?: boolean;
};

export function AssetScoreBox({ score, policyBox }: Props) {
  const major = MajorScore(score);
  const muiWidth = policyBox ? 16 : 23;
  const lineHeight = policyBox ? "48px" : "104px";
  const majorFontSize = policyBox ? 56 : 104;

  return (
    <Box
      sx={{
        p: 2.75,
        width: (theme) => theme.spacing(muiWidth),
        height: (theme) => theme.spacing(muiWidth),
        backgroundColor: (theme) => alpha(getColor(theme, major), 0.1),
        borderRadius: 1,
      }}
    >
      <Box sx={{ mt: 0.5, mb: 1 }}>
        <Typography
          fontSize={majorFontSize}
          fontWeight={700}
          sx={{
            lineHeight,
            textAlign: "center",
            color: (theme) => getColor(theme, major),
          }}
        >
          {major}
        </Typography>
      </Box>
      <Box
        sx={{ textAlign: "center", color: (theme) => getColor(theme, major) }}
      >
        <Typography fontWeight={700}>{score.value}/100</Typography>
      </Box>
    </Box>
  );
}
