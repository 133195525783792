import platform from "platform";
import semver from "semver";

export function getPlatform() {
  if (!("navigator" in window)) {
    return "macos";
  }

  const os = (
    navigator.userAgentData?.platform || navigator.platform
  )?.toLowerCase();

  if (os.startsWith("win")) return "windows";
  if (os.startsWith("mac")) return "macos";
  if (os.startsWith("linux")) return "linux";

  return "macos";
}

// Temporary utility helper for:
// https://github.com/firebase/firebase-js-sdk/issues/6716
export function isSafari16Plus() {
  const { name, version } = platform;
  const target = "16.1.0";
  const current = semver.coerce(version) || "0.0.0";
  return name === "Safari" && semver.gte(current, target);
}
