import type {
  CheckSelectionState,
  ExceptionButton,
  SelectedEntity,
} from "~/components/exceptions/types";
import { ExceptionTarget } from "~/components/exceptions/types";
import { ExceptionMutationAction } from "~/operations";
import { targetLabelMap } from "~/components/exceptions/constants";

type UseExceptionsTooltipParams = {
  selectedEntities: SelectedEntity[];
  showScopeButtons: boolean;
  showExceptionButtons: boolean;
  target: ExceptionTarget;
};

export function useExceptionsToolbar(params: UseExceptionsTooltipParams) {
  const { selectedEntities, showScopeButtons, showExceptionButtons, target } =
    params;

  const hasEnabledSelectedCheck = selectedEntities.some(
    (check) => !check.exception,
  );

  const hasOutOfScopeCheck = selectedEntities.some(
    (check) => check.exception?.action === ExceptionMutationAction.OutOfScope,
  );

  const hasSnoozedOrDisabledChecks = selectedEntities.some((check) =>
    [ExceptionMutationAction.Snooze, ExceptionMutationAction.Disable].includes(
      check.exception?.action as ExceptionMutationAction,
    ),
  );

  const hasAllChecksSnoozedOrDisabled = selectedEntities.every(
    (check) =>
      check.exception?.action === ExceptionMutationAction.Disable ||
      check.exception?.action === ExceptionMutationAction.Snooze,
  );

  const hasAllChecksOutOfScope = selectedEntities.every(
    (check) => check.exception?.action === ExceptionMutationAction.OutOfScope,
  );

  const hasAllChecksEnabled = selectedEntities.every(
    (check) => !check.exception,
  );

  // We need to determine in which state is currently our toolbar buttons to know which of them show/hide/disable
  // Also to determine which text to display in a tooltip for different combinations (exception + out of scope selections etc...)
  function getExceptionSelectionState(): CheckSelectionState {
    const isOnlyEnabled = selectedEntities.every(
      (check) => check.exception?.action === ExceptionMutationAction.Enable,
    );

    const isOnlyOutOfScopeSelected = selectedEntities.every(
      (check) => check.exception?.action === ExceptionMutationAction.OutOfScope,
    );

    const isOnlySnoozedOrDisabled = selectedEntities.every(
      (check) =>
        check.exception?.action === ExceptionMutationAction.Disable ||
        check.exception?.action === ExceptionMutationAction.Snooze,
    );

    if (isOnlyEnabled) {
      return "ENABLED";
    }

    if (isOnlyOutOfScopeSelected) {
      return "OUT_OF_SCOPE";
    }

    if (isOnlySnoozedOrDisabled) {
      return "SNOOZED_OR_DISABLED";
    }

    if (hasOutOfScopeCheck && hasSnoozedOrDisabledChecks) {
      return "MIXED";
    }

    if (hasEnabledSelectedCheck && hasSnoozedOrDisabledChecks) {
      return "ENABLED_WITH_SNOOZED_OR_DISABLED";
    }

    if (hasEnabledSelectedCheck && hasOutOfScopeCheck) {
      return "ENABLED_WITH_OUT_OF_SCOPE";
    }

    return "ENABLED";
  }

  function getButtonsTooltipBySelectionState(): Record<
    ExceptionButton,
    string
  > {
    switch (getExceptionSelectionState()) {
      case "SNOOZED_OR_DISABLED":
        return {
          REMOVE_EXCEPTION: "",
          SET_EXCEPTION: `You can't set an exception because selected ${targetLabelMap[target]}s already contain one.`,
          SET_IN_SCOPE: "",
          SET_OUT_OF_SCOPE: `You can't set an ${targetLabelMap[target]} in scope because it contains an exception.`,
        };
      case "OUT_OF_SCOPE":
        return {
          REMOVE_EXCEPTION: "",
          SET_EXCEPTION: `You can't set an exception because this ${targetLabelMap[target]} is out of scope; it's already excluded from compliance with this framework`,
          SET_IN_SCOPE: "",
          SET_OUT_OF_SCOPE: "",
        };
      case "MIXED":
      case "ENABLED_WITH_SNOOZED_OR_DISABLED":
      case "ENABLED_WITH_OUT_OF_SCOPE":
      case "SNOOZED_OR_DISABLED_WITH_OUT_OF_SCOPE":
        const text = `You can't make bulk changes to these ${
          targetLabelMap[target]
        }s because they are in different states (${
          showScopeButtons ? "in scope, out of scope, or " : ""
        }with or without exceptions). To make bulk changes, select only ${
          targetLabelMap[target]
        }s that are in the same state.`;
        return {
          REMOVE_EXCEPTION: text,
          SET_EXCEPTION: text,
          SET_IN_SCOPE: text,
          SET_OUT_OF_SCOPE: text,
        };
      case "ENABLED":
      default:
        return {
          REMOVE_EXCEPTION: "",
          SET_EXCEPTION: "",
          SET_IN_SCOPE: "",
          SET_OUT_OF_SCOPE: "",
        };
    }
  }

  const exceptionsButtonsVisibility: Record<ExceptionButton, boolean> = {
    REMOVE_EXCEPTION: hasSnoozedOrDisabledChecks && showExceptionButtons,
    SET_EXCEPTION: showExceptionButtons,
    SET_IN_SCOPE: hasOutOfScopeCheck && showScopeButtons,
    SET_OUT_OF_SCOPE: hasEnabledSelectedCheck && showScopeButtons,
  };

  const exceptionsButtonsAvailability: Record<ExceptionButton, boolean> = {
    REMOVE_EXCEPTION: hasAllChecksSnoozedOrDisabled,
    SET_EXCEPTION: hasAllChecksEnabled,
    SET_IN_SCOPE: hasAllChecksOutOfScope,
    SET_OUT_OF_SCOPE: hasAllChecksEnabled,
  };

  const exceptionsButtonsTooltipText = getButtonsTooltipBySelectionState();

  return {
    exceptionsButtonsVisibility,
    exceptionsButtonsAvailability,
    exceptionsButtonsTooltipText,
  };
}
