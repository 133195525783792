import { CopyIcon } from "../icons";
import {
  UseCopyTableToClipboardProps,
  useCopyTableToClipboard,
} from "./use-copy-table-to-clipboard";
import { Box, Button, SxProps } from "@mui/material";

export type CopyTableToClipboardProps = {
  tableData: UseCopyTableToClipboardProps["tableData"];
  sx?: SxProps;
};

export function CopyTableToClipboard({
  tableData,
  sx,
}: CopyTableToClipboardProps) {
  const { handleCopyTableToClipboard } = useCopyTableToClipboard({ tableData });
  return (
    <Box sx={sx}>
      <Button startIcon={<CopyIcon />} onClick={handleCopyTableToClipboard}>
        Copy table to clipboard
      </Button>
    </Box>
  );
}
