import { Fragment } from "react";
import { styled } from "@mui/material";
import { AnimatedStarsComponent } from "../components/animatedStars";
import { EmailVerificationComponent } from "~/unauthenticatedApp/components/user-management/emailVerification";
import { RecoverEmailComponent } from "~/unauthenticatedApp/components/user-management/recoverEmail";
import { ResetPasswordComponent } from "~/unauthenticatedApp/components/user-management/resetPassword";

type Props = {
  mode: string;
  actionCode: string;
};

export default function UserManagementPage({ mode, actionCode }: Props) {
  const determineMode = () => {
    if (mode === "recoverEmail")
      return <RecoverEmailComponent actionCode={actionCode} />;
    if (mode === "verifyEmail")
      return <EmailVerificationComponent actionCode={actionCode} />;
    if (mode === "resetPassword")
      return <ResetPasswordComponent actionCode={actionCode} />;
  };

  return (
    <Fragment>
      <AnimatedStarsComponent />
      <StyledContainer>{determineMode()}</StyledContainer>
    </Fragment>
  );
}

const StyledContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(
    205.6deg,
    #0f1428 14.74%,
    #16155d 66.21%,
    #760f72 100%
  );
`;
