import { Fragment, useState } from "react";
import {
  alpha,
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { DashboardTable } from "./dashboard-table";
import { SeverityChip } from "~/components/severity-chip";
import {
  GetOrganizationOverviewQuery,
  useGetOrganizationOverviewQuery,
} from "~/operations";
import { CloseIcon } from "~/components/icons";
import { TransitionProps } from "@mui/material/transitions";

type RawData = NonNullable<
  GetOrganizationOverviewQuery["organizationOverview"]
>["topVulnerabilities"];
type Datum = NonNullable<RawData>[0];
type Data = Datum[];

export type TopVulnerabilitiesProps = {
  organizationMrn: string;
};

export function TopVulnerabilities({
  organizationMrn,
}: TopVulnerabilitiesProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openItems, setOpenItems] = useState<string[]>([]);
  const [focusedItem, setFocusedItem] = useState<string | null>(null);

  const result = useGetOrganizationOverviewQuery({
    variables: { input: { organizationMrn } },
  });

  if (result.loading) {
    return <>Loading</>;
  }

  const rawData = result.data?.organizationOverview?.topVulnerabilities || [];
  const data = parseData(rawData);

  if (data.length === 0) {
    return (
      <Typography
        sx={{
          fontWeight: "bold",
          color: "text.secondary",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        Enable vulnerability scanning to track your top vulnerabilities
      </Typography>
    );
  }

  const toggleItemCollapse = (item: Datum) => {
    const nextOpenItems = openItems.includes(item.cve.id)
      ? openItems.filter((id) => id !== item.cve.id)
      : [...openItems, item.cve.id];
    setOpenItems(nextOpenItems);
  };

  const handleItemClick = (item: Datum) => {
    toggleItemCollapse(item);
    setFocusedItem(item.cve.id);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setOpenItems([]);
    setFocusedItem(null);
  };

  const handleDialogEntered: TransitionProps["onEntered"] = () => {
    if (focusedItem) {
      document
        .querySelector(`#${focusedItem}`)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleDialogItemClick = (item: Datum) => {
    toggleItemCollapse(item);
  };

  return (
    <Fragment>
      <DashboardTable>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: "10%", minWidth: "90px", pr: 0 }}>
              CVSS Score
            </TableCell>
            <TableCell sx={{ width: "70%", pr: 0 }}>ID/Title</TableCell>
            <TableCell sx={{ width: "20%", minWidth: "60px" }}>
              Assets
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={[{ ".MuiTableCell-body": { fontSize: 12, cursor: "pointer" } }]}
        >
          {data.map((item) => (
            <Tooltip
              title={item.cve.title || item.cve.description}
              arrow
              placement="top"
              disableInteractive
              enterDelay={500}
              enterNextDelay={500}
              PopperProps={{
                sx: {
                  transition: (theme) => theme.transitions.create("opacity"),
                  "&[data-popper-reference-hidden]": {
                    opacity: 0,
                    pointerEvents: "none",
                  },
                  ".MuiTooltip-tooltip": {
                    p: 1,
                    fontSize: 12,
                  },
                },
              }}
            >
              <TableRow key={item.cve.id} onClick={() => handleItemClick(item)}>
                <TableCell sx={{ width: "10%", minWidth: "90px", pr: 0 }}>
                  <SeverityChip
                    severity={item.cve.cvssScore.value}
                    useCvss
                    small
                  />
                </TableCell>
                <TableCell sx={{ width: "70%", pr: 0 }}>
                  <Typography
                    component="span"
                    fontWeight="bold"
                    fontSize="inherit"
                  >
                    {item.cve.id}:{" "}
                  </Typography>
                  <Typography component="span" fontSize="inherit">
                    {item.cve.title || item.cve.description}:
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "20%", minWidth: "60px" }}>
                  {item.totalAffectedAssets}
                </TableCell>
              </TableRow>
            </Tooltip>
          ))}
        </TableBody>
      </DashboardTable>
      <ItemsDialog
        data={data}
        openItems={openItems}
        focusedItem={focusedItem}
        open={dialogOpen}
        fullWidth
        maxWidth="lg"
        onClose={handleDialogClose}
        onItemClick={handleDialogItemClick}
        TransitionProps={{ onEntered: handleDialogEntered }}
      />
    </Fragment>
  );
}

function parseData(rawData: RawData): Data {
  return rawData || [];
}

type ItemsDialogProps = {
  data: Data;
  openItems: string[];
  focusedItem: string | null;
  onItemClick: ItemRowProps["onItemClick"];
} & DialogProps;

function ItemsDialog({
  data,
  openItems,
  focusedItem,
  onItemClick,
  ...dialogProps
}: ItemsDialogProps) {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h5"
          component="span"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          Top Vulnerabilities
        </Typography>
        <IconButton
          aria-label="close"
          onClick={(e) => dialogProps.onClose?.(e, "backdropClick")}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ scrollPaddingTop: "57px" }}>
        <Table
          stickyHeader
          sx={{
            backgroundColor: "background.light",
            mb: 3,
            borderRadius: 1,
            overflow: "hidden",

            thead: { boxShadow: "none" },
            "th, td": { borderColor: "background.lightest" },
            th: { backgroundColor: "background.lightest" },
            ".toggle-row:hover": {
              backgroundColor: "background.lighter",
              cursor: "pointer",
            },
            "tr:nth-last-of-type(2) td, tr:last-of-type td": {
              borderBottom: "none",
            },
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: "10%", minWidth: "90px", pr: 0 }}>
                CVSS Score
              </TableCell>
              <TableCell sx={{ width: "90%", pr: 0 }}>ID/Title</TableCell>
              <TableCell sx={{ minWidth: "60px" }}>Assets</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <ItemRow
                key={item.cve.id}
                item={item}
                onItemClick={onItemClick}
                open={openItems.includes(item.cve.id)}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
}

type ItemRowProps = {
  item: Datum;
  open: boolean;
  onItemClick: (item: Datum) => void;
};

function ItemRow({ item, open = false, onItemClick }: ItemRowProps) {
  return (
    <Fragment>
      <TableRow
        className="toggle-row"
        onClick={() => onItemClick(item)}
        id={item.cve.id}
        sx={{
          ".MuiTableCell-root": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 0,
            fontSize: "14px",
            py: 1.5,

            "&:last-of-type": {
              textAlign: "right",
              a: {
                justifyContent: "flex-end",
              },
            },
            "&.clickable": {
              p: 0,

              a: {
                display: "flex",
                height: "100%",
                py: 1.5,
                alignItems: "center",
              },
            },
          },
        }}
      >
        <TableCell sx={{ width: "10%", minWidth: "90px", pr: 0 }}>
          <SeverityChip severity={item.cve.cvssScore.value} useCvss small />
        </TableCell>
        <TableCell sx={{ width: "70%", pr: 0 }}>
          <Typography component="span" fontWeight="bold" fontSize="inherit">
            {item.cve.id}:{" "}
          </Typography>
          <Typography component="span" fontSize="inherit">
            {item.cve.title || item.cve.description}:
          </Typography>
        </TableCell>
        <TableCell sx={{ width: "20%", minWidth: "60px", textAlign: "right" }}>
          {item.totalAffectedAssets}
        </TableCell>
      </TableRow>
      <TableRow className="collapse-row">
        <TableCell
          colSpan={3}
          sx={{ py: 0, maxWidth: 0, "&:empty": { borderBottom: "none" } }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box>
              <Typography
                variant="body1"
                sx={{ my: 3, fontWeight: "bold", textTransform: "uppercase" }}
              >
                Spaces Impacted by Vulnerability:
              </Typography>
              <Table
                sx={{
                  mb: 2,
                  borderRadius: 1,
                  overflow: "hidden",

                  "th, td": { backgroundColor: "background.lightest" },
                  td: { backgroundColor: "background.lighter" },
                  "tr:last-of-type td": { borderBottom: "none" },
                }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Owners</TableCell>
                    <TableCell>Assets</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.stats?.map((stat) => {
                    const spaceId = stat.spaceMrn.split("/").pop();
                    return (
                      <TableRow
                        key={stat.spaceMrn}
                        sx={{
                          "&:hover .MuiTableCell-root": {
                            backgroundColor: (theme) =>
                              alpha(theme.palette.background.lightest!, 0.7),
                            cursor: "pointer",
                          },
                          ".MuiTableCell-root": {
                            p: 0,
                            height: "100%",

                            a: {
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                              p: 2,
                            },
                          },
                        }}
                      >
                        <TableCell sx={{ fontWeight: "bold" }}>
                          <RouterLink
                            to={`/space/vulns/cve/${item.cve.id}?spaceId=${spaceId}`}
                            target="_blank"
                          >
                            {stat.spaceName}
                          </RouterLink>
                        </TableCell>
                        <TableCell>
                          <RouterLink
                            to={`/space/vulns/cve/${item.cve.id}?spaceId=${spaceId}`}
                            target="_blank"
                          >
                            {stat.spaceOwners.join(", ")}
                          </RouterLink>
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          <RouterLink
                            to={`/space/vulns/cve/${item.cve.id}?spaceId=${spaceId}`}
                            target="_blank"
                          >
                            {stat.amountAssets}
                          </RouterLink>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}
