import { ReactNode, useId } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { BlastMeter } from "./blast-meter";
import { blastValueMap } from "~/components/blast/const";

export type BlastRadiusProps = {
  /**
   * Blast radius values are tshirt sizes: `none`, `s`, `m`, `l`.  Defaults to `none`.
   */
  radius?: string;
  /**
   * Display label next to blast radius icon. Leave `undefined` to hide label. Defaults to `undefined`.
   */
  label?: string;
  /**
   * Display tooltip on hover. Leave `undefined` to hide tooltip. Defaults to `undefined`.
   */
  tooltip?: ReactNode;
  /**
   * Color of blast radius icon. CSS color strings or theme palette strings are accepted. Defaults to `primary.main`.
   */
  color?: string;
  /**
   * Size of blast radius icon. Defaults to `24px`.
   */
  iconSize?: number;
  /**
   * Size of label font. Defaults to `iconSize / 2`.
   */
  fontSize?: number;
};

export function BlastRadius({
  radius = "none",
  label = undefined,
  tooltip = undefined,
  color = "primary.main",
  iconSize = 24,
  fontSize = undefined,
}: BlastRadiusProps) {
  const labelId = useId();

  return (
    <Tooltip
      title={tooltip}
      componentsProps={{
        tooltip: {
          sx: {
            maxWidth: "none",
            p: 1,
          },
        },
      }}
      placement="top"
      arrow
    >
      <Box
        className="blast-radius"
        sx={{
          "&, .blast-radius-meter, .blast-radius-label": {
            display: "inline-flex",
            alignItems: "center",
            verticalAlign: "middle",
            gap: 1,
          },
        }}
      >
        <Box className="blast-radius-meter">
          <BlastMeter
            value={blastValueMap[radius as keyof typeof blastValueMap]}
            labelledBy={label ? labelId : undefined}
            size={iconSize}
            color={color}
          />
        </Box>
        {label && (
          <Box className="blast-radius-label" id={labelId}>
            <Typography
              fontSize={fontSize !== undefined ? fontSize : iconSize / 2}
            >
              {label}
            </Typography>
          </Box>
        )}
      </Box>
    </Tooltip>
  );
}
