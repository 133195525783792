import { HubCard } from "~/components/hub-card";
import { Space } from "~/lib/types";
import { NavDrawerFleetIcon } from "~/components/icons";
import { Sunburst } from "~/components/sunburst/sunburst";
import { useAssetUrlStats } from "~/hooks/useAssetUrlStats";

type Props = {
  space: Space;
};

export function InventoryOverviewCardSunburst({ space }: Props) {
  const assetUrlStats = useAssetUrlStats({ space });

  return (
    <HubCard
      {...{
        loading: assetUrlStats.queryResult.loading,
        title: "Inventory Overview",
        icon: <NavDrawerFleetIcon />,
        sx: { height: "100%" },
      }}
    >
      <Sunburst assetUrlStats={assetUrlStats} />
    </HubCard>
  );
}
