import { useEffect, useRef, useState } from "react";
import { ButtonProps } from "@mui/material";
import hljs from "highlight.js/lib/core";
import bash from "highlight.js/lib/languages/bash";
import ini from "highlight.js/lib/languages/ini";
import javascript from "highlight.js/lib/languages/javascript";
import plaintext from "highlight.js/lib/languages/plaintext";
import shell from "highlight.js/lib/languages/shell";
import yaml from "highlight.js/lib/languages/yaml";
import diff from "highlight.js/lib/languages/diff";

hljs.registerLanguage("bash", bash);
hljs.registerLanguage("ini", ini);
hljs.registerLanguage("javascript", javascript);
hljs.registerLanguage("plaintext", plaintext);
hljs.registerLanguage("shell", shell);
hljs.registerLanguage("yaml", yaml);
hljs.registerLanguage("diff", diff);

hljs.configure({
  languages: [
    "bash",
    "ini",
    "javascript",
    "plaintext",
    "shell",
    "yaml",
    "diff",
  ],
  ignoreUnescapedHTML: true,
});

type UseCodeProps = {
  className: string | undefined;
  languages?: string[];
};

export function useCode({ className, languages = [] }: UseCodeProps) {
  const elRef = useRef<HTMLPreElement>(null);
  const [justCopied, setJustCopied] = useState<boolean>(false);

  useEffect(() => {
    if (elRef.current) {
      highlightCode();
    }
  });

  const highlightCode = () => {
    if (!elRef.current) {
      console.error("cannot highlight code, no element given");
      return;
    }

    const nodes = elRef.current.querySelectorAll<HTMLElement>(":scope > code");

    if (languages.length === 0 && className) {
      languages.push(className);
    }

    nodes.forEach((node) => {
      hljs.highlightElement(node);
    });
  };

  const handleCopyToClipboard: ButtonProps["onClick"] = async (e) => {
    e.stopPropagation();

    if (!elRef.current) {
      throw new Error(
        "Cannot copy code to clipboard because element does not exist in the tree.",
      );
    }

    const selection = window.getSelection();
    if (!selection) {
      throw new Error("Cannot get selection for browser");
    }

    try {
      selection.selectAllChildren(elRef.current);
      await navigator.clipboard.writeText(selection.toString());
      selection.removeAllRanges();
      setJustCopied(true);
      setTimeout(() => setJustCopied(false), 3000);
    } catch (e) {
      throw new Error("Failed to copy code to clipboard");
    }
  };

  return { justCopied, elRef, handleCopyToClipboard };
}
