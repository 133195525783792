import { Obj } from "../types";

type FindDifferenceProps = {
  defaultValues: Obj;
  newValues: Obj;
};

/**
 * Finds difference b/w two set of values on first-level nesting.
 */
const findDifference = ({
  defaultValues,
  newValues,
}: FindDifferenceProps): Obj => {
  const difference = Object.keys(defaultValues).reduce((acc, filterName) => {
    const initialValue = defaultValues[filterName];
    const newValue = newValues[filterName];

    if (JSON.stringify(initialValue) !== JSON.stringify(newValue)) {
      acc[filterName] = newValue;
    }

    return acc;
  }, {} as Obj);

  return difference;
};

export default findDifference;
