import { useSearchParams } from "react-router-dom";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { Vulnerabilities } from "./Vulnerabilities";

type Props = {
  isCicd?: boolean;
};

export function VulnerabilitiesTab({ isCicd = false }: Props) {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { space, asset, isVulnerabilityScanningEnabled } = useAssetOutlet();
  const assetMrn = isCicd ? (searchParams.get("jobId") as string) : asset?.mrn;

  if (!space || !asset || !assetMrn) return <></>;
  return (
    <Vulnerabilities
      assetMrn={assetMrn}
      space={space}
      isVulnerabilityScanningEnabled={isVulnerabilityScanningEnabled}
    />
  );
}
