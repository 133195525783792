import { forwardRef, useEffect, useImperativeHandle } from "react";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  FieldArrayWithId,
  useFieldArray,
  useForm,
  UseFormReturn,
} from "react-hook-form";
import { KeyValue } from "~/lib/types";
import { CheckOutlinedIcon, DeleteIcon, EditIcon } from "~/components/icons";

enum KVFieldArray {
  NAME = "keyValues",
  ID = "id",
}

type KVField = FieldArrayWithId<
  KVFormValues,
  KVFieldArray.NAME,
  KVFieldArray.ID
>;

type KVFormValues = {
  [KVFieldArray.NAME]: {
    key: string;
    value: string | null;
    isEditing: boolean;
  }[];
};

type KeyFieldName = `${KVFieldArray.NAME}.${number}.key`;
type ValueFieldName = `${KVFieldArray.NAME}.${number}.value`;

type KeyValueListProps = {
  keyValues: KeyValue[];
  readOnly?: boolean;
  onSubmit?: (keyValues: KeyValue[]) => void;
};

export type KeyValueListRef = {
  addNew: () => void;
};

export const KeyValueList = forwardRef<KeyValueListRef, KeyValueListProps>(
  ({ keyValues, readOnly = true, onSubmit }, ref) => {
    const defaultValues = {
      [KVFieldArray.NAME]: keyValues.map(({ key, value }) => ({
        key,
        value,
        isEditing: false,
      })),
    };

    const form = useForm<KVFormValues>({
      defaultValues,
      mode: "all",
    });

    const { control, getValues, watch, trigger } = form;

    const { fields, update, append, remove } = useFieldArray({
      control,
      name: KVFieldArray.NAME,
    });

    const watchedFields = watch(KVFieldArray.NAME);
    const controlledFields = fields.map((field, index) => {
      return {
        ...field,
        ...watchedFields[index],
      };
    });

    const onAddNew = () => {
      append({ key: "", value: "", isEditing: true });
    };

    const onEditToggle = (field: KVField, index: number) => {
      update(index, { ...field, isEditing: true });
    };

    const onSave = (field: KVField, index: number) => {
      update(index, { ...field, isEditing: false });
      onSubmit?.(getSubmitValues());
    };

    const onRemove = (field: KVField, index: number) => {
      remove(index);
      onSubmit?.(getSubmitValues());
    };

    const getSubmitValues = () => {
      const values = getValues();
      return values[KVFieldArray.NAME].map(({ key, value }) => ({
        key,
        value,
      }));
    };

    useImperativeHandle(ref, () => ({
      addNew: onAddNew,
    }));

    useEffect(() => {
      // fieldArray methods (append, prepend, etc) don't trigger validation?
      trigger();
    }, [controlledFields.length, trigger]);

    // If there are no annotations or one is not in the process of being created,
    // show the "No Annotations" view.
    if (controlledFields.length === 0) {
      return <NoAnnotationsView />;
    }

    return (
      <Box component="form" noValidate sx={{ width: "100%" }}>
        <TableContainer>
          <Table
            sx={{
              tableLayout: "fixed",
              "& tr:hover": { background: "inherit" },
            }}
          >
            <TableBody>
              {controlledFields.map((field, index) => (
                <KVFormRow
                  form={form}
                  key={field.id}
                  field={field}
                  index={index}
                  readOnly={readOnly}
                  onEditToggle={onEditToggle}
                  onSave={onSave}
                  onRemove={onRemove}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  },
);

type KVFormRowProps = {
  form: UseFormReturn<KVFormValues>;
  field: KVField;
  index: number;
  readOnly: boolean;
  onEditToggle: (field: KVField, index: number) => void;
  onSave: (field: KVField, index: number) => void;
  onRemove: (field: KVField, index: number) => void;
};

function KVFormRow({
  form,
  field,
  index,
  readOnly,
  onSave,
  onRemove,
  onEditToggle,
}: KVFormRowProps) {
  const { register, getFieldState, getValues, formState } = form;

  const keyFieldName: KeyFieldName = `${KVFieldArray.NAME}.${index}.key`;
  const valueFieldName: ValueFieldName = `${KVFieldArray.NAME}.${index}.value`;

  const keyFieldState = getFieldState(keyFieldName, formState);
  const keyFieldIsTouched = keyFieldState.isTouched;
  const keyFieldIsErrored = keyFieldIsTouched && !!keyFieldState.error;
  const keyFieldHelperText = keyFieldIsErrored
    ? keyFieldState.error?.message
    : undefined;

  const validateUniqueKey = (keyInputVal: string) => {
    const values = getValues();
    const valuesWithKey = values[KVFieldArray.NAME].filter(
      ({ key }) => key === keyInputVal,
    );
    return valuesWithKey.length === 1 || "Key must be unique.";
  };

  const readRow = (field: KVField, index: number) => (
    <TableRow sx={{ "th,td": { borderBottom: "none", py: 0 } }}>
      <TableCell
        variant="head"
        component="th"
        sx={{
          pl: 0,
          width: "50%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {field.key}
        <input type="hidden" {...register(keyFieldName)} />
      </TableCell>
      <TableCell
        sx={{
          pl: 0,
          width: "30%",
          wordBreak: "break-word",
        }}
      >
        {field.value}
        <input type="hidden" {...register(valueFieldName)} />
      </TableCell>
      {!readOnly && (
        <TableCell align="right" sx={{ p: 0 }}>
          <IconButton size="small" onClick={() => onEditToggle(field, index)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      )}
    </TableRow>
  );

  const editRow = (field: KVField, index: number) => (
    <TableRow
      sx={[
        {
          td: {
            pt: 1.5,
            pb: 0,
            pl: 0,
            verticalAlign: "top",
            borderBottom: "none",
          },
        },
      ]}
    >
      <TableCell variant="head" sx={{ width: "50%" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Key"
          required
          error={keyFieldIsErrored}
          helperText={keyFieldHelperText}
          {...register(keyFieldName, {
            required: "Key can't be empty.",
            validate: {
              uniqueKey: validateUniqueKey,
            },
          })}
        />
      </TableCell>
      <TableCell sx={{ width: "35%" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          label="Value"
          {...register(valueFieldName)}
        />
      </TableCell>
      <TableCell align="right" sx={{ pr: 0, width: "15%" }}>
        <IconButton
          size="small"
          color="error"
          onClick={() => onRemove(field, index)}
        >
          <DeleteIcon />
        </IconButton>
        <IconButton
          size="small"
          disabled={keyFieldIsErrored}
          onClick={() => onSave(field, index)}
        >
          <CheckOutlinedIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );

  return !readOnly && field.isEditing
    ? editRow(field, index)
    : readRow(field, index);
}

const NoAnnotationsView = () => {
  return (
    <Box>
      <Typography variant="body2" color="text.secondary">
        You have no annotations on this asset. To add your own tag or note about
        this asset, select the + icon.
      </Typography>
    </Box>
  );
};
