import React from "react";
import { Space } from "~/lib/types";
import { TestIamActionsQuery } from "~/operations";
import { CaseSettings } from "~/components/cases/components/CaseSettings";

type CasesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const Cases = ({ space, availablePermissions }: CasesProps) => {
  return (
    <CaseSettings space={space} availablePermissions={availablePermissions} />
  );
};
