import React from "react";
import { Box, Paper, Typography, useTheme } from "@mui/material";
import { Flex, MoonIcon } from "~/components/ui-library";
import { BlockIcon, VisibilityOffIcon } from "~/components/icons";

export enum DistributionState {
  Disabled = "DISABLED",
  OutOfScope = "OUT_OF_SCOPE",
  Snoozed = "SNOOZED",
}

type ExceptionCountIndicatorProps = {
  state: DistributionState;
  count: number;
};

export const ExceptionCountIndicator = ({
  state,
  count,
}: ExceptionCountIndicatorProps) => {
  const theme = useTheme();

  const getDistributionIcon = () => {
    const fontSize = 20;
    const color = theme.palette.text.disabled;
    switch (state) {
      case DistributionState.OutOfScope:
        return <VisibilityOffIcon sx={{ fontSize, color }} />;
      case DistributionState.Snoozed:
        return <MoonIcon sx={{ fontSize, color }} />;
      case DistributionState.Disabled:
        return <BlockIcon sx={{ fontSize, color }} />;
      default:
        return <Box />;
    }
  };

  const distributionIcon = getDistributionIcon();

  return (
    <Flex alignItems="center" gap={1}>
      <Paper
        sx={{
          borderRadius: "50%",
          display: "flex",
          p: "6px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {distributionIcon}
      </Paper>
      <Typography fontWeight={500}>{count}</Typography>
    </Flex>
  );
};
