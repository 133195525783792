import { createSvgIcon } from "@mui/material";

export const NpmIconBW = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 576 512"
    fill="currentColor"
  >
    <path d="M288 288h-32v-64h32v64zm288-128v192H288v32H160v-32H0V160h576zm-416 32H32v128h64v-96h32v96h32V192zm160 0H192v160h64v-32h64V192zm224 0H352v128h64v-96h32v96h32v-96h32v96h32V192z" />
  </svg>,
  "Npm",
);

export const NpmIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 7.5V15H6.75V16.5H12V15H24V7.5H0Z" fill="#CB3837" />
    <path
      d="M4.09654 9H1.5V13.5H4.09654V10.2083H5.41758V13.5H6.73862V9H4.09654ZM8.05965 9V15H10.7017V13.5H13.2983V9H8.05965ZM11.9772 12.2917H10.7017V10.2083H11.9772V12.2917ZM17.2159 9H14.6193V13.5H17.2159V10.2083H18.5368V13.5H19.8579V10.2083H21.1789V13.5H22.5V9H17.2159Z"
      fill="white"
    />
  </svg>,
  "Npm",
);
