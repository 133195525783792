import {
  CSSProperties,
  ChangeEvent,
  Component,
  MouseEventHandler,
  PropsWithChildren,
  ReactElement,
  ReactNode,
} from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
  ListItemSecondaryAction,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Divider,
  List,
  ListSubheader,
  Tabs,
  Tab,
  Checkbox,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  SelectChangeEvent,
  InputLabel,
  TextFieldProps,
} from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { LoadingButton } from "./loading-button";

export const ConfigurationTabs = styled(Tabs)`
  border-radius: ${(p) => p.theme.shape.borderRadius}px;

  .MuiTabs-indicator {
    height: 100%;
    width: 100%;
    background-color: ${(props) => props.theme.palette.action.focus};
  }
`;

export const ConfigurationTab = styled(Tab)`
  font-size: ${(props) => props.theme.typography.body1};
  text-transform: none;
  align-items: start;
`;

export const ConfigurationPaper = styled(Paper)`
  margin-bottom: ${(p) => p.theme.spacing(3)};

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  .MuiList-root {
    padding: 0;
  }

  .MuiAccordion-root {
    margin: 0;
    box-shadow: none;
    border-top: 1px solid ${(props) => props.theme.palette.divider};

    &::before {
      display: none;
    }
  }

  .MuiAccordionSummary-root {
    min-height: auto;
  }

  .MuiAccordionSummary-content {
    margin: ${(props) => props.theme.spacing(2, 0)};
  }

  .MuiAccordionDetails-root {
    display: block;
    padding: 0;
  }

  .MuiListItem-container {
    display: flex;
  }

  .MuiListItemSecondaryAction-root {
    top: auto;
    right: auto;
    position: relative;
    transform: none;
    padding: ${(props) => props.theme.spacing(2, 2, 2, 0)};

    .MuiButtonBase-root {
      white-space: nowrap;
    }
  }
`;

export const ConfigurationActions = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${(props) => props.theme.spacing(2)};
  border-top: 1px solid ${(props) => props.theme.palette.divider};

  .MuiButton-root:last-child {
    margin-right: 0;
  }

  > *:last-child {
    margin-left: auto;
  }
`;

export const DangerConfigurationPaper = styled(ConfigurationPaper)`
  border: 1px solid ${(props) => props.theme.palette.critical.main};
`;

export const ConfigurationListSubheader = styled(ListSubheader)<{
  component?: string;
}>`
  && {
    background-color: ${(props) => props.theme.palette.background.light};
    border-radius: 6px 6px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;

    .subheader-actions,
    .subheader-actions > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .subheader-actions button {
      margin-right: 0;
    }
  }
`;

export type ConfigurationListProps = {
  subheader?: ReactNode;
  title?: string;
  children: ReactNode;
  expandable?: boolean;
  sortButton?: ReactNode;
  // if expandable is set, the content is hidden initially
  initExpandableHide?: boolean;
  style?: CSSProperties;
};

export type ConfigurationListState = {
  isExpanded: boolean;
};

export class ConfigurationList extends Component<
  ConfigurationListProps,
  ConfigurationListState
> {
  state: ConfigurationListState = {
    isExpanded: true,
  };

  componentDidMount() {
    const { expandable, initExpandableHide } = this.props;
    if (expandable && initExpandableHide) {
      this.setState({ isExpanded: false });
    }
  }

  render() {
    const { title, children, expandable, sortButton, style } = this.props;
    const { isExpanded } = this.state;
    let { subheader } = this.props;

    if (title) {
      let handleToggle = () => {
        // if the user added the expandable flag
        if (expandable) {
          this.setState({
            isExpanded: !isExpanded,
          });
        }
      };

      let toggleButton = null;
      if (expandable) {
        toggleButton = isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;
      }

      subheader = (
        <ConfigurationListSubheader component="div" onClick={handleToggle}>
          <Typography component="span" variant="h6">
            {title}
          </Typography>
          <div className="subheader-actions">
            <div onClick={(e) => e.stopPropagation()}>{sortButton}</div>
            <div>{toggleButton}</div>
          </div>
        </ConfigurationListSubheader>
      );
    }

    return (
      <List subheader={subheader} style={style}>
        {isExpanded && children}
      </List>
    );
  }
}

export const ConfigurationContentWrapper = styled(Typography)`
  padding-left: ${(props) => props.theme.spacing(2)};
  padding-right: ${(props) => props.theme.spacing(3)};
`;

export const ConfigurationHeadline = styled(Typography)`
  && {
    margin: 0 0 1em 0;
  }
`;

export type ConfigurationSectionHeaderProps = {
  title: string;
};

export const ConfigurationSectionHeader = ({
  title,
}: ConfigurationSectionHeaderProps) => {
  return (
    <ListItem>
      <ConfigurationHeadline variant="h6">{title}</ConfigurationHeadline>
      <Divider />
    </ListItem>
  );
};

export type ConfigurationItemPProps = {};

export const ConfigurationItemP = ({
  children,
}: PropsWithChildren<ConfigurationItemPProps>) => {
  return (
    <ListItem style={{ display: "inline-block", padding: "16px" }}>
      {children}
    </ListItem>
  );
};

export type ConfigurationItemButtonProps = {
  primary?: ReactNode;
  secondary?: ReactNode;
  icon?: ReactNode;
  actionIcon?: ReactNode;
  endIcon?: ReactNode;
  action?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  button?: ReactNode;
  dataName?: string;
  loading?: boolean;
  href?: string;
  target?: string;
};

export const ConfigurationItemButton = ({
  primary,
  secondary,
  icon,
  action,
  actionIcon,
  endIcon,
  onClick,
  disabled,
  button,
  dataName,
  loading,
  href,
  target,
}: ConfigurationItemButtonProps) => {
  let primaryVal = primaryText(primary);
  let secondaryVal = secondaryText(secondary);

  return (
    <ListItem>
      {icon != null && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        primary={primaryVal}
        secondary={secondaryVal}
      ></ListItemText>
      <ListItemSecondaryAction>
        {action && (
          <LoadingButton
            variant="contained"
            color="primary"
            startIcon={actionIcon}
            endIcon={endIcon}
            onClick={onClick}
            disabled={disabled}
            data-name={dataName}
            loading={loading || false}
            buttonText={action}
            href={href}
            target={target}
          />
        )}
        {button}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export type ConfigurationItemConfirmButtonProps = {
  primary?: ReactNode;
  secondary?: ReactNode;
  confirm: ReactElement;
  icon?: ReactNode;
  actionIcon?: ReactNode;
  action: string;
  onClick?: MouseEventHandler;
};

export type ConfigurationItemConfirmButtonState = {
  disabled: boolean;
};

export class ConfigurationItemConfirmButton extends Component<
  ConfigurationItemConfirmButtonProps,
  ConfigurationItemConfirmButtonState
> {
  state: ConfigurationItemConfirmButtonState = {
    disabled: true,
  };

  render() {
    const { primary, secondary, confirm, icon, action, actionIcon, onClick } =
      this.props;

    let primaryVal = primaryText(primary);
    let secondaryVal = secondaryText(secondary);
    const disabled = this.state.disabled;

    const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
      this.setState({ disabled: !this.state.disabled });
    };

    return (
      <ListItem>
        {icon != null && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText
          primary={primaryVal}
          secondary={
            <>
              {secondaryVal}
              <FormControlLabel
                control={
                  <Checkbox checked={!disabled} onChange={handleCheckbox} />
                }
                label={confirm}
              />
            </>
          }
        ></ListItemText>
        <ListItemSecondaryAction>
          <Button
            variant="contained"
            startIcon={actionIcon}
            onClick={onClick}
            disabled={disabled}
          >
            {action}
          </Button>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }
}

export type ConfigurationItemBasicProps = {
  primary?: ReactNode;
  secondary?: ReactNode;
  icon?: ReactNode;
  action?: ReactNode;
};

export const ConfigurationItemBasic = ({
  primary,
  secondary,
  icon,
  action,
}: ConfigurationItemBasicProps) => {
  return (
    <ListItem>
      {icon}
      <ListItemText primary={primary} secondary={secondary}></ListItemText>
      {action != null && (
        <ListItemSecondaryAction>{action}</ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export type ConfigurationItemSwitchProps = {
  name?: string;
  value?: unknown;
  primary?: ReactNode;
  secondary?: ReactNode;
  icon?: ReactNode;
  actionIcon?: ReactNode;
  action: ReactNode;
  checked?: boolean;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
};

export const ConfigurationItemSwitch = ({
  name,
  value,
  primary,
  secondary,
  icon,
  action,
  checked = false,
  disabled = false,
  onChange,
}: ConfigurationItemSwitchProps) => {
  let primaryVal = primaryText(primary);
  let secondaryVal = secondaryText(secondary);

  return (
    <ConfigurationItemBasic
      icon={icon}
      primary={primaryVal}
      secondary={secondaryVal}
      action={
        <FormControlLabel
          control={
            <Switch
              name={name}
              value={value}
              checked={checked}
              disabled={disabled}
              onChange={onChange}
              color="primary"
              sx={{ cursor: disabled ? "not-allowed" : "" }}
            />
          }
          labelPlacement="start"
          label={action}
        />
      }
    ></ConfigurationItemBasic>
  );
};

export type ConfigurationItemBaseProps = {
  primary?: ReactNode;
  secondary?: ReactNode;
  children?: ReactNode;
  multiline?: boolean;
};

export const ConfigurationItemBase = ({
  primary,
  secondary,
  children,
}: ConfigurationItemBaseProps) => {
  let primaryVal = primaryText(primary);
  let secondaryVal = secondaryText(secondary);
  return (
    <ListItem
      sx={{
        alignItems: "flex-start",
        width: "75%",
      }}
    >
      <ListItemText
        primary={primaryVal}
        secondary={secondaryVal}
      ></ListItemText>
      <ListItemSecondaryAction sx={{ flex: 1 }}>
        {children}
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export type ConfigurationItemTextProps = {
  primary?: ReactNode;
  secondary?: ReactNode;
  actionIcon?: ReactNode;
  value: TextFieldProps["value"];
  label?: TextFieldProps["label"];
  name?: TextFieldProps["name"];
  disabled?: TextFieldProps["disabled"];
  type?: TextFieldProps["type"];
  required?: TextFieldProps["required"];
  onChange?: TextFieldProps["onChange"];
  onBlur?: TextFieldProps["onBlur"];
  error?: TextFieldProps["error"];
  helperText?: TextFieldProps["helperText"];
  fullWidth?: TextFieldProps["fullWidth"];
  multiline?: TextFieldProps["multiline"];
  rows?: TextFieldProps["rows"];
};

export const ConfigurationItemText = ({
  primary,
  secondary,
  value,
  label,
  name,
  disabled,
  type,
  required,
  onChange,
  onBlur,
  error,
  helperText,
  fullWidth = true,
  multiline,
  rows,
}: ConfigurationItemTextProps) => {
  let primaryVal = primaryText(primary);
  let secondaryVal = secondaryText(secondary);

  return (
    <ConfigurationItemBase
      primary={primaryVal}
      secondary={secondaryVal}
      multiline={multiline}
    >
      <TextField
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        required={required}
        type={type}
        disabled={disabled}
        label={label}
        variant="outlined"
        value={value}
        error={error}
        helperText={helperText}
        fullWidth={fullWidth}
        multiline={multiline}
        rows={rows}
      />
    </ConfigurationItemBase>
  );
};

export type ConfigurationItemSelectProps = {
  primary?: ReactNode;
  secondary?: ReactNode;
  name: string;
  multiline?: boolean;
  inputValues: string[];
  targetValue: string;
  onChange: (event: SelectChangeEvent) => void;
  label: string;
  helperText?: string;
  required: boolean;
  hasDefault: boolean;
};

export const ConfigurationItemSelect = ({
  primary,
  secondary,
  multiline,
  inputValues,
  targetValue,
  onChange,
  label,
  name,
  helperText,
  required,
  hasDefault,
}: ConfigurationItemSelectProps) => {
  return (
    <ConfigurationItemBase
      primary={primary}
      secondary={secondary}
      multiline={multiline}
    >
      <FormControl fullWidth required={required} sx={{ minWidth: 120 }}>
        <InputLabel>{label}</InputLabel>
        <Select
          label={label}
          value={targetValue}
          onChange={onChange}
          id={`${name}-select`}
        >
          {!hasDefault && (
            <MenuItem value="" key={`${label}-select-none`}>
              <em>None</em>
            </MenuItem>
          )}
          {inputValues.map((inputValue: string, i: number) => {
            return (
              <MenuItem
                key={`${label}-select-${inputValue}`}
                value={inputValue}
              >
                {inputValue}
              </MenuItem>
            );
          })}
        </Select>
        <FormHelperText>{helperText}</FormHelperText>
      </FormControl>
    </ConfigurationItemBase>
  );
};

export type ConfigurationItemLabelProps = {
  primary: ReactNode;
  secondary: ReactNode;
  actionIcon?: ReactNode;
  action?: string;
  label: string;
};

export const ConfigurationItemLabel = ({
  primary,
  secondary,
  action,
  actionIcon,
  label,
}: ConfigurationItemLabelProps) => {
  let primaryVal = primaryText(primary);
  let secondaryVal = secondaryText(secondary);

  return (
    <ConfigurationItemBase primary={primaryVal} secondary={secondaryVal}>
      <Typography variant="body2" component="span" color="textPrimary">
        {label}
      </Typography>
    </ConfigurationItemBase>
  );
};

function isString(x: any): x is string {
  return typeof x === "string";
}

function primaryText(primary: ReactNode): ReactNode {
  let primaryVal = primary;
  if (isString(primary)) {
    primaryVal = (
      <Typography component="span" variant="h6" color="textPrimary">
        {primary}
      </Typography>
    );
  }
  return primaryVal;
}

function secondaryText(secondary: ReactNode): ReactNode {
  let secondaryVal = secondary;
  if (isString(secondary)) {
    secondaryVal = (
      <>
        <Typography component="span" variant="body2" color="textPrimary">
          {secondary}
        </Typography>
      </>
    );
  }
  return secondaryVal;
}
