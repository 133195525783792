export const pluralize = (word: string, count: number): string => {
  if (count === 1) return word;

  const IGNORE_WORDS = ["security", "projects"];
  if (IGNORE_WORDS.includes(word)) return word;

  const VOWELS = ["a", "e", "i", "o", "u"];
  const isVowel = (char: string) => VOWELS.includes(char);

  const SIBILANTS = ["ch", "j", "s", "sh", "x", "z", "zh"];
  const endsWithSibilant = (word: string) =>
    SIBILANTS.some((s) => word.endsWith(s));

  const endsWithConsonantY = (word: string) =>
    word.endsWith("y") && !isVowel(word.slice(-2, -1));

  if (endsWithConsonantY(word)) {
    return `${word.slice(0, -1)}ies`;
  }

  if (endsWithSibilant(word)) {
    return `${word}es`;
  }

  return `${word}s`;
};
