import { ReactNode } from "react";
import { alpha, Button, SxProps } from "@mui/material";
import { motion } from "framer-motion";
import { fadeInOut } from "~/lib/animations";

type TabVariantProps = {
  isActive?: boolean;
  onClick?: () => void;
  label?: ReactNode;
  icon?: ReactNode;
  sx?: SxProps;
};

export const TabVariant = ({
  isActive,
  onClick,
  icon,
  label,
  sx = {},
}: TabVariantProps) => {
  const border = isActive ? "1px solid #9147FF" : "1px solid transparent";

  return (
    <Button
      variant="contained"
      size="small"
      onClick={onClick}
      component={motion.button}
      {...fadeInOut}
      sx={{
        px: 3,
        textTransform: "none",
        border,
        background: (theme) =>
          isActive
            ? alpha(theme.palette.primary.main, 0.4)
            : alpha(theme.palette.primary.main, 0.2),
        "&:hover": {
          backgroundColor: (theme) =>
            isActive
              ? alpha(theme.palette.primary.main, 0.24)
              : alpha(theme.palette.primary.main, 0.4),
        },
        ...sx,
      }}
      {...(icon
        ? {
            startIcon: icon,
          }
        : {})}
    >
      {label}
    </Button>
  );
};
