import {
  Autocomplete,
  AutocompleteProps,
  Box,
  createFilterOptions,
  IconButton,
  TextField,
  useTheme,
} from "@mui/material";
import { CancelIcon } from "~/components/icons";
import { getSearchPlaceholder } from "~/lib/search-placeholder";

//TokenSearch component is used in both the Service Account and API Token settings,
// TODO: replace token search with Search component and useSearch Hook that has already been built

type Props = {
  searchFilters: string[];
  handleFilterQuery: AutocompleteProps<string, true, false, true>["onChange"];
  onDelete: (value: string) => void;
  placeholder?: string | null;
};

export function TokenSearch({
  searchFilters = [],
  handleFilterQuery,
  onDelete,
  placeholder,
}: Props) {
  const filterOptions = createFilterOptions({
    trim: true,
  });
  const theme = useTheme();

  return (
    <Box component="form" noValidate sx={{ width: 1 }}>
      <Autocomplete
        id="api-tokens-search"
        freeSolo
        multiple
        options={[]}
        value={searchFilters}
        onChange={handleFilterQuery}
        renderTags={() => null}
        {...filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={getSearchPlaceholder(placeholder)}
            sx={{
              background: theme.palette.code.background,
              borderRadius: 1,
              color: "text.primary",
            }}
          />
        )}
      />
      <Box>
        {searchFilters.map((value) => {
          return <KeyValueChip key={value} {...{ value, onDelete }} />;
        })}
      </Box>
    </Box>
  );
}

// copied from cicd-search.tsx
type KeyValueChipProps = {
  value: string;
  onDelete: (value: string) => void;
};

function KeyValueChip({ value, onDelete }: KeyValueChipProps) {
  const label = `search: ${value}`;
  const chipSx = {
    display: "inline-flex",
    alignItems: "center",
    border: "1px solid",
    borderColor: "background.light",
    backgroundColor: "background.light",
    marginTop: 1,
    marginRight: 1,
    paddingRight: 1,
    borderRadius: 1,
    typography: "body2",
    cursor: "pointer",
    fontSize: "13px",
    overflowWrap: "anywhere",
  };
  const keySx = {
    backgroundColor: "background.paper",
    padding: 1,
    display: "inline-flex",
    alignSelf: "stretch",
    alignItems: "center",
  };
  const valSx = {
    backgroundColor: "background.light",
    padding: 1,
    display: "inline-flex",
    alignSelf: "stretch",
    alignItems: "center",
  };
  return (
    <Box sx={chipSx} aria-label={label}>
      <Box sx={keySx}>search:</Box>
      <Box sx={valSx}>{value}</Box>
      <IconButton onClick={() => onDelete(value)} sx={{ p: 0 }}>
        <CancelIcon sx={{ color: "text.primary", opacity: "0.26" }} />
      </IconButton>
    </Box>
  );
}
