import { useRef } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { KeyValue } from "~/lib/types";
import { AddIcon } from "./icons";
import { KeyValueList, KeyValueListRef } from "./key-value-list";
import { Asset } from "~/pages/inventory/asset/types";

type AssetAnnotationsProps = {
  asset: Asset;
  readOnly: boolean;
  onSubmit: (keyValues: KeyValue[]) => void;
};

export function AssetAnnotations({
  asset,
  readOnly,
  onSubmit,
}: AssetAnnotationsProps) {
  const keyValueListRef = useRef<KeyValueListRef>(null);

  const keyValueList = (
    <KeyValueList
      ref={keyValueListRef}
      keyValues={asset.annotations}
      readOnly={readOnly}
      onSubmit={onSubmit}
    />
  );

  const onAddClick = () => {
    keyValueListRef.current?.addNew();
  };
  const addButton = !readOnly ? (
    <IconButton size="small" onClick={onAddClick}>
      <AddIcon />
    </IconButton>
  ) : undefined;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 1.5,
          pb: 0.25,
          mb: 2,
          borderBottom: "1px solid",
          borderBottomColor: "background.lightest",
          textTransform: "uppercase",
        }}
      >
        <Typography variant="subtitle2">Mondoo Annotations</Typography>
        {addButton}
      </Box>
      <Box sx={{ pt: 1 }}>{keyValueList}</Box>
    </Box>
  );
}
