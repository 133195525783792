import { Fragment, useState } from "react";
import { useSnackbar } from "notistack";
import { Box, TextField, TextFieldProps, Typography } from "@mui/material";
import {
  ConfigurationPaper,
  ConfigurationItemSwitch,
  ConfigurationItemP,
} from "~/components/configuration-items";
import { LoadingButton } from "~/components/loading-button";
import { Space } from "~/lib/types";
import { Loading, LoadingFailedPage } from "~/components/loading";
import {
  GetIntegrationsSummaryDocument,
  LoadIntegrationsDocument,
  LoadIntegrationsQuery,
  TestIamActionsQuery,
  useLoadIntegrationsQuery,
  useSetIntegrationMutation,
} from "~/operations";
import { IamActions } from "~/lib/iam";
import {
  IntegrationAddHeader,
  OtherIntegrations,
} from "./integrations/headers/integration-add-header";

type Integration = NonNullable<LoadIntegrationsQuery["integrations"][0]>;
type IntegrationOpts = NonNullable<Integration["options"]>;

type ActionText = "Enabled" | "Disabled";

export enum ChatIntegrationName {
  Msteams = "msteams",
  Slack = "slack",
  Telegram = "telegram",
  Httppost = "httppost",
}

export function isChatIntegrationName(
  value: any,
): value is ChatIntegrationName {
  return Object.values(ChatIntegrationName).includes(value);
}

export type SpaceIntegrationSectionProps = {
  space: Space;
  view: ChatIntegrationName;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

type SpaceIntegrationSectionState = {
  loaded: boolean;
  msteams: Integration;
  slack: Integration;
  telegram: Integration;
  httppost: Integration;
  edit: IntegrationOpts;
};

const defaultEdit: IntegrationOpts = {
  __typename: "IntegrationOptions",
  chatid: "",
  token: "",
  url: "",
};

const spaceIntegrationInitialState: SpaceIntegrationSectionState = {
  loaded: false,
  msteams: {
    __typename: "Integration",
    mrn: "",
    name: "msteams",
    enabled: false,
  },
  slack: {
    __typename: "Integration",
    mrn: "",
    name: "slack",
    enabled: false,
  },
  telegram: {
    __typename: "Integration",
    mrn: "",
    name: "telegram",
    enabled: false,
  },
  httppost: {
    __typename: "Integration",
    mrn: "",
    name: "httppost",
    enabled: false,
  },
  edit: defaultEdit,
};

export function SpaceIntegrationSection({
  space,
  availablePermissions,
  view = ChatIntegrationName.Msteams,
}: SpaceIntegrationSectionProps) {
  const [state, setState] = useState<SpaceIntegrationSectionState>(
    spaceIntegrationInitialState,
  );
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const { loading, data, error } = useLoadIntegrationsQuery({
    variables: { mrn: space.mrn },
  });

  const [setIntegrationMutation] = useSetIntegrationMutation({
    refetchQueries: [LoadIntegrationsDocument, GetIntegrationsSummaryDocument],
  });

  const hasChatOpsPermission = availablePermissions?.includes(
    IamActions.CAPTAIN_SETINTEGRATION,
  );

  if (loading) {
    return <Loading />;
  }

  if (error || !data || !data.integrations) {
    return <LoadingFailedPage what="Integrations" />;
  }

  const integrations = data.integrations;

  let msteams = spaceIntegrationInitialState["msteams"];
  let slack = spaceIntegrationInitialState["slack"];
  let telegram = spaceIntegrationInitialState["telegram"];
  let httppost = spaceIntegrationInitialState["httppost"];

  for (const value of integrations) {
    if (value?.name === "msteams") {
      msteams = value;
    } else if (value?.name === "slack") {
      slack = value;
    } else if (value?.name === "telegram") {
      telegram = value;
    } else if (value?.name === "httppost") {
      httppost = value;
    }
  }

  const edit =
    integrations.find((integration) => integration?.name === view)?.options ||
    defaultEdit;

  if (!state.loaded) {
    setState((prevState) => {
      return {
        ...prevState,
        loaded: true,
        msteams: msteams,
        slack: slack,
        telegram: telegram,
        httppost: httppost,
        edit,
      };
    });
  }

  function setIntegration(integration: Integration) {
    const options = integration.options?.chatid
      ? // handle telegram
        { chatid: integration.options.chatid, token: integration.options.token }
      : // handle msteams, slack, httppost
        { url: integration.options?.url };

    const variables = {
      input: {
        mrn: integration.mrn,
        name: integration.name,
        enabled: integration.enabled,
        options,
      },
    };

    return setIntegrationMutation({ variables: variables });
  }

  const toggle = (name: ChatIntegrationName) => (): void => {
    let currentState = state;
    let integration = { ...currentState[name] };
    integration.enabled = !integration.enabled;
    currentState[name] = integration;

    setState((prevState) => {
      return {
        ...prevState,
        currentState,
      };
    });

    // send it to backend
    setIntegration(integration);
  };

  const saveIntegration = async (integrationName: ChatIntegrationName) => {
    setIsSaving(true);
    let integration = { ...state[integrationName] };
    integration.options = state.edit;

    try {
      await setIntegration(integration);
      enqueueSnackbar("Successfully saved Chat Ops Integration", {
        variant: "success",
      });
      setState((prevState) => ({
        ...prevState,
        [integrationName]: integration,
      }));
    } catch (error) {
      enqueueSnackbar("Failed to save Chat Ops Integration", {
        variant: "error",
      });
    }
    setIsSaving(false);
  };

  const changeUrl: TextFieldProps["onChange"] = (event) => {
    let edit = { ...state.edit };
    edit.url = (event.target as HTMLInputElement).value;
    setState((prevState) => {
      return { ...prevState, edit };
    });
  };

  const changeChatid: TextFieldProps["onChange"] = (event) => {
    let edit = { ...state.edit };
    edit.chatid = (event.target as HTMLInputElement).value;
    setState((prevState) => {
      return { ...prevState, edit };
    });
  };

  const changeToken: TextFieldProps["onChange"] = (event) => {
    let edit = { ...state.edit };
    edit.token = (event.target as HTMLInputElement).value;
    setState((prevState) => {
      return { ...prevState, edit };
    });
  };

  const actionText = (isEnabled: boolean): ActionText =>
    isEnabled ? "Enabled" : "Disabled";

  return (
    <Fragment>
      <IntegrationAddHeader type={`chatops/${view}` as OtherIntegrations} />
      <ConfigurationPaper>
        {view === ChatIntegrationName.Slack && (
          <Box>
            {!state.loaded && (
              <ConfigurationItemP>
                <Loading />
              </ConfigurationItemP>
            )}
            {state.loaded && (
              <>
                <ConfigurationItemSwitch
                  disabled={!hasChatOpsPermission}
                  checked={state.slack.enabled}
                  action={actionText(state.slack.enabled)}
                  onChange={toggle(ChatIntegrationName.Slack)}
                ></ConfigurationItemSwitch>
                <ConfigurationItemP>
                  <Typography variant="h6">URL</Typography>
                  <TextField
                    id="slack-webhook-url"
                    variant="outlined"
                    fullWidth
                    onChange={changeUrl}
                    value={state.edit.url}
                  />
                </ConfigurationItemP>
                <ConfigurationItemP>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() => saveIntegration(ChatIntegrationName.Slack)}
                    loading={isSaving}
                    buttonText="Save"
                    data-name="save-slack-chat-ops"
                    disabled={!hasChatOpsPermission}
                  />
                </ConfigurationItemP>
              </>
            )}
          </Box>
        )}

        {view === ChatIntegrationName.Msteams && (
          <Box>
            {!state.loaded && (
              <ConfigurationItemP>
                <Loading />
              </ConfigurationItemP>
            )}
            {state.loaded && (
              <>
                <ConfigurationItemSwitch
                  disabled={!hasChatOpsPermission}
                  checked={state.msteams.enabled}
                  action={actionText(state.msteams.enabled)}
                  onChange={toggle(ChatIntegrationName.Msteams)}
                ></ConfigurationItemSwitch>
                <ConfigurationItemP>
                  <Typography variant="h6">URL</Typography>
                  <TextField
                    id="msteams-webhook-url"
                    variant="outlined"
                    fullWidth
                    onChange={changeUrl}
                    value={state.edit.url}
                  />
                </ConfigurationItemP>
                <ConfigurationItemP>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    buttonText="Save"
                    data-name="save-msteams-chat-ops"
                    loading={isSaving}
                    onClick={() => saveIntegration(ChatIntegrationName.Msteams)}
                    disabled={!hasChatOpsPermission}
                  />
                </ConfigurationItemP>
              </>
            )}
          </Box>
        )}

        {view === ChatIntegrationName.Telegram && (
          <Box>
            {!state.loaded && (
              <ConfigurationItemP>
                <Loading />
              </ConfigurationItemP>
            )}
            {state.loaded && (
              <>
                <ConfigurationItemSwitch
                  disabled={!hasChatOpsPermission}
                  checked={state.telegram.enabled}
                  action={actionText(state.telegram.enabled)}
                  onChange={toggle(ChatIntegrationName.Telegram)}
                ></ConfigurationItemSwitch>
                <ConfigurationItemP>
                  <form noValidate autoComplete="off">
                    <Typography variant="h6">Chat ID</Typography>
                    <TextField
                      id="telegram-chatid"
                      variant="outlined"
                      fullWidth
                      onChange={changeChatid}
                      value={state.edit.chatid}
                    />
                  </form>
                </ConfigurationItemP>
                <ConfigurationItemP>
                  <form noValidate autoComplete="off">
                    <Typography variant="h6">Secret</Typography>
                    <TextField
                      id="telegram-token"
                      variant="outlined"
                      fullWidth
                      type="password"
                      onChange={changeToken}
                      value={state.edit.token}
                    />
                  </form>
                </ConfigurationItemP>
                <ConfigurationItemP>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      saveIntegration(ChatIntegrationName.Telegram)
                    }
                    loading={isSaving}
                    buttonText="Save"
                    data-name="save-telegram-chat-ops"
                    disabled={!hasChatOpsPermission}
                  />
                </ConfigurationItemP>
              </>
            )}
          </Box>
        )}

        {view === ChatIntegrationName.Httppost && (
          <Box>
            {!state.loaded && (
              <ConfigurationItemP>
                <Loading />
              </ConfigurationItemP>
            )}
            {state.loaded && (
              <>
                <ConfigurationItemSwitch
                  disabled={!hasChatOpsPermission}
                  checked={state.httppost.enabled}
                  action={actionText(state.httppost.enabled)}
                  onChange={toggle(ChatIntegrationName.Httppost)}
                ></ConfigurationItemSwitch>
                <ConfigurationItemP>
                  <form noValidate autoComplete="off">
                    <Typography variant="h6">Webhook URL</Typography>
                    <TextField
                      id="http-webhook-url"
                      variant="outlined"
                      fullWidth
                      onChange={changeUrl}
                      value={state.edit.url}
                    />
                  </form>
                </ConfigurationItemP>
                <ConfigurationItemP>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      saveIntegration(ChatIntegrationName.Httppost)
                    }
                    loading={isSaving}
                    buttonText="Save"
                    data-name="save-webhook-chat-ops"
                    disabled={!hasChatOpsPermission}
                  />
                </ConfigurationItemP>
              </>
            )}
          </Box>
        )}
      </ConfigurationPaper>
    </Fragment>
  );
}
