export const circleCiInstructions = {
  title: "CircleCI",
  tagline: "PIPELINE MONITORING FOR CIRCLECI PROJECTS",
  flags: [
    { id: "ci_cd", title: "CI/CD" },
    { id: "shift_left", title: "Shift Left" },
  ],
  instructions: [
    { id: 1, tagline: "Copy the Mondoo Platform credentials", token: true },
    {
      id: 2,
      tagline: "Securely store the credentials",

      markdownText:
        "In your CircleCI project, generate a new environment variable. Name the environment variable `MONDOO_CONFIG_BASE64`, and set the value to your generated credentials. To learn more, read the <a href='https://circleci.com/docs/set-environment-variable#set-an-environment-variable-in-a-project' target='_blank' rel='noopener'>CircleCI documentation</a>.",
    },
    {
      id: 3,
      tagline: "Create the CircleCI pipeline configuration",
      markdownText:
        "Now that you have Mondoo Platform credentials for running CI security scans, you can add cnspec steps to CircleCI pipelines using your `.circleci/config.yml` file. You can use the example below or customize it to suit your needs. To learn more, read the <a href='https://circleci.com/docs/set-environment-variable#set-an-environment-variable-in-a-project' target='_blank' rel='noopener'>CircleCI documentation</a>.",
      configExamples: [
        {
          id: "docker_config",
          config:
            '```yaml\nversion: 2\n\
  jobs:\n\
    build:\n\
      docker:\n\
        - image: centos:7\n\
      steps:\n\
        - setup_remote_docker\n\
        - checkout\n\
        # use a primary image that already has Docker (recommended)\n\
        # or install it during a build like we do here\n\
        - run:\n\
            name: Install Docker client\n\
            command: |\n\
              set -x\n\
              VER="18.09.3"\n\
              curl -L -o /tmp/docker-$VER.tgz https://download.docker.com/linux/static/stable/x86_64/docker-$VER.tgz\n\
              tar -xz -C /tmp -f /tmp/docker-$VER.tgz\n\
              mv /tmp/docker/* /usr/bin\n\
        - run:\n\
            name: Install cnspec\n\
            command: |\n\
              bash -c "$(curl -sSL https://install.mondoo.com/sh)"\n\
              ./cnspec version\n\
        # - run: docker login -u $DOCKER_USER -p $DOCKER_PASS\n\
        - run: docker build -t yourorg/docker-image:0.1.$CIRCLE_BUILD_NUM .\n\
        # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
        - run: ./cnspec scan docker yourorg/docker-image:0.1.$CIRCLE_BUILD_NUM --score-threshold 90\n\
        # - run: docker push docker yourorg/docker-image:0.1.$CIRCLE_BUILD_NUM```',
        },
        {
          id: "kubernetes_config",
          config:
            '```yaml\nversion: 2\n\
  jobs:\n\
    build:\n\
      docker:\n\
        - image: centos:7\n\
      steps:\n\
        - checkout\n\
        - run:\n\
            name: Install cnspec\n\
            command: |\n\
              bash -c "$(curl -sSL https://install.mondoo.com/sh)"\n\
              ./cnspec version\n\
        - run:\n\
            name: Scan Kubernetes Manifest\n\
            command: |\n\
              # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
              ./cnspec scan k8s MY_MANIFEST_FILE.yml --score-threshold 90```',
        },
        {
          id: "terraform_config",
          config:
            '```yaml\nversion: 2\n\
  jobs:\n\
    build:\n\
      docker:\n\
        - image: centos:7\n\
      steps:\n\
        - checkout\n\
        - run:\n\
            name: Install cnspec\n\
            command: |\n\
              bash -c "$(curl -sSL https://install.mondoo.com/sh)"\n\
              ./cnspec version\n\
        - run:\n\
            name: Scan Terraform\n\
            command: |\n\
              # be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
              ./cnspec scan terraform my_tf_config.tf --score-threshold 90```',
        },
      ],
    },
  ],
};
