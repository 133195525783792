import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  LoadResourceInvitationsDocument,
  useCreateInvitationMutation,
} from "~/operations";
import {
  ROLE_EDITOR_MRN,
  ROLE_OWNER_MRN,
  ROLE_VIEWER_MRN,
} from "./members-mgmt";
import { LoadingButton } from "~/components/loading-button";

export type AddMemberDialogProps = {
  resourceMrn: string;
  onClose?: () => void;
  dialogProps: DialogProps;
};

type AddMemberDialogState = {
  selectedRole: string;
  userEmail: string;
};

export function AddMemberDialog({
  resourceMrn,
  onClose,
  dialogProps,
}: AddMemberDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [createInvitation, { loading }] = useCreateInvitationMutation({
    refetchQueries: [LoadResourceInvitationsDocument],
  });

  const emptyState: AddMemberDialogState = {
    selectedRole: ROLE_VIEWER_MRN,
    userEmail: "",
  };

  const [state, setState] = useState<AddMemberDialogState>(emptyState);

  const mergeState = (params: Partial<AddMemberDialogState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const onAddMember = async () => {
    const inviteeEmail = state.userEmail;
    const roleMrn = state.selectedRole;

    try {
      if (!resourceMrn) throw new Error("resourceMrn is required");
      await createInvitation({
        variables: { input: { resourceMrn, roleMrn, inviteeEmail } },
      });
      enqueueSnackbar("Invite successfully created and sent", {
        variant: "success",
      });
    } catch (error) {
      const errorMsg = String(error).includes("AlreadyExists")
        ? "An invitation for this email already exists"
        : "Failed to create and send invite";
      enqueueSnackbar(errorMsg, {
        variant: "error",
      });
    } finally {
      onClose?.();
      mergeState(emptyState);
    }
  };

  const handleEmailChange: TextFieldProps["onChange"] = (evt) => {
    mergeState({
      userEmail: evt.target.value.trim(),
    });
  };

  const handleSelectRole: SelectProps<string>["onChange"] = (evt) => {
    mergeState({ selectedRole: evt.target.value });
  };

  return (
    <Dialog id="add-member-dialog" onClose={onClose} {...dialogProps}>
      <DialogTitle id="simple-dialog-title">Add Member</DialogTitle>
      <DialogContent>
        <Grid alignItems="center" container spacing={3}>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">Enter user email</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              name="userEmail"
              placeholder="user@example.com"
              onChange={handleEmailChange}
              fullWidth
              autoFocus
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body1">Select user role</Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <Select
              value={state.selectedRole}
              onChange={handleSelectRole}
              inputProps={{
                name: "role",
                id: "member-role",
              }}
              style={{
                width: "100%",
              }}
            >
              <MenuItem value={ROLE_VIEWER_MRN}>Viewer</MenuItem>
              <MenuItem value={ROLE_EDITOR_MRN}>Editor</MenuItem>
              <MenuItem value={ROLE_OWNER_MRN}>Owner</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
          <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              color="primary"
              onClick={onAddMember}
              loading={loading}
              data-name="add-member"
              buttonText="Add"
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
