import { Fragment } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  List,
  ListItem,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { CloseIcon, OpenInNewIcon } from "~/components/icons";
import { GradientButton } from "~/components/guides/components";
import { ScrollToTop } from "~/lib/scroll-to-top";
import {
  SearchPolicyDocument,
  SearchPolicyQuery,
  useAssignPolicyMutation,
  useSearchPolicyQuery,
  useUnassignPolicyMutation,
} from "~/operations";
import { Space } from "~/lib/types";
import { Loading } from "~/components/loading";
import { AnimatePresence, motion } from "framer-motion";
import { MondooIcon } from "~/components/icons/mondoo/mondoo-primary";
import { AzurePipelinesIcon } from "~/components/icons/mondoo/azure-pipelines";
import { policyIcon } from "~/pages/inventory/utils/policyIcon";
import { EmptyState } from "~/components/empty-state/empty-state";

type Props = {
  space: Space;
  filterTypes: string[];
  bonusMessage?: string;
  finalizeAction?: () => void;
};

export function RecommendedPolicies({
  space,
  filterTypes,
  bonusMessage,
  finalizeAction,
}: Props) {
  let navigate = useNavigate();
  const { loading, error, data } = useSearchPolicyQuery({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { input: { scopeMrn: space.mrn, platforms: filterTypes } },
  });

  const assignedPolicies =
    data?.content?.edges.filter((edge) => edge.node?.assigned === true) || [];

  const [assignPolicy, { loading: assignLoading }] = useAssignPolicyMutation({
    refetchQueries: [SearchPolicyDocument],
  });

  const [unassignPolicy, { loading: unassignLoading }] =
    useUnassignPolicyMutation({ refetchQueries: [SearchPolicyDocument] });

  const handleAssignPolicy = (policyMrn: string, assigned: boolean) => {
    if (assigned) {
      //unassign
      unassignPolicy({
        variables: {
          input: { assetMrn: space.mrn, policyMrns: [policyMrn] },
        },
      });
    } else if (!assigned) {
      //assign
      assignPolicy({
        variables: { input: { assetMrn: space.mrn, policyMrns: [policyMrn] } },
      });
    }
  };

  const handleFinalizeClick = () => {
    if (finalizeAction) {
      finalizeAction();
    }
    navigate(`/space/integrations?spaceId=${space.id}`);
  };

  return (
    <Fragment>
      <ScrollToTop />

      <AnimatePresence>
        {assignedPolicies.length === data?.content?.totalCount ? (
          <AllRecsEnabledMessage space={space} />
        ) : (
          <Box
            id="policy-list"
            component={motion.div}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 3.75,
                p: 2,
              }}
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="h6">
                  Recommended Policies and Query Packs
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ textAlign: { xs: "left", sm: "right" } }}
              >
                <Button
                  component={RouterLink}
                  to={`/space/security/policies?spaceId=${space.id}`}
                  variant="outlined"
                  color="secondary"
                  endIcon={<OpenInNewIcon />}
                  sx={{ py: 0.5, px: 1 }}
                >
                  go to policies
                </Button>
                <Button
                  component={RouterLink}
                  to={`/space/inventory/query-packs?spaceId=${space.id}`}
                  variant="outlined"
                  color="secondary"
                  endIcon={<OpenInNewIcon />}
                  sx={{ py: 0.5, px: 1, ml: 2 }}
                >
                  go to query packs
                </Button>
              </Grid>
            </Grid>
            <PolicyList
              {...{
                data,
                loading,
                assignLoading,
                unassignLoading,
                handleAssignPolicy,
              }}
            />
          </Box>
        )}
      </AnimatePresence>

      {bonusMessage === "azure-cloud" && (
        <AzurePipelinesMessage {...{ space }} />
      )}

      <Box sx={{ mt: 6, display: "flex", justifyContent: "center" }}>
        <GradientButton
          onClick={handleFinalizeClick}
          sx={{
            px: 2,
            py: 1,
            textTransform: "uppercase",
            fontWeight: 500,
          }}
        >
          finalize setup
        </GradientButton>
      </Box>
    </Fragment>
  );
}

type PolicyListProps = {
  data: SearchPolicyQuery | undefined;
  loading: boolean;
  handleAssignPolicy: (policyMrn: string, assigned: boolean) => void;
  assignLoading: boolean;
  unassignLoading: boolean;
};

const PolicyList = ({
  data,
  loading,
  handleAssignPolicy,
  assignLoading,
  unassignLoading,
}: PolicyListProps) => {
  // only show the block loading if its the first time loading up
  if (loading && !data) return <Loading what="policies" />;

  return (
    <Paper>
      <List
        disablePadding
        sx={{
          "& .MuiDivider-root:last-of-type": {
            display: "none",
          },
        }}
      >
        {data?.content?.edges?.map((x) => {
          return (
            <Fragment key={x.node?.mrn}>
              <ListItem sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: 1,
                  }}
                >
                  <Box sx={{ display: "flex" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mr: 2,
                        minWidth: 50,
                      }}
                    >
                      {policyIcon(x.node?.name || "blank", "large")}
                    </Box>
                    <Box>
                      <Typography fontWeight={700}>{x.node?.name}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    {loading || assignLoading || unassignLoading ? (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: 38,
                          width: 38,
                          mr: 1,
                        }}
                      >
                        <CircularProgress size={20} />
                      </Box>
                    ) : (
                      <Switch
                        checked={x.node?.assigned}
                        onChange={() =>
                          handleAssignPolicy(x.node?.mrn!, x.node?.assigned!)
                        }
                      />
                    )}
                  </Box>
                </Box>
              </ListItem>
              <Divider component="li" />
            </Fragment>
          );
        })}
      </List>
    </Paper>
  );
};

const AllRecsEnabledMessage = ({ space }: { space: Space }) => {
  return (
    <Box
      sx={{ mb: 5.75 }}
      id="enabledmessage"
      component={motion.div}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <EmptyState contentType={"integrations-finalize"} space={space} />
    </Box>
  );
};

const AzurePipelinesMessage = ({ space }: { space: Space }) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        placeContent: "center",
        alignItems: "center",
        py: 6,
        px: {
          xs: 2,
          sm: 3,
          md: 6,
        },
        my: 6,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          mb: 2,
        }}
      >
        <MondooIcon sx={{ color: "#9147FF", fontSize: 56 }} />
        <CloseIcon color="primary" sx={{ fontSize: 24, mx: 2 }} />
        <AzurePipelinesIcon sx={{ fontSize: 36 }} />
      </Box>
      <Box
        mb={3}
        sx={{
          width: { xs: "100%", sm: "70%", md: "38%" },
          textAlign: "center",
        }}
      >
        <Typography variant="h5" fontWeight={700} sx={{ mb: 1 }}>
          Don’t forget Azure Pipelines!
        </Typography>
        <Typography color="text.secondary">
          Integrate with Azure Pipelines to monitor your code before it ever
          reaches production.
        </Typography>
      </Box>
      <Button
        component={RouterLink}
        to={`/space/integrations/add/cicd/azure?spaceId=${space.id}`}
        variant="outlined"
        color="secondary"
      >
        Add Integration
      </Button>
    </Paper>
  );
};
