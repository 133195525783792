import { createSvgIcon } from "@mui/material";

export const MS365Icon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0421 1.51294L9.92975 1.58089C9.75105 1.68899 9.5827 1.80986 9.4256 1.94192L9.74965 1.71399H12.5L13 5.5L10.5 8L8 9.7377V11.7414C8 13.1409 8.73145 14.4387 9.9287 15.1634L12.5605 16.7564L7 20.0001H5.92755L3.92868 18.7902C2.73143 18.0655 2 16.7677 2 15.3682V8.6303C2 7.23035 2.73189 5.93225 3.92976 5.2077L9.92975 1.57843C9.96695 1.55594 10.0044 1.53411 10.0421 1.51294Z"
      fill="url(#paint0_radial_3039_21045)"
    />
    <path
      d="M10.0421 1.51294L9.92975 1.58089C9.75105 1.68899 9.5827 1.80986 9.4256 1.94192L9.74965 1.71399H12.5L13 5.5L10.5 8L8 9.7377V11.7414C8 13.1409 8.73145 14.4387 9.9287 15.1634L12.5605 16.7564L7 20.0001H5.92755L3.92868 18.7902C2.73143 18.0655 2 16.7677 2 15.3682V8.6303C2 7.23035 2.73189 5.93225 3.92976 5.2077L9.92975 1.57843C9.96695 1.55594 10.0044 1.53411 10.0421 1.51294Z"
      fill="url(#paint1_linear_3039_21045)"
    />
    <path
      d="M15.9996 9.5V11.7402C15.9996 13.1397 15.2682 14.4374 14.0709 15.1621L8.07093 18.7939C6.84353 19.5368 5.31638 19.5636 4.06738 18.8743L9.92828 22.422C11.2016 23.1928 12.7976 23.1928 14.0709 22.422L20.0709 18.7901C21.2682 18.0654 21.9996 16.7677 21.9996 15.3682V13.75L21.4996 13L15.9996 9.5Z"
      fill="url(#paint2_radial_3039_21045)"
    />
    <path
      d="M15.9996 9.5V11.7402C15.9996 13.1397 15.2682 14.4374 14.0709 15.1621L8.07093 18.7939C6.84353 19.5368 5.31638 19.5636 4.06738 18.8743L9.92828 22.422C11.2016 23.1928 12.7976 23.1928 14.0709 22.422L20.0709 18.7901C21.2682 18.0654 21.9996 16.7677 21.9996 15.3682V13.75L21.4996 13L15.9996 9.5Z"
      fill="url(#paint3_linear_3039_21045)"
    />
    <path
      d="M20.0703 5.20765L14.0703 1.57839C12.8369 0.832355 11.3011 0.809245 10.049 1.50906L9.92975 1.58116C8.7319 2.30572 8 3.60378 8 5.00375V9.7457L9.92975 8.5784C11.2026 7.80855 12.7975 7.80855 14.0703 8.5784L20.0703 12.2077C21.2307 12.9096 21.9538 14.1497 21.9979 15.4993C21.9993 15.4557 22 15.412 22 15.3682V8.63025C22 7.2303 21.2681 5.9322 20.0703 5.20765Z"
      fill="url(#paint4_radial_3039_21045)"
    />
    <path
      d="M20.0703 5.20765L14.0703 1.57839C12.8369 0.832355 11.3011 0.809245 10.049 1.50906L9.92975 1.58116C8.7319 2.30572 8 3.60378 8 5.00375V9.7457L9.92975 8.5784C11.2026 7.80855 12.7975 7.80855 14.0703 8.5784L20.0703 12.2077C21.2307 12.9096 21.9538 14.1497 21.9979 15.4993C21.9993 15.4557 22 15.412 22 15.3682V8.63025C22 7.2303 21.2681 5.9322 20.0703 5.20765Z"
      fill="url(#paint5_linear_3039_21045)"
    />
    <defs>
      <radialGradient
        id="paint0_radial_3039_21045"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.7093 5.31915) rotate(110.528) scale(16.6829 29.0983)"
      >
        <stop offset="0.06441" stopColor="#AE7FE2" />
        <stop offset="1" stopColor="#0078D4" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_3039_21045"
        x1="8.75595"
        y1="18.9342"
        x2="6.37565"
        y2="14.8173"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#114A8B" />
        <stop offset="1" stopColor="#0078D4" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint2_radial_3039_21045"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(5.21458 18.1756) rotate(-8.36717) scale(15.5252 10.2554)"
      >
        <stop offset="0.133928" stopColor="#D59DFF" />
        <stop offset="1" stopColor="#5E438F" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_3039_21045"
        x1="20.1779"
        y1="12.6884"
        x2="17.6272"
        y2="16.3458"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#493474" />
        <stop offset="1" stopColor="#8C66BA" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint4_radial_3039_21045"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(20.5276 13.252) rotate(-165.772) scale(12.4614 20.9776)"
      >
        <stop offset="0.0584996" stopColor="#50E6FF" />
        <stop offset="1" stopColor="#436DCD" />
      </radialGradient>
      <linearGradient
        id="paint5_linear_3039_21045"
        x1="8.4879"
        y1="1.52828"
        x2="12.2434"
        y2="1.52828"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#2D3F80" />
        <stop offset="1" stopColor="#436DCD" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>,
  "MS 365",
);
