import React from "react";
import {
  Alert,
  AlertColor,
  AlertTitle,
  Box,
  Link,
  Typography,
} from "@mui/material";
import { DisabledScanning } from "~/pages/inventory/components/EmptyState";
import { Space } from "~/lib/types";
import {
  AssetSoftwarePackagesType,
  SoftwareEmptyStateVariants,
} from "~/pages/inventory/components/AssetSoftware/types";

type SoftwareScanningEmptyStateProps = {
  variant: SoftwareEmptyStateVariants | null;
  packageScores: AssetSoftwarePackagesType | null | undefined;
  space: Space;
};

export const AssetSoftwareScanningEmptyState = ({
  variant,
  packageScores,
  space,
}: SoftwareScanningEmptyStateProps) => {
  if (!variant) return null;

  const noEntriesTitle = !packageScores
    ? "You're all set!"
    : "Vulnerability data not available";
  const noEntriesStatus: AlertColor = packageScores ? "success" : "error";
  let noEntriesMessage = (
    <Typography>
      No vulnerable packages found out of {packageScores?.stats?.totalPackages}{" "}
      packages scanned.
    </Typography>
  );

  if (variant === "scanningDisabled") {
    return (
      <Box mt={3}>
        <DisabledScanning space={space} />
      </Box>
    );
  }

  if (variant === "notFoundError") {
    noEntriesMessage = (
      <Typography>Mondoo didn't find any packages on this asset.</Typography>
    );
  }

  if (variant === "platformNotSupported") {
    noEntriesMessage = (
      <Typography>
        Mondoo didn't find any packages on this asset because we don't yet
        support the platform. Please let us know what platforms you want support
        for:{" "}
        <Link
          sx={{ textDecoration: "underline", color: "inherit" }}
          href="mailto:hello@mondoo.com"
        >
          hello@mondoo.com
        </Link>
      </Typography>
    );
  }

  return (
    <Alert severity={noEntriesStatus} variant="outlined" sx={{ my: 3 }}>
      <AlertTitle>{noEntriesTitle}</AlertTitle>
      {noEntriesMessage}
    </Alert>
  );
};
