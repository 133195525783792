import { forwardRef, Fragment, ReactElement, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import { ChartData } from "./donut-chart";

type Props = {
  showTooltip: boolean;
  tooltipContent: ReactNode;
};

export const DonutChartTooltip = forwardRef<HTMLElement, Props>(
  (props, ref) => (
    <Box
      ref={ref}
      sx={{
        backgroundColor: "rgba(97, 97, 97, 0.92)",
        px: 1,
        py: 0.5,
        borderRadius: 1,
        minWidth: "44px",
        maxWidth: `300px`,
        position: "absolute",
        transformOrigin: "center bottom",
        transition: "transform 500ms",
        pointerEvents: "none",
        opacity: props.showTooltip ? 1 : 0,
        zIndex: 1,
      }}
    >
      {props.tooltipContent}
      <Box
        component="span"
        sx={[
          {
            position: "absolute",
            left: "calc(50% - 8px)",
            top: "100%",
            width: "16px",
            height: "10px",
            overflow: "hidden",
            color: "rgba(97, 97, 97, 0.92)",
          },
          {
            "&:after": {
              content: '""',
              display: "block",
              width: "100%",
              height: "100%",
              margin: "auto",
              backgroundColor: "rgba(97, 97, 97, 0.92)",
              transform: "rotate(45deg)",
              transformOrigin: "100% 0",
            },
          },
        ]}
      />
    </Box>
  ),
);

// helper function to generate the content that gets placed inside the tooltip
export const generateTooltipContent = (
  data: ChartData,
  total: number,
): ReactElement => {
  return (
    <Fragment>
      <Typography
        fontWeight={700}
        fontSize={12}
        align="center"
        sx={{ textTransform: "uppercase" }}
      >
        {data.label} {data.value}
      </Typography>
      <Typography
        fontSize={12}
        align="center"
        sx={{ textTransform: "uppercase", top: 0, left: 0 }}
      >
        {Math.round((data.value / total) * 100)}%
      </Typography>
    </Fragment>
  );
};
