import { TableCell } from "@mui/material";
import { Impact } from "~/components/impact";
import { CommonCellProps } from "~/pages/inventory/components/AssetSoftware/types";

type ImpactCellProps = {
  hideTooltip?: boolean;
};

export const ImpactCell = ({
  pckg,
  hideTooltip,
  ...props
}: CommonCellProps & ImpactCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <Impact hideTooltip={hideTooltip} impact={pckg.riskScore} isRiskScore />
    </TableCell>
  );
};
