import { Box, useTheme } from "@mui/material";
import { ImpactResultIcon } from "./impact-result-icon";

type CheckStatsProps = {
  results: {
    passed?: number;
    failed?: number;
    errored?: number;
  };
};

export const CheckStats = ({ results }: CheckStatsProps) => {
  const theme = useTheme();

  return (
    <Box display="flex" gap={3}>
      {Object.prototype.hasOwnProperty.call(results, "passed") && (
        <Box display="flex" gap={1} alignItems="center">
          <ImpactResultIcon
            size="s"
            result="pass"
            color={theme.palette.none.main}
          />
          {results.passed}
        </Box>
      )}
      {Object.prototype.hasOwnProperty.call(results, "failed") && (
        <Box display="flex" gap={1} alignItems="center">
          <ImpactResultIcon
            size="s"
            result="fail"
            color={theme.palette.critical.main}
          />
          {results.failed}
        </Box>
      )}
      {Object.prototype.hasOwnProperty.call(results, "errored") && (
        <Box display="flex" gap={1} alignItems="center">
          <ImpactResultIcon
            size="s"
            result="error"
            color={theme.palette.error.main}
          />
          {results.errored}
        </Box>
      )}
    </Box>
  );
};

export default CheckStats;
