import { RiskFactorsIndicators } from "~/pages/space/security/components/RiskFactors/types";
import React from "react";
import {
  AccessToKeys,
  AdminUser,
  ContainerEnvironmentIcon,
  DatabaseAccessIcon,
  DefaultRiskAction,
  DefensiveIcon,
  EndOfLife,
  ExploitableIcon,
  InternetExposed,
  RemoteExecutionIcon,
  SensitiveDataIcon,
  ServiceRunning,
  TaggedPriorityIcon,
} from "~/components/icons";
import { SxProps } from "@mui/material";

type GetRiskFactorIconParams = {
  indicator: string | null | undefined;
  sx?: SxProps;
};

export const getRiskFactorIcon = ({
  indicator,
  sx: propsSx = {},
}: GetRiskFactorIconParams) => {
  const sx = {
    cursor: "pointer",
    ...propsSx,
  };

  const riskFactorsIconMap: Record<RiskFactorsIndicators, React.ReactElement> =
    {
      uncategorized: <InternetExposed {...{ sx }} />,
      "internet-facing": <InternetExposed {...{ sx }} />,
      "db-access": <DatabaseAccessIcon {...{ sx }} />,
      "user-risk": <AdminUser {...{ sx }} />,
      "asset-in-use": <ServiceRunning {...{ sx }} />,
      "code-execution": <RemoteExecutionIcon {...{ sx }} />,
      tagged: <TaggedPriorityIcon {...{ sx }} />,
      "sensitive-data": <SensitiveDataIcon {...{ sx }} />,
      credentials: <AccessToKeys {...{ sx }} />,
      containers: <ContainerEnvironmentIcon {...{ sx }} />,
      exploitable: <ExploitableIcon {...{ sx }} />,
      eol: <EndOfLife {...{ sx }} />,
      defensive: <DefensiveIcon {...{ sx }} />,
      default: <DefaultRiskAction {...{ sx }} />,
    };

  return (
    riskFactorsIconMap[(indicator || "default") as RiskFactorsIndicators] || (
      <DefaultRiskAction {...{ sx }} />
    )
  );
};
