import { alpha, SxProps, Theme, Typography } from "@mui/material";
import { Flex } from "~/components/ui-library";
import { CvssScoreType } from "~/lib/score";

export type CvssScoreBoxProps = {
  value: number;
  sx?: SxProps<Theme>;
  id?: string;
};

export function CvssScoreBox({ value, sx, id }: CvssScoreBoxProps) {
  // TODO:: consider switching None with Unknown globally for other usages as well
  // https://github.com/mondoohq/console/issues/4156
  const color = value !== 0 ? CvssScoreType(value) : "unknown";
  const label = value !== 0 ? CvssScoreType(value) : "unknown";
  const formattedValue = !!value ? (value / 10).toFixed(1) : "—";
  return (
    <Flex
      id={id}
      sx={[
        {
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: (theme) => theme.spacing(18),
          height: (theme) => theme.spacing(18),
          border: "2px solid",
          borderColor: (theme) => theme.palette[color].light,
          borderRadius: 2,
          backgroundColor: (theme) => alpha(theme.palette[color].main, 0.1),

          ".value": {
            fontSize: 48,
            lineHeight: 1,
            fontWeight: 700,
            mb: 1,
          },
          ".label": {
            textTransform: "uppercase",
            fontSize: 24,
            lineHeight: 1,
            fontWeight: 700,
            color: (theme) => theme.palette[color].main,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography className="value">{formattedValue}</Typography>
      <Typography className="label">{label}</Typography>
    </Flex>
  );
}
