import { Chip, Typography } from "@mui/material";
import { Tag } from "~/operations";

type FirewatchChipProps = {
  tag: NonNullable<Tag>;
};

export function ChipText({ tag }: FirewatchChipProps) {
  return (
    <Typography
      sx={{
        fontSize: 12,
        color: "text.secondary",
        textTransform: "capitalize",
      }}
    >
      {tag.key}{" "}
      <Typography component="span" sx={{ fontSize: 12, fontWeight: 700 }}>
        {tag.value}
      </Typography>
    </Typography>
  );
}

export function FirewatchChip({ tag }: FirewatchChipProps) {
  return (
    <Chip
      label={<ChipText {...{ tag }} />}
      data-testid={tag.key.split(" ").join("-")}
      variant="filled"
      size="small"
      className="firewatch-chip"
      sx={{
        borderRadius: "4px",
        backgroundColor: "background.light",
        color: "text.secondary",
        transition: "all 0s",
      }}
    />
  );
}
