import { pluralize } from "~/lib/pluralize";
import { RemediationPackageFieldsFragment } from "~/operations";
import { RemediationFilterContainer } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationFilterContainer";
import { RemediationFilterTile } from "~/components/DetailsPage/components/Remediation/RemediationFilter/RemediationFilterTile";

type RemediationFilterProps = {
  filter: string;
  onFilter: (version: string) => void;
  packages: Array<RemediationPackageFieldsFragment>;
  isAllVersionsSelected?: boolean;
  onAllClick: () => void;
  allPackagesCount: number;
};

export const RemediationFilter = ({
  onFilter,
  filter,
  packages,
  isAllVersionsSelected,
  onAllClick,
  allPackagesCount,
}: RemediationFilterProps) => {
  return (
    <RemediationFilterContainer>
      <RemediationFilterTile
        title="All packages"
        version={`${allPackagesCount} ${pluralize(
          "version",
          allPackagesCount,
        )}`}
        isActive={isAllVersionsSelected}
        onClick={onAllClick}
        id="ALL"
      />
      {packages.map((pckg) => {
        return (
          <RemediationFilterTile
            key={pckg.id}
            id={pckg.id}
            title={pckg.name}
            version={pckg.version}
            onClick={onFilter}
            isActive={pckg.id === filter}
          />
        );
      })}
    </RemediationFilterContainer>
  );
};
