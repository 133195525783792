import { createSvgIcon } from "@mui/material";

export const ViewComfyFilled = createSvgIcon(
  <svg viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 3.78947H3.78947V0H0V3.78947ZM0 8.52632H3.78947V4.73684H0V8.52632ZM4.73684 8.52632H8.52632V4.73684H4.73684V8.52632ZM9.47368 8.52632H13.2632V4.73684H9.47368V8.52632ZM4.73684 3.78947H8.52632V0H4.73684V3.78947ZM9.47368 0V3.78947H13.2632V0H9.47368ZM14.2105 8.52632H18V4.73684H14.2105V8.52632ZM0 13.2632H3.78947V9.47368H0V13.2632ZM4.73684 13.2632H8.52632V9.47368H4.73684V13.2632ZM9.47368 13.2632H13.2632V9.47368H9.47368V13.2632ZM14.2105 13.2632H18V9.47368H14.2105V13.2632ZM14.2105 0V3.78947H18V0H14.2105Z"
      fill="white"
    />
  </svg>,
  "View Comfy Filled",
);
