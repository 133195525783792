import { Fragment, useState } from "react";
import { useColorMode } from "~/providers/color-mode";
import { useViewer } from "~/providers/viewer";
import { LoadViewerDocument, useUpdateUserNameMutation } from "~/operations";
import {
  ConfigurationPaper,
  ConfigurationList,
  ConfigurationItemText,
  ConfigurationItemSwitch,
  ConfigurationItemTextProps,
  ConfigurationItemSwitchProps,
} from "./configuration-items";

type UserNameSectionProps = {};

export function UserNameSection({}: UserNameSectionProps) {
  const { viewer, updateViewerSettings } = useViewer();
  const { colorBlind } = useColorMode();
  const [name, setName] = useState<string>(viewer?.name || "");
  const [errMsg, setErrMsg] = useState<string>("");

  const [updateUserName] = useUpdateUserNameMutation();

  const handleChangeName: ConfigurationItemTextProps["onChange"] = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };

  const storeName = async () => {
    try {
      await updateUserName({
        variables: { input: { name } },
        refetchQueries: [LoadViewerDocument],
      });
      setErrMsg("");
    } catch {
      setErrMsg("Cannot store user name");
    }
  };

  const handleSetColorBlind: ConfigurationItemSwitchProps["onChange"] = (
    _event,
    value,
  ) => {
    updateViewerSettings({
      variables: { key: "colorblind", value: value.toString() },
      refetchQueries: [LoadViewerDocument],
    });
  };

  return (
    <Fragment>
      <ConfigurationPaper>
        <ConfigurationList title="General">
          <ConfigurationItemText
            onBlur={storeName}
            primary="Name"
            secondary="Your full name"
            label="Name"
            value={name}
            onChange={handleChangeName}
            error={errMsg.length === 0 ? false : true}
            helperText={errMsg}
          ></ConfigurationItemText>
          <ConfigurationItemText
            disabled={true}
            primary="Email"
            secondary="Email used for login and notifications"
            label="Email"
            value={viewer?.email || ""}
          ></ConfigurationItemText>
        </ConfigurationList>
      </ConfigurationPaper>

      <ConfigurationPaper>
        <ConfigurationList title="Display">
          <ConfigurationItemSwitch
            primary="Colorblind Mode"
            secondary="Modifies colors for easy viewing for colorblind users."
            checked={Boolean(colorBlind === "true")}
            action={colorBlind ? "On" : "Off"}
            onChange={handleSetColorBlind}
          />
        </ConfigurationList>
      </ConfigurationPaper>
    </Fragment>
  );
}
