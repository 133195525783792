import { Fragment, MouseEvent, useState } from "react";
import {
  Popper,
  Grow,
  IconButton,
  Paper,
  MenuList,
  ListItemIcon,
  ListItemText,
  MenuItem,
  ClickAwayListener,
  PopperPlacementType,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { MoreHorizIcon } from "~/components/icons";

type Props = {
  id: string;
  actions: Action[];
  placement?: PopperPlacementType;
  buttonIcon?: JSX.Element;
  buttonSize?: "small" | "medium" | "large";
  listProps?: {
    title?: string;
    checkbox?: boolean;
  };
};

export type Action = {
  label: string;
  action: () => void;
  icon?: JSX.Element;
  dataName?: string;
  checked?: boolean;
};

export function ActionMenu({
  id,
  actions,
  listProps,
  buttonIcon = <MoreHorizIcon />,
  placement = "bottom-end",
  buttonSize = "medium",
}: Props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      {actions?.length > 0 && (
        <IconButton
          aria-describedby={id}
          aria-haspopup="true"
          size={buttonSize}
          onClick={handleClick}
        >
          {buttonIcon}
        </IconButton>
      )}
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        transition
        sx={{ zIndex: 999 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} timeout={350}>
              <Paper sx={{ minWidth: 180, p: listProps?.title ? 1 : 0 }}>
                <Fragment>
                  {listProps?.title && (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ pt: 1, px: 2 }}
                    >
                      {listProps.title}
                    </Typography>
                  )}
                  <MenuList>
                    {actions.map((action: Action) => (
                      <MenuItem
                        {...(listProps?.checkbox
                          ? {}
                          : { onClick: action.action })}
                        key={action.label}
                        {...(action.dataName
                          ? { "data-name": action.dataName }
                          : {})}
                      >
                        {listProps?.checkbox ? (
                          <Fragment>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={action.checked}
                                  onChange={action.action}
                                  name={action.label}
                                />
                              }
                              label={action.label}
                            />
                          </Fragment>
                        ) : (
                          <Fragment>
                            {action.icon && (
                              <ListItemIcon>{action.icon}</ListItemIcon>
                            )}
                            <ListItemText>{action.label}</ListItemText>
                          </Fragment>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Fragment>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
    </Fragment>
  );
}
