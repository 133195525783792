export const githubInstructions = {
  title: "GitHub Actions",
  tagline: "PIPELINE MONITORING FOR GITHUB PROJECTS",
  flags: [
    { id: "ci_cd", title: "CI/CD" },
    { id: "shift_left", title: "Shift Left" },
  ],
  instructions: [
    { id: 1, tagline: "Copy the Mondoo Platform credentials", token: true },
    {
      id: 2,
      tagline: "Securely store the credentials",
      link: "Go to the GitHub Console",
      href: "https://github.com",
      markdownText:
        "In your GitHub repository, generate a new repository secret. Name the secret `MONDOO_CONFIG_BASE64`, and set the value to your generated credentials. To learn more, read the <a href='https://docs.github.com/en/actions/security-guides/encrypted-secrets' target='_blank' rel='noopener'>GitHub documentation</a>.",
    },
    {
      id: 3,
      tagline: "Use cnspec in your GitHub Actions workflows",
      markdownText:
        "Mondoo provides a number of different GitHub Actions available on the GitHub Marketplace. For the complete list of available actions and usage examples, see the <a href='https://github.com/marketplace/actions/mondoo-action' target='_blank' rel='noopener'>Mondoo action in the GitHub Marketplace</a>.",
      configExamples: [
        {
          id: "docker_config",
          config:
            "```yaml\n\
# Example GitHub Action to scan a Docker image\n\
# See https://github.com/mondoohq/actions/tree/main/docker-image for additional usage information.\n\
\nname: mondoo-cnspec-scan\n\
\n\
on:\n\
  pull_request:\n\
  push:\n\
    branches: [main]\n\
\n\
jobs:\n\
  scan:\n\
    runs-on: ubuntu-latest\n\
\n\
    steps:\n\
      - uses: actions/checkout@v3\n\
      - name: Scan with cnspec\n\
        uses: mondoohq/actions/docker-image@main\n\
        with:\n\
          service-account-credentials: ${{ secrets.MONDOO_CONFIG_BASE64 }}\n\
          image: ubuntu:22.04```",
        },
        {
          id: "kubernetes_config",
          config:
            "```yaml\n\
# Example GitHub Action to scan a Kubernetes manifest\n\
# See https://github.com/mondoohq/actions/tree/main/k8s-manifest for additional usage information.\n\
\n\
name: mondoo-cnspec-scan\n\
\n\
on:\n\
  pull_request:\n\
  push:\n\
    branches: [main]\n\
\n\
jobs:\n\
  scan:\n\
    runs-on: ubuntu-latest\n\
\n\
    steps:\n\
      - uses: actions/checkout@v3\n\
      - name: Scan with cnspec\n\
        uses: mondoohq/actions/k8s-manifest@main\n\
        with:\n\
          service-account-credentials: ${{ secrets.MONDOO_CONFIG_BASE64 }}\n\
          path: nginx.yml ```",
        },
        {
          id: "terraform_config",
          config:
            '```yaml\n\
# Example GitHub Action to scan a Terraform config\n\
# See https://github.com/mondoohq/actions/tree/main/terraform-hcl for additional usage information.\n\
\n\
name: mondoo-cnspec-scan\n\
\n\
on:\n\
  pull_request:\n\
  push:\n\
    branches: [main]\n\
\n\
jobs:\n\
  scan:\n\
    runs-on: ubuntu-latest\n\
\n\
    steps:\n\
      - uses: actions/checkout@v3\n\
      - name: Scan with cnspec\n\
        uses: mondoohq/actions/terraform-hcl@main\n\
        with:\n\
          service-account-credentials: ${{ secrets.MONDOO_CONFIG_BASE64 }}\n\
          path: "*.tf"\n\
          ```',
        },
      ],
    },
  ],
};
