import { Grid, Typography } from "@mui/material";
import { ListPolicyEdge } from "~/pages/inventory/asset/types";
import { PolicyResultDistribution } from "~/pages/inventory/components/PolicyScoreCard";
import { AssetScoreBox } from "../asset-score-box";

type PrintPolicySummaryHeaderProps = {
  listPolicyEdge: ListPolicyEdge;
};

export function PrintPolicySummaryHeader({
  listPolicyEdge,
}: PrintPolicySummaryHeaderProps) {
  const policy = listPolicyEdge.node;
  const { score, name, scoreStats } = policy;
  const { __typename, ...rest } = scoreStats || {};
  const stats = Object.entries(rest)
    .filter(([key, _value]) =>
      ["passed", "failed", "snoozed", "disabled"].includes(key),
    )
    .map(([key, value]) => {
      if (key === "errors") {
        key = "errorscan";
      }

      return {
        result: key as keyof ListPolicyEdge["node"]["scoreStats"],
        value,
      };
    });

  return (
    <Grid
      container
      item
      id={listPolicyEdge.node.mrn}
      sx={{
        pb: 4,
        px: 4,
        borderBottom: "1px solid #DBDADD",
      }}
    >
      <Grid container item xs="auto">
        <Grid item xs={12}>
          <Typography variant="h4" component="h3" fontSize={32}>
            {name}
          </Typography>
        </Grid>

        <Grid
          container
          item
          xs={5}
          columns={10}
          sx={{ alignItems: "end", ml: -1.25 }}
        >
          {stats.map((item) => (
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              key={item.result}
            >
              <PolicyResultDistribution result={item} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs="auto" sx={{ ml: "auto" }}>
        <AssetScoreBox score={score} policyBox />
      </Grid>
    </Grid>
  );
}
