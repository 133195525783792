import React from "react";
import {
  NoDataFound,
  NoMatchFilters,
} from "~/pages/inventory/components/EmptyState";
import {
  AssetSoftwarePackagesNotFoundType,
  AssetSoftwarePackagesType,
  SoftwareEmptyStateVariants,
} from "~/pages/inventory/components/AssetSoftware/types";

type SoftwareTableEmptyStateProps = {
  variant: SoftwareEmptyStateVariants | null;
  packageScores:
    | AssetSoftwarePackagesType
    | AssetSoftwarePackagesNotFoundType
    | null
    | undefined;
  handleResetFilters: () => void;
};

export const AssetSoftwareTableEmptyState = ({
  variant,
  packageScores,
  handleResetFilters,
}: SoftwareTableEmptyStateProps) => {
  if (!variant) return;

  if (variant === "noPackagesInstalled") {
    return (
      <NoDataFound
        title="No installed software detected!"
        content="Mondoo did not detect any installed packages on this asset."
      />
    );
  }

  if (variant === "noVulnerablePackages") {
    return (
      <NoDataFound
        title="Good news: No vulnerable software detected!"
        content={`Mondoo scanned ${packageScores?.stats?.totalPackages?.toLocaleString()} applications and found that they're all up to date.`}
      />
    );
  }

  if (variant === "noVulnerablePackagesWithAppliedFilters") {
    return (
      <NoMatchFilters
        title="No installed software matches your filter selection"
        content={`Out of the software detected on this asset, none matches the filters you selected. Reset them to reveal all ${
          packageScores?.stats?.vulnerablePackages?.toLocaleString() || ""
        } installed applications.`}
        onClick={handleResetFilters}
      />
    );
  }

  return null;
};
