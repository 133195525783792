export const LibraryHref = (typ: string, id: string): string => {
  // get params, if this includes a spaceId
  const windowUrl = new URL(window.location.href);
  const params = new URLSearchParams(windowUrl.search);

  // TODO: get rid of the different prefixes, since the query param indicates that already
  let prefix = "";
  if (params.has("organizationId")) {
    prefix = "/organization";
  } else if (params.has("spaceId")) {
    prefix = "/space";
  }

  let url = `${prefix}/library/${typ}/${id}`;
  let query = params.toString();
  if (query != "") {
    url = url + "?" + query;
  }
  return url;
};
