import { createSvgIcon } from "@mui/material";

export const DownloadSourceIcon = createSvgIcon(
  <svg
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M24.248 23.9996C23.9448 23.9983 23.6549 23.8729 23.4415 23.6505C23.2283 23.428 23.1092 23.1274 23.1102 22.8143C23.1115 22.5012 23.2331 22.2013 23.4484 21.981L29.2401 15.9953L23.4481 10.0095H23.4483C23.1964 9.70571 23.1107 9.29067 23.2204 8.90695C23.3303 8.52326 23.6207 8.22309 23.992 8.10955C24.3633 7.99623 24.7649 8.08477 25.0589 8.34514L31.6619 15.1575L31.6621 15.1572C31.8784 15.3788 32 15.6806 32 15.9955C32 16.3104 31.8784 16.6121 31.6621 16.8338L25.0592 23.6461L25.0589 23.6458C24.8452 23.8711 24.5532 23.9987 24.248 24L24.248 23.9996ZM8.55165 23.6454H8.55139C8.76431 23.4244 8.88365 23.1253 8.88365 22.8132C8.88365 22.5012 8.76431 22.2021 8.55139 21.981L2.75964 15.9953L8.55165 10.0096H8.5514C8.80334 9.70576 8.88901 9.29073 8.77936 8.907C8.66946 8.52331 8.37902 8.22314 8.00777 8.1096C7.63649 7.99628 7.23486 8.08483 6.94085 8.34519L0.337871 15.1568C0.121634 15.3785 0 15.6802 0 15.9951C0 16.3101 0.121634 16.6118 0.337871 16.8334L6.94085 23.6457L6.9411 23.6455C7.15478 23.8708 7.44676 23.9983 7.752 23.9996C8.0534 23.9954 8.34078 23.8679 8.55167 23.6455L8.55165 23.6454ZM13.7151 23.3503L20.307 9.70272V9.70298C20.4424 9.42417 20.4656 9.10109 20.3715 8.80466C20.2771 8.50819 20.0734 8.26232 19.8041 8.12079C19.5356 7.98455 19.226 7.96268 18.9417 8.05966C18.6576 8.1569 18.4218 8.36534 18.2849 8.64046L11.6819 22.288C11.5462 22.5669 11.523 22.8899 11.6174 23.1864C11.7115 23.4828 11.9155 23.7287 12.1845 23.8702C12.3444 23.9519 12.5201 23.9965 12.6986 23.9999C12.9095 23.9994 13.1162 23.9382 13.2955 23.8236C13.475 23.7092 13.6204 23.5453 13.7152 23.3506L13.7151 23.3503Z" />
  </svg>,
  "Download Source",
);
