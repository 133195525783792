import { ReactElement } from "react";
import { Impact } from "./impact";
import { Box } from "@mui/material";
import { ImpactResultIcon } from "./impact-result-icon";
import { useCheckImpactResult } from "./use-impact-result";
import { ImpactTooltip } from "./impact-tooltip";

type CheckImpactResultType = {
  score: {
    type: number;
    value: number;
  };
  impact: number;
  isActive?: boolean;
  isCVSS?: boolean;
  hideTooltip?: boolean;
};

export const ImpactResult = ({
  score,
  impact,
  isActive = true,
  isCVSS = false,
  hideTooltip = false,
}: CheckImpactResultType) => {
  const { result, color } = useCheckImpactResult({
    score,
    isActive,
    impact,
  });

  const TooltipWrapper = ({ children }: { children: ReactElement }) =>
    hideTooltip ? (
      children
    ) : (
      <ImpactTooltip
        color={color}
        impact={impact}
        isCVSS={isCVSS}
        result={result}
      >
        {children}
      </ImpactTooltip>
    );

  return (
    <TooltipWrapper>
      <Box display="inline-flex" gap={1}>
        <ImpactResultIcon result={result} color={color} />
        <Impact
          isActive={isActive}
          impact={impact}
          adjustedColor={color}
          hideTooltip
          isCVSS={isCVSS}
        />
      </Box>
    </TooltipWrapper>
  );
};
