import {
  JiraTicketContextProjectsPayload,
  JiraTicketContextType,
  useGetTicketContextQuery,
} from "~/operations";
import { useCallback, useMemo } from "react";
import { debounce } from "lodash";

type UseJiraTicketContextParams = {
  scopeMrn: string;
  skip?: boolean;
};

export function useJiraTicketContext({
  scopeMrn,
  skip = false,
}: UseJiraTicketContextParams) {
  const { data: ticketData, refetch } = useGetTicketContextQuery({
    variables: {
      input: {
        scopeMrn,
        jira: {
          type: JiraTicketContextType.Projects,
        },
      },
    },
    skip: skip,
  });

  const fetchProjects = useMemo(
    () =>
      debounce((query: string) => {
        refetch({
          input: {
            scopeMrn,
            jira: {
              type: JiraTicketContextType.Projects,
              projects: { query },
            },
          },
        });
      }, 400),
    [],
  );

  const selectedProjectKey = "case.projectId";

  const projects = (
    ticketData?.ticketContext as JiraTicketContextProjectsPayload | undefined
  )?.projects;

  const isProjectsEmpty = (projects || []).length === 0;

  const saveSelectedProject = (value: string) => {
    localStorage.setItem(selectedProjectKey, value);
  };

  const loadSelectedProject = useCallback(() => {
    const storedProjectId = localStorage.getItem(selectedProjectKey) || "";
    return (projects || []).find((project) => project.key === storedProjectId);
  }, [projects]);

  return {
    projects,
    isProjectsEmpty,
    saveSelectedProject,
    loadSelectedProject,
    fetchProjects,
  };
}
