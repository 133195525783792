import { useTheme } from "@mui/material";
import { BannerProps } from "./Banner";

type UseBannerProps = {
  type: BannerProps["type"];
};

export function useBanner({ type }: UseBannerProps) {
  const theme = useTheme();
  const getBannerColors = (type: string) => {
    switch (type) {
      case "info":
        return {
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.light,
        };
      case "warning":
      // not yet implemented
      case "error":
      // not yet implemented
      case "success":
      // not yet implemented
      default:
        return {
          background: theme.palette.secondary.main,
          color: theme.palette.secondary.light,
        };
    }
  };

  const bannerColors = getBannerColors(type);

  return {
    bannerColors,
  };
}
