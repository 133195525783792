import { createSvgIcon } from "@mui/material";

export const TotalEC2InstancesIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_16556)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.052 27L26 13.948L13 14V27.052L26.052 27ZM27 14H29V15H27V17H29V18H27V20H29V21H27V23H29V24H27V26H29V27H27V27.052C27 27.575 26.574 28 26.052 28H26V30H25V28H23V30H22V28H20V30H19V28H17V30H16V28H14V30H13V28H12.948C12.426 28 12 27.575 12 27.052V27H10V26H12V24H10V23H12V21H10V20H12V18H10V17H12V15H10V14H12V13.948C12 13.425 12.426 13 12.948 13H13V11H14V13H16V11H17V13H19V11H20V13H22V11H23V13H25V11H26V13H26.052C26.574 13 27 13.425 27 13.948V14ZM21 33H7V19H9V18H7.062C6.477 18 6 18.477 6 19.062V32.938C6 33.523 6.477 34 7.062 34H20.939C21.524 34 22 33.523 22 32.938V31H21V33ZM34 7.062V20.938C34 21.523 33.524 22 32.939 22H30V21H33V7H19V10H18V7.062C18 6.477 18.477 6 19.062 6H32.939C33.524 6 34 6.477 34 7.062Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_16556"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Total EC2 Instances",
);
