import { createSvgIcon } from "@mui/material";

export const PushpinIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.4241 0.243878C11.213 0.0335752 10.9053 -0.0485701 10.6169 0.0283513C10.3285 0.105418 10.1034 0.329943 10.026 0.617327C9.94881 0.904697 10.0313 1.21121 10.2423 1.42165L11.3218 2.49739L7.34417 6.46291C6.42135 5.94854 5.35564 5.74753 4.30798 5.89021C3.26047 6.03288 2.28783 6.5114 1.53745 7.25345L1.05504 7.73416C0.898182 7.89032 0.810064 8.10208 0.810064 8.32299C0.810064 8.54388 0.898182 8.75576 1.05504 8.91181L5.50251 13.3414L0.244821 18.5783C0.0336298 18.7888 -0.0488058 19.0953 0.0285327 19.3827C0.105726 19.67 0.331045 19.8946 0.61944 19.9716C0.907825 20.0486 1.21542 19.9664 1.42661 19.7561L6.68206 14.5192L11.0669 18.8886V18.8884C11.2236 19.0447 11.4361 19.1325 11.6578 19.1325C11.8795 19.1325 12.0921 19.0447 12.2487 18.8884L12.7311 18.4077V18.4079C13.4761 17.6597 13.9566 16.6903 14.1001 15.6462C14.2437 14.602 14.0424 13.5396 13.5266 12.6194L17.4939 8.66615L18.5734 9.74394C18.7844 9.95424 19.0922 10.0364 19.3806 9.95947C19.6689 9.8824 19.8942 9.65802 19.9715 9.37064C20.0488 9.08327 19.9664 8.77661 19.7552 8.56616L11.4241 0.243878ZM11.6663 17.1307L2.8299 8.3358C3.44016 7.78427 4.23991 7.48763 5.06355 7.50736C5.8872 7.5271 6.67166 7.86164 7.2544 8.44187L11.5598 12.7217C12.1412 13.3031 12.4764 14.0847 12.4962 14.9052C12.516 15.7255 12.219 16.5222 11.6663 17.1306L11.6663 17.1307ZM12.5014 11.3048L8.67216 7.49127L12.5037 3.67326L16.3329 7.49127L12.5014 11.3048Z"
      fill="white"
    />
  </svg>,
  "Pushpin",
);
