import { createSvgIcon } from "@mui/material";

export const CheckClipboardIcon = createSvgIcon(
  <svg viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.6613 22.6667C12.3062 22.6687 11.9649 22.529 11.7132 22.2785C11.4614 22.0281 11.3199 21.6875 11.3203 21.3324C11.3205 20.9772 11.4625 20.6368 11.7147 20.3866L17.0478 15.0536C17.2982 14.8011 17.6388 14.6592 17.9943 14.6592C18.3499 14.6592 18.6905 14.8011 18.9409 15.0536C19.1934 15.304 19.3353 15.6446 19.3353 16.0002C19.3353 16.3557 19.1934 16.6964 18.9409 16.9468L13.6079 22.2798C13.3561 22.5296 13.0155 22.6686 12.6613 22.6666L12.6613 22.6667Z"
      fill="url(#paint0_linear_12308_130942)"
    />
    <path
      d="M12.6616 22.6648C12.3071 22.6668 11.9665 22.5278 11.715 22.278L9.04836 19.6114V19.6116C8.7102 19.2735 8.57806 18.7804 8.70194 18.3184C8.8256 17.8565 9.18652 17.4956 9.64853 17.3718C10.1103 17.2481 10.6034 17.38 10.9416 17.7184L13.6082 20.385V20.3848C13.8606 20.6352 14.0026 20.9761 14.0026 21.3314C14.0026 21.687 13.8606 22.0278 13.6082 22.278C13.3564 22.5278 13.0158 22.6668 12.6616 22.6648L12.6616 22.6648Z"
      fill="url(#paint1_linear_12308_130942)"
    />
    <path
      d="M23.3294 31.9999H4.6639C3.60303 31.9999 2.58564 31.5785 1.83544 30.8283C1.08547 30.0783 0.664062 29.0609 0.664062 28.0001V6.6678C0.664062 5.60693 1.08547 4.58955 1.83544 3.83935C2.58564 3.08938 3.60303 2.66797 4.6639 2.66797H8.66373C9.01729 2.66797 9.35633 2.80836 9.60632 3.05835C9.85654 3.30834 9.99693 3.64759 9.99693 4.00117V5.33437H17.9966V4.00117C17.9966 3.64761 18.137 3.30834 18.3872 3.05835C18.6372 2.80836 18.9763 2.66797 19.3298 2.66797H23.3296C24.3905 2.66797 25.4079 3.08938 26.1581 3.83935C26.9081 4.58955 27.3295 5.60693 27.3295 6.6678V28.0001C27.3295 29.0609 26.9081 30.0783 26.1581 30.8283C25.4079 31.5785 24.3905 31.9999 23.3296 31.9999H23.3294ZM4.6639 5.33415C4.31012 5.33415 3.97107 5.47476 3.72108 5.72475C3.47109 5.97474 3.33047 6.31381 3.33047 6.66757V27.9998C3.33047 28.3534 3.47109 28.6927 3.72108 28.9427C3.97107 29.1926 4.31014 29.333 4.6639 29.333H23.3294C23.6832 29.333 24.0222 29.1926 24.2722 28.9427C24.5222 28.6927 24.6628 28.3534 24.6628 27.9998V6.66757C24.6628 6.31379 24.5222 5.97474 24.2722 5.72475C24.0222 5.47476 23.6832 5.33415 23.3294 5.33415H20.663C20.663 6.04149 20.382 6.71957 19.882 7.21978C19.3818 7.71976 18.7035 8.00078 17.9964 8.00078H9.99671C9.28959 8.00078 8.61128 7.71976 8.11107 7.21978C7.61109 6.71958 7.33007 6.04149 7.33007 5.33415H4.6639Z"
      fill="url(#paint2_linear_12308_130942)"
    />
    <path
      d="M17.9944 7.99967H9.99476C9.28764 7.99967 8.60933 7.71865 8.10912 7.21868C7.60914 6.71847 7.32812 6.04038 7.32812 5.33304V2.66663C7.32812 1.95929 7.60914 1.2812 8.10912 0.780997C8.60932 0.281017 9.28764 0 9.99476 0H17.9944C18.7015 0 19.3799 0.281017 19.8801 0.780997C20.38 1.2812 20.6611 1.95929 20.6611 2.66663V5.33304C20.6611 6.04038 20.38 6.71847 19.8801 7.21868C19.3799 7.71865 18.7015 7.99967 17.9944 7.99967ZM9.99476 2.66663V5.33304H17.9944V2.66663H9.99476Z"
      fill="url(#paint3_linear_12308_130942)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12308_130942"
        x1="11.3203"
        y1="14.6592"
        x2="19.5257"
        y2="22.4667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12308_130942"
        x1="8.65625"
        y1="17.3262"
        x2="14.127"
        y2="22.5342"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_12308_130942"
        x1="0.664063"
        y1="2.66797"
        x2="30.4401"
        y2="28.4005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_12308_130942"
        x1="7.32813"
        y1="0"
        x2="14.7371"
        y2="11.7387"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Checked Clipboard",
);
