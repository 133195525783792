import { createSvgIcon, SvgIcon } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export const PCIIcon = ({ ...props }) => {
  const { mode } = useColorMode();
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 48 48"
        enableBackground="new 0 0 48 48"
        xmlSpace="preserve"
      >
        <g>
          <path
            fill={mode === "light" ? "#363636" : "#fff"}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M35.5,30.8h4.9V17.7h-4.9V30.8z M35,13.5c0,0.9,0.3,1.6,0.8,2.1c0.6,0.5,1.3,0.8,2.1,0.8
			c0.8,0,1.5-0.3,2.1-0.8c0.6-0.5,0.8-1.2,0.8-2.1c0-0.9-0.3-1.6-0.8-2.1c-0.6-0.5-1.3-0.8-2.1-0.8c-0.8,0-1.5,0.3-2.1,0.8
			C35.2,11.9,35,12.6,35,13.5z M33.5,30.4l0-3.3c-0.6,0.2-1.2,0.4-1.8,0.6c-0.6,0.1-1.1,0.2-1.7,0.2c-0.6,0-1.1-0.1-1.6-0.2
			c-0.5-0.2-0.9-0.4-1.3-0.7c-0.4-0.3-0.7-0.7-0.9-1.1c-0.2-0.5-0.3-1-0.3-1.6c0-1.2,0.3-2.1,1-2.7c0.7-0.6,1.6-0.9,2.7-0.9
			c0.7,0,1.3,0.1,1.9,0.3c0.6,0.2,1.1,0.4,1.6,0.7l0-3.5c-0.6-0.2-0.6-0.3-1.4-0.4c-0.8-0.2-1.6-0.2-2.6-0.2c-1.2,0-2.3,0.2-3.4,0.5
			c-1,0.3-1.9,0.8-2.6,1.4c-0.7,0.6-1.3,1.3-1.7,2.2c-0.4,0.8-0.6,1.8-0.6,2.8c0,1,0.2,2,0.6,2.8c0.4,0.8,0.9,1.6,1.7,2.1
			c0.7,0.6,1.6,1,2.7,1.4c1.1,0.3,2.2,0.5,3.6,0.5c0.9,0,1.8-0.1,2.5-0.2C32.7,30.7,32.7,30.7,33.5,30.4z M19.9,22.2
			c0-1.5-0.6-2.6-1.8-3.4c-1.2-0.7-2.9-1.1-5-1.1H7.5v13.2h4.8v-4h0.8c1,0,2-0.1,2.8-0.3c0.8-0.2,1.6-0.5,2.2-0.9
			c0.6-0.4,1.1-0.9,1.4-1.5C19.8,23.6,19.9,22.9,19.9,22.2z M48.1,28.3L10.9,40.5L0,9.8l39.1-2.3L48.1,28.3z M15.3,22.2
			c0,0.6-0.2,1.1-0.6,1.4c-0.4,0.3-1,0.4-1.9,0.4h-0.5v-3.5h0.6C14.5,20.5,15.3,21.1,15.3,22.2z"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};

PCIIcon.displayName = "PCI";
