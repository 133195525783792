import { TokenStatus, TokenStatusFields } from "./types";

const isExpired = (token: TokenStatusFields) => {
  return new Date(token.expiresAt).getTime() - Date.now() <= 0;
};

export function useTokenStatus(token: TokenStatusFields): TokenStatus {
  if (token.revoked) return "revoked";
  if (isExpired(token)) return "expired";
  return "valid";
}
