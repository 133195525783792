import { createSvgIcon } from "@mui/material";

export const NavDrawerPolicyIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16.72 9.37988H13.02C12.9372 9.37988 12.87 9.44704 12.87 9.52988V10.3899C12.87 10.4727 12.9372 10.5399 13.02 10.5399H16.72C16.8028 10.5399 16.87 10.4727 16.87 10.3899V9.52988C16.87 9.44704 16.8028 9.37988 16.72 9.37988Z" />
    <path d="M16.72 15.75H13.02C12.9372 15.75 12.87 15.8172 12.87 15.9V16.76C12.87 16.8428 12.9372 16.91 13.02 16.91H16.72C16.8028 16.91 16.87 16.8428 16.87 16.76V15.9C16.87 15.8172 16.8028 15.75 16.72 15.75Z" />
    <path d="M11 14.57C10.8895 14.4607 10.7404 14.3994 10.585 14.3994C10.4296 14.3994 10.2805 14.4607 10.17 14.57L9.24 15.5L8.3 14.57C8.18953 14.4607 8.0404 14.3994 7.885 14.3994C7.72959 14.3994 7.58046 14.4607 7.47 14.57C7.36368 14.68 7.30426 14.8271 7.30426 14.98C7.30426 15.133 7.36368 15.28 7.47 15.39L8.41 16.33L7.47 17.27C7.36368 17.3801 7.30426 17.5271 7.30426 17.68C7.30426 17.833 7.36368 17.98 7.47 18.09C7.58046 18.1994 7.72959 18.2607 7.885 18.2607C8.0404 18.2607 8.18953 18.1994 8.3 18.09L9.24 17.15L10.17 18.09C10.2805 18.1994 10.4296 18.2607 10.585 18.2607C10.7404 18.2607 10.8895 18.1994 11 18.09C11.055 18.0369 11.0988 17.9732 11.1287 17.9027C11.1586 17.8323 11.174 17.7566 11.174 17.68C11.174 17.6035 11.1586 17.5278 11.1287 17.4573C11.0988 17.3869 11.055 17.3232 11 17.27L10.06 16.33L11 15.39C11.055 15.3369 11.0988 15.2732 11.1287 15.2027C11.1586 15.1323 11.174 15.0566 11.174 14.98C11.174 14.9035 11.1586 14.8278 11.1287 14.7573C11.0988 14.6869 11.055 14.6232 11 14.57Z" />
    <path d="M10.5 8.50012L8.44 10.5501L7.72 9.83012C7.66684 9.77508 7.60314 9.7313 7.5327 9.70141C7.46226 9.67151 7.38652 9.6561 7.31 9.6561C7.23347 9.6561 7.15773 9.67151 7.08729 9.70141C7.01685 9.7313 6.95316 9.77508 6.9 9.83012C6.79068 9.94058 6.72937 10.0897 6.72937 10.2451C6.72937 10.4005 6.79068 10.5497 6.9 10.6601L8 11.7901C8.10991 11.8971 8.25662 11.9579 8.41 11.9601C8.56623 11.9575 8.71592 11.8969 8.83 11.7901L11.29 9.33012C11.3993 9.21966 11.4606 9.07053 11.4606 8.91512C11.4606 8.75971 11.3993 8.61058 11.29 8.50012C11.1838 8.39806 11.0423 8.34106 10.895 8.34106C10.7477 8.34106 10.6062 8.39806 10.5 8.50012Z" />
    <path d="M18.73 3.82H16.17V3.6C16.17 3.17565 16.0014 2.76869 15.7014 2.46863C15.4013 2.16857 14.9943 2 14.57 2H9.42998C9.00564 2 8.59867 2.16857 8.29861 2.46863C7.99855 2.76869 7.82998 3.17565 7.82998 3.6V3.82H5.26998C4.84564 3.82 4.43867 3.98857 4.13861 4.28863C3.83855 4.58869 3.66998 4.99565 3.66998 5.42V20.42C3.67524 20.8409 3.84613 21.2427 4.14562 21.5385C4.44511 21.8342 4.84908 22 5.26998 22H18.73C19.1543 22 19.5613 21.8314 19.8614 21.5314C20.1614 21.2313 20.33 20.8243 20.33 20.4V5.4C20.3247 4.97913 20.1538 4.57728 19.8543 4.28154C19.5549 3.98579 19.1509 3.81997 18.73 3.82ZM9.12998 3.6C9.12998 3.5606 9.13774 3.52159 9.15282 3.48519C9.1679 3.4488 9.18999 3.41573 9.21785 3.38787C9.24571 3.36001 9.27878 3.33791 9.31518 3.32284C9.35158 3.30776 9.39059 3.3 9.42998 3.3H14.57C14.6495 3.3 14.7259 3.33161 14.7821 3.38787C14.8384 3.44413 14.87 3.52043 14.87 3.6V5.32C14.87 5.39957 14.8384 5.47587 14.7821 5.53213C14.7259 5.58839 14.6495 5.62 14.57 5.62H9.42998C9.39059 5.62 9.35158 5.61224 9.31518 5.59716C9.27878 5.58209 9.24571 5.55999 9.21785 5.53213C9.18999 5.50427 9.1679 5.4712 9.15282 5.4348C9.13774 5.39841 9.12998 5.3594 9.12998 5.32V3.6ZM19 20.4C19.0014 20.4398 18.9946 20.4794 18.98 20.5164C18.9654 20.5535 18.9434 20.5871 18.9152 20.6152C18.8871 20.6434 18.8534 20.6654 18.8164 20.68C18.7794 20.6946 18.7398 20.7014 18.7 20.7H5.26998C5.23021 20.7014 5.19058 20.6946 5.15355 20.68C5.11652 20.6654 5.08288 20.6434 5.05474 20.6152C5.0266 20.5871 5.00455 20.5535 4.98997 20.5164C4.97539 20.4794 4.96859 20.4398 4.96998 20.4V5.4C4.96998 5.32044 5.00159 5.24413 5.05785 5.18787C5.11411 5.13161 5.19042 5.1 5.26998 5.1H7.82998V5.3C7.82998 5.72435 7.99855 6.13131 8.29861 6.43137C8.59867 6.73143 9.00564 6.9 9.42998 6.9H14.57C14.9943 6.9 15.4013 6.73143 15.7014 6.43137C16.0014 6.13131 16.17 5.72435 16.17 5.3V5.1H18.73C18.8095 5.1 18.8859 5.13161 18.9421 5.18787C18.9984 5.24413 19.03 5.32044 19.03 5.4L19 20.4Z" />
  </svg>,
  "Policy",
);
