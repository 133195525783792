import { Box, Typography } from "@mui/material";
import { CompletionBar, CompletionBarProps } from "../completion-bar";

type CompletionProps = {
  completionData: CompletionBarProps;
  assets?: number;
};

export function CompletionCell({ completionData, assets }: CompletionProps) {
  if (!assets) {
    return (
      <Box>
        <Typography
          variant="caption"
          sx={{ color: "text.disabled", fontWeight: 700 }}
        >
          UNKNOWN
        </Typography>
      </Box>
    );
  }

  return <CompletionBar {...completionData} />;
}
