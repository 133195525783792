import { useEffect } from "react";
import {
  createSearchParams,
  Navigate,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import { ErrorBoundary } from "~/components/error-boundary";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { useViewer } from "~/providers/viewer";
import { TokenProvider } from "~/providers/token";
import {
  IntegrationsRoutes,
  InventoryRoutes,
  PageLayout,
  Redirect,
  SettingsRoutes,
  VulnsRoutes,
} from "~/routes";
import { IamActions } from "~/lib/iam";
import {
  LoadViewerDocument,
  useGetIntegrationsSummaryQuery,
  useLoadSpaceQuery,
  useTestIamActionsQuery,
} from "~/operations";
import { CicdRoutes } from "~/routes/space/cicd-routes";
import { AppNavDrawerItem, useAppNavDrawer } from "~/providers/app-nav-drawer";
import {
  AttachIcon,
  NavDrawerCicdIcon,
  NavDrawerFleetIcon,
  NavDrawerIntegrationsIcon,
  NavDrawerOverviewIcon,
  NavDrawerRiskActionsIcon,
  NavDrawerSecurityIcon,
  NavDrawerSettingsIcon,
  NavDrawerVulnerabilitiesIcon,
  NavDrawerWorkspacesIcon,
} from "~/components/icons";
import { SecurityRoutes } from "~/routes/space/security-routes";
import { VulnerabilitiesRoutes } from "~/routes/space/vulnerabilities-routes";
import { MVDRoutes } from "~/routes/space/mvd-routes";
import { Planning } from "./security/planning/planning";
import { PlanningEdit } from "./security/planning/planning-edit";
import { ComplianceRoutes } from "~/routes/space/compliance-routes";
import { isFeatureEnabled } from "~/login/features";
import { SoftwareDetails } from "~/pages/inventory/components/SoftwareDetails";
import { ComplianceNavIcon } from "./compliance/temporary/ComplianceNavIcon";
import { SpaceDashboard } from "./dashboard-space";
import { FullTextSearchResults } from "./Search";
import { CasesRoutes } from "~/routes/space/cases-routes";
import { CaseDetailsPage } from "~/components/cases/components/CaseDetailsPage";
import { WorkspaceRoutes } from "~/routes/space/workspace-routes";

export type SpacePagesProps = {};

export function SpacePages({}: SpacePagesProps) {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { viewerSettings, updateViewerSettings } = useViewer();
  const { setTree } = useAppNavDrawer();

  const spaceId = searchParams.get("spaceId");
  const spaceMrn = spaceId
    ? `//captain.api.mondoo.app/spaces/${spaceId}`
    : undefined;

  const { data, loading } = useLoadSpaceQuery({
    variables: { spaceMrn: spaceMrn || "" },
    skip: !spaceMrn,
  });
  const space = data?.space;

  const { data: integrationSummaryData, loading: integrationSummaryLoading } =
    useGetIntegrationsSummaryQuery({
      variables: { input: { spaceMrn: spaceMrn || "" } },
      skip: !spaceMrn,
    });

  const integrationsSummary = integrationSummaryData?.integrationsSummary;
  const integrationCount = integrationsSummary?.total ?? 0;
  //check if any integrations exist
  const hasIntegrations = integrationCount > 0;
  // loop through the integrations and check if any at all are failing, return true or false
  const hasFailingIntegrations = Boolean(
    integrationsSummary?.integrationSummaries?.some(
      ({ failedCount, warningCount }) => failedCount > 0 || warningCount > 0,
    ),
  );

  const iamActionsQuery = useTestIamActionsQuery({
    variables: {
      resourceMrn: spaceMrn || "",
      actions: IamActions.default(),
    },
    skip: !spaceMrn, // if we don't have a space, don't make this call
  });

  useEffect(() => {
    // If user's current spaceId is different from last spaceId
    if (space && space.id !== viewerSettings?.last_space_id) {
      // Update user's last spaceId
      updateViewerSettings({
        variables: { key: "last_space_id", value: space.id },
        refetchQueries: [LoadViewerDocument],
      });
    }
  }, [space]);

  useEffect(() => {
    const hasUpdateSpacePermission =
      iamActionsQuery.data?.testIamActions?.includes(
        IamActions.CAPTAIN_UPDATESPACE,
      );
    const hasCreateIntegrationPermission =
      iamActionsQuery.data?.testIamActions.includes(
        IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
      );

    const hasListCasesPermission =
      iamActionsQuery.data?.testIamActions.includes(
        IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
      );

    const integrationsTree: AppNavDrawerItem["tree"] = [
      {
        text: "All Integrations",
        link: {
          to: `/space/integrations?spaceId=${space?.id}`,
        },
        hasError: hasFailingIntegrations,
      },
      hasCreateIntegrationPermission
        ? {
            text: "Add New Integration",
            link: {
              to: `/space/integrations/add?spaceId=${space?.id}`,
            },
          }
        : undefined,
    ].flatMap((x) => x ?? []);

    const vulnerabilitiesTree: AppNavDrawerItem["tree"] = [
      {
        text: "Overview",
        link: {
          to: `/space/vulnerabilities?spaceId=${space?.id}`,
        },
      },

      {
        text: "Advisories",
        link: {
          to: `/space/vulnerabilities/advisories?spaceId=${space?.id}`,
        },
      },
      {
        text: "CVEs",
        link: {
          to: `/space/vulnerabilities/cves?spaceId=${space?.id}`,
        },
      },
      {
        text: "Software",
        link: {
          to: `/space/vulnerabilities/software?spaceId=${space?.id}`,
        },
      },
      {
        text: "Affected Assets",
        link: {
          to: `/space/vulnerabilities/affected-assets?spaceId=${space?.id}`,
        },
      },
      {
        text: "Vulnerability Database",
        link: {
          to: `/space/mvd`,
        },
      },
    ].flatMap((navItem) => navItem ?? []);

    const SecurityTree: AppNavDrawerItem["tree"] = [
      {
        text: "Overview",
        link: {
          to: `/space/security?spaceId=${space?.id}`,
        },
      },
      {
        text: "Policies",
        link: {
          to: `/space/security/policies?spaceId=${space?.id}`,
        },
      },
      {
        text: "Checks",
        link: {
          to: `/space/security/checks?spaceId=${space?.id}`,
        },
      },
    ];

    const ComplianceTree: AppNavDrawerItem["tree"] = [
      {
        text: "Frameworks",
        link: { to: `/space/compliance?spaceId=${space?.id}` },
      },
      {
        text: "Reports",
        link: { to: `/space/compliance/reports?spaceId=${space?.id}` },
      },
    ].flatMap((x) => x ?? []);

    const inventoryTree: AppNavDrawerItem["tree"] = [
      {
        text: "Assets",
        link: { to: `/space/inventory?spaceId=${space?.id}` },
      },
      {
        text: "Query Packs",
        link: { to: `/space/inventory/query-packs?spaceId=${space?.id}` },
      },
    ];

    setTree(
      [
        {
          text: "Dashboard",
          icon: <NavDrawerOverviewIcon />,
          link: {
            to: `/space/overview?spaceId=${space?.id}`,
          },
        },
        isFeatureEnabled("Workspaces")
          ? {
              text: "Workspaces",
              icon: <NavDrawerWorkspacesIcon />,
              link: {
                to: `/space/workspaces?spaceId=${space?.id}`,
              },
            }
          : null,
        {
          text: "Inventory",
          icon: <NavDrawerFleetIcon />,
          link: {
            to: `/space/inventory?spaceId=${space?.id}`,
          },
          tree: inventoryTree,
        },
        {
          text: "CI/CD",
          icon: <NavDrawerCicdIcon />,
          link: {
            to: `/space/cicd?spaceId=${space?.id}`,
          },
        },
        {
          text: "Vulnerabilities",
          icon: <NavDrawerVulnerabilitiesIcon />,
          link: {
            to: `/space/vulnerabilities?spaceId=${space?.id}`,
          },
          tree: vulnerabilitiesTree,
        },
        {
          text: "Security",
          icon: <NavDrawerSecurityIcon />,
          link: {
            to: `/space/security?spaceId=${space?.id}`,
          },
          tree: SecurityTree,
        },
        {
          text: "Compliance",
          icon: <ComplianceNavIcon />,
          link: {
            to: `/space/compliance?spaceId=${space?.id}`,
          },
          tree: ComplianceTree,
        },
        isFeatureEnabled("Risk Actions")
          ? {
              text: "Risk Actions",
              icon: <NavDrawerRiskActionsIcon />,
              link: {
                to: `/space/risk-actions?spaceId=${space?.id}`,
              },
            }
          : null,
        hasListCasesPermission
          ? {
              text: "Cases",
              icon: <AttachIcon />,
              link: {
                to: `/space/cases?spaceId=${space?.id}`,
              },
            }
          : null,
        {
          text: "Integrations",
          icon: <NavDrawerIntegrationsIcon />,
          link: {
            to:
              hasIntegrations || !hasCreateIntegrationPermission
                ? `/space/integrations?spaceId=${space?.id}`
                : `/space/integrations/add?spaceId=${space?.id}`,
          },
          tree: hasIntegrations ? integrationsTree : undefined,
          loading: integrationSummaryLoading,
          selected: location.pathname.startsWith("/space/integrations"),
        },
        hasUpdateSpacePermission
          ? {
              text: "Settings",
              icon: <NavDrawerSettingsIcon />,
              link: {
                to: `/space/settings?spaceId=${space?.id}`,
              },
            }
          : null,
      ].flatMap((navItem) => navItem ?? []),
    );
    return () => setTree([]);
  }, [
    space,
    iamActionsQuery?.data?.testIamActions.length,
    integrationSummaryLoading,
    integrationCount,
    location.href,
  ]);

  if (loading) {
    return <LoadingPage />;
  }

  // if we don't have a space, try to redirect to the last visited space
  if (!space) {
    const spaceId = viewerSettings?.last_space_id;
    if (spaceId) {
      let newParams: { [key: string]: string | string[] } = {};
      searchParams.forEach((_key: string, value: string) => {
        newParams[value] = searchParams.getAll(value);
      });

      newParams["spaceId"] = spaceId;
      return (
        <Navigate
          replace
          to={window.location.pathname + "?" + createSearchParams(newParams)}
        />
      );
    }
    return <Navigate to="/dashboard" replace />;
  }

  if (iamActionsQuery.error) {
    return <LoadingFailedPage />;
  }

  if (iamActionsQuery.loading || !iamActionsQuery.data || !space) {
    return <LoadingPage />;
  }

  const availablePermissions = iamActionsQuery.data.testIamActions;

  const hasListCasesPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_LISTCASES,
  );

  return (
    <TokenProvider space={space} availablePermissions={availablePermissions}>
      <ErrorBoundary key="space">
        <Routes>
          {/* Integrations Routes */}
          <Route
            path="integrations/*"
            element={
              <IntegrationsRoutes {...{ space, availablePermissions }} />
            }
          />

          {/* Fleet Routes */}
          {/* Deprecated - Redirect to "/inventory" */}
          <Route
            path="fleet/*"
            element={<Redirect from="space/fleet" to="space/inventory" />}
          />

          <Route element={<PageLayout />}>
            <Route
              index
              element={<SpaceDashboard {...{ space, availablePermissions }} />}
            />

            {isFeatureEnabled("Workspaces") && (
              <Route
                path="workspaces/*"
                element={
                  <WorkspaceRoutes {...{ space, availablePermissions }} />
                }
              />
            )}

            {/* Overview Route */}
            <Route
              path="overview"
              element={<SpaceDashboard {...{ space, availablePermissions }} />}
            />

            {/* Settings Routes */}
            <Route
              path="settings/*"
              element={<SettingsRoutes {...{ space, availablePermissions }} />}
            />

            {/* Inventory Routes */}
            <Route
              path="inventory/*"
              element={<InventoryRoutes {...{ space, availablePermissions }} />}
            />

            {/* CI/CD Projects Routes */}
            <Route
              path="cicd/*"
              element={<CicdRoutes {...{ space, availablePermissions }} />}
            />

            {/* Risk Actions Routes */}
            <Route path="risk-actions">
              <Route
                index
                element={<Planning {...{ space, availablePermissions }} />}
              />
              <Route
                path="edit"
                element={<PlanningEdit {...{ space, availablePermissions }} />}
              />
            </Route>

            {/* Security Routes */}

            <Route
              path="security/*"
              element={<SecurityRoutes {...{ space, availablePermissions }} />}
            />

            {/* Compliance Routes */}
            <Route
              path="compliance/*"
              element={
                <ComplianceRoutes {...{ space, availablePermissions }} />
              }
            />

            {/* New Vulnerabilities Routes */}
            <Route
              path="vulnerabilities/*"
              element={
                <VulnerabilitiesRoutes {...{ space, availablePermissions }} />
              }
            />

            {/* Vulnerabilities Routes */}
            <Route
              path="vulns/*"
              element={<VulnsRoutes {...{ space, availablePermissions }} />}
            />

            <Route path="software">
              <Route
                path=":name/*"
                element={<SoftwareDetails space={space} />}
              />
            </Route>

            {/* Mondoo Vulnerability Database Routes */}
            <Route path="mvd/*" element={<MVDRoutes space={space} />} />

            {hasListCasesPermission && (
              <Route
                path="cases/*"
                element={<CasesRoutes {...{ space, availablePermissions }} />}
              />
            )}
            <Route
              path="case"
              element={
                <CaseDetailsPage
                  availablePermissions={availablePermissions}
                  space={space}
                />
              }
            />
          </Route>
          <Route element={<PageLayout maxWidth={false} />}>
            <Route
              path="search"
              element={<FullTextSearchResults space={space} />}
            />
          </Route>
        </Routes>
      </ErrorBoundary>
    </TokenProvider>
  );
}
