import { Outlet, useOutletContext, useSearchParams } from "react-router-dom";
import { Box, Grid, Typography } from "@mui/material";
import {
  TabListItem,
  TabNavigation,
  useRouteMatch,
} from "~/components/tab-nav";
import { ComplianceBreadcrumbs } from "../breadcrumbs";
import { Space } from "~/lib/types";
import {
  ExceptionGroup,
  ExceptionMutationAction,
  ExceptionType,
  GetComplianceFrameworkQuery,
  ReviewStatus,
  TestIamActionsQuery,
  useGetComplianceFrameworkQuery,
} from "~/operations";
import {
  MostRecentChanges,
  MostRecentChangesProps,
} from "../most-recent-changes";
import { MoonIcon } from "~/components/ui-library";
import { FrameworkHeader } from "./framework-header";
import { BlockOutlined } from "@mui/icons-material";
import { sortBy } from "lodash";
import { useFetchExceptions } from "~/components/exceptions/use-fetch-exceptions";
import { PendingExceptionsTooltip } from "~/components/exceptions/pending-exceptions-tooltip";
import { CompletionV2 } from "~/pages/compliance/completionV2";
import { Distribution } from "~/pages/compliance/distribution";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { pluralize } from "~/lib/pluralize";

export type ComplianceFramework =
  GetComplianceFrameworkQuery["complianceFramework"];

type FrameworkContextType = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
  framework: ComplianceFramework;
  exceptionGroups: ExceptionGroup[];
};

type Props = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function Framework({ space, availablePermissions }: Props) {
  const [searchParams] = useSearchParams();
  const frameworkMrn = searchParams.get("frameworkMrn") || "";

  const {
    data: { complianceFramework: framework } = {},
    error,
    loading,
  } = useGetComplianceFrameworkQuery({
    variables: {
      input: {
        frameworkMrn,
        scopeMrn: space.mrn,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  const { pendingExceptionsGroups, exceptionGroups } = useFetchExceptions({
    mrn: frameworkMrn,
    scopeMrn: space.mrn,
    types: [ExceptionType.Compliance],
  });

  if (loading && !framework) {
    return <LoadingPage what="compliance framework" />;
  }

  if (error || !framework) {
    return <LoadingFailedPage what="compliance framework" />;
  }

  const tabList: TabListItem[] = [
    {
      label: "Controls",
      to: `controls?spaceId=${space.id}&frameworkMrn=${frameworkMrn}`,
      route: "/controls",
    },
    {
      label: "Policies",
      to: `policies?spaceId=${space.id}&frameworkMrn=${frameworkMrn}`,
      route: "/policies",
    },
    {
      label: "Assets",
      to: `assets?spaceId=${space.id}&frameworkMrn=${frameworkMrn}`,
      route: "/assets",
    },
    {
      label: (
        <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
          Exceptions
          {pendingExceptionsGroups.length > 0 && <PendingExceptionsTooltip />}
        </Box>
      ),
      to: `exceptions?spaceId=${space.id}&frameworkMrn=${frameworkMrn}`,
      route: "/exceptions",
    },
  ].flatMap((tab) => (tab ? [tab] : []));

  const currentTab = useRouteMatch(
    tabList.map((x) => x.route),
    "controls",
  );

  const maxChanges = 6;
  const changes: MostRecentChangesProps["changes"] = sortBy(
    pendingExceptionsGroups,
    "createdAt",
  )
    .filter((e) => e.reviewStatus === ReviewStatus.Approved)
    .reverse()
    .slice(0, maxChanges)
    .map((e) => {
      const isDisabled = e.action === ExceptionMutationAction.Disable;
      return {
        href: `exceptions?spaceId=${space.id}&frameworkMrn=${frameworkMrn}&exceptionId=${e.id}`,
        description: (
          <Typography sx={{ fontSize: (theme) => theme.spacing(1.5) }}>
            Moved {e.exceptions.length}{" "}
            {pluralize("control", e.exceptions.length)} to{" "}
            <Typography
              component="span"
              textTransform="uppercase"
              fontWeight="bold"
              sx={{ fontSize: (theme) => theme.spacing(1.5) }}
            >
              {e.action}
            </Typography>
          </Typography>
        ),
        startIcon: isDisabled ? <BlockOutlined /> : <MoonIcon />,
      };
    });

  return (
    <Box>
      <ComplianceBreadcrumbs framework={framework} space={space} />
      <FrameworkHeader framework={framework} space={space} />
      <Grid container spacing={3} pt={3} mb={5}>
        <Grid
          item
          xs={12}
          md={changes.length > 0 ? 8 : 12}
          rowSpacing={3}
          sx={{
            display: "flex",
            flexWrap: "nowrap",
            gap: 3,
          }}
        >
          <CompletionV2 progress={framework.stats.controls.averageCompletion} />
          <Distribution
            outOfScopeCount={framework.stats.controls.outOfScopeControls}
            disabledCount={framework.stats.controls.disabledControls}
            snoozedCount={framework.stats.controls.snoozedControls}
          />
        </Grid>
        {changes.length > 0 && (
          <Grid item xs={12} md={4}>
            {/* Most recent changes is using fake data - needs exceptions */}
            <MostRecentChanges {...{ changes }} />
          </Grid>
        )}
      </Grid>
      <TabNavigation {...{ id: "space-settings-tabs", tabList, currentTab }} />
      <Box>
        <Outlet
          context={
            {
              space,
              availablePermissions,
              framework,
              exceptionGroups,
            } satisfies FrameworkContextType
          }
        />
      </Box>
    </Box>
  );
}

export function useFrameworkContext() {
  return useOutletContext<FrameworkContextType>();
}
