import { motion } from "framer-motion";
import { alpha, Box, Button, SxProps, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { SyntheticEvent } from "react";

type Props = {
  id?: string;
  href?: string;
  onClick?: (e: SyntheticEvent<HTMLButtonElement>) => void;
  close?: boolean; //adding the close prop gives this button open/close looking capabilities
  layout?: SxProps;
};

export function AddButton({ id, href, onClick, close, layout }: Props) {
  const variants = {
    closed: {
      rotate: 0,
    },
    open: {
      rotate: 45,
    },
  };

  return (
    <Box
      component={motion.div}
      variants={variants}
      animate={close ? "open" : "closed"}
      sx={{ ...layout }}
    >
      <Button
        component={href ? RouterLink : Button}
        {...(id ? { id: `add-button-${id}` } : {})}
        {...(id
          ? { "data-name": close ? `close-button-${id}` : `add-button-${id}` }
          : {})}
        {...(href ? { to: href } : {})}
        {...(onClick ? { onClick } : {})}
        aria-label={close === undefined ? "Add" : close ? "Close" : "Open"}
        sx={[
          {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 40,
            height: 40,
            background: (theme) =>
              close
                ? `linear-gradient(to bottom right, ${alpha(
                    theme.palette.primary.main,
                    0.5,
                  )} 0%, ${alpha(theme.palette.secondary.main, 0.5)} 100%)`
                : "linear-gradient(133.55deg, #9147FF 0%, #4C35E8 100%)",
            borderRadius: "100%",
            transition: `background-color 300ms cubic-bezier(0.4, 0, 0.2, 1)`,
            cursor: "pointer",
            minWidth: "unset",
          },
          {
            "&:hover": {
              backgroundColor: (theme) =>
                close
                  ? alpha(theme.palette.error.main, 0.24)
                  : alpha(theme.palette.primary.main, 0.4),
            },
          },
        ]}
      >
        <Typography
          fontSize={40}
          lineHeight={"48px"}
          fontWeight={700}
          color={"common.white"}
        >
          +
        </Typography>
      </Button>
    </Box>
  );
}
