import { Component, Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Paper,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { Space } from "~/lib/types";
import { Code } from "../components/code";
import { VpnKeyIcon } from "~/components/icons";
import { IamActions } from "~/lib/iam";
import { TokenTimerWidget } from "../components/token-timer";
import { Markdown } from "../components/markdown";
import { useToken } from "~/providers/token";
import { ConfigurationListSubheader } from "~/components/configuration-items";
import { K8ConfigurationPanel } from "./integrations/kubernetes/k8-configuration-panel";
import {
  IntegrationAddHeader,
  OtherIntegrations,
} from "./integrations/headers/integration-add-header";
import { App } from "./integration-setup";

const ContentPaper = styled(Paper)`
  padding: 16px;
`;

export type AppDetailProps = {
  space: Space;
  item: App | null;
  availablePermissions: string[];
};

export type AppDetailState = {
  currentTab: string;
};

export function AppDetail({
  space,
  item,
  availablePermissions,
}: AppDetailProps) {
  const hasCreateAgentPermission =
    availablePermissions != null &&
    availablePermissions.includes(IamActions.AGENTMANAGER_CREATEAGENT);

  if (item == null) {
    return <></>;
  }

  return (
    <Box>
      <IntegrationAddHeader type={item.id as OtherIntegrations} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ConfigurationListSubheader component="div">
            <Typography component="span" variant="h6">
              Configuration
            </Typography>
          </ConfigurationListSubheader>
          <AddDefaultConfiguration
            item={item}
            space={space}
            hasCreateAgentPermission={hasCreateAgentPermission}
            availablePermissions={availablePermissions}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

type BashExampleProps = {
  title: string;
  description: string;
  name: string;
};

function BashExample({ title, description, name }: BashExampleProps) {
  return (
    <div key={`${name}-${title}-bash-example`}>
      {title != null && <Typography variant="h4">{title}</Typography>}
      <Markdown source={description} />
    </div>
  );
}

export type AddDefaultConfigurationProps = {
  space: Space;
  item: App;
  hasCreateAgentPermission: boolean;
  availablePermissions: string[];
};

export function AddDefaultConfiguration({
  space,
  item,
  hasCreateAgentPermission,
  availablePermissions,
}: AddDefaultConfigurationProps) {
  const { token } = useToken();
  let sections = [];

  // @TODO refactor this to use keys prop more efficiently
  // include token ticker
  if ("credentials" in item && "token" in item.credentials) {
    if (!hasCreateAgentPermission) {
      sections.push(<NoPermissionConfiguration key={sections.length} />);
    } else {
      sections.push(<TokenTimerWidget key={sections.length} />);
    }
  }

  if ("credentials" in item && "download" in item.credentials) {
    if (!hasCreateAgentPermission) {
      sections.push(<NoPermissionConfiguration key={sections.length} />);
    } else {
      sections.push(
        <CredentialsDownloader space={space} key={sections.length} />,
      );
    }
  }

  if ("customization" in item) {
    if (item.title === "Kubernetes") {
      const hasCreateK8IntegrationPermission = availablePermissions?.includes(
        IamActions.INTEGRATIONS_INTEGRATIONSMANAGER_CREATE,
      );

      sections.push(
        <K8ConfigurationPanel
          data={item.customization}
          space={space}
          key={sections.length}
          hasCreateK8IntegrationPermission={hasCreateK8IntegrationPermission}
        />,
      );
    }
  }

  // render all docs sections
  if ("documentation" in item) {
    Object.entries(item.documentation).forEach(([key, entry]) => {
      const tokenValue = token == null ? "PASTE_TOKEN" : token.value;
      const title = "title" in entry ? entry.title : "";
      const description = entry.description.replace(/\%TOKEN\%/g, tokenValue);
      sections.push(
        <BashExample
          key={key + description}
          title={title}
          name={title}
          description={description}
        />,
      );
    });
  }

  return <ContentPaper>{sections}</ContentPaper>;
}

export class NoPermissionConfiguration extends Component {
  state = {};

  render() {
    return (
      <ContentPaper>
        <Typography variant="h4">Configuration</Typography>
        <p>You have no permission to generate credentials</p>
      </ContentPaper>
    );
  }
}

export type CredentialsDownloaderProps = {
  space: Space;
};

type CredentialsDownloaderState = {
  base64: boolean;
  generated: boolean;
  credentials: string;
};

export function CredentialsDownloader(props: CredentialsDownloaderProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { genToken, exchangeToken } = useToken();
  const [state, setState] = useState<CredentialsDownloaderState>({
    base64: false,
    generated: false,
    credentials: "",
  });

  const getAgentCredentials = async () => {
    const token = await genToken({ spaceMrn: props.space.mrn, expiresIn: 120 });
    return exchangeToken(token);
  };

  const handleGenerateClick = async () => {
    try {
      const creds = await getAgentCredentials();
      setState((prevState) => ({
        ...prevState,
        generated: true,
        credentials: JSON.stringify(creds),
      }));
    } catch (error) {
      enqueueSnackbar("Failed to generate new credentials", {
        variant: "error",
      });
    }
  };

  const toggleBase64 = () => {
    setState((prevState) => ({
      ...prevState,
      base64: !prevState.base64,
    }));
  };

  const encodeBase64 = (val: string) => {
    if (state.base64) {
      return window.btoa(val);
    }
    return val;
  };

  return (
    <Fragment>
      <Typography variant="h4">Credentials</Typography>
      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateClick}
          sx={{ mr: 2 }}
          endIcon={<VpnKeyIcon />}
        >
          Generate new credentials
        </Button>

        <FormControlLabel
          control={
            <Checkbox
              checked={state.base64}
              onClick={toggleBase64}
              value="base64"
            />
          }
          label="Base64-encoded"
        />
      </Box>
      <Code copyButton className="shell">
        {encodeBase64(state.credentials)}
      </Code>
    </Fragment>
  );
}
