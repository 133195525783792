import React from "react";
import { Box, Paper, Typography } from "@mui/material";

type EmptyStateProps = {
  icon: React.ReactNode;
  headline: string;
  textContent?: React.ReactNode;
  actionButtons?: React.ReactNode;
};

// TODO:: Remove this component after refactoring empty states
// https://github.com/mondoohq/console/issues/4070
export const EmptyState = ({
  icon,
  headline,
  textContent,
  actionButtons,
}: EmptyStateProps) => {
  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
      }}
    >
      {icon}
      <Typography
        variant="h5"
        fontWeight={700}
        sx={{ mt: 2, mb: 1, textAlign: "center" }}
      >
        {headline}
      </Typography>
      {textContent && (
        <Typography sx={{ textAlign: "center" }}>{textContent}</Typography>
      )}
      {actionButtons && (
        <Box textAlign="center" pt={3}>
          {actionButtons}
        </Box>
      )}
    </Paper>
  );
};
