import { createSvgIcon } from "@mui/material";

export const ShieldGradientIcon = createSvgIcon(
  <svg viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5399 4.92148L13.3369 0.120191C12.9375 -0.0400638 12.4916 -0.0400638 12.0922 0.120191L0.889157 4.92148C0.637036 5.06353 0.423392 5.26496 0.266769 5.5083C0.0909835 5.77135 -0.00192669 6.08105 3.02905e-05 6.39743C3.02905e-05 26.4384 11.6832 31.6664 12.1811 31.8798C12.5805 32.0401 13.0264 32.0401 13.4258 31.8798C13.9238 31.6664 25.6069 26.4384 25.6069 6.39743C25.6088 6.08105 25.5159 5.77135 25.3401 5.5083C25.1588 5.25355 24.9134 5.05114 24.6288 4.92148H24.5399ZM12.7145 28.59C10.5451 27.2919 3.44984 21.9749 3.11197 7.4466L12.7145 3.33883L22.3171 7.4466C21.9792 22.0283 14.9196 27.3097 12.7145 28.59Z"
      fill="url(#paint0_linear_12308_130930)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_12308_130930"
        x1="0"
        y1="0"
        x2="31.6402"
        y2="24.0689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Security",
);
