import { createSvgIcon } from "@mui/material";

export const WindowsAuthIcon = createSvgIcon(
  <svg viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1622_35500)">
      <path d="M19.4311 0H0V19.905H19.4311V0Z" fill="#AAAAAA" />
      <path d="M41.0014 0H21.5703V19.905H41.0014V0Z" fill="#AAAAAA" />
      <path d="M19.4311 22.095H0V42H19.4311V22.095Z" fill="#AAAAAA" />
      <path d="M41.0014 22.095H21.5703V42H41.0014V22.095Z" fill="#AAAAAA" />
    </g>
    <defs>
      <clipPath id="clip0_1622_35500">
        <rect width="41" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  "Windows",
);
