import React from "react";
import { SectionHeading } from "~/components/DetailsPage/components";
import { EmptySection } from "~/components/vulnerabilities";
import { Box, Typography } from "@mui/material";
import { RemediationScripts } from "~/components/DetailsPage/components/Remediation/RemediationScripts";
import {
  AssetPackageDetailsFieldsFragment,
  useGetRemediationQuery,
} from "~/operations";
import { LoadingPage } from "~/components/loading";
import { RemediationFilterTile } from "~/components/DetailsPage/components/Remediation/RemediationFilter";

type SoftwareRemediationProps = {
  spaceMrn: string;
  software: AssetPackageDetailsFieldsFragment;
};

export const SoftwareRemediation = ({
  spaceMrn,
  software,
}: SoftwareRemediationProps) => {
  const { loading, data } = useGetRemediationQuery({
    variables: {
      input: {
        scopeMrn: spaceMrn,
        packages: [{ name: software.name, fixedVersion: software.available }],
      },
    },
  });

  if (data?.remediation?.__typename === "VulnerabilityRemediationNotFound") {
    return (
      <EmptySection
        id="software-remediation-empty"
        text="There is currently no software to remediate"
      />
    );
  }

  if (
    data?.remediation?.__typename === "VulnerabilityRemediationNotSupported"
  ) {
    return (
      <EmptySection
        id="software-remediation-unsupported"
        text="Remediation for this package is not supported"
      />
    );
  }

  if (loading) {
    return <LoadingPage what="Software remediation" />;
  }

  return (
    <Box id="remediation">
      <SectionHeading heading="Remediation" />

      <Typography textTransform="uppercase" fontWeight={700} mb={1}>
        UPDATE YOUR PACKAGES VIA COMMAND LINE
      </Typography>
      <Typography fontWeight={400} my={2} color="text.secondary">
        Update the following installed packages:
      </Typography>

      <RemediationFilterTile
        title={software.name}
        version={software.version}
        maxWidth="190px"
      />

      <RemediationScripts
        scripts={data?.remediation?.scripts || []}
        loading={loading}
      />
    </Box>
  );
};
