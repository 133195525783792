import React, { MouseEvent } from "react";
import { alpha, Button } from "@mui/material";
import { AttachIcon } from "~/components/icons";

type CreateCaseButtonProps = {
  onClick: (e: MouseEvent<HTMLElement>) => void;
};

export const CreateCaseButton = ({ onClick }: CreateCaseButtonProps) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      color="primary"
      sx={{
        background: (theme) => alpha(theme.palette.primary.main, 0.2),
        borderColor: (theme) => theme.palette.primary.main,
        color: "text.primary",
      }}
      startIcon={<AttachIcon />}
    >
      Create Case
    </Button>
  );
};
