import { ReactNode } from "react";
import { Collapse, TableCell, TableRow } from "@mui/material";

export type DetailRowProps = {
  children: ReactNode;
  colSpan: number;
  open: boolean;
  id?: string;
};

export function DetailRow({ children, colSpan, open, id }: DetailRowProps) {
  return (
    <TableRow
      id={id}
      className="detail-row"
      sx={{ borderBottom: "none", "&:hover": { backgroundColor: "unset" } }}
    >
      <TableCell colSpan={colSpan} sx={{ py: 0, maxWidth: 0 }}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </TableCell>
    </TableRow>
  );
}
