import { Space } from "~/lib/types";
import { AggregateScoreType } from "~/operations";
import { AdjustedAggregateScoreType, EntityInfoAsset } from "..";
import { PolicyMrnToURIEncodedId } from "~/lib/mrn";

export const getLinkByType = (
  type: AdjustedAggregateScoreType,
  spaceId: Space["id"],
  findingMrn: string,
  entity?: EntityInfoAsset,
) => {
  const searchParams = new URLSearchParams(document.location.search);
  const id = findingMrn.split("/").pop();
  switch (type) {
    case AggregateScoreType.Asset:
      const assetId = entity?.id;
      if (!assetId) return "";
      const link = `/space/inventory/${assetId}/vulnerabilities?spaceId=${spaceId}`;
      const riskFactorMrn = searchParams.get("riskFactorMrn");
      if (riskFactorMrn) {
        return `${link}&riskFactorMrn=${encodeURIComponent(riskFactorMrn)}`;
      }
      return link;
    case AggregateScoreType.Vulnerability:
    case "NewestRelevantVulnerabilities":
      return `/space/vulns/cve/${id}?spaceId=${spaceId}`;
    case AggregateScoreType.Advisory:
      return `/space/vulns/advisory/${id}?spaceId=${spaceId}`;
    case AggregateScoreType.Policy:
      return `/space/security/policies/${PolicyMrnToURIEncodedId(
        findingMrn,
      )}?spaceId=${spaceId}`;
    case AggregateScoreType.Check:
      return `/space/security/check?spaceId=${spaceId}&findingMrn=${findingMrn}`;
    case AggregateScoreType.Software:
      return `/space/vulnerabilities/software/${id}?spaceId=${spaceId}&genericFindingMrn=${encodeURIComponent(
        findingMrn,
      )}`;
    case AggregateScoreType.VersionedSoftware:
      return `/space/vulnerabilities/software/${id}?spaceId=${spaceId}&versionedFindingMrn=${encodeURIComponent(
        findingMrn,
      )}`;
    default:
      return "";
  }
};
