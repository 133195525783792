import { Impact as ImpactType } from "~/operations";
import { ImpactData } from "../types";
import { ImpactLabels } from "../constants";
import { useTheme } from "@mui/material";
import { useGetImpactColorUpdated } from "./impact-result-updated";

type UseImpactRatioParamsUpdated = {
  impact: ImpactType;
  adjustedColor?: string;
  isActive: boolean;
  isRiskScore?: boolean;
};

type UseImpactRatioReturnUpdated = {
  impactData: ImpactData;
  color: string;
  label: (typeof ImpactLabels)[keyof typeof ImpactLabels];
};

export function useImpactUpdated({
  impact,
  adjustedColor,
  isActive,
}: UseImpactRatioParamsUpdated): UseImpactRatioReturnUpdated {
  const theme = useTheme();

  const color = !isActive
    ? theme.palette.unknown.main
    : useGetImpactColorUpdated(impact);

  const label = getImpactLabelUpdated(impact);

  // It can be adjusted by success status, skipped/disabled/errored states
  const checkColor = adjustedColor || color;

  function getImpactData(impact: ImpactType): ImpactData {
    const { rating } = impact;

    let normalizedRating = rating.toLowerCase();

    if (normalizedRating === "none") {
      return {
        rating: 1,
      };
    } else if (normalizedRating === "low") {
      return {
        rating: 2,
      };
    } else if (normalizedRating === "medium") {
      return {
        rating: 3,
      };
    } else if (normalizedRating === "high") {
      return {
        rating: 4,
      };
    } else {
      return {
        rating: 5,
      };
    }
  }

  return {
    impactData: getImpactData(impact),
    color: checkColor,
    label,
  };
}

export function getImpactLabelUpdated(
  impact: ImpactType,
): (typeof ImpactLabels)[keyof typeof ImpactLabels] {
  const { rating } = impact;
  let normalizedRating = rating.toLowerCase();

  if (normalizedRating === "none") {
    return ImpactLabels.NONE;
  } else if (normalizedRating === "low") {
    return ImpactLabels.LOW;
  } else if (normalizedRating === "medium") {
    return ImpactLabels.MEDIUM;
  } else if (normalizedRating === "high") {
    return ImpactLabels.HIGH;
  } else {
    return ImpactLabels.Critical;
  }
}
