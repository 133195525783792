import { Box, Link, Typography } from "@mui/material";
import { OpenInNewIcon } from "~/components/icons";
import { DynamicIcon } from "~/components/icons/DynamicIcon";
import { Icon_Ids } from "~/operations";

export type SourceLink = {
  href: string;
  text: string;
  icon?: Icon_Ids | null;
};

export type SourcesProps = {
  links: SourceLink[];
  id?: string;
};

export function Sources({ links, id }: SourcesProps) {
  return (
    <Box id={id}>
      <Typography textTransform="uppercase" fontWeight={700} mb={1}>
        Sources
      </Typography>
      <Box component="ul" sx={{ pl: 0, listStyle: "none" }}>
        {links.map((link, i) => (
          <Box
            component="li"
            key={i}
            display="flex"
            alignItems="center"
            gap={1}
          >
            {link.icon && <DynamicIcon iconId={link.icon} />}
            <Link
              href={link.href}
              target="_blank"
              fontWeight={600}
              lineHeight={2}
              color="secondary.light"
              display="inline-flex"
              alignItems="center"
              gap={1}
            >
              {link.text} <OpenInNewIcon fontSize="inherit" />
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
