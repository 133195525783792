import { ReactElement } from "react";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { SelectionToolbar } from "~/components/data-table";
import {
  AttachIcon,
  EditIcon,
  EditOffIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from "~/components/icons";
import { ExceptionTarget, SelectedEntity } from "~/components/exceptions/types";
import { useExceptionsToolbar } from "~/components/exceptions/use-exceptions-toolbar";
import { pluralize } from "~/lib/pluralize";

type ExceptionsToolbarProps = {
  onSetExceptionClick?: () => void;
  onRemoveExceptionClick?: () => void;
  onSetScopeClick?: (isInScope: boolean) => void;
  onCancel: () => void;
  onCreateCaseClick?: () => void;
  onDeleteCaseClick?: () => void;
  selectedEntities: SelectedEntity[];
  totalCount: number;
  target: ExceptionTarget;
};

const TooltipWrapper = ({
  children,
  hideTooltip,
  title,
  key,
}: {
  children: ReactElement;
  hideTooltip: boolean;
  title: string;
  key: string;
}) =>
  hideTooltip ? (
    children
  ) : (
    <Tooltip key={key} arrow placement="top" title={title}>
      {children}
    </Tooltip>
  );

export const ExceptionsToolbar = ({
  onSetExceptionClick,
  onRemoveExceptionClick,
  onSetScopeClick,
  onCancel,
  onCreateCaseClick,
  selectedEntities,
  totalCount,
  target,
}: ExceptionsToolbarProps) => {
  const {
    exceptionsButtonsAvailability,
    exceptionsButtonsVisibility,
    exceptionsButtonsTooltipText,
  } = useExceptionsToolbar({
    selectedEntities,
    showScopeButtons: !!onSetScopeClick,
    showExceptionButtons: !!onSetExceptionClick,
    target,
  });

  return (
    <SelectionToolbar>
      <Typography>
        Selected {selectedEntities.length} of {totalCount}{" "}
        {pluralize(target, totalCount)}
      </Typography>
      {onCreateCaseClick && (
        <Button
          data-testid="toolbar-create-case"
          variant="contained"
          color="primary"
          onClick={() => onCreateCaseClick?.()}
          startIcon={
            <AttachIcon sx={{ fontSize: (theme) => theme.spacing(2.25) }} />
          }
        >
          Create Case
        </Button>
      )}
      {exceptionsButtonsVisibility.SET_OUT_OF_SCOPE && (
        <TooltipWrapper
          hideTooltip={exceptionsButtonsAvailability.SET_OUT_OF_SCOPE}
          key="SET_OUT_OF_SCOPE"
          title={exceptionsButtonsTooltipText.SET_OUT_OF_SCOPE}
        >
          <Box>
            <Button
              data-test-id="toolbar-set-out-of-scope"
              variant="contained"
              color="primary"
              onClick={() => onSetScopeClick?.(false)}
              disabled={!exceptionsButtonsAvailability.SET_OUT_OF_SCOPE}
              startIcon={
                <VisibilityOffIcon
                  sx={{ fontSize: (theme) => theme.spacing(2.25) }}
                />
              }
            >
              Set Out Of Scope
            </Button>
          </Box>
        </TooltipWrapper>
      )}
      {exceptionsButtonsVisibility.SET_IN_SCOPE && (
        <TooltipWrapper
          hideTooltip={exceptionsButtonsAvailability.SET_IN_SCOPE}
          key="SET_IN_SCOPE"
          title={exceptionsButtonsTooltipText.SET_IN_SCOPE}
        >
          <Box>
            <Button
              data-test-id="toolbar-set-in-scope"
              variant="contained"
              color="primary"
              onClick={() => onSetScopeClick?.(true)}
              disabled={!exceptionsButtonsAvailability.SET_IN_SCOPE}
              startIcon={
                <VisibilityIcon
                  sx={{ fontSize: (theme) => theme.spacing(2.25) }}
                />
              }
            >
              Set In Scope
            </Button>
          </Box>
        </TooltipWrapper>
      )}
      {exceptionsButtonsVisibility.SET_EXCEPTION && (
        <TooltipWrapper
          hideTooltip={exceptionsButtonsAvailability.SET_EXCEPTION}
          key="SET_EXCEPTION"
          title={exceptionsButtonsTooltipText.SET_EXCEPTION}
        >
          <Box>
            <Button
              data-test-id="toolbar-set-exception"
              variant="contained"
              color="primary"
              onClick={onSetExceptionClick}
              disabled={!exceptionsButtonsAvailability.SET_EXCEPTION}
              startIcon={
                <EditIcon sx={{ fontSize: (theme) => theme.spacing(2.25) }} />
              }
            >
              Set Exception
            </Button>
          </Box>
        </TooltipWrapper>
      )}
      {exceptionsButtonsVisibility.REMOVE_EXCEPTION && (
        <TooltipWrapper
          hideTooltip={exceptionsButtonsAvailability.REMOVE_EXCEPTION}
          key="REMOVE_EXCEPTION"
          title={exceptionsButtonsTooltipText.REMOVE_EXCEPTION}
        >
          <Box>
            <Button
              data-test-id="toolbar-remove-exception"
              variant="contained"
              color="primary"
              onClick={onRemoveExceptionClick}
              disabled={!exceptionsButtonsAvailability.REMOVE_EXCEPTION}
              startIcon={
                <EditOffIcon
                  sx={{ fontSize: (theme) => theme.spacing(2.25) }}
                />
              }
            >
              Remove Exception and Enable
            </Button>
          </Box>
        </TooltipWrapper>
      )}
      <Button onClick={onCancel}>Cancel</Button>
    </SelectionToolbar>
  );
};
