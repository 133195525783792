import { createSvgIcon } from "@mui/material";

export const OthersGradientIcon = createSvgIcon(
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.1245 4.00029C15.1238 4.00106 15.123 4.00183 15.1223 4.0026C14.8445 4.03105 14.5869 4.16382 14.3998 4.37528C14.2125 4.58675 14.109 4.86152 14.1093 5.14655V14.8776C14.1105 15.1821 14.2301 15.4738 14.442 15.6883C14.6537 15.9028 14.9405 16.0225 15.2388 16.0215H18.8752V18.8518H7.88874C7.85284 18.85 7.81692 18.85 7.78101 18.8518C7.50303 18.8797 7.24488 19.0122 7.05728 19.2237C6.86944 19.4352 6.76573 19.7104 6.76599 19.9957V23.9723L3.12077 23.972C2.8237 23.9733 2.53943 24.0943 2.32948 24.3086C2.11954 24.5229 2.00126 24.813 2.00001 25.116V34.847C1.99875 35.1515 2.11628 35.4443 2.32646 35.6604C2.53664 35.8765 2.82216 35.9987 3.12072 36H12.6543C12.9542 36.001 13.2422 35.88 13.4541 35.6634C13.6661 35.4471 13.7848 35.1531 13.7836 34.8471V25.1161C13.7823 24.8116 13.6628 24.5199 13.4511 24.3054C13.2392 24.0908 12.9526 23.9711 12.6543 23.9721H9.01788V21.1509H30.9844V23.9721H27.3455C27.0472 23.9711 26.7607 24.0908 26.5487 24.3054C26.337 24.5199 26.2175 24.8116 26.2162 25.1161V34.8471C26.215 35.1531 26.3337 35.4471 26.5457 35.6634C26.7576 35.88 27.0457 36.001 27.3455 36H36.8707C37.1706 36.001 37.4584 35.88 37.6705 35.6634C37.8825 35.4471 38.0012 35.1531 38 34.8471V25.1161C37.9987 24.8116 37.8792 24.5199 37.6673 24.3054C37.4556 24.0908 37.169 23.9711 36.8707 23.9721H33.2343V19.9956L33.234 19.9959C33.233 19.6929 33.1145 19.4025 32.9046 19.1882C32.6946 18.9742 32.4104 18.8532 32.1135 18.8519H21.1251V16.0216H24.7638C25.0621 16.0226 25.3487 15.9029 25.5606 15.6884C25.7723 15.4739 25.8921 15.1822 25.8934 14.8777V5.14666C25.8921 4.84216 25.7723 4.55047 25.5606 4.33596C25.3487 4.12142 25.0622 4.00172 24.7638 4.00275H15.2385C15.2004 4.00018 15.1625 3.99941 15.1243 4.00044L15.1245 4.00029Z"
      fill="url(#paint0_linear_11456_106204)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_11456_106204"
        x1="2"
        y1="4"
        x2="34.7572"
        y2="39.0322"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Others",
);
