import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { breadcrumbsIntegration, init as sentryInit } from "@sentry/react";
import { AuthProvider, useAuth } from "./login/provider";
import { GraphqlProvider } from "./providers/graphql";
import { ViewerProvider } from "./providers/viewer";
import { MondooThemeProvider } from "./providers/mondoo-theme";
import { ColorModeProvider } from "./providers/color-mode";
import { IncidentInfo } from "./components/incident-info";
import { GlobalWords } from "./lib/global.words";

import AuthenticatedApp from "./authenticatedApp/authenticatedApp";
import UnauthenticatedApp, {
  isUnauthRoute,
} from "./unauthenticatedApp/unauthenticatedApp";
import { Config } from "./configuration_provider";
import { AppNavDrawerProvider } from "./providers/app-nav-drawer";

const isLocalhost = window.location.hostname === "localhost";
const sentryDsn = Config.VITE_SENTRY_DSN || "";
const sentryEnv = Config.VITE_SENTRY_ENVIRONMENT || "";
const sentryIsEnabled = Config.VITE_SENTRY_ENABLE === "true";
const sentryHasConfig = sentryDsn.length > 0 && sentryEnv.length > 0;
const sentryCanInit = sentryIsEnabled && sentryHasConfig && !isLocalhost;

// initialize sentry
if (sentryCanInit) {
  try {
    sentryInit({
      dsn: sentryDsn,
      environment: sentryEnv,
      integrations: [
        breadcrumbsIntegration({
          dom: { serializeAttribute: ["data-testid", "id", "class"] },
        }),
      ],
    });
    console.log("Sentry initialized");
  } catch (error) {
    console.error("Sentry failed to initialize:", error);
  }
}

// initialize GlobalWords
GlobalWords.enable();

// Load app
const App = () => {
  const { state } = useAuth();

  const loggedIn = state.user?.emailVerified;
  const currentUrl = new URL(window.location.href);

  // When signing up for the first time, we are checking to see if the user
  // has selected the box to opt into email updates
  let emailOptIn = currentUrl.searchParams.get("emailOptIn");
  if (!emailOptIn) {
    emailOptIn = localStorage.getItem("emailOptIn");
  }
  if (!loggedIn && emailOptIn) {
    localStorage.setItem("mondoo_emailOptIn", emailOptIn);
  }

  let redirectTo = currentUrl.searchParams.get("redirectTo");

  if (!redirectTo) {
    redirectTo = localStorage.getItem("redirectTo");
  }

  // If not logged in and not navigating to an UnauthenticatedApp route
  if (!loggedIn && !isUnauthRoute(currentUrl)) {
    // Redirect to login and remember the original destination url
    const href = currentUrl.pathname + currentUrl.search;
    localStorage.setItem("redirectTo", href);
    window.location.href = `/login?redirectTo=${href}`;
  }

  // If not logged in and navigating to an UnauthenticatedApp route
  // we want to clear out the redirectTo param otherwise the user will
  // end up on the dashboard after logging in
  if (!loggedIn && isUnauthRoute(currentUrl)) {
    localStorage.setItem("redirectTo", "/");
  }

  // If logged in and current url contains a redirectTo search param
  if (loggedIn && redirectTo) {
    try {
      // check that URL is relative, if not relative - function will throw error
      // into catch block
      isRelativeUrl(redirectTo);
      // Redirect to the destination url specified by redirectTo
      const { pathname, search } = new URL(window.location.origin + redirectTo);
      window.location.href = pathname + search;
    } catch {
      // redirectTo param was malformed so just redirect to root path
      window.location.href = "/";
    } finally {
      localStorage.removeItem("redirectTo");
    }
  }

  return loggedIn ? (
    <GraphqlProvider>
      <BrowserRouter>
        <ViewerProvider>
          <ColorModeProvider>
            <AppNavDrawerProvider>
              <MondooThemeProvider loggedIn={loggedIn}>
                <AuthenticatedApp />
              </MondooThemeProvider>
            </AppNavDrawerProvider>
          </ColorModeProvider>
        </ViewerProvider>
      </BrowserRouter>
    </GraphqlProvider>
  ) : (
    <BrowserRouter>
      <IncidentInfo />
      <UnauthenticatedApp />
    </BrowserRouter>
  );
};

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <AuthProvider>
      <MondooThemeProvider>
        <App />
      </MondooThemeProvider>
    </AuthProvider>,
  );
} else {
  throw new Error("Root container element is not defined");
}

// Check if the URL provided is relative
const isRelativeUrl = (urlToTest: string): boolean => {
  const outcome =
    new URL(document.baseURI).origin ===
    new URL(urlToTest, document.baseURI).origin;

  if (outcome === false) {
    throw new Error();
  }

  return true;
};
