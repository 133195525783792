import { MajorScore, Score } from "~/lib/types";
import { Status } from "~/components/scores/mondoo-score-card";

///////////////////////////
// IMPORTANT SCORE INFORMATION
// score:
// A 80  ..  100 (100 A+ 95 A 85 A- 80)
// B 60  ..   79 ( 79 B+ 75 B 65 B- 60)
// C 30  ..   59 ( 59 C+ 50 C 40 C- 30)
// D 10  ..   29 ( 29 D+ 25 D 15 D- 10)
// F 0   ..    9
////////////////////////////
// SCORE TYPES
// Unknown: 0
// Scored: 2
// Error: 4
// Skip: 8
// Unscored: 16

export const ScoreType = (score: number | null, unscored: boolean = false) => {
  return score == null || unscored
    ? "unknown"
    : score >= 80
      ? "none"
      : score >= 60
        ? "low"
        : score >= 30
          ? "medium"
          : score >= 10
            ? "high"
            : "critical";
};

export const CvssScoreType = (
  score: number | null,
  affected: boolean = true,
  unscored: boolean = false,
): Status => {
  if (unscored) return "unknown";
  if (!affected) return "good";
  return score == null
    ? "unknown"
    : score >= 90
      ? "critical"
      : score >= 70
        ? "high"
        : score >= 40
          ? "medium"
          : score >= 1
            ? "low"
            : "none";
};

export const Score2CvssScore = (
  score: number | null | undefined,
  affected: boolean = true,
): string | null => {
  if (!affected) return "ok";
  if (score == null) return null;

  return ((score || 0) / 10.0).toFixed(1);
};

export const getMajorScore = (score: Score): MajorScore => {
  if (score.type === 0) return "U";
  if (score.type === 4) return "X";
  if (score.type === 8) return "U";
  if (score.type === 16) return "U";
  if (score.value >= 80) return "A";
  if (score.value >= 60) return "B";
  if (score.value >= 30) return "C";
  if (score.value >= 10) return "D";
  return "F";
};

export const getMajorScoreByValue = (scoreValue: number): MajorScore => {
  if (scoreValue >= 80) return "A";
  if (scoreValue >= 60) return "B";
  if (scoreValue >= 30) return "C";
  if (scoreValue >= 10) return "D";
  return "F";
};

export const ScoreStatus = (score: Score): string => {
  let major = getMajorScore(score);
  return rating2statusMap[major];
};

export const rating2statusMap = {
  A: "excellent",
  B: "low",
  C: "medium",
  D: "high",
  F: "critical",
  U: "unknown",
  X: "error",
};

export const filterRanges = {
  a: [80, 100],
  b: [60, 79],
  c: [30, 59],
  d: [10, 29],
  f: [0, 9],
  u: [-1, -1],
} as const;

export type FilterRangesKeys = keyof typeof filterRanges;

export const stateFromScore = (score: Score) => {
  if (score == null) {
    return 0;
  }
  // FAIL
  if (score.type == 2 && score.value != 100) {
    return 3;
    // PASS
  } else if (score.type == 2 && score.value == 100) {
    return 1;
    // ERROR
  } else if (score.type == 4) {
    return 4;
    // SKIP
  } else if (score.type == 8) {
    return 2;
    // SKIP
  } else if (score.type == 16) {
    return 2;
  }

  return 0;
};
