import React, { useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { useForm } from "react-hook-form";
import { Space } from "~/lib/types";
import { TicketIntegration } from "~/components/cases/components/CaseSettings/types";
import { ZendeskFormInput } from "~/pages/integrations/hosted-integrations/forms/zendesk/types";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";

type CaseSettingsIntegrationProps = {
  integration: TicketIntegration;
  space: Space;
  showAutoCreateSection: boolean;
};

export const CaseSettingsZendeskIntegration = ({
  integration,
  space,
  showAutoCreateSection,
}: CaseSettingsIntegrationProps) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { control, handleSubmit, reset, watch } = useForm<ZendeskFormInput>({
    defaultValues: {
      autoCloseTickets: false,
      autoCreateCases: false,
    },
    mode: "onChange",
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const onSubmit = async (data: ZendeskFormInput) => {
    const zendeskConfigurationOptions = {
      subdomain: data.subdomain.trim(),
      email: data.email.trim(),
      apiToken: "",
      autoCloseTickets: data.autoCloseTickets,
      autoCreateTickets: data.autoCreateCases,
    };

    await updateIntegration({
      variables: {
        input: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
          type: ClientIntegrationType.TicketSystemZendesk,
          configurationOptions: {
            zendeskConfigurationOptions,
          },
        },
      },
    });
  };

  useEffect(() => {
    // TypeScript users
    const subscription = watch(() => {
      isInitialized && handleSubmit(onSubmit)();
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, isInitialized]);

  useEffect(() => {
    const configOptions = integration?.configurationOptions;
    if (configOptions?.__typename !== "ZendeskConfigurationOptions") return;
    reset({
      subdomain: configOptions.subdomain,
      email: configOptions.email,
      autoCloseTickets: configOptions.autoCloseTickets,
      autoCreateCases: configOptions.autoCreateTickets,
    });
    setIsInitialized(true);
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <AutoConfigurationSection
          control={control}
          showAutoCreateSection={showAutoCreateSection}
        />
      </Paper>
    </Box>
  );
};
