import React from "react";
import { Grid, Typography } from "@mui/material";

export const Title = () => {
  return (
    <Grid item xs={12}>
      <Typography
        variant="h4"
        fontWeight={700}
        sx={{ mb: 3, textTransform: "uppercase" }}
      >
        Cases
      </Typography>
    </Grid>
  );
};
