import { Paper, Typography, Divider } from "@mui/material";
import { useSnackbar } from "notistack";
import { LoadingButton } from "~/components/loading-button";
import {
  LoadViewerDocument,
  useActivateUserInRegionMutation,
} from "~/operations";

export function SetupRegion() {
  const { enqueueSnackbar } = useSnackbar();
  const [activateUserInRegion, { loading }] = useActivateUserInRegionMutation({
    refetchQueries: [LoadViewerDocument],
  });

  const handleEnableClick = async () => {
    try {
      await activateUserInRegion();
    } catch (error) {
      enqueueSnackbar("Failed to enable region", { variant: "error" });
    }
  };

  return (
    <Paper
      id="enable-region"
      sx={{
        my: 3,
        mx: "auto",
        p: 3,
        textAlign: "center",
        width: "95%",
        maxWidth: 500,
      }}
    >
      <Typography variant="h4" mb={3}>
        Enable Region
      </Typography>
      <Typography variant="body1" mb={2}>
        The selected region is not enabled.
      </Typography>

      <Divider />

      <LoadingButton
        type="submit"
        variant="contained"
        color="primary"
        buttonText="Enable"
        loading={loading}
        disabled={loading}
        onClick={handleEnableClick}
        sx={{ mt: 2 }}
      />
    </Paper>
  );
}
