import { Fragment } from "react";
import { Box, DialogContent, DialogActions, Button, Link } from "@mui/material";
import {
  DialogContainer,
  InfoText,
  TitleArea,
  Command,
  GradientButton,
  TitleAreaProps,
} from "../components";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  OpenInNewIcon,
} from "~/components/icons";

type Props = {
  onClose: TitleAreaProps["onClose"];
  nav: {
    next: () => void;
    prev: () => void;
  };
};

export const StepThree = ({ nav, onClose }: Props) => {
  return (
    <DialogContainer>
      <TitleArea title="AWS Setup" onClose={onClose} />

      <DialogContent sx={{ p: 0, mt: 4 }}>
        <Box display="flex" flexDirection="column" height={1}>
          <Box>
            <Command
              number={2}
              tooltip={
                <Link
                  href="https://mondoo.com/docs/cnspec/cnspec-aws/cnspec-aws-cloudshell/#step-2-launch-cloudshell"
                  target="_blank"
                  rel="noopener"
                >
                  Mondoo Docs - Assess AWS Security from AWS CloudShell
                </Link>
              }
            >
              Open AWS CloudShell (and come back here!)
            </Command>
          </Box>
          <Box
            sx={{
              display: "flex",
              flex: "1",
              alignItems: "center",
              justifyContent: "center",
              py: 0,
            }}
          >
            <GradientButton
              endIcon={<OpenInNewIcon />}
              href={`https://console.aws.amazon.com/cloudshell/home`}
              target="_blank"
            >
              Open AWS CloudShell
            </GradientButton>
          </Box>
        </Box>
      </DialogContent>

      <InfoText
        text={
          <Fragment>
            You can also run scans of AWS from your local workstation. To learn
            more, read{" "}
            <Link
              href="https://mondoo.com/docs/cnspec/cloud/aws/"
              target="_blank"
              rel="noopener"
            >
              Scan AWS Accounts and EC2 Instances from Your Workstation
            </Link>{" "}
            for more information.
          </Fragment>
        }
      />
      <DialogActions sx={{ p: 0, pt: 0, justifyContent: "space-between" }}>
        <Button
          onClick={nav.prev}
          variant="outlined"
          color="secondary"
          startIcon={<ChevronLeftIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Back
        </Button>
        <Button
          onClick={nav.next}
          variant="contained"
          color="secondary"
          endIcon={<ChevronRightIcon />}
          size="large"
          sx={{
            textTransform: "none",
            fontWeight: 600,
            px: 3,
            py: 2,
          }}
        >
          Next step
        </Button>
      </DialogActions>
    </DialogContainer>
  );
};
