import { createSvgIcon } from "@mui/material";

export const RedHatIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.6821 13.5954C17.1272 13.5954 19.2197 13.2948 19.2197 11.5723C19.2196 11.4405 19.208 11.3089 19.185 11.1792L18.3295 7.43353C18.1329 6.61272 17.9595 6.23121 16.5145 5.51445C15.3931 4.93642 12.9538 4 12.2254 4C11.5434 4 11.3468 4.86705 10.5491 4.86705C9.77457 4.86705 9.20809 4.21965 8.47977 4.21965C7.78613 4.21965 7.33526 4.69364 6.98844 5.66474C6.98844 5.66474 6.01734 8.40462 5.89017 8.80925C5.87107 8.88081 5.86327 8.95493 5.86705 9.0289C5.85549 10.0925 10.052 13.5838 15.6821 13.5954ZM19.4393 12.2775C19.6358 13.2254 19.6358 13.3295 19.6358 13.4451C19.6358 15.0636 17.8208 15.9653 15.4277 15.9653C10.0173 15.9653 5.28902 12.7977 5.28902 10.7052C5.28849 10.415 5.34751 10.1278 5.46243 9.86127C3.52023 9.95376 1 10.3006 1 12.5202C1 16.1618 9.62428 20.6474 16.4451 20.6474C21.6821 20.6474 23 18.2775 23 16.4046C23 14.9364 21.7283 13.2717 19.4393 12.2775Z"
      fill="#EE0000"
    />
    <path
      d="M19.4394 12.2775C19.6359 13.2255 19.6359 13.3295 19.6359 13.4451C19.6359 15.0636 17.8209 15.9653 15.4278 15.9653C10.0174 15.9653 5.28907 12.7977 5.28907 10.7052C5.28854 10.415 5.34756 10.1278 5.46248 9.86129L5.89022 8.80927C5.87112 8.88083 5.86332 8.95494 5.8671 9.02892C5.8671 10.0925 10.0636 13.5838 15.6821 13.5838C17.1272 13.5838 19.2197 13.2833 19.2197 11.5607C19.2196 11.4289 19.208 11.2974 19.185 11.1676L19.4394 12.2775Z"
      fill="black"
    />
  </svg>,
  "Red Hat",
);
