import { Box, Button, Typography } from "@mui/material";
import { ArrowForwardIcon } from "~/components/icons";
import { NoPermissionsIcon } from "~/components/icons/mondoo";
import { EmptyState } from "~/pages/inventory/components/EmptyState";

export function ServiceAccountNoPermissions() {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          alignItems: { xs: "flex-start", sm: "center" },
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Typography variant="h5" component="h2" fontWeight={700}>
          Service Accounts
        </Typography>
        <Button disabled endIcon={<ArrowForwardIcon />}>
          Show In Service Accounts
        </Button>
      </Box>
      <EmptyState
        headline="Additional privileges required"
        icon={<NoPermissionsIcon sx={{ fontSize: 48 }} />}
        textContent=" Please contact an administrator."
      />
    </Box>
  );
}
