import { Flex } from "~/components/ui-library";
import { CreateTicketingIntegrationFirstStep } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationFirstStep";
import { Typography } from "@mui/material";
import { CreateTicketingIntegrationSecondStep } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationSecondStep";
import { CreateTicketingIntegrationThirdStep } from "~/components/cases/components/CasesPage/components/CreateTicketingIntegration/CreateTicketingIntegrationThirdStep";
import React from "react";

export const CreateTicketingIntegrationContent = () => {
  return (
    <>
      <Flex flexDirection="column" maxWidth={232}>
        <CreateTicketingIntegrationFirstStep
          sx={{ width: "100%", height: 144 }}
        />
        <Typography
          sx={{
            color: (theme) => theme.palette.text.secondary,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Set up an integration with the ticketing system of your choice.
        </Typography>
      </Flex>
      <Flex flexDirection="column" maxWidth={232}>
        <CreateTicketingIntegrationSecondStep
          sx={{ width: "100%", height: 144 }}
        />
        <Typography
          sx={{
            color: (theme) => theme.palette.text.secondary,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          Create cases from any findings within Mondoo.
        </Typography>
      </Flex>
      <Flex flexDirection="column" maxWidth={232}>
        <CreateTicketingIntegrationThirdStep
          sx={{ width: "100%", height: 144 }}
        />
        <Typography
          sx={{
            color: (theme) => theme.palette.text.secondary,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          As you fix issues, seamlessly sync progress between Mondoo and your
          ticketing system.
        </Typography>
      </Flex>
    </>
  );
};
