import React from "react";
import { NavDrawerVulnerabilitiesIcon } from "~/components/icons";
import { Button } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { EmptyState } from "~/pages/inventory/components/EmptyState/EmptyState";
import { Space } from "~/lib/types";

type DisabledScanningProps = {
  space: Space;
};

export const DisabledScanning = ({ space }: DisabledScanningProps) => {
  return (
    <EmptyState
      headline="Vulnerability scanning is disabled"
      icon={
        <NavDrawerVulnerabilitiesIcon
          sx={{ fontSize: 38, color: "text.disabled" }}
        />
      }
      textContent="An admin has disabled vulnerability scanning in the space settings. You can turn it back on there."
      actionButtons={
        <Button
          variant="outlined"
          component={RouterLink}
          to={`/space/settings/?spaceId=${space.id}`}
          sx={{
            mx: 1,
            borderColor: "secondary.light",
            color: "secondary.light",
          }}
        >
          GO TO SPACE SETTINGS
        </Button>
      }
    />
  );
};
