import { createSvgIcon } from "@mui/material";

export const NavDrawerOrganizationsIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 6.26001C12.5491 6.26004 13.0858 6.42328 13.542 6.729C13.9982 7.03472 14.3531 7.46912 14.5619 7.97702C14.7706 8.48492 14.8237 9.04341 14.7144 9.58154C14.605 10.1197 14.3382 10.6132 13.9478 10.9993C13.5574 11.3855 13.0611 11.6469 12.5218 11.7504C11.9825 11.8539 11.4246 11.7947 10.919 11.5804C10.4134 11.3662 9.98291 11.0065 9.68218 10.547C9.38144 10.0876 9.22405 9.5491 9.23 9.00001C9.2379 8.27057 9.53322 7.57369 10.0518 7.06069C10.5705 6.54768 11.2705 6.25997 12 6.26001ZM6.46 8.26001C6.88552 8.26033 7.30324 8.37426 7.67 8.59001C7.55212 9.71216 7.87083 10.8366 8.56 11.73C8.36098 12.1146 8.05953 12.4368 7.68895 12.6609C7.31837 12.885 6.89307 13.0024 6.46 13C6.13515 13.0211 5.80943 12.9753 5.50297 12.8655C5.19652 12.7557 4.91585 12.5842 4.67832 12.3616C4.4408 12.139 4.25146 11.87 4.12203 11.5713C3.99259 11.2726 3.92581 10.9505 3.92581 10.625C3.92581 10.2995 3.99259 9.9774 4.12203 9.67871C4.25146 9.38001 4.4408 9.11104 4.67832 8.88844C4.91585 8.66583 5.19652 8.49432 5.50297 8.38451C5.80943 8.27469 6.13515 8.22892 6.46 8.25001M17.54 8.25001C17.8649 8.22892 18.1906 8.27469 18.497 8.38451C18.8035 8.49432 19.0842 8.66583 19.3217 8.88844C19.5592 9.11104 19.7485 9.38001 19.878 9.67871C20.0074 9.9774 20.0742 10.2995 20.0742 10.625C20.0742 10.9505 20.0074 11.2726 19.878 11.5713C19.7485 11.87 19.5592 12.139 19.3217 12.3616C19.0842 12.5842 18.8035 12.7557 18.497 12.8655C18.1906 12.9753 17.8649 13.0211 17.54 13C17.1059 13.0014 16.6799 12.8826 16.3092 12.6567C15.9385 12.4307 15.6377 12.1065 15.44 11.72C16.1292 10.8266 16.4479 9.70216 16.33 8.58001C16.6968 8.36426 17.1145 8.25033 17.54 8.25001ZM6.85 16.35C6.85 14.72 9.16 13.35 12 13.35C14.84 13.35 17.15 14.68 17.15 16.35V17.74H6.85V16.35ZM2.5 17.74V16.55C2.5 15.45 4 14.55 6.02 14.26C5.52933 14.8453 5.26341 15.5863 5.27 16.35V17.74H2.5ZM21.5 17.74H18.73V16.35C18.7422 15.5888 18.4835 14.8481 18 14.26C20 14.53 21.52 15.45 21.52 16.55L21.5 17.74Z" />
  </svg>,
  "Organizations",
);
