import { createSvgIcon } from "@mui/material";

export const WifiGradientIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
    <path
      d="M2 17.9995L6 21.9995C15.94 12.0595 32.06 12.0595 42 21.9995L46 17.9995C33.86 5.85953 14.16 5.85953 2 17.9995ZM18 33.9995L24 39.9995L30 33.9995C26.7 30.6795 21.32 30.6795 18 33.9995ZM10 25.9995L14 29.9995C19.52 24.4795 28.48 24.4795 34 29.9995L38 25.9995C30.28 18.2795 17.74 18.2795 10 25.9995Z"
      fill="url(#paint0_linear_256_11527)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_256_11527"
        x1="2"
        y1="8.89453"
        x2="32.5624"
        y2="49.9924"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Network Security",
);
