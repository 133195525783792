import { Box, Grid, Paper, Tooltip, Typography, useTheme } from "@mui/material";
import { Flex, Text } from "~/components/ui-library";

type Framework = {
  progress: number;
};

type CompletionProps = Framework;

export function CompletionV2({ progress }: CompletionProps) {
  const theme = useTheme();

  const getGradient = () => {
    if (progress >= 91) {
      return {
        from: theme.palette.none.main,
        to: theme.palette.none.light,
      };
    } else if (progress >= 71) {
      return {
        from: theme.palette.low.main,
        to: theme.palette.low.light,
      };
    } else if (progress >= 41) {
      return {
        from: theme.palette.medium.main,
        to: theme.palette.medium.light,
      };
    } else if (progress >= 1) {
      return {
        from: theme.palette.high.main,
        to: theme.palette.high.light,
      };
    } else {
      return {
        from: theme.palette.critical.main,
        to: theme.palette.critical.light,
      };
    }
  };

  const gradient = getGradient();
  // if there is no progress to show, we need to set the border to none
  // otherwise it appears as a small strip of color on the left side
  const border = progress < 1 ? "none" : `1px solid ${getGradient().to}`;

  return (
    <Grid container item sx={{ position: "relative", height: "auto" }}>
      <Paper
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          p: 2,
          minWidth: 0,
          width: 1,
        }}
      >
        <Flex gap={2}>
          <Flex flexDirection="column" flex="1 0 auto" justifyContent="center">
            <Flex alignItems="center" justifyContent="space-between" mb={1}>
              <Text fontWeight={900} textTransform="uppercase">
                COMPLETION
              </Text>
            </Flex>
            <Tooltip title={`Completion: ${progress}%`} placement="top" arrow>
              <Flex
                sx={{
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                <Box
                  sx={(theme) => ({
                    position: "relative",
                    height: theme.spacing(2),
                    flex: "1 0 auto",
                  })}
                >
                  <Box
                    sx={(theme) => ({
                      position: "absolute",
                      height: theme.spacing(1),
                      width: 1,
                      backgroundColor: "background.default",
                      borderRadius: 0.5,
                    })}
                  />
                  <Box
                    sx={(theme) => ({
                      position: "absolute",
                      height: theme.spacing(1),
                      width: `${progress}%`,
                      background: `linear-gradient(270deg, ${gradient.from} 0%, ${gradient.to} 100%)`,
                      borderRadius: 0.5,
                      border,
                    })}
                  />
                </Box>
              </Flex>
            </Tooltip>
          </Flex>
          <Flex alignItems="baseline">
            <Typography
              sx={{ color: gradient.to }}
              fontSize="40px"
              fontWeight={700}
            >
              {progress}
            </Typography>
            <Typography fontWeight={700} sx={{ color: gradient.to }}>
              %
            </Typography>
          </Flex>
        </Flex>
      </Paper>
    </Grid>
  );
}
