import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { PrintAsset } from "~/components/print/print-asset";
import { Box } from "@mui/material";
import { LoadingPage } from "~/components/loading";

export function Print() {
  const navigate = useNavigate();
  function render() {
    const location = window.location;
    if (location == null) return <LoadingPage what="print view" />;

    const { searchParams } = new URL(window.location.href);
    const what = searchParams.get("what");
    const assetMrn = searchParams.get("assetMrn");
    let body = <></>;
    switch (what) {
      case "asset":
        if (assetMrn) {
          body = <PrintAsset assetMrn={assetMrn} />;
        }

        break;
      default:
        // FIXME: show an error view here
        navigate(window.location.origin);
    }

    return <PrintBox>{body}</PrintBox>;
  }
  return render();
}

export type PrintProps = {};

const PrintBox = styled(Box)`
  width: 100%;
  height: 100%;
  // padding: ${(p) => p.theme.spacing(4)};

  .MuiCardContent-root {
    padding: ${(p) => p.theme.spacing(2, 6)};
  }

  @media print {
    break-after: page;
  }

  @page {
    size: auto;
    margin: 10mm;
  }
`;
