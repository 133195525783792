import { createSvgIcon } from "@mui/material";

export const SensitiveDataIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.11 9.1093V6.0293C16.11 4.96843 15.6886 3.95102 14.9384 3.20087C14.1883 2.45072 13.1709 2.0293 12.11 2.0293C11.0491 2.0293 10.0317 2.45072 9.28157 3.20087C8.53143 3.95102 8.11 4.96843 8.11 6.0293V9.1093C6.85319 9.96454 5.90359 11.2001 5.40055 12.6347C4.89751 14.0692 4.86751 15.6272 5.31495 17.0801C5.76239 18.533 6.66372 19.8042 7.88667 20.7072C9.10962 21.6102 10.5898 22.0974 12.11 22.0974C13.6302 22.0974 15.1104 21.6102 16.3333 20.7072C17.5563 19.8042 18.4576 18.533 18.9051 17.0801C19.3525 15.6272 19.3225 14.0692 18.8194 12.6347C18.3164 11.2001 17.3668 9.96454 16.11 9.1093ZM9.61 6.0293C9.61 5.36626 9.87339 4.73037 10.3422 4.26153C10.8111 3.79269 11.447 3.5293 12.11 3.5293C12.773 3.5293 13.4089 3.79269 13.8778 4.26153C14.3466 4.73037 14.61 5.36626 14.61 6.0293V8.3393C13.0002 7.72376 11.2198 7.72376 9.61 8.3393V6.0293ZM12.11 20.6293C11 20.6293 9.91499 20.3 8.99221 19.6831C8.06944 19.0663 7.3504 18.1895 6.92609 17.1638C6.50177 16.1382 6.39126 15.0097 6.60854 13.9212C6.82581 12.8327 7.36111 11.8331 8.14668 11.0489C8.93225 10.2647 9.9328 9.73121 11.0217 9.51587C12.1106 9.30054 13.2389 9.41307 14.2638 9.83921C15.2887 10.2654 16.1642 10.986 16.7794 11.9098C17.3946 12.8337 17.722 13.9193 17.72 15.0293C17.7094 16.5102 17.1137 17.9269 16.0628 18.9703C15.0119 20.0138 13.591 20.5993 12.11 20.5993V20.6293Z"
      fill="currentColor"
    />
    <path
      d="M12.1093 12.0293C11.6469 12.0316 11.1996 12.194 10.8435 12.4889C10.4874 12.7838 10.2445 13.193 10.156 13.6469C10.0676 14.1007 10.1392 14.5711 10.3586 14.9782C10.5779 15.3852 10.9316 15.7036 11.3593 15.8793V17.2793C11.3593 17.4782 11.4383 17.669 11.5789 17.8096C11.7196 17.9503 11.9104 18.0293 12.1093 18.0293C12.3082 18.0293 12.4989 17.9503 12.6396 17.8096C12.7803 17.669 12.8593 17.4782 12.8593 17.2793V15.8793C13.2279 15.7302 13.5438 15.4745 13.7665 15.145C13.9891 14.8154 14.1085 14.427 14.1093 14.0293C14.1093 13.4989 13.8986 12.9902 13.5235 12.6151C13.1484 12.24 12.6397 12.0293 12.1093 12.0293Z"
      fill="currentColor"
    />
  </svg>,
  "Sensitive Data",
);
