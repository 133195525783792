import { Fragment, MouseEventHandler, useEffect, useState } from "react";
import {
  alpha,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Link as MuiLink,
  Popover,
  CircularProgress,
  Box,
} from "@mui/material";
import {
  Link as RouterLink,
  matchPath,
  resolvePath,
  useLocation,
} from "react-router-dom";
import { AppNavDrawerItem, useAppNavDrawer } from "~/providers/app-nav-drawer";
import {
  ExpandLessIcon,
  ExpandMoreIcon,
  WarningAmberIcon,
} from "~/components/icons";
import { AppNavDrawerTooltip } from "./tooltip";
import { useColorMode } from "~/providers/color-mode";

export type AppNavDrawerListItemProps = {
  item: AppNavDrawerItem;
};

export function AppNavDrawerListItem({ item }: AppNavDrawerListItemProps) {
  const navDrawer = useAppNavDrawer();
  const location = useLocation();

  const openStorageKey = "appNavDrawer.openedItems";
  const openedItems = () => {
    try {
      return JSON.parse(
        window.localStorage.getItem(openStorageKey) || "[]",
      ) as string[];
    } catch {
      return [];
    }
  };
  const openDefault = openedItems().includes(item.text);
  const [open, setOpen] = useState(openDefault);
  const openSubItems = !item.loading && item.tree && navDrawer.open && open;
  const { mode } = useColorMode();

  useEffect(() => {
    const nextOpenedItems = [
      ...openedItems().filter((text) => text !== item.text),
    ];
    if (open) {
      nextOpenedItems.push(item.text);
    }
    window.localStorage.setItem(
      openStorageKey,
      JSON.stringify(nextOpenedItems),
    );
  }, [open]);

  const handleSubNavToggle: MouseEventHandler<HTMLElement> = (event) => {
    if (navDrawer.open) {
      setOpen(!open);
    } else {
      handleSubNavPopoverOpen(event);
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const popoverOpen = Boolean(anchorEl) && !navDrawer.open;

  const handlePopoverOpen: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(null);
  };

  const isItemSelected = (item: AppNavDrawerItem) => {
    if (typeof item.selected === "boolean") return item.selected;
    return location.pathname.startsWith(resolvePath(item.link.to).pathname);
  };

  // FIXME: This logic is not flexible enough to handle every possible sub-nav
  // path structure. This is specific to `Integrations` sub-nav list:
  //   /space/integrations
  //   /space/integrations/*
  const isSubItemSelected = (item: AppNavDrawerItem, index: number) => {
    const itemPath = resolvePath(item.link.to).pathname;
    const isMatch = Boolean(matchPath(itemPath, location.pathname));
    const isStart = location.pathname.startsWith(itemPath);
    if (typeof item.selected === "boolean") return item.selected;
    return index === 0 ? isMatch : isStart;
  };

  const [subNavAnchorEl, setSubNavAnchorEl] = useState<HTMLElement | null>(
    null,
  );
  const popoverSubNavOpen = Boolean(subNavAnchorEl);

  const handleSubNavPopoverOpen: MouseEventHandler<HTMLElement> = (event) => {
    setAnchorEl(event.currentTarget);
    setSubNavAnchorEl(event.currentTarget);
  };

  const handleSubNavPopoverClose = () => {
    setAnchorEl(null);
    setSubNavAnchorEl(null);
  };

  const handleButtonMouseEnter: MouseEventHandler<HTMLElement> = (event) => {
    handlePopoverOpen(event);
  };

  const handleButtonMouseLeave: MouseEventHandler<HTMLElement> = (event) => {
    if (!popoverSubNavOpen) {
      handlePopoverClose(event);
    }
  };

  const tooltipContent = (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography sx={{ fontSize: 14, lineHeight: "24px" }}>
        {item.text}
      </Typography>
      {item.loading && (
        <CircularProgress size={14} color="inherit" sx={{ ml: 2 }} />
      )}
    </Box>
  );

  const hasSubNav = item.tree !== undefined && item.tree.length > 0;

  return (
    <Fragment>
      <ListItem sx={{ display: "block", py: 0 }}>
        <AppNavDrawerTooltip title={tooltipContent} open={popoverOpen}>
          <ListItemButton
            component={hasSubNav ? MuiLink : RouterLink}
            to={hasSubNav ? undefined : item.link.to}
            onClick={hasSubNav ? handleSubNavToggle : () => {}}
            selected={isItemSelected(item)}
            onMouseEnter={handleButtonMouseEnter}
            onMouseLeave={handleButtonMouseLeave}
            sx={[
              {
                px: 1,
                py: 0.5,
                borderRadius: 1,
                color: "text.secondary",
              },
              {
                "&:hover": {
                  color: "text.primary",
                  backgroundColor: "background.light",
                  ...(mode === "light" && {
                    color: "primary.main",
                  }),
                },
              },
              {
                "&.Mui-selected, &.Mui-selected:hover": {
                  backgroundColor: (theme) =>
                    alpha(theme.palette.primary.main, 0.5),
                  color: "text.primary",
                  ...(mode === "light" && {
                    backgroundColor: (theme) =>
                      alpha(theme.palette.primary.main, 0.2),
                    color: "primary.darker",
                  }),
                },
              },
            ]}
          >
            {item.icon && (
              <ListItemIcon sx={{ minWidth: 0, color: "inherit" }}>
                {item.icon}
              </ListItemIcon>
            )}
            <ListItemText
              primary={item.text}
              primaryTypographyProps={{
                sx: {
                  opacity: navDrawer.open ? 1 : 0,
                  transition: (theme) =>
                    theme.transitions.create("opacity", navDrawer.transition),
                  ml: 1,
                  fontSize: 14,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            />
            {item.loading && <CircularProgress size={14} color="inherit" />}
            {!item.loading && hasSubNav ? (
              open ? (
                <ExpandLessIcon
                  sx={{
                    opacity: navDrawer.open ? 1 : 0,
                    transition: (theme) =>
                      theme.transitions.create("opacity", navDrawer.transition),
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{
                    opacity: navDrawer.open ? 1 : 0,
                    transition: (theme) =>
                      theme.transitions.create("opacity", navDrawer.transition),
                  }}
                />
              )
            ) : undefined}
          </ListItemButton>
        </AppNavDrawerTooltip>
        {hasSubNav && (
          <Popover
            open={popoverSubNavOpen}
            anchorEl={subNavAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: -8,
              horizontal: -8,
            }}
            elevation={1}
            PaperProps={{
              sx: {
                backgroundColor: "background.light",
              },
            }}
            onClick={handleSubNavPopoverClose}
          >
            <List>
              {item.tree?.map((childItem, childIndex) => (
                <ListItem
                  key={`child-${childIndex}`}
                  sx={[{ display: "block", py: 0, px: 1 }]}
                >
                  <ListItemButton
                    component={RouterLink}
                    to={childItem.link.to}
                    selected={isSubItemSelected(childItem, childIndex)}
                    sx={[
                      {
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        color: "text.secondary",
                      },
                      {
                        "&:hover": {
                          color: "text.primary",
                          backgroundColor: "background.lighter",
                          ...(mode === "light" && {
                            color: "primary.main",
                          }),
                        },
                      },
                      {
                        "&.Mui-selected, &.Mui-selected:hover": {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.5),
                          color: "text.primary",
                          ...(mode === "light" && {
                            backgroundColor: (theme) =>
                              alpha(theme.palette.primary.main, 0.2),
                            color: "primary.darker",
                          }),
                        },
                      },
                    ]}
                  >
                    <ListItemText
                      primary={childItem.text}
                      primaryTypographyProps={{
                        sx: {
                          fontSize: 13,
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Popover>
        )}
      </ListItem>
      {hasSubNav && (
        <Collapse in={openSubItems} timeout="auto" unmountOnExit>
          <List>
            {item.tree?.map((childItem, childIndex) => (
              <ListItem
                key={`child-${childIndex}`}
                sx={[{ display: "block", py: 0 }]}
              >
                <ListItemButton
                  component={RouterLink}
                  to={childItem.link.to}
                  selected={isSubItemSelected(childItem, childIndex)}
                  sx={[
                    {
                      ml: 3,
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      color: "text.secondary",
                    },
                    {
                      "&:hover": {
                        color: "text.primary",
                        backgroundColor: "background.light",
                        ...(mode === "light" && {
                          color: "primary.main",
                        }),
                      },
                    },
                    {
                      "&.Mui-selected, &.Mui-selected:hover": {
                        backgroundColor: (theme) =>
                          alpha(theme.palette.primary.main, 0.5),
                        color: "text.primary",
                        ...(mode === "light" && {
                          backgroundColor: (theme) =>
                            alpha(theme.palette.primary.main, 0.2),
                          color: "primary.darker",
                        }),
                      },
                    },
                    {
                      "&::after": {
                        transition: "width 0.3s",
                        content: "''",
                        position: "absolute",
                        width: isSubItemSelected(childItem, childIndex)
                          ? 8
                          : 16,
                        height: childIndex === 0 ? 20 : 40,
                        borderLeft: "1px solid",
                        borderBottom: "1px solid",
                        borderColor: "background.lightest",
                        borderBottomLeftRadius: 4,
                        top: childIndex === 0 ? 0 : -20,
                        left: -8,
                        zIndex: 1,
                      },
                    },
                    { "&:hover::after": { width: 8 } },
                  ]}
                >
                  <ListItemText
                    primary={childItem.text}
                    primaryTypographyProps={{
                      sx: {
                        transition: "opacity 0.3s",
                        opacity: navDrawer.open ? 1 : 0,
                        ml: 1,
                        fontSize: 13,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                    sx={{ flex: childItem.hasError ? "unset" : undefined }}
                  />
                  {childItem.hasError && (
                    <WarningAmberIcon
                      sx={{ fontSize: 14, color: "warning.main", ml: 0.5 }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </Fragment>
  );
}
