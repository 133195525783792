import { Box, SxProps, Theme } from "@mui/material";
import { ReactNode, forwardRef } from "react";

export type ClampProps = {
  children?: ReactNode;
  lines?: number;
  sx?: SxProps<Theme>;
};

export const Clamp = forwardRef<HTMLDivElement, ClampProps>(function Clamp(
  { children, lines = 1, sx, ...props }: ClampProps,
  ref,
) {
  return (
    <Box
      ref={ref}
      {...props}
      sx={[
        {
          display: "-webkit-box",
          WebkitLineClamp: lines,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Box>
  );
});
