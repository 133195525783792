import { Component } from "react";
import {
  createFilterOptions,
  styled,
  Autocomplete,
  Paper,
  IconButton,
  InputBase,
  TextField,
  OutlinedInput,
  FormControl,
  InputAdornment,
  Box,
  IconButtonProps,
  InputBaseProps,
} from "@mui/material";
import { SearchIcon } from "~/components/icons";
import { AgentFilter } from "~/lib/types";

const FlexPaper = styled(Paper)`
  display: flex;
`;

const InputField = styled(InputBase)`
  flex: 1;
  padding-left: 24px;
`;

const SearchButton = styled(IconButton)`
  margin: ${(props) => props.theme.spacing(1)} !important;
`;

export type SearchBarProps = {
  onQuery: (s: string) => void;
  onChange: (s: string) => void;
  placeholder: string;
  value?: string | null;
};

type SearchBarState = {
  placeholder: string;
  value: string;
};

export class SearchBar extends Component<SearchBarProps, SearchBarState> {
  state: SearchBarState = {
    placeholder: "",
    value: "",
  };

  update: InputBaseProps["onChange"] = (e) => {
    this.setState({
      value: e.target.value,
    });
  };

  handleClick: IconButtonProps["onClick"] = (e) => {
    e.preventDefault();
    const c = this.props.onQuery;
    if (c != null) c(this.state.value);
  };

  handleKeyDown: InputBaseProps["onKeyDown"] = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const c = this.props.onQuery;
      if (c != null) c(this.state.value);
    }
  };

  render() {
    return (
      <FlexPaper>
        <SearchButton aria-label="Search" onClick={this.handleClick}>
          <SearchIcon />
        </SearchButton>
        <InputField
          placeholder={this.state.placeholder}
          onChange={this.update}
          value={this.state.value}
          onKeyDown={this.handleKeyDown}
        />
      </FlexPaper>
    );
  }
}

export type SearchFilterProps = {
  onQuery: (s: any[]) => void;
  // TODO: filter needs to be an array of filter entries
  filter: AgentFilter[];
  value: AgentFilter[];
  placeholder?: string;
  freeSolo?: boolean;
};

const filterOptions = createFilterOptions<AgentFilter>({
  matchFrom: "any",
  stringify: (option) => {
    if (option != null) {
      if (typeof option.title === "function") {
        return option.title();
      }
    }
    return "";
  },
});

export class SearchFilter extends Component<SearchFilterProps> {
  render() {
    const {
      onQuery,
      filter,
      value,
      placeholder = "Filter",
      freeSolo = false,
    } = this.props;
    const onChange = (e: object, value: any) => {
      if (onQuery != null) {
        onQuery(value);
      }
    };

    return (
      <Autocomplete
        multiple
        freeSolo={freeSolo}
        options={filter}
        onChange={onChange}
        value={value}
        filterSelectedOptions
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          if (typeof option !== "string") {
            return option.title();
          }
          return "";
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={placeholder}
            margin="normal"
            sx={{ margin: 0 }}
          />
        )}
      />
    );
  }
}

export class DatasetSearchBar extends Component<
  SearchBarProps,
  SearchBarState
> {
  state: SearchBarState = {
    placeholder: "",
    value: "",
  };

  constructor(props: SearchBarProps) {
    super(props);
    this.state.placeholder = props.placeholder;
    if (props.value) {
      this.state.value = props.value;
    }
  }

  update: InputBaseProps["onChange"] = (e) => {
    this.setState({
      value: e.target.value,
    });
    const c = this.props.onChange;
    if (c != null) c(e.target.value);
  };

  handleClick: IconButtonProps["onClick"] = (e) => {
    e.preventDefault();
    const c = this.props.onQuery;
    if (c != null) c(this.state.value);
  };

  handleKeyDown: InputBaseProps["onKeyDown"] = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const c = this.props.onQuery;
      if (c != null) c(this.state.value);
    }
  };

  render() {
    return (
      <Box sx={{ width: 1, display: "flex" }}>
        <FormControl variant="outlined" sx={{ flexGrow: 100 }}>
          <OutlinedInput
            id="outlined-secondary"
            color="primary"
            placeholder={this.state.placeholder}
            onChange={this.update}
            value={this.state.value}
            onKeyDown={this.handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="search"
                  onClick={this.handleClick}
                  size="large"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
            sx={{
              background: (theme) => theme.palette.code.background,
              borderRadius: 1,
              color: "text.primary",
            }}
          />
        </FormControl>
      </Box>
    );
  }
}
