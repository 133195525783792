import { Box } from "@mui/material";
import { InfoOutlinedIcon, WarningAmberIcon } from "~/components/icons";
import { getColor } from "./colors";

type Props = {
  status: "INFO" | "WARNING" | "ERROR" | "NONE" | undefined | null;
};

export const GetInfoIcon = ({ status }: Props) => {
  if (!status) return <Box />;

  const sxProps = {
    fontSize: 14,
    cursor: "pointer",
  };
  switch (status.toLowerCase()) {
    case "info":
      return (
        <InfoOutlinedIcon
          color="primary"
          sx={{ ...sxProps, color: "#29B6F6" }}
        />
      );
    case "warning":
      return (
        <WarningAmberIcon
          sx={{ ...sxProps, color: (theme) => getColor(theme, status) }}
        />
      );
    case "error":
      return (
        <WarningAmberIcon
          color="error"
          sx={{ ...sxProps, color: (theme) => getColor(theme, status) }}
        />
      );
    default:
      return <Box />;
  }
};
