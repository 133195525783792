import { Box, Typography } from "@mui/material";
import { getColor } from "~/lib/colors";
import { SpacePlanScoringSummary } from "~/operations";
import { ControlResultIcon } from "~/components/control-result-icon";

type ScoreBarChartProps = {
  data: SpacePlanScoringSummary;
  disableTotal?: boolean;
};

function ScoreBarChart({ data, disableTotal = false }: ScoreBarChartProps) {
  const { total, __typename, ...scores } = data;
  const { pass, fail, error } = scores;

  const calculateBarLength = (type: string) => {
    if (total === 0) return 0;
    switch (type.toLowerCase()) {
      case "pass":
        return `${(pass / total) * 100}%`;
      case "fail":
        return `${(pass / total) * 100 + (fail / total) * 100}%`;
      case "error":
        return `${
          (pass / total) * 100 + (fail / total) * 100 + (error / total) * 100
        }%`;
      default:
        return 0;
    }
  };

  return (
    <Box sx={{ height: 1, width: 1 }}>
      <Typography
        fontWeight={700}
        sx={{
          fontSize: 12,
          lineHeight: "12px",
          color: "text.secondary",
          opacity: disableTotal ? 0 : 1,
        }}
      >
        {total} Checks
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {Object.entries(scores)
          .filter(([key]) => ["pass", "fail"].includes(key))
          .map(([key, value]) => (
            <Box
              key={key}
              sx={{
                display: "inline-flex",
                pb: 1,
              }}
            >
              <ControlResultIcon status={key} size="sm" />
              <Typography
                sx={{
                  ml: 0.5,
                  fontSize: 12,
                  fontWeight: 700,
                  color: "text.secondary",
                }}
              >
                {Math.round((value / total) * 100) || 0}%
              </Typography>
            </Box>
          ))}
      </Box>
      <Box
        sx={{
          width: 1,
          height: 8,
          backgroundColor: "background.default",
          position: "relative",
        }}
      >
        {Object.entries(scores)
          .reverse()
          .map(([key, value], index) => {
            return (
              <Box
                key={key}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: 8,
                  width: calculateBarLength(key),
                  background: (theme) => getColor(theme, key, true),
                  zIndex: index,
                  borderRadius: 0.5,
                  border: "1px solid",
                  borderColor: (theme) =>
                    value === 0 ? "transparent" : getColor(theme, key),
                }}
              />
            );
          })}
      </Box>
    </Box>
  );
}

export default ScoreBarChart;
