import { useEffect, useState } from "react";
import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { ArrowForwardIcon } from "~/components/icons";

type CButtonProps = {
  content: string;
  loading?: boolean;
  step?: "email" | "password";
  noArrow?: boolean;
} & ButtonProps;

export const ContinueButton = ({
  loading,
  content,
  step,
  noArrow,
  ...props
}: CButtonProps) => {
  const [showArrow, setShowArrow] = useState<boolean>(false);

  useEffect(() => {
    if (noArrow || loading || Boolean(step === "password")) {
      setShowArrow(false);
    } else {
      setShowArrow(true);
    }
  }, [loading, step]);

  return (
    <Button
      fullWidth
      sx={{
        py: 2,
        background: `linear-gradient(91.79deg, #E94A18 0.08%, #D3A004 98.53%)`,
        boxShadow:
          "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
      }}
      {...(showArrow && {
        endIcon: (
          <ArrowForwardIcon
            sx={{
              mt: "-1px",
            }}
          />
        ),
      })}
      disabled={loading}
      {...props}
    >
      {loading ? <CircularProgress size={26} thickness={4} /> : content}
    </Button>
  );
};
