import { Fragment, ReactNode, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonProps,
  Checkbox,
  CheckboxProps,
  Divider,
  Grid,
  LinearProgress,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Paper,
  Slider,
  SliderProps,
  Step,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  AccessTimeIcon,
  AddIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
  HomeIcon,
  KeyboardArrowDownIcon,
  TimerOutlinedIcon,
} from "~/components/icons";
import {
  LoadSpacePlanDocument,
  TestIamActionsQuery,
  useApplySpacePlanMutation,
  useLoadSpacePlanQuery,
} from "~/operations";
import { Sort, Space } from "~/lib/types";
import {
  BarChartIcon,
  Flex,
  LightbulbIcon,
  LoadingFailedPage,
  LoadingPage,
  Search,
} from "~/components/ui-library";
import { BlockOutlined, ModeStandby } from "@mui/icons-material";
import { SeverityChip } from "~/components/severity-chip";
import ScoreBarChart from "./ScoreBarChart";
import { HubCard } from "~/components/hub-card";
import GradeBlock from "./GradeBlock";
import CollapsibleList from "./CollapsibleList";
import { getColorByCompletion } from "~/lib/colors";
import { getPolicyReportScoreEntryActionIcon } from "~/lib/policy-action-icon";
import {
  CreateMilestonalModal,
  CreateMilestonalModalProps,
} from "./create-milestone-modal";
import {
  RiskPlanner,
  FUTURE_GOALS_UID,
  sortBy,
  DraftCheck,
} from "./risk-planner";
import ImpactRadial from "./impact-radial";
import { usePrompt } from "~/hooks/usePrompt";

export type PlanningEditProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function PlanningEdit({
  space,
  availablePermissions,
}: PlanningEditProps) {
  const { data, loading, error } = useLoadSpacePlanQuery({
    variables: { input: { spaceMrn: space.mrn, scopeMrn: space.mrn } },
  });

  const [applySpacePlanMutation] = useApplySpacePlanMutation({
    refetchQueries: [LoadSpacePlanDocument],
  });

  const navigate = useNavigate();

  if (loading) {
    return <LoadingPage />;
  }

  const spacePlan = data?.spacePlan;

  if (error || !spacePlan) {
    return <LoadingFailedPage />;
  }

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/risk-actions"
      component={RouterLink}
      to={`/space/risk-actions?spaceId=${space.id}`}
      display="flex"
    >
      Risk Actions
    </Link>,
    <Typography key="/space/risk-actions/edit">Edit</Typography>,
  ];
  const planner = new RiskPlanner(spacePlan);
  if (planner.spacePlan.planApplied) {
    planner.setTargetStep(planner.plannedChecks.length + 1);
  } else {
    // if plan is not applied, set target step to recommended goal
    planner.setTargetStep(planner.recommendedIdx);
  }

  const handleApplyPlan = async () => {
    try {
      await applySpacePlanMutation({
        variables: { input: { mrn: space.mrn, ...planner.deltaInput } },
      });
      navigate(`/space/risk-actions?spaceId=${space.id}`);
    } catch (e) {
      console.error("Failed to apply plan", e);
    }
  };

  document.title = "Risk Actions · Mondoo";

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Typography variant="h4" fontWeight={700} sx={{ mb: 5 }}>
        Edit Plan
      </Typography>
      <PlanEditor
        space={space}
        planner={planner}
        onApplyPlan={handleApplyPlan}
      />
    </Box>
  );
}

type PlanEditorProps = {
  space: Space;
  planner: RiskPlanner;
  onApplyPlan: () => void;
};

function PlanEditor({ space, planner, onApplyPlan }: PlanEditorProps) {
  const [activeStep, setActiveStep] = useState(0);

  usePrompt(
    planner.isDirty
      ? "Edited plan has not been saved. Are you sure you want to leave?"
      : false,
    { beforeUnload: true },
  );

  const steps = [
    {
      label: "Establish your baseline",
      component: <PlanEditorBaseline planner={planner} />,
    },
    {
      label: "Plan your goals",
      component: <PlanEditorGoals planner={planner} />,
    },
    {
      label: "Review your plan",
      component: <PlanEditorReview planner={planner} />,
    },
  ];

  const isFirstStep = activeStep === 0;
  const isLastStep = activeStep === steps.length - 1;

  const handleApplyPlanClick: ButtonProps["onClick"] = () => {
    onApplyPlan();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 3 }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            sx={{
              ".MuiStepLabel-root": {
                cursor: "pointer",
              },
              ".MuiStepLabel-label": {
                textTransform: "uppercase",
                fontSize: "12px",
                fontWeight: "700",
              },
            }}
          >
            {steps.map((step, stepIndex) => (
              <Step key={step.label} onClick={() => setActiveStep(stepIndex)}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {steps.at(activeStep)?.component}
      </Grid>
      <Grid item xs={12}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            {!isFirstStep && (
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<ChevronLeftIcon />}
                onClick={() => setActiveStep(activeStep - 1)}
              >
                Back
              </Button>
            )}
          </Box>
          <Box sx={{ ".MuiButton-root": { ml: 1 } }}>
            <Button
              variant="outlined"
              color="secondary"
              component={RouterLink}
              to={`/space/risk-actions?spaceId=${space.id}`}
            >
              Cancel
            </Button>
            {!isLastStep && (
              <Button
                variant="contained"
                color="primary"
                endIcon={<ChevronRightIcon />}
                onClick={() => setActiveStep(activeStep + 1)}
              >
                Next Step
              </Button>
            )}
            {isLastStep && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleApplyPlanClick}
              >
                Apply Plan
              </Button>
            )}
          </Box>
        </Flex>
      </Grid>
    </Grid>
  );
}

type PlanEditorBaselineProps = {
  planner: RiskPlanner;
};

function PlanEditorBaseline({ planner }: PlanEditorBaselineProps) {
  // todo: remove hack once planner is refactored as hook
  const [version, setVersion] = useState(0);
  const forceRender = () => setVersion(version + 1);
  //

  const [searchValue, setSearchValue] = useState("");

  const activeChecks = [...planner.activeChecks].filter((c) =>
    c.mquery.title.toLowerCase().includes(searchValue.toLowerCase()),
  );
  const futureChecks = [...planner.plannedChecks]
    .filter((c) =>
      c.mquery.title.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .reverse();
  const activeScoringSummary = planner.checksScoreSummary(activeChecks);
  const futureScoringSummary = planner.checksScoreSummary(futureChecks);

  const targetMin = 1;
  const targetMax = planner.moveableCnt + 1;
  const targetRec = planner.recommendedIdx;

  const marks: SliderProps["marks"] = [
    // {
    //   value: targetMin,
    //   label: targetMin,
    // },
    {
      value: targetRec,
      label: "Recommended",
    },
    // {
    //   value: targetMax,
    //   label: targetMax,
    // },
  ];

  const handleSliderChange = (step: number) => {
    planner.setTargetStep(step);
    forceRender();
  };

  const [activeSort, setActiveSort] = useState<Sort>({
    field: "IMPACT",
    direction: "ASC",
  });
  const [futureSort, setFutureSort] = useState<Sort>({
    field: "IMPACT",
    direction: "ASC",
  });

  const sortDirection = (sort: Sort, header: Header) => {
    return header.id !== sort.field
      ? "DESC"
      : sort.direction === "ASC"
        ? "DESC"
        : "ASC";
  };

  const handleActiveSortClick = (header: Header) => {
    const direction = sortDirection(activeSort, header);
    setActiveSort({ field: header.id, direction });
  };

  const handleFutureSortClick = (header: Header) => {
    const direction = sortDirection(futureSort, header);
    setFutureSort({ field: header.id, direction });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 3 }}>
          <Flex
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: 700,
                fontSize: "24px",
              }}
            >
              Set your target score
            </Typography>
            <ModeStandby />
          </Flex>
          <Flex sx={{ pt: 8, pb: 4, px: 3 }}>
            <Typography color="text.secondary">{planner.minScore}%</Typography>
            <Slider
              min={targetMin}
              max={targetMax}
              marks={marks}
              value={planner.targetStep}
              onChange={(e, score) => {
                if (typeof score === "number") handleSliderChange(score);
              }}
              sx={{
                mx: 2,
                ".MuiSlider-rail": {
                  border: "1px solid",
                  borderColor: "primary.main",
                  borderRadius: "2px",
                  transition: "none",
                },
                ".MuiSlider-track": {
                  background:
                    "linear-gradient(270deg, #9147FF -0.66%, #3F137C 100%);",
                  borderRadius: "2px",
                  transition: "none",
                },
                ".MuiSlider-mark": {
                  backgroundColor: "transparent",
                  transition: "none",
                },
                "[data-index='0'].MuiSlider-mark": {
                  backgroundColor: "text.secondary",
                  height: "60%",
                  width: "1px",
                  top: "10%",
                  "&.MuiSlider-markActive": {
                    backgroundColor: "text.primary",
                  },
                },
                ".MuiSlider-markLabel": {
                  top: -25,
                  fontSize: "12px",
                  textTransform: "uppercase",
                },
                ".MuiSlider-thumb": {
                  height: (theme) => theme.spacing(3),
                  width: (theme) => theme.spacing(3),
                  border: "2px solid #D9D9D9",
                  background:
                    "radial-gradient(46.25% 46.64% at 50% 51.28%, #F9F9F9 0%, #BFBFBF 100%);",
                  boxShadow: "0px 0px 8px #000000",
                  transition: "none",
                },
              }}
            />
            <Typography color="text.secondary">{planner.maxScore}%</Typography>
          </Flex>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Search
          name="search-plan-checks"
          value={searchValue}
          onChangeHandler={(o) =>
            setSearchValue(Array.isArray(o.value) ? o.value.join("") : o.value)
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ borderRadius: 1, overflow: "hidden" }}>
          <Box sx={{ p: 2, pb: 2, backgroundColor: "background.lighter" }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
                textTransform: "uppercase",
                mb: 2,
              }}
            >
              <Flex mr={1} component="span">
                <BarChartIcon />
              </Flex>
              Active
              <Typography
                component="span"
                sx={{ ml: 0.5, color: "text.secondary", fontWeight: 700 }}
              >
                ({activeChecks.length})
              </Typography>
            </Typography>
            <ScoreBarChart data={activeScoringSummary} disableTotal />
          </Box>
          <FixedSizeList
            height={500}
            itemCount={activeChecks.length}
            itemSize={55}
            width="100%"
          >
            {({ index, style }: ListChildComponentProps) => {
              const check = activeChecks[index];
              return (
                <ListItem
                  style={style}
                  key={check.mquery.mrn}
                  sx={{ gap: 3, fontSize: 14 }}
                >
                  <ListItemAvatar>
                    <ImpactRadial
                      impactScore={check.mquery.impact?.value!}
                      completion={check.completion}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2">
                        {check.mquery.title}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            }}
          </FixedSizeList>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ borderRadius: 1, overflow: "hidden" }}>
          <Box sx={{ p: 2, pb: 2, backgroundColor: "background.lighter" }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
                textTransform: "uppercase",
                mb: 2,
              }}
            >
              <Flex mr={1} component="span">
                <LightbulbIcon />
              </Flex>
              Future Goals
              <Typography
                component="span"
                sx={{ ml: 0.5, color: "text.secondary", fontWeight: 700 }}
              >
                ({futureChecks.length})
              </Typography>
            </Typography>
            <ScoreBarChart data={futureScoringSummary} disableTotal />
          </Box>
          <FixedSizeList
            height={500}
            itemCount={futureChecks.length}
            itemSize={55}
            width="100%"
          >
            {({ index, style }: { index: number; style: any }) => {
              const check = futureChecks[index];
              return (
                <ListItem
                  style={style}
                  key={check.mquery.mrn}
                  sx={{ gap: 3, fontSize: 14 }}
                >
                  <ListItemAvatar>
                    <ImpactRadial
                      impactScore={check.mquery.impact?.value!}
                      completion={check.completion}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="body2">
                        {check.mquery.title}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            }}
          </FixedSizeList>
        </Paper>
      </Grid>
    </Grid>
  );
}

type PlanEditorGoalsProps = {
  planner: RiskPlanner;
};

function PlanEditorGoals({ planner }: PlanEditorGoalsProps) {
  // todo: remove hack once planner is refactored as hook
  const [version, setVersion] = useState(0);
  const forceRender = () => setVersion(version + 1);
  //

  const [searchValue, setSearchValue] = useState("");

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedChecks, setSelectedChecks] = useState<string[]>([]);
  const [moveMenuAnchorEl, setMoveMenuAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const moveMenuOpen = Boolean(moveMenuAnchorEl);
  const [exceptionsMoveMenuAnchorEl, setExceptionsMoveMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const exceptionsMoveMenuOpen = Boolean(exceptionsMoveMenuAnchorEl);

  const plannedChecks = planner.milestoneChecks;
  const exceptionsChecks = planner.exceptionsChecks;
  const futureScoringSummary = planner.checksScoreSummary(plannedChecks);
  const exceptionsScoringSummary = planner.checksScoreSummary(exceptionsChecks);
  const selectedPlannedChecks = selectedChecks.filter((sc) =>
    Boolean(plannedChecks.find((c) => c.mquery.mrn === sc)),
  );
  const selectedExceptionChecks = selectedChecks.filter((sc) =>
    Boolean(exceptionsChecks.find((c) => c.mquery.mrn === sc)),
  );

  const handleRemoveMilestoneClick = (groupUid: string) => {
    planner.removeMilestone(groupUid);
    forceRender();
  };

  const handleAddMilestoneClick = () => {
    setCreateModalOpen(true);
  };

  const handleCreateModalClose: CreateMilestonalModalProps["handleClose"] =
    () => {
      setCreateModalOpen(false);
    };

  const handleCreateModalSubmit: CreateMilestonalModalProps["handleCreate"] = ({
    year,
    month,
    day,
  }) => {
    const endDate = new Date(year, month, day).toISOString();
    const uid = `milestone-${crypto.randomUUID()}`;
    const title = "";
    planner.addMilestone({ uid, endDate, title });
    planner.moveChecksTo(selectedChecks, uid);
    setCreateModalOpen(false);
    setMoveMenuAnchorEl(null);
    setExceptionsMoveMenuAnchorEl(null);
  };

  const handleCheckChange = (check: string, checked: boolean) => {
    const nextSelected = checked
      ? [...selectedChecks, check]
      : selectedChecks.filter((c) => c !== check);
    setSelectedChecks(nextSelected);
  };

  const handleAllCheckChange: CheckboxProps["onChange"] = (event, checked) => {
    let nextSelected = selectedChecks.filter(
      (sc) => !Boolean(plannedChecks.find((c) => c.mquery.mrn === sc)),
    );
    if (checked) {
      nextSelected = [
        ...nextSelected,
        ...plannedChecks.map((c) => c.mquery.mrn),
      ];
    }
    setSelectedChecks(nextSelected);
  };

  const handleAllGroupCheckChange = (groupUid: string, checked: boolean) => {
    const groupChecks = planner.rankedChecks
      .filter((c) => c.groupUid === groupUid)
      .map((c) => c.mquery.mrn);
    let nextSelected = selectedChecks.filter((sc) => !groupChecks.includes(sc));
    if (checked) {
      nextSelected = [...nextSelected, ...groupChecks];
    }
    setSelectedChecks(nextSelected);
  };

  const handleMoveChecksClick: ButtonProps["onClick"] = (event) => {
    setMoveMenuAnchorEl(event.currentTarget);
  };

  const handleMoveMenuClose: MenuProps["onClose"] = () => {
    setMoveMenuAnchorEl(null);
  };

  const handleExceptionsMoveChecksClick: ButtonProps["onClick"] = (event) => {
    setExceptionsMoveMenuAnchorEl(event.currentTarget);
  };

  const handleExceptionsMoveMenuClose: MenuProps["onClose"] = () => {
    setExceptionsMoveMenuAnchorEl(null);
  };

  const handleMoveChecksToClick = (checks: string[], groupUid: string) => {
    planner.moveChecksTo(checks, groupUid);
    setMoveMenuAnchorEl(null);
    setExceptionsMoveMenuAnchorEl(null);
  };

  const [plannedSort, setPlannedSort] = useState<Sort>({
    field: "IMPACT",
    direction: "ASC",
  });
  const [exceptionsSort, setExceptionsSort] = useState<Sort>({
    field: "IMPACT",
    direction: "ASC",
  });

  const sortDirection = (sort: Sort, header: Header) => {
    return header.id !== sort.field
      ? "DESC"
      : sort.direction === "ASC"
        ? "DESC"
        : "ASC";
  };

  const handlePlannedSortClick = (header: Header) => {
    const direction = sortDirection(plannedSort, header);
    setPlannedSort({ field: header.id, direction });
  };

  const handleExceptionsSortClick = (header: Header) => {
    const direction = sortDirection(exceptionsSort, header);
    setExceptionsSort({ field: header.id, direction });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Search
          name="search-plan-checks"
          value={searchValue}
          onChangeHandler={(o) =>
            setSearchValue(Array.isArray(o.value) ? o.value.join("") : o.value)
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ borderRadius: 1, overflow: "hidden" }}>
          <Box sx={{ p: 2, pb: 0, backgroundColor: "background.lighter" }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
                textTransform: "uppercase",
                mb: 2,
              }}
            >
              <Flex mr={1} component="span">
                <LightbulbIcon />
              </Flex>
              Future Goals
              <Typography
                component="span"
                sx={{ ml: 0.5, color: "text.secondary", fontWeight: 700 }}
              >
                ({planner.milestoneChecks.length})
              </Typography>
            </Typography>
            <ScoreBarChart data={futureScoringSummary} disableTotal />
          </Box>
          <TableContainer sx={{ maxHeight: 450, overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead
                sx={{
                  ".MuiTableCell-root": {
                    backgroundColor: "background.lighter",
                  },
                }}
              >
                <TableRow>
                  <TableCell sx={{ width: "60px", pr: 0 }}>
                    <Checkbox
                      checked={
                        plannedChecks.length > 0 &&
                        selectedPlannedChecks.length === plannedChecks.length
                      }
                      indeterminate={
                        selectedPlannedChecks.length > 0 &&
                        selectedPlannedChecks.length !== plannedChecks.length
                      }
                      onChange={handleAllCheckChange}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "75px" }}>
                    <TableSortLabel
                      onClick={() =>
                        handlePlannedSortClick(goalsTableHeaders[1])
                      }
                      direction={
                        plannedSort.direction === "ASC" ? "asc" : "desc"
                      }
                      active={goalsTableHeaders[1].id === plannedSort.field}
                      sx={{ color: "text.secondary", fontWeight: 700 }}
                    >
                      {goalsTableHeaders[1].label}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Flex justifyContent="space-between">
                      <TableSortLabel
                        onClick={() =>
                          handlePlannedSortClick(goalsTableHeaders[2])
                        }
                        direction={
                          plannedSort.direction === "ASC" ? "asc" : "desc"
                        }
                        active={goalsTableHeaders[2].id === plannedSort.field}
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        {goalsTableHeaders[2].label}
                      </TableSortLabel>
                      {selectedPlannedChecks.length > 0 && (
                        <Button
                          variant="text"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            color: "text.secondary",
                            my: -1,
                          }}
                          endIcon={<KeyboardArrowDownIcon />}
                          onClick={handleMoveChecksClick}
                        >
                          Move {selectedPlannedChecks.length} to
                        </Button>
                      )}
                      <Menu
                        anchorEl={moveMenuAnchorEl}
                        open={moveMenuOpen}
                        onClose={handleMoveMenuClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem onClick={handleAddMilestoneClick}>
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText>New Milestone</ListItemText>
                        </MenuItem>
                        {planner.milestonesList.map((group) => (
                          <MenuItem
                            key={group.uid}
                            onClick={() =>
                              handleMoveChecksToClick(
                                selectedPlannedChecks,
                                group.uid,
                              )
                            }
                          >
                            <ListItemIcon>
                              {group.uid === FUTURE_GOALS_UID ? (
                                <LightbulbIcon />
                              ) : (
                                <AccessTimeIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText>
                              {planner.milestoneTitle(group.uid)}
                            </ListItemText>
                          </MenuItem>
                        ))}
                        <Divider />
                        <MenuItem
                          onClick={() =>
                            handleMoveChecksToClick(
                              selectedPlannedChecks,
                              "exceptions",
                            )
                          }
                        >
                          <ListItemIcon>
                            <BlockOutlined />
                          </ListItemIcon>
                          <ListItemText>Exceptions</ListItemText>
                        </MenuItem>
                      </Menu>
                    </Flex>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={3} sx={{ p: 0 }}>
                    <Button
                      variant="text"
                      sx={{
                        width: "100%",
                        fontSize: "12px",
                        fontWeight: 700,
                        borderRadius: 0,
                        p: 2,
                        color: "text.secondary",
                      }}
                      startIcon={<AddIcon />}
                      onClick={handleAddMilestoneClick}
                    >
                      Add Milestone
                    </Button>
                  </TableCell>
                </TableRow>

                {planner.milestonesList.map((group) => {
                  const groupChecks = planner.rankedChecks.filter(
                    (c) => c.groupUid === group.uid,
                  );
                  const selectedGroupChecks = selectedChecks.filter((sc) =>
                    Boolean(groupChecks.find((c) => c.mquery.mrn === sc)),
                  );
                  const groupCompletion = planner.milestoneCompletion(
                    group.uid,
                  );
                  return (
                    <Fragment key={group.uid}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "60px",
                            pr: 0,
                            backgroundColor: "background.lighter",
                          }}
                        >
                          {groupChecks.length > 0 && (
                            <Checkbox
                              checked={
                                groupChecks.length > 0 &&
                                selectedGroupChecks.length ===
                                  groupChecks.length
                              }
                              indeterminate={
                                selectedGroupChecks.length > 0 &&
                                selectedGroupChecks.length !==
                                  groupChecks.length
                              }
                              disabled={groupChecks.length === 0}
                              onChange={(e, checked) =>
                                handleAllGroupCheckChange(group.uid, checked)
                              }
                            />
                          )}
                          {groupChecks.length === 0 && (
                            <Button
                              variant="text"
                              sx={{
                                color: "text.secondary",
                                p: "9px",
                                minWidth: "0",
                                borderRadius: "50%",
                                opacity: group.uid === FUTURE_GOALS_UID ? 0 : 1,
                              }}
                              onClick={() =>
                                handleRemoveMilestoneClick(group.uid)
                              }
                              disabled={group.uid === FUTURE_GOALS_UID}
                            >
                              <CloseIcon />
                            </Button>
                          )}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{ backgroundColor: "background.lighter" }}
                        >
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              component="span"
                              sx={{
                                color: "text.secondary",
                                fontSize: "12px",
                                fontWeight: 700,
                                textTransform: "uppercase",
                              }}
                            >
                              {planner.milestoneTitle(group.uid)} (
                              {groupChecks.length})
                            </Typography>
                            <Flex alignItems="center" justifyContent="flex-end">
                              <Typography
                                component="span"
                                sx={{ fontSize: 12, fontWeight: 700, mr: 1 }}
                              >
                                {Math.round(groupCompletion * 100)}%
                              </Typography>
                              <LinearProgress
                                variant="determinate"
                                value={groupCompletion * 100}
                                sx={{
                                  height: 8,
                                  width: 100,
                                  borderRadius: "2px",
                                  backgroundColor: "background.default",
                                  "& .MuiLinearProgress-bar": {
                                    borderRadius: "2px",
                                    background: (theme) =>
                                      getColorByCompletion(
                                        theme,
                                        groupCompletion * 100,
                                        true,
                                      ),
                                  },
                                }}
                              />
                            </Flex>
                          </Flex>
                        </TableCell>
                      </TableRow>
                      {groupChecks
                        .filter((c) =>
                          c.mquery.title
                            .toLowerCase()
                            .includes(searchValue.toLowerCase()),
                        )
                        .sort(sortBy(plannedSort))
                        .map((check) => (
                          <TableRow key={check.mquery.mrn}>
                            <TableCell sx={{ width: "60px", pr: 0 }}>
                              <Checkbox
                                checked={selectedChecks.includes(
                                  check.mquery.mrn,
                                )}
                                onChange={(e, checked) =>
                                  handleCheckChange(check.mquery.mrn, checked)
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ width: "75px" }}>
                              <ImpactRadial
                                impactScore={check.mquery.impact?.value!}
                                completion={check.completion}
                              />
                            </TableCell>
                            <TableCell>{check.mquery.title}</TableCell>
                          </TableRow>
                        ))}
                    </Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ borderRadius: 1, overflow: "hidden" }}>
          <Box sx={{ p: 2, pb: 0, backgroundColor: "background.lighter" }}>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: 700,
                textTransform: "uppercase",
                mb: 2,
              }}
            >
              <Flex mr={1} component="span">
                <BlockOutlined />
              </Flex>
              Exceptions
              <Typography
                component="span"
                sx={{ ml: 0.5, color: "text.secondary", fontWeight: 700 }}
              >
                ({exceptionsChecks.length})
              </Typography>
            </Typography>
            <ScoreBarChart data={exceptionsScoringSummary} disableTotal />
          </Box>
          <TableContainer sx={{ maxHeight: 450, overflow: "auto" }}>
            <Table stickyHeader>
              <TableHead
                sx={{
                  ".MuiTableCell-root": {
                    backgroundColor: "background.lighter",
                  },
                }}
              >
                <TableRow>
                  <TableCell sx={{ width: "60px", pr: 0 }}>
                    <Checkbox
                      checked={
                        exceptionsChecks.length > 0 &&
                        selectedExceptionChecks.length ===
                          exceptionsChecks.length
                      }
                      indeterminate={
                        selectedExceptionChecks.length > 0 &&
                        selectedExceptionChecks.length !==
                          exceptionsChecks.length
                      }
                      disabled={exceptionsChecks.length === 0}
                      onChange={(e, checked) =>
                        handleAllGroupCheckChange("exceptions", checked)
                      }
                    />
                  </TableCell>
                  <TableCell sx={{ width: "75px" }}>
                    <TableSortLabel
                      onClick={() =>
                        handleExceptionsSortClick(goalsTableHeaders[1])
                      }
                      direction={
                        exceptionsSort.direction === "ASC" ? "asc" : "desc"
                      }
                      active={goalsTableHeaders[1].id === exceptionsSort.field}
                      sx={{ color: "text.secondary", fontWeight: 700 }}
                    >
                      {goalsTableHeaders[1].label}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <Flex justifyContent="space-between">
                      <TableSortLabel
                        onClick={() =>
                          handleExceptionsSortClick(goalsTableHeaders[2])
                        }
                        direction={
                          exceptionsSort.direction === "ASC" ? "asc" : "desc"
                        }
                        active={
                          goalsTableHeaders[2].id === exceptionsSort.field
                        }
                        sx={{ color: "text.secondary", fontWeight: 700 }}
                      >
                        {goalsTableHeaders[2].label}
                      </TableSortLabel>
                      {selectedExceptionChecks.length > 0 && (
                        <Button
                          variant="text"
                          sx={{
                            fontSize: "12px",
                            fontWeight: 700,
                            color: "text.secondary",
                            my: -1,
                          }}
                          endIcon={<KeyboardArrowDownIcon />}
                          onClick={handleExceptionsMoveChecksClick}
                        >
                          Move {selectedExceptionChecks.length} to
                        </Button>
                      )}
                      <Menu
                        anchorEl={exceptionsMoveMenuAnchorEl}
                        open={exceptionsMoveMenuOpen}
                        onClose={handleExceptionsMoveMenuClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem onClick={handleAddMilestoneClick}>
                          <ListItemIcon>
                            <AddIcon />
                          </ListItemIcon>
                          <ListItemText>New Milestone</ListItemText>
                        </MenuItem>
                        {planner.milestonesList.map((group) => (
                          <MenuItem
                            key={group.uid}
                            onClick={() =>
                              handleMoveChecksToClick(
                                selectedExceptionChecks,
                                group.uid,
                              )
                            }
                          >
                            <ListItemIcon>
                              {group.uid === FUTURE_GOALS_UID ? (
                                <LightbulbIcon />
                              ) : (
                                <AccessTimeIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText>
                              {planner.milestoneTitle(group.uid)}
                            </ListItemText>
                          </MenuItem>
                        ))}
                      </Menu>
                    </Flex>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {exceptionsChecks
                  .filter((c) =>
                    c.mquery.title
                      .toLowerCase()
                      .includes(searchValue.toLowerCase()),
                  )
                  .sort(sortBy(exceptionsSort))
                  .map((check) => (
                    <TableRow key={check.mquery.mrn}>
                      <TableCell sx={{ width: "60px", pr: 0 }}>
                        <Checkbox
                          checked={selectedChecks.includes(check.mquery.mrn)}
                          onChange={(e, checked) =>
                            handleCheckChange(check.mquery.mrn, checked)
                          }
                        />
                      </TableCell>
                      <TableCell sx={{ width: "75px" }}>
                        <ImpactRadial
                          impactScore={check.mquery.impact?.value!}
                          completion={check.completion}
                        />
                      </TableCell>
                      <TableCell>{check.mquery.title}</TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <CreateMilestonalModal
            open={createModalOpen}
            handleClose={handleCreateModalClose}
            handleCreate={handleCreateModalSubmit}
          />
        </Paper>
      </Grid>
    </Grid>
  );
}

type PlanEditorReviewProps = {
  planner: RiskPlanner;
};

function PlanEditorReview({ planner }: PlanEditorReviewProps) {
  const [searchValue, setSearchValue] = useState("");

  const groups = [
    ...planner.milestonesList.map((m) => m.uid),
    "active",
    "exceptions",
  ];

  const plannedSummary = planner.checksScoreSummary(planner.milestoneChecks);
  const plannedGradeScore = planner.checksGradeScore(planner.milestoneChecks);
  const activeSummary = planner.checksScoreSummary(planner.activeChecks);
  const activeGradeScore = planner.checksGradeScore(planner.activeChecks);

  return (
    <Box>
      <Grid container spacing={3}>
        <HubCard
          {...{
            loading: false,
            sizes: { xs: 12, md: 6 },
            title: "Scored",
            icon: <BarChartIcon />,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-end",
              height: 1,
            }}
          >
            <Flex sx={{ width: 1 }}>
              <GradeBlock scoreNumber={activeGradeScore} />
              <Box sx={{ width: (theme) => theme.spacing(2) }} />
              <ScoreBarChart data={activeSummary} disableTotal />
            </Flex>
          </Box>
        </HubCard>
        <HubCard
          {...{
            loading: false,
            sizes: { xs: 12, md: 6 },
            title: "Planned",
            icon: <TimerOutlinedIcon />,
          }}
        >
          <Box sx={{ pt: 3, pb: 1 }}>
            <Flex sx={{ width: 1 }}>
              <GradeBlock scoreNumber={plannedGradeScore} />
              <Box sx={{ width: (theme) => theme.spacing(2) }} />
              <ScoreBarChart data={plannedSummary} disableTotal />
            </Flex>
          </Box>
        </HubCard>
        <Grid item xs={12}>
          <Search
            name="search-plan-checks"
            value={searchValue}
            onChangeHandler={(o) =>
              setSearchValue(
                Array.isArray(o.value) ? o.value.join("") : o.value,
              )
            }
            fullWidth
          />
        </Grid>
      </Grid>
      {groups.map((g, i) => {
        const m = planner.milestones[g];
        const id = g;
        const title = !m ? g : planner.milestoneTitle(g);
        const icon =
          g === "active" ? (
            <BarChartIcon />
          ) : g === "exceptions" ? (
            <BlockOutlined />
          ) : g === FUTURE_GOALS_UID ? (
            <LightbulbIcon />
          ) : (
            <AccessTimeIcon />
          );
        const checks = planner
          .checksForGroup(g)
          .filter((c) =>
            c.mquery.title.toLowerCase().includes(searchValue.toLowerCase()),
          );
        return (
          <ChecksList
            key={id}
            id={id}
            checks={checks}
            title={title}
            icon={icon}
          />
        );
      })}
    </Box>
  );
}

type ChecksListProps = {
  checks: DraftCheck[];
  id: string;
  title: string;
  icon: ReactNode;
};

export function ChecksList({ checks, id, title, icon }: ChecksListProps) {
  checks = [...checks];

  const [sort, setSort] = useState<Sort>({
    field: "IMPACT",
    direction: "ASC",
  });

  const sortDirection = (sort: Sort, header: Header) => {
    return header.id !== sort.field
      ? "DESC"
      : sort.direction === "ASC"
        ? "DESC"
        : "ASC";
  };

  const handleSortClick = (header: Header) => {
    const direction = sortDirection(sort, header);
    setSort({ field: header.id, direction });
  };

  return checks.length > 0 ? (
    <Box sx={{ mt: 3, "&:first-of-type": { mt: 0 } }}>
      <CollapsibleList {...{ id, title, icon, total: checks.length }}>
        <TableContainer
          sx={{
            maxHeight: "450px",
            overflow: "auto",
            th: {
              backgroundColor: "background.light",
            },
            "th, td": {
              "&:nth-of-type(1), &:nth-of-type(2)": {
                width: "140px",
              },
              "&:last-of-type": {
                width: "50px",
              },
            },
          }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header.id} {...header.options}>
                    <TableSortLabel
                      onClick={() => handleSortClick(header)}
                      direction={sort.direction === "ASC" ? "asc" : "desc"}
                      active={header.id === sort.field}
                      sx={{ color: "text.secondary", fontWeight: 700 }}
                    >
                      {header.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {checks.sort(sortBy(sort)).map((check) => {
                const { __typename, ...scores } = check.scoreDistribution || {
                  pass: 0,
                  fail: 0,
                  error: 0,
                  unknown: 0,
                };
                const total = Object.values(scores).reduce(
                  (acc, x) => acc + x,
                  0,
                );

                const completion =
                  total === 0 ? 0 : Math.round((scores.pass / total) * 100);

                const fadeOut = Boolean(check.mquery.action !== "UNSPECIFIED");

                return (
                  <TableRow
                    key={check.mquery.mrn}
                    sx={{
                      ...(fadeOut ? { td: { color: "text.disabled" } } : {}),
                    }}
                  >
                    <TableCell>
                      <SeverityChip severity={check.mquery.impact?.value!} />
                    </TableCell>
                    <TableCell>
                      <Tooltip
                        title={`Completion: ${completion}%`}
                        arrow
                        placement="top"
                        PopperProps={{
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -8],
                              },
                            },
                          ],
                        }}
                      >
                        <Box sx={{ py: 1 }}>
                          <LinearProgress
                            variant="determinate"
                            value={completion}
                            sx={{
                              height: 8,
                              borderRadius: "2px",
                              backgroundColor: "background.default",
                              "& .MuiLinearProgress-bar": {
                                borderRadius: "2px",
                                background: (theme) =>
                                  fadeOut
                                    ? theme.palette.text.disabled
                                    : getColorByCompletion(
                                        theme,
                                        completion,
                                        true,
                                      ),
                              },
                            }}
                          />
                        </Box>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{check.mquery.title}</TableCell>
                    <TableCell>
                      {fadeOut && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {getPolicyReportScoreEntryActionIcon(
                            check.mquery.action,
                          )}
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </CollapsibleList>
    </Box>
  ) : (
    <Fragment></Fragment>
  );
}

export type Header = {
  id: string;
  label: string;
  sortable?: boolean;
  options?: TableCellProps;
};
//impact, completion, check, source

const tableHeaders: Header[] = [
  {
    id: "IMPACT",
    label: "Impact",
    options: {},
  },
  { id: "COMPLETION", label: "Completion", options: {} },
  {
    id: "CHECK",
    label: "Check",
    options: {},
  },
  { id: "ACTION", label: "", options: { align: "right" } },
];

const baselineTableHeaders: Header[] = [
  {
    id: "IMPACT",
    label: "Impact",
    options: {
      sx: {
        width: "75px",
      },
    },
  },
  {
    id: "CHECK",
    label: "Check",
    options: {},
  },
];

const goalsTableHeaders: Header[] = [
  {
    id: "SELECTED",
    label: "Selected",
    options: {
      sx: {
        width: "60px",
      },
    },
  },
  {
    id: "IMPACT",
    label: "Impact",
    options: {
      sx: {
        width: "75px",
      },
    },
  },
  {
    id: "CHECK",
    label: "Check",
    options: {},
  },
];
