import {
  ExceptionFormInput,
  SetExceptionDialog,
} from "~/components/exceptions/set-exception-dialog";
import { RemoveExceptionDialog } from "~/components/exceptions/remove-exception-dialog";
import { ExceptionTarget, SelectedEntity } from "~/components/exceptions/types";
import { ExceptionGroup } from "~/operations";

type ExceptionModalsProps = {
  isSetExceptionModalOpen: boolean;
  isRemoveExceptionModalOpen: boolean;
  onSetExceptionModalClose: () => void;
  onRemoveExceptionModalClose: () => void;
  onRemoveExceptionModalSave: () => void;
  onSetExceptionModalSave: (input: ExceptionFormInput) => Promise<void>;
  loading: boolean;
  target: ExceptionTarget;
  role: "security" | "compliance" | "asset";
  exceptionGroups: ExceptionGroup[];
  selectedEntities: SelectedEntity[];
};

export const ExceptionsModals = ({
  isSetExceptionModalOpen,
  isRemoveExceptionModalOpen,
  onSetExceptionModalClose,
  onRemoveExceptionModalClose,
  onRemoveExceptionModalSave,
  onSetExceptionModalSave,
  loading,
  target,
  role,
  exceptionGroups,
  selectedEntities,
}: ExceptionModalsProps) => {
  return (
    <>
      <SetExceptionDialog
        open={isSetExceptionModalOpen}
        onClose={onSetExceptionModalClose}
        onSave={onSetExceptionModalSave}
        loading={loading}
        target={target}
        role={role}
      />
      <RemoveExceptionDialog
        open={isRemoveExceptionModalOpen}
        onClose={onRemoveExceptionModalClose}
        onRemove={onRemoveExceptionModalSave}
        loading={loading}
        target={target}
        exceptionGroups={exceptionGroups}
        selectedEntities={selectedEntities}
      />
    </>
  );
};
