import { createSvgIcon } from "@mui/material";

export const AssetAIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path
      d="M12.01 11.9451H8.01961L7.07578 14.4034H5.46289L9.19045 4.54657H10.8272L14.5548 14.4034H12.9538L12.01 11.9451ZM8.54529 10.5848H11.4963L10.0148 6.44394L8.54529 10.5848Z"
      fill="#6CD9AF"
    />
    <path d="M10 7.27504e-06C8.68736 -0.00156101 7.38729 0.255327 6.17411 0.755973C4.96094 1.25662 3.85847 1.99121 2.92974 2.91773C2.001 3.84426 1.26423 4.94454 0.761542 6.15568C0.258854 7.36682 0.000113435 8.66505 0.000114374 9.97614C-0.00618645 11.2912 0.247905 12.5946 0.747772 13.8113C1.24764 15.0279 1.98342 16.1339 2.91278 17.0654C3.84214 17.997 4.94674 18.7359 6.16304 19.2395C7.37934 19.7431 8.68333 20.0016 10 20C11.3126 20.0016 12.6127 19.7447 13.8259 19.2441C15.0391 18.7434 16.1415 18.0088 17.0703 17.0823C17.999 16.1558 18.7358 15.0555 19.2385 13.8443C19.7411 12.6332 19.9999 11.335 19.9999 10.0239C20.0062 8.70878 19.7521 7.40541 19.2522 6.18876C18.7524 4.9721 18.0166 3.86616 17.0872 2.93458C16.1579 2.00299 15.0533 1.26412 13.837 0.760498C12.6207 0.256872 11.3167 -0.00158089 10 7.27504e-06ZM10 17.9594C8.94937 17.961 7.90875 17.7557 6.93764 17.3552C5.96654 16.9547 5.08402 16.3669 4.34055 15.6254C3.59709 14.8839 3.00728 14.0033 2.60486 13.034C2.20245 12.0646 1.99531 11.0255 1.99531 9.97614C1.99531 8.92676 2.20245 7.88767 2.60486 6.91831C3.00728 5.94896 3.59709 5.06835 4.34055 4.32688C5.08402 3.5854 5.96654 2.99761 6.93764 2.59712C7.90875 2.19662 8.94937 1.99129 10 1.99285C12.1198 1.99285 14.1528 2.83394 15.6517 4.3311C17.1507 5.82826 17.9927 7.85884 17.9927 9.97614C17.9927 12.0914 17.1523 14.1202 15.6559 15.617C14.1596 17.1138 12.1297 17.9563 10.0119 17.9594H10Z" />
  </svg>,
  "AssetA",
);
