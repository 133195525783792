import { createSvgIcon } from "@mui/material";

export const NetworkDevicesIcon = createSvgIcon(
  <svg viewBox="0 0 38 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.667969 8.0001L4.0013 11.3334C12.2846 3.0501 25.718 3.0501 34.0013 11.3334L37.3346 8.0001C27.218 -2.11657 10.8013 -2.11657 0.667969 8.0001ZM14.0013 21.3334L19.0013 26.3334L24.0013 21.3334C21.2513 18.5668 16.768 18.5668 14.0013 21.3334ZM7.33463 14.6668L10.668 18.0001C15.268 13.4001 22.7346 13.4001 27.3346 18.0001L30.668 14.6668C24.2346 8.23343 13.7846 8.23343 7.33463 14.6668Z"
      fill="url(#paint0_linear_7091_4308)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7091_4308"
        x1="0.667969"
        y1="0.412598"
        x2="26.1366"
        y2="34.6608"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6E45E2" />
        <stop offset="1" stopColor="#A22E95" />
      </linearGradient>
    </defs>
  </svg>,
  "Network Devices",
);
