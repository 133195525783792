import { Box, SxProps, Theme } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

type Props = {
  sx?: SxProps<Theme>;
};

export function IoOnTheMoon({ sx }: Props) {
  const { mode } = useColorMode();

  return (
    <Box
      component="svg"
      width="100%"
      height="auto"
      viewBox="0 0 293 515"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={sx}
    >
      <path
        d="M146.376 515C227.217 515 292.752 449.394 292.752 368.465C292.752 287.536 227.217 221.93 146.376 221.93C65.5347 221.93 0 287.536 0 368.465C0 449.394 65.5347 515 146.376 515Z"
        fill={
          mode === "light"
            ? "url(#paint0_radial_4292_40843)"
            : "url(#paint0_radial_4891_52949)"
        }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M175.403 240.545C192.737 244.432 205.242 255.639 203.346 265.627C201.45 275.614 185.831 280.495 168.496 276.608C151.161 272.722 138.656 261.514 140.552 251.527C142.448 241.539 158.068 236.659 175.403 240.545Z"
        fill={mode === "light" ? "#5F5D5D" : "#2B2B2B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.958 257.266C146.421 250.939 159.241 248.363 173.236 251.527C187.23 254.69 198.2 262.644 200.773 270.869C202.077 269.396 202.965 267.602 203.346 265.672C204.023 262.147 202.894 258.486 200.366 255.006C195.762 248.68 186.598 243.121 175.357 240.59C161.679 237.517 149.084 239.912 143.441 245.968C141.962 247.503 140.958 249.433 140.552 251.527C140.215 253.444 140.355 255.415 140.958 257.266Z"
        fill="#7A797A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.417 225.632C145.879 226.49 150.032 229.699 149.671 232.817C149.31 235.935 144.57 237.833 139.063 236.975C133.555 236.116 129.447 232.908 129.808 229.789C130.169 226.671 134.955 224.773 140.417 225.632Z"
        fill={mode === "light" ? "#5F5D5D" : "#2B2B2B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.3 279.5C161.724 280.54 164.884 283.432 164.388 286.008C163.891 288.584 159.873 289.759 155.449 288.72C151.025 287.68 147.865 284.788 148.362 282.257C148.858 279.726 152.876 278.461 157.3 279.5Z"
        fill={mode === "light" ? "#5F5D5D" : "#2B2B2B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.722 2.63656C146.105 -8.70661 190.57 17.5951 202.037 61.4764C205.542 74.8089 205.647 88.8089 202.342 102.193C199.037 115.576 192.428 127.915 183.122 138.077L185.018 145.262C189.893 163.926 105.702 185.98 100.826 167.316L98.8853 160.13C85.7879 155.858 73.9764 148.353 64.5404 138.306C55.1044 128.26 48.3479 115.996 44.8944 102.646C33.4281 58.783 59.295 14.043 102.722 2.63656Z"
        fill="#E8E8E8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.636 20.0538C141.365 8.34909 179.601 25.9739 193.956 59.3708C208.312 92.7676 193.46 129.328 160.731 140.987C128.003 152.647 89.7667 135.067 75.4112 101.67C61.0558 68.2736 75.9078 31.7133 108.636 20.0538Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.722 2.63656C146.105 -8.70661 190.57 17.5951 202.037 61.4764C213.503 105.358 187.636 150.098 144.209 161.486C100.781 172.875 56.3607 146.528 44.8944 102.646C33.4281 58.7649 59.295 14.043 102.722 2.63656ZM103.806 6.8846C62.771 17.6403 38.2584 59.9851 49.1378 101.516C60.0173 143.048 102.045 167.994 143.125 157.238C184.205 146.482 208.673 104.092 197.838 62.5611C187.004 21.0297 144.886 -3.85299 103.806 6.90268V6.8846Z"
        fill="#E5E5E5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.386 55.1676L109.449 61.7656C112.05 60.6499 114.719 59.699 117.439 58.9185C120.192 58.2833 122.981 57.8155 125.791 57.5175L126.107 49.7445C126.694 35.012 134.91 34.786 146.376 40.4802C155.043 44.7734 171.204 57.8791 182.987 72.3405C213.865 110.166 181.226 147.314 143.126 157.256C105.025 167.198 58.3925 150.839 66.8342 102.755C70.0393 84.3615 77.7136 65.0646 83.1759 57.0204C90.3988 46.4455 97.6216 42.6042 105.386 55.1676Z"
        fill="#8258E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.331 40.4803C134.865 34.7861 126.649 34.9669 126.062 49.7446L125.746 57.5176C122.936 57.8156 120.147 58.2834 117.394 58.9186C114.676 59.7063 112.008 60.657 109.404 61.7657L105.341 55.1676C97.6217 42.6043 90.3537 46.4456 83.1309 57.0205C79.3389 62.5791 74.4634 73.6511 70.7617 86.0337C71.6633 91.3917 73.2094 96.6208 75.3663 101.607C89.7669 135.004 127.958 152.629 160.686 140.924C176.847 135.139 188.675 123.344 194.769 108.792C196.439 97.3588 193.189 84.8407 182.897 72.2773C171.159 57.8792 155.043 44.7735 146.331 40.4803Z"
        fill="#9574EB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.6262 104.969C70.265 107.636 71.2582 108.675 71.7548 108.72C74.5988 92.0896 83.6273 78.4416 97.3508 68.7254C97.0799 65.8331 96.6285 62.7148 96.3576 60.9524C93.8748 44.6381 82.8599 64.6129 76.0885 82.9156C73.5064 90.0566 71.6758 97.4479 70.6262 104.969Z"
        fill="#E492BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.73 76.0915C182.355 78.2155 181.994 79.6616 181.587 79.8876C170.979 66.7819 156.398 59.3253 139.695 57.5628C138.521 54.8965 137.393 51.959 136.761 50.3321C130.982 34.8764 150.303 46.8975 165.155 59.5512C170.896 64.5196 176.114 70.0618 180.73 76.0915Z"
        fill="#E492BF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M157.436 150.613C122.631 162.092 82.0922 145.371 65.8408 113.194C65.7054 126.435 70.3099 136.739 77.7585 144.286C86.9672 151.252 97.633 156.037 108.955 158.284C120.276 160.53 131.959 160.179 143.125 157.256H143.171C150.995 155.205 158.446 151.926 165.245 147.54C162.714 148.739 160.105 149.765 157.436 150.613Z"
        fill="#7547DA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.203 136.106C230.748 133.734 223.945 132.451 217.07 132.31C217.153 128.227 216.881 124.144 216.257 120.108C224.67 120.188 233.013 121.654 240.95 124.447C252.326 128.559 246.232 139.496 237.203 136.106ZM210.298 156.578L211.968 158.386C220.591 167.56 232.96 180.44 219.868 192.28C216.889 194.991 212.781 197.025 208.222 198.471C205.456 199.304 202.635 199.938 199.78 200.369C197.432 196.121 192.873 192.641 192.873 192.641C205.739 191.286 219.778 185.501 208.808 172.757C206.732 170.362 204.43 168.102 203.166 166.746L202.669 166.204C205.569 163.296 208.128 160.066 210.298 156.578Z"
        fill="#8C68F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M101.639 177.141L89.225 204.889C88.5479 204.03 87.7804 203.443 86.7421 203.578C80.5576 204.527 79.2936 219.712 80.2867 223.553C81.5958 228.66 84.4398 230.467 88.2319 229.699C105.657 222.468 125.43 218.401 146.376 218.401C167.322 218.401 187.095 222.468 204.52 229.699C208.312 230.467 211.156 228.705 212.465 223.553C213.458 219.712 212.194 204.527 206.01 203.578C203.211 203.126 202.172 208.278 200.683 209.046C197.658 201.906 193.46 197.748 189.262 195.172C190.21 193.003 187.862 184.146 185.063 175.107C177.479 177.457 174.5 173.571 177.615 172.802C182.987 171.582 191.067 166.882 195.491 163.674C216.754 147.179 215.399 120.425 204.249 94.4397C202.94 91.3666 201.36 89.107 198.516 88.5195C196.168 84.9493 192.286 84.0455 190.616 84.4974C190.277 84.5849 189.963 84.7484 189.697 84.9755C189.432 85.2025 189.221 85.4872 189.081 85.808C185.966 83.1869 180.955 84.7686 180.323 88.8811C176.531 87.5253 173.326 93.4454 174.455 97.1964C172.965 99.3656 173.371 103.704 175.177 107.274C179.782 116.448 187.546 128.424 183.167 138.05L185.244 146.365C191.293 171.04 115.408 191.241 101.639 177.141Z"
        fill="#7E56EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.923 101.806C178.969 103.342 178.337 104.698 177.614 104.201C177.163 103.885 176.802 102.936 176.802 101.987C176.802 100.631 177.389 100.224 177.93 100.405C178.212 100.52 178.455 100.712 178.631 100.961C178.807 101.209 178.908 101.502 178.923 101.806ZM200.908 97.3317C201.991 100.45 201.585 105.602 195.626 108.675C189.577 111.838 180.052 108.494 180.819 102.077C181.722 94.3491 198.425 90.1462 200.908 97.3317ZM183.347 92.2703C184.025 94.9818 190.48 92.2251 189.983 90.6886C188.494 85.8078 182.174 87.7511 183.347 92.2703ZM196.033 91.6376C198.741 90.553 196.439 88.4741 194.949 87.6607C193.776 87.028 192.286 86.7569 191.473 87.6607C190.57 88.7001 192.827 91.502 193.866 91.8184C194.59 91.9792 195.345 91.9161 196.033 91.6376ZM179.601 97.8741C180.302 97.7121 180.934 97.3325 181.406 96.7895C182.038 95.8856 182.174 92.2703 180.819 91.9991C179.646 91.7731 178.607 92.8578 178.066 94.078C177.343 95.5693 176.757 98.6423 179.601 97.8741Z"
        fill="#A484FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M92.0688 213.683C91.4819 216.033 87.9156 215.174 88.5025 212.824C89.0429 210.845 89.7372 208.911 90.579 207.04C90.1961 206.3 89.7585 205.59 89.2699 204.916L101.278 178.026L101.684 177.168C102.903 178.433 104.618 179.382 106.695 180.105C106.56 180.06 106.379 180.015 106.244 179.97C104.348 186.297 103.174 191.087 103.264 193.708C104.68 193.261 106.159 193.047 107.643 193.075C109.358 193.075 109.674 195.606 107.417 195.606C94.6419 195.76 92.0688 213.656 92.0688 213.683Z"
        fill="#5E33D3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.48 222.333C110.307 209.86 111.029 201.183 122.676 195.037C123.804 194.449 124.391 194.088 124.301 192.822C124.166 191.015 123.579 187.851 122.631 181.66C116.356 181.976 110.758 181.479 106.695 180.078C106.56 180.033 106.379 179.988 106.244 179.943C104.348 186.269 103.174 191.06 103.264 193.681C104.68 193.234 106.159 193.02 107.643 193.048C109.359 193.048 109.72 195.579 107.418 195.579C94.6873 195.76 92.1142 213.656 92.1142 213.701C91.5273 216.051 87.961 215.192 88.5479 212.842C89.0883 210.863 89.7826 208.929 90.6245 207.058C90.2415 206.318 89.8039 205.608 89.3153 204.934C88.6382 204.075 87.8707 203.488 86.8325 203.623C80.6479 204.572 79.3839 219.757 80.377 223.598C81.6862 228.705 84.485 230.512 88.277 229.744H88.3222C95.8102 226.6 103.559 224.12 111.48 222.333Z"
        fill="#7148DF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.506 132.175C127.506 132.175 131.253 141.258 138.611 139.315C145.97 137.372 144.931 127.204 144.931 127.204L138.657 126.887C138.657 126.887 135.542 126.345 134.639 126.752C133.736 127.159 127.506 132.175 127.506 132.175Z"
        fill="#FF7FBA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.944 130.909C129.944 130.909 129.718 132.988 129.582 134.028C129.447 134.886 128.589 135.112 128.048 134.389C127.461 133.576 126.422 132.265 126.422 132.265C126.422 132.265 127.641 132.175 129.944 130.909ZM142.222 127.656C142.222 127.656 143.577 129.237 144.299 130.006C144.886 130.638 145.744 130.322 145.744 129.418C145.744 128.424 145.834 126.752 145.834 126.752C145.834 126.752 144.796 127.339 142.222 127.656Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M135.045 126.255C131.479 127.113 129.853 132.627 124.165 130.548C123.628 130.347 123.114 130.089 122.631 129.78C122.559 129.729 122.479 129.693 122.394 129.674C122.309 129.655 122.221 129.652 122.135 129.667C122.049 129.682 121.967 129.713 121.893 129.759C121.82 129.806 121.756 129.867 121.705 129.938C121.655 130.009 121.619 130.089 121.6 130.175C121.58 130.26 121.578 130.348 121.593 130.434C121.607 130.52 121.639 130.602 121.685 130.676C121.732 130.75 121.792 130.814 121.863 130.864C122.436 131.26 123.058 131.579 123.714 131.813C130.485 134.254 132.065 127.882 135.225 126.978C138.431 126.209 142.945 131 147.64 125.532C148.108 124.999 148.502 124.406 148.814 123.769C148.855 123.688 148.88 123.599 148.887 123.508C148.893 123.417 148.881 123.326 148.851 123.24C148.821 123.154 148.773 123.075 148.712 123.008C148.65 122.941 148.575 122.887 148.492 122.85C148.408 122.813 148.318 122.793 148.227 122.792C148.136 122.791 148.046 122.809 147.962 122.844C147.878 122.88 147.802 122.932 147.739 122.998C147.676 123.063 147.627 123.141 147.595 123.227C147.333 123.742 147.015 124.227 146.647 124.673C142.674 129.237 138.566 125.215 135.045 126.255ZM98.3437 124.583C98.2248 124.767 98.0691 124.925 97.8862 125.047C97.7034 125.169 97.4975 125.251 97.2813 125.289C97.0652 125.327 96.8435 125.32 96.6301 125.269C96.4168 125.217 96.2163 125.122 96.0415 124.989C95.6089 124.695 95.2974 124.253 95.1647 123.746C95.0319 123.24 95.0868 122.702 95.3192 122.233C101.639 109.76 114.234 110.618 121.863 123.227C123.037 125.17 121.186 126.3 120.148 124.628C113.737 114.008 103.58 114.324 98.3437 124.583ZM166.194 106.822C166.916 107.319 167.954 106.913 168.405 106.054C168.642 105.585 168.699 105.046 168.566 104.539C168.433 104.031 168.119 103.589 167.683 103.297C156.082 95.5241 145.518 102.438 145.021 117.171C144.931 119.476 147.143 119.521 147.188 117.533C147.595 105.105 156.623 100.405 166.194 106.822Z"
        fill="#4000C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M140.417 130.232C142.494 132.039 134.819 142.343 129.673 133.531C129.673 133.711 129.628 133.892 129.628 134.028C129.621 134.201 129.561 134.367 129.456 134.505C129.351 134.642 129.206 134.744 129.041 134.796C130.756 137.282 134.007 140.535 138.656 139.27C143.306 138.005 144.615 133.44 144.931 130.277C144.688 130.257 144.461 130.144 144.299 129.96C143.848 129.463 143.126 128.695 142.674 128.153C139.83 128.243 137.257 126.436 135.226 126.933C134.187 127.204 133.33 128.108 132.427 129.057C133.691 128.108 134.458 127.475 135.858 127.882C137.031 128.243 139.559 129.463 140.417 130.232Z"
        fill="#FB6DAF"
      />
      <path
        d="M174.861 194.675L171.836 195.398L168.586 186.568L166.825 194.838L164.523 195.38L159.151 188.872L160.28 198.182L157.255 198.905L155.495 183.675L156.984 183.314C157.283 183.238 157.597 183.254 157.887 183.359C158.169 183.454 158.42 183.626 158.609 183.856L164.839 191.765L166.735 181.913C166.796 181.61 166.936 181.33 167.141 181.099C167.358 180.891 167.621 180.736 167.909 180.647L169.444 180.286L174.861 194.675Z"
        fill="white"
      />
      <path
        d="M96.8808 474.866C102.519 466.396 96.2904 452.325 82.9688 443.438C69.6473 434.55 54.2773 434.212 48.6391 442.682C43.0009 451.151 49.2294 465.222 62.551 474.109C75.8726 482.997 91.2425 483.335 96.8808 474.866Z"
        fill={mode === "light" ? "#3E3D3D" : "#191919"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5947 471.006C56.6905 463.066 49.4135 451.75 49.0163 442.178C48.8944 442.345 48.7635 442.508 48.6506 442.684C43.0123 451.153 49.2375 465.226 62.5591 474.111C72.7614 480.917 84.16 482.697 91.4506 479.366C84.2503 478.847 76.1471 476.054 68.5947 471.006Z"
        fill="#595959"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.9145 426.46C44.6417 423.297 43.4002 419.202 45.1382 417.241C46.8762 415.28 50.9346 416.337 54.2074 419.501C57.4803 422.664 58.7217 426.758 57.0063 428.679C55.2909 430.6 51.1874 429.615 47.9145 426.46Z"
        fill={mode === "light" ? "#3E3D3D" : "#191919"}
      />
      <path
        d="M108.966 493.498C113.082 493.498 116.419 491.604 116.419 489.268C116.419 486.932 113.082 485.038 108.966 485.038C104.85 485.038 101.513 486.932 101.513 489.268C101.513 491.604 104.85 493.498 108.966 493.498Z"
        fill={mode === "light" ? "#3E3D3D" : "#191919"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M247.997 292.52C253.116 298.897 254.403 306.53 250.859 309.567C247.316 312.604 240.296 309.879 235.177 303.502C230.057 297.125 228.775 289.492 232.319 286.456C235.863 283.419 242.878 286.144 247.997 292.52Z"
        fill={mode === "light" ? "#5F5D5D" : "#2B2B2B"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.82 288.638C234.598 287.933 239.948 290.69 244.069 295.846C248.191 301.003 249.861 307.013 248.43 310.71C249.321 310.556 250.155 310.166 250.845 309.58C252.1 308.509 252.741 306.869 252.831 304.889C252.989 301.274 251.288 296.66 247.97 292.52C243.907 287.486 238.715 284.729 234.95 285.289C233.979 285.415 233.064 285.82 232.319 286.455C231.649 287.05 231.134 287.799 230.82 288.638Z"
        fill="#7A797A"
      />
      <path
        d="M217.642 263.145C218.566 261.986 217.685 259.744 215.674 258.137C213.662 256.531 211.283 256.168 210.36 257.327C209.436 258.485 210.317 260.727 212.328 262.334C214.34 263.94 216.719 264.303 217.642 263.145Z"
        fill={mode === "light" ? "#5F5D5D" : "#2B2B2B"}
      />
      <defs>
        <radialGradient
          id="paint0_radial_4891_52949"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(146.931 207.785) scale(285.235 285.545)"
        >
          <stop offset="0.01" stopColor="#7A797A" />
          <stop offset="0.16" stopColor="#717071" />
          <stop offset="0.42" stopColor="#575757" />
          <stop offset="0.75" stopColor="#2E2E2E" />
          <stop offset="0.85" stopColor="#1F1F1F" />
        </radialGradient>
        <radialGradient
          id="paint0_radial_4292_40843"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(146.931 208.035) scale(285.235 285.545)"
        >
          <stop offset="0.01" stopColor="#C4C1C4" />
          <stop offset="0.16" stopColor="#B7B7B7" />
          <stop offset="0.42" stopColor="#9B999B" />
          <stop offset="0.75" stopColor="#646264" />
          <stop offset="0.85" stopColor="#4D4C4D" />
        </radialGradient>
      </defs>
    </Box>
  );
}
