import { createSvgIcon } from "@mui/material";

export const LongLivedCredsIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.6364 10.6667H12.9431C12.3841 9.11333 10.8705 8 9.09091 8C6.83409 8 5 9.79333 5 12C5 14.2067 6.83409 16 9.09091 16C10.8705 16 12.3841 14.8867 12.9431 13.3333H13.1819L14.5455 14.6667L15.9091 13.3333L17.2727 14.6667L20 11.9733L18.6364 10.6667ZM9.09091 14C7.96591 14 7.04545 13.1 7.04545 12C7.04545 10.9 7.96591 10 9.09091 10C10.2159 10 11.1364 10.9 11.1364 12C11.1364 13.1 10.2159 14 9.09091 14Z"
      fill="url(#paint0_linear_3039_21027)"
    />
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="url(#paint1_linear_3039_21027)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3039_21027"
        x1="5"
        y1="8"
        x2="12.0048"
        y2="20.4855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3039_21027"
        x1="1.47368"
        y1="1.47368"
        x2="23.0454"
        y2="21.9803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9147FF" />
        <stop offset="1" stopColor="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Long Lived Credentials",
);
