import React from "react";
import { Grid } from "@mui/material";
import { EmptySection } from "~/components/vulnerabilities";
import { CasesSkeleton } from "~/components/cases/components/CasesPage/components";
import { ListCaseEdges } from "~/components/cases/types";
import { CasesTable } from "~/components/cases/components/CasesTable/CasesTable";
import { PageInfo, useGetCasesQuery } from "~/operations";
import { Space } from "~/lib/types";

type ClosedCasesProps = {
  closedCases: ListCaseEdges;
  hasCloseCasePermissions: boolean;
  isLoading: boolean;
  fetchMoreClosedCases: ReturnType<typeof useGetCasesQuery>["fetchMore"];
  closedCasesTotalCount: number;
  closedCasesPageInfo: PageInfo | undefined;
  space: Space;
};

export const ClosedCases = ({
  closedCases,
  isLoading,
  closedCasesTotalCount,
  fetchMoreClosedCases,
  closedCasesPageInfo,
  space,
}: ClosedCasesProps) => {
  const isEmpty = closedCases.length === 0;

  return (
    <Grid item xs={12}>
      {isLoading ? (
        <CasesSkeleton />
      ) : isEmpty ? (
        <EmptySection
          id="closed-cases-empty"
          text="There are currently no closed cases."
        />
      ) : (
        <CasesTable
          isLoading={isLoading}
          cases={closedCases}
          fetchMore={fetchMoreClosedCases}
          pageInfo={closedCasesPageInfo}
          totalCount={closedCasesTotalCount}
          space={space}
        />
      )}
    </Grid>
  );
};
