import { ClientIntegrationType, IntegrationType } from "~/operations";

// Ticketing systems should only show the total count
export const ticketingIntegrationTypes: Array<
  ClientIntegrationType | IntegrationType
> = [
  IntegrationType.TicketSystemEmail,
  IntegrationType.TicketSystemJira,
  IntegrationType.TicketSystemGithub,
  IntegrationType.TicketSystemGitlab,
  IntegrationType.TicketSystemZendesk,
  IntegrationType.TicketSystemAzureDevops,
];
