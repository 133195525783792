import { styled } from "@mui/material/styles";

type Props = {
  percentage: number;
};

export function MondooLoadingBar({ percentage }: Props) {
  return (
    <LoadingBlockContainer>
      <svg xmlns="http://www.w3.org/200/svg">
        <defs>
          <mask id="mask">
            <rect
              width="100%"
              height="100%"
              x={`${percentage}%`}
              y="0%"
              fill="#ffffff"
            />
          </mask>

          <linearGradient id="loadingGradient">
            <stop offset="10%" stopColor="#4d21ae" />
            <stop offset="100%" stopColor="#a029ef" />
          </linearGradient>
        </defs>

        <rect className="bar-gradient" width="100%" height="100%" />

        <g mask="url(#mask)">
          <rect className="bar-background" width="100%" height="100%" />
        </g>
      </svg>
    </LoadingBlockContainer>
  );
}

const LoadingBlockContainer = styled("div")`
  display: flex;
  height: 20px;
  width: 100%;

  svg {
    height: 100%;
    width: 100%;
  }

  .bar-background {
    fill: ${(p) => p.theme.palette.background.light};
  }

  .bar-gradient {
    fill: url(#loadingGradient);
  }
`;
