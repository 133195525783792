import { createSvgIcon } from "@mui/material";

export const ResourceIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M10.0628 19.9912H9.91441C9.8656 20.0029 9.81488 20.0029 9.76606 19.9912L1.85786 15.7731C1.75177 15.7177 1.66249 15.6324 1.60002 15.5269C1.53754 15.4214 1.50433 15.2998 1.5041 15.1756V4.82761C1.49863 4.77599 1.49863 4.72389 1.5041 4.67227C1.51882 4.60379 1.54594 4.53889 1.58398 4.48108C1.62696 4.42201 1.68141 4.37314 1.74374 4.33769C1.77649 4.29946 1.81505 4.26716 1.85786 4.2421H1.91491L9.73183 0.0718173C9.82017 0.0246116 9.91794 0 10.0171 0C10.1163 0 10.2141 0.0246116 10.3024 0.0718173L18.2106 4.20625C18.2534 4.23131 18.292 4.26361 18.3247 4.30184C18.365 4.34008 18.3996 4.38438 18.4274 4.43329C18.462 4.49172 18.4852 4.55667 18.4959 4.62447C18.5014 4.6761 18.5014 4.72819 18.4959 4.77981V15.1278C18.4957 15.252 18.4625 15.3736 18.4 15.4791C18.3375 15.5847 18.2482 15.6699 18.1421 15.7253L10.3595 19.9075C10.3104 19.9172 10.2601 19.9172 10.2111 19.9075L10.0628 19.9912ZM10.7018 9.36832V18.2346L17.3433 14.7574V5.96279L10.7018 9.36832ZM2.7936 14.7574L9.42372 18.2346V9.36832L2.7936 5.96279V14.7574ZM3.581 4.76786L10.0628 8.20924L16.5788 4.76786L10.0628 1.42208L3.581 4.76786Z" />
  </svg>,
  "Resource",
);
