import { useEffect, useState } from "react";
import { AutocompleteProps } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

export function useSearch() {
  let navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();
  const [searchFilters, setSearchFilters] = useState<string[]>(
    () => searchParams.get("queryTerms")?.split(",") || [],
  );

  useEffect(() => {
    const nextSearchFilters = searchParams.get("queryTerms");
    if (nextSearchFilters) {
      setSearchFilters(nextSearchFilters.split(","));
    } else {
      setSearchFilters([]);
    }
  }, [searchParams]);

  const onTagDelete = (value: string) => {
    const queryTerms = searchParams.get("queryTerms");

    if (queryTerms) {
      const qtArray = queryTerms.split(",");
      if (qtArray.length <= 1) {
        searchParams.delete("queryTerms");
      } else {
        const found = qtArray.indexOf(value);
        qtArray.splice(found, 1);
        searchParams.set("queryTerms", qtArray.join(","));
      }

      navigate(`${location.pathname}?${searchParams}`);
    }
  };

  const handleFilterQuery: AutocompleteProps<
    string,
    true,
    false,
    true
  >["onChange"] = (_event, query, reason) => {
    if (reason === "clear") {
      searchParams.delete("queryTerms");
    } else {
      searchParams.set("queryTerms", query.join(","));
    }

    navigate(`${location.pathname}?${searchParams}`);
  };

  const handleClearSearch = (params = ["queryTerms"]) => {
    params.forEach((filterParam) => {
      searchParams.delete(filterParam);
    });

    navigate(`${location.pathname}?${searchParams}`);
  };

  return { onTagDelete, handleFilterQuery, handleClearSearch, searchFilters };
}
