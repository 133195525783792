import { Box, Typography } from "@mui/material";
import { isSameMinute, FormatDate } from "~/lib/date";
import { AccessTimeIcon } from "./icons";

type Props = {
  lastScan?: string | null;
  updatedAt?: string | null;
};

export function LastOperations({ lastScan, updatedAt }: Props) {
  const operations = [
    // if lastScan or updatedAt is null, or the last scan and last check in are
    // the same, we will not display the last scanned timestamp
    ...(!lastScan ||
    !updatedAt ||
    isSameMinute(new Date(updatedAt), new Date(lastScan))
      ? []
      : [{ id: 1, display: "Scanned", timestamp: lastScan }]),
    { id: 2, display: "Updated", timestamp: updatedAt },
  ];

  return (
    <Box>
      {operations.map((x) => (
        <Box
          key={x.id}
          sx={{
            display: {
              xs: "block",
              sm: "inline-block",
            },
          }}
        >
          <AccessTimeIcon
            sx={{
              ml: {
                xs: 0,
                sm: 2.25,
              },
              fontSize: 16,
              height: 28,
              verticalAlign: "middle",
            }}
          />
          <Typography
            display="inline"
            fontSize={12}
            lineHeight={"31.92px"}
            sx={{ ml: 0.75 }}
          >
            {/* Last Check-in: April 05, 10:10am */}
            Last {x.display}: {FormatDate(x.timestamp)}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
