export const ShareLogo = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2052 18.188C11.08 18.3447 10.8294 18.2193 10.8606 18.0314L10.8605 18.0313C11.2677 16.1521 12.9904 11.3911 20.758 9.95047C20.852 9.95047 20.9147 9.85653 20.9147 9.76258V7.19413C20.9147 7.03748 21.1028 6.94353 21.228 7.03748L27.9307 12.2369C28.0247 12.3308 28.0247 12.4562 27.9307 12.5502L21.228 18.0941C21.1026 18.188 20.9147 18.0941 20.9147 17.9374V15.4005C20.9147 15.3065 20.8207 15.2126 20.7268 15.2126C19.6306 15.1499 13.9927 14.9619 11.2052 18.188ZM25.2362 25.2663H6.56873L6.56845 25.2666C5.15905 25.2666 4 24.1076 4 22.6667V10.3575C4 8.9168 5.15905 7.72655 6.56845 7.72677H12.77C13.4591 7.72677 14.0228 8.29044 14.0228 8.9795C14.0228 9.66857 13.4591 10.2322 12.77 10.2322L6.56845 10.2635C6.53699 10.2635 6.50575 10.2947 6.50575 10.3574V22.6667C6.50575 22.7294 6.53721 22.7606 6.56845 22.7606H25.2364C25.2679 22.7606 25.2991 22.7294 25.2991 22.6667V18.5948C25.2991 17.9058 25.8628 17.3421 26.5519 17.3421C27.2409 17.3421 27.8046 17.9058 27.8046 18.5948V22.6667C27.8046 24.1075 26.6456 25.2663 25.2362 25.2663Z"
      />
    </svg>
  );
};

ShareLogo.displayName = "ShareLogo";
