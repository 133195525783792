import { createSvgIcon } from "@mui/material";

export const VSCodeIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_4534_3985)">
      <mask
        id="mask0_4534_3985"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="22"
        height="22"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.6006 22.8498C16.9471 22.9848 17.3422 22.9761 17.6919 22.8078L22.2214 20.6284C22.6973 20.3993 23 19.9177 23 19.3892V4.61096C23 4.08252 22.6973 3.60083 22.2214 3.37181L17.6919 1.19226C17.233 0.971402 16.6958 1.0255 16.293 1.31836C16.2354 1.36019 16.1806 1.4069 16.1292 1.45838L7.45812 9.36916L3.68118 6.50214C3.32958 6.23526 2.8378 6.25713 2.51125 6.55417L1.29987 7.65611C0.900439 8.01944 0.899981 8.64782 1.29888 9.01177L4.57436 12.0001L1.29888 14.9883C0.899981 15.3523 0.900439 15.9807 1.29987 16.344L2.51125 17.4459C2.8378 17.743 3.32958 17.7648 3.68118 17.4979L7.45812 14.6309L16.1292 22.5417C16.2663 22.679 16.4274 22.7823 16.6006 22.8498ZM17.5033 7.00579L10.924 12.0001L17.5033 16.9943V7.00579Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_4534_3985)">
        <path
          d="M22.2212 3.3752L17.6882 1.19265C17.1635 0.940022 16.5365 1.04659 16.1247 1.45836L1.28539 14.9883C0.886248 15.3522 0.886706 15.9806 1.28638 16.344L2.4985 17.4459C2.82524 17.743 3.31733 17.7648 3.66914 17.4979L21.5391 3.94141C22.1386 3.4866 22.9997 3.9142 22.9997 4.66671V4.61408C22.9997 4.08586 22.6971 3.60435 22.2212 3.3752Z"
          fill="#0065A9"
        />
        <g filter="url(#filter0_d_4534_3985)">
          <path
            d="M22.2212 20.6249L17.6882 22.8075C17.1635 23.0601 16.5365 22.9535 16.1247 22.5417L1.28539 9.01179C0.886248 8.64786 0.886706 8.01946 1.28638 7.65613L2.4985 6.55419C2.82524 6.25715 3.31733 6.23528 3.66914 6.50216L21.5391 20.0587C22.1386 20.5135 22.9997 20.0859 22.9997 19.3334V19.386C22.9997 19.9142 22.6971 20.3958 22.2212 20.6249Z"
            fill="#007ACC"
          />
        </g>
        <g filter="url(#filter1_d_4534_3985)">
          <path
            d="M17.6887 22.8078C17.1639 23.0603 16.5368 22.9535 16.125 22.5417C16.6324 23.0491 17.5 22.6897 17.5 21.9722V2.02793C17.5 1.31034 16.6324 0.950974 16.125 1.45838C16.5368 1.04657 17.1639 0.939851 17.6887 1.19226L22.2209 3.37181C22.6971 3.60083 23 4.08252 23 4.61096V19.3892C23 19.9177 22.6971 20.3993 22.2209 20.6284L17.6887 22.8078Z"
            fill="#1F9CF0"
          />
        </g>
        <g opacity="0.25" style={{ mixBlendMode: "overlay" }}>
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.5869 22.8499C16.9334 22.9848 17.3286 22.9762 17.6783 22.8079L22.2077 20.6284C22.6836 20.3994 22.9863 19.9177 22.9863 19.3893V4.61103C22.9863 4.08257 22.6837 3.60088 22.2077 3.37186L17.6783 1.19232C17.2193 0.971463 16.6821 1.02556 16.2793 1.31842C16.2218 1.36026 16.1669 1.40697 16.1155 1.45844L7.44445 9.36921L3.66751 6.50222C3.31593 6.23531 2.82413 6.2572 2.49758 6.55422L1.2862 7.65616C0.886767 8.01951 0.886309 8.6479 1.28521 9.01182L4.56069 12.0001L1.28521 14.9884C0.886309 15.3523 0.886767 15.9807 1.2862 16.344L2.49758 17.446C2.82413 17.743 3.31593 17.7649 3.66751 17.498L7.44445 14.631L16.1155 22.5418C16.2527 22.679 16.4137 22.7824 16.5869 22.8499ZM17.4897 7.00586L10.9103 12.0001L17.4897 16.9944V7.00586Z"
            fill="url(#paint0_linear_4534_3985)"
          />
        </g>
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_4534_3985"
        x="-7.34701"
        y="-2.01754"
        width="38.6804"
        height="33.2941"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4.16667" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="overlay"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4534_3985"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4534_3985"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d_4534_3985"
        x="7.79166"
        y="-7.27658"
        width="23.5417"
        height="38.5533"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4.16667" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="overlay"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4534_3985"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4534_3985"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_4534_3985"
        x1="11.9863"
        y1="1.05682"
        x2="11.9863"
        y2="22.9434"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_4534_3985">
        <rect width="22" height="22" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>,
  "VSCode",
);
