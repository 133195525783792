import { useSnackbar } from "notistack";
import {
  Typography,
  TableCell,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
} from "@mui/material";
import { LoadingPage, LoadingFailedPage } from "~/components/loading";
import { LoadMore } from "~/components/load-more";
import { FormatDate } from "~/lib/date";
import { NoPermission } from "~/components/no-permission";
import { IamActions } from "~/lib/iam";
import { useAuditLogForwardPaginationQuery } from "~/operations";

const Loading = <LoadingPage what="Audit Logs" />;
const LoadingFailed = <LoadingFailedPage what="Audit Logs" />;

export type AuditLogProps = {
  resourceMrn: string;
  availablePermissions: string[] | null;
};

export function AuditLog({ resourceMrn, availablePermissions }: AuditLogProps) {
  const pageSize = 25;

  const hasListLogEntriesPermission = availablePermissions?.includes(
    IamActions.ACTION_MONDOO_AUDITLOG_AUDITLOGMANAGER_LISTLOGENTRIES,
  );

  const { loading, data, error, fetchMore } = useAuditLogForwardPaginationQuery(
    {
      variables: { resourceMrn, first: pageSize },
      skip: !hasListLogEntriesPermission,
    },
  );

  const { enqueueSnackbar } = useSnackbar();

  if (!hasListLogEntriesPermission) {
    return <NoPermission />;
  }

  if (loading) {
    return Loading;
  }

  if (error || !data || !data.auditlog || !data.auditlog.pageInfo) {
    return LoadingFailed;
  }

  const auditLog =
    data.auditlog.edges?.flatMap((edge) => edge.node ?? []) || [];
  const hasNextPage = data.auditlog.pageInfo.hasNextPage;
  const endCursor = data.auditlog.pageInfo.endCursor;

  const loadMore = async () => {
    try {
      await fetchMore({
        variables: {
          after: endCursor,
          first: pageSize,
        },
      });
    } catch (error) {
      enqueueSnackbar("Failed to load auditlog", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Subject</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {auditLog.map((item, index) => (
              <TableRow key={index}>
                <TableCell sx={{ verticalAlign: "top" }}>
                  <Typography variant="body1">
                    {item.identity.name || item.identity.mrn}
                  </Typography>
                </TableCell>
                <TableCell sx={{ verticalAlign: "top" }}>
                  <Typography variant="body1">{item.msg}</Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    {item.action}
                  </Typography>
                  <Typography
                    variant="caption"
                    color="text.secondary"
                    display="block"
                  >
                    {item.resource}
                  </Typography>
                </TableCell>
                <TableCell sx={{ verticalAlign: "top" }}>
                  {FormatDate(item.timestamp)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <LoadMore
        handleLoadMore={loadMore}
        hasNextPage={hasNextPage}
        loading={loading}
      />
    </>
  );
}
