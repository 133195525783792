import { TableRow } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { RankCell } from "~/components/DashboardList";
// import { getLinkByType } from "~/components/FirewatchPage/lib";
import { AssetCell } from "~/components/FirewatchRow/AssetCell";
import { BlastRadiusCell } from "~/components/FirewatchRow/BlastRadiusCell";
import { CheckCell } from "~/components/FirewatchRow/CheckCell";
import { DateCell } from "~/components/FirewatchRow/DateCell";
import { PolicyNameCell } from "~/components/FirewatchRow/PolicyNameCell";
import { RiskScoreCell } from "~/components/FirewatchRow/RiskScoreCell";
import { VulnerabilityCell } from "~/components/FirewatchRow/VulnerabilityCell";
import { AggregateScoreType } from "~/operations";
import { ImpactCell } from "~/components/FirewatchRow/ImpactCell";
import { AdvisoryCell } from "~/components/FirewatchRow/AdvisoryCell";
import { FirstFoundCell } from "~/components/FirewatchRow/FirstFoundCell";
import { Header } from "~/components/FirewatchPage/FirewatchPageColumns";
import { Space } from "~/lib/types";
import { AdjustedAggregateScoreType } from "~/components/FirewatchPage";
import { NameCell } from "~/components/FirewatchRow/NameCell";
import { SpaceCell } from "~/components/FirewatchRow/SpaceCell";
import { TypeCell } from "~/components/FirewatchRow/Cells";
import { RiskFactorsCell } from "~/components/Cells";

type SearchResultRowProps = {
  tableHeaders: Header[];
  pageType: AggregateScoreType;
  row: any;
};
export function SearchResultRow({
  pageType,
  row,
  tableHeaders,
}: SearchResultRowProps) {
  const shouldLinkTypes = [
    AggregateScoreType.Policy,
    AggregateScoreType.Vulnerability,
    AggregateScoreType.Advisory,
    AggregateScoreType.Asset,
    "NewestRelevantVulnerabilities",
    AggregateScoreType.Check,
    "ALL",
  ];

  const shouldLink = shouldLinkTypes.includes(pageType);

  return (
    <TableRow
      {...(shouldLink &&
        row?.findingMrn && {
          component: RouterLink,
          to: getSearchLink(
            row?.scoreType,
            row.spaceId,
            row?.findingMrn,
            row.nodeId,
            row?.entity?.id,
          ),
        })}
    >
      {tableHeaders.map((header) => {
        if (header.id === "RANK") {
          return (
            <RankCell key={`${header.id}-${row?.id}`} id={row?.rank || 0} />
          );
        } else if (header.id === "IMPACT") {
          return (
            <ImpactCell
              key={`${header.id}-${row?.id}`}
              impact={row?.riskScore || 0}
              isActive
            />
          );
        } else if (header.id === "BLAST_RADIUS") {
          return (
            <BlastRadiusCell
              key={`${header.id}-${row?.id}`}
              blastRadius={row?.blastRadius}
              isActive
              impact={row?.riskScore || 0}
            />
          );
        } else if (header.id === "CHECK") {
          return <CheckCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "ADVISORY") {
          return <AdvisoryCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "VULNERABILITY") {
          return (
            <VulnerabilityCell key={`${header.id}-${row?.id}`} row={row} />
          );
        } else if (header.id === "ASSET") {
          return (
            <AssetCell
              key={`${header.id}-${row?.id}`}
              {...{ title: row.title, tags: row.tags, iconId: row.iconId }}
            />
          );
        } else if (header.id === "POLICY") {
          return <PolicyNameCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "RISK_FACTORS") {
          return (
            <RiskFactorsCell
              key={`${header.id}-${row?.id}`}
              riskFactors={row?.riskFactors}
            />
          );
        } else if (header.id === "RISK_SCORE") {
          return (
            <RiskScoreCell
              key={`${header.id}-${row?.id}`}
              riskScore={row?.riskScore || 0}
            />
          );
        } else if (header.id === "SPACE") {
          return <SpaceCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "FIRST_FOUND") {
          return (
            <FirstFoundCell
              key={`${header.id}-${row?.id}`}
              discoveryTime={row?.firstDetectedAt}
            />
          );
        } else if (header.id === "TYPE") {
          return (
            <TypeCell key={`${header.id}-${row?.id}`} type={row.scoreType} />
          );
        } else if (header.id === "DATE") {
          return (
            <DateCell
              key={`${header.id}-${row?.id}`}
              date={row?.firstDetectedAt}
            />
          );
        } else if (header.id === "TITLE") {
          return <NameCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "LAST_UPDATED") {
          // TODO: Add update to mesh with first found cell
          return (
            <FirstFoundCell
              key={`${header.id}-${row?.id}`}
              discoveryTime={row?.lastScannedAt}
            />
          );
        }
        return <div></div>;
      })}
    </TableRow>
  );
}

export const getSearchLink = (
  type: AdjustedAggregateScoreType,
  spaceId: Space["id"],
  findingMrn: string,
  nodeId: string,
  entityId: string,
) => {
  const id = findingMrn.split("/").pop();

  //TODO: spaceId will not work until https://github.com/mondoohq/server/pull/8143 is merged
  switch (type) {
    case AggregateScoreType.Asset:
      // TODO: this will not work, we need the asset id for this to work
      return `/space/inventory/${entityId}/overview?spaceId=${spaceId}`;
    case AggregateScoreType.Vulnerability:
    case "NewestRelevantVulnerabilities":
      return `/space/vulns/cve/${id}?spaceId=${spaceId}`;
    case AggregateScoreType.Advisory:
      return `/space/vulns/advisory/${id}?spaceId=${spaceId}`;
    case AggregateScoreType.Policy:
      return `/space/security/policies/${id}?spaceId=${spaceId}`;
    case AggregateScoreType.Check:
      return `/space/security/checks?spaceId=${spaceId}&findingMrn=${findingMrn}`;
    default:
      return "";
  }
};
