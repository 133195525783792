import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Grid, Button, Divider } from "@mui/material";
import { formatRFC3339 } from "date-fns";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { Legend, JsonViewer, PanelContainer } from "./components";
import { LoadingPage, LoadingFailedPage } from "~/components/loading";
import { ErrorBoundary } from "~/components/error-boundary";
import { Column } from "./components/column";
import { DynamicTrail } from "./components/user-trail";
import { DownloadIcon } from "~/components/icons";
import { Field, Resource, useAssetResourcesQuery } from "~/operations";

type ResourcesExplorerProps = {};

export function ResourcesExplorer({}: ResourcesExplorerProps) {
  const explorerContainerRef = useRef<HTMLDivElement>(null);
  const { asset } = useAssetOutlet();
  const [popoverHeight, setPopoverHeight] = useState<number>(500);
  const [popoverContent, setPopoverContent] = useState<Field | null>(null);
  const [columns, setColumns] = useState<any[]>([]);
  const [userTrail, setUserTrail] = useState<string[]>([]);

  const { data, error, loading, refetch } = useAssetResourcesQuery({
    variables: {
      input: {
        assetMrn: asset?.mrn || "",
        selectedPaths: [],
        selectedNames: [],
      },
    },
    fetchPolicy: "no-cache",
    skip: !asset?.mrn,
  });

  useEffect(() => {
    if (data?.assetResources) {
      const next = JSON.parse(JSON.stringify(data.assetResources));
      setColumns((columns) => [...columns, next]);
    }
  }, [data]);

  useLayoutEffect(() => {
    const getViewerSize = () => {
      if (explorerContainerRef.current?.clientHeight) {
        setPopoverHeight(explorerContainerRef.current.clientHeight);
      }
    };
    window.addEventListener("resize", getViewerSize);
    getViewerSize();
    return () => window.removeEventListener("resize", getViewerSize);
  }, []);

  if (error) {
    return <LoadingFailedPage what="Resources" />;
  }

  if (!data || loading) {
    return <LoadingPage what="Resources" />;
  }

  const handleItemClick = (columnIndex: number, item: Resource | Field) => {
    updateUserTrail(columnIndex, item);

    let next = [...columns];
    // If the column the user clicks on is lower than the current number of columns, it means
    // we must remove those columns from our array, before making our API call to get more data.
    if (columnIndex < columns.length + 1) {
      [...Array(columns.length - columnIndex - 1)].forEach((_i) => next.pop());
    }

    // this is the end of the line, we display its content in the JSON viewer
    if (item?.nestedContent === false && item.__typename === "Field") {
      setColumns(next);
      return setPopoverContent(item);
    }
    // if the item does not have nested content, then we already have the data and can simply
    // push it into the columns array
    if (item.nestedContent === false) {
      next.push(item);
    }
    setColumns(next);

    // Clear content from the json viewer if are still navigating
    setPopoverContent(null);

    // if the item has nested content, we need to call the API for the next level of data
    // when the data comes back, our UseEffect hook will handle placing it into the columns array
    if (item.nestedContent === true) {
      if (!asset) {
        throw new Error("Oops, we seem to be missing an mrn for this resource");
      }
      refetch({
        input: {
          assetMrn: asset.mrn,
          selectedPaths: [item.path || ""],
          selectedNames: [item.name],
        },
      });
    }
  };

  const updateUserTrail = (columnIndex: number, item: Resource | Field) => {
    const nextUserTrail = [...userTrail];
    if (columnIndex < columns.length + 1) {
      [...Array(userTrail.length - columnIndex)].forEach((_i) =>
        nextUserTrail.pop(),
      );
    }
    nextUserTrail.push(item.name);
    setUserTrail(nextUserTrail);
  };

  const handleDownloadJson = () => {
    if (popoverContent?.value) {
      const jsonContent = JSON.stringify(
        JSON.parse(popoverContent.value),
        null,
        " ",
      );
      const file = new Blob([jsonContent], {
        type: "application/json",
      });
      let url = window.URL.createObjectURL(file);
      let a = document.createElement("a");
      a.href = url;
      a.download = generateFileName();
      a.click();
    }
  };

  const generateFileName = () => {
    const assetName = asset?.name;
    const date = formatRFC3339(new Date());
    return `${assetName}-${date}.json`;
  };

  return (
    <ErrorBoundary key="resourcesExplorer">
      <Box
        sx={{
          width: 1,
          pt: 3,
        }}
      >
        <Box
          sx={{
            width: 1,
            mt: "-1px",
            borderTop: "1px solid",
            borderColor: "background.light",
          }}
        >
          <Grid container position="relative">
            <Grid item xs={6} p={2}>
              <DynamicTrail {...{ asset, columns, userTrail }} />
            </Grid>
            <Grid item xs={6} px={2} py={1}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Legend />

                <Button
                  variant="text"
                  endIcon={<DownloadIcon />}
                  onClick={handleDownloadJson}
                >
                  Download JSON
                </Button>
              </Box>
            </Grid>
            <Divider sx={{ width: 1 }} />
            {/* JSON popover window */}
            <JsonViewer {...{ popoverHeight, popoverContent }} />
            <Box
              sx={{
                position: "relative",
                overflowX: "scroll",
              }}
            >
              <Box ref={explorerContainerRef}>
                <PanelContainer>
                  {columns?.map((column, index) => {
                    return (
                      <Column
                        key={index}
                        {...{
                          asset,
                          column,
                          columnIndex: index,
                          handleItemClick,
                          userTrail,
                        }}
                        listProps={{
                          sx: (theme) => ({
                            paddingBottom: theme.spacing(9),
                          }),
                        }}
                      />
                    );
                  })}
                </PanelContainer>
              </Box>
            </Box>
            <Divider sx={{ width: 1 }} />
          </Grid>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}
