import { createSvgIcon } from "@mui/material";

export const RockyLinuxIcon = createSvgIcon(
  <svg viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M243.841 168.452C248.264 155.786 250.667 142.174 250.667 128C250.667 60.2528 195.747 5.33301 128.001 5.33301C60.2538 5.33301 5.33398 60.2528 5.33398 128C5.33398 161.521 18.78 191.903 40.5729 214.043L165.003 89.6132L195.723 120.333L243.841 168.452ZM221.434 207.484L165.003 151.053L76.6312 239.424C92.2572 246.64 109.659 250.666 128.001 250.666C165.424 250.666 198.935 233.907 221.434 207.484Z"
      fill="#10B981"
    />
  </svg>,
  "RockyLinux",
);
