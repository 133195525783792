import { Box, SxProps, Theme } from "@mui/material";

type Props = {
  color?: string;
  width?: number;
  layout?: SxProps<Theme>;
  iconOnly?: boolean;
};

export function MondooLogoHorizontal({
  color,
  width,
  layout,
  iconOnly,
  ...props
}: Props) {
  return (
    <Box
      width={width ? `${width}px` : "128px"}
      display="flex"
      alignItems="center"
      justifyContent="start"
      sx={layout}
    >
      <Box
        component="svg"
        viewBox="0 0 1080 193.53"
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        sx={[
          {
            overflow: "visible",
            fill: color ? color : (theme) => theme.palette.text.primary,
          },
          {
            "#mondoo-logo-text": {
              opacity: iconOnly ? 0 : 1,
            },
          },
        ]}
        {...props}
      >
        <g id="a" />
        <g id="b">
          <g id="c">
            <g>
              <g id="mondoo-logo-text">
                <path d="M474.82,57.3h0v10.14c5.23-7.45,13.47-11.89,23.77-11.89,12.84,0,21.56,5.23,26.15,14.42,5.39-8.88,15.06-14.42,26.79-14.42,21.71,0,32.65,13.31,32.65,34.71v52.62h-22.51v-49.45c0-11.57-4.75-18.7-15.85-18.7-9.83,0-16.32,6.66-16.32,20.13v48.02h-22.35v-50.08c0-11.41-4.75-18.07-15.37-18.07s-16.8,6.82-16.8,20.6v47.55h-22.03v-63.71c0-12.08,9.79-21.87,21.87-21.87Z" />
                <path d="M595.59,100.09c0-24.73,19.5-44.54,45.17-44.54s45.33,19.81,45.33,44.54-19.5,44.54-45.33,44.54-45.17-19.97-45.17-44.54Zm68.79-.16c0-13.31-10.46-23.93-23.46-23.93s-23.46,10.62-23.46,23.93,10.3,23.93,23.46,23.93,23.46-10.62,23.46-23.93Z" />
                <path d="M718.98,57.3h0v10.78c5.55-7.77,14.42-12.52,25.04-12.52,20.29,0,33.13,13.31,33.13,35.66v51.67h-22.35v-48.34c0-12.2-5.87-19.81-17.44-19.81-10.3,0-18.23,7.61-18.23,20.92v47.23h-22.03v-63.71c0-12.08,9.79-21.87,21.87-21.87Z" />
                <path d="M787.84,100.09c0-27.26,19.65-44.54,41.53-44.54,11.25,0,20.6,4.75,26.47,12.68V17.84h21.56V121.33c0,11.9-9.65,21.56-21.56,21.56h0v-11.73c-6.02,8.4-15.69,13.47-26.79,13.47-21.08,0-41.21-17.28-41.21-44.54Zm69.26-.16c0-13.31-10.3-24.09-23.77-24.09s-23.78,10.62-23.78,24.09,10.62,24.09,23.78,24.09,23.77-10.78,23.77-24.09Z" />
                <path d="M888.25,100.09c0-24.73,19.5-44.54,45.17-44.54s45.33,19.81,45.33,44.54-19.5,44.54-45.33,44.54-45.17-19.97-45.17-44.54Zm68.79-.16c0-13.31-10.46-23.93-23.46-23.93s-23.46,10.62-23.46,23.93,10.3,23.93,23.46,23.93,23.46-10.62,23.46-23.93Z" />
                <path d="M989.5,100.09c0-24.73,19.5-44.54,45.17-44.54s45.33,19.81,45.33,44.54-19.5,44.54-45.33,44.54-45.17-19.97-45.17-44.54Zm68.79-.16c0-13.31-10.46-23.93-23.46-23.93s-23.46,10.62-23.46,23.93,10.3,23.93,23.46,23.93,23.46-10.62,23.46-23.93Z" />
              </g>
              <g id="mondoo-logo-icon">
                <path d="M290.3,0V193.53c53.44,0,96.77-43.32,96.77-96.77S343.74,0,290.3,0Z" />
                <circle cx="193.53" cy="96.77" r="96.77" />
                <path d="M0,96.77c0,53.44,43.32,96.77,96.77,96.77V0C43.32,0,0,43.32,0,96.77Z" />
              </g>
            </g>
          </g>
        </g>
      </Box>
    </Box>
  );
}
