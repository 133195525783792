import { findDifference, isObject, Obj } from "~/components/ui-library";

type UpdateQueryStringProps = {
  router: any;
  defaultValues: Obj;
  newValues: Obj;
};

/**
 * Updates the query-string of a given URL based on the difference between two sets of values,
 *  the default-values and the new-values
 */
const updateQueryString = ({
  router,
  defaultValues,
  newValues,
}: UpdateQueryStringProps) => {
  const params = new URLSearchParams("");

  const difference = findDifference({
    defaultValues,
    newValues,
  });

  // ToDo: Handle this from `src/pages/root.tsx`
  if (router.query.spaceId) {
    params.set("spaceId", router.query.spaceId);
  }

  Object.keys(difference).forEach((name) => {
    const value = newValues[name];
    params.set(name, isObject(value) ? JSON.stringify(value) : String(value));
  });

  router.replace(String(params));
};

export default updateQueryString;
