import { Button } from "@mui/material";
import { MondooLogoYellowIcon } from "~/components/ui-library/icons";

type UnlockFlagLinkProps = {
  url: string;
};

const UnlockFlagLink = ({ url }: UnlockFlagLinkProps) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ display: "flex", flexShrink: 0, textDecoration: "none" }}
    >
      <Button
        variant="text"
        sx={{
          height: 25,
        }}
      >
        <MondooLogoYellowIcon />
      </Button>
    </a>
  );
};

export default UnlockFlagLink;
