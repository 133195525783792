import { FormEvent, useCallback, useEffect, useReducer, useState } from "react";
import {
  IdTokenResult,
  ParsedToken,
  UserInfo,
  UserMetadata,
  User,
  AuthError,
} from "@firebase/auth";

import {
  State,
  AuthProviderProps,
  authReducer,
  Dispatch,
  AuthContext,
  SignupProps,
  AuthOptions,
  initialState,
} from "./auth-context";

import { GeolockDialog } from "~/components/geolock-dialog";

export let users = [
  {
    name: "Jane Doe",
    email: "jane@mondoo.com",
    token: "jane-token",
    provider: "password",
    emailVerified: true,
  },
  {
    name: "Curt Doe",
    email: "curt@mondoo.com",
    token: "curt-token",
    provider: "password",
    emailVerified: true,
  },
  {
    name: "Bob Doe",
    email: "bob@mondoo.com",
    token: "bob-token",
    provider: "password",
    emailVerified: true,
  },
];

export var selectedUser = users[0];

// method to store selected user in local storage
export let selectUser = (usr: any) => {
  console.log(`set user id: ${usr.token} name: ${usr.name}\n`);
  localStorage.setItem("user", JSON.stringify(usr));
  location.reload();
};

// fetch user from local storage
var userStringFromLocalStorage = localStorage.getItem("user");
if (userStringFromLocalStorage != null) {
  selectedUser = JSON.parse(userStringFromLocalStorage);
}

export class DevelopmentParsedToken implements ParsedToken {
  "exp"?: string;
  "sub"?: string;
  "auth_time"?: string;
  "iat"?: string;
  "firebase"?: {
    sign_in_provider?: string;
    sign_in_second_factor?: string;
  };
  [key: string]: string | object | undefined;
}

export class DevelopmentIdTokenResult implements IdTokenResult {
  authTime: string = "";
  expirationTime: string = "";
  issuedAtTime: string = "";
  signInProvider: string | null = "";
  signInSecondFactor: string | null = null;
  token: string = "";
  claims: ParsedToken = new DevelopmentParsedToken();
}

export class DevelopmentUser implements User {
  displayName: string | null = selectedUser.name;
  email: string | null = selectedUser.email;
  phoneNumber: string | null = null;
  photoURL: string | null = null;
  providerId: string = "github.com";
  uid: string = "";
  emailVerified: boolean = selectedUser.emailVerified;
  isAnonymous: boolean = false;
  metadata: UserMetadata = {};
  providerData: UserInfo[] = [
    {
      providerId: "LocalDev",
      uid: "LocalUID",
      displayName: selectedUser.name,
      email: selectedUser.email,
      phoneNumber: null,
      photoURL: null,
    },
  ];
  refreshToken: string = "";
  tenantId: string | null = "";

  delete(): Promise<void> {
    return Promise.resolve();
  }

  getIdToken(forceRefresh?: boolean): Promise<string> {
    return Promise.resolve(selectedUser.token);
  }

  getIdTokenResult(forceRefresh?: boolean): Promise<IdTokenResult> {
    return Promise.resolve(new DevelopmentIdTokenResult());
  }

  reload(): Promise<void> {
    return Promise.resolve();
  }

  toJSON(): object {
    return {};
  }
}

export const devUser = new DevelopmentUser();

// Error codes can be found at
// https://firebase.google.com/docs/reference/js/v8/firebase.auth.Error#code
const handleErrorMessage = (errorCode: string) => {
  let customError = "Oops, something went wrong. Please give it another try.";
  switch (errorCode) {
    default:
      return customError;
  }
};

export function DevelopmentAuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [hasFetchedUser, setHasFetchedUser] = useState<boolean>(false);

  useEffect(() => {
    return disableAuthForDevelopment();
  }, []);

  // This takes care of most of the work to set us up for no auth dev mode
  const disableAuthForDevelopment = () => {
    // set our fake user in state
    dispatch({ type: "set user success", user: devUser, idTokenResult: null });
    // make sure we set the user to have being fetched or we'll get a blank screen
    setHasFetchedUser(true);
  };

  const logout = async () => {
    try {
      // reset heap analytics identity for a new anonymous session
      window.heap.resetIdentity();

      location.reload();
    } catch (error) {
      console.log("%c---- ERROR LOGGING OUT", "background: pink; color: black");
      const message = handleErrorMessage((error as AuthError).code);
      dispatch({ type: "set user failure", error: message });
    }
  };

  const loginWithProvider = async (
    event: FormEvent,
    dispatch: Dispatch,
    _providerOption: AuthOptions,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
  };

  const loginWithSSO = async (
    event: FormEvent,
    dispatch: Dispatch,
    _orgId: string,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
  };

  const signupWithEmailAndPassword = async (
    event: FormEvent,
    dispatch: Dispatch,
    _props: SignupProps,
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
  };

  const loginWithEmailAndPassword = async (
    event: FormEvent,
    dispatch: Dispatch,
    _props: { email: string; password: string },
  ) => {
    event.preventDefault();
    dispatch({ type: "set user begin" });
  };

  const sendResetPasswordEmail = async (
    dispatch: Dispatch,
    _email: string,
    event?: FormEvent,
  ) => {
    event?.preventDefault();
    dispatch({ type: "reset password begin" });
  };

  const sendUserEmailVerification = async () => {};

  const changePassword = async (
    _oldPassword: string,
    _newPassword: string,
  ): Promise<void> => {
    const { user } = state;
    if (!user?.email) {
      return Promise.reject("Unable to update password");
    }

    return Promise.resolve();
  };

  const clearErrors = useCallback(() => {
    dispatch({ type: "clear errors" });
  }, []);

  const setGeolock = (isGeolocked: boolean) => {
    dispatch({ type: "set geolock", isGeolocked });
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
        logout,
        loginWithProvider,
        signupWithEmailAndPassword,
        loginWithEmailAndPassword,
        loginWithSSO,
        sendResetPasswordEmail,
        sendUserEmailVerification,
        changePassword,
        clearErrors,
        setGeolock,
        handleErrorMessage,
      }}
    >
      {hasFetchedUser && children}
      {state.isGeolocked && <GeolockDialog />}
    </AuthContext.Provider>
  );
}
