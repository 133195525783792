import { createSvgIcon } from "@mui/material";

export const EC2SecurityGroupsIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_14040)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.9961 22.5668C26.9831 23.7958 26.6141 29.7648 20.9961 30.1098V10.4428L26.9961 12.7558V22.5668ZM13.9961 21.8448V12.7558L19.9961 10.4428V30.1048C14.2511 29.7058 14.0041 22.6668 13.9961 21.8448ZM20.3161 9.24775L13.3161 11.9458C13.1231 12.0208 12.9961 12.2058 12.9961 12.4128V21.8498C13.0041 22.8008 13.2851 31.1428 20.4961 31.1428C27.5511 31.1428 27.9811 24.0068 27.9961 22.5718V12.4128C27.9961 12.2058 27.8691 12.0208 27.6761 11.9458L20.6761 9.24775C20.5601 9.20375 20.4321 9.20375 20.3161 9.24775ZM29.9961 22.5718C29.9961 22.5978 29.9821 25.2628 28.7901 27.9018C27.2201 31.3798 24.4291 33.1428 20.4961 33.1428C16.5781 33.1428 13.7931 31.2718 12.2181 27.5808C11.0141 24.7608 10.9961 21.8858 10.9961 21.8578V10.6968L20.4961 7.03575L29.9961 10.6968V22.5718ZM30.6761 9.88675L20.6761 6.03375C20.5601 5.98875 20.4321 5.98875 20.3161 6.03375L10.3161 9.88675C10.1231 9.96175 9.99609 10.1468 9.99609 10.3538V21.8578C9.99609 21.9808 10.1161 34.1428 20.4961 34.1428C30.8761 34.1428 30.9961 22.6878 30.9961 22.5718V10.3538C30.9961 10.1468 30.8691 9.96175 30.6761 9.88675Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_14040"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "EC2 Security Groups",
);
