import { SmallDonutCard } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { NavDrawerVulnerabilitiesIcon } from "~/components/icons";
import { Metrics, useMetrics } from "~/hooks";

export function VulnerabilitiesDonutChart({ space }: DonutChartCardProps) {
  const { vulnerableAssetsSeverity, isLoading } = useMetrics({
    entityMrn: space.mrn,
    metricMrns: [Metrics.VulnerableAssetsSeverity],
  });

  return (
    <SmallDonutCard
      to={`affected-assets?spaceId=${space.id}`}
      data-testid="vulnerable-assets-donut-chart"
      loading={isLoading}
      data={vulnerableAssetsSeverity}
      title="Vulnerable Assets"
      icon={<NavDrawerVulnerabilitiesIcon />}
    />
  );
}
