import { SharedPalette } from "~/types/material-ui";

// https://www.figma.com/design/L3iS3IX1XB1GhywMyqFnMD/Mondoo-Brand-Design-System?node-id=272-5283&t=z6cWWNaDf6GG4FQf-4
export const sharedColorBlindPalette: SharedPalette = {
  primary: {
    main: "#9147FF",
    light: "#A07AFF",
    dark: "#5000C1",
    darker: "#3F137C", // used mainly for the top nav bar
    gradient: "linear-gradient(to bottom right, #9147FF 0%, #A07AFF 100%)",
    contrastText: "#FFFFFF",
  },
  secondary: {
    main: "#4C35E8",
    light: "#8797FF",
    dark: "#2A15B7",
    gradient: "linear-gradient(to bottom right, #4C35E8 0%, #8797FF 100%)",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#FB6000",
    light: "#FF873D",
    dark: "#82431D",
    gradient: "linear-gradient(90deg, #FF873D 0%, #FB6000 100%)",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FBD153",
    light: "#FFED9F",
    dark: "#755800",
    gradient: "linear-gradient(to left, #FBD153 0%, #FFED9F 100%)",
    contrastText: "#000000",
  },
  critical: {
    main: "#FF8024",
    light: "#FFA361",
    dark: "#85512A",
    gradient: "linear-gradient(90deg, #FFA361 0%,#FF8024  100%)",
    contrastText: "#FFFFFF",
  },
  high: {
    main: "#CD8362",
    light: "#D9A087",
    dark: "#705242",
    gradient: "linear-gradient(90deg, #D9A087 0%,#CD8362  100%)",
    contrastText: "#FFFFFF",
  },
  medium: {
    main: "#AC8589",
    light: "#C1A4A7",
    dark: "#625253",
    gradient: "linear-gradient(90deg, #C1A4A7 0%,#AC8589  100%)",
    contrastText: "#FFFFFF",
  },
  low: {
    main: "#8388BC",
    light: "#A6A9CF",
    dark: "#51536A",
    gradient: "linear-gradient(90deg,#A6A9CF  0%, #8388BC 100%)",
    contrastText: "#000000",
  },
  // none and good are the same for CVE purposes; none will be treated differently
  // when looking at general-purpose reports
  none: {
    main: "#518AF8",
    light: "#84ADFA",
    dark: "#3E5386",
    gradient: "linear-gradient(90deg, #84ADFA 0%, #518AF8 100%)",
    contrastText: "#000000",
  },
  unknown: {
    main: "#BBC3C3",
    light: "#D6DBDB",
    dark: "#838282",
    gradient: "linear-gradient(90deg, #D6DBDB 0%, #BBC3C3 100%)",
    contrastText: "#000000",
  },
  errorScan: {
    main: "#F4DC00",
    light: "#FFED4D",
    dark: "#8B8543",
    gradient: "linear-gradient(90deg, #F4DC00 0%, #FFED4D 100%)",
    contrastText: "#000000",
  },
  // score rating
  fail: {
    main: "#FF8024",
    light: "#FFA361",
    dark: "#85512A",
    gradient: "linear-gradient(90deg, #FFA361 0%,#FF8024  100%)",
    contrastText: "#FFFFFF",
  },
  poor: {
    main: "#CD8362",
    light: "#D9A087",
    dark: "#705242",
    gradient: "linear-gradient(90deg, #D9A087 0%,#CD8362  100%)",
    contrastText: "#FFFFFF",
  },
  fair: {
    main: "#AC8589",
    light: "#C1A4A7",
    dark: "#625253",
    gradient: "linear-gradient(90deg, #C1A4A7 0%,#AC8589  100%)",
    contrastText: "#FFFFFF",
  },
  good: {
    main: "#8388BC",
    light: "#A6A9CF",
    dark: "#51536A",
    gradient: "linear-gradient(90deg,#A6A9CF  0%, #8388BC 100%)",
    contrastText: "#000000",
  },
  excellent: {
    main: "#518AF8",
    light: "#84ADFA",
    dark: "#3E5386",
    gradient: "linear-gradient(90deg, #84ADFA 0%, #518AF8 100%)",
    contrastText: "#000000",
  },
  unrated: {
    main: "#BBC3C3",
    light: "#D6DBDB",
    dark: "#838282",
    gradient: "linear-gradient(90deg, #D6DBDB 0%, #BBC3C3 100%)",
    contrastText: "#000000",
  },
};
