import { FC, ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Card, Typography, CardContent, TableRow } from "@mui/material";
import { ScoreCard, ScoreCardBox } from "./score-card";
import { Score } from "~/lib/types";

export type ReportCardProps = {
  title: string;
  subtitle: any;
  unscored?: boolean;
  eol?: boolean;
  affected?: boolean;
  score: Score;
  scoreCard?: ReactNode;
  children?: ReactNode;
};

export const ReportCard: FC<ReportCardProps> = (props) => {
  const {
    title,
    subtitle,
    affected = true,
    unscored = false,
    eol = false,
    score,
    children,
  } = props;
  let { scoreCard } = props;

  if (scoreCard == null) {
    scoreCard = (
      <ScoreCardBox>
        <ScoreCard
          score={score}
          affected={affected}
          unscored={unscored}
          eol={eol}
        />
      </ScoreCardBox>
    );
  }

  return (
    <FlexCard>
      <FlexColumn>
        <CardContent>
          <div>
            <ReportTitle>{title}</ReportTitle>
            <Typography variant="subtitle2">{subtitle}</Typography>
          </div>
          {children}
        </CardContent>
      </FlexColumn>
      {scoreCard}
    </FlexCard>
  );
};

export const ClickableTableRow = styled(TableRow)<{
  component?: typeof RouterLink;
  to?: string;
}>`
  cursor: pointer;

  &:hover {
    background: ${(props) => props.theme.palette.background.light};
  }

  & .hideUntilHover {
    visibility: hidden;
  }

  &:hover .hideUntilHover {
    visibility: initial;
  }

  & .fadeUntilHover {
    opacity: 0;
    transition: opacity 325ms;
  }

  &:hover .fadeUntilHover {
    opacity: 1;
  }
`;

const FlexCard = styled(Card)`
  display: flex;
  min-height: 150px;
  align-items: start;
  padding: 0 ${(props) => props.theme.spacing(4)};
`;

const FlexColumn = styled("div")`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ReportTitle = styled("div")`
  font-size: 32px;
  line-height: 40px;
  margin-top: 22px;
  margin-bottom: 7px;
  word-break: break-all;

  @media print {
    font-size: 14pt;
  }
`;
