import { gitlabInstructions } from "./gitlab";
import { githubInstructions } from "./github";
import { circleCiInstructions } from "./circleci";
import { azureInstructions } from "./azure";
import { jenkinsInstructions } from "./jenkins";
import { Flag } from "~/components/content-flag";

export type ConfigExample = {
  id: string;
  config: string;
};

type cicdInstructions = {
  title: string;
  tagline: string;
  flags: Flag[];
  instructions: {
    id: number;
    link?: string;
    href?: string;
    typography?: string;
    markdownText?: string;
    markdown?: string;
    tagline: string;
    token?: boolean;
    configExamples?: ConfigExample[];
  }[];
};

export function getCicdInstructions(type: string): cicdInstructions {
  switch (type.toLowerCase()) {
    case "azure":
      return azureInstructions;
    case "circleci":
      return circleCiInstructions;
    case "github":
      return githubInstructions;
    case "gitlab":
      return gitlabInstructions;
    case "jenkins":
      return jenkinsInstructions;

    default:
      return gitlabInstructions;
  }
}
