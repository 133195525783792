import { SyntheticEvent, useState } from "react";
import {
  ClickAwayListener,
  ClickAwayListenerProps,
  Grow,
  IconButtonProps,
  ListItemIcon,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { DeleteIcon, EditIcon } from "~/components/icons";
import { CardMenu } from "~/components/card-menu";
import { Member } from "./members-mgmt";

export type EditMemberActionMenuProps = {
  member: Member;
  hasSetMembershipPermission?: boolean;
  hasRemoveMembershipPermission?: boolean;
  handleEditMember: (event: SyntheticEvent, member: Member) => void;
  handleDeleteMember: (event: SyntheticEvent, member: Member) => void;
};

type EditMemberActionMenuState = {
  openActionMenu: boolean;
  openActionMenuAnchorEl: HTMLElement | null;
  anchorEl: HTMLElement | null;
};

export function EditMemberActionMenu(props: EditMemberActionMenuProps) {
  const {
    member,
    hasSetMembershipPermission,
    hasRemoveMembershipPermission,
    handleEditMember,
    handleDeleteMember,
  } = props;

  const hasPermissions =
    hasSetMembershipPermission || hasRemoveMembershipPermission;

  const [state, setState] = useState<EditMemberActionMenuState>({
    anchorEl: null,
    openActionMenu: false,
    openActionMenuAnchorEl: null,
  });

  const mergeState = (params: Partial<EditMemberActionMenuState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const { anchorEl } = state;
  const isOpen = Boolean(anchorEl);

  const handleCardMenuClick: IconButtonProps["onClick"] = (event) => {
    event.stopPropagation();
    mergeState({
      anchorEl: event.currentTarget,
    });
  };

  const handleClickAway: ClickAwayListenerProps["onClickAway"] = (event) => {
    event.stopPropagation();
    mergeState({ anchorEl: null });
  };

  const handleEditClick: MenuItemProps["onClick"] = (event) => {
    handleEditMember(event, member);
    mergeState({ anchorEl: null });
  };

  const handleDeleteClick: MenuItemProps["onClick"] = (event) => {
    handleDeleteMember(event, member);
    mergeState({ anchorEl: null });
  };

  return (
    <>
      {hasPermissions && <CardMenu onClick={handleCardMenuClick} />}
      <Popper open={isOpen} anchorEl={anchorEl} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper style={{ minWidth: 180 }}>
              <ClickAwayListener onClickAway={handleClickAway}>
                <MenuList>
                  {hasSetMembershipPermission && (
                    <MenuItem key="edit" onClick={handleEditClick}>
                      <ListItemIcon>
                        <EditIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">Edit</Typography>
                    </MenuItem>
                  )}
                  {hasRemoveMembershipPermission && (
                    <MenuItem key="delete" onClick={handleDeleteClick}>
                      <ListItemIcon>
                        <DeleteIcon />
                      </ListItemIcon>
                      <Typography variant="inherit">Delete</Typography>
                    </MenuItem>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
