import { IntegrationType } from "~/operations";

export const mapIntegrationRouteToIntegrationType = (
  integrationTypeId: string | undefined,
): IntegrationType => {
  if (!integrationTypeId) return IntegrationType.Unknown;

  switch (integrationTypeId) {
    case "aws":
      return IntegrationType.Aws;
    case "amazons3":
      return IntegrationType.AwsS3;
    case "amazon-s3-compatible":
      return IntegrationType.S3;
    case "azure":
      return IntegrationType.Azure;
    case "azure_blob":
      return IntegrationType.AzureBlob;
    case "bigquery":
      return IntegrationType.Bigquery;
    case "defender":
      return IntegrationType.MicrosoftDefender;
    case "gcp":
      return IntegrationType.Gcp;
    case "gcs_bucket":
      return IntegrationType.GcsBucket;
    case "github":
      return IntegrationType.Github;
    case "gitlab":
      return IntegrationType.Gitlab;
    case "google_workspace":
      return IntegrationType.GoogleWorkspace;
    case "hosted_slack":
      return IntegrationType.HostedSlack;
    case "host":
      return IntegrationType.Host;
    case "httppost":
      return IntegrationType.Httppost;
    case "kubernetes":
      return IntegrationType.K8S;
    case "managed":
      return IntegrationType.ManagedClient;
    case "ms365":
      return IntegrationType.Ms365;
    case "msteams":
      return IntegrationType.Msteams;
    case "microsoft_defender":
      return IntegrationType.MicrosoftDefender;
    case "oci":
      return IntegrationType.Oci;
    case "okta":
      return IntegrationType.Okta;
    case "postgres":
      return IntegrationType.Postgres;
    case "s3":
      return IntegrationType.S3;
    case "slack":
      return IntegrationType.Slack;
    case "shodan":
      return IntegrationType.Shodan;
    case "snowflake":
      return IntegrationType.Snowflake;
    case "telegram":
      return IntegrationType.Telegram;
    case "ticket_system_jira":
      return IntegrationType.TicketSystemJira;
    case "ticket_system_email":
      return IntegrationType.TicketSystemEmail;
    case "ticket_system_zendesk":
      return IntegrationType.TicketSystemZendesk;
    case "ticket_system_github":
      return IntegrationType.TicketSystemGithub;
    case "ticket_system_gitlab":
      return IntegrationType.TicketSystemGitlab;
    case "ticket_system_azure_devops":
      return IntegrationType.TicketSystemAzureDevops;
    default:
      return IntegrationType.Unknown;
  }
};
