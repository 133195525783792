import { TableCell } from "@mui/material";
import { PublishedDate } from "~/pages/inventory/components/PublishedDate";
import { SoftwareDetailsCommonCellProps } from "./types";

export const PublishCell = ({
  cve,
  advisory,
  ...props
}: SoftwareDetailsCommonCellProps) => {
  return (
    <TableCell data-testid={props["data-testid"]}>
      <PublishedDate
        dateString={String(advisory?.publishedAt || cve?.published)}
      />
    </TableCell>
  );
};
