import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import { alpha, Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { NavDrawerIntegrationsIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import { IntegrationType, useGetIntegrationsSummaryQuery } from "~/operations";
import { Loading, LoadingFailed } from "~/components/loading";
import {
  activeSummaries,
  formatIntegrationName,
  mapIntegrationType,
} from "~/lib/integrations";
import { DynamicIntegrationIcon } from "../integrations/components/dynamic-integration-icon";
import { Clamp } from "~/components/clamp/clamp";

type IntegrationsOverviewProps = {
  space: Space;
};

export function IntegrationsOverview({ space }: IntegrationsOverviewProps) {
  const { data, error, loading } = useGetIntegrationsSummaryQuery({
    variables: { input: { spaceMrn: space.mrn } },
  });

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          width: 1,
          p: 10,
          placeContent: "center",
        }}
      >
        <LoadingFailed what="integrations" />
      </Box>
    );
  }

  const summaries = activeSummaries(
    data?.integrationsSummary.integrationSummaries || [],
  ).map((summary) => {
    return {
      type: summary.type,
      count: summary.totalCount,
    };
  });

  const integrationHref = (type: IntegrationType) => {
    return `/space/integrations/${encodeURIComponent(
      mapIntegrationType(type),
    )}?spaceId=${space.id}`;
  };

  return (
    <Grid container rowSpacing={3} item>
      <DividerTitle
        icon={<NavDrawerIntegrationsIcon sx={{ fontSize: 32 }} />}
        title="Integrations"
      />
      <Grid container item spacing={{ xs: 2, md: 3 }}>
        {loading ? (
          <Loading />
        ) : (
          summaries.map((integration, i) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={i}
              className="integration-overview-card"
            >
              <Paper
                component={RouterLink}
                to={integrationHref(integration.type)}
                sx={{
                  display: "flex",
                  gap: 2,
                  p: 2,
                  transition: "all .35s ease-in-out",
                  "&:hover, &:focus-visible": {
                    background: (theme) =>
                      alpha(theme.palette.primary.main, 0.2),
                    opacity: 1,
                  },
                }}
              >
                <DynamicIntegrationIcon
                  {...{ type: integration.type, sx: { fontSize: 40 } }}
                />
                <Box>
                  <Clamp lines={1}>
                    <Typography fontWeight={700} textTransform="uppercase">
                      {formatIntegrationName(integration.type)}
                    </Typography>
                  </Clamp>
                  <Typography color="text.secondary" sx={{ fontSize: 12 }}>
                    {integration.count}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          ))
        )}
      </Grid>
    </Grid>
  );
}

type DividerTitleProps = {
  icon: ReactNode;
  title: string;
};

export const DividerTitle = ({ icon, title }: DividerTitleProps) => {
  return (
    <Grid container item sx={{ display: "flex", alignItems: "center" }}>
      <Grid item sx={{ display: "flex", placeContent: "center" }}>
        {icon}
        <Typography
          variant="h5"
          component="h3"
          fontWeight={700}
          sx={{ mr: 3, ml: 1 }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item container xs alignItems="center">
        <Grid item xs>
          <Divider />
        </Grid>
      </Grid>
    </Grid>
  );
};
