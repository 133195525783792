import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Transitions, useTheme } from "@mui/material";
import { LinkProps as RouterLinkProps } from "react-router-dom";

type AppNavDrawerTree = Array<AppNavDrawerItem>;

type AppNavDrawerItem = {
  text: string;
  link: RouterLinkProps;
  icon?: ReactNode;
  tree?: AppNavDrawerItem[];
  loading?: boolean;
  selected?: boolean;
  hasError?: boolean;
};

type AppNavDrawerProviderProps = {
  children: ReactNode;
};

type AppNavDrawerContextTypes =
  | {
      open: boolean;
      setOpen: Dispatch<SetStateAction<boolean>>;
      tree: AppNavDrawerTree;
      setTree: Dispatch<SetStateAction<AppNavDrawerTree>>;
      width: string;
      transition: NonNullable<Parameters<Transitions["create"]>[1]>;
      navbarHeight: number;
      setNavbarHeight: Dispatch<SetStateAction<number>>;
    }
  | undefined;

const AppNavDrawerContext = createContext<AppNavDrawerContextTypes>(undefined);

function AppNavDrawerProvider({ children }: AppNavDrawerProviderProps) {
  const theme = useTheme();

  const openStorageKey = "appNavDrawer.open";
  const openDefault = () => {
    try {
      return Boolean(
        JSON.parse(window.localStorage.getItem(openStorageKey) || "true"),
      );
    } catch {
      return true;
    }
  };

  const [open, setOpen] = useState(openDefault());
  const [tree, setTree] = useState<AppNavDrawerTree>([]);

  useEffect(() => {
    window.localStorage.setItem(openStorageKey, String(open));
  }, [open]);

  const [navbarHeight, setNavbarHeight] = useState(64);

  useLayoutEffect(() => {
    document.documentElement.style.scrollPaddingTop = `${navbarHeight}px`;
  }, [navbarHeight]);

  const width = open ? theme.spacing(26) : theme.spacing(9);
  const transition: Parameters<Transitions["create"]>[1] = {
    duration: theme.transitions.duration.standard,
    easing: theme.transitions.easing.easeInOut,
  };

  return (
    <AppNavDrawerContext.Provider
      value={{
        open,
        setOpen,
        tree,
        setTree,
        width,
        transition,
        navbarHeight,
        setNavbarHeight,
      }}
    >
      {children}
    </AppNavDrawerContext.Provider>
  );
}

function useAppNavDrawer() {
  const context = useContext(AppNavDrawerContext);
  if (context === undefined) {
    throw new Error(
      "useAppNavDrawer must be used within a AppNavDrawerProvider",
    );
  }
  return context;
}

export {
  AppNavDrawerProvider,
  useAppNavDrawer,
  type AppNavDrawerItem,
  type AppNavDrawerTree,
};
