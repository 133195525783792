import { createSvgIcon } from "@mui/material";

export const WindowsInstancesIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_17047)" />
    <path
      d="M6 6H19.2734V19.2677H6V6ZM20.7266 6H34V19.2677H20.7266V6ZM6 20.7266H19.2734V34H6V20.7266ZM20.7266 20.7266H34V34H20.7266"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_17047"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Windows Instances",
);
