import { FC } from "react";
import { styled } from "@mui/material/styles";

import { ContentBody } from "./content-body";
import { CircularProgress, Avatar } from "@mui/material";
import { ClearIcon } from "~/components/icons";

const CB = styled(ContentBody)`
  height: 80vh;
  width: 100%;
`;

const Text = styled("span")`
  margin: 0 ${(props) => props.theme.spacing(3)};
  color: ${(props) => props.theme.palette.text.primary};
`;

const Large = styled(Text)`
  font-size: 1.3em;
`;

const Box = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Red = styled(Avatar)`
  background: ${(props) => props.theme.palette.critical.main} !important;
  margin: 10px;
`;

const Message = styled("div")`
  color: ${(props) => props.theme.palette.text.primary};
  text-align: center;
  padding: ${(props) => props.theme.spacing(5)}
    ${(props) => props.theme.spacing(10)} 0;
  border-top: 1px solid ${(props) => props.theme.palette.action.selected};
  margin-top: ${(props) => props.theme.spacing(4)};
  max-width: 500px;
`;

export type LoadingProps = {
  what?: string;
  message?: any;
  vertical?: boolean;
};

export const Loading: FC<LoadingProps> = ({ what, vertical }) => (
  <Box
    sx={{
      ...(vertical && {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }),
    }}
  >
    <CircularProgress color="secondary" sx={{ ...(vertical && { mb: 2 }) }} />
    {what && <Text>Loading {what}...</Text>}
  </Box>
);

export const LoadingFailed: FC<LoadingProps> = ({ what, message }) => (
  <Box>
    <div>
      <Box>
        <Red>
          <ClearIcon />
        </Red>
        <Large>Failed to load {what}</Large>
      </Box>
      {message != null && <Message>Message: {message}</Message>}
    </div>
  </Box>
);

export const LoadingPage: FC<LoadingProps> = ({ what }) => (
  <CB>
    <Loading what={what} />
  </CB>
);

export const LoadingFailedPage: FC<LoadingProps> = ({ what, message }) => (
  <CB>
    <LoadingFailed what={what} message={message} />
  </CB>
);
