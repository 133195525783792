import React from "react";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { Space } from "~/lib/types";

type CasesBreadcrumbsProps = {
  space: Space;
};

export const CasesBreadcrumbs = ({ space }: CasesBreadcrumbsProps) => {
  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key="cases">Cases</Typography>,
  ];
  return (
    <Grid item xs={12}>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
    </Grid>
  );
};
