import { createSvgIcon } from "@mui/material";

export const PlusIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      d="M21 13.2857H13.2857V21H10.7143V13.2857H3V10.7143H10.7143V3H13.2857V10.7143H21V13.2857Z"
      fill="currentColor"
    />
  </svg>,
  "Add",
);
