import { createSvgIcon } from "@mui/material";

export const HeartbeatIcon = createSvgIcon(
  <svg
    viewBox="0 0 16 9"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M16 4.6661C15.9997 4.88602 15.8215 5.06422 15.6016 5.06454H13.2926L12.1853 7.47316V7.47304C12.1202 7.61407 11.9793 7.70447 11.824 7.70504H11.7997H11.7995C11.6356 7.69421 11.4948 7.58454 11.4443 7.42823L10.2973 3.82584L8.56106 8.47746C8.50257 8.63285 8.35402 8.73603 8.18792 8.73649H8.16398C7.98921 8.72668 7.84156 8.60332 7.80064 8.43301L6.18346 1.79034L4.82986 5.60112C4.77286 5.75959 4.62248 5.86516 4.45409 5.86505H0.398444C0.178409 5.86505 0 5.68663 0 5.4666C0 5.24657 0.178421 5.06816 0.398444 5.06816H4.17274L5.87885 0.265065C5.93529 0.106367 6.08532 0.000227649 6.25371 0H6.27503C6.4506 0.00980465 6.59914 0.132818 6.64158 0.303473L8.26801 6.9835L9.95407 2.46701V2.4669C10.0124 2.31151 10.1608 2.20833 10.3269 2.20765H10.3373C10.5071 2.21209 10.6554 2.32348 10.7071 2.48514L11.8909 6.20603L12.6753 4.50027V4.50015C12.7403 4.35924 12.8812 4.26872 13.0366 4.26815H15.6015C15.8214 4.26838 15.9996 4.44657 15.9999 4.66659L16 4.6661Z" />
  </svg>,
  "heartbeat",
);
