import { ReactNode } from "react";
import { Box, SxProps, Theme, Typography } from "@mui/material";

type AssetStatItemProps = {
  icon: ReactNode;
  label: string;
  value: string;
  onClick?: () => void;
  sx?: SxProps<Theme>;
};

export function StatItem({
  icon,
  label,
  value,
  onClick,
  sx,
}: AssetStatItemProps) {
  return (
    <Box
      onClick={onClick}
      sx={[
        {
          display: "flex",
          alignItems: "center",
          cursor: onClick ? "pointer" : "inherit",

          ".icon": {
            display: "flex",
            mr: 0.5,
            fontSize: 24,
          },
          ".value, .label": {
            fontSize: 16,
            fontWeight: 700,
          },
          ".value": {
            mr: 0.5,
          },
          ".label": {
            color: "text.secondary",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box className="icon">{icon}</Box>
      <Typography className="value" component="span">
        {value}
      </Typography>
      <Typography
        className="label"
        component="span"
        data-testid={`stat-${label}`}
      >
        {label}
      </Typography>
    </Box>
  );
}
