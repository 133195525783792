import { createSvgIcon } from "@mui/material";

export const AutoscalingGroupsIcon = createSvgIcon(
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M40 0H0V40H40V0Z" fill="url(#paint0_linear_1045_13894)" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.021 19.4953C18.021 19.3403 17.95 19.1983 17.833 19.1043L14.879 16.1513L14.172 16.8583L16.31 18.9953H7V19.9953H16.314L14.172 22.1373L14.879 22.8443L17.848 19.8753C17.956 19.7813 18.021 19.6433 18.021 19.4953ZM33.986 18.9953H24.675L26.813 16.8583L26.106 16.1513L23.137 19.1203C23.03 19.2133 22.964 19.3513 22.964 19.4993C22.964 19.6543 23.036 19.7973 23.153 19.8903L26.106 22.8443L26.813 22.1373L24.671 19.9953H33.986V18.9953ZM20.996 31.3243V22.0103H19.996V31.3203L17.858 29.1833L17.151 29.8903L20.12 32.8573C20.213 32.9663 20.352 33.0323 20.5 33.0323C20.656 33.0323 20.8 32.9593 20.893 32.8413L23.844 29.8903L23.137 29.1833L20.996 31.3243ZM17.858 9.83725L17.151 9.13025L20.103 6.17925C20.285 5.94725 20.683 5.93925 20.877 6.16225L23.844 9.13025L23.137 9.83725L20.996 7.69625V17.0103H19.996V7.70025L17.858 9.83725Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1045_13894"
        x1="0"
        y1="0"
        x2="40.9862"
        y2="38.9625"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9147FF" />
        <stop offset="1" stop-color="#4C35E8" />
      </linearGradient>
    </defs>
  </svg>,
  "Autoscaling Groups",
);
