import { createSvgIcon } from "@mui/material";

export const NumberIcon = createSvgIcon(
  <svg
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M18.5 8.075L19 6.05H15L16 2H14L13 6.05H9L10 2H8L7 6.05H3L2.5 8.075H6.5L5.5 12.125H1.5L1 14.15H5L4 18.2H6L7 14.15H11L10 18.2H12L13 14.15H17L17.5 12.125H13.5L14.5 8.075H18.5ZM11.5 12.125H7.5L8.5 8.075H12.5L11.5 12.125Z" />
  </svg>,
  "Number",
);
