import { ReactNode, useMemo } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { useColorMode } from "~/providers/color-mode";
import { createMondooTheme } from "~/themes/createTheme";
import { SnackbarComponents } from "~/themes/notification";
import { darkTheme } from "~/themes";
import "~/style/index.css";

type MondooThemeProviderProps = {
  children: ReactNode;
  loggedIn?: boolean;
};

export function MondooThemeProvider({
  children,
  loggedIn = false,
}: MondooThemeProviderProps) {
  // when not logged in, we don't have access to a users preference, so we return dark mode by default
  let theme;
  if (loggedIn) {
    const { mode, colorBlind, getThemeFromProvider } = useColorMode();
    theme = useMemo(
      () => createMondooTheme(getThemeFromProvider()),
      [mode, colorBlind],
    );
  } else {
    theme = createMondooTheme(darkTheme);
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <SnackbarProvider maxSnack={3} Components={SnackbarComponents}>
        {children}
      </SnackbarProvider>
    </ThemeProvider>
  );
}
