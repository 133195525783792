import { ReactNode } from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Breadcrumbs, Link } from "@mui/material";
import { HomeIcon } from "~/components/icons";

type Props = {
  trail: ReactNode[];
};

export function CicdBreadcrumbs({ trail = [] }: Props) {
  const [searchParams] = useSearchParams();
  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${searchParams.get("spaceId")}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    ...trail,
  ];

  return (
    <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
      {breadcrumbs}
    </Breadcrumbs>
  );
}
