import { createSvgIcon } from "@mui/material";

export const MoonsIcon = createSvgIcon(
  <svg
    viewBox="0 0 69 35"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M51.6089 0V34.4054C61.1093 34.4054 68.8125 26.704 68.8125 17.2018C68.8125 7.69957 61.1093 0 51.6089 0Z" />
    <path d="M34.4052 34.4072C43.9065 34.4072 51.6088 26.7049 51.6088 17.2036C51.6088 7.70231 43.9065 0 34.4052 0C24.904 0 17.2017 7.70231 17.2017 17.2036C17.2017 26.7049 24.904 34.4072 34.4052 34.4072Z" />
    <path d="M0 17.2036C0 26.704 7.70134 34.4072 17.2036 34.4072V0C7.70134 0 0 7.70134 0 17.2036Z" />
  </svg>,
  "Mondoo",
);
