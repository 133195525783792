import { ReactElement, useEffect, useState } from "react";
import { styled } from "@mui/material";
import { keyframes } from "@emotion/react";
import { motion } from "framer-motion";

export function AnimatedStarsComponent() {
  const [stars, setStars] = useState<ReactElement[] | undefined>();

  useEffect(() => {
    const newStars = generateStars();
    setStars(newStars);
  }, []);

  const generateStars = () => {
    return Array.from({ length: 100 }, (_x, i) => {
      return <Star key={`star-${i}`} />;
    });
  };

  return <StarsContainer>{stars}</StarsContainer>;
}

const StarsContainer = styled("div")`
  width: 100%;
`;

// Single Star Component
function Star(): ReactElement {
  // Generate random number up to set number
  const size = Math.floor(Math.random() * 4);
  const top = Math.floor(Math.random() * 101);
  const left = Math.floor(Math.random() * 101);
  // Generate random number between given numbers
  const moveDistance = -Math.floor(Math.random() * (40 - 20) + 20);
  const speed = Math.floor(Math.random() * (15 - 8) + 8);
  const moveDuration = Math.floor(Math.random() * (25 - 18) + 18);

  return (
    <StyledSvg
      height={size * 2}
      width={size * 2}
      left={left}
      top={top}
      animate={{ y: moveDistance }}
      transition={{ duration: moveDuration }}
    >
      <Circle cx={size} cy={size} r={size} speed={speed} />
    </StyledSvg>
  );
}

const twinkle = keyframes`
0% {
  opacity: 0;
  transform: scale(0);
}
20% {
  opacity: .7;
}
50% {
  opacity: .35;
}
80% {
  opacity: 1;
  transform: scale(1);
}
100% {
  opacity: 0;
}
`;

const StyledSvg = styled(motion.svg)<{ top: number; left: number }>`
  position: absolute;
  top: ${(p) => (p.top ? `${p.top}%` : 0)};
  left: ${(p) => (p.left ? `${p.left}%` : 0)};
`;

const Circle = styled("circle")<{ speed: number }>`
  fill: rgb(206, 229, 255);
  animation: ${twinkle} ${(p) => (p.speed ? p.speed : 2)}s linear infinite;
`;
