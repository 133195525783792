import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { SmallSpan, DisplayPricing, BillingButton } from "./components";
import { SubscriptionInfoQuery, SubscriptionItem } from "~/operations";

type SubscriptionPlans = NonNullable<
  SubscriptionInfoQuery["subscriptionPlans"]
>;

type PlanData = {
  [key: string]: PlanDataItem;
};

type PlanDataItem = {
  key: string;
  name: string;
  description: string;
  cost: number;
  currency: string;
  priceID: string;
  assetsQuantity: number;
  buildsQuantity: number;
  supportType: string;
  premiumSupportAvailable: boolean;
  isCurrent: boolean;
  isSelfService: boolean;
  isDowngrade: boolean;
  isUpgrade: boolean;
};

type AvailableSubscriptionsProps = {
  subscriptionPlans: SubscriptionPlans;
  onPlanSelect: (items: SubscriptionItem[]) => void;
};

export function AvailableSubscriptions({
  subscriptionPlans,
  onPlanSelect,
}: AvailableSubscriptionsProps) {
  const planData: PlanData = {};
  subscriptionPlans.forEach((p) => {
    const { basePlan } = p;
    const assetsEntitlement = basePlan.entitlements?.find(
      (e) => e.key === "org_assets",
    );
    const buildsEntitlement = basePlan.entitlements?.find(
      (e) => e.key === "org_pipeline_builds",
    );
    planData[basePlan.key] = {
      key: basePlan.key,
      name: basePlan.name,
      description: basePlan.description,
      cost: basePlan.price.cost,
      currency: basePlan.price.currency,
      priceID: basePlan.price.id,
      assetsQuantity: assetsEntitlement?.value || 0,
      buildsQuantity: buildsEntitlement?.value || 0,
      supportType: "Community-based",
      premiumSupportAvailable: basePlan.key !== "plan_community",
      isCurrent: p.isCurrent,
      isSelfService: p.isSelfService,
      isDowngrade: p.isDowngrade,
      isUpgrade: p.isUpgrade,
    };
  });

  const communityPlan = planData["plan_community"];
  const teamPlan = planData["plan_team"];
  const enterprisePlan = planData["plan_enterprise"];

  const planButton = (plan: PlanDataItem) => {
    let text = "Unavailable";
    if (plan.isCurrent) {
      text = "Active";
    } else if (plan.isUpgrade && plan.isSelfService) {
      text = "Upgrade";
    } else if (plan.isDowngrade && plan.isSelfService) {
      text = "Downgrade";
    } else if (!plan.isSelfService) {
      text = "Get a quote";
    }

    return (
      <BillingButton
        color="primary"
        active={plan.isCurrent}
        disabled={plan.isCurrent}
        onClick={() => {
          const items = [{ priceID: plan.priceID, quantity: 1 }];
          onPlanSelect(items);
        }}
      >
        {text}
      </BillingButton>
    );
  };

  const formatCost = (cost: number) => (cost / 100).toString();

  return (
    <Grid container component={Paper} mt={2}>
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "background.light",
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
        p={0}
      >
        <Typography
          component="h3"
          variant="body1"
          sx={{ textTransform: "uppercase", padding: 2 }}
        >
          Available Subscriptions
        </Typography>
      </Grid>

      <Grid container item>
        <Table sx={[{ td: { borderBottom: "none", verticalAlign: "top" } }]}>
          <TableBody>
            <TableRow
              sx={[
                {
                  "td p": {
                    fontWeight: 700,
                    display: "inline-block",
                    marginRight: 1,
                    marginBottom: 1,
                  },
                },
              ]}
            >
              <TableCell></TableCell>
              <TableCell>
                <Typography>{communityPlan.name}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{teamPlan.name}</Typography>
                <Chip
                  label="Recommended"
                  color="primary"
                  variant="outlined"
                  size="small"
                />
              </TableCell>
              <TableCell>
                <Typography>{enterprisePlan.name}</Typography>
              </TableCell>
            </TableRow>

            <TableRow sx={[{ td: { verticalAlign: "top" } }]}>
              <TableCell></TableCell>
              <TableCell>
                <Typography>{communityPlan.description}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{teamPlan.description}</Typography>
              </TableCell>
              <TableCell>
                <Typography>{enterprisePlan.description}</Typography>
              </TableCell>
            </TableRow>

            <TableRow sx={[{ td: { verticalAlign: "bottom" } }]}>
              <TableCell></TableCell>
              <TableCell>
                <Typography
                  variant="h5"
                  color="none.main"
                  sx={{ fontWeight: 700 }}
                >
                  ${formatCost(communityPlan.cost)}{" "}
                  <SmallSpan text="- free forever" sx={{ color: "#fff" }} />
                </Typography>
              </TableCell>
              <TableCell>
                <DisplayPricing
                  prepend="From"
                  price={formatCost(teamPlan.cost)}
                  text="/ month"
                />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>

            <TableRow sx={{ backgroundColor: "grey.900" }}>
              <TableCell align="right">
                <Typography sx={{ fontWeight: 700 }}>Assets</Typography>
              </TableCell>
              <TableCell>
                <Typography>{communityPlan.assetsQuantity}</Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {teamPlan.assetsQuantity} <SmallSpan text="(Extendable)" />
                </Typography>
              </TableCell>
              <TableCell>
                <Typography>
                  {enterprisePlan.assetsQuantity}{" "}
                  <SmallSpan text="(Extendable)" />
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="right">
                <Typography sx={{ fontWeight: 700 }}>CI/CD Scans</Typography>
              </TableCell>
              <TableCell>
                <Typography>Unlimited</Typography>
              </TableCell>
              <TableCell>
                <Typography>Unlimited</Typography>
              </TableCell>
              <TableCell>
                <Typography>Unlimited</Typography>
              </TableCell>
            </TableRow>

            <TableRow
              sx={[
                {
                  backgroundColor: "grey.900",
                  td: {
                    verticalAlign: "top",
                  },
                },
              ]}
            >
              <TableCell align="right">
                <Typography sx={{ fontWeight: 700 }}>Support</Typography>
              </TableCell>
              <TableCell>
                <Typography gutterBottom>
                  {communityPlan.supportType}
                </Typography>
                <Typography sx={{ color: "#987BEA" }}>
                  {communityPlan.premiumSupportAvailable
                    ? "Premium support available"
                    : ""}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography gutterBottom>{teamPlan.supportType}</Typography>
                <Typography sx={{ color: "#987BEA" }}>
                  {teamPlan.premiumSupportAvailable
                    ? "Premium support available"
                    : ""}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography gutterBottom>
                  {enterprisePlan.supportType}
                </Typography>
                <Typography sx={{ color: "#987BEA" }}>
                  {enterprisePlan.premiumSupportAvailable
                    ? "Premium support available"
                    : ""}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow sx={[{ td: { verticalAlign: "bottom" } }]}>
              <TableCell></TableCell>
              <TableCell>{planButton(communityPlan)}</TableCell>
              <TableCell>{planButton(teamPlan)}</TableCell>
              <TableCell>{planButton(enterprisePlan)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
