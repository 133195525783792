import { createSvgIcon } from "@mui/material";

export const DefaultRiskAction = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 20" fill="none">
    <path
      d="M10.99 2.00054C11.1766 1.99916 11.3602 2.04753 11.5219 2.14069C11.6836 2.23385 11.8175 2.36842 11.91 2.53054L19.84 16.2605C19.9353 16.4236 19.9857 16.6089 19.9862 16.7978C19.9867 16.9866 19.9372 17.1722 19.8428 17.3358C19.7483 17.4993 19.6122 17.6349 19.4484 17.7288C19.2846 17.8228 19.0988 17.8716 18.91 17.8705H3.05995C2.87196 17.8699 2.68745 17.8197 2.52502 17.7251C2.36258 17.6304 2.22795 17.4946 2.13467 17.3314C2.0414 17.1682 1.99278 16.9833 1.9937 16.7953C1.99463 16.6073 2.04507 16.4228 2.13995 16.2605L10.06 2.53054C10.1555 2.369 10.2916 2.23516 10.4547 2.14222C10.6178 2.04928 10.8022 2.00045 10.99 2.00054ZM10.99 0.500542C10.5316 0.491112 10.0792 0.604923 9.67989 0.830082C9.28059 1.05524 8.94906 1.38349 8.71995 1.78054L0.839953 15.5105C0.616577 15.9014 0.499379 16.344 0.500002 16.7942C0.500626 17.2444 0.619047 17.6866 0.843505 18.0769C1.06796 18.4672 1.39064 18.7919 1.77947 19.0189C2.1683 19.2458 2.60975 19.367 3.05995 19.3705H18.91C19.361 19.3688 19.8037 19.2488 20.1939 19.0226C20.5841 18.7963 20.9082 18.4717 21.1339 18.0812C21.3595 17.6906 21.4788 17.2477 21.4799 16.7967C21.4809 16.3456 21.3637 15.9022 21.14 15.5105L13.21 1.78054C12.9874 1.38886 12.6643 1.06371 12.274 0.838684C11.8837 0.61366 11.4404 0.496924 10.99 0.500542Z"
      fill="currentColor"
    />
    <path
      d="M11.7402 7.91992C11.7402 7.50571 11.4044 7.16992 10.9902 7.16992C10.576 7.16992 10.2402 7.50571 10.2402 7.91992V11.4199C10.2402 11.8341 10.576 12.1699 10.9902 12.1699C11.4044 12.1699 11.7402 11.8341 11.7402 11.4199V7.91992Z"
      fill="currentColor"
    />
    <path
      d="M10.9902 15.1699C11.4044 15.1699 11.7402 14.8341 11.7402 14.4199C11.7402 14.0057 11.4044 13.6699 10.9902 13.6699C10.576 13.6699 10.2402 14.0057 10.2402 14.4199C10.2402 14.8341 10.576 15.1699 10.9902 15.1699Z"
      fill="currentColor"
    />
  </svg>,
  "Risk Action",
);
