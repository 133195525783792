import { Box } from "@mui/material";
import { useColorMode } from "~/providers/color-mode";

export function Jupiter({}) {
  const { mode } = useColorMode();
  return (
    <Box
      component="svg"
      width="100%"
      height="auto"
      viewBox="0 0 206 207"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        {...(mode === "light"
          ? { opacity: "0.3" }
          : { clipPath: "url(#clip0_4002_14265)" })}
      >
        {mode === "light" ? (
          <path
            d="M79.9067 189.685C127.42 202.416 176.257 174.22 188.988 126.707C201.719 79.1937 173.523 30.3562 126.01 17.6252C78.497 4.89411 29.6595 33.0904 16.9285 80.6034C4.19738 128.116 32.3937 176.954 79.9067 189.685Z"
            fill="url(#paint0_radial_4292_40765)"
            stroke="#BCBCBC"
            strokeWidth="10"
          />
        ) : (
          <path
            d="M81.2008 184.855C126.046 196.872 172.142 170.258 184.159 125.413C196.175 80.5669 169.562 34.4712 124.716 22.4548C79.8702 10.4385 33.7744 37.0519 21.7581 81.8975C9.74172 126.743 36.3551 172.839 81.2008 184.855Z"
            fill="url(#paint0_radial_4891_52924)"
          />
        )}
        <path
          opacity="0.25"
          d="M122.348 144.909C100.736 139.118 93.0852 151.809 70.5372 145.767C47.9892 139.725 47.7119 124.91 26.0995 119.119L20.0688 117.503C20.841 122.017 21.981 126.46 23.4775 130.788C39.7505 135.258 43.58 150.908 67.5944 157.343C91.6971 163.801 102.833 152.048 119.259 156.449L158.11 166.859C161.569 163.861 164.775 160.583 167.698 157.061L122.348 144.909Z"
          fill={mode === "light" ? "#0BBAFF" : "#039CD8"}
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.368 136.215L128.542 122.328C104.579 115.908 106.647 101.721 84.1018 95.6802C61.5567 89.6393 56.2426 102.956 32.2911 96.538L19.5165 93.1151C18.7622 99.1652 18.6731 105.28 19.2506 111.349L27.5835 113.582C51.5465 120.003 49.4768 134.186 72.0219 140.227C94.5671 146.268 99.8803 132.954 123.832 139.372L171.473 152.137C175.269 146.806 178.425 141.047 180.877 134.979C180.71 135.396 180.539 135.805 180.368 136.215ZM74.2901 132.344C61.8379 129.007 53.4596 119.938 55.5647 112.081C57.6699 104.225 69.4718 100.563 81.9126 103.896C94.3534 107.23 102.742 116.305 100.638 124.159C98.5336 132.012 86.7452 135.681 74.2901 132.344Z"
          fill="#2D234F"
        />
        <path
          opacity="0.3"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M184.875 84.4538C184.643 83.4498 184.392 82.451 184.122 81.4573L43.6444 43.8166C42.9163 44.5408 42.1994 45.2802 41.4937 46.0349L184.875 84.4538Z"
          fill={mode === "light" ? "#0BBAFF" : "#039CD8"}
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M51.7969 170.132C69.4879 183.665 91.816 189.648 113.904 186.773L51.7969 170.132Z"
          fill="#18142D"
        />
        <path
          opacity="0.25"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M133.703 102.534C117.274 98.1324 113.507 82.3856 89.4068 75.9281C65.304 69.4697 54.1683 81.2233 37.7394 76.8212L24.4771 73.2676C23.4269 76.0101 22.5198 78.8052 21.7593 81.6417C21.2441 83.5644 20.8008 85.4891 20.4292 87.4157L33.7915 90.9961C55.4038 96.7871 63.0506 84.0987 85.5986 90.1404C108.147 96.1821 108.425 110.994 130.037 116.785L182.428 130.823C183.065 128.97 183.647 127.08 184.163 125.158C184.923 122.319 185.536 119.443 185.998 116.541L133.703 102.534Z"
          fill={mode === "light" ? "#0BBAFF" : "#039CD8"}
        />
        <path
          d="M75.0593 24.1048L166.786 48.6829C165.634 47.3368 164.44 46.0281 163.203 44.757L80.1113 22.4926C78.4053 22.9711 76.7214 23.5085 75.0593 24.1048Z"
          fill="#2B2B2B"
        />
        <path
          d="M120.631 185.61L46.5537 165.761C48.2369 167.284 49.9867 168.741 51.803 170.133L113.904 186.773C116.166 186.478 118.408 186.09 120.631 185.61Z"
          fill="#141414"
        />
        <path
          d="M43.6445 43.8166L184.133 81.4605C183.838 80.3785 183.523 79.3046 183.189 78.2387L46.0727 41.4984C45.2509 42.2525 44.4416 43.0253 43.6445 43.8166Z"
          fill="#1C1C1C"
        />
        <path
          d="M39.3393 48.4387L185.542 87.6136C185.342 86.5571 185.12 85.5079 184.875 84.466L41.4908 46.0463C40.7639 46.8116 40.0468 47.6091 39.3393 48.4387Z"
          fill="#2B2B2B"
        />
        <path
          d="M185.996 116.547C186.15 115.585 186.287 114.624 186.408 113.663L134.448 99.7409C119.169 95.6469 115.975 80.0537 90.155 73.1353C64.3349 66.2168 53.7698 78.1233 38.4905 74.0292L25.5619 70.565C25.1839 71.4584 24.8223 72.3593 24.477 73.2676L37.7394 76.8213C54.1683 81.2234 65.304 69.4698 89.4067 75.9281C113.507 82.3856 117.274 98.1324 133.703 102.535L185.996 116.547Z"
          fill="#191919"
        />
        <path
          opacity="0.53"
          d="M74.2864 132.346C86.7393 135.683 98.5407 132.019 100.646 124.164C102.751 116.308 94.3618 107.235 81.9089 103.898C69.4559 100.562 57.6545 104.225 55.5496 112.08C53.4447 119.936 61.8335 129.009 74.2864 132.346Z"
          fill="#372731"
        />
        <path
          d="M130.027 116.789C108.414 110.998 108.133 96.1849 85.5883 90.1439C63.0431 84.103 55.3934 96.7907 33.7811 90.9997L20.4275 87.4216C20.0569 89.3219 19.7522 91.2195 19.5136 93.1145L32.2995 96.5404C56.2625 102.961 61.5623 89.6409 84.1103 95.6826C106.658 101.724 104.599 115.913 128.551 122.331L180.365 136.214C181.108 134.453 181.793 132.657 182.42 130.827L130.027 116.789Z"
          fill="#1C1C1C"
        />
        <path
          d="M72.0218 140.227C49.4852 134.188 51.5349 120 27.5833 113.582L19.2505 111.349C19.4464 113.418 19.7182 115.469 20.0661 117.503L26.0997 119.119C47.712 124.91 47.9922 139.726 70.5373 145.767C93.0825 151.808 100.736 139.118 122.348 144.909L167.707 157.063C169.025 155.474 170.286 153.833 171.49 152.142L123.849 139.376C99.8802 132.954 94.5698 146.269 72.0218 140.227Z"
          fill="#1C1C1C"
        />
        <path
          d="M74.2864 132.346C86.7393 135.683 98.5407 132.019 100.646 124.164C102.751 116.308 94.3618 107.235 81.9089 103.898C69.4559 100.562 57.6545 104.225 55.5496 112.08C53.4447 119.936 61.8335 129.009 74.2864 132.346Z"
          fill="#1C1C1C"
        />
        <path
          d="M119.256 156.448C102.83 152.047 91.6943 163.801 67.5915 157.342C43.5772 150.908 39.7484 135.254 23.469 130.785C23.9441 132.154 24.4513 133.513 24.9908 134.862C38.1477 139.916 42.4077 154.12 66.7016 160.629C92.5217 167.548 103.084 155.64 118.366 159.735L154.881 169.519C155.973 168.662 157.045 167.778 158.096 166.868L119.253 156.46L119.256 156.448Z"
          fill="#191919"
        />
      </g>
      <defs>
        <radialGradient
          id="paint0_radial_4891_52924"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.5046 49.8323) rotate(15) scale(150.356)"
        >
          <stop offset="0.09" stopColor="#24946A" />
          <stop offset="1" stopColor="#14261E" />
        </radialGradient>
        <radialGradient
          id="paint0_radial_4292_40765"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.5051 50.0823) rotate(15) scale(150.356)"
        >
          <stop offset="0.09" stopColor="#24946A" />
          <stop offset="0.09" stopColor="#56F0B6" />
          <stop offset="1" stopColor="#14261E" />
          <stop offset="1" stopColor="#0D5B38" />
        </radialGradient>
        <clipPath id="clip0_4891_52924">
          <rect
            width="168.129"
            height="168.129"
            fill="white"
            transform="translate(43.5151 0.447266) rotate(15)"
          />
        </clipPath>
      </defs>
    </Box>
  );
}
