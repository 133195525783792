import { useTheme } from "@mui/material";
import { useGetImpactColor } from "~/components/impact/use-get-impact-color";
import { VersionDistribution, VersionDistributionEntry } from "~/operations";

type DistrByColor = { [color: string]: VersionDistributionEntry[] };

type UseSoftwareVersionDistributionParams = {
  versionDistribution: VersionDistribution | null | undefined;
};

export function useSoftwareVersionDistribution({
  versionDistribution,
}: UseSoftwareVersionDistributionParams) {
  const theme = useTheme();

  const total = versionDistribution?.total || 0;
  const distribution = versionDistribution?.distribution || [];

  const longestVersion = distribution.reduce((maxElement, current) => {
    return Math.max(maxElement, current.version.length);
  }, 0);

  const distributionByColor = distribution.reduce<DistrByColor>(
    (acc, current) => {
      const color = useGetImpactColor({
        impact: current.riskScore,
        isRiskScore: true,
      });
      const versionsByColor = acc[color] || [];
      versionsByColor.push(current as VersionDistributionEntry);
      acc[color] = versionsByColor.sort((a, b) => b.count - a.count);
      return acc;
    },
    {},
  );

  const colorsOrder = [
    theme.palette.critical.main,
    theme.palette.high.main,
    theme.palette.medium.main,
    theme.palette.low.main,
    theme.palette.none.main,
  ];

  const sortedDistributionByColor = Object.entries(distributionByColor).sort(
    ([color1], [color2]) => {
      return colorsOrder.indexOf(color1) - colorsOrder.indexOf(color2);
    },
  );

  const threshold = 5;

  const getSortedDistributionByColorWithoutRest = (
    items: VersionDistributionEntry[],
  ) => {
    return [...items].filter((version) => {
      return (version.count / total) * 100 > threshold;
    });
  };

  const getItemsToHide = (items: VersionDistributionEntry[]) => {
    return (
      [...items].filter(
        (version) => (version.count / total) * 100 < threshold,
      ) || []
    );
  };

  const getRestItems = (items: VersionDistributionEntry[]) => {
    return items.reduce((acc, item) => ({
      ...acc,
      count: acc.count + item.count,
    }));
  };

  const impactFractions = sortedDistributionByColor
    .reduce((acc, [color, items]) => {
      const totalGroupCount = items.reduce((agg, curr) => {
        return agg + curr.count;
      }, 0);

      return acc + `${totalGroupCount}fr `;
    }, "")
    .trim();

  return {
    sortedDistributionByColor,
    longestVersion,
    total,
    threshold,
    getSortedDistributionByColorWithoutRest,
    getItemsToHide,
    getRestItems,
    impactFractions,
  };
}
