import { ReactNode, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  Box,
} from "@mui/material";
import { LoadingButton } from "./loading-button";
import { pluralize } from "../lib/pluralize";
import { useViewer } from "~/providers/viewer";
import { LoadViewerDocument } from "~/operations";

type DeleteConfirmDialogProps = {
  title?: string;
  content?: string | ReactNode;
  dividers?: boolean;
  open: boolean;
  items?: any[]; // needs any[] until awsIntegration has type
  onConfirm: () => void;
  onClose: () => void;
  itemType?: string;
  itemName?: (item: any) => string; // needs any until awsIntegration has type
  isDeleting: boolean;
  includeDoNotAskCheck?: boolean;
};

export function DeleteConfirmDialog({
  title,
  content,
  items,
  dividers = true,
  itemName,
  itemType,
  onConfirm,
  onClose,
  open,
  isDeleting,
  includeDoNotAskCheck = false,
}: DeleteConfirmDialogProps) {
  const { updateViewerSettings } = useViewer();
  const [doNotAskChecked, setDoNotAskChecked] = useState<boolean>(false);

  const handleChangeAskDeleteSetting = (value: boolean) => {
    updateViewerSettings({
      variables: { key: "assetDoNotAskToDelete", value: value.toString() },
      refetchQueries: [LoadViewerDocument],
    });
  };

  let listContent: ReactNode;
  // this is crap
  if (items && itemName && itemType) {
    listContent = (
      <>
        <p>
          Confirm deletion of {items.length} selected{" "}
          {pluralize(itemType, items.length)}:
        </p>
        <ul>
          {items.map((item, index) => (
            <li key={index}>{itemName(item) || "n/a"}</li>
          ))}
        </ul>
      </>
    );
  }

  const handleCheckboxChange: CheckboxProps["onChange"] = (event) => {
    setDoNotAskChecked(event.target.checked);
    handleChangeAskDeleteSetting(event.target.checked);
  };

  const renderDialogueContent = () => {
    if (content) {
      return content;
    } else {
      if (listContent) {
        return listContent;
      } else {
        return "Are you sure you'd like to delete this?";
      }
    }
  };

  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle
        id="delete-agents"
        variant="h5"
        sx={{ textTransform: "uppercase", fontWeight: 700 }}
      >
        {title ? title : "Confirm deletion"}
      </DialogTitle>
      <DialogContent dividers={dividers}>
        <Box>{renderDialogueContent()}</Box>
        <Box mt={1}>
          {includeDoNotAskCheck && (
            <FormControlLabel
              label="Do not ask me for confirmation again"
              control={
                <Checkbox
                  checked={doNotAskChecked}
                  onChange={handleCheckboxChange}
                />
              }
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={isDeleting}
          buttonText="Delete"
          onClick={() => onConfirm()}
          variant="contained"
          color="critical"
        />
        <Button onClick={() => onClose()} variant="contained" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
