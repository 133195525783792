import { createSvgIcon } from "@mui/material";

export const ProviderGitlabIcon = createSvgIcon(
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="128" height="128" rx="4" fill="white" />
    <path
      d="M114.269 53.5776L114.123 53.2046L99.9675 16.326C99.6795 15.6032 99.1695 14.99 98.5108 14.5745C97.8516 14.166 97.0828 13.9693 96.3081 14.0109C95.5334 14.0524 94.7902 14.3303 94.1787 14.807C93.574 15.2973 93.1353 15.9617 92.9224 16.7098L83.3646 45.9013H44.6625L35.1047 16.7098C34.8974 15.9576 34.4578 15.2899 33.8484 14.8015C33.2369 14.3249 32.4936 14.047 31.7189 14.0055C30.9442 13.9639 30.1754 14.1606 29.5163 14.5691C28.859 14.9863 28.3495 15.5989 28.0596 16.3206L13.8773 53.1829L13.7365 53.556C11.6988 58.871 11.4473 64.7033 13.0199 70.1735C14.5925 75.6437 17.9039 80.4552 22.4549 83.8827L22.5037 83.9205L22.6336 84.0124L44.1968 100.133L54.8646 108.193L61.3628 113.09C62.1229 113.666 63.051 113.978 64.0054 113.978C64.9598 113.978 65.8879 113.666 66.648 113.09L73.1462 108.193L83.8141 100.133L105.507 83.9151L105.561 83.8719C110.102 80.4438 113.406 75.6372 114.976 70.1744C116.546 64.7116 116.298 58.8876 114.269 53.5776V53.5776Z"
      fill="#E24329"
    />
    <path
      d="M114.269 53.5776L114.123 53.2046C107.225 54.6179 100.726 57.5344 95.0884 61.7458L64 85.2125C74.5866 93.2077 83.8032 100.154 83.8032 100.154L105.496 83.9367L105.551 83.8935C110.098 80.4656 113.407 75.6559 114.979 70.1884C116.551 64.7209 116.302 58.8915 114.269 53.5776Z"
      fill="#FC6D26"
    />
    <path
      d="M44.1968 100.154L54.8646 108.214L61.3628 113.112C62.1229 113.688 63.0511 114 64.0054 114C64.9598 114 65.8879 113.688 66.648 113.112L73.1462 108.214L83.8141 100.154C83.8141 100.154 74.5866 93.1861 64 85.2125C53.4134 93.1861 44.1968 100.154 44.1968 100.154Z"
      fill="#FCA326"
    />
    <path
      d="M32.9062 61.7458C27.2733 57.5258 20.7751 54.6017 13.8773 53.183L13.7365 53.556C11.6988 58.871 11.4473 64.7033 13.0199 70.1735C14.5925 75.6437 17.9039 80.4553 22.4549 83.8827L22.5037 83.9205L22.6336 84.0124L44.1968 100.133C44.1968 100.133 53.4025 93.1861 64 85.1909L32.9062 61.7458Z"
      fill="#FC6D26"
    />
  </svg>,
  "GitLab",
);
