import { OverflowHelper } from "~/components/OverflowHelper";
import { Box, Chip, TableCell, Typography } from "@mui/material";
import { AdvisoryVendorIcon } from "~/components/icons/mondoo/utils/advisoryVendorIcon";
import { advisoryTitle } from "~/lib/types";
import { CommonCellProps } from "~/pages/inventory/components/Advisories/types";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";
import { BlockIcon } from "~/components/icons";
import { MoonIcon } from "~/components/ui-library";
import { ScoreState } from "~/operations";

export const AdvisoryCell = ({ advisory, ...props }: CommonCellProps) => {
  const hasException = props?.hasException ? advisory.state : "";

  const ExceptionChip = ({
    hasException,
  }: {
    hasException: typeof props.hasException;
  }) => {
    return (
      <>
        {hasException === ScoreState.Snoozed && (
          <Chip label="Snoozed" icon={<MoonIcon />} size="small" />
        )}
        {hasException === ScoreState.Disabled && (
          <Chip label="Disabled" icon={<BlockIcon />} size="small" />
        )}
      </>
    );
  };

  return (
    <TableCell data-testid={props["data-testid"]}>
      <OverflowHelper>
        <Typography
          fontSize="14px"
          style={{
            display: "flex",
            alignItems: "center",
          }}
          gap={1}
          data-testid="advisory-id"
        >
          {hasException && <ExceptionChip hasException={hasException} />}
          <AdvisoryVendorIcon id={advisory.id} />
          {advisory.id} {advisoryTitle(advisory)}
        </Typography>
      </OverflowHelper>
      <Box display="flex" gap={1} mt={1}>
        {advisory.tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
      </Box>
    </TableCell>
  );
};
