import { AffectedAssetsTable } from "~/pages/space/vulnerabilities/components/AffectedAssets/AffectedAssetsTable";
import { useCheckAffectedAssets } from "~/pages/space/vulnerabilities/components/AffectedAssets/hooks/useCheckAffectedAssets";
import { Space } from "~/lib/types";
import {
  AssetContextualLinkType,
  SelectionProps,
} from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { SectionHeading } from "~/components/DetailsPage/components";
import { EmptySection } from "~/components/vulnerabilities";
import { Grid } from "@mui/material";
import { Loading, LoadingFailed } from "~/components/loading";

type CheckAffectedAssetsAdapterProps = {
  checkMrn: string;
  space: Space;
  selectionProps?: SelectionProps | undefined;
};

export const CheckAffectedAssetsAdapter = ({
  checkMrn,
  space,
  selectionProps,
}: CheckAffectedAssetsAdapterProps) => {
  const {
    assets,
    orderBy,
    handleSortClick,
    filteredTotalCount,
    pageInfo,
    fetchMore,
    error,
    loading,
  } = useCheckAffectedAssets({
    space,
    checkMrn,
  });

  if (error) {
    return <LoadingFailed what="Affected Assets" />;
  }

  if (loading) {
    return <Loading what="Affected Assets" />;
  }

  return (
    <Grid id="affected-assets" item xs={12}>
      <SectionHeading heading="Affected Assets" />
      {(assets?.length || 0) > 0 && !error ? (
        <AffectedAssetsTable
          assets={assets}
          orderBy={orderBy}
          handleSortClick={handleSortClick}
          space={space}
          filteredTotalCount={filteredTotalCount}
          pageInfo={pageInfo}
          fetchMore={fetchMore}
          selectionProps={selectionProps}
          urlContextType={AssetContextualLinkType.Check}
          contextId={checkMrn}
        />
      ) : (
        <EmptySection
          id="affected-assets-list-empty"
          text={`There are currently no affected assets for this check`}
        />
      )}
    </Grid>
  );
};
