import { Fragment } from "react";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { MoonIcon } from "~/components/ui-library";
import {
  AccessTimeIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  PersonIcon,
} from "~/components/icons";
import { FormatDate } from "~/lib/date";
import { BlockOutlined } from "@mui/icons-material"; // TODO::UILIBRARY move into ui-library, properly exposing props
import { useExceptionApproval } from "./use-exception-approval";
import { ExceptionGroup, TestIamActionsQuery } from "~/operations";
import { Space } from "~/lib/types";
import { LoadingButton } from "~/components/loading-button";
import { RemoveExceptionDialog } from "~/components/exceptions/remove-exception-dialog";

type ControlExceptionApprovalProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  exceptionGroup: ExceptionGroup;
  frameworkMrn: string;
  controlMrn: string;
  space: Space;
};

export function ControlExceptionApproval({
  availablePermissions,
  exceptionGroup,
  frameworkMrn,
  controlMrn,
  space,
}: ControlExceptionApprovalProps) {
  const { status, handle, hasReviewPermissions, deleteDialogOpen } =
    useExceptionApproval({
      availablePermissions,
      exceptionGroup,
      frameworkMrn,
      controlMrn,
      space,
    });

  return (
    <Box
      component={Paper}
      sx={{
        padding: 3,
        pb: 1,
        border: "1px solid",
        borderColor: "primary.main",
        borderRadius: 1,
      }}
      data-test-id="control-exception-approval"
    >
      <Grid container rowGap={3}>
        <Grid item container xs={12}>
          <Grid
            item
            xs={12}
            md="auto"
            order={{ md: 2 }}
            sx={{ mb: { xs: 2, md: 0 } }}
            textAlign="right"
          >
            {status.isApproved ? (
              <Button
                variant="outlined"
                disabled
                startIcon={<CheckIcon />}
                sx={{ color: "text.secondary" }}
                data-test-id="exception-approved-tag"
              >
                Approved
              </Button>
            ) : (
              <Fragment>
                {hasReviewPermissions && (
                  <Fragment>
                    <LoadingButton
                      variant="outlined"
                      startIcon={<CloseIcon />}
                      sx={{ color: "error.light" }}
                      onClick={handle.reject}
                      data-test-id="exception-reject-button"
                      loading={status.rejectLoading}
                      disabled={status.rejectLoading || status.acceptLoading}
                      buttonText="Reject"
                    />

                    <LoadingButton
                      variant="outlined"
                      startIcon={<CheckIcon />}
                      sx={{ ml: 3, color: "none.light" }}
                      onClick={handle.accept}
                      data-test-id="exception-accept-button"
                      loading={status.acceptLoading}
                      disabled={status.acceptLoading || status.rejectLoading}
                      buttonText="Accept"
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </Grid>

          <Grid item container columnGap={3} xs={12} md order={{ md: 1 }}>
            <Grid item xs="auto">
              {status.isSnoozed && <MoonIcon />}
              {status.isDisabled && <BlockOutlined />}
            </Grid>
            <Grid item container xs>
              <Typography fontWeight={700} textTransform="uppercase">
                {exceptionGroup?.title}
              </Typography>
              <Grid item container xs={12} sx={{ columnGap: 3, rowGap: 0.5 }}>
                {exceptionGroup?.author && (
                  <Grid item xs={12} sm="auto">
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: (theme) => theme.spacing(1.5),
                        color: "text.secondary",
                      }}
                    >
                      <PersonIcon fontSize="small" sx={{ mr: 1 }} />
                      {exceptionGroup?.author.name}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} sm="auto">
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: (theme) => theme.spacing(1.5),
                      color: "text.secondary",
                    }}
                  >
                    <AccessTimeIcon fontSize="small" sx={{ mr: 1 }} />
                    {FormatDate(exceptionGroup?.createdAt)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            color="text.secondary"
            data-test-id="exception-justification"
          >
            {exceptionGroup?.justification}
          </Typography>
        </Grid>
        {hasReviewPermissions && (
          <Grid
            item
            container
            xs={12}
            display="flex"
            justifyContent="flex-end"
            columnGap={2}
            rowGap={1}
            sx={{
              pt: 1,
              borderTop: "1px solid",
              borderColor: "background.lightest",
            }}
          >
            <Grid item xs={12} sm="auto" textAlign="right">
              <Button
                startIcon={<DeleteIcon />}
                onClick={handle.openDeleteDialog}
                data-test-id="exception-delete-button"
                disabled={
                  status.rejectLoading || status.acceptLoading || status.loading
                }
              >
                Delete Exception and Enable
              </Button>
            </Grid>
            {/* Editing is not yet available */}
            {/* <Grid item xs={12} sm="auto" textAlign="right">
              <Button startIcon={<EditIcon />} onClick={handle.edit}>
                Edit Exception
              </Button>
            </Grid> */}
          </Grid>
        )}
      </Grid>
      <RemoveExceptionDialog
        open={deleteDialogOpen}
        onClose={handle.closeDeleteDialog}
        onRemove={handle.delete}
        loading={status.rejectLoading || status.loading}
        target="control"
        exceptionGroups={[exceptionGroup]}
        selectedEntities={[
          {
            groupId: exceptionGroup?.id,
            mrn: controlMrn,
            exception: {
              action: exceptionGroup.action,
              justification: exceptionGroup.justification,
            },
          },
        ]}
      />
    </Box>
  );
}
