import { Box, styled } from "@mui/material";
import { PrintPackages } from "./print-packages";
import { PrintAdvisories } from "./print-advisories";
import { ListPolicyEdge } from "~/pages/inventory/asset/types";
import { PrintPolicySummaryHeader } from "./PrintPolicySummaryHeader";

type PrintPolicyVulnReportProps = {
  edge: ListPolicyEdge;
  assetMrn: string;
};

export function PrintPolicyVulnReport({
  edge,
  assetMrn,
}: PrintPolicyVulnReportProps) {
  return (
    <PrintPolicyVulnBox id={edge.node.mrn}>
      <PrintPolicySummaryHeader listPolicyEdge={edge} />
      <PrintAdvisories assetMrn={assetMrn} />
      <PrintPackages assetMrn={assetMrn} hideEmpty />
    </PrintPolicyVulnBox>
  );
}

const PrintPolicyVulnBox = styled(Box)`
  td {
    padding: 10px;
  }

  .MuiTableContainer-root {
    margin-bottom: ${(p) => p.theme.spacing(4)};
  }
`;
