import { createSvgIcon } from "@mui/material";

export const CopyLinkIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.94496 15.896L9.22216 16.6091C8.58127 17.2414 7.7289 17.5899 6.82252 17.5899C5.91613 17.5899 5.0638 17.2416 4.42287 16.6091C3.78198 15.9768 3.42879 15.1359 3.42879 14.2417C3.42879 13.3475 3.7818 12.5067 4.42287 11.8742L6.87865 9.45139C7.51971 8.81894 8.37208 8.47065 9.27847 8.47065C10.1849 8.47065 11.0372 8.81893 11.6781 9.45139C12.6272 10.3877 12.928 11.7912 12.4445 13.0271C12.3023 13.3903 12.4857 13.7985 12.8538 13.9385C13.2212 14.079 13.6354 13.898 13.7776 13.5348C14.4647 11.779 14.0373 9.78495 12.6885 8.45471C11.7778 7.55591 10.5668 7.06094 9.27868 7.06094C7.99057 7.06094 6.77952 7.55574 5.8685 8.45471L3.41272 10.8774C2.5017 11.7762 2 12.9708 2 14.2418C2 15.5126 2.50153 16.7074 3.41272 17.6062C4.3234 18.505 5.53445 19 6.82256 19C8.11067 19 9.32172 18.5052 10.2324 17.6064L10.9552 16.8933C11.2343 16.6181 11.2343 16.1717 10.9552 15.8963C10.6766 15.621 10.2241 15.621 9.945 15.8962L9.94496 15.896ZM20.5895 6.39115C18.7094 4.53637 15.65 4.5362 13.7694 6.39115L13.0466 7.10425C12.7676 7.37943 12.7676 7.82586 13.0466 8.10123C13.3252 8.37657 13.7777 8.3764 14.0568 8.10123L14.7796 7.38813C16.1031 6.08239 18.2559 6.08289 19.5793 7.38813C20.9024 8.69387 20.9024 10.8178 19.5793 12.1234L17.1237 14.5462C15.8002 15.8519 13.6474 15.8519 12.324 14.5462C11.375 13.6099 11.0741 12.2064 11.5577 10.9704C11.6999 10.6073 11.5165 10.1992 11.1484 10.0589C10.7799 9.91863 10.3669 10.0994 10.2245 10.4628C9.53746 12.2186 9.96488 14.2127 11.3136 15.5429C12.2536 16.4705 13.4885 16.9343 14.7235 16.9343C15.9582 16.9343 17.1935 16.4703 18.1336 15.5429L20.5898 13.1197C22.4701 11.2649 22.4701 8.24644 20.5896 6.39111L20.5895 6.39115Z" />
  </svg>,
  "CopyLink",
);
