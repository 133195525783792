import { Link as RouterLink, useParams } from "react-router-dom";
import {
  Box,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { Loading, LoadingFailed } from "../components/loading";
import { Space } from "~/lib/types";
import {
  AggregateScoreType,
  TestIamActionsQuery,
  useGetAggregateScoresQuery,
  useLoadCveSpaceQuery,
} from "~/operations";
import { HomeIcon } from "~/components/icons";
import { Flex, TextTitle } from "~/components/ui-library";
import {
  CveExploits,
  CveExploitsProps,
  CveRiskFactors,
  CveSources,
  EmptySection,
} from "~/components/vulnerabilities";
import { FlagOutlined, Radar } from "@mui/icons-material";
import { useInventory } from "./inventory/hooks/useInventory";
import {
  Stats,
  StatsProps,
} from "~/components/DetailsPage/components/Stats/Stats";
import { SectionHeading } from "~/components/DetailsPage/components";
import { Header } from "~/components/DetailsPage/components/Header";
import { useFindingRiskFactors } from "./space/security/components/RiskFactors/hooks/useFindingRiskFactors";
import { ScoreBlock } from "~/pages/space/security/components/Check/ScoreBlock";
import { isFeatureEnabled } from "~/login/features";
import { IamActions } from "~/lib/iam";
import { VulnerabilityAffectedAssetsAdapter } from "~/pages/space/vulnerabilities/components/AffectedAssets/VulnerabilityAffectedAssetsAdapter";
import { AdvisoriesTable } from "~/pages/space/vulnerabilities/components/Vulnerability/AdvisoriesTable";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";

export type SpaceVulnerabilityPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const SpaceVulnerabilityPage = ({
  space,
  availablePermissions,
}: SpaceVulnerabilityPageProps) => {
  const { id = "" } = useParams();
  const { spaceStats } = useInventory({ spaceMrn: space.mrn });

  const hasCreatePermissions = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATECASE,
  );

  const { data, loading, error } = useLoadCveSpaceQuery({
    variables: { id, spaceMrn: space.mrn },
  });

  const { data: aggScoreData, loading: aggScoreLoading } =
    useGetAggregateScoresQuery({
      variables: {
        entityMrn: space.mrn,
        filter: {
          findingMrn: data?.cve?.mrn,
        },
      },
      skip: !data?.cve?.mrn,
    });

  const {
    riskFactorsWithDocs,
    riskFactors,
    loading: riskFactorsLoading,
  } = useFindingRiskFactors({
    spaceMrn: space.mrn,
    findingMrn: String(data?.cve?.mrn || ""),
    scoreType: AggregateScoreType.Vulnerability,
  });

  if (loading || aggScoreLoading || riskFactorsLoading) {
    return <Loading what="Vulnerability" />;
  }

  const cve = data?.cve;
  const aggScore =
    aggScoreData?.aggregateScores?.__typename === "AggregateScoresConnection"
      ? aggScoreData.aggregateScores.edges?.at(0)?.node
      : undefined;

  // When no aggregateScore exists, we fallback to the cvssScore of the CVE.
  let fallbackCvssScore = undefined;
  if (!aggScore) {
    if (cve?.cvssScore.value) {
      // If the CVE has no cvssScore - the scoreblock will fallback further to UNKNOWN
      fallbackCvssScore = cve?.cvssScore;
    }
  }

  if (error || !cve) {
    return <LoadingFailed what="Vulnerability" />;
  }

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/vulns"
      component={RouterLink}
      to={`/space/vulnerabilities/cves?spaceId=${space.id}`}
      display="flex"
    >
      Vulnerabilities
    </Link>,
    <Typography key={"/space/vulns/cve/:cve"}>{cve.id}</Typography>,
  ];

  const totalScanned =
    spaceStats.data?.space?.stats?.riskdistribution.total || 0;
  const totalAffected = aggScore?.blastRadius?.affected || 0;

  const stats: StatsProps["stats"] = [
    {
      label: "Scanned",
      value: totalScanned < 0 ? "---" : totalScanned.toString(),
      icon: <Radar fontSize="inherit" />,
    },
    {
      label: "Affected",
      value: totalAffected < 0 ? "---" : totalAffected.toString(),
      icon: <FlagOutlined fontSize="inherit" />,
      onClick: () => {
        document
          .querySelector(`#affected-assets`)
          ?.scrollIntoView({ behavior: "smooth" });
      },
    },
    // {
    //   label: "Updated",
    //   count: -1, // TODO
    //   icon: <ZoomOutMap fontSize="inherit" />,
    // },
  ];

  const exploits: CveExploitsProps["exploits"] =
    cve.exploits?.flatMap((e) => e ?? []) || [];

  document.title = `${cve.id} · CVEs · Mondoo`;

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>

      <Header
        id="cve-header"
        title={cve.id}
        created={String(cve.publishedAt)}
        lastModified={String(cve.modifiedAt)}
        riskFactors={riskFactors}
      />

      <Box id="description">
        <SectionHeading heading="Description" />
        <Typography textTransform="uppercase" fontWeight={700} mb={1}>
          Summary
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Stats id="cve-asset-stats" stats={stats} />
                {cve.summary && (
                  <ShowMoreSummary id="cve-summary" text={cve.summary} />
                )}
              </Grid>
              <Grid item xs={12}>
                <CveSources
                  id="cve-sources"
                  url={cve.url}
                  source={cve.source}
                  references={cve.references}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <ScoreBlock
              hasScore={Boolean(aggScore)}
              mainScore={aggScore?.riskScore}
              cvssScore={aggScore?.cvss || fallbackCvssScore}
              epssScore={aggScore?.epss}
              blastRadius={aggScore?.blastRadius}
              riskFactors={aggScore?.riskFactors}
              hasError={
                Boolean(aggScore) && aggScore?.__typename !== "AggregateScore"
              }
            />
          </Grid>
        </Grid>
      </Box>

      <Box id="risk-factors" className="section">
        <SectionHeading heading="Risk Assessment" />
        <Box className="section-content">
          <CveRiskFactors
            id="cve-risk-factors"
            cvssScore={cve.cvssScore}
            epssScore={cve.epssScore}
            riskFactors={riskFactorsWithDocs}
          />
        </Box>
      </Box>

      {isFeatureEnabled("Known Exploits") && (
        <Box id="exploits" className="section">
          <Flex center gap={3} my={4} className="section-header">
            <TextTitle bold sx={{ whiteSpace: "nowrap", flexShrink: 0 }}>
              Known Exploits
            </TextTitle>
            <Box width={1}>
              <Divider />
            </Box>
          </Flex>
          <Box className="section-content">
            {exploits.length > 0 ? (
              <CveExploits id="cve-exploits" exploits={exploits} />
            ) : (
              <EmptySection
                id="cve-exploits-empty"
                text="There are currently no known exploits for this CVE"
              />
            )}
          </Box>
        </Box>
      )}

      {/* TODO: Need API support */}
      {/* <Box id="remediation" className="section">
        <SectionHeading heading="Remediation" />
        <Box className="section-content">
          <CveRemediation />
        </Box>
      </Box> */}

      <Box id="cve-advisories" className="section">
        <SectionHeading heading="Related Advisories" />
        <Box className="section-content">
          <AdvisoriesTable space={space} />
        </Box>
      </Box>

      <VulnerabilityAffectedAssetsAdapter
        space={space}
        vulnerabilityId={id}
        vulnerabilityScoreType="CVE"
      />
    </Box>
  );
};
