import {
  DialogContent,
  Box,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import {
  DialogContainer,
  GradientButton,
} from "~/components/guides/components";
import mondooWaveCat from "./media/mondoo-wave-cat.png";

type Props = {
  nav: {
    next: () => void;
    prev: () => void;
  };
};

export const Intro = ({ nav }: Props) => {
  return (
    <DialogContainer>
      <DialogContent sx={{ p: 0 }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            pb: {
              xs: 7,
              sm: 6,
              md: 5,
            },
          }}
        >
          <Box
            component="img"
            src={mondooWaveCat}
            sx={{ height: "auto", width: 204 }}
          />
        </Box>
        <DialogContentText
          variant="h4"
          align="center"
          sx={{
            fontSize: {
              xs: 24,
              sm: 32,
              md: 40,
            },
            fontWeight: 900,
            textTransform: "uppercase",
            background: (theme) =>
              `-webkit-linear-gradient(${theme.palette.primary.light}, ${theme.palette.secondary.light})`,
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Hi! I'll be your guide to <br />
          setup Mondoo.
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ p: 0, pt: 6, justifyContent: "center" }}>
        <GradientButton onClick={nav.next}>Let's do this!</GradientButton>
      </DialogActions>
    </DialogContainer>
  );
};
