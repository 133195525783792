import { createSvgIcon } from "@mui/material";

export const ProviderCircleCIIcon = createSvgIcon(
  <svg viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="128" height="128" rx="4" fill="white" />
    <path
      d="M51.6994 63.5C51.6994 57.1378 56.8786 51.9743 63.2601 51.9743C69.6416 51.9743 74.8208 57.1378 74.8208 63.5C74.8208 69.8622 69.6416 75.0257 63.2601 75.0257C56.8786 75.1179 51.6994 69.8622 51.6994 63.5ZM63.2601 15C40.5087 15 21.4566 30.4905 16.0925 51.5133C16.0925 51.6977 16 51.7899 16 51.9743C16 53.2652 17.0173 54.2795 18.3121 54.2795H37.9191C38.8439 54.2795 39.6763 53.7262 40.0462 52.8964C44.1156 44.1369 52.9017 38.0513 63.2601 38.0513C77.3179 38.0513 88.7861 49.4848 88.7861 63.5C88.7861 77.5152 77.3179 88.9487 63.2601 88.9487C52.9942 88.9487 44.1156 82.8631 40.0462 74.1036C39.6763 73.2738 38.8439 72.7205 37.9191 72.7205H18.3121C17.0173 72.7205 16 73.7348 16 75.0257C16 75.2101 16 75.3023 16.0925 75.4867C21.4566 96.5095 40.5087 112 63.2601 112C90.1734 112 112 90.2395 112 63.4078C112 36.6683 90.1734 15 63.2601 15Z"
      fill="#343434"
    />
  </svg>,
  "CircleCI",
);
