import { createSvgIcon } from "@mui/material";

export const NavDrawerIntegrationsIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M16.36 13.2399H11.44V8.31988C11.44 7.87167 11.2619 7.44181 10.945 7.12487C10.6281 6.80794 10.1982 6.62988 9.74999 6.62988H4.86999C4.42178 6.62988 3.99192 6.80794 3.67498 7.12487C3.35805 7.44181 3.17999 7.87167 3.17999 8.31988V19.8099C3.17999 20.2581 3.35805 20.688 3.67498 21.0049C3.99192 21.3218 4.42178 21.4999 4.86999 21.4999H16.36C16.8082 21.4999 17.2381 21.3218 17.555 21.0049C17.8719 20.688 18.05 20.2581 18.05 19.8099V14.9299C18.05 14.4817 17.8719 14.0518 17.555 13.7349C17.2381 13.4179 16.8082 13.2399 16.36 13.2399ZM4.86999 7.99988H9.74999C9.83486 7.99988 9.91625 8.0336 9.97627 8.09361C10.0363 8.15362 10.07 8.23501 10.07 8.31988V13.2399H4.86999C4.76355 13.23 4.65643 13.23 4.54999 13.2399V8.31988C4.54999 8.23501 4.58371 8.15362 4.64372 8.09361C4.70373 8.0336 4.78512 7.99988 4.86999 7.99988ZM4.86999 20.1299C4.78512 20.1299 4.70373 20.0962 4.64372 20.0362C4.58371 19.9761 4.54999 19.8948 4.54999 19.8099V14.9299C4.54999 14.845 4.58371 14.7636 4.64372 14.7036C4.70373 14.6436 4.78512 14.6099 4.86999 14.6099H10.07V19.8099C10.07 19.8519 10.0617 19.8935 10.0456 19.9323C10.0296 19.9712 10.006 20.0064 9.97627 20.0362C9.94655 20.0659 9.91127 20.0894 9.87245 20.1055C9.83363 20.1216 9.79202 20.1299 9.74999 20.1299H4.86999ZM16.68 19.8099C16.68 19.8948 16.6463 19.9761 16.5863 20.0362C16.5263 20.0962 16.4449 20.1299 16.36 20.1299H11.41C11.4203 20.0235 11.4203 19.9163 11.41 19.8099V14.6099H16.33C16.4149 14.6099 16.4963 14.6436 16.5563 14.7036C16.6163 14.7636 16.65 14.845 16.65 14.9299L16.68 19.8099Z" />
    <path d="M18.05 2.5C17.2331 2.5 16.4347 2.74222 15.7555 3.19603C15.0763 3.64984 14.547 4.29486 14.2344 5.04952C13.9218 5.80418 13.84 6.63458 13.9993 7.43572C14.1587 8.23686 14.552 8.97276 15.1296 9.55035C15.7072 10.1279 16.4431 10.5213 17.2443 10.6806C18.0454 10.84 18.8758 10.7582 19.6305 10.4456C20.3851 10.133 21.0301 9.60368 21.484 8.92451C21.9378 8.24533 22.18 7.44684 22.18 6.63C22.18 5.53466 21.7449 4.48417 20.9703 3.70965C20.1958 2.93512 19.1453 2.5 18.05 2.5ZM18.05 9.39C17.5041 9.39 16.9705 9.22813 16.5166 8.92486C16.0627 8.62158 15.709 8.19053 15.5001 7.68621C15.2912 7.18188 15.2365 6.62694 15.343 6.09155C15.4495 5.55616 15.7124 5.06438 16.0984 4.67839C16.4844 4.29239 16.9761 4.02953 17.5115 3.92303C18.0469 3.81654 18.6019 3.87119 19.1062 4.08009C19.6105 4.28899 20.0416 4.64275 20.3448 5.09663C20.6481 5.55051 20.81 6.08412 20.81 6.63C20.8073 7.36119 20.5157 8.06167 19.9987 8.5787C19.4817 9.09573 18.7812 9.38737 18.05 9.39Z" />
  </svg>,
  "Integrations",
);
