import { TableCell, TableRow } from "@mui/material";

type HalfDataTableRowProps = {
  title: string;
  value: React.ReactNode;
};

export function HalfDataTableRow({ title, value }: HalfDataTableRowProps) {
  return (
    <TableRow>
      <TableCell
        width="50%"
        sx={{ py: 1, pl: 0, color: "text.secondary", verticalAlign: "top" }}
      >
        {title}
      </TableCell>
      <TableCell
        sx={{
          py: 1,
          pl: 0,
        }}
      >
        {value}
      </TableCell>
    </TableRow>
  );
}
