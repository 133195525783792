import { useContext } from "react";
import { AuthContext } from "./auth-context";
import { FirebaseAuthProvider } from "./firebase-provider";
import { DevelopmentAuthProvider } from "./development-provider";
import { MondooOidcProvider } from "./oidc-provider";
import { Config } from "~/configuration_provider";

function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

let AuthProvider = DevelopmentAuthProvider;

switch (Config.VITE_AUTH_PROVIDER) {
  case "dev":
    AuthProvider = DevelopmentAuthProvider;
    break;
  case "oidc":
    AuthProvider = MondooOidcProvider;
    break;
  case "firebase":
  default:
    AuthProvider = FirebaseAuthProvider;
    break;
}

export { AuthProvider, useAuth };
