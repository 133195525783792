import { Fragment, useEffect, useState } from "react";
import { Alert, Box, Container, Link, useTheme } from "@mui/material";
import makeCancellable from "make-cancellable-promise";
import { AnimatePresence, motion } from "framer-motion";

type IncidentState = {
  msg: string;
  open: boolean;
  indicator: "none" | "maintenance" | "minor" | "major" | "critical";
  fetched: boolean;
};

type StatuspageResponse = {
  page: {
    id: string;
    name: string;
    time_zone: string;
    updated_at: string;
    url: string;
  };
  status: {
    description: string;
    indicator: "none" | "maintenance" | "minor" | "major" | "critical";
  };
};

const incidentInfoInitialState: IncidentState = {
  msg: "",
  indicator: "none",
  open: false,
  fetched: false,
};

export function IncidentInfo() {
  const [incident, setIncident] = useState(incidentInfoInitialState);

  useEffect(() => {
    const { promise, cancel } = makeCancellable(fetchIncidents());
    promise
      .then((data: StatuspageResponse) => {
        const { status } = data;

        const hasIncident = status.indicator !== "none";
        const msg = formatIncidentMessage(status.indicator);

        setIncident((prevState) => ({
          ...prevState,
          open: hasIncident,
          indicator: status.indicator,
          msg,
          fetched: true,
        }));
      })
      .catch((error) => console.log(error));

    return () => {
      cancel();
    };
  }, []);

  const fetchIncidents = async (): Promise<StatuspageResponse> => {
    const response = await fetch(
      "https://mondoo.statuspage.io/api/v2/status.json",
    );
    const data = await response.json();
    return data;
  };

  const closeIncident = (): void => {
    setIncident((prevState) => {
      return {
        ...prevState,
        open: false,
      };
    });
  };

  const formatIncidentMessage = (
    indicator: StatuspageResponse["status"]["indicator"],
  ): string => {
    switch (indicator.toLowerCase()) {
      case "maintenance":
        return "Mondoo is currently undergoing maintenance";
      default:
        return "There is an ongoing incident on Mondoo";
    }
  };

  return (
    <AnimatePresence mode="wait">
      {incident.open ? (
        <AlertNotification
          key="incident"
          incident={incident}
          close={closeIncident}
        />
      ) : (
        <Fragment />
      )}
    </AnimatePresence>
  );
}

type Props = {
  incident: IncidentState;
  close: () => void;
};

const AlertNotification = ({ incident, close }: Props) => {
  const theme = useTheme();

  const getSeverity = () => {
    switch (incident.indicator) {
      case "maintenance":
        return "info";
      default:
        return "warning";
    }
  };

  const getAlertColors = () => {
    switch (incident.indicator) {
      case "maintenance":
        return {
          borderTop: "solid",
          backgroundColor: "#19105C", // <--secondary/darker
          borderColor: "secondary.main",
          borderWidth: theme.spacing(0.5),
          ".MuiPaper-root": {
            backgroundColor: "#19105C",
          },
          ".MuiAlert-message": {
            color: "secondary.light",
            fontSize: theme.typography.body1,
            fontWeight: 500,
          },
          ".MuiAlert-icon": {
            color: "secondary.light",
            pt: "9px",
          },
          ".MuiAlert-action": {
            color: "secondary.light",
          },
          ".MuiLink-root": {
            color: "secondary.light",
          },
        };
      default:
        return {
          borderTop: "solid",
          backgroundColor: "warning.dark",
          borderColor: "warning.main",
          borderWidth: theme.spacing(0.5),
          ".MuiAlert-message": {
            color: "warning.light",
            fontSize: theme.typography.body1,
            fontWeight: 500,
          },
          ".MuiAlert-icon": {
            color: "warning.light",
            pt: "9px",
          },
          ".MuiAlert-action": {
            color: "warning.light",
          },
          ".MuiLink-root": {
            color: "warning.light",
          },
        };
    }
  };

  const variants = {
    closed: {
      opacity: 0,
      height: 0,
      transition: {
        ease: "easeIn",
        duration: 0.225,
      },
    },
    open: {
      opacity: 1,
      height: "auto",
      transition: {
        ease: "easeOut",
        duration: 0.325,
      },
    },
  };

  return (
    <Box
      component={motion.div}
      variants={variants}
      initial="closed"
      animate="open"
      exit="closed"
      sx={getAlertColors}
    >
      <Container>
        <Alert
          id="incident-info"
          variant="filled"
          severity={getSeverity()}
          onClose={close}
          sx={{
            borderRadius: 0,
          }}
        >
          <Link target="_blank" href={"https://status.mondoo.com/"}>
            {incident.msg}
          </Link>
        </Alert>
      </Container>
    </Box>
  );
};
