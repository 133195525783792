import { Typography } from "@mui/material";
import { NavDrawerPolicyIcon } from "~/components/icons";
import { Space } from "~/lib/types";

export function renderAssetEmptyStateContent({
  target,
  space,
}: {
  target: "checks" | "policies";
  space: Space | undefined;
}) {
  return {
    icon: <NavDrawerPolicyIcon sx={{ fontSize: 38, color: "primary.main" }} />,
    headline: `No ${target} to show!`,
    textContent: (
      <Typography color="text.secondary" sx={{ textAlign: "center" }}>
        Check out the available policies to find one that matches your security
        goals and your infrastructure
      </Typography>
    ),
    actionButtons: space && [
      {
        text: "Add Policies",
        to: `/space/security/policies/add?spaceId=${space.id}`,
      },
    ],
  };
}
