import {
  Dispatch,
  FormEvent,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import { styled, css, keyframes } from "@mui/material";
import { MondooLoadingBar } from "../mondooLoadingBar";

type AnimatedTypingProps = {
  typingSpeed: number;
} & WaitProps;

export function AnimatedTyping({
  prepend,
  link,
  type,
  color,
  formDetails,
  typingSpeed,
  text = "",
  delay = 0,
}: AnimatedTypingProps) {
  const [line, setLine] = useState<string>("");
  const [percentage, setPercentage] = useState<number>(0);
  const [showCursor, setShowCursor] = useState<boolean>(false);

  useEffect(() => {
    setShowCursor(type === "command");
    start();
  }, []);

  const start = async () => {
    await wait(delay);
    if (type === "progress") {
      const barProgress = Array.from(Array(20).keys());
      for (let _bar of barProgress) {
        await wait(typingSpeed);
        setPercentage((prev) => prev + 5);
      }
    } else if (type === "output" || type === "input") {
      setLine(text);
    } else {
      const splitLine = text.split("");
      for (let char of splitLine) {
        await wait(typingSpeed);
        setLine((prev) => prev + char);
      }
    }
    setShowCursor(false);
  };

  if (type === "progress") {
    return (
      <StyledProgressContainer>
        <MondooLoadingBar percentage={percentage} />
        <span className="percentage-tag">{percentage}%</span>
      </StyledProgressContainer>
    );
  }

  if (type === "input") {
    return (
      <form
        onSubmit={(e) => {
          formDetails?.onSubmit(e);
        }}
      >
        <StyledLabel htmlFor={formDetails?.id} prepend={prepend}></StyledLabel>
        <StyledInput
          id={formDetails?.id}
          name={formDetails?.id}
          type="password"
          autoFocus
        />
      </form>
    );
  }

  return (
    <AnimatedText
      prepend={prepend}
      showCursor={showCursor}
      type={type}
      color={color}
    >
      <Fragment>
        {line}{" "}
        {link && (
          <Link style={{ textDecoration: "underline" }} to={link.to}>
            {link.text}
          </Link>
        )}
      </Fragment>
    </AnimatedText>
  );
}

///////////////////////////////////////////
///// Function for utilizing Animated Typing

export type HandleSubmit = ({
  id,
  event,
}: {
  id: string;
  event: FormEvent<HTMLFormElement>;
}) => Promise<void>;

export type WaitProps = {
  id: string;
  type: string;
  text: string;
  delay?: number;
  prepend?: string;
  color?: string;
  link?: {
    text: string;
    to: string;
  };
  formDetails?: {
    onSubmit: (e: FormEvent<HTMLFormElement>) => ReturnType<HandleSubmit>;
    id: string;
  };
};

const NEXT_LINE_DELAY = 500;

export const waitForTyping = async (
  setter: Dispatch<SetStateAction<ReactNode[]>>,
  options: WaitProps,
) => {
  const typingSpeed = getTypingSpeed(options.type);
  const delay = options.delay || 0;

  setter((prev) => [
    ...prev,
    <AnimatedTyping key={options.id} typingSpeed={typingSpeed} {...options} />,
  ]);

  if (options.type === "progress" || options.type === "output") {
    await wait(1000 + delay + NEXT_LINE_DELAY);
  } else {
    await wait(options.text.length * typingSpeed + delay + NEXT_LINE_DELAY);
  }

  return Promise.resolve();
};

// Helper function that will wait for a given amount of milliseconds
export const wait = (delay: number) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

// Helper function to determine the typingspeed that should render
// dependent on whether or not the CLI should be "typing", or rendering
// immediate output, or displaying a loading bar.
export const getTypingSpeed = (type: string) => {
  if (type === "output") {
    return 0;
  } else if (type === "progress") {
    return 50;
  } else {
    return 40;
  }
};

const Blink = keyframes`
  50% { opacity: 0 }
`;

const showBlinker = css`
  &:after {
    content: "▋";
    color: hotpink;
    animation: ${Blink} 0.5s infinite;
  }
`;

const AnimatedText = styled("span", {
  shouldForwardProp: (prop: string) =>
    !["showCursor", "prepend", "type", "color"].includes(prop),
})<{
  showCursor: boolean;
  prepend?: string;
  type?: string;
  color?: string;
}>`
  display: block;
  margin-bottom: 4px;
  color: ${(p) =>
    p.type === "output" ? (p.color ? p.color : "mediumspringgreen") : ""};

  &:before {
    content: "${(p) => (p.prepend ? `${p.prepend} ` : "")}";
  }
  ${({ showCursor }) => (showCursor ? showBlinker : "")};
`;

const StyledLabel = styled("label", {
  shouldForwardProp: (prop: string) => prop !== "prepend",
})<{ prepend?: string }>`
  &:before {
    content: "${(p) => (p.prepend ? `${p.prepend} ` : "")}";
  }
`;

const StyledInput = styled("input")`
  appearance: none;
  background: inherit;
  outline: none;
  border: none;
  width: 80%;
  color: mediumspringgreen;
  font-size: 16px;
  letter-spacing: 4px;
  caret-color: hotpink;
`;

const StyledProgressContainer = styled("div")`
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .percentage-tag {
    margin-left: ${(p) => p.theme.spacing(1)}px;
  }
`;
