import { useState } from "react";
import { Box, Typography, Theme, styled, TextFieldProps } from "@mui/material";
import {
  ControllerRenderProps,
  DeepMap,
  DeepPartial,
  FieldError,
  UseFormRegister,
} from "react-hook-form";
import { AuthFormInput, Complexity } from "./auth-component";
import { AuthTextField } from "./auth-text-field";

type Props = {
  register: UseFormRegister<AuthFormInput>;
  field: ControllerRenderProps<AuthFormInput, "password">;
  errors: DeepMap<DeepPartial<AuthFormInput>, FieldError>;
  complexity: Complexity;
  getComplexityScore: (password: string) => void;
  placeholder: string;
};

export function PasswordComplexityInput({
  field,
  errors,
  register,
  getComplexityScore,
  complexity,
  placeholder,
}: Props) {
  const [displayBar, setDisplayBar] = useState<boolean>(false);

  const handleChange: TextFieldProps["onChange"] = (e) => {
    const shouldShowBar = e.target.value.length > 0;
    setDisplayBar(shouldShowBar);
    getComplexityScore(e.target.value);
  };

  const getComplexityLabel = (): string => {
    if (!displayBar) return "";
    switch (complexity.score) {
      case 0:
        return "very weak";
      case 1:
        return "weak";
      case 2:
        return "medium";
      case 3:
        return "strong";
      case 4:
        return "very-strong";
    }
    return "";
  };

  // Returns the label provided by zxcvbn for
  // estimated time to crack using offline slow hashing
  const getCrackLabel = (): string => {
    if (!displayBar) return "";
    return `time to crack: ${complexity.crackTime}`;
  };

  return (
    <Box position="relative" mb={"12px"}>
      <AuthTextField
        {...field}
        type="password"
        variant="outlined"
        autoComplete="off"
        fullWidth
        {...register("password", {
          required: true,
          minLength: 8,
          maxLength: 100,
          onChange: handleChange,
          validate: {
            isComplex: () => complexity.isComplex,
          },
        })}
        placeholder={placeholder}
        error={Boolean(errors.password)}
        helperText={
          errors.password?.type === "required" && "Password is required"
        }
      />
      <Strength displayBar={displayBar} complexityscore={complexity.score}>
        <StyledTypography>{getComplexityLabel()}</StyledTypography>
        <StyledTypography>{getCrackLabel()}</StyledTypography>
      </Strength>
    </Box>
  );
}

const Strength = styled("div", {
  shouldForwardProp: (prop: string) =>
    !["displayBar", "complexityScore"].includes(prop),
})<{
  displayBar: boolean;
  complexityscore: number | undefined;
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 12px;
  padding: 2px 8px;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;

  &&::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(p) => calculateColor(p.complexityscore, p.theme)};
    transform-origin: left;
    transform: scale3d(
      ${(p) => calculateScorePercent(p.complexityscore, p.displayBar)},
      1,
      1
    );
    transition:
      transform 350ms,
      background 250ms;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 12px;
  color: black;
  mix-blend-mode: overlay;
`;

// Calculates a score of 0 to 1 in .2 increments based
// off the password score from zxcvbn
const calculateScorePercent = (
  score: number | undefined,
  displayBar: boolean,
): number => {
  if (!displayBar) return 0;
  switch (score) {
    case 0:
      return 0.2;
    case 1:
      return 0.4;
    case 2:
      return 0.6;
    case 3:
      return 0.8;
    case 4:
      return 1;
  }
  return 0;
};

// Calculates the color of the password strength bar based
// off the password score from zxcvbn
const calculateColor = (score: number | undefined, theme: Theme): string => {
  switch (score) {
    case 1:
      return theme.palette.high.gradient;
    case 2:
      return theme.palette.warning.gradient;
    case 3:
      return theme.palette.none.gradient;
    case 4:
      return theme.palette.low.gradient;
  }

  return theme.palette.critical.gradient;
};
