import { Box, Grid, Typography } from "@mui/material";
import { Space } from "~/lib/types";
import { AggregateScoreType } from "~/operations";
import { IntegrationsOverview } from "./IntegrationsOverview";
import { InventoryOverviewCardSunburst } from "./InventoryOverviewCard";
import { FirewatchDashboardCard } from "~/components/FirewatchDashboardCard";
import { ShowDetails } from "~/components/ShowDetails/ShowDetails";

type OverviewProps = {
  space: Space;
};

export function Overview({ space }: OverviewProps) {
  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", textTransform: "uppercase" }}
        >
          {space.name}
        </Typography>
        {space.description && (
          <Box sx={{ color: "text.secondary" }}>
            <ShowDetails children={space.description} />
          </Box>
        )}
      </Box>
      <Grid container spacing={3}>
        <Grid container item spacing={3} xs={12}>
          <Grid item xs={12} md={6}>
            <InventoryOverviewCardSunburst space={space} />
          </Grid>
          <Grid container item spacing={3} xs={12} md={6}>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                space={space}
                pageType={AggregateScoreType.Vulnerability}
              />
            </Grid>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                space={space}
                pageType={AggregateScoreType.Check}
              />
            </Grid>
          </Grid>
        </Grid>
        <IntegrationsOverview {...{ space }} />
      </Grid>
    </Box>
  );
}
