import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { MttrPerScoreCard } from "~/pages/dashboard/vulnerabilities/mttr-per-score-card";
import { RemediatedCard } from "~/pages/dashboard/vulnerabilities/remediated-card";
import { Space } from "~/lib/types";
import { VulnerableAssetsCardSunburst } from ".";
import { Banner } from "~/components/Banner";
import { usePlatformVulnerabilityConfiguration } from "~/hooks";
import React, { useState } from "react";
import { useViewer } from "~/providers/viewer";
import { AggregateScoreType, SpaceSettingsInput } from "~/operations";
import {
  AdvisoriesDonutChart,
  CvesDonutChart,
  VulnerabilitiesDonutChart,
} from "./components";
import { FirewatchDashboardCard } from "~/components/FirewatchDashboardCard";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";

type DashboardProps = {
  space: Space;
};

export function Dashboard({ space }: DashboardProps) {
  const { updateSpace } = useViewer();
  const { pvcIsEnabled } = usePlatformVulnerabilityConfiguration({ space });
  const [vulnConfigUpdating, setVulnConfigUpdating] = useState(false);

  const settings = space.settings;
  const garbageCollectAssetsConfiguration = {
    enable: settings?.garbageCollectAssetsConfiguration.enable,
    afterDays: settings?.garbageCollectAssetsConfiguration.afterDays,
  };
  const terminatedAssetsConfiguration = {
    cleanup: settings?.terminatedAssetsConfiguration.cleanup,
  };
  const unusedServiceAccountsConfiguration = {
    cleanup: settings?.unusedServiceAccountsConfiguration.cleanup,
  };
  const platformVulnerabilityConfiguration = {
    enable: settings?.platformVulnerabilityConfiguration.enable,
  };

  const settingsInput: SpaceSettingsInput = {
    garbageCollectAssetsConfiguration,
    terminatedAssetsConfiguration,
    unusedServiceAccountsConfiguration,
    platformVulnerabilityConfiguration,
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Typography key="/space/vulnerabilities">Dashboard</Typography>,
  ];

  const handleEnableVulnerabilityScanning = async () => {
    const settings = {
      ...settingsInput,
      platformVulnerabilityConfiguration: { enable: true },
    };

    setVulnConfigUpdating(true);

    try {
      await updateSpace({
        variables: {
          input: {
            name: space.name,
            mrn: space.mrn,
            settings,
          },
        },
        awaitRefetchQueries: true,
        onCompleted: () => {
          setVulnConfigUpdating(false);
        },
      });
    } catch (error) {
      setVulnConfigUpdating(false);
    }
  };

  return (
    <>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Grid container spacing={3}>
        <Banner
          type="info"
          showBanner={pvcIsEnabled === false}
          title="Get the most out of Mondoo by turning on vulnerability scanning!"
          message="Your dashboard will be filled with a handy overview of the health of your entire fleet as you scale up your business."
          buttonText="ENABLE VULNERABILITY SCANNING"
          buttonLoading={vulnConfigUpdating}
          onClick={handleEnableVulnerabilityScanning}
        />
        <Grid item mb={2}>
          <Typography variant="h4" component="h1" sx={{ fontWeight: 700 }}>
            OVERVIEW
          </Typography>
        </Grid>
        <Grid container item spacing={3} xs={12} sx={{ height: 1 }}>
          <Grid item xs={12} lg={6}>
            <VulnerableAssetsCardSunburst space={space} />
          </Grid>
          <Grid container item spacing={3} xs={12} lg={6}>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                space={space}
                pageType={AggregateScoreType.Vulnerability}
              />
            </Grid>
            <Grid item xs={12}>
              <FirewatchDashboardCard
                space={space}
                pageType={"NewestRelevantVulnerabilities"}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={6} lg={4} order={{ xs: 1 }}>
            <VulnerabilitiesDonutChart {...{ space }} />
          </Grid>
          <Grid item xs={12} sm={6} lg={4} order={{ xs: 2 }}>
            <AdvisoriesDonutChart {...{ space }} />
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 3, md: 4, lg: 3 }}>
            <CvesDonutChart {...{ space }} />
          </Grid>
          <Grid item xs={12} md={6} lg={4} order={{ xs: 4, md: 3, lg: 4 }}>
            <MttrPerScoreCard space={space} />
          </Grid>
          <Grid item xs={12} lg={8} order={{ xs: 5 }}>
            <RemediatedCard space={space} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
