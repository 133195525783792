import { alpha, Typography } from "@mui/material";
import { ShowMoreSummary } from "~/components/ShowMore/ShowMoreSummary";

type SoftwareDescriptionProps = {
  description: string | null | undefined;
};

export const SoftwareSummary = ({ description }: SoftwareDescriptionProps) => {
  return (
    <>
      {description ? (
        <ShowMoreSummary
          id="software-summary"
          text={description}
          maxHeight={80}
        />
      ) : (
        <Typography
          sx={{
            fontWeight: 700,
            color: (theme) => alpha(theme.palette.text.primary, 0.33),
          }}
        >
          No summary has been provided for this software yet.
        </Typography>
      )}
    </>
  );
};
