import { Link as RouterLink } from "react-router-dom";
import { Button, Grid, Paper, Typography } from "@mui/material";
import Framework from "./images/framework.svg";
import FrameworkLight from "./images/framework-light.png";
import Exceptions from "./images/exceptions.svg";
import ExceptionsLight from "./images/exceptions-light.png";
import Download from "./images/download.svg";
import DownloadLight from "./images/download-light.png";
import { Space } from "~/lib/types";
import { useColorMode } from "~/providers/color-mode";

export function NoReports({ space }: { space: Space }) {
  const { mode } = useColorMode();
  const sections = [
    {
      id: "framework",
      text: "Select your framework",
      image: mode === "light" ? FrameworkLight : Framework,
    },
    {
      id: "exceptions",
      text: "Set exceptions and resolve findings",
      image: mode === "light" ? ExceptionsLight : Exceptions,
    },
    {
      id: "download",
      text: "Download your report",
      image: mode === "light" ? DownloadLight : Download,
    },
  ];

  return (
    <Grid
      component={Paper}
      container
      item
      rowGap={6}
      sx={{
        p: 4,
      }}
    >
      <Grid item xs={12}>
        <Typography variant="h5" fontWeight={700} textAlign="center">
          No reports yet! Follow these steps to get what you need to ace your
          audit!
        </Typography>
      </Grid>
      <Grid container columnSpacing={3} rowSpacing={5} item>
        {sections.map((section) => (
          <Grid
            item
            xs={12}
            md={4}
            key={section.id}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={section.image}
              alt={section.text}
              style={{ maxHeight: "144px" }}
            />
            <Typography
              fontWeight={600}
              color="text.secondary"
              textAlign="center"
            >
              {section.text}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Button
          component={RouterLink}
          to={`/space/compliance?spaceId=${space.id}`}
          variant="contained"
          color="primary"
          sx={{
            background: "linear-gradient(134deg, #9147FF 0%, #4C35E8 100%)",
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              opacity: 0.7,
            },
          }}
        >
          View Frameworks
        </Button>
      </Grid>
    </Grid>
  );
}
