import { useTheme } from "@mui/material";
import { RiskFactorsTitle } from "./risk-factors-title";

export function useRiskFactorsContentStatus() {
  const theme = useTheme();
  const positiveRiskFactors = ["defensive"]; // positive risk factors should show up green

  const getRiskStatus = (
    affected: number = 0,
    total: number = 0,
    indicator: string = "",
  ): { status: "active" | "inactive"; color: string } => {
    // if the risk factor is 0/Total, it should be green
    if (affected === 0) {
      return { status: "inactive", color: theme.palette.success.main };
    }
    // if the risk factor is positive, it should be green
    if (positiveRiskFactors.includes(indicator)) {
      // if positive, and affected is less than total, it should be red (reverse from a negative risk factor)
      if (affected < total) {
        return { status: "active", color: theme.palette.critical.main };
      }
      return { status: "inactive", color: theme.palette.success.main };
    }

    return { status: "active", color: theme.palette.critical.main };
  };

  const createRiskFactorSectionTitle = (
    riskFactors: RiskFactorsTitle["riskFactors"],
  ) => {
    return (
      riskFactors
        ?.map((rf) => `${rf?.affected}/${rf?.total} ${rf?.title}`)
        .join(", ") || ""
    );
  };

  return {
    getRiskStatus,
    createRiskFactorSectionTitle,
  };
}
