import { AffectedAssetsTable } from "~/pages/space/vulnerabilities/components/AffectedAssets/AffectedAssetsTable";
import { Space } from "~/lib/types";
import { SelectionProps } from "~/pages/space/vulnerabilities/components/AffectedAssets/types";
import { Grid } from "@mui/material";
import { EmptySection } from "~/components/vulnerabilities";
import { Loading, LoadingFailed } from "~/components/loading";
import {
  AggregateScoreOrder,
  AggregateScoreOrderField,
  CheckScoreOrder,
  CheckScoreOrderField,
  OrderDirection,
  ScoreStateFilter,
  useGetCheckScoresQuery,
} from "~/operations";
import { useSort } from "~/pages/inventory/hooks/useSort";
import { useSearch } from "~/components/search/useSearch";
import { usePolicyOutletContext } from "~/components/policy-gql/policy-gql";

type SecurityPolicyAffectedAssetsAdaptorProps = {
  space: Space;
  selectionProps?: SelectionProps | undefined;
};

export const SecurityPolicyAffectedAssetsAdaptor = ({
  space,
  selectionProps,
}: SecurityPolicyAffectedAssetsAdaptorProps) => {
  const { searchFilters } = useSearch();
  const { policy } = usePolicyOutletContext();
  const { handleSortClick, orderBy } = useSort<CheckScoreOrder>({
    defaultSort: {
      field: CheckScoreOrderField.Score,
      direction: OrderDirection.Asc,
    },
    validFields: [CheckScoreOrderField.Score, CheckScoreOrderField.AssetName],
  });

  const {
    data: checkData,
    error,
    loading,
    fetchMore,
  } = useGetCheckScoresQuery({
    variables: {
      entityMrn: space.mrn,
      first: 10,
      after: null,
      orderBy,
      filter: {
        queryTerms: searchFilters,
        policyMrn: policy.mrn,
        state: ScoreStateFilter.Open,
      },
    },
  });

  if (error) {
    return <LoadingFailed what="Affected Assets" />;
  }

  if (loading) {
    return <Loading what="Affected Assets" />;
  }

  const checkScores =
    checkData?.checkScores?.__typename === "CheckScoresConnection"
      ? checkData.checkScores
      : undefined;

  const { edges, pageInfo, filteredTotalCount } = checkScores || {};

  const assets = edges?.flatMap(({ node }) =>
    node?.asset
      ? {
          id: node.asset.id,
          mrn: node.asset.mrn,
          score: node.riskScore || 0,
          lastUpdated: node.lastUpdated ?? "",
          riskFactors: node.riskFactors,
          title: node.asset.name,
          iconId: node.iconId,
          tags: node.tags,
        }
      : [],
  );

  return (
    <Grid id="affected-assets" item xs={12}>
      {(assets?.length || 0) > 0 && !error ? (
        <AffectedAssetsTable
          assets={assets}
          orderBy={orderBy}
          handleSortClick={handleSortClick}
          space={space}
          filteredTotalCount={filteredTotalCount || 0}
          pageInfo={pageInfo}
          fetchMore={fetchMore}
          selectionProps={selectionProps}
          urlContextType={"ASSET"}
          contextId={""}
        />
      ) : (
        <EmptySection
          id="affected-assets-list-empty"
          text={`There are currently no affected assets for this policy`}
        />
      )}
    </Grid>
  );
};
