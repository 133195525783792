import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { ApolloError } from "@apollo/client";
import { useViewer } from "~/providers/viewer";
import { useNavigate } from "react-router-dom";
import {
  useCreateSpaceMutation,
  useSpaceIdSuggestionLazyQuery,
} from "~/operations";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { Grid2 as Grid } from "@mui/material";
import { LoadingButton } from "~/components/loading-button";
import { SubmitHandler, useForm } from "react-hook-form";
import { IdField } from "~/pages/space/Workspaces/components/FormFields/IdField";
import { CreateFormInput } from "../FormFields/types";
import { DisplayNameField } from "~/pages/space/Workspaces/components/FormFields/DisplayNameField";
import { DescriptionField } from "~/pages/space/Workspaces/components/FormFields/DescriptionField";

export type CreateSpaceProps = {};

const SPACE_ID_UNAVAILABLE = "This space id is not available";

export function CreateSpace({}: CreateSpaceProps) {
  const { viewer, refetch, currentOrg } = useViewer();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const hasCreateSpacePermissions = true;

  const [
    getSpaceIdSuggestion,
    { data: spaceIdSuggestion, refetch: refetchSpaceIdSuggestion },
  ] = useSpaceIdSuggestionLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [createSpace, { loading: isCreateSpaceLoading }] =
    useCreateSpaceMutation();

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setError,
    formState: { errors, isValid },
  } = useForm<CreateFormInput>({
    mode: "onChange",
    defaultValues: {
      name: "",
      description: "",
      id: "",
    },
  });

  useEffect(() => {
    const values = getValues();

    reset({
      ...values,
      id: spaceIdSuggestion?.spaceIDSuggestion?.id || "",
    });

    if (spaceIdSuggestion?.spaceIDSuggestion?.available !== true) {
      setError("id", { message: SPACE_ID_UNAVAILABLE });
    }
  }, [spaceIdSuggestion]);

  const getIdSuggestion = async (id = "") => {
    // ID needs to be hyphenated to be valid
    const idAvailable = id.trim().split(" ").join("-").toLowerCase();
    const parentMrn = currentOrg()?.mrn;
    const suggestion = await refetchSpaceIdSuggestion({
      input: { parentMrn, idAvailable },
    });

    return suggestion?.data.spaceIDSuggestion;
  };

  useEffect(() => {
    getSpaceIdSuggestion({
      variables: {
        input: { parentMrn: currentOrg()?.mrn, idAvailable: "" },
      },
    });
  }, []);

  const handleFormSubmit: SubmitHandler<CreateFormInput> = async (values) => {
    try {
      const owner = currentOrg()?.mrn;
      const id = values.id.trim().split(" ").join("-").toLowerCase();
      const name = values.name;
      const description = values.description || "";

      const createSpaceResult = await createSpace({
        variables: {
          input: {
            orgMrn: owner || "",
            name,
            id,
            description,
            settings: {
              terminatedAssetsConfiguration: { cleanup: true },
            },
          },
        },
      });
      const createdSpace = createSpaceResult.data?.createSpace;

      if (!createdSpace) throw new Error("Failed to create space");

      enqueueSnackbar(`Successfully created space: ${name}`, {
        variant: "success",
      });
      // we have to wait for viewer and settings to load again
      // before we can navigate to the new space
      await refetch();
      navigate(`/space/overview?spaceId=${createdSpace.id}`);
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.map((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
      } else {
        enqueueSnackbar("Failed to create space", { variant: "error" });
      }
    }
  };

  if (!viewer) {
    return <LoadingPage what="spaces" />;
  }

  if (viewer.organizations?.length === 0) {
    return <LoadingFailedPage what="organization" />;
  }

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)}>
      <Grid container size={12} gap={5}>
        <IdField
          errors={errors}
          register={register}
          idSuggestion={spaceIdSuggestion?.spaceIDSuggestion}
          onGenerateId={getIdSuggestion}
          unavailableErrorMessage={SPACE_ID_UNAVAILABLE}
          canEdit
          target="space"
        />

        <DisplayNameField errors={errors} register={register} target="space" />

        <DescriptionField register={register} target="space" />

        <Grid size={{ xs: 12 }} style={{ textAlign: "center" }}>
          <LoadingButton
            type="submit"
            buttonText="Create Space"
            variant="contained"
            color="primary"
            disabled={!isValid || !hasCreateSpacePermissions}
            loading={isCreateSpaceLoading}
          />
        </Grid>
      </Grid>
    </form>
  );
}
