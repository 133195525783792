import { AssetsProps } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/types";
import { Box, Button, Collapse, Grid } from "@mui/material";
import { SectionHeading } from "~/components/DetailsPage/components";
import { CaseAssetsTable } from "~/components/cases/components/CaseDetailsPage/components/AssetsSection/CaseAssetsTable";
import React, { useState } from "react";
import { Flex } from "~/components/ui-library";
import { ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { pluralize } from "~/lib/pluralize";
import { EmptySection } from "~/components/vulnerabilities";

export const MitigatedAssets = ({
  assets,
  orderBy,
  urlContextType,
  contextId,
  filteredTotalCount,
  fetchMore,
  pageInfo,
  space,
  handleSortClick,
  totalCount,
}: AssetsProps) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Grid item xs={12}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{
          "& .section-header": {
            flex: "1 0 auto",
          },
        }}
      >
        <SectionHeading heading="Mitigated Assets" />
        {totalCount > 0 && (
          <Button
            variant="text"
            color="primary"
            endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            onClick={() => setOpen((isOpen) => !isOpen)}
          >
            SHOW {totalCount} {pluralize("ASSET", totalCount)}
          </Button>
        )}
      </Flex>
      {totalCount > 0 ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box className="section-content">
            <CaseAssetsTable
              assets={assets}
              contextId={contextId}
              fetchMore={fetchMore}
              filteredTotalCount={filteredTotalCount}
              handleSortClick={handleSortClick}
              orderBy={orderBy}
              pageInfo={pageInfo}
              space={space}
              urlContextType={urlContextType}
              totalCount={totalCount}
            />
          </Box>
        </Collapse>
      ) : (
        <EmptySection
          id="mitigated-assets-empty"
          text="There are currently no mitigated assets."
        />
      )}
    </Grid>
  );
};
