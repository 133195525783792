import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  IconButton,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Fragment, ReactNode, useState } from "react";
import { CloseIcon } from "~/components/icons";
import { LoadingButton } from "~/components/loading-button";
import { AvailableComplianceFramework } from "./UseAvailableFrameworkCard";
import { Space } from "~/lib/types";
import { ComplianceFrameworkMutationAction } from "~/operations";

type Props = {
  framework: AvailableComplianceFramework;
  space: Space;
  isOpen: boolean;
  onClose: () => void;
  onActivate: (state: ComplianceFrameworkMutationAction) => Promise<void>;
};

export function ConfirmActivateFrameworkDialog({
  framework,
  space,
  isOpen,
  onClose,
  onActivate,
}: Props) {
  const [frameworkStatus, setFrameworkStatus] =
    useState<ComplianceFrameworkMutationAction>(
      ComplianceFrameworkMutationAction.Enable,
    );

  const handleFrameworkStatusChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFrameworkStatus(event.target.value as ComplianceFrameworkMutationAction);
  };
  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      data-test-id="set-exception-dialog"
    >
      <DialogTitle
        component="div"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 5,
        }}
      >
        <Typography sx={{ display: "flex", alignItems: "center" }} gap={2}>
          <Typography
            variant="h5"
            sx={{
              textTransform: "uppercase",
              fontWeight: "700",
            }}
          >
            {`Add ${framework.name} Framework`}
          </Typography>
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 5, pb: 5 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          This will add the <BoldText>{framework.name}</BoldText>
          framework to the <BoldText>{space.name}</BoldText>
          space.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Would you like to set the framework to <BoldText>ACTIVE</BoldText> or{" "}
          <BoldText>PREVIEW?</BoldText>
        </Typography>
        <FormControl>
          <RadioGroup
            aria-label="framework-status"
            name="framework-status"
            value={frameworkStatus}
            onChange={handleFrameworkStatusChange}
          >
            <FormControlLabel
              value={ComplianceFrameworkMutationAction.Enable}
              control={<Radio />}
              label={
                <Typography>
                  Set framework to <BoldText>ACTIVE</BoldText> if you're working
                  toward a scheduled audit
                </Typography>
              }
            />
            <FormControlLabel
              value={ComplianceFrameworkMutationAction.Preview}
              control={<Radio />}
              label={
                <Typography>
                  Set framework to <BoldText>PREVIEW</BoldText> for an early
                  look at how this space will perform in a future audit
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 5 }}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={() => onActivate(frameworkStatus)}
          disabled={false}
          loading={false}
          buttonText="Add Framework"
          data-test-id="add-framework-modal-button"
        />
      </DialogActions>
    </Dialog>
  );
}

const BoldText = ({ children }: { children: ReactNode }) => (
  <Fragment>
    <Typography component="span" sx={{ fontWeight: 700 }}>
      {children}
    </Typography>{" "}
  </Fragment>
);
