import { GetAssetPackagesQuery } from "~/operations";

export type CommonCellProps = {
  pckg: AssetPackageNodeType;
  "data-testid"?: string;
};

export const PackageTypes = {
  OS_PACKAGE: "OS_PACKAGE",
  APPLICATION: "APPLICATION",
} as const;

export type SoftwareEmptyStateVariants =
  | "noVulnerablePackages"
  | "notFoundError"
  | "noVulnerablePackagesWithAppliedFilters"
  | "platformNotSupported"
  | "scanningDisabled"
  | "noPackagesInstalled";

export type AssetSoftwarePackagesType = Extract<
  NonNullable<NonNullable<GetAssetPackagesQuery["packageScores"]>>,
  { __typename: "PackageScores" }
>;

export type AssetSoftwarePackagesNotFoundType = Extract<
  NonNullable<NonNullable<GetAssetPackagesQuery["packageScores"]>>,
  { __typename: "NotFound" }
>;

export type AssetPackageNodeType = NonNullable<
  NonNullable<NonNullable<AssetSoftwarePackagesType["edges"]>[0]>["node"]
>;
