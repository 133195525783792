import { Box, Button, IconButton, Typography } from "@mui/material";
import {
  AccessTimeIcon,
  ArrowForwardIcon,
  RefreshIcon,
} from "~/components/icons";
import { formatDistanceToNowStrict } from "date-fns";
import { Link as RouterLink } from "react-router-dom";
import { UseAssetUrlStats } from "~/hooks/useAssetUrlStats";

export type SunburstHeaderProps = {
  assetUrlStats: UseAssetUrlStats;
};

export function SunburstHeader({ assetUrlStats }: SunburstHeaderProps) {
  const viewAsListLink = `/space/inventory?spaceId=${
    assetUrlStats.space.id
  }&assetUrlFilter=${encodeURIComponent(
    JSON.stringify(assetUrlStats.activePath.slice(1)),
  )}`;

  return (
    <Box className="sunburst-header" sx={{ display: "flex", width: "100%" }}>
      <Box
        className="sunburst-refresh"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 0.5,
          fontSize: "12px",
          color: "text.secondary",
        }}
      >
        {assetUrlStats.isRefreshing ? (
          <RefreshIcon fontSize="inherit" />
        ) : (
          <AccessTimeIcon fontSize="inherit" />
        )}
        <Typography sx={{ fontSize: "inherit", whiteSpace: "nowrap" }}>
          <strong>
            {assetUrlStats.isRefreshing
              ? "Refresh in progress"
              : "Last Refreshed:"}
          </strong>{" "}
          {!assetUrlStats.isRefreshing && (
            <>
              {assetUrlStats.lastRefreshed
                ? `${formatDistanceToNowStrict(
                    assetUrlStats.lastRefreshed,
                  )} ago`
                : "Never"}
            </>
          )}
        </Typography>
        <IconButton
          aria-label="Refresh"
          sx={{ fontSize: "16px", mx: -1 }}
          onClick={assetUrlStats.onRefreshClick}
          disabled={assetUrlStats.isRefreshing}
        >
          <RefreshIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Button
        component={RouterLink}
        to={viewAsListLink}
        color="secondary"
        size="small"
        endIcon={<ArrowForwardIcon fontSize="inherit" />}
        sx={{ ml: "auto" }}
      >
        View As List
      </Button>
    </Box>
  );
}
