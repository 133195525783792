import {
  Box,
  Checkbox,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { Fragment } from "react";
import { DataTable } from "../data-table";
import { OrderDirection } from "../ui-library";
import { Pagination, PaginationProps } from "../pagination";
import { FirewatchRow } from "../FirewatchRow/FirewatchRow";
import {
  AdjustedAggregateScoreType,
  AggregateScoresEdges,
  AggregateScoresNode,
} from "../FirewatchPage";
import { getFirewatchColumns } from "../FirewatchPage/FirewatchPageColumns";
import { Space } from "~/lib/types";
import { Loading } from "../loading";
import { pageNameByType } from "../FirewatchPage/lib";
import { ExceptionData } from "../policy-gql/PolicyChecks";

type FirewatchListProps = {
  size?: string;
  space: Space;
  loading: boolean;
  data: AggregateScoresEdges;
  pageType: AdjustedAggregateScoreType;
  field: string;
  direction: OrderDirection;
  handleSortClick: (field: string) => void;
  paginationProps?: PaginationProps;
  disableSorting?: boolean;
  allowExceptionSetting?: boolean;
  exceptionData?: ExceptionData;
  noTags?: boolean;
};

export function FirewatchList({
  size,
  space,
  pageType,
  data,
  loading,
  handleSortClick,
  field,
  direction,
  paginationProps,
  disableSorting = false,
  allowExceptionSetting = false,
  exceptionData,
  noTags,
}: FirewatchListProps) {
  const tableHeaders = getFirewatchColumns(pageType, size);
  const dashBoardList = size === "small";

  return (
    <Fragment>
      <DataTable
        firewatchSize={size}
        selectable={allowExceptionSetting}
        selection={exceptionData?.selectedChecks}
      >
        <TableHead
          sx={{
            ...(dashBoardList && {
              backgroundColor: "inherit",
              "& th": {
                fontSize: 10,
                color: "text.secondary",
                textTransform: "uppercase",
                borderBottom: "none",
              },
            }),
          }}
        >
          <TableRow>
            {allowExceptionSetting && exceptionData && (
              <TableCell
                width={50}
                sx={{ display: exceptionData.policyEnabled ? "" : "none" }}
              >
                <Checkbox
                  checked={
                    exceptionData.selectedChecks.length === (data?.length || 0)
                  }
                  indeterminate={
                    exceptionData.selectedChecks.length > 0 &&
                    exceptionData.selectedChecks.length < (data?.length || 0)
                  }
                  onChange={(_, checked) =>
                    exceptionData.handleCheckAll(checked)
                  }
                />
              </TableCell>
            )}
            {tableHeaders.map((header) => {
              const { sortable } = header;
              const isSortable = sortable && !disableSorting;

              return (
                <TableCell
                  key={header.field}
                  align={header.options?.textAlign}
                  width={header.options?.width}
                  sx={header.options?.sx}
                >
                  {isSortable ? (
                    <TableSortLabel
                      sx={{
                        ...(dashBoardList && {
                          "& .MuiTableSortLabel-icon": { fontSize: 10 },
                        }),
                      }}
                      onClick={() => handleSortClick(header.field)}
                      direction={
                        direction === OrderDirection.Asc ? "asc" : "desc"
                      }
                      active={header.field === field}
                    >
                      {header.label}
                    </TableSortLabel>
                  ) : (
                    header.label
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* If the data is loading, show a loading message */}
          {loading ? (
            <TableRow>
              <TableCell colSpan={tableHeaders.length}>
                <Box sx={{ my: 10 }}>
                  <Loading what={`${pageNameByType(pageType)}`} />
                </Box>
              </TableCell>
            </TableRow>
          ) : (
            data?.map((edge) => (
              <FirewatchRow
                size={size}
                key={edge.node?.id}
                space={space}
                pageType={pageType}
                tableHeaders={tableHeaders}
                row={edge.node as Omit<AggregateScoresNode, "nodeTitle">}
                allowExceptionSetting={allowExceptionSetting}
                exceptionData={exceptionData}
                noTags={noTags}
              />
            ))
          )}
        </TableBody>
      </DataTable>
      {paginationProps?.pageInfo && !loading && (
        <Pagination {...paginationProps} />
      )}
    </Fragment>
  );
}
