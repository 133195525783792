import { MouseEventHandler, useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { SummaryProgress } from "./progress-bars";
import { ScoreRatingDistribution } from "~/lib/types";

export type RiskDistributionProps = {
  data: ScoreRatingDistribution;
  onClickFail?: MouseEventHandler;
  onClickPoor?: MouseEventHandler;
  onClickFair?: MouseEventHandler;
  onClickGood?: MouseEventHandler;
  onClickExcellent?: MouseEventHandler;
  onClickUnknown?: MouseEventHandler;
  onClickError?: MouseEventHandler;
  variant?: "list" | "bar";
};

type RiskDistributionData = {
  type: string;
  value: number;
  primary: string;
  onClick?: MouseEventHandler;
};

export function RiskDistribution({
  data,
  onClickFail,
  onClickPoor,
  onClickFair,
  onClickGood,
  onClickExcellent,
  onClickUnknown,
  onClickError,
  variant = "list",
}: RiskDistributionProps) {
  const theme = useTheme();

  const initialStats: RiskDistributionData[] = [
    {
      type: "F",
      value: 0,
      primary: theme.palette.fail.main,
    },
    {
      type: "D",
      value: 0,
      primary: theme.palette.poor.main,
    },
    {
      type: "C",
      value: 0,
      primary: theme.palette.fair.main,
    },
    {
      type: "B",
      value: 0,
      primary: theme.palette.good.main,
    },
    {
      type: "A",
      value: 0,
      primary: theme.palette.excellent.main,
    },
  ];

  const [riskArray, setRiskArray] =
    useState<RiskDistributionData[]>(initialStats);
  const [total, setTotal] = useState<number>(9999);

  useEffect(() => {
    setTotal(data.total);
    setRiskArray([
      {
        type: "X",
        value: data.Error,
        primary: theme.palette.error.main,
        onClick: onClickError,
      },
      {
        type: "U",
        value: data.U,
        primary: theme.palette.unrated.main,
        onClick: onClickUnknown,
      },
      {
        type: "F",
        value: data.F,
        primary: theme.palette.fail.main,
        onClick: onClickFail,
      },
      {
        type: "D",
        value: data.D,
        primary: theme.palette.poor.main,
        onClick: onClickPoor,
      },
      {
        type: "C",
        value: data.C,
        primary: theme.palette.fair.main,
        onClick: onClickFair,
      },
      {
        type: "B",
        value: data.B,
        primary: theme.palette.good.main,
        onClick: onClickGood,
      },
      {
        type: "A",
        value: data.A,
        primary: theme.palette.excellent.main,
        onClick: onClickExcellent,
      },
    ]);
  }, [data]);

  if (variant === "bar") {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "8px",
          backgroundColor: "background.lighter",
          borderRadius: 1,
          overflow: "hidden",
        }}
      >
        {riskArray
          .filter((stat) => stat.value > 0)
          .map((stat, index) => {
            const percentage = (stat.value / total) * 100;
            const title = `${stat.type}: ${Math.round(percentage)}%`;
            return (
              <Tooltip key={stat.type} title={title} placement="top" arrow>
                <Box
                  sx={{
                    height: "8px",
                    width: `calc(${percentage}% - 1px)`,
                    backgroundColor: stat.primary,
                    mr: "1px",
                    "&:last-of-type": {
                      mr: 0,
                      width: `${percentage}%`,
                    },
                  }}
                >
                  <Typography sx={visuallyHidden}>{title}</Typography>
                </Box>
              </Tooltip>
            );
          })}
      </Box>
    );
  }

  // 'list' variant is default
  return (
    <StyledList>
      {riskArray.map((stat: RiskDistributionData, index: number) => (
        <ListItem key={index}>
          <StyleListItemText
            primary={
              <StyledDiv>
                <SummaryProgress
                  variant="determinate"
                  value={(stat.value / total) * 100}
                  forecolor={stat.primary}
                />
              </StyledDiv>
            }
            secondary={`${stat.type} ${((stat.value / total) * 100).toFixed(
              0,
            )}%`}
            onClick={stat.onClick}
          ></StyleListItemText>
        </ListItem>
      ))}
    </StyledList>
  );
}

const StyledList = styled(List)`
  padding: 0;
`;

const StyleListItemText = styled(ListItemText)`
  && {
    text-align: center;
    margin: 0;
    cursor: pointer;
  }
`;

const StyledDiv = styled("div")`
  padding: 0px 40px 2px 40px;
`;
