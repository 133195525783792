import React from "react";
import { Flex } from "~/components/ui-library";
import { TabVariant } from "./TabVariant";

export const TabVariantSkeleton = () => {
  const commonProps = {
    sx: {
      minHeight: "32px",
      minWidth: "100px",
    },
  };
  return (
    <Flex flexWrap={"wrap"} gap={2}>
      <TabVariant {...commonProps} isActive />
      <TabVariant {...commonProps} />
      <TabVariant {...commonProps} />
      <TabVariant {...commonProps} />
    </Flex>
  );
};
