import { ElementType, forwardRef, ReactNode } from "react";
import { Box, BoxProps } from "@mui/material";

type FlexProps<T extends ElementType = "div"> = BoxProps<T> & {
  center?: boolean;
  children?: ReactNode;
  component?: React.ElementType;
};

const Flex = forwardRef(
  ({ center, component = "div", ...restProps }: FlexProps, ref) => {
    return (
      <Box
        ref={ref}
        display="flex"
        component={component}
        {...(center ? { alignItems: "center", justifyContent: "center" } : {})}
        {...restProps}
      />
    );
  },
);

export default Flex;
