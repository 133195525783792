import React from "react";
import { SvgIcon, useTheme } from "@mui/material";

export const CreateTicketingIntegrationSecondStep = ({ ...props }) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props}>
      <svg
        width="208"
        height="144"
        viewBox="0 0 208 144"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="190"
          height="126"
          rx="7"
          fill={theme.palette.background.light}
          stroke={theme.palette.background.lightest}
          strokeWidth="2"
        />
        <path
          d="M8 28C8 25.7909 9.79086 24 12 24H82C84.2091 24 86 25.7909 86 28C86 30.2091 84.2091 32 82 32H12C9.79086 32 8 30.2091 8 28Z"
          fill={theme.palette.background.lightest}
        />
        <path
          d="M8 48C8 45.7909 9.79086 44 12 44H180C182.209 44 184 45.7909 184 48C184 50.2091 182.209 52 180 52H12C9.79086 52 8 50.2091 8 48Z"
          fill={theme.palette.background.lightest}
        />
        <path
          d="M8 64C8 61.7909 9.79086 60 12 60H180C182.209 60 184 61.7909 184 64C184 66.2091 182.209 68 180 68H12C9.79086 68 8 66.2091 8 64Z"
          fill={theme.palette.background.lightest}
        />
        <path
          d="M8 84C8 79.5817 11.5817 76 16 76H176C180.418 76 184 79.5817 184 84V112C184 116.418 180.418 120 176 120H16C11.5817 120 8 116.418 8 112V84Z"
          fill={theme.palette.background.lightest}
        />
        <g filter="url(#filter0_d_3126_107747)">
          <path
            d="M160 28C160 25.7909 161.791 24 164 24H180C182.209 24 184 25.7909 184 28C184 30.2091 182.209 32 180 32H164C161.791 32 160 30.2091 160 28Z"
            fill="#9147FF"
          />
        </g>
        <path
          d="M132 28C132 25.7909 133.791 24 136 24H152C154.209 24 156 25.7909 156 28C156 30.2091 154.209 32 152 32H136C133.791 32 132 30.2091 132 28Z"
          fill="white"
        />
        <path
          d="M1 8C1 4.13401 4.13401 1 8 1H184C187.866 1 191 4.13401 191 8V15H1V8Z"
          fill={theme.palette.background.lighter}
          stroke={theme.palette.background.lightest}
          strokeWidth="2"
        />
        <circle
          cx="192"
          cy="128"
          r="16"
          fill="url(#paint0_linear_3126_107747)"
        />
        <path
          d="M195.562 132.242V134H187.781V132.5L191.461 128.555C191.831 128.143 192.122 127.781 192.336 127.469C192.549 127.151 192.703 126.867 192.797 126.617C192.896 126.362 192.945 126.12 192.945 125.891C192.945 125.547 192.888 125.253 192.773 125.008C192.659 124.758 192.49 124.565 192.266 124.43C192.047 124.294 191.776 124.227 191.453 124.227C191.109 124.227 190.812 124.31 190.562 124.477C190.318 124.643 190.13 124.875 190 125.172C189.875 125.469 189.812 125.805 189.812 126.18H187.555C187.555 125.503 187.716 124.883 188.039 124.32C188.362 123.753 188.818 123.302 189.406 122.969C189.995 122.63 190.693 122.461 191.5 122.461C192.297 122.461 192.969 122.591 193.516 122.852C194.068 123.107 194.484 123.477 194.766 123.961C195.052 124.44 195.195 125.013 195.195 125.68C195.195 126.055 195.135 126.422 195.016 126.781C194.896 127.135 194.724 127.49 194.5 127.844C194.281 128.193 194.016 128.547 193.703 128.906C193.391 129.266 193.044 129.638 192.664 130.023L190.688 132.242H195.562Z"
          fill="white"
        />
        <defs>
          <filter
            id="filter0_d_3126_107747"
            x="148"
            y="12"
            width="48"
            height="32"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="4"
              operator="dilate"
              in="SourceAlpha"
              result="effect1_dropShadow_3126_107747"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="4" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.501961 0 0 0 0 0.262745 0 0 0 0 0.980392 0 0 0 0.33 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_3126_107747"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_3126_107747"
              result="shape"
            />
          </filter>
          <linearGradient
            id="paint0_linear_3126_107747"
            x1="176"
            y1="112"
            x2="208.789"
            y2="143.17"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9147FF" />
            <stop offset="1" stopColor="#4C35E8" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
