import React from "react";
import { Box } from "@mui/material";
import { Stats } from "~/components/DetailsPage/components";
import { Stat } from "~/components/DetailsPage/components/Stats/Stats";

type SoftwareStatsProps = {
  stats: Stat[];
};

export const SoftwareStats = ({ stats }: SoftwareStatsProps) => {
  return (
    <Box my={2}>
      <Stats id="software-asset-stats" stats={stats} />
    </Box>
  );
};
