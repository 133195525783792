import React from "react";

import { createSvgIcon } from "@mui/material";

export const ClipboardPassIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" fill="none">
    <g id="Nav Icon">
      <g id="Group 430">
        <g id="Group 622">
          <g id="Group 621">
            <path
              id="Vector"
              d="M30.1004 15.7598H22.7004C22.5347 15.7598 22.4004 15.8941 22.4004 16.0598V17.7798C22.4004 17.9455 22.5347 18.0798 22.7004 18.0798H30.1004C30.2661 18.0798 30.4004 17.9455 30.4004 17.7798V16.0598C30.4004 15.8941 30.2661 15.7598 30.1004 15.7598Z"
              fill="currentColor"
            />
            <path
              id="Vector_2"
              d="M30.1004 28.5H22.7004C22.5347 28.5 22.4004 28.6343 22.4004 28.8V30.52C22.4004 30.6857 22.5347 30.82 22.7004 30.82H30.1004C30.2661 30.82 30.4004 30.6857 30.4004 30.52V28.8C30.4004 28.6343 30.2661 28.5 30.1004 28.5Z"
              fill="currentColor"
            />
            <path
              id="Vector_3"
              d="M18.661 26.1401C18.4401 25.9215 18.1418 25.7988 17.831 25.7988C17.5202 25.7988 17.2219 25.9215 17.001 26.1401L15.141 28.0001L13.261 26.1401C13.0401 25.9215 12.7418 25.7988 12.431 25.7988C12.1202 25.7988 11.8219 25.9215 11.601 26.1401C11.3884 26.3601 11.2695 26.6541 11.2695 26.9601C11.2695 27.266 11.3884 27.5601 11.601 27.7801L13.481 29.6601L11.601 31.5401C11.3884 31.7601 11.2695 32.0541 11.2695 32.3601C11.2695 32.6661 11.3884 32.9601 11.601 33.1801C11.8219 33.3987 12.1202 33.5213 12.431 33.5213C12.7418 33.5213 13.0401 33.3987 13.261 33.1801L15.141 31.3001L17.001 33.1801C17.2219 33.3987 17.5202 33.5213 17.831 33.5213C18.1418 33.5213 18.4401 33.3987 18.661 33.1801C18.7711 33.0738 18.8586 32.9464 18.9184 32.8055C18.9782 32.6646 19.009 32.5131 19.009 32.3601C19.009 32.207 18.9782 32.0556 18.9184 31.9147C18.8586 31.7738 18.7711 31.6464 18.661 31.5401L16.781 29.6601L18.661 27.7801C18.7711 27.6738 18.8586 27.5464 18.9184 27.4055C18.9782 27.2646 19.009 27.1131 19.009 26.9601C19.009 26.807 18.9782 26.6556 18.9184 26.5147C18.8586 26.3738 18.7711 26.2464 18.661 26.1401Z"
              fill="currentColor"
            />
            <path
              id="Vector_4"
              d="M17.6604 13.9998L13.5404 18.0998L12.1004 16.6598C11.9941 16.5497 11.8667 16.4621 11.7258 16.4023C11.5849 16.3425 11.4334 16.3117 11.2804 16.3117C11.1273 16.3117 10.9759 16.3425 10.835 16.4023C10.6941 16.4621 10.5667 16.5497 10.4604 16.6598C10.2418 16.8807 10.1191 17.1789 10.1191 17.4898C10.1191 17.8006 10.2418 18.0988 10.4604 18.3198L12.6604 20.5798C12.8802 20.7937 13.1736 20.9154 13.4804 20.9198C13.7929 20.9145 14.0922 20.7933 14.3204 20.5798L19.2404 15.6598C19.459 15.4388 19.5816 15.1406 19.5816 14.8298C19.5816 14.5189 19.459 14.2207 19.2404 13.9998C19.028 13.7956 18.7449 13.6816 18.4504 13.6816C18.1559 13.6816 17.8727 13.7956 17.6604 13.9998Z"
              fill="currentColor"
            />
            <path
              id="Vector_5"
              d="M34.12 4.64H29V4.2C29 3.35131 28.6629 2.53737 28.0627 1.93726C27.4626 1.33714 26.6487 1 25.8 1H15.52C14.6713 1 13.8574 1.33714 13.2573 1.93726C12.6571 2.53737 12.32 3.35131 12.32 4.2V4.64H7.2C6.35131 4.64 5.53737 4.97714 4.93726 5.57726C4.33714 6.17737 4 6.99131 4 7.84V37.84C4.01052 38.6817 4.3523 39.4854 4.95128 40.0769C5.55026 40.6684 6.35819 41.0001 7.2 41H34.12C34.9687 41 35.7826 40.6629 36.3827 40.0627C36.9829 39.4626 37.32 38.6487 37.32 37.8V7.8C37.3095 6.95826 36.9677 6.15456 36.3687 5.56307C35.7697 4.97158 34.9618 4.63993 34.12 4.64ZM14.92 4.2C14.92 4.12121 14.9355 4.04319 14.9657 3.97039C14.9958 3.89759 15.04 3.83145 15.0957 3.77574C15.1515 3.72002 15.2176 3.67583 15.2904 3.64567C15.3632 3.61552 15.4412 3.6 15.52 3.6H25.8C25.9591 3.6 26.1117 3.66321 26.2243 3.77574C26.3368 3.88826 26.4 4.04087 26.4 4.2V7.64C26.4 7.79913 26.3368 7.95174 26.2243 8.06426C26.1117 8.17679 25.9591 8.24 25.8 8.24H15.52C15.4412 8.24 15.3632 8.22448 15.2904 8.19433C15.2176 8.16417 15.1515 8.11998 15.0957 8.06426C15.04 8.00855 14.9958 7.94241 14.9657 7.86961C14.9355 7.79681 14.92 7.71879 14.92 7.64V4.2ZM34.66 37.8C34.6628 37.8795 34.6492 37.9588 34.62 38.0329C34.5909 38.1069 34.5468 38.1742 34.4905 38.2305C34.4342 38.2868 34.3669 38.3309 34.2929 38.36C34.2188 38.3892 34.1395 38.4028 34.06 38.4H7.2C7.12045 38.4028 7.04119 38.3892 6.96713 38.36C6.89307 38.3309 6.8258 38.2868 6.76952 38.2305C6.71324 38.1742 6.66914 38.1069 6.63998 38.0329C6.61082 37.9588 6.59721 37.8795 6.6 37.8V7.8C6.6 7.64087 6.66321 7.48826 6.77574 7.37574C6.88826 7.26321 7.04087 7.2 7.2 7.2H12.32V7.6C12.32 8.44869 12.6571 9.26262 13.2573 9.86274C13.8574 10.4629 14.6713 10.8 15.52 10.8H25.8C26.6487 10.8 27.4626 10.4629 28.0627 9.86274C28.6629 9.26262 29 8.44869 29 7.6V7.2H34.12C34.2791 7.2 34.4317 7.26321 34.5443 7.37574C34.6568 7.48826 34.72 7.64087 34.72 7.8L34.66 37.8Z"
              fill="currentColor"
            />
          </g>
          <rect
            id="Rectangle 2642"
            x="31"
            y="34"
            width="11"
            height="9"
            fill="#292928"
          />
          <path
            id="CheckCircleFilled"
            d="M36.6602 30C32.2442 30 28.6602 33.584 28.6602 38C28.6602 42.416 32.2442 46 36.6602 46C41.0762 46 44.6602 42.416 44.6602 38C44.6602 33.584 41.0762 30 36.6602 30ZM35.0602 42L31.0602 38L32.1882 36.872L35.0602 39.736L41.1322 33.664L42.2602 34.8L35.0602 42Z"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>,
  "Clipboard Pass",
);
