import React, { ReactElement } from "react";
import { alpha, Button } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "~/components/icons";
import { useColorMode } from "~/providers/color-mode";

type DropdownAutocompleteProps = {
  open: boolean;
  anchorRef: React.RefObject<HTMLButtonElement>;
  selected: ReactElement;
  scope?: string;
  onToggle: () => void;
};

export const DropdownAutocomplete = ({
  open,
  anchorRef,
  selected,
  scope = "",
  onToggle,
  ...rest
}: DropdownAutocompleteProps) => {
  const { mode } = useColorMode();
  return (
    <Button
      ref={anchorRef}
      variant="outlined"
      aria-controls={open ? `${scope}-button-menu` : undefined}
      aria-expanded={open ? "true" : undefined}
      aria-label={`Select from available ${scope}`}
      aria-haspopup="menu"
      onClick={onToggle}
      sx={{
        px: 0.5,
        backgroundColor: mode === "light" ? "common.white" : "common.black",
        borderColor: open
          ? (theme) => theme.palette.primary.main
          : (theme) => alpha(theme.palette.text.primary, 0.23),
        justifyContent: "space-between",
        padding: "14px 14px",
        alignItems: "center",
        fontSize: "16px",
        width: "100%",
        fontWeight: "400",
        textTransform: "unset",
        "&:hover": {
          // overriding button styles
          backgroundColor: mode === "light" ? "common.white" : "common.black",
          borderColor: (theme) =>
            open ? "primary.main" : theme.palette.text.primary,
        },
        "&:focused": {
          borderColor: open
            ? (theme) => theme.palette.primary.main
            : (theme) => theme.palette.text.primary,
        },
      }}
      endIcon={
        open ? (
          <ExpandLessIcon fontSize="small" />
        ) : (
          <ExpandMoreIcon fontSize="small" />
        )
      }
      {...rest}
    >
      {selected}
    </Button>
  );
};
