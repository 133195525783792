import React from "react";
import { alpha, Box, Typography } from "@mui/material";

export const SoftwareSources = () => {
  return (
    <Box mt={4}>
      <Typography textTransform="uppercase" fontWeight={700} mb={1}>
        Sources
      </Typography>
      <Typography
        sx={{
          fontWeight: 700,
          color: (theme) => alpha(theme.palette.text.primary, 0.33),
        }}
      >
        No sources provided for this content yet
      </Typography>
    </Box>
  );
};
