import { Box, Grid, Typography } from "@mui/material";
import { Space } from "~/lib/types";
import {
  ComplianceFrameworkState,
  TestIamActionsQuery,
  useGetActiveComplianceFrameworksQuery,
} from "~/operations";
import { ComplianceBreadcrumbs } from "../breadcrumbs";
import { ComplianceEntrySplash } from "./ComplianceEntrySplash";
import { ActiveFrameworks } from "../ActiveFrameworks/ActiveFrameworks";

import { useLayoutEffect, useState } from "react";
import { AddFramework } from "../AddFramework/AddFramework";
import { AnimatePresence, motion } from "framer-motion";
import { ComplianceHeader } from "../components/ComplianceHeader";
import { IamActions } from "~/lib/iam";
import { LoadingFailedPage, LoadingPage } from "~/components/loading";

type ComplianceEntryPageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function ComplianceEntryPage({
  space,
  availablePermissions,
}: ComplianceEntryPageProps) {
  const [isAdding, setIsAdding] = useState<boolean>(true);
  const [hasFrameworks, setHasFrameworks] = useState<boolean>(false);

  const { data, error, loading } = useGetActiveComplianceFrameworksQuery({
    variables: {
      input: {
        scopeMrn: space.mrn,
        states: [
          ComplianceFrameworkState.Active,
          ComplianceFrameworkState.Preview,
        ],
      },
    },
  });

  useLayoutEffect(() => {
    if (data?.complianceFrameworks && data.complianceFrameworks.length > 0) {
      setIsAdding(false);
      setHasFrameworks(true);
    } else {
      setIsAdding(false);
      setHasFrameworks(false);
    }
  }, [data]);

  if (error) {
    return <LoadingFailedPage what="Compliance" />;
  }

  if (loading) {
    return <LoadingPage what="Compliance" />;
  }

  const hasAddFrameworkPermission = availablePermissions?.includes(
    IamActions.ACTION_CNSPEC_POLICY_POLICYHUB_SETBUNDLE,
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <ComplianceBreadcrumbs {...{ space }} mb={0} />
      </Grid>
      <ComplianceHeader
        {...{
          space,
          hasFrameworks,
          hasAddFrameworkPermission,
          isAdding,
          setIsAdding,
        }}
      />
      {!hasFrameworks && !isAdding && (
        <ComplianceEntrySplash space={space} setIsAdding={setIsAdding} />
      )}

      {!hasFrameworks && isAdding && (
        <AddFramework
          space={space}
          availablePermissions={availablePermissions}
        />
      )}

      {hasFrameworks && data && (
        <AnimatePresence mode="wait">
          {isAdding ? (
            <AddFramework
              key="add-framework"
              space={space}
              availablePermissions={availablePermissions}
            />
          ) : (
            <ActiveFrameworks
              key="active-frameworks"
              space={space}
              data={data}
            />
          )}
        </AnimatePresence>
      )}
    </Grid>
  );
}
