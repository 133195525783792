import React, { useCallback } from "react";

export function useRemediationScripts() {
  const [scriptTab, setScriptTab] = React.useState(0);

  const handleChangeScriptTab = useCallback(
    (_event: React.SyntheticEvent, newValue: number) => {
      setScriptTab(newValue);
    },
    [],
  );

  return {
    scriptTab,
    handleChangeScriptTab,
  };
}
