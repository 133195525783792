import { Box, Collapse, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useSections } from "~/hooks/useSections";
import { RiskFactorStats } from "~/operations";
import RiskFactorsContent from "~/components/vulnerabilities/risk-factors-content";
import { RiskFactorsTitle } from "~/components/vulnerabilities/risk-factors-title";
import { RiskGraph } from "~/components/RiskGraph";
import { AssetStarIcon, FormatAlignLeftIcon } from "~/components/icons";

type RiskStatsWithDescription = RiskFactorStats & {
  description: {
    active: string;
    inactive: string;
  };
};
export type AssetRiskFactorsProps = {
  id?: string;
  riskFactors?: (RiskStatsWithDescription | null)[] | null | undefined;
};

export function AssetRiskFactors({
  id,
  riskFactors = fakeRiskFactors,
}: AssetRiskFactorsProps) {
  const sections = useSections({
    key: "assetRiskAssessment",
    sections: ["riskFactors"],
  });

  const rfsections = useSections({
    key: "assetRiskAssessment.riskFactors",
    sections: ["graph", "list"],
  });

  const suffix = (
    <ToggleButtonGroup
      value={rfsections.openSections[0]}
      exclusive
      onChange={(e, view) => {
        if (view !== null) rfsections.setOpen([view]);
      }}
      sx={{
        pointerEvents: sections.isOpen("riskFactors") ? "default" : "none",
        opacity: sections.isOpen("riskFactors") ? 1 : 0,
        transition: (theme) => theme.transitions.create(["opacity"]),
      }}
    >
      <ToggleButton value="graph">
        <AssetStarIcon />
      </ToggleButton>
      <ToggleButton value="list">
        <FormatAlignLeftIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );

  return (
    <Box id={id} display="flex" flexDirection="column" gap={5}>
      {riskFactors && riskFactors.length > 0 && (
        <Box>
          <>
            <RiskFactorsTitle
              open={sections.isOpen("riskFactors")}
              onClick={() => sections.toggle("riskFactors")}
              riskFactors={riskFactors}
              showSubtitle={true}
              suffix={suffix}
            />
            <Collapse in={sections.isOpen("riskFactors")} unmountOnExit>
              {rfsections.isOpen("list") && (
                <RiskFactorsContent riskFactors={riskFactors} />
              )}
              {rfsections.isOpen("graph") && <RiskGraph />}
            </Collapse>
          </>
        </Box>
      )}
    </Box>
  );
}

const fakeRiskFactors: AssetRiskFactorsProps["riskFactors"] = [
  {
    title: "Internet Exposed",
    affected: 1,
    total: 1,
    mrn: "",
    indicator: "internet-facing",
    description: {
      active:
        "The SSH service is running and can expose security issues to outside attackers.",
      inactive:
        "The SSH service is not running, which reduces the attack surface on the asset.",
    },
    __typename: "RiskFactorStats",
  },
  {
    title: "Remote Code Execution",
    affected: 1,
    total: 1,
    mrn: "",
    indicator: "code-execution",
    description: {
      active:
        "The vulnerability can be exploited from a remote location, i.e. from outside using the internet or from another computer within the intranet.",
      inactive:
        "The vulnerability cannot be remotely exploited by an attacker.",
    },

    __typename: "RiskFactorStats",
  },
];
