import { useEffect, useMemo, useState } from "react";

export function useViewport() {
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    let timer: number;

    function handleResize() {
      if (timer) {
        window.clearTimeout(timer);
      }

      setIsResizing(true);

      timer = window.setTimeout(() => {
        setIsResizing(false);
      }, 100);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return useMemo(() => {
    return {
      isResizing,
    };
  }, [isResizing]);
}
