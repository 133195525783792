import { Box } from "@mui/material";
import { DonutChart } from "~/components/charts/donut-chart";
import { CardSizes, HubCard } from "~/components/hub-card";
import { NavDrawerPolicyIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import { Metrics, useMetrics } from "~/hooks";
import { Flex } from "~/components/ui-library";

type Props = {
  sizes?: CardSizes;
  space: Space;
};

export function PolicyOverviewCard({ space, sizes }: Props) {
  const { policyGrades, isLoading } = useMetrics({
    entityMrn: space.mrn,
    metricMrns: [Metrics.PolicyGradeDistribution],
  });

  return (
    <Flex gap={2} flexDirection="row">
      <HubCard
        to={`policies?spaceId=${space.id}`}
        {...{
          loading: isLoading,
          sizes,
          title: "Policy Overview",
          icon: <NavDrawerPolicyIcon fontSize="small" />,
        }}
        PaperProps={{ height: 264 }}
        data-testid="policy-overview-card"
      >
        <Box sx={{ mt: 2 }}>
          <DonutChart data={policyGrades} dataType="policies" />
        </Box>
      </HubCard>
    </Flex>
  );
}
