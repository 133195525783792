import { createSvgIcon } from "@mui/material";

export const GoogleWorkspaceIcon = createSvgIcon(
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.12 24.5C45.12 22.94 44.98 21.44 44.72 20H24V28.52H35.84C35.32 31.26 33.76 33.58 31.42 35.14V40.68H38.56C42.72 36.84 45.12 31.2 45.12 24.5Z"
      fill="#4285F4"
    />
    <path
      d="M23.9994 46C29.9394 46 34.9194 44.04 38.5594 40.68L31.4194 35.1399C29.4594 36.4599 26.9594 37.2599 23.9994 37.2599C18.2794 37.2599 13.4194 33.4 11.6794 28.2H4.35938V33.88C7.97937 41.06 15.3994 46 23.9994 46Z"
      fill="#34A853"
    />
    <path
      d="M11.68 28.18C11.24 26.86 10.98 25.46 10.98 24C10.98 22.54 11.24 21.14 11.68 19.82V14.14H4.36C2.86 17.1 2 20.44 2 24C2 27.56 2.86 30.9 4.36 33.86L10.06 29.42L11.68 28.18Z"
      fill="#FBBC05"
    />
    <path
      d="M23.9994 10.76C27.2394 10.76 30.1194 11.88 32.4194 14.04L38.7194 7.74C34.8994 4.18 29.9394 2 23.9994 2C15.3994 2 7.97937 6.94 4.35938 14.14L11.6794 19.82C13.4194 14.62 18.2794 10.76 23.9994 10.76Z"
      fill="#EA4335"
    />
  </svg>,
  "Google Workspace",
);
