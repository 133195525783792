import { createSvgIcon } from "@mui/material";

export const DebianIcon = createSvgIcon(
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    enableBackground="new 0 0 48 48"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      fill="#A80030"
      d="M27.1,25.2c-0.7,0,0.1,0.4,1.1,0.5c0.3-0.2,0.5-0.4,0.7-0.6C28.3,25.3,27.7,25.3,27.1,25.2z"
    />
    <path
      fill="#A80030"
      d="M31,24.3c0.4-0.6,0.8-1.3,0.9-1.9c-0.1,0.5-0.4,0.9-0.6,1.3c-1.4,0.9-0.1-0.5,0-1C29.8,24.5,31.1,23.8,31,24.3z
	"
    />
    <path
      fill="#A80030"
      d="M32.5,20.5c0.1-1.3-0.3-0.9-0.4-0.4C32.2,20.2,32.4,21,32.5,20.5z"
    />
    <path
      fill="#A80030"
      d="M24.4,2.6c0.4,0.1,0.8,0.1,0.8,0.2C25.6,2.7,25.7,2.6,24.4,2.6z"
    />
    <polygon fill="#A80030" points="25.1,2.8 24.9,2.8 25.1,2.8 " />
    <path
      fill="#A80030"
      d="M37.3,21c0,1.2-0.3,1.8-0.7,2.8l-0.6,0.3c-0.5,1,0,0.6-0.3,1.4c-0.8,0.7-2.4,2.2-3,2.4c-0.4,0,0.3-0.5,0.3-0.6
	c-1.1,0.7-0.9,1.1-2.5,1.6l0-0.1c-4.1,1.9-9.7-1.9-9.6-7c0,0.3-0.1,0.2-0.2,0.4c-0.2-2.7,1.2-5.3,3.7-6.4c2.4-1.2,5.2-0.7,6.9,0.9
	c-0.9-1.2-2.8-2.5-5-2.4c-2.2,0-4.2,1.4-4.8,2.9c-1.1,0.7-1.2,2.7-1.7,3c-0.6,4.8,1.2,6.8,4.4,9.2c0.5,0.3,0.1,0.4,0.2,0.6
	c-1-0.5-2-1.2-2.8-2.1c0.4,0.6,0.9,1.2,1.5,1.7c-1-0.3-2.3-2.4-2.7-2.5c1.7,3.1,6.9,5.4,9.6,4.2c-1.3,0-2.9,0-4.3-0.5
	c-0.6-0.3-1.4-0.9-1.3-1.1c3.7,1.4,7.6,1.1,10.8-1.5c0.8-0.6,1.7-1.7,2-1.7c-0.4,0.6,0.1,0.3-0.2,0.8c0.8-1.3-0.4-0.5,0.8-2.3
	l0.4,0.6c-0.2-1.1,1.4-2.4,1.2-4.2c0.3-0.5,0.4,0.6,0,1.8c0.5-1.4,0.1-1.6,0.3-2.7c0.1,0.4,0.3,0.8,0.4,1.2c-0.3-1.3,0.3-2.2,0.5-3
	c-0.2-0.1-0.5,0.6-0.6-1c0-0.7,0.2-0.4,0.3-0.5c-0.1-0.1-0.5-0.6-0.7-1.6c0.2-0.2,0.4,0.6,0.6,0.6c-0.1-0.8-0.4-1.4-0.4-2
	c-0.6-1.3-0.2,0.2-0.7-0.5c-0.6-2,0.5-0.5,0.6-1.4c1,1.4,1.5,3.6,1.8,4.5c-0.2-1.1-0.5-2.2-0.9-3.2c0.3,0.1-0.5-2.3,0.4-0.7
	c-0.9-3.4-3.9-6.6-6.7-8c0.3,0.3,0.8,0.7,0.6,0.8c-1.4-0.8-1.1-0.9-1.3-1.2c-1.1-0.5-1.2,0-1.9,0c-2.1-1.1-2.5-1-4.5-1.7l0.1,0.4
	c-1.4-0.5-1.6,0.2-3.2,0c-0.1-0.1,0.5-0.3,1-0.3c-1.4,0.2-1.3-0.3-2.6,0c0.3-0.2,0.7-0.4,1-0.6c-1.1,0.1-2.6,0.6-2.2,0.1
	c-1.8,0.8-5,1.9-6.8,3.6l-0.1-0.4c-0.8,1-3.6,3-3.8,4.2L10.2,10c-0.4,0.7-0.7,1.6-1,2.3c-0.6,1-0.8,0.4-0.7,0.5
	c-1.1,2.2-1.7,4.1-2.1,5.7c0.3,0.5,0,3,0.1,5.1c-0.6,10,7,19.8,15.3,22c1.2,0.4,3,0.4,4.6,0.5c-1.8-0.5-2-0.3-3.8-0.9
	c-1.3-0.6-1.6-1.3-2.5-2.1l0.4,0.6c-1.8-0.6-1-0.8-2.5-1.2l0.4-0.5c-0.6,0-1.5-1-1.8-1.5l-0.6,0c-0.8-0.9-1.2-1.6-1.1-2.1l-0.2,0.4
	c-0.2-0.4-2.8-3.5-1.5-2.8c-0.2-0.2-0.6-0.4-0.9-1l0.3-0.3c-0.6-0.8-1.2-1.9-1.1-2.2c0.3,0.5,0.6,0.5,0.8,0.6
	c-1.6-4-1.7-0.2-2.9-4.1l0.3,0C9.2,28.7,9.1,28.4,9,28l0.1-1.1c-1.2-1.3-0.3-5.7-0.2-8.1c0.1-1,1-2,1.6-3.6l-0.4-0.1
	c0.8-1.3,4.3-5.3,5.9-5.1c0.8-1-0.2,0-0.3-0.3c1.7-1.8,2.3-1.3,3.5-1.6c1.3-0.8-1.1,0.3-0.5-0.3c2.2-0.6,1.6-1.3,4.4-1.6
	c0.3,0.2-0.7,0.3-1,0.5C24,6,28,6.2,30.6,7.4c3,1.4,6.3,5.5,6.5,9.4l0.2,0c-0.1,1.5,0.2,3.3-0.3,5L37.3,21z"
    />
    <path
      fill="#A80030"
      d="M19.2,26.2l-0.1,0.5c0.5,0.7,0.9,1.4,1.5,1.9C20.1,27.8,19.8,27.4,19.2,26.2z"
    />
    <path
      fill="#A80030"
      d="M20.3,26.2c-0.3-0.3-0.4-0.6-0.6-1c0.2,0.6,0.5,1.1,0.8,1.6L20.3,26.2z"
    />
    <path
      fill="#A80030"
      d="M40.3,21.8l-0.1,0.3c-0.2,1.4-0.6,2.8-1.3,4.1C39.7,24.8,40.1,23.3,40.3,21.8z"
    />
    <path
      fill="#A80030"
      d="M24.5,2.2C25,2,25.7,2.1,26.2,2c-0.7,0.1-1.4,0.1-2,0.2L24.5,2.2z"
    />
    <path
      fill="#A80030"
      d="M7.2,11.4c0.1,1.1-0.8,1.5,0.2,0.8C7.9,11,7.2,11.9,7.2,11.4z"
    />
    <path
      fill="#A80030"
      d="M6,16.3c0.2-0.7,0.3-1.1,0.4-1.5C5.8,15.6,6.1,15.7,6,16.3z"
    />
  </svg>,
  "Debian",
);
