import { Checkbox, TableCell, TableRow } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { RankCell } from "../DashboardList";
import { ImpactCell } from "./ImpactCell";
import { BlastRadiusCell } from "./BlastRadiusCell";
import { CheckCell } from "./CheckCell";
import { AdvisoryCell } from "./AdvisoryCell";
import { FirstFoundCell } from "./FirstFoundCell";
import { VulnerabilityCell } from "./VulnerabilityCell";
import {
  AdjustedAggregateScoreType,
  AggregateScoresNode,
} from "../FirewatchPage";
import { AggregateScoreType } from "~/operations";
import { getLinkByType } from "../FirewatchPage/lib";
import { Space } from "~/lib/types";
import { Header } from "../FirewatchPage/FirewatchPageColumns";
import { DateCell } from "./DateCell";
import { RiskScoreCell } from "./RiskScoreCell";
import { PolicyNameCell } from "./PolicyNameCell";
import { AssetCell } from "./AssetCell";
import { SoftwareCell } from "~/components/FirewatchRow/SoftwareCell";
import { AssetsCountCell } from "~/components/FirewatchRow/AssetsCountCell";
import { RiskFactorsCell } from "../Cells";
import { ExceptionData } from "../policy-gql/PolicyChecks";

type FirewatchRowProps = {
  pageType: AdjustedAggregateScoreType;
  tableHeaders: Header[];
  row: AggregateScoresNode;
  space: Space;
  size?: string;
  allowExceptionSetting?: boolean;
  exceptionData?: ExceptionData;
  noTags?: boolean;
};

export function FirewatchRow({
  pageType,
  tableHeaders,
  row,
  space,
  size,
  allowExceptionSetting,
  exceptionData,
  noTags,
}: FirewatchRowProps) {
  // If the page type is Vulnerability or Advisory, we should link to the specific page
  const shouldLinkTypes = [
    AggregateScoreType.Policy,
    AggregateScoreType.Vulnerability,
    AggregateScoreType.Advisory,
    AggregateScoreType.Asset,
    "NewestRelevantVulnerabilities",
    AggregateScoreType.Check,
    AggregateScoreType.Software,
    AggregateScoreType.VersionedSoftware,
  ];
  const shouldLink = shouldLinkTypes.includes(pageType);

  return (
    <TableRow
      data-testid="firewatch-list-row"
      {...(shouldLink &&
        row?.findingMrn && {
          component: RouterLink,
          to: getLinkByType(
            pageType,
            space.id,
            row.findingMrn,
            row.entity?.__typename === "EntityInfoAsset"
              ? row?.entity
              : undefined,
          ),
        })}
      sx={{
        "&.MuiTableRow-root:hover .firewatch-chip": {
          backgroundColor: "background.lighter",
        },
        ...(size === "small" && {
          sx: { td: { pl: 0 } },
        }),
      }}
    >
      {allowExceptionSetting && exceptionData && (
        <TableCell>
          <Checkbox
            checked={Object.values(exceptionData.selectedChecks).some(
              (check) => check?.mrn === row.findingMrn,
            )}
            onChange={(e, checked) => {
              exceptionData.handleCheck(e, checked, {
                groupId: row.id,
                mrn: row.findingMrn,
                exception: null,
              });
            }}
            onClick={(e) => e.stopPropagation()}
          />
        </TableCell>
      )}
      {tableHeaders.map((header) => {
        if (header.id === "RANK") {
          return (
            <RankCell key={`${header.id}-${row?.id}`} id={row?.rank || 0} />
          );
        } else if (header.id === "IMPACT") {
          return (
            <ImpactCell
              key={`${header.id}-${row?.id}`}
              impact={row?.riskScore || 0}
              isActive
            />
          );
        } else if (header.id === "BLAST_RADIUS") {
          return (
            <BlastRadiusCell
              key={`${header.id}-${row?.id}`}
              blastRadius={row?.blastRadius}
              isActive
              impact={row?.riskScore || 0}
            />
          );
        } else if (header.id === "CHECK") {
          return (
            <CheckCell
              key={`${header.id}-${row?.id}`}
              row={row}
              size={size}
              noTags={noTags}
              exceptionData={exceptionData}
            />
          );
        } else if (header.id === "ADVISORY") {
          return <AdvisoryCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "VULNERABILITY") {
          return (
            <VulnerabilityCell
              key={`${header.id}-${row?.id}`}
              row={row}
              size={size}
            />
          );
        } else if (header.id === "ASSET") {
          return (
            <AssetCell
              key={`${header.id}-${row?.id}`}
              {...{ title: row.title, tags: row.tags, iconId: row.iconId }}
            />
          );
        } else if (header.id === "POLICY") {
          return <PolicyNameCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "SOFTWARE") {
          return <SoftwareCell key={`${header.id}-${row?.id}`} row={row} />;
        } else if (header.id === "RISK_FACTORS") {
          return (
            <RiskFactorsCell
              key={`${header.id}-${row?.id}`}
              riskFactors={row?.riskFactors}
            />
          );
        } else if (header.id === "RISK_SCORE") {
          return (
            <RiskScoreCell
              key={`${header.id}-${row?.id}`}
              riskScore={row?.riskScore || 0}
            />
          );
        } else if (header.id === "FIRST_FOUND") {
          return (
            <FirstFoundCell
              key={`${header.id}-${row?.id}`}
              discoveryTime={row?.firstDetectedAt}
            />
          );
        } else if (header.id === "DATE") {
          return (
            <DateCell
              key={`${header.id}-${row?.id}`}
              date={row?.firstDetectedAt}
            />
          );
        } else if (header.id === "LAST_UPDATED") {
          // TODO: Add update to mesh with first found cell
          return (
            <FirstFoundCell
              key={`${header.id}-${row?.id}`}
              discoveryTime={row?.lastScannedAt}
            />
          );
        } else if (header.id === "ASSETS_COUNT") {
          return (
            <AssetsCountCell
              key={`${header.id}-${row?.id}`}
              count={row?.blastRadius?.assets || 0}
            />
          );
        }
        return <div></div>;
      })}
    </TableRow>
  );
}
