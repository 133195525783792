import { ReactNode } from "react";
import { TableCell, TableRow } from "@mui/material";

export type EmptyRowProps = {
  children: ReactNode;
  colSpan?: number;
  id?: string;
};

export function EmptyRow({ children, colSpan, id }: EmptyRowProps) {
  return (
    <TableRow
      id={id}
      className="empty-row"
      sx={{
        height: 300,
        border: (theme) => `2px dashed ${theme.palette.background.lightest}`,
        "&:hover": {
          backgroundColor: "unset",
        },
      }}
    >
      <TableCell colSpan={colSpan} sx={{ py: 0, maxWidth: 0 }} align="center">
        {children}
      </TableCell>
    </TableRow>
  );
}
