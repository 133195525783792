import React, { useRef, useState } from "react";
import { FormControl, Typography } from "@mui/material";
import { Controller, UseFormRegister } from "react-hook-form";
import { AzureDevopsProject } from "~/operations";
import {
  Autocomplete,
  DropdownAutocomplete,
} from "~/components/Form/components";
import { Control, FormState } from "react-hook-form/dist/types/form";
import { CreateCasesFormInput } from "~/components/cases/components/CreateCaseModal/CreateCaseModal";
import { NotesField } from "~/components/cases/components/CreateCaseModal/NotesField";
import { TitleField } from "~/components/cases/components/CreateCaseModal/TitleField";

type CreateCaseModalProps = {
  hasCreateCasePermissions: boolean;
  register: UseFormRegister<CreateCasesFormInput>;
  control: Control<CreateCasesFormInput>;
  formState: FormState<CreateCasesFormInput>;
  isProjectsEmpty: boolean;
  projects: AzureDevopsProject[] | null | undefined;
  saveSelectedProject: (value: string) => void;
  fetchProjects: (value: string) => void;
};

export const CreateCaseAzureModal = ({
  hasCreateCasePermissions,
  control,
  register,
  formState,
  projects,
  isProjectsEmpty,
  saveSelectedProject,
  fetchProjects,
}: CreateCaseModalProps) => {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = useState(false);
  const [projectQuery, setProjectQuery] = useState("");

  const handleToggle = () => {
    setIsAutocompleteOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <FormControl fullWidth>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ mb: 1, fontWeight: 700 }}
        >
          Project
        </Typography>

        <Controller
          key="projectId"
          name="azure.projectId"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            const getDisplayValue = () => {
              const selectedProject = projects?.find(
                (project) => project.name === field.value,
              );
              const selectedProjectTitle = selectedProject?.name;

              if (projectQuery.length > 0 && isProjectsEmpty) {
                return <>No projects match the search criteria</>;
              }

              if (isProjectsEmpty) {
                return (
                  <>No projects available. Please create one in Azure DevOps.</>
                );
              }

              if (!field.value || !selectedProjectTitle) {
                return <>Select a project</>;
              }

              return <>{selectedProjectTitle}</>;
            };

            return (
              <>
                <DropdownAutocomplete
                  open={isAutocompleteOpen}
                  anchorRef={anchorRef}
                  selected={getDisplayValue()}
                  onToggle={handleToggle}
                  data-testid="project-dropdown"
                />
                <Autocomplete
                  options={(projects || []).map(({ name }) => ({
                    value: name,
                    label: name,
                  }))}
                  scope="projects"
                  selected={field.value}
                  onSelect={(option) => {
                    saveSelectedProject(option);
                    field.onChange(option);
                    setIsAutocompleteOpen(false);
                  }}
                  open={isAutocompleteOpen}
                  onClose={() => setIsAutocompleteOpen(false)}
                  anchorRef={anchorRef}
                  onSearchInputChange={(e) => {
                    setProjectQuery(e.target.value);
                    fetchProjects(e.target.value);
                  }}
                />
              </>
            );
          }}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <TitleField
          textFieldProps={register("azure.title", { required: true })}
          errorMessage={formState.errors?.azure?.title?.message}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
      <FormControl fullWidth sx={{ marginTop: 3 }}>
        <NotesField
          errorMessage={formState.errors.azure?.notes?.message}
          textFieldProps={register("azure.notes")}
          disabled={!hasCreateCasePermissions}
        />
      </FormControl>
    </>
  );
};
