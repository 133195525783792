import { createSvgIcon } from "@mui/material";

export const GitLabIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 380 380"
    fill="currentColor"
  >
    <path d="M357.24,155.29l-.48-1.24L309.66,31.14a12.22,12.22,0,0,0-4.84-5.83,12.37,12.37,0,0,0-18.59,7.11l-31.8,97.29H125.67L93.87,32.42a12.38,12.38,0,0,0-4.18-6.36,12.61,12.61,0,0,0-14.41-.77,12.38,12.38,0,0,0-4.85,5.84L23.25,154l-.47,1.24a87.44,87.44,0,0,0,29,101.07l.16.12.43.31,71.74,53.72,35.49,26.87,21.62,16.32a14.55,14.55,0,0,0,17.58,0l21.62-16.32,35.49-26.87,72.17-54,.18-.15A87.47,87.47,0,0,0,357.24,155.29Z" />
  </svg>,
  "GitLab",
);
