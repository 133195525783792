import { Typography } from "@mui/material";

export const FormattedRecipient = ({
  name,
  email,
}: {
  name: string;
  email: string;
}) => {
  return (
    <Typography>
      {name}{" "}
      <Typography component="span" variant="caption" color="text.secondary">
        ({email})
      </Typography>
    </Typography>
  );
};
