export const jenkinsInstructions = {
  title: "Jenkins",
  tagline: "PIPELINE MONITORING FOR JENKINS PROJECTS",
  flags: [
    { id: "ci_cd", title: "CI/CD" },
    { id: "shift_left", title: "Shift Left" },
  ],
  instructions: [
    { id: 1, tagline: "Copy the Mondoo Platform credentials", token: true },
    {
      id: 2,
      tagline: "Securely store the credentials",
      markdownText:
        "In your Jenkins server, generate a new <a href='https://www.jenkins.io/doc/pipeline/tour/environment/' target='_blank' rel='noopener'>CI/CD variable</a>. Name the environment variable `MONDOO_CONFIG_BASE64`, and set the value to your generated credentials. To learn more, read the <a href='https://www.jenkins.io/doc/' target='_blank' rel='noopener'>Jenkins documentation</a>.",
    },
    {
      id: 3,
      tagline: "Use cnspec in your Jenkins pipelines",
      markdownText:
        "Now that you have Mondoo credentials for running CI security scans, you can add cnspec steps to Jenkins pipelines using your Jenkinsfile. You can use the example below or customize it to suit your needs. To learn more, read the <a href='https://mondoo.com/docs/platform/infra/supply/cicd/jenkins/' target='_blank' rel='noopener'>Mondoo documentation</a> and the <a href='https://www.jenkins.io/doc/' target='_blank' rel='noopener'>Jenkins documentation</a>.",
      configExamples: [
        {
          id: "docker_config", //jenkins docker config?
          config:
            "```javascript\npipeline {\n\
      environment {\n\
        REGISTRY = \"jenkins-docker-example\"\n\
      }\n\
      agent any\n\
      stages {\n\
        stage('Cloning Git Repository') {\n\
          steps {\n\
            // be sure to change this to your project repository\n\
            git 'https://github.com/lunalectric/backend.git'\n\
          }\n\
        }\n\
        stage('Building image') {\n\
          steps{\n\
            script {\n\
              dockerImage = docker.build(\"${REGISTRY}:${env.BUILD_ID}\")\n\
            }\n\
          }\n\
        }\n\
        stage('Scan image') {\n\
          environment {\n\
            MONDOO_CONFIG_BASE64 = credentials('MONDOO_CONFIG_BASE64')\n\
          }\n\
          steps{\n\
            sh 'bash -c \"$(curl -sSL https://install.mondoo.com/sh)\"'\n\
            sh './cnspec version'\n\
            // be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
            sh \"./cnspec scan docker ${REGISTRY}:${env.BUILD_ID} --score-threshold 90\"\n\
          }\n\
        }\n\
        stage('Deploy Image') {\n\
          // For a Docker Registry which requires authentication,\n\
          // add a \"Username/Password\" Credentials intro-item from the Jenkins home page and use the\n\
          // Credentials ID as a second argument to withRegistry():\n\
          environment {\n\
            REGISTRY_CREDS = credentials('REGISTRY_CREDS')\n\
          }\n\
          steps{\n\
            script {\n\
              docker.withRegistry( '', REGISTRY_CREDS ) {\n\
                dockerImage.push()\n\
              }\n\
            }\n\
          }\n\
        }\n\
        stage('Remove Unused docker image') {\n\
          steps{\n\
            sh \"docker rmi ${REGISTRY}:${env.BUILD_ID}\"\n\
          }\n\
        }\n\
      }\n\
    }```",
        },
        {
          id: "kubernetes_config",
          config:
            "```javascript\npipeline {\n\
      agent any\n\
      stages {\n\
        stage('Cloning Git Repository') {\n\
          steps {\n\
            // be sure to change this to your project repository\n\
            git 'https://github.com/lunalectric/backend.git'\n\
          }\n\
        }\n\
        stage('Scan Manifest') {\n\
          environment {\n\
            MONDOO_CONFIG_BASE64 = credentials('MONDOO_CONFIG_BASE64')\n\
          }\n\
          steps{\n\
            sh 'bash -c \"$(curl -sSL https://install.mondoo.com/sh)\"'\n\
            sh './cnspec version'\n\
            // be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
            sh \"./cnspec scan k8s MY_MANIFEST_FILE.yml --score-threshold 90\"\n\
          }\n\
        }\n\
      }\n\
    }```",
        },
        {
          id: "terraform_config",
          config:
            "```javascript\npipeline {\n\
      agent any\n\
      stages {\n\
        stage('Cloning Git Repository') {\n\
          steps {\n\
            // be sure to change this to your project repository\n\
            git 'https://github.com/lunalectric/backend.git'\n\
          }\n\
        }\n\
        stage('Scan Terraform') {\n\
          environment {\n\
            MONDOO_CONFIG_BASE64 = credentials('MONDOO_CONFIG_BASE64')\n\
          }\n\
          steps{\n\
            sh 'bash -c \"$(curl -sSL https://install.mondoo.com/sh)\"'\n\
            sh './cnspec version'\n\
            // be sure to change the score-threshold value to control the minimum accepted asset score before CI jobs fail\n\
            sh \"./cnspec scan terraform my_tf_config.tf --score-threshold 90\"\n\
          }\n\
        }\n\
      }\n\
    }```",
        },
      ],
    },
  ],
};
