import { createSvgIcon } from "@mui/material";

export const NodejsIconBW = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    fill="currentColor"
  >
    <path d="M224 508c-6.7 0-13.5-1.8-19.4-5.2l-61.7-36.5c-9.2-5.2-4.7-7-1.7-8 12.3-4.3 14.8-5.2 27.9-12.7 1.4-.8 3.2-.5 4.6.4l47.4 28.1c1.7 1 4.1 1 5.7 0l184.7-106.6c1.7-1 2.8-3 2.8-5V149.3c0-2.1-1.1-4-2.9-5.1L226.8 37.7c-1.7-1-4-1-5.7 0L36.6 144.3c-1.8 1-2.9 3-2.9 5.1v213.1c0 2 1.1 4 2.9 4.9l50.6 29.2c27.5 13.7 44.3-2.4 44.3-18.7V167.5c0-3 2.4-5.3 5.4-5.3h23.4c2.9 0 5.4 2.3 5.4 5.3V378c0 36.6-20 57.6-54.7 57.6-10.7 0-19.1 0-42.5-11.6l-48.4-27.9C8.1 389.2.7 376.3.7 362.4V149.3c0-13.8 7.4-26.8 19.4-33.7L204.6 9c11.7-6.6 27.2-6.6 38.8 0l184.7 106.7c12 6.9 19.4 19.8 19.4 33.7v213.1c0 13.8-7.4 26.7-19.4 33.7L243.4 502.8c-5.9 3.4-12.6 5.2-19.4 5.2zm149.1-210.1c0-39.9-27-50.5-83.7-58-57.4-7.6-63.2-11.5-63.2-24.9 0-11.1 4.9-25.9 47.4-25.9 37.9 0 51.9 8.2 57.7 33.8.5 2.4 2.7 4.2 5.2 4.2h24c1.5 0 2.9-.6 3.9-1.7s1.5-2.6 1.4-4.1c-3.7-44.1-33-64.6-92.2-64.6-52.7 0-84.1 22.2-84.1 59.5 0 40.4 31.3 51.6 81.8 56.6 60.5 5.9 65.2 14.8 65.2 26.7 0 20.6-16.6 29.4-55.5 29.4-48.9 0-59.6-12.3-63.2-36.6-.4-2.6-2.6-4.5-5.3-4.5h-23.9c-3 0-5.3 2.4-5.3 5.3 0 31.1 16.9 68.2 97.8 68.2 58.4-.1 92-23.2 92-63.4z" />
  </svg>,
  "Nodejs",
);

export const NodejsIcon = createSvgIcon(
  <svg viewBox="0 0 22 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0202 0.303765C10.6127 -0.0353843 11.3844 -0.0369162 11.9764 0.303765C14.9547 1.98666 17.9339 3.66691 20.9117 5.35083C21.4718 5.66632 21.8465 6.28964 21.8407 6.935V17.0599C21.8449 17.732 21.4333 18.3702 20.8424 18.6786C17.8739 20.3522 14.9069 22.0278 11.9389 23.7015C11.3341 24.0473 10.5469 24.0206 9.961 23.6465C9.07105 23.1306 8.17956 22.6172 7.28951 22.1018C7.10764 21.9934 6.90262 21.9071 6.7742 21.7307C6.88773 21.5777 7.09071 21.5587 7.25564 21.4918C7.62713 21.3737 7.96832 21.1841 8.3096 21.0001C8.3959 20.941 8.50126 20.9637 8.58398 21.0165C9.345 21.4529 10.0993 21.9019 10.8629 22.3341C11.0258 22.4281 11.1907 22.3033 11.3299 22.2256C14.243 20.5793 17.1596 18.9391 20.0721 17.2922C20.18 17.2403 20.2396 17.1257 20.2309 17.0075C20.2329 13.6675 20.2314 10.3269 20.2319 6.98691C20.2442 6.85279 20.1666 6.72947 20.0453 6.67457C17.0871 5.00862 14.1305 3.34011 11.1727 1.67372C11.1215 1.63849 11.0608 1.61959 10.9986 1.6195C10.9364 1.61941 10.8757 1.63813 10.8243 1.67321C7.86662 3.34011 4.91045 5.01015 1.95275 6.67602C1.8319 6.731 1.75079 6.85228 1.76517 6.98691C1.76568 10.3269 1.76517 13.6675 1.76517 17.008C1.76005 17.0657 1.77271 17.1236 1.80147 17.1738C1.83023 17.2241 1.8737 17.2643 1.92603 17.2891C2.7153 17.7367 3.5056 18.1811 4.29539 18.6272C4.74032 18.8667 5.28662 19.009 5.77683 18.8255C6.20943 18.6703 6.51266 18.2289 6.50441 17.7696C6.50849 14.449 6.50237 11.128 6.50747 7.80802C6.49666 7.66062 6.63649 7.53883 6.77981 7.5527C7.15905 7.55015 7.53879 7.54759 7.91803 7.55372C8.07632 7.55015 8.18526 7.70887 8.16568 7.85738C8.16415 11.1989 8.16977 14.5405 8.16313 17.8821C8.16415 18.7726 7.79828 19.7416 6.97454 20.1774C5.95973 20.7031 4.70543 20.5916 3.70288 20.0875C2.83496 19.6543 2.00671 19.1431 1.1542 18.679C0.56177 18.3724 0.152153 17.7316 0.156323 17.06V6.935C0.15011 6.27628 0.539642 5.64164 1.11717 5.33074C4.08517 3.65576 7.05266 1.97951 10.0202 0.303765Z"
      fill="#8CC84B"
    />
    <path
      d="M12.6106 7.31816C13.9051 7.23484 15.2909 7.2688 16.4557 7.9065C17.3576 8.39518 17.8576 9.4208 17.8735 10.4228C17.8483 10.5579 17.707 10.6325 17.578 10.6232C17.2024 10.6227 16.8268 10.6283 16.4512 10.6206C16.2919 10.6268 16.1993 10.4799 16.1793 10.339C16.0714 9.85961 15.8099 9.3848 15.3587 9.15356C14.666 8.80675 13.8629 8.8242 13.1075 8.83144C12.5561 8.86071 11.9632 8.90846 11.496 9.23271C11.1374 9.47833 11.0285 9.97416 11.1565 10.3735C11.2772 10.6602 11.6081 10.7527 11.8789 10.838C13.4389 11.246 15.092 11.2054 16.6223 11.7423C17.2558 11.9612 17.8755 12.3868 18.0924 13.0501C18.376 13.9391 18.2517 15.0017 17.6192 15.7154C17.1063 16.3028 16.3592 16.6224 15.6141 16.796C14.6229 17.0171 13.5942 17.0227 12.5875 16.9245C11.641 16.8166 10.656 16.5679 9.92524 15.923C9.30038 15.3805 8.99519 14.5351 9.02549 13.7192C9.03273 13.5814 9.16992 13.4853 9.30192 13.4966C9.68013 13.4936 10.0583 13.4925 10.4366 13.4971C10.5876 13.4863 10.6996 13.6169 10.7074 13.7593C10.7772 14.216 10.9488 14.6954 11.3471 14.9662C12.1158 15.4622 13.0803 15.4282 13.9605 15.4421C14.6897 15.4097 15.5082 15.3999 16.1033 14.918C16.4172 14.6431 16.5103 14.1832 16.4254 13.7875C16.3335 13.4535 15.984 13.2978 15.684 13.196C14.144 12.7089 12.4724 12.8856 10.9473 12.3348C10.3281 12.1159 9.72941 11.7022 9.49153 11.0662C9.15962 10.1659 9.3117 9.05237 10.0105 8.36275C10.6919 7.6768 11.6754 7.41271 12.6106 7.31816Z"
      fill="#8CC84B"
    />
  </svg>,
  "Nodejs",
);
