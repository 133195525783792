import { createSvgIcon } from "@mui/material";

export const SlackColorIcon = createSvgIcon(
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.61922 14.8968C5.61922 16.1691 4.58185 17.2064 3.30961 17.2064C2.03737 17.2064 1 16.1691 1 14.8968C1 13.6246 2.03737 12.5872 3.30961 12.5872H5.61922V14.8968Z"
      fill="#E01E5A"
    />
    <path
      d="M6.78418 14.8968C6.78418 13.6246 7.82155 12.5872 9.09379 12.5872C10.366 12.5872 11.4034 13.6246 11.4034 14.8968V20.6806C11.4034 21.9529 10.366 22.9902 9.09379 22.9902C7.82155 22.9902 6.78418 21.9529 6.78418 20.6806C6.78418 20.6904 6.78418 14.8968 6.78418 14.8968Z"
      fill="#E01E5A"
    />
    <path
      d="M9.10258 5.61922C7.83034 5.61922 6.79297 4.58185 6.79297 3.30961C6.79297 2.03737 7.83034 1 9.10258 1C10.3748 1 11.4122 2.03737 11.4122 3.30961V5.61922H9.10258Z"
      fill="#36C5F0"
    />
    <path
      d="M9.1032 6.78381C10.3754 6.78381 11.4128 7.82118 11.4128 9.09342C11.4128 10.3657 10.3657 11.4128 9.1032 11.4128H3.30961C2.03737 11.4128 1 10.3755 1 9.10321C1 7.83097 2.03737 6.7936 3.30961 6.7936C3.30961 6.78381 9.1032 6.78381 9.1032 6.78381Z"
      fill="#36C5F0"
    />
    <path
      d="M18.3818 9.10319C18.3818 7.83095 19.4192 6.79358 20.6914 6.79358C21.9637 6.79358 23.0011 7.83095 23.0011 9.10319C23.0011 10.3754 21.9637 11.4128 20.6914 11.4128H18.3818V9.10319Z"
      fill="#2EB67D"
    />
    <path
      d="M17.2169 9.1032C17.2169 10.3754 16.1795 11.4128 14.9073 11.4128C13.635 11.4128 12.5977 10.3754 12.5977 9.1032V3.30961C12.5977 2.03737 13.635 1 14.9073 1C16.1795 1 17.2169 2.03737 17.2169 3.30961V9.1032Z"
      fill="#2EB67D"
    />
    <path
      d="M14.8965 18.3808C16.1688 18.3808 17.2061 19.4182 17.2061 20.6904C17.2061 21.9626 16.1688 23 14.8965 23C13.6243 23 12.5869 21.9626 12.5869 20.6904V18.3808H14.8965Z"
      fill="#ECB22E"
    />
    <path
      d="M14.8965 17.2162C13.6243 17.2162 12.5869 16.1788 12.5869 14.9066C12.5869 13.6344 13.6243 12.597 14.8965 12.597H20.6803C21.9526 12.597 22.9899 13.6344 22.9899 14.9066C22.9899 16.1788 21.9526 17.2162 20.6803 17.2162H14.8965Z"
      fill="#ECB22E"
    />
  </svg>,
  "SlackColor",
);
