import UnlockFlagLink from "../UnlockFlagLink";
import FeaturedFlag from "../FeaturedFlag";
import ReviewFlag from "../ReviewFlag";
import { FlagTypes } from "../../../types";

type FlagsProps = {
  name: string;
};

const Flags = ({ name }: FlagsProps) => {
  if (name?.toLowerCase() === FlagTypes.featured?.toLowerCase()) {
    return <FeaturedFlag />;
  } else if (name?.toLowerCase() === FlagTypes.unlock?.toLowerCase()) {
    return <UnlockFlagLink url="https://console.mondoo.com" />;
  } else if (name?.toLowerCase() === FlagTypes.review?.toLowerCase()) {
    return <ReviewFlag />;
  }

  return null;
};

export default Flags;
