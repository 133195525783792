import { createSvgIcon } from "@mui/material";

export const ChecklistIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 18" fill="none">
    <path
      d="M17.0802 1.3C17.1275 1.3 17.1743 1.30931 17.218 1.3274C17.2617 1.3455 17.3014 1.37201 17.3348 1.40544C17.3682 1.43887 17.3947 1.47856 17.4128 1.52223C17.4309 1.56591 17.4402 1.61272 17.4402 1.66V16.34C17.4402 16.4355 17.4023 16.527 17.3348 16.5946C17.2673 16.6621 17.1757 16.7 17.0802 16.7H2.40023C2.34971 16.7057 2.29856 16.7005 2.25016 16.685C2.20175 16.6694 2.15719 16.6438 2.1194 16.6098C2.08161 16.5758 2.05146 16.5342 2.03092 16.4877C2.01038 16.4412 1.99992 16.3908 2.00023 16.34V1.66C1.99992 1.60916 2.01038 1.55883 2.03092 1.51233C2.05146 1.46582 2.08161 1.42419 2.1194 1.39018C2.15719 1.35617 2.20175 1.33055 2.25016 1.31501C2.29856 1.29947 2.34971 1.29435 2.40023 1.3H17.0802ZM17.0802 0H2.40023C1.95998 0 1.53775 0.174892 1.22644 0.486203C0.915127 0.797513 0.740234 1.21974 0.740234 1.66V16.34C0.740234 16.7803 0.915127 17.2025 1.22644 17.5138C1.53775 17.8251 1.95998 18 2.40023 18H17.0802C17.5205 18 17.9427 17.8251 18.254 17.5138C18.5653 17.2025 18.7402 16.7803 18.7402 16.34V1.66C18.7402 1.21974 18.5653 0.797513 18.254 0.486203C17.9427 0.174892 17.5205 0 17.0802 0Z"
      fill="currentColor"
    />
    <path
      d="M15.0903 5.02002H11.2003C11.1174 5.02002 11.0503 5.08718 11.0503 5.17002V6.17002C11.0503 6.25286 11.1174 6.32002 11.2003 6.32002H15.0903C15.1731 6.32002 15.2403 6.25286 15.2403 6.17002V5.17002C15.2403 5.08718 15.1731 5.02002 15.0903 5.02002Z"
      fill="currentColor"
    />
    <path
      d="M15.0903 11.3201H11.2003C11.1174 11.3201 11.0503 11.3872 11.0503 11.4701V12.4701C11.0503 12.5529 11.1174 12.6201 11.2003 12.6201H15.0903C15.1731 12.6201 15.2403 12.5529 15.2403 12.4701V11.4701C15.2403 11.3872 15.1731 11.3201 15.0903 11.3201Z"
      fill="currentColor"
    />
    <path
      d="M7.51007 12L8.51007 11C8.57573 10.9422 8.62937 10.872 8.66792 10.7935C8.70647 10.715 8.72918 10.6297 8.73475 10.5424C8.74032 10.4551 8.72865 10.3676 8.70039 10.2848C8.67213 10.202 8.62785 10.1256 8.57007 10.06C8.51229 9.9943 8.44214 9.94066 8.36363 9.90211C8.28512 9.86356 8.19978 9.84085 8.1125 9.83528C8.02521 9.82971 7.93768 9.84138 7.8549 9.86964C7.77213 9.8979 7.69573 9.94218 7.63007 9.99996L6.63007 11L5.63007 9.99996C5.57039 9.93827 5.49891 9.88921 5.41989 9.8557C5.34086 9.8222 5.2559 9.80493 5.17007 9.80493C5.08424 9.80493 4.99928 9.8222 4.92025 9.8557C4.84123 9.88921 4.76975 9.93827 4.71007 9.99996C4.59034 10.1232 4.52336 10.2882 4.52336 10.46C4.52336 10.6317 4.59034 10.7968 4.71007 10.92L5.71007 11.92L4.71007 12.92C4.64966 12.9804 4.60174 13.0521 4.56905 13.131C4.53636 13.2099 4.51953 13.2945 4.51953 13.38C4.51953 13.4654 4.53636 13.55 4.56905 13.6289C4.60174 13.7078 4.64966 13.7796 4.71007 13.84C4.83207 13.962 4.99754 14.0305 5.17007 14.0305C5.2555 14.0305 5.34009 14.0137 5.41902 13.981C5.49795 13.9483 5.56966 13.9004 5.63007 13.84L6.63007 12.79L7.63007 13.84C7.75326 13.9597 7.91828 14.0267 8.09007 14.0267C8.26186 14.0267 8.42688 13.9597 8.55007 13.84C8.61176 13.7803 8.66082 13.7088 8.69433 13.6298C8.72783 13.5508 8.7451 13.4658 8.7451 13.38C8.7451 13.2941 8.72783 13.2092 8.69433 13.1301C8.66082 13.0511 8.61176 12.9796 8.55007 12.92L7.51007 12Z"
      fill="currentColor"
    />
    <path
      d="M9.19035 3.70992C9.13067 3.64823 9.0592 3.59917 8.98017 3.56566C8.90115 3.53216 8.81619 3.51489 8.73035 3.51489C8.64452 3.51489 8.55956 3.53216 8.48053 3.56566C8.40151 3.59917 8.33003 3.64823 8.27035 3.70992L5.72035 6.25992L4.91035 5.44992C4.7851 5.34779 4.62629 5.29599 4.4649 5.30462C4.30352 5.31325 4.15114 5.38169 4.03749 5.4966C3.92383 5.6115 3.85706 5.76462 3.8502 5.92608C3.84333 6.08755 3.89686 6.24579 4.00035 6.36992L5.27035 7.63992C5.39302 7.76086 5.5581 7.82904 5.73035 7.82992C5.90178 7.82508 6.06547 7.75747 6.19035 7.63992L9.19035 4.63992C9.3131 4.51629 9.38198 4.34914 9.38198 4.17492C9.38198 4.0007 9.3131 3.83355 9.19035 3.70992Z"
      fill="currentColor"
    />
  </svg>,
  "Checklist",
);
