import { tableFromIPC } from "apache-arrow";

import { useGetMetricsQuery } from "~/operations";
import { getColor } from "~/lib/colors";
import { ChartData } from "~/components/charts/donut-chart";
import { useTheme } from "@mui/material";
import { GridData } from "~/pages/dashboard/grid-segment";
import { SmallDonutData } from "~/components/SmallDonut";

type UseMetricsParams = {
  entityMrn: string;
  metricMrns: Array<Metrics>;
  skip?: boolean;
};

export enum Metrics {
  // provides a count of policies by category for a given asset or space
  PolicyCategoryDistribution = "//metrics.api.mondoo.app/categories/policies",
  // provides a distribution of policy grades for a given asset or space. For spaces, this is the distribution of the average grades
  PolicyGradeDistribution = "//metrics.api.mondoo.app/distribution/policies",
  // provides a distribution of check severities for a given asset or space
  ChecksResultDistribution = "//metrics.api.mondoo.app/distribution/checks",
  // provides a count of vulnerable assets by severity for a given space
  VulnerableAssetsSeverity = "//metrics.api.mondoo.app/severity/assets/vulns",
  // provides a count of vulnerabilities by severity for a given space
  AdvisoriesSeverity = "//metrics.api.mondoo.app/severity/advisories",
  // provides a count of vulnerabilities by severity for a given space
  CVEsSeverity = "//metrics.api.mondoo.app/severity/vulns",
  // provides stats of remediation severities for a given space
  RemediationSeverity = "//metrics.api.mondoo.app/severity/remediation",
  // provides stats of remediation severities for a given space
  MTTRSeverity = "//metrics.api.mondoo.app/severity/remediation",
  // provides a count of assets by grade in a given space
  SecurityAssetsStats = "//metrics.api.mondoo.app/severity/assets/security",
}

type PolicyCategory = {
  category: string;
  count: number;
};

type Severity = "none" | "low" | "medium" | "high" | "critical";

type PolicyGrade = {
  grade: "a" | "b" | "c" | "d" | "f" | "errored" | "unrated";
  count: number;
};

type CheckResult = {
  errored: number;
  failing: number;
  passing: number;
  unscored: number;
  severity: Severity;
};

type VulnerableAssetSeverity = {
  severity: Severity;
  count: number;
};

type AdvisorySeverity = {
  severity: Severity;
  count: number;
};

type CVESeverity = {
  severity: Severity;
  count: number;
};

type RemediationSeverity = {
  fixed: number;
  mttr_seconds: number;
  severity: Severity;
  total: number;
};

type MttrObject = {
  [category: string]: {
    days: number;
    hours: number;
    minutes: number;
  };
};

type CheckResultType = {
  errored: number;
  failing: number;
  passing: number;
  severity: Severity;
  skipped: number;
  total: number;
  unscored: number;
};

export function useMetrics({ entityMrn, metricMrns }: UseMetricsParams) {
  const theme = useTheme();

  const { data, loading, error } = useGetMetricsQuery({
    variables: {
      entityMrn,
      metricMrns,
    },
  });

  function getBaseMetrics(metrics: Metrics) {
    const metricsEntry = data?.metrics?.entries?.find(
      (m) => m.metricMrn === metrics,
    );

    if (!metricsEntry) return [];

    const binaryData = Uint8Array.from(
      atob(metricsEntry.arrowResult || ""),
      (c) => c.charCodeAt(0),
    );

    const tableData = tableFromIPC(binaryData).toArray();
    return tableData.map((e) => {
      return e.toJSON();
    });
  }

  function getPolicyCategories(): Array<GridData> {
    const policyCategories: Array<PolicyCategory> = getBaseMetrics(
      Metrics.PolicyCategoryDistribution,
    );

    return policyCategories.map((c) => ({
      title: c.category,
      score: c.count,
    }));
  }

  function getPolicyGrades(): Array<ChartData> {
    const policyGradesMetrics: Array<PolicyGrade> = getBaseMetrics(
      Metrics.PolicyGradeDistribution,
    );

    return policyGradesMetrics.map((gradeMetrics) => {
      const grade = gradeMetrics.grade.toUpperCase();

      if (grade === "ERRORED") {
        return {
          label: "ERROR",
          value: gradeMetrics.count,
          color: getColor(theme, "error"),
        };
      }

      return {
        label: grade,
        value: gradeMetrics.count,
        color: getColor(theme, grade),
      };
    });
  }

  function getChecksResults(): Array<CheckResult> {
    const checkResults = getBaseMetrics(Metrics.ChecksResultDistribution);
    return checkResults.reduce((acc, item) => {
      return {
        ...acc,
        [item.severity]: item,
      };
    }, {});
  }

  function getVulnerableAssetsSeverity(): SmallDonutData {
    const vulnerableAssetSeverities: Array<VulnerableAssetSeverity> =
      getBaseMetrics(Metrics.VulnerableAssetsSeverity);

    const total = vulnerableAssetSeverities.reduce((acc, current) => {
      return acc + current.count;
    }, 0);

    return {
      total,
      context: vulnerableAssetSeverities.map((s) => ({
        impact: s.severity,
        value: s.count,
      })),
    };
  }

  function getAdvisoriesSeverity(): SmallDonutData {
    const advisoriesSeverities: Array<AdvisorySeverity> = getBaseMetrics(
      Metrics.AdvisoriesSeverity,
    );

    const total = advisoriesSeverities.reduce((acc, current) => {
      return acc + current.count;
    }, 0);

    return {
      total,
      context: advisoriesSeverities.map((s) => ({
        impact: s.severity,
        value: s.count,
      })),
    };
  }

  function getCVEsSeverity(): SmallDonutData {
    const cvesSeverities: Array<CVESeverity> = getBaseMetrics(
      Metrics.CVEsSeverity,
    );

    const total = cvesSeverities.reduce((acc, current) => {
      return acc + current.count;
    }, 0);

    return {
      total,
      context: cvesSeverities.map((s) => ({
        impact: s.severity,
        value: s.count,
      })),
    };
  }

  function getRemediationSeverity(): Array<GridData> {
    const remediationSeverity: Array<RemediationSeverity> = getBaseMetrics(
      Metrics.RemediationSeverity,
    );

    return remediationSeverity.map((c) => ({
      title: c.severity,
      score: c.fixed,
      total: c.total,
    }));
  }

  function getMTTRSeverity(): MttrObject {
    const mttrSeverity: Array<RemediationSeverity> = getBaseMetrics(
      Metrics.MTTRSeverity,
    );

    const defaultData: MttrObject = {
      none: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
      low: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
      medium: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
      high: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
      critical: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
    };

    return mttrSeverity.reduce((acc, data) => {
      const SECONDS_IN_MINUTE = 60n;
      const SECONDS_IN_HOUR = 60n * SECONDS_IN_MINUTE;
      const SECONDS_IN_DAY = 24n * SECONDS_IN_HOUR;

      // Calculate the number of days
      const days = BigInt(data.mttr_seconds) / SECONDS_IN_DAY;
      // Calculate the number of hours
      const hours =
        (BigInt(data.mttr_seconds) % SECONDS_IN_DAY) / SECONDS_IN_HOUR;

      // Calculate the number of minutes
      const minutes =
        (BigInt(data.mttr_seconds) % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE;

      return {
        ...acc,
        [data.severity]: {
          days: Number(days),
          hours: Number(hours),
          minutes: Number(minutes),
        },
      };
    }, defaultData);
  }

  function getAssetsGrades(): Array<ChartData> {
    const assetsGradesMetrics: Array<PolicyGrade> = getBaseMetrics(
      Metrics.SecurityAssetsStats,
    );

    return assetsGradesMetrics.map((gradeMetrics) => {
      const grade = gradeMetrics.grade.toUpperCase();

      if (grade === "ERRORED") {
        return {
          label: "ERROR",
          value: gradeMetrics.count,
          color: getColor(theme, "error"),
        };
      }

      return {
        label: grade,
        value: gradeMetrics.count,
        color: getColor(theme, grade),
      };
    });
  }

  function getPassedCheckStats(): Array<GridData> {
    const checksPassedStats: Array<CheckResultType> = getBaseMetrics(
      Metrics.ChecksResultDistribution,
    );

    return checksPassedStats
      .filter((c) => c.severity !== "none")
      .map((c) => ({
        title: c.severity,
        score: c.passing,
        total: c.total,
      }));
  }

  return {
    isLoading: loading,
    error,
    policyCategories: getPolicyCategories(),
    policyGrades: getPolicyGrades(),
    checksResults: getChecksResults(),
    vulnerableAssetsSeverity: getVulnerableAssetsSeverity(),
    advisoriesSeverity: getAdvisoriesSeverity(),
    cvesSeverity: getCVEsSeverity(),
    remediationSeverity: getRemediationSeverity(),
    mttrSeverity: getMTTRSeverity(),
    assetsGrades: getAssetsGrades(),
    passedChecks: getPassedCheckStats(),
  };
}
