import { SxProps, Theme, useTheme } from "@mui/material";
import { MetricBox } from "./metric-box";
import { useEpssRisk, EpssMetric } from "./useEpssRisk";
import { CvssScoreType } from "~/lib/score";

export type EpssMetricBoxProps = {
  metric: EpssMetric;
  sx?: SxProps<Theme>;
};

export function EpssMetricBox({ sx, metric }: EpssMetricBoxProps) {
  const risk = useEpssRisk();
  const theme = useTheme();
  const { label, formattedValue, description } = metric;
  const color = theme.palette[CvssScoreType(risk.cvssScore)].main;
  const highlighted = risk.isFocused(metric);
  return (
    <MetricBox
      label={label}
      value={formattedValue}
      description={description}
      color={color}
      highlighted={highlighted}
      onMouseEnter={() => risk.onMetricMouseEnter(metric)}
      onMouseLeave={() => risk.onMetricMouseLeave(metric)}
      onFocus={() => risk.onMetricFocus(metric)}
      onBlur={() => risk.onMetricBlur(metric)}
      sx={sx}
    />
  );
}
