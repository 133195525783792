// Code is heavily inspired from https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
function fallbackCopyToClipboard(text: string) {
  // Check if there is anything selected
  let selection = document.getSelection();
  const selected =
    selection != null && selection.rangeCount > 0
      ? selection.getRangeAt(0)
      : false;

  // Copy data into clipboard
  const el = document.createElement("textarea");
  el.value = text;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  el.select();

  try {
    // Attempt copy command
    return document.execCommand("copy") ? Promise.resolve() : Promise.reject();
  } catch (err) {
    return Promise.reject(err);
  } finally {
    document.body.removeChild(el);
    // Restore selection
    selection = document.getSelection();
    if (selection && selected) {
      selection.removeAllRanges();
      selection.addRange(selected);
    }
  }
}

export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    return fallbackCopyToClipboard(text);
  }
  return navigator.clipboard.writeText(text);
}
