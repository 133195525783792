import { FieldLabel } from "~/pages/space/Workspaces/components/FormFields/FieldLabel";
import { Grid2 as Grid, TextField } from "@mui/material";
import React from "react";
import { UseFormRegister } from "react-hook-form";
import {
  CreateFormInput,
  TargetType,
} from "~/pages/space/Workspaces/components/FormFields/types";

type DescriptionFieldProps = {
  register: UseFormRegister<CreateFormInput>;
  disabled?: boolean;
  target: TargetType;
};

export const DescriptionField = ({
  register,
  disabled = false,
  target,
}: DescriptionFieldProps) => {
  return (
    <Grid size={{ xs: 12 }} container gap={1}>
      <FieldLabel
        id="description-input"
        label="Description"
        caption={`Help users understand the purpose of this ${target}, i.e., what it's supposed to be used for or what it contains.`}
        optional
      />
      <TextField
        id="description-input"
        fullWidth
        multiline
        minRows={3}
        placeholder="Description"
        variant="outlined"
        disabled={disabled}
        slotProps={{
          input: {
            sx: { backgroundColor: "code.background" },
          },
        }}
        {...register("description")}
      />
    </Grid>
  );
};
