import { SmallDonutCard } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { AdvisoriesIcon } from "~/components/icons";
import { Metrics, useMetrics } from "~/hooks";

export function AdvisoriesDonutChart({ space }: DonutChartCardProps) {
  const { advisoriesSeverity, isLoading } = useMetrics({
    entityMrn: space.mrn,
    metricMrns: [Metrics.AdvisoriesSeverity],
  });

  return (
    <SmallDonutCard
      to={`advisories?spaceId=${space.id}`}
      loading={isLoading}
      data={advisoriesSeverity}
      data-testid="advisories-donut-chart"
      title="Advisories"
      icon={<AdvisoriesIcon />}
    />
  );
}
