import { useState } from "react";
import {
  ExceptionGroup,
  ExceptionMutationAction,
  ExceptionReviewAction,
  GetComplianceControlDocument,
  GetComplianceFrameworkDocument,
  ListExceptionGroupsDocument,
  ReviewStatus,
  TestIamActionsQuery,
  useApplyExceptionMutation,
  useApplyExceptionReviewMutation,
} from "~/operations";
import { IamActions } from "~/lib/iam";
import { Space } from "~/lib/types";
import { useSnackbar } from "notistack";
import { getError } from "~/lib/handle-error";

type UseExceptionApprovalProps = {
  availablePermissions: TestIamActionsQuery["testIamActions"];
  exceptionGroup: ExceptionGroup;
  frameworkMrn: string;
  controlMrn: string;
  space: Space;
};

export function useExceptionApproval({
  availablePermissions,
  exceptionGroup,
  frameworkMrn,
  controlMrn,
  space,
}: UseExceptionApprovalProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [acceptLoading, setAcceptLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const hasApplyExceptionReviewPermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_APPLYEXCEPTIONREVIEWMUTATION,
  );

  const [applyExceptionReview, { loading: applyExceptionReviewLoading }] =
    useApplyExceptionReviewMutation();

  const handleReviewActionChange = async (action: ExceptionReviewAction) => {
    const completionAction =
      action === ExceptionReviewAction.Rejected
        ? ExceptionMutationAction.Enable
        : null;

    if (action === ExceptionReviewAction.Approved) {
      setAcceptLoading(true);
    }
    if (action === ExceptionReviewAction.Rejected) {
      setRejectLoading(true);
    }
    try {
      await applyExceptionReview({
        variables: {
          input: {
            scopeMrn: space.mrn,
            exceptionId: exceptionGroup.id,
            action,
          },
        },
        notifyOnNetworkStatusChange: true,
        awaitRefetchQueries: true,
        refetchQueries: [ListExceptionGroupsDocument],
        onCompleted: () => {
          if (completionAction) {
            handleControlActivationChange(completionAction);
          } else {
            setRejectLoading(false);
            setAcceptLoading(false);
          }
        },
      });
    } catch (error) {
      const message = getError(error);
      enqueueSnackbar(`Failed to apply exception review: ${message}`, {
        variant: "error",
      });
    }
  };

  const [applyException, { loading: applyExceptionLoading }] =
    useApplyExceptionMutation({
      refetchQueries: [
        ListExceptionGroupsDocument,
        {
          query: GetComplianceFrameworkDocument,
          variables: {
            input: {
              frameworkMrn,
              scopeMrn: space.mrn,
            },
          },
        },
        {
          query: GetComplianceControlDocument,
          variables: {
            input: {
              controlMrn: controlMrn,
              frameworkMrn,
              scopeMrn: space.mrn,
            },
          },
        },
      ],
    });

  const handleControlActivationChange = async (
    action: ExceptionMutationAction,
  ) => {
    if (action === ExceptionMutationAction.Enable) {
      setRejectLoading(true);
    }
    if (action === ExceptionMutationAction.Disable) {
      setAcceptLoading(true);
    }
    try {
      await applyException({
        variables: {
          input: {
            scopeMrn: space.mrn,
            controlMrns: [controlMrn],
            action,
          },
        },
        awaitRefetchQueries: true,
        notifyOnNetworkStatusChange: true,
        onCompleted: () => {
          setRejectLoading(false);
          setAcceptLoading(false);
        },
      });
    } catch (error) {
      const message = getError(error);
      enqueueSnackbar(`Failed to apply exception: ${message}`, {
        variant: "error",
      });
    }
  };

  const handleAccept = () => {
    handleReviewActionChange(ExceptionReviewAction.Approved);
  };

  const handleReject = () => {
    handleReviewActionChange(ExceptionReviewAction.Rejected);
  };

  const handleEdit = () => {
    // Todo: no API for this yet
  };

  const handleDelete = () => {
    // Todo: no API for this yet, here we are just rejecting the exception and turning the control back on
    handleReviewActionChange(ExceptionReviewAction.Rejected);
  };

  const handleOpenDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  return {
    deleteDialogOpen,
    hasReviewPermissions: hasApplyExceptionReviewPermission,
    status: {
      isSnoozed: exceptionGroup.action === ExceptionMutationAction.Snooze,
      isDisabled: exceptionGroup.action === ExceptionMutationAction.Disable,
      isApproved: exceptionGroup.reviewStatus === ReviewStatus.Approved,
      loading: applyExceptionLoading || applyExceptionReviewLoading,
      acceptLoading,
      rejectLoading,
    },
    handle: {
      accept: handleAccept,
      reject: handleReject,
      edit: handleEdit,
      delete: handleDelete,
      openDeleteDialog: handleOpenDeleteDialog,
      closeDeleteDialog: handleCloseDeleteDialog,
    },
  };
}
