import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Typography, Link } from "@mui/material";
import { Asset } from "~/pages/inventory/asset/types";
import { Field, Resource } from "~/operations";
import { ResourceIcon } from "./icons";
import { DynamicResourceIcon } from "./get-icon";

type DynamicTrailProps = {
  asset: Asset | null;
  columns: (Resource | Field)[];
  userTrail: string[];
};

export const DynamicTrail = ({
  asset,
  columns,
  userTrail,
}: DynamicTrailProps) => {
  //   const trail = columns.map((column) => column.name);

  return (
    <Breadcrumbs
      sx={{
        overflowWrap: "anywhere",
        ".MuiBreadcrumbs-separator": { mt: -0.25 },
      }}
      separator="›"
    >
      <Link
        key="link-1"
        component={RouterLink}
        to={window.location.pathname + window.location.search}
        display="flex"
        alignItems="center"
      >
        <ResourceIcon sx={{ mr: 1.25, fontSize: "14px" }} />
        <Typography variant="body2">{asset?.name}</Typography>
      </Link>
      {userTrail.map((t, i) => (
        <Link key={`trail-link-${i + 1}`} display="flex" alignItems="center">
          <DynamicResourceIcon
            sx={{ mr: 1.25, fontSize: "14px", color: "text.primary" }}
          />
          <Typography variant="body2" color="text.primary">
            {t}
          </Typography>
        </Link>
      ))}
    </Breadcrumbs>
  );
};
