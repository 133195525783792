import { SvgIcon } from "@mui/material";

const TRANSITION_SPEED = "transform 325ms";

export function GradientArrow({ direction }: { direction?: string }) {
  const degrees = (direction: string | undefined): number => {
    if (!direction) return 0;
    switch (direction.toLowerCase()) {
      case "up":
        return -90;
      case "down":
        return 90;
      case "left":
        return 180;
      default:
        return 0;
    }
  };

  return (
    <SvgIcon
      className="gradient-arrow"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        transition: TRANSITION_SPEED,
        transform: `rotate(${degrees(direction)}deg)`,
      }}
    >
      <path
        d="M12 4L10.59 5.41L16.17 11L4 11L4 13L16.17 13L10.58 18.58L12 20L20 12L12 4Z"
        fill="url(#paint0_linear_4486_52124)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4486_52124"
          x1="20"
          y1="4"
          x2="4.41498"
          y2="20.3945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9147FF" />
          <stop offset="1" stopColor="#A22E95" />
        </linearGradient>
      </defs>
    </SvgIcon>
  );
}
