import { ExceptionGroup } from "~/operations";
import { SelectedEntity } from "~/components/exceptions/types";

type useRemoveExceptionTextVariantParams = {
  exceptionGroups: ExceptionGroup[];
  selectedEntities: SelectedEntity[];
};

export const RemoveExceptionTextVariant = {
  SeparateGroups: "SeparateGroups",
  GroupSubset: "GroupSubset",
  SingleCheck: "SingleCheck",
  Generic: "Generic",
} as const;

export function useRemoveExceptionTextVariant({
  exceptionGroups,
  selectedEntities,
}: useRemoveExceptionTextVariantParams): keyof typeof RemoveExceptionTextVariant {
  const checksContainingExceptions = selectedEntities.filter(
    (check) => check?.exception,
  );

  const isChecksFromSameGroup = checksContainingExceptions.every(
    (check) => check.groupId === checksContainingExceptions[0]?.groupId,
  );

  // If checks are from different groups, display corresponding text
  if (!isChecksFromSameGroup) return RemoveExceptionTextVariant.SeparateGroups;

  // Now we are sure that we have all the checks from the same group
  const groupExceptionsLength =
    exceptionGroups.find(
      (exceptionGroup) =>
        exceptionGroup.id === checksContainingExceptions[0]?.groupId,
    )?.exceptions?.length || 0;

  // if we have less exceptions selected than we have in our group, it means we only remove a subset of checks from
  // exception
  if (checksContainingExceptions.length < groupExceptionsLength) {
    return RemoveExceptionTextVariant.GroupSubset;
  }

  // If we have only one selected check under exception we want to display the justification of it
  if (checksContainingExceptions.length === 1) {
    return RemoveExceptionTextVariant.SingleCheck;
  }

  // In other cases we just display generic message without specifying justification
  return RemoveExceptionTextVariant.Generic;
}
