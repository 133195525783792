import { Box, Breadcrumbs, Link, Typography, useTheme } from "@mui/material";
import { Flex } from "~/components/ui-library";
import { AddButton } from "~/components/add-button";
import { CreateWorkspaceFormFields } from "~/pages/space/Workspaces/components/CreateWorkspace/CreateWorkspaceFormFields";
import {
  Link as RouterLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import { Space } from "~/lib/types";
import { TestIamActionsQuery, useCreateWorkspaceMutation } from "~/operations";
import { NumberDot } from "~/components/guides/components";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CreateFormInput } from "~/pages/space/Workspaces/components/FormFields/types";
import { ApolloError } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useViewer } from "~/providers/viewer";
import { IamActions } from "~/lib/iam";

export type CreateWorkspacePageProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const CreateWorkspacePage = ({
  availablePermissions,
}: CreateWorkspacePageProps) => {
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { refetch } = useViewer();
  const spaceId = searchParams.get("spaceId");
  const spaceMrn = spaceId
    ? `//captain.api.mondoo.app/spaces/${spaceId}`
    : undefined;

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${spaceId}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/workspaces"
      component={RouterLink}
      to={`/space/workspaces?spaceId=${spaceId}`}
      display="flex"
    >
      Workspaces
    </Link>,
    <Typography key="/spaces/workspace/new">Create New</Typography>,
  ];

  const hasCreateWorkspacePermission = availablePermissions.includes(
    IamActions.ACTION_MONDOO_POLICY_EXTENDEDHUB_CREATEWORKSPACE,
  );

  const [createWorkspace, { loading: isCreateWorkspaceLoading }] =
    useCreateWorkspaceMutation();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<CreateFormInput>({
    mode: "onBlur",
    defaultValues: {
      name: "",
      description: "",
    },
  });

  const handleFormSubmit: SubmitHandler<CreateFormInput> = async (values) => {
    try {
      const ownerMrn = spaceMrn || "";
      const name = values.name;
      const description = values.description || "";

      const createSpaceResult = await createWorkspace({
        variables: {
          input: {
            ownerMrn,
            name,
            description,
            selections: {
              selections: [
                {
                  conditions: [],
                },
              ],
            },
          },
        },
      });
      const createdWorkspace = createSpaceResult.data?.createWorkspace;

      if (!createdWorkspace) throw "Failed to create workspace";

      enqueueSnackbar(`Successfully created workspace: ${name}`, {
        variant: "success",
      });
      // we have to wait for viewer and settings to load again
      // before we can navigate to the new space
      await refetch();
      navigate(`/space/overview?workspaceId=${createdWorkspace.mrn}`);
    } catch (err) {
      if (err instanceof ApolloError) {
        err.graphQLErrors.map((e) => {
          enqueueSnackbar(e.message, { variant: "error" });
        });
      } else {
        enqueueSnackbar("Failed to create workspace", { variant: "error" });
      }
    }
  };

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3, overflowWrap: "anywhere" }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <Box
        id="create-new-space-header"
        mb={6}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          gap: 2,
        }}
      >
        <Flex flexDirection="column">
          <Typography
            variant="h4"
            fontWeight={700}
            textTransform="uppercase"
            sx={{ mb: 1 }}
          >
            Create a Workspace
          </Typography>
          <Typography>
            Create a new workspace to structure a project, focus on specific
            assets, define criteria you want to monitor, or something entirely
            different – it’s up to you! Workspaces will dynamically pull in
            everything from this space that matches the criteria you define
            below.
          </Typography>
        </Flex>
        <Flex id="create-new-workspace-actions" alignItems="center" gap={4}>
          <AddButton
            id="workspace"
            href={`/space/workspaces?spaceId=${spaceId}`}
            aria-label="Create New Workspace"
            close
          />
        </Flex>
      </Box>
      <Flex gap={5} flexDirection="column">
        <Flex alignItems="flex-start">
          <NumberDot
            number={1}
            backgroundColor={theme.palette.background.lightest}
          />
          <Flex flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              component="p"
              fontWeight={700}
              sx={{
                fontSize: { xs: 16, sm: 24 },
              }}
            >
              Select assets to include
            </Typography>
            <Typography variant="body2" color="text.secondary" component="p">
              Define the criteria by which assets should be included in or
              excluded from your new workspace. Policy and Vulnerability
              behavior match your space settings.
            </Typography>
          </Flex>
        </Flex>
        <Flex alignItems="flex-start">
          <NumberDot
            number={2}
            backgroundColor={theme.palette.background.lightest}
          />
          <Flex flexDirection="column" gap={1}>
            <Typography
              variant="h5"
              component="p"
              fontWeight={700}
              sx={{
                fontSize: { xs: 16, sm: 24 },
              }}
            >
              Describe your workspace
            </Typography>
            <Typography variant="body2" color="text.secondary" component="p">
              Naming your workspace and providing a representative description
              of the purpose of the workspace makes both collaboration and
              documentation easier.
            </Typography>
            <Box mt={5}>
              <CreateWorkspaceFormFields
                onSubmit={handleFormSubmit}
                handleSubmit={handleSubmit}
                register={register}
                isLoading={isCreateWorkspaceLoading}
                disabled={!isValid || !hasCreateWorkspacePermission}
                errors={errors}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
