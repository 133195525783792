import React, { useEffect, useState } from "react";
import { Box, Paper, TextField, useTheme } from "@mui/material";
import { AutoConfigurationSection } from "~/pages/integrations/hosted-integrations/forms/jira/AutoConfigurationSection";
import {
  ClientIntegrationType,
  GetClientIntegrationDocument,
  useUpdateClientIntegrationConfigurationMutation,
} from "~/operations";
import { Controller, useForm } from "react-hook-form";
import { Space } from "~/lib/types";
import { TicketIntegration } from "~/components/cases/components/CaseSettings/types";
import { motion } from "framer-motion";
import { SubField } from "~/components/Form/components";
import { GithubIssueFormInput } from "~/pages/integrations/hosted-integrations/forms/github-issues/types";
import { Flex } from "~/components/ui-library";
import {
  helperTextStyles,
  ValidationMessage,
} from "~/pages/integrations/validations/helpers";

type CaseSettingsIntegrationProps = {
  integration: TicketIntegration;
  space: Space;
  showAutoCreateSection: boolean;
};

export const CaseSettingsGithubIntegration = ({
  integration,
  space,
  showAutoCreateSection,
}: CaseSettingsIntegrationProps) => {
  const theme = useTheme();
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getFieldState,
    formState: { errors },
  } = useForm<GithubIssueFormInput>({
    defaultValues: {
      defaultRepoName: "",
      defaultRepoOwner: "",
      autoCloseTickets: false,
      autoCreateCases: false,
    },
    mode: "onChange",
  });

  const [updateIntegration] = useUpdateClientIntegrationConfigurationMutation({
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    refetchQueries: [
      {
        query: GetClientIntegrationDocument,
        variables: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
        },
      },
    ],
  });

  const onSubmit = async (data: GithubIssueFormInput) => {
    const githubTicketingConfigurationOptions = {
      enterpriseUrl: data.enterpriseUrl || "",
      defaultRepoOwner: data.defaultRepoOwner || "",
      defaultRepoName: data.defaultRepoName || "",
      autoCloseTickets: data.autoCloseTickets,
      autoCreateTickets: data.autoCreateCases,
      token: "",
    };

    await updateIntegration({
      variables: {
        input: {
          mrn: `//integration.api.mondoo.app/spaces/${
            space.id
          }/integrations/${integration?.mrn.split("/").pop()}`,
          type: ClientIntegrationType.TicketSystemGithub,
          configurationOptions: {
            githubTicketingConfigurationOptions,
          },
        },
      },
    });
  };

  const [watchAutoCreate, watchAutoClose] = watch([
    "autoCreateCases",
    "autoCloseTickets",
  ]);

  useEffect(() => {
    isInitialized && handleSubmit(onSubmit)();
  }, [watchAutoClose, watchAutoCreate]);

  const handleTextFieldBlur = () => {
    handleSubmit(onSubmit)();
  };

  useEffect(() => {
    const configOptions = integration?.configurationOptions;
    if (configOptions?.__typename !== "GithubTicketingConfigurationOptions")
      return;
    reset({
      enterpriseUrl: configOptions.enterpriseUrl || "",
      defaultRepoName: configOptions.defaultRepoName || "",
      defaultRepoOwner: configOptions.defaultRepoOwner || "",
      autoCloseTickets: configOptions.autoCloseTickets,
      autoCreateCases: configOptions.autoCreateTickets,
    });
    setIsInitialized(true);
  }, []);

  return (
    <Box>
      <Paper sx={{ p: 3 }}>
        <AutoConfigurationSection
          control={control}
          showAutoCreateSection={showAutoCreateSection}
          autoCreateSubField={
            <Box
              component={motion.div}
              initial={false}
              transition={{
                duration: 0.35,
                staggerChildren: 0.25,
              }}
              sx={{ overflow: "hidden" }}
            >
              <SubField
                configuration="Select a drift organization and repository"
                caption="Specify the GitHub organization and repository in which to create an issue for each new drift case."
                disabled={!watchAutoCreate}
                field={
                  <Flex flex="1 0 auto" gap={2} alignItems="flex-end">
                    <Controller
                      name="defaultRepoOwner"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onBlur={handleTextFieldBlur}
                          fullWidth
                          disabled={!watchAutoCreate}
                          sx={{
                            background: theme.palette.code.background,
                            borderRadius: 1,
                            color: "text.primary",
                            ...helperTextStyles,
                          }}
                          placeholder={"Your GitHub organization..."}
                          error={
                            getFieldState("defaultRepoOwner").isTouched &&
                            Boolean(errors.defaultRepoOwner)
                          }
                          helperText={
                            Boolean(errors.defaultRepoOwner) &&
                            getFieldState("defaultRepoOwner").isTouched && (
                              <ValidationMessage
                                error={errors.defaultRepoOwner}
                              />
                            )
                          }
                        />
                      )}
                    />
                    <Controller
                      name="defaultRepoName"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onBlur={handleTextFieldBlur}
                          fullWidth
                          disabled={!watchAutoCreate}
                          sx={{
                            background: theme.palette.code.background,
                            borderRadius: 1,
                            color: "text.primary",
                            ...helperTextStyles,
                          }}
                          placeholder={"Your GitHub repository..."}
                          error={
                            getFieldState("defaultRepoName").isTouched &&
                            Boolean(errors.defaultRepoName)
                          }
                          helperText={
                            Boolean(errors.defaultRepoName) &&
                            getFieldState("defaultRepoName").isTouched && (
                              <ValidationMessage
                                error={errors.defaultRepoName}
                              />
                            )
                          }
                        />
                      )}
                    />
                  </Flex>
                }
              />
            </Box>
          }
        />
      </Paper>
    </Box>
  );
};
