import {
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { CliManager, FeatureFlag, features } from "./features";

export function FeatureFlags() {
  const cli = new CliManager();

  const availableFeatures = [...features];
  const storedFeatures: FeatureFlag[] = JSON.parse(
    localStorage.getItem("features") || "[]",
  );

  availableFeatures.forEach((af) => {
    const feature = storedFeatures.find((sf) => sf.name === af.name);
    if (feature) {
      feature.enabled = af.enabled;
    }
  });

  const handleOnChange = (feature: FeatureFlag) => {
    //if enabled -> disable
    if (feature.enabled) {
      cli.disableFeature(feature.name);
      // if disabled -> enable
    } else if (!feature.enabled) {
      cli.enableFeature(feature.name);
    }
  };

  return (
    <Box>
      <Typography variant="h4" fontWeight={700}>
        Feature Flags
      </Typography>

      <List>
        {availableFeatures.map((feature) => (
          <ListItem key={feature.name} disablePadding>
            <Checkbox
              edge="start"
              onChange={() => handleOnChange(feature)}
              checked={feature.enabled}
              inputProps={{ "aria-labelledby": feature.name }}
              sx={{ mr: 2 }}
            />

            <ListItemText
              primary={feature.name}
              primaryTypographyProps={{ sx: { mt: 2 } }}
              secondary={feature?.description}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
