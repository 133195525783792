import { ReactNode } from "react";
import { Box } from "@mui/material";

type TabPanelProps = {
  children: ReactNode;
  index: number | string;
  value: number | string;
};

export const TabPanel = ({
  children,
  value,
  index,
  ...other
}: TabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
};
