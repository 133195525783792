import {
  ClientIntegrationType,
  useGetTicketingIntegrationsQuery,
} from "~/operations";
import { useCallback, useEffect, useState } from "react";

type UseTicketIntegrationsParams = {
  spaceMrn: string;
  skip?: boolean;
};

export function useTicketIntegrations({
  spaceMrn,
  skip,
}: UseTicketIntegrationsParams) {
  const [selectedIntegrationMrn, setSelectedIntegrationMrn] = useState("");

  const { data: ticketIntegrationsData } = useGetTicketingIntegrationsQuery({
    variables: {
      input: {
        spaceMrn,
      },
    },
    fetchPolicy: "cache-and-network",
    skip,
  });

  const selectedIntegrationKey = "case.integrationMrn";
  const saveSelectedIntegration = (value: string) => {
    localStorage.setItem(selectedIntegrationKey, value);
  };

  const integrations =
    ticketIntegrationsData?.ticketingIntegrations.integrations;

  const loadSelectedIntegration = useCallback(() => {
    const storedIntegrationMrn =
      localStorage.getItem(selectedIntegrationKey) || "";
    return (
      (integrations || []).find((i) => i?.mrn === storedIntegrationMrn)?.mrn ||
      ""
    );
  }, [integrations]);

  useEffect(() => {
    const defaultIntegration = loadSelectedIntegration();
    const jiraIntegrationType =
      ticketIntegrationsData?.ticketingIntegrations.integrations.find(
        (i) => i?.type === ClientIntegrationType.TicketSystemJira,
      )?.mrn || "";

    setSelectedIntegrationMrn(
      defaultIntegration || jiraIntegrationType || integrations?.[0]?.mrn || "",
    );
  }, [ticketIntegrationsData]);

  const ticketingIntegrations =
    ticketIntegrationsData?.ticketingIntegrations.integrations.map(
      (integration) => ({
        value: integration?.mrn,
        label: integration?.name,
        type: integration?.type,
      }),
    );

  const selectedIntegration =
    ticketIntegrationsData?.ticketingIntegrations.integrations?.find(
      (integration) => integration?.mrn === selectedIntegrationMrn,
    );

  return {
    ticketingIntegrations,
    selectedIntegration,
    selectedIntegrationMrn,
    setSelectedIntegrationMrn,
    saveSelectedIntegration,
  };
}
