import { Fragment, useState } from "react";
import {
  DangerZone,
  DangerZoneItem,
  DangerZoneItemProps,
} from "~/components/danger-zone";
import { useOrgSettingsOutletContext } from "./settings";
import { IamActions } from "~/lib/iam";
import { useViewer } from "~/providers/viewer";
import { useNavigate } from "react-router";
import { useSnackbar } from "notistack";
import { OrganizationNameSettings } from "~/pages/organization/settings/name-and-description-settings";

type OrganizationSettingsPageState = {
  name: string;
  nameErrMsg: string;
  isDeleting: boolean;
};

export function GeneralSettings() {
  const { availablePermissions, org } = useOrgSettingsOutletContext();
  const { deleteOrg } = useViewer();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const initialState: OrganizationSettingsPageState = {
    name: org.name,
    nameErrMsg: "",
    isDeleting: false,
  };

  const [state, setState] = useState(initialState);

  const mergeState = (params: Partial<OrganizationSettingsPageState>) => {
    return setState((prevState) => ({
      ...prevState,
      ...params,
    }));
  };

  const deleteOrgPermission = availablePermissions?.includes(
    IamActions.CAPTAIN_DELETEORGANIZATION,
  );

  const handleDeleteOrg: DangerZoneItemProps["onClick"] = async () => {
    mergeState({ isDeleting: true });
    try {
      await deleteOrg({ variables: { orgMrn: org.mrn } });
      enqueueSnackbar(`Successfully deleted organization: ${org.name}`, {
        variant: "success",
      });
      navigate("/dashboard");
    } catch (error) {
      enqueueSnackbar(`Could not delete organization ${org.name}`, {
        variant: "error",
      });
    } finally {
      mergeState({ isDeleting: false });
    }
  };

  return (
    <Fragment>
      <OrganizationNameSettings />
      {deleteOrgPermission && (
        <DangerZone>
          <DangerZoneItem
            primary="Delete Organization"
            secondary="Once you delete an organization, all its data will be erased including spaces, assets and reports. Please be certain."
            confirm="I confirm that I want to delete this organization including all its related content"
            action="Delete"
            isDeleting={state.isDeleting}
            onClick={handleDeleteOrg}
          />
        </DangerZone>
      )}
    </Fragment>
  );
}
