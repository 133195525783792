import { Typography } from "@mui/material";
import { pluralize } from "~/lib/pluralize";
import { FormatDate } from "~/lib/date";
import { LockClosedIcon } from "~/components/icons";

type HeaderTagsProps = {
  totalAssets: number;
  createdBy?: string | undefined;
  closedAt?: string | undefined;
};

export const HeaderTags = ({
  totalAssets,
  createdBy,
  closedAt,
}: HeaderTagsProps) => {
  return (
    <>
      {closedAt && (
        <Typography
          id="created-by"
          variant="caption"
          color="text.secondary"
          fontWeight={700}
          sx={{ mx: 3, display: "inline-flex", alignItems: "center" }}
        >
          <LockClosedIcon
            fontSize="inherit"
            sx={{ mr: 0.5, color: (theme) => theme.palette.text.secondary }}
          />
          Closed {FormatDate(closedAt)}
        </Typography>
      )}

      {createdBy && (
        <Typography
          id="created-by"
          variant="caption"
          color="text.secondary"
          fontWeight={700}
          sx={{ mx: 3, display: "inline-flex", alignItems: "center" }}
        >
          Created By {createdBy}
        </Typography>
      )}

      <Typography
        id="assets"
        variant="caption"
        color="text.secondary"
        fontWeight={700}
        sx={{ mr: 3, display: "inline-flex", alignItems: "center" }}
      >
        {pluralize("Asset", totalAssets)} {totalAssets}
      </Typography>
    </>
  );
};
