import { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  IconButton,
  IconButtonProps,
  TextField,
} from "@mui/material";
import { KeyValue } from "~/lib/types";
import { CancelIcon } from "./icons";
import { JobsTypes } from "~/pages/cicd/jobs";
import {
  LoadCicdProjectJobsQuery,
  useAssetSearchSuggestionsQuery,
} from "~/operations";

type Project = NonNullable<
  LoadCicdProjectJobsQuery["cicdProjectJobs"]
>["project"];

type AssetSearchProps = {
  spaceMrn: string;
  filters: KeyValue[];
  onQuery:
    | ((query: KeyValue[], deleted?: KeyValue) => void)
    | ((query: KeyValue[] | string[]) => void);
  projectId?: string | null;
  project: Project;
};

export const CicdSearch = ({
  spaceMrn,
  filters,
  onQuery,
  projectId,
  project,
}: AssetSearchProps) => {
  const [keyValues, setKeyValues] = useState<KeyValue[]>([]);
  const [selectedKeyValue, setSelectedKeyValue] = useState<string[]>([]);
  const [placeholder, setPlaceholder] = useState<string>("Search...");

  const defaultFilters = [
    {
      key: "mondoo.com/project-id",
      value: projectId,
    },
  ];

  const suggestionsResult = useAssetSearchSuggestionsQuery({
    variables: {
      input: {
        spaceMrn,
        searchKey: "",
        isCiCd: true,
        labelFilter: defaultFilters,
      },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    setKeyValues(filters);
  }, [selectedKeyValue, filters]);

  // Update the placeholder text when searching
  useEffect(() => {
    if (selectedKeyValue.length > 0) {
      const reg = new RegExp(`^${project.type}\/`);
      const string = selectedKeyValue[0].replace(reg, "");
      setPlaceholder(`Search values for "${string}"`);
    } else {
      const text = searchPlaceHolderText();
      setPlaceholder(text);
    }
  }, [selectedKeyValue]);

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} placeholder={placeholder} fullWidth />;
  };

  const formatSearchKey = (key: string) => {
    if (key.includes(project.type)) {
      return key.replaceAll(`${project.type}/`, "");
    } else if (key.includes("mondoo.com") && !key.includes("k8s")) {
      return key;
    } else {
      return `${project.type}/${key}`;
    }
  };

  const searchPlaceHolderText = () => {
    switch (project.type) {
      case JobsTypes.Github:
      case JobsTypes.Gitlab: // fallthrough
        return "Search for PRs, branches, ...";
      case JobsTypes.Kubernetes:
        return "Filter by Namespace, Name, Kind, Operation, ...";
      default:
        return "Search...";
    }
  };

  const renderTags = (
    value: string[],
    getTagProps: AutocompleteRenderGetTagProps,
  ) =>
    value.map((v, index) => {
      const tagProps = getTagProps({ index });
      const sx = {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: "background.paper",
        borderColor: "background.light",
      };
      return (
        <Chip
          label={v}
          {...tagProps}
          deleteIcon={<></>}
          onClick={tagProps.onDelete}
          variant="outlined"
          sx={sx}
        />
      );
    });

  const onChange: AutocompleteProps<string, true, false, true>["onChange"] = (
    _event,
    value,
    reason,
  ) => {
    if (value.length === 2) {
      const key = formatSearchKey(value[0]);
      setSelectedKeyValue([]);
      updateKeyValues([...keyValues, { key, value: value[1] }]);
      fetchSuggestions("");
    } else {
      if (reason === "createOption") {
        setSelectedKeyValue([]);
        updateKeyValues([
          ...keyValues,
          { key: `${project.type}/search`, value: value[0] },
        ]);
        fetchSuggestions("");
      } else {
        setSelectedKeyValue(value);
        if (value[0]) {
          const formattedValue = formatSearchKey(value[0]);
          fetchSuggestions(formattedValue);
        } else {
          fetchSuggestions("");
        }
      }
    }
  };

  const onDelete = (index: number) => {
    const kvs: KeyValue[] = JSON.parse(JSON.stringify(keyValues));
    const deleted: KeyValue = kvs.splice(index, 1).pop()!;
    if (deleted.key.includes(project.type)) {
      deleted.key = formatSearchKey(deleted.key);
    }
    setKeyValues(kvs);
    onQuery(kvs, deleted);
    fetchSuggestions("");
  };

  const fetchSuggestions = (searchKey: string) => {
    return suggestionsResult.refetch({
      input: {
        spaceMrn,
        isCiCd: true,
        searchKey,
        labelFilter: defaultFilters,
      },
    });
  };

  const updateKeyValues = (updatedKeyValues: KeyValue[]) => {
    setKeyValues(updatedKeyValues);
    onQuery(updatedKeyValues);
  };

  const suggestions =
    suggestionsResult.data?.assetSearchSuggestions.suggestions;

  const options =
    suggestions
      ?.filter((s) => s.value !== "mondoo.com/project-id")
      .map((s) => {
        return s.value.replace(`${project.type}/`, "");
      }) || [];

  return (
    <Box component="form" noValidate sx={{ width: "100%" }}>
      <Autocomplete
        freeSolo
        options={options}
        loading={suggestionsResult.loading}
        renderInput={renderInput}
        renderTags={renderTags}
        multiple
        value={selectedKeyValue}
        onChange={onChange}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Prevent's default 'Enter' behavior.
            // this blocks the page from refreshing when pressing enter with no text present
            event.preventDefault();
          }
        }}
      />
      <Box>
        {keyValues.map((keyValue, index) => (
          <KeyValueChip
            key={index}
            keyValue={{
              key: formatSearchKey(keyValue.key),
              value: keyValue.value,
            }}
            onDelete={() => onDelete(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

type KeyValueChipProps = {
  keyValue: KeyValue;
  onDelete?: IconButtonProps["onClick"];
};

function KeyValueChip({ keyValue, onDelete }: KeyValueChipProps) {
  const { key, value } = keyValue;
  const label = `${key} ${value}`;
  const chipSx = {
    display: "inline-flex",
    alignItems: "center",
    border: "1px solid",
    borderColor: "background.light",
    backgroundColor: "background.light",
    marginTop: 1,
    marginRight: 1,
    paddingRight: 1,
    borderRadius: 1,
    typography: "body2",
    cursor: "pointer",
    fontSize: "13px",
    overflowWrap: "anywhere",
  };
  const keySx = {
    backgroundColor: "background.paper",
    padding: 1,
    display: "inline-flex",
    alignSelf: "stretch",
    alignItems: "center",
  };
  const valSx = {
    backgroundColor: "background.light",
    padding: 1,
    display: "inline-flex",
    alignSelf: "stretch",
    alignItems: "center",
  };
  return (
    <Box sx={chipSx} aria-label={label}>
      <Box sx={keySx}>{key}</Box>
      <Box sx={valSx}>{value}</Box>
      <IconButton onClick={onDelete} sx={{ p: 0 }}>
        <CancelIcon sx={{ color: "text.primary", opacity: "0.26" }} />
      </IconButton>
    </Box>
  );
}
