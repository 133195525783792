import { Fragment, ReactElement, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  AccordionSummary,
  alpha,
  Box,
  Button,
  Chip,
  Typography,
} from "@mui/material";
import { Token, useToken } from "~/providers/token";
import {
  AppleIcon,
  ChevronRightIcon,
  ExpandMoreIcon,
  LinuxIcon,
} from "~/components/icons";
import { WindowsIcon } from "~/components/icons/mondoo/integrations";
import { TokenCustomizer } from "~/components/token-customizer";
import { Code } from "~/components/code";
import { WorkstationScans } from "./workstation-scans";
import InstallInstructionData from "~/pages/integration-managed-agents.json";
import { getPlatform } from "~/lib/user-agent";
import {
  IntegrationAddHeader,
  OtherIntegrations,
} from "./headers/integration-add-header";
import { Markdown } from "~/components/markdown";

export function Workstation({ product }: { product: string }) {
  const { token } = useToken();
  const [os, setOs] = useState<string>(() => getPlatform());
  const [expandedIndex, setExpandedIndex] = useState<number>(0);

  const [qsInstructions, setQsInstructions] = useState<string>(
    InstallInstructionData[os as keyof typeof InstallInstructionData].install,
  );

  const [view, setView] = useState<string>("setup");

  let cmd = "login";

  // Swap for real user token in Quick Setup
  useEffect(() => {
    let install =
      InstallInstructionData[os as keyof typeof InstallInstructionData].install;
    const tokenValue = token == null ? "PASTE_TOKEN" : token.value;

    // mondoo workstation should use cnspec login not mondoo login
    let login = product;
    if (product === "mondoo") {
      login = "cnspec";
    }

    install = install.replace(/\%TOKEN\%/g, tokenValue);
    install = install.replace(/\%PRODUCT\%/g, login);
    install = install.replace(/\%CMD\%/g, cmd);

    setQsInstructions(install);
  }, [token, os]);

  const handlePanelChange = (panelIndex: number, isExpanded: boolean) => {
    if (isExpanded) {
      setExpandedIndex(panelIndex);
    }
  };

  return (
    <Fragment>
      {view === "setup" ? (
        <Fragment>
          <IntegrationAddHeader
            {...{ type: `workstation/${product}` as OtherIntegrations }}
          />
          <Box>
            {/* Quick Setup */}
            <PaperAccordion
              title="Quick Setup"
              os={os}
              setInstructions={setOs}
              expanded={expandedIndex === 0}
              onChange={(_event, isExpanded) =>
                handlePanelChange(0, isExpanded)
              }
              className="integration"
            >
              <Fragment>
                <Code
                  className="bash"
                  copyButton
                  dataId={`workstation-qs-${os}`}
                >
                  {qsInstructions}
                </Code>
                <Box mt={-1}>
                  <TokenCustomizer />
                </Box>
              </Fragment>
            </PaperAccordion>

            {/* Manual Setup */}
            <PaperAccordion
              title="Manual Setup"
              os={os}
              setInstructions={setOs}
              expanded={expandedIndex === 1}
              onChange={(_event, isExpanded) =>
                handlePanelChange(1, isExpanded)
              }
            >
              <Fragment>
                {os === "macos" && (
                  <MacOSInstructions
                    {...{ product: product, cmd: cmd, token: token }}
                  />
                )}
                {os === "linux" && (
                  <LinuxInstructions
                    {...{ product: product, cmd: cmd, token: token }}
                  />
                )}
                {os === "windows" && <WindowsInstructions {...{ token }} />}
                <Box mt={-1}>
                  <TokenCustomizer />
                </Box>
              </Fragment>
            </PaperAccordion>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<ChevronRightIcon />}
              onClick={() => setView("add scans")}
            >
              start scanning
            </Button>
          </Box>
        </Fragment>
      ) : (
        <WorkstationScans />
      )}
    </Fragment>
  );
}

type PaperAccordionProps = {
  os: string;
  title: string;
  children: ReactElement;
  setInstructions?: any;
} & AccordionProps;

const PaperAccordion = ({
  os,
  title,
  children,
  setInstructions,
  ...rest
}: PaperAccordionProps) => {
  const [activeTab, setActiveTab] = useState<string>(() => os);
  const osChips = ["macOS", "Linux", "Windows"];

  useEffect(() => {
    setActiveTab(os);
  }, [os]);

  const handleClick = (label: string) => {
    setActiveTab(label);
    setInstructions(label.toLowerCase());
  };

  return (
    <Box mb={3}>
      <Accordion {...rest}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ transform: "rotate(180deg)" }} />}
          sx={{
            flexDirection: "row-reverse",
            "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
              transform: "rotate(180deg)",
            },
          }}
        >
          <Typography
            variant="h5"
            component="p"
            fontWeight={700}
            sx={{ pl: 1 }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={3}>
            {osChips.map((x) => (
              <OsChip
                label={x}
                active={activeTab.toLowerCase() === x.toLowerCase()}
                key={x}
                onClick={() => handleClick(x)}
              />
            ))}
          </Box>
          <Fragment>{children}</Fragment>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

// Chip for OS instruction selection
const OsChip = ({
  active,
  label,
  onClick,
}: {
  active: boolean;
  label: string;
  onClick: () => void;
}) => {
  const getIcon = (label: string) => {
    switch (label.toLowerCase()) {
      case "macos":
        return <AppleIcon />;
      case "linux":
        return <LinuxIcon />;
      default:
        return <WindowsIcon />;
    }
  };

  return (
    <Chip
      onClick={onClick}
      {...{ label, icon: getIcon(label) }}
      sx={{
        mr: 2,
        px: { xs: 0, sm: 2 },
        height: { xs: 36, sm: 48 },
        borderRadius: { xs: 36, sm: 48 },
        background: active
          ? (theme) => alpha(theme.palette.primary.main, 0.5)
          : "transparent",
      }}
    />
  );
};

const MacOSInstructions = ({
  product,
  cmd,
  token,
}: {
  product: string;
  cmd: string;
  token: Token;
}) => {
  return (
    <Box className="integration">
      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Install {product} via the macOS universal binary package:
      </Typography>
      <Markdown source={macPkgInstructions} trusted />

      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Install the PKG non-interactively with the installer utility:
      </Typography>
      <Markdown
        copyButton
        dataId="workstation-windows-manual-run-with-cmd"
        source={
          "```bash\ninstaller -pkg ./mondoo_(version)_darwin_universal.pkg -target /Library\n```"
        }
        trusted
      />

      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Log into Mondoo Platform:
      </Typography>
      <Code
        className="bash"
        copyButton
        dataId="workstation-macos-manual-register"
      >
        {registerTokenCommand(product, cmd, token)}
      </Code>
    </Box>
  );
};
const LinuxInstructions = ({
  product,
  cmd,
  token,
}: {
  product: string;
  cmd: string;
  token: Token;
}) => {
  return (
    <Box className="integration">
      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Install the {product} package directly via yum:
      </Typography>
      <Markdown
        copyButton
        className="integration strings-all-white"
        dataId="workstation-linux-manual-yum-install"
        source={
          "```bash\ncurl --retry 3 --retry-delay 10 -sSL https://releases.mondoo.com/rpm/mondoo repo | tee/etc/yum.repos.d/mondoo.repo\nyum install -y " +
          product +
          "\n```"
        }
        trusted
      />
      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Install the {product} package directly via apt:
      </Typography>
      <Markdown
        copyButton
        className="integration all-white-commenting"
        dataId="workstation-linux-manual-apt-install"
        source={aptInstructions(product)}
        trusted
      />
      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Install the {product} package directly via zypper:
      </Typography>
      <Markdown
        copyButton
        className="integration all-white-commenting"
        dataId="workstation-linux-manual-zypper-install"
        source={zypperInstructions(product)}
        trusted
      />
      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Log into Mondoo Platform:
      </Typography>
      <Code
        className="bash"
        copyButton
        dataId="workstation-linux-manual-register"
      >
        {registerTokenCommand(product, cmd, token)}
      </Code>
    </Box>
  );
};
const WindowsInstructions = ({ token }: { token: Token }) => {
  return (
    <Box className="integration" sx={{ mb: 3 }}>
      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Install the Mondoo package via MSI:
      </Typography>
      <Markdown source={msiInstructions} trusted />

      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Run with PowerShell:
      </Typography>
      <Markdown
        copyButton
        dataId="workstation-windows-manual-run-with-powershell"
        source={
          "```Powershell\nStart-Process -Wait msiexec -ArgumentList '/qn /i mondoo.msi'\n```"
        }
        trusted
      />
      <Typography sx={{ mb: -1, color: "text.secondary" }}>
        Run with Cmd:
      </Typography>
      <Markdown
        copyButton
        dataId="workstation-windows-manual-run-with-cmd"
        source={
          '```cmd\nstart /wait msiexec /qn /i mondoo.msi REGISTRATIONTOKEN="' +
          `${token.value ? token.value : "PASTE TOKEN"}` +
          '"\n```'
        }
      />
      <Typography>
        The msi package REGISTRATIONTOKEN argument allows you to pass in the
        token to the MSI installer. If provided, the client will be registered
        automatically during the installation.
      </Typography>
    </Box>
  );
};

// prettier-ignore
const aptInstructions = (product: string) =>
  '```bash\napt update -y && apt install -y apt-transport-https ca-certificates gnupg curl\n\
curl --retry 3 --retry-delay 10 -sSL https://releases.mondoo.com/debian/pubkey.gpg | gpg --dearmor --yes --output /usr/share/keyrings/mondoo-archive-keyring.gpg\n\
echo "deb [signed-by=/usr/share/keyrings/mondoo-archive-keyring.gpg] https://releases.mondoo.com/debian/ stable main" | tee /etc/apt/sources.list.d/mondoo.list\n\
apt update -y && apt install -y ' + product + '\n```';

// prettier-ignore
const zypperInstructions  = (product: string) => "```bash\ncurl --retry 3 --retry-delay 10 -sSL https://releases.mondoo.com/rpm/mondoo.repo | tee /etc/zypp/repos.d/mondoo.repo\n\
rpm --import https://releases.mondoo.com/rpm/pubkey.gpg\n\
zypper -n install " + product + "\n```"

// prettier-ignore
const macPkgInstructions =
  "\
1. Download the latest version of the Mondoo package from <a href='https://releases.mondoo.com/mondoo/latest' target='_blank' rel='noopener'>releases.mondoo.com</a>.\n\n\
2. Execute the PKG installer.\n\n\
3. Follow the steps, confirm the license agreement, and complete the installation.\n\n";

// prettier-ignore
const msiInstructions =
  "\
1. Download the latest version of Mondoo package, which includes cnquery and cnspec, from <a href='https://releases.mondoo.com/mondoo/latest' target='_blank' rel='noopener'>releases.mondoo.com</a>.\n\n\
2. Execute the MSI installer as an administrator.\n\n\
3. Follow the steps, confirm the license agreement, and register the client with your account on Mondoo Platform.\n\n\
If you enter a registration token during the installation, the client registers automatically. If you skip entering the registration token in the GUI installer, use the manual registration process described below after the installation.";

// prettier-ignore
const registerTokenCommand = (product: string, cmd: string, token: Token) => {
let login = product
if (product === 'mondoo') {
   login = 'cnspec'
}

return `${login} ${cmd} --token '${token?.value ? token?.value : "PASTE_TOKEN"}'`
}
