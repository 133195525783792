import { createSvgIcon } from "@mui/material";

export const NavDrawerSecurityIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
  >
    <path d="M12 3.47658L19.498 6.5179V11.0248C19.4629 13.1676 18.719 15.2451 17.3725 16.9607C16.026 18.6764 14.1457 19.9425 12 20.5785C9.85427 19.9425 7.97398 18.6764 6.62748 16.9607C5.28099 15.2451 4.53709 13.1676 4.50197 11.0248V6.5179L12 3.47658ZM12 2.04408C11.7983 2.0476 11.5989 2.0849 11.4108 2.15427L3.91276 5.19559C3.6403 5.30484 3.40806 5.48889 3.24554 5.72434C3.08302 5.95979 2.99757 6.23601 3.00005 6.5179V11.0248C3.02687 13.4792 3.87371 15.8615 5.41746 17.8253C6.96122 19.7892 9.12143 21.2323 11.5841 21.9449C11.856 22.0184 12.144 22.0184 12.4159 21.9449C14.8804 21.2317 17.042 19.787 18.5859 17.8209C20.1299 15.8548 20.9756 13.4701 20.9999 11.0138V6.5179C21.0024 6.23601 20.917 5.95979 20.7545 5.72434C20.5919 5.48889 20.3597 5.30484 20.0872 5.19559L12.5892 2.11019C12.4011 2.04082 12.2017 2.00353 12 2V2.04408Z" />
  </svg>,
  "Security",
);
