import { AuditLog as ReusableAuditLog } from "~/pages/space-settings/settings-auditlog";
import { useOrgSettingsOutletContext } from "./settings";

export function AuditLog() {
  const { org, availablePermissions } = useOrgSettingsOutletContext();
  return (
    //AuditLog is being reused in both the space and org level
    <ReusableAuditLog {...{ availablePermissions, resourceMrn: org.mrn }} />
  );
}
