import { useTheme } from "@mui/material";

type UseImpactResultIconParams = {
  color: string;
  size?: "s" | "m" | "l";
};

export function useImpactResultIcon({
  color,
  size = "m",
}: UseImpactResultIconParams) {
  const theme = useTheme();

  const iconDimensions = {
    s: {
      diameter: theme.spacing(3),
      fontSize: 16,
    },
    m: {
      diameter: theme.spacing(4),
      fontSize: 24,
    },
    l: {
      diameter: theme.spacing(5),
      fontSize: 32,
    },
  };

  const diameter = iconDimensions[size].diameter;
  const fontSize = iconDimensions[size].fontSize;

  const iconStyles = {
    fontSize: fontSize,
    lineHeight: "16px",
    color,
  };

  return {
    fontSize,
    iconStyles,
    diameter,
  };
}
