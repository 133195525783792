import { createSvgIcon } from "@mui/material";

export const GitLabColorIcon = createSvgIcon(
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 380 380"
    fill="currentColor"
  >
    <g>
      <path
        fill="#e24329"
        d="M357.23,155.28l-.48-1.25L309.66,31.13a12.3,12.3,0,0,0-4.85-5.83,12.37,12.37,0,0,0-18.59,7.11l-31.8,97.29H125.67L93.87,32.41a12.38,12.38,0,0,0-4.18-6.36,12.61,12.61,0,0,0-14.41-.77,12.38,12.38,0,0,0-4.85,5.84L23.25,154l-.47,1.24a87.46,87.46,0,0,0,29,101.07l.16.13.43.3,71.74,53.72,35.49,26.86,21.62,16.33a14.55,14.55,0,0,0,17.58,0l21.62-16.33,35.49-26.86,72.17-54,.18-.15A87.46,87.46,0,0,0,357.23,155.28Z"
      />

      <path
        fill="#fc6d26"
        d="M357.23,155.28l-.48-1.25a158.94,158.94,0,0,0-63.32,28.47L190,260.7c35.22,26.65,65.88,49.8,65.88,49.8l72.17-54.05.18-.14A87.47,87.47,0,0,0,357.23,155.28Z"
      />

      <path
        fill="#fca326"
        d="M124.12,310.5l35.49,26.86,21.62,16.32a14.55,14.55,0,0,0,17.58,0l21.62-16.32,35.49-26.86s-30.7-23.23-65.92-49.8C154.78,287.27,124.12,310.5,124.12,310.5Z"
      />

      <path
        fill="#fc6d26"
        d="M86.56,182.5A158.83,158.83,0,0,0,23.25,154l-.47,1.24a87.46,87.46,0,0,0,29,101.07l.16.13.43.3,71.74,53.72L190,260.63Z"
      />
    </g>
  </svg>,
  "GitLabColor",
);
