import { SmallDonutCard } from "~/components/SmallDonut";
import { DonutChartCardProps } from "~/components/SmallDonut/types";
import { CVEIcon } from "~/components/icons";
import { Metrics, useMetrics } from "~/hooks";

export function CvesDonutChart({ space }: DonutChartCardProps) {
  const { cvesSeverity, isLoading } = useMetrics({
    entityMrn: space.mrn,
    metricMrns: [Metrics.CVEsSeverity],
  });

  return (
    <SmallDonutCard
      to={`cves?spaceId=${space.id}`}
      loading={isLoading}
      data={cvesSeverity}
      data-testid="cves-donut-chart"
      title="CVES"
      icon={<CVEIcon />}
    />
  );
}
