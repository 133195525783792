import { Fragment, useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import { debounce } from "lodash";
import { GroupsIcon, LanguageIcon, WorkspacesIcon } from "~/components/icons";
import { useViewer } from "~/providers/viewer";
import {
  useListSharedSpacesQuery,
  useLoadOrganizationStatsQuery,
} from "~/operations";
import { Config } from "~/configuration_provider";
import { ScopeButton, ScopeButtonProps, ScopeOption } from ".";
import { useOrgAndSpaceData } from "~/hooks";
import { useSearchParams } from "react-router-dom";

export type AppNavBreadcrumbsProps = {};

export function AppNavBreadcrumbs({}: AppNavBreadcrumbsProps) {
  const isPrivateInstance = Config.VITE_PRIVATE_INSTANCE === "true";
  const isSharedSpaces = location.pathname.startsWith("/spaces");

  const [searchParams] = useSearchParams();
  const orgId = searchParams.get("organizationId");
  const spaceId = searchParams.get("spaceId");
  const { orgData: org, spaceData: space } = useOrgAndSpaceData({
    orgId,
    spaceId,
  });

  const { viewer, regions, selectedRegion, setSelectedRegion } = useViewer();

  const [orgQuery, setOrgQuery] = useState("");
  const [spaceQuery, setSpaceQuery] = useState("");
  const [selectedOrgOption, setSelectedOrgOption] = useState<ScopeOption>();
  const [selectedSpaceOption, setSelectedSpaceOption] = useState<ScopeOption>();

  const { data: orgStatsData, refetch: refetchOrgStats } =
    useLoadOrganizationStatsQuery({
      variables: { mrn: org?.mrn || "" },
      skip: !org?.mrn,
    });

  const fetchOrgStats = useMemo(
    () => debounce(refetchOrgStats, 300),
    [refetchOrgStats],
  );

  const { data: sharedSpacesData } = useListSharedSpacesQuery();

  const sharedSpaces =
    sharedSpacesData?.sharedSpaces?.edges?.flatMap((e) => e.node ?? []) || [];
  const sharedSpacesCount = sharedSpacesData?.sharedSpaces?.totalCount || 0;
  const hasSharedSpaces = sharedSpacesCount > 0;

  const isSharedSpace = (orgId?: string | null) => {
    return orgId && !viewer?.organizations?.some((o) => o.id === orgId);
  };

  // Build region options
  const regionOptions =
    regions?.map(({ name }) => ({ id: name, name, href: "/dashboard" })) || [];
  const selectedRegionOption = regionOptions.find(
    ({ name }) => name === selectedRegion.name,
  );

  // Build org options
  const orgs =
    viewer?.organizations?.map(({ id, name }) => ({
      id,
      name,
      href: `/organization/overview?organizationId=${id}`,
    })) || [];
  const sharedOrg = {
    id: "shared-spaces-000000",
    name: "Shared Spaces",
    href: "/spaces",
  };
  if (hasSharedSpaces) {
    orgs.push(sharedOrg);
  }
  const orgOptions = orgs.filter((o) =>
    o.name.toLowerCase().includes(orgQuery.toLowerCase()),
  );

  useEffect(() => {
    const selectedOrg = orgs.find((o) => o.id === org?.id);
    if (selectedOrg) {
      setSelectedOrgOption(selectedOrg);
    }
  }, [org]);

  // Build space options
  const orgSpaces =
    orgStatsData?.organization?.spacesList.edges.flatMap((e) => e.node ?? []) ||
    [];
  const spaceOptions =
    (selectedOrgOption?.id === sharedOrg.id ? sharedSpaces : orgSpaces).map(
      ({ id, name }) => ({ id, name, href: `/space/overview?spaceId=${id}` }),
    ) || [];

  useEffect(() => {
    if (space) {
      setSelectedSpaceOption({
        id: space.id,
        name: space.name,
        href: `/space/overview?spaceId=${space.id}`,
      });
      if (isSharedSpace(space.organization?.id)) {
        setSelectedOrgOption(sharedOrg);
      }
    }
  }, [space]);

  useEffect(() => {
    if (!spaceId) {
      setSelectedSpaceOption(undefined);
      if (isSharedSpaces) {
        setSelectedOrgOption(sharedOrg);
      } else if (!orgId) {
        setSelectedOrgOption(undefined);
      }
    }
  }, [orgId, spaceId, isSharedSpaces]);

  const onOrgQuery: ScopeButtonProps["onQuery"] = (query) => {
    setOrgQuery(query);
  };

  const onSpaceQuery: ScopeButtonProps["onQuery"] = (query) => {
    setSpaceQuery(query);
    fetchOrgStats({ query });
  };

  const onRegionSelect: ScopeButtonProps["onSelect"] = (option) => {
    const region = regions?.find((r) => r.name === option.name);
    if (region) {
      setSelectedRegion(region);
    }
  };

  const onOrgSelect: ScopeButtonProps["onSelect"] = (option) => {
    setSelectedOrgOption(option);
    setSelectedSpaceOption(undefined);
    setOrgQuery("");
    setSpaceQuery("");
  };

  const onSpaceSelect: ScopeButtonProps["onSelect"] = (option) => {
    setSelectedSpaceOption(option);
    setSpaceQuery("");
  };

  const separator = (
    <Typography aria-hidden color="text.secondary" fontSize={13} children="›" />
  );

  return (
    <Box id="app-nav-breadcrumbs" sx={{ mr: "auto", pl: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        {!isPrivateInstance && (
          <Fragment>
            <ScopeButton
              scope="regions"
              icon={<LanguageIcon />}
              options={regionOptions}
              selected={selectedRegionOption}
              onSelect={onRegionSelect}
            />
            {separator}
          </Fragment>
        )}
        <ScopeButton
          scope="organizations"
          icon={<GroupsIcon />}
          options={orgOptions}
          selected={selectedOrgOption}
          onSelect={onOrgSelect}
          query={orgQuery}
          onQuery={onOrgQuery}
        />
        {selectedOrgOption && (
          <Fragment>
            {separator}
            <ScopeButton
              scope="spaces"
              icon={<WorkspacesIcon />}
              options={spaceOptions}
              selected={selectedSpaceOption}
              onSelect={onSpaceSelect}
              query={spaceQuery}
              onQuery={onSpaceQuery}
            />
          </Fragment>
        )}
      </Box>
    </Box>
  );
}
