import { createSvgIcon } from "@mui/material";

export const OracleLinuxIcon = createSvgIcon(
  <svg
    viewBox="0 0 48 48"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.300782 24C0.300782 32.283 7.01178 39 15.2918 39H32.7098C36.6867 38.9984 40.5002 37.4173 43.3114 34.6043C46.1227 31.7913 47.7016 27.9769 47.7008 24C47.7008 15.717 40.9898 9 32.7098 9H15.2918C11.3149 9.00159 7.50139 10.5827 4.69012 13.3957C1.87885 16.2087 0.299986 20.0231 0.300782 24ZM42.0338 24C42.0346 25.2747 41.7842 26.5371 41.297 27.7151C40.8099 28.8931 40.0954 29.9635 39.1944 30.8653C38.2935 31.7671 37.2237 32.4825 36.0462 32.9708C34.8687 33.4591 33.6065 33.7106 32.3318 33.711H15.6818C14.4065 33.7114 13.1437 33.4605 11.9655 32.9725C10.7873 32.4846 9.71684 31.7693 8.81525 30.8674C7.91366 29.9655 7.19865 28.8948 6.71109 27.7165C6.22354 26.5381 5.97299 25.2752 5.97378 24C5.97339 22.7255 6.22402 21.4634 6.71138 20.2858C7.19874 19.1082 7.91327 18.0381 8.81419 17.1367C9.7151 16.2352 10.7848 15.52 11.9621 15.0319C13.1394 14.5438 14.4013 14.2924 15.6758 14.292H32.3288C33.6035 14.292 34.8658 14.5431 36.0434 15.031C37.2211 15.5189 38.2911 16.2341 39.1923 17.1356C40.0935 18.0371 40.8084 19.1073 41.2959 20.2851C41.7834 21.4629 42.0342 22.7253 42.0338 24Z"
      fill="#EA1B22"
    />
  </svg>,
  "Oracle Linux",
);
