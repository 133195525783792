import { AggregateScoreType } from "~/operations";
import { AdjustedAggregateScoreType } from "..";
import { Space } from "~/lib/types";
import { Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { HomeIcon } from "~/components/icons";
import React from "react";

type BreadcrumbsByTypeParams = {
  type: AdjustedAggregateScoreType;
  space: Space;
};

export const breadcrumbsByType = ({ space, type }: BreadcrumbsByTypeParams) => {
  switch (type) {
    case AggregateScoreType.Asset:
      return [
        <Link
          key="/space/overview"
          component={RouterLink}
          to={`/space/overview?spaceId=${space.id}`}
          display="flex"
        >
          <HomeIcon fontSize="inherit" />
        </Link>,
        <Typography key="/space/vulnerabilities">Affected Assets</Typography>,
      ];
    case AggregateScoreType.Vulnerability:
      return [
        <Link
          key="/space/overview"
          component={RouterLink}
          to={`/space/overview?spaceId=${space.id}`}
          display="flex"
        >
          <HomeIcon fontSize="inherit" />
        </Link>,
        <Typography key="/space/vulnerabilities">Vulnerabilities</Typography>,
      ];
    case AggregateScoreType.Advisory:
      return [
        <Link
          key="/space/overview"
          component={RouterLink}
          to={`/space/overview?spaceId=${space.id}`}
          display="flex"
        >
          <HomeIcon fontSize="inherit" />
        </Link>,
        <Typography key="/space/vulnerabilities">Advisories</Typography>,
      ];
    case AggregateScoreType.Check:
      return [
        <Link
          key="/space/overview"
          component={RouterLink}
          to={`/space/overview?spaceId=${space.id}`}
          display="flex"
        >
          <HomeIcon fontSize="inherit" />
        </Link>,
        <Typography key="/space/vulnerabilities">Checks</Typography>,
      ];
    case AggregateScoreType.Software:
      return [
        <Link
          key="/space/overview"
          component={RouterLink}
          to={`/space/overview?spaceId=${space.id}`}
          display="flex"
        >
          <HomeIcon fontSize="inherit" />
        </Link>,
        <Typography key="/space/vulnerabilities">Software</Typography>,
      ];
    default:
      return [
        <Link
          key="/space/overview"
          component={RouterLink}
          to={`/space/overview?spaceId=${space.id}`}
          display="flex"
        >
          <HomeIcon fontSize="inherit" />
        </Link>,
        <Typography key="/space/vulnerabilities">Vulnerabilities</Typography>,
      ];
  }
};
