import { Box, TableCell, Typography } from "@mui/material";
import { pluralize } from "~/lib/pluralize";

type AssetsCountCellProps = {
  count: number;
  percentage?: number;
};

export const AssetsCountCell = ({
  count,
  percentage,
}: AssetsCountCellProps) => {
  return (
    <TableCell>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontSize={14}>
          {count} {pluralize("Install", count)}
        </Typography>
        {percentage && (
          <Typography variant="caption" color="text.secondary" fontSize={12}>
            {percentage}
          </Typography>
        )}
      </Box>
    </TableCell>
  );
};
