const getPageIndexes = ({
  page = 1,
  rowsPerPage = 10,
}: Record<"page" | "rowsPerPage", number>): Record<"from" | "to", number> => {
  const from = (page - 1) * rowsPerPage;
  const to = from + rowsPerPage;

  return {
    from,
    to,
  };
};

export default getPageIndexes;
