import { Box, TableCell, Typography } from "@mui/material";
import { Clamp } from "../clamp/clamp";
import { FirewatchChip } from "./ChipText";
import { AggregateScoresNode } from "../FirewatchPage";
import { PackageIcon } from "~/components/icons";
import { DynamicIcon } from "~/components/icons/DynamicIcon";

type SoftwareCellProps = { row: AggregateScoresNode; size?: string };

export function SoftwareCell({ row, size }: SoftwareCellProps) {
  const { tags } = row || {};

  return (
    <TableCell>
      <Clamp lines={size === "small" ? 2 : 1}>
        <Typography
          fontSize="14px"
          fontWeight="bold"
          style={{
            display: "flex",
            alignItems: "center",
            lineHeight: "16px",
          }}
          gap={1}
          data-testid="software-name"
        >
          <DynamicIcon
            iconId={row?.iconId}
            defaultIcon={PackageIcon}
            size="16"
          />
          {row?.title}
        </Typography>
      </Clamp>
      {size !== "small" && (
        <Box display="flex" gap={1} mt={1}>
          {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
        </Box>
      )}
    </TableCell>
  );
}
