import { useCallback } from "react";
import { useBeforeUnload, useBlocker } from "react-router-dom";

// https://github.com/remix-run/react-router/issues/8139#issuecomment-1382428200
// https://gist.github.com/chaance/2f3c14ec2351a175024f62fd6ba64aa6
export function usePrompt(
  message: string | null | undefined | false,
  { beforeUnload }: { beforeUnload?: boolean } = {},
) {
  // TODO: `useBlocker` can only be used within a data router.
  //
  // const blocker = useBlocker(
  //   useCallback(
  //     () => (typeof message === "string" ? !window.confirm(message) : false),
  //     [message]
  //   )
  // );
  // const prevState = useRef(blocker.state);
  // useEffect(() => {
  //   if (blocker.state === "blocked") {
  //     blocker.reset();
  //   }
  //   prevState.current = blocker.state;
  // }, [blocker]);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (beforeUnload && typeof message === "string") {
          event.preventDefault();
          event.returnValue = message;
        }
      },
      [message, beforeUnload],
    ),
    { capture: true },
  );
}
