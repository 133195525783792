import { FormatDateYear, formatDistance } from "~/lib/date";
import {
  ApplySpacePlanInput,
  LoadSpacePlanQuery,
  SpacePlanQueryMutationDestinationType,
  SpacePlanScoringSummary,
} from "~/operations";
import { Sort } from "~/lib/types";

type RequiredNotNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
type Ensure<T, K extends keyof T> = T & Required<RequiredNotNull<Pick<T, K>>>;
type SpacePlan = NonNullable<LoadSpacePlanQuery["spacePlan"]>;
type ActiveGroup = Ensure<SpacePlan["active"], "queries">;
type ExceptionsGroup = Ensure<SpacePlan["exceptions"], "queries">;
type MilestoneGroup = Ensure<
  NonNullable<SpacePlan["milestones"]>[0],
  "queries"
>;
type Checks =
  | ActiveGroup["queries"]
  | ExceptionsGroup["queries"]
  | MilestoneGroup["queries"];
type Check = Checks[0];
export type DraftCheck = Check & {
  completion: number;
  rank: number;
  groupUid: string;
  prevGroupUid: string;
  origGroupUid: string;
};
export type DraftMilestone = {
  uid: string;
  endDate?: string | null;
  title?: string | null;
  justification?: string | null;
};

function parseCheck(q: Check, groupUid: string): DraftCheck {
  const impact = q.mquery.impact?.value || 0;
  const success = q.scoreDistribution?.pass || 0;
  const total =
    success +
    (q.scoreDistribution?.fail || 0) +
    (q.scoreDistribution?.error || 0) +
    (q.scoreDistribution?.unknown || 0);
  const completion = success / total || 0;
  const rank =
    ((total - success) * (100 - impact) + 100 * success) / total || 0;
  return {
    ...q,
    groupUid,
    prevGroupUid: groupUid,
    origGroupUid: groupUid,
    completion,
    rank,
  };
}

export const FUTURE_GOALS_UID = "milestone-futuregoals";

export class RiskPlanner {
  spacePlan: SpacePlan;

  checks: { [mrn: string]: DraftCheck } = {};

  milestones: { [uid: string]: DraftMilestone } = {};

  targetStep = 1;
  moveableCnt = 0;
  recommendedIdx = 0;
  recommendedScore = 100;
  minScore = 0;
  maxScore = 100;

  constructor(spacePlan: SpacePlan) {
    // clone spacePlan since apollo result data is readonly
    this.spacePlan = JSON.parse(JSON.stringify(spacePlan)) as SpacePlan;

    this.spacePlan.active.queries?.forEach((q) => {
      this.checks[q.mquery.mrn] = parseCheck(q, "active");
    });

    this.spacePlan.exceptions.queries?.forEach((q) => {
      this.checks[q.mquery.mrn] = parseCheck(q, "exceptions");
    });

    this.spacePlan.milestones?.forEach((m) => {
      const { uid, endDate, title, justification, queries } = m;
      this.addMilestone({ uid, endDate, title, justification });

      queries?.forEach((q) => {
        this.checks[q.mquery.mrn] = parseCheck(q, m.uid);
        this.moveCheckTo(q.mquery.mrn, uid);
      });
    });

    if (!this.milestones[FUTURE_GOALS_UID]) {
      this.addMilestone({ uid: FUTURE_GOALS_UID, title: "Future Goals" });
    }

    // moveableCnt
    // The position of the first element that is fully completed. Completed
    // elements are definitely part of the baseline and thus fixed.
    let fixedIdx = this.rankedChecks.length - 1;
    for (; fixedIdx >= 0; fixedIdx--) {
      if (this.rankedChecks[fixedIdx].completion < 1) {
        fixedIdx++;
        break;
      }
    }
    this.moveableCnt = fixedIdx;

    const recommendedCompletion = 0.8;

    const initialRecommendation = (list: DraftCheck[]): number => {
      // 1. We try to go by completion and find the first entry with a low completion
      if (list == null || list.length == 0) return 0;
      for (let i = list.length - 1; i >= 0; i--) {
        if (list[i].completion < recommendedCompletion) {
          return i + 1;
        }
      }

      // 2. If that doesn't work, we try going to rank, using the average rank as
      // the starting point
      let ranksum = 0;
      let rankidx = 0;
      for (; rankidx < list.length && list[rankidx].completion < 1; rankidx++) {
        ranksum += list[rankidx].rank;
      }
      // 3. Edge-case: if we have no ranks at all, then everything is part of
      // the baseline
      if (rankidx == 0) {
        return 0;
      }

      const rankavg = ranksum / rankidx;
      for (; rankidx >= 0; rankidx--) {
        if (list[rankidx].rank < rankavg) {
          return rankidx;
        }
      }

      // everything is part of the baseline; sanity-fallback
      return 0;
    };

    this.recommendedIdx = initialRecommendation(this.rankedChecks);
    this.minScore = this.checksOverallScore(this.rankedChecks);
  }

  addMilestone(input: {
    uid?: string | null;
    endDate?: string | null;
    title?: string | null;
    justification?: string | null;
  }) {
    const uid = input.uid || `milestone-${crypto.randomUUID()}`;
    this.milestones[uid] = {
      uid,
      title: input.title || "",
      justification: input.justification || "",
      endDate: input.endDate,
    };
  }

  removeMilestone(uid: string) {
    this.rankedChecks
      .filter((c) => c.groupUid === uid)
      .forEach((c) => {
        this.moveCheckTo(c.mquery.uid, FUTURE_GOALS_UID);
      });
    delete this.milestones[uid];
  }

  moveCheckTo(mrn: string, groupUid: string) {
    const currUid = this.checks[mrn].groupUid;
    if (currUid !== groupUid) {
      this.checks[mrn].prevGroupUid = currUid;
      this.checks[mrn].groupUid = groupUid;
    }
  }

  moveChecksTo(mrns: string[], groupUid: string) {
    mrns.forEach((mrn) => this.moveCheckTo(mrn, groupUid));
  }

  setTargetStep(step: number) {
    this.targetStep = step;
    this.rankedChecks.forEach((check, index) => {
      if (index >= step - 1) {
        this.moveToBaseline(check.mquery.mrn);
      } else {
        this.moveFromBaseline(check.mquery.mrn);
      }
    });
  }

  moveFromBaseline(mrn: string) {
    const currGroupUid = this.checks[mrn].groupUid;
    if (currGroupUid === "active") {
      const prevGroupUid = this.checks[mrn].prevGroupUid;
      const nextGroupUid =
        prevGroupUid === "active" ? FUTURE_GOALS_UID : prevGroupUid;
      this.moveCheckTo(mrn, nextGroupUid);
    }
  }

  moveToBaseline(mrn: string) {
    const currGroupUid = this.checks[mrn].groupUid;
    if (currGroupUid !== "active") {
      this.moveCheckTo(mrn, "active");
    }
  }

  checksForGroup(groupUid: string) {
    return this.rankedChecks.filter((c) => c.groupUid === groupUid);
  }

  checksScoreSummary(
    checks: Pick<DraftCheck, "scoreDistribution">[],
  ): SpacePlanScoringSummary {
    return checks.reduce(
      (acc, curr) => {
        const { pass = 0, fail = 0, error = 0 } = curr.scoreDistribution || {};
        acc.pass = acc.pass + pass;
        acc.fail = acc.fail + fail;
        acc.error = acc.error + error;
        acc.total = acc.total + pass + fail + error;
        return acc;
      },
      {
        total: 0,
        pass: 0,
        fail: 0,
        error: 0,
        __typename: "SpacePlanScoringSummary",
      },
    );
  }

  checksGradeScore(checks: Pick<DraftCheck, "scoreDistribution">[]): number {
    const summary = this.checksScoreSummary(checks);
    return Math.round((summary.pass / summary.total) * 100);
  }

  checksOverallScore(checks: Pick<DraftCheck, "rank">[]): number {
    const x = checks.reduce((acc, x) => acc + x.rank, 0) / checks.length;
    return Math.round(x);
  }

  milestoneTitle(groupUid: string) {
    const group = this.milestones[groupUid];
    let title = group.title;
    if (!title) {
      title = "Milestone";
      if (group.endDate) {
        title = `${formatDistance(
          new Date(group.endDate),
          new Date(),
        )} - ${FormatDateYear(group.endDate)}`;
      }
    }
    return title;
  }

  milestoneCompletion(groupUid: string) {
    const groupChecks = this.rankedChecks.filter(
      (c) => c.groupUid === groupUid,
    );
    const total = groupChecks.length;
    const completionTotal = groupChecks.reduce(
      (acc, c) => acc + c.completion,
      0,
    );
    return total === 0 ? 0 : completionTotal / total;
  }

  get rankedChecks() {
    return Object.values(this.checks).sort((a, b) => {
      return a.rank - b.rank;
    });
  }

  get activeChecks() {
    return this.rankedChecks.filter((c) => c.groupUid === "active");
  }

  get exceptionsChecks() {
    return this.rankedChecks.filter((c) => c.groupUid === "exceptions");
  }

  get plannedChecks() {
    return this.rankedChecks.filter((c) => c.groupUid !== "active");
  }

  get milestoneChecks() {
    return this.rankedChecks.filter(
      (c) => c.groupUid !== "active" && c.groupUid !== "exceptions",
    );
  }

  get futureGoalsChecks() {
    return this.rankedChecks.filter((c) => c.groupUid === FUTURE_GOALS_UID);
  }

  get milestonesList() {
    return Object.values(this.milestones).sort((a, b) => {
      const aTime = new Date(a.endDate || 864e13).getTime();
      const bTime = new Date(b.endDate || 864e13).getTime();
      return aTime - bTime;
    });
  }

  get deltaInput(): Pick<ApplySpacePlanInput, "deltas" | "milestones"> {
    return {
      milestones: this.milestonesList.map(({ uid, endDate }) => ({
        uid,
        endDate,
      })),
      deltas: this.rankedChecks
        .filter((c) => c.origGroupUid !== c.groupUid)
        .map((c) => {
          const queryMrn = c.mquery.mrn;
          const to =
            c.groupUid === "active"
              ? SpacePlanQueryMutationDestinationType.Active
              : c.groupUid === "exceptions"
                ? SpacePlanQueryMutationDestinationType.Exceptions
                : SpacePlanQueryMutationDestinationType.Milestone;
          const milestoneUid =
            to === SpacePlanQueryMutationDestinationType.Milestone
              ? c.groupUid
              : undefined;
          return { queryMrn, to, milestoneUid };
        }),
    };
  }

  get isDirty() {
    const { deltas } = this.deltaInput;
    return deltas && deltas.length > 0;
  }
}

export const sortByImpact = (a: DraftCheck, b: DraftCheck) => {
  const aImpact = a.mquery.impact?.value || 0;
  const bImpact = b.mquery.impact?.value || 0;
  return bImpact - aImpact;
};

export const sortByCompletion = (a: DraftCheck, b: DraftCheck) => {
  return b.completion - a.completion;
};

export const sortByTitle = (a: DraftCheck, b: DraftCheck) => {
  const aTitle = a.mquery.title.toUpperCase();
  const bTitle = b.mquery.title.toUpperCase();
  if (aTitle === bTitle) return 0;
  return bTitle < aTitle ? -1 : 1;
};

export const sortBy = (sort: Sort) => (a: DraftCheck, b: DraftCheck) => {
  const sortBy = () => {
    switch (sort.field) {
      case "COMPLETION":
        return sortByCompletion(a, b);
      case "CHECK":
        return sortByTitle(a, b);
      case "IMPACT":
      default:
        return sortByImpact(a, b);
    }
  };
  const direction = sort.direction === "ASC" ? 1 : -1;
  return sortBy() * direction;
};
