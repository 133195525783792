import { Obj } from "~/components/ui-library";

type GetQueryMrnProps = Obj & {
  namespace: string;
  uid: string;
};

const getQueryMrn = ({ namespace, uid }: GetQueryMrnProps): string =>
  namespace !== "mondoohq"
    ? `//policy.api.mondoo.app/spaces/${namespace}/queries/${uid}`
    : `//policy.api.mondoo.app/queries/${uid}`;

export default getQueryMrn;
