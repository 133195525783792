import { OverflowHelper } from "~/components/OverflowHelper";
import { Box, TableCell, Typography } from "@mui/material";
import { Score2CvssScore } from "~/lib/score";
import { cvssVersion } from "~/components/cvss";

import { SoftwareDetailsCveCellProps } from "./types";
import { Tag } from "~/operations";
import { FirewatchChip } from "~/components/FirewatchRow/ChipText";

export const TitleCell = ({ cve, ...props }: SoftwareDetailsCveCellProps) => {
  // Manually build the tags array to display as chips
  let tags: Tag[] = [];
  if (cve.cvssScore.value > 0) {
    tags.push({
      key: `CVSS${cvssVersion(cve.cvssScore.vector)}`,
      value: Score2CvssScore(cve.cvssScore.value) || "",
      __typename: "Tag",
    });
  }
  if (cve.epssScore?.probability) {
    tags.push({
      key: "EPSS",
      value: (Number(cve.epssScore.probability) * 100).toFixed(1) + "%",
      __typename: "Tag",
    });
  }

  return (
    <TableCell width="70%" data-testid={props["data-testid"]}>
      <OverflowHelper>
        <Typography fontSize="14px" data-testid="cve-id">
          <Typography fontSize="14px" fontWeight="bold" variant="caption">
            {cve.id}:{" "}
          </Typography>
          {cve.title}
        </Typography>
      </OverflowHelper>
      <Box display="flex" gap={1} mt={1}>
        {tags?.map((tag) => <FirewatchChip key={tag.key} tag={tag} />)}
      </Box>
    </TableCell>
  );
};
