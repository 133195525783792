export const Validations = {
  nameInvalidLengthRegex: /^(.){2,30}$/,
  nameInvalidLengthErrorMsg: "The name must be between 2 and 30 characters.",

  nameInvalidCharactersRegex: /^([a-zA-Ÿ \-'_]|\d){2,30}$/,
  nameInvalidCharactersErrorMsg:
    "The name uses invalid characters. Enter letters, numbers, single quotes, hyphens or spaces.",

  idInvalidLengthRegex: /^(.){6,35}$/,
  idInvalidLengthErrorMsg: "The id must be between 6 and 35 characters.",

  idInvalidCharactersRegex: /^[a-z]([\d-_]|[a-z]){4,33}[a-z\d]$/,
  idInvalidCharactersErrorMsg:
    "The id uses invalid characters. Enter lowercase letters, numbers or hyphens.",

  idInformMsg:
    "Id can have lowercase letters, digits, or hyphens. It must start with a lowercase letter and end with a letter or number.",

  isIntegrationMrn:
    /(^\/\/integration.api.mondoo.app)(\/spaces\/).*(\/aws|managed\/).*/,

  isNumber: /^[0-9]*$/,
};
