import { CSSProperties, ReactNode } from "react";
import { Typography, useTheme } from "@mui/material";
import { TypographyProps, SpacingProps, SxProps } from "@mui/system";

export type TextProps = TypographyProps &
  SpacingProps & {
    className?: string;
    bold?: boolean;
    secondary?: boolean;
    color?: CSSProperties["color"];
    whiteSpace?: CSSProperties["whiteSpace"];
    children?: ReactNode;
    onClick?: () => void;
    sx?: SxProps;
    noWrap?: boolean;
  };

const Text = ({
  className,
  bold,
  secondary,
  color,
  fontSize,
  ...restProps
}: TextProps) => {
  const theme = useTheme();

  return (
    <Typography
      className={className}
      fontWeight={bold ? 700 : 400}
      fontSize={fontSize || 16}
      color={secondary ? theme.palette.text.secondary : color}
      {...restProps}
    />
  );
};

export default Text;
