import { useCallback } from "react";
import { Dialog, DialogContent } from "@mui/material";
import { Config } from "~/configuration_provider";

type RequestQuoteDialogProps = {
  open: boolean;
  onClose: () => void;
};

export function RequestQuoteDialog({ open, onClose }: RequestQuoteDialogProps) {
  const formContainerRef = useCallback((node: HTMLElement | null) => {
    if (node?.id) {
      try {
        const region = Config.VITE_HUBSPOT_REGION;
        const portalId = Config.VITE_HUBSPOT_PORTAL_ID;
        const formId = Config.VITE_HUBSPOT_CONTACT_FORM_ID;
        const target = `#${node.id}`;
        // No hbspt typings available :(
        // @ts-ignore
        hbspt.forms.create({ region, portalId, formId, target });
      } catch (e) {
        console.error("Error creating hubspot form", e);
      }
    }
  }, []);

  return (
    <Dialog open={open} onClose={onClose} keepMounted>
      <DialogContent
        id="quote-form-container"
        ref={formContainerRef}
        sx={{ minWidth: 600, background: "white" }}
      >
        {/* Hubspot form will be created in here. */}
      </DialogContent>
    </Dialog>
  );
}
