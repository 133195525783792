import { Markdown } from "~/components/markdown";
import { ShowMore } from "./ShowMore";

export type ShowMoreSummaryProps = {
  text: string;
  maxHeight?: number;
  id?: string;
};

export function ShowMoreSummary({ text, maxHeight, id }: ShowMoreSummaryProps) {
  return (
    <ShowMore id={id} maxHeight={maxHeight}>
      <Markdown source={text} sx={{ color: "text.secondary" }} />
    </ShowMore>
  );
}
