import { Chip as MuiChip, alpha, styled } from "@mui/material";

export const Chip = styled(MuiChip)((props) => {
  const defaultColor = props.theme.palette.text.disabled;
  const color =
    props.color && props.color !== "default"
      ? props.theme.palette[props.color].main
      : defaultColor;
  return {
    color,
    backgroundColor: alpha(color, 0.1),
    textTransform: "uppercase",
    fontSize: "14px",
    fontWeight: 600,
  };
});
