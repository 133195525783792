import { Advisories } from "./Advisories";
import { useAssetOutlet } from "~/pages/inventory/asset";
import { useSearchParams } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";

type AdvisoriesTabProps = {
  isCicd?: boolean;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export const AdvisoriesTab = ({
  isCicd = false,
  availablePermissions,
}: AdvisoriesTabProps) => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { space, asset, isVulnerabilityScanningEnabled } = useAssetOutlet();
  const assetMrn = isCicd ? (searchParams.get("jobId") as string) : asset?.mrn;

  if (!space || !asset || !assetMrn) return <></>;

  return (
    <Advisories
      assetMrn={assetMrn}
      space={space}
      isVulnerabilityScanningEnabled={isVulnerabilityScanningEnabled}
      availablePermissions={availablePermissions}
    />
  );
};
