import { Route, Routes } from "react-router-dom";
import { TestIamActionsQuery } from "~/operations";
import { Space } from "~/lib/types";
import { CasesPage } from "~/components/cases/components/CasesPage";

export type CasesRoutesProps = {
  space: Space;
  availablePermissions: TestIamActionsQuery["testIamActions"];
};

export function CasesRoutes({ space, availablePermissions }: CasesRoutesProps) {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <CasesPage
            space={space}
            availablePermissions={availablePermissions}
          />
        }
      />
    </Routes>
  );
}
