import { useParams, Link as RouterLink } from "react-router-dom";
import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import {
  K8sConfigurationOptionsInput,
  K8sScanNodesStyle,
  useGetClientIntegrationQuery,
  useGetClientIntegrationTokenQuery,
} from "~/operations";
import { Space } from "~/lib/types";
import appsData from "~/pages/integration-setup.json";

import { LoadingFailedPage, LoadingPage } from "~/components/loading";
import { HomeIcon } from "~/components/icons";
import { OperatorConfiguration } from "./operator-configuration/operator-configuration";

export type UpdateK8Data = {
  name: string;
  configurationOptions: K8sConfigurationOptionsInput;
  token: string;
  handleUpdate: (configOptions: K8sConfigurationOptionsInput) => Promise<void>;
};

type K8UpdateConfigurationProps = {
  space: Space;
};

export const K8UpdateConfiguration = ({
  space,
}: K8UpdateConfigurationProps) => {
  const { integrationId } = useParams();
  const integrationMrn: string = `//integration.api.mondoo.app/spaces/${space.id}/integrations/${integrationId}`;

  const { data, loading, error } = useGetClientIntegrationQuery({
    variables: { mrn: integrationMrn },
  });

  const {
    data: tokenData,
    loading: tokenLoading,
    error: tokenError,
  } = useGetClientIntegrationTokenQuery({
    variables: { input: { mrn: integrationMrn } },
  });

  if (loading || tokenLoading) {
    return <LoadingPage />;
  }

  const integration = data?.clientIntegration.integration;

  if (
    error ||
    tokenError ||
    !tokenData ||
    !integrationId ||
    integration?.configurationOptions?.__typename !== "K8sConfigurationOptions"
  ) {
    return <LoadingFailedPage />;
  }

  const { name, configurationOptions } = integration;
  const { token } = tokenData.getClientIntegrationToken;

  // configurationOptions is coming through as the type of agent instead of K8s
  const defaultValues = {
    integrationName: name,
    scanNodes: configurationOptions?.scanNodes || false,
    scanNodesStyle:
      configurationOptions?.scanNodesStyle || K8sScanNodesStyle.Cronjob,

    // workload scanning section
    scanWorkloads: configurationOptions?.scanWorkloads || false,
    workloadImageScanning: configurationOptions?.scanPublicImages || false,
    namespaceScanning: false,
    namespaceAllowList:
      configurationOptions?.namespaceAllowList?.join(", ") || "",
    namespaceDenyList:
      configurationOptions?.namespaceDenyList?.join(", ") || "",

    // scan incoming deployments section
    scanDeploys: configurationOptions?.scanDeploys || false,
    certificateManager: configurationOptions?.certificateManager || "",
  };

  const breadcrumbs = [
    <Link
      key="/space/overview"
      component={RouterLink}
      to={`/space/overview?spaceId=${space.id}`}
      display="flex"
    >
      <HomeIcon fontSize="inherit" />
    </Link>,
    <Link
      key="/space/integrations"
      component={RouterLink}
      to={`/space/integrations?spaceId=${space.id}`}
    >
      Integrations
    </Link>,
    <Link
      key="/space/integrations/kubernetes"
      component={RouterLink}
      to={`/space/integrations/kubernetes?spaceId=${space.id}`}
    >
      Kubernetes
    </Link>,
    <Link
      key="/space/integrations/kubernetes/integration"
      component={RouterLink}
      to={`/space/integrations/kubernetes/${integrationId}?spaceId=${space.id}`}
    >
      {name}
    </Link>,
    <Typography key={1}>Operator Configuration</Typography>,
  ];

  return (
    <Box>
      <Breadcrumbs sx={{ mb: 3 }} separator="›">
        {breadcrumbs}
      </Breadcrumbs>
      <OperatorConfiguration
        space={space}
        setup={appsData["mondoo/kubernetes"]}
        updateFlow={{
          values: defaultValues,
          integrationMrn,
          integrationId,
          token,
        }}
      />
    </Box>
  );
};
